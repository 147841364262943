import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import { SmLoginReqDto } from "../../model/social-media/sm-login-req.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const SocialMediaApi = {
  smLogin: async (request: SmLoginReqDto): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.socialMedia.smLogin,
      request
    );
    return response.data;
  },
  getSocialMediaSpecs: async (): Promise<ApiResponse<SmBriefRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<SmBriefRespDto[]>>(
      ENDPOINTS.userMicroservice.socialMedia.getSocialMediaSpec
    );
    return response.data;
  },

  getSocialMediaSpecByCreatorId: async (
    creatorId: string
  ): Promise<ApiResponse<SmBriefRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<SmBriefRespDto[]>>(
      ENDPOINTS.userMicroservice.socialMedia.getSocialMediaSpecByCreatorId(
        creatorId
      )
    );
    return response.data;
  },

  unlinkSocialMedia: async (
    platform: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.socialMedia.unlinkSocialMedia(platform)
    );
    return response.data;
  },
};
export default SocialMediaApi;
