import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { ConversationMessageDto } from "../../model/campaign/conversation-message.dto";
import { DeliverableConversationDto } from "../../model/campaign/deliverable-conversation.dto";
import { DeliverableMessageWIdDto } from "../../model/campaign/deliverable-message-w-id.dto";
import { DeliverableMessageDto } from "../../model/campaign/deliverable-message.dto";
import { OngoingDeliverableExtendedDto } from "../../model/campaign/ongoing-deliverable-extended.dto";
import { EmpInterceptor } from "../emp-interceptor";

const OngoingTaskApi = {
  fetchBrandConversations: async (
    taskId: string
  ): Promise<ApiResponse<DeliverableConversationDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<DeliverableConversationDto[]>>(
      ENDPOINTS.campaignMsvc.ongoingTaskConversation.brandConversations(taskId)
    );
    return response.data;
  },

  fetchSellerConversations: async (
    taskId: string,
    representativeId: string
  ): Promise<ApiResponse<DeliverableConversationDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<DeliverableConversationDto[]>>(
      `${ENDPOINTS.campaignMsvc.ongoingTaskConversation.sellerConversations(taskId)}?representativeId=${representativeId}`
    );
    return response.data;
  },

  sendMessage: async (
    request: DeliverableMessageDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.ongoingTaskConversation.sendMessage,
      request
    );
    return response.data;
  },

  markAllMessagesForBrand: async (
    conversationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.ongoingTaskConversation.markAllMessagesForBrand(
        conversationId
      )
    );
    return response.data;
  },

  markAllMessagesForSeller: async (
    conversationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.ongoingTaskConversation.markAllMessagesForSeller(
        conversationId
      )
    );
    return response.data;
  },

  fetchMessageInConversation: async (
    conversationId: string,
    offset?: number,
    pageSize?: number
  ): Promise<ApiResponse<ConversationMessageDto>> => {
    const pageSizeQuery = pageSize ?? 30;
    const offsetQuery = offset ?? 0;
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ConversationMessageDto>>(
      `${ENDPOINTS.campaignMsvc.ongoingTaskConversation.getMessagesInConversation(conversationId)}?pageSize=${pageSizeQuery}&offset=${offsetQuery}`
    );
    return response.data;
  },
  fetchSellerDeliverables: async (
    taskId: string,
    deliverableId?: string
  ): Promise<ApiResponse<OngoingDeliverableExtendedDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.ongoingTask.sellerDeliverables(taskId)}`;
    if (deliverableId) {
      url += `?deliverableId=${deliverableId}`;
    }
    const response =
      await axios.get<ApiResponse<OngoingDeliverableExtendedDto[]>>(url);
    return response.data;
  },
  fetchBrandDeliverables: async (
    taskId: string,
    deliverableId?: string
  ): Promise<ApiResponse<OngoingDeliverableExtendedDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.ongoingTask.brandDeliverables(taskId)}`;
    if (deliverableId) {
      url += `?deliverableId=${deliverableId}`;
    }
    const response =
      await axios.get<ApiResponse<OngoingDeliverableExtendedDto[]>>(url);
    return response.data;
  },
};
export default OngoingTaskApi;
