import "./subscription-alert-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PlanSelectionView from "../views/plan-selection-view";
import { AnimatePresence, easeOut, motion, useAnimation } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { useLocation, useNavigate } from "react-router-dom";
import ToastUtils from "../../utilities/toast-utils";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../utilities/user-utils";
import EmpAnimatedLoader from "../shared/emp-animated-loader/emp-animated-loader";
import UserApi from "../../api/user-msvc/user.api";
import SelectPaymentMethodView from "../views/select-payment-method-view";
import { CardDto } from "../../model/payment/payment/card.dto";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { ENV_CONSTANTS, getEnv } from "../../constants/envConstants";
import PaymentFailModal, { PaymentFailModalRef } from "./payment-fail-modal";

export interface SubscriptionAlertModalRef {
  show: () => void;
  hide: () => void;
}

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: easeOut,
    },
  },
};

const growVariant = {
  hidden: { scale: 0.2, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.4,
      ease: easeOut,
    },
  },
  exit: {
    opacity: 0,
  },
};

type VisualVariant = {
  image: string;
  title: JSX.Element;
  description: string;
};
const KeyVisualVariant: { [key: string]: VisualVariant } = {
  creatorAccess: {
    image:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/influencer.webp",
    title: <>"Unlock our full suite of Features"</>,
    description:
      "You will gain access to campaigns, and you will be able to hire other creators for the job",
  },
  default: {
    image:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/megaphone.png",
    title: <>"Unlock our full suite of Features"</>,
    description:
      "You will gain access to campaigns, and you will be able to hire other creators for the job",
  },
};

type StepType = "plan-selection" | "payment-method";
interface SelectedPlan {
  id: string;
  type: "trial" | "price" | "proceed";
}
const SubscriptionAlertModal = forwardRef((_, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const [enrolmentLoaderVisible, setEnrolmentLoaderVisible] =
    useState<boolean>(false);
  const appBaseUrl = useRef<string>(ENV_CONSTANTS[getEnv()].appUrl);

  const hasDismissedRef = useRef(true);
  const paymentFailModalRef = useRef<PaymentFailModalRef>();

  const [keyVisualVariant, setKeyVisualVariant] = useState<
    "creatorAccess" | "default"
  >("default");
  const [step, setStep] = useState<StepType>("plan-selection");
  const cardsRef = useRef<CardDto[]>();
  const selectedPlanRef = useRef<SelectedPlan>();

  const dismiss = useCallback(async () => {
    setStep("plan-selection");
    await empDelay(300);
    setVisible(false);
  }, []);

  
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (hasDismissed?: boolean) => {
    hasDismissedRef.current = hasDismissed === undefined ?? true;
    setVisible(true);
  };

  return (
    <>
      <PaymentFailModal ref={paymentFailModalRef} />
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={"hidden"}
            variants={fadeInVariant}
            animate={"visible"}
            exit={"hidden"}
            className="subscription-modal-overlay"
          ></motion.div>
        )}
        {visible && (
          <motion.div
            initial={"hidden"}
            exit={"exit"}
            variants={growVariant}
            animate={"visible"}
            className="emp-subscription-alert-modal"
          >
            <EmpAnimatedLoader isVisible={enrolmentLoaderVisible} />
            <div className="content-section">
              <div className="left-section">
                <img
                  className="logo"
                  alt="Emplifive"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/emplifive-logo-white.svg"
                />
                <div className="pattern-wrapper">
                  <div className="image-wrapper">
                    <img
                      alt="Megaphone"
                      className="megaphone-img"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/megaphone.png"
                    />
                  </div>
                  <img
                    className="pattern"
                    alt="pattern"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/patterns//emplifive-pattern-1.svg"
                  />
                </div>
                <h1 className="cta-header">
                  Unlock our <span className="highlighted">full</span> suite of
                  Features
                </h1>
                <p className="cta-description">
                  You will gain access to campaigns, and you will be able to
                  hire other creators for the job
                </p>
              </div>
              <div className="right-section">
                {hasDismissedRef && (
                  <div
                    onClick={() => dismiss()}
                    className="dismiss-icon-wrapper"
                  >
                    <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
                  </div>
                )}
                {step === "plan-selection" && (
                  <PlanSelectionView
                    callbackUrl={`${appBaseUrl.current}/brand/home?emp-callback=subscription`}
                    toPaymentMethod={(
                      cards: CardDto[],
                      selectedPlan: SelectedPlan
                    ) => {
                      cardsRef.current = cards;
                      selectedPlanRef.current = selectedPlan;
                      setStep("payment-method");
                    }}
                  />
                )}
                {step === "payment-method" &&
                  cardsRef.current &&
                  selectedPlanRef.current && (
                    <SelectPaymentMethodView
                      callbackUrl={`${appBaseUrl.current}/brand/home?emp-callback=subscription`}
                      cards={cardsRef.current}
                      selectedPlan={selectedPlanRef.current}
                      onBack={() => {
                        setStep("plan-selection");
                      }}
                      onDismiss={() => {
                        dismiss();
                      }}
                    />
                  )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});

export default SubscriptionAlertModal;
