import "./rate-card-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ToastUtils from "../../utilities/toast-utils";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { RateCardRespDto } from "../../model/social-media/rate-card-resp.dto";
import UsdIcon from "../../components/icon/usd-icon";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import CustomRateCardModal, {
  CustomRateCardModalRef,
} from "./custom-rate-card-modal";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import PlusIcon from "../../components/icon/plus-icon";
import EmpException from "../../exception/empException";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import TrashIcon from "../../components/icon/trash-icon";
import { RateCardSaveReqDto } from "../../model/social-media/rate-card-save-req.dto";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import TranslationUtil from "../../utilities/translation.util";
import { FormattedMessage, useIntl } from "react-intl";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";

export type EmpSocialMediaPlatform = "Facebook" | "Instagram" | "TikTok" | "X";
export interface RateCardModalRef {
  show: (creatorId: string, platform: EmpSocialMediaPlatform) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const RateCardModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);

  const [rateCardDto, setRateCardDto] = useState<RateCardRespDto[]>();
  const creatorIdRef = useRef<string>();
  const platformRef = useRef<string>();
  // Form is set to empty at first
  const [form, setForm] = useState<IFormGroup>();
  const customRateCardModalRef = useRef<CustomRateCardModalRef>();
  const addedIdRefs = useRef<number[]>([]);
  const deletedIdRefs = useRef<number[]>([]);
  const saveRateBtnRef = useRef<EmpButtonRef>();
  const isSavingRef = useRef<boolean>(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (creatorId: string, platform: EmpSocialMediaPlatform) => {
    creatorIdRef.current = creatorId;
    platformRef.current = platform;
    fetchRateCard(creatorId, platform);
    setVisible(true);
  };

  const fetchRateCard = async (
    creatorId: string,
    platform: EmpSocialMediaPlatform
  ) => {
    try {
      const resp = await RateCardApi.getOneRateCard(creatorId, platform);
      setRateCardDto(resp.data);
      // generate form data
      const formGroup: IFormGroup = {};
      const numericRegex = /^\d+(\.\d+)?$/;
      const only2DecimalPlaceRegex = /^\d+(\.\d{1,10})?$/;

      for (const rateCard of resp.data) {
        formGroup[`rate:${rateCard.rateId}`] = new FormControl(
          "text",
          [
            new NumericRangeValidator(
              -1,
              50000,
              "Budget must be more than USD -1",
              "Rate must not exceed USD 50,000"
            ),
            new PatternValidator(
              numericRegex,
              intl.formatMessage({ id: "validation_numericPatternValidator" })
            ),
            new PatternValidator(
              only2DecimalPlaceRegex,
              intl.formatMessage(
                { id: "validation_numericDecimalsPatternValidator" },
                { point: "2" }
              )
            ),
          ],
          rateCard.price.toFixed(2)
        );
        for (const addOnCard of rateCard.addOnRates) {
          formGroup[`rate:${addOnCard.rateId}`] = new FormControl(
            "text",
            [
              new NumericRangeValidator(
                -1,
                50000,
                "Budget must be more than USD -1",
                "Rate must not exceed USD 50,000"
              ),
              new PatternValidator(
                numericRegex,
                intl.formatMessage({ id: "validation_numericPatternValidator" })
              ),
              new PatternValidator(
                only2DecimalPlaceRegex,
                intl.formatMessage(
                  { id: "validation_numericDecimalsPatternValidator" },
                  { point: "2" }
                )
              ),
            ],
            addOnCard.price.toFixed(2)
          );
        }
      }
      setForm(formGroup);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.reset(form!);
    setForm({ ...form });
  };

  /**
   * Saves a custom rate to the rateCardDto array based on the provided rateCard and context.
   * If the context is "rate", it adds the rate to the top-level rateCardDto array.
   * If the context is "add-on", it adds the rate to the corresponding parent rate's addOnRates array.
   *
   * @param rateCard - The rateCard object to be saved.
   * @param context - The context in which the rateCard is being saved ("add-on" or "rate").
   */
  const customRateOnSave = (
    rateCard: RateCardRespDto,
    context: "add-on" | "rate"
  ) => {
    try {
      if (!form || !rateCardDto) throw new EmpException("Form not ready");
      form[`rate:${rateCard.rateId}`] = new FormControl(
        "text",
        [
          new LengthValidator(
            0,
            100,
            undefined,
            "Rate must not exceed 100 characters"
          ),
          new PatternValidator(/^\d+(\.\d{1,10})?$/, "Numeric Value Only"),
        ],
        rateCard.price.toString()
      );

      addedIdRefs.current.push(rateCard.rateId);

      if (context === "rate") {
        rateCardDto.push(rateCard);
        setRateCardDto([...rateCardDto]);
      } else if (context === "add-on") {
        const parentRateCard = rateCardDto.find(
          (elem) => elem.rateId === rateCard.parentId!
        );
        if (!parentRateCard) throw new EmpException("Parent Id Not found");
        parentRateCard.addOnRates.push(rateCard);
        setRateCardDto([...rateCardDto]);
      }
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
    }
  };

  /**
   * Deletes a rate from the rateCardDto array based on the provided rateId and parentId (optional).
   * If a parentId is specified, it deletes the rate from the corresponding addOnRates array.
   * If no parentId is provided, it deletes the rate from the top-level rateCardDto array.
   *
   * @param rateId - The ID of the rate to be deleted.
   * @param parentId - The ID of the parent rate (optional).
   */
  const deleteRateOnClick = (rateCard: RateCardRespDto) => {
    try {
      if (!form || !rateCardDto) throw new EmpException("Form not ready");
      // This ia a add-on
      const rateIdToBeDeleted: number[] = [];
      if (rateCard.parentId !== undefined) {
        const parentIdx = rateCardDto.findIndex(
          (elem) => elem.rateId === rateCard.parentId
        );
        if (parentIdx < 0) throw new EmpException("Parent Id not found");

        const addOnIdx = rateCardDto[parentIdx].addOnRates.findIndex(
          (elem) => elem.rateId === rateCard.rateId
        );
        if (addOnIdx < 0) throw new EmpException("Add-on not found");
        rateCardDto[parentIdx].addOnRates.splice(addOnIdx);
        setRateCardDto([...rateCardDto]);
        rateIdToBeDeleted.push(rateCard.rateId);
      }
      // This is not an addon
      else if (rateCard.parentId === undefined) {
        const rateIdx = rateCardDto.findIndex(
          (elem) => elem.rateId === rateCard.rateId
        );
        if (rateIdx < 0) throw new EmpException("Rate not found");
        // Delete children
        rateIdToBeDeleted.push(rateCard.rateId);
        rateCard.addOnRates.forEach((elem) =>
          rateIdToBeDeleted.push(elem.rateId)
        );

        // Remove the dto object itself
        rateCardDto.splice(rateIdx);

        setRateCardDto([...rateCardDto]);
      }

      // If this deleted item is newly added, remove it from the added array
      for (let ratesToBeDeleted of rateIdToBeDeleted) {
        const addedIndex = addedIdRefs.current.findIndex(
          (elem) => elem === ratesToBeDeleted
        );
        if (addedIndex > -1) {
          addedIdRefs.current.splice(addedIndex, 1);
          return;
        }
      }
      deletedIdRefs.current = [...deletedIdRefs.current, ...rateIdToBeDeleted];
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
    }
  };

  const saveRates = async () => {
    try {
      saveRateBtnRef.current?.setButtonState("loading");
      if (!form) throw new EmpException("Form not found");
      if (!creatorIdRef.current)
        throw new EmpException("Unable to get current creator");
      if (!platformRef.current)
        throw new EmpException("Unable to get current platform");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      if (!isValid) return;
      const request: RateCardSaveReqDto = {
        creatorId: creatorIdRef.current,
        deletedId: deletedIdRefs.current,
        rateCards: [],
      };
      rateCardDto!.forEach((elem) => {
        request.rateCards.push({
          rateCardId: elem.rateId,
          rateName: elem.rateName,
          socialMediaRateId: elem.socialMediaRateId,
          isNewRate: Boolean(addedIdRefs.current.includes(elem.rateId)),
          price: Number(form[`rate:${elem.rateId}`].getValue()),
          platform: platformRef.current!,
          description: elem.description,
          origin: elem.origin,
        });
        elem.addOnRates.forEach((addOnElem) => {
          request.rateCards.push({
            rateCardId: addOnElem.rateId,
            rateName: addOnElem.rateName,
            isNewRate: Boolean(addedIdRefs.current.includes(addOnElem.rateId)),
            socialMediaRateId: addOnElem.socialMediaRateId,
            price: Number(form[`rate:${addOnElem.rateId}`].getValue()),
            platform: platformRef.current!,
            description: addOnElem.description,
            parentId: addOnElem.parentId,
            origin: addOnElem.origin,
          });
        });
      });
      const response = await RateCardApi.saveRateCard(request);
      if (response.data.status === "success") {
        ToastUtils.success("Saved", "Rates has been successfully saved");
        dismiss();
        props.onSave();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save rates. Please try again"
      );
    } finally {
      isSavingRef.current = false;
      saveRateBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"lg"}
    >
      {/* This is body */}
      <div className="emp-rate-card-modal">
        <CustomRateCardModal
          onSave={customRateOnSave}
          ref={customRateCardModalRef}
        />
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            {platformRef.current} <FormattedMessage id="rateCardModal_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="rateCardModal_desc" />
          </p>
        </div>
        {form && rateCardDto && (
          <div className="rate-section mt-4">
            {rateCardDto.map((elem, index) => {
              return (
                <div className="rate-item-wrapper" key={elem.rateId}>
                  <div className="rate-item mt-3  content-wrapper">
                    {platformRef.current && (
                      <div className="info-section">
                        <div className="header-wrapper">
                          {elem.origin === "Template" && (
                            <span className="rate-header">
                              {TranslationUtil.translateRateCardName(
                                intl,
                                platformRef.current,
                                elem.rateName
                              )}
                            </span>
                          )}
                          {elem.origin === "Custom" && (
                            <span className="rate-header">{elem.rateName}</span>
                          )}
                          {elem.origin === "Custom" && (
                            <EmpPill
                              backgroundColor={Color.NEUTRAL[150]}
                              color={Color.NEUTRAL[600]}
                              text={"Custom Rate"}
                            />
                          )}
                        </div>
                        {elem.origin === "Template" && (
                          <p className="mt-1 rate-description">
                            {TranslationUtil.translateRateCardDescription(
                              intl,
                              platformRef.current,
                              elem.rateName
                            )}
                          </p>
                        )}
                        {elem.origin === "Custom" && (
                          <p className="mt-1 rate-description">
                            {elem.description}
                          </p>
                        )}
                      </div>
                    )}
                    <div className="input-section">
                      <div className="input-wrapper">
                        <EmpTextInput
                          leftIconComponent={UsdIcon}
                          leftIconSize={11}
                          labelText={intl.formatMessage({
                            id: "rateCardModal_startsFromInputLabel",
                          })}
                          compressedLabel
                          id={`rate:${elem.rateId}`}
                          placeholder={"00.00"}
                          formControl={form[`rate:${elem.rateId}`]}
                        />
                      </div>
                      <div className="button-icon-wrapper">
                        <EmpIconButton
                          buttonStyle="secondary"
                          icon={
                            <PlusIcon backgroundColor={Color.NEUTRAL[500]} />
                          }
                          onSubmit={() =>
                            customRateCardModalRef.current?.show(
                              "add-on",
                              elem.rateId
                            )
                          }
                        />
                      </div>
                      {elem.origin === "Custom" && (
                        <div className="button-icon-wrapper">
                          <EmpIconButton
                            buttonStyle="secondary"
                            icon={
                              <TrashIcon backgroundColor={Color.NEUTRAL[500]} />
                            }
                            onSubmit={() => {
                              deleteRateOnClick(elem);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {elem.addOnRates.map((addOnElem, index) => {
                    return (
                      <div key={addOnElem.rateId} className="rate-item mt-2">
                        <div className="collapsible-section">
                          <div className="line-wrapper">
                            <div className="line"></div>
                          </div>
                        </div>
                        <div className="content-wrapper add-on">
                          {platformRef.current && (
                            <div className="info-section">
                              <div className="header-wrapper">
                                {addOnElem.origin === "Template" && (
                                  <span className="rate-header">
                                    {TranslationUtil.translateRateCardName(
                                      intl,
                                      platformRef.current,
                                      addOnElem.rateName
                                    )}{" "}
                                    (add-on)
                                  </span>
                                )}
                                {addOnElem.origin === "Custom" && (
                                  <span className="rate-header">
                                    {addOnElem.rateName} (add-on)
                                  </span>
                                )}
                                {addOnElem.origin === "Custom" && (
                                  <EmpPill
                                    backgroundColor={Color.NEUTRAL[150]}
                                    color={Color.NEUTRAL[600]}
                                    text={"Custom Rate"}
                                  />
                                )}
                              </div>
                              {addOnElem.origin === "Template" && (
                                <p className="mt-1 rate-description">
                                  {TranslationUtil.translateRateCardDescription(
                                    intl,
                                    platformRef.current,
                                    addOnElem.rateName
                                  )}
                                </p>
                              )}
                              {addOnElem.origin === "Custom" && (
                                <p className="mt-1 rate-description">
                                  {addOnElem.description}
                                </p>
                              )}
                            </div>
                          )}
                          <div className="input-section">
                            <div className="input-wrapper">
                              <EmpTextInput
                                leftIconComponent={UsdIcon}
                                leftIconSize={11}
                                labelText="Starts From"
                                compressedLabel
                                id={`rate:${addOnElem.rateId}`}
                                placeholder={"00.00"}
                                formControl={form[`rate:${addOnElem.rateId}`]}
                              />
                            </div>
                            {addOnElem.origin === "Custom" && (
                              <div className="button-icon-wrapper">
                                <EmpIconButton
                                  onSubmit={() => {
                                    deleteRateOnClick(addOnElem);
                                  }}
                                  buttonStyle="secondary"
                                  icon={
                                    <TrashIcon
                                      backgroundColor={Color.NEUTRAL[500]}
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
        <div className="custom-rate-section">
          <span className="header">
            <FormattedMessage id="rateCardModal_customRateSectionHeader" /> 💰
          </span>
          <div className="custom-rate-button-wrapper">
            <EmpButton
              onSubmit={() => {
                customRateCardModalRef.current?.show("rate");
              }}
              className="mt-3"
              buttonHeight={"sm"}
              buttonStyle={"secondary"}
              text={<FormattedMessage id="rateCardModal_customRateBtn" />}
            />
          </div>
        </div>

        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={<FormattedMessage id="cta_cancel" />}
            onSubmit={() => {
              dismiss();
            }}
          />

          <EmpButton
            ref={saveRateBtnRef}
            isFullWidth={false}
            text={<FormattedMessage id="cta_save" />}
            onSubmit={() => {
              if (isSavingRef.current) return;
              isSavingRef.current = true;
              saveRates();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default RateCardModal;
