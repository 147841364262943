import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import DraftApi from "../../../api/campaign-msvc/draft.api";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import {
  DELIVERABLE_STATUS,
  FILE_TYPE,
} from "../../../constants/app.constants";
import { AppContext } from "../../../context/app.context";
import { ExtendedDraftDto } from "../../../model/campaign/extended-draft.dto";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FileUtils from "../../../utilities/file-util";
import UserUtils from "../../../utilities/user-utils";
import FileIcon from "../../icon/file-icon";
import InfoCircleIcon from "../../icon/info-circle-icon";
import RefreshIcon from "../../icon/refresh-icon";
import ViewDraftReviewModal, {
  ViewDraftReviewModalRef,
} from "../../modals/view-draft-review-modal";
import EmpButton from "../../shared/emp-button/emp-button";
import EmpLoader, { EmpLoaderRef } from "../../shared/emp-loader/emp-loader";
import EmpPill from "../../shared/EmpPill/EmpPill";
import "../emp-kanban.scss";
import "./ongoing-kanban.scss";
import { DraftDto } from "../../../model/campaign/draft.dto";

interface Props {
  task: TaskDto;
  deliverableId: string;
  toDraft: () => void;
}

type ClassifiedDeliverables = {
  pendingDraft: OngoingDeliverableExtendedDto[];
  inReview: OngoingDeliverableExtendedDto[];
  approved: OngoingDeliverableExtendedDto[];
  completed: OngoingDeliverableExtendedDto[];
  dispute: OngoingDeliverableExtendedDto[];
};

export interface SellerOngoingKanbanRef {
  fetchTaskNegotiations: () => void;
}
export const SellerOngoingKanban = forwardRef((props: Props, ref) => {
  const { task, deliverableId, toDraft } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [ongoingDeliverables, setOngoingDeliverables] = useState<
    OngoingDeliverableExtendedDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user: userContext } = useContext(AppContext);
  const userRoleRef = useRef<string>();
  const userRef = useRef<UserDto>();
  const [ongoingDeliverableDraftMap, setOngoingDeliverableDraftMap] =
    useState<Map<string, DraftDto>>();
  const viewDraftReviewRef = useRef<ViewDraftReviewModalRef>();

  useEffect(() => {
    if (loading === true) empLoaderRef.current?.show();
    if (loading === false) empLoaderRef.current?.hide();
  }, [loading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  const [classifiedNegotiations, setClassifiedNegotiations] =
    useState<ClassifiedDeliverables>({
      pendingDraft: [],
      inReview: [],
      approved: [],
      dispute: [],
      completed: [],
    });

  useImperativeHandle(ref, () => {
    return {
      fetchOngoingDeliverables,
    };
  });

  const fetchOngoingDeliverables = useCallback(
    async (deliverableId: string) => {
      try {
        if (!userRef.current) {
          const user = await UserUtils.fetchUser(userContext);
          userRef.current = user;
          userRoleRef.current = user.role === "talent" ? "creator" : "agency";
        }

        setLoading(true);
        const resp = await OngoingTaskApi.fetchSellerDeliverables(
          task.id,
          deliverableId
        );
        const evidenceResps = await DraftApi.fetchDraftAsSeller(task.id);

        const draftResps = await DraftApi.fetchDraftAsSeller(task.id);
        const draftMap = new Map<string, DraftDto>();
        draftResps.data.forEach((draft) => {
          draftMap.set(draft.creatorUserId, draft);
        });
        setOngoingDeliverableDraftMap(draftMap);
        setOngoingDeliverables(resp.data);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch seller latest negotiation"
        );
      } finally {
        setLoading(false);
      }
    },
    [task, location, userContext]
  );

  useEffect(() => {
    // Splitting the cards.
    const myClassifiedNegotiations: ClassifiedDeliverables = {
      pendingDraft: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.PENDING_DRAFT
      ),
      inReview: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.IN_REVIEW
      ),
      approved: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.APPROVED
      ),
      dispute: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.DISPUTE
      ),
      completed: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.COMPLETED
      ),
    };
    setClassifiedNegotiations(myClassifiedNegotiations);
  }, [ongoingDeliverables]);

  useEffect(() => {
    fetchOngoingDeliverables(deliverableId);
  }, [fetchOngoingDeliverables, deliverableId]);

  const viewDraftOnClick = (
    ongoingDeliverable: OngoingDeliverableExtendedDto
  ) => {
    if (!ongoingDeliverableDraftMap?.has(ongoingDeliverable.creatorUserId))
      return;

    // Construct the Draft Extended Dto
    const draftExtended: ExtendedDraftDto = {
      ...ongoingDeliverable,
      draft: ongoingDeliverableDraftMap.get(ongoingDeliverable.creatorUserId)!,
    };
    viewDraftReviewRef.current?.show(draftExtended, task, "edit");
  };

  const renderDraftFile = (creatorUserId: string) => {
    if (!ongoingDeliverableDraftMap?.has(creatorUserId)) return;
    const draft = ongoingDeliverableDraftMap.get(creatorUserId)!;

    const viewableDraft = draft.draftAttachments[0];
    if (!viewableDraft) return <></>;

    if (viewableDraft.fileType === FILE_TYPE.IMAGE) {
      return (
        <div className="draft-content-wrapper">
          <img
            className="draft-img"
            alt={draft.remarks}
            src={viewableDraft.attachmentUrl}
          />
        </div>
      );
    } else if (viewableDraft.fileType === FILE_TYPE.FILE) {
      return (
        <div className="draft-content-wrapper">
          <div className="draft-file-wrapper">
            <FileIcon size={24} backgroundColor={Color.NEUTRAL[300]} />
            <span className="title">{viewableDraft.attachmentName}</span>
            <span className="specs">
              {FileUtils.convertBytesToReadableSize(
                viewableDraft.attachmentSize
              )}
            </span>
          </div>
        </div>
      );
    } else if (viewableDraft.fileType === FILE_TYPE.VIDEO) {
      return (
        <div className="draft-content-wrapper">
          <video
            className="draft-video"
            muted
            loop
            controls={false}
            src={viewableDraft.attachmentUrl}
          />
        </div>
      );
    }
  };

  return (
    <div className="emp-kanban emp-ongoing-kanban">
      <ViewDraftReviewModal
        ref={viewDraftReviewRef}
        onSave={() => {
          fetchOngoingDeliverables(deliverableId);
        }}
      />
      <EmpLoader ref={empLoaderRef} background="transparent" />
      {!loading && userRef.current && (
        <div className="kanban-body-container">
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Pending Draft
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This column lists creators who are yet to submit their
                      drafts for the Brand's approval.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
              <div className="plus-icon-hidden-placeholder"></div>
            </div>
            {classifiedNegotiations.pendingDraft.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  className="done-img"
                  alt="Done"
                  src="https://emplifive-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/done.png"
                />
                <span className="empty-state-title">
                  Well Done! No pending drafts
                </span>
                <p className="empty-state-description">
                  Looks like all drafts has been submitted or approved.
                </p>
              </div>
            )}
            {classifiedNegotiations.pendingDraft.length > 0 &&
              classifiedNegotiations.pendingDraft.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      This creator has yet to submit a Draft.
                    </p>
                    <div
                      className="mt-1"
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                      <div className="revision-pill">
                        <RefreshIcon
                          backgroundColor={Color.NEUTRAL[500]}
                          strokeWidth={3}
                          size={10}
                        />
                        <span>
                          {elem.revisionsLeft} Revision
                          {elem.revisionsLeft > 1 ? "s" : ""} Left
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                In Review
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This column displays the creators whose drafts are
                      currently under review by the Brand.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            {classifiedNegotiations.inReview.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Drafts for Review</span>
                <p className="empty-state-description">
                  There are no drafts that require your approval.
                </p>
              </div>
            )}
            {classifiedNegotiations.inReview.length > 0 &&
              classifiedNegotiations.inReview.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {ongoingDeliverableDraftMap &&
                      ongoingDeliverableDraftMap.has(elem.creatorUserId) &&
                      renderDraftFile(elem.creatorUserId)}
                    <p className="kanban-description mt-2">
                      Under Review Description
                    </p>
                    <div className="mt-2 actions-wrapper">
                      <div>
                        <EmpPill
                          backgroundColor={Color.NEUTRAL[150]}
                          color={Color.NEUTRAL[600]}
                          size={"sm"}
                          text={DateUtil.toReadableDate(elem.createdDate)}
                        />
                        <div className="revision-pill mt-1">
                          <RefreshIcon
                            backgroundColor={Color.NEUTRAL[500]}
                            strokeWidth={3}
                            size={10}
                          />
                          <span>
                            {elem.revisionsLeft} Revision
                            {elem.revisionsLeft > 1 ? "s" : ""} Left
                          </span>
                        </div>
                      </div>
                      <EmpButton
                        onSubmit={() => {
                          viewDraftOnClick(elem);
                        }}
                        isFullWidth={false}
                        text={"View"}
                        buttonHeight="sm"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                In Dispute
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      A dispute has been raised! A Customer Success
                      representative from Emplifive will be reaching out to the
                      relevant parties to address and resolve this issue.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.dispute.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Disputes</span>
                <p className="empty-state-description">
                  Neither party has submitted a dispute{" "}
                </p>
              </div>
            )}
            {classifiedNegotiations.dispute.length > 0 &&
              classifiedNegotiations.dispute.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}
                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      There is a dispute regarding this creator
                    </p>

                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Approved
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {" "}
                      After the Brands approve your draft, the card will
                      transition to this state. Following this, you must the
                      proof of work to further move the card to the completed
                      state.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.approved.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Approved Drafts</span>
                <p className="empty-state-description">
                  Approval of your drafts by the Brands is necessary before the
                  card can move to this state.
                </p>
              </div>
            )}
            {classifiedNegotiations.approved.length > 0 &&
              classifiedNegotiations.approved.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      This creator has yet to submit a post.
                    </p>

                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Completed
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      The card will only progress to this column once proof of
                      work has been submitted following the brand's approval. A
                      creator becomes eligible for payouts when all their
                      deliverables have reached this state.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.completed.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Completed Work</span>
                <p className="empty-state-description">
                  The card can only progress to this state after you submit the
                  proof of work, following the brand's approval.
                </p>
              </div>
            )}
            {classifiedNegotiations.completed.length > 0 &&
              classifiedNegotiations.completed.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      The creator has completed the task.
                    </p>
                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
});
