import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { TaskPaymentDetailsDto } from "../../model/campaign/task-payment-details.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import FormFieldUtils from "../../utilities/form-field.util";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../shared/emp-checkbox/emp-checkbox";
import EmpLink from "../shared/emp-link/emp-link";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./delete-task-modal.scss";
import { LatestNegotiationSingleDto } from "../../model/campaign/latest-negotiation-single.dto";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PLATFORM_ROLES } from "../../constants/app.constants";
import InfoCircleIcon from "../icon/info-circle-icon";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import EmpException from "../../exception/empException";
import ToastUtils from "../../utilities/toast-utils";
import { useNavigate } from "react-router-dom";

export interface DeleteTaskModalRef {
  show: (task: TaskDto, latstNegotiation: LatestNegotiationSingleDto) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

interface Representative {
  id: string;
  creatorRepresentative: UserDto;
  agencyRepresentative: OrganisationRespDto;
  representativeId: string;
  representativeRole: string;
  status: string;
}

const DeleteTaskModal = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [task, setTask] = useState<TaskDto>();

  const submitBtnRef = useRef<EmpButtonRef>();
  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);
  const [hasNegotiation, setHasNegotiation] = useState<boolean>(false);
  const [representatives, setRepresentatives] = useState<Representative[]>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (
    task: TaskDto,
    latestNegotiation: LatestNegotiationSingleDto
  ) => {
    setTask(task);
    setVisible(true);
    if (!latestNegotiation.hasNegotiation) {
      setHasNegotiation(false);
      setRepresentatives([]);
      return;
    }
    setHasNegotiation(true);
    const uniqueRepresentativeIdSet = new Set<string>();
    const negotiations = latestNegotiation.latestNegotiation;

    const representativeList: Representative[] = [];
    for (const negotiation of negotiations) {
      if (uniqueRepresentativeIdSet.has(negotiation.representativeId)) continue;
      const {
        id,
        creatorRepresentative,
        agencyRepresentative,
        representativeId,
        representativeRole,
        status,
      } = negotiation;
      representativeList.push({
        id,
        creatorRepresentative,
        agencyRepresentative,
        representativeId,
        representativeRole,
        status,
      });
      uniqueRepresentativeIdSet.add(negotiation.representativeId);
    }
    setRepresentatives(representativeList);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setTask(undefined);
    setAckIsChecked(false);
    ackCheckboxRef.current?.setChecked(false);
  };

  const deleteTask = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      if (!task || !representatives)
        throw new EmpException("Task or representatives not found");
      const resp = await CampaignApi.deleteTask(task.campaign.id, task.id);
      if (resp.data.status === "success") {
        let toastMessage = "";
        if (representatives.length > 0) {
          toastMessage = `${task.name} successfully deleted. All participants have been informed of this outcome.`;
        } else {
          toastMessage = `${task.name} has been successfully deleted.`;
        }
        ToastUtils.success("Task has been deleted.", toastMessage);
        navigate("/brand/campaigns?pageNo=1&pageSize=20#My%20Tasks");
      } else {
        ToastUtils.error("Unexpected Error Occurred", "Unable to delete task");
      }
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error occurred when deleting tasks"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"md"}
    >
      {/* This is body */}
      <div>
        <div className="emp-delete-task-modal">
          <div className="modal-header-wrapper">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            {task && (
              <>
                <span className="intro-subtitle">DELETING TASK</span>
                <h1 className="title">{task.name}</h1>
                {hasNegotiation && (
                  <p className="emp-paragraph mt-3">
                    All listed participants below will be notified via email and
                    in-app notification that this task has been cancelled.
                  </p>
                )}
                {!hasNegotiation && (
                  <p className="emp-paragraph mt-3">
                    Are you sure you want to proceed with the deletion of task{" "}
                    {task.name}.
                  </p>
                )}
              </>
            )}
          </div>
          {/* START: Creator nvoice Item Listing */}
          {representatives && representatives.length > 0 && (
            <section className="participants-wrapper mt-3">
              <div className="header-wrapper">
                <div className="participants-header">
                  <span className="header-text">Participant</span>
                </div>
              </div>
              <div className="body-wrapper">
                {representatives.map((elem) => {
                  return (
                    <div className="row-wrapper">
                      <div className="participants-wrapper">
                        <div className="profile-wrapper">
                          {elem.representativeRole ===
                            PLATFORM_ROLES.CREATOR && (
                            <>
                              {elem.creatorRepresentative.imageType ===
                                "url" && (
                                <img
                                  className="profile"
                                  alt={elem.creatorRepresentative.fullName}
                                  src={elem.creatorRepresentative.imageResource}
                                />
                              )}
                              {elem.creatorRepresentative.imageType ===
                                "avatar" && (
                                <div
                                  className="profile"
                                  style={{
                                    background:
                                      elem.creatorRepresentative.imageResource,
                                  }}
                                >
                                  <span className="initials">
                                    {elem.creatorRepresentative.initials}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                          {elem.representativeRole ===
                            PLATFORM_ROLES.AGENCY && (
                            <>
                              <img
                                className="profile org"
                                alt={elem.agencyRepresentative.companyName}
                                src={elem.agencyRepresentative.logo}
                              />
                            </>
                          )}
                        </div>
                        <div className="info-wrapper">
                          <span className="creator-name">
                            {elem.creatorRepresentative.fullName}
                          </span>
                          <span className="representative-lbl mt-1">
                            {elem.representativeRole === "agency"
                              ? `Managed by ${elem.agencyRepresentative.companyName}`
                              : "Freelance Creator"}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          <section className="acknowledgement-wrapper mt-4">
            <div className="card">
              <div className="intro-wrapper">
                <span>CONFIRMATION OF DELETION</span>
              </div>
              <div className="control-wrapper mt-2">
                <div className="checkbox-wrapper">
                  <EmpCheckbox
                    onChange={() => {
                      ackCheckboxRef.current?.setChecked(!ackIsChecked);
                      setAckIsChecked(!ackIsChecked);
                    }}
                    ref={ackCheckboxRef}
                    id={"acknowledgement"}
                  />
                </div>
                <div className="checkbox-text-wrapper">
                  <div
                    onClick={() => {
                      ackCheckboxRef.current?.setChecked(!ackIsChecked);
                      setAckIsChecked(!ackIsChecked);
                    }}
                  >
                    <span className={ackIsChecked ? "checked" : ""}>
                      I acknowledge that once a task is deleted, the action
                      cannot be undone.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* END: Creator Invoice Item Listing */}
          <section className="button-wrapper mt-4">
            <EmpButton
              disabled={!ackIsChecked}
              isFullWidth={false}
              buttonStyle="danger"
              ref={submitBtnRef}
              onSubmit={deleteTask}
              text={"Delete Task"}
            />
          </section>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default DeleteTaskModal;
