import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { AccessControlSellerDto } from "../../model/payment/payment/access-control-seller.dto";
import StringUtils from "../../utilities/string.util";
import { EmpInterceptor } from "../emp-interceptor";

const AccessControlApi = {
  verifyAccessForSellers: async (
    query: AccessControlSellerDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const { representativeId, resourceType, campaignId, taskId } = query;
    const url = StringUtils.generateGetURL({
      href: `${ENDPOINTS.campaignMsvc.accessControl.verifyAccessControlForSeller}`,
      params: {
        representativeId,
        resourceType,
        campaignId,
        taskId,
      },
    });
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(url);
    return response.data;
  },
  verifyAccessForBrands: async (
    brandOrgId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.accessControl.verifyAccessControlForBrand
    );
    return response.data;
  },
};
export default AccessControlApi;
