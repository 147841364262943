import { useCallback, useEffect, useState } from "react";

import EmpSidebar from "../../../../components/shared/emp-sidebar/emp-sidebar";
import { SmConnectionBriefRespDto } from "../../../../model/smm/smm-connection-brief-resp.dto";
import { OverviewTab } from "./smm-calendar-overview-tab";
import { PostHistoryTab } from "./smm-post-history-tab";
import { ScheduledPostTab } from "./smm-scheduled-post-tab";
import { DraftsTab } from "./smm-drafts-tab";
import { useLocation, useSearchParams } from "react-router-dom";

type BrandSocialMediaManagerCalendarTabProps = {
  connects: SmConnectionBriefRespDto[];
  fetchSocialMediaConnections: () => Promise<void>;
  loading: boolean;
};

const BrandSocialMediaManagerCalendarTab: React.FC<
  BrandSocialMediaManagerCalendarTabProps
> = ({ connects, fetchSocialMediaConnections, loading }) => {
  const [activeSidebarItem, setActiveSidebarItem] = useState("Overview");
  const [postIds, setPostIds] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const menuItems = [
    {
      name: "Overview",
      onClick: () => {
        setSearchParams();
        setActiveSidebarItem("Overview");
        setPostIds([]);
      },
    },
    {
      name: "Scheduled Posts",
      onClick: () => {
        setSearchParams();
        setActiveSidebarItem("Scheduled Posts");
        setPostIds([]);
      },
    },
    {
      name: "Post History",
      onClick: () => {
        setSearchParams();
        setActiveSidebarItem("Post History");
        setPostIds([]);
      },
    },
    {
      name: "Drafts",
      onClick: () => {
        setSearchParams();
        setActiveSidebarItem("Drafts");
        setPostIds([]);
      },
    },
    // ...other items
  ];

  const renderTabContent = useCallback(() => {
    switch (activeSidebarItem) {
      case "Scheduled Posts":
        return <ScheduledPostTab postIds={postIds} setPostIds={setPostIds} />;
      case "Post History":
        return <PostHistoryTab postIds={postIds} setPostIds={setPostIds} />;
      case "Drafts":
        return <DraftsTab postIds={postIds} setPostIds={setPostIds} />;
      default:
        return (
          <OverviewTab
            connects={connects}
            postIds={postIds}
            setPostIds={setPostIds}
          />
        );
    }
  }, [activeSidebarItem, postIds, connects]);

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    if (!location.hash) return;
    const updateTabs = () => {
      const fragment = decodeURIComponent(window.location.hash.slice(1));
      const fragmentParts = fragment.split("?");
      const targetPart = fragmentParts[0];

      // Extracting query parameters
      const queryParams = new URLSearchParams(fragmentParts[1]);
      const postIds = queryParams.get("postIds");

      const fragmentToSidebarItemMap: { [key: string]: string } = {
        "scheduled-posts": "Scheduled Posts",
        "post-history": "Post History",
        drafts: "Drafts",
      };

      // Default to "Overview" if the fragment isn't found in the map
      const sidebarItem = fragmentToSidebarItemMap[targetPart] || "Overview";
      const postId = postIds ? postIds.split(",") : [];

      setActiveSidebarItem(sidebarItem);
      if (postId.length > 0) {
        setPostIds(postId);
      } else {
        setPostIds([]);
      }
    };

    updateTabs();
  }, [location.hash]);

  return (
    <>
      {/* <EmpLoader ref={empLoaderRef} background="transparent" /> */}
      <div className="brand-social-media-manager-calendar-tab mt-6">
        <div className="calendar-sidebar-wrapper">
          <EmpSidebar
            title="Calendar"
            menuItems={menuItems}
            activeItem={activeSidebarItem}
            isSticky
          />
        </div>
        {renderTabContent()}
      </div>
    </>
  );
};

export default BrandSocialMediaManagerCalendarTab;
