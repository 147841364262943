import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import { TASK_TYPE } from "../../../constants/app.constants";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import { CreatorRecruitmentCardDto } from "../../../model/user/creator-recruitment-card.dto";
import { Color } from "../../../utilities/colors";
import StringUtils from "../../../utilities/string.util";
import FacebookIcon from "../../icon/facebook-icon";
import InstagramIcon from "../../icon/instagram-icon";
import TargetIcon from "../../icon/target-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import EmpContent from "../emp-content/emp-content";
import EmpLink from "../emp-link/emp-link";
import EmpRoundedPill from "../emp-rounded-pill/emp-rounded-pill";
import "./emp-sm-recruitment-card.scss";
import FormFieldUtils from "../../../utilities/form-field.util";
import XIcon from "../../icon/x-icon";
import useElementWidth from "../../../hooks/useElementWidth";

interface Props {
  // cardWidth?: number;
  mode?: "default" | "skeleton" | "loading";
  card?: CreatorRecruitmentCardDto;
  onClick?: (context: "card" | "invite-btn") => void;
}

export const EmpSmRecruitmentCard = (props: Props) => {
  const mode = props.mode ?? "default";
  const card = useRef<CreatorRecruitmentCardDto | undefined>(
    props.card ?? undefined
  );
  // const width = props.cardWidth ?? 300;
  const interestPillsRef = useRef<HTMLDivElement[]>([]);
  const servicePillsRef = useRef<HTMLDivElement[]>([]);
  const pillSectionRef = useRef<HTMLDivElement>(null);
  const [isReady, setReady] = useState(false);

  const [width, cardRef] = useElementWidth();

  useEffect(() => {
    setReady(true);
  }, []);

  const calculateTotalFollowers = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return FormFieldUtils.toCompact(record.facebookMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.INSTAGRAM)
        return FormFieldUtils.toCompact(
          record.instagramMetrics?.followers ?? 0
        );
      else if (record.platform === TASK_TYPE.TIKTOK)
        return FormFieldUtils.toCompact(record.tiktokMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.X)
        return FormFieldUtils.toCompact(record.xMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.GENERAL)
        return FormFieldUtils.toCompact(
          (record.facebookMetrics?.followers ?? 0) +
            (record.instagramMetrics?.followers ?? 0) +
            (record.tiktokMetrics?.followers ?? 0)
        );
    },
    []
  );

  const calculateAvgImpressions = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return FormFieldUtils.toCompact(
          record.facebookMetrics?.avgImpressions ?? 0
        );
      else if (record.platform === TASK_TYPE.INSTAGRAM)
        return FormFieldUtils.toCompact(
          record.instagramMetrics?.avgImpressions ?? 0
        );
      else if (record.platform === TASK_TYPE.TIKTOK)
        return FormFieldUtils.toCompact(
          record.tiktokMetrics?.avgImpressions ?? 0
        );
      else if (record.platform === TASK_TYPE.X)
        return FormFieldUtils.toCompact(record.xMetrics?.avgImpressions ?? 0);
      else if (
        record.platform === TASK_TYPE.GENERAL &&
        record.facebookMetrics?.avgImpressions === undefined &&
        record.instagramMetrics?.avgImpressions === undefined &&
        record.tiktokMetrics?.avgImpressions === undefined
      )
        return "Unknown";
      else if (record.platform === TASK_TYPE.GENERAL)
        return FormFieldUtils.toCompact(
          (record.facebookMetrics?.avgImpressions ?? 0) +
            (record.instagramMetrics?.avgImpressions ?? 0) +
            (record.tiktokMetrics?.avgImpressions ?? 0) +
            (record.xMetrics?.avgImpressions ?? 0)
        );
    },
    []
  );

  const calculateAverageEngagementAcrossMultiplePlatform = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      const metricKeys = ["facebookMetrics", "tiktokMetrics", "xMetrics"];
      const { total, count } = metricKeys.reduce(
        (acc, key) => {
          const metricKey = key as
            | "facebookMetrics"
            | "tiktokMetrics"
            | "xMetrics";
          const engagement = record[metricKey]?.avgEngagements;
          if (engagement !== undefined) {
            acc.total += engagement;
            acc.count += 1;
          }
          return acc;
        },
        { total: 0, count: 0 }
      );
      // Return the average or 0 if no valid metrics were found
      return count === 0 ? 0 : total / count;
    },
    []
  );

  const calculateAverageEngagement = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return record.facebookMetrics?.avgEngagements
          ? `${record.facebookMetrics?.avgEngagements.toFixed(2)}%`
          : "0%";
      else if (record.platform === TASK_TYPE.TIKTOK)
        return record.tiktokMetrics?.avgEngagements
          ? `${record.tiktokMetrics?.avgEngagements.toFixed(2)}%`
          : "0%";
      else if (record.platform === TASK_TYPE.X)
        return record.xMetrics?.avgEngagements
          ? `${record.xMetrics?.avgEngagements.toFixed(2)}%`
          : "0%";
      else if (
        record.platform === TASK_TYPE.GENERAL &&
        record.facebookMetrics?.avgEngagements === undefined &&
        record.tiktokMetrics?.avgEngagements === undefined &&
        record.xMetrics?.avgEngagements === undefined
      )
        return "Unknown";
      else if (record.platform === TASK_TYPE.GENERAL)
        return `${calculateAverageEngagementAcrossMultiplePlatform(
          record
        ).toFixed(2)}%`;
    },
    [calculateAverageEngagementAcrossMultiplePlatform]
  );

  const interestPillRenderable = useMemo(() => {
    const GAP = 4;
    const PILL_WIDTH = 38;
    if (!card.current || !isReady || !pillSectionRef.current) return <></>;

    if (interestPillsRef.current.length === 0) {
      return <>No Interests Set</>;
    }

    const containerWidth = width - 26;
    let deductableWidth = containerWidth;
    let numRenderedPills = 0;

    let index = 0;
    for (let interestPill of interestPillsRef.current) {
      let deductableWidthAfterPill = deductableWidth;
      if (index < servicePillsRef.current.length - 1) {
        deductableWidthAfterPill -= PILL_WIDTH;
      }
      if (deductableWidthAfterPill > interestPill.clientWidth) {
        numRenderedPills += 1;
        deductableWidth -= interestPill.clientWidth;
        deductableWidth -= GAP;
      } else break;
    }
    const pillsRemaining = interestPillsRef.current.length - numRenderedPills;
    const renderedInterest = card.current.interests.slice(0, numRenderedPills);
    const tooltipContent = card.current.interests
      .slice(numRenderedPills, card.current.interests.length)
      .map((elem) => elem.label)
      .join(", ");

    return (
      <div className="pill-wrapper">
        {renderedInterest.map((tag) => {
          return (
            <EmpRoundedPill clickable={false} key={tag.id} text={tag.label} />
          );
        })}
        {pillsRemaining > 0 && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{tooltipContent}</Tooltip>}
          >
            <div>
              <EmpRoundedPill clickable={false} text={`+${pillsRemaining}`} />
            </div>
          </Whisper>
        )}
      </div>
    );
  }, [width, card, isReady]);

  const servicePillRenderable = useMemo(() => {
    const GAP = 4;
    const PILL_WIDTH = 38;
    if (!card.current || !isReady || !pillSectionRef.current) return <></>;

    if (servicePillsRef.current.length === 0) {
      return <>No Services Set</>;
    }
    const containerWidth = width - 26;
    let deductableWidth = containerWidth;
    let numRenderedPills = 0;

    let index = 0;
    for (let servicePill of servicePillsRef.current) {
      let deductableWidthAfterPill = deductableWidth;
      if (index < servicePillsRef.current.length - 1) {
        deductableWidthAfterPill -= PILL_WIDTH;
      }
      if (deductableWidthAfterPill > servicePill.clientWidth) {
        numRenderedPills += 1;
        deductableWidth -= servicePill.clientWidth;
        deductableWidth -= GAP;
      } else break;
    }
    const pillsRemaining = servicePillsRef.current.length - numRenderedPills;
    const renderedService = card.current.services.slice(0, numRenderedPills);
    const tooltipContent = card.current.services
      .slice(numRenderedPills, card.current.services.length)
      .map((elem) => elem.label)
      .join(", ");

    return (
      <div className="pill-wrapper">
        {renderedService.map((tag) => {
          return (
            <EmpRoundedPill clickable={false} key={tag.id} text={tag.label} />
          );
        })}
        {pillsRemaining > 0 && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{tooltipContent}</Tooltip>}
          >
            <div>
              <EmpRoundedPill clickable={false} text={`+${pillsRemaining}`} />
            </div>
          </Whisper>
        )}
      </div>
    );
  }, [width, card, isReady]);

  /**
   * Generates a representative text based on the creator representatives.
   * The representative text indicates the creator's agency affiliation.
   *
   * @returns {JSX.Element} - Formatted representative text
   */
  const representativesRenderable: JSX.Element = useMemo(() => {
    if (!card.current) return <></>;
    const representatives = card.current.representatives;
    if (representatives.length === 0) return <>Freelancer</>;

    return (
      <div>
        {representatives.length === 1 && (
          <EmpLink text={representatives[0].companyName} />
        )}
        {representatives.length > 1 && (
          <>{`${representatives.length} Agencies`}</>
        )}
      </div>
    );
  }, [card]);

  return (
    <div className="emp-sm-recruitment-card" ref={cardRef}>
      {mode === "default" && card.current && (
        <div
          className="recruitment-card"
          onClick={() => {
            if (props.onClick) props.onClick("card");
          }}
        >
          <div className="bio-section">
            <div className="image-wrapper">
              <div className="sm-icon">
                {card.current.platform === TASK_TYPE.FACEBOOK && (
                  <FacebookIcon size={9} backgroundColor={Color.NEUTRAL[0]} />
                )}
                {card.current.platform === TASK_TYPE.INSTAGRAM && (
                  <InstagramIcon size={9} backgroundColor={Color.NEUTRAL[0]} />
                )}
                {card.current.platform === TASK_TYPE.TIKTOK && (
                  <TikTokIcon size={9} backgroundColor={Color.NEUTRAL[0]} />
                )}
                {card.current.platform === TASK_TYPE.X && (
                  <XIcon size={9} backgroundColor={Color.NEUTRAL[0]} />
                )}
                {card.current.platform === TASK_TYPE.GENERAL && (
                  <TargetIcon size={9} backgroundColor={Color.NEUTRAL[0]} />
                )}
              </div>
              {card.current.imageType === "url" && (
                <img
                  className="profile-img"
                  alt="creator"
                  src={card.current.imageResource}
                />
              )}
              {card.current.imageType === "avatar" && (
                <div
                  className="profile-avatar"
                  style={{ background: card.current.imageResource }}
                >
                  <span>{card.current.initials}</span>
                </div>
              )}
            </div>
            <div className="account-details-wrapper">
              <div className="name">{card.current.displayedName}</div>
              <div className="email">
                {calculateTotalFollowers(card.current)}
                &nbsp;Followers
              </div>
            </div>
          </div>
          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                label={"Avg Impressions"}
                value={<>{calculateAvgImpressions(card.current)}</>}
              />
            </div>
            <div className="metric-right">
              {[
                TASK_TYPE.FACEBOOK,
                TASK_TYPE.TIKTOK,
                TASK_TYPE.X,
                TASK_TYPE.GENERAL,
              ].includes(card.current.platform) && (
                <EmpContent
                  label={"Avg Engagements"}
                  value={<>{calculateAverageEngagement(card.current)}</>}
                />
              )}
              {card.current.platform === TASK_TYPE.INSTAGRAM && (
                <EmpContent
                  label={"Avg Reach"}
                  value={`${
                    card.current.instagramMetrics?.avgReach
                      ? FormFieldUtils.toCompact(
                          card.current.instagramMetrics.avgReach
                        )
                      : "Unknown"
                  }`}
                />
              )}
            </div>
          </div>
          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                label={"Country"}
                value={
                  <>
                    {card.current.country && (
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[card.current.country]["flag_1x1"]
                          }
                        />
                        <span>
                          {COUNTRY_CONSTANTS[card.current.country].name}
                        </span>
                      </div>
                    )}
                    {!card.current.country && <span>Unknown</span>}
                  </>
                }
              />
            </div>
            <div className="metric-right">
              <EmpContent
                label={"Representative"}
                value={representativesRenderable}
              />
            </div>
          </div>

          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                label={"Gender"}
                value={StringUtils.capitalizeWords(
                  card.current.gender ?? "Unknown"
                )}
              />
            </div>
            <div className="metric-right">
              <EmpContent
                label={"Age"}
                value={(card.current.age ?? "Unknown").toString()}
              />
            </div>
          </div>

          <div ref={pillSectionRef} className="pill-section mt-3">
            <div className="hidden-section">
              <div className="pill-wrapper">
                {" "}
                {card.current.interests.map((tag, index) => {
                  return (
                    <div
                      key={tag.id}
                      ref={(el: HTMLDivElement) => {
                        interestPillsRef.current[index] = el;
                      }}
                    >
                      <EmpRoundedPill text={tag.label} />
                    </div>
                  );
                })}{" "}
              </div>
            </div>
            <EmpContent label={"Interest"} value={interestPillRenderable} />
          </div>

          <div ref={pillSectionRef} className="pill-section mt-3">
            <div className="hidden-section">
              <div className="pill-wrapper">
                {" "}
                {card.current.services.map((tag, index) => {
                  return (
                    <div
                      key={tag.id}
                      ref={(el: HTMLDivElement) => {
                        servicePillsRef.current[index] = el;
                      }}
                    >
                      <EmpRoundedPill text={tag.label} />
                    </div>
                  );
                })}{" "}
              </div>
            </div>
            <EmpContent label={"Services"} value={servicePillRenderable} />
          </div>
          <div className="action-section mt-6">
            {/* <div className="btn-wrapper">
                    <EmpButton
                        onSubmit={() => { }}
                        buttonHeight="sm"
                        text={"Invite"} />
                </div> */}
          </div>
        </div>
      )}
      {mode === "skeleton" && (
        <div className="skeletal-card">
          <div className="bio-section">
            <div className="image-wrapper skeleton emp-shimmer"></div>
            <div className="account-details-wrapper">
              <div className="name skeleton emp-shimmer"></div>
              <div className="email skeleton emp-shimmer"></div>
            </div>
          </div>
          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 120,
                  valueWidth: 40,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
            <div className="metric-right">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 120,
                  valueWidth: 40,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
          </div>
          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 80,
                  valueWidth: 60,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
            <div className="metric-right">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 100,
                  valueWidth: 80,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
          </div>

          <div className="metrics-section mt-3">
            <div className="metric-left">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 60,
                  valueWidth: 40,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
            <div className="metric-right">
              <EmpContent
                mode="skeleton"
                skeletonSpecs={{
                  headerWidth: 40,
                  valueWidth: 30,
                  useShimmer: true,
                }}
                label={""}
                value={""}
              />
            </div>
          </div>
          <EmpContent
            className="mt-4"
            mode="skeleton"
            skeletonSpecs={{
              headerWidth: 60,
              valueWidth: "100%",
              useShimmer: true,
            }}
            label={""}
            value={""}
          />
          <EmpContent
            className="mt-4"
            mode="skeleton"
            skeletonSpecs={{
              headerWidth: 60,
              valueWidth: "100%",
              useShimmer: true,
            }}
            label={""}
            value={""}
          />
        </div>
      )}
      {mode === "loading" && <div className="loading-card emp-shimmer"></div>}
    </div>
  );
};
