import { useCallback } from "react";
import { InstagramMedia } from "../../model/campaign/social-media.dto";
import "./pow-modal-listing.scss";
import { DateUtil } from "../../utilities/date";
import EyeIcon from "../icon/eye-icon";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import PlayIcon from "../icon/play-icon";
import EmpContent from "../shared/emp-content/emp-content";
import EmpLink from "../shared/emp-link/emp-link";
import { Color } from "../../utilities/colors";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import {
  EVIDENCE_TYPE_DISPLAY,
  PROOF_OF_WORK_MEDIA_TYPE,
} from "../../constants/app.constants";
import FileCheckIcon from "../icon/file-check-icon";
import InstagramIcon from "../icon/instagram-icon";
import { EmpImage } from "../shared/emp-image/emp-image";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import CheckIcon from "../icon/check-icon";
import EmpPill from "../shared/EmpPill/EmpPill";
import ShareIcon from "../icon/share-icon";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  socialMediaObject: InstagramMedia;
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
}
export const InstagramPow = (props: Props) => {
  const {
    socialMediaObject: instagramObj,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
  } = props;

  const renderPowModalListing = useCallback(() => {
    const mapping: { [key: string]: string } = {
      "instagram-story-picture": "Instagram Story (Picture)",
      "instagram-story-video": "Instagram Story (Video)",
      "instagram-feed-picture": "Instagram Post",
      "instagram-feed-video": "Instagram Reel",
    };
    return (
      <>
        {[
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
        ].includes(instagramObj.mediaType) && (
          <img
            className="preview-image"
            alt={instagramObj.name}
            src={instagramObj.picture}
          />
        )}
        {[
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
        ].includes(instagramObj.mediaType) && (
          <video className="preview-image" src={instagramObj.picture} />
        )}
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              <span className="card-title">
                {mapping[instagramObj.mediaType]}
              </span>
              {instagramObj.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE && (
                <div className="metrics-bar mt-2">
                  <div className="metric-wrapper">
                    <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.impressionCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.likeCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <MessageIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[600]}
                    />
                    <span>{instagramObj.commentCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.shareCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <div className="download-btn-wrapper">
                      <EmpLink
                        href={evidenceRecord.mediaUrl}
                        newTab
                        text={"Link to Post"}
                        showNewTabIcon
                      />
                    </div>
                  </div>
                </div>
              )}
              {instagramObj.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO && (
                <div className="metrics-bar mt-2">
                  <div className="metric-wrapper">
                    <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.playCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.impressionCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.likeCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <MessageIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[600]}
                    />
                    <span>{instagramObj.commentCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{instagramObj.shareCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <div className="download-btn-wrapper">
                      <EmpLink
                        href={evidenceRecord.mediaUrl}
                        newTab
                        text={"Link to Post"}
                        showNewTabIcon
                      />
                    </div>
                  </div>
                </div>
              )}
              {instagramObj.mediaProductType === "STORY" && (
                <div className="metrics-bar mt-2">
                  <div className="metric-wrapper">
                    <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>{instagramObj.impressionCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>{instagramObj.likeCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <MessageIcon
                      backgroundColor={Color.NEUTRAL[600]}
                      size={14}
                    />
                    <span>{instagramObj.commentCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>{instagramObj.shareCount}</span>
                  </div>
                  <div className="metric-wrapper">
                    <div className="download-btn-wrapper">
                      <EmpLink
                        href={evidenceRecord.mediaUrl}
                        newTab
                        text={"Download Post Media"}
                        showNewTabIcon
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={CheckIcon}
              {...PILL_COLORS.green}
              text={"Live Data"}
              tooltip={`This data is pulled directly from the ${evidenceRecord.platform} API. Refresh the page to access the most up-to-date information.`}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [instagramObj, evidenceRecord]);

  const renderPowCard = useCallback(() => {
    return (
      <div className="image-wrapper">
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <img
              className="social-media-avatar"
              alt={instagramObj?.name}
              src={instagramObj?.avatarPicture}
            />
            <div className="social-media-platform-badge">
              <InstagramIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">{instagramObj?.name}</span>
          </div>
        </div>
        <div className="post-wrapper">
          {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
            <div className={`additional-deliverable-wrapper-badge`}>
              <span className="badge-text">
                +{noOfOtherEvidenceRecords - 1}
              </span>
              <FileCheckIcon
                strokeWidth={2.5}
                size={12}
                backgroundColor={Color.NEUTRAL[300]}
              />
            </div>
          )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <img
              className="attachment-img"
              alt="Uploaded evidence"
              src={instagramObj?.picture}
            />
          )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
          ].includes(evidenceRecord?.mediaType) && (
            <video
              muted
              loop
              className="attachment-video"
              src={instagramObj?.picture}
            />
          )}
        </div>
        <div className="sm-metrics-section">
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <div className="metrics-bar">
              {instagramObj?.mediaProductType === "REELS" && (
                <div className="metric-wrapper">
                  <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{instagramObj?.playCount}</span>
                </div>
              )}
              <div className="metric-wrapper">
                <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{instagramObj?.impressionCount}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{instagramObj?.likeCount}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{instagramObj?.commentCount}</span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{instagramObj.shareCount}</span>
              </div>
            </div>
          )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <div className="metrics-bar">
              <div className="metric-wrapper">
                <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{instagramObj.impressionCount}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{instagramObj.likeCount}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{instagramObj.commentCount}</span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{instagramObj.shareCount}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [instagramObj, evidenceRecord, noOfOtherEvidenceRecords]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <img
              className="social-media-avatar"
              alt={instagramObj?.name}
              src={instagramObj?.avatarPicture}
            />
            <div className="social-media-platform-badge">
              <InstagramIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">{instagramObj?.name}</span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <img
              className="sm-image"
              alt="Uploaded evidence"
              src={instagramObj.picture}
            />
          )}

          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
          ].includes(evidenceRecord.mediaType) && (
            <div className="sm-video-wrapper">
              <video
                className="sm-video"
                muted
                controls
                autoPlay
                loop
                src={instagramObj.picture}
              />
            </div>
          )}
        </div>
        {(instagramObj as InstagramMedia).mediaProductType === "FEED" && (
          <div className="sm-metrics">
            <div className="metric-wrapper">
              <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).impressionCount}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).likeCount}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).commentCount}</span>
            </div>
            <div className="metric-wrapper">
              <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{instagramObj.shareCount}</span>
            </div>
          </div>
        )}
        {(instagramObj as InstagramMedia).mediaProductType === "REELS" && (
          <div className="sm-metrics">
            <div className="metric-wrapper">
              <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).playCount}</span>
            </div>
            <div className="metric-wrapper">
              <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).impressionCount}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).likeCount}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{(instagramObj as InstagramMedia).commentCount}</span>
            </div>
            <div className="metric-wrapper">
              <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{instagramObj.shareCount}</span>
            </div>
          </div>
        )}
        {(instagramObj as InstagramMedia).mediaProductType === "STORY" && (
          <div className="sm-metrics">
            <div className="metric-wrapper">
              <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{instagramObj.impressionCount}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{instagramObj.likeCount}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{instagramObj.commentCount}</span>
            </div>
            <div className="metric-wrapper">
              <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{instagramObj.shareCount}</span>
            </div>
          </div>
        )}
      </>
    );
  }, [instagramObj, evidenceRecord]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {[
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
        ].includes(evidenceRecord.mediaType) && (
          <video src={instagramObj.picture}></video>
        )}
        {[
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
          PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
        ].includes(evidenceRecord.mediaType) && (
          <EmpImage alt="Proof of work preview" src={instagramObj.picture} />
        )}
      </>
    );
  }, [instagramObj, evidenceRecord]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
