import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SupportApi from "../../../api/referral-and-support-msvc/support.api";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import AlertSquareIcon from "../../../components/icon/alert-square";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpCountrySelect from "../../../components/shared/emp-country-select/emp-country-select";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../../components/shared/emp-cropper/emp-cropper";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import { OnboardingDetailsReqDto } from "../../../model/onboarding/onboarding-details-req.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EmpExceptionHandlerBuilder from "../../../utilities/errorUtils/empExceptionHandlerBuilder";
import FileUtils from "../../../utilities/file-util";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import "./brand-basic-info-step.scss";
import useUser from "../../../hooks/useUser";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
interface StepProps {
  nextStep: (intent: "done" | "skip") => void;
}
export const BrandBasicInfoStep = (props: StepProps) => {
  const navigate = useNavigate();
  const isMobile = useEmpBreakpoint(500);
  const [isLoaded, setIsLoaded] = useState(true);
  const isLogoModifiedRef = useRef(false);
  const { user } = useUser();
  // const [user, setUser] = useState<UserDto>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const submitBtnRef = useRef<EmpButtonRef>();

  useEffect(() => {
    if (!user) return;
    try {
      if (user.userOnboardingState === "COMPLETED") {
        navigate("/brand/home");
      }
      const orgs = user.organisation;
      if (!orgs || orgs.length === 0) return;
      const org = orgs[0];
      const { companyName, postalCode, address } = org;

      setForm((prev) => {
        prev.companyName.forceUpdateValue(companyName);
        prev.address.forceUpdateValue(address);
        prev.postalCode.forceUpdateValue(postalCode);
        return { ...prev };
      });
      setIsLoaded(true);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, [user, navigate]);

  const [logo, setLogo] = useState<string>();

  const [form, setForm] = useState<IFormGroup>({
    companyName: new FormControl("text", [
      new RequiredValidator("Company Name is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Company Name must not exceed 100 characters"
      ),
    ]),
    country: new FormControl("text", []),
    address: new FormControl("text", [
      new RequiredValidator("Address is required"),
      new LengthValidator(
        0,
        200,
        undefined,
        "Address must not exceed 200 characters"
      ),
    ]),
    postalCode: new FormControl("text", [
      new RequiredValidator("Postal Code is required"),
      new LengthValidator(
        0,
        10,
        undefined,
        "Postal code must not exceed 10 characters"
      ),
    ]),
  });
  const cropperRef = useRef<EmpCropperRef>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>();

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);

    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 3MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await FileUtils.readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    if (cropperRef.current === undefined) return;
    cropperRef.current.open(base64);
  };

  const onSubmit = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      setHasSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      const imageIsValid = !(logo === undefined && user!.imageType === "none");
      if (logo === undefined && user!.imageType === "none") {
        setImageErrorMessage("Please upload a profile image");
      }
      if (!imageIsValid || !isValid) return;
      const request: OnboardingDetailsReqDto = {
        isImageModified: isLogoModifiedRef.current,
        image: logo,
        country: form.country.getValue(),
        companyName: form.companyName.getValue(),
        address: form.address.getValue(),
        postalCode: form.postalCode.getValue(),
      };
      const resp = await OnboardingApi.saveBrandSpec(request);
      // SETTING UP BRAND REFERRAL
      await SupportApi.setupBrandReferral();
      if (resp.data.status === "success") {
        props.nextStep("done");
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  return (
    <div className="emp-brand-basic-info-step">
      <EmpCropper
        onCrop={(base64: string) => {
          setLogo(base64);
          isLogoModifiedRef.current = true;
        }}
        ref={cropperRef}
      />
      <div className="form-wrapper">
        <div className="header-section">
          <span className="step-lbl">Personal Details</span>
        </div>
        <motion.div
          className="header-wrapper"
          variants={fadeInVariants}
          initial="hidden"
          animate={isLoaded ? "visible" : "hidden"}
          transition={{ duration: 0.2, delay: 0 * animationDelay }}
        >
          <p className="description mt-4">
            Please fill in the necessary information
          </p>
        </motion.div>
        <div className="body-section">
          <motion.div
            className="image-upload-group"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 1 * animationDelay }}
          >
            {user && (
              <>
                {logo && <img alt="Profile" src={logo} />}
                {!logo && user.imageType === "url" && (
                  <img alt="Profile" src={user.imageResource} />
                )}
                {!logo && user.imageType === "none" && (
                  <img
                    alt="Upload"
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/empty-profile.png"
                    }
                  />
                )}
                {!logo && user.imageType === "avatar" && (
                  <div
                    style={{ background: user.imageResource }}
                    className="avatar"
                  >
                    <span className="initials">{user.initials}</span>
                  </div>
                )}
              </>
            )}
            <label className="logo-upload" htmlFor={"logo-upload"}>
              Upload Logo
            </label>
            {imageErrorMessage && (
              <div className="emp-error-message-wrapper">
                <AlertSquareIcon
                  backgroundColor={Color.RED[600]}
                  size={16}
                  bottom={1}
                />
                <span>{imageErrorMessage}</span>
              </div>
            )}
            <input
              className="upload-hidden"
              accept="image/*"
              type="file"
              id="logo-upload"
              onChange={handleFileUpload}
              name="myfile"
            ></input>
          </motion.div>
          <motion.div
            style={{ position: "relative", zIndex: 4 }}
            className="mt-6"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 1 * animationDelay }}
          >
            <EmpTextInput
              id={"email"}
              formControl={form.companyName}
              labelText="Company Name"
              required
              placeholder="Enter company name"
            />
          </motion.div>
          <motion.div
            style={{ position: "relative", zIndex: 3 }}
            className="mt-3"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 2 * animationDelay }}
          >
            <EmpCountrySelect
              labelText="Country"
              placeholder="Enter Country"
              id={"country"}
              formControl={form.country}
            />
          </motion.div>
          <motion.div
            style={{ position: "relative", zIndex: 2 }}
            className="mt-6"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 3 * animationDelay }}
          >
            <EmpTextInput
              id={"address"}
              formControl={form.address}
              labelText="Address"
              required
              placeholder="Enter Address"
            />
          </motion.div>
          <motion.div
            style={{ position: "relative", zIndex: 1 }}
            className="mt-4"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 4 * animationDelay }}
          >
            <EmpTextInput
              id={"postalCode"}
              formControl={form.postalCode}
              labelText="Postal Code"
              required
              placeholder="Enter Postal Code"
            />
          </motion.div>
          <div className="btn-section">
            <motion.div
              className="mt-10 btn-wrapper"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 5 * animationDelay }}
            >
              <EmpButton
                ref={submitBtnRef}
                buttonHeight={isMobile ? "lg" : "sm"}
                text={"Next Step"}
                onSubmit={() => {
                  onSubmit();
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
