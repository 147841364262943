import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useEmpGuard from "../../hooks/useEmpGuard";
import { SM_REDIRECT } from "../../constants/app.constants";
import { LocalStorageUtil } from "../../utilities/cache/local-storage-util";

type Props = {};

export type SmRedirectDefinitionLocalStorage = {
  value: string;
  platform: "X" | "TikTok";
  metadata?: { [key: string]: string };
};

const URL_BINDING = {
  // Creator Home Page
  [SM_REDIRECT.CREATOR_HOME]: (queryParam: string, pathParam: string) =>
    `/creator/home${pathParam}${queryParam}`,
  // Creator Onboarding Page
  [SM_REDIRECT.CREATOR_ONBOARD]: (queryParam: string, pathParam: string) =>
    `/creator/onboard${pathParam}${queryParam}#social-media`,
  // Creator KYC Page
  [SM_REDIRECT.CREATOR_KYC]: (queryParam: string, pathParam: string) =>
    `/creator/kyc${pathParam}${queryParam}#creator-professional-details`,
  // Brand SMM
  [SM_REDIRECT.BRAND_SMM]: (queryParam: string, pathParam: string) =>
    `/brand/social-media-manager${pathParam}${queryParam}`,
  // Proof of work
  [SM_REDIRECT.SELLER_PROOF_OF_WORK]: (queryParam: string, pathParam: string) =>
    `/creator/campaign-details${pathParam}${queryParam}#Proof%20of%20Work`,
};

const SM_PARAM_BINDING = {
  X: (code: string) => `?sm=x&x_code=${code}`,
  TikTok: (code: string) => `?sm=tiktok&tiktok_code=${code}`,
};

const DEFAULT_URL = "/sign-in";
const SmRedirectPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);

  /**
   * Navigates to the appropriate page based on the redirect definition and code.
   *
   * @function
   * @param {SmRedirectDefinitionLocalStorage} redirectDefinition - The redirect definition from local storage.
   * @param {string | null} [code] - The code used for binding social media accounts, if available.
   * @returns {void}
   */
  const navigateToPage = useCallback(
    (
      redirectDefinition: SmRedirectDefinitionLocalStorage,
      code?: string | null
    ) => {
      const { value, metadata, platform } = redirectDefinition;

      if (!URL_BINDING[value] || !code) {
        navigate(DEFAULT_URL);
        return;
      }

      let pathParam = "";
      let queryParam = SM_PARAM_BINDING[platform](code);
      if (metadata) {
        const metadataQueryParam = Object.entries(metadata)
          .filter(([key]) => key !== "base")
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        queryParam += `&${metadataQueryParam}`;
        pathParam = metadata.base;
      }

      const redirectUrl = URL_BINDING[value](queryParam, pathParam);
      navigate(redirectUrl);
    },
    [navigate]
  );

  /**
   * Effect hook to handle navigation based on URL parameters and local storage redirect definition.
   *
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const redirectDefinition = LocalStorageUtil.getItem(
      "sm-redirect-definition"
    ) as SmRedirectDefinitionLocalStorage;

    navigateToPage(redirectDefinition, code);
  }, [navigateToPage]);
  return <></>;
};

export default SmRedirectPage;
