import { useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreatorInfoApi from "../../../api/user-msvc/creator-info.api";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import { INVITATION_TYPE } from "../../../constants/app.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { CreatorAgencyInvitationDto } from "../../../model/onboarding/creator-agency-invitation-resp.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import StringUtils from "../../../utilities/string.util";
import ToastUtils from "../../../utilities/toast-utils";
import CheckIcon from "../../icon/check-icon";
import CreatorJoinAgencyConfirmationModal, {
  CreatorJoinAgencyConfirmationModalRef,
} from "../../modals/creator-join-agency-confirmation-modal";
import ExclusivePartnershipConfirmationModal, {
  ExclusivePartnershipConfirmationModalRef,
} from "../../modals/exclusive-partnership-confirmation-modal";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
}
export const AgencyInvitationNotification = (props: Props) => {
  const intl = useIntl();
  const { notification, user } = props;
  const creatorJoinAgencyConfirmationModalRef =
    useRef<CreatorJoinAgencyConfirmationModalRef>();
  const exclusivePartnershipConfirmationModalRef =
    useRef<ExclusivePartnershipConfirmationModalRef>();

  const [creatorAgencyInvitation, setCreatorAgencyInvitation] =
    useState<CreatorAgencyInvitationDto>();
  const [isLoading, setLoading] = useState(false);

  const getAgencyInvitation = async (): Promise<
    CreatorAgencyInvitationDto[]
  > => {
    try {
      const resp = await CreatorInfoApi.getCreatorPartnerships(user.id);
      return resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to get agency invitations"
      );
      throw new Error();
    }
  };

  const getCreatorAgencyInvitation =
    async (): Promise<CreatorAgencyInvitationDto> => {
      try {
        if (creatorAgencyInvitation) return creatorAgencyInvitation;
        const resp = await CreatorInfoApi.getCreatorPartnershipById(
          notification.metadata.invitationId
        );
        setCreatorAgencyInvitation(resp.data);
        return resp.data;
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator invitation"
        );
        throw new Error();
      }
    };

  /**
   * Accepts the agency invitation with the specified ID and displays a success toast message.
   *
   * @param {boolean} skipConfirmation - called from the modal directly
   * @returns {Promise<void>} - A Promise that resolves once the invitation is accepted.
   * @throws {Error} - If there's an error while accepting the invitation.
   */
  const acceptAgencyInvitation = async (
    skipConfirmation?: boolean
  ): Promise<void> => {
    try {
      setLoading(true);
      // Fetches creator agency invitation
      const creatorAgencyInvitation = await getCreatorAgencyInvitation();
      const allAgencyInvitation = await getAgencyInvitation();
      setLoading(false);
      const canSkipConfirmation = skipConfirmation ?? false;
      if (!canSkipConfirmation) {
        if (requiresConfirmation(creatorAgencyInvitation, allAgencyInvitation))
          return;
      }

      const resp = await OnboardingApi.creatorAcceptInvitation({
        creatorAgencyInvitationId: notification.metadata.invitationId,
      });
      if (resp.data.status === "success") {
        PubSub.publish(PUB_SUB_TOPICS.PARTNERSHIP);
        ToastUtils.success(
          "Invitation Accepted",
          `You have accepted the invitation to join ${notification.sender.senderName}`
        );
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to accept invitation"
      );
    }
  };

  const requiresConfirmation = (
    creatorAgencyInvitation: CreatorAgencyInvitationDto,
    allAgencyInvitation: CreatorAgencyInvitationDto[]
  ): boolean => {
    if (notification.metadata.invitationType === INVITATION_TYPE.EXCLUSIVE) {
      if (
        allAgencyInvitation.findIndex(
          (elem) =>
            elem.invitationType === INVITATION_TYPE.AFFILIATE &&
            elem.status === "ACCEPTED"
        ) > -1
      ) {
        creatorJoinAgencyConfirmationModalRef.current?.show(
          creatorAgencyInvitation
        );
        return true;
      }
      exclusivePartnershipConfirmationModalRef.current?.show(
        user,
        creatorAgencyInvitation
      );
      return true;
    }
    return false;
  };

  /**
   * Rejects the agency invitation with the specified ID and displays a success toast message.
   *
   * @param {string} invitationId - The ID of the agency invitation.
   * @param {string} agencyName - The name of the agency.
   * @returns {Promise<void>} - A Promise that resolves once the invitation is rejected.
   * @throws {Error} - If there's an error while rejecting the invitation.
   */
  const rejectAgencyInvitation = async () => {
    try {
      const resp = await OnboardingApi.creatorRejectInvitation({
        creatorAgencyInvitationId: notification.metadata.invitationId,
      });
      if (resp.data.status === "success") {
        PubSub.publish(PUB_SUB_TOPICS.PARTNERSHIP);
        ToastUtils.success(
          "Invitation Rejected",
          `You have rejected the invitation to join ${notification.sender.senderName}`
        );
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to reject invitation"
      );
    }
  };

  const translateInvitationType = (invitationType: string) => {
    if (invitationType === "exclusive-invitation")
      return intl.formatMessage({
        id: "agencyInvitationNotification_exclusiveInvitation",
      });
    if (invitationType === "affiliate-invitation")
      return intl.formatMessage({
        id: "agencyInvitationNotification_affiliateInvitation",
      });
  };

  return (
    <div className="emp-notification-record">
      {isLoading && (
        <div className="loader">
          <div className="emp-spinner"></div>
        </div>
      )}
      <CreatorJoinAgencyConfirmationModal
        ref={creatorJoinAgencyConfirmationModalRef}
        onSave={() => {
          acceptAgencyInvitation(true);
        }}
      />

      <ExclusivePartnershipConfirmationModal
        ref={exclusivePartnershipConfirmationModalRef}
        onSave={() => {
          acceptAgencyInvitation(true);
        }}
      />

      {/* Organisation Img */}
      {notification.sender.senderType === "organisation" && (
        <img
          alt={notification.sender.senderName}
          className="organisation-img"
          src={notification.sender.imageResource}
        />
      )}
      {/* User Img */}
      {notification.sender.senderType === "user" && (
        <>
          {notification.sender.imageType === "url" && (
            <img
              alt={notification.sender.senderName}
              className="user-img"
              src={notification.sender.imageResource}
            />
          )}
          {notification.sender.imageType === "avatar" && (
            <div
              style={{ background: notification.sender.imageResource }}
              className="user-avatar"
            >
              <span className="initials">{notification.sender.initials}</span>
            </div>
          )}
        </>
      )}
      <div className="notification-content">
        <div className="content-wrapper">
          <span className="info-text">
            <FormattedMessage
              id="agencyInvitationNotification_content"
              values={{
                sender: notification.sender.senderName,
                invitationType: (
                  <span className="emp-highlighted">
                    {translateInvitationType(
                      notification.metadata.invitationType
                    )}
                  </span>
                ),
              }}
            />
          </span>
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
          {/* <span className="small-text">{DateUtils.toReadableDateWithTime(notification.createdAt.toISOString())}</span> */}
          <div className="button-wrapper">
            {notification.metadata.state === "valid" && (
              <>
                <EmpLink
                  text={
                    <FormattedMessage id="agencyInvitationNotification_acceptBtn" />
                  }
                  onSubmit={() => {
                    acceptAgencyInvitation();
                  }}
                />
                <EmpLink
                  linkStyle="danger"
                  text={
                    <FormattedMessage id="agencyInvitationNotification_rejectBtn" />
                  }
                  onSubmit={() => {
                    rejectAgencyInvitation();
                  }}
                />
              </>
            )}
            {notification.metadata.state === "accepted" && (
              <>
                <span className="disabled-text">
                  <FormattedMessage id="agencyInvitationNotification_accepted" />
                </span>
              </>
            )}
            {notification.metadata.state === "rejected" && (
              <>
                <span className="disabled-text">
                  <FormattedMessage id="agencyInvitationNotification_rejected" />
                </span>
              </>
            )}
            {notification.metadata.state === "revoked" && (
              <>
                <span className="disabled-text">
                  <FormattedMessage id="agencyInvitationNotification_revoked" />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
