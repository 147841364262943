import "../../../styles/shared/proof-of-work-view.scss";
import _debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import { useLocation } from "react-router-dom";
import DraftApi from "../../../api/campaign-msvc/draft.api";
import { DraftDto } from "../../../model/campaign/draft.dto";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import { ExtendedEvidenceDraftDto } from "../../../model/campaign/extended-evidence-draft.dto";
import { EvidenceDto } from "../../../model/campaign/evidence.dto";
import { EmpEvidenceCard } from "../../../components/shared/emp-evidence-card/emp-evidence-card";
import SubmitEvidenceModal, {
  SubmitEvidenceModalRef,
} from "../../../components/modals/submit-evidence-modal";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import useSelfAdjustingCards from "../../../hooks/useSelfAdjustingCards";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import { XLoader } from "../../../components/loaders/x-loader";
import { SOCIAL_MEDIA_TYPE } from "../../../constants/app.constants";
import { empDelay } from "../../../utilities/delay";
import CampaignSmUpdateStatus from "../../../components/views/campaign-sm-update-status";

interface Props {
  task: TaskDto;
  user: UserDto;
}

export const SellerSubmitEvidenceView = (props: Props) => {
  const location = useLocation();
  const { task: taskProp, user } = props;

  const [task, setTask] = useState<TaskDto>(taskProp);
  const [loading, setLoading] = useState(true);

  const submitEvidenceModalRef = useRef<SubmitEvidenceModalRef>();
  const [ongoingDeliverableMap, setOngoingDeliverableMap] =
    useState<Map<string, OngoingDeliverableExtendedDto>>();
  const representativeIdRef = useRef<string>();
  const [mappedExtendedEvidenceList, setMappedExtendedEvidenceList] =
    useState<Map<string, ExtendedEvidenceDraftDto[]>>();

  const hasTriggeredSmBinding = useRef(false);

  const GUTTER_WIDTH = 10;
  const MIN_CARD_WIDTH = 240;
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const { cardWidth, shimmerCard } = useSelfAdjustingCards(
    MIN_CARD_WIDTH,
    GUTTER_WIDTH,
    resizeDivRef,
    1
  );

  const fetchSellerOngoingDeliverables = useCallback(
    async (task: TaskDto) => {
      try {
        setLoading(true);
        const userRole = location.pathname.split("/")[1];
        let representativeId = "";
        if (userRole === "creator") {
          representativeId = user.id;
        } else if (userRole === "agency") {
          representativeId = user.organisation![0].id;
        }
        representativeIdRef.current = representativeId;

        const ongoingDeliverableResp =
          await OngoingTaskApi.fetchSellerDeliverables(task.id);
        const latestDraftsResp = await DraftApi.fetchDraftAsSeller(task.id);
        const evidenceResp = await EvidenceApi.fetchEvidenceAsSeller(task.id);

        const ongoingDeliverableMap = new Map<
          string,
          OngoingDeliverableExtendedDto
        >();
        ongoingDeliverableResp.data.forEach((elem) => {
          ongoingDeliverableMap.set(elem.id, elem);
        });
        setOngoingDeliverableMap(ongoingDeliverableMap);
        const latestDraftMap = new Map<string, DraftDto>();
        latestDraftsResp.data.forEach((elem) => {
          latestDraftMap.set(
            `${elem.deliverableId}_${elem.creatorUserId}`,
            elem
          );
        });

        const evidenceMap = new Map<string, EvidenceDto>();
        evidenceResp.data.forEach((elem) => {
          evidenceMap.set(elem.id, elem);
        });
        const extendedDraftList: ExtendedEvidenceDraftDto[] =
          ongoingDeliverableResp.data.map((elem) => {
            const draft = latestDraftMap.get(elem.id);
            const evidence = evidenceMap.get(elem.id);

            const obj = {
              id: elem.id,
              campaignId: elem.campaignId,
              taskId: elem.taskId,
              deliverableId: elem.deliverableId,
              status: elem.status,
              representativeId: elem.representativeId,
              representativeRole: elem.representativeRole,
              creator: elem.creator,
              agencyRepresentative: elem.agencyRepresentative,
              brand: elem.brand,
              createdDate: elem.createdDate,
              updatedDate: elem.updatedDate,
              revisionsLeft: elem.revisionsLeft,
              remarks: elem.remarks,
              draft: draft && {
                id: draft.id,
                status: draft.status,
                fileType: draft.draftAttachments[0]?.fileType,
                attachmentUrl: draft.draftAttachments[0]?.attachmentUrl,
                attachmentSize: draft.draftAttachments[0]?.attachmentSize,
                attachmentName: draft.draftAttachments[0]?.attachmentName,
                brandResponse: draft.brandResponse,
                remarks: draft.remarks,
                createdDate: draft.createdDate,
                updatedDate: draft.updatedDate,
              },
              evidence: evidence && {
                id: evidence.id,
                taskId: evidence.taskId,
                representativeId: evidence.representativeId,
                representativeRole: evidence.representativeRole,
                agencyOrgId: evidence.agencyOrgId,
                creatorUserId: evidence.creatorUserId,
                numRecords: evidence.numRecords,
                status: evidence.status,
                evidenceRecords: evidence.evidenceRecords,
                createdDate: evidence.createdDate,
                updatedDate: evidence.createdDate,
              },
            };
            return obj;
          });

        extendedDraftList.sort((a, b) => {
          if (a.evidence && b.evidence) {
            return (
              new Date(b.evidence.updatedDate).getTime() -
              new Date(a.evidence.updatedDate).getTime()
            );
          } else if (a.evidence) {
            return -1;
          } else if (b.evidence) {
            return 1;
          } else {
            return 0;
          }
        });
        
        const map = new Map<string, ExtendedEvidenceDraftDto[]>();
        extendedDraftList.forEach((elem) => {
          if (!map.has(elem.deliverableId)) {
            map.set(elem.deliverableId, [elem]);
          } else {
            map.get(elem.deliverableId)!.push(elem);
          }
        });
        setMappedExtendedEvidenceList(map);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch deliverables"
        );
      } finally {
        setLoading(false);
      }
    },
    [location, user]
  );

  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
      fetchSellerOngoingDeliverables(taskProp);
    }
  }, [taskProp, fetchSellerOngoingDeliverables]);

  const onChat = (deliverableId: string, creatorUserId: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    // Set a query parameter
    urlParams.set("deliverableId", deliverableId);
    urlParams.set("creatorUserId", creatorUserId);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
    // Let fragment hash
    window.location.hash = "Chat";
  };

  const toDraft = () => {
    window.location.hash = "Submit Draft";
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    const xCode = urlParams.get("x_code");
    const ongoingDeliverableId = urlParams.get("ongoingDeliverableId");
    const representativeIdParam = urlParams.get("representativeId");

    if (
      !ongoingDeliverableId ||
      !representativeIdParam ||
      !task ||
      !ongoingDeliverableMap ||
      hasTriggeredSmBinding.current
    ) {
      return;
    }

    const openModal = async () => {
      await empDelay(500);
      submitEvidenceModalRef.current?.show(
        representativeIdParam,
        ongoingDeliverableId,
        ongoingDeliverableMap.get(ongoingDeliverableId)!,
        "sm-binding"
      );
    };
    if (tikTokCode || xCode) {
      openModal();
      hasTriggeredSmBinding.current = true;
    }
  }, [ongoingDeliverableMap, task]);

  return (
    <div className="emp-proof-of-work-view">
      {loading && task.platform === SOCIAL_MEDIA_TYPE.X && (
        <XLoader
          title={"Tweet Storm Brewing"}
          description="We will not take more than 2 mins"
        />
      )}
      <section className="task-details-section">
        <SubmitEvidenceModal
          task={task}
          ref={submitEvidenceModalRef}
          onSave={() => {
            fetchSellerOngoingDeliverables(task);
          }}
        />
        <div className="task-name-wrapper">
          <div className="info-wrapper">
            <div>
              <h2 className="section-label">My Proof of Work</h2>
              <p className="emp-paragraph mt-2">
                After receiving approval, you can submit your proof of work
                here.
              </p>
            </div>
          </div>
          <CampaignSmUpdateStatus task={task} role={"seller"} />
        </div>
      </section>

      <section className="deliverable-section" ref={resizeDivRef}>
        {task.deliverables.map((elem, index) => {
          return (
            <div key={elem.id} className="deliverable-wrapper">
              <span className="deliverable-indicator">
                PROOF OF WORK FOR DELIVERABLE {index + 1}
              </span>
              <h3 className="deliverable-name-lbl">{elem.name}</h3>
              <EmpEditorView value={elem.description} />

              <section className="deliverable-cards-section mt-2">
                {/* Loaded Cards */}
                {!loading &&
                  mappedExtendedEvidenceList &&
                  ongoingDeliverableMap &&
                  mappedExtendedEvidenceList.has(elem.id) &&
                  mappedExtendedEvidenceList.get(elem.id)!.map((evidence) => {
                    return (
                      <EmpEvidenceCard
                        cardWidth={cardWidth}
                        key={evidence.id}
                        role="seller"
                        onCreate={() => {
                          submitEvidenceModalRef.current?.show(
                            representativeIdRef.current!,
                            evidence.id,
                            ongoingDeliverableMap.get(evidence.id)!
                          );
                        }}
                        onView={() => {}}
                        onChat={() => {
                          onChat(evidence.id, evidence.creator.id);
                        }}
                        toDraft={() => {
                          toDraft();
                        }}
                        evidenceItem={evidence}
                        mode={"default"}
                      />
                    );
                  })}

                {/* Loading Shimmer Cards */}
                {loading &&
                  shimmerCard.map((elem) => {
                    return (
                      <EmpEvidenceCard
                        key={elem}
                        cardWidth={cardWidth}
                        role={"seller"}
                        mode={"skeleton"}
                      />
                    );
                  })}
              </section>
            </div>
          );
        })}
      </section>
    </div>
  );
};
