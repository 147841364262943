import { useCallback, useEffect, useRef, useState } from "react";
import EmailIcon from "../../components/icon/email-icon";
import LockIcon from "../../components/icon/lock-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import _debounce from "lodash/debounce";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import "./sign-in-page.scss";
import { motion, useAnimation } from "framer-motion";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import ChevronRightIcon from "../../components/icon/chevron-right";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";

import AlertSquareIcon from "../../components/icon/alert-square";
import { useLocation, useNavigate } from "react-router-dom";
import useEmpGuard from "../../hooks/useEmpGuard";
import EmpLink from "../../components/shared/emp-link/emp-link";
import ForgotPasswordModal, {
  ForgotPasswordModalRef,
} from "./forgot-password-modal";
import { PermsUtils } from "../../constants/permissions.constants";
import UserApi from "../../api/user-msvc/user.api";
import EmpLanguagePicker from "../../components/shared/emp-language-picker/emp-language-picker";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import {
  signIn,
  getCurrentUser,
  signInWithRedirect,
  signOut as awsSignOut,
} from "aws-amplify/auth";
import EmpGoogleButton from "../../components/shared/emp-google-button/emp-google-button";
import useEmpAuth from "../../hooks/useEmpAuth";
import AuthErrorModal, {
  AuthErrorModalRef,
} from "../../components/modals/auth-error-modal";
import { AUTH_CUSTOM_STATES } from "../../constants/app.constants";

const slideVariants = {
  hidden: { width: "0%" },
  visible: { width: "100%" },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const loaderVariants = {
  reset: { width: "0%" },
  max: { width: "100%" },
};

interface Slide {
  testimony: string;
  representative: string;
  image: string;
  sequence: number;
}

export const SignInPage = () => {
  const navigate = useNavigate();
  useEmpGuard("PUBLIC", navigate);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const referralCode = urlSearchParams.get("code") ?? undefined;
  const intl = useIntl();

  const [imgDimensions, setImgDimensions] = useState<{
    height: number | string;
    width: number | string;
  }>({ height: 0, width: 0 });
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hiddenElemRef = useRef<HTMLDivElement>(null);
  const signinButtonRef = useRef<EmpButtonRef>();
  const forgotPasswordModalRef = useRef<ForgotPasswordModalRef>();
  const authErrorModalRef = useRef<AuthErrorModalRef>();
  const contentAnimator = useAnimation();
  const loaderAnimator = useAnimation();

  const [slides, setSlides] = useState<Slide[]>([]);
  const [currentSlide, setCurrentSlide] = useState<Slide>();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const slidesSequenceRef = useRef<Slide[]>([]);

  const { getErrorCode } = useEmpAuth();

  useEffect(() => {
    const errorCode = getErrorCode(location);
    console.log(errorCode);
    if (errorCode) {
      authErrorModalRef.current?.show(errorCode);
    }
  }, [location, getErrorCode]);

  useEffect(() => {
    const slide1: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide1" }),
      representative: "Talent from EMERGE Group",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-1.png",
      sequence: 0,
    };

    const slide2: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide2" }),
      representative: "Freelance Talent",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-2.png",
      sequence: 1,
    };

    const slide3: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide3" }),
      representative: "Talent from Kahuism",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-3.png",
      sequence: 2,
    };

    const slide4: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide4" }),
      representative: "Talent from EMERGE Group",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-1.png",
      sequence: 3,
    };

    const slide5: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide1" }),
      representative: "Talent from EMERGE Group",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-2.png",
      sequence: 4,
    };

    const slide6: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide2" }),
      representative: "Freelance Talent",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-3.png",
      sequence: 5,
    };
    const slide7: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide3" }),
      representative: "Talent from Optimizz",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-1.png",
      sequence: 6,
    };

    const slide8: Slide = {
      testimony: intl.formatMessage({ id: "signIn_slide4" }),
      representative: "Talent from EMERGE Group",
      image:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/carousel-slides/signin-slide-2.png",
      sequence: 7,
    };
    slidesSequenceRef.current = [
      slide1,
      slide2,
      slide3,
      slide4,
      slide5,
      slide6,
      slide7,
      slide8,
    ];
    setCurrentSlide(slide1);
    setSlides([slide1]);
  }, [intl]);

  // This will start the info animation whenever the current slide use state is changed
  useEffect(() => {
    const playContent = async () => {
      contentAnimator.start("hidden");
      await empDelay(1000);
      contentAnimator.start("visible");
    };
    playContent();
    loaderAnimator.start("max");
  }, [currentSlide]);

  const [form, setForm] = useState<IFormGroup>();

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      return {
        email: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_emailRequired" })
            ),
            new LengthValidator(
              0,
              100,
              undefined,
              "Email must not exceed 100 characters"
            ),
            new EmailValidator(
              intl.formatMessage({ id: "validation_emailRequired" }),
              intl.formatMessage({ id: "validation_emailInvalid" }),
              intl.formatMessage({ id: "validation_emailTooLong" })
            ),
          ],
          prevForm?.email.getValue() ?? ""
        ),
        password: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_passwordRequired" })
            ),
          ],
          prevForm?.password.getValue() ?? ""
        ),
      };
    });
  }, [intl]);

  const changeSlide = async (direction: "left" | "right") => {
    if (!currentSlide) return;
    const index = currentSlide.sequence;
    if (direction === "right") {
      const lastIndex = slidesSequenceRef.current.length - 1;
      let nextIndex = index + 1;
      if (nextIndex > lastIndex) nextIndex = 0;

      const displayedSlide = slidesSequenceRef.current[nextIndex];
      loaderAnimator.set("reset");
      setCurrentSlide(displayedSlide);

      if (slides.length >= 3) {
        slides.shift();
      }
      slides.push(displayedSlide);
      setSlides([...slides]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!hiddenElemRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(
        hiddenElemRef.current!.clientHeight,
        hiddenElemRef.current!.clientWidth
      );
    });
    resizeObserver.observe(hiddenElemRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const computeHiddenFormHeight = (height: number, width: number) => {
    setImgDimensions({ height, width });
  };
  const debounceFn: (height: number, width: number) => void = useCallback(
    _debounce(computeHiddenFormHeight, 300, { leading: true }),
    []
  );

  const signInOnClick = async () => {
    try {
      if (!form) return;
      signinButtonRef.current?.setButtonState("loading");
      setHasSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      // Prepare to send the form
      const email = form.email.getValue();
      const password = form.password.getValue();
      await signIn({ username: email, password });
      if (await checkIfSignedIn()) {
        // Determine which page to route
        const user = await UserApi.fetchUser();
        const redirectUrl = PermsUtils.initialRoute(user.data);
        navigate(redirectUrl);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e);
        if (e.name === "NotAuthorizedException") {
          setErrorMessage("Incorrect email or password");
          return;
        }
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
      }
    } finally {
      signinButtonRef.current?.setButtonState("default");
    }
  };

  async function checkIfSignedIn() {
    try {
      await getCurrentUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleFormOnChange = (formControl: FormControl) => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  return (
    <div ref={wrapperRef} className="emp-signin-page cbz-background-gradient">
      <ForgotPasswordModal ref={forgotPasswordModalRef} onSave={() => {}} />
      <AuthErrorModal ref={authErrorModalRef} />
      <div className="emp-signin-content">
        <div className="emp-form-area ">
          <div className="emp-form-wrapper">
            <div className="logo-wrapper">
              <img
                className="logo"
                alt="creatorbuzz logo"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
              />
              <div className="language-picker-mobile">
                <EmpLanguagePicker
                  bordered={true}
                  responsive={false}
                  menuPlacement="right"
                />
              </div>
            </div>

            <h1 className="title">
              <FormattedMessage id="signIn_header" />
            </h1>
            <p className="description">
              <FormattedMessage id="signIn_subtitle" />
            </p>
            {form && (
              <>
                <div className="mt-6">
                  <EmpTextInput
                    labelText={intl.formatMessage({ id: "input_email_label" })}
                    placeholder={intl.formatMessage({
                      id: "input_email_placeholder",
                    })}
                    type="email"
                    leftIconComponent={EmailIcon}
                    id={"email"}
                    onKeypress={(key) => {
                      if (key === "Enter") signInOnClick();
                    }}
                    onChange={() => {
                      handleFormOnChange(form.email);
                    }}
                    formControl={form.email}
                  />
                </div>

                <div className="mt-4">
                  <EmpTextInput
                    labelText={intl.formatMessage({
                      id: "input_password_label",
                    })}
                    placeholder={intl.formatMessage({
                      id: "input_password_placeholder",
                    })}
                    leftIconComponent={LockIcon}
                    id={"password"}
                    type="password"
                    showPassword
                    onKeypress={(key) => {
                      if (key === "Enter") signInOnClick();
                    }}
                    enterKeyHint="done"
                    onChange={() => {
                      handleFormOnChange(form.password);
                    }}
                    formControl={form.password}
                  />
                </div>
              </>
            )}
            {errorMessage && (
              <div className="emp-error-message-wrapper mt-3">
                <AlertSquareIcon
                  backgroundColor={Color.RED[600]}
                  size={16}
                  bottom={1}
                />
                <span>{errorMessage}</span>
              </div>
            )}
            <div>
              <EmpLink
                onSubmit={() => {
                  forgotPasswordModalRef.current?.show();
                }}
                text={intl.formatMessage({ id: "signIn_forgotPassword" })}
              />
            </div>
            <div className="mt-8">
              <EmpButton
                ref={signinButtonRef}
                buttonHeight="lg"
                text={intl.formatMessage({ id: "button_signIn" })}
                onSubmit={() => {
                  signInOnClick();
                }}
              />
              <EmpGoogleButton
                className="mt-3"
                buttonHeight="lg"
                onSubmit={() => {
                  signInWithRedirect({
                    provider: "Google",
                    customState: JSON.stringify({
                      origin: AUTH_CUSTOM_STATES.DEFAULT_SSO,
                      referralCode: referralCode,
                    }),
                  });
                }}
              />
            </div>
            <div className="sign-in-wrapper mt-3">
              <span>
                <FormattedMessage id="signIn_signUpText" />
                <EmpLink
                  onSubmit={() => navigate("/sign-up")}
                  text={intl.formatMessage({ id: "signIn_signUpCta" })}
                />{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="emp-gallery-area">
          <div className="gallery-background-mask">
            {slides.map((elem) => {
              return (
                <div key={elem.sequence} className="slide-wrapper">
                  <motion.div
                    className="slide"
                    variants={slideVariants}
                    initial={"hidden"}
                    animate={"visible"}
                    transition={{
                      duration: 1.2,
                      delay: 0.4,
                    }}
                  >
                    <div
                      className="slide-anchor"
                      style={{
                        width: imgDimensions.width,
                        height: imgDimensions.height,
                      }}
                    >
                      {/* <img className="slide-image" style={{ width: imgDimensions.width, height: imgDimensions.height }} srcSet={elem.image} /> */}
                      <img
                        className="slide-image"
                        alt={"slide"}
                        style={{ height: 600 }}
                        srcSet={elem.image}
                      />
                    </div>
                  </motion.div>
                </div>
              );
            })}
            <div ref={hiddenElemRef} className="hidden-div"></div>
            {currentSlide && (
              <div className="info-wrapper">
                <motion.div
                  variants={textVariants}
                  initial={"hidden"}
                  animate={contentAnimator}
                >
                  <p className="testimony-line">{currentSlide.testimony}</p>
                </motion.div>
                {/* <motion.div
                            variants={textVariants}
                            initial={"hidden"}
                            animate={contentAnimator}>
                            <span className="name-lbl">{currentSlide.name}</span>
                        </motion.div>
                        <motion.div
                            variants={textVariants}
                            initial={"hidden"}
                            animate={contentAnimator}>
                            <span className="representative-lbl">{currentSlide.representative}</span>
                        </motion.div> */}
              </div>
            )}
            <div className="button-wrapper">
              {/* <EmpIconButton
                            onSubmit={() => changeSlide("left")}
                            buttonStyle="translucent"
                            icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />} /> */}
              <div className="ml-2">
                <EmpIconButton
                  onSubmit={() => changeSlide("right")}
                  buttonStyle="translucent"
                  icon={<ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />}
                />
              </div>
            </div>
            <div className="loader-wrapper">
              <motion.div
                className="loader-bar"
                variants={loaderVariants}
                initial={"reset"}
                animate={loaderAnimator}
                transition={{
                  ease: "linear",
                  delay: 1.2,
                  duration: 6,
                }}
                onAnimationComplete={() => {
                  changeSlide("right");
                }}
              ></motion.div>
            </div>
          </div>

          {/* <div className="gallery-background-mask hidden">
                <div ref={hiddenSectionRef} className="hidden-section"></div>
            </div> */}
        </div>
      </div>
    </div>
  );
};
