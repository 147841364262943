import { sign } from "crypto";
import { motion } from "framer-motion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { empDelay } from "../../../utilities/delay";
import AnnouncementIcon from "../../icon/announcement-icon";
import LayoutIcon from "../../icon/layout-icon";
import LogOut3Icon from "../../icon/log-out-3-icon";
import SettingsIcon from "../../icon/settings-icon";
import ShareIcon from "../../icon/share-icon";
import UserIcon from "../../icon/user-icon";
import "./emp-creator-side-nav.scss";

interface Props {
  onSignOut: () => void;
  user: UserDto;
}

export interface EmpAnimatedMenuBtnRef {
  toBarIcon: () => void;
  toCloseIcon: () => void;
}

const drawerVariants = {
  hidden: { x: "100%" },
  shown: { x: "0" },
};
const overlayVariants = {
  hidden: { opacity: 0 },
  shown: { opacity: 1 },
};
const fadeInVariant = {
  hidden: { opacity: 0 },
  shown: { opacity: 1 },
};

const navMenuDuration = 0.6;
const delay = 0.05;

const EmpCreatorSideNav = forwardRef((props: Props, ref) => {
  const [isOpen, setOpen] = useState(false);
  const [drawerAnimState, setDrawerAnimState] = useState<"hidden" | "shown">(
    "hidden"
  );
  const { onSignOut, user } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) setDrawerAnimState("shown");
  }, [isOpen]);

  useEffect(() => {
    const animateCloseDrawer = async () => {
      await empDelay(250);
      setOpen(false);
    };
    if (drawerAnimState === "hidden") {
      animateCloseDrawer();
    }
  }, [drawerAnimState]);

  const topBarElemRef = useRef<HTMLDivElement>(null);
  const middleBarElemRef = useRef<HTMLDivElement>(null);
  const bottomBarElemRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        toBarIcon,
        toCloseIcon,
      };
    },
    []
  );

  const toBarIcon = () => {
    topBarElemRef.current?.classList.remove("top");
    middleBarElemRef.current?.classList.remove("middle");
    bottomBarElemRef.current?.classList.remove("bottom");
  };

  const toCloseIcon = () => {
    topBarElemRef.current?.classList.add("top");
    middleBarElemRef.current?.classList.add("middle");
    bottomBarElemRef.current?.classList.add("bottom");
  };

  const handleClose = (info: any) => {
    const closeThreshold = 300;

    if (info.point.x > closeThreshold) {
      setDrawerAnimState("hidden");
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setOpen(!isOpen);
        }}
        className="animated-btn-wrapper"
      >
        <div ref={topBarElemRef} className="bar"></div>
        <div ref={middleBarElemRef} className="bar"></div>
        <div ref={bottomBarElemRef} className="bar"></div>
      </div>

      {isOpen && (
        <motion.div
          onClick={() => {
            setDrawerAnimState("hidden");
          }}
          transition={{ duration: 0.25, ease: "linear" }}
          variants={overlayVariants}
          initial="hidden"
          animate={drawerAnimState}
          className="side-nav-overlay"
        ></motion.div>
      )}

      {isOpen && (
        <motion.div
          drag="x"
          dragConstraints={{ left: 0, right: 0 }} // Set drag constraints
          dragMomentum={false}
          dragElastic={0.3}
          onDragEnd={(event, info) => handleClose(info)}
          variants={drawerVariants}
          transition={{ duration: 0.25, ease: "easeOut" }}
          initial="hidden"
          animate={drawerAnimState}
          className="side-nav-menu"
        >
          <div className="side-nav-menu-wrapper">
            <div className="side-nav-profile-wrapper">
              {user.imageType === "avatar" && (
                <div
                  style={{ background: user.imageResource }}
                  className="profile-picture"
                >
                  <span className="initials">{user.initials}</span>
                </div>
              )}
              {user.imageType === "url" && (
                <img src={user.imageResource} className="profile-picture" />
              )}
              <div className="profile-details-wrapper">
                <span className="name-lbl">{user.fullName}</span>
                <span className="email-lbl">{user.email}</span>
              </div>
            </div>
            <div className="side-nav-menu-item-wrapper mt-3">
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 0,
                }}
                onClick={() => {
                  setDrawerAnimState("hidden");
                  navigate("/creator/home");
                }}
                className="side-nav-menu-item"
              >
                <div className="icon-wrapper">
                  <LayoutIcon
                    strokeWidth={2.5}
                    left={1}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={16}
                  />
                </div>

                <span className="label">Dashboard</span>
              </motion.div>
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 1,
                }}
                onClick={() => {
                  setDrawerAnimState("hidden");
                  navigate("/creator/profile");
                }}
                className="side-nav-menu-item"
              >
                <div className="icon-wrapper">
                  <UserIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={18}
                  />
                </div>
                <span className="label">Profile</span>
              </motion.div>
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 2,
                }}
                onClick={() => {
                  setDrawerAnimState("hidden");
                  navigate("/creator/campaigns-new");
                }}
                className="side-nav-menu-item"
              >
                <div className="icon-wrapper">
                  <AnnouncementIcon
                    strokeWidth={2.5}
                    left={1}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={18}
                  />
                </div>
                <span className="label">Campaigns</span>
              </motion.div>
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 3,
                }}
                className="side-nav-menu-item"
                onClick={() => {
                  setDrawerAnimState("hidden");
                  navigate("/creator/settings");
                }}
              >
                <div className="icon-wrapper">
                  <SettingsIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={18}
                  />
                </div>
                <span className="label">Settings</span>
              </motion.div>
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 4,
                }}
                onClick={() => {
                  setDrawerAnimState("hidden");
                  navigate("/creator/referrals");
                }}
                className="side-nav-menu-item"
              >
                <div className="icon-wrapper">
                  <ShareIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={18}
                  />
                </div>
                <span className="label">Referrals</span>
              </motion.div>
              <motion.div
                variants={fadeInVariant}
                initial="hidden"
                animate="shown"
                transition={{
                  duration: navMenuDuration,
                  delay: 0.1 + delay * 5,
                }}
                onClick={() => {
                  onSignOut();
                }}
                className="side-nav-menu-item"
              >
                <div className="icon-wrapper">
                  <LogOut3Icon
                    strokeWidth={2.5}
                    backgroundColor={Color.RED[600]}
                    size={18}
                  />
                </div>
                <span className="label red">Logout</span>
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
});
export default EmpCreatorSideNav;
