import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import EmpException from "../../exception/empException";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import TrashIcon from "../icon/trash-icon";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./delete-modal.scss";

export interface DeleteModalRef {
  // Context = what type of item needs to be deleted, item = name of item that needs to be deleted
  show: (
    context: string,
    item: string,
    fn: (id: string) => void,
    id: string
  ) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
  suppressToast?: boolean;
  title?: string | JSX.Element;
  deleteBtnText?: string;
  description?: string | JSX.Element;
}

const DeleteModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const actionBtnRef = useRef<EmpButtonRef>(null);

  const infoRef = useRef<{ context: string; item: string; id: string }>({
    context: "",
    item: "",
    id: "",
  });
  const deleteFnRef = useRef<(id: string) => void>();
  const suppressToast = props.suppressToast ?? false;
  const title = props.title ?? (
    <FormattedMessage id="deleteResourceModal_header" />
  );
  const description = props.description ?? (
    <FormattedMessage
      id="deleteResourceModal_desc"
      values={{
        resource: <span className="highlighted">{infoRef.current.item}</span>,
      }}
    />
  );
  const deleteBtnText = props.deleteBtnText ?? (
    <FormattedMessage id="cta_delete" />
  );

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    context: string,
    item: string,
    fn: (id: string) => void,
    id: string
  ) => {
    infoRef.current = { context, item, id };
    deleteFnRef.current = fn;
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
  };

  const deleteOnClick = async () => {
    try {
      if (!deleteFnRef.current)
        throw new EmpException("Unable to get delete function");
      deleteFnRef.current(infoRef.current.id);
      props.onSave();
      dismiss();
      if (!suppressToast) {
        ToastUtils.success(
          `Successfully deleted ${infoRef.current.item}`,
          `${infoRef.current.context} deleted`
        );
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to delete ${infoRef.current.context}`
      );
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={`Delete ${infoRef.current.context}`}
      showHeader={false}
      showHeaderBorder={false}
      showFooter={false}
      showFooterBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-delete-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="delete-modal-text-content-section">
          <div className="title-wrapper">
            <TrashIcon size={18} />
            <h2 className="title">{title}</h2>
          </div>
          <p className="description">{description}</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 16,
          }}
        >
          <div style={{ width: "fit-content" }}>
            <EmpButton
              ref={actionBtnRef}
              onSubmit={() => {
                deleteOnClick();
              }}
              text={deleteBtnText}
              buttonStyle="danger"
            />
          </div>
          <div style={{ width: "fit-content", marginRight: 8 }}>
            <EmpButton
              text={<FormattedMessage id="cta_cancel" />}
              buttonStyle="secondary"
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default DeleteModal;
