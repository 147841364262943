import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
  onView: () => void;
}

export const RateCardResponseNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  const userRoleRef = useRef<string>("");

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  const viewOnClick = async () => {
    navigate(
      `/${userRoleRef.current}/creator-details/${notification.metadata.creator.id}`
    );
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  return (
    <div className="emp-notification-record" onClick={notifOnClick}>
      {isLoading && (
        <div className="loader">
          <div className="emp-spinner"></div>
        </div>
      )}

      {/* Organisation Img */}
      <div className="organisation-img-wrapper">
        {notification.sender.senderType === "organisation" && (
          <img
            alt={notification.sender.senderName}
            className="organisation-img"
            src={notification.sender.imageResource}
          />
        )}
        {notification.metadata.creator.imageType === "url" && (
          <img
            alt={notification.metadata.creator.fulName}
            className="badge-img small-profile-badge"
            src={notification.metadata.creator.imageResource}
          />
        )}
        {notification.metadata.creator.imageType === "avatar" && (
          <div
            style={{ background: notification.metadata.creator.imageResource }}
            className="badge-avatar small-profile-badge"
          >
            <span className="initials">
              {notification.metadata.creator.initials}
            </span>
          </div>
        )}
      </div>
      <div className="notification-content">
        <div className="content-wrapper">
          <span className="info-text">
            {notification.sender.senderName} has{" "}
            <span className="emp-highlighted">
              {" "}
              {notification.metadata.state}
            </span>{" "}
            your request to view the creator rate card.
          </span>
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
          <div className="button-wrapper">
            <EmpLink text={"View"} onSubmit={viewOnClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
