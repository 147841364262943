import "../brand-event-post-modal.scss";
import "./tiktok-config-post-modal.scss";

import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Divider, Toggle } from "rsuite";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import EmpButton, {
  EmpButtonRef,
} from "../../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../../components/shared/emp-modal/emp-modal";
import { SmConnectionBriefRespDto } from "../../../../model/smm/smm-connection-brief-resp.dto";
import { AppContext } from "../../../../context/app.context";
import TikTokIcon from "../../../../components/icon/tiktok-icon";
import { EMPTY_PROFILE_IMG_URL } from "../../../../constants/app.constants";
import { Color } from "../../../../utilities/colors";
import { FormControl } from "../../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../../utilities/formUtils/requiredValidator";
import { LengthValidator } from "../../../../utilities/formUtils/lengthValidator";
import XIcon from "../../../../components/icon/x-icon";
import AlertCircleIcon from "../../../../components/icon/alert-circle";
import PlayIcon from "../../../../components/icon/play-icon";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../../../../components/shared/emp-checkbox/emp-checkbox";
import EmpDropdownBtn from "../../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import ChevronDownIcon from "../../../../components/icon/chevron-down";
import SocialMediaManagementTikTokApi from "../../../../api/smm-msvc/smm-tiktok";
import EmpLoader from "../../../../components/shared/emp-loader/emp-loader";
import EmpLoaderV2 from "../../../../components/shared/emp-loader-v2/emp-loader-v2";
import moment from "moment";
import EmpPill from "../../../../components/shared/EmpPill/EmpPill";

export interface TikTokConfigPostModalRef {
  show: (connectProfile: SmConnectionBriefRespDto[]) => void;
  dismiss: () => void;
}

type PostType = "post" | "reel" | "story";

type PlatformType = "facebook" | "instagram" | "tiktok" | "x";

interface Props {
  generalForm: IFormGroup;
  isMultiEdit?: boolean;
  multiForm?: IFormGroup[];
  setIsUpdatedTikTokSettings: (isUpdated: boolean) => void;
  videoFiles?: File[] | string[];
  imageFiles?: File[] | string[];
  multiVideo?: Record<string, File[]>;
  multiImage?: Record<string, File[]>;
  fnSubmit: () => void;
  defaultForm?: Record<string, IFormGroup>;
}

type privacyOptionsType = {
  label: string;
  value: string;
};

const privacyMap: Record<string, string> = {
  PUBLIC_TO_EVERYONE: "Public",
  FOLLOWER_OF_CREATOR: "Followers",
  MUTUAL_FOLLOW_FRIENDS: "Mutual Friends",
  SELF_ONLY: "Only Me",
};

const TikTokConfigPostModal = forwardRef(
  (
    {
      generalForm,
      isMultiEdit,
      multiForm,
      setIsUpdatedTikTokSettings,
      videoFiles,
      imageFiles,
      multiVideo,
      multiImage,
      fnSubmit,
      defaultForm,
    }: Props,
    ref
  ) => {
    const backBtnRef = useRef<EmpButtonRef>();
    const postBtnRef = useRef<EmpButtonRef>();
    const yourBrandCheckboxRef = useRef<EmpCheckboxRef>();
    const brandedContentCheckboxRef = useRef<EmpCheckboxRef>();
    const commentAbilityCheckboxRef = useRef<EmpCheckboxRef>();
    const duetAbilityCheckboxRef = useRef<EmpCheckboxRef>();
    const stitchAbilityCheckboxRef = useRef<EmpCheckboxRef>();

    const { user: userContext } = useContext(AppContext);

    const [yourBrandChecked, setYourBrandChecked] = useState(false);
    const [brandedContentChecked, setBrandedContentChecked] = useState(false);
    const [isContentDisclosure, setIsContentDisclosure] =
      useState<boolean>(false);
    const [commentAbilityChecked, setCommentAbilityChecked] = useState(false);
    const [duetAbilityChecked, setDuetAbilityChecked] = useState(false);
    const [stitchAbilityChecked, setStitchAbilityChecked] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);

    const [multiFormDescription, setMultiFormDescription] = useState<string[]>(
      []
    );
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentPrivacyLevel, setCurrentPrivacyLevel] = useState<string>();
    const [privacyLevelOptions, setPrivacyLevelOptions] = useState<
      privacyOptionsType[]
    >([]);
    const [previewDescription, setPreviewDescription] = useState<string>("");
    const [scheduledDate, setScheduledDate] = useState<number>();
    const [selectedConnect, setSelectedConnect] =
      useState<SmConnectionBriefRespDto | null>(null);
    const [connects, setConnects] = useState<SmConnectionBriefRespDto[]>([]);

    // form state
    const [form, setForm] = useState<IFormGroup>({
      privacy_level: new FormControl("text", [
        new RequiredValidator("Please select a privacy level"),
      ]),
      disable_duet: new FormControl("boolean", []),
      disable_comment: new FormControl("boolean", []),
      disable_stitch: new FormControl("boolean", []),
      brand_content_toggle: new FormControl("boolean", []),
      brand_organic_toggle: new FormControl("boolean", []),
    });
    const [multiSettingForm, setMultiSettingForm] = useState<IFormGroup[]>([]);

    // form functions
    const resetForm = () => {
      // Resetting the form
      FormGroupUtil.reset(form);
      setForm({ ...form });
    };

    const handleChangeSetting = (key: string, value: any) => {
      if (isMultiEdit) {
        multiSettingForm[currentIndex][key].setValue(value);
        return;
      }
      form[key].setValue(value);
    };

    const handleChangeContentDisclosure = () => {
      setIsContentDisclosure(!isContentDisclosure);
    };

    const handleChangePrivacyLevel = (option: string) => {
      setCurrentPrivacyLevel(option);
      form.privacy_level.setValue(option);
    };
    const getScheduledTime = (form: IFormGroup) => {
      const date = new Date(form.dateOfPosting?.getValue());
      const time = new Date(form.timeOfPosting?.getValue());
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

      const timestampInMinutes = Math.floor(date.getTime() / 1000 / 60);
      return timestampInMinutes;
    };

    useImperativeHandle(ref, () => ({
      show: (connectProfile: SmConnectionBriefRespDto[]) => {
        if (isMultiEdit) {
          const multiFormDescriptionValue: string[] = multiForm!
            .filter((form) => form.platform.getValue() === "tiktok")
            .map((form) => form.description.getValue());
          setPreviewDescription(multiFormDescriptionValue[0]);
          setMultiFormDescription(multiFormDescriptionValue);
          setScheduledDate(getScheduledTime(multiForm![0]));
          setMultiSettingForm(connectProfile.map(() => ({ ...form })));
        } else {
          if (defaultForm) {
            setPreviewDescription(
              defaultForm[
                connectProfile[0]?.socialMediaUserId!
              ].description.getValue()
            );
            setScheduledDate(
              getScheduledTime(
                defaultForm[connectProfile[0]?.socialMediaUserId!]
              )
            );
          } else {
            setPreviewDescription(generalForm.description.getValue());
            setScheduledDate(getScheduledTime(generalForm));
          }
        }

        setSelectedConnect(connectProfile[0]);
        setConnects(connectProfile);
        setVisible(true);
      },
      dismiss: () => {
        setVisible(false);
      },
    }));

    const dismiss = () => {
      setVisible(false);
      setSelectedConnect(null);
      setConnects([]);
      setYourBrandChecked(false);
      setBrandedContentChecked(false);
      setCommentAbilityChecked(false);
      setDuetAbilityChecked(false);
      setStitchAbilityChecked(false);
      setIsContentDisclosure(false);
      setPrivacyLevelOptions([]);
      setCurrentPrivacyLevel(undefined);
      setPreviewDescription("");
      setScheduledDate(undefined);
      setMultiFormDescription([]);
      setCurrentIndex(0);

      resetForm();
    };

    const handleFormSubmit = () => {
      if (isMultiEdit) {
        const currentConnect = connects[currentIndex];
        const currentMultiForm = multiForm!.find(
          (form) =>
            form.socialMediaUserId.getValue() ===
            currentConnect.socialMediaUserId
        );

        if (currentMultiForm) {
          currentMultiForm.privacy_level.setValue(currentPrivacyLevel);
          currentMultiForm.disable_comment.setValue(commentAbilityChecked);
          currentMultiForm.disable_duet.setValue(duetAbilityChecked);
          currentMultiForm.disable_stitch.setValue(stitchAbilityChecked);
          currentMultiForm.brand_content_toggle.setValue(brandedContentChecked);
          currentMultiForm.brand_organic_toggle.setValue(yourBrandChecked);
        }

        const nextIndex = currentIndex + 1;
        if (nextIndex <= connects.length - 1) {
          setCurrentIndex(nextIndex);
          setPreviewDescription(multiFormDescription[nextIndex]);
          setScheduledDate(getScheduledTime(multiForm![nextIndex]));
          setSelectedConnect(connects[nextIndex]);
          return;
        }

        setIsUpdatedTikTokSettings(true);
        fnSubmit();
        dismiss();
      } else {
        if (defaultForm) {
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].privacy_level.setValue(form.privacy_level.getValue());
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].disable_comment.setValue(form.disable_comment.getValue());
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].disable_duet.setValue(form.disable_duet.getValue());
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].disable_stitch.setValue(form.disable_stitch.getValue());
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].brand_content_toggle.setValue(form.brand_content_toggle.getValue());
          defaultForm[
            selectedConnect?.socialMediaUserId!
          ].brand_organic_toggle.setValue(form.brand_organic_toggle.getValue());
        }
        generalForm.privacy_level.setValue(form.privacy_level.getValue());
        generalForm.disable_comment.setValue(form.disable_comment.getValue());
        generalForm.disable_duet.setValue(form.disable_duet.getValue());
        generalForm.disable_stitch.setValue(form.disable_stitch.getValue());
        generalForm.brand_content_toggle.setValue(
          form.brand_content_toggle.getValue()
        );
        generalForm.brand_organic_toggle.setValue(
          form.brand_organic_toggle.getValue()
        );

        setIsUpdatedTikTokSettings(true);
        fnSubmit();
        dismiss();
      }
    };

    const handleBackButton = async () => {
      dismiss();
    };

    const isSubmitDisabled = useMemo(() => {
      if (isContentDisclosure) {
        return !(
          currentPrivacyLevel &&
          (yourBrandChecked || brandedContentChecked)
        );
      }
      return !currentPrivacyLevel;
    }, [
      brandedContentChecked,
      currentPrivacyLevel,
      isContentDisclosure,
      yourBrandChecked,
    ]);

    const renderFilePreview = useMemo(() => {
      const isString = (value: any): boolean => typeof value === "string";

      const video = isMultiEdit
        ? multiVideo?.[connects[currentIndex]?.socialMediaUserId]?.[0] || null
        : videoFiles?.[0] || null;
      const image = isMultiEdit
        ? multiImage?.[connects[currentIndex]?.socialMediaUserId]?.[0] || null
        : imageFiles?.[0] || null;

      if (!video && !image) return <></>;

      return (
        <div className="media-wrapper">
          {video && (
            <>
              <video>
                <source
                  src={
                    isString(video)
                      ? (video as string)
                      : URL.createObjectURL(video as File)
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <PlayIcon backgroundColor={Color.NEUTRAL[100]} size={20} />
            </>
          )}
          {image && (
            <img
              src={
                isString(image)
                  ? (image as string)
                  : URL.createObjectURL(image as File)
              }
              alt="Post media"
            />
          )}
        </div>
      );
    }, [
      connects,
      currentIndex,
      imageFiles,
      isMultiEdit,
      multiImage,
      multiVideo,
      videoFiles,
    ]);

    const renderInteractionCheckbox = () => {
      const handleCheckboxChange = (
        ref: React.MutableRefObject<EmpCheckboxRef | undefined>,
        checkedState: boolean,
        setCheckedState: React.Dispatch<React.SetStateAction<boolean>>,
        settingKey: string
      ) => {
        ref.current?.setChecked(!checkedState);
        handleChangeSetting(settingKey, !checkedState);
        setCheckedState(!checkedState);
      };

      const video = isMultiEdit
        ? multiVideo?.[connects[currentIndex]?.socialMediaUserId]?.[0] || null
        : videoFiles?.[0] || null;

      const checkboxData = [
        {
          ref: commentAbilityCheckboxRef,
          checkedState: commentAbilityChecked,
          setCheckedState: setCommentAbilityChecked,
          id: "commentAbility",
          labelText: "Comment",
          settingKey: "disable_comment",
          disableControl: false,
        },
        {
          ref: duetAbilityCheckboxRef,
          checkedState: duetAbilityChecked,
          setCheckedState: setDuetAbilityChecked,
          id: "duetAbility",
          labelText: "Duet",
          settingKey: "disable_duet",
          disableControl: !video,
        },
        {
          ref: stitchAbilityCheckboxRef,
          checkedState: stitchAbilityChecked,
          setCheckedState: setStitchAbilityChecked,
          id: "stitchAbility",
          labelText: "Stitch",
          settingKey: "disable_stitch",
          disableControl: !video,
        },
      ];

      return (
        <>
          {checkboxData.map(
            ({
              ref,
              checkedState,
              setCheckedState,
              id,
              labelText,
              settingKey,
              disableControl,
            }) => (
              <div
                className={`checkbox-wrapper ${
                  disableControl ? "disable" : ""
                }`}
                key={id}
              >
                <EmpCheckbox
                  onChange={() =>
                    handleCheckboxChange(
                      ref,
                      checkedState,
                      setCheckedState,
                      settingKey
                    )
                  }
                  ref={ref}
                  id={id}
                  labelText={labelText}
                  disableControl={disableControl}
                />
              </div>
            )
          )}
        </>
      );
    };
    const renderPrompt = useCallback(() => {
      if (!isContentDisclosure) return null;
      if (!yourBrandChecked && !brandedContentChecked)
        return (
          <div className="disclosure-warning-wrapper mb-5">
            <EmpPill
              backgroundColor={Color.RED[50]}
              color={Color.RED[500]}
              text={
                "You need to indicate if your content promotes yourseft, a third party, or both."
              }
              icon={
                <AlertCircleIcon backgroundColor={Color.RED[500]} size={16} />
              }
              size="md"
              fullWidth={true}
            />
          </div>
        );

      let promptText = "";
      if (yourBrandChecked) {
        promptText = brandedContentChecked
          ? "Your photo/video will be labeled as 'Paid partnership’"
          : "Your photo/video will be labeled as 'Promotional Content’";
      } else if (brandedContentChecked) {
        promptText = "Your photo/video will be labeled as 'Paid partnership’";
      }

      return promptText ? (
        <div className="disclosure-warning-wrapper mb-5">
          <EmpPill
            backgroundColor={Color.PRIMARY[100]}
            color={Color.PRIMARY[500]}
            text={promptText}
            icon={
              <AlertCircleIcon backgroundColor={Color.PRIMARY[500]} size={16} />
            }
            size="md"
            fullWidth={true}
          />
        </div>
      ) : null;
    }, [brandedContentChecked, isContentDisclosure, yourBrandChecked]);

    useEffect(() => {
      const getUserPrivacy = async () => {
        if (!userContext?.organisation) return;
        const brandId = userContext.organisation[0].id;
        const response = await SocialMediaManagementTikTokApi.getUserPrivacy(
          brandId,
          selectedConnect?.socialMediaUserId!
        );
        if (response.status === "success") {
          const { data } = response;
          const privacyOptions: privacyOptionsType[] = (
            data as unknown as string[]
          ).map((privacy: string) => ({
            label: privacyMap[privacy as keyof typeof privacyMap],
            value: privacy,
          }));
          setPrivacyLevelOptions(privacyOptions);
        }
      };

      if (selectedConnect && userContext) {
        getUserPrivacy();
      }
    }, [selectedConnect, userContext]);

    return (
      <>
        <EmpModal
          customClassName="brand-event-post-modal tiktok-config-post-modal"
          visible={visible}
          setVisible={setVisible}
          showHeader={false}
          showFooter={true}
          onClose={() => {
            dismiss();
          }}
          size={"lg"}
        >
          {/* This is body */}
          <div className="content-wrapper">
            <div className="pl-5 pr-5">
              <div className="event-post-modal-header">
                <h1>Configure Post Settings for TikTok</h1>
                {isMultiEdit && (
                  <span>
                    {currentIndex + 1}/{connects.length}
                  </span>
                )}
              </div>
              <div className="selected-connect-wrapper">
                {isMultiEdit ? (
                  <div className="profile-wrapper tiktok-config">
                    <div className={`picture-section`}>
                      <img
                        src={
                          selectedConnect?.pictureUrl || EMPTY_PROFILE_IMG_URL
                        }
                        alt={`${selectedConnect?.name} ${selectedConnect?.platform} dp`}
                      />
                      <div
                        className={`social-media-bubble ${selectedConnect?.platform.toLowerCase()}`}
                      >
                        <TikTokIcon
                          backgroundColor={Color.NEUTRAL[0]}
                          size={12}
                        />
                      </div>
                    </div>
                    <div className="info-section">
                      <span className="handle-name-lbl">
                        {selectedConnect?.name}
                      </span>
                      <span className="metric-lbl">
                        {selectedConnect?.followers} Followers
                      </span>
                    </div>
                  </div>
                ) : (
                  connects.map((connect, index) => (
                    <div className="profile-wrapper tiktok-config" key={index}>
                      <div className={`picture-section`}>
                        <img
                          src={connect.pictureUrl || EMPTY_PROFILE_IMG_URL}
                          alt={`${connect.name} ${connect.platform} dp`}
                        />
                        <div
                          className={`social-media-bubble ${connect.platform.toLowerCase()}`}
                        >
                          <TikTokIcon
                            backgroundColor={Color.NEUTRAL[0]}
                            size={12}
                          />
                        </div>
                      </div>
                      <div className="info-section">
                        <span className="handle-name-lbl">{connect.name}</span>
                        <span className="metric-lbl">
                          {connect.followers} Followers
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="tiktok-config-post-video-content-wrapper">
                {renderFilePreview}
                <div className="video-detail">
                  <h2>Caption</h2>
                  <p className="mt-2 mb-2">
                    {previewDescription || "No description provided"}
                  </p>
                  <label className="mb-1">Scheduled Date</label>
                  <p>
                    {!!scheduledDate
                      ? moment(scheduledDate).format("DD MMM h:mmA [GMT]Z")
                      : "Now"}
                  </p>
                </div>
              </div>
            </div>

            <Divider />
            <div className="pl-5 pr-5">
              <h1>Additional Settings</h1>
              <div className="content-disclosure-wrapper">
                <label className="mb-1">Commercial Content Disclosure</label>
                <div className="content-disclosure">
                  <p>
                    Turn on Content Disclosure setting to indicate whether this
                    content promotes yourself, a brand, product or service
                  </p>
                  <Toggle
                    checked={isContentDisclosure}
                    onChange={handleChangeContentDisclosure}
                  />
                </div>
              </div>
              {isContentDisclosure && (
                <motion.div
                  className="content-disclosure-wrapper"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="mb-3">
                    <label>Your Brand</label>
                    <div className="content-disclosure gap-0">
                      <EmpCheckbox
                        onChange={() => {
                          yourBrandCheckboxRef.current?.setChecked(
                            !yourBrandChecked
                          );
                          handleChangeSetting(
                            "brand_organic_toggle",
                            !yourBrandChecked
                          );
                          setYourBrandChecked(!yourBrandChecked);
                        }}
                        ref={yourBrandCheckboxRef}
                        id={"yourBrandCheckbox"}
                      />
                      <p>
                        You are promoting yourself or your own business. This
                        content will be classified as Brand Organic.
                      </p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Branded Content</label>
                    <div className="content-disclosure gap-0">
                      <EmpCheckbox
                        onChange={() => {
                          brandedContentCheckboxRef.current?.setChecked(
                            !brandedContentChecked
                          );
                          handleChangeSetting(
                            "brand_content_toggle",
                            !brandedContentChecked
                          );
                          setBrandedContentChecked(!brandedContentChecked);
                        }}
                        ref={brandedContentCheckboxRef}
                        id={"brandedContentCheckbox"}
                      />
                      <p>
                        You are promoting another brand or a third party. This
                        content will be classified as Branded Content.
                      </p>
                    </div>
                  </div>
                  {renderPrompt()}
                </motion.div>
              )}
              <div className="mb-3">
                <label>
                  Privacy Level <span className="asterisk-mark">&nbsp;*</span>
                </label>
                <div className="content-disclosure w-fit">
                  {privacyLevelOptions.length > 0 ? (
                    <EmpDropdownBtn
                      text={
                        currentPrivacyLevel
                          ? privacyMap[currentPrivacyLevel]
                          : "Please Select a Privacy Value"
                      }
                      buttonStyle="outline-secondary"
                      placement="left"
                      menuButtonSpecs={privacyLevelOptions.map((option) => ({
                        context: option.value,
                        label: option.label,
                        action: () => {
                          handleChangePrivacyLevel(option.value);
                        },
                      }))}
                      rightIcon={ChevronDownIcon}
                    />
                  ) : (
                    <EmpLoaderV2 isLoading={true} background="dark" />
                  )}
                </div>
              </div>
              <div>
                <label>Interaction Ability</label>
                <div className="interaction-checkbox-group">
                  {renderInteractionCheckbox()}
                </div>
              </div>
              <div>
                By posting, you agree to TikTok's&nbsp;
                <Link
                  to={"https://www.tiktok.com/legal/page/global/bc-policy/en"}
                  target="_blank"
                >
                  Branded Content Policy&nbsp;
                </Link>
                and&nbsp;
                <Link
                  to={
                    "https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                  }
                  target="_blank"
                >
                  Music Usage Confirmation
                </Link>
                .
              </div>
            </div>
          </div>

          {/* This is footer */}
          <div className="footer-btn-wrapper">
            <div className="left-side">
              <EmpButton
                ref={backBtnRef}
                text={"Back"}
                isFullWidth={false}
                buttonStyle="secondary"
                onSubmit={handleBackButton}
              />
            </div>
            <div className="right-side">
              <EmpButton
                onSubmit={() => handleFormSubmit()}
                isFullWidth={false}
                text={
                  isMultiEdit && currentIndex + 1 !== connects.length
                    ? "Next"
                    : "Proceed"
                }
                buttonHeight="md"
                ref={postBtnRef}
                disabled={isSubmitDisabled}
              />
            </div>
          </div>
        </EmpModal>
      </>
    );
  }
);

export default TikTokConfigPostModal;
