import "./emp-dropdown-btn.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import EmpButton, {
  ButtonStyleType,
  EmpButtonRef,
} from "../emp-button/emp-button";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import { IconProps } from "../../../model/common/icon";
import { Tooltip, Whisper } from "rsuite";

export interface MenuButtonSpec<T> {
  action: (record: T) => void;
  context: T;
  label: string;
  buttonStyle?: "primary" | "danger" | "disabled";
  tooltip?: string;
}
interface Props<T> {
  text: string;
  menuButtonSpecs: MenuButtonSpec<T>[];
  isLoading?: boolean;
  placement?: "right" | "left" | "top" | "bottom";
  buttonStyle?: ButtonStyleType;
  leftIcon?: React.FC<IconProps>;
  rightIcon?: React.FC<IconProps>;
  dropdownElement?: MenuButtonSpec<T>[];
  isDisabled?: boolean;
}
const MENU_ITEM_HEIGHT = 30;
const GUTTER_WIDTH = 10;
const MAX_MENU_ITEMS = 8;

const menuButtonStyle: React.CSSProperties = {
  height: MENU_ITEM_HEIGHT,
};

const EmpDropdownBtn = <T extends {}>(props: Props<T>) => {
  const [menuButtonSpecs] = useState<MenuButtonSpec<T>[]>(
    props.menuButtonSpecs.map((elem) => {
      return { ...elem, buttonStyle: elem.buttonStyle ?? "primary" };
    })
  );

  const buttonRef = useRef<EmpButtonRef>();
  const hiddenMenu = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const placement = props.placement ?? "right";
  const isSelectedRef = useRef(false);
  const [isSelected, setSelected] = useState(false);

  const [menuWrapperStyle, setMenuWrapperStyle] = useState<React.CSSProperties>(
    {}
  );
  const [placementStyle, setPlacementStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (props === undefined) return;
    const state = props.isLoading ? "loading" : "default";
    buttonRef.current?.setButtonState(state);
  }, [props.isLoading]);

  const handleClickOutside = (event: MouseEvent) => {
    // This function is called when the user clicks outside of the menu,
    // and is used to close the menu.
    if (!isSelectedRef.current) return;
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setSelected(false);
    }
  };

  /**
   * This function is called when the user clicks on a menu item.
   * It should navigate to the link specified in the spec.
   * @param spec
   */
  const menuItemOnClick = (spec: MenuButtonSpec<T>) => {
    spec.action(spec.context);
    setSelected(false);
  };

  // This function is called when the component is mounted and whenever the
  // isSelected prop changes.
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    // Calculating the width of the dropdown menu
    const width = hiddenMenu.current?.clientWidth;
    const height = hiddenMenu.current?.clientHeight;
    setMenuWrapperStyle({
      width: width,
      maxHeight: MENU_ITEM_HEIGHT * MAX_MENU_ITEMS,
    });
    setPlacementStyle({
      bottom: -GUTTER_WIDTH - height!,
      right: 0,
    });
  }, []);

  const iconOnClick = () => {
    if (props.isDisabled) return;
    if (!isSelected) {
      setSelected(true);
    }
  };

  const buttonStyle = props.buttonStyle || "secondary";
  return (
    <>
      <div
        ref={menuRef}
        onClick={() => iconOnClick()}
        className="emp-dropdown-menu"
      >
        <div style={{ width: "fit-content" }}>
          <EmpButton
            buttonStyle={buttonStyle}
            leftIcon={props?.leftIcon}
            rightIcon={props?.rightIcon}
            ref={buttonRef}
            text={props.text}
            disabled={props.isDisabled ?? false}
          />
        </div>
        {isSelected && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className="emp-dropdown-wrapper"
            style={{ ...menuWrapperStyle, ...placementStyle }}
          >
            {props?.dropdownElement
              ? ReactKeyUtil.addReactKey(props.dropdownElement).map(
                  (elem: any) => (
                    <div
                      onClick={() => menuItemOnClick(elem)}
                      key={elem.reactKey}
                      className={`emp-dropdown-menu-btn ${elem.buttonStyle}`}
                      style={menuButtonStyle}
                    >
                      {elem!.element}
                    </div>
                  )
                )
              : ReactKeyUtil.addReactKey(menuButtonSpecs).map((elem) => {
                  return (
                    <Fragment key={elem.reactKey}>
                      {elem.tooltip && (
                        <Whisper
                          placement="top"
                          speaker={<Tooltip>{elem.tooltip}</Tooltip>}
                        >
                          <button
                            onClick={() => {
                              if (elem.buttonStyle === "disabled") return;
                              menuItemOnClick(elem);
                            }}
                            key={elem.reactKey}
                            className={`emp-button-reset emp-dropdown-menu-btn ${elem.buttonStyle}`}
                            style={menuButtonStyle}
                          >
                            {elem.label}
                          </button>
                        </Whisper>
                      )}
                      {!elem.tooltip && (
                        <button
                          onClick={() => {
                            if (elem.buttonStyle === "disabled") return;
                            menuItemOnClick(elem);
                          }}
                          key={elem.reactKey}
                          className={`emp-button-reset emp-dropdown-menu-btn ${elem.buttonStyle}`}
                          style={menuButtonStyle}
                        >
                          {elem.label}
                        </button>
                      )}
                    </Fragment>
                  );
                })}
          </motion.div>
        )}
      </div>

      {/* Hidden Menu Item to Compute Width */}
      <div ref={hiddenMenu} className="emp-dropdown-wrapper hidden">
        {ReactKeyUtil.addReactKey(menuButtonSpecs).map((elem) => {
          return (
            <div
              key={elem.reactKey}
              className={`emp-dropdown-menu-btn ${elem.buttonStyle}`}
              style={menuButtonStyle}
            >
              {elem.label}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default EmpDropdownBtn;
