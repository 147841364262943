import "./brand-add-member-modal.scss";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { FormControl } from "../../../utilities/formUtils/formControl";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import { EmailValidator } from "../../../utilities/formUtils/emailValidator";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import EmailIcon from "../../../components/icon/email-icon";
import ToastUtils from "../../../utilities/toast-utils";
import EmpExceptionHandlerBuilder from "../../../utilities/errorUtils/empExceptionHandlerBuilder";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import EmpAvatar from "../../../components/shared/emp-avatar/emp-avatar";
import { OrganisationUsersRespDto } from "../../../model/organisation/organisation-users-resp.dto";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { Color } from "../../../utilities/colors";
import { OrganisationInviteReqDto } from "../../../model/organisation/organisation-invite-req.dto";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import OrganisationApi from "../../../api/user-msvc/organisation.api";
import XCloseIcon from "../../../components/icon/x-close-icon";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../../utilities/translation.util";

export interface BrandAddMemberModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const BrandAddMemberModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [organisationUsers, setOrganisationUsers] = useState<
    OrganisationUsersRespDto[]
  >([]);

  const ROLE_OPTIONS = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "inviteTeamMemberModal_accessPrivilegeOptionAdministrator",
        }),
        value: "administrator",
      },
      {
        label: intl.formatMessage({
          id: "inviteTeamMemberModal_accessPrivilegeOptionManager",
        }),
        value: "maintainer",
      },
      {
        label: intl.formatMessage({
          id: "inviteTeamMemberModal_accessPrivilegeOptionMember",
        }),
        value: "member",
      },
    ];
  }, [intl]);

  const invitationBtnRef = useRef<EmpButtonRef>(null);

  const [form, setForm] = useState<IFormGroup>({
    email: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_emailRequired" })
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        intl.formatMessage({ id: "validation_emailTooLong" })
      ),
      new EmailValidator(),
    ]),
    name: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage(
          { id: "validation_requiredDynamic" },
          { field: "Member name" }
        )
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        intl.formatMessage(
          { id: "validation_maxLengthDynamic" },
          { char: "100" }
        )
      ),
    ]),
    role: new FormControl(
      "text",
      [
        new RequiredValidator(
          intl.formatMessage(
            { id: "validation_requiredDynamic" },
            { field: "Role" }
          )
        ),
      ],
      "member"
    ),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async () => {
    try {
      setVisible(true);
      const response = await OrganisationApi.fetchAllUserInOrganisation();
      setOrganisationUsers(response.data);
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  const fetchUsersInOrganisation = async () => {
    try {
      const response = await OrganisationApi.fetchAllUserInOrganisation();
      setOrganisationUsers(response.data);
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const inviteUserOnSubmit = async () => {
    try {
      setSubmitted(true);
      invitationBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      if (!isValid) return;
      const request: OrganisationInviteReqDto = {
        fullName: form.name.getValue(),
        email: form.email.getValue(),
        role: form.role.getValue(),
      };
      const response = await OnboardingApi.inviteUserToOrg(request);
      if (response.data.status === "success") {
        ToastUtils.success(
          "User Invited",
          `We have sent an invitation email to ${form.name.getValue()}`
        );
        resetForm();
        setForm({ ...form });
        setSubmitted(false);
        fetchUsersInOrganisation();
        props.onSave();
      }
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      invitationBtnRef.current?.setButtonState("default");
    }
  };

  const resetForm = () => {
    // Resetting the form
    form.name.reset();
    form.email.reset();
    form.role.forceUpdateValue("member");

    setForm({ ...form });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-brand-add-member-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="inviteTeamMemberModal_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="inviteTeamMemberModal_desc" />
          </p>
        </div>

        <div className="invitation-form-section">
          <div className="text-input-wrapper">
            <div className="pr-1" style={{ flex: 1 }}>
              <EmpTextInput
                leftIconComponent={EmailIcon}
                required
                id={"email"}
                labelText={intl.formatMessage({
                  id: "inviteTeamMemberModal_emailInputLabel",
                })}
                formControl={form.email}
                onChange={validate}
                placeholder={intl.formatMessage({
                  id: "inviteTeamMemberModal_emailInputPlaceholder",
                })}
              />
            </div>
          </div>
          <div className="text-input-wrapper">
            <div className="pr-1" style={{ flex: 1 }}>
              <EmpTextInput
                id={"name"}
                formControl={form.name}
                required
                labelText={intl.formatMessage({
                  id: "inviteTeamMemberModal_nameInputLabel",
                })}
                onChange={validate}
                placeholder={intl.formatMessage({
                  id: "inviteTeamMemberModal_nameInputPlaceholder",
                })}
              />
            </div>
            <div className="pl-1" style={{ flex: 1 }}>
              <EmpSelect
                id={"role"}
                required
                labelText={intl.formatMessage({
                  id: "inviteTeamMemberModal_accessPrivilegeInputLabel",
                })}
                formControl={form.role}
                placeholder={intl.formatMessage({
                  id: "inviteTeamMemberModal_accessPrivilegeInputLabel",
                })}
                onChange={validate}
                selectOptions={ROLE_OPTIONS}
              />
            </div>
          </div>
          <div className="button-wrapper">
            <EmpButton
              onSubmit={() => {
                inviteUserOnSubmit();
              }}
              ref={invitationBtnRef}
              isFullWidth={false}
              text={
                <FormattedMessage id="inviteTeamMemberModal_sendInvitationBtn" />
              }
            />
          </div>
        </div>

        <div className="invited-member-section">
          {organisationUsers.map((elem) => {
            return (
              <div key={elem.id} className="member">
                <div className="member-left-elem">
                  <EmpAvatar user={elem} />
                  <div className="member-info-wrapper">
                    <span className="member-name-lbl">{elem.fullName}</span>
                    <span className="member-email-lbl">{elem.email}</span>
                  </div>
                </div>
                <div className="member-right-elem">
                  <EmpPill
                    backgroundColor={Color.NEUTRAL[150]}
                    color={Color.NEUTRAL[800]}
                    text={TranslationUtil.translateMemberRole(intl, elem.role)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default BrandAddMemberModal;
