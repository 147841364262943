import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import {
  SocialFacebookFeedPostDto,
  SocialFacebookImagePostDto,
  SocialFacebookReelDto,
  SocialFacebookStoryDto,
  SocialFacebookVideoPostDto,
} from "../../model/smm/smm-facebook.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SocialMediaManagementFacebookApi = {
  getFacebookFeeds: async (
    id: string,
    socialMediaUserId: string,
    after?: string,
    before?: string,
    size?: number
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.getFeeds(id, socialMediaUserId),
      { params: { after, before, size } }
    );
    return response.data;
  },

  getFacebookStories: async (
    id: string,
    socialMediaUserId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.getStories(id, socialMediaUserId)
    );
    return response.data;
  },

  getFacebookVideoReels: async (
    id: string,
    socialMediaUserId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.getVideoReels(id, socialMediaUserId)
    );
    return response.data;
  },

  getMediaStatus: async (
    id: string,
    socialMediaUserId: string,
    mediaId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.getMediaStatus(
        id,
        socialMediaUserId,
        mediaId
      )
    );
    return response.data;
  },

  createFeed: async (
    id: string,
    socialMediaUserId: string,
    feedData: SocialFacebookFeedPostDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.createFeed(id, socialMediaUserId),
      feedData
    );
    return response.data;
  },
  createImagePost: async (
    id: string,
    socialMediaUserId: string,
    feedData: SocialFacebookImagePostDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.createImagePost(id, socialMediaUserId),
      feedData
    );
    return response.data;
  },
  createVideoPost: async (
    id: string,
    socialMediaUserId: string,
    feedData: SocialFacebookVideoPostDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.facebook.createVideoPost(id, socialMediaUserId),
      feedData
    );
    return response.data;
  },

  createReel: async (
    id: string,
    socialMediaUserId: string,
    reelData: SocialFacebookReelDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post(
      ENDPOINTS.smmManagement.facebook.createReel(id, socialMediaUserId),
      reelData
    );
    return response.data;
  },

  createStory: async (
    id: string,
    socialMediaUserId: string,
    storyData: SocialFacebookStoryDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post(
      ENDPOINTS.smmManagement.facebook.createStory(id, socialMediaUserId),
      storyData
    );
    return response.data;
  },

  deletePostById: async (
    id: string,
    socialMediaUserId: string,
    postId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete(
      ENDPOINTS.smmManagement.facebook.deletePostById(
        id,
        socialMediaUserId,
        postId
      )
    );
    return response.data;
  },
};

export default SocialMediaManagementFacebookApi;
