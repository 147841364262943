import { useNavigate } from "react-router-dom";
import { CampaignWOrgDto } from "../../../model/campaign/campaign-w-org.dto";
import { CampaignDto } from "../../../model/campaign/campaign.dto";
import { DateUtil } from "../../../utilities/date";
import StringUtils from "../../../utilities/string.util";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-campaign-card.scss";

interface Props {
  campaign: CampaignDto | CampaignWOrgDto;
  showOrg?: boolean;
  redirectUrl?: string;
}

export const EmpCampaignCard = (props: Props) => {
  // Define the function with a generic type parameter
  function getCampaignWithType<T extends boolean>(
    showOrg: T,
    campaign: T extends true ? CampaignWOrgDto : CampaignDto
  ) {
    return campaign;
  }
  const navigate = useNavigate();
  const showOrg = props.showOrg ?? false;
  const campaign = getCampaignWithType(showOrg, props.campaign);

  const cardOnClick = () => {
    if (!props.redirectUrl) return;
    navigate(props.redirectUrl);
  };

  return (
    <div
      onClick={cardOnClick}
      className="emp-campaign-card"
      style={{
        backgroundImage: `url(${campaign.pictureUrl})`,
      }}
    >
      <div className="campaign-details-wrapper">
        <div className="campaign-name-wrapper">
          <span className="campaign-name-lbl">{campaign.name}</span>
        </div>
        <div className="campaign-info-wrapper">
          <div className="light-content">
            <span className="header">CREATED ON</span>
            <span className="content">
              {DateUtil.toReadableDate(campaign.createdDate)}
            </span>
          </div>
          <div className="light-content">
            <span className="header">LAST UPDATED</span>
            <span className="content">
              {DateUtil.toReadableDate(campaign.updatedDate)}
            </span>
          </div>
        </div>
      </div>
      <div className="card-overlay"></div>
      <div className="top-bar">
        {showOrg && (
          <img
            referrerPolicy="no-referrer"
            className="organisation-img-abs"
            alt={(campaign as CampaignWOrgDto).organisation.companyName}
            src={(campaign as CampaignWOrgDto).organisation.logo}
          />
        )}
        {!showOrg && (
          <EmpPill
            backgroundColor="rgba(0,0,0,0.6)"
            text={StringUtils.convertHyphensToTitleCase(campaign.objective)}
          />
        )}
        {showOrg && (
          <div className="organisation-wrapper">
            <div className="organisation-img-placeholder"></div>
            <div>
              <span className="organisation-name-lbl">
                {(campaign as CampaignWOrgDto).organisation.companyName}{" "}
              </span>
              <span className="objective-lbl">
                {StringUtils.convertHyphensToTitleCase(campaign.objective)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
