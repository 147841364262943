import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ProfileApi from "../../api/user-msvc/profile.api";
import AlertSquareIcon from "../../components/icon/alert-square";
import XCloseIcon from "../../components/icon/x-close-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpException from "../../exception/empException";
import { SaveProfileNameReqDto } from "../../model/profile/save-profile-name-req.dto";
import { Color } from "../../utilities/colors";
import { DateUtil } from "../../utilities/date";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import "./edit-name-confirmation-modal.scss";

export interface EditNameConfirmationModalRef {
  show: (params: RequestParam) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

interface RequestParam {
  companyName: string;
  organisationId: string;
}
export const EditNameConfirmationModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const requestParamsRef = useRef<RequestParam>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (param: RequestParam) => {
    requestParamsRef.current = param;
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    setErrorMessage(undefined);
    props.onSave();
  };

  /**
   * Saves the company name.
   * @async
   * @returns {Promise<void>} A Promise that resolves when the save operation is completed.
   * @throws {EmpException} If unable to save the name.
   */
  const saveName = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!requestParamsRef.current)
        throw new EmpException("Unable to save name");
      const { companyName, organisationId } = requestParamsRef.current;
      const request: SaveProfileNameReqDto = {
        companyName,
      };
      const resp = await ProfileApi.saveCompanyName(organisationId, request);
      if (resp.data.status === "error") {
        const validChangeDate = DateUtil.toReadableDateWithTime(
          resp.data.statusMessage
        );
        const message = `You can only change the organization name once every 30 days. Please wait until ${validChangeDate} before requesting another name change.`;
        setErrorMessage(message);
      } else if (resp.data.status === "success") {
        ToastUtils.success(
          "Updated",
          "Your organisation name has been changed"
        );
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save about name"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-edit-name-confirmation-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <h2 className="title">Change Organisation Name</h2>
          {requestParamsRef.current && (
            <p className="description mt-2">
              Are you sure you want to change the organisation name to{" "}
              <span className="highlighted">
                {requestParamsRef.current.companyName}
              </span>
            </p>
          )}

          {errorMessage && (
            <div className="error-message-wrapper mt-4">
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            isFullWidth={false}
            text={"Save"}
            onSubmit={() => {
              saveName();
            }}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
