import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SelectOption } from "../../../model/common/selectOption";
import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import ChevronDownIcon from "../../icon/chevron-down";
import "./emp-select.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;

  labelText?: string;
  description?: string;
  value?: string;
  placeholder?: string | JSX.Element;
  hidePlaceholder?: boolean;
  initialValue?: string;
  selectOptions: SelectOption[];
  required?: boolean;

  // EmpTextInput Form Integration
  disabled?: boolean;
  id: string;
  formControl: FormControl;
  formValidation?: () => void;

  // Callbacks to the parent component
  onChange?: (formControl: FormControl) => void;
}
const EmpSelect = (props: Props) => {
  const hidePlaceholder = props.hidePlaceholder ?? false;
  const [errorMessage, setErrorMessage] = useState<string>();
  const {
    onChange,
    required,
    selectOptions,
    style,
    className,
    id,
    labelText,
    placeholder,
    formControl,
    description,
  } = props;

  const value = formControl.getValue();
  const isRequired = required ? required : false;
  const disabled = props.disabled ?? false;
  const selectInputRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  useEffect(() => {
    selectInputRef.current!.value = formControl.getValue();
  }, [formControl.resetFlag, selectOptions]);

  useEffect(() => {}, [formControl.value]);

  const handleOnChange = (value: string) => {
    formControl.setValue(value);

    // Propagate this change to the parent component
    if (onChange) onChange(formControl);
  };

  return (
    <div className={`emp-select-wrapper ${className} `} style={style}>
      <label className={`block ${description ? "mb-1" : "mb-2"}`} htmlFor={id}>
        {labelText} {isRequired && <span className="required">*</span>}
      </label>
      {description && <p>{description}</p>}
      <div className="emp-form-control">
        <div className="emp-select-icon">
          <ChevronDownIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
        </div>
        <select
          required
          className={`emp-text-input ${disabled ? "disabled" : ""}`}
          defaultValue={value}
          ref={selectInputRef}
          disabled={disabled}
          // Callbacks
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            handleOnChange(event.target.value);
          }}
        >
          {!hidePlaceholder && (
            <option value="">
              {placeholder && placeholder}
              {!placeholder && (
                <FormattedMessage id="select_defaultPlaceholder" />
              )}
            </option>
          )}
          {selectOptions.map((elem) => {
            return (
              <option key={elem.value} value={elem.value}>
                {elem.label}
              </option>
            );
          })}
        </select>
      </div>
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[400]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
export default EmpSelect;
