import { ENDPOINTS } from "../constants/endpoints";
import { ApiResponse } from "../model/api/api-response";
import { CampaignSearchResultsDto } from "../model/campaign/campaign-search-results.dto";
import { UserSearchResultsDto } from "../model/user/user-search-results.dto";
import { EmpInterceptor } from "./emp-interceptor";

const SearchApi = {
  campaignSearch: async (
    role: string,
    keyword?: string
  ): Promise<ApiResponse<CampaignSearchResultsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = ENDPOINTS.campaignMsvc.search.base(role);
    if (keyword) url += `?keyword=${keyword}`;
    const response =
      await axios.get<ApiResponse<CampaignSearchResultsDto>>(url);
    return response.data;
  },
  userSearch: async (
    role: string,
    keyword?: string
  ): Promise<ApiResponse<UserSearchResultsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = ENDPOINTS.userMicroservice.search.searchUsers(role);
    if (keyword) url += `?keyword=${keyword}`;
    const response = await axios.get<ApiResponse<UserSearchResultsDto>>(url);
    return response.data;
  },
};
export default SearchApi;
