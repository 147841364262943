import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KycApi from "../../api/payment-msvc/kyc.api";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { KYC_PROGRESS } from "../../constants/app.constants";
import {
  accountCurrencyConstants,
  bankCountryCode,
  countryCodes,
} from "../../constants/selectConstants";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { SaveKycPaymentDto } from "../../model/payment/kyc/save-kyc-payment.dto";
import { isAwValidateBeneficiaryErrDto } from "../../model/payment/kyc/aw-validate-beneficiary-err.dto";
import { getAirwallexErrorConstants } from "../../constants/airwallex-error.constants";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../../components/shared/emp-checkbox/emp-checkbox";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;
interface Props {
  kycInfo?: KycDto;
  nextStep: () => void;
}

export const KycCreatorPaymentStep = (props: Props) => {
  const { kycInfo, nextStep } = props;
  const intl = useIntl();
  const [form, setForm] = useState<IFormGroup>();
  const nextBtnRef = useRef<EmpButtonRef>();

  const [hasSubmitted, setSubmitted] = useState(false);
  // Ensures that this component will only be set once
  const prefillFlagRef = useRef(false);

  const airwallexErrorConstants = useMemo(() => {
    return getAirwallexErrorConstants(intl);
  }, [intl]);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());

  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);

  useEffect(() => {
    if (!kycInfo || !form || prefillFlagRef.current) return;

    if (kycInfo.status === "rejected") fetchKycRejection();

    setForm((prevForm: IFormGroup | undefined) => {
      if (!prevForm) return prevForm;

      prevForm.firstName.forceUpdateValue(
        kycInfo?.kycCreatorInfo?.firstName ?? ""
      );
      prevForm.lastName.forceUpdateValue(
        kycInfo?.kycCreatorInfo?.lastName ?? ""
      );

      prevForm.countryCode.forceUpdateValue(
        kycInfo?.kycPayment?.countryCode ?? ""
      );
      prevForm.streetAddress.forceUpdateValue(
        kycInfo?.kycPayment?.streetAddress ?? ""
      );
      prevForm.city.forceUpdateValue(kycInfo?.kycPayment?.city ?? "");
      prevForm.postalCode.forceUpdateValue(
        kycInfo?.kycPayment?.postalCode ?? ""
      );
      prevForm.accountHolderName.forceUpdateValue(
        kycInfo?.kycPayment?.accountHolderName ?? ""
      );
      prevForm.accountCurrency.forceUpdateValue(
        kycInfo?.kycPayment?.accountCurrency ?? ""
      );
      prevForm.accountNumber.forceUpdateValue(
        kycInfo?.kycPayment?.accountNumber ?? ""
      );
      prevForm.bankCountryCode.forceUpdateValue(
        kycInfo?.kycPayment?.bankCountryCode ?? ""
      );
      prevForm.swiftCode.forceUpdateValue(kycInfo?.kycPayment?.swiftCode ?? "");
      prevForm.state.forceUpdateValue(kycInfo?.kycPayment?.state ?? "");
      return { ...prevForm };
    });
    prefillFlagRef.current = true;
  }, [kycInfo, form]);

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        firstName: new FormControl("text", [new RequiredValidator()]),
        lastName: new FormControl("text", [new RequiredValidator()]),
        accountType: new FormControl(
          "text",
          [new RequiredValidator()],
          "PERSONAL"
        ),
        countryCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_countryCodeRequired" })
            ),
          ],
          prevForm?.countryCode.getValue() ?? ""
        ),
        streetAddress: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_streetAddressRequired" })
            ),
            new LengthValidator(
              0,
              200,
              undefined,
              intl.formatMessage(
                { id: "validation_streetAddressMaxLength" },
                { char: 200 }
              )
            ),
          ],
          prevForm?.streetAddress.getValue() ?? ""
        ),
        city: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_cityRequired" })
            ),
          ],
          prevForm?.city.getValue() ?? ""
        ),
        state: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_stateRequired" })
            ),
          ],
          prevForm?.city.getValue() ?? ""
        ),
        postalCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_postalCodeRequired" })
            ),
          ],
          prevForm?.postalCode.getValue() ?? ""
        ),
        accountHolderName: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_accountHolderRequired" })
            ),
            new LengthValidator(
              0,
              200,
              undefined,
              intl.formatMessage(
                { id: "validation_accountHolderMaxLength" },
                { char: 200 }
              )
            ),
          ],
          prevForm?.accountHolderName.getValue() ?? ""
        ),
        accountCurrency: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_accountCurrencyRequired" })
            ),
          ],
          prevForm?.accountCurrency.getValue() ?? ""
        ),
        accountNumber: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_accountNumberRequired" })
            ),
            new PatternValidator(
              /^\d+$/,
              intl.formatMessage({ id: "validation_accountNumberNumber" })
            ),
            new LengthValidator(
              0,
              17,
              undefined,
              intl.formatMessage(
                { id: "validation_accountNumberMaxLength" },
                { char: 17 }
              )
            ),
          ],
          prevForm?.accountNumber.getValue() ?? ""
        ),
        bankCountryCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_bankCountryCodeRequired" })
            ),
          ],
          prevForm?.bankCountryCode.getValue() ?? ""
        ),
        swiftCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_swiftCodeRequired" })
            ),
          ],
          prevForm?.swiftCode.getValue() ?? ""
        ),
      };
      form.firstName.forceUpdateValue(kycInfo?.kycCreatorInfo?.firstName ?? "");
      form.lastName.forceUpdateValue(kycInfo?.kycCreatorInfo?.lastName ?? "");
      if (hasSubmitted) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const onSubmit = async () => {
    try {
      nextBtnRef.current?.setButtonState("loading");
      if (!form) return;
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const request: SaveKycPaymentDto = {
        firstName: form.firstName.getValue(),
        lastName: form.lastName.getValue(),
        accountType: form.accountType.getValue(),
        countryCode: form.countryCode.getValue(),
        streetAddress: form.streetAddress.getValue(),
        postalCode: form.postalCode.getValue(),
        state: form.state.getValue(),
        city: form.city.getValue(),
        accountHolderName: form.accountHolderName.getValue(),
        accountCurrency: form.accountCurrency.getValue(),
        accountNumber: form.accountNumber.getValue(),
        bankCountryCode: form.bankCountryCode.getValue(),
        swiftCode: form.swiftCode.getValue(),
        entityType: "PERSONAL",
      };
      const resp = await KycApi.saveKycProgress(
        KYC_PROGRESS.PAYMENT_INFO.step,
        { details: request }
      );
      if (isAwValidateBeneficiaryErrDto(resp.data)) {
        ToastUtils.error(
          "Some Fields Are Invalid",
          "Please Correct the values"
        );
        for (let error of resp.data.errors) {
          if (!(error.code in airwallexErrorConstants)) continue;

          // Include more error messages when necessaru
          if (error.source === "beneficiary.bank_details.swift_code") {
            form.swiftCode.errorMessage = airwallexErrorConstants[error.code];
            form.swiftCode.hasError = true;
          }
        }
        setForm({ ...form });
      } else if (resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_paymentStep_successToastHeader",
          }),
          intl.formatMessage({
            id: "kycFormSection_paymentStep_successToastDesc",
          })
        );
        nextStep();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save KYC progress"
      );
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="kyc-form">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
      >
        <h2 className="form-header">
          <FormattedMessage id="kycFormSection_paymentStep_header" />
        </h2>
        <p className="emp-paragraph mt-3">
          <FormattedMessage id="kycFormSection_paymentStep_desc" />
        </p>
      </motion.div>
      {form && (
        <div className="mt-6">
          <div className="form-label-wrapper">
            <span>
              <FormattedMessage id="kycFormSection_paymentStep_beneficiaryDetails" />
            </span>
          </div>
          <div className="form-control-wrapper">
            <div className="split-forms">
              <motion.div
                className="split-form-wrapper"
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
              >
                <EmpTextInput
                  id={"firstName"}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_firstNamePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "kycFormSection_firstNameLabel",
                  })}
                  disabled
                  required
                  formControl={form.firstName}
                />
              </motion.div>
              <motion.div
                className="split-form-wrapper"
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
              >
                <EmpTextInput
                  id={"lastName"}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_lastNamePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "kycFormSection_lastNameLabel",
                  })}
                  disabled
                  formControl={form.lastName}
                />
              </motion.div>
            </div>

            <motion.div
              className="split-form-wrapper"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
            >
              <EmpTextInput
                id={"accountType"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_accountTypeLabel",
                })}
                disabled
                formControl={form.accountType}
              />
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
            >
              <EmpSelect
                id={"countryCode"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_countryCodeLabel",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_countryCodeDesc",
                })}
                required
                placeholder={intl.formatMessage({
                  id: "kycFormSection_countryCodePlaceholder",
                })}
                onChange={formOnChange}
                formControl={form.countryCode}
                selectOptions={countryCodes}
              />
              {fieldDisputeMap.has("kycPayment_countryCode") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_countryCode")!.message}
                  </p>
                </div>
              )}
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 3 }}
            >
              <EmpTextInput
                id={"streetAddress"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_streetAddressLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_streetAddressPlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_streetAddressDesc2",
                })}
                multiline
                rows={2}
                textAreaAdaptiveHeight
                required
                onChange={formOnChange}
                formControl={form.streetAddress}
              />
              {fieldDisputeMap.has("kycPayment_streetAddress") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_streetAddress")!.message}
                  </p>
                </div>
              )}
            </motion.div>
            <div className="split-forms">
              <motion.div
                className="split-form-wrapper"
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: ANIM_DELAY * 4 }}
              >
                <EmpTextInput
                  id={"city"}
                  labelText={intl.formatMessage({
                    id: "kycFormSection_cityLabel",
                  })}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_cityPlaceholder",
                  })}
                  required
                  formControl={form.city}
                />
                {fieldDisputeMap.has("kycPayment_city") && (
                  <div className="dispute-box">
                    <span className="reviewer-comment">
                      <FormattedMessage id="kycFormSection_reviewerComment" />
                    </span>
                    <p className="comment">
                      {fieldDisputeMap.get("kycPayment_city")!.message}
                    </p>
                  </div>
                )}
              </motion.div>
              <motion.div
                className="split-form-wrapper"
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: ANIM_DELAY * 4 }}
              >
                <EmpTextInput
                  id={"state"}
                  labelText={intl.formatMessage({
                    id: "kycFormSection_stateLabel",
                  })}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_statePlaceholder",
                  })}
                  required
                  formControl={form.state}
                />
                {fieldDisputeMap.has("kycPayment_state") && (
                  <div className="dispute-box">
                    <span className="reviewer-comment">
                      <FormattedMessage id="kycFormSection_state" />
                    </span>
                    <p className="comment">
                      {fieldDisputeMap.get("kycPayment_state")!.message}
                    </p>
                  </div>
                )}
              </motion.div>
            </div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 3 }}
            >
              <EmpTextInput
                id={"postalCode"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_postalCodeLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_postalCodePlaceholder",
                })}
                required
                formControl={form.postalCode}
              />
              {fieldDisputeMap.has("kycPayment_postalCode") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_postalCode")!.message}
                  </p>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      )}

      {form && (
        <div className="mt-3">
          <div className="form-label-wrapper">
            <span>
              <FormattedMessage id="kycFormSection_paymentStep_bankAccountDetails" />
            </span>
          </div>
          <div className="form-control-wrapper">
            <motion.div
              style={{ zIndex: 2 }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 5 }}
            >
              <EmpSelect
                id={"accountCurrency"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_accountCurrencyLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_accountCurrencyPlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_accountCurrencyDesc",
                })}
                required
                onChange={formOnChange}
                formControl={form.accountCurrency}
                selectOptions={accountCurrencyConstants}
              />
              {fieldDisputeMap.has("kycPayment_accountCurrency") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_accountCurrency")!.message}
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              style={{ zIndex: 2 }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 6 }}
            >
              <EmpTextInput
                id={"accountHolderName"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_accountHolderLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_accountHolderPlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_accountHolderDesc",
                })}
                required
                onChange={formOnChange}
                formControl={form.accountHolderName}
              />
              {fieldDisputeMap.has("kycPayment_accountHolderName") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get("kycPayment_accountHolderName")!
                        .message
                    }
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              style={{ zIndex: 2 }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 7 }}
            >
              <EmpTextInput
                labelText={intl.formatMessage({
                  id: "kycFormSection_accountNumberLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_accountNumberPlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_accountNumberDesc",
                })}
                required
                onChange={formOnChange}
                formControl={form.accountNumber}
                id={"accountNumber"}
              />
              {fieldDisputeMap.has("kycPayment_accountNumber") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_accountNumber")!.message}
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              style={{ zIndex: 2 }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 8 }}
            >
              <EmpSelect
                id={"bankCountryCode"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_bankCountryCodeLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_bankCountryCodePlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_bankCountryCodeDesc",
                })}
                required
                onChange={formOnChange}
                selectOptions={bankCountryCode}
                formControl={form.bankCountryCode}
              />
              {fieldDisputeMap.has("kycPayment_bankCountryCode") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_bankCountryCode")!.message}
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              style={{ zIndex: 2 }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
            >
              <EmpTextInput
                id={"swiftCode"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_swiftCodeLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_swiftCodePlaceholder",
                })}
                description={intl.formatMessage({
                  id: "kycFormSection_swiftCodeDesc",
                })}
                required
                tooltip={
                  <>
                    <span>
                      <b>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipHeader1" />
                      </b>
                    </span>
                    <ol>
                      <li>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipPoint1" />
                      </li>
                      <li>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipPoint2" />
                      </li>
                      <li>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipPoint3" />
                      </li>
                      <li>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipPoint4" />
                      </li>
                    </ol>
                    <span>
                      <b>
                        <FormattedMessage id="kycFormSection_swiftCodeTooltipHeader2" />
                      </b>
                    </span>
                    <p>
                      <FormattedMessage id="kycFormSection_swiftCodeTooltipBody2" />
                    </p>
                  </>
                }
                onChange={formOnChange}
                formControl={form.swiftCode}
              />
              {fieldDisputeMap.has("kycPayment_swiftCode") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycPayment_swiftCode")!.message}
                  </p>
                </div>
              )}
            </motion.div>

            <div className="btn-wrapper">
              <EmpButton
                ref={nextBtnRef}
                isFullWidth={false}
                onSubmit={onSubmit}
                text={<FormattedMessage id="cta_submit" />}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
