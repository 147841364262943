import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { CampaignAnalyticsSummaryDto } from "../../model/campaign/campaign-analytics-summary.dto";
import { EmpInterceptor } from "../emp-interceptor";

const CampaignAnalyticsSummaryApi = {
  fetch: async (
    taskId: string,
    role: "brand" | "seller"
  ): Promise<ApiResponse<CampaignAnalyticsSummaryDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CampaignAnalyticsSummaryDto>>(
      ENDPOINTS.campaignMsvc.campaignAnalyticsSummary.fetchSummary(taskId, role)
    );
    return response.data;
  },
};
export default CampaignAnalyticsSummaryApi;
