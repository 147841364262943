import { PUB_SUB_TOPICS } from "../constants/pubSubTopics";
import PubSub from "pubsub-js";
import { UserDto } from "../model/user-management/user.dto";
import UserApi from "../api/user-msvc/user.api";
import { OrganisationRespDto } from "../model/user/organisation-resp.dto";
import EmpException from "../exception/empException";

/**
 * Custom implemented service to display alert toast when an event is triggered.
 */
const UserUtils = {
  updateUser: (): void => {
    PubSub.publish(PUB_SUB_TOPICS.USER);
  },

  fetchUser: async (userContext: UserDto | null): Promise<UserDto> => {
    if (userContext) return userContext as UserDto;
    const resp = await UserApi.fetchUser();
    return resp.data;
  },

  fetchOrganisation: async (
    userContext: UserDto | null
  ): Promise<OrganisationRespDto> => {
    if (
      userContext &&
      userContext?.organisation &&
      userContext?.organisation?.length > 0
    ) {
      return userContext.organisation[0];
    }
    const resp = await UserApi.fetchUser();
    const user = resp.data;
    if (user?.organisation && user?.organisation?.length > 0)
      return user.organisation[0];
    else throw new EmpException("No organisation found");
  },
};
export default UserUtils;
