export const USER_ROLES = {
  BRAND: "brand",
  AGENCY: "agency",
  ORG_ADMIN: "org-admin",
  ORG_MEMBER: "org-member",
  ORG_MAINTAINER: "org-maintainer",
  TALENT: "talent",
};

export const AUTH_ERR_CODES = {
  EMAIL_NOT_WHITELISTED: "AUTH001",
  USER_EXIST: "AUTH002",
};
export const PLATFORM_ROLES = {
  AGENCY: "agency",
  BRAND: "brand",
  CREATOR: "creator",
};
export const AUTH_CUSTOM_STATES = {
  CREATOR_SSO: "creator-sso",
  MANAGED_CREATOR_SSO: "managed-creator-sso",
  ORG_INVITATION_SSO: "org-invitation-sso",
  BRAND_SSO: "brand-sso",
  AGENCY_SSO: "agency-sso",
  DEFAULT_SSO: "default-sso",
};
export const EMPTY_PROFILE_IMG_URL =
  "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png";
export const TASK = {
  PAYMENT_INFO: 1,
  COMPLETE_PROFILE: 2,
  TEAM_ACCOUNTS: 3,
  INVITE_CREATORS: 4,
  BASIC_INFO: 5,
};
export const CAMPAIGN_ACCESS_CONTROL = {
  SUCCESS: "CA_000",
  CREATOR_HAS_EXCLUSIVE: "CA_001",
  MISMATCH_REPRESENTATIVE: "CA_002",
  CREATOR_HAS_PARTNERSHIP: "CA_003",
};

export const X_MEDIA_TYPE = {
  PHOTO: "photo",
  VIDEO: "video",
};

export const ISSUER_RESPONSE_CODE = {
  SUSPECTED_FRAUD: "ERR_59",
  PICK_UP_CARD: "ERR_07",
  DO_NOT_HONOR: "ERR_05",
  INVALID_CARD: "ERR_14",
  INSUFFICIENT_FUNDS: "ERR_51",
  ISSUER_DECLINED: "ERR_ISSUER_DECLINED",
  RISK_DECLINED: "ERR_RISK_DECLINED",
  GENERIC_ERROR: "ERR_GENERIC",
};

export const CAMPAIGN_RESOURCE_TYPE = {
  CAMPAIGN_TASK_LISTING: "campaign-task-listing",
  ONGOING_TASK: "ongoing-task",
  ONGOING_TASK_CHAT: "ongoing-task-chat",
  ONGOING_TASK_PAYMENT: "ongoing-task-payment",
};
export const INVITATION_TYPE = {
  AFFILIATE: "affiliate-invitation",
  EXCLUSIVE: "exclusive-invitation",
};

export const SOCIAL_MEDIA_TYPE = {
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  TIKTOK: "TikTok",
  X: "X",
};

export const SM_REDIRECT = {
  CREATOR_HOME: "creator-home",
  CREATOR_ONBOARD: "creator-onboard",
  CREATOR_KYC: "creator-kyc",
  BRAND_SMM: "brand-smm",
  SELLER_PROOF_OF_WORK: "seller-proof-of-work",
};

export const TASK_TYPE = {
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  TIKTOK: "TikTok",
  X: "X",
  GENERAL: "General",
};

export const NOTIFICATION_RECORD = {
  AGENCY_INVITATION: "agency-invitation",
  CREATOR_INVITATION_RESPONSE: "creator-invitation-response",
  GENERIC_MESSAGE: "generic-message",
  RECRUITING_TASK_INVITATION: "task-invitation",
  RECRUITING_TASK_APPLICATION: "task-application",
  RECRUITING_TASK_COUNTEROFFER: "task-counteroffer",
  RECRUITING_TASK_ACCEPTANCE: "task-acceptance",
  RECRUITING_TASK_REJECTION: "task-rejection",
  RECRUITING_TASK_START: "task-start",
  RECRUITING_REVIEW_DELIVERABLES: "review-deliverable",

  ONGOING_TASK_NEW_DRAFT: "new-draft",
  ONGOING_TASK_UPDATE_DRAFT: "update-draft",
  ONGOING_TASK_DRAFT_ACCEPTANCE: "draft-acceptance",
  ONGOING_TASK_DRAFT_REJECTION: "draft-rejection",
  ONGOING_TASK_NEW_EVIDENCE: "new-evidence",
  ONGOING_TASK_UPDATE_EVIDENCE: "update-evidence",
  ONGOING_TASK_BRAND_PAYOUT_ELIGIBLE: "brand-payout-eligible",
  ONGOING_TASK_SELLER_PAYOUT_ELIGIBLE: "seller-payout-eligible",
  TASK_CHAT: "task-chat",
  DELETE_TASK: "delete-task",
  RATE_CARD_REQUEST: "rate-card-request",
  RATE_CARD_RESPONSE: "rate-card-response",
  KYC: "kyc",

  SMM_POST_PUBLISHED: "post-published",
};

export const AGENCY_PRIVACY_SETTING_NAME = {
  RATE_PRIVACY: "ratePrivacy",
};

export const NEGOTIATION_STATUS = {
  INVITED: "invited",
  APPLIED: "applied",
  REVIEW_DELIVERABLES: "review-deliverables",
  SELLER_NEGOTIATE: "seller-counteroffer",
  BRAND_NEGOTIATE: "brand-counteroffer",
  // ACCEPTED: "accepted",
  BRAND_ACCEPT: "brand-accepted",
  SELLER_ACCEPT: "seller-accepted",
  BRAND_REJECT: "brand-rejected",
  SELLER_REJECT: "seller-rejected",
  DUP_REJECT: "dup-rejected",
};

export const TASK_STATUS = {
  RECRUITING: "recruiting",
  ONGOING: "ongoing",
  COMPLETED: "completed",
  DELETED: "deleted",
};

export const ATTACHMENT_TYPE = {
  IMAGES: "images",
  FILE: "file",
  IMAGE: "image",
  VIDEO: "video",
  NONE: "none",
};

export const DELIVERABLE_STATUS = {
  PENDING_DRAFT: "pending-draft",
  IN_REVIEW: "in-review",
  APPROVED: "approved",
  DISPUTE: "dispute",
  COMPLETED: "completed",
};

export const FILE_TYPE = {
  IMAGES: "images",
  IMAGE: "image",
  VIDEO: "video",
  FILE: "file",
};

export const DECISION = {
  APPROVE: "approve",
  REJECT: "reject",
};

export const EVIDENCE_TYPE = {
  ATTACHMENT: "attachment",
  SOCIAL_MEDIA_IMAGE: "sm-image",
  SOCIAL_MEDIA_LINK: "sm-link",
};
export const EVIDENCE_TYPE_DISPLAY = {
  ATTACHMENT: "File Attachment",
  SOCIAL_MEDIA_IMAGE: "Screenshot Submission",
  SOCIAL_MEDIA_LINK: "Platform API Submission",
};

export const PROOF_OF_WORK_MEDIA_TYPE = {
  INSTAGRAM_STORY_PICTURE: "instagram-story-picture",
  INSTAGRAM_STORY_VIDEO: "instagram-story-video",
  INSTAGRAM_FEED_PICTURE: "instagram-feed-picture",
  INSTAGRAM_FEED_VIDEO: "instagram-feed-video",
  FACEBOOK_PICTURE: "facebook-picture",
  FACEBOOK_TEXT: "facebook-text",
  FACEBOOK_VIDEO: "facebook-video",
  TIKTOK_VIDEO: "tiktok-video",
  X_TWEET: "x-tweet",
};

export const KYC_PROGRESS = {
  ORG_BASIC_INFO: {
    id: 1,
    step: "org-basic-info",
  },
  ORG_SHAREHOLDER_INFO: {
    id: 2,
    step: "org-shareholder-info",
  },
  PAYMENT_INFO: {
    id: 3,
    step: "payment-info",
  },
  CREATOR_PROFESSIONAL_DETAILS: {
    id: 4,
    step: "creator-professional-details",
  },
  CREATOR_PERSONAL_INFO: {
    id: 5,
    step: "creator-personal-info",
  },
};

export const TASK_PAYOUT_STATUS = {
  AWAITING_COMPLETION: "AWAITING-COMPLETION",
  OBSERVATION_WINDOW: "OBSERVATION-WINDOW",
  PAYOUT_COMPLETED: "PAYOUT-COMPLETED",
  PAYOUT_FAILED: "PAYOUT-FAILED",
  PAYOUT_REFUNDED: "PAYOUT-REFUNDED",
};

export const GENERAL_TASK_MEDIA_TYPE = {
  INSTAGRAM_STORY: "instagram-story",
  INSTAGRAM_POST: "instagram-post",
  TIKTOK_VIDEO: "tiktok-video",
  FACEBOOK_POST: "facebook-post",
  X_TWEET: "x-tweet",
};

export const X_TWEET_FETCH_ORIGIN = {
  PROOF_OF_WORK_SELECTION: "pow-selection",
};
