import "./configure-payouts-alert-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

export interface ConfigurePayoutsAlertModaRef {
  show: () => void;
  dismiss: () => void;
}

interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const ConfigurePayoutsAlertModal = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const userRoleRef = useRef<string>();
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async () => {
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
  };

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"xs"}
    >
      {/* This is body */}
      <div>
        <div className="emp-configure-payouts-modal">
          <motion.img
            variants={fadeInVariants}
            initial="hidden"
            animate={"visible"}
            alt="Bank"
            className="bank-img"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/bank.png"
            transition={{ duration: 0.2, delay: 0 * animationDelay }}
          />

          <div className="content-section">
            <motion.span
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 1 * animationDelay,
              }}
              className="invitation-failed-lbl"
            >
              You'll need to configure your bank payouts first!
            </motion.span>
            <motion.span
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 2 * animationDelay,
              }}
              className="heading mt-3"
            >
              Before you can accept or undertake any campaign tasks, it is
              mandatory to complete our Know Your Customer (KYC) process and
              link your bank account for receiving payouts.
            </motion.span>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              className="button-wrapper mt-5"
              transition={{
                duration: animationDuration,
                delay: 4 * animationDelay,
              }}
            >
              <EmpButton
                onSubmit={() => {
                  navigate(`/${userRoleRef.current}/settings#payment`);
                }}
                text={"Take me there!"}
              />
              <EmpButton
                className="mt-3"
                buttonStyle="secondary"
                onSubmit={() => {
                  dismiss();
                }}
                text={"Cancel"}
              />
            </motion.div>
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ConfigurePayoutsAlertModal;
