import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KycApi from "../../api/payment-msvc/kyc.api";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpCheckboxGroup from "../../components/shared/emp-checkbox-group/emp-checkbox-group";
import EmpCountrySelect from "../../components/shared/emp-country-select/emp-country-select";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { KYC_PROGRESS } from "../../constants/app.constants";
import {
  getBusinessTypeOptions,
  getYesNoOptions,
  industryOptions,
} from "../../constants/selectConstants";
import EmpException from "../../exception/empException";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { SaveKycOrgBasicInfoDto } from "../../model/payment/kyc/save-kyc-org-basic-info.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;

interface Props {
  kycInfo?: KycDto;
  nextStep: () => void;
}

export const KycOrgBasicInfoStep = (props: Props) => {
  const { kycInfo, nextStep } = props;
  const intl = useIntl();
  const [form, setForm] = useState<IFormGroup>();

  const [checkboxForm, setCheckboxForm] = useState<IFormGroup>({
    sameCountry: new FormControl("text", [], ["sameCountry"]),
    sameCompanyReg: new FormControl("text", [], ["sameCompanyReg"]),
  });

  const [sgForm, setSgForm] = useState<IFormGroup>();

  const nextBtnRef = useRef<EmpButtonRef>();
  const hasSubmittedRef = useRef(false);

  // Ensures that this component will only be set once
  const prefillFlagRef = useRef(false);
  const [showSingaporeObligations, setShowSingaporeObligations] =
    useState(false);
  const [showCountryTaxResidence, setShowCountryTaxResidence] = useState(false);
  const [showTaxId, setShowTaxId] = useState(false);

  const businessTypeOptions = useMemo(() => {
    return getBusinessTypeOptions(intl);
  }, [intl]);
  const yesNoOptions = useMemo(() => {
    return getYesNoOptions(intl);
  }, [intl]);

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());

  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    if (!kycInfo || !form || prefillFlagRef.current) return;
    if (kycInfo.status === "rejected") fetchKycRejection();

    if (kycInfo.kycBusinessInfo?.country === "sg") {
      setShowSingaporeObligations(true);
      setSgForm((prevForm: IFormGroup | undefined) => {
        if (!prevForm) return prevForm;
        const hasDonations =
          kycInfo.kycBusinessInfo?.singaporeObligations?.hasDonations;
        const hasRewardsCrowdfunding =
          kycInfo.kycBusinessInfo?.singaporeObligations?.hasRewardsCrowdfunding;
        const hasFinancialServices =
          kycInfo.kycBusinessInfo?.singaporeObligations?.hasFinancialServices;
        prevForm.hasDonations.forceUpdateValue(
          hasDonations === undefined ? "" : hasDonations ? "Y" : "N"
        );
        prevForm.hasRewardsCrowdfunding.forceUpdateValue(
          hasRewardsCrowdfunding === undefined
            ? ""
            : hasRewardsCrowdfunding
              ? "Y"
              : "N"
        );
        prevForm.hasFinancialServices.forceUpdateValue(
          hasFinancialServices === undefined
            ? ""
            : hasFinancialServices
              ? "Y"
              : "N"
        );
        return { ...prevForm };
      });
    }
    // Hide the checkboxes
    if (
      kycInfo?.kycBusinessInfo?.country !==
      kycInfo?.kycBusinessInfo?.taxResidence
    ) {
      checkboxForm.sameCountry.forceUpdateValue([]);
      setShowCountryTaxResidence(true);
    }
    if (
      kycInfo?.kycBusinessInfo?.companyRegistrationNumber !==
      kycInfo?.kycBusinessInfo?.taxId
    ) {
      checkboxForm.sameCompanyReg.forceUpdateValue([]);
      setShowTaxId(true);
    }
    setForm((prevForm: IFormGroup | undefined) => {
      if (!prevForm) return prevForm;
      prevForm.companyRegistrationNumber.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.companyRegistrationNumber ?? ""
      );
      prevForm.legalBusinessName.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.legalBusinessName ?? ""
      );
      prevForm.businessType.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.businessType ?? ""
      );
      prevForm.industry.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.industry ?? ""
      );
      prevForm.businessWebsite.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.businessWebsite ?? ""
      );
      prevForm.taxId.forceUpdateValue(kycInfo?.kycBusinessInfo?.taxId ?? "");
      prevForm.country.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.country ?? ""
      );
      prevForm.taxResidence.forceUpdateValue(
        kycInfo?.kycBusinessInfo?.taxResidence ?? ""
      );
      return { ...prevForm };
    });
    prefillFlagRef.current = true;
  }, [kycInfo, form]);

  useEffect(() => {
    setSgForm((prevForm: IFormGroup | undefined) => {
      const sgForm = {
        hasDonations: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_optionRequired" })
            ),
          ],
          prevForm?.hasDonations.getValue() ?? ""
        ),
        hasRewardsCrowdfunding: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_optionRequired" })
            ),
          ],
          prevForm?.hasRewardsCrowdfunding.getValue() ?? ""
        ),
        hasFinancialServices: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_optionRequired" })
            ),
          ],
          prevForm?.hasFinancialServices.getValue() ?? ""
        ),
      };
      if (hasSubmittedRef.current) FormGroupUtil.validate(sgForm);
      return sgForm;
    });
  }, [intl]);

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        country: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_countryRequired" })
            ),
          ],
          prevForm?.country.getValue() ?? ""
        ),
        companyRegistrationNumber: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({
                id: "validation_companyRegistrationRequired",
              })
            ),
            new LengthValidator(
              0,
              50,
              undefined,
              intl.formatMessage(
                { id: "validation_companyRegistrationMaxLength" },
                { char: 50 }
              )
            ),
          ],
          prevForm?.companyRegistrationNumber.getValue() ?? ""
        ),
        legalBusinessName: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_legalBusinessNameRequired" })
            ),
            new LengthValidator(
              0,
              80,
              undefined,
              intl.formatMessage(
                { id: "validation_legalBusinessNameMaxLength" },
                { char: 80 }
              )
            ),
          ],
          prevForm?.legalBusinessName.getValue() ?? ""
        ),
        businessType: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_businessTypeRequired" })
            ),
          ],
          prevForm?.businessType.getValue() ?? ""
        ),
        industry: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_industryRequired" })
            ),
          ],
          prevForm?.industry.getValue() ?? ""
        ),
        businessWebsite: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_websiteRequired" })
            ),
            new PatternValidator(
              /^(?:https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.+~#?&//=]*)$/,
              intl.formatMessage({ id: "validation_websiteInvalid" })
            ),
            new LengthValidator(
              0,
              80,
              undefined,
              intl.formatMessage(
                { id: "validation_websiteMaxLength" },
                { char: 80 }
              )
            ),
          ],
          prevForm?.businessWebsite.getValue() ?? ""
        ),
        taxResidence: new FormControl(
          "text",
          [],
          prevForm?.taxResidence.getValue() ?? ""
        ),
        taxId: new FormControl("text", [], prevForm?.taxId.getValue() ?? ""),
      };
      if (hasSubmittedRef.current) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmittedRef.current) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const onSubmit = async () => {
    try {
      nextBtnRef.current?.setButtonState("loading");
      if (!form) return;
      hasSubmittedRef.current = true;

      let isValid = true;
      let singaporeObligations = undefined;
      if (showSingaporeObligations) {
        if (!sgForm) throw new EmpException("sg form not found");
        const sgFormIsValid = FormGroupUtil.validate(sgForm);
        setSgForm({ ...sgForm });
        if (!sgFormIsValid) isValid = false;

        singaporeObligations = {
          hasDonations: sgForm.hasDonations.getValue(),
          hasRewardsCrowdfunding: sgForm.hasRewardsCrowdfunding.getValue(),
          hasFinancialServices: sgForm.hasFinancialServices.getValue(),
        };
      }

      if (checkboxForm.sameCountry.getValue().length === 0) {
        if (form.taxResidence.getValue().trim().length === 0) {
          form.taxResidence.errorMessage = intl.formatMessage({
            id: "validation_taxResidenceRequired",
          });
          form.taxResidence.hasError = true;
          isValid = false;
        } else {
          form.taxResidence.errorMessage = "";
          form.taxResidence.hasError = false;
        }
      }
      if (checkboxForm.sameCompanyReg.getValue().length === 0) {
        if (form.taxId.getValue().trim().length === 0) {
          form.taxId.errorMessage = intl.formatMessage({
            id: "validation_taxIdRequired",
          });
          form.taxId.hasError = true;
          isValid = false;
        } else {
          form.taxId.errorMessage = "";
          form.taxId.hasError = false;
        }
      }

      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!formIsValid) isValid = false;
      if (!isValid) return;

      const request: SaveKycOrgBasicInfoDto = {
        country: form.country.getValue(),
        companyRegistrationNumber: form.companyRegistrationNumber.getValue(),
        legalBusinessName: form.legalBusinessName.getValue(),
        businessType: form.businessType.getValue(),
        industry: form.industry.getValue(),
        businessWebsite: form.businessWebsite.getValue(),
        taxId:
          checkboxForm.sameCompanyReg.getValue().length > 0
            ? form.companyRegistrationNumber.getValue()
            : form.taxId.getValue(),
        taxResidence:
          checkboxForm.sameCountry.getValue().length > 0
            ? form.country.getValue()
            : form.taxResidence.getValue(),
        singaporeObligations,
      };

      const resp = await KycApi.saveKycProgress(
        KYC_PROGRESS.ORG_BASIC_INFO.step,
        { details: request }
      );

      if (isApiStatus(resp.data) && resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_basicInfo_successToastHeader",
          }),
          intl.formatMessage({
            id: "kycFormSection_basicInfo_successToastDesc",
          })
        );
        nextStep();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save KYC progress"
      );
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="kyc-form">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
      >
        <h2 className="form-header">
          <FormattedMessage id="kycFormSection_basicInfo_header" />
        </h2>
        <p className="emp-paragraph mt-3">
          <FormattedMessage id="kycFormSection_basicInfo_desc" />
        </p>
      </motion.div>

      {form && (
        <div className="form-control-wrapper mt-6">
          <motion.div
            style={{ zIndex: 1 }}
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
          ></motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 3 }}
          >
            <EmpTextInput
              id={"legalBusinessName"}
              labelText={intl.formatMessage({
                id: "kycFormSection_legalBusinessNameLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_legalBusinessNamePlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.legalBusinessName}
            />

            {fieldDisputeMap.has("kycBusinessInfo_legalBusinessName") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get("kycBusinessInfo_legalBusinessName")!
                      .message
                  }
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 4 }}
          >
            <EmpSelect
              id={"businessType"}
              required
              labelText={intl.formatMessage({
                id: "kycFormSection_businessTypeLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_businessTypePlaceholder",
              })}
              onChange={formOnChange}
              formControl={form.businessType}
              selectOptions={businessTypeOptions}
            />

            {fieldDisputeMap.has("kycBusinessInfo_businessType") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycBusinessInfo_businessType")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 5 }}
          >
            <EmpSelect
              id={"industry"}
              labelText={intl.formatMessage({
                id: "kycFormSection_industryLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_industryPlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.industry}
              selectOptions={industryOptions}
            />

            {fieldDisputeMap.has("kycBusinessInfo_industry") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycBusinessInfo_industry")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 6 }}
          >
            <EmpTextInput
              id={"businessWebsite"}
              labelText={intl.formatMessage({
                id: "kycFormSection_businessWebsiteLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_businessWebsitePlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.businessWebsite}
            />

            {fieldDisputeMap.has("kycBusinessInfo_businessWebsite") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get("kycBusinessInfo_businessWebsite")!
                      .message
                  }
                </p>
              </div>
            )}
          </motion.div>
        </div>
      )}
      {form && (
        <div className="mt-2 form-control-wrapper">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 7 }}
          >
            <EmpTextInput
              id={"companyRegistrationNumber"}
              description={intl.formatMessage({
                id: "kycFormSection_companyRegNoDesc",
              })}
              labelText={intl.formatMessage({
                id: "kycFormSection_companyRegNoLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_companyRegNoPlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.companyRegistrationNumber}
            />
            {fieldDisputeMap.has(
              "kycBusinessInfo_companyRegistrationNumber"
            ) && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get(
                      "kycBusinessInfo_companyRegistrationNumber"
                    )!.message
                  }
                </p>
              </div>
            )}
          </motion.div>
          <motion.div
            style={{ zIndex: 1 }}
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 8 }}
          >
            <EmpCountrySelect
              description={intl.formatMessage({
                id: "kycFormSection_countryDesc",
              })}
              labelText={intl.formatMessage({
                id: "kycFormSection_countryLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_countryPlaceholder",
              })}
              required
              intl={intl}
              id={"country"}
              formControl={form.country}
              onChange={(formControl) => {
                formOnChange(formControl);
                setShowSingaporeObligations(formControl.getValue() === "sg");
              }}
            />

            {fieldDisputeMap.has("kycBusinessInfo_country") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycBusinessInfo_country")!.message}
                </p>
              </div>
            )}
          </motion.div>
        </div>
      )}

      {showSingaporeObligations && sgForm && (
        <div className="mt-3 form-subsection">
          <div className="form-label-wrapper">
            <span>
              <FormattedMessage id="kycFormSection_basicInfo_sgObligations" />
            </span>
          </div>
          <div className="form-control-wrapper">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
            >
              <EmpSelect
                id={"hasDonations"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_acceptDonationsLabel",
                })}
                required
                placeholder={intl.formatMessage({
                  id: "kycFormSection_yesNoPlaceholder",
                })}
                onChange={formOnChange}
                formControl={sgForm.hasDonations}
                selectOptions={yesNoOptions}
              />

              {fieldDisputeMap.has("kycBusinessInfo_hasDonations") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get("kycBusinessInfo_hasDonations")!
                        .message
                    }
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
            >
              <EmpSelect
                id={"industry"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_rewardsBasedCrowdfundingLabel",
                })}
                required
                placeholder={intl.formatMessage({
                  id: "kycFormSection_yesNoPlaceholder",
                })}
                onChange={formOnChange}
                formControl={sgForm.hasRewardsCrowdfunding}
                selectOptions={yesNoOptions}
              />
              {fieldDisputeMap.has(
                "kycBusinessInfo_hasRewardsCrowdfunding"
              ) && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get(
                        "kycBusinessInfo_hasRewardsCrowdfunding"
                      )!.message
                    }
                  </p>
                </div>
              )}
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
            >
              <EmpSelect
                id={"industry"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_financialServiceLabel",
                })}
                required
                placeholder={intl.formatMessage({
                  id: "kycFormSection_yesNoPlaceholder",
                })}
                onChange={formOnChange}
                formControl={sgForm.hasFinancialServices}
                selectOptions={yesNoOptions}
              />
              {fieldDisputeMap.has("kycBusinessInfo_hasFinancialServices") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get(
                        "kycBusinessInfo_hasFinancialServices"
                      )!.message
                    }
                  </p>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      )}

      {form && (
        <div className="form-subsection">
          <div className="form-label-wrapper">
            <span>
              <FormattedMessage id="kycFormSection_basicInfo_taxInfo" />
            </span>
          </div>
          <div className="mt-2 form-control-wrapper">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
            >
              <EmpCheckboxGroup
                id={"sameCountry"}
                onChange={() => {
                  setShowCountryTaxResidence(!showCountryTaxResidence);
                }}
                formControl={checkboxForm.sameCountry}
                checkboxOptions={[
                  {
                    label: intl.formatMessage({
                      id: "kycFormSection_taxResidenceCheck",
                    }),
                    value: "sameCountry",
                  },
                ]}
              />
              {showCountryTaxResidence && (
                <EmpCountrySelect
                  labelText={intl.formatMessage({
                    id: "kycFormSection_taxResidenceLabel",
                  })}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_taxResidencePlaceholder",
                  })}
                  required
                  intl={intl}
                  id={"tax-residence"}
                  formControl={form.taxResidence}
                  onChange={(formControl) => {
                    formOnChange(formControl);
                  }}
                />
              )}

              {fieldDisputeMap.has("kycBusinessInfo_taxResidence") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get("kycBusinessInfo_taxResidence")!
                        .message
                    }
                  </p>
                </div>
              )}
              <EmpCheckboxGroup
                id={"sameCompanyReg"}
                onChange={() => {
                  setShowTaxId(!showTaxId);
                }}
                formControl={checkboxForm.sameCompanyReg}
                checkboxOptions={[
                  {
                    label: intl.formatMessage({
                      id: "kycFormSection_taxIdCheck",
                    }),
                    value: "sameCompanyReg",
                  },
                ]}
              />

              {showTaxId && (
                <EmpTextInput
                  id={"taxId"}
                  description={intl.formatMessage({
                    id: "kycFormSection_taxIdDesc",
                  })}
                  labelText={intl.formatMessage({
                    id: "kycFormSection_taxIdLabel",
                  })}
                  placeholder={intl.formatMessage({
                    id: "kycFormSection_taxIdPlaceholder",
                  })}
                  required
                  onChange={formOnChange}
                  formControl={form.taxId}
                />
              )}
              {fieldDisputeMap.has("kycBusinessInfo_taxId") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycBusinessInfo_taxId")!.message}
                  </p>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      )}

      <div className="btn-wrapper mt-2 ph-4">
        <EmpButton
          ref={nextBtnRef}
          isFullWidth={false}
          onSubmit={onSubmit}
          text={<FormattedMessage id="cta_nextStep" />}
        />
      </div>
    </div>
  );
};
