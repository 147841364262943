import { IntlShape } from "react-intl";
import { SelectOptionWithHtml } from "../model/common/select-option-with-html";
import { SelectOption } from "../model/common/selectOption";
import { Color } from "../utilities/colors";
import { GENERAL_TASK_MEDIA_TYPE, SOCIAL_MEDIA_TYPE } from "./app.constants";
import { COUNTRY_CONSTANTS } from "./countries.contants";
import FacebookIcon from "../components/icon/facebook-icon";
import InstagramIcon from "../components/icon/instagram-icon";
import TikTokIcon from "../components/icon/tiktok-icon";
import XIcon from "../components/icon/x-icon";

export const userRoleOptions: SelectOption[] = [
  { label: "Talent Agencies", value: "agency" },
  { label: "Talent", value: "talent" },
  { label: "Enterprise", value: "brand" },
];

export const taskStatusOptions: SelectOption[] = [
  { label: "Recruiting", value: "recruiting" },
  { label: "Ongoing", value: "ongoing" },
];

export const getCreatorSearchOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({
      id: "exploreCreatorsView_searchOptions_searchByNameOption",
    }),
    value: "search-by-name",
  },
  {
    label: intl.formatMessage({
      id: "exploreCreatorsView_searchOptions_searchByHandleOption",
    }),
    value: "search-by-handle",
  },
];

export const getEvidenceTypeOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({ id: "evidenceType_smLinkOption" }),
    value: "sm-link",
  },
  {
    label: intl.formatMessage({ id: "evidenceType_smImageOption" }),
    value: "sm-image",
  },
  {
    label: intl.formatMessage({ id: "evidenceType_smAttachment" }),
    value: "attachment",
  },
];

export const getCreatorSortOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({
      id: "exploreCreatorsView_sortOptions_sortByFollowers",
    }),
    value: "sort-by-followers",
  },
  {
    label: intl.formatMessage({
      id: "exploreCreatorsView_sortOptions_sortByImpressions",
    }),
    value: "sort-by-impressions",
  },
  {
    label: intl.formatMessage({
      id: "exploreCreatorsView_sortOptions_sortByJoinDate",
    }),
    value: "sort-by-join-date",
  },
];

export const getPartnershipTypeOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({
      id: "inviteCreatorModal_partnershipTypeAffiliateOption",
    }),
    value: "affiliate-invitation",
  },
  {
    label: intl.formatMessage({
      id: "inviteCreatorModal_partnershipTypeExclusiveOption",
    }),
    value: "exclusive-invitation",
  },
];

export const getGenderOptions = (intl: IntlShape): SelectOption[] => [
  { label: intl.formatMessage({ id: "genderOption_male" }), value: "male" },
  { label: intl.formatMessage({ id: "genderOption_female" }), value: "female" },
  {
    label: intl.formatMessage({ id: "genderOption_preferNotToSay" }),
    value: "hidden",
  },
  { label: intl.formatMessage({ id: "genderOption_other" }), value: "other" },
];

export const getYesNoOptions = (intl: IntlShape): SelectOption[] => [
  { label: intl.formatMessage({ id: "yes_option" }), value: "Y" },
  { label: intl.formatMessage({ id: "no_option" }), value: "N" },
];

export const getServiceProviderOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({ id: "agencyServices_influencerMarketing" }),
    value: "1",
  },
  {
    label: intl.formatMessage({ id: "agencyServices_socialMediaMarketing" }),
    value: "2",
  },
  {
    label: intl.formatMessage({ id: "agencyServices_organiseEvents" }),
    value: "3",
  },
  { label: intl.formatMessage({ id: "agencyServices_others" }), value: "4" },
];

export const getBusinessTypeOptions = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({ id: "businessType_company" }),
    value: "company",
  },
  {
    label: intl.formatMessage({ id: "businessType_partnership" }),
    value: "partnership",
  },
  { label: intl.formatMessage({ id: "businessType_sole" }), value: "sole" },
  {
    label: intl.formatMessage({ id: "businessType_charity" }),
    value: "charity",
  },
];

export const getNationalities = (intl: IntlShape): SelectOption[] => [
  {
    label: intl.formatMessage({ id: "nationalityOption_afghan" }),
    value: "Afghan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_albanian" }),
    value: "Albanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_algerian" }),
    value: "Algerian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_american" }),
    value: "American",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_andorran" }),
    value: "Andorran",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_angolan" }),
    value: "Angolan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_antiguans" }),
    value: "Antiguans",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_argentinean" }),
    value: "Argentinean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_armenian" }),
    value: "Armenian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_australian" }),
    value: "Australian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_austrian" }),
    value: "Austrian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_azerbaijani" }),
    value: "Azerbaijani",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bahamian" }),
    value: "Bahamian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bahraini" }),
    value: "Bahraini",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bangladeshi" }),
    value: "Bangladeshi",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_barbadian" }),
    value: "Barbadian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_barbudans" }),
    value: "Barbudans",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_batswana" }),
    value: "Batswana",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_belarusian" }),
    value: "Belarusian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_belgian" }),
    value: "Belgian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_belizean" }),
    value: "Belizean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_beninese" }),
    value: "Beninese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bhutanese" }),
    value: "Bhutanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bolivian" }),
    value: "Bolivian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bosnian" }),
    value: "Bosnian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_brazilian" }),
    value: "Brazilian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_british" }),
    value: "British",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bruneian" }),
    value: "Bruneian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_bulgarian" }),
    value: "Bulgarian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_burkinabe" }),
    value: "Burkinabe",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_burmese" }),
    value: "Burmese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_burundian" }),
    value: "Burundian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_cambodian" }),
    value: "Cambodian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_cameroonian" }),
    value: "Cameroonian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_canadian" }),
    value: "Canadian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_cape_verdean" }),
    value: "Cape Verdean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_central_african" }),
    value: "Central African",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_chadian" }),
    value: "Chadian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_chilean" }),
    value: "Chilean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_chinese" }),
    value: "Chinese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_colombian" }),
    value: "Colombian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_comoran" }),
    value: "Comoran",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_congolese" }),
    value: "Congolese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_costa_rican" }),
    value: "Costa Rican",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_croatian" }),
    value: "Croatian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_cuban" }),
    value: "Cuban",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_cypriot" }),
    value: "Cypriot",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_czech" }),
    value: "Czech",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_danish" }),
    value: "Danish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_djibouti" }),
    value: "Djibouti",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_dominican" }),
    value: "Dominican",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_dutch" }),
    value: "Dutch",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_east_timorese" }),
    value: "East Timorese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ecuadorean" }),
    value: "Ecuadorean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_egyptian" }),
    value: "Egyptian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_emirian" }),
    value: "Emirian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_equatorial_guinean" }),
    value: "Equatorial Guinean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_eritrean" }),
    value: "Eritrean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_estonian" }),
    value: "Estonian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ethiopian" }),
    value: "Ethiopian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_fijian" }),
    value: "Fijian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_filipino" }),
    value: "Filipino",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_finnish" }),
    value: "Finnish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_french" }),
    value: "French",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_gabonese" }),
    value: "Gabonese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_gambian" }),
    value: "Gambian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_georgian" }),
    value: "Georgian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_german" }),
    value: "German",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ghanaian" }),
    value: "Ghanaian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_greek" }),
    value: "Greek",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_grenadian" }),
    value: "Grenadian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_guatemalan" }),
    value: "Guatemalan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_guinea-bissauan" }),
    value: "Guinea-Bissauan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_guinean" }),
    value: "Guinean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_guyanese" }),
    value: "Guyanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_haitian" }),
    value: "Haitian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_herzegovinian" }),
    value: "Herzegovinian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_honduran" }),
    value: "Honduran",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_hungarian" }),
    value: "Hungarian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_i-kiribati" }),
    value: "I-Kiribati",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_icelander" }),
    value: "Icelander",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_indian" }),
    value: "Indian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_indonesian" }),
    value: "Indonesian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_iranian" }),
    value: "Iranian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_iraqi" }),
    value: "Iraqi",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_irish" }),
    value: "Irish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_israeli" }),
    value: "Israeli",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_italian" }),
    value: "Italian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ivorian" }),
    value: "Ivorian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_jamaican" }),
    value: "Jamaican",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_japanese" }),
    value: "Japanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_jordanian" }),
    value: "Jordanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_kazakhstani" }),
    value: "Kazakhstani",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_kenyan" }),
    value: "Kenyan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_kittian_and_nevisian" }),
    value: "Kittian and Nevisian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_kuwaiti" }),
    value: "Kuwaiti",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_kyrgyz" }),
    value: "Kyrgyz",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_laotian" }),
    value: "Laotian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_latvian" }),
    value: "Latvian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_lebanese" }),
    value: "Lebanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_liberian" }),
    value: "Liberian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_libyan" }),
    value: "Libyan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_liechtensteiner" }),
    value: "Liechtensteiner",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_lithuanian" }),
    value: "Lithuanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_luxembourger" }),
    value: "Luxembourger",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_macedonian" }),
    value: "Macedonian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_malagasy" }),
    value: "Malagasy",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_malawian" }),
    value: "Malawian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_malaysian" }),
    value: "Malaysian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_maldivan" }),
    value: "Maldivan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_malian" }),
    value: "Malian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_maltese" }),
    value: "Maltese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_marshallese" }),
    value: "Marshallese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mauritanian" }),
    value: "Mauritanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mauritian" }),
    value: "Mauritian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mexican" }),
    value: "Mexican",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_micronesian" }),
    value: "Micronesian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_moldovan" }),
    value: "Moldovan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_monacan" }),
    value: "Monacan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mongolian" }),
    value: "Mongolian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_moroccan" }),
    value: "Moroccan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mosotho" }),
    value: "Mosotho",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_motswana" }),
    value: "Motswana",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_mozambican" }),
    value: "Mozambican",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_namibian" }),
    value: "Namibian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_nauruan" }),
    value: "Nauruan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_nepalese" }),
    value: "Nepalese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_new_zealander" }),
    value: "New Zealander",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_nicaraguan" }),
    value: "Nicaraguan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_nigerian" }),
    value: "Nigerian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_nigerien" }),
    value: "Nigerien",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_north_korean" }),
    value: "North Korean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_northern_irish" }),
    value: "Northern Irish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_norwegian" }),
    value: "Norwegian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_omani" }),
    value: "Omani",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_pakistani" }),
    value: "Pakistani",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_palauan" }),
    value: "Palauan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_panamanian" }),
    value: "Panamanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_papua_new_guinean" }),
    value: "Papua New Guinean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_paraguayan" }),
    value: "Paraguayan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_peruvian" }),
    value: "Peruvian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_polish" }),
    value: "Polish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_portuguese" }),
    value: "Portuguese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_qatari" }),
    value: "Qatari",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_romanian" }),
    value: "Romanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_russian" }),
    value: "Russian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_rwandan" }),
    value: "Rwandan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_saint_lucian" }),
    value: "Saint Lucian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_salvadoran" }),
    value: "Salvadoran",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_samoan" }),
    value: "Samoan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_san_marinese" }),
    value: "San Marinese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_sao_tomean" }),
    value: "Sao Tomean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_saudi" }),
    value: "Saudi",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_scottish" }),
    value: "Scottish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_senegalese" }),
    value: "Senegalese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_serbian" }),
    value: "Serbian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_seychellois" }),
    value: "Seychellois",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_sierra_leonean" }),
    value: "Sierra Leonean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_singaporean" }),
    value: "Singaporean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_slovakian" }),
    value: "Slovakian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_slovenian" }),
    value: "Slovenian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_solomon_islander" }),
    value: "Solomon Islander",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_somali" }),
    value: "Somali",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_south_african" }),
    value: "South African",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_south_korean" }),
    value: "South Korean",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_spanish" }),
    value: "Spanish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_sri_lankan" }),
    value: "Sri Lankan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_sudanese" }),
    value: "Sudanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_surinamer" }),
    value: "Surinamer",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_swazi" }),
    value: "Swazi",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_swedish" }),
    value: "Swedish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_swiss" }),
    value: "Swiss",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_syrian" }),
    value: "Syrian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_taiwanese" }),
    value: "Taiwanese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_tajik" }),
    value: "Tajik",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_tanzanian" }),
    value: "Tanzanian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_thai" }),
    value: "Thai",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_togolese" }),
    value: "Togolese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_tongan" }),
    value: "Tongan",
  },
  {
    label: intl.formatMessage({
      id: "nationalityOption_trinidadian_or_tobagonian",
    }),
    value: "Trinidadian or Tobagonian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_tunisian" }),
    value: "Tunisian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_turkish" }),
    value: "Turkish",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_tuvaluan" }),
    value: "Tuvaluan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ugandan" }),
    value: "Ugandan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_ukrainian" }),
    value: "Ukrainian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_uruguayan" }),
    value: "Uruguayan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_uzbekistani" }),
    value: "Uzbekistani",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_venezuelan" }),
    value: "Venezuelan",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_vietnamese" }),
    value: "Vietnamese",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_welsh" }),
    value: "Welsh",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_yemenite" }),
    value: "Yemenite",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_zambian" }),
    value: "Zambian",
  },
  {
    label: intl.formatMessage({ id: "nationalityOption_zimbabwean" }),
    value: "Zimbabwean",
  },
];

export const campaignObjectiveOptions: SelectOption[] = [
  {
    label: "Increase Social Media Engagement",
    value: "increase-social-media-engagement",
  },
  {
    label: "Grow Social Media Following",
    value: "grow-social-media-following",
  },
  {
    label: "Drive Website Traffic from Social Media",
    value: "drive-website-traffic-from-social-media",
  },
  { label: "Generate Leads", value: "generate-leads" },
  { label: "Boost Sales Conversion", value: "boost-sales-conversion" },
  { label: "Fostering Community Growth", value: "fostering-community-growth" },
];

export const cryptoModeOfPaymentOptions: SelectOption[] = [
  {
    label: "USDC",
    value: "USDC",
  },
  {
    label: "Tether USDt",
    value: "USDT",
  },
  { label: "Ethereum", value: "ETH" },
];

export const cryptoChainOptionsV2: SelectOption[] = [
  {
    label: "Ethereum Mainnet",
    value: "ETH",
  },
  {
    label: "BNB Smart Chain Mainnet",
    value: "BSC",
  },
];

export const testnetChainOptions: SelectOption[] = [
  {
    label: "Sepolia",
    value: "SEPOLIA",
  },
];

export const cryptoChainOptions: SelectOption[] = [
  {
    label: "Ethereum (ERC-20)",
    value: "ethereum",
  },
  {
    label: "Binance Smart Chain (BEP-20)",
    value: "bsc",
  },
  {
    label: "Polygon (Matic)",
    value: "polygon",
  },
  { label: "Arbitrum", value: "arbitrum" },
  { label: "Optimism", value: "optimism" },
];

export const campaignSortOptions: SelectOption[] = [
  { label: "Latest Activity", value: "sort-by-updated-desc" },
  { label: "Oldest Activity", value: "sort-by-updated-asc" },
];

export const searchGenderOptions: SelectOption[] = [
  { label: "All Genders", value: "all" },
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Perfer not to say", value: "hidden" },
  { label: "Others", value: "other" },
];

export const searchAgeRangeOptions: SelectOption[] = [
  { label: "All ages", value: "all" },
  { label: "16-17", value: "16-17" },
  { label: "18-24", value: "18-24" },
  { label: "25-34", value: "25-34" },
  { label: "35-44", value: "35-44" },
  { label: "45-54", value: "45-54" },
  { label: "55-64", value: "55-64" },
  { label: "65-99", value: "65-99" },
];

export const searchRepresentativeOptions: SelectOption[] = [
  { label: "Freelance Creators", value: "freelance-creators" },
  { label: "Exclusive Creators", value: "exclusive-creators" },
  { label: "Affiliate Creators", value: "affiliate-creators" },
];

export const countriesOptions: SelectOption[] = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "AndorrA", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People'S Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People'S Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Surilabel", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const getPlatformSelectOptions = (): SelectOptionWithHtml[] => {
  const getHtml = (platform: string, icon: JSX.Element): JSX.Element => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        {icon}
        <span>{platform}</span>
      </div>
    );
  };
  const result: SelectOptionWithHtml[] = [];
  const iconOptions = { backgroundColor: Color.NEUTRAL[400], size: 14 };
  result.push({
    label: SOCIAL_MEDIA_TYPE.FACEBOOK,
    value: SOCIAL_MEDIA_TYPE.FACEBOOK,
    html: getHtml(
      SOCIAL_MEDIA_TYPE.FACEBOOK,
      <FacebookIcon {...iconOptions} />
    ),
  });
  result.push({
    label: SOCIAL_MEDIA_TYPE.INSTAGRAM,
    value: SOCIAL_MEDIA_TYPE.INSTAGRAM,
    html: getHtml(
      SOCIAL_MEDIA_TYPE.INSTAGRAM,
      <InstagramIcon {...iconOptions} />
    ),
  });
  result.push({
    label: SOCIAL_MEDIA_TYPE.TIKTOK,
    value: SOCIAL_MEDIA_TYPE.TIKTOK,
    html: getHtml(SOCIAL_MEDIA_TYPE.TIKTOK, <TikTokIcon {...iconOptions} />),
  });
  result.push({
    label: SOCIAL_MEDIA_TYPE.X,
    value: SOCIAL_MEDIA_TYPE.X,
    html: getHtml(SOCIAL_MEDIA_TYPE.X, <XIcon {...iconOptions} />),
  });
  return result;
};

export const getCountryOptionWithHtml = (): SelectOptionWithHtml[] => {
  const result: SelectOptionWithHtml[] = [];
  for (const countryCode in COUNTRY_CONSTANTS) {
    const item = COUNTRY_CONSTANTS[countryCode];
    result.push({
      label: item.name,
      value: countryCode,
      html: (
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <img
            style={{
              width: 20,
              height: 15,
              border: `1px solid ${Color.NEUTRAL[100]}`,
              borderRadius: 2,
            }}
            alt={item.name}
            src={item.flag_4x3}
          />
          <span>{item.name}</span>
        </div>
      ),
    });
  }
  return result;
};

export const taskPlatforms: SelectOption[] = [
  { label: "General Task", value: "General" },
  { label: "Facebook Task", value: "Facebook" },
  { label: "Instagram Task", value: "Instagram" },
  { label: "TikTok Task", value: "TikTok" },
  { label: "X (Formerly Twitter) Task", value: "X" },
];

export const instagramMediaTypeOptions: SelectOption[] = [
  { label: "Instagram Post", value: "post" },
  { label: "Instagram Story", value: "story" },
];

export const platformMediaTypeOptions: SelectOption[] = [
  { label: "Instagram Post", value: GENERAL_TASK_MEDIA_TYPE.INSTAGRAM_POST },
  { label: "Instagram Story", value: GENERAL_TASK_MEDIA_TYPE.INSTAGRAM_STORY },
  { label: "Facebook Post", value: GENERAL_TASK_MEDIA_TYPE.FACEBOOK_POST },
  { label: "TikTok Video", value: GENERAL_TASK_MEDIA_TYPE.TIKTOK_VIDEO },
  { label: "X Tweet", value: GENERAL_TASK_MEDIA_TYPE.X_TWEET },
];

export const platformOptions: SelectOption[] = [
  { label: "Instagram", value: SOCIAL_MEDIA_TYPE.INSTAGRAM },
  { label: "Facebook", value: SOCIAL_MEDIA_TYPE.FACEBOOK },
  { label: "TikTok", value: SOCIAL_MEDIA_TYPE.TIKTOK },
  { label: "X", value: SOCIAL_MEDIA_TYPE.X },
];

export const numEmployeesOptions: SelectOption[] = [
  { label: "1-99", value: "1-to-99" },
  { label: "100-999", value: "100-to-999" },
  { label: "1000-4999", value: "1000-to-4999" },
  { label: "5000+", value: "5000-and-above" },
];

export const industryOptions: SelectOption[] = [
  { label: "Accounting", value: "Accounting" },
  { label: "Airlines / Aviation", value: "Airlines / Aviation" },
  {
    label: "Alternative Dispute Resolution",
    value: "Alternative Dispute Resolution",
  },
  { label: "Alternative Medicine", value: "Alternative Medicine" },
  { label: "Animation", value: "Animation" },
  { label: "Apparel / Fashion", value: "Apparel / Fashion" },
  { label: "Architecture / Planning", value: "Architecture / Planning" },
  { label: "Arts / Crafts", value: "Arts / Crafts" },
  { label: "Automotive", value: "Automotive" },
  { label: "Aviation / Aerospace", value: "Aviation / Aerospace" },
  { label: "Banking / Mortgage", value: "Banking / Mortgage" },
  { label: "Biotechnology / Greentech", value: "Biotechnology / Greentech" },
  { label: "Broadcast Media", value: "Broadcast Media" },
  { label: "Building Materials", value: "Building Materials" },
  {
    label: "Business Supplies / Equipment",
    value: "Business Supplies / Equipment",
  },
  {
    label: "Capital Markets / Hedge Fund / Private Equity",
    value: "Capital Markets / Hedge Fund / Private Equity",
  },
  { label: "Chemicals", value: "Chemicals" },
  {
    label: "Civic / Social Organization",
    value: "Civic / Social Organization",
  },
  { label: "Civil Engineering", value: "Civil Engineering" },

  { label: "Commercial Real Estate", value: "Commercial Real Estate" },
  { label: "Computer Games", value: "Computer Games" },
  { label: "Computer Hardware", value: "Computer Hardware" },
  { label: "Computer Networking", value: "Computer Networking" },
  {
    label: "Computer Software / Engineering",
    value: "Computer Software / Engineering",
  },
  {
    label: "Computer / Network Security",
    value: "Computer / Network Security",
  },
  { label: "Construction", value: "Construction" },
  {
    label: "Content Creator / Influencer",
    value: "Content Creator / Influencer",
  },
  { label: "Consumer Electronics", value: "Consumer Electronics" },
  { label: "Consumer Goods", value: "Consumer Goods" },
  { label: "Consumer Services", value: "Consumer Services" },
  { label: "Cosmetics", value: "Cosmetics" },
  { label: "Dairy", value: "Dairy" },
  { label: "Defense / Space", value: "Defense / Space" },
  { label: "Design", value: "Design" },
  { label: "E - Learning", value: "E - Learning" },
  { label: "Education Management", value: "Education Management" },
  {
    label: "Electrical / Electronic Manufacturing",
    value: "Electrical / Electronic Manufacturing",
  },
  {
    label: "Entertainment / Movie Production",
    value: "Entertainment / Movie Production",
  },
  { label: "Environmental Services", value: "Environmental Services" },
  { label: "Events Services", value: "Events Services" },
  { label: "Executive Office", value: "Executive Office" },
  { label: "Facilities Services", value: "Facilities Services" },
  { label: "Farming", value: "Farming" },
  { label: "Financial Services", value: "Financial Services" },
  { label: "Fine Art", value: "Fine Art" },
  { label: "Fishery", value: "Fishery" },
  { label: "Food Production", value: "Food Production" },
  { label: "Food / Beverages", value: "Food / Beverages" },
  { label: "Fundraising", value: "Fundraising" },
  { label: "Furniture", value: "Furniture" },
  {
    label: "Glass / Ceramics / Concrete",
    value: "Glass / Ceramics / Concrete",
  },
  { label: "Government Administration", value: "Government Administration" },
  { label: "Government Relations", value: "Government Relations" },
  {
    label: "Graphic Design / Web Design",
    value: "Graphic Design / Web Design",
  },
  { label: "Health / Fitness", value: "Health / Fitness" },
  {
    label: "Higher Education / Acadamia",
    value: "Higher Education / Acadamia",
  },
  { label: "Hospital / Health Care", value: "Hospital / Health Care" },
  { label: "Hospitality", value: "Hospitality" },
  { label: "Human Resources / HR", value: "Human Resources / HR" },
  { label: "Import / Export", value: "Import / Export" },
  {
    label: "Individual / Family Services",
    value: "Individual / Family Services",
  },
  { label: "Industrial Automation", value: "Industrial Automation" },
  { label: "Information Services", value: "Information Services" },
  {
    label: "Information Technology / IT",
    value: "Information Technology / IT",
  },
  { label: "Insurance", value: "Insurance" },
  { label: "International Affairs", value: "International Affairs" },
  {
    label: "International Trade / Development",
    value: "International Trade / Development",
  },
  { label: "Internet", value: "Internet" },
  {
    label: "Investment Banking / Venture",
    value: "Investment Banking / Venture",
  },
  {
    label: "Investment Management / Hedge Fund / Private Equity",
    value: "Investment Management / Hedge Fund / Private Equity",
  },
  { label: "Judiciary", value: "Judiciary" },
  { label: "Law Enforcement", value: "Law Enforcement" },
  { label: "Law Practice / Law Firms", value: "Law Practice / Law Firms" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Legislative Office", value: "Legislative Office" },
  { label: "Leisure / Travel", value: "Leisure / Travel" },
  { label: "Library", value: "Library" },
  { label: "Logistics / Procurement", value: "Logistics / Procurement" },
  { label: "Luxury Goods / Jewelry", value: "Luxury Goods / Jewelry" },
  { label: "Machinery", value: "Machinery" },
  { label: "Management Consulting", value: "Management Consulting" },
  { label: "Maritime", value: "Maritime" },
  { label: "Market Research", value: "Market Research" },
  {
    label: "Marketing / Advertising / Sales",
    value: "Marketing / Advertising / Sales",
  },
  {
    label: "Mechanical or Industrial Engineering",
    value: "Mechanical or Industrial Engineering",
  },
  { label: "Media Production", value: "Media Production" },
  { label: "Medical Equipment", value: "Medical Equipment" },
  { label: "Medical Practice", value: "Medical Practice" },
  { label: "Mental Health Care", value: "Mental Health Care" },
  { label: "Military Industry", value: "Military Industry" },
  { label: "Mining / Metals", value: "Mining / Metals" },
  { label: "Motion Pictures / Film", value: "Motion Pictures / Film" },
  { label: "Museums / Institutions", value: "Museums / Institutions" },
  { label: "Music", value: "Music" },
  { label: "Nanotechnology", value: "Nanotechnology" },
  { label: "Newspapers / Journalism", value: "Newspapers / Journalism" },
  {
    label: "Non - Profit / Volunteering",
    value: "Non - Profit / Volunteering",
  },
  {
    label: "Oil / Energy / Solar / Greentech",
    value: "Oil / Energy / Solar / Greentech",
  },
  { label: "Online Publishing", value: "Online Publishing" },
  { label: "Outsourcing / Offshoring", value: "Outsourcing / Offshoring" },
  { label: "Package / Freight Delivery", value: "Package / Freight Delivery" },
  { label: "Packaging / Containers", value: "Packaging / Containers" },
  { label: "Paper / Forest Products", value: "Paper / Forest Products" },
  { label: "Performing Arts", value: "Performing Arts" },
  { label: "Pharmaceuticals", value: "Pharmaceuticals" },
  { label: "Philanthropy", value: "Philanthropy" },
  { label: "Photography", value: "Photography" },
  { label: "Plastics", value: "Plastics" },
  { label: "Political Organization", value: "Political Organization" },
  {
    label: "Primary / Secondary Education",
    value: "Primary / Secondary Education",
  },
  { label: "Printing", value: "Printing" },
  { label: "Professional Training", value: "Professional Training" },
  { label: "Program Development", value: "Program Development" },
  { label: "Public Relations / PR", value: "Public Relations / PR" },
  { label: "Public Safety", value: "Public Safety" },
  { label: "Publishing Industry", value: "Publishing Industry" },
  { label: "Railroad Manufacture", value: "Railroad Manufacture" },
  { label: "Ranching", value: "Ranching" },
  { label: "Real Estate / Mortgage", value: "Real Estate / Mortgage" },
  {
    label: "Recreational Facilities / Services",
    value: "Recreational Facilities / Services",
  },
  { label: "Religious Institutions", value: "Religious Institutions" },
  { label: "Renewables / Environment", value: "Renewables / Environment" },
  { label: "Research Industry", value: "Research Industry" },
  { label: "Restaurants", value: "Restaurants" },
  { label: "Retail Industry", value: "Retail Industry" },
  { label: "Security / Investigations", value: "Security / Investigations" },
  { label: "Semiconductors", value: "Semiconductors" },
  { label: "Shipbuilding", value: "Shipbuilding" },
  { label: "Sporting Goods", value: "Sporting Goods" },
  { label: "Sports", value: "Sports" },
  { label: "Staffing / Recruiting", value: "Staffing / Recruiting" },
  { label: "Supermarkets", value: "Supermarkets" },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Textiles", value: "Textiles" },
  { label: "Think Tanks", value: "Think Tanks" },
  { label: "Tobacco", value: "Tobacco" },
  { label: "Translation / Localization", value: "Translation / Localization" },
  { label: "Transportation", value: "Transportation" },
  { label: "Utilities", value: "Utilities" },
  { label: "Venture Capital / VC", value: "Venture Capital / VC" },
  { label: "Veterinary", value: "Veterinary" },
  { label: "Warehousing", value: "Warehousing" },
  { label: "Wholesale", value: "Wholesale" },
  { label: "Wine / Spirits", value: "Wine / Spirits" },
  { label: "Wireless", value: "Wireless" },
  { label: "Writing / Editing", value: "Writing / Editing" },
  { label: "Other Industry", value: "Other Industry" },
];

export const countryCodes = [
  { label: "AD", value: "AD" },
  { label: "AE", value: "AE" },
  { label: "AG", value: "AG" },
  { label: "AI", value: "AI" },
  { label: "AL", value: "AL" },
  { label: "AM", value: "AM" },
  { label: "AN", value: "AN" },
  { label: "AO", value: "AO" },
  { label: "AQ", value: "AQ" },
  { label: "AR", value: "AR" },
  { label: "AS", value: "AS" },
  { label: "AT", value: "AT" },
  { label: "AU", value: "AU" },
  { label: "AW", value: "AW" },
  { label: "AX", value: "AX" },
  { label: "AZ", value: "AZ" },
  { label: "BA", value: "BA" },
  { label: "BB", value: "BB" },
  { label: "BD", value: "BD" },
  { label: "BE", value: "BE" },
  { label: "BF", value: "BF" },
  { label: "BG", value: "BG" },
  { label: "BH", value: "BH" },
  { label: "BI", value: "BI" },
  { label: "BJ", value: "BJ" },
  { label: "BL", value: "BL" },
  { label: "BM", value: "BM" },
  { label: "BN", value: "BN" },
  { label: "BO", value: "BO" },
  { label: "BQ", value: "BQ" },
  { label: "BR", value: "BR" },
  { label: "BS", value: "BS" },
  { label: "BT", value: "BT" },
  { label: "BU", value: "BU" },
  { label: "BV", value: "BV" },
  { label: "BW", value: "BW" },
  { label: "BZ", value: "BZ" },
  { label: "CA", value: "CA" },
  { label: "CC", value: "CC" },
  { label: "CF", value: "CF" },
  { label: "CG", value: "CG" },
  { label: "CH", value: "CH" },
  { label: "CI", value: "CI" },
  { label: "CK", value: "CK" },
  { label: "CL", value: "CL" },
  { label: "CM", value: "CM" },
  { label: "CN", value: "CN" },
  { label: "CO", value: "CO" },
  { label: "CP", value: "CP" },
  { label: "CR", value: "CR" },
  { label: "CS", value: "CS" },
  { label: "CV", value: "CV" },
  { label: "CW", value: "CW" },
  { label: "CX", value: "CX" },
  { label: "CY", value: "CY" },
  { label: "CZ", value: "CZ" },
  { label: "DE", value: "DE" },
  { label: "DG", value: "DG" },
  { label: "DJ", value: "DJ" },
  { label: "DK", value: "DK" },
  { label: "DM", value: "DM" },
  { label: "DO", value: "DO" },
  { label: "DZ", value: "DZ" },
  { label: "EA", value: "EA" },
  { label: "EC", value: "EC" },
  { label: "EE", value: "EE" },
  { label: "EG", value: "EG" },
  { label: "EH", value: "EH" },
  { label: "ER", value: "ER" },
  { label: "ES", value: "ES" },
  { label: "ET", value: "ET" },
  { label: "EU", value: "EU" },
  { label: "EZ", value: "EZ" },
  { label: "FI", value: "FI" },
  { label: "FJ", value: "FJ" },
  { label: "FK", value: "FK" },
  { label: "FM", value: "FM" },
  { label: "FO", value: "FO" },
  { label: "FR", value: "FR" },
  { label: "FX", value: "FX" },
  { label: "GA", value: "GA" },
  { label: "GB", value: "GB" },
  { label: "GD", value: "GD" },
  { label: "GE", value: "GE" },
  { label: "GF", value: "GF" },
  { label: "GG", value: "GG" },
  { label: "GH", value: "GH" },
  { label: "GI", value: "GI" },
  { label: "GL", value: "GL" },
  { label: "GM", value: "GM" },
  { label: "GN", value: "GN" },
  { label: "GP", value: "GP" },
  { label: "GQ", value: "GQ" },
  { label: "GR", value: "GR" },
  { label: "GS", value: "GS" },
  { label: "GT", value: "GT" },
  { label: "GU", value: "GU" },
  { label: "GW", value: "GW" },
  { label: "GY", value: "GY" },
  { label: "HK", value: "HK" },
  { label: "HM", value: "HM" },
  { label: "HN", value: "HN" },
  { label: "HR", value: "HR" },
  { label: "HU", value: "HU" },
  { label: "IC", value: "IC" },
  { label: "ID", value: "ID" },
  { label: "IE", value: "IE" },
  { label: "IL", value: "IL" },
  { label: "IM", value: "IM" },
  { label: "IN", value: "IN" },
  { label: "IO", value: "IO" },
  { label: "IS", value: "IS" },
  { label: "IT", value: "IT" },
  { label: "JE", value: "JE" },
  { label: "JM", value: "JM" },
  { label: "JO", value: "JO" },
  { label: "JP", value: "JP" },
  { label: "KE", value: "KE" },
  { label: "KG", value: "KG" },
  { label: "KH", value: "KH" },
  { label: "KI", value: "KI" },
  { label: "KM", value: "KM" },
  { label: "KN", value: "KN" },
  { label: "KR", value: "KR" },
  { label: "KW", value: "KW" },
  { label: "KY", value: "KY" },
  { label: "KZ", value: "KZ" },
  { label: "LA", value: "LA" },
  { label: "LB", value: "LB" },
  { label: "LC", value: "LC" },
  { label: "LI", value: "LI" },
  { label: "LK", value: "LK" },
  { label: "LR", value: "LR" },
  { label: "LS", value: "LS" },
  { label: "LT", value: "LT" },
  { label: "LU", value: "LU" },
  { label: "LV", value: "LV" },
  { label: "LY", value: "LY" },
  { label: "MA", value: "MA" },
  { label: "MC", value: "MC" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MF", value: "MF" },
  { label: "MG", value: "MG" },
  { label: "MH", value: "MH" },
  { label: "MK", value: "MK" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MP", value: "MP" },
  { label: "MQ", value: "MQ" },
  { label: "MR", value: "MR" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "MU", value: "MU" },
  { label: "MV", value: "MV" },
  { label: "MW", value: "MW" },
  { label: "MX", value: "MX" },
  { label: "MY", value: "MY" },
  { label: "NA", value: "NA" },
  { label: "NC", value: "NC" },
  { label: "NE", value: "NE" },
  { label: "NF", value: "NF" },
  { label: "NG", value: "NG" },
  { label: "NI", value: "NI" },
  { label: "NL", value: "NL" },
  { label: "NO", value: "NO" },
  { label: "NP", value: "NP" },
  { label: "NR", value: "NR" },
  { label: "NU", value: "NU" },
  { label: "NZ", value: "NZ" },
  { label: "OM", value: "OM" },
  { label: "PA", value: "PA" },
  { label: "PE", value: "PE" },
  { label: "PF", value: "PF" },
  { label: "PG", value: "PG" },
  { label: "PH", value: "PH" },
  { label: "PK", value: "PK" },
  { label: "PL", value: "PL" },
  { label: "PM", value: "PM" },
  { label: "PN", value: "PN" },
  { label: "PR", value: "PR" },
  { label: "PS", value: "PS" },
  { label: "PT", value: "PT" },
  { label: "PW", value: "PW" },
  { label: "PY", value: "PY" },
  { label: "QA", value: "QA" },
  { label: "RE", value: "RE" },
  { label: "RO", value: "RO" },
  { label: "RS", value: "RS" },
  { label: "RW", value: "RW" },
  { label: "SA", value: "SA" },
  { label: "SB", value: "SB" },
  { label: "SC", value: "SC" },
  { label: "SE", value: "SE" },
  { label: "SG", value: "SG" },
  { label: "SH", value: "SH" },
  { label: "SI", value: "SI" },
  { label: "SJ", value: "SJ" },
  { label: "SK", value: "SK" },
  { label: "SL", value: "SL" },
  { label: "SM", value: "SM" },
  { label: "SN", value: "SN" },
  { label: "SR", value: "SR" },
  { label: "ST", value: "ST" },
  { label: "SZ", value: "SZ" },
  { label: "TA", value: "TA" },
  { label: "TC", value: "TC" },
  { label: "TD", value: "TD" },
  { label: "TF", value: "TF" },
  { label: "TG", value: "TG" },
  { label: "TH", value: "TH" },
  { label: "TJ", value: "TJ" },
  { label: "TK", value: "TK" },
  { label: "TL", value: "TL" },
  { label: "TM", value: "TM" },
  { label: "TN", value: "TN" },
  { label: "TO", value: "TO" },
  { label: "TR", value: "TR" },
  { label: "TT", value: "TT" },
  { label: "TV", value: "TV" },
  { label: "TW", value: "TW" },
  { label: "TZ", value: "TZ" },
  { label: "UA", value: "UA" },
  { label: "UG", value: "UG" },
  { label: "UK", value: "UK" },
  { label: "UM", value: "UM" },
  { label: "US", value: "US" },
  { label: "UY", value: "UY" },
  { label: "UZ", value: "UZ" },
  { label: "VA", value: "VA" },
  { label: "VC", value: "VC" },
  { label: "VG", value: "VG" },
  { label: "VI", value: "VI" },
  { label: "VN", value: "VN" },
  { label: "VU", value: "VU" },
  { label: "WF", value: "WF" },
  { label: "WS", value: "WS" },
  { label: "ZA", value: "ZA" },
  { label: "ZM", value: "ZM" },
  { label: "ZW", value: "ZW" },
];

export const accountCurrencyConstants = [
  { label: "CHF", value: "CHF" },
  { label: "MXN", value: "MXN" },
  { label: "ARS", value: "ARS" },
  { label: "SAR", value: "SAR" },
  { label: "CLP", value: "CLP" },
  { label: "ZAR", value: "ZAR" },
  { label: "INR", value: "INR" },
  { label: "VND", value: "VND" },
  { label: "CNY", value: "CNY" },
  { label: "THB", value: "THB" },
  { label: "AUD", value: "AUD" },
  { label: "KRW", value: "KRW" },
  { label: "ILS", value: "ILS" },
  { label: "NPR", value: "NPR" },
  { label: "JPY", value: "JPY" },
  { label: "PLN", value: "PLN" },
  { label: "BDT", value: "BDT" },
  { label: "GBP", value: "GBP" },
  { label: "BOB", value: "BOB" },
  { label: "IDR", value: "IDR" },
  { label: "HUF", value: "HUF" },
  { label: "PHP", value: "PHP" },
  { label: "TRY", value: "TRY" },
  { label: "HKD", value: "HKD" },
  { label: "AED", value: "AED" },
  { label: "EUR", value: "EUR" },
  { label: "DKK", value: "DKK" },
  { label: "COP", value: "COP" },
  { label: "CAD", value: "CAD" },
  { label: "USD", value: "USD" },
  { label: "MYR", value: "MYR" },
  { label: "NOK", value: "NOK" },
  { label: "EGP", value: "EGP" },
  { label: "RON", value: "RON" },
  { label: "UYU", value: "UYU" },
  { label: "SGD", value: "SGD" },
  { label: "MAD", value: "MAD" },
  { label: "LKR", value: "LKR" },
  { label: "CZK", value: "CZK" },
  { label: "PKR", value: "PKR" },
  { label: "SEK", value: "SEK" },
  { label: "PEN", value: "PEN" },
  { label: "NZD", value: "NZD" },
  { label: "BRL", value: "BRL" },
];

export const bankCountryCode = [
  { label: "AD", value: "AD" },
  { label: "AE", value: "AE" },
  { label: "AG", value: "AG" },
  { label: "AI", value: "AI" },
  { label: "AL", value: "AL" },
  { label: "AM", value: "AM" },
  { label: "AN", value: "AN" },
  { label: "AO", value: "AO" },
  { label: "AR", value: "AR" },
  { label: "AT", value: "AT" },
  { label: "AU", value: "AU" },
  { label: "AW", value: "AW" },
  { label: "AZ", value: "AZ" },
  { label: "BA", value: "BA" },
  { label: "BD", value: "BD" },
  { label: "BE", value: "BE" },
  { label: "BG", value: "BG" },
  { label: "BH", value: "BH" },
  { label: "BL", value: "BL" },
  { label: "BO", value: "BO" },
  { label: "BR", value: "BR" },
  { label: "BW", value: "BW" },
  { label: "BZ", value: "BZ" },
  { label: "CA", value: "CA" },
  { label: "CH", value: "CH" },
  { label: "CL", value: "CL" },
  { label: "CN", value: "CN" },
  { label: "CO", value: "CO" },
  { label: "CR", value: "CR" },
  { label: "CW", value: "CW" },
  { label: "CY", value: "CY" },
  { label: "CZ", value: "CZ" },
  { label: "DE", value: "DE" },
  { label: "DK", value: "DK" },
  { label: "DM", value: "DM" },
  { label: "DO", value: "DO" },
  { label: "DZ", value: "DZ" },
  { label: "EC", value: "EC" },
  { label: "EE", value: "EE" },
  { label: "EG", value: "EG" },
  { label: "ES", value: "ES" },
  { label: "FI", value: "FI" },
  { label: "FJ", value: "FJ" },
  { label: "FR", value: "FR" },
  { label: "FO", value: "FO" },
  { label: "GB", value: "GB" },
  { label: "GD", value: "GD" },
  { label: "GE", value: "GE" },
  { label: "GF", value: "GF" },
  { label: "GG", value: "GG" },
  { label: "GI", value: "GI" },
  { label: "GL", value: "GL" },
  { label: "GQ", value: "GQ" },
  { label: "GR", value: "GR" },
  { label: "GT", value: "GT" },
  { label: "GY", value: "GY" },
  { label: "HK", value: "HK" },
  { label: "HN", value: "HN" },
  { label: "HR", value: "HR" },
  { label: "HU", value: "HU" },
  { label: "IC", value: "IC" },
  { label: "ID", value: "ID" },
  { label: "IE", value: "IE" },
  { label: "IL", value: "IL" },
  { label: "IM", value: "IM" },
  { label: "IN", value: "IN" },
  { label: "IS", value: "IS" },
  { label: "IT", value: "IT" },
  { label: "JE", value: "JE" },
  { label: "JM", value: "JM" },
  { label: "JO", value: "JO" },
  { label: "JP", value: "JP" },
  { label: "KE", value: "KE" },
  { label: "KH", value: "KH" },
  { label: "KG", value: "KG" },
  { label: "KR", value: "KR" },
  { label: "KZ", value: "KZ" },
  { label: "KW", value: "KW" },
  { label: "LC", value: "LC" },
  { label: "LI", value: "LI" },
  { label: "LK", value: "LK" },
  { label: "LT", value: "LT" },
  { label: "LU", value: "LU" },
  { label: "LV", value: "LV" },
  { label: "MA", value: "MA" },
  { label: "MC", value: "MC" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MF", value: "MF" },
  { label: "MK", value: "MK" },
  { label: "MO", value: "MO" },
  { label: "MQ", value: "MQ" },
  { label: "MR", value: "MR" },
  { label: "MT", value: "MT" },
  { label: "MU", value: "MU" },
  { label: "MV", value: "MV" },
  { label: "MX", value: "MX" },
  { label: "MY", value: "MY" },
  { label: "NA", value: "NA" },
  { label: "NC", value: "NC" },
  { label: "NG", value: "NG" },
  { label: "NL", value: "NL" },
  { label: "NO", value: "NO" },
  { label: "NP", value: "NP" },
  { label: "NZ", value: "NZ" },
  { label: "OM", value: "OM" },
  { label: "PA", value: "PA" },
  { label: "PE", value: "PE" },
  { label: "PF", value: "PF" },
  { label: "PH", value: "PH" },
  { label: "PK", value: "PK" },
  { label: "PL", value: "PL" },
  { label: "PM", value: "PM" },
  { label: "PR", value: "PR" },
  { label: "PS", value: "PS" },
  { label: "PT", value: "PT" },
  { label: "PY", value: "PY" },
  { label: "QA", value: "QA" },
  { label: "RE", value: "RE" },
  { label: "RO", value: "RO" },
  { label: "RS", value: "RS" },
  { label: "SA", value: "SA" },
  { label: "SC", value: "SC" },
  { label: "SE", value: "SE" },
  { label: "SG", value: "SG" },
  { label: "SI", value: "SI" },
  { label: "SK", value: "SK" },
  { label: "SM", value: "SM" },
  { label: "SN", value: "SN" },
  { label: "SV", value: "SV" },
  { label: "TF", value: "TF" },
  { label: "TH", value: "TH" },
  { label: "TJ", value: "TJ" },
  { label: "TL", value: "TL" },
  { label: "TN", value: "TN" },
  { label: "TR", value: "TR" },
  { label: "TZ", value: "TZ" },
  { label: "TW", value: "TW" },
  { label: "UA", value: "UA" },
  { label: "UG", value: "UG" },
  { label: "US", value: "US" },
  { label: "UY", value: "UY" },
  { label: "UZ", value: "UZ" },
  { label: "VG", value: "VG" },
  { label: "VN", value: "VN" },
  { label: "VU", value: "VU" },
  { label: "WF", value: "WF" },
  { label: "XK", value: "XK" },
  { label: "YT", value: "YT" },
  { label: "ZA", value: "ZA" },
  { label: "ZM", value: "ZM" },
];
