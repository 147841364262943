import { useCallback, useEffect, useRef, useState } from "react";
import "./explore-creators-view.scss";
import _debounce from "lodash/debounce";
import { Color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import InviteTalentsModal, {
  InviteTalentsModalRef,
} from "../../../components/modals/invite-talents-modal";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import SearchIcon from "../../../components/icon/search-icon";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import FacebookIcon from "../../../components/icon/facebook-icon";
import UserApi from "../../../api/user-msvc/user.api";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { CreatorListingItemDto } from "../../../model/user-management/creator-listing-item-resp.dto";
import InstagramIcon from "../../../components/icon/instagram-icon";
import { CreatorListingQueryDto } from "../../../model/user-management/creator-listing-query-req.dto";
import EmpPagination, {
  EmpPaginationProps,
} from "../../../components/shared/EmpPagination/EmpPagination";
import { EmpQueryable } from "../../../model/common/tableFilters";
import OrganisationApi from "../../../api/user-msvc/organisation.api";
import { SmCreatorRespDto } from "../../../model/user-management/sm-creator-resp.dto";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpException from "../../../exception/empException";
import EmpLoader, {
  EmpLoaderRef,
} from "../../../components/shared/emp-loader/emp-loader";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../../utilities/translation.util";
import { SelectOption } from "../../../model/common/selectOption";
import {
  getCreatorSearchOptions,
  getCreatorSortOptions,
} from "../../../constants/selectConstants";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import FormFieldUtils from "../../../utilities/form-field.util";
import XIcon from "../../../components/icon/x-icon";

interface CreatorListingItemExtended extends CreatorListingItemDto {
  hasJoined: boolean;
  invitationType: string;
}

export const ExploreCreatorsView = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const inviteTalentModalRef = useRef<InviteTalentsModalRef>();

  const [myTalents, setMyTalents] = useState<SmCreatorRespDto[]>();
  const [cardWidth, setCardWidth] = useState<number>(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const totalRecordsRef = useRef<number>();
  const [creatorListing, setCreatorListing] = useState<
    CreatorListingItemExtended[]
  >([]);

  const [creatorSortOptions, setCreatorSortOptions] = useState<SelectOption[]>(
    []
  );
  const [creatorSearchOptions, setCreatorSearchOptions] = useState<
    SelectOption[]
  >([]);

  // Translation use effect.
  useEffect(() => {
    setCreatorSortOptions(getCreatorSortOptions(intl));
    setCreatorSearchOptions(getCreatorSearchOptions(intl));
  }, [intl]);

  const GUTTER_WIDTH = 12;
  const MIN_CARD_WIDTH = 260;

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
    searchBy: new FormControl("text", [], "search-by-name"),
    sortBy: new FormControl("text", [], "sort-by-join-date"),
  });

  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const query = useRef<EmpQueryable>({});

  useEffect(() => {
    if (isLoading === true) empLoaderRef.current?.show();
    if (isLoading === false) empLoaderRef.current?.hide();
  }, [isLoading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );

    setCardWidth(cardWidth);
    setShimmerCards(Array.from(Array(maxCards * 3).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  /**
   * Fetches a creator listing based on the provided queryable object.
   * @param {EmpQueryable} queryable - The queryable object used for pagination and filtering.
   * @returns {Promise<void>} - A Promise that resolves once the creator listing is fetched.
   */
  const fetchListing = useCallback(async (queryable: EmpQueryable) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("pageNo", `${queryable?.pageNo ?? 1}`);
      searchParams.set("pageSize", `${queryable?.pageSize ?? 10}`);
      if (form.search.getValue().length > 0) {
        searchParams.set("search", form.search.getValue());
      }
      navigate(
        `${window.location.pathname}?${searchParams.toString()}${
          window.location.hash
        }`
      );

      setIsLoading(true);
      query.current = queryable;
      const request: CreatorListingQueryDto = {
        searchOption: form.searchBy.getValue(),
        sortOption: form.sortBy.getValue(),
        keyword: form.search.getValue(),
        pageNo: queryable?.pageNo ?? 1,
        pageSize: queryable?.pageSize ?? 10,
      };
      const resp = await UserApi.creatorListing(request);
      setPaginable({
        currentPage: resp.data.currentPage,
        totalRecord: resp.data.totalRecord,
        pageSize: resp.data.pageSize,
      });
      totalRecordsRef.current = resp.data.totalRecord;

      const creatorListingRecords = resp.data.records;

      // Bind invited creators
      let ownedTalents = myTalents;
      if (ownedTalents === undefined) {
        ownedTalents = await fetchOwnedCreators();
      }
      const ownedCreatorIds = new Set(ownedTalents.map((elem) => elem.id));
      const extendedCreatorListingRecords = creatorListingRecords.map(
        (elem) => {
          const agencyInvitations = elem.agencies.map(
            (elem) => elem.invitationType
          );
          let agencyInvitationType = "none";
          if (agencyInvitations.length > 0) {
            if (agencyInvitations.includes("exclusive-invitation"))
              agencyInvitationType = "exclusive-invitation";
            else agencyInvitationType = "affiliate-invitation";
          }
          return {
            ...elem,
            hasJoined: ownedCreatorIds.has(elem.id),
            invitationType: agencyInvitationType,
          };
        }
      );
      setCreatorListing(extendedCreatorListingRecords);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator listing"
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchOwnedCreators = async (): Promise<SmCreatorRespDto[]> => {
    try {
      const response = await OrganisationApi.fetchAgencyCreators();
      setMyTalents(response.data);
      return response.data;
    } catch (e) {
      throw new EmpException("Unable to fetch owned creators");
    }
  };

  /**
   * Generates a representative text based on the given creator listing item.
   * The representative text indicates the creator's agency affiliation.
   *
   * @param {CreatorListingItemDto} item - The creator listing item.
   * @returns {JSX.Element} - Formatted representative text
   */
  const recommendRepresentativeText = (
    item: CreatorListingItemDto
  ): JSX.Element => {
    if (item.agencies.length === 0) return <>None</>;
    else if (item.agencies.length === 1)
      return <span>{item.agencies[0].name}</span>;
    else return <>{`${item.agencies.length} agencies`}</>;
  };

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo");
    const pageSize = searchParams.get("pageSize");
    const search = searchParams.get("search");
    if (search) {
      updateFormSearch(search);
    }
    fetchListing({
      pageNo: pageNo ? Number(pageNo) : 1,
      pageSize: pageSize ? Number(pageSize) : 20,
    });

    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [fetchListing, updateFormSearch]);

  const renderActionButton = (
    creatorListing: CreatorListingItemExtended
  ): JSX.Element => {
    if (creatorListing.hasJoined) {
      return (
        <div className="btn-wrapper">
          <EmpButton
            buttonHeight="sm"
            disabled
            text={
              <FormattedMessage id="exploreCreatorsView_creatorCardJoinedBtn" />
            }
          />
        </div>
      );
    } else if (creatorListing.invitationType === "exclusive-invitation") {
      return (
        <div className="btn-wrapper">
          <EmpButton
            buttonHeight="sm"
            disabled
            text={
              <FormattedMessage id="exploreCreatorsView_creatorCardExclusiveBtn" />
            }
          />
        </div>
      );
    } else {
      return (
        <div className="btn-wrapper">
          <EmpButton
            buttonHeight="sm"
            onSubmit={() => {
              inviteTalentModalRef.current?.show(
                "existing",
                creatorListing.name,
                creatorListing.id
              );
            }}
            text={
              <FormattedMessage id="exploreCreatorsView_creatorCardInviteBtn" />
            }
          />
        </div>
      );
    }
  };

  const searchOnClick = () => {
    fetchListing({});
  };

  return (
    <div className="emp-explore-creator-view">
      <EmpLoader ref={empLoaderRef} />
      <InviteTalentsModal
        ref={inviteTalentModalRef}
        onSave={() => {
          inviteTalentModalRef.current?.dismiss();
        }}
      />
      <div>
        <div className="explore-header-wrapper">
          <div className="right-elem">
            <h1>
              <FormattedMessage id="exploreCreatorsView_header" />
            </h1>
            <p className="page-description">
              <FormattedMessage
                id="exploreCreatorsView_desc"
              />
            </p>
          </div>
        </div>
        {/* Search Form Section */}
        <section className="mt-4 search-form-section">
          <div className="search-input">
            <EmpTextInput
              labelText={
                <FormattedMessage id="exploreCreatorsView_searchCreatorsInput" />
              }
              id={"search"}
              onKeypress={(e) => {
                if (e === "Enter") {
                  fetchListing({});
                }
              }}
              formControl={form.search}
              placeholder={intl.formatMessage({
                id: "exploreCreatorsView_searchCreatorsPlaceholder",
              })}
              leftIconComponent={SearchIcon}
            />
          </div>

          <div className="search-options-input">
            <EmpSelect
              labelText={intl.formatMessage({
                id: "exploreCreatorsView_searchOptionsInput",
              })}
              placeholder={intl.formatMessage({
                id: "exploreCreatorsView_searchOptionsPlaceholder",
              })}
              id={"searchBy"}
              formControl={form.searchBy}
              selectOptions={creatorSearchOptions}
            />
          </div>
          <div className="search-options-input">
            <EmpSelect
              labelText={intl.formatMessage({
                id: "exploreCreatorsView_sortOptionsInput",
              })}
              placeholder={intl.formatMessage({
                id: "exploreCreatorsView_sortOptionsPlaceholder",
              })}
              id={"sortBy"}
              formControl={form.sortBy}
              selectOptions={creatorSortOptions}
            />
          </div>
          <div className="search-button-wrapper">
            <EmpButton
              onSubmit={() => {
                searchOnClick();
              }}
              isFullWidth={false}
              buttonStyle="primary"
              text={<FormattedMessage id="exploreCreatorsView_searchBtn" />}
            />
          </div>
        </section>
        <div ref={resizeDivRef} style={{ width: "100%" }}></div>

        <div className="cards-section-wrapper">
          {totalRecordsRef.current !== undefined && (
            <span className="records-title">
              <FormattedMessage
                id="exploreCreatorsView_searchResults"
                values={{
                  value: totalRecordsRef.current,
                  count: TranslationUtil.getCount(totalRecordsRef.current),
                }}
              />
            </span>
          )}
          <EmpPagination
            onChange={(queryable) =>
              fetchListing(queryable as CreatorListingQueryDto)
            }
            pagination={paginable}
            queryable={query.current}
          />
        </div>
        {!isLoading && (
          <div className="cards-section">
            {creatorListing.length > 0 &&
              creatorListing.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="creator-card"
                    style={{ width: cardWidth }}
                  >
                    <div className="bio-section">
                      {item.imageType === "url" && (
                        <img
                          referrerPolicy="no-referrer"
                          className="profile-img"
                          alt="creator"
                          src={item.imageResource}
                        />
                      )}
                      {item.imageType === "avatar" && (
                        <div
                          className="profile-avatar"
                          style={{ background: item.imageResource }}
                        >
                          <span>{item.initials}</span>
                        </div>
                      )}
                      <div className="account-details-wrapper">
                        <div className="name">{item.name}</div>
                      </div>
                    </div>
                    <div className="metrics-section mt-3">
                      <div className="metric-left">
                        <EmpContent
                          label={
                            <FormattedMessage id="exploreCreatorsView_creatorCardAvgImpressionsLabel" />
                          }
                          value={FormFieldUtils.toCompact(
                            item.impressions
                          ).toString()}
                        />
                      </div>
                      <div className="metric-right">
                        <EmpContent
                          label={
                            <FormattedMessage id="exploreCreatorsView_creatorCardAvgFollowersLabel" />
                          }
                          value={FormFieldUtils.toCompact(
                            item.followers
                          ).toString()}
                        />
                      </div>
                    </div>
                    <div className="metrics-section mt-3">
                      <div className="metric-left">
                        <EmpContent
                          label={
                            <FormattedMessage id="exploreCreatorsView_creatorCardCountryLabel" />
                          }
                          value={
                            <div className="country-wrapper">
                              <img
                                className="img-flag"
                                alt="country"
                                src={
                                  COUNTRY_CONSTANTS[item.country]["flag_1x1"]
                                }
                              />
                              <span>
                                {COUNTRY_CONSTANTS[item.country].name}
                              </span>
                            </div>
                          }
                        />
                      </div>
                      <div className="metric-right">
                        <EmpContent
                          label={
                            <FormattedMessage id="exploreCreatorsView_creatorCardRepresentativeLabel" />
                          }
                          value={recommendRepresentativeText(item)}
                        />
                      </div>
                    </div>
                    <span className="social-media-heading mt-4">
                      <FormattedMessage id="exploreCreatorsView_creatorCardSocialMediaAccountsLabel" />
                    </span>
                    <div className="social-media-section mt-2">
                      {item.socialMedia.length > 0 &&
                        item.socialMedia.map((sm) => {
                          return (
                            <div key={sm.platform} className="social-media">
                              <div className="image-wrapper">
                                <EmpImage
                                  className="social-img"
                                  alt="creator"
                                  src={sm.picture}
                                  fallback={EMPTY_PROFILE_IMG_URL}
                                />
                                <div className="platform-badge">
                                  {sm.platform === "Facebook" && (
                                    <FacebookIcon
                                      backgroundColor={Color.NEUTRAL[0]}
                                      size={10}
                                    />
                                  )}
                                  {sm.platform === "Instagram" && (
                                    <InstagramIcon
                                      backgroundColor={Color.NEUTRAL[0]}
                                      size={10}
                                    />
                                  )}
                                  {sm.platform === "TikTok" && (
                                    <TikTokIcon
                                      backgroundColor={Color.NEUTRAL[0]}
                                      size={10}
                                    />
                                  )}
                                  {sm.platform === "X" && (
                                    <XIcon
                                      backgroundColor={Color.NEUTRAL[0]}
                                      size={10}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="social-details-wrapper">
                                <div className="handle">
                                  {sm.socialMediaName}
                                </div>
                                <div className="platform">{sm.platform}</div>
                              </div>
                            </div>
                          );
                        })}
                      {item.socialMedia.length === 0 && (
                        <div className="social-media-empty-state">
                          <span>No accounts connected</span>
                        </div>
                      )}
                    </div>

                    <div className="action-section mt-6">
                      <div className="btn-wrapper-section">
                        <div className="btn-wrapper">
                          <EmpButton
                            onSubmit={() => {
                              navigate(`/agency/creator-details/${item.id}`);
                            }}
                            buttonHeight="sm"
                            buttonStyle="secondary"
                            text={
                              <FormattedMessage id="exploreCreatorsView_creatorCardViewBtn" />
                            }
                          />
                        </div>
                        {renderActionButton(item)}
                      </div>
                    </div>
                  </div>
                );
              })}

            {creatorListing.length === 0 && (
              <div className="cards-empty-state">
                <div className="overlay">
                  <div className="info-wrapper">
                    <img
                      className="empty-img"
                      alt="No talents found"
                      srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                    />
                    <h2 className="header">No Creators found</h2>
                    <p className="description">
                      Please try another search query!
                    </p>
                  </div>
                </div>
                {shimmerCard.map((elem, index) => {
                  return (
                    <div
                      key={elem}
                      className={`talent-placeholder-card mt-6`}
                      style={{ width: cardWidth }}
                    >
                      <div className="details-wrapper ">
                        <div className="profile shimmer-color"></div>
                        <div className="account-details-wrapper ml-2">
                          <div className="name shimmer-color"></div>
                          <div className="email shimmer-color"></div>
                        </div>
                      </div>
                      <div className="metrics-wrapper mt-4">
                        <div className="metric pr-2">
                          <div className="title shimmer-color"></div>
                          <div className="value shimmer-color"></div>
                        </div>
                        <div className="metric pl-2">
                          <div className="title shimmer-color"></div>
                          <div className="value shimmer-color"></div>
                        </div>
                      </div>
                      <div className="social-media-wrapper mt-6">
                        <div className="icon shimmer-color"></div>
                        <div className="icon shimmer-color"></div>
                        <div className="icon shimmer-color"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className="footer-section-wrapper">
          <EmpPagination
            onChange={(queryable) =>
              fetchListing(queryable as CreatorListingQueryDto)
            }
            pagination={paginable}
            queryable={query.current}
          />
        </div>
      </div>
    </div>
  );
};
