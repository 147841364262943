import "./EmpTable.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { v4 } from "uuid";
import { PAGINATION } from "../../../constants/paginationConstants";
import { EmpQueryable, EmpSortType } from "../../../model/common/tableFilters";
import EmpPagination, {
  EmpPaginationProps,
} from "../EmpPagination/EmpPagination";
import EmpSortIcon from "../EmpSortIcon/emp-sort-icon";
import { EmpExpandToggleBtn } from "../emp-expand-toggle-btn/emp-expand-toggle-btn";

export interface EmpTableContentSpec<T> {
  title: string | JSX.Element;
  dataIndex: string;
  sorter?: boolean;
  defaultValue?: string;
  hasCollapsible?: (record: T) => boolean;
  width?: number;
  fixed?: boolean;
  render?: (record: T, index: number) => JSX.Element;
}

export interface EmpTableSeperator {
  type: "emp-table-separator";
  content: string | JSX.Element;
}

export interface EmpTableEmptyRow {
  type: "emp-empty-row";
  rowKey: string;
}

export interface EmpTableCollapsible {
  type: "emp-table-collapsible";
  rowKey: string;
  content: JSX.Element;
}

export interface EmpTableProps<T> {
  contentColumns: EmpTableContentSpec<T>[];
  data: (T | EmpTableSeperator | EmpTableCollapsible | EmpTableEmptyRow)[];
  pagination?: EmpPaginationProps;
  rowKey: string;
  onChange?: (queryable: EmpQueryable) => void;
  loading?: boolean;
  shimmerLoading?: {
    width: number[];
    overlayDesign: JSX.Element;
  };
  progressLoading?: boolean;
  showEmptyState?: boolean;
  tableInCard?: boolean;
  paddingSize?: "md" | "sm";
  style?: React.CSSProperties;
  emptyStateHeader?: string | JSX.Element;
  emptyStateDescription?: string | JSX.Element;
  isLastColumnFixed?: boolean;
  isFirstColumnFixed?: boolean;
  collapsibleExpandedByDefault?: boolean;
}

interface EmpTableRecordType {
  [key: string]: any;
}
export const COLLAPSIBLE_BTN_COLUMN = "collapsible-btn";

export interface EmpTableRef {
  getRecordHeight: (numRecords: number, from: "top" | "bottom") => number;
}

const EmpTableComponent = <T extends EmpTableRecordType>(
  props: EmpTableProps<T>,
  ref: React.Ref<EmpTableRef>
) => {
  const {
    onChange,
    contentColumns,
    data,
    rowKey,
    pagination,
    shimmerLoading,
    isLastColumnFixed,
    isFirstColumnFixed,
    progressLoading,
  } = props;
  const loading = props.loading ?? false;
  const paddingSize = props.paddingSize ?? "md";
  const tableInCard = props.tableInCard ?? false;
  const showEmptyState = props.showEmptyState ?? true;
  const collapsibleExpandedByDefault =
    props.collapsibleExpandedByDefault ?? false;
  // Hashmap to maintain the sorting states of each column
  const [sortMap, setSortMap] = useState<{ [key: string]: EmpSortType }>({});
  const [paginationState, setPagination] = useState<EmpPaginationProps>(
    pagination ?? {}
  );

  const rowElementRef = useRef<HTMLTableRowElement[]>([]);

  const getRecordHeight = useCallback(
    (numRecords: number, from: "top" | "bottom") => {
      let rowElems: HTMLTableRowElement[];
      if (from === "top") {
        rowElems = rowElementRef.current.slice(0, numRecords);
      } else {
        rowElems = rowElementRef.current.slice(-numRecords);
      }

      let totalHeight = 0;
      for (let elem of rowElems) {
        if (elem) {
          totalHeight += elem.offsetHeight;
        }
      }
      return totalHeight;
    },
    []
  );

  const [tableIsScrolled, setTableIsScrolled] = useState<boolean>(
    !!isLastColumnFixed
  );

  useImperativeHandle(ref, () => ({
    getRecordHeight,
  }));

  const queryableRef = useRef<EmpQueryable>(pagination ?? {});
  const [queryable, setQueryable] = useState<EmpQueryable>({});

  const emptyStateHeader = props.emptyStateHeader ?? "No Results Found";
  const emptyStateDescription =
    props.emptyStateDescription ?? "Try searching for something else";

  const [collapsibleTracker, setCollapsibleTracker] = useState<
    Map<string, boolean>
  >(new Map());

  useEffect(() => {
    console.log({ collapsibleTracker });
  }, [collapsibleTracker]);

  const [collapsibleWrapperHeightMap, setCollapsibleWrapperHeightMap] =
    useState<Map<string, number>>(new Map());
  const collapsibleWrapperDivRef = useRef<Map<string, HTMLDivElement>>(
    new Map()
  );
  const hasSetInitialCollapsibleHeight = useRef(false);

  useEffect(() => {
    if (
      hasSetInitialCollapsibleHeight.current === false &&
      collapsibleTracker.size > 0
    ) {
      const heightMap = new Map();
      for (let [key, value] of collapsibleWrapperDivRef.current) {
        const height = value.offsetHeight;
        heightMap.set(key, height);
      }
      setCollapsibleWrapperHeightMap(() => {
        return new Map(heightMap);
      });
      hasSetInitialCollapsibleHeight.current = true;
    }
  }, [collapsibleTracker]);

  useEffect(() => {
    if (
      props.contentColumns.find(
        (elem) => elem.dataIndex === COLLAPSIBLE_BTN_COLUMN
      )
    ) {
      const tracker = new Map<string, boolean>();
      for (const record of props.data) {
        if (
          record.type === "emp-table-separator" ||
          record.type === "emp-table-collapsible"
        )
          continue;
        const r = record as T;
        tracker.set(r[props.rowKey], collapsibleExpandedByDefault);
        console.log("TRACKER STUFF", tracker, collapsibleExpandedByDefault);
      }
      setCollapsibleTracker(tracker);
    }
  }, [
    props.contentColumns,
    props.data,
    props.rowKey,
    collapsibleExpandedByDefault,
  ]);

  // Update queryableRef, and update pagination if there is a change detected.
  useEffect(() => {
    queryableRef.current = {
      pageNo: pagination?.currentPage ?? 1,
      pageSize: pagination?.pageSize ?? PAGINATION.PAGE_SIZE,
    };
    setPagination(pagination ?? {});
  }, [pagination]);

  // eslint-disable-next-line
  useEffect(() => {
    if (!props.contentColumns) return;
    // Initializing the sort parameters
    for (let colSpec of props.contentColumns) {
      if (!colSpec.sorter || colSpec.dataIndex in sortMap) continue;
      sortMap[colSpec.dataIndex] = "neutral";
    }
    setSortMap({ ...sortMap });
    // eslint-disable-next-line
  }, [props]);

  /**
   * Handles the change event of the sort mechanism
   * @param sortKey - The key to sort by
   */
  const onSortChange = (sortKey: string) => {
    if (!sortMap[sortKey]) throw new Error("Sort key is invalid");

    // Need to reset other sort keys
    const sortMapKeys = Object.keys(sortMap);
    sortMapKeys
      .filter((elem) => elem !== sortKey)
      .forEach((elem) => (sortMap[elem] = "neutral"));

    // Change sortKey in the sortMap
    switch (sortMap[sortKey]) {
      case "neutral":
        sortMap[sortKey] = "asc";
        break;
      case "asc":
        sortMap[sortKey] = "desc";
        break;
      case "desc":
        sortMap[sortKey] = "neutral";
        break;
      default:
        break;
    }
    setSortMap({ ...sortMap });
    // Set sort to undefined, if its in the neutral position
    const empQueryable: EmpQueryable = {
      ...queryableRef.current,
      sort:
        sortMap[sortKey] !== "neutral"
          ? { field: sortKey, direction: sortMap[sortKey] }
          : undefined,
    };
    queryableRef.current = empQueryable;
    setQueryable(empQueryable);

    // Propagate this change to the main component
    if (onChange) onChange(empQueryable);
  };

  /**
   * Renders a cell of a table
   * @param record - The record to be displayed in the cell
   * @param col - The specification of the column the cell belongs to
   * @param index - The index of the record
   * @returns The element to be displayed in the cell
   */
  const renderCell = (
    record: T,
    col: EmpTableContentSpec<T>,
    index: number
  ) => {
    // Return plain text element if the 'render' function is undefined
    const width = col.width ? `${col.width}px` : "initial";
    const whiteSpace = col.width ? "normal" : "nowrap";
    if (!col.render)
      return (
        <div style={{ width, whiteSpace }} className="emp-table-content">
          <span>{record[col.dataIndex]}</span>
        </div>
      );

    // Return element within colSpec
    return (
      <div style={{ width, whiteSpace }} className="emp-table-content">
        {col.render(record, index)}
      </div>
    );
  };

  /**
   * Renders a header cell for a table
   * @param col - The specification of the column the header belongs to
   * @returns The header cell element to be displayed
   */
  const renderHeader = (col: EmpTableContentSpec<T>): JSX.Element => {
    const width = col.width ? `${col.width}px` : "initial";
    const whiteSpace = col.width ? "normal" : "nowrap";
    if (!col.sorter)
      return (
        <th
          style={{ width, whiteSpace }}
          className={`${tableInCard ? "table-in-card" : ""} ${
            tableIsScrolled ? "shadow" : ""
          } ${isFirstColumnFixed ? "first-fixed" : ""} ${
            isLastColumnFixed ? "last-fixed" : ""
          }  ${paddingSize}-padding`}
          key={col.dataIndex}
        >
          <div className="emp-table-header">{col.title}</div>
        </th>
      );
    return (
      <th
        style={{ width, whiteSpace }}
        className={`${tableInCard ? "table-in-card" : ""} ${
          tableIsScrolled ? "shadow" : ""
        } ${isFirstColumnFixed ? "first-fixed" : ""} ${
          isLastColumnFixed ? "last-fixed" : ""
        }`}
        onClick={() => onSortChange(col.dataIndex)}
        key={col.dataIndex}
      >
        <div className="emp-table-sortable-header">
          {col.title}
          <div className="ml-2">
            <EmpSortIcon
              bottom={1}
              size={18}
              sortState={sortMap[col.dataIndex]}
            />
          </div>
        </div>
      </th>
    );
  };

  const onTableScroll = (e: React.UIEvent<HTMLTableElement, UIEvent>) => {
    const elem = e.target as HTMLTableElement;

    if (isLastColumnFixed) {
      const isScrolledToEnd =
        Math.ceil(elem.scrollWidth - elem.clientWidth) ===
        Math.ceil(elem.scrollLeft);
      setTableIsScrolled(!isScrolledToEnd);
      return;
    } else {
      setTableIsScrolled(elem.scrollLeft > 0);
    }
  };

  return (
    <div className="emp-table">
      {shimmerLoading && loading && (
        <div className="empty-overlay">{shimmerLoading.overlayDesign}</div>
      )}
      <table
        style={{ ...props.style }}
        onScroll={(e) => onTableScroll(e)}
        cellSpacing={0}
        cellPadding={0}
      >
        {/* Table Headers */}
        <thead>
          <tr>{contentColumns.map((elem) => renderHeader(elem))}</tr>
        </thead>
        {/* Table Content */}
        <tbody>
          {progressLoading !== undefined && (
            <tr>
              <td colSpan={contentColumns.length} style={{ padding: 0 }}>
                {progressLoading && (
                  <div className="emp-progress">
                    <div className="indeterminate"></div>
                  </div>
                )}
              </td>
            </tr>
          )}

          {data.length > 0 &&
            data.map((record, index) => {
              if (record.type === "emp-table-separator") {
                const rcrd = record as EmpTableSeperator;
                return (
                  <tr
                    key={v4()}
                    ref={(el: HTMLTableRowElement) => {
                      rowElementRef.current[index] = el;
                    }}
                  >
                    <td
                      className={`separator ${paddingSize}-padding`}
                      colSpan={contentColumns.length}
                    >
                      {rcrd.content}
                    </td>
                  </tr>
                );
              } else if (record.type === "emp-table-collapsible") {
                const rcrd = record as EmpTableCollapsible;
                const height = collapsibleWrapperHeightMap.get(rcrd.rowKey);
                return (
                  <tr
                    key={`${rcrd.rowKey}_collapsible`}
                    ref={(el: HTMLTableRowElement) => {
                      rowElementRef.current[index] = el;
                    }}
                  >
                    <td
                      className={`collapsible`}
                      colSpan={contentColumns.length}
                    >
                      <div
                        className="collapsible-wrapper"
                        style={{
                          height: collapsibleTracker.get(rcrd.rowKey)
                            ? "100%"
                            : 0,
                        }}
                      >
                        <div
                          ref={(elem: HTMLDivElement) =>
                            collapsibleWrapperDivRef.current.set(
                              rcrd.rowKey,
                              elem
                            )
                          }
                        >
                          {rcrd.content}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              } else if (record.type === "emp-empty-row") {
                const rcrd = record as EmpTableEmptyRow;
                return (
                  <tr
                    key={`${rcrd.rowKey}_empty`}
                    ref={(el: HTMLTableRowElement) => {
                      rowElementRef.current[index] = el;
                    }}
                  >
                    {Array.from(
                      { length: contentColumns.length },
                      (_, index) => index + 1
                    ).map((elem) => {
                      return (
                        <td>
                          <div className="empty-shimmer emp-shimmer"></div>
                        </td>
                      );
                    })}
                  </tr>
                );
              } else
                return (
                  <tr
                    key={(record as T)[rowKey]}
                    ref={(el: HTMLTableRowElement) => {
                      rowElementRef.current[index] = el;
                    }}
                  >
                    {contentColumns.map((col) => {
                      if (col.dataIndex === COLLAPSIBLE_BTN_COLUMN) {
                        const rowKey = (record as T)[props.rowKey];
                        return (
                          <td
                            className={`${tableIsScrolled ? "shadow" : ""} ${
                              isFirstColumnFixed ? "first-fixed" : ""
                            } ${isLastColumnFixed ? "last-fixed" : ""}
                            ${paddingSize}-padding`}
                            key={col.dataIndex}
                          >
                            {col.hasCollapsible!(record as T) && (
                              <EmpExpandToggleBtn
                                onClick={() => {
                                  setCollapsibleTracker((prev) => {
                                    const prevVal = prev.get(rowKey);
                                    prev.set(rowKey, !prevVal);
                                    return new Map(prev);
                                  });
                                }}
                                isExpanded={
                                  collapsibleTracker.get(rowKey) ?? false
                                }
                              />
                            )}
                          </td>
                        );
                      }
                      return (
                        <td
                          className={`${tableIsScrolled ? "shadow" : ""} ${
                            isFirstColumnFixed ? "first-fixed" : ""
                          } ${
                            isLastColumnFixed ? "last-fixed" : ""
                          } ${paddingSize}-padding`}
                          key={col.dataIndex}
                        >
                          {renderCell(record as T, col, index)}
                        </td>
                      );
                    })}
                  </tr>
                );
            })}

          {shimmerLoading &&
            loading &&
            [1, 2, 3, 4, 5].map((record) => {
              return (
                <tr key={record}>
                  {contentColumns.map((col, index) => {
                    return (
                      <td
                        className={`${tableIsScrolled ? "shadow" : ""} ${
                          isFirstColumnFixed ? "first-fixed" : ""
                        } ${
                          isLastColumnFixed ? "last-fixed" : ""
                        } ${paddingSize}-padding`}
                        key={col.dataIndex}
                      >
                        <div className="emp-table-content">
                          <div
                            className="shimmer-block emp-shimmer"
                            style={{
                              width: shimmerLoading.width[index] ?? 100,
                            }}
                          ></div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      {showEmptyState && data.length === 0 && !loading && (
        <div className="emp-empty-table">
          <span className="emp-table-title">{emptyStateHeader}</span>
          <p className="emp-table-description">{emptyStateDescription}</p>
        </div>
      )}
      <div className="pagination-wrapper">
        <EmpPagination
          className="mt-4 mr-8"
          pagination={paginationState}
          queryable={queryable}
          onChange={(queryable) => {
            queryableRef.current = queryable;
            if (onChange) onChange(queryable);
          }}
        />
      </div>
    </div>
  );
};

const EmpTable = forwardRef(EmpTableComponent) as <T>(
  props: EmpTableProps<T> & { ref?: React.Ref<any> }
) => ReturnType<typeof EmpTableComponent>;

export default EmpTable;
