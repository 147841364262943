import { useEffect, useState } from "react";
import { Color } from "../../../utilities/colors";
import GridIcon from "../../icon/grid-icon";
import ListIcon from "../../icon/list-icon";
import "./emp-toggle-view-switch.scss";

export type EmpToggleViewType = "grid" | "list";

interface Props {
  view: EmpToggleViewType;
  onChange?: (view: EmpToggleViewType) => void;
}

export const EmpToggleViewSwitch = (props: Props) => {
  const { view, onChange } = props;
  const [selectedView, setSelectedView] = useState<EmpToggleViewType>(
    props.view
  );

  useEffect(() => {
    setSelectedView(view);
  }, [view]);

  return (
    <div className="emp-toggle-view-switch">
      <div
        onClick={() => {
          if (onChange) onChange("list");
        }}
        className={`tabbed-option ${selectedView === "list" ? "active" : ""}`}
      >
        <ListIcon
          backgroundColor={
            selectedView === "list" ? Color.NEUTRAL[200] : Color.NEUTRAL[500]
          }
        />
      </div>
      <div
        onClick={() => {
          if (onChange) onChange("grid");
        }}
        className={`tabbed-option ${selectedView === "grid" ? "active" : ""}`}
      >
        <GridIcon
          backgroundColor={
            selectedView === "grid" ? Color.NEUTRAL[200] : Color.NEUTRAL[500]
          }
        />
      </div>
    </div>
  );
};
