import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./agency-payment-view.scss";
import EmpTable, {
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { useNavigate } from "react-router-dom";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import EditPaymentModal, {
  EditPaymentModalRef,
} from "../../../components/modals/edit-payment-modal";
import { FormattedMessage, useIntl } from "react-intl";
import { PermsUtils } from "../../../constants/permissions.constants";
import { PaymentRecordDto } from "../../../model/payment/payment/payment-record.dto";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import { UserDto } from "../../../model/user-management/user.dto";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import EmpManageWalletModal, {
  EmpManageWalletModalRef,
} from "../../../components/modals/manage-wallet-modal";
import useWalletAddressManager, {
  WalletInformation,
} from "../../../hooks/useWalletAddressManager";

type CurrentViewType = "brand" | "agency";
export const AgencyPaymentView = () => {
  const intl = useIntl();
  const { fetchWalletInformation } = useWalletAddressManager();
  const manageWalletModalRef = useRef<EmpManageWalletModalRef>();
  const [user, setUser] = useState<UserDto>();

  const editPaymentModalRef = useRef<EditPaymentModalRef>();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<CurrentViewType>("agency");

  const paymentContentSpec: EmpTableContentSpec<PaymentRecordDto>[] =
    useMemo(() => {
      return [
        {
          title: "",
          dataIndex: "collapsible-btn",
          hasCollapsible: (record) => {
            return record.type === "campaign-payment";
          },
        },
        {
          title: "Initiator",
          dataIndex: "initiator",
          render: (record) => {
            return (
              <div className="campaign-payment-user">
                <div className="profile-wrapper">
                  {record.initiator.initiatorType === "organisation" && (
                    <img
                      className="profile"
                      src={record.initiator.organisation!.logo}
                      alt={record.initiator.organisation!.companyName}
                    />
                  )}
                  {record.initiator.initiatorType === "user" &&
                    record.initiator.user!.imageType === "url" && (
                      <img
                        className="profile"
                        src={record.initiator.user!.imageResource}
                        alt={record.initiator.user!.fullName}
                      />
                    )}
                  {record.initiator.initiatorType === "user" &&
                    record.initiator.user!.imageType === "avatar" && (
                      <div
                        className="profile"
                        style={{
                          background: record.initiator.user!.imageResource,
                        }}
                      >
                        <span className="initials">
                          {record.initiator.user!.initials}
                        </span>
                      </div>
                    )}
                  {record.initiator.initiatorType === "system" && (
                    <img
                      className="profile"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/emplifive-system-logo.png"
                      alt={"Emplifive"}
                    />
                  )}
                </div>
                <div className="info-wrapper">
                  {record.initiator.initiatorType === "user" && (
                    <span className="creator-name">
                      {record.initiator.user!.fullName}
                    </span>
                  )}
                  {record.initiator.initiatorType === "organisation" && (
                    <span className="creator-name">
                      {record.initiator.organisation!.companyName}
                    </span>
                  )}
                  {record.initiator.initiatorType === "system" && (
                    <span className="creator-name">System</span>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "settingsPaymentView_descriptionHeader",
          }),
          dataIndex: "description",
          width: 240,
          render: (record) => {
            return <div className="description-cell">{record.description}</div>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_amountHeader" }),
          dataIndex: "amount",
          render: (record) => {
            return <>${FormFieldUtils.toMoney(Number(record.amount))}</>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_statusHeader" }),
          dataIndex: "status",
          render: (record) => {
            return (
              <>
                {record.status === "PAID" && (
                  <EmpPill text={"Paid"} {...PILL_COLORS["green"]} />
                )}
              </>
            );
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_dateHeader" }),
          dataIndex: "date",
          render: (record) => {
            return <>{DateUtil.toReadableDateWithTime(record.createdDate)}</>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_actionHeader" }),
          dataIndex: "action",
          render: (record) => {
            if (record.type === "subscription") {
              return (
                <EmpButton
                  buttonHeight="sm"
                  buttonStyle="secondary"
                  isFullWidth={false}
                  text={"To Subscription"}
                  onSubmit={() => {
                    navigate("/brand/settings#subscription");
                  }}
                />
              );
            } else if (record.type === "campaign-payment") {
              return (
                <EmpButton
                  buttonHeight="sm"
                  buttonStyle="secondary"
                  isFullWidth={false}
                  text={"To Task"}
                  onSubmit={() => {
                    navigate(
                      `/brand/campaign-details/${
                        record.campaignPayment!.campaignId
                      }/task/${record.campaignPayment!.taskId}`
                    );
                  }}
                />
              );
            }
            return <></>;
          },
        },
      ];
    }, [intl, navigate]);
  const [walletInfo, setWalletInfo] = useState<WalletInformation | null>();
  const hasDualRole: boolean = useMemo(() => {
    if (!user) return false;
    return PermsUtils.hasDualRole(user);
  }, [user]);

  const fetchWalletInfo = useCallback(async () => {
    const resp = await fetchWalletInformation();
    setWalletInfo(resp);
  }, [fetchWalletInformation]);

  useEffect(() => {
    fetchWalletInfo();
  }, [fetchWalletInfo]);
  return (
    <div className="emp-agency-payment-view">
      <EmpManageWalletModal
        ref={manageWalletModalRef}
        onSave={fetchWalletInfo}
      />
      <EditPaymentModal ref={editPaymentModalRef} onSave={() => {}} />
      <div className="view-header-wrapper">
        <div className="text-wrapper">
          <div className="text-status-wrapper">
            <h1 className="section-header">
              <FormattedMessage id="settingsPaymentView_header" />
            </h1>
            {walletInfo && walletInfo.hasWallet && (
              <EmpPill size="sm" {...PILL_COLORS.green} text={"Wallet Added"} />
            )}
            {walletInfo && !walletInfo.hasWallet && (
              <EmpPill
                size="sm"
                {...PILL_COLORS.amber}
                text={"No Wallet Added"}
              />
            )}
          </div>
          <p className="emp-paragraph">
            <FormattedMessage id="settingsPaymentView_desc" />
          </p>
        </div>
      </div>
      {walletInfo && (
        <div className="card-wrapper">
          <div className="svg-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 800 800"
              opacity="0.96"
            >
              <defs>
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad"
                >
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="100%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-2"
                >
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="0%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  gradientTransform="rotate(270)"
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-3"
                >
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="100%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  gradientTransform="rotate(270)"
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-4"
                >
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="0%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                </linearGradient>
              </defs>
              <g strokeWidth="1.5" stroke="url(#rrreflection-grad)" fill="none">
                <circle r="351" cx="50%" cy="0"></circle>
                <circle r="338" cx="50%" cy="0"></circle>
                <circle r="325" cx="50%" cy="0"></circle>
                <circle r="312" cx="50%" cy="0"></circle>
                <circle r="299" cx="50%" cy="0"></circle>
                <circle r="286" cx="50%" cy="0"></circle>
                <circle r="273" cx="50%" cy="0"></circle>
                <circle r="260" cx="50%" cy="0"></circle>
                <circle r="247" cx="50%" cy="0"></circle>
                <circle r="234" cx="50%" cy="0"></circle>
                <circle r="221" cx="50%" cy="0"></circle>
                <circle r="208" cx="50%" cy="0"></circle>
                <circle r="195" cx="50%" cy="0"></circle>
                <circle r="182" cx="50%" cy="0"></circle>
                <circle r="169" cx="50%" cy="0"></circle>
                <circle r="156" cx="50%" cy="0"></circle>
                <circle r="143" cx="50%" cy="0"></circle>
                <circle r="130" cx="50%" cy="0"></circle>
                <circle r="117" cx="50%" cy="0"></circle>
                <circle r="104" cx="50%" cy="0"></circle>
                <circle r="91" cx="50%" cy="0"></circle>
                <circle r="78" cx="50%" cy="0"></circle>
                <circle r="65" cx="50%" cy="0"></circle>
                <circle r="52" cx="50%" cy="0"></circle>
                <circle r="39" cx="50%" cy="0"></circle>
                <circle r="26" cx="50%" cy="0"></circle>
                <circle r="13" cx="50%" cy="0"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-2)"
                fill="none"
              >
                <circle r="351" cx="50%" cy="100%"></circle>
                <circle r="338" cx="50%" cy="100%"></circle>
                <circle r="325" cx="50%" cy="100%"></circle>
                <circle r="312" cx="50%" cy="100%"></circle>
                <circle r="299" cx="50%" cy="100%"></circle>
                <circle r="286" cx="50%" cy="100%"></circle>
                <circle r="273" cx="50%" cy="100%"></circle>
                <circle r="260" cx="50%" cy="100%"></circle>
                <circle r="247" cx="50%" cy="100%"></circle>
                <circle r="234" cx="50%" cy="100%"></circle>
                <circle r="221" cx="50%" cy="100%"></circle>
                <circle r="208" cx="50%" cy="100%"></circle>
                <circle r="195" cx="50%" cy="100%"></circle>
                <circle r="182" cx="50%" cy="100%"></circle>
                <circle r="169" cx="50%" cy="100%"></circle>
                <circle r="156" cx="50%" cy="100%"></circle>
                <circle r="143" cx="50%" cy="100%"></circle>
                <circle r="130" cx="50%" cy="100%"></circle>
                <circle r="117" cx="50%" cy="100%"></circle>
                <circle r="104" cx="50%" cy="100%"></circle>
                <circle r="91" cx="50%" cy="100%"></circle>
                <circle r="78" cx="50%" cy="100%"></circle>
                <circle r="65" cx="50%" cy="100%"></circle>
                <circle r="52" cx="50%" cy="100%"></circle>
                <circle r="39" cx="50%" cy="100%"></circle>
                <circle r="26" cx="50%" cy="100%"></circle>
                <circle r="13" cx="50%" cy="100%"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-3)"
                fill="none"
              >
                <circle r="351" cx="0" cy="50%"></circle>
                <circle r="338" cx="0" cy="50%"></circle>
                <circle r="325" cx="0" cy="50%"></circle>
                <circle r="312" cx="0" cy="50%"></circle>
                <circle r="299" cx="0" cy="50%"></circle>
                <circle r="286" cx="0" cy="50%"></circle>
                <circle r="273" cx="0" cy="50%"></circle>
                <circle r="260" cx="0" cy="50%"></circle>
                <circle r="247" cx="0" cy="50%"></circle>
                <circle r="234" cx="0" cy="50%"></circle>
                <circle r="221" cx="0" cy="50%"></circle>
                <circle r="208" cx="0" cy="50%"></circle>
                <circle r="195" cx="0" cy="50%"></circle>
                <circle r="182" cx="0" cy="50%"></circle>
                <circle r="169" cx="0" cy="50%"></circle>
                <circle r="156" cx="0" cy="50%"></circle>
                <circle r="143" cx="0" cy="50%"></circle>
                <circle r="130" cx="0" cy="50%"></circle>
                <circle r="117" cx="0" cy="50%"></circle>
                <circle r="104" cx="0" cy="50%"></circle>
                <circle r="91" cx="0" cy="50%"></circle>
                <circle r="78" cx="0" cy="50%"></circle>
                <circle r="65" cx="0" cy="50%"></circle>
                <circle r="52" cx="0" cy="50%"></circle>
                <circle r="39" cx="0" cy="50%"></circle>
                <circle r="26" cx="0" cy="50%"></circle>
                <circle r="13" cx="0" cy="50%"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-4)"
                fill="none"
              >
                <circle r="351" cx="100%" cy="50%"></circle>
                <circle r="338" cx="100%" cy="50%"></circle>
                <circle r="325" cx="100%" cy="50%"></circle>
                <circle r="312" cx="100%" cy="50%"></circle>
                <circle r="299" cx="100%" cy="50%"></circle>
                <circle r="286" cx="100%" cy="50%"></circle>
                <circle r="273" cx="100%" cy="50%"></circle>
                <circle r="260" cx="100%" cy="50%"></circle>
                <circle r="247" cx="100%" cy="50%"></circle>
                <circle r="234" cx="100%" cy="50%"></circle>
                <circle r="221" cx="100%" cy="50%"></circle>
                <circle r="208" cx="100%" cy="50%"></circle>
                <circle r="195" cx="100%" cy="50%"></circle>
                <circle r="182" cx="100%" cy="50%"></circle>
                <circle r="169" cx="100%" cy="50%"></circle>
                <circle r="156" cx="100%" cy="50%"></circle>
                <circle r="143" cx="100%" cy="50%"></circle>
                <circle r="130" cx="100%" cy="50%"></circle>
                <circle r="117" cx="100%" cy="50%"></circle>
                <circle r="104" cx="100%" cy="50%"></circle>
                <circle r="91" cx="100%" cy="50%"></circle>
                <circle r="78" cx="100%" cy="50%"></circle>
                <circle r="65" cx="100%" cy="50%"></circle>
                <circle r="52" cx="100%" cy="50%"></circle>
                <circle r="39" cx="100%" cy="50%"></circle>
                <circle r="26" cx="100%" cy="50%"></circle>
                <circle r="13" cx="100%" cy="50%"></circle>
              </g>
            </svg>
          </div>
          <div className="payout-wrapper">
            <div className="bank-name-wrapper">
              {walletInfo.hasWallet && (
                <EmpImage
                  fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/bank.png"
                  className="bank-logo"
                  alt={"Crypto Chain"}
                  src={walletInfo.wallet!.blockchainNetwork.icon}
                />
              )}
              <h2 className="bank-name-header">
                {" "}
                {walletInfo.hasWallet
                  ? walletInfo.wallet!.blockchainNetwork.displayName
                  : "Payout Wallet"}
              </h2>
            </div>
            <span className="bank-account-number mt-2">
              {walletInfo.hasWallet
                ? walletInfo.wallet!.walletAddress
                : "Please add your payout wallet"}
            </span>
          </div>
          <EmpButton
            text={walletInfo.hasWallet ? "Edit" : "Add Wallet"}
            isFullWidth={false}
            onSubmit={() => {
              manageWalletModalRef.current?.show(walletInfo);
            }}
          />
        </div>
      )}
      <div className="view-header-wrapper mt-6 mb-4">
        <h1 className="section-header">
          <FormattedMessage id="settingsPaymentView_recentTransactionHeader" />
        </h1>
      </div>
      {hasDualRole && (
        <div className="tabbed-option-wrapper mb-2">
          <div
            onClick={() => {
              if (currentView === "agency") return;
              setCurrentView("agency");
            }}
            className={`tabbed-option ${
              currentView === "agency" ? "active" : ""
            }`}
          >
            <span>Agency</span>
          </div>
          <div
            onClick={() => {
              if (currentView === "brand") return;
              setCurrentView("brand");
            }}
            className={`tabbed-option ${
              currentView === "brand" ? "active" : ""
            }`}
          >
            <span>Brand</span>
          </div>
        </div>
      )}
      <div className="card-wrapper  no-padding">
        {currentView === "brand" && (
          <div className="emp-card no-padding">
            <EmpTable
              shimmerLoading={{
                width: [100, 120, 80, 50, 120, 100],
                overlayDesign: (
                  <>
                    <EmpLoaderV2 isLoading={true} />
                  </>
                ),
              }}
              showEmptyState={true}
              contentColumns={paymentContentSpec}
              data={[]}
              tableInCard
              emptyStateDescription={
                <FormattedMessage id="settingsPaymentView_tableEmptyDesc" />
              }
              emptyStateHeader={
                <FormattedMessage id="settingsPaymentView_tableEmptyHeader" />
              }
              rowKey={"id"}
            />
          </div>
        )}
        {currentView === "agency" && (
          <div className="emp-card no-padding">
            <EmpTable
              shimmerLoading={{
                width: [100, 120, 80, 50, 120, 100],
                overlayDesign: (
                  <div className="table-overlay-wrapper">
                    <img
                      alt="No tasks"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/gray-loudspeaker.png"
                    />
                    <span className="header">No Payout Records</span>
                    <span className="description">
                      No Payout Transactions Found
                    </span>
                  </div>
                ),
              }}
              loading={true}
              showEmptyState={false}
              contentColumns={paymentContentSpec}
              data={[]}
              tableInCard
              rowKey={"id"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
