import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import moment from "moment";
import jstz from "jstz";

import SocialMediaManagementFacebookApi from "../../../api/smm-msvc/smm-facebook";
import SocialMediaManagementInstagramApi from "../../../api/smm-msvc/smm-instagram";
import SocialMediaUploaderApi from "../../../api/smm-msvc/smm-uploader";
import SocialMediaManagementApi from "../../../api/smm-msvc/social-media-management.api";
import { EmpButtonRef } from "../../../components/shared/emp-button/emp-button";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import {
  SocialFacebookFeedPostDto,
  SocialFacebookImagePostDto,
  SocialFacebookVideoPostDto,
  SocialFacebookStoryDto,
  SocialFacebookReelDto,
} from "../../../model/smm/smm-facebook.dto";
import {
  SocialInstagramCarouselPostDto,
  SocialInstagramReelDto,
  SocialInstagramPostDto,
  SocialInstagramStoryDto,
  SocialInstagramCarouselMediaItemDto,
} from "../../../model/smm/smm-instagram.dto";
import {
  SmDeletePostDto,
  SmPostDto,
  SmPostForMultiPostDto,
  SmPostType,
  SmUpdatePostDto,
} from "../../../model/smm/smm-post.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import FileUtils from "../../../utilities/file-util";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import ToastUtils from "../../../utilities/toast-utils";
import {
  SmUploadMediaResponseDto,
  SmUploaderCompleteMultipartUploadRequestDto,
} from "../../../model/smm/smm-uploader.dto";
import {
  SocialXPollPostRequestDto,
  SocialXPostDto,
  SocialXPostMediaDto,
  SocialXPostRequestDto,
  SocialXThreadPostRequestDto,
} from "../../../model/smm/smm-x.dto";
import SocialMediaManagementXApi from "../../../api/smm-msvc/smm-x";
import SocialMediaManagementTikTokApi from "../../../api/smm-msvc/smm-tiktok";
import {
  SocialTiktokImagePostRequestDto,
  SocialTiktokPostSettings,
  SocialTiktokVideoPostRequestDto,
} from "../../../model/smm/smm-tiktok.dto";

type ErrorObject = {
  [key: string]: string[];
};

type postUtilsType = {
  form: IFormGroup;
  postType: SmPostType | null;
  userContext: UserDto | null;
  selectedConnects: SmConnectionBriefRespDto[];
  captureSectionRef?: React.RefObject<HTMLDivElement>;
  video?: File[] | string[];
  imageFiles?: File[] | string[];
  isMultiEdit?: boolean;
  multiVideo?: Record<string, File[]>;
  multiImage?: Record<string, File[]>;
  multiForm?: IFormGroup[];
  saveToDraftBtnRef: React.MutableRefObject<EmpButtonRef | undefined>;
  postBtnRef: React.MutableRefObject<EmpButtonRef | undefined>;
  postNowBtnRef?: React.MutableRefObject<EmpButtonRef | undefined>;
  discardBtnRef: React.MutableRefObject<EmpButtonRef | undefined>;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  dismiss: () => void;

  // for X
  threadForm?: IFormGroup[];
  threadVideo?: Record<string, File[][] | string[][]>;
  threadImage?: Record<string, File[][] | string[][]>;
  multiThreadForm?: Record<string, IFormGroup[]>;

  commonForm?: Record<string, IFormGroup>;
};

export function postUtils({
  form,
  postType,
  userContext,
  selectedConnects,
  captureSectionRef,
  video,
  imageFiles,
  isMultiEdit,
  multiVideo,
  multiImage,
  multiForm,
  saveToDraftBtnRef,
  postBtnRef,
  postNowBtnRef,
  discardBtnRef,
  setIsUploading = () => {},
  dismiss,

  threadForm,
  threadVideo,
  threadImage,
  multiThreadForm,

  commonForm,
}: postUtilsType): {
  handleCreatePost: (
    type: "immediately" | "schedule",
    saveAsDraft?: boolean
  ) => Promise<void>;
  handleEditPost: (
    postData: SmPostDto,
    isEdit?: boolean,
    type?: "immediately" | "schedule",
    isDraft?: boolean
  ) => Promise<void>;
  handleEditThreadPost: (
    postData: SmPostDto,
    isEdit?: boolean,
    type?: "immediately" | "schedule",
    isDraft?: boolean
  ) => Promise<void>;
  handleCreateMultiplePosts: (
    type: "immediately" | "schedule",
    saveAsDraft?: boolean
  ) => Promise<void>;
  handleChangeLoadingState: (state: "loading" | "default") => void;
  getScheduledTime: () => number;
} {
  const getBrandId = () => {
    if (userContext?.organisation) {
      return userContext.organisation[0].id;
    }
    return "";
  };

  const handleChangeLoadingState = (state: "loading" | "default") => {
    saveToDraftBtnRef.current?.setButtonState(state);
    postBtnRef.current?.setButtonState(state);
    discardBtnRef.current?.setButtonState(state);
    if (postNowBtnRef) {
      postNowBtnRef.current?.setButtonState(state);
    }
  };

  const handleUploadCaptureSection = async () => {
    if (!captureSectionRef?.current) {
      console.error("Component reference is null");
      return;
    }

    try {
      const canvas = await html2canvas(captureSectionRef.current, {
        useCORS: true,
      });
      const blob: Blob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to convert canvas to blob"));
          }
        }, "image/png");
      });

      const filename = `preview-image-${uuidv4()}.png`;
      const file = new File([blob], filename, { type: "image/png" });
      const formData = new FormData();
      formData.append("file", file);

      const response = await SocialMediaUploaderApi.uploadMedia(formData);
      if (response.status === "success") {
        return response.data[0].url;
      } else {
        throw new Error(response.error?.message || "Failed to upload media");
      }
    } catch (error) {
      console.error("Error during upload process:", error);
      return;
    }
  };

  const renderPostLimitError = (type: "immediately" | "schedule") => {
    const date = new Date(form.dateOfPosting?.getValue());
    const time = new Date(form.timeOfPosting?.getValue());
    date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

    ToastUtils.error(
      "Error creating post",
      `Post limit is reached for ${moment(
        type === "schedule" ? date : new Date()
      ).format("ddd, DD MMM YYYY (UTCZ)")} (${jstz.determine().name()})`
    );
    return;
  };

  const getScheduledTime = () => {
    const date = new Date(form.dateOfPosting?.getValue());
    const time = new Date(form.timeOfPosting?.getValue());
    date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

    const timestampInMinutes = Math.floor(date.getTime() / 1000 / 60);
    return timestampInMinutes;
  };

  const getDuration = (form: IFormGroup): number => {
    const { pollLengthMinutes, pollLengthHours, pollLengthDays } = form;
    return (
      +pollLengthMinutes.getValue() +
      +pollLengthHours.getValue() * 60 +
      +pollLengthDays.getValue() * 1440
    );
  };

  const handleCreatePost = async (
    type: "immediately" | "schedule",
    saveAsDraft?: boolean
  ) => {
    try {
      if (!form || !commonForm) return;
      handleChangeLoadingState("loading");

      const isSchedulePost = type === "schedule";
      const commonFormValues =
        commonForm[selectedConnects[0].socialMediaUserId];
      const postType = commonFormValues.postType?.getValue();

      const processItem = async (
        item: File[] | string[],
        type: "video" | "image"
      ) => {
        // If the item is an array of Files, upload them.
        if (item && item.length > 0 && item[0] instanceof File) {
          return handleUpload(
            item as File[],
            type,
            postType as SmPostType,
            selectedConnects[0].platform
          );
        }
        // If the item is an array of strings, return the strings directly.
        return item as string[];
      };

      const [videoUrl, imageUrl] = await Promise.all([
        video ? processItem(video, "video") : null,
        imageFiles ? processItem(imageFiles, "image") : null,
      ]);

      if (video && !videoUrl) {
        return;
      }

      if (imageFiles && !imageUrl) {
        return;
      }

      const platform = selectedConnects[0]?.platform.toLowerCase();
      await createPost(
        isSchedulePost,
        platform,
        videoUrl,
        imageUrl,
        selectedConnects[0],
        saveAsDraft
      );
    } catch (error) {
      const { message } = error as any;
      if (message === "Post Limit is reached for today") {
        renderPostLimitError(type);
      } else {
        ToastUtils.error("Error creating post", message || "Unknown error");
      }
    } finally {
      handleChangeLoadingState("default");
    }
  };

  const handleCreateMultiplePosts = async (
    type: "immediately" | "schedule",
    saveAsDraft?: boolean
  ) => {
    try {
      if (!multiForm) return;
      handleChangeLoadingState("loading");
      const isSchedulePost = type === "schedule";
      const posts: SmPostForMultiPostDto[] = [];

      const handleChangePostType = (
        platform: string,
        postType: string,
        videoUrl: File[] | string[] | null,
        imageUrl: File[] | string[] | null
      ) => {
        const postTypeValue = postType;
        const hasVideo = videoUrl && videoUrl.length > 0;
        const hasImage = imageUrl && imageUrl.length > 0;

        if (platform === "facebook") {
          if (postTypeValue === "reel") {
            return postTypeValue;
          }
          if (postTypeValue === "story") {
            return postTypeValue;
          }
          if (!hasVideo && !hasImage) {
            return "feed";
          }
          if (hasVideo) {
            return "video-post";
          }
          if (hasImage) {
            return "images-post";
          }
        }
        if (platform === "instagram") {
          const hasMultipleImages = imageUrl && imageUrl?.length > 1;
          const hasMultipleVideos = videoUrl && videoUrl?.length > 1;
          const isBothMedia = hasVideo && hasImage;
          const isCarousel =
            isBothMedia || hasMultipleImages || hasMultipleVideos;
          if (isCarousel) {
            return "carousel";
          }
          if (postTypeValue === "reel") {
            return postTypeValue;
          }
          if (postTypeValue === "story") {
            return postTypeValue;
          }
          return "single-post";
        }
        if (platform === "tiktok") {
          if (hasImage) {
            return "images-post";
          }
          return "video-post";
        }

        return postTypeValue;
      };

      if (isMultiEdit) {
        for (let i = 0; i < multiForm.length; i++) {
          const form = multiForm[i];
          const {
            socialMediaUserId,
            platform: socialPlatform,
            postType,
          } = form;
          const connectId = selectedConnects.find(
            (connectId) =>
              connectId.socialMediaUserId === socialMediaUserId.getValue()
          );
          if (!connectId) {
            continue;
          }

          if (postType.getValue() === "tweet") {
            if (!multiThreadForm) {
              continue;
            }
            const formattedThreadForm =
              multiThreadForm[socialMediaUserId.getValue()];
            const formattedPostType =
              formattedThreadForm.length === 1 ? "tweet" : "thread";

            const processItem = async (
              item: File[] | string[],
              type: "video" | "image"
            ) => {
              // If the item is an array of Files, upload them.
              if (item && item.length > 0 && item[0] instanceof File) {
                return handleUpload(
                  item as File[],
                  type,
                  formattedPostType,
                  "x"
                );
              }
              // If the item is an array of strings, return the strings directly.
              return item as string[];
            };
            if (formattedPostType === "tweet") {
              const [videoUrl, imageUrl] = await Promise.all([
                threadVideo![socialMediaUserId.getValue()]
                  ? processItem(
                      threadVideo![socialMediaUserId.getValue()][0],
                      "video"
                    )
                  : null,
                threadImage![socialMediaUserId.getValue()]
                  ? processItem(
                      threadImage![socialMediaUserId.getValue()][0],
                      "image"
                    )
                  : null,
              ]);
              if (
                threadVideo![socialMediaUserId.getValue()].length !== 0 &&
                !videoUrl
              ) {
                return;
              }

              if (
                threadImage![socialMediaUserId.getValue()].length !== 0 &&
                !imageUrl
              ) {
                return;
              }

              const data = await getPostPayload(
                formattedThreadForm[0],
                isSchedulePost,
                "x",
                formattedPostType,
                videoUrl,
                imageUrl,
                saveAsDraft
              );

              const payload: SmPostForMultiPostDto = {
                social_media_user_id: socialMediaUserId.getValue(),
                platform: "x",
                post_type: formattedPostType,
                data,
              };
              posts.push(payload);
            } else {
              const [videoUrl, imageUrl] = await Promise.all([
                threadVideo![socialMediaUserId.getValue()]
                  ? Promise.all(
                      threadVideo![socialMediaUserId.getValue()].map((video) =>
                        processItem(video, "video")
                      )
                    )
                  : null,
                threadImage![socialMediaUserId.getValue()]
                  ? Promise.all(
                      threadImage![socialMediaUserId.getValue()].map((image) =>
                        processItem(image, "image")
                      )
                    )
                  : null,
              ]);

              function isContainNull(arr: any[]): boolean {
                return arr.includes(null);
              }

              if (
                threadVideo![socialMediaUserId.getValue()] &&
                isContainNull(videoUrl as (string[] | null)[])
              ) {
                ToastUtils.error(
                  "Error creating post",
                  "Please upload all videos for thread post"
                );
                return;
              }
              if (
                threadImage![socialMediaUserId.getValue()] &&
                isContainNull(imageUrl as (string[] | null)[])
              ) {
                ToastUtils.error(
                  "Error creating post",
                  "Please upload all images for thread post"
                );
                return;
              }

              const data = await getXThreadPayload(
                formattedThreadForm,
                isSchedulePost,
                videoUrl,
                imageUrl,
                saveAsDraft
              );

              const payload: SmPostForMultiPostDto = {
                social_media_user_id: socialMediaUserId.getValue(),
                platform: "x",
                post_type: formattedPostType,
                data,
              };
              posts.push(payload);
            }

            continue;
          }

          const video = multiVideo && multiVideo[socialMediaUserId.getValue()];
          const imageFiles =
            multiImage && multiImage[socialMediaUserId.getValue()];

          const processItem = async (
            item: File[] | string[],
            type: "video" | "image"
          ) => {
            // If the item is an array of Files, upload them.
            if (item && item.length > 0 && item[0] instanceof File) {
              return handleUpload(
                item as File[],
                type,
                postType.getValue(),
                socialPlatform.getValue()
              );
            }
            // If the item is an array of strings, return the strings directly.
            return item as string[];
          };

          const [videoUrl, imageUrl] = await Promise.all([
            video ? processItem(video, "video") : null,
            imageFiles ? processItem(imageFiles, "image") : null,
          ]);

          if (video && !videoUrl) {
            return;
          }

          if (imageFiles && !imageUrl) {
            return;
          }

          const post_type = handleChangePostType(
            socialPlatform.getValue(),
            postType.getValue(),
            videoUrl,
            imageUrl
          );

          const data = await getPostPayload(
            form,
            isSchedulePost,
            socialPlatform.getValue(),
            post_type,
            videoUrl,
            imageUrl,
            saveAsDraft
          );

          const payload: SmPostForMultiPostDto = {
            social_media_user_id: socialMediaUserId.getValue(),
            platform: socialPlatform.getValue(),
            post_type,
            data,
          };
          posts.push(payload);
        }
      } else {
        for (let i = 0; i < selectedConnects.length; i++) {
          const connect = selectedConnects[i];
          const commonFormValues = commonForm![connect.socialMediaUserId];
          const postType = commonFormValues.postType?.getValue();
          if (connect.platform.toLowerCase() === "x" && postType !== "poll") {
            continue;
          }
          const formattedPostType = handleChangePostType(
            connect.platform.toLowerCase(),
            postType as string,
            video ? video : null,
            imageFiles ? imageFiles : null
          );

          const processItem = async (
            item: File[] | string[],
            type: "video" | "image"
          ) => {
            // If the item is an array of Files, upload them.
            if (item && item.length > 0 && item[0] instanceof File) {
              return handleUpload(
                item as File[],
                type,
                formattedPostType,
                connect.platform
              );
            }
            // If the item is an array of strings, return the strings directly.
            return item as string[];
          };

          const [videoUrl, imageUrl] = await Promise.all([
            video ? processItem(video, "video") : null,
            imageFiles ? processItem(imageFiles, "image") : null,
          ]);

          if (video && !videoUrl) {
            return;
          }

          if (imageFiles && !imageUrl) {
            return;
          }

          const post_type = handleChangePostType(
            connect.platform.toLowerCase(),
            postType as string,
            videoUrl,
            imageUrl
          );

          const data = await getPostPayload(
            commonFormValues,
            isSchedulePost,
            connect.platform.toLowerCase(),
            post_type,
            videoUrl,
            imageUrl,
            saveAsDraft
          );
          const payload: SmPostForMultiPostDto = {
            social_media_user_id: connect.socialMediaUserId,
            platform: connect.platform.toLowerCase(),
            post_type,
            data,
          };
          posts.push(payload);
        }
        for (let i = 0; i < selectedConnects.length; i++) {
          const connect = selectedConnects[i];
          const commonFormValues = commonForm![connect.socialMediaUserId];
          const postType = commonFormValues.postType?.getValue();
          if (connect.platform.toLowerCase() !== "x" || !threadForm) {
            continue;
          }
          if (postType === "poll") {
            continue;
          }
          const formattedPostType =
            threadForm.length === 1 ? "tweet" : "thread";

          const processItem = async (
            item: File[] | string[],
            type: "video" | "image"
          ) => {
            // If the item is an array of Files, upload them.
            if (item && item.length > 0 && item[0] instanceof File) {
              return handleUpload(
                item as File[],
                type,
                formattedPostType,
                connect.platform
              );
            }
            // If the item is an array of strings, return the strings directly.
            return item as string[];
          };

          if (formattedPostType === "tweet") {
            const [videoUrl, imageUrl] = await Promise.all([
              video ? processItem(video, "video") : null,
              imageFiles ? processItem(imageFiles, "image") : null,
            ]);

            if (video && !videoUrl) {
              return;
            }

            if (imageFiles && !imageUrl) {
              return;
            }

            const data = await getPostPayload(
              threadForm[0],
              isSchedulePost,
              connect.platform.toLowerCase(),
              formattedPostType,
              videoUrl,
              imageUrl,
              saveAsDraft
            );

            const payload: SmPostForMultiPostDto = {
              social_media_user_id: connect.socialMediaUserId,
              platform: connect.platform.toLowerCase(),
              post_type: formattedPostType,
              data,
            };
            posts.push(payload);
          } else {
            const [videoUrl, imageUrl] = await Promise.all([
              threadVideo
                ? threadVideo[connect.socialMediaUserId]
                  ? Promise.all(
                      threadVideo![connect.socialMediaUserId].map((video) =>
                        processItem(video, "video")
                      )
                    )
                  : null
                : null,
              threadImage
                ? threadImage[connect.socialMediaUserId]
                  ? Promise.all(
                      threadImage![connect.socialMediaUserId].map((image) =>
                        processItem(image, "image")
                      )
                    )
                  : null
                : null,
            ]);

            if (threadVideo![connect.socialMediaUserId] && !videoUrl) {
              return;
            }
            if (threadImage![connect.socialMediaUserId] && !imageUrl) {
              return;
            }
            const data = await getXThreadPayload(
              threadForm,
              isSchedulePost,
              videoUrl,
              imageUrl,
              saveAsDraft
            );

            const payload: SmPostForMultiPostDto = {
              social_media_user_id: connect.socialMediaUserId,
              platform: connect.platform.toLowerCase(),
              post_type: formattedPostType,
              data,
            };
            posts.push(payload);
          }
        }
      }

      const response = await SocialMediaManagementApi.post.createMultiplePost(
        getBrandId(),
        { posts }
      );
      if (response.status === "success") {
        ToastUtils.success(
          "Success",
          saveAsDraft
            ? "Drafts created successfully"
            : "Posts created successfully"
        );
        dismiss();
      } else {
        ToastUtils.error("Error", "Error creating posts");
      }
    } catch (error) {
      const { message } = error as any;
      if (message === "Post Limit is reached for today") {
        renderPostLimitError(type);
      } else {
        ToastUtils.error("Error creating post", message || "Unknown error");
      }
    } finally {
      handleChangeLoadingState("default");
    }
  };

  /**
   * Asynchronously handles the upload of files, categorizing them by size and applying
   * different upload strategies based on their size (normal upload for files smaller than 50MB,
   * and multipart upload for larger files). It also truncates file names to a maximum length,
   * performs media validation, and returns the URLs of the uploaded files upon success.
   *
   * @param {File[]} files - An array of File objects to be uploaded.
   * @param {"video" | "image"} uploadType - The type of the files being uploaded, either 'video' or 'image'.
   *
   * @returns {Promise<string[] | null>} A promise that resolves to an array of URLs of the uploaded files upon successful upload and validation,
   *                                     or null if the upload or validation fails, or if initial conditions are not met.
   *
   * @throws Will throw an error if there's an issue during the file upload process.
   *
   * @example
   * // Assuming you have a File object for an image and video.
   * handleUpload([imageFile, videoFile], "image").then((urls) => {
   *   if (urls) {
   *     console.log("Uploaded files:", urls);
   *   } else {
   *     console.error("Failed to upload files.");
   *   }
   * });
   */
  const handleUpload = async (
    files: File[],
    uploadType: "video" | "image",
    postType: string,
    platform: string
  ): Promise<string[] | null> => {
    try {
      if (!selectedConnects) return null;
      const formattedPostType = postType === "post" ? "single-post" : postType;
      const fileSizeLimit = 50;

      const smallerFiles: File[] = [];
      const largerFiles: File[] = [];
      const mergedFiles: SmUploadMediaResponseDto[] = [];
      files.forEach((file) => {
        const maxNameLength = 20;
        // Get file extension
        const extension = file.name.substring(file.name.lastIndexOf("."));
        const namePartMaxLength = maxNameLength - extension.length;
        // Truncate name part
        const namePart = file.name
          .substring(0, file.name.lastIndexOf("."))
          .substring(0, namePartMaxLength);
        const truncatedFileName = `${namePart}${extension}`;
        const fileWithTruncatedName = new File([file], truncatedFileName, {
          type: file.type,
        });

        // const isSmallerThan50MB = FileUtils.isFileSizeSmallerThanMB(
        //   fileWithTruncatedName,
        //   fileSizeLimit
        // );

        // if (isSmallerThan50MB) {
        //   smallerFiles.push(fileWithTruncatedName);
        // } else {
        //   largerFiles.push(fileWithTruncatedName);
        // }
        largerFiles.push(fileWithTruncatedName);
      });

      // if (smallerFiles.length > 0) {
      //   const res: SmUploadMediaResponseDto[] = await handleUploadMedia(
      //     smallerFiles
      //   );
      //   if (!res) {
      //     return null;
      //   }
      //   res.map((file) => mergedFiles.push(file));
      // }

      // if (largerFiles.length > 0) {
      setIsUploading(true);
      for (const file of largerFiles) {
        // Step 1: Create Multipart Upload
        const multipartUploadResponse = await handleCreateMultipartUpload(file);
        if (!multipartUploadResponse) {
          console.log(`Failed to create multipart upload for ${file.name}`);
          continue; // Skip to the next file if multipart upload creation fails
        }
        const { Key: key, UploadId: uploadId } = multipartUploadResponse;

        // Step 2: Upload Parts
        let parts: any[] = [];
        await handleUploadPart(file, uploadId, key, parts);

        // Step 3: Complete Multipart Upload
        const completeMultipartData = {
          key,
          uploadId,
          parts,
        };
        const res: SmUploadMediaResponseDto | null =
          await handleCompleteMultipartUpload(completeMultipartData);

        if (!res) {
          return null;
        }
        mergedFiles.push(res);
      }
      setIsUploading(false);
      // }

      // if (mergedFiles.length !== files.length) {
      //   ToastUtils.error(
      //     `Error uploading ${uploadType}`,
      //     "Failed to upload some files"
      //   );
      //   return null;
      // }

      const response = await handleValidateMedia(
        mergedFiles,
        formattedPostType,
        [platform.toLowerCase()]
      );
      if (!response) return null;

      if (response.errors.length === 0) {
        const urls = mergedFiles.map((file) => file.url);
        return urls;
      } else {
        ToastUtils.error(
          `Error uploading ${uploadType}`,
          response.error?.message as string
        );
        return null;
      }
    } catch (error) {
      ToastUtils.error(`Error uploading ${uploadType}`, error as string);

      return null;
    }
  };

  /**
   * Asynchronously uploads an array of `File` objects to a social media platform's media service.
   * This function prepares a `FormData` object with the files to be uploaded, sends the data
   * using the `SocialMediaUploaderApi.uploadMedia` method, and handles the response. If the upload
   * is successful, it returns the data received from the server. In case of failure, it displays
   * an error notification and returns `null`.
   *
   * @param {File[]} files - An array of `File` objects representing the media files to be uploaded.
   *
   * @returns {Promise<any | null>} A promise that resolves to the server response data on successful upload,
   *                                or `null` if the upload fails or encounters an error.
   *
   * @throws {Error} Catches and logs any error that occurs during the file upload process, returning `null`.
   *                 It also displays an error notification to the user.
   *
   * @example
   * // Assuming you have selected files through an input element of type file,
   * // you can call this function to upload them.
   * handleUploadMedia(selectedFiles).then((uploadResponse) => {
   *   if (uploadResponse) {
   *     console.log("Media uploaded successfully", uploadResponse);
   *   } else {
   *     console.error("Failed to upload media.");
   *   }
   * });
   */
  const handleUploadMedia = async (files: File[]): Promise<any | null> => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await SocialMediaUploaderApi.uploadMedia(formData);

      if (response.status === "success") {
        return response.data;
      } else {
        ToastUtils.error(
          "Error uploading media",
          response.error?.message as string
        );
        return null;
      }
    } catch (error) {
      ToastUtils.error("Error uploading media", error as string);
      return null;
    }
  };

  /**
   * Asynchronously validates the media files against the specified social media platforms' requirements.
   * This function maps over the provided file URLs to create a data structure with each file's URL,
   * inferred media type (based on the file extension), and the intended post type. It then calls
   * the `SocialMediaUploaderApi.validateMedia` method with this data, along with the post type
   * and platforms for validation. On successful validation, it returns the response data.
   * If validation fails or an error occurs, an error notification is displayed, and `null` is returned.
   *
   * @param {string[]} fileUrls - An array of strings representing the URLs of the uploaded media files to be validated.
   * @param {string} postType - A string indicating the type of post the media is intended for (e.g., "post", "story").
   * @param {string[]} platforms - An array of strings representing the social media platforms to validate the media against.
   *
   * @returns {Promise<any | null>} A promise that resolves to the server's response data upon successful validation,
   *                                or `null` if the validation fails or an error occurs.
   *
   * @throws {Error} Catches and logs any error that occurs during the media validation process, returning `null`.
   *                 Additionally, it displays an error notification to the user with the error message.
   *
   * @example
   * // Validate media URLs for a specific post type and platform.
   * handleValidateMedia(["https://example.com/image.jpg"], "post", ["instagram"])
   *   .then((validationResponse) => {
   *     if (validationResponse) {
   *       console.log("Media validation successful", validationResponse);
   *     } else {
   *       console.error("Failed to validate media.");
   *     }
   *   });
   */
  const handleValidateMedia = async (
    medias: SmUploadMediaResponseDto[],
    postType: string,
    platforms: string[]
  ): Promise<any | null> => {
    try {
      const data = medias.map((media) => ({
        key: media.key,
        url: media.url,
        mediaType: media.url.includes("mp4") ? "video" : "image",
        postType,
      }));

      const response = await SocialMediaUploaderApi.validateMedia(
        postType,
        platforms,
        data
      );
      if (response.status === "success") {
        return response.data;
      } else {
        let errors: ErrorObject[];

        if (
          response.error?.message.charAt(0) !== "{" &&
          response.error?.message.charAt(0) !== "["
        ) {
          ToastUtils.error(
            "Error validating media",
            response.error?.message as string
          );
          return null;
        }

        errors = JSON.parse(response.error?.message as string);

        // Function to format the error messages
        function formatErrors(errors: ErrorObject[]): string {
          return errors
            .map((errorObj) => {
              return Object.entries(errorObj)
                .map(([url, messages]) => {
                  const decodedUrl = decodeURIComponent(url);
                  const filePart =
                    decodedUrl
                      .split("/")
                      .pop()
                      ?.split("-")
                      .pop()
                      ?.split("+")
                      .join(" ") ?? "Unknown file";
                  const formattedMessages = messages
                    .map((message, index) => `  ${index + 1}. ${message}`)
                    .join("\n");
                  return `Error for file: ${filePart}\n${formattedMessages}`;
                })
                .join("\n");
            })
            .join("\n");
        }

        ToastUtils.error("Error validating media", formatErrors(errors));
        return null;
      }
    } catch (error) {
      ToastUtils.error("Error validating media", error as string);
      return null;
    }
  };

  /**
   * Initiates a multipart upload process for a given file by generating a unique key for the file and
   * calling the `SocialMediaUploaderApi.createMultipart` method. This process is necessary for uploading
   * large files in smaller, manageable parts. If the initiation is successful, it returns the response
   * data containing essential information for the multipart upload, such as the upload ID. If the process
   * fails, it displays an error notification and returns `null`.
   *
   * @param {File} file - The file object to be uploaded using the multipart upload process. This file
   *                      should already be prepared for upload, and this function will generate a
   *                      unique key for it based on the current timestamp and the file name.
   *
   * @returns {Promise<any | null>} A promise that resolves to the data needed to proceed with the multipart
   *                                upload (e.g., upload ID) if the initiation is successful. Returns `null`
   *                                if there is an error in initiating the multipart upload process.
   *
   * @throws {Error} Captures and handles any error that occurs during the multipart upload initiation,
   *                 logging the error message and returning `null`. An error notification is displayed
   *                 to the user with the respective error message.
   *
   * @example
   * // Assuming `file` is a File object representing a large video file
   * handleCreateMultipartUpload(file).then((multipartData) => {
   *   if (multipartData) {
   *     console.log("Multipart upload initiated successfully", multipartData);
   *   } else {
   *     console.error("Failed to initiate multipart upload.");
   *   }
   * });
   */
  const handleCreateMultipartUpload = async (
    file: File
  ): Promise<any | null> => {
    try {
      const key = `uploads/${Date.now()}-${file.name}`; // The key for the file in the bucket
      const response = await SocialMediaUploaderApi.createMultipart(key);

      if (response.status === "success") {
        return response.data;
      } else {
        ToastUtils.error(
          "Error uploading video",
          response.error?.message as string
        );
        return null;
      }
    } catch (error) {
      ToastUtils.error("Error uploading video", error as string);
      return null;
    }
  };

  /**
   * Asynchronously uploads parts of a large file in chunks to a server, utilizing a presigned URL for each part.
   * This function is a part of a multipart upload process, where each chunk (part) of the file is uploaded
   * separately. It calculates the chunk size, iterates through each chunk, generates a presigned URL for the
   * part, and uploads the chunk. Upon successful upload of a chunk, its ETag (Entity Tag) and part number are
   * stored in an array to track the uploaded parts. This function supports the overall multipart upload process
   * by handling the uploading of individual parts.
   *
   * @param {File} file - The file object to be uploaded in parts.
   * @param {string} uploadId - The upload ID obtained from the initiation of a multipart upload.
   * @param {string} key - The unique key associated with the file for the multipart upload.
   * @param {Object[]} parts - An array to hold information about the successfully uploaded parts, including
   *                           the ETag and part number for each part.
   *
   * @returns {Promise<void | null>} A promise that resolves with no value when all parts are successfully uploaded,
   *                                 or `null` if an error occurs during the upload of any part.
   *
   * @throws {Error} Catches and rethrows errors related to part upload failures. An error during the upload of any
   *                 part results in an error notification displayed to the user and the upload process halting.
   *
   * @example
   * // Assuming `file` is a File object of a large video file, `uploadId` is obtained from initiating a multipart upload,
   * // `key` is the unique file identifier, and `parts` is an empty array.
   * handleUploadPart(file, uploadId, key, parts).then(() => {
   *   console.log("All parts uploaded successfully", parts);
   * }).catch((error) => {
   *   console.error("Failed to upload all parts", error);
   * });
   */
  const handleUploadPart = async (
    file: File,
    uploadId: string,
    key: string,
    parts: any[]
  ): Promise<void | null> => {
    try {
      // Calculate chunk size, e.g., 50MB per part
      const CHUNK_SIZE = 50 * 1024 * 1024; // 50MB
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const blob = file.slice(start, end);

        // Generate presigned URL for the part
        const presignedUrlResponse =
          await SocialMediaUploaderApi.generatePresignedUrl(
            partNumber,
            uploadId,
            key
          );
        const presignedUrl = presignedUrlResponse.data;

        try {
          // Upload the part to the presigned URL using Axios
          const response = await axios.put(presignedUrl, blob, {
            headers: {
              "Content-Type": file.type,
            },
          });
          const etag = response.headers["etag"];

          // Add the ETag and part number to the parts array
          parts.push({ ETag: etag, PartNumber: partNumber });
        } catch (error) {
          ToastUtils.error("Error uploading media", error as string);
          throw error; // Rethrow or handle as needed
        }
      }
    } catch (error) {
      ToastUtils.error("Error uploading media", error as string);
      return null;
    }
  };

  /**
   * Completes the multipart upload process for a file after all its parts have been uploaded.
   * This function sends a request to a server to finalize the multipart upload, providing all
   * necessary data for completion, including the list of uploaded parts and their identifiers.
   * Upon successful completion, the server typically responds with information about the uploaded
   * file, such as its URL. In case of failure, an error notification is displayed and `null` is returned.
   *
   * @param {SmUploaderCompleteMultipartUploadRequestDto} completeMultipartData - An object containing
   *        all the necessary data to complete the multipart upload. This includes identifiers like
   *        the upload ID, key, and an array of parts with their ETags and part numbers.
   *
   * @returns {Promise<any | null>} A promise that resolves to the server's response data upon successful
   *                                completion of the multipart upload, or `null` if the completion fails
   *                                or an error occurs.
   *
   * @throws {Error} Catches and logs any error that occurs during the completion of the multipart upload,
   *                 returning `null`. An error notification is displayed to the user with the respective
   *                 error message.
   *
   * @example
   * // Assuming `completeMultipartData` is an object containing the upload ID, key, and parts data required to
   * // complete the upload.
   * handleCompleteMultipartUpload(completeMultipartData).then((uploadInfo) => {
   *   if (uploadInfo) {
   *     console.log("Multipart upload completed successfully", uploadInfo);
   *   } else {
   *     console.error("Failed to complete multipart upload.");
   *   }
   * });
   */
  const handleCompleteMultipartUpload = async (
    completeMultipartData: SmUploaderCompleteMultipartUploadRequestDto
  ): Promise<SmUploadMediaResponseDto | null> => {
    try {
      const response = await SocialMediaUploaderApi.completeMultipart(
        completeMultipartData
      );

      if (response.status === "success") {
        return response.data as unknown as SmUploadMediaResponseDto;
      } else {
        ToastUtils.error(
          "Error completing multipart upload",
          response.error?.message as string
        );
        return null;
      }
    } catch (error) {
      ToastUtils.error("Error completing multipart upload", error as string);
      return null;
    }
  };

  const getPostPayload = async (
    form: IFormGroup,
    isSchedulePost: boolean,
    platform: string,
    postType: string,
    videoUrl: string[] | null,
    imageUrl: string[] | null,
    saveAsDraft?: boolean
  ) => {
    const hasImage = imageUrl && imageUrl.length > 0;
    const hasVideo = videoUrl && videoUrl.length > 0;
    const media_type = hasImage ? "image" : "video";
    const scheduled_time = isSchedulePost ? getScheduledTime() : null;
    if (platform === "facebook") {
      if (postType === "reel") {
        if (!videoUrl) {
          ToastUtils.error("Error", "Please upload a video to post");
          return;
        }

        const fileUrl = videoUrl ? videoUrl[0] : null;
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialFacebookReelDto = {
          description: form.description.getValue(),
          link: fileUrl as string,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      } else if (postType === "story") {
        if (!imageUrl && !videoUrl) {
          ToastUtils.error("Error", "Please upload an image or video to post");
          return;
        }

        const fileUrl = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialFacebookStoryDto = {
          link: fileUrl as string,
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      } else {
        if (postType === "feeds" || postType === "feed") {
          // If also no description is provided, show an error.
          if (!form.description) {
            ToastUtils.error("Error", "Please upload a video or image to post");
            return;
          }
          const preview_url = await handleUploadCaptureSection();

          const payload: SocialFacebookFeedPostDto = {
            message: form.description.getValue(),
            child_attachments: [],
            preview_url,
          };

          if (saveAsDraft) {
            payload.is_draft = true;
          }

          if (isSchedulePost) {
            payload.scheduled_time = scheduled_time as number;
          }

          return payload;
        }

        if (hasVideo || hasImage) {
          if (hasVideo) {
            const preview_url = await handleUploadCaptureSection();

            const payload: SocialFacebookVideoPostDto = {
              description: form.description.getValue(),
              file_url: videoUrl[0],
              title: "Sample Video",
              preview_url,
            };

            if (saveAsDraft) {
              payload.is_draft = true;
            }

            if (isSchedulePost) {
              payload.scheduled_time = scheduled_time as number;
            }

            return payload;
          } else if (hasImage) {
            const preview_url = await handleUploadCaptureSection();
            const payload: SocialFacebookImagePostDto = {
              message: form.description.getValue(),
              image_urls: imageUrl,
              preview_url,
            };

            if (saveAsDraft) {
              payload.is_draft = true;
            }

            if (isSchedulePost) {
              payload.scheduled_time = scheduled_time as number;
            }

            return payload;
          }
        }
      }
    }
    if (platform === "instagram") {
      if (postType === "reel") {
        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramReelDto = {
          media_url,
          caption: form.description.getValue(),
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      } else if (postType === "story") {
        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramPostDto = {
          media_url,
          caption: form.description.getValue(),
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      } else {
        const hasMultipleImages = imageUrl && imageUrl?.length > 1;
        const hasMultipleVideos = videoUrl && videoUrl?.length > 1;
        const isBothMedia = imageUrl && videoUrl;

        if (postType === "carousel") {
          const media_items: SocialInstagramCarouselMediaItemDto[] = [];

          // Create media items for videos
          if (hasMultipleVideos || isBothMedia) {
            videoUrl?.forEach((url) => {
              media_items.push({
                url,
                media_type: "video",
                caption: form.description.getValue(),
              });
            });
          }

          // Create media items for images if there are multiple images or both media types
          if (hasMultipleImages || isBothMedia) {
            imageUrl?.forEach((url) => {
              media_items.push({
                url,
                media_type: "image",
                caption: form.description.getValue(),
              });
            });
          }

          if (media_items.length === 0) {
            ToastUtils.error(
              "Error",
              "Please upload multiple images or videos to create a carousel."
            );
            return;
          }

          const preview_url = await handleUploadCaptureSection();
          const payload: SocialInstagramCarouselPostDto = {
            media_items,
            caption: form.description.getValue(),
            preview_url,
          };

          if (saveAsDraft) {
            payload.is_draft = true;
          }

          if (isSchedulePost) {
            payload.scheduled_time = scheduled_time as number;
          }

          return payload;
        }

        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramPostDto = {
          media_url,
          caption: form.description.getValue(),
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      }
    }
    if (platform === "tiktok") {
      // Prefer video over image if both are available.
      if (hasVideo) {
        const settings: SocialTiktokPostSettings = {
          privacy_level: form.privacy_level.getValue(),
          disable_duet: !form.disable_duet.getValue(),
          disable_comment: !form.disable_comment.getValue(),
          disable_stitch: !form.disable_stitch.getValue(),
          brand_content_toggle: form.brand_content_toggle.getValue(),
          brand_organic_toggle: form.brand_organic_toggle.getValue(),
        };
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialTiktokVideoPostRequestDto = {
          title: form.description.getValue(),
          video_url: videoUrl[0],
          settings,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      } else if (hasImage) {
        const settings: SocialTiktokPostSettings = {
          privacy_level: form.privacy_level.getValue(),
          disable_duet: !form.disable_duet.getValue(),
          disable_comment: !form.disable_comment.getValue(),
          disable_stitch: !form.disable_stitch.getValue(),
          brand_content_toggle: form.brand_content_toggle.getValue(),
          brand_organic_toggle: form.brand_organic_toggle.getValue(),
        };
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialTiktokImagePostRequestDto = {
          title: form.description.getValue(),
          description: form.description.getValue(),
          photo_urls: imageUrl,
          settings,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      }
    }
    if (platform === "twitter" || platform === "x") {
      if (postType === "poll") {
        const getOptions = (): string[] => {
          const optionsLength = form.pollOptionSize.getValue();
          const pollFormControlMap = [
            form.pollOption,
            form.pollOption2,
            form.pollOption3,
            form.pollOption4,
          ];

          return pollFormControlMap
            .slice(0, optionsLength)
            .map((control) => control.getValue())
            .filter(Boolean);
        };

        const duration_minutes = getDuration(form);

        const options = getOptions();

        const preview_url = await handleUploadCaptureSection();

        const payload: SocialXPollPostRequestDto = {
          text: form.description.getValue(),
          preview_url,
          options,
          duration_minutes,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        return payload;
      }

      return await getXPostPayload(
        form,
        isSchedulePost,
        videoUrl,
        imageUrl,
        saveAsDraft,
        hasImage,
        hasVideo,
        scheduled_time
      );
    }
  };

  const getXPostPayload = async (
    form: IFormGroup,
    isSchedulePost: boolean,
    videoUrl: string[] | null,
    imageUrl: string[] | null,
    saveAsDraft?: boolean,
    hasImage?: boolean | null,
    hasVideo?: boolean | null,
    scheduled_time?: number | null
  ) => {
    const media: SocialXPostMediaDto[] = [];

    if (hasVideo) {
      videoUrl!.forEach((url) => {
        media.push({
          url,
          type: "video",
        });
      });
    }

    if (hasImage) {
      imageUrl!.forEach((url) => {
        media.push({
          url,
          type: "image",
        });
      });
    }
    const preview_url = await handleUploadCaptureSection();

    const payload: SocialXPostRequestDto = {
      text: form.tweet.getValue(),
      media,
      preview_url,
    };

    if (saveAsDraft) {
      payload.is_draft = true;
    }

    if (isSchedulePost) {
      payload.scheduled_time = scheduled_time as number;
    }
    return payload;
  };

  const getXThreadPayload = async (
    threadForm: IFormGroup[],
    isSchedulePost: boolean,
    videoUrl: (string[] | null)[] | null,
    imageUrl: (string[] | null)[] | null,
    saveAsDraft?: boolean
  ): Promise<SocialXThreadPostRequestDto> => {
    const scheduled_time = isSchedulePost ? getScheduledTime() : null;
    const thread: SocialXPostRequestDto[] = [];

    const processMediaUrls = (
      mediaUrls: (string[] | null)[] | null,
      type: "image" | "video"
    ): (SocialXPostMediaDto[] | null)[] => {
      if (!mediaUrls) return [];
      return mediaUrls.map((urls) =>
        urls ? urls.map((url) => ({ url, type })) : null
      );
    };

    const images = processMediaUrls(imageUrl, "image");
    const videos = processMediaUrls(videoUrl, "video");

    threadForm.forEach((form, index) => {
      const media: SocialXPostMediaDto[] = [
        ...(images[index] || []),
        ...(videos[index] || []),
      ];
      thread.push({
        text: form.tweet.getValue(),
        media,
      });
    });

    const preview_url = await handleUploadCaptureSection();

    const payload: SocialXThreadPostRequestDto = {
      preview_url,
      thread,
      ...(saveAsDraft && { is_draft: true }),
      ...(isSchedulePost && { scheduled_time: scheduled_time as number }),
    };

    return payload;
  };

  const createPost = async (
    isSchedulePost: boolean,
    platform: string,
    videoUrl: string[] | null,
    imageUrl: string[] | null,
    selectedConnect: SmConnectionBriefRespDto,
    saveAsDraft?: boolean
  ) => {
    const media_type = imageUrl ? "image" : "video";
    const form = commonForm![selectedConnects[0].socialMediaUserId];
    const scheduled_time = isSchedulePost ? getScheduledTime() : null;

    const commonFormValues = commonForm![selectedConnects[0].socialMediaUserId];
    const postType = commonFormValues.postType?.getValue();

    if (platform === "facebook") {
      if (postType === "post") {
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : isSchedulePost
          ? "Post scheduled successfully!"
          : "Post created successfully!";
        if (
          (!videoUrl || videoUrl.length === 0) &&
          (!imageUrl || imageUrl.length === 0)
        ) {
          // If also no description is provided, show an error.
          if (!form.description) {
            ToastUtils.error("Error", "Please upload a video or image to post");
            return;
          }
          const preview_url = await handleUploadCaptureSection();

          const payload: SocialFacebookFeedPostDto = {
            message: form.description.getValue(),
            child_attachments: [],
            preview_url,
          };

          if (saveAsDraft) {
            payload.is_draft = true;
          }

          if (isSchedulePost) {
            payload.scheduled_time = scheduled_time as number;
          }

          // Otherwise, create a post with text only.
          const posted = await facebookCreatePost.createPost(
            selectedConnect,
            payload,
            successMessage
          );
          if (posted) return;
        }

        // If either video or image is available, proceed to create a post accordingly.
        if (videoUrl || imageUrl) {
          // Prefer video over image if both are available.
          if (videoUrl) {
            const preview_url = await handleUploadCaptureSection();

            const payload: SocialFacebookVideoPostDto = {
              description: form.description.getValue(),
              file_url: videoUrl[0],
              title: "Sample Video",
              preview_url,
            };

            if (saveAsDraft) {
              payload.is_draft = true;
            }

            if (isSchedulePost) {
              payload.scheduled_time = scheduled_time as number;
            }

            const posted = await facebookCreatePost.createVideoPost(
              selectedConnect,
              payload,
              successMessage
            );
            if (posted) return;
          } else if (imageUrl) {
            const preview_url = await handleUploadCaptureSection();

            const payload: SocialFacebookImagePostDto = {
              message: form.description.getValue(),
              image_urls: imageUrl,
              preview_url,
            };

            if (saveAsDraft) {
              payload.is_draft = true;
            }

            if (isSchedulePost) {
              payload.scheduled_time = scheduled_time as number;
            }
            // This condition is now only reached if there's an image but no video.
            const posted = await facebookCreatePost.createImagePost(
              selectedConnect,
              payload,
              successMessage
            );
            if (posted) return;
          }
        }
      } else if (postType === "reel") {
        if (!videoUrl) {
          ToastUtils.error("Error", "Please upload a video to post");
          return;
        }
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : "Reel posted successfully! It may take a few minutes to appear on your profile";
        const fileUrl = videoUrl ? videoUrl[0] : null;
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialFacebookReelDto = {
          description: form.description.getValue(),
          link: fileUrl as string,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }
        const posted = await facebookCreatePost.createReel(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      } else if (postType === "story") {
        if (!imageUrl && !videoUrl) {
          ToastUtils.error("Error", "Please upload an image or video to post");
          return;
        }
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : "Story posted successfully!";
        const fileUrl = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialFacebookStoryDto = {
          link: fileUrl as string,
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }
        const posted = await facebookCreatePost.createStory(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      }
    }
    if (platform === "instagram") {
      if (postType === "post") {
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : isSchedulePost
          ? "Post scheduled successfully!"
          : "Post created successfully!";
        const hasMultipleImages = imageUrl && imageUrl?.length > 1;
        const hasMultipleVideos = videoUrl && videoUrl?.length > 1;
        const isBothMedia = imageUrl && videoUrl;
        const isCarousel =
          isBothMedia || hasMultipleImages || hasMultipleVideos;

        if (isCarousel) {
          const media_items: SocialInstagramCarouselMediaItemDto[] = [];

          // Create media items for videos
          if (hasMultipleVideos || isBothMedia) {
            videoUrl?.forEach((url) => {
              media_items.push({
                url,
                media_type: "video",
                caption: form.description.getValue(),
              });
            });
          }

          // Create media items for images if there are multiple images or both media types
          if (hasMultipleImages || isBothMedia) {
            imageUrl?.forEach((url) => {
              media_items.push({
                url,
                media_type: "image",
                caption: form.description.getValue(),
              });
            });
          }

          if (media_items.length === 0) {
            ToastUtils.error(
              "Error",
              "Please upload multiple images or videos to create a carousel."
            );
            return;
          }
          const preview_url = await handleUploadCaptureSection();

          const payload: SocialInstagramCarouselPostDto = {
            media_items,
            caption: form.description.getValue(),
            preview_url,
          };

          if (saveAsDraft) {
            payload.is_draft = true;
          }

          if (isSchedulePost) {
            payload.scheduled_time = scheduled_time as number;
          }

          const posted = await instagramCreatePost.createCarousel(
            selectedConnect,
            payload,
            successMessage
          );
          if (posted) return;
          ToastUtils.error("Error", "Please upload images or videos to post");
          return;
        }

        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramPostDto = {
          media_url,
          caption: form.description.getValue(),
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        const posted = await instagramCreatePost.createPost(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      } else if (postType === "reel") {
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : "Reel posted successfully! It may take a few minutes to appear on your profile";
        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramReelDto = {
          media_url,
          caption: form.description.getValue(),
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        const posted = await instagramCreatePost.createReel(
          selectedConnect,
          payload,
          successMessage
        );

        if (posted) return;
      } else if (postType === "story") {
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : "Story posted successfully!";
        const media_url = media_type === "image" ? imageUrl![0] : videoUrl![0];
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialInstagramPostDto = {
          media_url,
          caption: form.description.getValue(),
          media_type,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        const posted = await instagramCreatePost.createStory(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      }
    }
    if (platform === "tiktok") {
      const successMessage = saveAsDraft
        ? "Post saved as draft successfully!"
        : isSchedulePost
        ? "Post scheduled successfully!"
        : "Post created successfully!";

      // Prefer video over image if both are available.
      if (videoUrl) {
        const settings: SocialTiktokPostSettings = {
          privacy_level: form.privacy_level.getValue(),
          disable_duet: !form.disable_duet.getValue(),
          disable_comment: !form.disable_comment.getValue(),
          disable_stitch: !form.disable_stitch.getValue(),
          brand_content_toggle: form.brand_content_toggle.getValue(),
          brand_organic_toggle: form.brand_organic_toggle.getValue(),
        };
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialTiktokVideoPostRequestDto = {
          title: form.description.getValue(),
          video_url: videoUrl[0],
          settings,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        const posted = await tiktokCreatePost.createVideoPost(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      } else if (imageUrl) {
        const settings: SocialTiktokPostSettings = {
          privacy_level: form.privacy_level.getValue(),
          disable_duet: !form.disable_duet.getValue(),
          disable_comment: !form.disable_comment.getValue(),
          disable_stitch: !form.disable_stitch.getValue(),
          brand_content_toggle: form.brand_content_toggle.getValue(),
          brand_organic_toggle: form.brand_organic_toggle.getValue(),
        };
        const preview_url = await handleUploadCaptureSection();

        const payload: SocialTiktokImagePostRequestDto = {
          title: form.description.getValue(),
          description: form.description.getValue(),
          photo_urls: imageUrl,
          settings,
          preview_url,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }
        // This condition is now only reached if there's an image but no video.
        const posted = await tiktokCreatePost.createImagePost(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      }
    }
    if (platform === "twitter" || platform === "x") {
      if (postType === "poll") {
        const successMessage = saveAsDraft
          ? "Post saved as draft successfully!"
          : isSchedulePost
          ? "Post scheduled successfully!"
          : "Post created successfully!";

        const getOptions = (): string[] => {
          const optionsLength = form.pollOptionSize.getValue();
          const pollFormControlMap = [
            form.pollOption,
            form.pollOption2,
            form.pollOption3,
            form.pollOption4,
          ];

          return pollFormControlMap
            .slice(0, optionsLength)
            .map((control) => control.getValue())
            .filter(Boolean);
        };

        const duration_minutes = getDuration(form);

        const options = getOptions();

        const preview_url = await handleUploadCaptureSection();

        const payload: SocialXPollPostRequestDto = {
          text: form.description.getValue(),
          preview_url,
          options,
          duration_minutes,
        };

        if (saveAsDraft) {
          payload.is_draft = true;
        }

        if (isSchedulePost) {
          payload.scheduled_time = scheduled_time as number;
        }

        const posted = await xCreatePost.createPoll(
          selectedConnect,
          payload,
          successMessage
        );
        if (posted) return;
      }
      const successMessage = saveAsDraft
        ? "Post saved as draft successfully!"
        : isSchedulePost
        ? "Post scheduled successfully!"
        : "Post created successfully!";
      const media: SocialXPostMediaDto[] = [];

      if (videoUrl) {
        videoUrl.forEach((url) => {
          media.push({
            url,
            type: "video",
          });
        });
      }

      if (imageUrl) {
        imageUrl.forEach((url) => {
          media.push({
            url,
            type: "image",
          });
        });
      }
      const preview_url = await handleUploadCaptureSection();

      const payload: SocialXPostRequestDto = {
        text: form.description.getValue(),
        media,
        preview_url,
      };

      if (saveAsDraft) {
        payload.is_draft = true;
      }

      if (isSchedulePost) {
        payload.scheduled_time = scheduled_time as number;
      }

      const posted = await xCreatePost.createTweet(
        selectedConnect,
        payload,
        successMessage
      );
      if (posted) return;
    }
  };

  const instagramCreatePost = {
    getInstagramMedia: async () => {},
    createPost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialInstagramPostDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementInstagramApi.createSinglePost(
        getBrandId(),
        selectedConnect!.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
    createReel: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialInstagramReelDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementInstagramApi.createReel(
        getBrandId(),
        selectedConnect!.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
    createCarousel: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialInstagramCarouselPostDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementInstagramApi.createCarousel(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
    createStory: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialInstagramStoryDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementInstagramApi.createStory(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
  };

  const facebookCreatePost = {
    createPost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialFacebookFeedPostDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementFacebookApi.createFeed(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },

    createImagePost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialFacebookImagePostDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementFacebookApi.createImagePost(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        //  post success
        dismiss();
        ToastUtils.success("Success", successMessage);
        return true;
      }
      return false;
    },

    createVideoPost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialFacebookVideoPostDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementFacebookApi.createVideoPost(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        //  post success
        dismiss();
        ToastUtils.success("Success", successMessage);
        return true;
      }
      return false;
    },

    createReel: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: any,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementFacebookApi.createReel(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },

    createStory: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialFacebookStoryDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementFacebookApi.createStory(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
  };

  const tiktokCreatePost = {
    createVideoPost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialTiktokVideoPostRequestDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementTikTokApi.createVideoPost(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },

    createImagePost: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialTiktokImagePostRequestDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementTikTokApi.createImagePost(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
  };

  const xCreatePost = {
    createTweet: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialXPostRequestDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementXApi.createTweet(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },

    createThread: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialXThreadPostRequestDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementXApi.createThread(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },

    createPoll: async (
      selectedConnect: SmConnectionBriefRespDto,
      payload: SocialXPollPostRequestDto,
      successMessage: string
    ) => {
      const response = await SocialMediaManagementXApi.createPoll(
        getBrandId(),
        selectedConnect.socialMediaUserId,
        payload
      );
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
        return true;
      }
      return false;
    },
  };

  const handleEditPost = async (
    postData: SmPostDto,
    isEdit?: boolean,
    type?: "immediately" | "schedule",
    isDraft?: boolean
  ) => {
    try {
      if (!form) return;
      handleChangeLoadingState("loading");
      const { id: postId, metadata, socialSpec, postType } = postData;

      const successMessage = isEdit
        ? isDraft
          ? "Draft updated successfully!"
          : "Post updated successfully!"
        : type === "schedule"
        ? "Post scheduled successfully"
        : "Post published successfully!";

      const formattedRecord = JSON.parse(metadata);

      const { key } = getMediaKey(formattedRecord);

      const processItem = async (
        item: File[] | string[],
        type: "video" | "image"
      ) => {
        // If the item is an array of Files, upload them.
        if (item && item.length > 0 && item[0] instanceof File) {
          return handleUpload(
            item as File[],
            type,
            postType as SmPostType,
            socialSpec.platform
          );
        }
        // If the item is an array of strings, return the strings directly.
        return item as string[];
      };

      const [videoUrl, imageUrl] = await Promise.all([
        video ? processItem(video, "video") : null,
        imageFiles ? processItem(imageFiles, "image") : null,
      ]);

      if (video && !videoUrl) {
        return;
      }

      if (imageFiles && !imageUrl) {
        return;
      }

      async function updateMetaData(
        metadata: any,
        videoUrl: string[],
        imageUrl: string[],
        key: string,
        platform: string
      ) {
        // Split the key to get individual media item keys
        const keys = key.split(",");
        // Update description
        if (metadata.caption !== undefined && metadata.caption !== null) {
          metadata.caption = form.description.getValue();
        } else if (
          metadata.message !== undefined &&
          metadata.message !== null
        ) {
          metadata.message = form.description.getValue();
        } else if (
          metadata.description !== undefined &&
          metadata.description !== null
        ) {
          metadata.description = form.description.getValue();
        } else if (metadata.text !== undefined && metadata.text !== null) {
          metadata.text = form.description.getValue();
        } else if (metadata.title !== undefined && metadata.title !== null) {
          metadata.title = form.description.getValue();
        }

        // clear media items
        if (metadata.media_items) {
          metadata.media_items = [];
        }

        if (metadata.media) {
          metadata.media = [];
        }

        //index for media items
        let tempIndex = 0;

        // Update video URLs
        if (videoUrl) {
          if (keys.includes("video_urls")) {
            metadata.video_urls[0] = videoUrl; // Update the first URL if it's an array
          } else if (keys.includes("media_url")) {
            metadata.media_url = videoUrl[0]; // Assume 'media_url' is used for videos
            metadata.media_type = "video";
          } else if (keys.includes("file_url")) {
            metadata.file_url = videoUrl[0]; // Assume 'file_url' is used for videos
          } else if (keys.includes("link")) {
            metadata.link = videoUrl[0]; // Assume 'link' can be used for a video
            metadata.media_type = "video";
          } else if (keys.includes("media_items")) {
            videoUrl.forEach((url, index) => {
              const payload = {
                media_type: "video",
                url: url,
                caption: "",
              };
              metadata.media_items[index] = payload;
              tempIndex++;
            });
          } else if (keys.includes("media")) {
            videoUrl.forEach((url, index) => {
              const payload = {
                type: "video",
                url: url,
              };
              metadata.media[index] = payload;
            });
          } else if (keys.includes("video_url")) {
            metadata.video_url = videoUrl[0];
          }
        }

        // Update image URLs
        if (imageUrl) {
          if (keys.includes("image_urls")) {
            imageUrl.forEach((url, index) => {
              metadata.image_urls[index] = url;
            });
          } else if (keys.includes("link")) {
            metadata.link = imageUrl[0]; // Assume 'link' can be used for an image
          } else if (keys.includes("media_url")) {
            metadata.media_url = imageUrl[0]; // Assume 'media_url' is used for an image
            metadata.media_type = "image";
          } else if (keys.includes("media_items")) {
            imageUrl.forEach((url) => {
              const payload = {
                media_type: "image",
                url: url,
                caption: "",
              };
              metadata.media_items[tempIndex] = payload;
              tempIndex++;
            });
          } else if (keys.includes("media")) {
            imageUrl.forEach((url, index) => {
              const payload = {
                type: "image",
                url: url,
              };
              metadata.media[index] = payload;
            });
          } else if (keys.includes("photo_urls")) {
            metadata.photo_urls = imageUrl;
          }
        }

        // for tiktok config
        if (platform === "tiktok") {
          const settings: SocialTiktokPostSettings = {
            privacy_level: form.privacy_level.getValue(),
            disable_duet: !form.disable_duet.getValue(),
            disable_comment: !form.disable_comment.getValue(),
            disable_stitch: !form.disable_stitch.getValue(),
            brand_content_toggle: form.brand_content_toggle.getValue(),
            brand_organic_toggle: form.brand_organic_toggle.getValue(),
          };
          metadata.settings = settings;
        }

        if (postType === "poll-tweet") {
          const getOptions = (): string[] => {
            const optionsLength = form.pollOptionSize.getValue();
            const pollFormControlMap = [
              form.pollOption,
              form.pollOption2,
              form.pollOption3,
              form.pollOption4,
            ];

            return pollFormControlMap
              .slice(0, optionsLength)
              .map((control) => control.getValue())
              .filter(Boolean);
          };
          metadata.options = getOptions();
          const duration_minutes = getDuration(form);
          metadata.duration_minutes = duration_minutes;
        }

        return metadata;
      }

      const updatedMetadata = await updateMetaData(
        formattedRecord,
        videoUrl as string[],
        imageUrl as string[],
        key,
        socialSpec.platform.toLowerCase()
      );
      let formattedMetadata = JSON.stringify(updatedMetadata);

      const payload: SmUpdatePostDto = {
        postIds: [postId.toString()],
        data: {
          metadata: formattedMetadata,
        },
      };

      if (!isEdit) {
        const isSchedulePost = type === "schedule";

        const scheduled_time = isSchedulePost ? getScheduledTime() : 0;

        payload.data.scheduledTime = scheduled_time;
      }

      if (isDraft) {
        payload.data.is_draft = true;
      }

      const response = await SocialMediaManagementApi.post.updatePost(payload);
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
      }
      return;
    } catch (error) {
      const errorMessage = isDraft
        ? "Error editing draft"
        : "Error editing post";

      ToastUtils.error(
        errorMessage,
        (error as any)?.message || "Unknown error"
      );
    } finally {
      handleChangeLoadingState("default");
    }
  };

  const handleEditThreadPost = async (
    postData: SmPostDto,
    isEdit?: boolean,
    type?: "immediately" | "schedule",
    isDraft?: boolean
  ) => {
    try {
      if (!threadForm) return;
      handleChangeLoadingState("loading");
      const { id: postId, metadata, socialSpec } = postData;
      const { socialMediaUserId } = socialSpec;
      const successMessage = isEdit
        ? isDraft
          ? "Draft updated successfully!"
          : "Post updated successfully!"
        : type === "schedule"
        ? "Post scheduled successfully"
        : "Post published successfully!";

      const threads: any[] = [];
      let isUploadNewVideo: boolean = false;
      let isUploadNewImage: boolean = false;

      const processItem = async (
        item: File[] | string[],
        type: "video" | "image"
      ) => {
        // If the item is an array of Files, upload them.
        if (item && item.length > 0 && item[0] instanceof File) {
          if (type === "video") {
            isUploadNewVideo = true;
          } else {
            isUploadNewImage = true;
          }
          return handleUpload(
            item as File[],
            type,
            postType as SmPostType,
            socialSpec.platform
          );
        }
        // If the item is an array of strings, return the strings directly.
        return item as string[];
      };

      const [videoUrl, imageUrl] = await Promise.all([
        threadVideo![socialMediaUserId]
          ? Promise.all(
              threadVideo![socialMediaUserId].map((video) =>
                processItem(video, "video")
              )
            )
          : null,
        threadImage![socialMediaUserId]
          ? Promise.all(
              threadImage![socialMediaUserId].map((image) =>
                processItem(image, "image")
              )
            )
          : null,
      ]);

      function isContainNull(arr: any[], checkValue: boolean): boolean {
        if (checkValue) {
          return arr.includes(null);
        } else {
          return false;
        }
      }

      if (
        threadVideo![socialMediaUserId] &&
        isContainNull(videoUrl as (string[] | null)[], isUploadNewVideo)
      ) {
        return;
      }
      if (
        threadImage![socialMediaUserId] &&
        isContainNull(imageUrl as (string[] | null)[], isUploadNewImage)
      ) {
        return;
      }

      for (const [i, form] of threadForm.entries()) {
        const metadata: any = {
          text: form.tweet.getValue(),
          media: [],
        };

        // Update video URLs
        if (videoUrl && videoUrl[i]) {
          videoUrl[i]!.forEach((url) => {
            metadata.media.push({
              type: "video",
              url: url,
            });
          });
        }

        // Update image URLs
        if (imageUrl && imageUrl[i]) {
          imageUrl[i]!.forEach((url) => {
            metadata.media.push({
              type: "image",
              url: url,
            });
          });
        }

        threads.push(metadata);
      }
      const formattedRecord = JSON.parse(metadata);

      const updatedMetadata = {
        preview_url: formattedRecord?.preview_url,
        thread: threads,
      };

      let formattedMetadata = JSON.stringify(updatedMetadata);

      const payload: SmUpdatePostDto = {
        postIds: [postId.toString()],
        data: {
          metadata: formattedMetadata,
        },
      };

      if (!isEdit) {
        const isSchedulePost = type === "schedule";

        const scheduled_time = isSchedulePost ? getScheduledTime() : 0;

        payload.data.scheduledTime = scheduled_time;
      }

      if (isDraft) {
        payload.data.is_draft = true;
      }

      const response = await SocialMediaManagementApi.post.updatePost(payload);
      if (response.status === "success") {
        ToastUtils.success("Success", successMessage);
        dismiss();
      }
      return;
    } catch (error) {
      const errorMessage = isDraft
        ? "Error editing draft"
        : "Error editing post";

      ToastUtils.error(
        errorMessage,
        (error as any)?.message || "Unknown error"
      );
    } finally {
      handleChangeLoadingState("default");
    }
  };

  const getMediaKey = (formattedRecord: any): { key: string } => {
    const {
      image_urls,
      video_urls,
      media_url,
      media_items,
      link,
      file_url,
      photo_urls,
      video_url,
      media,
    } = formattedRecord;

    let keys = [];

    if (link) {
      keys.push("link");
    }
    if (file_url) {
      keys.push("file_url");
    }
    if (image_urls?.length) {
      keys.push("image_urls");
    }
    if (video_urls?.length) {
      keys.push("video_urls");
    }
    if (media_url) {
      keys.push("media_url");
    }
    if (media_items?.length) {
      keys.push("media_items");
    }

    if (photo_urls?.length) {
      keys.push("photo_urls");
    }

    if (video_url) {
      keys.push("video_url");
    }
    if (media?.length) {
      keys.push("media");
    }

    const key = keys.join(",");

    return { key };
  };
  return {
    handleCreatePost,
    handleCreateMultiplePosts,
    handleEditPost,
    handleChangeLoadingState,
    handleEditThreadPost,
    getScheduledTime,
  };
}

type DeletePostUtilsType = {
  dismiss?: () => void;
  getPost?: () => void;
};

export function deletePostUtils({
  dismiss = () => {},
  getPost = () => {},
}: DeletePostUtilsType): {
  handleDeleteDraft: (postId: string) => Promise<void>;
  handleDeletePost: (postId: string) => Promise<void>;
} {
  const handleDeleteDraft = async (postId: string) => {
    try {
      const payload: SmDeletePostDto = {
        postIds: [postId],
      };
      const response = await SocialMediaManagementApi.post.deletePost(payload);
      if (response.status === "success") {
        ToastUtils.success("Success", "Draft deleted successfully!");

        dismiss();
        getPost();
      }
      return;
    } catch (error) {
      ToastUtils.error("Error", "Error deleting draft");
      return;
    }
  };

  const handleDeletePost = async (postId: string) => {
    try {
      const payload: SmDeletePostDto = {
        postIds: [postId],
      };
      const response = await SocialMediaManagementApi.post.deletePost(payload);
      if (response.status === "success") {
        ToastUtils.success("Success", "Post deleted successfully!");

        dismiss();
        getPost();
      }
      return;
    } catch (error) {
      ToastUtils.error("Error", "Error deleting post");
      return;
    }
  };

  return {
    handleDeleteDraft,
    handleDeletePost,
  };
}
