import { useAbly } from "ably/react";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { DeliverableConversationDto } from "../../../../model/campaign/deliverable-conversation.dto";
import { DeliverableMessageWIdDto } from "../../../../model/campaign/deliverable-message-w-id.dto";
import { TaskDto } from "../../../../model/campaign/task.dto";
import { ChatUtil } from "../../../../utilities/chat.util";
import "./seller-chat-mobile.scss";
import SellerChatModal, { SellerChatModalRef } from "./seller-chat-modal";
import { Color } from "../../../../utilities/colors";

interface Props {
  task: TaskDto;
  conversations?: DeliverableConversationDto[];
  representativeId: string;
  onRefresh: () => void;
}

const chatItemVariant = {
  initial: {},
  tap: { scale: 0.95, backgroundColor: Color.NEUTRAL[800] },
};

export const SellerChatMobile = (props: Props) => {
  const { conversations, representativeId, onRefresh, task } = props;
  const [isLoading, setLoading] = useState(true);
  const sellerChatModalRef = useRef<SellerChatModalRef>();

  const [loadedConversations, setLoadedConversations] = useState<
    DeliverableConversationDto[] | undefined
  >(conversations);
  const ably = useAbly();

  useEffect(() => {
    if (!conversations) return;
    setLoadedConversations(conversations);
    const unsubscribeFunctions: any[] = [];
    for (const conversation of conversations) {
      const channel = ably.channels.get(conversation.id);
      channel.subscribe((message) => {
        const newMessage = message.data as DeliverableMessageWIdDto;
        const editedConversation = conversations.find(
          (elem) => elem.id === conversation.id
        );
        if (!editedConversation) return;
        editedConversation.lastMessage = ChatUtil.getLastMessage(newMessage);
        if (newMessage.senderId !== representativeId) {
          editedConversation.unreadCount += 1;
        }
        setLoadedConversations([...conversations]);
      });
      // Add each unsubscribe function to the array
      unsubscribeFunctions.push(() => channel.unsubscribe());
    }
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [ably, conversations]);

  useEffect(() => {
    if (conversations) setLoading(false);
  }, [conversations]);

  return (
    <div className="emp-seller-mobile show-on-mobile-flex">
      <SellerChatModal
        task={task}
        representativeId={representativeId}
        onDismiss={() => {
          onRefresh();
        }}
        ref={sellerChatModalRef}
      />
      <div className="conversation-header">
        <h2>Conversations</h2>
      </div>

      <section className="chat-item-section">
        {/* Loading State */}

        {isLoading && (
          <>
            <div className="skeletal-chat-item">
              <div className="skeletal-profile-pic emp-shimmer"></div>
              <div className="skeletal-msg-wrapper">
                <div className="skeletal-name emp-shimmer"></div>
                <div className="skeletal-message-wrapper">
                  <div className="skeletal-message-div">
                    <div className="skeletal-message emp-shimmer"></div>
                    <div className="skeletal-message-2 emp-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="skeletal-chat-item">
              <div className="skeletal-profile-pic emp-shimmer"></div>
              <div className="skeletal-msg-wrapper">
                <div className="skeletal-name emp-shimmer"></div>
                <div className="skeletal-message-wrapper">
                  <div className="skeletal-message-div">
                    <div className="skeletal-message emp-shimmer"></div>
                    <div className="skeletal-message-2 emp-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="skeletal-chat-item">
              <div className="skeletal-profile-pic emp-shimmer"></div>
              <div className="skeletal-msg-wrapper">
                <div className="skeletal-name emp-shimmer"></div>
                <div className="skeletal-message-wrapper">
                  <div className="skeletal-message-div">
                    <div className="skeletal-message emp-shimmer"></div>
                    <div className="skeletal-message-2 emp-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!isLoading && loadedConversations && (
          <>
            {loadedConversations.map((elem) => {
              return (
                <motion.div
                  onClick={() => {
                    sellerChatModalRef.current?.show(elem.id, elem);
                  }}
                  variants={chatItemVariant}
                  whileTap="tap"
                  className="chat-item"
                  key={elem.id}
                >
                  <div className="profile-pic-wrapper">
                    <img
                      className="profile-pic org"
                      src={elem.brand.logo}
                      alt={elem.brand.companyName}
                    />
                    {elem.representativeRole === "agency" && (
                      <>
                        {elem.creator.imageType === "url" && (
                          <img
                            className="profile-pic badge"
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className="profile-pic badge"
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="avatar">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="msg-wrapper">
                    <span className="name-lbl">{elem.brand.companyName}</span>
                    <div className="message-wrapper">
                      <div className="message-div">
                        <p className="emp-paragraph last-message-lbl">
                          {!elem.lastMessage
                            ? "No Messages yet..."
                            : elem.lastMessage}
                        </p>
                      </div>
                    </div>
                  </div>
                  {elem.unreadCount > 0 && (
                    <div className="unread-badge">{elem.unreadCount}</div>
                  )}
                </motion.div>
              );
            })}
          </>
        )}
      </section>
    </div>
  );
};
