import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { SmConnectionBriefRespDto } from "../../model/smm/smm-connection-brief-resp.dto";
import {
  GetPostsResponseDto,
  SmCreateMultiplePostReqDto,
  SmDeletePostDto,
  SmGetPostsQueryDto,
  SmUpdatePostDto,
} from "../../model/smm/smm-post.dto";
import { SocialTiktokAuthResponseDto } from "../../model/smm/smm-tiktok.dto";
import { SocialXAuthResponseDto } from "../../model/smm/smm-x.dto";
import { SmLoginReqDto } from "../../model/social-media/sm-login-req.dto";
import { TtMediaInfoDto } from "../../model/tiktok/tt-media-info-resp.dto";
import { EmpInterceptor } from "../emp-interceptor";

type OriginType = "brand-tiktok" | "brand-x";

const SocialMediaManagementApi = {
  post: {
    createMultiplePost: async (
      brandId: string,
      request: SmCreateMultiplePostReqDto
    ): Promise<ApiResponse<ApiStatus>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.post<ApiResponse<ApiStatus>>(
        ENDPOINTS.smmManagement.post.createMultiplePost(brandId),
        request
      );
      return response.data;
    },
    getAllPost: async (
      query: SmGetPostsQueryDto
    ): Promise<ApiResponse<GetPostsResponseDto>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.get<ApiResponse<GetPostsResponseDto>>(
        ENDPOINTS.smmManagement.post.getAllPost,
        { params: query }
      );
      return response.data;
    },
    updatePost: async (
      request: SmUpdatePostDto
    ): Promise<ApiResponse<ApiStatus>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.put<ApiResponse<ApiStatus>>(
        ENDPOINTS.smmManagement.post.updatePost,
        request
      );
      return response.data;
    },
    deletePost: async (
      request: SmDeletePostDto
    ): Promise<ApiResponse<ApiStatus>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.delete<ApiResponse<ApiStatus>>(
        ENDPOINTS.smmManagement.post.deletePost,
        { data: request }
      );
      return response.data;
    },
  },

  getSocialMediaConnection: async (
    brandId: string
  ): Promise<ApiResponse<SmConnectionBriefRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<SmConnectionBriefRespDto[]>>(
      ENDPOINTS.smmManagement.getSocialMediaConnection(brandId)
    );
    return response.data;
  },

  smLogin: async (
    brandId: string,
    request: SmLoginReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.smBind(brandId),
      request
    );
    return response.data;
  },

  unlinkSocialMedia: async (
    brandId: string,
    socialMediaPlatform: string,
    socialUserId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.smUnBind(
        brandId,
        socialMediaPlatform,
        socialUserId
      )
    );
    return response.data;
  },
  tiktok: {
    auth: async (
      brandId: string
    ): Promise<ApiResponse<SocialTiktokAuthResponseDto>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.get<
        ApiResponse<SocialTiktokAuthResponseDto>
      >(`${ENDPOINTS.smmManagement.tiktok.auth}?brandId=${brandId}`);
      return response.data;
    },

    getToken: async (
      code: string,
      brandId: string
    ): Promise<ApiResponse<ApiStatus>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.post<ApiResponse<ApiStatus>>(
        `${ENDPOINTS.smmManagement.tiktok.getToken}?brandId=${brandId}`,
        { code }
      );
      return response.data;
    },

    basicProfile: async (
      brandId: string,
      socialMediaUserId: string
    ): Promise<ApiResponse<TtMediaInfoDto>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.get<ApiResponse<TtMediaInfoDto>>(
        ENDPOINTS.smmManagement.tiktok.basicProfile(brandId, socialMediaUserId)
      );
      return response.data;
    },
  },
  x: {
    auth: async (
      brandId: string,
      origin: OriginType
    ): Promise<ApiResponse<SocialXAuthResponseDto>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.get<ApiResponse<SocialXAuthResponseDto>>(
        `${ENDPOINTS.smmManagement.x.auth}?origin=${origin}&brandId=${brandId}`
      );
      return response.data;
    },
    getToken: async (
      code: string,
      brandId: string,
      origin: OriginType
    ): Promise<ApiResponse<ApiStatus>> => {
      const axios = await EmpInterceptor.axiosClient();
      const response = await axios.post<ApiResponse<ApiStatus>>(
        `${ENDPOINTS.smmManagement.x.getToken}?origin=${origin}&brandId=${brandId}`,
        { code }
      );
      return response.data;
    },
  },
};

export default SocialMediaManagementApi;
