import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ActiveTaskDto } from "../../model/campaign/active-task.dto";
import { BrandCampaignMetricsDto } from "../../model/campaign/brand-campaign-metrics.dto";
import { BrandTaskStatusBreakdownDto } from "../../model/campaign/brand-task-status-breakdown.dto";
import { CampaignAndTaskOptionsDto } from "../../model/campaign/campaign-and-task-options.dto";
import { CreatorActiveTaskDto } from "../../model/campaign/creator-active-task.dto";
import { OngoingTaskMetricsDto } from "../../model/campaign/ongoing-task-metrics.dto";
import { TaskDeliverableCompletionBreakdownDto } from "../../model/campaign/task-deliverable-completion-breakdown.dto";
import { TaskPaymentSummaryDto } from "../../model/campaign/task-payment-summary.dto";
import { EmpInterceptor } from "../emp-interceptor";

const CampaignSummaryApi = {
  fetchCampaignMetrics: async (): Promise<
    ApiResponse<BrandCampaignMetricsDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<BrandCampaignMetricsDto>>(
      `${ENDPOINTS.campaignMsvc.campaignSummary.getMetrics}`
    );
    return response.data;
  },
  fetchCampaignOptions: async (
    campaignId: string
  ): Promise<ApiResponse<CampaignAndTaskOptionsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CampaignAndTaskOptionsDto>>(
      ENDPOINTS.campaignMsvc.campaignSummary.getOptions(campaignId)
    );
    return response.data;
  },

  fetchTaskBreakdown: async (
    campaignId?: string,
    taskId?: string
  ): Promise<ApiResponse<BrandTaskStatusBreakdownDto>> => {
    const queryParams = new URLSearchParams();
    if (campaignId) {
      queryParams.append("campaignId", campaignId);
    }
    if (taskId) {
      queryParams.append("taskId", taskId);
    }
    const queryString = queryParams.toString();
    const queryUrl = queryString ? `?${queryString}` : ""; // Add the question mark if there are query parameters
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<BrandTaskStatusBreakdownDto>>(
      `${ENDPOINTS.campaignMsvc.campaignSummary.getTaskBreakdown}${queryUrl}`
    );
    return response.data;
  },
  fetchBrandActiveTasks: async (
    brandOrgId: string
  ): Promise<ApiResponse<ActiveTaskDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ActiveTaskDto[]>>(
      ENDPOINTS.campaignMsvc.campaignSummary.fetchBrandActiveTasks(brandOrgId)
    );
    return response.data;
  },
  fetchAgencyActiveTasks: async (
    agencyOrgId: string
  ): Promise<ApiResponse<ActiveTaskDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ActiveTaskDto[]>>(
      ENDPOINTS.campaignMsvc.campaignSummary.fetchAgencyActiveTasks(agencyOrgId)
    );
    return response.data;
  },
  fetchCreatorActiveTask: async (): Promise<
    ApiResponse<CreatorActiveTaskDto[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorActiveTaskDto[]>>(
      ENDPOINTS.campaignMsvc.campaignSummary.fetchCreatorActiveTask
    );
    return response.data;
  },
  fetchTaskDeliverableBreakdownByBrand: async (
    taskId: string
  ): Promise<ApiResponse<TaskDeliverableCompletionBreakdownDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<TaskDeliverableCompletionBreakdownDto[]>
    >(
      ENDPOINTS.campaignMsvc.campaignSummary.getTaskDeliverableBreakdownByBrand(
        taskId
      )
    );
    return response.data;
  },
  fetchTaskDeliverableBreakdownBySeller: async (
    taskId: string
  ): Promise<ApiResponse<TaskDeliverableCompletionBreakdownDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<TaskDeliverableCompletionBreakdownDto[]>
    >(
      ENDPOINTS.campaignMsvc.campaignSummary.getTaskDeliverableBreakdownBySeller(
        taskId
      )
    );
    return response.data;
  },
  fetchOngoingTaskMetricsByBrand: async (
    taskId: string
  ): Promise<ApiResponse<OngoingTaskMetricsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<OngoingTaskMetricsDto>>(
      ENDPOINTS.campaignMsvc.campaignSummary.getOngoingTaskMetricsByBrand(
        taskId
      )
    );
    return response.data;
  },
  fetchOngoingTaskMetricsBySeller: async (
    taskId: string,
    sellerId: string
  ): Promise<ApiResponse<OngoingTaskMetricsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<OngoingTaskMetricsDto>>(
      ENDPOINTS.campaignMsvc.campaignSummary.getOngoingTaskMetricsBySeller(
        taskId,
        sellerId
      )
    );
    return response.data;
  },
  fetchPaymentSummary: async (
    taskId: string
  ): Promise<ApiResponse<TaskPaymentSummaryDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<TaskPaymentSummaryDto>>(
      ENDPOINTS.campaignMsvc.campaignSummary.getPaymentSummary(taskId)
    );
    return response.data;
  },
};
export default CampaignSummaryApi;
