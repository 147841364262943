import { useMemo } from "react";
import useUser from "./useUser";
import { PLATFORM_ROLES } from "../constants/app.constants";

const useSubscriptionGuard = () => {
  const { user } = useUser();

  const hasSubscription = useMemo(() => {
    if (!user) return false;
    if (!user?.organisation || user.organisation.length === 0) return false;

    // Check if user is a brand
    const organisation = user.organisation[0];
    const isBrand = organisation.organisationType.find(
      (elem) => elem.type === PLATFORM_ROLES.BRAND
    );
    if (!isBrand) return false;
    // Check if user has subscription
    if (!user.subscription?.hasSubscription) return false;
    return true;
  }, [user]);
  return { hasSubscription };
};
export default useSubscriptionGuard;
