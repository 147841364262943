import "./review-evidence-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { TaskDto } from "../../model/campaign/task.dto";
import { FormattedMessage } from "react-intl";
import SubmitEvidenceListingView from "./submit-evidence-modal-view/submit-evidence-listing-view";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EvidenceApi from "../../api/campaign-msvc/evidence.api";
import { EvidenceDto } from "../../model/campaign/evidence.dto";
import { OngoingDeliverableExtendedDto } from "../../model/campaign/ongoing-deliverable-extended.dto";
import SubmitEvidenceDetailsView from "./submit-evidence-modal-view/submit-evidence-details-view";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import { XLoader } from "../loaders/x-loader";
import { SOCIAL_MEDIA_TYPE } from "../../constants/app.constants";

export interface ReviewEvidenceModalRef {
  show: (
    brandOrgId: string,
    evidenceId: string,
    task: TaskDto,
    ongoingDeliverable: OngoingDeliverableExtendedDto
  ) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

type ViewModeType = "none" | "listing" | "form" | "evidence-detail";
const ReviewEvidenceModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const taskRef = useRef<TaskDto>();
  const [viewMode, setViewMode] = useState<ViewModeType>("none");
  const [loading, setLoading] = useState(true);
  const evidenceIdRef = useRef<string>();
  const brandOrgIdRef = useRef<string>();
  const selectedEvidenceRecord = useRef<EvidenceRecordDto>();

  const [form, setForm] = useState<IFormGroup>({
    evidenceType: new FormControl("text", [new RequiredValidator()]),
  });

  const ongoingDeliverableRef = useRef<OngoingDeliverableExtendedDto>();
  const [evidence, setEvidence] = useState<EvidenceDto>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchEvidenceById = async (
    evidenceId: string,
    representativeId: string
  ): Promise<EvidenceDto> => {
    try {
      setLoading(true);
      evidenceIdRef.current = evidenceId;
      brandOrgIdRef.current = representativeId;
      const resp = await EvidenceApi.fetchEvidenceByIdAsBrand(evidenceId);
      setEvidence(resp.data);
      return resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch evidence");
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  const hasEvidenceRecords = (evidence?: EvidenceDto): boolean => {
    return Boolean(
      evidence?.evidenceRecords?.length && evidence.evidenceRecords.length > 0
    );
  };

  const show = async (
    brandOrgId: string,
    evidenceId: string,
    task: TaskDto,
    ongoingDeliverable: OngoingDeliverableExtendedDto
  ) => {
    setVisible(true);
    ongoingDeliverableRef.current = ongoingDeliverable;
    taskRef.current = task;
    const evidence = await fetchEvidenceById(evidenceId, brandOrgId);
    setLoading(false);

    if (hasEvidenceRecords(evidence)) {
      setViewMode("listing");
      return;
    }
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
    setLoading(true);
    setViewMode("none");
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-review-evidence-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        {loading && taskRef.current?.platform !== SOCIAL_MEDIA_TYPE.X && (
          <div className="loader-wrapper">
            <div className="emp-spinner large"></div>
          </div>
        )}

        {loading && taskRef.current?.platform === SOCIAL_MEDIA_TYPE.X && (
          <XLoader
            title={"Tweet Storm Brewing"}
            description="We will not take more than 2 mins"
            positioning="absolute"
          />
        )}

        {!loading && evidenceIdRef.current && brandOrgIdRef.current && (
          <>
            <div className="text-content-section mb-2">
              <h2 className="title">
                <FormattedMessage id="submitEvidenceModal_title" />
              </h2>
              <p className="emp-paragraph mt-2">
                <FormattedMessage id="submitEvidenceModal_desc" />
              </p>
            </div>
            {viewMode === "listing" && taskRef.current && evidence && (
              <SubmitEvidenceListingView
                task={taskRef.current}
                evidence={evidence}
                onEvidenceSelected={(evidenceRecord) => {
                  setViewMode("evidence-detail");
                  selectedEvidenceRecord.current = evidenceRecord;
                }}
                role="brand"
              />
            )}
            {viewMode === "evidence-detail" &&
              selectedEvidenceRecord.current &&
              taskRef.current && (
                <SubmitEvidenceDetailsView
                  back={() => {
                    setViewMode("listing");
                  }}
                  task={taskRef.current}
                  evidenceRecordDto={selectedEvidenceRecord.current}
                  role="brand"
                />
              )}
          </>
        )}
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ReviewEvidenceModal;
