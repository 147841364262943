import { useCallback, useContext, useEffect, useRef, useState } from "react";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import StringUtils from "../../../utilities/string.util";
import _debounce from "lodash/debounce";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import ChevronUpIcon from "../../../components/icon/chevron-up";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import UserUtils from "../../../utilities/user-utils";
import { AppContext } from "../../../context/app.context";
import { UserDto } from "../../../model/user-management/user.dto";
import "../../../utilities/viewUtils/brand-task-status-card.scss";
import "./brand-ongoing-task-summary-view.scss";
import { NegotiationLogDto } from "../../../model/campaign/negotiation-log.dto";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CampaignSummaryApi from "../../../api/campaign-msvc/campaign-summary.api";
import {
  OngoingTaskMetricsDto,
  TaskStatusMetrics,
} from "../../../model/campaign/ongoing-task-metrics.dto";
import ChevronRightIcon from "../../../components/icon/chevron-right";
import { useNavigate } from "react-router-dom";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

interface Props {
  task: TaskDto;
  fetchTask: () => void;
}

export const BrandOngoingTaskSummaryView = (props: Props) => {
  const { task } = props;
  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();
  const [negotationLogs, setNegotationLogs] = useState<NegotiationLogDto[]>([]);

  const navigate = useNavigate();
  const [deliverableIsExpanded, setDeliverableIsExpanded] = useState(true);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const [metricsLoading, setMetricsLoading] = useState(false);

  // Wrapper element to compute expandable size
  const deliverableInfoRef = useRef<HTMLDivElement>(null);

  const fetchOngoingTaskMetric = useCallback(async () => {
    try {
      setMetricsLoading(true);
      const resp = await CampaignSummaryApi.fetchOngoingTaskMetricsByBrand(
        task.id
      );
      setOngoingTaskMetrics(resp.data);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to fetch ongoing task metric"
      );
    } finally {
      setMetricsLoading(false);
    }
  }, [task]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  useEffect(() => {
    fetchOngoingTaskMetric();
  }, [fetchOngoingTaskMetric]);

  const GUTTER_WIDTH = 20;
  const MIN_CARD_WIDTH = 180;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [deliverableInfoHeight, setDeliverableInfoHeight] = useState<number>(0);
  const [ongoingTaskMetrics, setOngoingTaskMetrics] =
    useState<OngoingTaskMetricsDto>();

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cards = Math.min(maxCards, 5);
    const cardWidth = Math.floor(
      (rowWidth - (cards - 1) * GUTTER_WIDTH) / cards
    );
    setCardWidth(cardWidth);
  };

  function calculateTotalOuterHeight(divRef: HTMLDivElement) {
    let totalHeight = 0;

    // Helper function to include margin
    function getOuterHeight(el: HTMLElement) {
      let style = window.getComputedStyle(el);
      return (
        el.offsetHeight +
        parseInt(style.marginTop) +
        parseInt(style.marginBottom)
      );
    }

    // Loop through the immediate children of divRef
    Array.from(divRef.children).forEach((child) => {
      const c = child as HTMLElement;
      totalHeight += getOuterHeight(c);
    });

    return totalHeight;
  }

  const updateDeliverableSectionHeight = () => {
    const height = calculateTotalOuterHeight(deliverableInfoRef.current!);
    setDeliverableInfoHeight(height);
  };

  // eslint-disable-next-line
  const debounceFn2: () => void = useCallback(
    _debounce(updateDeliverableSectionHeight, 300, { leading: true }),
    []
  );
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
      debounceFn2();
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn, debounceFn2, task]);

  const renderCountries = (countries: string[]): JSX.Element => {
    const maxDisplayCountries = 3;
    const extra =
      countries.length > maxDisplayCountries
        ? countries.length - maxDisplayCountries
        : undefined;
    const renderableCountries = countries.slice(0, maxDisplayCountries);
    return (
      <div className="country-wrapper">
        {renderableCountries.map((elem) => {
          return (
            <img
              key={COUNTRY_CONSTANTS[elem].name}
              className="country"
              alt={COUNTRY_CONSTANTS[elem].name}
              src={COUNTRY_CONSTANTS[elem].flag_1x1}
            />
          );
        })}
        {extra && <div className="extra">{extra}</div>}
      </div>
    );
  };
  // const renderHiredCreators = (latestNegotiation: LatestNegotiationSingleDto): JSX.Element => {
  //     const maxDisplayCreators = 3
  //     if (!latestNegotiation.hasNegotiation) return <>No confirmed creators yet...</>
  //     const hiredCreators = latestNegotiation.latestNegotiation.filter(elem => elem.status === NEGOTIATION_STATUS.ACCEPTED);
  //     const extra = hiredCreators.length > maxDisplayCreators ? hiredCreators.length - maxDisplayCreators : undefined;

  //     if (hiredCreators.length > 0) {
  //         return <div className="country-wrapper">
  //             {hiredCreators.map(elem => {
  //                 return <div>
  //                     {elem.creatorRepresentative.imageType === "url" && <img alt="Profile" className={`profile-photo`} src={elem.creatorRepresentative.imageResource} />}
  //                     {elem.creatorRepresentative.imageType === "none" && <img alt="Empty profile" className={`profile-photo`} src={"https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"} />}
  //                     {elem.creatorRepresentative.imageType === "avatar" && <div style={{ background: elem.creatorRepresentative.imageResource }} className={`avatar`}>
  //                         <span className="initials">{elem.creatorRepresentative.initials}</span>
  //                     </div>}
  //                 </div>
  //             })}
  //             {extra && <div className="extra">{extra}</div>}
  //         </div>
  //     }
  //     else {
  //         return <div>No Creators</div>
  //     }
  // }

  const renderMetricCreators = (
    taskStatusMetric: TaskStatusMetrics
  ): JSX.Element => {
    const maxDisplayCreators = 3;
    if (taskStatusMetric.creators.length === 0)
      return <span className="no-creators-lbl">NO CREATORS</span>;
    const creators = taskStatusMetric.creators;
    const extra =
      creators.length > maxDisplayCreators
        ? creators.length - maxDisplayCreators
        : undefined;

    if (creators.length > 0) {
      return (
        <div className="country-wrapper">
          {creators.map((elem, index) => {
            if (index < maxDisplayCreators)
              return (
                <div key={elem.id}>
                  {elem.imageType === "url" && (
                    <img
                      alt="Profile"
                      className={`profile-photo`}
                      src={elem.imageResource}
                    />
                  )}
                  {elem.imageType === "none" && (
                    <img
                      alt="Empty profile"
                      className={`profile-photo`}
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {elem.imageType === "avatar" && (
                    <div
                      style={{ background: elem.imageResource }}
                      className={`avatar`}
                    >
                      <span className="initials">{elem.initials}</span>
                    </div>
                  )}
                </div>
              );
            else return <></>;
          })}
          {extra && <div className="extra">{extra}</div>}
        </div>
      );
    } else {
      return <span className="no-creators-lbl">NO CREATORS</span>;
    }
  };

  const redirectTab = (toTab: string) => {
    window.history.replaceState({}, "", `${window.location.pathname}`);
    window.location.hash = toTab;
  };

  return (
    <div className="emp-brand-ongoing-task-summary-view">
      {metricsLoading && (
        <section className="metrics-section mb-6">
          <div className="metric-card-skeleton emp-shimmer"></div>
          <div className="metric-card-skeleton emp-shimmer"></div>
          <div className="metric-card-skeleton emp-shimmer"></div>
          <div className="metric-card-skeleton emp-shimmer"></div>
        </section>
      )}
      {ongoingTaskMetrics && !metricsLoading && (
        <section className="metrics-section mb-6">
          {/* PENDING DRAFT METRIC */}
          <div
            className="metric-card"
            onClick={() => {
              redirectTab("Progress Wall");
            }}
          >
            <div className="info-wrapper">
              <div className="profile-wrapper">
                {renderMetricCreators(ongoingTaskMetrics.pendingDraft)}
              </div>
              <div className="metric-wrapper">
                <span className="metric-title">Pending Drafts</span>
                <span className="metric">
                  {ongoingTaskMetrics.pendingDraft.count}
                </span>
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </div>
          {/* IN REVIEW METRIC */}
          <div
            className="metric-card"
            onClick={() => {
              redirectTab("Review Drafts");
            }}
          >
            {ongoingTaskMetrics.inReview.count > 0 && (
              <div className="absolute-pill-wrapper">
                <EmpPill
                  size="sm"
                  backgroundColor={Color.RED[400]}
                  color={Color.NEUTRAL[0]}
                  text={"Action"}
                />
              </div>
            )}
            <div className="info-wrapper">
              <div className="profile-wrapper">
                {renderMetricCreators(ongoingTaskMetrics.inReview)}
              </div>
              <div className="metric-wrapper">
                <span className="metric-title">Drafts Awaiting Review</span>
                <span className="metric">
                  {ongoingTaskMetrics.inReview.count}
                </span>
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </div>
          {/* APPROVED METRIC */}
          <div
            className="metric-card"
            onClick={() => {
              redirectTab("Progress Wall");
            }}
          >
            <div className="info-wrapper">
              <div className="profile-wrapper">
                {renderMetricCreators(ongoingTaskMetrics.approved)}
              </div>
              <div className="metric-wrapper">
                <span className="metric-title">
                  Pending Proof of Work Submission
                </span>
                <span className="metric">
                  {ongoingTaskMetrics.approved.count}
                </span>
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </div>
          {/* COMPLETED METRIC */}
          <div
            className="metric-card"
            onClick={() => {
              redirectTab("View Proof of Work");
            }}
          >
            <div className="info-wrapper">
              <div className="profile-wrapper">
                {renderMetricCreators(ongoingTaskMetrics.completed)}
              </div>
              <div className="metric-wrapper">
                <span className="metric-title">Completed Evidence</span>
                <span className="metric">
                  {ongoingTaskMetrics.completed.count}
                </span>
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </div>
        </section>
      )}
      {task && (
        <section className="task-details-section">
          <div className="task-name-wrapper">
            <div className="info-wrapper">
              <h2 className="section-label">{task.name}</h2>
              <EmpPill
                text={StringUtils.capitalizeWords(task.status)}
                {...PILL_COLORS.primary}
              />
            </div>
          </div>
          <p className="task-description mt-2">{task.description}</p>

          <div ref={resizeDivRef} className="task-details mt-6">
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"BUDGET"}
                value={`${FormFieldUtils.toMoney(
                  0,
                  true
                )}/${FormFieldUtils.toMoney(Number(task.budget), true)}`}
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"EST TASK RUNNING DATE"}
                value={`${DateUtil.toReadableDate(
                  task.startDate
                )} to ${DateUtil.toReadableDate(task.endDate)}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"MODE OF PAYMENT"}
                value={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <EmpCoinIcon
                      mode="icon-only"
                      iconValue={task.paymentMode}
                    />
                    {task.paymentMode}
                  </div>
                }
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"OBJECTIVE"}
                value={`${StringUtils.convertHyphensToTitleCase(
                  task.objective
                )}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"COUNTRIES"}
                value={renderCountries(task.countries)}
              />
            </div>

            {/* <div style={{ width: cardWidth }}>
                    <EmpContent
                        label={"HIRED CREATORS"}
                        value={renderHiredCreators(brandLatestNegotiation)} />
                </div> */}
          </div>
        </section>
      )}

      {task && (
        <section className="deliverable-section">
          <div className="title-wrapper">
            <h2 className="section-label">
              {task.deliverables.length} Deliverable
              {task.deliverables.length > 1 ? "s" : ""}
            </h2>
            <div className="link-wrapper">
              {task.deliverables.length > 0 && (
                <EmpLink
                  onSubmit={() => {
                    setDeliverableIsExpanded(!deliverableIsExpanded);
                  }}
                  text={
                    deliverableIsExpanded ? (
                      <>
                        Hide{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronUpIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        Show Deliverables{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronDownIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    )
                  }
                />
              )}
            </div>
          </div>
          <div
            className="collapsible-section"
            style={{
              height: deliverableIsExpanded ? deliverableInfoHeight : 0,
            }}
          >
            <div style={{ boxSizing: "border-box" }} ref={deliverableInfoRef}>
              {task.deliverables.map((elem, index) => {
                return (
                  <div key={elem.id} className="deliverable-wrapper">
                    <span className="deliverable-indicator">
                      DELIVERABLE {index + 1}
                    </span>
                    <h3 className="deliverable-name-lbl mb-2">{elem.name}</h3>
                    <EmpEditorView value={elem.description} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
