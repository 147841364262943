import { useState, useEffect, useCallback } from "react";
import _debounce from "lodash/debounce";

function useSelfAdjustingCards(
  minCardWidth: number,
  gutter: number,
  resizeDivRef: React.RefObject<HTMLDivElement>,
  rows?: number,
  eventFlag?: boolean
) {
  const [cardWidth, setCardWidth] = useState(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const computeDetailsCardWidth = () => {
    const shimmerRows = rows ?? 1;
    const rowWidth = resizeDivRef.current?.offsetWidth || 0;
    const maxCards = Math.max(Math.floor(rowWidth / minCardWidth), 1);
    const newCardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * gutter) / maxCards
    );
    setCardWidth(newCardWidth);
    setShimmerCards(Array.from(Array(maxCards * shimmerRows).keys()));
  };
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  useEffect(() => {
    if (!resizeDivRef.current) {
      setCardWidth((_) => {
        return 0;
      });
      return;
    }
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current?.offsetWidth || 0);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn, resizeDivRef, eventFlag]);
  return { cardWidth, shimmerCard };
}

export default useSelfAdjustingCards;
