import { Color } from "../../../utilities/colors";
import LinkExternalIcon from "../../icon/link-external";
import "./emp-link.scss";

interface Props {
  text: string | JSX.Element;
  onSubmit?: () => void;
  linkStyle?: "primary" | "danger" | "secondary" | "primary-lighter" | "white";
  newTab?: boolean;
  showNewTabIcon?: boolean;
  disabled?: boolean;
  className?: string;
  href?: string;
}

const EmpLink = (props: Props) => {
  const disabled = props.disabled ?? false;
  const { text, onSubmit, className } = props;
  const newTab = props.newTab ?? false;
  const showNewTabIcon = props.showNewTabIcon ?? false;

  const linkStyle = props.linkStyle ?? "primary";
  return (
    <>
      {disabled && (
        <span className={`emp-link disabled ${className ?? ""}`}>
          {text}{" "}
          {showNewTabIcon && (
            <LinkExternalIcon
              size={12}
              backgroundColor={Color.NEUTRAL[500]}
              strokeWidth={3}
              top={1.5}
            />
          )}
        </span>
      )}
      {!disabled && (
        <a
          target={newTab ? "_blank" : "_self"}
          href={props.href}
          rel="noreferrer"
          className={`emp-link ${linkStyle} ${className ?? ""}`}
          onClick={(event) => {
            event.stopPropagation();
            if (onSubmit) onSubmit();
          }}
        >
          {text}{" "}
          {showNewTabIcon && (
            <LinkExternalIcon
              size={14}
              backgroundColor={Color.PRIMARY[600]}
              strokeWidth={3}
              top={1.5}
            />
          )}
        </a>
      )}
    </>
  );
};

export default EmpLink;
