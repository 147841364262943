import { useRef } from "react";
import { useSlate } from "slate-react";
import { IconProps } from "../../../../model/common/icon";
import { Color } from "../../../../utilities/colors";
import InsertLinkModal, { InsertLinkModalRef } from "./insert-link-modal";
import { Range, Transforms } from "slate";

interface EmpButtonProps {
  icon: React.FC<IconProps>;
}

const EmpLinkButton = (props: EmpButtonProps) => {
  const { icon: Icon } = props;
  const editor = useSlate();
  const insertLinkModalRef = useRef<InsertLinkModalRef>();

  const processInsertedUrl = (url: string) => {
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };
    if (isCollapsed) {
      const anchorPath = [...selection!.anchor.path];
      anchorPath[anchorPath.length - 1] += 2;
      Transforms.insertNodes(editor, link);
      Transforms.select(editor, anchorPath);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  return (
    <div
      className={`elem-modifier-btn`}
      onMouseDown={(event) => {
        insertLinkModalRef.current?.show();
      }}
    >
      <InsertLinkModal ref={insertLinkModalRef} onClose={processInsertedUrl} />
      <Icon backgroundColor={Color.NEUTRAL[500]} size={14} />
    </div>
  );
};

export default EmpLinkButton;
