import "./brand-event-post-modal.scss";

import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateRangePicker } from "rsuite";
import _debounce from "lodash/debounce";
import jstz from "jstz";

import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import { AppContext } from "../../../context/app.context";
import { FILE_TYPE } from "../../../constants/app.constants";
import { Color } from "../../../utilities/colors";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import BrandPostPreviewModal from "./brand-post-preview-modal";
import FileUtils from "../../../utilities/file-util";
import EmpDatePicker from "../../../components/shared/emp-date-picker/emp-date-picker";
import AlertCircleIcon from "../../../components/icon/alert-circle";
import { deletePostUtils, postUtils } from "../utils/smm-posts-utils";
import ToastUtils from "../../../utilities/toast-utils";
import { SmPostDto } from "../../../model/smm/smm-post.dto";
import DeleteModal, {
  DeleteModalRef,
} from "../../../components/modals/delete-modal";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import EmpDateInput from "../../../components/shared/emp-time-input/emp-time-input";
import XCloseIcon from "../../../components/icon/x-close-icon";
import PlayIcon from "../../../components/icon/play-icon";
import ConfirmModal, {
  ConfirmModalRef,
} from "../../../components/modals/confirm-modal";
import moment from "moment";
import TikTokConfigPostModal, {
  TikTokConfigPostModalRef,
} from "./tiktok/tiktok-config-post-modal";
import { EmpExpandToggleBtn } from "../../../components/shared/emp-expand-toggle-btn/emp-expand-toggle-btn";
import EmpDropdownBtn from "../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";

export interface BrandEditPostModalRef {
  show: (post: SmPostDto) => void;
  dismiss: () => void;
}

interface Props {
  getPost: () => Promise<void>;
  isEditDraft?: boolean;
}

type PostType = "post" | "reel" | "story" | "thread-tweet";

type PlatformType = "facebook" | "instagram" | "tiktok" | "x";

const hashtags = ["Newpost", "Viral", "Crickets"];
const BrandEditPostModal = forwardRef(
  ({ getPost, isEditDraft }: Props, ref) => {
    const discardBtnRef = useRef<EmpButtonRef>();
    const postBtnRef = useRef<EmpButtonRef>();
    const postNowBtnRef = useRef<EmpButtonRef>();
    const saveToDraftBtnRef = useRef<EmpButtonRef>();
    const deleteModalRef = useRef<DeleteModalRef>();
    const confirmModalRef = useRef<ConfirmModalRef>();
    const tiktokConfigPostModalRef = useRef<TikTokConfigPostModalRef>(null);

    const { user: userContext } = useContext(AppContext);

    const [visible, setVisible] = useState<boolean>(false);
    const [isSchedulePost, setIsSchedulePost] = useState(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUpdatedTikTokSettings, setIsUpdatedTikTokSettings] =
      useState(false);

    const [comparedTimezone, setComparedTimezone] = useState<Date>(new Date());
    const [, setPollValue] = useState("");
    const [pollLengthValue, setPollLengthValue] = useState("");
    const [pollOptions, setPollOptions] = useState(2);
    const [socialPostType, setSocialPostType] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("Draft Post");
    const [postData, setPostData] = useState<SmPostDto>();
    const [previewDescription, setPreviewDescription] = useState<string>("");
    const [selectedConnects, setSelectedConnects] = useState<
      SmConnectionBriefRespDto[]
    >([]);

    // file upload state
    const [uploadedFileType, setUploadedFileType] = useState<
      (typeof FILE_TYPE)[keyof typeof FILE_TYPE] | "carousel"
    >();
    const [video, setVideo] = useState<File[] | string[]>();
    const [imageFiles, setImageFiles] = useState<File[] | string[]>();

    // form state
    const [postType, setPostType] = useState<PostType | null>(null);
    const [form, setForm] = useState<IFormGroup>({
      postType: new FormControl("text", [
        new RequiredValidator("Post Type is required"),
      ]),

      description: new FormControl("text", [
        new LengthValidator(
          0,
          300,
          "",
          "Post description must not exceed 300 characters"
        ),
      ]),

      pollOption: new FormControl(
        "text",
        [new RequiredValidator("Poll Option 1 is required")],
        ""
      ),
      pollOption2: new FormControl(
        "text",
        [new RequiredValidator("Poll Option 2 is required")],
        ""
      ),
      pollOption3: new FormControl("text", [], ""),
      pollOption4: new FormControl("text", [], ""),
      pollOptionSize: new FormControl("text", [], "2"),

      pollLengthDays: new FormControl("text", [], "0"),
      pollLengthHours: new FormControl("text", [], "0"),
      pollLengthMinutes: new FormControl("text", [], "0"),

      platform: new FormControl("text", [
        new RequiredValidator("Please select a platform"),
      ]),
      dateOfPosting: new FormControl("date", [new RequiredValidator()]),

      timeOfPosting: new FormControl("date", [new RequiredValidator()]),

      privacy_level: new FormControl("text", [
        new RequiredValidator("Please select a privacy level"),
      ]),
      disable_duet: new FormControl("boolean", []),
      disable_comment: new FormControl("boolean", []),
      disable_stitch: new FormControl("boolean", []),
      brand_content_toggle: new FormControl("boolean", []),
      brand_organic_toggle: new FormControl("boolean", []),
    });

    // form state for Twitter/X
    const [threadForm, setThreadForm] = useState<IFormGroup[]>([
      {
        tweet: new FormControl(
          "text",
          [
            new LengthValidator(
              0,
              280,
              "",
              "Tweet must not exceed 280 characters"
            ),
          ],
          ""
        ),
      },
    ]);

    const [threadVideo, setThreadVideo] =
      useState<Record<string, File[][] | string[][]>>();
    const [threadImage, setThreadImage] =
      useState<Record<string, File[][] | string[][]>>();
    const [isThreadFileUpload, setIsThreadFileUpload] =
      useState<boolean>(false);

    // form functions
    const resetForm = () => {
      // Resetting the form
      FormGroupUtil.resetToInitial(form);
      setForm({ ...form });
      setThreadForm([
        {
          tweet: new FormControl(
            "text",
            [
              new LengthValidator(
                0,
                280,
                "",
                "Tweet must not exceed 280 characters"
              ),
            ],
            ""
          ),
        },
      ]);
    };

    // file upload functions (for UI and validation)
    const handleFileUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const files: FileList | null = event.target.files;
      if (!files || files.length === 0) return;

      const videoFiles = FileUtils.getAllVideo(files);
      const imageFiles = FileUtils.findImage(files);

      if (videoFiles.length > 1) {
        ToastUtils.error("Error", "You can only upload one video file");

        return;
      }

      resetUploadState();

      // Validate and set video files
      const videoValid =
        videoFiles.length > 0 &&
        validateAndSetFiles(videoFiles, setVideo, "Video", 10 * 1024); // 10GB in MB

      // Validate and set image files
      const imageValid =
        imageFiles.length > 0 &&
        validateAndSetFiles(imageFiles, setImageFiles, "Image", 2); // 2MB

      if (videoValid || imageValid) {
        // Assuming a preference for displaying image type if available
        const fileType =
          imageFiles[0] && videoFiles[0]
            ? "carousel"
            : FileUtils.getFileType(imageFiles[0] || videoFiles[0]);
        setUploadedFileType(fileType);
      }
    };

    function resetUploadState() {
      setVideo(undefined);
      setImageFiles(undefined);
    }

    function validateAndSetFiles(
      files: File[],
      setFunction: Function,
      fileType: string,
      maxSizeInMB: number
    ): boolean {
      let isValid = true;
      files.forEach((file) => {
        if (!FileUtils.isFileSizeSmallerThanMB(file, maxSizeInMB)) {
          ToastUtils.error(
            "Error",
            `${fileType} size must not exceed ${maxSizeInMB}MB`
          );
          isValid = false;
          return; // Exit loop on first invalid file
        }
      });

      if (isValid) {
        setFunction(files);
      }

      return isValid;
    }

    // file upload for twitter/x (thread)
    const handleFileXUpload = async (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number
    ): Promise<void> => {
      // Reset the upload state if it's not multi-edit mode

      const files: FileList | null = event.target.files;
      if (!files || files.length === 0) return;
      if (files.length > 5) {
        ToastUtils.error("Error", "You can only upload up to 5 files");
        return;
      }

      resetXUploadState();
      const videoFiles = FileUtils.getAllVideo(files);
      const imageFiles = FileUtils.findImage(files);

      if (videoFiles.length > 1) {
        ToastUtils.error("Error", "You can only upload one video file");
        return;
      }

      const updateVideoFiles = (files: File[]) => {
        const newArr: File[][] | string[][] = threadVideo
          ? threadVideo[selectedConnects[0]?.socialMediaUserId as string]
          : [];
        newArr[index] = files;
        setThreadVideo((prevThreadVideo) => ({
          ...prevThreadVideo,
          [selectedConnects[0]?.socialMediaUserId as string]: newArr,
        }));
      };
      const updateImageFiles = (files: File[]) => {
        const newArr: File[][] | string[][] = threadImage
          ? threadImage[selectedConnects[0]?.socialMediaUserId as string]
          : [];
        newArr[index] = files;
        setThreadImage((prevThreadImage) => ({
          ...prevThreadImage,
          [selectedConnects[0]?.socialMediaUserId as string]: newArr,
        }));
      };

      const videoValid =
        videoFiles.length > 0 &&
        validateAndSetFiles(videoFiles, updateVideoFiles, "Video", 10 * 1024); // 10GB in MB

      // Validate and set image files
      const imageValid =
        imageFiles.length > 0 &&
        validateAndSetFiles(imageFiles, updateImageFiles, "Image", 2); // 2MB

      if (!videoValid) {
        const newArr: File[][] =
          (threadVideo as Record<string, File[][]>)[
            selectedConnects[0]?.socialMediaUserId as string
          ] || [];
        newArr[index] = [];
        setThreadVideo((prevMultiVideo) => ({
          ...prevMultiVideo,
          [selectedConnects[0]?.socialMediaUserId as string]: newArr,
        }));
      }
      if (!imageValid) {
        const newArr: File[][] =
          (threadImage as Record<string, File[][]>)[
            selectedConnects[0]?.socialMediaUserId as string
          ] || [];
        newArr[index] = [];
        setThreadImage((prevMultiImage) => ({
          ...prevMultiImage,
          [selectedConnects[0]?.socialMediaUserId as string]: newArr,
        }));
      }
      setIsThreadFileUpload(!isThreadFileUpload);
      return;
    };

    function resetXUploadState() {
      setThreadVideo(undefined);
      setThreadImage(undefined);
      setUploadedFileType(undefined);
    }

    const isTextInputAvailable = useMemo(() => {
      if (!postType || !selectedConnects) return false;

      const platformsSet = new Set(
        selectedConnects.map((connect) => connect.platform.toLowerCase())
      );

      // Check if either "facebook" or "instagram" are included when postType is "story"
      if (
        (platformsSet.has("facebook") || platformsSet.has("instagram")) &&
        postType === "story"
      ) {
        return false;
      }

      return true;
    }, [postType, selectedConnects]);

    useImperativeHandle(ref, () => ({
      show: (post: SmPostDto) => {
        const {
          title,
          mediaUrls,
          formattedPostType,
          options,
          duration_minutes,
          thread,
        } = processPost(post);
        categorizeAndSetMedia(mediaUrls);
        const { scheduledTime, postType, socialSpec } = post;
        if (!isEditDraft) updateScheduledTime(scheduledTime);
        if (postType === "poll-tweet") {
          form.pollOption.setValue(options[0]);
          form.pollOption2.setValue(options[1]);
          form.pollOption3.setValue(options[2] || "");
          form.pollOption4.setValue(options[3] || "");
          form.pollLengthDays.setValue(
            Math.floor(duration_minutes / (60 * 24))
          );
          form.pollLengthHours.setValue(
            Math.floor((duration_minutes / 60) % 24)
          );
          form.pollLengthMinutes.setValue(duration_minutes % 60);
          form.pollOptionSize.setValue(options.length);
          setPollOptions(options.length);
          setPollValue(title);
          setPollLengthValue(
            `${duration_minutes / (60 * 24)} Days, ${
              (duration_minutes / 60) % 24
            } Hours, ${duration_minutes % 60} Minutes`
          );
        }
        if (postType === "thread-tweet") {
          const mediaArr: any = [];
          (thread as any[]).forEach((tweet, index) => {
            const { text, media } = tweet;
            if (index === 0) {
              threadForm[0].tweet.setValue(text);
            } else {
              setThreadForm((prev) => {
                return [
                  ...prev,
                  {
                    tweet: new FormControl(
                      "text",
                      [
                        new LengthValidator(
                          0,
                          280,
                          "",
                          "Tweet must not exceed 280 characters"
                        ),
                      ],
                      text
                    ),
                  },
                ];
              });
            }
            if (media.length > 0) {
              mediaArr.push({ media, index });
            }
          });
          const result: Record<string, string[][]> = {
            video: [],
            img: [],
          };

          mediaArr.forEach((item: any) => {
            item.media.forEach((media: any) => {
              const mediaType = media.type === "video" ? "video" : "img";

              if (!result[mediaType][item.index]) {
                result[mediaType][item.index] = [];
              }

              result[mediaType][item.index].push(media.url);
            });
          });

          const videoArr: any = Array.from(
            { length: result.video.length },
            (_, index) => [
              ...(result.video[index] === undefined ? [] : result.video[index]),
            ]
          );
          const imgArr: any = Array.from(
            { length: result.img.length },
            (_, index) => [
              ...(result.img[index] === undefined ? [] : result.img[index]),
            ]
          );

          setThreadVideo({
            [socialSpec.socialMediaUserId]: videoArr,
          });
          setThreadImage({
            [socialSpec.socialMediaUserId]: imgArr,
          });
          setIsThreadFileUpload(true);
        }

        setSocialPostType(postType);
        setModalTitle(formatTitle(postType));
        setSelectedConnects([socialSpec]);
        setPostType(formattedPostType);
        if (postType === "thread-tweet") {
          setPreviewDescription(thread[0].text);
          form.description.setValue(title);
        } else {
          setPreviewDescription(title);
          form.description.setValue(title);
        }
        setPostData(post);
        setVisible(true);
      },
      dismiss: () => {
        setVisible(false);
      },
    }));
    const getDateAndTimeFromTimestamp = (timestampInMinutes: number) => {
      const timestampInMilliseconds = timestampInMinutes * 60 * 1000;

      const date = new Date(timestampInMilliseconds);

      const dateOfPosting = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const timeOfPosting = new Date(
        0,
        0,
        0,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      );

      return {
        dateOfPosting,
        timeOfPosting,
      };
    };

    const updateScheduledTime = (scheduledTime: number) => {
      if (!scheduledTime) return;
      const { dateOfPosting, timeOfPosting } =
        getDateAndTimeFromTimestamp(scheduledTime);
      form.dateOfPosting.setValue(dateOfPosting);
      form.timeOfPosting.setValue(timeOfPosting);
      setIsSchedulePost(true);

      dateOfPosting.setHours(
        timeOfPosting.getHours(),
        timeOfPosting.getMinutes(),
        timeOfPosting.getSeconds()
      );
      setComparedTimezone(dateOfPosting);
    };

    function processPost(post: SmPostDto) {
      const formattedRecord = JSON.parse(post.metadata);
      const title =
        formattedRecord.caption ||
        formattedRecord.message ||
        formattedRecord.description ||
        formattedRecord.title ||
        formattedRecord.text ||
        "";
      const { postType } = post;
      const formattedPostType = ["story", "reel"].includes(postType)
        ? (postType as PostType)
        : "post";
      const mediaUrls = getMediaUrls(formattedRecord);
      const { options, duration_minutes, thread } = formattedRecord;

      return {
        title,
        mediaUrls,
        formattedPostType,
        options,
        duration_minutes,
        thread,
      };
    }

    function getMediaUrls(formattedRecord: any): string[] {
      const {
        image_urls,
        video_urls,
        media_url,
        media_items,
        link,
        file_url,
        photo_urls,
        video_url,
        media,
      } = formattedRecord;
      let urls = [];

      if (link) urls.push(link);
      if (file_url) urls.push(file_url);
      if (image_urls?.length) urls.push(...image_urls);
      if (video_urls?.length) urls.push(...video_urls);
      if (photo_urls?.length) urls.push(...photo_urls);
      if (video_url) urls.push(video_url);
      if (media_url) urls.push(media_url);
      if (media_items?.length)
        urls.push(...media_items.map((item: any) => item.url));

      if (media?.length) urls.push(...media.map((item: any) => item.url));

      return urls;
    }

    function categorizeAndSetMedia(mediaUrls: string[]) {
      const images: string[] = [];
      const videos: string[] = mediaUrls.filter((url) => {
        const isVideo = [".mp4", ".avi", ".mov"].some((ext) =>
          url.endsWith(ext)
        );
        if (!isVideo) images.push(url);
        return isVideo;
      });

      if (videos.length > 0) setVideo(videos);
      if (images.length > 0) setImageFiles(images);

      // determine File type
      const fileType =
        videos[0] && images[0] ? "carousel" : images[0] ? "image" : "video";
      setUploadedFileType(fileType);
    }

    function formatTitle(title: string): string {
      const parts = title.split("-");
      return (
        parts
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
          .join(" ") + (parts.length === 1 ? " Post" : "")
      );
    }
    const dismiss = () => {
      setVisible(false);
      setSelectedConnects([]);
      setPostType(null);
      setPostData(undefined);
      setVideo(undefined);
      setImageFiles(undefined);
      setUploadedFileType(undefined);
      setPreviewDescription("");
      setIsSchedulePost(false);
      resetForm();

      setIsUpdatedTikTokSettings(false);
      setPollOptions(2);
      setPollValue("");
      setPollLengthValue("");

      setThreadVideo(undefined);
      setThreadImage(undefined);
      setIsThreadFileUpload(false);

      // refresh post to get the latest data
      getPost();
    };

    const { handleEditPost, handleEditThreadPost } = postUtils({
      form,
      postType,
      userContext,
      selectedConnects,
      video,
      imageFiles,
      saveToDraftBtnRef,
      postBtnRef,
      postNowBtnRef,
      discardBtnRef,
      dismiss,
      threadForm,
      threadVideo,
      threadImage,
    });

    const { handleDeletePost } = deletePostUtils({ getPost, dismiss });

    const handleSaveDraftSubmit = () => {
      if (!postData) return;
      const isEdit = true;
      const isDraft = true;
      if (postData.postType === "thread-tweet") {
        handleEditThreadPost(
          postData,
          isEdit,
          isSchedulePost ? "schedule" : "immediately",
          isDraft
        );
      } else {
        handleEditPost(
          postData,
          isEdit,
          isSchedulePost ? "schedule" : "immediately",
          isDraft
        );
      }
    };

    const handleEditDraftSubmit = () => {
      if (
        postNowBtnRef.current?.getButtonState() === "loading" ||
        postBtnRef.current?.getButtonState() === "loading"
      )
        return;
      if (
        selectedConnects[0].platform.toLowerCase() === "tiktok" &&
        !isUpdatedTikTokSettings
      ) {
        tiktokConfigPostModalRef.current?.show(selectedConnects);
        return;
      }
      if (!postData) return;
      const isNotEdit = false;
      if (postData.postType === "thread-tweet") {
        handleEditThreadPost(
          postData,
          isNotEdit,
          isSchedulePost ? "schedule" : "immediately"
        );
      } else {
        handleEditPost(
          postData,
          isNotEdit,
          isSchedulePost ? "schedule" : "immediately"
        );
      }
    };

    const handleEditPostSubmit = (isEditSchedulePost?: boolean) => {
      if (!postData) return;
      const isEdit = false;
      if (postData.postType === "thread-tweet") {
        handleEditThreadPost(
          postData,
          isEdit,
          isEditSchedulePost ? "schedule" : "immediately"
        );
      } else {
        handleEditPost(
          postData,
          isEdit,
          isEditSchedulePost ? "schedule" : "immediately"
        );
      }
    };

    const handleAddHashtag = (hashtag: string) => {
      if (!postType || !isTextInputAvailable) return;
      form.description.setValue(
        form.description.getValue() + " #" + hashtag + " "
      );
      handleChangePreviewDescription();
    };

    const handleChangePreviewDescription = _debounce(() => {
      const description = form.description.getValue();
      form.description.validate();
      threadForm[0].tweet.setValue(description);
      threadForm[0].tweet.validate();
      setPreviewDescription(description);
    }, 200);

    const updateComparedTimezone = useCallback(() => {
      const date = new Date(form.dateOfPosting?.getValue());
      const time = new Date(form.timeOfPosting?.getValue());
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
      setComparedTimezone(date);
    }, [form.dateOfPosting, form.timeOfPosting]);

    const handleDateChange = () => {
      if (form.dateOfPosting.getValue() === null) {
        if (!isEditDraft) {
          form.dateOfPosting.setValue(new Date());
        }
        setIsSchedulePost(false);
        return;
      }
      setIsSchedulePost(true);
      updateComparedTimezone();
    };
    const handleTimeChange = () => {
      if (isSchedulePost) {
        updateComparedTimezone();

        return;
      }
      form.dateOfPosting.setValue(new Date());
      setIsSchedulePost(true);
      updateComparedTimezone();
    };

    const isSubmitAvailable = useCallback(() => {
      if (socialPostType === "poll-tweet") {
        return true;
      }

      const platformArr = selectedConnects.map((connect) => {
        return connect.platform.toLowerCase() as PlatformType;
      });

      const { length } = previewDescription;
      // Check if the selected platform supports the selected post type

      switch (platformArr[0]) {
        case "instagram":
          if (postType === "reel") {
            return !!video;
          }

          return !!imageFiles || !!video;
        case "tiktok":
          return !!video || !!imageFiles;

        default:
          if (postType === "story" && length === 0) {
            return !!imageFiles || !!video;
          }
          if (postType === "reel") {
            return !!video;
          }
          if (length === 0) {
            return !!imageFiles || !!video;
          }

          return true;
      }
    }, [postType, selectedConnects, previewDescription, imageFiles, video]);

    const inputFileValidation = useMemo(() => {
      const imageType = "image/jpeg, image/jpg, image/png";
      const videoType = "video/*";
      let accept = "";

      switch (socialPostType) {
        case "feed":
          break;
        case "poll-tweet":
          break;
        case "images-post":
          accept = `${imageType}`;
          break;
        case "video-post":
          accept = `${videoType}`;
          break;
        case "reel":
          accept = ` ${videoType}`;
          break;

        default:
          accept = `${imageType}, ${videoType}`;
          break;
      }

      return accept;
    }, [socialPostType]);

    const isMultipleFileUpload = useMemo(() => {
      if (socialPostType === "single-post") {
        return false;
      }
      if (postType === "reel" || postType === "story") {
        return false;
      }
      return true;
    }, [socialPostType, postType]);

    const renderWarningMessage = useCallback(() => {
      const platformArr = selectedConnects.map((connect) => {
        return connect.platform.toLowerCase() as PlatformType;
      });

      if (platformArr.includes("facebook") && imageFiles && video) {
        return (
          <div className="post-modal-warning-wrapper">
            <div>
              <div className="warning-icon">
                <AlertCircleIcon backgroundColor={Color.RED[700]} size={20} />
              </div>
              <div className="warning-text">
                <p>
                  Please choose either images or a video for your Facebook post,
                  as mixed media types are not supported.
                </p>
              </div>
            </div>
          </div>
        );
      }
    }, [selectedConnects, imageFiles, video]);
    const renderPreviewFile = useCallback(
      (isThread: boolean, threadIndex?: number) => {
        const isString = (value: any): boolean => typeof value === "string";

        const handleFileRemove = (
          fileType: "video" | "image",
          fileIndex: number
        ) => {
          if (isThread) {
            if (!threadVideo || !threadImage) return;
            const stateSetter =
              fileType === "video" ? setThreadVideo : setThreadImage;
            stateSetter((prevState) => {
              const newState = { ...prevState };
              const threadArr: any =
                newState[selectedConnects[0].socialMediaUserId][
                  threadIndex as number
                ];
              newState[selectedConnects[0].socialMediaUserId][
                threadIndex as number
              ] = threadArr.filter(
                (_: any, index: number) => index !== fileIndex
              ) as File[] | string[];
              return newState;
            });
            return;
          }

          let newFile: File[] | string[] = [];

          // for prevent remove file for specific platform
          const platform = postData?.platformName.toLocaleLowerCase();
          const totalFiles = (imageFiles?.length ?? 0) + (video?.length ?? 0);

          const isSingleFileOnPlatform =
            (platform === "tiktok" || platform === "facebook") &&
            totalFiles === 1;
          const isSingleFileOnPostType =
            (postType === "reel" || postType === "story") && totalFiles === 1;

          if (isSingleFileOnPlatform || isSingleFileOnPostType) {
            ToastUtils.error(
              "Error",
              "You can't remove the only file for this post type"
            );
            return;
          }

          if (fileType === "video") {
            if (!video) return;
            if (isString(video[0])) {
              newFile = (video as string[]).filter(
                (_, index) => index !== fileIndex
              );
            } else {
              newFile = (video as File[]).filter(
                (_, index) => index !== fileIndex
              );
            }
            setVideo(newFile);
          } else {
            if (!imageFiles) return;
            if (isString(imageFiles[0])) {
              newFile = (imageFiles as string[]).filter(
                (_, index) => index !== fileIndex
              );
            } else {
              newFile = (imageFiles as File[]).filter(
                (_, index) => index !== fileIndex
              );
            }
            setImageFiles(newFile);
          }
        };

        const renderVideoPreview = (videoFilesArr: (File | string)[]) => {
          const videoSrc = isString(videoFilesArr[0])
            ? (videoFilesArr[0] as string)
            : URL.createObjectURL(videoFilesArr[0] as File);

          return (
            <div className="video-preview">
              <video src={videoSrc}></video>
              <div className="play-wrapper">
                <PlayIcon backgroundColor={Color.NEUTRAL[100]} size={16} />
              </div>
              <div
                className="x-button"
                onClick={() => handleFileRemove("video", 0)}
              >
                <XCloseIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
              </div>
            </div>
          );
        };

        const renderImagePreviews = (imageFilesArr: (File | string)[]) => {
          return imageFilesArr.map((file, index) => {
            const imgSrc = isString(file)
              ? (file as string)
              : URL.createObjectURL(file as File);

            return (
              <div className="image-preview" key={index}>
                <img src={imgSrc} alt="preview" />
                <div
                  className="x-button"
                  onClick={() => handleFileRemove("image", index)}
                >
                  <XCloseIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                </div>
              </div>
            );
          });
        };

        let videoFilesArr;
        let imageFilesArr;
        if (isThread) {
          videoFilesArr = threadVideo
            ? threadVideo[selectedConnects[0]?.socialMediaUserId as string][
                threadIndex as number
              ]
            : [];
          imageFilesArr = threadImage
            ? threadImage[selectedConnects[0]?.socialMediaUserId as string][
                threadIndex as number
              ]
            : [];
        } else {
          videoFilesArr = video;
          imageFilesArr = imageFiles;
        }

        const hasVideo = videoFilesArr && videoFilesArr.length > 0;
        const hasImages = imageFilesArr && imageFilesArr.length > 0;

        if (!hasVideo && !hasImages) return null;

        return (
          <div className="preview-file-wrapper">
            <div className="preview-file">
              {hasVideo && renderVideoPreview(videoFilesArr!)}
              {hasImages && renderImagePreviews(imageFilesArr!)}
            </div>
          </div>
        );
      },
      [imageFiles, selectedConnects, threadImage, threadVideo, video]
    );

    const renderTextContentSection = useCallback(() => {
      if (socialPostType === "poll-tweet") {
        const renderPollInput = () => {
          const formattedPollOptions = form?.pollOptionSize.getValue() || 2;

          const formattedDescription = form?.description;

          const pollFormControlMap: Record<number, FormControl> = {
            1: form?.pollOption,
            2: form?.pollOption2,
            3: form?.pollOption3,
            4: form?.pollOption4,
          };

          const pollFormLengthMap: Record<string, FormControl> = {
            minutes: form?.pollLengthMinutes,
            hours: form?.pollLengthHours,
            days: form?.pollLengthDays,
          };

          const pollOptionSize = form?.pollOptionSize;

          const handleAddPollOption = () => {
            if (formattedPollOptions < 4) {
              setPollOptions(+formattedPollOptions + 1);
              pollOptionSize.setValue((+formattedPollOptions + 1).toString());
            }
          };

          const handleRemovePollOption = () => {
            if (formattedPollOptions > 2) {
              setPollOptions(+formattedPollOptions - 1);
              pollOptionSize.setValue((+formattedPollOptions - 1).toString());
            }
          };

          const handleChangePollLength = (type: string, value: number) => {
            const formattedValue = value.toString();
            const typeMap: Record<string, () => void> = {
              days: () => {
                if (value === 7) {
                  pollFormLengthMap["minutes"].setValue("0");
                  pollFormLengthMap["hours"].setValue("0");
                  pollFormLengthMap["days"].setValue("7");
                  setPollLengthValue("7 Days");
                } else {
                  pollFormLengthMap["days"].setValue(formattedValue);
                  setPollLengthValue(`${value} Days`);
                }
              },
              hours: () => {
                pollFormLengthMap["hours"].setValue(formattedValue);
                setPollLengthValue(`${value} Hours`);
              },
              minutes: () => {
                pollFormLengthMap["minutes"].setValue(formattedValue);
                setPollLengthValue(`${value} Minutes`);
              },
            };

            if (typeMap[type]) {
              typeMap[type]();
            } else {
              console.warn(`Unknown type: ${type}`);
            }
          };

          const menuButtonSpecs: Record<string, any> = {
            days: Array.from({ length: 8 }, (_, index) => {
              return {
                context: index,
                label: `${index}`,
                action: () => {
                  handleChangePollLength("days", index);
                },
              };
            }),
            hours: Array.from({ length: 24 }, (_, index) => {
              return {
                context: index,
                label: `${index}`,
                action: () => {
                  handleChangePollLength("hours", index);
                },
              };
            }),
            minutes: Array.from({ length: 60 }, (_, index) => {
              return {
                context: index,
                label: `${index}`,
                action: () => {
                  handleChangePollLength("minutes", index);
                },
              };
            }),
          };

          const handleChangePollContent = _debounce((value: FormControl) => {
            // for rerendering the poll options
            setPollValue(value.getValue());
            value.validate();
          }, 200);

          const renderPollOptions = () => {
            return Array.from({ length: formattedPollOptions }, (_, index) => (
              <div key={index} className="poll-option-wrapper">
                <EmpTextInput
                  labelText={`Choice ${index + 1}`}
                  formControl={pollFormControlMap[index + 1]}
                  placeholder={`Choice ${index + 1}`}
                  multiline
                  id={`poll-option-${index}`}
                  onChange={handleChangePollContent}
                  characterCount={25}
                  preventEnter={true}
                />
                <div className="poll-btn-wrapper">
                  {+formattedPollOptions > 2 &&
                    +formattedPollOptions === index + 1 && (
                      <EmpExpandToggleBtn
                        onClick={handleRemovePollOption}
                        isExpanded={true}
                      />
                    )}
                  {+formattedPollOptions >= 2 && +formattedPollOptions < 4 && (
                    <EmpExpandToggleBtn
                      onClick={handleAddPollOption}
                      isExpanded={false}
                    />
                  )}
                </div>
              </div>
            ));
          };

          return (
            <div className="text-content-section">
              <div className="top-section">
                <div className="poll-input-wrapper mb-2">
                  <EmpTextInput
                    labelText="Question"
                    formControl={formattedDescription}
                    placeholder="What's your poll question?"
                    onChange={handleChangePreviewDescription}
                    multiline
                    id="poll-question"
                    characterCount={280}
                    preventEnter={true}
                  />
                  <div className="poll-options mb-2">{renderPollOptions()}</div>
                  <h3 className="mb-1">Poll Length</h3>
                  <p className="mb-2">
                    Poll length refers to how long this post will be available
                    for poll for.
                  </p>
                  <div className="poll-length mb-2">
                    <div>
                      <label>Days</label>
                      <EmpDropdownBtn
                        text={
                          !!pollFormLengthMap["days"].getValue()
                            ? pollFormLengthMap["days"].getValue() + " Days"
                            : "Select Days"
                        }
                        menuButtonSpecs={menuButtonSpecs["days"]}
                        buttonStyle="outline-secondary"
                        rightIcon={ChevronDownIcon}
                      />
                    </div>
                    <div>
                      <label>Hours</label>
                      <EmpDropdownBtn
                        text={
                          !!pollFormLengthMap["hours"].getValue()
                            ? pollFormLengthMap["hours"].getValue() + " Hours"
                            : "Select Hours"
                        }
                        menuButtonSpecs={menuButtonSpecs["hours"]}
                        rightIcon={ChevronDownIcon}
                        buttonStyle={
                          pollLengthValue === "7 Days"
                            ? "secondary"
                            : "outline-secondary"
                        }
                        isDisabled={pollLengthValue === "7 Days"}
                      />
                    </div>
                    <div>
                      <label>Minutes</label>
                      <EmpDropdownBtn
                        text={
                          !!pollFormLengthMap["minutes"].getValue()
                            ? pollFormLengthMap["minutes"].getValue() +
                              " Minutes"
                            : "Select Minutes"
                        }
                        menuButtonSpecs={menuButtonSpecs["minutes"]}
                        rightIcon={ChevronDownIcon}
                        buttonStyle={
                          pollLengthValue === "7 Days"
                            ? "secondary"
                            : "outline-secondary"
                        }
                        isDisabled={pollLengthValue === "7 Days"}
                      />
                    </div>
                  </div>
                </div>
                {/* <label className="suggested-hashtag">
              Suggested #hashtag
            </label>
            <div className="hashtag-list">
              {hashtags.map((hashtag, index) => (
                <span
                  key={index}
                  onClick={() => handleAddHashtag(hashtag)}
                >
                  <EmpRoundedPill
                    clickable={!!postType}
                    backgroundColor={Color.PRIMARY[100]}
                    color={Color.PRIMARY[500]}
                    key={index}
                    text={"#" + hashtag}
                  />
                </span>
              ))}
            </div> */}
              </div>
            </div>
          );
        };
        return renderPollInput();
      }

      if (socialPostType === "thread-tweet") {
        const renderTweetInput = () => {
          const handleChangeThread = _debounce((value: FormControl) => {
            setPreviewDescription(value.getValue());
            value.validate();
          }, 200);

          const removeThreadFile = (index: number) => {
            const newThreadImage = { ...threadImage };
            const newThreadVideo = { ...threadVideo };
            newThreadImage[selectedConnects[0].socialMediaUserId] = (
              newThreadImage[selectedConnects[0].socialMediaUserId] as any
            ).filter((_: any, i: number) => i !== index);
            newThreadVideo[selectedConnects[0].socialMediaUserId] = (
              newThreadVideo[selectedConnects[0].socialMediaUserId] as any
            ).filter((_: any, i: number) => i !== index);

            setThreadImage(newThreadImage);
            setThreadVideo(newThreadVideo);
          };

          const handleRemoveThread = (index: number) => {
            setThreadForm((prevThreadForm) => {
              return prevThreadForm.filter((_, i) => i !== index);
            });

            removeThreadFile(index);
          };

          const formattedThreadForm = threadForm;

          const handleAddThread = () => {
            setThreadForm((prevThreadForm) => {
              return [
                ...prevThreadForm,
                {
                  tweet: new FormControl(
                    "text",
                    [
                      new LengthValidator(
                        0,
                        280,
                        "",
                        "Tweet must not exceed 280 characters"
                      ),
                    ],
                    ""
                  ),
                },
              ];
            });
          };

          return formattedThreadForm.map((IForm: IFormGroup, index: number) => (
            <>
              <div
                className={`text-content-section ${index !== 0 ? "mt-3" : ""}`}
              >
                <div className="top-section">
                  <div className="thread-post-title">
                    <h3 className="mb-2">
                      {index === 0 ? "Main Tweet" : "Post " + index}
                    </h3>
                    {formattedThreadForm.length > 1 && (
                      <EmpButton
                        onSubmit={() => handleRemoveThread(index)}
                        text={
                          <XCloseIcon
                            backgroundColor={Color.NEUTRAL[500]}
                            size={20}
                          />
                        }
                        buttonHeight="sm"
                        isFullWidth={false}
                        buttonStyle="secondary"
                      />
                    )}
                  </div>
                  <EmpTextInput
                    id={`tweet-${index}`}
                    key={index}
                    formControl={IForm.tweet}
                    characterCount={280}
                    preventEnter={true}
                    multiline
                    textAreaAdaptiveHeight
                    disabled={!isTextInputAvailable}
                    onChange={() => handleChangeThread(IForm.tweet)}
                    onFocus={() => handleChangeThread(IForm.tweet)}
                    placeholder="Tell us about your marketing initiative..."
                    isChangeValueFromParent
                  />
                </div>
                {renderPreviewFile(true, index)}
                <div className="event-post-bottom-section">
                  <div className="file-input-wrapper">
                    <input
                      className="upload-hidden"
                      type="file"
                      id="logo-upload"
                      onChange={(e) => handleFileXUpload(e, index)}
                      name="thread-files[]"
                      multiple={true}
                      accept="image/jpeg, image/jpg, image/png, video/*"
                    />
                    <p className="upload-text">Upload Media</p>
                  </div>
                </div>
              </div>
              {formattedThreadForm.length - 1 === index && (
                <div className="append-tweet-btn mt-1">
                  <EmpButton
                    onSubmit={handleAddThread}
                    text="Append Tweet"
                    buttonHeight="sm"
                    isFullWidth={false}
                    buttonStyle="secondary"
                  />
                </div>
              )}
            </>
          ));
        };

        return renderTweetInput();
      }
      return (
        <div className="text-content-section">
          <div className="top-section">
            <EmpTextInput
              id={"description"}
              multiline
              textAreaAdaptiveHeight
              disabled={!isTextInputAvailable}
              required={false}
              formControl={form.description}
              onChange={() => handleChangePreviewDescription()}
              onFocus={() => handleChangePreviewDescription()}
              rows={10}
              characterCount={300}
              placeholder={"Tell us about your marketing initiative..."}
              isChangeValueFromParent
            />
            {/* <label className="suggested-hashtag">Suggested #hashtag</label>
              <div className="hashtag-list">
                {hashtags.map((hashtag, index) => (
                  <span key={index} onClick={() => handleAddHashtag(hashtag)}>
                    <EmpRoundedPill
                      clickable={!!postType}
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[500]}
                      key={index}
                      text={"#" + hashtag}
                    />
                  </span>
                ))}
              </div> 
            */}
          </div>
          {!!inputFileValidation && (
            <>
              {renderPreviewFile(false)}
              <div className="event-post-bottom-section">
                <div className="file-input-wrapper">
                  <input
                    className="upload-hidden"
                    type="file"
                    id="logo-upload"
                    onChange={handleFileUpload}
                    name="files[]"
                    multiple={isMultipleFileUpload}
                    accept={inputFileValidation}
                    disabled={!inputFileValidation}
                  />
                  <p className="upload-text">Upload Media</p>
                </div>
              </div>
            </>
          )}
        </div>
      );
    }, [
      form,
      handleChangePreviewDescription,
      handleFileUpload,
      inputFileValidation,
      isMultipleFileUpload,
      isTextInputAvailable,
      pollLengthValue,
      renderPreviewFile,
      socialPostType,
    ]);

    const renderSchedulePostWarningMessage = useCallback(() => {
      if (!isSchedulePost) return null;
      return (
        <div className="brand-post-pill-wrapper mt-1 mb-2">
          <EmpPill
            backgroundColor={"transparent"}
            color={Color.NEUTRAL[400]}
            text={`*Post will publish on ${moment(comparedTimezone).format(
              "ddd, DD MMM YYYY HH:mm (UTCZ)"
            )} (${jstz.determine().name()})`}
            size="sm"
          />
        </div>
      );
    }, [comparedTimezone, isSchedulePost]);

    return (
      <>
        <EmpModal
          customClassName="brand-event-post-modal"
          visible={visible}
          setVisible={setVisible}
          header={
            <div className="event-post-modal-header">Edit {modalTitle}</div>
          }
          showHeaderBorder={true}
          showFooter={true}
          showFooterBorder={true}
          onClose={() => {
            dismiss();
          }}
          size={"lg"}
        >
          {/* This is body */}
          <div className="content-wrapper">
            {selectedConnects && postType && (
              <BrandPostPreviewModal
                generalForm={form}
                videoFiles={video}
                imageFiles={imageFiles}
                postType={postType}
                fileUploadedType={uploadedFileType}
                selectedConnects={selectedConnects}
                description={previewDescription}
                socialPostType={socialPostType}
                pollOptions={pollOptions}
                threadForm={socialPostType === "tweet" ? undefined : threadForm}
                threadVideo={threadVideo}
                threadImage={threadImage}
              />
            )}

            {isUploading && (
              <EmpLoaderV2
                isLoading={true}
                loadingText="Wait a moment for the upload to complete"
                background="dark"
              />
            )}

            {renderTextContentSection()}

            {renderWarningMessage()}

            <div className="date-picker-wrapper">
              <label>Post Schedule (Optional)</label>

              <div>
                <EmpDatePicker
                  disabledDate={DateRangePicker.beforeToday}
                  formControl={form.dateOfPosting}
                  placeholder={`Schedule`}
                  onChange={handleDateChange}
                />
                <EmpDateInput
                  formControl={form.timeOfPosting}
                  onChange={handleTimeChange}
                />
              </div>
            </div>

            {renderSchedulePostWarningMessage()}
          </div>

          {/* This is footer */}
          {isEditDraft ? (
            <div className="footer-btn-wrapper">
              <div className="left-side">
                <EmpButton
                  ref={discardBtnRef}
                  text={"Cancel"}
                  isFullWidth={false}
                  buttonStyle="danger"
                  onSubmit={() => {
                    dismiss();
                  }}
                />
                <EmpButton
                  onSubmit={() => handleSaveDraftSubmit()}
                  isFullWidth={false}
                  text={"Save to draft"}
                  buttonHeight="md"
                  buttonStyle="secondary"
                  ref={saveToDraftBtnRef}
                />
              </div>
              <div className="right-side">
                <EmpButton
                  onSubmit={() => handleEditDraftSubmit()}
                  isFullWidth={false}
                  text={
                    isSchedulePost ? "Schedule Post(s)" : "Post Immediately"
                  }
                  buttonHeight="md"
                  ref={postBtnRef}
                  disabled={!isSubmitAvailable()}
                />
              </div>
            </div>
          ) : (
            <div className="footer-btn-wrapper">
              <div className="left-side">
                <EmpButton
                  ref={discardBtnRef}
                  text={"Remove Post"}
                  isFullWidth={false}
                  buttonStyle="danger"
                  onSubmit={() => {
                    deleteModalRef.current?.show(
                      "Delete Scheduled Post",
                      "post",
                      handleDeletePost,
                      postData!.id.toString()
                    );
                  }}
                />
                <EmpButton
                  onSubmit={() => handleSaveDraftSubmit()}
                  isFullWidth={false}
                  text={"Revert to Draft"}
                  buttonHeight="md"
                  buttonStyle="secondary"
                  ref={saveToDraftBtnRef}
                />
              </div>
              <div className="right-side">
                <EmpButton
                  onSubmit={() => {
                    confirmModalRef.current?.show(
                      "Delete Scheduled Post",
                      "post",
                      handleEditPostSubmit
                    );
                  }}
                  isFullWidth={false}
                  text="Post Now"
                  buttonHeight="md"
                  ref={postNowBtnRef}
                  disabled={!isSubmitAvailable()}
                  buttonStyle="secondary"
                />
                <EmpButton
                  onSubmit={() => handleEditPostSubmit(true)}
                  isFullWidth={false}
                  text="Save"
                  buttonHeight="md"
                  ref={postBtnRef}
                  disabled={!isSubmitAvailable()}
                />
              </div>
            </div>
          )}
        </EmpModal>
        <DeleteModal ref={deleteModalRef} onSave={() => {}} suppressToast />
        <ConfirmModal
          description={
            postData ? (
              <>
                Are you sure you want to post immediately? Your schedule to post
                at{" "}
                <span className="highlighted">
                  {moment(postData.scheduledTime * 1000 * 60).format(
                    "DD MMM YYYY, hh:mm A"
                  )}
                </span>{" "}
                will be removed and your post will be published now.
              </>
            ) : undefined
          }
          ref={confirmModalRef}
          onSave={() => {}}
          suppressToast
        />
        {!isUpdatedTikTokSettings && (
          <TikTokConfigPostModal
            ref={tiktokConfigPostModalRef}
            generalForm={form}
            setIsUpdatedTikTokSettings={setIsUpdatedTikTokSettings}
            videoFiles={video}
            imageFiles={imageFiles}
            fnSubmit={() =>
              handleEditPost(
                postData!,
                false,
                isSchedulePost ? "schedule" : "immediately"
              )
            }
          />
        )}
      </>
    );
  }
);

export default BrandEditPostModal;
