import "./creator-join-agency-confirmation-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { FormattedMessage, useIntl } from "react-intl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";

export interface CreatorJoinAgencyConfirmationModalRef {
  show: (agencyInvitation: CreatorAgencyInvitationDto) => void;
  dismiss: () => void;
}

interface Props {
  onSave: (agencyInvitation: CreatorAgencyInvitationDto) => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const CreatorJoinAgencyConfirmationModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const [agency, setAgency] = useState<OrganisationRespDto>();
  const [agencyInvitation, setAgencyInvitation] =
    useState<CreatorAgencyInvitationDto>();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const [form, setForm] = useState<IFormGroup>({
    joinConfirmation: new FormControl("text", [
      new RequiredValidator("Please enter the confirmation text"),
      new PatternValidator(
        /^confirmation to join$/i,
        "Please enter 'confirmation to join' to proceed"
      ),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (agencyInvitation: CreatorAgencyInvitationDto) => {
    setAgencyInvitation(agencyInvitation);
    setAgency(agencyInvitation.agency);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    FormGroupUtil.reset(form);
    setForm({ ...form });
    setAgencyInvitation(undefined);
    setAgency(undefined);
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) {
      setForm({ ...form });
      const isValid = formControl.validate();
      if (isValid) setButtonDisabled(false);
      else setButtonDisabled(true);
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        {agency && (
          <div className="emp-join-agency-confirmation-modal">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 0.2, delay: 0 * animationDelay }}
            >
              <img alt="Profile" className="profile-photo" src={agency.logo} />
            </motion.div>
            <div className="content-section">
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 1 * animationDelay,
                }}
                className="heading"
              >
                <FormattedMessage
                  id="affToExclDisclaimerModal_header"
                  values={{ agency: agency.companyName }}
                />
              </motion.span>
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
                className="description mt-4"
              >
                <FormattedMessage
                  id="affToExclDisclaimerModal_desc"
                  values={{
                    exclusive: (
                      <span className="highlighted">
                        <FormattedMessage id="affToExclDisclaimerModal_exclusivePartnershipText" />
                      </span>
                    ),
                    agency: (
                      <span className="highlighted">{agency.companyName}</span>
                    ),
                    terminated: (
                      <span className="highlighted">
                        <FormattedMessage id="affToExclDisclaimerModal_terminatedText" />
                      </span>
                    ),
                  }}
                />
              </motion.p>
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 4 * animationDelay,
                }}
                className="description mt-4"
              ></motion.p>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className="mt-8"
                transition={{
                  duration: animationDuration,
                  delay: 5 * animationDelay,
                }}
              >
                <EmpTextInput
                  id={intl.formatMessage({
                    id: "affToExclDisclaimerModal_confirmationInputLabel",
                  })}
                  labelText="Confirmation to join agency"
                  description={intl.formatMessage(
                    { id: "affToExclDisclaimerModal_confirmationInputDesc" },
                    { confirmation: "confirmation to join" }
                  )}
                  required
                  formControl={form.joinConfirmation}
                  onChange={validate}
                  placeholder={intl.formatMessage(
                    {
                      id: "affToExclDisclaimerModal_confirmationInputPlaceholder",
                    },
                    { confirmation: "confirmation to join" }
                  )}
                />
              </motion.div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className={"button-wrapper"}
                transition={{
                  duration: animationDuration,
                  delay: 6 * animationDelay,
                }}
              >
                <EmpButton
                  buttonStyle={"secondary"}
                  isFullWidth={false}
                  onSubmit={() => {
                    dismiss();
                  }}
                  className="mt-8"
                  text={<FormattedMessage id="cta_cancel" />}
                />

                <EmpButton
                  disabled={buttonDisabled}
                  isFullWidth={false}
                  onSubmit={() => {
                    props.onSave(agencyInvitation!);
                    dismiss();
                  }}
                  className="mt-8"
                  text={
                    <FormattedMessage id="affToExclDisclaimerModal_proceedToJoinBtn" />
                  }
                />
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CreatorJoinAgencyConfirmationModal;
