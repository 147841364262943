import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import useCampaignAnalytics from "../../../hooks/useCampaignAnalytics";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { CampaignAnalyticsSummaryDto } from "../../../model/campaign/campaign-analytics-summary.dto";
import { motion } from "framer-motion";
import AnnouncementIcon from "../../../components/icon/announcement-icon";
import { Color } from "../../../utilities/colors";
import FileAttachmentIcon from "../../../components/icon/file-attachment-icon";
import { CampaignAnalyticsBreakdownTable } from "../../../components/table/campaign-analytics-breakdown-table";
import { CampaignAnalyticsDetailsDto } from "../../../model/campaign/campaign-analytics-details.dto";
import FormFieldUtils from "../../../utilities/form-field.util";
import EmpMultiFilter from "../../../components/shared/emp-multi-filter/emp-multi-filter";
import { SOCIAL_MEDIA_TYPE } from "../../../constants/app.constants";
import { SelectOptionWithHtml } from "../../../model/common/select-option-with-html";
import FacebookIcon from "../../../components/icon/facebook-icon";
import XIcon from "../../../components/icon/x-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import { SelectOption } from "../../../model/common/selectOption";
import SearchIcon from "../../../components/icon/search-icon";
import "../../../styles/shared/campaign-analytics-view.scss";
import CampaignSmUpdateStatus from "../../../components/views/campaign-sm-update-status";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";
interface Props {
  task: TaskDto;
  user: UserDto;
}

const DELAY_MS = 0.2;
const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

type DetailFilterType = {
  platforms: string[];
  deliverableIds: string[];
};
export const SellerCampaignAnalyticsView = (props: Props) => {
  const { task, user } = props;

  const [campaignSummary, setCampaignSummary] =
    useState<CampaignAnalyticsSummaryDto>();
  const [platformSelectOptions, setPlatformSelectOptions] =
    useState<SelectOptionWithHtml[]>();
  const hasSetPlatformSelectOptionsRef = useRef(false);
  const detailFilterRef = useRef<DetailFilterType>({
    platforms: [],
    deliverableIds: [],
  });

  const [detailsIsUpdating, setDetailsIsUpdating] = useState(false);

  const [details, setDetails] = useState<CampaignAnalyticsDetailsDto[]>();
  const { fetchSummary, fetchDetails } = useCampaignAnalytics(
    task.id,
    "seller"
  );

  const getPlatformSelectOptions = useCallback(():
    | SelectOptionWithHtml[]
    | undefined => {
    const getHtml = (platform: string, icon: JSX.Element): JSX.Element => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          {icon}
          <span>{platform}</span>
        </div>
      );
    };

    if (!details) return;

    const platformSet = new Set<string>();
    for (let detail of details) {
      for (let record of detail.evidenceRecordMetrics) {
        platformSet.add(record.evidenceRecord.platform);
      }
    }

    const result: SelectOptionWithHtml[] = [];
    const iconOptions = { backgroundColor: Color.NEUTRAL[400], size: 14 };
    if (platformSet.has(SOCIAL_MEDIA_TYPE.FACEBOOK)) {
      result.push({
        label: SOCIAL_MEDIA_TYPE.FACEBOOK,
        value: SOCIAL_MEDIA_TYPE.FACEBOOK,
        html: getHtml(
          SOCIAL_MEDIA_TYPE.FACEBOOK,
          <FacebookIcon {...iconOptions} />
        ),
      });
    }
    if (platformSet.has(SOCIAL_MEDIA_TYPE.INSTAGRAM)) {
      result.push({
        label: SOCIAL_MEDIA_TYPE.INSTAGRAM,
        value: SOCIAL_MEDIA_TYPE.INSTAGRAM,
        html: getHtml(
          SOCIAL_MEDIA_TYPE.INSTAGRAM,
          <InstagramIcon {...iconOptions} />
        ),
      });
    }
    if (platformSet.has(SOCIAL_MEDIA_TYPE.TIKTOK)) {
      result.push({
        label: SOCIAL_MEDIA_TYPE.TIKTOK,
        value: SOCIAL_MEDIA_TYPE.TIKTOK,
        html: getHtml(
          SOCIAL_MEDIA_TYPE.TIKTOK,
          <TikTokIcon {...iconOptions} />
        ),
      });
    }
    if (platformSet.has(SOCIAL_MEDIA_TYPE.X)) {
      result.push({
        label: SOCIAL_MEDIA_TYPE.X,
        value: SOCIAL_MEDIA_TYPE.X,
        html: getHtml(SOCIAL_MEDIA_TYPE.X, <XIcon {...iconOptions} />),
      });
    }
    return result;
  }, [details]);

  useEffect(() => {
    if (hasSetPlatformSelectOptionsRef.current || !details) return;
    const options = getPlatformSelectOptions();
    setPlatformSelectOptions(options);
    hasSetPlatformSelectOptionsRef.current = true;
  }, [getPlatformSelectOptions, details]);

  const deliverableSelectOptions: SelectOption[] = useMemo(() => {
    const results: SelectOption[] = [];
    for (let i = 0; i < task.deliverables.length; i++) {
      results.push({
        value: task.deliverables[i].id,
        label: `Deliverable ${i + 1}`,
      });
    }
    return results;
  }, [task]);

  const retrieveSummary = useCallback(async () => {
    const resp = await fetchSummary();
    setCampaignSummary(resp);
  }, [fetchSummary]);

  const retrieveDetails = useCallback(async () => {
    try {
      setDetailsIsUpdating(true);
      const resp = await fetchDetails(detailFilterRef.current);
      resp.sort((a, b) => {
        const aHasEvidence = a.evidenceRecordMetrics.length > 0;
        const bHasEvidence = b.evidenceRecordMetrics.length > 0;
        if (aHasEvidence && !bHasEvidence) return -1;
        if (!aHasEvidence && bHasEvidence) return 1;
        return 0;
      });
      setDetails(resp);
    } finally {
      setDetailsIsUpdating(false);
    }
  }, [fetchDetails]);

  useEffect(() => {
    retrieveSummary();
    retrieveDetails();
  }, [retrieveSummary, retrieveDetails]);

  const costPerEngagement: JSX.Element | string = useMemo(() => {
    if (!campaignSummary?.metrics?.engagement?.costPerValue) return "0.00";
    const cpv = campaignSummary.metrics.engagement.costPerValue;
    if (cpv === -1) return "Hidden";
    return <>{FormFieldUtils.formatNumber(cpv)}</>;
  }, [campaignSummary]);

  const costPerImpression: JSX.Element | string = useMemo(() => {
    if (!campaignSummary?.metrics?.impression?.costPerValue) return "0.00";
    const cpv = campaignSummary.metrics.impression.costPerValue;
    console.log(FormFieldUtils.formatNumber(cpv));
    if (cpv === -1) return "Hidden";
    return <>{FormFieldUtils.formatNumber(cpv)}</>;
  }, [campaignSummary]);

  return (
    <section className="emp-campaign-analytics-view">
      <section className="task-details-section">
        <div className="task-name-wrapper">
          <div className="info-wrapper">
            <h2 className="section-label">Campaign Analytics</h2>
            <p className="emp-paragraph">
              View analytics based on submitted proof of work.
            </p>
          </div>
          <CampaignSmUpdateStatus task={task} role={"seller"} />
        </div>
      </section>

      <section className="metrics-section mt-4">
        <div className="metrics-tile-wrapper">
          <motion.div
            className="metric-tile"
            initial="hidden"
            animate={"visible"}
            transition={{ delay: 1.5 * DELAY_MS, duration: 0.2 }}
            variants={fadeInVariant}
          >
            <div className="details-wrapper">
              {campaignSummary && (
                <span className="metric-value">
                  {FormFieldUtils.toCommify(
                    campaignSummary.metrics.impression.value
                  )}
                </span>
              )}
              {!campaignSummary && (
                <div className="empty-metric-value emp-shimmer"></div>
              )}
              <AnnouncementIcon
                top={2}
                size={24}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
            <span className="metric-header">Total Impressions</span>
          </motion.div>

          <motion.div
            className="metric-tile"
            initial="hidden"
            animate={"visible"}
            transition={{ delay: 2.5 * DELAY_MS, duration: 0.2 }}
            variants={fadeInVariant}
          >
            <div className="details-wrapper">
              {campaignSummary && (
                <span className="metric-value">
                  {FormFieldUtils.toCommify(
                    campaignSummary.metrics.totalInteractionCount.value
                  )}
                </span>
              )}
              {!campaignSummary && (
                <div className="empty-metric-value emp-shimmer"></div>
              )}
              <FileAttachmentIcon
                top={2}
                size={24}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
            <span className="metric-header">Total Interactions</span>
          </motion.div>
          <motion.div
            className="metric-tile"
            initial="hidden"
            animate={"visible"}
            transition={{ delay: 2 * DELAY_MS, duration: 0.2 }}
            variants={fadeInVariant}
          >
            <div className="details-wrapper">
              {campaignSummary && (
                <span
                  className="metric-value"
                  style={{ display: "flex", gap: 4 }}
                >
                  {costPerEngagement}
                  <EmpCoinIcon
                    size={24}
                    iconValue={task.paymentMode}
                    mode="icon-only"
                  />
                </span>
              )}
              {!campaignSummary && (
                <div className="empty-metric-value emp-shimmer"></div>
              )}
              <FileAttachmentIcon
                top={2}
                size={24}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
            <span className="metric-header">Cost per Engagement</span>
          </motion.div>
          <motion.div
            className="metric-tile"
            initial="hidden"
            animate={"visible"}
            transition={{ delay: 3 * DELAY_MS, duration: 0.2 }}
            variants={fadeInVariant}
          >
            <div className="details-wrapper">
              {campaignSummary && (
                <span
                  className="metric-value"
                  style={{ display: "flex", gap: 4 }}
                >
                  {costPerImpression}
                  <EmpCoinIcon
                    size={24}
                    iconValue={task.paymentMode}
                    mode="icon-only"
                  />
                </span>
              )}
              {!campaignSummary && (
                <div className="empty-metric-value emp-shimmer"></div>
              )}
              <FileAttachmentIcon
                top={2}
                size={24}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
            <span className="metric-header">Cost Per Impression</span>
          </motion.div>
        </div>
      </section>

      <section className="table-details-section mt-4">
        <h2>Analytics Breakdown</h2>
        <div className="mt-4">
          <section className="form-section">
            <div className="control">
              {platformSelectOptions && (
                <EmpMultiFilter
                  label={"Platforms"}
                  allByDefault
                  hasAllOption
                  onChange={(values: string[]) => {
                    detailFilterRef.current.platforms = values;
                  }}
                  menuItems={platformSelectOptions}
                />
              )}
            </div>
            <div className="control">
              {deliverableSelectOptions && (
                <EmpMultiFilter
                  label={"Deliverables"}
                  allByDefault
                  hasAllOption
                  onChange={(values: string[]) => {
                    detailFilterRef.current.deliverableIds = values;
                  }}
                  menuItems={deliverableSelectOptions}
                />
              )}
            </div>
            <div className="button-control">
              <EmpButton
                text={"Search"}
                leftIcon={SearchIcon}
                buttonHeight="lg"
                buttonStyle="secondary"
                isFullWidth={false}
                onSubmit={retrieveDetails}
              />
            </div>
          </section>

          <CampaignAnalyticsBreakdownTable
            role="seller"
            rawRecords={details}
            isLoading={!details}
            progressLoading={detailsIsUpdating}
            task={task}
          />
        </div>
      </section>
    </section>
  );
};
