import { useCallback, useEffect, useState } from "react";
import "./support-view.scss";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import UserApi from "../../../api/user-msvc/user.api";
import EmpException from "../../../exception/empException";
import CsOrgAssignmentApi from "../../../api/referral-and-support-msvc/cs-org-assignment.api";
import { AdminUserDto } from "../../../model/support-referral/cs-org-assignment/admin-user.dto";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import EmailIcon from "../../../components/icon/email-icon";
import { Color } from "../../../utilities/colors";
import CopyIcon from "../../../components/icon/copy-icon";
import ToastUtils from "../../../utilities/toast-utils";
import { FormattedMessage } from "react-intl";

export const SupportView = () => {
  const [customerSuccess, setCustomerSuccess] = useState<AdminUserDto>();

  /**
   * Retrieves details for the current user.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await UserApi.fetchUser();
      const user = response.data;
      if (!user.organisation || user.organisation.length === 0)
        throw new EmpException("No Organisation");
      const organisation = user.organisation[0];
      const csObj = await CsOrgAssignmentApi.fetchOrgAssignmentByOrgId(
        organisation.id
      );
      setCustomerSuccess(csObj.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return (
    <div className="emp-support-view">
      <div className="view-header-wrapper">
        <div>
          <h1>
            <FormattedMessage id="settingsSupportView_header" />
          </h1>
          <p className="page-description">
            <FormattedMessage id="settingsSupportView_desc" />
          </p>
        </div>
      </div>
      {customerSuccess && (
        <div className="card-wrapper">
          <div className="support-card">
            <div className="image-wrapper">
              {customerSuccess.imageType === "url" && (
                <img alt="Profile" src={customerSuccess.imageResource} />
              )}
              {customerSuccess.imageType === "avatar" && (
                <div
                  style={{ background: customerSuccess.imageResource }}
                  className="avatar"
                >
                  <span className="initials">{customerSuccess.initials}</span>
                </div>
              )}
            </div>
            <div className="details-wrapper">
              <span className="name-lbl block">{customerSuccess.fullName}</span>
              <span className="email-lbl block mt-1">
                {customerSuccess.email}
              </span>
            </div>
            <div className="action-wrapper">
              <EmpIconButton
                onSubmit={() => {
                  ToastUtils.success("Copied", "Email Address Copied");
                  navigator.clipboard.writeText(customerSuccess.email);
                }}
                buttonStyle="secondary"
                icon={
                  <CopyIcon size={18} backgroundColor={Color.NEUTRAL[500]} />
                }
              />
              <EmpIconButton
                onSubmit={() => {
                  const emailAddress = customerSuccess.email;
                  const mailtoLink = `mailto:${emailAddress}`;
                  window.location.href = mailtoLink;
                }}
                buttonStyle="secondary"
                icon={
                  <EmailIcon size={18} backgroundColor={Color.NEUTRAL[500]} />
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
