import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./delete-modal.scss";
import { FormControl } from "../../utilities/formUtils/formControl";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import EmpException from "../../exception/empException";
import { RejectTaskDto } from "../../model/campaign/reject-task.dto";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import ToastUtils from "../../utilities/toast-utils";

type ModalIntent = "reject" | "accept" | "none";
type ModalActionIntent = "reject" | "accept";

export interface TaskResponseRemarksModalRef {
  // Context = what type of item needs to be deleted, item = name of item that needs to be deleted
  show: (
    roleContext: string,
    intent: ModalActionIntent,
    latestNegotiation: LatestNegotiationExtendedDto
  ) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const TaskResponseRemarksModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const actionBtnRef = useRef<EmpButtonRef>(null);

  const roleContextRef = useRef<string>();
  const [latestNegotiation, setLatestNegotiation] =
    useState<LatestNegotiationExtendedDto>();
  const [intent, setIntent] = useState<ModalIntent>("none");

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = useCallback(
    async (
      roleContext: string,
      modalIntent: ModalActionIntent,
      latestNegotiation: LatestNegotiationExtendedDto
    ) => {
      setLatestNegotiation(latestNegotiation);
      setIntent(modalIntent);
      roleContextRef.current = roleContext;
      setVisible(true);
    },
    []
  );

  const dismiss = () => {
    FormGroupUtil.reset(acceptRejectForm);
    setAcceptRejectForm({ ...acceptRejectForm });
    setVisible(false);
  };

  const [acceptRejectForm, setAcceptRejectForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [
      new LengthValidator(
        10,
        400,
        "Description must be at least 10 characters",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  /**
   * Handles the rejection of a task with the provided details.
   *
   * @function
   * @async
   * @throws {EmpException} Throws an exception if required context or negotiation details are missing.
   */
  const rejectTask = useCallback(async () => {
    try {
      if (!latestNegotiation) {
        throw new EmpException(
          "latest negotiation is required",
          "Invalid state"
        );
      }
      if (!roleContextRef.current)
        throw new EmpException("context is required", "Invalid State");

      const isValid = FormGroupUtil.validate(acceptRejectForm);
      console.log(isValid);
      setAcceptRejectForm({ ...acceptRejectForm });
      if (!isValid) return;

      const request: RejectTaskDto = {
        role: roleContextRef.current,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: acceptRejectForm.remarks.getValue(),
      };

      const resp = await NegotiationApi.rejectTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Rejected!", "You have rejected this application!");
        onSave();
        dismiss();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error rejecting task application"
      );
    }
  }, [acceptRejectForm, onSave, latestNegotiation]);

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showHeaderBorder={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      modalGlobalElement={
        <button onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </button>
      }
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-task-response-ramarks-modal">
        <section className="emp-modal-common-header">
          <div className="emp-modal-hstack-wrapper">
            {intent === "reject" && <h2>Reject User</h2>}
            {intent === "accept" && <h2>Accept User</h2>}
          </div>
          {intent === "reject" && (
            <p className="emp-paragraph mt-2">
              Are you certain you want to reject the user? You may also provide
              optional remarks to state the reason for rejection.
            </p>
          )}
        </section>
        <section className="mt-6 ph-4">
          <EmpTextInput
            id={"remarks"}
            labelText="Remarks (Optional)"
            multiline
            textAreaAdaptiveHeight
            tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
            formControl={acceptRejectForm.remarks}
            rows={2}
            characterCount={300}
            placeholder={"Enter your remarks here..."}
          />
        </section>
        <div
          className="ph-4"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 16,
          }}
        >
          {intent === "reject" && (
            <div style={{ width: "fit-content" }}>
              <EmpButton
                ref={actionBtnRef}
                onSubmit={rejectTask}
                text={"Reject"}
                buttonStyle="danger"
              />
            </div>
          )}
          <div style={{ width: "fit-content", marginRight: 8 }}>
            <EmpButton
              text={<FormattedMessage id="cta_cancel" />}
              buttonStyle="secondary"
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default TaskResponseRemarksModal;
