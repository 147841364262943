import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckIcon from "../../components/icon/check-icon";
import LogOut3Icon from "../../components/icon/log-out-3-icon";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import { PermsUtils } from "../../constants/permissions.constants";
import { Color } from "../../utilities/colors";
import "./creator-onboarding-page.scss";
import { CreatorBasicInfoStep } from "./views/creator-basic-info-step";
import { SelectCreatorInfoStep } from "./views/select-creator-info-step";
import { SocialMediaStep } from "./views/social-media-step";
import useEmpAuth from "../../hooks/useEmpAuth";
import useUser from "../../hooks/useUser";
import { PLATFORM_ROLES } from "../../constants/app.constants";
import EmpButton from "../../components/shared/emp-button/emp-button";
import ChevronLeftIcon from "../../components/icon/chevron-left";

type FromOriginType = "external-role-selection";

function isFromOriginType(value: string): value is FromOriginType {
  return value === "external-role-selection";
}
export const CreatorOnboardingPage = () => {
  const [searchParams] = useSearchParams();
  const [fromOrigin, setFromOrigin] = useState<FromOriginType | null>(null);

  useEffect(() => {
    const paramValue = searchParams.get("from");
    if (paramValue) {
      if (isFromOriginType(paramValue)) {
        setFromOrigin(paramValue);
      }
    }
  }, [searchParams]);

  const navigate = useNavigate();
  const [stepperStatus, setStepperStatus] = useState({
    "basic-info": "in-progress",
    "social-media": "pending",
    "creator-info": "pending",
  });
  const [currentStep, setCurrentStep] = useState<
    "basic-info" | "social-media" | "creator-info"
  >("basic-info");

  const { signOut } = useEmpAuth();
  const { user } = useUser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (!user) return;
    const roleClassification = PermsUtils.getUserRole(user);
    if (roleClassification === PLATFORM_ROLES.AGENCY) {
      navigate("/agency/home");
    } else if (roleClassification === PLATFORM_ROLES.BRAND) {
      navigate("/brand/home");
    }
  }, [user, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const scopes = urlParams.get("scopes");
    if (code && scopes) {
      setStepperStatus((prevStepperStatus) => {
        prevStepperStatus["basic-info"] = "done";
        prevStepperStatus["social-media"] = "in-progress";
        return { ...prevStepperStatus };
      });
      setCurrentStep("social-media");
      window.location.hash = "social-media";
    }
  }, []);

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    const fragment = window.location.hash;
    if (fragment !== "") {
      if (fragment === "#basic-info") {
        setCurrentStep("basic-info");
      } else if (fragment === "#social-media") {
        setCurrentStep("social-media");
      } else if (fragment === "#creator-info") {
        setCurrentStep("creator-info");
      }
    } else {
      setCurrentStep("basic-info");
    }
  }, []);

  const advanceStep = (origin: string, intent: "done" | "skip") => {
    if (origin === "basic-info") {
      stepperStatus["basic-info"] = intent;
      stepperStatus["social-media"] = "in-progress";
      setCurrentStep("social-media");
      window.location.hash = "social-media";
    } else if (origin === "social-media") {
      stepperStatus["social-media"] = intent;
      stepperStatus["creator-info"] = "in-progress";
      setCurrentStep("creator-info");
      window.location.hash = "creator-info";
    } else if (origin === "creator-info") {
      navigate("/creator/home");
    }
    setStepperStatus({ ...stepperStatus });
  };

  const skipStep = (origin: "basic-info" | "social-media" | "creator-info") => {
    if (
      (origin === "social-media" || origin === "creator-info") &&
      stepperStatus["basic-info"] == "in-progress"
    )
      return;
    setCurrentStep(origin);
    stepperStatus[origin] = "in-progress";
    window.location.hash = origin;
    setStepperStatus({ ...stepperStatus });
  };

  return (
    <div className="emp-creator-onboarding-page">
      <div className="overall-content-wrapper">
        {fromOrigin === "external-role-selection" && (
          <EmpButton
            isFullWidth={false}
            onSubmit={() => {
              navigate("/external/role-selection");
            }}
            text={"Back to role selection"}
            leftIcon={ChevronLeftIcon}
            buttonStyle="secondary"
            buttonHeight="sm"
            className="mb-2"
          />
        )}
        <div className="content-wrapper">
          <div className="progress-section">
            <div className="stepper-section">
              <div className="logo-wrapper">
                <img
                  className="logo"
                  alt="emplifive logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
                <div className="control-wrapper">
                  <EmpIconButton
                    onSubmit={() => {
                      signOut();
                    }}
                    buttonStyle="secondary"
                    icon={
                      <LogOut3Icon
                        size={17}
                        backgroundColor={Color.NEUTRAL[500]}
                      />
                    }
                  />
                </div>
              </div>
              <span className="title-lbl mt-3">
                <FormattedMessage id="creatorOnboardingPage_header" />
              </span>
              <div className="progress-wrapper mt-3">
                <div className="step completed"></div>
                <div
                  className={`step ${
                    ["social-media", "creator-info"].includes(currentStep)
                      ? "completed"
                      : ""
                  }`}
                ></div>
                <div
                  className={`step ${
                    currentStep === "creator-info" ? "completed" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="step-item-section">
              <div
                className="step"
                onClick={() => {
                  skipStep("basic-info");
                }}
              >
                {stepperStatus["basic-info"] === "in-progress" && (
                  <div className="in-progress"></div>
                )}
                {stepperStatus["basic-info"] === "pending" && (
                  <div className="unchecked">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[400]} />
                  </div>
                )}
                {stepperStatus["basic-info"] === "done" && (
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                {stepperStatus["basic-info"] === "skip" && (
                  <div className="skipped">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                <span className="step-label">
                  <FormattedMessage id="creatorOnboardingPage_personalDetailsStep" />
                </span>
              </div>
              <div
                className="step"
                onClick={() => {
                  skipStep("social-media");
                }}
              >
                {stepperStatus["social-media"] === "in-progress" && (
                  <div className="in-progress"></div>
                )}
                {stepperStatus["social-media"] === "pending" && (
                  <div className="unchecked">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[400]} />
                  </div>
                )}
                {stepperStatus["social-media"] === "done" && (
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                {stepperStatus["social-media"] === "skip" && (
                  <div className="skipped">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                <span className="step-label">
                  <FormattedMessage id="creatorOnboardingPage_bindAccountsStep" />
                </span>
              </div>
              <div
                className="step"
                onClick={() => {
                  skipStep("creator-info");
                }}
              >
                {stepperStatus["creator-info"] === "in-progress" && (
                  <div className="in-progress"></div>
                )}
                {stepperStatus["creator-info"] === "pending" && (
                  <div className="unchecked">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[400]} />
                  </div>
                )}
                {stepperStatus["creator-info"] === "done" && (
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                {stepperStatus["creator-info"] === "skip" && (
                  <div className="skipped">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                <span className="step-label">
                  <FormattedMessage id="creatorOnboardingPage_servicesAndInterestsStep" />
                </span>
              </div>
            </div>
          </div>
          {currentStep === "basic-info" && (
            <CreatorBasicInfoStep
              nextStep={(intent) => {
                advanceStep("basic-info", intent);
              }}
            />
          )}
          {currentStep === "social-media" && (
            <SocialMediaStep
              nextStep={(intent) => {
                advanceStep("social-media", intent);
              }}
              prevStep={() => {
                setCurrentStep("basic-info");
                stepperStatus["basic-info"] = "in-progress";
                setStepperStatus({ ...stepperStatus });
              }}
            />
          )}
          {currentStep === "creator-info" && (
            <SelectCreatorInfoStep
              nextStep={(intent) => {
                advanceStep("creator-info", intent);
              }}
              prevStep={() => {
                setCurrentStep("social-media");
                stepperStatus["social-media"] = "in-progress";
                setStepperStatus({ ...stepperStatus });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
