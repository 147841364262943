import { useState, useEffect, useRef, MutableRefObject } from "react";

const useElementWidth = (): [
  number,
  MutableRefObject<HTMLDivElement | null>
] => {
  const [width, setWidth] = useState<number>(0);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = elementRef.current;

    const updateWidth = () => {
      if (element) {
        setWidth(element.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === element) {
          updateWidth();
        }
      }
    });

    if (element) {
      resizeObserver.observe(element);
      updateWidth(); // Initial width
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  return [width, elementRef];
};

export default useElementWidth;
