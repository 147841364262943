import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
}

export const CreatorInvitationResponseNotification = (props: Props) => {
  const intl = useIntl();
  const [isLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  const translateInvitationOutcome = (state: string) => {
    if (state === "accepted")
      return intl.formatMessage({
        id: "creatorInvitationResponseNotification_accepted",
      });
    if (state === "rejected")
      return intl.formatMessage({
        id: "creatorInvitationResponseNotification_rejected",
      });
  };

  return (
    <div className="emp-notification-record" onClick={notifOnClick}>
      {isLoading && (
        <div className="loader">
          <div className="emp-spinner"></div>
        </div>
      )}

      {/* Organisation Img */}
      {notification.sender.senderType === "organisation" && (
        <img
          alt={notification.sender.senderName}
          className="organisation-img"
          src={notification.sender.imageResource}
        />
      )}
      {/* User Img */}
      {notification.sender.senderType === "user" && (
        <>
          {notification.sender.imageType === "url" && (
            <img
              alt={notification.sender.senderName}
              className="user-img"
              src={notification.sender.imageResource}
            />
          )}
          {notification.sender.imageType === "avatar" && (
            <div
              style={{ background: notification.sender.imageResource }}
              className="user-avatar"
            >
              <span className="initials">{notification.sender.initials}</span>
            </div>
          )}
        </>
      )}
      <div className="notification-content">
        <div className="content-wrapper">
          <span className="info-text">
            <FormattedMessage
              id="creatorInvitationResponseNotification_content"
              values={{
                sender: notification.sender.senderName,
                action: (
                  <span className="emp-highlighted">
                    {translateInvitationOutcome(notification.metadata.state)}
                  </span>
                ),
              }}
            />
          </span>
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
        </div>
      </div>
    </div>
  );
};
