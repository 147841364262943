import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { empDelay } from "../../utilities/delay";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./review-deliverable-changelog-modal.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpContent from "../shared/emp-content/emp-content";
import FormFieldUtils from "../../utilities/form-field.util";
import { DateUtil } from "../../utilities/date";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import { DeliverableChangelogComparison } from "../../model/campaign/deliverable-changelog-comparison.dto";
import EmpEditorView from "../shared/emp-editor/emp-editor-viewer";
import EmpException from "../../exception/empException";
import { RejectTaskDto } from "../../model/campaign/reject-task.dto";
import { AcceptTaskDto } from "../../model/campaign/accept-task.dto";
import ToastUtils from "../../utilities/toast-utils";
import { motion } from "framer-motion";
import { NEGOTIATION_STATUS } from "../../constants/app.constants";
import EmpLink from "../shared/emp-link/emp-link";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "./task-deliverable-modal";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { CounterofferDto } from "../../model/campaign/counteroffer.dto";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../icon/chevron-left";
import { EmpCoinIcon } from "../shared/emp-coin-icon/emp-coin-icon";

export interface ReviewDeliverableChangelogModalRef {
  show: (task: TaskDto, context: "seller" | "brand") => void;
  dismiss: () => void;
}

interface Props {
  latestNegotiations: LatestNegotiationExtendedDto[];
  onSave: () => void;
}

type ModalViewMode = "list-view" | "detail-view";

const ReviewDeliverableChangelogModal = forwardRef((props: Props, ref) => {
  const { onSave, latestNegotiations: latestNegotiationsProps } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [latestNegotiations, setLatestNegotiations] = useState<
    LatestNegotiationExtendedDto[]
  >([]);
  const [selectedNegotiation, setSelectedNegotiation] =
    useState<LatestNegotiationExtendedDto>();
  const [modalMode, setModalMode] = useState<ModalViewMode>("list-view");
  const [hasBackButton, setHasBackButton] = useState(false);
  const taskRef = useRef<TaskDto>();
  const contextRef = useRef<"seller" | "brand">();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  useEffect(() => {
    setLatestNegotiations(latestNegotiationsProps);
    setHasBackButton(latestNegotiationsProps.length > 1);
  }, [latestNegotiationsProps]);

  const show = async (task: TaskDto, context: "seller" | "brand") => {
    try {
      taskRef.current = task;
      contextRef.current = context;

      if (latestNegotiations.length === 0) return;
      // Set the first entry as the detail view
      else if (latestNegotiations.length === 1) {
        setSelectedNegotiation(latestNegotiations[0]);
        setModalMode("detail-view");
        setHasBackButton(false);
      } else {
        setSelectedNegotiation(undefined);
        setModalMode("list-view");
        setHasBackButton(true);
      }
      setVisible(true);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error occurred in update negotiation changelog modal"
      );
    }
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      shouldOverflow={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={modalMode === "list-view" ? "sm" : "lg"}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
    >
      <div className="emp-review-deliverable-changelog-modal">
        {modalMode === "list-view" && (
          <section className="list-view">
            <section className="emp-modal-common-header">
              <div className="emp-modal-hstack-wrapper">
                <h2>Review Updated Deliverables</h2>
              </div>
              <p className="emp-paragraph mt-2">
                The brand has revised the deliverables. Please review the
                changes to accept, reject, or suggest a new offer, ensuring fair
                compensation.
              </p>
            </section>
            {latestNegotiations && (
              <section className="response-section">
                {latestNegotiations.map((elem) => {
                  return (
                    <div key={elem.id} className={`response-form-row`}>
                      <div className="relative-wrapper">
                        {elem.creatorRepresentative.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creatorRepresentative.fullName}
                            src={elem.creatorRepresentative.imageResource}
                          />
                        )}
                        {elem.creatorRepresentative.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{
                              background:
                                elem.creatorRepresentative.imageResource,
                            }}
                          >
                            <span className="initials">
                              {elem.creatorRepresentative.initials}
                            </span>
                          </div>
                        )}
                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-section">
                        <div className="rep-name-section">
                          <span className="rep-name-lbl">
                            {elem.creatorRepresentative.fullName}
                          </span>
                        </div>
                        <div className="rate-section mt-1">
                          <span className="rate-lbl">
                            {elem.amount}
                            {taskRef.current && (
                              <EmpCoinIcon
                                top={2}
                                className="ml-1"
                                iconValue={taskRef.current.paymentMode}
                                mode="icon-only"
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="form-section">
                        <EmpButton
                          buttonHeight="sm"
                          buttonStyle="secondary"
                          text={
                            contextRef.current === "seller" ? "Review" : "View"
                          }
                          onSubmit={() => {
                            setSelectedNegotiation(elem);
                            setModalMode("detail-view");
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </section>
            )}
            <section className="button-wrapper-section">
              <EmpButton
                buttonStyle="secondary"
                isFullWidth={false}
                onSubmit={dismiss}
                text={"Cancel"}
              />
            </section>
          </section>
        )}
        {modalMode === "detail-view" &&
          selectedNegotiation &&
          taskRef.current &&
          contextRef.current && (
            <ReviewDeliverableView
              latestNegotiation={selectedNegotiation}
              onClose={dismiss}
              onComplete={() => {
                onSave();
                if (!hasBackButton) dismiss();
              }}
              task={taskRef.current}
              hasBackButton={hasBackButton}
              onBack={() => {
                setModalMode("list-view");
              }}
              context={contextRef.current}
            />
          )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default ReviewDeliverableChangelogModal;

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

interface RdProps {
  latestNegotiation: LatestNegotiationExtendedDto;
  task: TaskDto;
  context: "brand" | "seller";
  hasBackButton: boolean;
  onBack: () => void;
  onClose: () => void;
  onComplete: () => void;
}
const ReviewDeliverableView = (rdProps: RdProps) => {
  const [modalViewMode, setModalViewMode] = useState<
    "default" | "counteroffer"
  >("default");

  const {
    latestNegotiation,
    task,
    context,
    onComplete,
    onClose,
    hasBackButton,
    onBack,
  } = rdProps;
  const [deliverableComparison, setDeliverableComparison] =
    useState<DeliverableChangelogComparison>();

  const rejectBtnRef = useRef<EmpButtonRef>();
  const acceptBtnRef = useRef<EmpButtonRef>();
  const counterofferBtnRef = useRef<EmpButtonRef>();
  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();

  const fetchDeliverableChangelogComparison = useCallback(
    async (negotiationId: string) => {
      try {
        setModalViewMode("default");
        const resp = await NegotiationApi.getDeliverableChangelogComparison(
          negotiationId
        );
        console.log(resp.data);
        setDeliverableComparison(resp.data);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator info status"
        );
      }
    },
    []
  );

  /**
   * Accepts the task based on the latest negotiation details.
   *
   * @returns {Promise<void>} - A promise that resolves when the task is accepted.
   * @throws {Error} - If an error occurs while accepting the task.
   */
  const acceptTask = async () => {
    try {
      acceptBtnRef.current?.setButtonState("loading");
      if (!context)
        throw new EmpException("context is required", "Invalid State");
      const request: AcceptTaskDto = {
        role: context,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: "",
      };
      const resp = await NegotiationApi.acceptTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Accepted!",
          "You have accepted the revised deliverables for this task."
        );
        if (hasBackButton) {
          onBack();
        }
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error accepting task");
    } finally {
      acceptBtnRef.current?.setButtonState("default");
    }
  };

  /**
   * Rejects the task based on the latest negotiation details.
   *
   * @returns {Promise<void>} - A promise that resolves when the task is rejected.
   * @throws {Error} - If an error occurs while rejecting the task.
   */
  const rejectTask = async () => {
    try {
      rejectBtnRef.current?.setButtonState("loading");
      if (!context)
        throw new EmpException("context is required", "Invalid State");
      const request: RejectTaskDto = {
        role: context,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: "",
      };
      const resp = await NegotiationApi.rejectTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Rejected!",
          "You will no longer be part of this task."
        );
        if (hasBackButton) {
          onBack();
        }
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error rejecting task");
    } finally {
      rejectBtnRef.current?.setButtonState("default");
    }
  };

  useEffect(() => {
    fetchDeliverableChangelogComparison(latestNegotiation.id);
  }, [latestNegotiation, fetchDeliverableChangelogComparison]);

  // Task Counteroffer View
  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const [form, setForm] = useState<IFormGroup>({
    amount: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const sendCounteroffer = async () => {
    try {
      counterofferBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      console.log(isValid);
      setForm({ ...form });
      if (!isValid) return;

      const request: CounterofferDto = {
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: form.amount.getValue(),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: form.remarks.getValue(),
      };
      const resp = await NegotiationApi.sendSellerCounteroffer(request);

      if (resp.data.status === "success") {
        ToastUtils.success(
          "Counteroffer Submitted",
          "You have submitted a counteroffer for the revised deliverables."
        );
        if (hasBackButton) {
          onBack();
        }
        onComplete();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error rejecting task application"
      );
    } finally {
      counterofferBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <section className="review-deliverable-view">
      <section className="emp-modal-common-header">
        <div className="emp-modal-hstack-wrapper">
          {hasBackButton && (
            <EmpIconButton
              suppressMobileView
              onSubmit={onBack}
              buttonStyle="secondary"
              icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[500]} />}
            />
          )}
          <h2>Review Updated Deliverables</h2>
        </div>
        <p className="emp-paragraph mt-2">
          Please review the changes to accept, reject, or suggest a new offer,
          ensuring fair compensation.
        </p>
      </section>

      {/* ModalViewMode === "default" */}
      {modalViewMode === "default" && (
        <>
          <section className="details-section">
            <div className="creator-wrapper">
              <div className="relative-wrapper">
                {latestNegotiation.creatorRepresentative.imageType ===
                  "url" && (
                  <img
                    className={`profile-photo`}
                    alt={latestNegotiation.creatorRepresentative.fullName}
                    src={latestNegotiation.creatorRepresentative.imageResource}
                  />
                )}
                {latestNegotiation.creatorRepresentative.imageType ===
                  "avatar" && (
                  <div
                    className={`avatar`}
                    style={{
                      background:
                        latestNegotiation.creatorRepresentative.imageResource,
                    }}
                  >
                    <span className="initials">
                      {latestNegotiation.creatorRepresentative.initials}
                    </span>
                  </div>
                )}
              </div>
              <span className="name-lbl">
                {latestNegotiation.creatorRepresentative.fullName}
              </span>
            </div>
            <div className="details-wrapper">
              <EmpContent
                label={"AMOUNT"}
                value={FormFieldUtils.toMoney(
                  Number(latestNegotiation.amount),
                  true
                )}
              />
              <EmpContent
                label={"CREATED DATE"}
                value={DateUtil.toReadableDateWithTime(
                  latestNegotiation.createdDate
                )}
              />
            </div>
          </section>

          <section className="deliverable-changelog-section">
            <h3 className="deliverable-changelog-section-header">
              Deliverable Changelog
            </h3>
            <section className="comparison-section">
              <div className="comparison-column">
                <div className="column-header-wrapper first">Before</div>
                <div className="column-body-wrapper first">
                  {deliverableComparison &&
                    deliverableComparison.oldVersionDeliverables.map(
                      (elem, index) => {
                        return (
                          <article className="deliverable-card">
                            <span key={index} className="deliverable-indicator">
                              DELIVERABLE {index + 1}
                            </span>
                            <h3 className="deliverable-name-lbl">
                              {elem.name}
                            </h3>
                            <div className="mt-1">
                              <EmpEditorView value={elem.description} />
                            </div>
                          </article>
                        );
                      }
                    )}
                  {!deliverableComparison &&
                    [0, 1].map((elem) => {
                      return (
                        <article className="deliverable-card-shimmer">
                          <div className="deliverable-number-shimmer emp-shimmer"></div>
                          <div className="deliverable-name-shimmer emp-shimmer"></div>
                          <div className="deliverable-description-shimmer emp-shimmer"></div>
                        </article>
                      );
                    })}
                </div>
              </div>
              <div className="comparison-column">
                <div className="column-header-wrapper">After</div>
                <div className="column-body-wrapper">
                  {deliverableComparison &&
                    deliverableComparison.latestVersionDeliverables.map(
                      (elem, index) => {
                        return (
                          <article className="deliverable-card">
                            <span key={index} className="deliverable-indicator">
                              DELIVERABLE {index + 1}
                            </span>
                            <h3 className="deliverable-name-lbl">
                              {elem.name}
                            </h3>
                            <div className="mt-1">
                              <EmpEditorView value={elem.description} />
                            </div>
                          </article>
                        );
                      }
                    )}
                  {!deliverableComparison &&
                    [0, 1].map((elem) => {
                      return (
                        <article className="deliverable-card-shimmer">
                          <div className="deliverable-number-shimmer emp-shimmer"></div>
                          <div className="deliverable-name-shimmer emp-shimmer"></div>
                          <div className="deliverable-description-shimmer emp-shimmer"></div>
                        </article>
                      );
                    })}
                </div>
              </div>
            </section>
          </section>
          {/* Button Wrapper Section */}
          {context === "seller" && (
            <section className="button-wrapper-section">
              <EmpButton
                ref={rejectBtnRef}
                isFullWidth={false}
                onSubmit={rejectTask}
                buttonStyle="danger"
                text={"Reject"}
              />

              <EmpButton
                buttonStyle="secondary"
                isFullWidth={false}
                onSubmit={() => {
                  setModalViewMode("counteroffer");
                }}
                text={"Negotiate"}
              />

              <EmpButton
                ref={acceptBtnRef}
                isFullWidth={false}
                onSubmit={acceptTask}
                buttonStyle="primary"
                text={"Accept"}
              />
            </section>
          )}
          {context === "brand" && (
            <section className="button-wrapper-section">
              <EmpButton
                buttonStyle="secondary"
                isFullWidth={false}
                onSubmit={onClose}
                text={"Cancel"}
              />
            </section>
          )}
        </>
      )}
      {/* ModalViewMode === "counteroffer" */}
      {modalViewMode === "counteroffer" && (
        <>
          <section className="counteroffer-section">
            <TaskDeliverableModal ref={taskDeliverableModalRef} />
            <section className="profile-photo-section">
              {context === "brand" && (
                <div className="profile-photo-wrapper">
                  <img
                    alt="Profile"
                    className="profile-photo org-photo"
                    src={latestNegotiation.brand.logo}
                  />
                  <span className="plus-label">+</span>
                  <div className="creator-rep-wrapper">
                    <div className="relative-wrapper">
                      <div className="creator-profile">
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "url" && (
                          <img
                            alt="Profile"
                            className="profile-photo"
                            src={
                              latestNegotiation.creatorRepresentative
                                .imageResource
                            }
                          />
                        )}
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "none" && (
                          <img
                            alt="Empty profile"
                            src={
                              "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                            }
                          />
                        )}
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "avatar" && (
                          <div
                            style={{
                              background:
                                latestNegotiation.creatorRepresentative
                                  .imageResource,
                            }}
                            className="avatar"
                          >
                            <span className="initials">
                              {latestNegotiation.creatorRepresentative.initials}
                            </span>
                          </div>
                        )}
                      </div>
                      {latestNegotiation.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            alt="Agency brand"
                            className="profile-photo org-photo"
                            src={latestNegotiation.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {context === "seller" && (
                <div className="profile-photo-wrapper">
                  <div className="creator-rep-wrapper">
                    <div className="relative-wrapper">
                      <div className="creator-profile">
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "url" && (
                          <img
                            alt="Profile"
                            className="profile-photo"
                            src={
                              latestNegotiation.creatorRepresentative
                                .imageResource
                            }
                          />
                        )}
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "none" && (
                          <img
                            alt="Empty profile"
                            src={
                              "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                            }
                          />
                        )}
                        {latestNegotiation.creatorRepresentative.imageType ===
                          "avatar" && (
                          <div
                            style={{
                              background:
                                latestNegotiation.creatorRepresentative
                                  .imageResource,
                            }}
                            className="avatar"
                          >
                            <span className="initials">
                              {latestNegotiation.creatorRepresentative.initials}
                            </span>
                          </div>
                        )}
                      </div>
                      {latestNegotiation.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            alt="Agency brand"
                            className="profile-photo org-photo"
                            src={latestNegotiation.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <span className="plus-label">+</span>
                  <img
                    alt="Profile"
                    className="profile-photo org-photo"
                    src={latestNegotiation.brand.logo}
                  />
                </div>
              )}
            </section>

            <div className="content-section">
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 1 * animationDelay,
                }}
                className="task-name-lbl"
              >
                {[
                  NEGOTIATION_STATUS.BRAND_ACCEPT,
                  NEGOTIATION_STATUS.SELLER_ACCEPT,
                ].includes(latestNegotiation.status) &&
                  context === "seller" &&
                  latestNegotiation.representativeRole === "creator" && (
                    <>Submit a counteroffer to join this task.</>
                  )}
                {[
                  NEGOTIATION_STATUS.BRAND_ACCEPT,
                  NEGOTIATION_STATUS.SELLER_ACCEPT,
                ].includes(latestNegotiation.status) &&
                  context === "seller" &&
                  latestNegotiation.representativeRole === "agency" && (
                    <>
                      Submit a counteroffer for{" "}
                      {latestNegotiation.creatorRepresentative.fullName} to join
                      this task.
                    </>
                  )}
                {[
                  NEGOTIATION_STATUS.BRAND_ACCEPT,
                  NEGOTIATION_STATUS.SELLER_ACCEPT,
                ].includes(latestNegotiation.status) &&
                  context === "brand" && (
                    <>
                      Submit a counteroffer for{" "}
                      {latestNegotiation.creatorRepresentative.fullName} to take
                      part in this task.
                    </>
                  )}
              </motion.span>

              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 2 * animationDelay,
                }}
                className="description mt-4"
              >
                You may refer to the{" "}
                <EmpLink
                  text={"deliverables"}
                  onSubmit={() => {
                    taskDeliverableModalRef.current?.show(task);
                  }}
                />{" "}
                to find out the scope of this task.
              </motion.p>

              <div>
                <hr className="emp-hr" />
                <motion.div
                  variants={fadeInVariants}
                  className="mt-4"
                  initial="hidden"
                  animate={"visible"}
                  transition={{
                    duration: animationDuration,
                    delay: 3 * animationDelay,
                  }}
                >
                  <EmpTextInput
                    id={"rate"}
                    labelText="Your Counteroffer"
                    leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
                    leftIconSize={12}
                    description={`If the ${
                      context === "brand" ? "creator / agency" : "brand"
                    } finds your offer agreeable, you'll be all set to join this campaign task.`}
                    required
                    formControl={form.amount}
                    placeholder={"00.00"}
                  />
                </motion.div>

                <motion.div
                  variants={fadeInVariants}
                  initial="hidden"
                  animate={"visible"}
                  className="mt-3"
                  transition={{
                    duration: animationDuration,
                    delay: 3 * animationDelay,
                  }}
                >
                  <EmpTextInput
                    id={"remarks"}
                    labelText="Remarks (Optional)"
                    multiline
                    textAreaAdaptiveHeight
                    tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                    formControl={form.remarks}
                    rows={2}
                    characterCount={300}
                    placeholder={
                      "You may elaborate more about the scope of work, or any price justifications"
                    }
                  />
                </motion.div>
              </div>
            </div>
          </section>

          <section className="button-wrapper-section">
            <EmpButton
              buttonStyle="secondary"
              onSubmit={() => {
                setModalViewMode("default");
              }}
              isFullWidth={false}
              className="mt-4"
              text={"Cancel"}
            />

            <EmpButton
              ref={counterofferBtnRef}
              onSubmit={sendCounteroffer}
              className="mt-4"
              isFullWidth={false}
              buttonStyle="primary"
              text={"Submit Counteroffer"}
            />
          </section>
        </>
      )}
    </section>
  );
};
