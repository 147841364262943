import React, { createContext, useMemo, useState } from "react";
import { UserDto } from "../model/user-management/user.dto";

interface AppContextType {
  user: UserDto | null;
  setUser: React.Dispatch<React.SetStateAction<UserDto | null>> | null;
}

interface EmpAppProviderProps {
  children: React.ReactElement;
}

export const AppContext = createContext<AppContextType>({
  user: null,
  setUser: null,
});

export const EmpAppProvider = (props: EmpAppProviderProps) => {
  const [user, setUser] = useState<UserDto | null>(null);
  const contextValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
};
