import { useState, useRef, useCallback, useEffect } from "react";
import useWalletAddressManager, {
  SavedWalletInformation,
  WalletInformation,
} from "../../../hooks/useWalletAddressManager";
import { SelectOption } from "../../../model/common/selectOption";
import { CreateWalletAddressDto } from "../../../model/user/wallet-address/create-wallet-address.dto";
import { FormGroupUtil } from "../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpSelect from "../../shared/emp-select/emp-select";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import { ManageWalletAnimation } from "./manage-wallet-animation";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { BlockchainNetworkDto } from "../../../model/user/wallet-address/blockchain-networks.dto";

interface Props {
  walletInformation: WalletInformation;
  onSave: (savedWalletInformation: SavedWalletInformation) => void;
  onDismiss: () => void;
}
export const ManageWalletFormView = (props: Props) => {
  const { walletInformation, onSave, onDismiss } = props;

  const [selectNetworkOptions, setSelectNetworkOptions] = useState<
    SelectOption[] | null
  >();
  const [fetchedNetworkOptions, setFetchedNetworkOptions] = useState<
    BlockchainNetworkDto[] | null
  >();
  const submitButtonRef = useRef<EmpButtonRef>();
  const [form, setForm] = useState({
    network: new FormControl("text", [
      new RequiredValidator("Please select a chain"),
    ]),
    walletAddress: new FormControl("text", [
      new RequiredValidator("Please input your wallet address"),
    ]),
  });
  const { fetchAvailableNetworks, upsertWalletAddress } =
    useWalletAddressManager();

  const initialiseForm = useCallback(
    async (walletInformation: WalletInformation) => {
      if (!selectNetworkOptions) {
        const networkOptionsResp = await fetchAvailableNetworks();
        if (networkOptionsResp) {
          setFetchedNetworkOptions(networkOptionsResp);
          setSelectNetworkOptions(
            networkOptionsResp.map((elem) => {
              return { label: elem.displayName, value: elem.id };
            })
          );
        }
      }
      if (walletInformation.hasWallet) {
        form.walletAddress.forceUpdateValue(
          walletInformation.wallet!.walletAddress
        );
        form.network.forceUpdateValue(
          walletInformation.wallet!.blockchainNetwork.id
        );
      }
    },
    [fetchAvailableNetworks, form, selectNetworkOptions]
  );

  useEffect(() => {
    initialiseForm(walletInformation);
  }, [walletInformation, initialiseForm]);

  /**
   * Submits the form to create or update a wallet address.
   *
   * This function validates the form, constructs the request payload, and sends it to the server
   * to create or update the wallet address. The function updates the button state to "loading" while the
   * request is in progress and resets it to "default" once the process completes.
   *
   * @async
   * @function submitForm
   * @returns {Promise<void>} Resolves when the form submission is complete.
   * @throws Will catch and log any errors that occur during the form submission process.
   */
  const submitForm = useCallback(async () => {
    try {
      submitButtonRef.current?.setButtonState("loading");
      const isFormValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isFormValid) return;

      const request: CreateWalletAddressDto = {
        blockchainNetworkId: form.network.getValue(),
        walletAddress: form.walletAddress.getValue(),
      };
      await upsertWalletAddress(request);

      // Prepare saved wallet information.
      const savedBlockchainNetwork = fetchedNetworkOptions!.find(
        (elem) => elem.id === request.blockchainNetworkId
      )!;

      const savedWalletInformation: SavedWalletInformation = {
        blockchainNetwork: savedBlockchainNetwork,
        walletAddress: request.walletAddress,
      };
      onSave(savedWalletInformation);
    } catch (e) {
      console.error(e);
    } finally {
      submitButtonRef.current?.setButtonState("default");
    }
  }, [form, upsertWalletAddress, onSave, fetchedNetworkOptions]);

  return (
    <div className="form-view">
      <section className="header-section">
        <h2>Bind your wallet to receive payouts</h2>
      </section>
      <section className="banner-section">
        <ManageWalletAnimation />
      </section>
      <section className="form-section">
        <p className="emp-paragraph">
          In order to receive payment once you have completed a campaign, you
          will need to connect your wallet.
        </p>
        {selectNetworkOptions && (
          <EmpSelect
            className="mt-3"
            labelText="Select Chain"
            required
            id={"chain"}
            formControl={form.network}
            placeholder="Select Chain"
            selectOptions={selectNetworkOptions}
          />
        )}
        <EmpTextInput
          id={"wallet-addr"}
          required
          labelText="Wallet Address"
          formControl={form.walletAddress}
          placeholder="0x000......0000A"
        />
      </section>
      <section className="footer-section">
        <EmpButton
          text={"Save"}
          buttonHeight="lg"
          isFullWidth={false}
          onSubmit={() => {
            submitForm();
          }}
          ref={submitButtonRef}
        />
        <EmpButton
          text={"Cancel"}
          buttonHeight="lg"
          isFullWidth={false}
          buttonStyle="secondary"
          onSubmit={onDismiss}
        />
      </section>
    </div>
  );
};
