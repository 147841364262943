import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserApi from "../../api/user-msvc/user.api";
import CheckIcon from "../../components/icon/check-icon";
import LogOut3Icon from "../../components/icon/log-out-3-icon";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import EmpLanguagePicker from "../../components/shared/emp-language-picker/emp-language-picker";
import { PermsUtils } from "../../constants/permissions.constants";
import { PlanDto } from "../../model/subscription/plan.dto";
import { Color } from "../../utilities/colors";
import "./brand-onboarding-page.scss";
import { BrandBasicInfoStep } from "./views/brand-basic-info-step";
import BrandPlanSelectionStep from "./views/brand-plan-selection-step";
import useEmpAuth from "../../hooks/useEmpAuth";
import useUser from "../../hooks/useUser";
import { PLATFORM_ROLES } from "../../constants/app.constants";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import EmpButton from "../../components/shared/emp-button/emp-button";

export type BrandOnboardingViewType = "basic-info" | "plan-selection";

type FromOriginType = "external-role-selection";

function isFromOriginType(value: string): value is FromOriginType {
  return value === "external-role-selection";
}

export const BrandOnboardingPage = () => {
  const [searchParams] = useSearchParams();
  const [fromOrigin, setFromOrigin] = useState<FromOriginType | null>(null);

  useEffect(() => {
    const paramValue = searchParams.get("from");
    if (paramValue) {
      if (isFromOriginType(paramValue)) {
        setFromOrigin(paramValue);
      }
    }
  }, [searchParams]);

  const navigate = useNavigate();
  const { signOut } = useEmpAuth();
  const { user } = useUser();

  const [stepperStatus, setStepperStatus] = useState({
    "basic-info": "in-progress",
    "plan-selection": "pending",
    "subscription-payment": "pending",
  });
  const [currentStep, setCurrentStep] =
    useState<BrandOnboardingViewType>("basic-info");
  const selectedPlanRef = useRef<PlanDto>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (!user) return;
    const roleClassification = PermsUtils.getUserRole(user);
    if (roleClassification === PLATFORM_ROLES.AGENCY) {
      navigate("/agency/home");
    } else if (roleClassification === PLATFORM_ROLES.CREATOR) {
      navigate("/creator/home");
    }
  }, [user, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Payment : Receive callback from URL, and redirect to the correct hash.
    const paymentCallback = urlParams.get("emp-callback");
    if (paymentCallback === "onboard-plan-selection") {
      setStepperStatus((prev) => {
        prev["basic-info"] = "done";
        prev["plan-selection"] = "in-progress";
        return { ...prev };
      });
      setCurrentStep("plan-selection");
      window.location.hash = "plan-selection";
    }
  }, []);

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    const fragment = window.location.hash;
    if (fragment !== "") {
      if (fragment === "#basic-info") {
        setCurrentStep("basic-info");
      } else if (fragment === "#plan-selection") {
        setCurrentStep("plan-selection");
      }
    } else {
      setCurrentStep("basic-info");
    }
  }, []);

  const advanceStep = (origin: string, intent: "done" | "skip") => {
    if (origin === "basic-info") {
      stepperStatus["basic-info"] = intent;
      stepperStatus["plan-selection"] = "in-progress";
      setCurrentStep("plan-selection");
      window.location.hash = "plan-selection";
    }
    setStepperStatus({ ...stepperStatus });
  };

  const skipStep = (origin: "basic-info" | "plan-selection") => {
    if (
      origin === "plan-selection" &&
      stepperStatus["basic-info"] === "in-progress"
    )
      return;
    setCurrentStep(origin);
    stepperStatus[origin] = "in-progress";
    window.location.hash = origin;
    setStepperStatus({ ...stepperStatus });
  };

  return (
    <div className="emp-brand-onboarding-page">
      <div className="overall-content-wrapper">
        {fromOrigin === "external-role-selection" && (
          <EmpButton
            isFullWidth={false}
            onSubmit={() => {
              navigate("/external/role-selection");
            }}
            text={"Back to role selection"}
            leftIcon={ChevronLeftIcon}
            buttonStyle="secondary"
            buttonHeight="sm"
            className="mb-2"
          />
        )}
        <div className="content-wrapper">
          <div className="progress-section">
            <div className="stepper-section">
              <div className="logo-wrapper">
                <img
                  className="logo"
                  alt="creatorfi logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
                <div className="control-wrapper">
                  <EmpIconButton
                    onSubmit={() => {
                      signOut();
                    }}
                    buttonStyle="secondary"
                    icon={
                      <LogOut3Icon
                        size={17}
                        backgroundColor={Color.NEUTRAL[500]}
                      />
                    }
                  />
                </div>
              </div>
              <span className="title-lbl mt-3">Onboarding Process</span>
              <div className="progress-wrapper mt-3">
                <div className="step completed"></div>
                <div
                  className={`step ${
                    ["plan-selection", "subscription-payment"].includes(
                      currentStep
                    )
                      ? "completed"
                      : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="step-item-section">
              <div
                className="step"
                onClick={() => {
                  skipStep("basic-info");
                }}
              >
                {stepperStatus["basic-info"] === "in-progress" && (
                  <div className="in-progress"></div>
                )}
                {stepperStatus["basic-info"] === "pending" && (
                  <div className="unchecked">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[400]} />
                  </div>
                )}
                {stepperStatus["basic-info"] === "done" && (
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                {stepperStatus["basic-info"] === "skip" && (
                  <div className="skipped">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                <span className="step-label">Personal Details</span>
              </div>
              <div
                className="step"
                onClick={() => {
                  skipStep("plan-selection");
                }}
              >
                {stepperStatus["plan-selection"] === "in-progress" && (
                  <div className="in-progress"></div>
                )}
                {stepperStatus["plan-selection"] === "pending" && (
                  <div className="unchecked">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[400]} />
                  </div>
                )}
                {stepperStatus["plan-selection"] === "done" && (
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                {stepperStatus["plan-selection"] === "skip" && (
                  <div className="skipped">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                )}
                <span className="step-label">Select Subscription Plan</span>
              </div>
            </div>
          </div>
          {currentStep === "basic-info" && (
            <BrandBasicInfoStep
              nextStep={(intent) => {
                advanceStep("basic-info", intent);
              }}
            />
          )}
          {currentStep === "plan-selection" && (
            <BrandPlanSelectionStep
              nextStep={(intent, plan) => {
                advanceStep("plan-selection", intent);
                selectedPlanRef.current = plan;
              }}
              prevStep={() => {
                setCurrentStep("basic-info");
                stepperStatus["basic-info"] = "in-progress";
                setStepperStatus({ ...stepperStatus });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
