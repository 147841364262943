import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NOTIFICATION_RECORD } from "../../../constants/app.constants";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";
import "./task-chat-event-notification.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onView: () => void;
  onRefresh: () => void;
}

export const TaskChatEventNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = props;

  const userRoleRef = useRef<string>("");

  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  const viewOnClick = async () => {
    navigate(
      `/${userRoleRef.current}/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}#Chat`
    );
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  const creatorOnClick = () => {
    navigate(
      `/${userRoleRef.current}/creator-details/${notification.metadata.creatorUserId}`
    );
  };

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  return (
    <div
      className="emp-notification-record emp-task-chat-event-notification"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* ============================ NOTIFICATION DISPLAY IMG ================================== */}
      {/* ----------------------- Notification Type : Task Application --------------------------- */}
      <div className="organisation-img-wrapper">
        {/* Mini-Badge Image for creator if representative is Agency */}

        {userRoleRef.current === "brand" && (
          <>
            {notification.metadata.representativeRole === "agency" && (
              <img
                alt={notification.metadata.agencyRepresentative!.companyName}
                className="badge-img small-profile-badge"
                src={notification.metadata.agencyRepresentative!.logo}
              />
            )}
            <>
              {notification.metadata.creatorRepresentative.imageType ===
                "url" && (
                <img
                  alt={notification.metadata.creatorRepresentative.fullName}
                  className="user-img"
                  src={
                    notification.metadata.creatorRepresentative.imageResource
                  }
                />
              )}
              {notification.metadata.creatorRepresentative.imageType ===
                "avatar" && (
                <div
                  style={{
                    background:
                      notification.metadata.creatorRepresentative.imageResource,
                  }}
                  className="user-avatar"
                >
                  <span className="initials">
                    {notification.metadata.creatorRepresentative.initials}
                  </span>
                </div>
              )}
            </>
          </>
        )}
        {userRoleRef.current !== "brand" && (
          <img
            alt={notification.metadata.brand.companyName}
            className="organisation-img"
            src={notification.metadata.brand.logo}
          />
        )}
      </div>

      <div className="notification-content">
        <div className="content-wrapper">
          {/* Notification Record Type = task-start */}

          {userRoleRef.current === "agency" && (
            <span className="info-text">
              <span className="emp-highlighted">
                {notification.sender.senderName}
              </span>{" "}
              has sent you {notification.metadata.unreadCount} message
              {notification.metadata.unreadCount > 1 ? "s" : ""}
            </span>
          )}
          {userRoleRef.current === "creator" && (
            <span className="info-text">
              <span className="emp-highlighted">
                {notification.sender.senderName}
              </span>{" "}
              has sent you {notification.metadata.unreadCount} message
              {notification.metadata.unreadCount > 1 ? "s" : ""}
            </span>
          )}
          {userRoleRef.current === "brand" && (
            <span className="info-text">
              <span className="emp-highlighted">
                {notification.sender.senderName}
              </span>{" "}
              has sent you {notification.metadata.unreadCount} message
              {notification.metadata.unreadCount > 1 ? "s" : ""}
            </span>
          )}

          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        {/* <div className="chat-bubble mt-1 mb-2">
                <p className="chat-content">{notification.metadata.latestMessage}</p>
            </div> */}
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(
            notification.updatedAt.toString()
          )}`}</span>

          <div className="button-wrapper">
            {notification.metadata.state === "valid" && (
              <>
                <EmpLink text={"View"} onSubmit={viewOnClick} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
