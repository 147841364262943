import { motion } from "framer-motion";
import { forwardRef, useImperativeHandle, useState } from "react";
import { empDelay } from "../../../utilities/delay";

interface Props {
  background?: "default" | "transparent";
}

const loaderVariant = {
  hidden: { opacity: 0, y: 0 },
  visible: { opacity: 1, y: 0 },
};

const overlayVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export interface EmpLoaderRef {
  show: () => void;
  hide: () => void;
}

const EmpLoader = forwardRef((props: Props, ref) => {
  const background = props.background ?? "transparent";
  const [isLoading, setIsLoading] = useState(false);
  const [variant, setVariant] = useState<"hidden" | "visible">("hidden");

  useImperativeHandle(ref, () => {
    return {
      show,
      hide,
    };
  });

  const show = () => {
    setIsLoading(true);
    setVariant("visible");
  };

  const hide = async () => {
    setVariant("hidden");
    await empDelay(600);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <motion.div
          initial={variant}
          variants={overlayVariant}
          animate={variant}
          transition={{ duration: 0.5 }}
          className={`emp-loader-overlay ${background}`}
        >
          <motion.div
            initial={variant}
            variants={loaderVariant}
            animate={variant}
            transition={{ duration: 0.5 }}
            className="emp-loader"
          >
            <span className="label">Loading...</span>
            <div className="emp-progress mt-2">
              <div className="indeterminate"></div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
});
export default EmpLoader;
