import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { PUB_SUB_TOPICS } from "../../../../constants/pubSubTopics";
import { empDelay } from "../../../../utilities/delay";
import "./global-notifications-manager.scss";
import { v4 } from "uuid";
import { EmpGlobalNotification } from "../../../../model/common/global-notification";

interface GlobalNotificationExtended extends EmpGlobalNotification {
  id: string;
  state: "foreground" | "background";
}

export const GlobalNotificationManager = () => {
  const [notifications, setNotifications] = useState<
    GlobalNotificationExtended[]
  >([]);

  useEffect(() => {
    const globalNotificationCallback = (
      _: string,
      data: EmpGlobalNotification
    ) => {
      setNotifications((prev) => {
        prev.forEach((elem) => (elem.state = "background"));
        prev.push({
          ...data,
          id: v4(),
          state: "foreground",
        });
        return [...prev];
      });
    };
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.GLOBAL_NOTIFICATION,
      globalNotificationCallback
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, []);

  return (
    <div className="global-notifications-wrapper">
      <AnimatePresence>
        {notifications.map((elem) => {
          return (
            <GlobalNotification
              onDismiss={(id: string) => {
                setNotifications((prev) => {
                  const dismissedNotiIdx = prev.findIndex(
                    (elem) => elem.id === id
                  );
                  if (dismissedNotiIdx >= 0) {
                    return prev.filter((_, idx) => idx !== dismissedNotiIdx);
                  }
                  return prev;
                });
              }}
              data={elem}
              key={elem.id}
            />
          );
        })}
      </AnimatePresence>
    </div>
  );
};

interface GlobalNotificationProp {
  data: GlobalNotificationExtended;
  onDismiss: (id: string) => void;
}
const notificationVariant = {
  initial: { y: -100, scale: 1, x: "-50%" },
  foreground: { y: 20, scale: 1, x: "-50%" },
  background: { y: 10, scale: 0.8, x: "-50%" },
};
const GlobalNotification = (props: GlobalNotificationProp) => {
  const { data, onDismiss } = props;

  const dismissNotification = useCallback(async () => {
    await empDelay(6000);
    onDismiss(data.id);
  }, [data.id, onDismiss]);

  useEffect(() => {
    dismissNotification();
  }, [dismissNotification]);

  return (
    <motion.div
      onClick={() => {
        onDismiss(data.id);
      }}
      variants={notificationVariant}
      initial="initial"
      animate={props.data.state}
      exit="initial"
      className={`global-notification ${props.data.state}`}
    >
      <div>
        {props.data.sender.senderRole === "creator" && (
          <>
            {props.data.sender.imageType === "url" && (
              <img
                className={"profile-pic"}
                alt={props.data.sender.senderName}
                src={props.data.sender.imageResource}
              />
            )}
            {props.data.sender.imageType === "avatar" && (
              <div
                className="profile-pic"
                style={{ background: props.data.sender.imageResource }}
              >
                <span className="initials">{props.data.sender.initials}</span>
              </div>
            )}
          </>
        )}
        {props.data.sender.senderRole !== "creator" && (
          <img
            alt={props.data.sender.senderName}
            className={"profile-pic"}
            src={props.data.sender.imageResource}
          />
        )}
      </div>
      <div className="notification-content-wrapper">
        <span className="notification-title-lbl">{data.title}</span>
        <span className="notification-message-lbl">{data.message}</span>
      </div>
    </motion.div>
  );
};
