import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import XCloseIcon from "../../components/icon/x-close-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import { CancellationInfoDto } from "../../model/payment/payment/cancellation-info.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../shared/emp-checkbox/emp-checkbox";
import EmpContent from "../shared/emp-content/emp-content";
import "./subscription-cancellation-modal.scss";

export interface SubscriptionCancellationModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

export const SubscriptionCancellationModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [cancellationInfo, setCancellationInfo] =
    useState<CancellationInfoDto>();
  const [ackIsChecked, setAckIsChecked] = useState(false);
  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async () => {
    setVisible(true);
  };

  const dismiss = () => {
    ackCheckboxRef.current?.setChecked(false);
    setAckIsChecked(false);
    setVisible(false);
    props.onSave();
  };

  /**
   * cancel subscription
   * @async
   * @returns {Promise<void>} A Promise that resolves when the save operation is completed.
   * @throws {EmpException} If unable to save the name.
   */
  const cancelSubscription = async () => {
    try {
      // Run Cancellation Logic.
      dismiss();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to cancel subscription"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-subscription-cancellation-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <h2 className="title">We're sorry to see you go</h2>
        </div>
        <section className="cancellation-content-section">
          <div className="cancellation-card mt-4">
            <h2>Subscription Cancellation</h2>

            <div className="info-wrapper mt-3">
              <EmpContent label={"Plan"} value={"Blub Plan Name"} />
              <EmpContent label={"Expiration Date"} value={"1 Jan 2025"} />
            </div>
          </div>

          <div
            className="acknowledgement-wrapper mt-4"
            onClick={() => {
              ackCheckboxRef.current?.setChecked(!ackIsChecked);
              setAckIsChecked(!ackIsChecked);
            }}
          >
            <div className="checkbox-wrapper">
              <EmpCheckbox
                disableControl
                ref={ackCheckboxRef}
                id={"acknowledgement"}
              />
            </div>
            <p
              className={`emp-paragraph ${ackIsChecked ? "selected-text" : ""}`}
            >
              I confirm that I will be terminating this subscription. And have
              acknowledged that I will lose access to the platform on the date.
            </p>
          </div>
        </section>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            disabled={!ackIsChecked}
            ref={saveBtnRef}
            isFullWidth={false}
            buttonStyle="danger"
            text={"Cancel Subscription"}
            onSubmit={() => {
              cancelSubscription();
            }}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
