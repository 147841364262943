import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import SocialMediaApi from "../../../api/social-integration-msvc/social-media.api";
import TikTokApi from "../../../api/social-integration-msvc/tiktok.api";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import { TikTokLoader } from "../../../components/loaders/tiktok-loader";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import { FacebookPageDto } from "../../../model/social-media/facebook-page.dto";
import { InstagramConnectedAccountDto } from "../../../model/social-media/instagram-connected-account.dto";
import { SmBriefRespDto } from "../../../model/social-media/sm-brief-resp.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import FacebookPageSelectionModal, {
  FacebookPageSelectionModalRef,
} from "../../creator-home-page/dashboard-cards/facebook-page-selection-modal";
import InstagramAccountSelectionModal, {
  InstagramAccountSelectionModalRef,
} from "../../creator-home-page/dashboard-cards/instagram-account-selection-modal";
import InstagramConnectInfoModal, {
  InstagramConnectInfoModalRef,
} from "../../creator-home-page/dashboard-cards/instagram-connect-info-modal";
import { UnlinkBtn } from "../../creator-home-page/dashboard-cards/unlink-btn";
import FacebookUtils from "../../creator-home-page/facebook";
import "./social-media-step.scss";
import {
  SM_REDIRECT,
  SOCIAL_MEDIA_TYPE,
} from "../../../constants/app.constants";
import { useXLogin } from "../../creator-home-page/dashboard-cards/hooks/useXLogin";
import XIcon from "../../../components/icon/x-icon";
import { XLoader } from "../../../components/loaders/x-loader";
import { empDelay } from "../../../utilities/delay";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;

interface StepProps {
  nextStep: (intent: "done" | "skip") => void;
  prevStep: () => void;
}

interface FacebookLoginAttributes {
  id: string;
  access_token: string;
}

export const SocialMediaStep = (props: StepProps) => {
  const facebookUtil = new FacebookUtils();
  const facebookPageSelectionModalRef = useRef<FacebookPageSelectionModalRef>();
  const instagramAccountSelectionModalRef =
    useRef<InstagramAccountSelectionModalRef>();
  const instagramConnectInfoModalRef = useRef<InstagramConnectInfoModalRef>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { xLogin, bindXAccount } = useXLogin();

  const [socialMediaSpecs, setSocialMediaSpecs] = useState<SmBriefRespDto[]>(
    []
  );

  const [anyPlatformConnected, setAnyPlatformConnected] =
    useState<boolean>(false);
  const [smIsLoading, setSmIsLoading] = useState<"tiktok" | "none" | "x">(
    "none"
  );

  const isMobile = useEmpBreakpoint(500);

  // TikTok Detection
  const bindTiktokAccount = useCallback(async (tiktokCode: string) => {
    try {
      setSmIsLoading("tiktok");
      const response = await TikTokApi.getToken(tiktokCode);
      if (response.data.status === "success") {
        setSmIsLoading("none");
        window.history.replaceState("", "", "/creator/onboard#social-media");
        ToastUtils.success(
          "Account Binded",
          "You have binded your TikTok account"
        );

        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to TikTok account"
      );
    } finally {
      setSmIsLoading("none");
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    if (tikTokCode) {
      bindTiktokAccount(tikTokCode);
    }

    const xCode = urlParams.get("x_code");
    if (xCode) {
      setSmIsLoading("x");
      bindXAccount(xCode, setSmIsLoading, getUserSocialMediaMetrics);
    }
  }, [bindTiktokAccount, bindXAccount]);

  //facebook test
  useEffect(() => {
    getUserSocialMediaMetrics();
  }, []);

  const getUserSocialMediaMetrics = useCallback(async () => {
    try {
      const resp = await SocialMediaApi.getSocialMediaSpecs();
      setSocialMediaSpecs(resp.data);
      setAnyPlatformConnected(checkIfAnyPlatformsConnected(resp.data));
      setIsLoaded(true);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  }, []);

  const bindPlatform = (platform: string) => {
    if (platform === "Facebook") facebookLogin();
    else if (platform === "Instagram")
      instagramConnectInfoModalRef.current?.show();
    else if (platform === "TikTok") tiktokLogin();
    else if (platform === SOCIAL_MEDIA_TYPE.X) {
      xLogin(SM_REDIRECT.CREATOR_ONBOARD);
    }
  };

  const checkIfAnyPlatformsConnected = (
    socialMediaBriefs: SmBriefRespDto[]
  ) => {
    for (let socialMediaBrief of socialMediaBriefs) {
      if (socialMediaBrief.isAvailable) return true;
    }
    return false;
  };

  const facebookLogin = async () => {
    try {
      const response = await facebookUtil.login();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookPageResp = (await facebookUtil.getFacebookPages(
          userId
        )) as FacebookPageDto;

        if (facebookPageResp.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Your account does not have a Facebook Page"
          );
          return;
        }
        if (facebookPageResp.data.length > 1) {
          facebookPageSelectionModalRef.current?.show(
            facebookPageResp,
            "Facebook"
          );
          return;
        }
        saveFacebookLogin(facebookPageResp.data[0], "Facebook");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const instagramLogin = async () => {
    try {
      const response = await facebookUtil.instagramLogin();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookInstagramConnectedPage =
          (await facebookUtil.getInstagramConnectedAccount(
            userId
          )) as InstagramConnectedAccountDto;
        if (facebookInstagramConnectedPage.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Instagram needs to bind with a Facebook Page"
          );
          return;
        } //
        if (facebookInstagramConnectedPage.data.length > 0) {
          instagramAccountSelectionModalRef.current?.show(
            facebookInstagramConnectedPage,
            "Instagram"
          );
          return;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const tiktokLogin = async () => {
    try {
      localStorage.setItem(
        "branch-blind-social",
        JSON.stringify(SM_REDIRECT.CREATOR_ONBOARD)
      );
      const resp = await TikTokApi.auth();
      window.location.href = resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to TikTok"
      );
    }
  };

  const saveFacebookLogin = async (
    fbPageSpec: FacebookLoginAttributes,
    platform: string
  ) => {
    try {
      const resp = await SocialMediaApi.smLogin({
        socialMediaPlatform: platform,
        accessToken: fbPageSpec.access_token,
        socialMediaUserId: fbPageSpec.id,
      });
      if (resp.data.status === "success") {
        ToastUtils.success("Success", "Succesfully binded your FB account");
        if (platform === "Facebook") {
          facebookPageSelectionModalRef.current?.dismiss();
        } else if (platform === "Instagram") {
          instagramAccountSelectionModalRef.current?.dismiss();
        }
        facebookLogout();
        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to bind Facebook Page"
      );
    }
  };

  const facebookLogout = async () => {
    await facebookUtil.logout();
  };

  const onUnlink = async (platform: string) => {
    try {
      const resp = await SocialMediaApi.unlinkSocialMedia(platform);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Successfully Unlinked",
          `Your ${platform} account has been unlinked`
        );
        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to unlink ${platform}`
      );
    }
  };

  return (
    <div className="emp-creator-social-media-step">
      {smIsLoading === "tiktok" && <TikTokLoader />}
      {smIsLoading === "x" && <XLoader />}
      <FacebookPageSelectionModal
        ref={facebookPageSelectionModalRef}
        onSave={saveFacebookLogin}
        onDismiss={facebookLogout}
      />
      <InstagramAccountSelectionModal
        ref={instagramAccountSelectionModalRef}
        onSave={saveFacebookLogin}
        onDismiss={facebookLogout}
      />

      <InstagramConnectInfoModal
        ref={instagramConnectInfoModalRef}
        onSave={() => {
          instagramLogin();
        }}
      />
      <div className="form-wrapper">
        <div className="header-section">
          <EmpIconButton
            buttonStyle="secondary"
            icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[100]} />}
            onSubmit={() => {
              props.prevStep();
            }}
          />
          <span className="step-lbl">
            <FormattedMessage id="bindSocialMediaStep_header" />
          </span>
        </div>
        <div className="body-section">
          <p className="intro-para">
            <FormattedMessage id="bindSocialMediaStep_desc" />
          </p>
          <motion.div
            className="image-upload-group mt-4"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 1 * animationDelay }}
          >
            {socialMediaSpecs.map((elem, index) => {
              return (
                <div key={elem.platform} className="platform-card mt-2">
                  {/* Empty State */}
                  {!elem.isAvailable && (
                    <div className="platform-logo">
                      {elem.platform === "Facebook" && (
                        <FacebookIcon
                          size={28}
                          backgroundColor={Color.NEUTRAL[300]}
                        />
                      )}
                      {elem.platform === "Instagram" && (
                        <InstagramIcon
                          size={28}
                          backgroundColor={Color.NEUTRAL[300]}
                        />
                      )}
                      {elem.platform === "TikTok" && (
                        <TikTokIcon
                          size={28}
                          backgroundColor={Color.NEUTRAL[300]}
                        />
                      )}
                      {elem.platform === "X" && (
                        <XIcon size={28} backgroundColor={Color.NEUTRAL[300]} />
                      )}
                    </div>
                  )}
                  {!elem.isAvailable && (
                    <div className="content">
                      <span className="status-lbl">
                        <FormattedMessage id="bindSocialMediaStep_notConnectedLabel" />
                      </span>
                      <span className="action-lbl">
                        <FormattedMessage
                          id="bindSocialMediaStep_platformCta"
                          values={{
                            cta: (
                              <EmpLink
                                onSubmit={() => {
                                  bindPlatform(elem.platform);
                                }}
                                text={<FormattedMessage id="cta_here" />}
                              />
                            ),
                            platform: elem.platform,
                          }}
                        />{" "}
                      </span>
                    </div>
                  )}

                  {/* Filled State */}
                  {elem.isAvailable && (
                    <>
                      <div className="left-elem">
                        <div className="social-media-img-wrapper">
                          <div className="social-media-logo-placeholder">
                            {elem.platform === "Facebook" && (
                              <FacebookIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "Instagram" && (
                              <InstagramIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "TikTok" && (
                              <TikTokIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "X" && (
                              <XIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                          </div>
                          <img
                            alt={`${elem.name} profile`}
                            className="social-media-image"
                            src={elem.pictureUrl}
                          />
                        </div>
                        <div className="content">
                          <span className="status-lbl">{elem.name}</span>
                          <span className="action-lbl">
                            <FormattedMessage id="bindSocialMediaStep_socialMediaFollowers" />
                            : {elem.followers}
                          </span>
                        </div>
                      </div>
                      <div className="right-elem">
                        <UnlinkBtn
                          onSubmit={() => {
                            onUnlink(elem.platform);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </motion.div>
          <div className="btn-section">
            <motion.div
              className="mt-10 btn-wrapper"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 5 * animationDelay }}
            >
              <EmpButton
                buttonHeight={isMobile ? "lg" : "sm"}
                text={
                  anyPlatformConnected ? (
                    <FormattedMessage id="cta_nextStep" />
                  ) : (
                    <FormattedMessage id="cta_skip" />
                  )
                }
                onSubmit={() => {
                  const intent = anyPlatformConnected ? "done" : "skip";
                  props.nextStep(intent);
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
