import { useEffect, useRef, useState } from "react";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import SearchIcon from "../../icon/search-icon";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import SearchModal, { SearchModalRef } from "./emp-search-modal";
import "./emp-search-bar.scss";
import EmpButton from "../../shared/emp-button/emp-button";

export const SearchBar = () => {
  const searchModalRef = useRef<SearchModalRef>();

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
  });

  const searchBarOnFocus = async () => {
    try {
      searchModalRef.current?.show();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to saerch for campaign data"
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!searchModalRef.current) return;

      const modalIsVisible = searchModalRef.current.visible;
      if ((event.ctrlKey || event.metaKey) && event.key === "f") {
        if (!modalIsVisible) {
          event.preventDefault();
          searchModalRef.current?.show(true);
        } else {
          searchModalRef.current?.dismiss();
        }
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="emp-search-bar-wrapper ml-5">
      <SearchModal ref={searchModalRef} />

      <div className="emp-search-bar">
        <EmpTextInput
          id={"search"}
          formControl={form.search}
          onFocus={searchBarOnFocus}
          placeholder={"Search Creators, Campaigns and Tasks..."}
          leftIconComponent={SearchIcon}
        />
      </div>
      <div className="emp-search-button">
        <EmpButton
          onSubmit={() => {
            searchModalRef.current?.show();
          }}
          text={"Search"}
          leftIcon={SearchIcon}
          isFullWidth={false}
          buttonStyle="secondary"
        />
      </div>
    </div>
  );
};
