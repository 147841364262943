import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { ApiStatusWithPayload } from "../../model/api/api-status-with-payload";
import { CampaignWOrgDto } from "../../model/campaign/campaign-w-org.dto";
import { CreateCampaignDto } from "../../model/campaign/create-campaign.dto";
import { CreateTaskDto } from "../../model/campaign/create-task.dto";
import { DeleteCampaignRecommendationDto } from "../../model/campaign/delete-campaign-recommendation.dto";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import { QueryCampaignDto } from "../../model/campaign/query-campaign.dto";
import { QueryTaskCardDto } from "../../model/campaign/query-task-card.dto";
import { TaskCardDto } from "../../model/campaign/task-card.dto";
import { TaskPaymentDetailsDto } from "../../model/campaign/task-payment-details.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import { UpdateTaskWNegotiationDto } from "../../model/campaign/update-task-w-negotiation.dto";
import { UpdateTaskDto } from "../../model/campaign/update-task.dto";
import { Paginatable } from "../../model/common/paginatable";
import { EmpInterceptor } from "../emp-interceptor";

const CampaignApi = {
  createCampaign: async (
    request: CreateCampaignDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.campaign.base,
      request
    );
    return response.data;
  },
  fetchCampaigns: async (): Promise<ApiResponse<CampaignWOrgDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CampaignWOrgDto[]>>(
      ENDPOINTS.campaignMsvc.campaign.base
    );
    return response.data;
  },
  queryTaskInBrand: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.queryTaskInBrand,
      request
    );
    return response.data;
  },
  queryCampaigns: async (
    request: QueryCampaignDto
  ): Promise<ApiResponse<Paginatable<CampaignWOrgDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<
      ApiResponse<Paginatable<CampaignWOrgDto[]>>
    >(ENDPOINTS.campaignMsvc.campaign.queryCampaign, request);
    return response.data;
  },
  fetchCampaignById: async (
    campaignId: string
  ): Promise<ApiResponse<CampaignWOrgDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CampaignWOrgDto>>(
      ENDPOINTS.campaignMsvc.campaign.fetchById(campaignId)
    );
    return response.data;
  },
  createTask: async (
    campaignId: string,
    request: CreateTaskDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.campaign.createTask(campaignId),
      request
    );
    return response.data;
  },
  fetchTasksInCampaign: async (
    campaignId: string,
    role: "seller" | "brand"
  ): Promise<ApiResponse<TaskCardDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<TaskCardDto[]>>(
      ENDPOINTS.campaignMsvc.campaign.fetchTasksInCampaign(campaignId, role)
    );
    return response.data;
  },
  queryTasks: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.queryTask,
      request
    );
    return response.data;
  },
  querySellerEngagementTasks: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.querySellerEngagementTasks,
      request
    );
    return response.data;
  },

  querySellerCompletedTasks: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.querySellerCompletedTasks,
      request
    );
    return response.data;
  },

  queryBrandCompletedTasks: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.queryBrandCompletedTasks,
      request
    );
    return response.data;
  },
  queryCreatorExclusiveEngagementTasks: async (
    request?: QueryTaskCardDto
  ): Promise<ApiResponse<Paginatable<TaskCardDto[]>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<Paginatable<TaskCardDto[]>>>(
      ENDPOINTS.campaignMsvc.campaign.queryCreatorExclusiveEngagementTasks,
      request
    );
    return response.data;
  },
  fetchTaskById: async (
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<TaskDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<TaskDto>>(
      ENDPOINTS.campaignMsvc.campaign.fetchOneTask(campaignId, taskId)
    );
    return response.data;
  },
  updateTask: async (
    campaignId: string,
    taskId: string,
    request: UpdateTaskDto
  ): Promise<
    ApiResponse<ApiStatusWithPayload<LatestNegotiationExtendedDto[] | null>>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.campaign.updateTask(campaignId, taskId),
      request
    );
    return response.data;
  },

  updateTaskWithDeliverableUpdate: async (
    campaignId: string,
    taskId: string,
    request: UpdateTaskWNegotiationDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.campaign.updateTaskWithDeliverableUpdate(
        campaignId,
        taskId
      ),
      request
    );
    return response.data;
  },
  fetchTaskPaymentDetails: async (
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<TaskPaymentDetailsDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.campaign.fetchTaskPaymentDetails(
      campaignId,
      taskId
    )}`;
    const response = await axios.get<ApiResponse<TaskPaymentDetailsDto>>(url);
    return response.data;
  },
  tryStartTask: async (
    campaignId: string,
    taskId: string,
    transactionHash: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.campaign.startTask(
      campaignId,
      taskId
    )}`;
    const response = await axios.post<ApiResponse<ApiStatus>>(url, {
      transactionHash,
    });
    return response.data;
  },
  deleteTask: async (
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.campaign.deleteTask(
      campaignId,
      taskId
    )}`;
    const response = await axios.delete<ApiResponse<ApiStatus>>(url);
    return response.data;
  },

  deleteCampaign: async (
    campaignId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.campaign.deleteCampaign(campaignId)}`;
    const response = await axios.delete<ApiResponse<ApiStatus>>(url);
    return response.data;
  },

  fetchCampaignDeletionRecommendation: async (
    campaignId: string
  ): Promise<ApiResponse<DeleteCampaignRecommendationDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.campaign.fetchCampaignDeletionRecommendation(
      campaignId
    )}`;
    const response = await axios.get<
      ApiResponse<DeleteCampaignRecommendationDto>
    >(url);
    return response.data;
  },
}; /*  */
export default CampaignApi;
