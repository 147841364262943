import { BaseValidator, IValidator } from "./baseValidation";
import { IFormControl } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";

export class RequiredValidator extends BaseValidator implements IValidator {
  customErrorMessage: string = VALIDATION_MESSAGE.IS_REQUIRED;

  /**
   * @purpose Override error message if necessary
   * @param errorMessage Custom error message that
   * overrides default Required Validator error message
   */
  constructor(errorMessage?: string) {
    super();
    if (errorMessage) this.customErrorMessage = errorMessage;
  }

  validate(formControl: IFormControl): boolean {
    if (formControl.formType === "text") {
      return this.validateText(formControl);
    } else if (formControl.formType === "checkbox-group") {
      return this.validateCheckboxGroup(formControl);
    } else if (formControl.formType === "date-range") {
      return this.validateDateRange(formControl);
    } else if (formControl.formType === "rich-text") {
      return this.validateRichText(formControl);
    } else if (formControl.formType === "date") {
      return this.validateDate(formControl);
    }
    throw new Error(
      "The requiredValidator is unable to validate this form type"
    );
  }
  countRichTextCharacters = (value: any) => {
    let totalCharacters = 0;
    function traverse(currentNode: any) {
      if (Array.isArray(currentNode)) {
        currentNode.forEach((childNode) => traverse(childNode));
      } else if (currentNode && typeof currentNode === "object") {
        if (
          currentNode.hasOwnProperty("text") &&
          typeof currentNode.text === "string"
        ) {
          totalCharacters += currentNode.text.length;
        }
        if (currentNode.hasOwnProperty("children")) {
          traverse(currentNode.children);
        }
      }
    }
    traverse(value);
    return totalCharacters;
  };

  validateDate(formControl: IFormControl): boolean {
    const value = formControl.value;
    if (value === undefined || value === null || value.length === 0) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }

  validateRichText(formControl: IFormControl): boolean {
    const value = formControl.value;
    const charCount = this.countRichTextCharacters(value);

    if (charCount <= 0) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }

  validateCheckboxGroup(formControl: IFormControl): boolean {
    const evaluable = formControl.value as string[];

    if (evaluable.length <= 0) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }

  validateDateRange(formControl: IFormControl): boolean {
    const evaluable = formControl.value as { startDate: Date; endDate: Date };
    if (!evaluable.startDate || !evaluable.endDate) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }

  validateText(formControl: IFormControl): boolean {
    const value = formControl.value;
    const evaluable = value ? value.trim() : "";

    if (evaluable.length <= 0 || evaluable === "") {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }
}
