import { useState, useRef, useEffect } from "react";
import { Transforms } from "slate";
import { useSlate, ReactEditor } from "slate-react";
import UnfurlApi from "../../../../api/campaign-msvc/unfurl.api";
import { UrlPreview } from "../../../../model/campaign/url-preview.dto";
import { EmpImage } from "../../emp-image/emp-image";

const LinkNodeElement = (props: any) => {
  const mode = props.mode ?? "edit";

  const [urlPreview, setUrlPreview] = useState<UrlPreview>();
  const [isSelected, setIsSelected] = useState(false);
  const isSelectedRef = useRef<boolean>(isSelected);
  const componentRef = useRef<HTMLElement>(null);
  const [isLoading, setLoading] = useState(true);
  const [urlHasFetched, setUrlHasFetched] = useState(false);

  const editor = useSlate() as ReactEditor;
  const pathRef = useRef<number[]>(ReactEditor.findPath(editor, props.element));

  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  const handleClickOutside = (event: any) => {
    // If the click is outside the component, set isSelected to false
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsSelected(false);
    }
  };

  useEffect(() => {
    const handleBackspaceKeydown = (event: any) => {
      if (event.key === "Backspace") {
        if (isSelectedRef.current) {
          event.preventDefault();
          Transforms.removeNodes(editor, { at: pathRef.current });
        }
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleBackspaceKeydown);
    // Return function to be called when unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleBackspaceKeydown);
    };
  }, []);

  const componentOnClick = () => {
    if (mode === "edit") {
      setIsSelected(true);
      const path = ReactEditor.findPath(editor, props.element);
      pathRef.current = path;
    } else if (mode === "view") {
      window.open(props.element.url, "_blank");
    }
  };

  function extractAndFormatDomain(url: string): string {
    try {
      // Parse the URL to get the hostname
      const hostname = new URL(url).hostname;

      // Split the hostname into parts
      const parts = hostname.split(".").reverse();

      // Check if there's at least a domain and a top-level domain
      if (parts.length < 2) {
        throw new Error("URL must include a domain and a top-level domain.");
      }

      // Determine the main domain and subdomain
      let mainDomain = parts[1];
      let subDomain = parts.length > 2 ? parts[2] : "";

      // If there are only two parts, the main domain is the first part in the original array (second in reversed)
      if (parts.length === 2) {
        mainDomain = parts[1];
        subDomain = "";
      }

      // Capitalize the first letter of each part and format
      const format = (str: string) =>
        str.charAt(0).toUpperCase() + str.slice(1);
      return subDomain
        ? `${format(subDomain)} ${format(mainDomain)}`
        : format(mainDomain);
    } catch (error) {
      console.error("Invalid URL:", error);
      return "";
    }
  }

  useEffect(() => {
    const getLinkPreview = async (url: string) => {
      try {
        const resp = await UnfurlApi.unfurlUrl(url);
        setUrlPreview(resp.data);
        setUrlHasFetched(true);
      } catch (e) {
        console.error(e);
        setUrlPreview({
          favicon:
            "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-web-link.svg",
          title: extractAndFormatDomain(url),
        });
      } finally {
        setLoading(false);
      }
    };
    if (urlHasFetched) return;
    getLinkPreview(props.element.url);
  }, [props.element.url]);
  return (
    <span
      onClick={componentOnClick}
      className={`slate-link ${isSelected ? "selected" : ""}`}
      {...props.attributes}
    >
      <span ref={componentRef} className="link-wrapper" contentEditable={false}>
        {!urlPreview && isLoading && (
          <>
            <div className="emp-spinner medium"></div>
            <span>Loading link...</span>
          </>
        )}
        {urlPreview && !isLoading && (
          <>
            <EmpImage
              className="icon"
              alt={`${urlPreview.title} favicon`}
              fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-web-link.svg"
              src={urlPreview.favicon}
              referrerPolicy="no-referrer"
            />
            <span>{urlPreview.title}</span>
          </>
        )}
      </span>
      <span contentEditable={false} className="hidden">
        {props.children}
      </span>
    </span>
  );
};
export default LinkNodeElement;
