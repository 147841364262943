import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { AgencyPrivacySettingsDto } from "../../model/user/agency-privacy-settings.dto";
import { UpdateAgencyPrivacySettingDto } from "../../model/user/update-agency-privacy-setting.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SettingsApi = {
  fetchAgencyPrivacySettings: async (): Promise<
    ApiResponse<AgencyPrivacySettingsDto[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<AgencyPrivacySettingsDto[]>>(
      ENDPOINTS.userMicroservice.settings.agencyPrivacy
    );
    return response.data;
  },
  updateAgencyPrivacySettings: async (
    request: UpdateAgencyPrivacySettingDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.settings.agencyPrivacy,
      request
    );
    return response.data;
  },
  fetchRatePrivacy: async (): Promise<
    ApiResponse<AgencyPrivacySettingsDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<AgencyPrivacySettingsDto>>(
      ENDPOINTS.userMicroservice.settings.fetchRatePrivacy
    );
    return response.data;
  },
};
export default SettingsApi;
