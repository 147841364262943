import { motion, AnimatePresence } from "framer-motion";
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import XCloseIcon from "../../../../components/icon/x-close-icon";
import EmpModal from "../../../../components/shared/emp-modal/emp-modal";
import { Color } from "../../../../utilities/colors";
import "./seller-chat-modal.scss";
import { DateUtil } from "../../../../utilities/date";
import { DeliverableMessageWIdDto } from "../../../../model/campaign/deliverable-message-w-id.dto";
import OngoingTaskApi from "../../../../api/campaign-msvc/ongoing-task.api";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import { DeliverableConversationDto } from "../../../../model/campaign/deliverable-conversation.dto";
import EmpException from "../../../../exception/empException";
import { ATTACHMENT_TYPE } from "../../../../constants/app.constants";
import { DeliverableMessageDto } from "../../../../model/campaign/deliverable-message.dto";
import { useAbly, usePresence } from "ably/react";
import UploadChatAttachmentModal, {
  UploadChatAttachmentModalRef,
} from "../../../../components/modals/upload-chat-attachment-modal";
import FileIcon from "../../../../components/icon/file-icon";
import FileUtils from "../../../../utilities/file-util";
import PaperclipIcon from "../../../../components/icon/paperclip-icon";
import { TaskDto } from "../../../../model/campaign/task.dto";
import useOngoingMessages from "../../../../hooks/useOngoingMessages";
import EmpPill from "../../../../components/shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../../../constants/pill-mappers.constants";

export interface SellerChatModalRef {
  show: (
    conversationId: string,
    conversation: DeliverableConversationDto
  ) => void;
  dismiss: () => void;
}

const sendButtonVariant = {
  send: {
    backgroundColor: Color.PRIMARY[500],
    color: Color.NEUTRAL[100],
  },
  attachment: {
    backgroundColor: Color.NEUTRAL[800],
    color: Color.NEUTRAL[100],
  },
};

interface Props {
  representativeId: string;
  task: TaskDto;
  onDismiss: () => void;
}

const SellerChatModal = forwardRef((props: Props, ref) => {
  const { representativeId, task } = props;
  const ably = useAbly();

  const uploadChatAttachmentRef = useRef<UploadChatAttachmentModalRef>();
  const chatBodyElemRef = useRef<HTMLDivElement>(null);
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [hasNewMessageFlag, setHasNewMessageFlag] = useState(false);
  const [sendBtnMode, setSendBtnMode] = useState<"attachment" | "send">(
    "attachment"
  );

  const loadedMessagesRef = useRef<number>(0);
  const [selectedConversationMessages, setSelectedConversationMessage] =
    useState<DeliverableMessageWIdDto[]>();

  const { formattedMessages } = useOngoingMessages(
    selectedConversationMessages
  );
  const hasMoreRef = useRef(false);
  const [conversation, setConversation] =
    useState<DeliverableConversationDto>();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const latestMessageRef = useRef<HTMLDivElement>(null);
  const latestMessageHeightRef = useRef<number>(0);
  const [latestMessage, setLatestMessage] = useState<{
    id: string;
    content: string;
    timestamp: string;
  }>();
  const [isloadingNextBatchLoader, setLoadingNextBatchLoader] = useState(false);
  const loadingNextMessageRef = useRef(false);
  const [isModalMounted, setModalMounted] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (
    conversationId: string,
    conversation: DeliverableConversationDto
  ) => {
    setConversation(conversation);
    getMessageInConversation(conversationId);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    setModalMounted(false);
    setSelectedConversationMessage(undefined);
    props.onDismiss();
  };

  const markAllMessagesForSeller = useCallback(
    async (conversationId: string) => {
      try {
        await OngoingTaskApi.markAllMessagesForSeller(conversationId);
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Unable to mark all messages as read"
        );
      }
    },
    []
  );

  const getMessageInConversation = async (
    conversationId: string,
    offset?: number
  ) => {
    try {
      const recordsOffset = offset ?? 0;
      const resp = await OngoingTaskApi.fetchMessageInConversation(
        conversationId,
        recordsOffset
      );
      const messages = resp.data.messages;
      hasMoreRef.current = resp.data.hasMore;

      // If there are messages, append. Otherwise, replace
      setSelectedConversationMessage((prev) => {
        if (!prev) return messages;
        return [...prev, ...messages];
      });
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch conversations"
      );
    } finally {
      loadingNextMessageRef.current = false;
      setLoadingNextBatchLoader(false);
    }
  };

  useEffect(() => {
    if (!isModalMounted) return;
    if (!chatBodyElemRef.current) return;

    const handleScroll = () => {
      if (loadingNextMessageRef.current || !hasMoreRef.current) return;

      const calcHeight =
        chatBodyElemRef.current!.scrollHeight -
        chatBodyElemRef.current!.clientHeight;
      const treshold =
        calcHeight - Math.abs(chatBodyElemRef.current!.scrollTop);
      if (treshold < 100) {
        getMessageInConversation(conversation!.id, loadedMessagesRef.current);
        loadingNextMessageRef.current = true;
        setLoadingNextBatchLoader(true);
      }
    };

    // Add scroll event listener
    chatBodyElemRef.current.addEventListener("scroll", handleScroll);
    // Remove event listener on cleanup
    return () => {
      chatBodyElemRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isModalMounted]);

  useEffect(() => {
    if (conversation && conversation.id) {
      markAllMessagesForSeller(conversation.id);
      const newChannel = ably.channels.get(conversation.id);
      newChannel.subscribe((message) => {
        const newMessage = message.data as DeliverableMessageWIdDto;
        setSelectedConversationMessage((prev) => {
          let messages: DeliverableMessageWIdDto[] = prev ?? [];
          messages.unshift(newMessage);
          return [...messages];
        });
      });
      // Unsubscribe when the component unmounts or conversation.id changes
      return () => {
        newChannel.unsubscribe();
      };
    }
  }, [ably, conversation, markAllMessagesForSeller]);

  // Check if user is online
  const { presenceData } = usePresence("is_online", representativeId);
  useEffect(() => {
    if (!conversation) return;
    if (presenceData.find((elem) => elem.data === conversation.brand.id)) {
      setIsUserOnline(true);
    } else setIsUserOnline(false);
  }, [presenceData, conversation]);

  const sendMessage = async () => {
    setHasNewMessageFlag(true);
    const value = textareaRef.current!.value;
    if (!conversation) throw new EmpException("No conversation found");

    const senderId =
      conversation.representativeRole === "agency"
        ? conversation.agencyRepresentative!.id
        : conversation.creator.id!;

    const request: DeliverableMessageDto = {
      representativeId: conversation.representativeId,
      representativeRole: conversation.representativeRole,
      taskId: task.id,
      agencyOrgId: conversation.agencyRepresentative?.id,
      brandOrgId: conversation.brand.id,
      creatorUserId: conversation.creator.id!,

      conversationId: conversation.id,
      senderId: senderId,
      senderRole: conversation.representativeRole,
      recipientId: conversation.brand.id,
      recipientRole: "brand",
      text: value,
      attachmentType: ATTACHMENT_TYPE.NONE,
    };
    await OngoingTaskApi.sendMessage(request);
    textareaRef.current!.value = "";
    autoResizeTextArea();
    setSendBtnMode("attachment");
  };

  function autoResizeTextArea() {
    textareaRef.current!.style.height = "auto"; // Reset the height to auto
    textareaRef.current!.style.height =
      textareaRef.current!.scrollHeight + "px"; // Set the height to match the content
  }

  useEffect(() => {
    if (hasNewMessageFlag === false || latestMessage === undefined) return;
    const p = latestMessageRef.current!.childNodes[0]
      .firstChild as HTMLParagraphElement;
    latestMessageHeightRef.current = latestMessageRef.current!.offsetHeight;
  }, [hasNewMessageFlag, latestMessage]);

  useEffect(() => {
    loadedMessagesRef.current = selectedConversationMessages
      ? selectedConversationMessages.length
      : 0;
  }, [selectedConversationMessages]);

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      verticalPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      responsiveSheet
      onMounted={() => {
        setModalMounted(true);
      }}
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-seller-chat-modal">
        {conversation && (
          <UploadChatAttachmentModal
            task={task}
            ref={uploadChatAttachmentRef}
            onSave={() => {}}
          />
        )}

        <div className="modal-header-wrapper">
          <div onClick={() => dismiss()} className="dismiss-modal-sheet-btn">
            <XCloseIcon
              size={16}
              backgroundColor={Color.NEUTRAL[350]}
              strokeWidth={3}
            />
          </div>
        </div>
        {/* Conversation Header */}
        {conversation && (
          <div className="seller-chat-header">
            <div className="profile-pic-wrapper">
              <img
                className="profile-pic org"
                alt={conversation.brand.companyName}
                src={conversation.brand.logo}
              />
              {conversation.representativeRole === "agency" && (
                <>
                  {conversation.creator.imageType === "url" && (
                    <img
                      className="profile-pic badge"
                      alt={conversation.creator.fullName}
                      src={conversation.creator.imageResource}
                    />
                  )}
                  {conversation.creator.imageType === "avatar" && (
                    <div
                      className="profile-pic badge"
                      style={{ background: conversation.creator.imageResource }}
                    >
                      <span className="avatar">
                        {conversation.creator.initials}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="name-section">
              <div className="chat-name-wrapper">
                <span className="chat-name-lbl">
                  {conversation.brand.companyName}
                </span>
                <div
                  className={`activity-status ${isUserOnline ? "online" : ""}`}
                ></div>
              </div>
              {isUserOnline && <span className="activity-lbl">Online</span>}
              {!isUserOnline && <span className="activity-lbl">Offline</span>}
            </div>
          </div>
        )}
        {/* Conversation Body */}
        {hasNewMessageFlag && latestMessage && (
          <div ref={latestMessageRef} className="me-chat-wrapper floating">
            <div className="me-chat-bubble">
              <p>{latestMessage.content}</p>
              <span className="timestamp">{latestMessage.timestamp}</span>
            </div>
          </div>
        )}
        <div ref={chatBodyElemRef} className="seller-chat-body">
          {formattedMessages.map((elem, index) => {
            return (
              <Fragment key={elem.id}>
                {elem.type === "message" && (
                  <>
                    {representativeId === elem.senderId && (
                      <motion.div
                        initial={
                          latestMessage && index === 0
                            ? { height: 0 }
                            : { height: "fit-content" }
                        }
                        animate={
                          latestMessage && index === 0
                            ? { height: latestMessageHeightRef.current }
                            : {}
                        }
                        transition={{ duration: 0.1 }}
                        key={elem.id}
                        className="me-chat-wrapper"
                      >
                        {selectedConversationMessages &&
                          index === selectedConversationMessages?.length - 1 &&
                          isloadingNextBatchLoader && (
                            <div className="batch-message-loader">
                              <div className="emp-spinner"></div>
                            </div>
                          )}
                        <motion.div className="me-chat-bubble">
                          {elem.attachmentType === ATTACHMENT_TYPE.IMAGES && (
                            <div className="image-wrapper">
                              {elem.imageFiles!.map((img, index) => {
                                return (
                                  <div
                                    key={img.link}
                                    className={`image-inner-wrapper ${
                                      elem.imageFiles!.length % 2 > 0 &&
                                      index === elem.imageFiles!.length - 1
                                        ? "full-width"
                                        : "half-width"
                                    }`}
                                  >
                                    <img
                                      className={`single-img ${
                                        elem.imageFiles!.length > 1
                                          ? "square-img"
                                          : ""
                                      }`}
                                      alt={`uploaded ${index}`}
                                      src={img.link}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {elem.attachmentType === ATTACHMENT_TYPE.VIDEO && (
                            <div className="video-wrapper">
                              <video
                                muted
                                controls
                                loop
                                src={elem.video!.link}
                              ></video>
                            </div>
                          )}
                          {elem.attachmentType === ATTACHMENT_TYPE.FILE && (
                            <div
                              className="file-wrapper"
                              onClick={() => {
                                FileUtils.handleFileDownload(
                                  elem.file!.name,
                                  elem.file!.link
                                );
                              }}
                            >
                              <div className="file-indicator my-bubble-file">
                                <FileIcon
                                  backgroundColor={Color.PRIMARY[200]}
                                  size={18}
                                />
                              </div>
                              <div className="file-info-wrapper my-bubble-file">
                                <span className="filename-lbl">
                                  {elem.file!.name}
                                </span>
                                <span className="size-lbl">
                                  {FileUtils.convertBytesToReadableSize(
                                    elem.file!.size
                                  )}
                                </span>
                              </div>
                            </div>
                          )}

                          <p
                            dangerouslySetInnerHTML={{ __html: elem.text }}
                          ></p>
                          <span className="timestamp">
                            {DateUtil.toReadable12HrTime(elem.createdAt)}
                          </span>
                        </motion.div>
                      </motion.div>
                    )}

                    {conversation!.brand.id === elem.senderId && (
                      <motion.div
                        initial={
                          latestMessage && index === 0
                            ? { height: 0 }
                            : { height: "fit-content" }
                        }
                        animate={
                          latestMessage && index === 0
                            ? { height: latestMessageHeightRef.current }
                            : {}
                        }
                        transition={{ duration: 0.1 }}
                        key={elem.id}
                        className="sender-chat-wrapper"
                      >
                        {selectedConversationMessages &&
                          index === selectedConversationMessages?.length - 1 &&
                          isloadingNextBatchLoader && (
                            <div className="batch-message-loader">
                              <div className="emp-spinner"></div>
                            </div>
                          )}
                        <motion.div className="sender-chat-bubble">
                          {elem.attachmentType === ATTACHMENT_TYPE.IMAGES && (
                            <div className="image-wrapper">
                              {elem.imageFiles!.map((img, index) => {
                                return (
                                  <div
                                    key={img.link}
                                    className={`image-inner-wrapper ${
                                      elem.imageFiles!.length % 2 > 0 &&
                                      index === elem.imageFiles!.length - 1
                                        ? "full-width"
                                        : "half-width"
                                    }`}
                                  >
                                    <img
                                      className={`single-img ${
                                        elem.imageFiles!.length > 1
                                          ? "square-img"
                                          : ""
                                      }`}
                                      alt={`uploaded ${index}`}
                                      src={img.link}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {elem.attachmentType === ATTACHMENT_TYPE.VIDEO && (
                            <div className="video-wrapper">
                              <video
                                muted
                                controls
                                loop
                                src={elem.video!.link}
                              ></video>
                            </div>
                          )}
                          {elem.attachmentType === ATTACHMENT_TYPE.FILE && (
                            <div
                              className="file-wrapper"
                              onClick={() => {
                                FileUtils.handleFileDownload(
                                  elem.file!.name,
                                  elem.file!.link
                                );
                              }}
                            >
                              <div className="file-indicator recipient-bubble-file">
                                <FileIcon
                                  backgroundColor={Color.NEUTRAL[600]}
                                  size={18}
                                />
                              </div>
                              <div className="file-info-wrapper recipient-bubble-file">
                                <span className="filename-lbl">
                                  {elem.file!.name}
                                </span>
                                <span className="size-lbl">
                                  {FileUtils.convertBytesToReadableSize(
                                    elem.file!.size
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                          <p
                            dangerouslySetInnerHTML={{ __html: elem.text }}
                          ></p>
                          <span className="timestamp">
                            {DateUtil.toReadable12HrTime(elem.createdAt)}
                          </span>
                        </motion.div>
                      </motion.div>
                    )}
                  </>
                )}
                {elem.type === "date" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <EmpPill text={elem.date} {...PILL_COLORS.gray} />
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>

        {/* Send Message Section */}
        <div className="send-message-section">
          <div className="send-message-input">
            <textarea
              rows={1}
              ref={textareaRef}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevent the default "Enter" behavior (submitting form)
                  sendMessage();
                }
              }}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSendBtnMode("send");
                } else {
                  setSendBtnMode("attachment");
                }
              }}
              placeholder="Send Message..."
              onInput={() => {
                autoResizeTextArea();
              }}
            />
          </div>

          {conversation && (
            <AnimatePresence>
              <motion.button
                variants={sendButtonVariant}
                onClick={() => {
                  if (sendBtnMode === "send") sendMessage();
                  else
                    uploadChatAttachmentRef.current?.show(
                      "seller",
                      conversation
                    );
                }}
                transition={{ ease: "backInOut" }}
                initial="attachment"
                animate={sendBtnMode}
                className="send-btn"
              >
                {sendBtnMode === "send" ? (
                  "Send"
                ) : (
                  <PaperclipIcon
                    size={20}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                )}
              </motion.button>
            </AnimatePresence>
          )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default SellerChatModal;
