import _debounce from "lodash/debounce";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignApi from "../../../api/campaign-msvc/campaign.api";
import SearchIcon from "../../../components/icon/search-icon";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import { EmpTaskCard } from "../../../components/shared/emp-task-card/emp-task-card";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import EmpPagination, {
  EmpPaginationProps,
} from "../../../components/shared/EmpPagination/EmpPagination";
import { TASK_STATUS } from "../../../constants/app.constants";
import { campaignSortOptions } from "../../../constants/selectConstants";
import { AppContext } from "../../../context/app.context";
import { QueryTaskCardDto } from "../../../model/campaign/query-task-card.dto";
import { TaskCardDto } from "../../../model/campaign/task-card.dto";
import { EmpQueryable } from "../../../model/common/tableFilters";
import { UserDto } from "../../../model/user-management/user.dto";
import { empDelay } from "../../../utilities/delay";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import UserUtils from "../../../utilities/user-utils";
import "./seller-completed-tasks-view.scss";

export const SellerCompletedTasksView = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const [taskCards, setTaskCards] = useState<TaskCardDto[]>([]);
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const [numRecords, setNumRecords] = useState<number>(0);
  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const query = useRef<EmpQueryable>({});

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
    sortBy: new FormControl("text", [], "sort-by-updated-desc"),
  });

  const queryTasks = useCallback(async (queryable?: QueryTaskCardDto) => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("pageNo", `${queryable?.pageNo ?? 1}`);
      searchParams.set("pageSize", `${queryable?.pageSize ?? 20}`);
      if (form.search.getValue().length > 0) {
        searchParams.set("search", form.search.getValue());
      }
      navigate(
        `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`
      );
      query.current = queryable as EmpQueryable;
      const resp = await CampaignApi.querySellerCompletedTasks({
        search: form.search.getValue(),
        status: [TASK_STATUS.RECRUITING],
        pageNo: queryable?.pageNo ?? 1,
        pageSize: queryable?.pageSize ?? 20,
      });
      setPaginable({
        currentPage: resp.data.currentPage,
        totalRecord: resp.data.totalRecord,
        pageSize: resp.data.pageSize,
      });
      setNumRecords(resp.data.totalRecord);
      setTaskCards(resp.data.records);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch tasks");
    } finally {
      await empDelay(200);
      setLoading(false);
    }
  }, []);

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo");
    const pageSize = searchParams.get("pageSize");
    const search = searchParams.get("search");
    if (search) {
      updateFormSearch(search);
    }
    queryTasks({
      pageNo: pageNo ? Number(pageNo) : 1,
      pageSize: pageSize ? Number(pageSize) : 20,
    });
    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [queryTasks, updateFormSearch]);

  const [userRole, setUserRole] = useState<"creator" | "agency">();
  const { user: userContext } = useContext(AppContext);
  const userRef = useRef<UserDto>();

  const fetchUser = useCallback(async () => {
    if (!userRef.current) {
      const user = await UserUtils.fetchUser(userContext);
      userRef.current = user;
    }
    if (userRef.current.role === "talent") setUserRole("creator");
    else if (
      userRef.current.organisation &&
      userRef.current.organisation.length > 0 &&
      userRef.current.organisation[0].organisationType.find(
        (elem) => elem.type === "agency"
      )
    ) {
      setUserRole("agency");
    }
  }, [userContext]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // Campaign Card
  return (
    <div className="emp-browse-task-view">
      <div className="controls-section">
        <div className="search-textbox">
          <EmpTextInput
            labelText="Search Task"
            id={"search"}
            onKeypress={(e) => {
              if (e === "Enter") {
                queryTasks(query.current);
              }
            }}
            formControl={form.search}
            placeholder={"Search Task Keywords"}
            leftIconComponent={SearchIcon}
          />
        </div>
        <div className="sort-by-textbox">
          <EmpSelect
            labelText="Sort"
            id={"sortBy"}
            onChange={() => {}}
            formControl={form.sortBy}
            placeholder={"Sort Options"}
            selectOptions={campaignSortOptions}
          />
        </div>
      </div>
      <div className="cards-section-wrapper">
        {taskCards !== undefined && numRecords !== undefined && (
          <span className="records-title">
            {numRecords} Task{numRecords > 1 ? "s" : ""} found
          </span>
        )}
        <EmpPagination
          onChange={(queryable) => queryTasks(queryable as QueryTaskCardDto)}
          pagination={paginable}
          queryable={query.current}
        />
      </div>

      <div className="campaign-card-section" ref={resizeDivRef}>
        {!isLoading &&
          taskCards.length > 0 &&
          taskCards.map((elem) => {
            return (
              <EmpTaskCard
                role={userRole}
                showEngaged={false}
                key={elem.id}
                campaignRedirectUrl={`/${userRole}/campaign-details/${elem.campaign.id}`}
                redirectUrl={`/${userRole}/campaign-details/${elem.campaign.id}/task/${elem.id}`}
                taskCardInfo={elem}
              />
            );
          })}
        {!isLoading && taskCards.length === 0 && (
          <div className="blank-overlay">
            <div className="info-wrapper">
              <img
                className="empty-img"
                alt="No tasks found"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-file.png"
              />
              <h2 className="header mt-3">No Tasks found</h2>
              <p className="description">
                Please try another search query. Looking to create a new
                campaign? Click on the button below
              </p>
            </div>
          </div>
        )}
        {!isLoading &&
          taskCards.length === 0 &&
          [1, 2, 3, 4].map((elem) => {
            return <EmpTaskCard key={elem} mode={"skeleton"} />;
          })}
        {isLoading &&
          [1, 2, 3, 4].map((elem) => {
            return <EmpTaskCard key={elem} mode={"skeleton"} />;
          })}
      </div>
    </div>
  );
};
