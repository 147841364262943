import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import EmpException from "../../../exception/empException";
import { AcceptTaskDto } from "../../../model/campaign/accept-task.dto";
import { CounterofferDto } from "../../../model/campaign/counteroffer.dto";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import { RejectTaskDto } from "../../../model/campaign/reject-task.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpContent from "../../shared/emp-content/emp-content";
import EmpLink from "../../shared/emp-link/emp-link";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import "../invite-task-modal.scss";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "../task-deliverable-modal";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";
import FormFieldUtils from "../../../utilities/form-field.util";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

interface Props {
  task: TaskDto;
  latestNegotiation: LatestNegotiationExtendedDto;
  onBackClick: () => void;
  onComplete: () => void;
}

type ModalViewMode = "selection" | "negotiation";
const TaskInvitationResponseView = (props: Props) => {
  const { task, latestNegotiation, onComplete } = props;

  const rejectBtnRef = useRef<EmpButtonRef>();
  const submitBtnRef = useRef<EmpButtonRef>();
  const counterofferBtnRef = useRef<EmpButtonRef>();
  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();

  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;
  const [modalViewMode, setModalViewMode] =
    useState<ModalViewMode>("selection");

  const contextRef = useRef<"brand" | "seller">();
  const location = useLocation();
  useEffect(() => {
    const role = location.pathname.split("/")[1];
    if (role === "brand") contextRef.current = "brand";
    else if (role === "creator" || "agency") contextRef.current = "seller";
  }, [location]);

  /**
   * Accepts the task based on the latest negotiation details.
   *
   * @returns {Promise<void>} - A promise that resolves when the task is accepted.
   * @throws {Error} - If an error occurs while accepting the task.
   */
  const acceptTask = async () => {
    try {
      if (!contextRef.current)
        throw new EmpException("context is required", "Invalid State");
      const request: AcceptTaskDto = {
        role: contextRef.current,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: "",
      };
      const resp = await NegotiationApi.acceptTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Accepted!", "This invitation has been accepted");
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error accepting task");
    }
  };

  /**
   * Rejects the task based on the latest negotiation details.
   *
   * @returns {Promise<void>} - A promise that resolves when the task is rejected.
   * @throws {Error} - If an error occurs while rejecting the task.
   */
  const rejectTask = async () => {
    try {
      rejectBtnRef.current?.setButtonState("loading");
      if (!contextRef.current)
        throw new EmpException("context is required", "Invalid State");

      const isValid = FormGroupUtil.validate(acceptRejectForm);
      setAcceptRejectForm({ ...acceptRejectForm });
      if (!isValid) return;

      const request: RejectTaskDto = {
        role: contextRef.current,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: acceptRejectForm.remarks.getValue(),
      };
      const resp = await NegotiationApi.rejectTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Rejected!", "The application has been rejected");
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error accepting task");
    } finally {
      rejectBtnRef.current?.setButtonState("default");
    }
  };
  const [form, setForm] = useState<IFormGroup>({
    amount: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const [acceptRejectForm, setAcceptRejectForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [
      new LengthValidator(
        10,
        400,
        "Description must be at least 10 characters",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const sendCounteroffer = async () => {
    try {
      counterofferBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid || !contextRef.current) return;

      const request: CounterofferDto = {
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: form.amount.getValue(),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: form.remarks.getValue(),
      };
      let resp;
      if (contextRef.current === "brand") {
        resp = await NegotiationApi.sendBrandCounteroffer(request);
      } else {
        resp = await NegotiationApi.sendSellerCounteroffer(request);
      }

      if (resp.data.status === "success") {
        ToastUtils.success(
          "Counteroffer Submitted",
          "The brand has received your counter offer."
        );
        onComplete();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error rejecting task application"
      );
    } finally {
      counterofferBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="single-response-view">
      <TaskDeliverableModal ref={taskDeliverableModalRef} />
      <section className="profile-photo-section">
        <div className="profile-photo-wrapper">
          <img
            alt="Profile"
            className="profile-photo org-photo"
            src={latestNegotiation.brand.logo}
          />
          <span className="plus-label">+</span>
          <div className="creator-rep-wrapper">
            {latestNegotiation.representativeRole === "creator" && (
              <div className="creator-profile">
                {latestNegotiation.creatorRepresentative.imageType ===
                  "url" && (
                  <img
                    alt="Profile"
                    className="profile-photo"
                    src={latestNegotiation.creatorRepresentative.imageResource}
                  />
                )}
                {latestNegotiation.creatorRepresentative.imageType ===
                  "none" && (
                  <img
                    alt="Empty profile"
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                    }
                  />
                )}
                {latestNegotiation.creatorRepresentative.imageType ===
                  "avatar" && (
                  <div
                    style={{
                      background:
                        latestNegotiation.creatorRepresentative.imageResource,
                    }}
                    className="avatar"
                  >
                    <span className="initials">
                      {latestNegotiation.creatorRepresentative.initials}
                    </span>
                  </div>
                )}
              </div>
            )}

            {latestNegotiation.representativeRole === "agency" && (
              <div className="creator-profile relative-wrapper">
                <img
                  alt="Agency brand"
                  className="profile-photo org-photo"
                  src={latestNegotiation.agencyRepresentative.logo}
                />
                <div className="absolute-wrapper">
                  {latestNegotiation.creatorRepresentative.imageType ===
                    "url" && (
                    <img
                      alt="Profile"
                      className="profile-photo"
                      src={
                        latestNegotiation.creatorRepresentative.imageResource
                      }
                    />
                  )}
                  {latestNegotiation.creatorRepresentative.imageType ===
                    "none" && (
                    <img
                      alt="Empty profile"
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {latestNegotiation.creatorRepresentative.imageType ===
                    "avatar" && (
                    <div
                      style={{
                        background:
                          latestNegotiation.creatorRepresentative.imageResource,
                      }}
                      className="avatar"
                    >
                      <span className="initials">
                        {latestNegotiation.creatorRepresentative.initials}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="content-section">
        <motion.span
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 1 * animationDelay,
          }}
          className="task-name-lbl"
        >
          You are invited to join {task.name} for <br />
          <span className="emp-highlighted">
            {latestNegotiation.amount}{" "}
            <EmpCoinIcon
              top={2}
              className="ml-1"
              iconValue={task.paymentMode}
              mode="icon-only"
            />
          </span>
        </motion.span>

        <motion.p
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
          className="description mt-4"
        >
          You may refer to the{" "}
          <EmpLink
            text={"deliverables"}
            onSubmit={() => {
              taskDeliverableModalRef.current?.show(task);
            }}
          />{" "}
          to find out the scope of this task.
        </motion.p>

        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
          className="mt-4"
        >
          <EmpContent
            label={"BRAND'S OFFER"}
            value={
              <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                {FormFieldUtils.formatNumber(Number(latestNegotiation.amount))}
                <EmpCoinIcon iconValue={task.paymentMode} />
              </div>
            }
          />
        </motion.div>
        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
          className="mt-4"
        >
          <EmpContent
            label={"BRAND'S REMARKS"}
            value={
              latestNegotiation.remarks.length === 0
                ? "No Remarks"
                : latestNegotiation.remarks
            }
          />
        </motion.div>

        {modalViewMode === "selection" && (
          <motion.p
            variants={fadeInVariants}
            initial="hidden"
            animate={"visible"}
            transition={{
              duration: animationDuration,
              delay: 4 * animationDelay,
            }}
            className="mt-4"
          >
            <EmpTextInput
              id={"remarks"}
              labelText="Remarks (Optional)"
              multiline
              textAreaAdaptiveHeight
              tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
              formControl={acceptRejectForm.remarks}
              rows={2}
              characterCount={300}
              placeholder={"Enter your remarks here..."}
            />
          </motion.p>
        )}

        {modalViewMode === "negotiation" && (
          <div>
            <hr className="emp-hr" />
            <motion.div
              variants={fadeInVariants}
              className="mt-4"
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 3 * animationDelay,
              }}
            >
              <EmpTextInput
                id={"rate"}
                labelText="Your Counteroffer"
                leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
                leftIconSize={12}
                description={`If the ${
                  contextRef.current === "brand" ? "creator / agency" : "brand"
                } finds your offer agreeable, you'll be all set to join this campaign task.`}
                required
                formControl={form.amount}
                placeholder={"00.00"}
              />
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              className="mt-3"
              transition={{
                duration: animationDuration,
                delay: 3 * animationDelay,
              }}
            >
              <EmpTextInput
                id={"remarks"}
                labelText="Remarks (Optional)"
                multiline
                textAreaAdaptiveHeight
                tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                formControl={form.remarks}
                rows={2}
                characterCount={300}
                placeholder={
                  "You may elaborate more about the scope of work, or any price justifications"
                }
              />
            </motion.div>
          </div>
        )}
      </div>
      <motion.div
        variants={fadeInVariants}
        initial="hidden"
        animate={"visible"}
        style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}
        transition={{ duration: animationDuration, delay: 4 * animationDelay }}
      >
        {modalViewMode === "selection" && (
          <>
            <EmpButton
              onSubmit={rejectTask}
              ref={rejectBtnRef}
              buttonStyle="danger"
              className="mt-4"
              text={"Reject"}
            />

            <EmpButton
              buttonStyle="secondary"
              onSubmit={() => {
                setModalViewMode("negotiation");
              }}
              className="mt-4"
              text={"Negotiate"}
            />

            <EmpButton
              ref={submitBtnRef}
              onSubmit={acceptTask}
              className="mt-4"
              buttonStyle="primary"
              text={"Accept"}
            />
          </>
        )}

        {modalViewMode === "negotiation" && (
          <>
            <EmpButton
              buttonStyle="secondary"
              onSubmit={() => {
                setModalViewMode("selection");
              }}
              isFullWidth={false}
              className="mt-4"
              text={"Back"}
            />

            <EmpButton
              ref={counterofferBtnRef}
              onSubmit={sendCounteroffer}
              className="mt-4"
              isFullWidth={false}
              buttonStyle="primary"
              text={"Submit Counteroffer"}
            />
          </>
        )}
      </motion.div>
    </div>
  );
};
export default TaskInvitationResponseView;
