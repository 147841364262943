import "./profile-picture-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Color } from "../../utilities/colors";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import { UserDto } from "../../model/user-management/user.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { UserProfilePicsRespDto } from "../../model/user-management/user-profile-pics-resp.dto";
import CameraIcon from "../../components/icon/camera-icon";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../components/shared/emp-cropper/emp-cropper";
import ToastUtils from "../../utilities/toast-utils";
import UserApi from "../../api/user-msvc/user.api";
import AlertSquareIcon from "../../components/icon/alert-square";
import XCloseIcon from "../../components/icon/x-close-icon";
import { FormattedMessage } from "react-intl";
import FileUtils from "../../utilities/file-util";

export interface ProfilePictureModalRef {
  show: (user: UserDto) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const ProfilePictureModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>();
  const saveBtnRef = useRef<EmpButtonRef>();
  const [profileImages, setProfileImages] = useState<UserProfilePicsRespDto>();

  const [imageErrorMessage, setImageErrorMessage] = useState<string>();
  const [logo, setLogo] = useState<string>();
  const [uploadedImage, setUploadedImage] = useState<string>();
  const cropperRef = useRef<EmpCropperRef>();
  const [selectedImage, setSelectedImage] = useState<string>("");
  const imageType = useRef<"url" | "avatar">("avatar");
  const isLogoModifiedRef = useRef(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (user: UserDto) => {
    imageType.current = user.imageType as "url" | "avatar";
    setUser(user);
    setLogo(user.imageResource);
    getDisplayPics();
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    setSelectedImage("");
    setUploadedImage(undefined);
  };

  const onSave = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (selectedImage === "") setImageErrorMessage("Please select an Image");

      const resp = await UserApi.updateTalentImage({
        imageType: selectedImage === "Uploaded" ? "base64" : "url",
        imageResource: logo!,
      });
      if (resp.status === "success") {
        ToastUtils.success(
          "Profile Picture Updated",
          "Your profile picture has been changed"
        );
        props.onSave();
        dismiss();
      }
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  const getDisplayPics = async () => {
    try {
      const resp = await UserApi.getUserImage();
      setProfileImages(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch user display photos"
      );
    }
  };

  /**
   * This function reads a file as a base64 string.
   * @param file - The file to be read.
   * @returns A promise that resolves with a base64 string.
   */
  const readFileAsBase64 = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64);
      };
    });

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);
    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 3MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    if (cropperRef.current === undefined) return;
    cropperRef.current.open(base64);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showFooter={false}
      showHeader={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-profile-pic-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <EmpCropper
          onCrop={(base64: string) => {
            imageType.current = "url";
            setLogo(base64);
            setUploadedImage(base64);
            setSelectedImage("Uploaded");
            isLogoModifiedRef.current = true;
          }}
          ref={cropperRef}
        />
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="changeProfilePictureModal_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="changeProfilePictureModal_desc" />
          </p>
        </div>

        <section className="content-section mt-4">
          {user && (
            <div className="main-picture">
              {imageType.current === "url" && <img alt="Profile" src={logo} />}
              {imageType.current === "avatar" && (
                <div
                  style={{ background: user.imageResource }}
                  className="avatar"
                >
                  <span className="initials">{user.initials}</span>
                </div>
              )}

              {imageErrorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{imageErrorMessage}</span>
                </div>
              )}
            </div>
          )}
          {profileImages && (
            <div className="display-pics-wrapper mt-4">
              {!profileImages.facebook && (
                <div className="platform-logo">
                  <FacebookIcon
                    size={24}
                    backgroundColor={Color.NEUTRAL[200]}
                  />
                </div>
              )}
              {profileImages.facebook && (
                <img
                  className={`profile-image ${selectedImage === "Facebook" ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedImage("Facebook");
                    imageType.current = "url";
                    setLogo(profileImages.facebook);
                  }}
                  alt="Facebook user pic"
                  src={profileImages.facebook}
                />
              )}

              {!profileImages.instagram && (
                <div className="platform-logo">
                  <InstagramIcon
                    size={24}
                    backgroundColor={Color.NEUTRAL[200]}
                  />
                </div>
              )}
              {profileImages.instagram && (
                <img
                  className={`profile-image ${selectedImage === "Instagram" ? "selected" : ""}`}
                  alt="Instagram user pic"
                  src={profileImages.instagram}
                  onClick={() => {
                    setSelectedImage("Instagram");
                    imageType.current = "url";
                    setLogo(profileImages.instagram);
                  }}
                />
              )}
              {profileImages.tiktok && (
                <img
                  className={`profile-image ${selectedImage === "TikTok" ? "selected" : ""}`}
                  alt="Tiktok user pic"
                  src={profileImages.tiktok}
                  onClick={() => {
                    setSelectedImage("TikTok");
                    imageType.current = "url";
                    setLogo(profileImages.tiktok);
                  }}
                />
              )}
              {!profileImages.tiktok && (
                <div className="platform-logo">
                  <TikTokIcon size={24} backgroundColor={Color.NEUTRAL[200]} />
                </div>
              )}

              {uploadedImage && (
                <img
                  className={`profile-image ${selectedImage === "Uploaded" ? "selected" : ""}`}
                  alt="Uploaded user pic"
                  onClick={() => {
                    setSelectedImage("Uploaded");
                    imageType.current = "url";
                    setLogo(uploadedImage);
                  }}
                  src={uploadedImage}
                />
              )}

              <label className="logo-upload" htmlFor={"logo-upload"}>
                <div className="camera-btn">
                  <CameraIcon size={24} backgroundColor={Color.NEUTRAL[200]} />
                </div>
              </label>
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="logo-upload"
                onChange={handleFileUpload}
                name="myfile"
              ></input>
            </div>
          )}
        </section>

        <div className="footer-btn-wrapper mt-6">
          <div className="mr-2">
            <EmpButton
              isFullWidth={false}
              buttonStyle={"secondary"}
              text={<FormattedMessage id="cta_cancel" />}
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
          <EmpButton
            isFullWidth={false}
            ref={saveBtnRef}
            text={
              <FormattedMessage id="changeProfilePictureModal_changePictureBtn" />
            }
            disabled={selectedImage === ""}
            onSubmit={onSave}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ProfilePictureModal;
