import _debounce from "lodash/debounce";
import "../../../utilities/viewUtils/brand-task-status-card.scss";
import "./brand-recruiting-task-summary-view.scss";
import { useLocation, useNavigate } from "react-router-dom";
import "./brand-deleted-task-view.scss";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { TaskDto } from "../../../model/campaign/task.dto";
import { useEffect, useRef } from "react";
import { easeIn, motion } from "framer-motion";

interface Props {
  task: TaskDto;
}

const ELEM_VARIANT = {
  hidden: { opacity: 0, y: 10 },
  show: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8, ease: easeIn },
  },
};
const CONTAINER_VARIANT = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.2,
    },
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
};
export const BrandDeletedTaskView = (props: Props) => {
  const { task } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const userRoleRef = useRef<string>("");

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  return (
    <motion.div
      variants={CONTAINER_VARIANT}
      initial="hide"
      animate="show"
      className="emp-brand-deleted-task-view"
    >
      <motion.img
        variants={ELEM_VARIANT}
        className="empty-img"
        alt="Task Deleted"
        srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-file.png"
      />
      <motion.h3 variants={ELEM_VARIANT}>Task has been deleted</motion.h3>
      <motion.p variants={ELEM_VARIANT} className="emp-paragraph">
        Oops! The task titled{" "}
        <span className="emp-highlighted">{task.name}</span> is no longer
        accessible. It may have been deleted. Please search for an alternative
        or reach out for assistance if needed.
      </motion.p>
      <motion.div variants={ELEM_VARIANT} className="button-wrapper mt-4">
        <EmpButton
          buttonStyle="secondary"
          onSubmit={() => {
            navigate(`/${userRoleRef.current}/home`);
          }}
          isFullWidth={false}
          text={"To Home"}
        />
        <EmpButton
          isFullWidth={false}
          onSubmit={() => {
            navigate(`/${userRoleRef.current}/campaigns#My%20Tasks`);
          }}
          text={"Search Tasks"}
        />
      </motion.div>
    </motion.div>
  );
};
