import { useRef } from "react";
import EmpButton, { EmpButtonRef } from "../emp-button/emp-button";
import InvoiceApi from "../../../api/payment-msvc/invoice.api";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";

interface Props {
  generatedInvoiceId: string;
}

const EmpDownloadButton = (props: Props) => {
  const { generatedInvoiceId } = props;
  const downloadBtnRef = useRef<EmpButtonRef>();

  const downloadInvoice = async (generatedInvoiceId: string) => {
    try {
      downloadBtnRef.current?.setButtonState("loading");
      await InvoiceApi.downloadInvoice(generatedInvoiceId);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to fetch payment records"
      );
    } finally {
      downloadBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpButton
      ref={downloadBtnRef}
      className="mr-2"
      buttonHeight="sm"
      buttonStyle="secondary"
      isFullWidth={false}
      text={"Invoice"}
      onSubmit={() => {
        downloadInvoice(generatedInvoiceId);
      }}
    />
  );
};
export default EmpDownloadButton;
