import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { FormattedMessage } from "react-intl";

import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import { TikTokLoader } from "../../../components/loaders/tiktok-loader";
import InstagramConnectInfoModal from "../../creator-home-page/dashboard-cards/instagram-connect-info-modal";
import { AppContext } from "../../../context/app.context";
import BrandSmmAccountSelectionModal from "./brand-account-selection-modal";
import BrandUnbindModal from "./brand-unbind-modal";
import { usePlatformLogin } from "../hooks/usePlatformLogin";
import { useSocialMediaConnection } from "../hooks/useSocialMediaConnection";

export interface BrandSocialMediaManagerModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  connects: SmConnectionBriefRespDto[];
  fetchSocialMediaConnections: () => void;
}

const BrandSocialMediaManagerModal = forwardRef(
  ({ connects, fetchSocialMediaConnections }: Props, ref) => {
    const { user: userContext } = useContext(AppContext);

    const [smmModalVisible, setSmmModalVisible] = useState<boolean>(false);
    const [smIsLoading, setSmIsLoading] = useState<
      "tiktok" | "twitter" | "none"
    >("none");

    const {
      bindPlatform,
      savePlatformLogin,
      facebookLogout,
      instagramLogin,
      platformModalRefs,
      onUnlink,
    } = usePlatformLogin({
      userContext,
      setSmmModalVisible,
      fetchSocialMediaConnections,
      setSmIsLoading,
    });

    const { renderCurrentConnected, renderNewConnection } =
      useSocialMediaConnection({
        connects,
        bindPlatform,
        onUnlink,
      });

    useImperativeHandle(ref, () => {
      return {
        show,
        dismiss,
      };
    });

    const show = async () => {
      setSmmModalVisible(true);
    };

    const dismiss = () => {
      setSmmModalVisible(false);
    };

    return (
      <>
        {smIsLoading === "tiktok" && <TikTokLoader />}
        <BrandSmmAccountSelectionModal
          platform="Facebook"
          ref={platformModalRefs.facebookPageSelectionModalRef}
          socialConnected={connects}
          onSave={savePlatformLogin}
          onDismiss={facebookLogout}
        />
        <BrandSmmAccountSelectionModal
          platform="Instagram"
          ref={platformModalRefs.instagramAccountSelectionModalRef}
          socialConnected={connects}
          onSave={savePlatformLogin}
          onDismiss={facebookLogout}
        />
        <InstagramConnectInfoModal
          ref={platformModalRefs.instagramConnectInfoModalRef}
          onSave={() => {
            instagramLogin();
          }}
        />

        <BrandUnbindModal
          ref={platformModalRefs.unbindModalRef}
          fetchSocialMediaConnections={fetchSocialMediaConnections}
        />

        <EmpModal
          visible={smmModalVisible}
          setVisible={setSmmModalVisible}
          header="Manage Social Media Connections"
          showHeaderBorder={false}
          showFooterBorder={false}
          size={"md"}
          onClose={() => dismiss()}
        >
          {/* This is body */}
          <div className="brand-social-media-manager-modal">
            <p className="emp-paragraph mb-2">
              <FormattedMessage id="brandManageSocialMediaModal_modalCurrentConnected" />{" "}
              ({connects?.length}/5)
            </p>
            {renderCurrentConnected}

            <p className="emp-paragraph mt-3 mb-2">
              <FormattedMessage id="brandManageSocialMediaModal_newConnection" />
            </p>
            {renderNewConnection}
          </div>

          {/* This is footer */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div style={{ width: "fit-content", marginRight: 8 }}>
              <EmpButton
                text={<FormattedMessage id="cta_cancel" />}
                buttonStyle="secondary"
                onSubmit={() => {
                  dismiss();
                }}
              />
            </div>
          </div>
        </EmpModal>
      </>
    );
  }
);

export default BrandSocialMediaManagerModal;
