import { forwardRef, useEffect, useRef, useState } from "react";
import { DateRangePicker, Tooltip, Whisper } from "rsuite";
import { DateRange, RangeType, ValueType } from "rsuite/esm/DateRangePicker";
import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import InfoCircleIcon from "../../icon/info-circle-icon";
import "./emp-date-range-picker.scss";

interface Props {
  label: string;
  isRequired?: boolean;
  formControl: FormControl;
  placeholder: string;
  onChange?: (values: FormControl) => void;
  hasSearch?: boolean;
  tooltip?: string;
  ranges?: RangeType[];
}

export interface EmpDateRangePickerRef {
  reset: () => void;
}

const EmpDateRangePicker = forwardRef((props: Props, ref) => {
  const { beforeToday } = DateRangePicker;

  const { placeholder, label, onChange, formControl } = props;
  const [value, setValue] = useState<DateRange>();

  const ranges = props.ranges ?? [];
  const isRequired = props.isRequired ?? false;
  const tooltip = props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : <></>;

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  // Initialize the component when values has already been pre-populated.
  useEffect(() => {
    setValue([
      formControl.getValue().startDate,
      formControl.getValue().endDate,
    ]);
  }, [formControl, formControl.value]);

  return (
    <>
      <label>
        {label}
        {isRequired && <span className="required">*</span>}
        {props.tooltip && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={tooltip}
          >
            <div className="emp-tooltip-wrapper">
              <InfoCircleIcon size={14} backgroundColor={Color.NEUTRAL[500]} />
            </div>
          </Whisper>
        )}
      </label>
      <DateRangePicker
        block
        onChange={(
          value: DateRange | null,
          event: React.SyntheticEvent<Element, Event>
        ) => {
          if (!value || value.length < 2) return;
          formControl.setValue({
            startDate: value[0],
            endDate: value[1],
          });
          setValue(value);
          if (onChange) onChange(formControl);
        }}
        onClean={() => {
          formControl.setValue({
            startDate: undefined,
            endDate: undefined,
          });
          setValue(undefined);
          if (onChange) onChange(formControl);
        }}
        value={value}
        shouldDisableDate={beforeToday!()}
        character=" ⇌ "
        format="dd MMM yyyy"
        placement="topStart"
        ranges={ranges}
        showOneCalendar
        appearance="default"
        placeholder={placeholder}
      />

      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </>
  );
});

export default EmpDateRangePicker;
