import { EmpQueryable } from "../../../model/common/tableFilters";

interface UseSmPostListProps {
  setSearchParams: any;
  isLoading: boolean;
  getPost: () => Promise<void>;
  setTimeView?: (value: React.SetStateAction<any>) => void;
  setSocialView?: (value: React.SetStateAction<any>) => void;
  setPostStatus?: (value: React.SetStateAction<any>) => void;
  paginationQuery?: React.MutableRefObject<EmpQueryable>;
  setPostIds?: (value: React.SetStateAction<any>) => void;
}

export function useSmPostList({
  isLoading,
  setSearchParams,
  getPost,
  setTimeView,
  setSocialView,
  setPostStatus,
  paginationQuery,
  setPostIds,
}: UseSmPostListProps): {
  updateSearchParameters: (paramName: string, value?: string) => void;
  handleTimeViewClick: (timeView: any) => Promise<void>;
  handleRefreshClick: () => Promise<void>;
  handleSocialClick: (social?: any) => Promise<void>;
  handleStatusDropdownClick: (status?: any) => Promise<void>;
  handlePaginationChange: (queryable: EmpQueryable) => void;
  formattedPostType: (postType: string) => string;
  getPostDescription: (post: any) => string;
} {
  /**
   * Updates the URL's search parameters based on the provided parameter name and value.
   * If the parameter name is not "page", the "page" parameter is deleted from the search parameters.
   * This is useful for applications that need to manage URL parameters for filtering and pagination.
   *
   * @param {string} paramName - The name of the parameter to update in the URL's search parameters.
   * @param {string} [value] - The value to set for the parameter. If undefined, the parameter is deleted.
   */
  const updateSearchParameters = (paramName: string, value?: string) => {
    const searchParameters = new URLSearchParams(window.location.search);
    if (paramName !== "page") {
      searchParameters.delete("page");
    }
    value === undefined
      ? searchParameters.delete(paramName)
      : searchParameters.set(paramName, value);

    setSearchParams(searchParameters);
  };

  const handleRemovePostIds = () => {
    if (!setPostIds) return;
    setPostIds([]);
  };

  const handleRefreshClick = async () => {
    if (isLoading) return;
    getPost();
  };

  const handleSocialClick = async (social?: any) => {
    if (!setSocialView || !setPostIds) return;
    setSocialView(social || "All");
    updateSearchParameters("platform", social);
    handleRemovePostIds();
  };

  const handleStatusDropdownClick = async (status: string) => {
    if (!setPostStatus) return;
    const capitalizedStatus =
      status?.charAt(0).toUpperCase() + status?.slice(1);

    setPostStatus(capitalizedStatus || "Status");
    updateSearchParameters("status", status);
    handleRemovePostIds();
  };

  const handleTimeViewClick = async (timeView: any) => {
    if (!setTimeView) return;
    setTimeView(timeView);
    updateSearchParameters("timeView", timeView);
  };

  const handlePaginationChange = (queryable: EmpQueryable) => {
    if (isLoading || !paginationQuery) return;
    paginationQuery.current = queryable;
    updateSearchParameters("page", queryable.pageNo?.toString());
  };

  const formattedPostType = (postType: string) => {
    const postTypeMapping: Record<string, string> = {
      feed: "Feed",
      "images-post": "Images Post",
      "video-post": "Video Post",
      story: "Story",
      reel: "Reel",
      "single-post": "Post",
      carousel: "Carousel",
      tweet: "Tweet",
      "thread-tweet": "Thread",
      "poll-tweet": "Poll Tweet",
    };

    return postTypeMapping[postType] || "-";
  };

  const getPostDescription = (post: any) => {
    return (
      post.description ||
      post.message ||
      post.caption ||
      post.text ||
      post.title ||
      (post.thread ? post.thread[0]?.text : "-")
    );
  };
  return {
    updateSearchParameters,
    handleTimeViewClick,
    handleRefreshClick,
    handleSocialClick,
    handleStatusDropdownClick,
    handlePaginationChange,
    formattedPostType,
    getPostDescription,
  };
}
