export interface AwValidateBeneficiaryErrDto {
  code: string;
  errors: Error[];
}

export interface Error {
  source: string;
  code: string;
  params?: Params;
}
//
export interface Params {
  length_min: number;
  length_max: number;
}

export function isAwValidateBeneficiaryErrDto(
  obj: any
): obj is AwValidateBeneficiaryErrDto {
  return (
    typeof obj.code === "string" &&
    Array.isArray(obj.errors) &&
    obj.errors.every(
      (error: any) =>
        typeof error.source === "string" &&
        typeof error.code === "string" &&
        (!error.params ||
          (typeof error.params.length_min === "number" &&
            typeof error.params.length_max === "number"))
    )
  );
}
