import "./dismiss-creator-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import ToastUtils from "../../utilities/toast-utils";
import { FormattedMessage, useIntl } from "react-intl";

export interface DismissCreatorModalRef {
  show: (smCreator: SmCreatorRespDto, organisationId: string) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

type DismissCreatorSpec = {
  fullName: string;
  imageResource: string;
  initials: string;
  imageType: string;
  organisationId: string;
  creatorId: string;
};

const DismissCreatorModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const dismissCreatorSpecRef = useRef<DismissCreatorSpec>();

  const [form, setForm] = useState<IFormGroup>({
    confirmation: new FormControl("text", [
      new PatternValidator(
        /^confirm dismissal$/i,
        "Please enter 'confirm dismissal' to proceed"
      ),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (smCreator: SmCreatorRespDto, organisationId: string) => {
    const {
      id: creatorId,
      name: fullName,
      imageResource,
      imageType,
      initials,
    } = smCreator;
    dismissCreatorSpecRef.current = {
      fullName,
      imageResource,
      initials,
      imageType,
      creatorId,
      organisationId,
    };
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    FormGroupUtil.reset(form);
    setForm({ ...form });
    dismissCreatorSpecRef.current = undefined;
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    const isValid = formControl.validate();
    if (isValid) setButtonDisabled(false);
    else setButtonDisabled(true);
  };

  /**
   * Dismisses a creator from the agency.
   * @async
   * @returns {Promise<void>} A Promise that resolves when the dismissal is completed.
   * @throws {EmpException} If there is an error dismissing the creator.
   */
  const onDismissCreator = async () => {
    try {
      if (!dismissCreatorSpecRef.current) return;
      const { creatorId, organisationId, fullName } =
        dismissCreatorSpecRef.current;
      const resp = await OrganisationApi.dismissCreator(
        creatorId,
        organisationId
      );
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Creator dismissed",
          `${fullName} has been dismissed from the agency`
        );
        props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error dismissing creator");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"xs"}
    >
      {/* This is body */}
      <div>
        {dismissCreatorSpecRef.current && (
          <div className="emp-dismiss-creator-modal">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 0.2, delay: 0 * animationDelay }}
            >
              {dismissCreatorSpecRef.current.imageType === "url" && (
                <img
                  alt="Profile"
                  className="profile-photo"
                  src={dismissCreatorSpecRef.current.imageResource}
                />
              )}
              {dismissCreatorSpecRef.current.imageType === "none" && (
                <img
                  alt="Empty"
                  src={
                    "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  }
                />
              )}
              {dismissCreatorSpecRef.current.imageType === "avatar" && (
                <div
                  style={{
                    background: dismissCreatorSpecRef.current.imageResource,
                  }}
                  className="avatar"
                >
                  <span className="initials">
                    {dismissCreatorSpecRef.current.initials}
                  </span>
                </div>
              )}
            </motion.div>
            <div className="content-section">
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 1 * animationDelay,
                }}
                className="heading"
              >
                <FormattedMessage id="dismissCreatorModal_header" />
              </motion.span>
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
                className="description mt-2"
              >
                <FormattedMessage
                  id="dismissCreatorModal_desc"
                  values={{ name: dismissCreatorSpecRef.current.fullName }}
                />
              </motion.p>
            </div>
            <div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className="mt-8"
                transition={{
                  duration: animationDuration,
                  delay: 5 * animationDelay,
                }}
              >
                <EmpTextInput
                  id={"confirmation"}
                  description={intl.formatMessage(
                    { id: "dismissCreatorModal_confirmationInputDesc" },
                    { confirmation: "confirm dismissal" }
                  )}
                  formControl={form.confirmation}
                  onChange={validate}
                  placeholder={intl.formatMessage(
                    { id: "dismissCreatorModal_confirmationInputPlaceholder" },
                    { confirmation: "confirm dismissal" }
                  )}
                />
              </motion.div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className={"button-wrapper"}
                transition={{
                  duration: animationDuration,
                  delay: 6 * animationDelay,
                }}
              >
                <div style={{ flex: 1 }}>
                  <EmpButton
                    buttonStyle={"secondary"}
                    onSubmit={() => {
                      dismiss();
                    }}
                    className="mt-8"
                    text={<FormattedMessage id="cta_cancel" />}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <EmpButton
                    disabled={buttonDisabled}
                    buttonStyle="danger"
                    onSubmit={() => {
                      onDismissCreator();
                    }}
                    className="mt-8"
                    text={
                      <FormattedMessage id="dismissCreatorModal_dismissCreatorBtn" />
                    }
                  />
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default DismissCreatorModal;
