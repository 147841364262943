import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { ApiStatusWithPayload } from "../../model/api/api-status-with-payload";
import { Paginatable } from "../../model/common/paginatable";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { CreatorInfoOptionsRespDto } from "../../model/user-management/creator-info-options-resp.dto";
import { CreatorInfoReqDto } from "../../model/user-management/creator-info-req.dto";
import { CreatorInfoDto } from "../../model/user-management/creator-info.dto";
import { CreatorCardDto } from "../../model/user/creator-card.dto";
import { CreatorRecruitmentCardDto } from "../../model/user/creator-recruitment-card.dto";
import { CreatorTaskRepDto } from "../../model/user/creator-task-rep.dto";
import { QueryCreatorRecruitmentCardDto } from "../../model/user/query-creator-recruitment-card.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const CreatorInfoApi = {
  checkCreatorInfoCompletion: async (
    id: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.creator.checkCreatorInfoCompletion(id)
    );
    return response.data;
  },
  getCreatorListing: async (
    request: QueryCreatorRecruitmentCardDto
  ): Promise<
    ApiResponse<ApiStatusWithPayload<Paginatable<CreatorCardDto[]>>>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<
      ApiResponse<ApiStatusWithPayload<Paginatable<CreatorCardDto[]>>>
    >(ENDPOINTS.userMicroservice.creator.fetchCreatorListing, request);
    return response.data;
  },
  fetchCreatorInfoOptions: async (): Promise<
    ApiResponse<CreatorInfoOptionsRespDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorInfoOptionsRespDto>>(
      ENDPOINTS.userMicroservice.creator.fetchCreatorInfoOptions
    );
    return response.data;
  },
  saveCreatorInfo: async (
    request: CreatorInfoReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.creator.root,
      request
    );
    return response.data;
  },
  fetchCreatorInfo: async (
    id: string
  ): Promise<ApiResponse<CreatorInfoDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorInfoDto>>(
      ENDPOINTS.userMicroservice.creator.fetchCreator(id)
    );
    return response.data;
  },
  fetchCreatorRecruitmentListing: async (
    taskType: string,
    request: QueryCreatorRecruitmentCardDto
  ): Promise<
    ApiResponse<ApiStatusWithPayload<Paginatable<CreatorRecruitmentCardDto[]>>>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<
      ApiResponse<
        ApiStatusWithPayload<Paginatable<CreatorRecruitmentCardDto[]>>
      >
    >(
      ENDPOINTS.userMicroservice.creator.fetchCreatorRecruitmentListing(
        taskType
      ),
      request
    );
    return response.data;
  },

  getCreatorPartnerships: async (
    userId: string
  ): Promise<ApiResponse<CreatorAgencyInvitationDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorAgencyInvitationDto[]>>(
      ENDPOINTS.userMicroservice.creator.getCreatorPartnerships(userId)
    );
    return response.data;
  },

  getCreatorPartnershipById: async (
    invitationId: string
  ): Promise<ApiResponse<CreatorAgencyInvitationDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorAgencyInvitationDto>>(
      ENDPOINTS.userMicroservice.creator.creatorPartnershipById(invitationId)
    );
    return response.data;
  },

  getCreatorTaskRepresentatives: async (
    userId: string
  ): Promise<ApiResponse<CreatorTaskRepDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorTaskRepDto>>(
      ENDPOINTS.userMicroservice.creator.getCreatorTaskRepresentatives(userId)
    );
    return response.data;
  },

  getCreatorRecruitmentCardById: async (
    userId: string
  ): Promise<ApiResponse<CreatorRecruitmentCardDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorRecruitmentCardDto>>(
      ENDPOINTS.userMicroservice.creator.getCreatorRecruitmentCardById(userId)
    );
    return response.data;
  },

  setupSSOManagedCreator: async (
    invitationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.creator.creatorPartnershipById(invitationId)
    );
    return response.data;
  },
};
export default CreatorInfoApi;
