import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NOTIFICATION_RECORD } from "../../../constants/app.constants";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import BellIcon from "../../icon/bell-icon";
import { AgencyInvitationNotification } from "../../notification-record/agency-invitation-notification/agency-invitation-notification";
import { CreatorInvitationResponseNotification } from "../../notification-record/creator-invitation-response-notification/creator-invitation-response-notification";
import { GenericMessageNotification } from "../../notification-record/generic-message-notification/generic-message-notification";
import EmpLink from "../emp-link/emp-link";
import "./emp-notification.scss";
import { v4 } from "uuid";
import ToastUtils from "../../../utilities/toast-utils";
import { empDelay } from "../../../utilities/delay";
import EmpButton from "../emp-button/emp-button";
import { RateCardRequestNotification } from "../../notification-record/rate-card-request-notification/rate-card-request-notification";
import { RateCardResponseNotification } from "../../notification-record/rate-card-response-notification/rate-card-response-notification";
import { FormattedMessage } from "react-intl";
import { KycMessageNotification } from "../../notification-record/kyc-message-notification/kyc-message-notification";
import { RecruitingTaskEventNotification } from "../../notification-record/recruiting-task-event-notification/recruiting-task-event-notification";
import { OngoingTaskEventNotification } from "../../notification-record/ongoing-task-event-notification/ongoing-task-event-notification";
import { TaskChatEventNotification } from "../../notification-record/task-chat-event-notification/task-chat-event-notification";
import { DeleteTaskNotification } from "../../notification-record/delete-task-notification/delete-task-notification";
import { PostPublishedEventNotification } from "../../notification-record/post-published-event-notification/post-published-event-notification";

interface Props {
  user: UserDto;
  userType: "user" | "organisation";
}

export const EmpNotification = (props: Props) => {
  const { user, userType } = props;
  const notificationElemRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const [isSelected, setSelected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hash, setHash] = useState<string>(v4());

  useEffect(() => {
    const count = notifications.reduce((accum, currentValue) => {
      const val: number = currentValue.hasViewed ? 0 : 1;
      return accum + val;
    }, 0);
    setUnreadCount(count);
  }, [notifications]);

  const fetchNotifications = useCallback(async () => {
    try {
      setLoading(true);
      let recipientUserId = "";
      if (userType === "organisation")
        recipientUserId = user.organisation![0].id;
      else recipientUserId = user.id;
      const resp = await NotificationApi.fetchNotifications(recipientUserId);
      setNotifications(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch notifications"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const markAllAsRead = useCallback(async () => {
    try {
      setLoading(true);
      let recipientUserId = "";
      if (userType === "organisation")
        recipientUserId = user.organisation![0].id;
      else recipientUserId = user.id;

      const resp = await NotificationApi.markAllNotificationAsRead(
        recipientUserId
      );
      if (resp.data.status === "success") {
        fetchNotifications();
        ToastUtils.success("Done", "Marked all notifications as read");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch notifications"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const handleViewDismiss = () => {
    setHash(v4());
  };

  useEffect(() => {
    setSelected(false);
  }, [hash]);

  useEffect(() => {
    if (isSelected) fetchNotifications();
  }, [isSelected, fetchNotifications]);

  const renderNotificationRecord = (
    notification: NotificationDto
  ): JSX.Element => {
    const recruitingtaskNotificationEvents = [
      NOTIFICATION_RECORD.RECRUITING_TASK_INVITATION,
      NOTIFICATION_RECORD.RECRUITING_TASK_APPLICATION,
      NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER,
      NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE,
      NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION,
      NOTIFICATION_RECORD.RECRUITING_TASK_START,
    ];
    const ongoingTaskNotificationEvents = [
      NOTIFICATION_RECORD.ONGOING_TASK_NEW_DRAFT,
      NOTIFICATION_RECORD.ONGOING_TASK_UPDATE_DRAFT,
      NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_ACCEPTANCE,
      NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_REJECTION,
      NOTIFICATION_RECORD.ONGOING_TASK_NEW_EVIDENCE,
      NOTIFICATION_RECORD.ONGOING_TASK_UPDATE_EVIDENCE,
      NOTIFICATION_RECORD.ONGOING_TASK_BRAND_PAYOUT_ELIGIBLE,
      NOTIFICATION_RECORD.ONGOING_TASK_SELLER_PAYOUT_ELIGIBLE,
    ];
    if (notification.event === NOTIFICATION_RECORD.AGENCY_INVITATION)
      return (
        <AgencyInvitationNotification
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    else if (
      notification.event === NOTIFICATION_RECORD.CREATOR_INVITATION_RESPONSE
    )
      return (
        <CreatorInvitationResponseNotification
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    else if (notification.event === NOTIFICATION_RECORD.GENERIC_MESSAGE)
      return (
        <GenericMessageNotification
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    else if (ongoingTaskNotificationEvents.includes(notification.event))
      return (
        <OngoingTaskEventNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    else if (recruitingtaskNotificationEvents.includes(notification.event))
      return (
        <RecruitingTaskEventNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    else if (notification.event === NOTIFICATION_RECORD.TASK_CHAT) {
      return (
        <TaskChatEventNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    } else if (notification.event === NOTIFICATION_RECORD.RATE_CARD_REQUEST) {
      return (
        <RateCardRequestNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    } else if (notification.event === NOTIFICATION_RECORD.RATE_CARD_RESPONSE) {
      return (
        <RateCardResponseNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    } else if (notification.event === NOTIFICATION_RECORD.KYC) {
      return (
        <KycMessageNotification
          onView={() => {
            handleViewDismiss();
          }}
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    } else if (notification.event === NOTIFICATION_RECORD.DELETE_TASK) {
      return (
        <DeleteTaskNotification
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
        />
      );
    } else if (notification.event === NOTIFICATION_RECORD.SMM_POST_PUBLISHED)
      return (
        <PostPublishedEventNotification
          onRefresh={() => {
            fetchNotifications();
          }}
          key={notification.id}
          notification={notification}
          user={user}
          onView={() => {
            handleViewDismiss();
          }}
        />
      );
    else return <></>;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // This function is called when the user clicks outside of the menu,
      // and is used to close the menu.
      if (
        notificationElemRef.current &&
        !notificationElemRef.current.contains(event.target as Node)
      ) {
        setSelected(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationElemRef]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <div
      ref={notificationElemRef}
      onClick={() => {
        setSelected(true);
      }}
      className="emp-notification"
    >
      {isSelected && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
          className="notification-box"
        >
          <div className="title-wrapper">
            {isLoading && (
              <div className="emp-progress mt-2 notification-loader">
                <div className="indeterminate"></div>
              </div>
            )}

            <span className="title">
              <FormattedMessage id="notifications_header" />
            </span>
            <EmpLink
              disabled={notifications.length === 0}
              text={<FormattedMessage id="notifications_markAsReadLink" />}
              onSubmit={() => {
                markAllAsRead();
              }}
            />
          </div>
          {notifications.length > 0 && (
            <div className="notification-wrapper">
              {notifications.map((elem) => {
                return renderNotificationRecord(elem);
              })}
            </div>
          )}
          {notifications.length === 0 && (
            <div className="notification-empty-wrapper">
              <img
                className="empty-state-bell-img"
                alt="empty state notification"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/bell.png"
              />
              <span className="empty-state-header mt-2">
                <FormattedMessage id="notifications_emptyHeader" />
              </span>
            </div>
          )}
          <div className="footer-wrapper">
            <EmpButton
              onSubmit={() => {
                setHash(v4());
              }}
              text={<FormattedMessage id="notifications_closeNotifications" />}
            />
          </div>
        </motion.div>
      )}
      <div className="bell-icon-wrapper">
        {unreadCount > 0 && (
          <div className="unread-count-wrapper">{unreadCount}</div>
        )}
        <BellIcon backgroundColor={Color.NEUTRAL[300]} />
      </div>
    </div>
  );
};
