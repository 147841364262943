import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { UpdateBeneficiaryDto } from "../../model/payment/beneficiary/update-beneficiary.dto";
import { AwBeneficiaryDto } from "../../model/payment/kyc/aw-beneficiary.dto";
import { EmpInterceptor } from "../emp-interceptor";

const BeneficiaryApi = {
  fetchBeneficiary: async (): Promise<
    ApiResponse<ApiStatus | AwBeneficiaryDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus | AwBeneficiaryDto>>(
      ENDPOINTS.paymentMicroservice.airwallexBeneficiary.base
    );
    return response.data;
  },

  updateBeneficiary: async (
    request: UpdateBeneficiaryDto
  ): Promise<ApiResponse<ApiStatus | AwBeneficiaryDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus | AwBeneficiaryDto>>(
      ENDPOINTS.paymentMicroservice.airwallexBeneficiary.base,
      request
    );
    return response.data;
  },
};
export default BeneficiaryApi;
