import _debounce from "lodash/debounce";
import { TaskDto } from "../../../model/campaign/task.dto";
import EmpButton from "../../shared/emp-button/emp-button";
import { FormattedMessage } from "react-intl";
import { TASK_TYPE } from "../../../constants/app.constants";
import SubmitEvidenceSelectSmModal, {
  SubmitEvidenceSelectSmModalRef,
} from "./submit-evidence-select-sm-modal";
import { useEffect, useRef } from "react";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import { EvidenceDto } from "../../../model/campaign/evidence.dto";
import { empDelay } from "../../../utilities/delay";

interface Props {
  task: TaskDto;
  redirectTo: (destination: "sm-link" | "sm-image" | "file-attachment") => void;
  onSetPlatform: (platform: string) => void;
  back: () => void;
  hasBack: boolean;
  ongoingDeliverable: OngoingDeliverableExtendedDto;
}

const SubmitEvidenceMethodsView = (props: Props) => {
  const { task, redirectTo, back, onSetPlatform, hasBack, ongoingDeliverable } =
    props;
  const submitEvidenceSelectSmModalRef =
    useRef<SubmitEvidenceSelectSmModalRef>();

  const hasTriggeredSmBinding = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    const xCode = urlParams.get("x_code");

    const openModal = async () => {
      await empDelay(400);
      submitEvidenceSelectSmModalRef.current?.show(
        ongoingDeliverable.creatorUserId
      );
    };
    if (xCode || tikTokCode || hasTriggeredSmBinding.current) {
      openModal();
      hasTriggeredSmBinding.current = true;
    }
  }, [ongoingDeliverable]);
  return (
    <div className="select-method-view">
      {/* Submit Social By Link */}
      <SubmitEvidenceSelectSmModal
        task={task}
        onSelect={(platform: string) => {
          redirectTo("sm-link");
          onSetPlatform(platform);
        }}
        ref={submitEvidenceSelectSmModalRef}
        ongoingDeliverable={ongoingDeliverable}
      />
      <section className="method-card-section ">
        <button
          className="method-card emp-button-reset"
          onClick={() => {
            submitEvidenceSelectSmModalRef.current?.show(
              ongoingDeliverable.creatorUserId
            );
          }}
        >
          <img
            className="method-image"
            alt="Social Media Link"
            src="https://emplifive-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/evidence-link.png"
          />
          <div className="ml-3">
            <h3 className="method-title">
              Select post from your social account (Recommended)
            </h3>
            <p className="emp-paragraph mt-1">
              The metrics will be automatically synced from the platform.
            </p>
          </div>
        </button>

        {/* Submit Social Media */}
        <button
          className="method-card mt-2 emp-button-reset"
          onClick={() => {
            redirectTo("sm-image");
          }}
        >
          <img
            className="method-image"
            alt="Social Media Link"
            src="https://emplifive-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/evidence-image.png"
          />
          <div className="ml-3">
            <h3 className="method-title">Upload Social Media Image</h3>
            <p className="emp-paragraph mt-1">
              Submit image attachments from your social media platform
            </p>
          </div>
        </button>

        {/* Submit Social Media */}
        <button
          className="method-card mt-2 emp-button-reset"
          onClick={() => {
            redirectTo("file-attachment");
          }}
        >
          <img
            className="method-image"
            alt="Social Media Link"
            src="https://emplifive-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/evidence-attachment.png"
          />
          <div className="ml-3">
            <h3 className="method-title">Upload File Attachment</h3>
            <p className="emp-paragraph mt-1">
              Submit file attachments as proof of work
            </p>
          </div>
        </button>
      </section>

      <div
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
      >
        {hasBack && (
          <EmpButton
            isFullWidth={false}
            buttonStyle="secondary"
            text={<FormattedMessage id="cta_back" />}
            onSubmit={() => {
              back();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SubmitEvidenceMethodsView;
