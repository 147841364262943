import {
  CAMPAIGN_SELLER_PAYMENT_GUIDE,
  CAMPAIGN_BRAND_PAYMENT_GUIDE,
  CAMPAIGN_STRUCTURE_GUIDE,
  Guide,
  ONGOING_TASK_GUIDE_SPEC,
  RECRUITING_TASK_GUIDE_SPEC,
} from "./campaign-guide-specs";

export const RESTRUCTURE_CAMPAIGN_GUIDE: Guide = {
  id: 1,
  header: "Structuring your Campaign",
  isRecommended: true,
  description:
    "You will learn how to properly structure campaigns, tasks and deliverables.",
  guideViewSpec: CAMPAIGN_STRUCTURE_GUIDE,
};

export const BRAND_PAYMENT_PROCESS_GUIDE: Guide = {
  id: 2,
  header: "Payment Process",
  isRecommended: true,
  description:
    "This guide explains how campaign payments are processed on Emplifive",
  guideViewSpec: CAMPAIGN_BRAND_PAYMENT_GUIDE,
};

export const SELLER_PAYMENT_PROCESS_GUIDE: Guide = {
  id: 2,
  header: "Payment Process",
  isRecommended: true,
  description:
    "This guide explains how campaign payments are processed on Emplifive",
  guideViewSpec: CAMPAIGN_SELLER_PAYMENT_GUIDE,
};

export const RECRUITING_TASK_GUIDE: Guide = {
  id: 3,
  header: "Recruiting task Activities",
  description:
    "This guide explains the different activities that happen in a Recruiting Task",
  guideViewSpec: RECRUITING_TASK_GUIDE_SPEC,
};

export const ONGOING_TASK_GUIDE: Guide = {
  id: 4,
  header: "Ongoing task Activities",
  description:
    "This guide explains the different activities that happen in a Ongoing Task",
  guideViewSpec: ONGOING_TASK_GUIDE_SPEC,
};
