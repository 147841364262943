import { useEffect, RefObject } from "react";

function useClickOutside(
  refs: RefObject<HTMLElement>[],
  callback: () => void
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      // Check if the clicked target is outside all passed refs
      const clickedOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node)
      );
      if (clickedOutside) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback]);
}

export default useClickOutside;
