import { useEffect, useState } from "react";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import CheckIcon from "../../icon/check-icon";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
}
const SENDER_NAME_PLACEHOLDER = "{{senderName}}";
const HIGHLIGHT_START = "{s:hl}";
const HIGHLIGHT_END = "{e:hl}";
export const GenericMessageNotification = (props: Props) => {
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  const formatMessage = (
    message: string
  ): {
    __html: string;
  } => {
    let formattedMessage = message;
    formattedMessage = formattedMessage.replaceAll(
      SENDER_NAME_PLACEHOLDER,
      notification.sender.senderName
    );
    formattedMessage = formattedMessage.replaceAll(
      HIGHLIGHT_START,
      '<span class="emp-highlighted">'
    );
    formattedMessage = formattedMessage.replaceAll(HIGHLIGHT_END, "</span>");
    return { __html: formattedMessage };
  };

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* Organisation Img */}
      {notification.sender.senderType === "organisation" && (
        <img
          alt={notification.sender.senderName}
          className="organisation-img"
          src={notification.sender.imageResource}
        />
      )}
      {/* User Img */}
      {notification.sender.senderType === "user" && (
        <>
          {notification.sender.imageType === "url" && (
            <img
              alt={notification.sender.senderName}
              className="user-img"
              src={notification.sender.imageResource}
            />
          )}
          {notification.sender.imageType === "avatar" && (
            <div
              style={{ background: notification.sender.imageResource }}
              className="user-avatar"
            >
              <span className="initials">{notification.sender.initials}</span>
            </div>
          )}
        </>
      )}
      <div className="notification-content">
        <div className="content-wrapper">
          <span
            className="info-text"
            dangerouslySetInnerHTML={formatMessage(
              notification.metadata.message
            )}
          ></span>
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
        </div>
      </div>
    </div>
  );
};
