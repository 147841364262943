import { useEffect, useState, RefObject } from "react";

const useInfiniteScroll = (
  isDataMounted: boolean,
  lastElementRef: RefObject<HTMLDivElement>,
  hasMoreRef: RefObject<boolean>
): boolean => {
  const [shouldFetchMore, setShouldFetchMore] = useState(false);

  useEffect(() => {
    if (!isDataMounted || !lastElementRef.current) return;

    console.log({
      lastElementRef: lastElementRef.current,
      hasMoreRef: hasMoreRef.current,
    });
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMoreRef.current) {
          setShouldFetchMore(true);
          console.log(true);
        }
      },
      { threshold: 1.0 }
    );

    const currentRef = lastElementRef.current;
    if (currentRef) {
      observer.observe(currentRef);
      console.log("observing", currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isDataMounted, lastElementRef, hasMoreRef]);

  useEffect(() => {
    if (shouldFetchMore) {
      setShouldFetchMore(false); // Reset after triggering the fetch
    }
  }, [shouldFetchMore]);

  return shouldFetchMore;
};

export default useInfiniteScroll;
