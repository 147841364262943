import { easeOut, motion } from "framer-motion";
import { CampaignGuideKvVariants } from "./campaign-guide-kv-variants";
import EmpButton from "../components/shared/emp-button/emp-button";
import InfoCircleIcon from "../components/icon/info-circle-icon";
import { Color } from "../utilities/colors";

export interface Guide {
  id: number;
  header: string;
  isRecommended?: boolean;
  description: string;
  guideViewSpec: GuideViewSpecs;
}

export interface GuideItems {
  title: string;
  description: JSX.Element;
  visualContent: JSX.Element;
}
export interface GuideViewSpecs {
  guideName: string;
  guideItems: GuideItems[];
}

export const CAMPAIGN_STRUCTURE_GUIDE: GuideViewSpecs = {
  guideName: "Campaign Restructuring",
  guideItems: [
    {
      title: "Campaigns",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            A campaign serves as the main container for all related tasks and
            engagements.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="campaign-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-restructuring-campaign.png"
            alt="Campaign Restructuring"
          />
        </div>
      ),
    },
    {
      title: "Tasks",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            A task is a set of work that creators are tasked to complete.
          </motion.p>
          <ul className="desc-ul">
            <motion.li
              variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            >
              Tasks are platform-specific, engage only creators and deliverables
              relevant to a particular social media site, like Facebook.
            </motion.li>
            <motion.li
              variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            >
              Available platforms include{" "}
              <span className="emp-highlighted">Facebook</span>,{" "}
              <span className="emp-highlighted">Instagram</span>, and{" "}
              <span className="emp-highlighted">TikTok</span>.
            </motion.li>
            <motion.li
              variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            >
              Use the <span className="emp-highlighted">General Tasks</span>{" "}
              category for work on other platforms, including event appearances
              or promotions on LinkedIn, Twitch live streams, etc.
            </motion.li>
          </ul>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.div
            className="task-kv"
            variants={CampaignGuideKvVariants.TASK_KV_CONTAINER_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="task-img"
              variants={CampaignGuideKvVariants.TASK_KV_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-restructuring-task-1.png"
              alt="Task Restructuring"
            />
            <motion.img
              className="task-img"
              variants={CampaignGuideKvVariants.TASK_KV_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-restructuring-task-2.png"
              alt="Task Restructuring"
            />
            <motion.div
              className="task-option-img-wrapper"
              variants={CampaignGuideKvVariants.TASK_KV_VARIANT}
            >
              <img
                className="task-option-img"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-restructuring-task-3.png"
                alt="Task Restructuring"
              />
            </motion.div>
          </motion.div>
        </div>
      ),
    },
    {
      title: "Specify Deliverables",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Each task must{" "}
            <span className="emp-highlighted">
              include one or more deliverables
            </span>
            .
          </motion.p>
          <ul className="desc-ul">
            <motion.li
              variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            >
              Deliverables must be{" "}
              <span className="emp-highlighted">fully completed</span> by
              creators/agencies to complete a task.
            </motion.li>
            <motion.li
              variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            >
              Payouts require{" "}
              <span className="emp-highlighted">
                completion of all specified deliverables
              </span>
              ; (e.g completing 2 out of 3 deliverables, does not meet brand
              criteria for payment.)
            </motion.li>
          </ul>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content anchored-bottom-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="deliverable-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-restructuring-deliverable.png"
            alt="Structuring Deliverables"
          />
        </div>
      ),
    },
  ],
};

export const CAMPAIGN_SELLER_PAYMENT_GUIDE: GuideViewSpecs = {
  guideName: "Campaign Payment Process",
  guideItems: [
    {
      title: "Platform Fee",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Emplifive charges a{" "}
            <span className="emp-highlighted">15% platform fee</span> for
            facilitating successful engagements and processing payouts.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-process-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payment-process.png"
            alt="Campaign Restructuring"
          />
        </div>
      ),
    },
    {
      title: "Businesses make payments first",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Businesses must pay upfront through Emplifive to initiate a campaign
            task. Once all deliverables for a task are completed,
            agencies/creators will be eligible for their payouts.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <div className="brand-pay-first-kv">
            <motion.img
              variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
              className="brand-pay-first-img"
              initial="hide"
              animate="show"
              exit="hide"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-camaign-payment-brand.png"
              alt="Campaign Restructuring"
            />
            <motion.div
              variants={CampaignGuideKvVariants.FADE_VARIANT}
              initial="hide"
              animate="show"
              exit="hide"
              transition={{ duration: 1 }}
              className="brand-pay-first-bottom-wrapper"
            >
              <motion.div
                variants={CampaignGuideKvVariants.BTN_ENTRY}
                transition={{ duration: 0.6, delay: 0.5, ease: easeOut }}
                initial="hide"
                animate="show"
                exit="hide"
              >
                <EmpButton text={"Make Payment"} isFullWidth={false} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      ),
    },
    {
      title: "Payouts",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            After <span className="emp-highlighted">all deliverables</span> are
            completed, a five-day observation period commences. Following this
            period, the creator's{" "}
            <span className="emp-highlighted">payout will be processed</span>.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-payout-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payout.png"
            alt="Campaign Restructuring"
          />
        </div>
      ),
    },
    {
      title: "Settling Disputes",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            If there are discrepancies or concerns with the submitted work,
            businesses can{" "}
            <span className="emp-highlighted">initiate a dispute</span> during
            the{" "}
            <span className="emp-highlighted">five-day observation period</span>
            .
          </motion.p>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph mt-3"
          >
            {" "}
            In the absence of any disputes, payments will be processed and
            released to creators/agencies on the scheduled payout date.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-cs-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payment-cs.png"
            alt="Customer Success"
          />
        </div>
      ),
    },
    {
      title: "Payout Destination",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Payouts are directly issued to the agency/creator's{" "}
            <span className="emp-highlighted">bank account</span>, set up during
            the KYC process.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-bank-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payment-bank.png"
            alt="Payout Bank"
          />
        </div>
      ),
    },
  ],
};

export const CAMPAIGN_BRAND_PAYMENT_GUIDE: GuideViewSpecs = {
  guideName: "Campaign Payment Process",
  guideItems: [
    {
      title: "Businesses make payments first",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Businesses must pay upfront through Emplifive to initiate a campaign
            task. Once all deliverables for a task are completed,
            agencies/creators will be eligible for their payouts.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <div className="brand-pay-first-kv">
            <motion.img
              variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
              className="brand-pay-first-img"
              initial="hide"
              animate="show"
              exit="hide"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-camaign-payment-brand.png"
              alt="Campaign Restructuring"
            />
            <motion.div
              variants={CampaignGuideKvVariants.FADE_VARIANT}
              initial="hide"
              animate="show"
              exit="hide"
              transition={{ duration: 1 }}
              className="brand-pay-first-bottom-wrapper"
            >
              <motion.div
                variants={CampaignGuideKvVariants.BTN_ENTRY}
                transition={{ duration: 0.6, delay: 0.5, ease: easeOut }}
                initial="hide"
                animate="show"
                exit="hide"
              >
                <EmpButton text={"Make Payment"} isFullWidth={false} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      ),
    },
    {
      title: "Payouts",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            After <span className="emp-highlighted">all deliverables</span> are
            completed, a five-day observation period commences. Following this
            period, the creator's{" "}
            <span className="emp-highlighted">payout will be processed</span>.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-payout-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payout.png"
            alt="Campaign Restructuring"
          />
        </div>
      ),
    },
    {
      title: "Settling Disputes",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            If there are discrepancies or concerns with the submitted work,
            businesses can{" "}
            <span className="emp-highlighted">initiate a dispute</span> during
            the{" "}
            <span className="emp-highlighted">five-day observation period</span>
            .
          </motion.p>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph mt-3"
          >
            {" "}
            In the absence of any disputes, payments will be processed and
            released to creators/agencies on the scheduled payout date.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-cs-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payment-cs.png"
            alt="Customer Success"
          />
        </div>
      ),
    },
    {
      title: "Payout Destination",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Payouts are directly issued to the agency/creator's{" "}
            <span className="emp-highlighted">bank account</span>, set up during
            the KYC process.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-bank-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-campaign-payment-bank.png"
            alt="Payout Bank"
          />
        </div>
      ),
    },
  ],
};

export const RECRUITING_TASK_GUIDE_SPEC: GuideViewSpecs = {
  guideName: "Recruiting Task",
  guideItems: [
    {
      title: "Tasks",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            <span className="emp-highlighted">Invite suitable creators</span>{" "}
            based on metrics, interests, and services to join your campaign
            task.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.div
            className="recruiting-creator-card-kv"
            variants={CampaignGuideKvVariants.TASK_KV_CONTAINER_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="creator-card-img"
              variants={CampaignGuideKvVariants.TASK_KV_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-recruiting-task-creator-card-1.png"
              alt="Creator Card 1"
            />
            <motion.img
              className="creator-card-img"
              variants={CampaignGuideKvVariants.TASK_KV_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-recruiting-task-creator-card-2.png"
              alt="Creator Card 2"
            />
          </motion.div>
        </div>
      ),
    },
    {
      title: "Tasks Applications",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Creators/Agencies can apply to participate in your Campaign Tasks if
            they are interested.{" "}
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <div className="recruiting-application-kv">
            <motion.img
              variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
              className="recruiting-application-img"
              initial="hide"
              animate="show"
              exit="hide"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-recruiting-application.png"
              alt="Task Application"
            />
            <motion.div
              variants={CampaignGuideKvVariants.FADE_VARIANT}
              initial="hide"
              animate="show"
              exit="hide"
              transition={{ duration: 1 }}
              className="recruiting-application-bottom-wrapper"
            >
              <motion.div
                variants={CampaignGuideKvVariants.BTN_ENTRY}
                initial="hide"
                animate="show"
                transition={{ duration: 0.6, delay: 0.6, ease: easeOut }}
                exit="hide"
              >
                <EmpButton
                  text={"Reject"}
                  buttonStyle="danger"
                  isFullWidth={false}
                />
              </motion.div>
              <motion.div
                variants={CampaignGuideKvVariants.BTN_ENTRY}
                transition={{ duration: 0.6, delay: 0.5, ease: easeOut }}
                initial="hide"
                animate="show"
                exit="hide"
              >
                <EmpButton
                  text={"Negotiate"}
                  buttonStyle="secondary"
                  isFullWidth={false}
                />
              </motion.div>
              <motion.div
                variants={CampaignGuideKvVariants.BTN_ENTRY}
                transition={{ duration: 0.6, delay: 0.6, ease: easeOut }}
                initial="hide"
                animate="show"
                exit="hide"
              >
                <EmpButton text={"Accept"} isFullWidth={false} />
              </motion.div>
            </motion.div>
          </div>
        </div>
      ),
    },
    {
      title: "Raise Counteroffers",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Utilize the counteroffer feature to negotiate prices with
            creators/agencies.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content anchored-bottom-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="recruiting-counteroffer-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-recruiting-counteroffer.png"
            alt="Recruiting Counteroffer"
          />
        </div>
      ),
    },
    {
      title: "Starting Tasks",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            After finalizing negotiations, with creators marked as{" "}
            <span className="emp-highlighted">Ready to Start</span>, you may
            start your task by making payment first.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content anchored-top-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="recruiting-start-task-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-camaign-payment-brand.png"
            alt="Recruiting Counteroffer"
          />
        </div>
      ),
    },
  ],
};

export const ONGOING_TASK_GUIDE_SPEC: GuideViewSpecs = {
  guideName: "Ongoing Task",
  guideItems: [
    {
      title: "Drafts Submission",
      description: (
        <>
          <motion.div
            className="info-alert"
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
          >
            <InfoCircleIcon backgroundColor={Color.PRIMARY[500]} size={14} />
            <span>
              This step applies only to deliverables that require drafts
            </span>
          </motion.div>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Creators/Agencies{" "}
            <span className="emp-highlighted">
              must submit deliverable drafts
            </span>{" "}
            for brand review.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content anchored-top-kv">
          <motion.img
            variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="ongoing-draft-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-draft.png"
            alt="Recruiting Counteroffer"
          />
        </div>
      ),
    },
    {
      title: "Review Drafts",
      description: (
        <>
          <motion.div
            className="info-alert"
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
          >
            <InfoCircleIcon backgroundColor={Color.PRIMARY[500]} size={14} />
            <span>
              This step applies only to deliverables that require drafts
            </span>
          </motion.div>

          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Review drafts from Creators/Agencies, by approving or rejecting
            them. <span className="emp-highlighted">Up to 3 revisions</span> are
            permitted.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <motion.div
            className="ongoing-draft-card-kv"
            variants={CampaignGuideKvVariants.EMPTY_CONTAINER_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="ongoing-draft-card left-card"
              variants={
                CampaignGuideKvVariants.ONGOING_DRAFT_RIGHT_CARD_VARIANT
              }
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-draft-card-2.png"
              alt="Ongoing Draft Card Placeholder"
            />
            <motion.img
              className="ongoing-draft-card center-card"
              variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-draft-card-1.png"
              alt="Ongoing Draft Card"
            />
            <motion.img
              className="ongoing-draft-card right-card"
              variants={CampaignGuideKvVariants.ONGOING_DRAFT_LEFT_CARD_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-draft-card-2.png"
              alt="Ongoing Draft Card Placeholder"
            />
          </motion.div>
        </div>
      ),
    },
    {
      title: "Submit Proof of Work",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            Upon draft approval, creators will{" "}
            <span className="emp-highlighted">finalize deliverables</span> and{" "}
            <span className="emp-highlighted">submit proof of work</span>,
            marking the deliverable as complete. Completion of all deliverables
            triggers task completion and schedules a payout.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.div
            className="pow-options-kv"
            variants={
              CampaignGuideKvVariants.ONGOING_POW_OPTIONS_CONTAINER_VARIANT
            }
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="pow-img"
              variants={CampaignGuideKvVariants.ONGOING_POW_OPTIONS_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-pow-option-1.png"
              alt="Proof of work option - Select Social Media Post"
            />
            <motion.img
              className="pow-img"
              variants={CampaignGuideKvVariants.ONGOING_POW_OPTIONS_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-pow-option-2.png"
              alt="Proof of work option - Upload Social media image"
            />
            <motion.img
              className="pow-img"
              variants={CampaignGuideKvVariants.ONGOING_POW_OPTIONS_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-pow-option-3.png"
              alt="Proof of work option - Upload File Attachment"
            />
          </motion.div>
        </div>
      ),
    },
    {
      title: "Review Proof of work",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            <span className="emp-highlighted">
              Campaign Task owners will receive notifications about completed
              work
            </span>{" "}
            and can review the submitted proofs of work from Creators/Agencies.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <motion.div
            className="ongoing-proof-of-work-card-kv"
            variants={CampaignGuideKvVariants.EMPTY_CONTAINER_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="ongoing-proof-of-work-card left-card"
              variants={CampaignGuideKvVariants.ONGOING_DRAFT_LEFT_CARD_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-proof-of-work-card-2.png"
              alt="Ongoing Proof of Work Card Placeholder"
            />
            <motion.img
              className="ongoing-proof-of-work-card center-card"
              variants={CampaignGuideKvVariants.ENTRY_BOTTOM_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-proof-of-work-card-1.png"
              alt="Ongoing Proof of Work Card"
            />
            <motion.img
              className="ongoing-proof-of-work-card right-card"
              variants={
                CampaignGuideKvVariants.ONGOING_DRAFT_RIGHT_CARD_VARIANT
              }
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-proof-of-work-card-2.png"
              alt="Ongoing Proof of Work Card Placeholder"
            />
          </motion.div>
        </div>
      ),
    },
    {
      title: "Payouts",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            After a creator completes all deliverables, the payout status enters
            the <span className="emp-highlighted">Observation Window</span>{" "}
            phase. This period allows the brand to dispute any unsatisfactory
            work. Following the 5-day window, the creator's payout is{" "}
            <span className="emp-highlighted">processed</span>.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content centered-kv">
          <motion.img
            variants={CampaignGuideKvVariants.CAMPAIGN_KV_VARIANT}
            initial="hide"
            animate="show"
            exit="hide"
            className="payment-payout-kv"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-payout.png"
            alt="Campaign Restructuring"
          />
        </div>
      ),
    },
    {
      title: "Progress Wall",
      description: (
        <>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph"
          >
            A concise overview to track your campaign's progress and monitor all
            essential activities.
          </motion.p>
        </>
      ),
      visualContent: (
        <div className="guide-visual-content">
          <motion.div
            className="ongoing-progress-wall-kv"
            variants={
              CampaignGuideKvVariants.ONGOING_PROGRESSWALL_CONTAINER_VARIANT
            }
            initial="hide"
            animate="show"
            exit="hide"
          >
            <motion.img
              className="ongoing-col"
              variants={CampaignGuideKvVariants.ONGOING_PROGRESSWALL_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-kb-col-1.png"
              alt="Ongoing Draft Card Placeholder"
            />
            <motion.img
              className="ongoing-col"
              variants={CampaignGuideKvVariants.ONGOING_PROGRESSWALL_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-kb-col-2.png"
              alt="Ongoing Draft Card"
            />
            <motion.img
              className="ongoing-col"
              variants={CampaignGuideKvVariants.ONGOING_PROGRESSWALL_VARIANT}
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/guides/guide-ongoing-kb-col-3.png"
              alt="Ongoing Draft Card Placeholder"
            />
          </motion.div>
        </div>
      ),
    },
  ],
};
