import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { AcceptTaskDto } from "../../model/campaign/accept-task.dto";
import { CounterofferDto } from "../../model/campaign/counteroffer.dto";
import { CreateTaskApplicationDto } from "../../model/campaign/create-task-application.dto";
import { CreateTaskInvitationDto } from "../../model/campaign/create-task-invitation.dto";
import { DeliverableChangelogComparison } from "../../model/campaign/deliverable-changelog-comparison.dto";
import { LatestNegotiationSingleDto } from "../../model/campaign/latest-negotiation-single.dto";
import { LatestNegotiationDto } from "../../model/campaign/latest-negotiation.dto";
import { NegotiationExtendedDto } from "../../model/campaign/negotiation-extended.dto";
import { NegotiationLogDto } from "../../model/campaign/negotiation-log.dto";
import { RejectTaskDto } from "../../model/campaign/reject-task.dto";
import { TaskPaymentDetailsDto } from "../../model/campaign/task-payment-details.dto";
import { EmpInterceptor } from "../emp-interceptor";

const NegotiationApi = {
  createNegotiations: async (
    request: CreateTaskInvitationDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.base,
      request
    );
    return response.data;
  },
  createApplication: async (
    request: CreateTaskApplicationDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.createApplication,
      request
    );
    return response.data;
  },
  acceptTask: async (
    request: AcceptTaskDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.acceptNegotiation,
      request
    );
    return response.data;
  },
  rejectTask: async (
    request: RejectTaskDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.rejectNegotiation,
      request
    );
    return response.data;
  },
  sendBrandCounteroffer: async (
    request: CounterofferDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.brandCounteroffer,
      request
    );
    return response.data;
  },
  sendSellerCounteroffer: async (
    request: CounterofferDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.negotiation.sellerCounteroffer,
      request
    );
    return response.data;
  },
  getNegotiationById: async (
    negotiationId: string
  ): Promise<ApiResponse<NegotiationExtendedDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<NegotiationExtendedDto>>(
      ENDPOINTS.campaignMsvc.negotiation.negotiationById(negotiationId)
    );
    return response.data;
  },
  getLatestNegotiation: async (
    campaignId: string,
    taskId: string,
    status?: string
  ): Promise<ApiResponse<LatestNegotiationDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.negotiation.latestNegotiation}?taskId=${taskId}&campaignId=${campaignId}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await axios.get<ApiResponse<LatestNegotiationDto[]>>(url);
    return response.data;
  },
  getLatestNegotiationByRepresentative: async (
    representativeId: string,
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<LatestNegotiationSingleDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.negotiation.latestNegotiationByRepresentative(
      representativeId
    )}?taskId=${taskId}&campaignId=${campaignId}`;
    const response =
      await axios.get<ApiResponse<LatestNegotiationSingleDto>>(url);
    return response.data;
  },
  getLatestNegotiationByBrandOrgId: async (
    brandOrgId: string,
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<LatestNegotiationSingleDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.negotiation.latestNegotiationByBrandOrg(
      brandOrgId
    )}?taskId=${taskId}&campaignId=${campaignId}`;
    const response =
      await axios.get<ApiResponse<LatestNegotiationSingleDto>>(url);
    return response.data;
  },
  getBrandNegotiationLogs: async (
    campaignId: string,
    taskId: string
  ): Promise<ApiResponse<NegotiationLogDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    let url = `${ENDPOINTS.campaignMsvc.negotiation.brandNegotiationLogs(
      campaignId,
      taskId
    )}`;
    const response = await axios.get<ApiResponse<NegotiationLogDto[]>>(url);
    return response.data;
  },

  getDeliverableChangelogComparison: async (
    negotiationId: string
  ): Promise<ApiResponse<DeliverableChangelogComparison>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<DeliverableChangelogComparison>
    >(ENDPOINTS.campaignMsvc.negotiation.deliverableComparison(negotiationId));
    return response.data;
  },
};
export default NegotiationApi;
