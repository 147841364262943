import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./my-creators-view.scss";
import _debounce from "lodash/debounce";
import { Color } from "../../../utilities/colors";
import { useNavigate } from "react-router-dom";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import OrganisationApi from "../../../api/user-msvc/organisation.api";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import InviteTalentsModal, {
  InviteTalentsModalRef,
} from "../../../components/modals/invite-talents-modal";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableProps,
  EmpTableSeperator,
} from "../../../components/shared/EmpTable/EmpTable";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import { SmCreatorRespDto } from "../../../model/user-management/sm-creator-resp.dto";
import { OrganisationTalentInvitationStatus } from "../../../model/user/organisation-talent-invitation-status.dto";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import EmpKebabMenu from "../../../components/shared/emp-kebab-menu/emp-kebab-menu";
import { MenuButtonSpec } from "../../../components/shared/emp-profile-dropdown-btn/emp-profile-dropdown-btn";
import DismissCreatorModal, {
  DismissCreatorModalRef,
} from "../../../components/modals/dismiss-creator-modal";
import { AppContext } from "../../../context/app.context";
import EmpException from "../../../exception/empException";
import EmpLoader, {
  EmpLoaderRef,
} from "../../../components/shared/emp-loader/emp-loader";
import { FormattedMessage, useIntl } from "react-intl";
import PlusIcon from "../../../components/icon/plus-icon";
import FormFieldUtils from "../../../utilities/form-field.util";
import StringUtils from "../../../utilities/string.util";
import XIcon from "../../../components/icon/x-icon";
import { SOCIAL_MEDIA_TYPE } from "../../../constants/app.constants";

const statusBackgroundMap: {
  [key: string]: { backgroundColor: string; color: string };
} = {
  Pending: {
    backgroundColor: Color.NEUTRAL[150],
    color: Color.NEUTRAL[800],
  },
  Joined: {
    backgroundColor: Color.GREEN[500],
    color: Color.NEUTRAL[0],
  },
};
export const MyCreatorsView = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const inviteTalentModalRef = useRef<InviteTalentsModalRef>();

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [maxCards, setMaxCards] = useState<number>(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [talents, setTalents] = useState<SmCreatorRespDto[]>([]);
  const [talentInvitation, setTalentInvitation] =
    useState<OrganisationTalentInvitationStatus[]>();
  const [talentInvitationTableProps, setTalentInvitationTableProps] =
    useState<EmpTableProps<OrganisationTalentInvitationStatus>>();

  const dismissCreatorModalRef = useRef<DismissCreatorModalRef>();
  const talentSettingsKebabBtnRefs = useRef<HTMLDivElement[]>([]);
  const talentInvitationResendBtnRefs = useRef<EmpButtonRef[]>([]);
  const talentInvitationRevokeBtnRefs = useRef<EmpButtonRef[]>([]);
  const talentInvitationContentSpec: EmpTableContentSpec<OrganisationTalentInvitationStatus>[] =
    useMemo(() => {
      return [
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_creatorHeader",
          }),
          dataIndex: "creatorName",
          render: (record) => {
            if (record.hasAccount)
              return (
                <div className="creator-record-row">
                  {record.creatorImageType === "url" && (
                    <img
                      className="profile"
                      alt={record.creatorName}
                      src={record.creatorImageResource}
                    />
                  )}
                  {record.creatorImageType === "none" && (
                    <img
                      className="profile"
                      alt={record.creatorName}
                      src={record.creatorImageResource}
                    />
                  )}
                  {record.creatorImageType === "avatar" && (
                    <div
                      className="profile avatar"
                      style={{ background: record.creatorImageResource }}
                    >
                      <span className="initials">{record.creatorInitials}</span>
                    </div>
                  )}
                  {record.hasCompletedOnboarding && (
                    <EmpLink
                      onSubmit={() => {
                        navigate(`/agency/creator-details/${record.id}`);
                      }}
                      text={record.creatorName}
                    />
                  )}
                  {!record.hasCompletedOnboarding && (
                    <span> {record.creatorName}</span>
                  )}
                </div>
              );
            else
              return (
                <div className="creator-record-row">
                  <img
                    className="profile"
                    alt={record.creatorName}
                    src={record.creatorImageResource}
                  />
                  <span> {record.creatorName}</span>
                </div>
              );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_emailAddressHeader",
          }),
          dataIndex: "email",
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_hasAccountHeader",
          }),
          dataIndex: "hasAccount",
          render: (record) => {
            return (
              <span>
                {record.hasAccount ? (
                  <FormattedMessage id="agencyMyCreatorsView_yesData" />
                ) : (
                  <FormattedMessage id="agencyMyCreatorsView_noData" />
                )}
              </span>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_invitationSentDateHeader",
          }),
          dataIndex: "createdDate",
          render: (record) => {
            return (
              <span>{DateUtil.toReadableDate(record.invitationSentDate)}</span>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_joinedDateHeader",
          }),
          dataIndex: "joinedDate",
          render: (record) => {
            return (
              <span>
                {record.joinedDate
                  ? DateUtil.toReadableDate(record.joinedDate)
                  : "-"}
              </span>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_statusHeader",
          }),
          dataIndex: "status",
          render: (record) => {
            let statusText = "";
            if (record.status === "Joined") {
              statusText = intl.formatMessage({
                id: "agencyMyCreatorsView_joinedStatusData",
              });
            } else if (record.status === "Pending") {
              statusText = intl.formatMessage({
                id: "agencyMyCreatorsView_pendingStatusData",
              });
            }
            return (
              <EmpPill
                text={statusText}
                backgroundColor={
                  statusBackgroundMap[record.status].backgroundColor
                }
                color={statusBackgroundMap[record.status].color}
              />
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_connectedAccountsHeader",
          }),
          dataIndex: "connectedAccount",
          render: (record) => {
            return (
              <div className="social-media-wrapper">
                {record.hasAccount && (
                  <>
                    <div>
                      <FacebookIcon
                        size={18}
                        backgroundColor={
                          record.socialMediaAccountsConnected.includes(
                            SOCIAL_MEDIA_TYPE.FACEBOOK
                          )
                            ? Color.NEUTRAL[550]
                            : Color.NEUTRAL[150]
                        }
                      />
                    </div>
                    <div className="ml-2">
                      <InstagramIcon
                        size={18}
                        backgroundColor={
                          record.socialMediaAccountsConnected.includes(
                            SOCIAL_MEDIA_TYPE.INSTAGRAM
                          )
                            ? Color.NEUTRAL[550]
                            : Color.NEUTRAL[150]
                        }
                      />
                    </div>
                    <div className="ml-2">
                      <TikTokIcon
                        size={18}
                        backgroundColor={
                          record.socialMediaAccountsConnected.includes(
                            SOCIAL_MEDIA_TYPE.TIKTOK
                          )
                            ? Color.NEUTRAL[550]
                            : Color.NEUTRAL[150]
                        }
                      />
                    </div>
                    <div className="ml-2">
                      <XIcon
                        size={18}
                        backgroundColor={
                          record.socialMediaAccountsConnected.includes(
                            SOCIAL_MEDIA_TYPE.X
                          )
                            ? Color.NEUTRAL[550]
                            : Color.NEUTRAL[150]
                        }
                      />
                    </div>
                  </>
                )}
                {!record.hasAccount && (
                  <>
                    <span>
                      <FormattedMessage id="agencyMyCreatorsView_noAccountsConnectedData" />
                    </span>
                  </>
                )}
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyMyCreatorsView_actionHeader",
          }),
          dataIndex: "action",
          render: (record, index) => {
            return (
              <>
                {record.status === "Pending" && (
                  <div className="action-button-wrapper ">
                    <EmpButton
                      ref={(el: EmpButtonRef) => {
                        talentInvitationRevokeBtnRefs.current[index] = el;
                      }}
                      onSubmit={() => {
                        revokeInvitation(
                          record,
                          talentInvitationRevokeBtnRefs.current[index]
                        );
                      }}
                      isFullWidth={false}
                      buttonStyle="secondary"
                      text={
                        <FormattedMessage id="agencyMyCreatorsView_revokeBtn" />
                      }
                    />

                    <EmpButton
                      ref={(el: EmpButtonRef) => {
                        talentInvitationResendBtnRefs.current[index] = el;
                      }}
                      onSubmit={() => {
                        resendInvitation(
                          record,
                          talentInvitationResendBtnRefs.current[index]
                        );
                      }}
                      isFullWidth={false}
                      buttonStyle="secondary"
                      text={
                        <FormattedMessage id="agencyMyCreatorsView_resendEmailBtn" />
                      }
                    />
                  </div>
                )}
                {record.status === "Joined" && <></>}
              </>
            );
          },
        },
      ];
    }, [intl, navigate]);

  const { user: userContext } = useContext(AppContext);

  // Toggling the loading
  useEffect(() => {
    if (talentInvitation && talents) setIsLoading(false);
    else setIsLoading(true);
  }, [talentInvitation, talents]);

  useEffect(() => {
    if (isLoading === true) empLoaderRef.current?.show();
    if (isLoading === false) empLoaderRef.current?.hide();
  }, [isLoading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  const fetchTalentInvitationStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await OrganisationApi.fetchTalentInvitationStatus();
      const recentCreators = resp.data;
      // Split up by status
      const invitedCreators = recentCreators.filter(
        (elem) => elem.status === "Pending"
      );
      const joinedCreators = recentCreators.filter(
        (elem) => elem.status === "Joined"
      );

      const tableRecords: (
        | OrganisationTalentInvitationStatus
        | EmpTableSeperator
      )[] = [];
      if (invitedCreators.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: (
            <FormattedMessage
              id="agencyMyCreatorsView_recentCreatorActivityInvitationSection"
              values={{
                value: invitedCreators.length,
                count: invitedCreators.length === 1 ? 1 : "other",
              }}
            />
          ),
        });
        tableRecords.push(...invitedCreators);
      }
      if (joinedCreators.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: (
            <FormattedMessage
              id="agencyMyCreatorsView_recentCreatorActivityAcceptanceSection"
              values={{
                value: joinedCreators.length,
                count: joinedCreators.length === 1 ? 1 : "other",
              }}
            />
          ),
        });
        tableRecords.push(...joinedCreators);
      }

      setTalentInvitation(resp.data);
      setTalentInvitationTableProps({
        data: tableRecords,
        contentColumns: talentInvitationContentSpec,
        rowKey: "id",
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when fetching talent invitation status"
      );
    }
  }, []);

  const fetchTalents = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await OrganisationApi.fetchAgencyCreators();
      setTalents(response.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    fetchTalents();
    fetchTalentInvitationStatus();
  }, [fetchTalentInvitationStatus, fetchTalents]);

  const GUTTER_WIDTH = 16;
  const MIN_CARD_WIDTH = 210;

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);

    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );

    setCardWidth(cardWidth);
    setMaxCards(maxCards);
    setShimmerCards(Array.from(Array(maxCards * 3).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  const resendInvitation = async (
    record: OrganisationTalentInvitationStatus,
    buttonRef: EmpButtonRef
  ) => {
    try {
      buttonRef.setButtonState("loading");
      const resp = await OnboardingApi.resendInvitation({
        hasAccount: record.hasAccount,
        role: "talent",
        currentEmailAddress: record.email,
        newEmailAddress: record.email,
        creatorId: record.hasAccount ? record.id : undefined,
      });
      if (resp.data.status === "success") {
        ToastUtils.success("Email sent", "The email has been sent");
      } else if (
        resp.data.status === "error" &&
        resp.data.statusMessage.startsWith("rate-limit")
      ) {
        // statusMessage = "rate-limit:20", where 20 refers to the time left till the next invocation
        const waitMinutes = resp.data.statusMessage.split(":")[1];
        ToastUtils.error(
          "Please wait before resending",
          `Please wait for ${waitMinutes} minutes before resending`
        );
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to resend email");
    } finally {
      buttonRef.setButtonState("default");
    }
  };

  const revokeInvitation = async (
    record: OrganisationTalentInvitationStatus,
    buttonRef: EmpButtonRef
  ) => {
    try {
      buttonRef.setButtonState("loading");
      const resp = await OnboardingApi.revokeInvitation({
        hasAccount: record.hasAccount,
        role: "talent",
        emailAddress: record.email,
        creatorId: record.hasAccount ? record.id : undefined,
      });
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Invitation Revoked",
          "The talent will not be able to join your Agency"
        );
        fetchTalentInvitationStatus();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to resend email");
    } finally {
      buttonRef.setButtonState("default");
    }
  };

  /**
   * Dismisses a creator.
   * @param {SmCreatorRespDto} record - The creator record to dismiss.
   * @returns {void}
   * @throws {EmpException} If the user is not loaded or if no organization is assigned.
   */
  const onDismissCreator = (record: SmCreatorRespDto) => {
    try {
      if (!dismissCreatorModalRef.current || !userContext?.organisation)
        throw new EmpException("User not loaded");
      if (userContext.organisation.length === 0)
        throw new EmpException("Not organisation assigned");
      const orgId = userContext.organisation[0].id;
      dismissCreatorModalRef.current.show(record, orgId);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Error dismissing creator");
    }
  };

  const prepareCreatorKebabMenuItems = (record: SmCreatorRespDto) => {
    const menuItems: MenuButtonSpec<SmCreatorRespDto>[] = [
      {
        label: intl.formatMessage({
          id: "agencyMyCreatorsView_creatorCardKebabMenuDismissOption",
        }),
        context: record,
        action: onDismissCreator,
      },
    ];
    return menuItems;
  };

  const renderPartnershipText = (partnershipType: string) => {
    if (partnershipType === "affiliate-invitation")
      return "Affiliate Partnership";
    else if (partnershipType === "exclusive-invitation")
      return "Exclusive Partnership";
    else return "Invalid";
  };

  return (
    <div className="emp-my-creator-view">
      <EmpLoader ref={empLoaderRef} />
      <DismissCreatorModal
        ref={dismissCreatorModalRef}
        onSave={() => {
          fetchTalentInvitationStatus();
          fetchTalents();
        }}
      />

      <InviteTalentsModal
        ref={inviteTalentModalRef}
        onSave={() => {
          fetchTalentInvitationStatus();
        }}
      />
      <div>
        <div className="my-creator-header-wrapper">
          <div className="right-elem">
            <p className="emp-paragraph">
              <FormattedMessage
                id={"agencyMyCreatorsView_desc"}
              />
            </p>
          </div>
          <EmpButton
            rightIcon={PlusIcon}
            buttonHeight="sm"
            isFullWidth={false}
            text={
              <FormattedMessage id="agencyMyCreatorsView_inviteCreatorBtn" />
            }
            onSubmit={() => inviteTalentModalRef.current?.show("new")}
          />
        </div>
        <div ref={resizeDivRef} className="talent-empty-state"></div>
        {!isLoading &&
          talentInvitation &&
          talentInvitation.length === 0 &&
          talents.length === 0 && (
            <div className="talent-empty-state">
              <div className="overlay">
                <div className="info-wrapper">
                  <img
                    className="empty-img"
                    alt="No talents found"
                    srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                  />
                  <h2 className="header">
                    <FormattedMessage id="agencyMyCreatorsView_emptyCreatorsHeader" />
                  </h2>
                  <p className="description">
                    <FormattedMessage id="agencyMyCreatorsView_emptyCreatorsDesc" />
                  </p>
                  <EmpButton
                    onSubmit={() => {
                      inviteTalentModalRef.current?.show("new");
                    }}
                    isFullWidth={false}
                    text={
                      <FormattedMessage id="agencyMyCreatorsView_inviteCreatorBtn" />
                    }
                  />
                </div>
              </div>
              {shimmerCard.map((elem, index) => {
                return (
                  <div
                    key={elem}
                    className={`talent-placeholder-card ${
                      (index + 1) % maxCards !== 0 && "mr-4"
                    } mt-6`}
                    style={{ width: cardWidth }}
                  >
                    <div className="details-wrapper ">
                      <div className="profile shimmer-color"></div>
                      <div className="account-details-wrapper ml-2">
                        <div className="name shimmer-color"></div>
                        <div className="email shimmer-color"></div>
                      </div>
                    </div>
                    <div className="metrics-wrapper mt-4">
                      <div className="metric pr-2">
                        <div className="title shimmer-color"></div>
                        <div className="value shimmer-color"></div>
                      </div>
                      <div className="metric pl-2">
                        <div className="title shimmer-color"></div>
                        <div className="value shimmer-color"></div>
                      </div>
                    </div>
                    <div className="social-media-wrapper mt-6">
                      <div className="icon shimmer-color"></div>
                      <div className="icon shimmer-color"></div>
                      <div className="icon shimmer-color"></div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        {!isLoading && talentInvitation && talentInvitation.length > 0 && (
          <div className="emp-card table-card no-h-padding mt-5">
            <div className="emp-card-padded-section">
              <h1 className="emp-card-header">
                <FormattedMessage id="agencyMyCreatorsView_invitationStatusCardHeader" />
              </h1>
              <p className="emp-card-description">
                <FormattedMessage id="agencyMyCreatorsView_invitationStatusCardDesc" />
              </p>
            </div>
            {talentInvitationTableProps && (
              <div className="talent-table">
                <EmpTable
                  contentColumns={talentInvitationContentSpec}
                  data={talentInvitationTableProps.data}
                  rowKey={talentInvitationTableProps.rowKey}
                />
              </div>
            )}
          </div>
        )}

        {/* Onboarded Talents Section  */}
        {!isLoading && talents && (
          <div className="onboarded-talents">
            {talents.map((elem, index) => {
              return (
                <div
                  onClick={(e: React.MouseEvent) => {
                    const clickedElement = e.target as Node;
                    const kebabBtnElem =
                      talentSettingsKebabBtnRefs.current[index];
                    if (
                      clickedElement === kebabBtnElem ||
                      kebabBtnElem.contains(clickedElement)
                    ) {
                      return;
                    }
                    navigate(`/agency/creator-details/${elem.id}`);
                  }}
                  key={elem.id}
                  className={`talent-card ${
                    (index + 1) % maxCards !== 0 && "mr-4"
                  } mt-6`}
                  style={{ width: cardWidth }}
                >
                  <div className="details-wrapper ">
                    {elem.imageType === "url" && (
                      <img
                        alt={elem.name}
                        className="profile-img"
                        src={elem.imageResource}
                      />
                    )}
                    {elem.imageType === "avatar" && (
                      <div
                        className="profile-avatar"
                        style={{ background: elem.imageResource }}
                      >
                        <span>{elem.initials}</span>
                      </div>
                    )}
                    <div className="account-details-wrapper ml-2">
                      <div className="name">{elem.name}</div>
                      <div className="email">{elem.email}</div>
                    </div>
                  </div>
                  <div className="metrics-wrapper mt-4">
                    <div className="metric-left pr-2">
                      <EmpContent
                        label={
                          <FormattedMessage id="agencyMyCreatorsView_creatorCardImpressionsLabel" />
                        }
                        value={elem.impressions.toString()}
                      />
                    </div>
                    <div className="metric-right pl-2">
                      <EmpContent
                        label={
                          <FormattedMessage id="agencyMyCreatorsView_creatorCardCountryLabel" />
                        }
                        value={
                          <div className="country-wrapper">
                            <img
                              className="img-flag"
                              alt="country"
                              src={COUNTRY_CONSTANTS[elem.country]["flag_1x1"]}
                            />
                            <span>{COUNTRY_CONSTANTS[elem.country].name}</span>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div className="metrics-wrapper mt-4">
                    <EmpContent
                      label="Partnership"
                      value={renderPartnershipText(elem.partnershipType)}
                    />
                  </div>
                  <div className="social-media-wrapper mt-3">
                    <div className="content-wrapper">
                      {elem.platforms.length > 0 && (
                        <>
                          {elem.platforms.includes(
                            SOCIAL_MEDIA_TYPE.FACEBOOK
                          ) && (
                            <FacebookIcon
                              size={15}
                              backgroundColor={Color.NEUTRAL[400]}
                            />
                          )}
                          {elem.platforms.includes(
                            SOCIAL_MEDIA_TYPE.INSTAGRAM
                          ) && (
                            <InstagramIcon
                              size={15}
                              backgroundColor={Color.NEUTRAL[400]}
                            />
                          )}
                          {elem.platforms.includes(
                            SOCIAL_MEDIA_TYPE.TIKTOK
                          ) && (
                            <TikTokIcon
                              size={15}
                              backgroundColor={Color.NEUTRAL[400]}
                            />
                          )}
                          {elem.platforms.includes(SOCIAL_MEDIA_TYPE.X) && (
                            <XIcon
                              size={15}
                              backgroundColor={Color.NEUTRAL[400]}
                            />
                          )}
                        </>
                      )}
                      {elem.platforms.length === 0 && (
                        <>
                          <span>
                            <FormattedMessage id="agencyMyCreatorsView_creatorCardNoSocialMediaAccountsLabel" />
                          </span>
                        </>
                      )}
                    </div>
                    <div
                      ref={(el: HTMLDivElement) =>
                        (talentSettingsKebabBtnRefs.current[index] = el)
                      }
                    >
                      <EmpKebabMenu
                        menuButtonSpecs={prepareCreatorKebabMenuItems(elem)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
