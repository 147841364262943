import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import PlatformReferralApi from "../../api/referral-and-support-msvc/platform-referral.api";
import ReferralCodeApi from "../../api/referral-and-support-msvc/referral-code.api";
import UserApi from "../../api/user-msvc/user.api";
import CopyIcon from "../../components/icon/copy-icon";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableProps,
} from "../../components/shared/EmpTable/EmpTable";
import { AppContext } from "../../context/app.context";
import EmpException from "../../exception/empException";
import { Color } from "../../utilities/colors";
import { DateUtil } from "../../utilities/date";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import ToastUtils from "../../utilities/toast-utils";
import "./referral-page.scss";

interface PlatformReferralRecord {
  id: string;
  subjectId: string;
  commissionRate: number;
  fullName: string;
  imageResource: string;
  initials: string;
  imageType: string;
  createdDate: string;
  type: string;
  role: string;
}

export const ReferralPage = () => {
  const { user: userContext } = useContext(AppContext);
  const location = useLocation();

  const intl = useIntl();
  const [form, setForm] = useState<IFormGroup>({
    link: new FormControl("text", []),
  });

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<PlatformReferralRecord>[] =
    useMemo(() => {
      const role = location.pathname.split("/")[1];

      const userTypeMapper = (type: string): string => {
        if (type === "creator")
          return intl.formatMessage({ id: "referralPage_creatorType" });
        else if (type === "brand")
          return intl.formatMessage({ id: "referralPage_brandType" });
        else if (type === "agency")
          return intl.formatMessage({ id: "referralPage_agencyType" });
        return "Unknown role";
      };

      return [
        {
          title: intl.formatMessage({
            id: "referralPage_organisationNameHeader",
          }),
          dataIndex: "organisationName",
          render: (record) => {
            let navTarget = "#";
            if (record.type === "user") {
              if (role === "agency")
                navTarget = `/agency/creator-details/${record.subjectId}`;
              else if (role === "brand")
                navTarget = `/brand/creator-details/${record.subjectId}`;
              else if (role === "creator") {
                navTarget = `/creator/creator-details/${record.subjectId}`;
              }
            }

            return (
              <div className="display-pic-wrapper">
                {record.imageType === "url" && (
                  <img
                    className="profile-pic"
                    src={record.imageResource}
                    alt={record.fullName}
                  />
                )}
                {record.imageType === "avatar" && (
                  <div
                    className="profile-avatar"
                    style={{ backgroundImage: record.imageResource }}
                  >
                    <span>{record.initials}</span>
                  </div>
                )}
                {record.imageType === "none" && (
                  <img
                    className="profile-pic"
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                    }
                    alt={record.fullName}
                  />
                )}
                {record.type === "user" && (
                  <EmpLink
                    text={
                      record.imageType === "none"
                        ? "Nameless Pigeon"
                        : record.fullName
                    }
                    href={navTarget}
                  />
                )}
                {record.type === "organisation" && (
                  <span>{record.fullName}</span>
                )}
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({ id: "referralPage_dateJoinedHeader" }),
          dataIndex: "createdDate",
        },
        {
          title: intl.formatMessage({ id: "referralPage_typeHeader" }),
          dataIndex: "type",
          render: (record) => {
            return (
              <EmpPill
                backgroundColor={Color.NEUTRAL[100]}
                color={Color.NEUTRAL[600]}
                text={userTypeMapper(record.role)}
              />
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "referralPage_commissionRateHeader",
          }),
          dataIndex: "commissionRate",
          render: (record) => {
            return <span>{`${record.commissionRate}%`}</span>;
          },
        },
        {
          title: intl.formatMessage({ id: "referralPage_actionHeader" }),
          dataIndex: "action",
          render: () => {
            return (
              <>
                {/* <EmpButton isFullWidth={false} buttonStyle="secondary" text={"View Earnings"} /> */}
              </>
            );
          },
        },
      ];
    }, [intl]);

  const [tableProps, setTableProps] =
    useState<EmpTableProps<PlatformReferralRecord>>();

  const getUser = useCallback(async () => {
    try {
      const userResp = userContext ?? (await UserApi.fetchUser()).data;
      let subjectId = userResp.id;
      if (userResp.role !== "talent") {
        if (userResp.organisation?.length === 0 || !userResp?.organisation)
          throw new EmpException("No organisation bound to user");
        subjectId = userResp.organisation[0].id;
      }
      getReferralCode(subjectId);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const getReferralCode = useCallback(async (organisationId: string) => {
    try {
      const resp = await ReferralCodeApi.fetchReferralCode(organisationId);
      setForm((f) => {
        f.link.forceUpdateValue(resp.data.referralLink);
        return { ...f };
      });
      const platformReferralResp =
        await PlatformReferralApi.fetchPlatformReferralDetails(
          resp.data.referralCode
        );
      const platformReferrals = platformReferralResp.data;

      const platformReferralRecords = platformReferrals.map((elem) => {
        return {
          id: elem.id,
          subjectId: elem.genericUser.id,
          fullName: elem.genericUser.fullName,
          imageResource: elem.genericUser.imageResource,
          initials: elem.genericUser.initials,
          imageType: elem.genericUser.imageType,
          createdDate: DateUtil.toReadableDateWithTime(
            elem.createdDate.toString()
          ),
          type: elem.type,
          role: elem.genericUser.role,
          commissionRate: elem.commissionRate,
        };
      });

      setTableProps({
        contentColumns: contentSpec,
        data: platformReferralRecords,
        rowKey: "id",
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch referral details"
      );
    }
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="emp-page-content no-padding referral-page">
        <div className="coming-soon-overlay">
          <div className="overlay-content-wrapper">
            <img
              alt="coming soon"
              className="construction-img"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/construction.png"
            />

            <h1>Referral Programs Coming Soon</h1>
            <p className="emp-paragraph mt-3">
              Your referral page is still undergoing some revamp! Do check out
              this space in the future for some exciting referral programs to
              help you earn through Emplifive!
            </p>
          </div>
        </div>
        <div className="header-wrapper mt-6">
          <h1>
            <FormattedMessage id="referralPage_header" />
          </h1>
        </div>

        <div className="content">
          <div className="referral-intro-card mt-4">
            <div className="grid-h one"></div>
            <div className="grid-h two"></div>
            <div className="grid-h three"></div>
            <div className="grid-v one"></div>
            <div className="grid-v two"></div>
            <div className="grid-v three"></div>
            <div className="image-wrapper">
              <img
                alt="referral"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/money.png"
              />
            </div>
            <h2 className="title-lbl">
              <FormattedMessage id="referralPage_cardHeader" />
            </h2>
            <p className="description-lbl mt-3">
              <FormattedMessage
                id="referralPage_cardDesc"
                values={{
                  cta: (
                    <EmpLink
                      text={<FormattedMessage id="cta_here" />}
                    ></EmpLink>
                  ),
                }}
              />
            </p>
            <div className="mt-6 form-wrapper">
              <EmpTextInput
                labelText={<FormattedMessage id="referralPage_referralLabel" />}
                compressedLabel
                disabled
                id={"link"}
                formControl={form.link}
              />
              <EmpIconButton
                onSubmit={() => {
                  ToastUtils.success(
                    intl.formatMessage({
                      id: "referralPage_referralCopiedToastHeader",
                    }),
                    intl.formatMessage({
                      id: "referralPage_referralCopiedToastDesc",
                    })
                  );
                  navigator.clipboard.writeText(form.link.getValue());
                }}
                buttonStyle="secondary"
                icon={
                  <CopyIcon backgroundColor={Color.NEUTRAL[500]} size={16} />
                }
              />
            </div>
          </div>

          <div className="emp-card no-padding mt-4 platform-referral-card">
            <div className="emp-card-header-section">
              <span className="emp-card-header block">
                <FormattedMessage id="referralPage_tableHeader" />
              </span>
              <span className="emp-card-description block">
                <FormattedMessage id="referralPage_tableDesc" />
              </span>
            </div>
            {tableProps && (
              <EmpTable
                contentColumns={contentSpec}
                data={tableProps.data}
                rowKey={tableProps.rowKey}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
