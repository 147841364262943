import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { AwValidateBeneficiaryErrDto } from "../../model/payment/kyc/aw-validate-beneficiary-err.dto";
import { KycRejectionRespDto } from "../../model/payment/kyc/kyc-rejection-resp.dto";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { SaveKycProgressDto } from "../../model/payment/kyc/save-kyc-progress.dto";
import { EmpInterceptor } from "../emp-interceptor";

const KycApi = {
  saveKycProgress: async (
    step: string,
    dto: SaveKycProgressDto
  ): Promise<ApiResponse<ApiStatus | AwValidateBeneficiaryErrDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.paymentMicroservice.kyc.saveKycProgress(step),
      dto
    );
    return response.data;
  },
  fetchKyc: async (): Promise<ApiResponse<KycDto | ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<KycDto | ApiStatus>>(
      ENDPOINTS.paymentMicroservice.kyc.base
    );
    return response.data;
  },
  downloadKyc: async (documentType: string, documentName: string) => {
    const downloadUrl =
      ENDPOINTS.paymentMicroservice.kyc.downloadKycAsUser(documentType);
    const axios = await EmpInterceptor.axiosClient();
    try {
      const response = await axios.get(downloadUrl, {
        responseType: "blob", // Set responseType to 'blob' to handle binary data
      });

      if (response.status === 200) {
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName);
        document.body.appendChild(link);

        link.click();

        // Clean up the object URL to free up resources
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the file:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while downloading the file:", error);
    }
  },
  submitKyc: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.paymentMicroservice.kyc.submitKyc
    );
    return response.data;
  },
  retractKyc: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.paymentMicroservice.kyc.retractKyc
    );
    return response.data;
  },
  getRejection: async (): Promise<
    ApiResponse<ApiStatus | KycRejectionRespDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<ApiStatus | KycRejectionRespDto>
    >(ENDPOINTS.paymentMicroservice.kyc.getKycRejection);
    return response.data;
  },
};
export default KycApi;
