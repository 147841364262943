import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { SmMetricsRefreshStatus } from "../../model/campaign/sm-metrics-refresh-status.dto";
import { EmpInterceptor } from "../emp-interceptor";

const XProofOfWorkApi = {
  refreshMetrics: async (
    taskId: string,
    role: "brand" | "seller"
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.xProofOfWork.refreshMetrics(taskId, role)
    );
    return response.data;
  },

  getStatus: async (
    taskId: string,
    role: "brand" | "seller"
  ): Promise<ApiResponse<SmMetricsRefreshStatus[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<SmMetricsRefreshStatus[]>>(
      ENDPOINTS.campaignMsvc.xProofOfWork.fetchStatus(taskId, role)
    );
    return response.data;
  },
};
export default XProofOfWorkApi;
