import { useEffect, useState } from "react";
import {
  DeliverableMessageWIdDto,
  MessageDateStamp,
  OngoingConversationMessagesType,
} from "../model/campaign/deliverable-message-w-id.dto";
import DOMPurify from "dompurify";
import moment from "moment";

/**
 * Custom hook to format and process ongoing messages.
 * This hook sanitizes message text, replaces newlines with HTML <br> tags,
 * and inserts date stamps for each new day.
 *
 * @param {DeliverableMessageWIdDto[]} messages - Array of messages to process.
 * @returns {OngoingConversationMessagesType[]} formattedMessages - The formatted and processed messages.
 */
const useOngoingMessages = (messages?: DeliverableMessageWIdDto[]) => {
  const [formattedMessages, setFormattedMessages] = useState<
    OngoingConversationMessagesType[]
  >([]);

  useEffect(() => {
    if (!messages || messages.length === 0) {
      setFormattedMessages([]);
      return;
    }

    const messageMap = new Map();
    for (let message of messages) {
      // Sanitize the message text
      const sanitizedMessage = DOMPurify.sanitize(message.text);
      const newMessage = {
        ...message,
        text: sanitizedMessage.replace(/\n/g, "<br>"),
        type: "message",
      };

      // Format the message date
      const messageDate = moment(newMessage.createdAt).format("YYYY-MM-DD");

      // Group messages by date
      if (!messageMap.has(messageDate)) {
        messageMap.set(messageDate, []);
      }
      messageMap.get(messageDate).push(newMessage);
    }

    const processedMessages = [];
    for (let [date, messages] of messageMap) {
      processedMessages.push(...messages);
      const formattedDate = moment(date).format("D MMM");
      const dateStamp = {
        id: `date-${date}`,
        type: "date",
        date: formattedDate,
      };
      processedMessages.push(dateStamp);
    }

    setFormattedMessages(processedMessages);
  }, [messages]);

  return { formattedMessages };
};
export default useOngoingMessages;
