export interface ApiStatus {
  statusMessage: string;
  status: "success" | "error";
}

export function isApiStatus(obj: any): obj is ApiStatus {
  return (
    typeof obj === "object" &&
    "statusMessage" in obj &&
    "status" in obj &&
    (obj.status === "success" || obj.status === "error")
  );
}
