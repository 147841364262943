import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PAGINATION } from "../../../constants/paginationConstants";
import { EmpQueryable } from "../../../model/common/tableFilters";
import { Color } from "../../../utilities/colors";
import ChevronLeftIcon from "../../icon/chevron-left";
import ChevronRightIcon from "../../icon/chevron-right";
import HorizontalDotsIcon from "../../icon/horizontal-dots-icon";
import "./EmpPagination.scss";

export interface EmpPaginationProps {
  defaultCurrent?: number;
  hideOnSinglePage?: boolean;
  currentPage?: number;
  totalRecord?: number;
  pageSize?: number;
}

interface Props {
  pagination?: EmpPaginationProps;
  onChange?: (queryable: EmpQueryable) => void;
  queryable: EmpQueryable;
  className?: string;
}

const EmpPagination = (props: Props) => {
  const { onChange, queryable, pagination } = props;
  const className = props.className ?? "";
  // Setting the default settings
  const defaultCurrent = pagination?.defaultCurrent ?? 1;
  const hideOnSinglePage = pagination?.hideOnSinglePage ?? true;
  const [currentPage, setCurrentPage] = useState(
    pagination?.currentPage ?? defaultCurrent
  );
  const totalRecord = pagination?.totalRecord ?? 1;
  const pageSize = pagination?.pageSize ?? PAGINATION.PAGE_SIZE;
  const visiblePages: number = 3;

  // Update current page, if a change in pagination prop is detected.
  useEffect(() => {
    setCurrentPage(pagination?.currentPage ?? defaultCurrent);
  }, [pagination, defaultCurrent]);

  /**
   * Calculates the maximum number of pages based on the total number of records and the page size
   *
   * @returns The maximum number of pages
   */
  const calculateMaxPages = (): number => {
    return Math.ceil(totalRecord / pageSize);
  };

  /**
   * Renders the pagination buttons in a specific format
   * @returns {JSX.Element} A React component that displays the pagination buttons
   */
  const renderPaginationBtns = () => {
    const maxPages = calculateMaxPages();
    const btnArray = computeNumButtons(currentPage, maxPages);
    console.log(btnArray)
    return (
      <>
        <button
          onClick={() => {
            pageBtnOnClick(currentPage - 1);
          }}
          disabled={currentPage === 1}
          className={`directional-btn ${currentPage === 1 ? "disabled" : ""}`}
        >
          <ChevronLeftIcon
            top={1}
            backgroundColor={
              currentPage === 1 ? Color.NEUTRAL[400] : Color.NEUTRAL[800]
            }
          />{" "}
          <FormattedMessage id="pagination_prev" />
        </button>

        {currentPage > visiblePages && maxPages !== (visiblePages + 1) && (
          <>
            <button
              onClick={() => {
                pageBtnOnClick(1);
              }}
              key={1}
              className={`pagination-btn ${currentPage === 1 ? "active" : ""}`}
            >
              1
            </button>
            <HorizontalDotsIcon backgroundColor={Color.NEUTRAL[400]} />
          </>
        )}
        {btnArray.map((elem) => {
          return (
            <button
              onClick={() => {
                pageBtnOnClick(elem);
              }}
              key={elem}
              className={`pagination-btn ${currentPage === elem ? "active" : ""}`}
            >
              {elem}
            </button>
          );
        })}
        {(maxPages - currentPage) >= visiblePages &&
          maxPages !== visiblePages + 1 && (
            <>
              <HorizontalDotsIcon backgroundColor={Color.NEUTRAL[400]} />
              <button
                onClick={() => {
                  pageBtnOnClick(maxPages);
                }}
                key={maxPages}
                className={`pagination-btn ${currentPage === maxPages ? "active" : ""}`}
              >
                {maxPages}
              </button>
            </>
          )}

        <button
          onClick={() => {
            pageBtnOnClick(currentPage + 1);
          }}
          disabled={currentPage === maxPages}
          className={`directional-btn ${currentPage === maxPages ? "disabled" : ""}`}
        >
          {" "}
          <FormattedMessage id="pagination_next" />{" "}
          <ChevronRightIcon
            top={1}
            backgroundColor={
              currentPage === maxPages ? Color.NEUTRAL[400] : Color.NEUTRAL[800]
            }
          />
        </button>
      </>
    );
  };

  /**
   * Computes the number of buttons to be displayed in a pagination component
   *
   * @param currentPage - The current page number
   * @param maxPage - The maximum number of pages
   *
   * @returns An array of numbers representing the buttons to be displayed in the pagination component
   */
  const computeNumButtons = (
    currentPage: number,
    maxPage: number
  ): number[] => {
    // Returns [1, ..., maxPage]
    if (maxPage <= visiblePages + 2) {
      return Array.from({ length: maxPage }, (_, i) => i + 1);
    }
    // Middle section
    else if (
      currentPage > visiblePages &&
      currentPage <= maxPage - visiblePages
    ) {
      // -1 is hardcoded, to make sure that current page is in the center
      return Array.from(
        { length: visiblePages },
        (_, i) => currentPage - 1 + i
      );
    } else if (currentPage <= visiblePages) {
      const length = Math.max(currentPage + 1, visiblePages);
      return Array.from({ length }, (_, i) => i + 1);
    } else if (currentPage > maxPage - visiblePages) {
      const length = Math.max(maxPage + 1 - currentPage, visiblePages + 1);
      return Array.from({ length }, (_, i) => maxPage + 1 - length + i);
    }
    return [];
  };

  /**
   * Handles the onClick event of a page button in a pagination component
   *
   * @param pageNo - The page number of the button being clicked
   */
  const pageBtnOnClick = (pageNo: number) => {
    setCurrentPage(pageNo);
    if (onChange) onChange({ ...queryable, pageNo, pageSize });
  };

  return (
    <>
      {(!hideOnSinglePage || calculateMaxPages() !== 1) && (
        <div className={`emp-pagination ${className}`}>
          {renderPaginationBtns()}
        </div>
      )}
    </>
  );
};
export default EmpPagination;
