import "./brand-post-preview-modal.scss";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

import FacebookIcon from "../../../components/icon/facebook-icon";
import { Color } from "../../../utilities/colors";
import ShareIcon from "../../../components/icon/share-icon";
import ThumbUpIcon from "../../../components/icon/thumb-up-icon";
import CommentIcon from "../../../components/icon/comment-icon";
import MessageWithPhoneIcon from "../../../components/icon/message-with-phone-icon";
import HeartIcon from "../../../components/icon/heart-icon";
import ThumbUpFillIcon from "../../../components/icon/thumb-up-icon copy";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import InstagramIcon from "../../../components/icon/instagram-icon";
import XIcon from "../../../components/icon/x-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import DotpointsIcon from "../../../components/icon/dotpoints-icon";
import EarthIcon from "../../../components/icon/earth-icon";
import BookMarkIcon from "../../../components/icon/bookmark-icon";
import InstagramShareIcon from "../../../components/icon/ins-share-icon";
import emptyMediaImg from "../../../assets/images/empty-image.png";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import AlertCircleIcon from "../../../components/icon/alert-circle";
import EmpDropdownBtn, {
  MenuButtonSpec,
} from "../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import { IconProps } from "../../../model/common/icon";
import AudioIcon from "../../../components/icon/audio-icon";
import TiktokCommentIcon from "../../../components/icon/tiktok-comment-icon";
import TiktokLikeIcon from "../../../components/icon/tiktok-like-icon";
import TiktokShareIcon from "../../../components/icon/tiktok-share-icon";
import BarChartIcon from "../../../components/icon/bar-chart-icon";
import RepeatIcon from "../../../components/icon/repost-icon";
import { SmPostType } from "../../../model/smm/smm-post.dto";
import VideoRecordOffIcon from "../../../components/icon/video-recorder-off-icon";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { Carousel } from "rsuite";

type PlatformIconProps = {
  icon: (props: IconProps) => JSX.Element;
  label: string;
  className: string;
};

const platformIcons: Record<string, PlatformIconProps> = {
  facebook: {
    icon: FacebookIcon,
    label: "Facebook",
    className: "facebook",
  },
  instagram: {
    icon: InstagramIcon,
    label: "Instagram",
    className: "instagram",
  },
  tiktok: {
    icon: TikTokIcon,
    label: "TikTok",
    className: "tiktok",
  },
  x: {
    icon: XIcon,
    label: "X",
    className: "x",
  },
};

type BrandPostPreviewModalProps = {
  captureSectionRef?: React.RefObject<HTMLDivElement>;
  videoFiles?: File[] | string[];
  imageFiles?: File[] | string[];
  fileUploadedType?: string;
  postType: SmPostType;
  selectedConnects: SmConnectionBriefRespDto[];
  currentSelectedConnect?: SmConnectionBriefRespDto;
  description: string;
  pollOptions?: number;
  generalForm?: IFormGroup;
  multiForm?: IFormGroup[];
  isMultiEdit?: boolean;
  socialPostType?: string;

  // for X
  threadForm?: IFormGroup[];
  threadVideo?: Record<string, File[][] | string[][]>;
  threadImage?: Record<string, File[][] | string[][]>;
};

const BrandPostPreviewModal: React.FC<BrandPostPreviewModalProps> = ({
  captureSectionRef,
  videoFiles,
  imageFiles,
  fileUploadedType,
  postType,
  selectedConnects,
  currentSelectedConnect,
  description,
  pollOptions,
  generalForm,
  multiForm,
  isMultiEdit,
  socialPostType,

  // for X
  threadForm,
  threadVideo,
  threadImage,
}) => {
  const [previewIndex, setPreviewIndex] = useState(0);

  const handleChangePreviewIndex = (index: number) => {
    setPreviewIndex(index);
  };

  const socialMediaOptions = useMemo(() => {
    return selectedConnects.map((connection, index) => ({
      action: () => handleChangePreviewIndex(index),
      context: connection.platform,
      label: connection.platform,
    }));
  }, [selectedConnects]);

  const currentPreview = useMemo(() => {
    return selectedConnects[previewIndex] || selectedConnects[0];
  }, [previewIndex, selectedConnects]);

  const renderDropdownItems = (): MenuButtonSpec<any>[] => {
    return socialMediaOptions.map((option, index) => {
      const connect = selectedConnects[index];
      return {
        label: connect?.platform,
        action: () => option.action(),
        context: connect?.platform,
        element: (
          <div
            className="profile-wrapper"
            key={index}
            onClick={() => option.action()}
          >
            <div className={`picture-section`}>
              <img
                src={connect?.pictureUrl || EMPTY_PROFILE_IMG_URL}
                alt={`${connect?.name} ${connect?.platform} `}
              />
              <div
                className={`social-media-bubble ${connect?.platform.toLowerCase()}`}
              >
                {connect?.platform.toLowerCase() === "facebook" && (
                  <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "instagram" && (
                  <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "tiktok" && (
                  <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "x" && (
                  <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
              </div>
            </div>
            <div className="info-section">
              <span className="handle-name-lbl">{connect?.name}</span>
              <span className="metric-lbl">- {connect?.platform}</span>
            </div>
          </div>
        ),
      };
    });
  };

  const renderWarning = (platform: string) => {
    if (postType === "poll") return <></>;
    const warningText: Record<string, any> = {
      facebook: {
        general:
          "For Facebook posts, we recommend using a resolution of 1200x630 pixels for images (max 4MB) and 1280x720 pixels for videos (max 1GB,3s-20min).",
        reel: "We recommend using a 9:16 aspect ratio and 1920x1080 pixels resolution (minimum 540 x 960) for Facebook Reels.",
        story:
          "We recommend using a 9:16 aspect ratio and 1920x1080 pixels resolution (minimum 540 x 960) for Facebook Stories.",
      },
      instagram: {
        general:
          "For Instagram posts, we recommend using a resolution of 1080x1080 pixels for images (max 8MB) and 1920x1080 pixels for videos (max 4GB, 3s-60min).",
        reel: "We recommend using a 9:16 aspect ratio (Min: 0.01:1, Max: 10:1) and a 1920x1080 pixels resolution (minimum 540x960) for Instagram Reels (max 1GB, 3s-10min).",

        story:
          "We recommend using a 9:16 aspect ratio and a 1920x1080 pixels resolution for Instagram Stories (max 100MB, 3s-60s).",
      },
      tiktok: {
        general:
          "For TikTok posts, we recommend using a resolution of 360x360 to 4096x4096 pixels for videos (max 4GB, 3s-10min) and up to 1080x1080 pixels for images (max 20MB).",
        video:
          "For TikTok videos, we recommend using a resolution of 360x360 to 4096x4096 pixels (max 4GB, 3s-10min).",
        image:
          "For TikTok images, we recommend using a resolution of up to 1080x1080 pixels (max 20MB).",
      },
      x: {
        // general:
        //   "For X posts, use 32x32 to 1280x1024 pixels for videos (max 512MB, 0.5s-140s, 5000 Kbps, 128 Kbps audio), up to 1080x1080 pixels for images (max 5MB), and 1280 pixels wide for GIFs (max 15MB).",

        general:
          "For X posts, use 32x32 to 1280x1024 pixels for videos (max 512MB, 0.5s-140s, 5000 Kbps, 128 Kbps audio), up to 1080x1080 pixels for images (max 5MB)",
        video: {
          title: "For Videos",
          text: "Use 32x32 to 1280x1024 pixels (max 512MB, 0.5s-140s, 5000 Kbps, 128 Kbps audio).",
        },
        image: {
          title: "For Images",
          text: "Use up to 1080x1080 pixels (max 5MB).",
        },
        // gif: { title: "For GIFs", text: "Use 1280 pixels wide (max 15MB)." },
      },
    };

    const platformWarning = warningText[platform];
    if (!platformWarning) return <></>;
    const renderXWarnings = () => {
      const xFileTypes = ["video", "image"];
      return xFileTypes.map((type, index) => (
        <div className="preview-warning-wrapper">
          <div key={index}>
            <div className="warning-icon">
              <AlertCircleIcon backgroundColor={Color.YELLOW[700]} size={20} />
            </div>
            <div className="warning-text">
              <span>{platformWarning[type].title}</span>
              <p>{platformWarning[type].text}</p>
            </div>
          </div>
        </div>
      ));
    };

    if (platform === "x") {
      return <>{renderXWarnings()}</>;
    }

    const specificWarnings: Record<string, any> = {
      reel: platformWarning.reel,
      story: platformWarning.story,
      "tiktok-video": platformWarning.video,
      "tiktok-image": platformWarning.image,
      general: platformWarning.general,
    };

    const specificWarning =
      specificWarnings[postType] || platformWarning.general;

    if (specificWarning) {
      return (
        <div className="preview-warning-wrapper">
          <div>
            <div className="warning-icon">
              <AlertCircleIcon backgroundColor={Color.YELLOW[700]} size={20} />
            </div>
            <div className="warning-text">
              <p>{specificWarning}</p>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (selectedConnects.length === 1) {
      setPreviewIndex(0);
    }
  }, [selectedConnects]);

  useEffect(() => {
    if (currentSelectedConnect) {
      const index = selectedConnects.findIndex(
        (connect) =>
          connect.socialMediaUserId === currentSelectedConnect.socialMediaUserId
      );
      if (index !== -1) {
        setPreviewIndex(index);
      }
    }
  }, [currentSelectedConnect, selectedConnects, selectedConnects.length]);
  return (
    <div className="brand-post-preview-modal">
      <h3 className="post-header">
        <div>Post preview</div>
      </h3>
      <div
        className={
          "platform-dropdown-wrapper " +
          currentPreview?.platform.toLocaleLowerCase()
        }
      >
        <EmpDropdownBtn
          text={
            platformIcons[currentPreview?.platform.toLocaleLowerCase()]?.label
          }
          buttonStyle="outline-secondary"
          menuButtonSpecs={socialMediaOptions}
          leftIcon={
            platformIcons[
              socialMediaOptions[previewIndex]?.context.toLocaleLowerCase()
            ]?.icon
          }
          rightIcon={ChevronDownIcon}
          dropdownElement={renderDropdownItems()}
          isDisabled={isMultiEdit}
        />
      </div>
      <div className="mocking-post-content-wrapper" ref={captureSectionRef}>
        <PreviewMedia
          previewIndex={previewIndex}
          selectedConnects={selectedConnects}
          postType={postType}
          description={description}
          videoFiles={videoFiles}
          imageFiles={imageFiles}
          fileUploadedType={fileUploadedType}
          pollOptions={pollOptions}
          generalForm={generalForm}
          multiForm={multiForm}
          isMultiEdit={isMultiEdit}
          socialPostType={socialPostType}
          threadForm={threadForm}
          threadVideo={threadVideo}
          threadImage={threadImage}
        />
      </div>
      {renderWarning(currentPreview.platform.toLocaleLowerCase())}
    </div>
  );
};

export default BrandPostPreviewModal;

type PreviewMediaProps = {
  previewIndex: number;
  videoFiles?: File[] | string[];
  imageFiles?: File[] | string[];
  fileUploadedType?: string;
  postType: SmPostType;
  selectedConnects: SmConnectionBriefRespDto[];
  currentSelectedConnect?: SmConnectionBriefRespDto;
  description: string;
  pollOptions?: number;
  generalForm?: IFormGroup;
  multiForm?: IFormGroup[];
  isMultiEdit?: boolean;
  socialPostType?: string;

  // for X
  threadForm?: IFormGroup[];
  threadVideo?: Record<string, File[][] | string[][]>;
  threadImage?: Record<string, File[][] | string[][]>;
};

const PreviewMedia: React.FC<PreviewMediaProps> = ({
  previewIndex,
  description,
  fileUploadedType,
  imageFiles,
  postType,
  selectedConnects,
  videoFiles,
  pollOptions,
  generalForm,
  multiForm,
  isMultiEdit,
  socialPostType,

  // for X
  threadForm,
  threadVideo,
  threadImage,
}) => {
  const currentPreview = useMemo(() => {
    return selectedConnects[previewIndex] || selectedConnects[0];
  }, [previewIndex, selectedConnects]);

  // render the file preview
  const FilePreview = ({
    files,
    fileType,
    totalFiles,
  }: {
    files: File[] | string[];
    fileType: "video" | "image";
    totalFiles: number;
  }) => {
    if (!files || files.length === 0) return null;

    // check if already 5 files are uploaded
    const excessImageFiles =
      fileType === "image" && totalFiles - files.length > 5;
    if (excessImageFiles) return <></>;

    const indexLastFile = () => {
      const maxFilesIndex = 4;

      // Direct return for cases where totalFiles matches files.length or when having more than 5 video files
      if (
        totalFiles === files.length ||
        (fileType === "video" && files.length > 5)
      ) {
        return maxFilesIndex;
      }

      // For images, adjust index based on the number of videos not included in the files array
      if (fileType === "image") {
        return Math.max(maxFilesIndex - (totalFiles - files.length), 0); // Prevent negative index
      }

      // Default case for videos with less than or equal to 5 files or any other fileType not covered above
      return Math.min(files.length - 1, maxFilesIndex); // Ensures index is within bounds
    };

    const renderOverlayText = () => {
      // Check if there's a need to display the overlay text at all
      const shouldDisplayOverlay =
        totalFiles > 5 &&
        ((fileType === "video" && files.length >= 5) || fileType === "image");

      if (!shouldDisplayOverlay) return null;

      // Calculate the overlay number based on the condition
      const overlayNumber =
        fileType === "video"
          ? Math.max(totalFiles, files.length) - 5
          : totalFiles - 5;

      // Render the overlay text with the calculated number
      return (
        <div className="overlay-text">
          <p>+{overlayNumber}</p>
        </div>
      );
    };

    if (typeof files[0] === "string") {
      const renderFile = (file: string, index: number) => {
        if (index <= indexLastFile()) {
          return (
            <div className="file-preview" key={index}>
              {fileType === "video" ? (
                <video width="100%" height="100%" controls>
                  <source src={file} type="video/mp4" />
                </video>
              ) : (
                <img src={file} alt={`file-${index}`} />
              )}
              {/* {index === indexLastFile() && renderOverlayText()} */}
            </div>
          );
        }
        return;
      };

      return (
        <>{files.map((file, index) => renderFile(file as string, index))}</>
      );
    }
    const renderFile = (file: File, index: number) => {
      if (index <= indexLastFile()) {
        return (
          <div className="file-preview" key={index}>
            {fileType === "video" ? (
              <video width="100%" height="100%" controls>
                <source src={URL.createObjectURL(file)} type="video/mp4" />
              </video>
            ) : (
              <img src={URL.createObjectURL(file)} alt={`file-${index}`} />
            )}
            {index === indexLastFile() && renderOverlayText()}
          </div>
        );
      }
      return;
    };
    return <>{files.map((file, index) => renderFile(file as File, index))}</>;
  };

  const EmptyImagePreview = () => (
    <div className="file-preview empty-image">
      <img src={emptyMediaImg} alt="EMPTY_IMG" />
    </div>
  );

  const MediaComponent: React.FC = useCallback(() => {
    const platformType = currentPreview?.platform.toLowerCase();
    const defaultPostFile = () => {
      const totalVideoFiles = videoFiles ? videoFiles.length : 0;
      const totalImageFiles = imageFiles ? imageFiles.length : 0;
      const totalFiles = totalVideoFiles + totalImageFiles;
      const displayLimit = 5;

      let layoutClass = "";

      // Determine layout class based on total files
      const layoutClasses = [
        "single-item",
        "two-items",
        "three-items",
        "four-items",
        "five-items",
        "many-items",
      ];
      layoutClass =
        totalFiles > displayLimit
          ? "many-items"
          : layoutClasses[totalFiles - 1] || "";

      const filesToRender = [];

      // Handle Instagram empty state
      if (
        totalFiles === 0 &&
        currentPreview.platform.toLowerCase() === "instagram"
      ) {
        filesToRender.push(<EmptyImagePreview />);
      } else {
        // Add video files preview
        if (totalVideoFiles && videoFiles) {
          filesToRender.push(
            <FilePreview
              files={videoFiles}
              fileType="video"
              totalFiles={totalFiles}
            />
          );
        }

        // // Add image files preview with condition
        if (
          totalImageFiles &&
          !(totalVideoFiles > displayLimit) &&
          imageFiles
        ) {
          filesToRender.push(
            <FilePreview
              files={imageFiles}
              fileType="image"
              totalFiles={totalFiles}
            />
          );
        }
      }

      return filesToRender.length > 0 ? (
        <div
          className={`file-preview-wrapper ${layoutClass} ${currentPreview.platform.toLowerCase()}`}
        >
          {filesToRender}
        </div>
      ) : null;
    };

    const emptyContent = (
      <div className="preview-modal-empty-content">
        <VideoRecordOffIcon backgroundColor={Color.NEUTRAL[600]} size={100} />
        <span>
          <FormattedMessage id="brandSocialMedia_emptyMediaMessage" />
        </span>
      </div>
    );

    const renderFacebookReelsFile = () => {
      if (currentPreview.platform.toLowerCase() !== "facebook") return null;
      const isString =
        typeof videoFiles?.[0] === "string" ||
        typeof imageFiles?.[0] === "string";
      const file = imageFiles?.[0] || videoFiles?.[0];

      if (!file) return emptyContent;

      const fileUrl = isString
        ? (file as string)
        : URL.createObjectURL(file as File);
      if (fileUploadedType === "video") {
        return (
          <video width="100%" height="100%" autoPlay loop>
            <source src={fileUrl} type="video/mp4" />
          </video>
        );
      }
      return <img src={fileUrl} alt="Story Content" />;
    };

    const renderFacebookStoryFile = () => {
      if (currentPreview.platform.toLowerCase() !== "facebook") return null;
      const isString =
        typeof videoFiles?.[0] === "string" ||
        typeof imageFiles?.[0] === "string";
      const file = imageFiles?.[0] || videoFiles?.[0];

      if (!file) return emptyContent;

      const fileUrl = isString
        ? (file as string)
        : URL.createObjectURL(file as File);
      if (fileUploadedType === "video") {
        return (
          <video width="100%" height="100%" autoPlay loop>
            <source src={fileUrl} type="video/mp4" />
          </video>
        );
      }
      return <img src={fileUrl} alt="Story Content" />;
    };
    const renderInstagramStoryFile = () => {
      if (currentPreview.platform.toLowerCase() !== "instagram") return null;

      const isString =
        typeof videoFiles?.[0] === "string" ||
        typeof imageFiles?.[0] === "string";
      const file = imageFiles?.[0] || videoFiles?.[0];

      if (!file) return emptyContent;

      const fileUrl = isString
        ? (file as string)
        : URL.createObjectURL(file as File);
      if (fileUploadedType === "video") {
        return (
          <video width="100%" height="100%" autoPlay loop>
            <source src={fileUrl} type="video/mp4" />
          </video>
        );
      }
      return <img src={fileUrl} alt="Story Content" />;
    };
    const renderInstagramReelsFile = () => {
      if (currentPreview.platform.toLowerCase() !== "instagram") return null;

      const isString =
        typeof videoFiles?.[0] === "string" ||
        typeof imageFiles?.[0] === "string";
      const file = imageFiles?.[0] || videoFiles?.[0];

      if (!file) return emptyContent;

      const fileUrl = isString
        ? (file as string)
        : URL.createObjectURL(file as File);

      if (fileUploadedType === "video") {
        return (
          <video width="100%" height="100%" autoPlay loop>
            <source src={fileUrl} type="video/mp4" />
          </video>
        );
      }
      return emptyContent;
    };

    const renderTiktokFile = () => {
      if (currentPreview.platform.toLowerCase() !== "tiktok") return null;

      const isString =
        typeof videoFiles?.[0] === "string" ||
        typeof imageFiles?.[0] === "string";
      const file = videoFiles?.[0] || imageFiles?.[0];
      const isVideo = videoFiles?.[0] ? true : false;
      if (!file) return emptyContent;

      const fileUrl = isString
        ? (file as string)
        : URL.createObjectURL(file as File);

      if (isVideo) {
        return (
          <video width="100%" height="100%" autoPlay loop>
            <source src={fileUrl} type="video/mp4" />
          </video>
        );
      }

      const imageUrl = isString
        ? (imageFiles as string[])
        : (imageFiles as File[]).map((f) => URL.createObjectURL(f as File));
      return (
        <Carousel autoplay className="custom-slider">
          {imageUrl.map((image, index) => (
            <img src={image} key={index} alt="Tiktok Content" />
          ))}
        </Carousel>
      );
    };

    const renderTweetFile = () => {
      if (currentPreview.platform.toLowerCase() !== "x") return null;
      let formattedVideoFiles;
      let formattedImageFiles;
      if (threadForm) {
        formattedVideoFiles = threadVideo
          ? threadVideo?.[currentPreview.socialMediaUserId][0]
          : [];
        formattedImageFiles = threadImage
          ? threadImage[currentPreview.socialMediaUserId][0]
          : [];
      } else {
        formattedVideoFiles = videoFiles;
        formattedImageFiles = imageFiles;
      }

      const totalVideoFiles = formattedVideoFiles
        ? formattedVideoFiles.length
        : 0;
      const totalImageFiles = formattedImageFiles
        ? formattedImageFiles.length
        : 0;
      const totalFiles = totalVideoFiles + totalImageFiles;
      const displayLimit = 5;

      let layoutClass = "";

      // Determine layout class based on total files
      const layoutClasses = [
        "single-item",
        "two-items",
        "three-items",
        "four-items",
        "five-items",
        "many-items",
      ];
      layoutClass =
        totalFiles > displayLimit
          ? "many-items"
          : layoutClasses[totalFiles - 1] || "";

      const filesToRender = [];

      // Handle Instagram empty state
      if (
        totalFiles === 0 &&
        currentPreview.platform.toLowerCase() === "instagram"
      ) {
        filesToRender.push(<EmptyImagePreview />);
      } else {
        // Add video files preview
        if (totalVideoFiles && formattedVideoFiles) {
          filesToRender.push(
            <FilePreview
              files={formattedVideoFiles}
              fileType="video"
              totalFiles={totalFiles}
            />
          );
        }

        // // Add image files preview with condition
        if (
          totalImageFiles &&
          !(totalVideoFiles > displayLimit) &&
          formattedImageFiles
        ) {
          filesToRender.push(
            <FilePreview
              files={formattedImageFiles}
              fileType="image"
              totalFiles={totalFiles}
            />
          );
        }
      }

      return filesToRender.length > 0 ? (
        <div
          className={`file-preview-wrapper ${layoutClass} ${currentPreview.platform.toLowerCase()}`}
        >
          {filesToRender}
        </div>
      ) : null;
    };

    const fileElement: { [key: string]: any } = {
      facebook: {
        post: defaultPostFile(),
        story: renderFacebookStoryFile(),
        reel: renderFacebookReelsFile(),
        "tiktok-video": renderFacebookReelsFile(),
        "tiktok-image": defaultPostFile(),
        tweet: defaultPostFile(),
      },
      instagram: {
        post: defaultPostFile(),
        story: renderInstagramStoryFile(),
        reel: renderInstagramReelsFile(),
        "tiktok-video": renderInstagramReelsFile(),
        "tiktok-image": defaultPostFile(),
        tweet: defaultPostFile(),
      },
      tiktok: {
        "tiktok-video": renderTiktokFile(),
        "tiktok-image": renderTiktokFile(),
        tweet: renderTiktokFile(),
        post: renderTiktokFile(),
        story: renderTiktokFile(),
        reel: renderTiktokFile(),
      },
      x: {
        post: renderTweetFile(),
        story: renderTweetFile(),
        reel: renderTweetFile(),
        tweet: renderTweetFile(),
        poll: <></>,
      },
    };
    return fileElement[platformType][postType] || null;
  }, [
    currentPreview,
    fileUploadedType,
    imageFiles,
    postType,
    threadImage,
    threadVideo,
    videoFiles,
  ]);

  const renderContent = useCallback(() => {
    const platformType = currentPreview.platform;
    switch (platformType.toLowerCase()) {
      case "facebook":
        if (postType === "reel") {
          return (
            <div className="post-content-wrapper preview-facebook facebook-reels">
              <div className="top-content">
                <div className="user-info">
                  <div className="profile-picture">
                    <img
                      src={
                        selectedConnects[previewIndex]?.pictureUrl ||
                        EMPTY_PROFILE_IMG_URL
                      }
                      alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    />
                  </div>
                  <div className="profile-info">
                    <div className="profile-name">
                      {selectedConnects[previewIndex]?.name}
                    </div>
                    <div className="post-time">
                      <EarthIcon size={12} backgroundColor={Color.NEUTRAL[0]} />{" "}
                      Public
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-content">
                <div
                  className={`post-content ${
                    description.length === 0 ? "empty-description" : ""
                  }`}
                >
                  {description || "Your caption here..."}
                  <br />
                </div>
                <div className="audio-wrapper">
                  <AudioIcon size={12} backgroundColor={Color.NEUTRAL[0]} />
                  <div className="audio-name-wrapper">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div className="audio-name" key={index}>
                        <span>
                          {selectedConnects[previewIndex]?.name} · Original
                          audio
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="reels-content">
                <MediaComponent />
              </div>
            </div>
          );
        } else if (postType === "story") {
          return (
            <div className="post-content-wrapper preview-instagram instagram-story">
              <div className="top-content">
                <div className="progress-bars">
                  <div className="progress-bar" />
                </div>
                <div className="user-info">
                  <div className="profile-picture">
                    <img
                      src={
                        selectedConnects[previewIndex]?.pictureUrl ||
                        EMPTY_PROFILE_IMG_URL
                      }
                      alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    />
                  </div>
                  <div className="username">
                    {selectedConnects[previewIndex]?.name} - <span>13 h</span>
                  </div>
                </div>
              </div>
              <div className="story-content">
                <MediaComponent />
              </div>
            </div>
          );
        }
        return (
          <div className="post-content-wrapper preview-facebook">
            <div className="post-author-wrapper">
              <img
                src={
                  selectedConnects[previewIndex]?.pictureUrl ||
                  EMPTY_PROFILE_IMG_URL
                }
                alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                className="profile-pic"
              />
              <div className="profile-info">
                <div className="profile-name">
                  {selectedConnects[previewIndex]?.name}
                </div>
                <div className="post-time">
                  <p>13 h</p>
                  <DotpointsIcon
                    size={2}
                    backgroundColor={Color.NEUTRAL[900]}
                  />
                  <EarthIcon size={8} backgroundColor={Color.NEUTRAL[700]} />
                </div>
              </div>
            </div>
            <div
              className={`post-content ${
                description.length === 0 ? "empty-description" : ""
              }`}
            >
              {description || "Your caption here..."}
              <br />
              <MediaComponent />
            </div>
            <div className="post-interactions">
              <div className="reaction-stats">
                <div className="like-icon">
                  <ThumbUpFillIcon
                    backgroundColor={Color.NEUTRAL[0]}
                    size={10}
                  />
                </div>
                <div className="heart-icon">
                  <HeartIcon backgroundColor={Color.NEUTRAL[0]} size={10} />
                </div>
                221k
              </div>
              <div>
                <span className="comments">42.5k comments</span>
                <span className="shares">8.6k shares</span>
              </div>
            </div>
            <div className="post-actions">
              <button className="action-button like">
                <ThumbUpIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                Like
              </button>
              <button className="action-button comment">
                <CommentIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                Comment
              </button>
              <button className="action-button send">
                <MessageWithPhoneIcon
                  backgroundColor={Color.NEUTRAL[700]}
                  size={16}
                />
                Send
              </button>
              <button className="action-button share">
                <ShareIcon backgroundColor={Color.NEUTRAL[700]} size={16} />
                Share
              </button>
            </div>
          </div>
        );
      case "instagram":
        if (postType === "story") {
          return (
            <div className="post-content-wrapper preview-instagram instagram-story">
              <div className="top-content">
                <div className="progress-bars">
                  <div className="progress-bar" />
                </div>
                <div className="user-info">
                  <div className="profile-picture">
                    <img
                      src={
                        selectedConnects[previewIndex]?.pictureUrl ||
                        EMPTY_PROFILE_IMG_URL
                      }
                      alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    />
                  </div>
                  <div className="username">
                    {selectedConnects[previewIndex]?.name} - <span>13 h</span>
                  </div>
                </div>
              </div>
              <div className="story-content">
                <MediaComponent />
              </div>
            </div>
          );
        }
        if (postType === "reel") {
          return (
            <div className="post-content-wrapper preview-instagram instagram-reels">
              <div className="bottom-content">
                <div className="user-info">
                  <div className="profile-picture">
                    <img
                      src={
                        selectedConnects[previewIndex]?.pictureUrl ||
                        EMPTY_PROFILE_IMG_URL
                      }
                      alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    />
                  </div>
                  <div className="username">
                    {selectedConnects[previewIndex]?.name} - <span>13 h</span>
                  </div>
                </div>
              </div>
              <div className="reels-content">
                <MediaComponent />
              </div>
            </div>
          );
        }
        return (
          <div className="post-content-wrapper preview-instagram">
            <div className="post-author-wrapper">
              <img
                src={
                  selectedConnects[previewIndex]?.pictureUrl ||
                  EMPTY_PROFILE_IMG_URL
                }
                alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                className="profile-pic"
              />
              <div className="profile-info">
                <div className="profile-name">
                  {selectedConnects[previewIndex]?.name}
                </div>
                <div className="post-time">
                  @{selectedConnects[previewIndex]?.name}
                </div>
              </div>
            </div>
            <div className="post-content">
              <MediaComponent />
            </div>
            <div className="post-interactions">
              <div className="reaction-stats">
                <HeartIcon backgroundColor={Color.NEUTRAL[800]} size={16} />
                <CommentIcon backgroundColor={Color.NEUTRAL[800]} size={16} />
                <InstagramShareIcon
                  backgroundColor={Color.NEUTRAL[700]}
                  size={16}
                />
              </div>
              <div>
                <BookMarkIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
              </div>
            </div>
            <div className="instagram-post-like">123,341 likes</div>
            <div
              className={`instagram-post-description ${
                description.length === 0 ? "empty-description" : ""
              }`}
            >
              <span>{selectedConnects[previewIndex]?.name}</span>{" "}
              {description || "Your caption here..."}
            </div>
          </div>
        );
      case "tiktok":
        return (
          <div className="post-content-wrapper preview-tiktok">
            <div className="bottom-content">
              <div className="left-content text-wrap">
                <div className="user-info">
                  <div className="username">
                    {selectedConnects[previewIndex]?.name}
                  </div>
                </div>
                <div
                  className={`post-content ${
                    description.length === 0 ? "empty-description" : ""
                  }`}
                >
                  {description || "Your caption here..."}
                </div>
                <div className="audio-wrapper">
                  <AudioIcon size={12} backgroundColor={Color.NEUTRAL[0]} />
                  <div className="audio-name-wrapper">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div className="audio-name" key={index}>
                        <span>
                          {selectedConnects[previewIndex]?.name} · Original
                          audio
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="right-content">
                <div className="user-wrapper">
                  <div className="profile-picture">
                    <img
                      src={
                        selectedConnects[previewIndex]?.pictureUrl ||
                        EMPTY_PROFILE_IMG_URL
                      }
                      alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    />
                  </div>
                </div>
                <div className="tiktok-icon-wrapper">
                  <TiktokLikeIcon
                    size={32}
                    backgroundColor={Color.NEUTRAL[0]}
                  />
                  <span>10k</span>
                </div>
                <div className="tiktok-icon-wrapper">
                  <TiktokCommentIcon
                    size={32}
                    backgroundColor={Color.NEUTRAL[0]}
                  />
                  <span>555</span>
                </div>
                <div className="tiktok-icon-wrapper">
                  <TiktokShareIcon
                    size={32}
                    backgroundColor={Color.NEUTRAL[0]}
                  />
                  <span>555</span>
                </div>
              </div>
            </div>
            <div className="reels-content">
              <MediaComponent />
            </div>
          </div>
        );
      default:
        if (postType === "poll") {
          if (!generalForm) return <></>;

          const getMultiForm = () => {
            if (!multiForm) return;
            const currentId = multiForm.find(
              (form) =>
                form.socialMediaUserId.getValue() ===
                currentPreview.socialMediaUserId
            );
            return currentId;
          };

          const pollFormControlMap: Record<number, FormControl> = {
            1: isMultiEdit
              ? (getMultiForm()?.pollOption as FormControl)
              : generalForm.pollOption,
            2: isMultiEdit
              ? (getMultiForm()?.pollOption2 as FormControl)
              : generalForm.pollOption2,
            3: isMultiEdit
              ? (getMultiForm()?.pollOption3 as FormControl)
              : generalForm.pollOption3,
            4: isMultiEdit
              ? (getMultiForm()?.pollOption4 as FormControl)
              : generalForm.pollOption4,
          };

          const renderPollOptions = () => {
            let percentages: number[];
            switch (pollOptions) {
              case 3:
                percentages = [60, 30, 10];
                break;
              case 4:
                percentages = [40, 20, 30, 10];
                break;
              default:
                percentages = [70, 30];
                break;
            }

            return (
              <ul className="poll-options">
                {Array.from({ length: pollOptions || 0 }, (_, index) => (
                  <li className={`poll-option `} key={index}>
                    <div
                      className="color-bar"
                      style={{ width: `${percentages[index]}%` }}
                    />
                    <div className="option-text">
                      {pollFormControlMap[index + 1]?.getValue() || "Option"}
                    </div>
                    <div className="option-percentage">
                      {percentages[index]}%
                    </div>
                  </li>
                ))}
              </ul>
            );
          };

          return (
            <div className="post-content-wrapper preview-x">
              <div className="post-author-wrapper">
                <img
                  src={
                    selectedConnects[previewIndex]?.pictureUrl ||
                    EMPTY_PROFILE_IMG_URL
                  }
                  alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                  className="profile-pic"
                />
              </div>
              <div className="x-post-main-content">
                <div className="profile-info">
                  <div className="profile-name">
                    {selectedConnects[previewIndex]?.name}
                  </div>
                  <div className="post-time">
                    <DotpointsIcon
                      size={2}
                      backgroundColor={Color.NEUTRAL[900]}
                    />
                    <p>13 h</p>
                  </div>
                </div>
                <div
                  className={`post-content ${
                    description.length === 0 ? "empty-description" : ""
                  }`}
                >
                  {description || "Your caption here..."}
                </div>
                {renderPollOptions()}
                <div className="post-actions">
                  <div className="left-content">
                    <button className="action-button repost">
                      <RepeatIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      30k
                    </button>
                    <button className="action-button comment">
                      <CommentIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      10K
                    </button>
                    <button className="action-button like">
                      <HeartIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      50K
                    </button>
                    <button className="action-button view">
                      <BarChartIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      100k
                    </button>
                  </div>
                  <div className="right-content">
                    <ShareIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                    <BookMarkIcon
                      backgroundColor={Color.NEUTRAL[700]}
                      size={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }

        // for edit modal
        if (socialPostType === "poll-tweet") {
          if (!generalForm) return <></>;

          const pollFormControlMap: Record<number, FormControl> = {
            1: generalForm.pollOption,
            2: generalForm.pollOption2,
            3: generalForm.pollOption3,
            4: generalForm.pollOption4,
          };
          const renderPollOptions = () => {
            let percentages: number[];
            switch (pollOptions) {
              case 3:
                percentages = [60, 30, 10];
                break;
              case 4:
                percentages = [40, 20, 30, 10];
                break;
              default:
                percentages = [70, 30];
                break;
            }

            return (
              <ul className="poll-options">
                {Array.from({ length: pollOptions || 0 }, (_, index) => (
                  <li className={`poll-option `} key={index}>
                    <div
                      className="color-bar"
                      style={{ width: `${percentages[index]}%` }}
                    />
                    <div className="option-text">
                      {pollFormControlMap[index + 1]?.getValue() || "Option"}
                    </div>
                    <div className="option-percentage">
                      {percentages[index]}%
                    </div>
                  </li>
                ))}
              </ul>
            );
          };

          return (
            <div className="post-content-wrapper preview-x">
              <div className="post-author-wrapper">
                <img
                  src={
                    selectedConnects[previewIndex]?.pictureUrl ||
                    EMPTY_PROFILE_IMG_URL
                  }
                  alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                  className="profile-pic"
                />
              </div>
              <div className="x-post-main-content">
                <div className="profile-info">
                  <div className="profile-name">
                    {selectedConnects[previewIndex]?.name}
                  </div>
                  <div className="post-time">
                    <DotpointsIcon
                      size={2}
                      backgroundColor={Color.NEUTRAL[900]}
                    />
                    <p>13 h</p>
                  </div>
                </div>
                <div
                  className={`post-content ${
                    description.length === 0 ? "empty-description" : ""
                  }`}
                >
                  {description || "Your caption here..."}
                </div>
                {renderPollOptions()}
                <div className="post-actions">
                  <div className="left-content">
                    <button className="action-button repost">
                      <RepeatIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      30k
                    </button>
                    <button className="action-button comment">
                      <CommentIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      10K
                    </button>
                    <button className="action-button like">
                      <HeartIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      50K
                    </button>
                    <button className="action-button view">
                      <BarChartIcon
                        backgroundColor={Color.NEUTRAL[700]}
                        size={14}
                      />
                      100k
                    </button>
                  </div>
                  <div className="right-content">
                    <ShareIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                    <BookMarkIcon
                      backgroundColor={Color.NEUTRAL[700]}
                      size={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }

        if (
          socialPostType === "thread-tweet" ||
          (threadForm && threadForm.length > 1)
        ) {
          if (!threadForm) return <></>;
          return threadForm.map((form, index) => {
            const tweet = form.tweet?.getValue();
            const imageFiles = threadImage
              ? threadImage[currentPreview.socialMediaUserId][index]
              : [];
            const videoFiles = threadVideo
              ? threadVideo?.[currentPreview.socialMediaUserId][index]
              : [];

            const defaultPostFile = () => {
              const totalVideoFiles = videoFiles ? videoFiles.length : 0;
              const totalImageFiles = imageFiles ? imageFiles.length : 0;
              const totalFiles = totalVideoFiles + totalImageFiles;
              const displayLimit = 5;

              let layoutClass = "";

              // Determine layout class based on total files
              const layoutClasses = [
                "single-item",
                "two-items",
                "three-items",
                "four-items",
                "five-items",
                "many-items",
              ];
              layoutClass =
                totalFiles > displayLimit
                  ? "many-items"
                  : layoutClasses[totalFiles - 1] || "";

              const filesToRender = [];

              // Handle Instagram empty state
              if (
                totalFiles === 0 &&
                currentPreview.platform.toLowerCase() === "instagram"
              ) {
                filesToRender.push(<EmptyImagePreview />);
              } else {
                // Add video files preview
                if (totalVideoFiles && videoFiles) {
                  filesToRender.push(
                    <FilePreview
                      files={videoFiles}
                      fileType="video"
                      totalFiles={totalFiles}
                    />
                  );
                }

                // // Add image files preview with condition
                if (
                  totalImageFiles &&
                  !(totalVideoFiles > displayLimit) &&
                  imageFiles
                ) {
                  filesToRender.push(
                    <FilePreview
                      files={imageFiles}
                      fileType="image"
                      totalFiles={totalFiles}
                    />
                  );
                }
              }

              return filesToRender.length > 0 ? (
                <div
                  className={`file-preview-wrapper ${layoutClass} ${currentPreview.platform.toLowerCase()}`}
                >
                  {filesToRender}
                </div>
              ) : null;
            };

            return (
              <div className="post-content-wrapper preview-x" key={index}>
                <div className="post-author-wrapper">
                  <img
                    src={
                      selectedConnects[previewIndex]?.pictureUrl ||
                      EMPTY_PROFILE_IMG_URL
                    }
                    alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                    className="profile-pic"
                  />
                </div>
                <div className="x-post-main-content">
                  <div className="profile-info">
                    <div className="profile-name">
                      {selectedConnects[previewIndex]?.name}
                    </div>
                    <div className="post-time">
                      <DotpointsIcon
                        size={2}
                        backgroundColor={Color.NEUTRAL[900]}
                      />
                      <p>13 h</p>
                    </div>
                  </div>
                  <div
                    className={`post-content ${
                      tweet.length === 0 ? "empty-description" : ""
                    }`}
                  >
                    {tweet || "Your caption here..."}
                    <br />
                    {defaultPostFile()}
                  </div>
                  {index === 0 && (
                    <div className="post-actions">
                      <div className="left-content">
                        <button className="action-button repost">
                          <RepeatIcon
                            backgroundColor={Color.NEUTRAL[700]}
                            size={14}
                          />
                          30k
                        </button>
                        <button className="action-button comment">
                          <CommentIcon
                            backgroundColor={Color.NEUTRAL[700]}
                            size={14}
                          />
                          10K
                        </button>
                        <button className="action-button like">
                          <HeartIcon
                            backgroundColor={Color.NEUTRAL[700]}
                            size={14}
                          />
                          50K
                        </button>
                        <button className="action-button view">
                          <BarChartIcon
                            backgroundColor={Color.NEUTRAL[700]}
                            size={14}
                          />
                          100k
                        </button>
                      </div>
                      <div className="right-content">
                        <ShareIcon
                          backgroundColor={Color.NEUTRAL[700]}
                          size={14}
                        />
                        <BookMarkIcon
                          backgroundColor={Color.NEUTRAL[700]}
                          size={12}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          });
        }

        return (
          <div className="post-content-wrapper preview-x">
            <div className="post-author-wrapper">
              <img
                src={
                  selectedConnects[previewIndex]?.pictureUrl ||
                  EMPTY_PROFILE_IMG_URL
                }
                alt={`${selectedConnects[previewIndex]?.name} ${selectedConnects[previewIndex]?.platform}`}
                className="profile-pic"
              />
            </div>
            <div className="x-post-main-content">
              <div className="profile-info">
                <div className="profile-name">
                  {selectedConnects[previewIndex]?.name}
                </div>
                <div className="post-time">
                  <DotpointsIcon
                    size={2}
                    backgroundColor={Color.NEUTRAL[900]}
                  />
                  <p>13h</p>
                </div>
              </div>
              <div
                className={`post-content ${
                  description.length === 0 ? "empty-description" : ""
                }`}
              >
                {description || "Your caption here..."}
                <br />
                <MediaComponent />
              </div>
              <div className="post-actions">
                <div className="left-content">
                  <button className="action-button repost">
                    <RepeatIcon
                      backgroundColor={Color.NEUTRAL[700]}
                      size={14}
                    />
                    30k
                  </button>
                  <button className="action-button comment">
                    <CommentIcon
                      backgroundColor={Color.NEUTRAL[700]}
                      size={14}
                    />
                    10K
                  </button>
                  <button className="action-button like">
                    <HeartIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                    50K
                  </button>
                  <button className="action-button view">
                    <BarChartIcon
                      backgroundColor={Color.NEUTRAL[700]}
                      size={14}
                    />
                    100k
                  </button>
                </div>
                <div className="right-content">
                  <ShareIcon backgroundColor={Color.NEUTRAL[700]} size={14} />
                  <BookMarkIcon
                    backgroundColor={Color.NEUTRAL[700]}
                    size={12}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
  }, [
    currentPreview,
    postType,
    selectedConnects,
    previewIndex,
    description,
    MediaComponent,
    socialPostType,
    threadForm,
    generalForm,
    isMultiEdit,
    multiForm,
    pollOptions,
    threadImage,
    threadVideo,
  ]);
  return <>{renderContent()}</>;
};
