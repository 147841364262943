import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NOTIFICATION_RECORD } from "../../../constants/app.constants";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onView: () => void;
  onRefresh: () => void;
}

export const OngoingTaskEventNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = props;

  const userRoleRef = useRef<string>("");
  // const [negotiationObj, setNegotiationObj] = useState<NegotiationExtendedDto>();

  // const [isLoading, setLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  const viewOnClick = async () => {
    if (
      [
        NOTIFICATION_RECORD.ONGOING_TASK_NEW_DRAFT,
        NOTIFICATION_RECORD.ONGOING_TASK_UPDATE_DRAFT,
        NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_ACCEPTANCE,
        NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_REJECTION,
      ].includes(notification.event)
    ) {
      if (userRoleRef.current === "brand")
        navigate(
          `/${userRoleRef.current}/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}#Review%20Drafts`
        );
      else
        navigate(
          `/${userRoleRef.current}/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}#Submit%20Draft`
        );
    } else {
      navigate(
        `/${userRoleRef.current}/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}`
      );
    }
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  const creatorOnClick = () => {
    navigate(
      `/${userRoleRef.current}/creator-details/${notification.metadata.creatorUserId}`
    );
  };

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* {isLoading && <div className="loader">
            <div className="emp-spinner"></div>
        </div>} */}

      {/* ============================ NOTIFICATION DISPLAY IMG ================================== */}
      {/* ----------------------- Notification Type : New Draft & Update Draft --------------------------- */}
      <div className="organisation-img-wrapper">
        {notification.sender.senderRole !== "brand" && (
          <div className="organisation-img-wrapper">
            {/* Mini-Badge Image for creator if representative is Agency */}
            {notification.metadata.representativeRole === "agency" && (
              <img
                alt={notification.sender.senderName}
                className="badge-img org small-profile-badge"
                src={notification.metadata.agencyRepresentative.logo}
              />
            )}

            {/* The organisation or user image himself */}
            <>
              {notification.metadata.creatorRepresentative.imageType ===
                "url" && (
                <img
                  alt={notification.metadata.creatorRepresentative.fullName}
                  className="user-img"
                  src={
                    notification.metadata.creatorRepresentative.imageResource
                  }
                />
              )}
              {notification.metadata.creatorRepresentative.imageType ===
                "avatar" && (
                <div
                  style={{
                    background:
                      notification.metadata.creatorRepresentative.imageResource,
                  }}
                  className="user-avatar"
                >
                  <span className="initials">
                    {notification.metadata.creatorRepresentative.initials}
                  </span>
                </div>
              )}
            </>
          </div>
        )}
        {notification.sender.senderRole === "brand" && (
          <div className="organisation-img-wrapper">
            {notification.metadata.representativeRole === "agency" &&
              notification.metadata.creatorRepresentative.imageType ===
                "url" && (
                <img
                  alt={notification.sender.senderName}
                  className="badge-img small-profile-badge"
                  src={
                    notification.metadata.creatorRepresentative.imageResource
                  }
                />
              )}
            {notification.metadata.representativeRole === "agency" &&
              notification.metadata.creatorRepresentative.imageType ===
                "avatar" && (
                <div
                  style={{
                    background:
                      notification.metadata.creatorRepresentative.imageResource,
                  }}
                  className="badge-avatar small-profile-badge"
                >
                  <span className="initials">
                    {notification.metadata.creatorRepresentative.initials}
                  </span>
                </div>
              )}

            {/* The organisation or user image himself */}
            <img
              alt={notification.metadata.brand.companyName}
              className="organisation-img"
              src={notification.metadata.brand.logo}
            />
          </div>
        )}
      </div>

      <div className="notification-content">
        <div className="content-wrapper">
          {/* Notification Record Type = new-draft */}
          {notification.event === NOTIFICATION_RECORD.ONGOING_TASK_NEW_DRAFT &&
            !notification.metadata.agencyRepresentative && (
              <span className="info-text">
                {notification.metadata.creatorRepresentative.fullName} has
                submitted a new draft
              </span>
            )}
          {notification.event === NOTIFICATION_RECORD.ONGOING_TASK_NEW_DRAFT &&
            notification.metadata.agencyRepresentative && (
              <span className="info-text">
                {notification.metadata.agencyRepresentative.companyName} has
                submitted a draft on behalf of{" "}
                {notification.metadata.creatorRepresentative.fullName}
              </span>
            )}

          {/* Notification Record Type = update-draft */}
          {notification.event ===
            NOTIFICATION_RECORD.ONGOING_TASK_UPDATE_DRAFT && (
            <span className="info-text">
              {notification.metadata.creatorRepresentative.fullName} has updated
              an existing draft
            </span>
          )}

          {/* Notification Record Type = draft-acceptance */}
          {notification.event ===
            NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_ACCEPTANCE && (
            <span className="info-text">
              {notification.metadata.brand.companyName} has approved your Draft.
              You may proceed to complete the deliverable and submit proof of
              work.
            </span>
          )}

          {/* Notification Record Type = draft-rejection */}
          {notification.event ===
            NOTIFICATION_RECORD.ONGOING_TASK_DRAFT_REJECTION && (
            <span className="info-text">
              {notification.metadata.brand.companyName} has rejected your Draft.
              Please resubmit another draft.
            </span>
          )}

          {/* Notification Record Type = new-evidence */}
          {notification.event ===
            NOTIFICATION_RECORD.ONGOING_TASK_NEW_EVIDENCE && (
            <>
              {!notification.metadata.agencyRepresentative && (
                <span className="info-text">
                  {notification.metadata.creatorRepresentative.fullName} has
                  submitted a new proof of work
                </span>
              )}
              {notification.metadata.agencyRepresentative && (
                <span className="info-text">
                  {notification.metadata.agencyRepresentative.companyName} has
                  submitted a proof of work on behalf of{" "}
                  {notification.metadata.creatorRepresentative.fullName}
                </span>
              )}
            </>
          )}

          {/* Notification Record Type = brand-payout-eligible */}
          {[
            NOTIFICATION_RECORD.ONGOING_TASK_BRAND_PAYOUT_ELIGIBLE,
            NOTIFICATION_RECORD.ONGOING_TASK_SELLER_PAYOUT_ELIGIBLE,
          ].includes(notification.event) && (
            <>
              {userRoleRef.current === "brand" && (
                <span className="info-text">
                  {notification.metadata.creatorRepresentative.fullName} has
                  completed all the deliverables. If no disputes are raised
                  within 5 days, payout will be made to the creator
                </span>
              )}
              {userRoleRef.current === "agency" && (
                <span className="info-text">
                  {notification.metadata.creatorRepresentative.fullName} has
                  completed all the deliverables. If no disputes are raised
                  within 5 days, payout will be made to you
                </span>
              )}
              {userRoleRef.current === "creator" && (
                <span className="info-text">
                  You have completed all the deliverables. If no disputes are
                  raised within 5 days, the payout will be made to{" "}
                  {notification.metadata.representativeRole === "agency"
                    ? notification.metadata.agencyRepresentative.companyName
                    : "you"}
                  .
                </span>
              )}
            </>
          )}
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>

        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>

          <div className="button-wrapper">
            {notification.metadata.state === "valid" && (
              <>
                <EmpLink text={"View"} onSubmit={viewOnClick} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
