import { useCallback, useRef } from "react";

import { InstagramConnectInfoModalRef } from "../../creator-home-page/dashboard-cards/instagram-connect-info-modal";
import { BrandSmmAccountSelectionModalRef } from "../modal/brand-account-selection-modal";
import { BrandUnbindModalRef } from "../modal/brand-unbind-modal";
import SocialMediaManagementApi from "../../../api/smm-msvc/social-media-management.api";
import { FacebookPageDto } from "../../../model/social-media/facebook-page.dto";
import { InstagramConnectedAccountDto } from "../../../model/social-media/instagram-connected-account.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import UserUtils from "../../../utilities/user-utils";
import FacebookUtils from "../../creator-home-page/facebook";
import EmpException from "../../../exception/empException";
import { SM_REDIRECT } from "../../../constants/app.constants";

interface UsePlatformLoginProps {
  userContext: UserDto | null;
  setSmmModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fetchSocialMediaConnections: () => void;
  setSmIsLoading: React.Dispatch<
    React.SetStateAction<"tiktok" | "twitter" | "none">
  >;
}

interface PlatformModalRefs {
  facebookPageSelectionModalRef: React.RefObject<any>;
  instagramAccountSelectionModalRef: React.RefObject<any>;
  instagramConnectInfoModalRef: React.RefObject<any>;
  unbindModalRef: React.RefObject<any>;
}

interface FacebookLoginAttributes {
  id: string;
  access_token: string;
}

export function usePlatformLogin({
  userContext,
  setSmmModalVisible,
  fetchSocialMediaConnections,
  setSmIsLoading,
}: UsePlatformLoginProps): {
  bindPlatform: (platform: string) => void;
  instagramLogin: () => Promise<void>;
  facebookLogout: () => Promise<void>;
  savePlatformLogin: (
    fbPageSpec: FacebookLoginAttributes,
    platform: string
  ) => Promise<void>;
  bindTwitterAccount: (twitterCode: string) => Promise<void>;
  onUnlink: (connect: any) => void;
  platformModalRefs: PlatformModalRefs;
} {
  const facebookUtil = new FacebookUtils();

  const userRef = useRef<UserDto>();

  const facebookPageSelectionModalRef =
    useRef<BrandSmmAccountSelectionModalRef>();
  const instagramAccountSelectionModalRef =
    useRef<BrandSmmAccountSelectionModalRef>();
  const instagramConnectInfoModalRef = useRef<InstagramConnectInfoModalRef>();
  const unbindModalRef = useRef<BrandUnbindModalRef>();

  const setCurrentUser = useCallback(async () => {
    if (!userRef.current) {
      const user = await UserUtils.fetchUser(userContext);
      userRef.current = user;
    }
  }, [userContext]);

  const validateOrganisationMembership = (user: UserDto) => {
    // Check if 'organisation' is defined and has at least one item
    if (!user.organisation || user.organisation.length === 0) {
      throw new EmpException("Not an organisation");
    }
  };

  const bindPlatform = useCallback((platform: string) => {
    if (platform === "Facebook") facebookLogin();
    else if (platform === "Instagram") {
      setSmmModalVisible(false);
      instagramConnectInfoModalRef.current?.show();
    } else if (platform === "TikTok") {
      localStorage.setItem(
        "branch-blind-social",
        JSON.stringify(SM_REDIRECT.BRAND_SMM)
      );
      tiktokLogin();
    } else if (platform === "X" || platform === "Twitter") {
      localStorage.setItem(
        "branch-blind-social",
        JSON.stringify(SM_REDIRECT.BRAND_SMM)
      );
      twitterLogin();
    }
  }, []);

  const facebookLogin = async () => {
    try {
      const response = await facebookUtil.login();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookPageResp = (await facebookUtil.getFacebookPages(
          userId
        )) as FacebookPageDto;

        if (facebookPageResp.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Your account does not have a Facebook Page"
          );
          return;
        }
        if (facebookPageResp.data.length > 1) {
          setSmmModalVisible(false);
          facebookPageSelectionModalRef.current?.show(facebookPageResp);
          return;
        }
        setSmmModalVisible(false);
        savePlatformLogin(facebookPageResp.data[0], "facebook");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const instagramLogin = async () => {
    try {
      const response = await facebookUtil.instagramLogin();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookInstagramConnectedPage =
          (await facebookUtil.getInstagramConnectedAccount(
            userId
          )) as InstagramConnectedAccountDto;

        if (facebookInstagramConnectedPage.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Instagram needs to bind with a Facebook Page"
          );
          return;
        }
        if (facebookInstagramConnectedPage.data.length > 0) {
          instagramAccountSelectionModalRef.current?.show(
            facebookInstagramConnectedPage
          );
          return;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const tiktokLogin = async () => {
    try {
      await setCurrentUser();
      const user = userRef.current!;
      validateOrganisationMembership(user);

      if (user.organisation) {
        const brandId = user.organisation[0].id;
        const resp = await SocialMediaManagementApi.tiktok.auth(brandId);
        window.location.href = resp.data?.authUrl;
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to TikTok"
      );
    }
  };

  const twitterLogin = async () => {
    try {
      await setCurrentUser();
      const user = userRef.current!;
      validateOrganisationMembership(user);

      if (user.organisation) {
        const brandId = user.organisation[0].id;
        const resp = await SocialMediaManagementApi.x.auth(brandId, "brand-x");
        window.location.href = resp.data.authUrl;
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to Twitter"
      );
    }
  };

  const savePlatformLogin = async (
    fbPageSpec: FacebookLoginAttributes,
    platform: string
  ) => {
    try {
      await setCurrentUser();
      const user = userRef.current!;
      validateOrganisationMembership(user);

      if (user.organisation) {
        const brandId = user.organisation[0].id;
        const resp = await SocialMediaManagementApi.smLogin(brandId, {
          socialMediaPlatform: platform,
          code: fbPageSpec.access_token,
          socialMediaUserId: fbPageSpec.id,
        });

        if (resp.data.status === "success") {
          ToastUtils.success("Success", "Succesfully binded your FB account");
          if (platform === "Facebook") {
            facebookPageSelectionModalRef.current?.dismiss();
          } else if (platform === "Instagram") {
            instagramAccountSelectionModalRef.current?.dismiss();
          }
          facebookLogout();
          fetchSocialMediaConnections();
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to bind Facebook Page"
      );
    }
  };

  const facebookLogout = async () => {
    await facebookUtil.logout();
  };

  // Twitter/X Detection
  const bindTwitterAccount = useCallback(
    async (twitterCode: string) => {
      try {
        await setCurrentUser();
        const user = userRef.current!;
        validateOrganisationMembership(user);

        if (user.organisation) {
          const brandId = user.organisation[0].id;

          const response = await SocialMediaManagementApi.smLogin(brandId, {
            socialMediaPlatform: "x",
            code: twitterCode,
            socialMediaUserId: "",
          });
          // const response = await SocialMediaManagementApi.x.getToken(
          //   twitterCode,
          //   brandId,
          //   "brand-x"
          // );
          if (response.data.status === "success") {
            setSmIsLoading("none");
            ToastUtils.success(
              "Account Binded",
              "You have binded your Twitter account successfully."
            );
            fetchSocialMediaConnections();
          }
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to Twitter account"
        );
      }
    },
    [fetchSocialMediaConnections, setCurrentUser, setSmIsLoading]
  );

  const onUnlink = useCallback(
    (connect: SmConnectionBriefRespDto) => {
      setSmmModalVisible(false);
      unbindModalRef.current?.show(connect);
    },
    [setSmmModalVisible]
  );

  return {
    bindPlatform,
    instagramLogin,
    facebookLogout,
    savePlatformLogin,
    bindTwitterAccount,
    onUnlink,
    platformModalRefs: {
      facebookPageSelectionModalRef,
      instagramAccountSelectionModalRef,
      instagramConnectInfoModalRef,
      unbindModalRef,
    },
  };
}
