import { useCallback, useEffect, useRef, useState } from "react";
import CampaignSummaryApi from "../../../api/campaign-msvc/campaign-summary.api";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import { BrandTaskStatusBreakdownDto } from "../../../model/campaign/brand-task-status-breakdown.dto";
import { SelectOption } from "../../../model/common/selectOption";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Color } from "../../../utilities/colors";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import BlobAnimation from "../../../components/svgs/blob-animation";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import { useNavigate } from "react-router-dom";

const DONUT_OPTION: any = {
  chart: {
    type: "pie",
    backgroundColor: "transparent",
    style: {
      fontFamily: "Inter",
    },
    reflow: true,
  },
  title: {
    text: "",
    align: "left",
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ["50%", "50%"],
    },
  },
  series: [
    {
      name: "Task Category",
      data: undefined,
      size: "45%",
      dataLabels: {
        color: "#ffffff",
        distance: "-50%",
      },
    },
    {
      name: "Task Status",
      data: undefined,
      size: "80%",
      innerSize: "60%",
      dataLabels: {
        distance: "0",
        format: '<b>{point.name}:</b> <span style="opacity: 0.5">{y}</span>',
        style: {
          fontWeight: "normal",
        },
      },
      id: "versions",
    },
  ],
};

interface Props {
  onLoaded: () => void;
}
export const BrandTaskBreakdownPieChart = (props: Props) => {
  const { onLoaded } = props;
  const navigate = useNavigate();
  const [allCampaignsSelected, setAllCampaignsSelected] =
    useState<boolean>(true);
  const [campaignSelectOptions, setCampaignSelectOptions] =
    useState<SelectOption[]>();
  const [taskSelectOptions, setTaskSelectOptions] = useState<SelectOption[]>();
  const firstLoadFlag = useRef<boolean>(false);
  const selectedCampaignRef = useRef<string>("all");
  const [taskStatusBreakdown, setTaskStatusBreakdown] =
    useState<BrandTaskStatusBreakdownDto>();

  // Render an empty state if there are not tasks
  const [hasTask, setHasTask] = useState(false);

  const [chartLoading, setChartLoading] = useState(true);
  const [donutOptions, setDonutOptions] = useState<any>();

  const prepareDonutChartOption = (
    innerCategory: any[],
    outerCategory: any[]
  ) => {
    const donutOptions = { ...DONUT_OPTION };
    donutOptions.series[0].data = innerCategory;
    donutOptions.series[1].data = outerCategory;
    setDonutOptions(donutOptions);
  };

  const [form, setForm] = useState<IFormGroup>({
    campaigns: new FormControl("text", []),
    tasks: new FormControl("text", []),
  });

  const onCampaignChange = (formControl: FormControl) => {
    const campaignId = formControl.getValue();
    if (campaignId === "all") {
      getTaskBreakdown("all", "all");
    } else {
      getTaskBreakdown(campaignId, "all");
    }
  };

  const onTaskChange = (formControl: FormControl) => {
    const taskId = formControl.getValue();
    const campaignId = form.campaigns.getValue();
    if (taskId === "all") {
      getTaskBreakdown(campaignId, "all");
    } else {
      getTaskBreakdown(campaignId, taskId);
    }
  };

  const fetchSelectOptions = useCallback(async (campaignId: string) => {
    try {
      if (
        selectedCampaignRef.current === campaignId &&
        firstLoadFlag.current === true
      ) {
        return;
      }
      const resp = await CampaignSummaryApi.fetchCampaignOptions(campaignId);
      setCampaignSelectOptions([
        { label: "All Campaigns", value: "all" },
        ...resp.data.campaignOptions,
      ]);
      setTaskSelectOptions([
        { label: "All Tasks", value: "all" },
        ...resp.data.taskOptions,
      ]);
      setAllCampaignsSelected(campaignId === "all");

      setHasTask(resp.data.taskOptions.length > 0);

      selectedCampaignRef.current = campaignId;
      firstLoadFlag.current = true;
      if (campaignId === "all") {
        form.campaigns.forceUpdateValue("all");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to get dropdown values"
      );
    } finally {
      setChartLoading(false);
    }
  }, []);

  const getTaskBreakdown = useCallback(
    async (campaignId: string, taskId: string) => {
      try {
        setChartLoading(true);
        const campaignIdQuery = campaignId === "all" ? undefined : campaignId;
        const taskIdQuery = taskId === "all" ? undefined : taskId;

        const resp = await CampaignSummaryApi.fetchTaskBreakdown(
          campaignIdQuery,
          taskIdQuery
        );
        const taskBreakdown = resp.data;

        // This is to prepare for temporary donut chart.
        // Preparing the catgegory container.
        const innerCategoryData = [];
        const outerCategoryData = [];
        // Get outercircle
        if (
          taskBreakdown.taskStatus === "both" ||
          taskBreakdown.taskStatus === "ongoing"
        ) {
          innerCategoryData.push({
            y:
              (taskBreakdown.pendingDraftCount ?? 0) +
              (taskBreakdown.inReviewCount ?? 0) +
              (taskBreakdown.approvedCount ?? 0) +
              (taskBreakdown.completedCount ?? 0),
            name: "Ongoing Statuses",
            color: Color.PRIMARY[600],
          });
          if (taskBreakdown.pendingDraftCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.pendingDraftCount ?? 0,
              name: "Pending Draft",
              color: Color.PRIMARY[800],
            });
          }
          if (taskBreakdown.inReviewCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.inReviewCount ?? 0,
              name: "In-Review",
              color: Color.PRIMARY[700],
            });
          }
          if (taskBreakdown.approvedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.approvedCount ?? 0,
              name: "Approved",
              color: Color.PRIMARY[600],
            });
          }
          if (taskBreakdown.completedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.completedCount ?? 0,
              name: "Completed",
              color: Color.PRIMARY[500],
            });
          }
        }

        if (
          taskBreakdown.taskStatus === "both" ||
          taskBreakdown.taskStatus === "recruiting"
        ) {
          innerCategoryData.push({
            y:
              (taskBreakdown.invitedCount ?? 0) +
              (taskBreakdown.appliedCount ?? 0) +
              (taskBreakdown.brandNegotiate ?? 0) +
              (taskBreakdown.sellerNegotiate ?? 0) +
              (taskBreakdown.rejectedCount ?? 0) +
              (taskBreakdown.acceptedCount ?? 0),
            name: "Recruiting Statuses",
            color: "#FFA000",
          });
          if (taskBreakdown.invitedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.invitedCount ?? 0,
              name: "Invited",
              color: "#ffe199",
            });
          }
          if (taskBreakdown.appliedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.appliedCount ?? 0,
              name: "Applied",
              color: "#ffd980",
            });
          }
          if (taskBreakdown.brandNegotiate > 0) {
            outerCategoryData.push({
              y: taskBreakdown.brandNegotiate ?? 0,
              name: "Brand Negotiation",
              color: "#ffd166",
            });
          }
          if (taskBreakdown.sellerNegotiate > 0) {
            outerCategoryData.push({
              y: taskBreakdown.sellerNegotiate ?? 0,
              name: "Your negotiation",
              color: "#ffca4d",
            });
          }
          if (taskBreakdown.rejectedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.rejectedCount ?? 0,
              name: "Rejected",
              color: "#ffc233",
            });
          }
          if (taskBreakdown.acceptedCount > 0) {
            outerCategoryData.push({
              y: taskBreakdown.acceptedCount ?? 0,
              name: "Accepted",
              color: "#ffb300",
            });
          }
        }
        prepareDonutChartOption(innerCategoryData, outerCategoryData);
        setTaskStatusBreakdown(resp.data);
        fetchSelectOptions(campaignId);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to get Campaign Metrics"
        );
      }
    },
    [fetchSelectOptions]
  );

  useEffect(() => {
    getTaskBreakdown("all", "all").then(() => {
      onLoaded();
    });
  }, [getTaskBreakdown]);

  return (
    <div className="no-padding pie-wrapper">
      <h2 className="card-header">Task Breakdown Chart</h2>
      {campaignSelectOptions && taskSelectOptions && (
        <div className="form-section mt-4">
          <div className="form-control-wrapper">
            <EmpSelect
              labelText="Select Campaign"
              selectOptions={campaignSelectOptions}
              id={"campaigns"}
              onChange={onCampaignChange}
              formControl={form.campaigns}
            />
          </div>

          <div className="form-control-wrapper">
            {!allCampaignsSelected && (
              <EmpSelect
                labelText="Select Task"
                selectOptions={taskSelectOptions}
                id={"tasks"}
                onChange={onTaskChange}
                formControl={form.tasks}
              />
            )}
          </div>
        </div>
      )}

      {hasTask && donutOptions && (
        <div className="chart-wrapper">
          <EmpLoaderV2 background="light" isLoading={chartLoading} />
          <div className="blob-animation-wrapper">
            <BlobAnimation />
          </div>
          <HighchartsReact
            containerProps={{ style: { width: "300px", height: 300 } }}
            options={donutOptions}
            highcharts={Highcharts}
          />
        </div>
      )}

      {!hasTask && (
        <div className="empty-chart-skeleton-wrapper">
          <div className="empty-chart-overlay">
            <h2>No Tasks Yet</h2>
            <p className="emp-paragraph">
              Please create a campaign and a task inside it to view this metric
            </p>
            <EmpButton
              className="mt-2"
              text={"Create Campaign"}
              buttonHeight="sm"
              onSubmit={() => {
                navigate("/brand/campaigns");
              }}
              isFullWidth={false}
            />
          </div>
          <div className="empty-pie-skeleton">
            <div className="outer emp-shimmer">
              <div className="inner"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
