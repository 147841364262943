import { useCallback, useEffect, useState } from "react";
import { MediaSnapshotDto } from "../../model/campaign/media-snapshot.dto";
import "./pow-modal-listing.scss";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import EmpLink from "../shared/emp-link/emp-link";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import { Color } from "../../utilities/colors";
import EmpContent from "../shared/emp-content/emp-content";
import { DateUtil } from "../../utilities/date";
import RepeatIcon from "../icon/repost-icon";
import Barchart1Icon from "../icon/barchart-1-icon";
import XIcon from "../icon/x-icon";
import FileCheckIcon from "../icon/file-check-icon";
import { ProfileSnapshotDto } from "../../model/campaign/profile-snapshot.dto";
import { EmpImage } from "../shared/emp-image/emp-image";
import {
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE_DISPLAY,
} from "../../constants/app.constants";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import CheckIcon from "../icon/check-icon";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  mediaSnapshots: MediaSnapshotDto[];
  profileSnapshot?: ProfileSnapshotDto;
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
}
export const XPowFallback = (props: Props) => {
  const {
    mediaSnapshots,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  } = props;
  const [captionItem, setCaptionItem] = useState<MediaSnapshotDto>();
  const [imageItem, setImageItem] = useState<MediaSnapshotDto[]>();

  useEffect(() => {
    const foundCaptionItem = mediaSnapshots.find(
      (elem) => elem.type === "text"
    );
    setCaptionItem(foundCaptionItem);
    const foundImageItem = mediaSnapshots.filter(
      (elem) => elem.type === "image"
    );
    setImageItem(foundImageItem);
  }, [mediaSnapshots]);

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        <div className="preview-text">
          {captionItem && <p className="text">{captionItem.caption}</p>}
        </div>
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              <span className="card-title">Tweet</span>
              <div className="metrics-bar mt-2">
                <div className="metric-wrapper">
                  <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>
                    {evidenceRecord.metricSnapshot?.commentCount ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <RepeatIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>
                    {evidenceRecord.metricSnapshot?.shareCount ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
                </div>
                <div className="metric-wrapper">
                  <Barchart1Icon
                    size={14}
                    backgroundColor={Color.NEUTRAL[600]}
                  />
                  <span>
                    {evidenceRecord.metricSnapshot?.impression ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <div className="download-btn-wrapper">
                    <EmpLink
                      href={evidenceRecord.mediaUrl}
                      newTab
                      text={"Link to Post"}
                      showNewTabIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={CheckIcon}
              {...PILL_COLORS.green}
              text={"Live Data"}
              tooltip={`This data is pulled directly from the ${evidenceRecord.platform} API. Refresh the page to access the most up-to-date information.`}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [captionItem, evidenceRecord]);

  const renderPowCard = useCallback(() => {
    return (
      <div className="image-wrapper">
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />

            <div className="social-media-platform-badge">
              <XIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
        </div>
        <div className="post-wrapper">
          {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
            <div
              className={`additional-deliverable-wrapper-badge anchor-bottom`}
            >
              <span className="badge-text">
                +{noOfOtherEvidenceRecords - 1}
              </span>
              <FileCheckIcon
                strokeWidth={2.5}
                size={12}
                backgroundColor={Color.NEUTRAL[300]}
              />
            </div>
          )}
          <div className="x-tweet-wrapper">
            <p
              // CLAMP HERE IF THERE IS A POLL
              className={`x-tweet-text ${
                imageItem && imageItem.length > 0 ? "clamp" : ""
              }`}
            >
              {captionItem?.caption ?? "-"}
            </p>

            {/* {tweet.poll && (
            <div className="x-poll-wrapper mt-2">
              <XTweetPollWidget poll={tweet.poll!} />
            </div>
          )} */}
            {imageItem && imageItem.length > 0 && (
              <div className="x-media-grid-wrapper mt-2">
                <div className="x-media-grid">
                  {imageItem.map((media, mediaIndex) => {
                    return (
                      <div
                        key={media.sequence}
                        className={`x-media-wrapper items-${imageItem.length}`}
                      >
                        <img
                          referrerPolicy="no-referrer"
                          alt={`post ${mediaIndex + 1}`}
                          src={media.mediaUrl}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sm-metrics-section">
          <div className="metrics-bar">
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <RepeatIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.shareCount ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <Barchart1Icon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    captionItem,
    evidenceRecord,
    imageItem,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  ]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />

            <div className="social-media-platform-badge">
              <XIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content text-bg">
          <div className="x-tweet-wrapper">
            <p className="x-tweet-text">{captionItem?.caption}</p>

            {/* {(socialMediaObj as XTweet).poll && (
                <div className="x-poll-wrapper">
                  <XTweetPollWidget poll={(socialMediaObj as XTweet).poll!} />
                </div>
              )} */}

            {imageItem && imageItem.length > 0 && (
              <div className="x-media-grid-wrapper">
                <div className="x-media-grid">
                  {imageItem.map((media, mediaIndex) => {
                    return (
                      <div
                        key={media.mediaUrl}
                        className={`x-media-wrapper items-${imageItem.length}`}
                      >
                        <img
                          alt={`post ${mediaIndex + 1}`}
                          src={media.mediaUrl}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sm-metrics">
          <div className="metric-wrapper">
            <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <RepeatIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.shareCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <Barchart1Icon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
          </div>
        </div>
      </>
    );
  }, [captionItem, imageItem, evidenceRecord, profileSnapshot]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {(!imageItem || imageItem?.length === 0) && (
          <div className="text-container">Text</div>
        )}
        {imageItem && imageItem.length > 0 && (
          <EmpImage alt="Proof of work preview" src={imageItem[0].mediaUrl} />
        )}
      </>
    );
  }, [imageItem]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
