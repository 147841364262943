import { useContext, useState, useEffect, useCallback } from "react";
import { AppContext } from "../context/app.context";
import UserApi from "../api/user-msvc/user.api";
import EmpException from "../exception/empException";
import { getCurrentUser } from "aws-amplify/auth";
import { PLATFORM_ROLES } from "../constants/app.constants";

type AccountOriginType = "none" | "google" | "emplifive";
type SellerType = "creator" | "agency" | "not-applicable" | null;

const useUser = () => {
  const { user: userContext, setUser } = useContext(AppContext);
  const [accountOrigin, setAccountOrigin] = useState<AccountOriginType>();
  const [sellerType, setSellerType] = useState<SellerType>(null);
  const [loading, setLoading] = useState<boolean>(!userContext);
  const [error, setError] = useState<string | null>(null);

  const getUserOrigin = useCallback(async () => {
    let computedAccountOrigin: AccountOriginType = "none";
    const authUser = await getCurrentUser();
    if (authUser.username.startsWith("google")) {
      computedAccountOrigin = "google";
    } else {
      computedAccountOrigin = "emplifive";
    }
    setAccountOrigin(computedAccountOrigin);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userContext) {
        try {
          const userResp = await UserApi.fetchUser();
          if (setUser) setUser(userResp.data);
        } catch (err) {
          setError("Failed to fetch user");
        } finally {
          setLoading(false);
        }
      }
    };
    fetchUser();
    getUserOrigin();
  }, [userContext, setUser, getUserOrigin]);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const refreshUser = useCallback(async () => {
    try {
      const userResp = await UserApi.fetchUser();
      if (setUser) setUser(userResp.data);
    } catch (err) {
      console.error("Failed to fetch user");
      throw new EmpException("Unable to fetch user");
    }
  }, [setUser]);

  // Check if seller profile is a creator or agency
  useEffect(() => {
    const determineSellerType = () => {
      if (!userContext) {
        return null;
      }
      if (userContext.role === "talent") {
        return "creator";
      }
      if (
        userContext.organisation?.[0]?.organisationType.some(
          (elem) => elem.type === PLATFORM_ROLES.AGENCY
        )
      ) {
        return "agency";
      }
      return "not-applicable";
    };

    try {
      const sellerType = determineSellerType();
      setSellerType(sellerType);
    } catch (error) {
      console.error("Error occurred when getting sellerRole:", error);
      setSellerType(null);
    }
  }, [userContext]);

  return {
    user: userContext,
    loading,
    error,
    sellerType,
    refreshUser,
    accountOrigin,
  };
};
export default useUser;
