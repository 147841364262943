import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Editable, Slate, withReact } from "slate-react";
import { Node, Transforms } from "slate";
import _debounce from "lodash/debounce";
import "./emp-editor.scss";
import { createEditor } from "slate";
import LeafElement from "./nodes/leaf-element";
import LinkNodeElement from "./nodes/link-node-element";
import {
  BulletedNodeElement,
  ListNodeElement,
} from "./nodes/list-node-element";
import ParagraphNodeElement from "./nodes/paragraph-node-element";
import { withEmpEditor } from "./utility/with-emp-editor";

interface Props {
  value: string;
}
const EmpEditorView = (props: Props) => {
  const [valueString, setValueString] = useState(props.value);
  const [valueNodes] = useState(JSON.parse(props.value));
  // const valueRef = useRef<Node[]>(value);
  const [editor] = useState(() => withEmpEditor(withReact(createEditor())));

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "bulleted-list":
        return <BulletedNodeElement {...props} />;
      case "list-item":
        return <ListNodeElement {...props} />;
      case "link":
        return <LinkNodeElement {...props} mode="view" />;
      default:
        return <ParagraphNodeElement {...props} />;
    }
  }, []);

  const renderLeaf = useCallback((props: any) => {
    return <LeafElement {...props} />;
  }, []);

  useEffect(() => {
    setValueString(props.value);
  }, [props.value]);

  useEffect(() => {
    const value = JSON.parse(valueString) as Node[];
    let totalNodes = editor.children.length;

    const val = value;
    if (val.length <= 0) return;

    // Remove all nodes
    for (let i = 0; i < totalNodes - 1; i++) {
      Transforms.removeNodes(editor, {
        at: [totalNodes - i - 1],
      });
    }

    // Add nodes
    for (const block of val) {
      Transforms.insertNodes(editor, block, {
        at: [editor.children.length],
      });
    }
    // Remove the last node that was leftover from before
    Transforms.removeNodes(editor, {
      at: [0],
    });
  }, [editor, valueString]);

  return (
    <div className="emp-editor-viewer">
      <Slate editor={editor} initialValue={valueNodes}>
        <Editable
          style={{
            outline: "none",
          }}
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </div>
  );
};

export default EmpEditorView;
