import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchApi from "../../../api/search.api";
import { PLATFORM_ROLES, TASK_TYPE } from "../../../constants/app.constants";
import { CampaignSearchResultsDto } from "../../../model/campaign/campaign-search-results.dto";
import { UserSearchResultsDto } from "../../../model/user/user-search-results.dto";
import { Color } from "../../../utilities/colors";
import { empDelay } from "../../../utilities/delay";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FacebookIcon from "../../icon/facebook-icon";
import InstagramIcon from "../../icon/instagram-icon";
import SearchIcon from "../../icon/search-icon";
import TargetIcon from "../../icon/target-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import XCloseIcon from "../../icon/x-close-icon";
import EmpModal from "../../shared/emp-modal/emp-modal";
import _debounce from "lodash/debounce";
import "./emp-search-modal.scss";
import EmpLink from "../../shared/emp-link/emp-link";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";

export interface SearchModalRef {
  show: (triggeredByFinder?: boolean) => void;
  dismiss: () => void;
  visible: boolean;
}

interface Props {}

const SearchModal = forwardRef((props: Props, ref) => {
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();
  const osRef = useRef<string>("unknown");

  const location = useLocation();
  const navigate = useNavigate();

  const [showFinderTooltip, setShowFinderTooltip] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
  const [campaignSearchResults, setCampaignSearchResults] =
    useState<CampaignSearchResultsDto>();
  const [userSearchResults, setUserSearchResults] =
    useState<UserSearchResultsDto>();
  const roleRef = useRef<string>("");
  const currentKeywordRef = useRef<string>("");

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
      visible,
    };
  });

  const searchCampaigns = async (keyword?: string) => {
    try {
      currentKeywordRef.current = keyword ?? "";
      const resp = await SearchApi.campaignSearch(roleRef.current, keyword);
      setCampaignSearchResults(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to saerch for campaign data"
      );
    }
  };

  const searchUsers = async (keyword?: string) => {
    try {
      const resp = await SearchApi.userSearch(roleRef.current, keyword);
      setUserSearchResults(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to saerch for campaign data"
      );
    }
  };
  function handleInputChange(value: string) {
    setLoading(true);
    Promise.all([searchUsers(value), searchCampaigns(value)]).then(() => {
      empDelay(300).then(() => {
        setLoading(false);
      });
    });
  }
  const debouncedHandleInputChange = _debounce(handleInputChange, 300);

  const show = async (triggeredByFinder?: boolean) => {
    if (triggeredByFinder) setShowFinderTooltip(true);
    setVisible(true);
    await empDelay(100);
    setLoading(true);
    Promise.all([searchCampaigns(), searchUsers()]).then(() => {
      empDelay(300).then(() => {
        setLoading(false);
      });
    });
    searchInputRef.current?.focus();
  };

  const dismiss = async () => {
    setVisible(false);
    setShowFinderTooltip(false);
  };

  useEffect(() => {
    roleRef.current = location.pathname.split("/")[1];
    if (platform.includes("mac") || userAgent.includes("mac")) {
      osRef.current = "macOS";
    } else if (platform.includes("win") || userAgent.includes("win")) {
      osRef.current = "Windows";
    } else {
      osRef.current = "unknown";
    }
  }, [location, platform, userAgent]);

  const navigateToCreatorPage = (creatorId: string) => {
    if (roleRef.current === PLATFORM_ROLES.AGENCY) {
      navigate(`/agency/creator-details/${creatorId}`);
    } else if (roleRef.current === PLATFORM_ROLES.BRAND) {
      navigate(`/brand/creator-details/${creatorId}`);
    }
    dismiss();
  };

  const navigateToAgencyPage = (organisationId: string) => {
    if (roleRef.current === PLATFORM_ROLES.BRAND) {
      navigate(`/brand/agency/${organisationId}`);
    }
    dismiss();
  };

  const navigateToTaskPage = (taskId: string, campaignId: string) => {
    if (roleRef.current === PLATFORM_ROLES.AGENCY) {
      navigate(`/agency/campaign-details/${campaignId}/task/${taskId}`);
    } else if (roleRef.current === PLATFORM_ROLES.BRAND) {
      navigate(`/brand/campaign-details/${campaignId}/task/${taskId}`);
    }
    dismiss();
  };

  const navigateToCampaignPage = (campaignId: string) => {
    if (roleRef.current === PLATFORM_ROLES.AGENCY) {
      navigate(`/agency/campaign-details/${campaignId}`);
    } else if (roleRef.current === PLATFORM_ROLES.BRAND) {
      navigate(`/brand/campaign-details/${campaignId}`);
    }
    dismiss();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dismiss();
      }
    };
    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div style={{ position: "fixed", zIndex: "1000" }}>
      <EmpModal
        visible={visible}
        setVisible={setVisible}
        showHeader={false}
        verticalPadding={false}
        showFooter={false}
        showFooterBorder={false}
        bodyPadding={false}
        onClose={dismiss}
        showHeaderBorder={false}
        size={"sm"}
      >
        {/* This is body */}
        <div>
          {showFinderTooltip && (
            <div className="finder-tip">
              Pressed &nbsp;
              {(osRef.current === "unknown" || osRef.current === "Windows") && (
                <code>Ctrl + F</code>
              )}
              {osRef.current === "macOS" && <code>Cmd + F</code>}? Hit it again
              to open the browser search.
            </div>
          )}
          <div className="emp-search-modal">
            <div className="modal-header-wrapper">
              <div
                className={`searchbar-wrapper ${searchBoxIsFocused ? "focused" : ""}`}
              >
                <div className="search-icon-wrapper">
                  <SearchIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
                </div>
                <input
                  ref={searchInputRef}
                  onFocus={() => {
                    setSearchBoxIsFocused(true);
                  }}
                  onBlur={() => {
                    setSearchBoxIsFocused(false);
                  }}
                  onChange={(e) => {
                    debouncedHandleInputChange(e.target.value);
                  }}
                  type="text"
                  placeholder="Search for Campaigns, Task and Creators"
                />

                <div
                  onClick={() => dismiss()}
                  className={`dismiss-wrapper ${searchBoxIsFocused ? "focused" : ""}`}
                >
                  <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="emp-progress">
                <div className="indeterminate"></div>
              </div>
            )}
            {!isLoading && <div className="empty-loading-placeholder"></div>}
            <section className="results-section">
              {userSearchResults && (
                <>
                  <div className="subsection">
                    {currentKeywordRef.current.length > 0 &&
                      userSearchResults.creators.length > 0 && (
                        <>
                          Showing {userSearchResults.creators.length} out of{" "}
                          {userSearchResults.creatorsCount} Creators
                        </>
                      )}
                    {currentKeywordRef.current.length > 0 &&
                      userSearchResults.creators.length === 0 && (
                        <>No Creators found</>
                      )}
                    {currentKeywordRef.current.length === 0 && (
                      <>Recommended Creators</>
                    )}
                    <EmpLink
                      text={"See all"}
                      onSubmit={() => {
                        if (roleRef.current === PLATFORM_ROLES.AGENCY)
                          navigate(
                            `/agency/creators?pageNo=1&pageSize=20&search=${currentKeywordRef.current}#Explore%20Creators`
                          );
                        else if (roleRef.current === PLATFORM_ROLES.BRAND) {
                          navigate(
                            `/brand/creators?pageNo=1&pageSize=20&search=${currentKeywordRef.current}#Creators`
                          );
                          PubSub.publish(PUB_SUB_TOPICS.BRAND_CREATOR_SEARCH);
                        }
                        dismiss();
                      }}
                    />
                  </div>
                  {userSearchResults &&
                    userSearchResults.creators.map((elem) => {
                      return (
                        <div
                          key={elem.id}
                          onClick={() => {
                            navigateToCreatorPage(elem.id);
                          }}
                          className="record"
                        >
                          {elem.imageType === "url" && (
                            <img
                              className="profile-img"
                              alt={elem.name}
                              src={elem.imageResource}
                            />
                          )}
                          {elem.imageType === "avatar" && (
                            <div
                              className="profile-img"
                              style={{ background: elem.imageResource }}
                            >
                              <span className="initials">{elem.initials}</span>
                            </div>
                          )}
                          <div>
                            <span className="name-lbl">{elem.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  {roleRef.current === "brand" && (
                    <>
                      <div className="subsection bordered">
                        {currentKeywordRef.current.length > 0 &&
                          userSearchResults.agencies.length > 0 && (
                            <>
                              Showing {userSearchResults.agencies.length} out of{" "}
                              {userSearchResults.agenciesCount} Agencies
                            </>
                          )}
                        {currentKeywordRef.current.length > 0 &&
                          userSearchResults.agencies.length === 0 && (
                            <>No Agencies found</>
                          )}
                        {currentKeywordRef.current.length === 0 && (
                          <>Recommended Agencies</>
                        )}
                      </div>
                      {userSearchResults.agencies.length > 0 &&
                        userSearchResults.agencies.map((elem) => {
                          return (
                            <div
                              key={elem.id}
                              onClick={() => {
                                navigateToAgencyPage(elem.id);
                              }}
                              className="record"
                            >
                              <img
                                className="profile-img org"
                                alt={elem.companyName}
                                src={elem.logo}
                              />
                              <div>
                                <span className="name-lbl">
                                  {elem.companyName}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </>
              )}

              {campaignSearchResults && (
                <>
                  <div className="subsection bordered">
                    {currentKeywordRef.current.length > 0 &&
                      campaignSearchResults.task.length > 0 && (
                        <>
                          Showing {campaignSearchResults.task.length} out of{" "}
                          {campaignSearchResults.taskCount} Tasks
                        </>
                      )}
                    {currentKeywordRef.current.length > 0 &&
                      campaignSearchResults.task.length === 0 && (
                        <>No Tasks found</>
                      )}
                    {currentKeywordRef.current.length === 0 && (
                      <>Recommended Tasks</>
                    )}
                    <EmpLink
                      text={"See all"}
                      onSubmit={() => {
                        if (roleRef.current === PLATFORM_ROLES.AGENCY)
                          navigate(
                            `/agency/campaigns-new?pageNo=1&pageSize=20&search=${currentKeywordRef.current}#Browse%20Tasks`
                          );
                        else if (roleRef.current === PLATFORM_ROLES.BRAND)
                          navigate(
                            `/brand/campaigns?pageNo=1&pageSize=20&search=${currentKeywordRef.current}#My%20Tasks`
                          );
                        dismiss();
                      }}
                    />
                  </div>
                  {campaignSearchResults &&
                    campaignSearchResults.task.map((elem) => {
                      return (
                        <div
                          onClick={() => {
                            navigateToTaskPage(elem.id, elem.campaignId);
                          }}
                          key={elem.id}
                          className="record"
                        >
                          <img
                            className="campaign-img"
                            alt={elem.name}
                            src={elem.imageUrl}
                          />
                          <div>
                            <span className="name-lbl">{elem.name}</span>
                            <div className="task-platform-wrapper">
                              {elem.platform === TASK_TYPE.FACEBOOK && (
                                <>
                                  <FacebookIcon
                                    size={10}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                  <span className="task-type-small">
                                    Facebook
                                  </span>
                                </>
                              )}
                              {elem.platform === TASK_TYPE.INSTAGRAM && (
                                <>
                                  <InstagramIcon
                                    size={10}
                                    top={0.5}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                  <span className="task-type-small">
                                    Instagram
                                  </span>
                                </>
                              )}
                              {elem.platform === TASK_TYPE.TIKTOK && (
                                <>
                                  <TikTokIcon
                                    size={10}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                  <span className="task-type-small">
                                    TikTok
                                  </span>
                                </>
                              )}
                              {elem.platform === TASK_TYPE.GENERAL && (
                                <>
                                  <TargetIcon
                                    size={10}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                  <span className="task-type-small">
                                    General Task{" "}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="subsection bordered">
                    {currentKeywordRef.current.length > 0 &&
                      campaignSearchResults.campaign.length > 0 && (
                        <>
                          Showing {campaignSearchResults.campaign.length} out of{" "}
                          {campaignSearchResults.campaignCount} Campaigns
                        </>
                      )}
                    {currentKeywordRef.current.length > 0 &&
                      campaignSearchResults.campaign.length === 0 && (
                        <>No Campaigns found</>
                      )}
                    {currentKeywordRef.current.length === 0 && (
                      <>Recommended Campaigns</>
                    )}
                    <EmpLink
                      text={"See all"}
                      onSubmit={() => {
                        if (roleRef.current === PLATFORM_ROLES.AGENCY)
                          navigate(
                            `/agency/campaigns-new?pageNo=1&pageSize=20&search=${currentKeywordRef.current}#Browse%20Campaigns`
                          );
                        if (roleRef.current === PLATFORM_ROLES.BRAND)
                          navigate(
                            `/brand/campaigns?search=${currentKeywordRef.current}#My%20Campaigns`
                          );
                        dismiss();
                      }}
                    />
                  </div>
                  {campaignSearchResults &&
                    campaignSearchResults.campaign.map((elem) => {
                      return (
                        <div
                          onClick={() => {
                            navigateToCampaignPage(elem.id);
                          }}
                          key={elem.id}
                          className="record"
                        >
                          <img
                            className="campaign-img"
                            alt={elem.name}
                            src={elem.imageUrl}
                          />
                          <div>
                            <span className="name-lbl">{elem.name}</span>
                            <div className="task-platform-wrapper"></div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </section>
          </div>
        </div>
        {/* This is footer */}
        <div></div>
      </EmpModal>
    </div>
  );
});

export default SearchModal;
