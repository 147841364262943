import { useEffect, useState } from "react";
import "./sign-up-page.scss";
import { AgencyInterestView } from "./views/agency-interest-view";
import { BrandSignUpView } from "./views/brand-sign-up-view";
import { CreatorSignUpView } from "./views/creator-sign-up-view";
import { RoleSelectionView } from "./views/role-selection-view";

export type ViewType =
  | "role-selection"
  | "agency-interest"
  | "creator-sign-up"
  | "brand-sign-up";

export const SignUpPage = () => {
  const [currentView, setCurrentView] = useState<ViewType>("role-selection");

  useEffect(() => {
    window.scrollTo(0, 0);
    const fragment = window.location.hash;
    if (fragment !== "") {
      if (fragment === "#role-selection") {
        setCurrentView("role-selection");
      } else if (fragment === "#brand-sign-up") {
        setCurrentView("brand-sign-up");
      } else if (fragment === "#creator-sign-up") {
        setCurrentView("creator-sign-up");
      } else if (fragment === "#agency-interest") {
        setCurrentView("agency-interest");
      }
    } else {
      setCurrentView("role-selection");
    }
  }, []);

  return (
    <div className="emp-sign-up-page cbz-background-gradient">
      {currentView === "role-selection" && (
        <RoleSelectionView
          onViewChange={(viewType) => setCurrentView(viewType)}
        />
      )}
      {currentView === "agency-interest" && (
        <AgencyInterestView
          mode="unauthenticated-lead"
          onViewChange={(viewType) => setCurrentView(viewType)}
        />
      )}
      {currentView === "creator-sign-up" && (
        <CreatorSignUpView
          onViewChange={(viewType) => setCurrentView(viewType)}
        />
      )}
      {currentView === "brand-sign-up" && (
        <BrandSignUpView
          onViewChange={(viewType) => setCurrentView(viewType)}
        />
      )}
    </div>
  );
};
