import "./invite-talents-modal.scss";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import ToastUtils from "../../utilities/toast-utils";
import { FormControl } from "../../utilities/formUtils/formControl";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import EmailIcon from "../icon/email-icon";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import EmpSelect from "../shared/emp-select/emp-select";
import { getPartnershipTypeOptions } from "../../constants/selectConstants";
import CreatorHasExclusiveAgencyModal, {
  CreatorHasExclusiveAgencyModalRef,
} from "./creator-has-exclusive-agency-modal";
import EmpLink from "../shared/emp-link/emp-link";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormattedMessage, useIntl } from "react-intl";
import { ApiStatus } from "../../model/api/api-status";
import EmpCryptoInputField from "../shared/emp-crypto-input-field";

export interface InviteTalentsModalRef {
  show: (mode: "new" | "existing", name?: string, creatorId?: string) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const InviteTalentsModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const invitationBtnRef = useRef<EmpButtonRef>(null);
  const [onboardingLinkSpec, setOnboardingLinkSpec] = useState<string>();
  const [formMode, setFormMode] = useState<"new" | "existing">("new");
  const creatorIdRef = useRef<string>();

  const creatorHasExclusiveAgencyModalRef =
    useRef<CreatorHasExclusiveAgencyModalRef>();

  const [newInvitationForm, setNewInvitationForm] = useState<IFormGroup>({
    email: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_emailRequired" })
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        intl.formatMessage({ id: "validation_emailTooLong" })
      ),
      new EmailValidator(),
    ]),
    name: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage(
          { id: "validation_requiredDynamic" },
          { field: "Member name" }
        )
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        intl.formatMessage(
          { id: "validation_maxLengthDynamic" },
          { char: "100" }
        )
      ),
    ]),
    invitationType: new FormControl(
      "text",
      [new RequiredValidator("Invitation type is required")],
      "affiliate-invitation"
    ),
  });

  const [existingInvitationForm, setExistingInvitationForm] =
    useState<IFormGroup>({
      name: new FormControl("text", [
        new RequiredValidator(
          intl.formatMessage(
            { id: "validation_requiredDynamic" },
            { field: "Member name" }
          )
        ),
        new LengthValidator(
          0,
          100,
          undefined,
          intl.formatMessage(
            { id: "validation_maxLengthDynamic" },
            { char: "100" }
          )
        ),
      ]),
      invitationType: new FormControl(
        "text",
        [new RequiredValidator("Invitation type is required")],
        "affiliate-invitation"
      ),
    });

  const partnershipTypeOptions = useMemo(() => {
    return getPartnershipTypeOptions(intl);
  }, [intl]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    mode: "new" | "existing",
    name?: string,
    creatorId?: string
  ) => {
    setFormMode(mode);
    if (mode === "existing" && name) {
      creatorIdRef.current = creatorId;
      existingInvitationForm.name.forceUpdateValue(name);
    }
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formMode === "existing") {
      if (formControl.validateTrackDiff()) {
        setExistingInvitationForm({ ...existingInvitationForm });
      }
    }
    if (formMode === "new") {
      if (formControl.validateTrackDiff()) {
        setNewInvitationForm({ ...newInvitationForm });
      }
    }
  };

  const inviteUserOnSubmit = async () => {
    try {
      invitationBtnRef.current?.setButtonState("loading");
      let creatorName: string = "";
      let apiStatus: ApiStatus | undefined = undefined;
      if (formMode === "new") {
        const isValid = FormGroupUtil.validate(newInvitationForm);
        setNewInvitationForm({ ...newInvitationForm });
        if (!isValid) return;

        creatorName = newInvitationForm.name.getValue();
        const resp = await OnboardingApi.inviteNewCreatorToAgy({
          fullName: newInvitationForm.name.getValue(),
          email: newInvitationForm.email.getValue(),
          invitationType: newInvitationForm.invitationType.getValue(),
        });
        apiStatus = resp.data;
        processFormResponse(
          apiStatus,
          creatorName,
          "email",
          newInvitationForm.email.getValue()
        );
      } else if (formMode === "existing") {
        const isValid = FormGroupUtil.validate(existingInvitationForm);
        setExistingInvitationForm({ ...existingInvitationForm });
        if (!isValid) return;
        creatorName = existingInvitationForm.name.getValue();
        const resp = await OnboardingApi.inviteExistingCreatorToAgy({
          creatorId: creatorIdRef.current!,
          fullName: existingInvitationForm.name.getValue(),
          invitationType: existingInvitationForm.invitationType.getValue(),
        });
        apiStatus = resp.data;
        processFormResponse(
          apiStatus,
          creatorName,
          "creatorId",
          creatorIdRef.current!
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      invitationBtnRef.current?.setButtonState("default");
    }
  };

  const processFormResponse = (
    response: ApiStatus,
    creatorName: string,
    creatorIdentifier: "email" | "creatorId",
    identifier: string
  ) => {
    let onboardingLink = undefined;
    if (response.status === "success") {
      if (response.statusMessage === "new-invitation-existing-creator")
        ToastUtils.success(
          "Creator Invited",
          `We have sent an invitation email to ${creatorName}`
        );
      else if (response.statusMessage.startsWith("new-invitation{}")) {
        onboardingLink = response.statusMessage.split("{}")[1];
        ToastUtils.success(
          "Creator Invited",
          `We have sent an invitation email to ${creatorName}`
        );
      } else if (
        response.statusMessage === "new-invitation-to-existing-creator"
      ) {
        ToastUtils.success(
          "Creator Invited",
          `We have sent an invitation to ${creatorName}`
        );
      } else if (response.statusMessage === "existing-invitation")
        ToastUtils.success(
          "Creator Invited",
          `We have sent an invitation to ${creatorName}`
        );
      else if (response.statusMessage === "resent-invitation") {
        ToastUtils.success(
          "Resent Invitation",
          `We have resent an invitation email to ${creatorName}`
        );
      }
      setOnboardingLinkSpec(onboardingLink);
      resetForm();
      setSubmitted(false);
      props.onSave();
    } else if (response.status === "error") {
      if (response.statusMessage === "creator-already-joined")
        ToastUtils.error(
          "Invitation not sent",
          `Creator has already joined the Agency`
        );
      else if (response.statusMessage === "creator-exclusive") {
        creatorHasExclusiveAgencyModalRef.current!.show(
          creatorIdentifier,
          identifier
        );
      } else if (response.statusMessage.startsWith("rate-limit")) {
        // statusMessage = "rate-limit:20", where 20 refers to the time left till the next invocation
        const waitMinutes = response.statusMessage.split(":")[1];
        ToastUtils.error(
          "Please wait before resending",
          `Please wait for ${waitMinutes} minutes before resending`
        );
      } else if (response.statusMessage === "invalid-email-role") {
        ToastUtils.error(
          "Invitation Failed",
          `You are unable to send an invitation to this email address.`
        );
      }
    }
  };

  const resetForm = () => {
    // Resetting the form
    existingInvitationForm.name.reset();
    existingInvitationForm.invitationType.forceUpdateValue(
      "affiliate-invitation"
    );
    setExistingInvitationForm({ ...existingInvitationForm });

    newInvitationForm.name.reset();
    newInvitationForm.email.reset();
    newInvitationForm.invitationType.forceUpdateValue("affiliate-invitation");
    setExistingInvitationForm({ ...newInvitationForm });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-invite-talents-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <CreatorHasExclusiveAgencyModal
          ref={creatorHasExclusiveAgencyModalRef}
        />
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="inviteCreatorModal_header" />
          </h2>
        </div>
        {formMode === "new" && (
          <div className="invitation-form-section">
            <div className="text-input-wrapper">
              <div className="pr-1" style={{ flex: 1 }}>
                <EmpTextInput
                  leftIconComponent={EmailIcon}
                  id={"email"}
                  labelText={intl.formatMessage({
                    id: "inviteCreatorModal_emailInputLabel",
                  })}
                  required
                  formControl={newInvitationForm.email}
                  onChange={validate}
                  placeholder={intl.formatMessage({
                    id: "inviteCreatorModal_emailInputPlaceholder",
                  })}
                />
              </div>
            </div>
            <div className="text-input-row-wrapper">
              <div className="text-input-wrapper">
                <div style={{ flex: 1 }}>
                  <EmpTextInput
                    id={"name"}
                    formControl={newInvitationForm.name}
                    required
                    labelText={intl.formatMessage({
                      id: "inviteCreatorModal_nameInputLabel",
                    })}
                    onChange={validate}
                    placeholder={intl.formatMessage({
                      id: "inviteCreatorModal_nameInputPlaceholder",
                    })}
                  />
                </div>
              </div>
              <div className="text-input-wrapper">
                <div style={{ flex: 1 }}>
                  <EmpSelect
                    id="invitationType"
                    formControl={newInvitationForm.invitationType}
                    required
                    labelText={intl.formatMessage({
                      id: "inviteCreatorModal_partnershipTypeInputLabel",
                    })}
                    onChange={validate}
                    placeholder={intl.formatMessage({
                      id: "inviteCreatorModal_partnershipTypeInputPlaceholder",
                    })}
                    selectOptions={partnershipTypeOptions}
                  />
                </div>
              </div>
            </div>

            {onboardingLinkSpec && (
              <>
                <div className="onboarding-link-section">
                  <div className="text-content-section mb-4">
                    <h2 className="title">Invitation link sent</h2>
                    <p className="description">
                      You may send this link privately to your creator.
                    </p>
                  </div>

                  <p className="mt-1">
                    {onboardingLinkSpec}{" "}
                    <EmpLink
                      onSubmit={() => {
                        ToastUtils.success("Copied", "Onboarding Link Copied");
                        navigator.clipboard.writeText(onboardingLinkSpec);
                      }}
                      text="Copy link"
                    />
                  </p>
                </div>
              </>
            )}
            <div className="button-wrapper">
              <EmpButton
                onSubmit={() => {
                  inviteUserOnSubmit();
                }}
                ref={invitationBtnRef}
                isFullWidth={false}
                text={
                  <FormattedMessage id="inviteCreatorModal_sendInvitationBtn" />
                }
              />
            </div>
          </div>
        )}

        {formMode === "existing" && (
          <div className="invitation-form-section">
            <div className="text-input-wrapper"></div>
            <div className="text-input-row-wrapper">
              <div className="text-input-wrapper">
                <div style={{ flex: 1 }}>
                  <EmpTextInput
                    id={"name"}
                    formControl={existingInvitationForm.name}
                    required
                    disabled
                    labelText={intl.formatMessage({
                      id: "inviteCreatorModal_nameInputLabel",
                    })}
                    onChange={validate}
                    placeholder={intl.formatMessage({
                      id: "inviteCreatorModal_nameInputPlaceholder",
                    })}
                  />
                </div>
              </div>
              <div className="text-input-wrapper">
                <div style={{ flex: 1 }}>
                  <EmpSelect
                    id="invitationType"
                    formControl={existingInvitationForm.invitationType}
                    required
                    labelText={intl.formatMessage({
                      id: "inviteCreatorModal_partnershipTypeInputLabel",
                    })}
                    onChange={validate}
                    placeholder={intl.formatMessage({
                      id: "inviteCreatorModal_partnershipTypeInputPlaceholder",
                    })}
                    selectOptions={partnershipTypeOptions}
                  />
                </div>
              </div>
            </div>
            {onboardingLinkSpec && (
              <>
                <div className="onboarding-link-section">
                  <div className="text-content-section mb-4">
                    <h2 className="title">Invitation link sent</h2>
                    <p className="description">
                      You may send this link privately to your creator.
                    </p>
                  </div>

                  <p className="mt-1">
                    {onboardingLinkSpec}{" "}
                    <EmpLink
                      onSubmit={() => {
                        ToastUtils.success("Copied", "Onboarding Link Copied");
                        navigator.clipboard.writeText(onboardingLinkSpec);
                      }}
                      text="Copy link"
                    />
                  </p>
                </div>
              </>
            )}
            <div className="button-wrapper">
              <EmpButton
                onSubmit={() => {
                  inviteUserOnSubmit();
                }}
                ref={invitationBtnRef}
                isFullWidth={false}
                text={
                  <FormattedMessage id="inviteCreatorModal_sendInvitationBtn" />
                }
              />
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default InviteTalentsModal;
