import isNil from "lodash/fp/isNil";
import omitBy from "lodash/fp/omitBy";
import isEmpty from "lodash/fp/isEmpty";
import { stringify, IStringifyOptions as StringifyOptionsType } from "qs";
import DOMPurify from "dompurify";
import qs from "qs";

type GenerateGetURLType = {
  href: string;
  params?: Record<string, unknown>;
  stringifyOption?: StringifyOptionsType;
};

class StringUtils {
  static capitalizeWords(input: string): string {
    return input.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  static toTitleCase(input: string): string {
    return input.replace(/\b[A-Z]+\b/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    });
  }

  static convertHyphensToTitleCase(sentence: string): string {
    const words = sentence.split("-");

    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });
    return capitalizedWords.join(" ");
  }

  static stringToHtmlRepresentation(string: string): string {
    // Regular expression to detect URLs in the string
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // First, sanitize the string to avoid XSS attacks
    let sanitizedString = DOMPurify.sanitize(string);

    // Replace detected URLs with HTML-compatible anchor tags
    const resultString = sanitizedString.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noreferrer" class="emp-link">${url}</a>`;
    });

    // Replace newlines with <br> tags
    return resultString.replace(/\n/g, "<br>");
  }

  static generateGetURL({
    href,
    params,
    stringifyOption = { arrayFormat: "brackets" },
  }: GenerateGetURLType) {
    const normalizedParamsFunction = omitBy(isNil);
    const normalizedParams = normalizedParamsFunction(params);
    return isEmpty(normalizedParams)
      ? href
      : `${href}${href.includes("?") ? "&" : "?"}${stringify(
          normalizedParams,
          stringifyOption
        )}`;
  }

  static buildCommaSeparatedQueryString(queryObject: {
    [key: string]: string[] | undefined;
  }) {
    // Clean the query object by removing empty arrays and undefined values
    const cleanedQueryObject = Object.keys(queryObject).reduce(
      (acc: { [key: string]: string[] }, key) => {
        const value = queryObject[key];
        // Only include keys with non-empty arrays or non-undefined values
        if (
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && value !== undefined)
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    // Convert the cleaned object to a query string
    const queryString = qs.stringify(cleanedQueryObject, {
      arrayFormat: "comma",
    });

    return queryString;
  }

  static isNumeric(value?: string) {
    if (!value) return false;
    return /^-?\d+$/.test(value);
  }
}
export default StringUtils;
