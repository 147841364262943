import "./edit-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormattedMessage } from "react-intl";

export interface EditModalRef {
  // Context = what type of item needs to be deleted, item = name of item that needs to be deleted
  show: (context: string, key: string, item: string) => void;
  dismiss: () => void;
}

interface Props {
  onSave: (context: string, updatedValue: string) => void;
}

const EditModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const actionBtnRef = useRef<EmpButtonRef>(null);
  const [isDisabled, setDisabled] = useState(true);
  const infoRef = useRef<{ context: string; key: string; item: string }>({
    context: "",
    key: "",
    item: "",
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const [form, setForm] = useState<IFormGroup>({
    edit: new FormControl("text", [
      new RequiredValidator("This field is required"),
    ]),
  });

  const show = async (context: string, key: string, item: string) => {
    infoRef.current = { context, key, item };
    form.edit.forceUpdateValue(item);
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    form.edit.reset();
  };

  const editOnClick = async () => {
    try {
      actionBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;
      props.onSave(infoRef.current.key, form.edit.getValue());
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to edit ${infoRef.current.context}`
      );
    } finally {
      actionBtnRef.current?.setButtonState("default");
    }
  };

  const validate = (formControl: FormControl) => {
    setDisabled(formControl.isPristine());
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={
        <>
          <FormattedMessage id="cta_update" /> {infoRef.current.context}
        </>
      }
      showHeader={false}
      showHeaderBorder={false}
      showFooter={false}
      showFooterBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-edit-modal">
        <div className="title-wrapper">
          <h3>
            <FormattedMessage id="cta_update" /> {infoRef.current.context}
          </h3>
        </div>
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <EmpTextInput
            labelText={infoRef.current.context}
            placeholder={`Update ${infoRef.current.context}`}
            id={"edit"}
            onChange={(fc) => validate(fc)}
            formControl={form.edit}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 16,
          }}
        >
          <div style={{ width: "fit-content" }}>
            <EmpButton
              ref={actionBtnRef}
              onSubmit={() => {
                editOnClick();
              }}
              disabled={isDisabled}
              text={<FormattedMessage id="cta_save" />}
            />
          </div>
          <div style={{ width: "fit-content", marginRight: 8 }}>
            <EmpButton
              text={<FormattedMessage id="cta_cancel" />}
              buttonStyle="secondary"
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default EditModal;
