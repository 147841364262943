import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { EmpCampaignAccessControlView } from "../../components/shared/emp-campaign-access-control-view/emp-campaign-access-control-view";
import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import "./seller-campaign-listing-page.scss";
import { BrowseCampaignView } from "./views/browse-campaign-view";
import { BrowseTaskView } from "./views/browse-task-view";
import { EngagedTaskView } from "./views/engaged-task-view";
import { ExclusiveEngagedView } from "./views/exclusive-engaged-view";
import { SellerCompletedTasksView } from "./views/seller-completed-tasks-view";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import HelpCircleIcon from "../../components/icon/help-circle-icon";
import { Color } from "../../utilities/colors";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "../../components/modals/campaign-guide-modal";

export const SellerCampaignListingPage = () => {
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const [tabs, setTabs] = useState([
    {
      display: "Browse Campaigns",
      text: "Browse Campaigns",
      isSelected: false,
    },
    {
      display: "Browse Tasks",
      text: "Browse Tasks",
      isSelected: false,
    },
    {
      display: "Current Engagements",
      text: "Current Engagements",
      isSelected: false,
    },
    {
      display: "Completed Tasks",
      text: "Completed Tasks",
      isSelected: false,
    },
  ]);
  const location = useLocation();
  const [accessControlGranted, setAccessControlGranted] = useState(false);
  const [accessControlVisible, setAccessControlVisible] = useState(true);

  const [selectedTab, setSelectedTab] = useState<string>();
  const [displayMode, setDisplayMode] = useState<"default" | "exclusive">();
  useEffect(() => {
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    if (!tabs) return;
    setInitialTabs(tabs);
  }, [location]);

  const setInitialTabs = (tab: EmpTab[]) => {
    let fragment = window.location.hash.slice(1);
    let modifiedTab = tab;
    // unset all tabs
    modifiedTab.forEach((elem) => (elem.isSelected = false));
    if (fragment) {
      fragment = fragment.replaceAll("%20", " ");
      const fragmentHash = tab.find((elem) => elem.text === fragment);
      if (!fragmentHash)
        modifiedTab = tab.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      else fragmentHash.isSelected = true;
    } else {
      modifiedTab = tab.map((tab, index) =>
        index === 0 ? { ...tab, isSelected: true } : tab
      );
    }
    setTabs([...modifiedTab]);
  };

  return (
    <>
      <div className="emp-page-wrapper emp-responsive">
        {accessControlVisible && (
          <EmpCampaignAccessControlView
            resourceType="campaign-task-listing"
            role="seller"
            onGranted={(level) => {
              setDisplayMode(level);
              setAccessControlGranted(true);
            }}
            onUnmount={() => {
              setAccessControlVisible(false);
            }}
          />
        )}
        {accessControlGranted && (
          <>
            <CampaignGuideModal ref={campaignGuideModalRef} />
            <div className="bg-tint"></div>
            <div className="emp-page-content-no-padding emp-seller-campaign-listing-page">
              {/* To be displayed once access control has been complete */}

              <div className="header-wrapper">
                <h1 className="page-header">Browse Campaigns</h1>
                <EmpIconButton
                  onSubmit={() => {
                    campaignGuideModalRef.current?.show();
                  }}
                  buttonStyle="secondary"
                  suppressMobileView
                  icon={
                    <HelpCircleIcon
                      backgroundColor={Color.NEUTRAL[400]}
                      size={18}
                    />
                  }
                />
              </div>
              {displayMode === "exclusive" && <ExclusiveEngagedView />}
              {displayMode === "default" && (
                <>
                  <EmpSecondaryTabBar
                    tabs={tabs}
                    onChange={(tabLabel: string) => {
                      setSelectedTab(tabLabel);
                      window.location.hash = tabLabel;
                    }}
                  />
                  <div className="campaign-view-wrapper mt-4">
                    {selectedTab === "Current Engagements" && (
                      <EngagedTaskView
                        onRedirect={(dest) => {
                          setSelectedTab(dest);
                          window.location.hash = dest;
                        }}
                      />
                    )}
                    {selectedTab === "Browse Campaigns" && (
                      <BrowseCampaignView />
                    )}
                    {selectedTab === "Browse Tasks" && <BrowseTaskView />}
                    {selectedTab === "Completed Tasks" && (
                      <SellerCompletedTasksView />
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
