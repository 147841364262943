import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpContent from "../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import "./agency-creator-details-page.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpRoundedPill from "../../components/shared/emp-rounded-pill/emp-rounded-pill";
import InstagramView from "../../components/views/instagram-view";
import FacebookView from "../../components/views/facebook-view";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import UserApi from "../../api/user-msvc/user.api";
import SocialMediaApi from "../../api/social-integration-msvc/social-media.api";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import {
  EMPTY_PROFILE_IMG_URL,
  INVITATION_TYPE,
  SOCIAL_MEDIA_TYPE,
} from "../../constants/app.constants";
import EmpException from "../../exception/empException";
import TiktokView from "../../components/views/tiktok-view";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpButton from "../../components/shared/emp-button/emp-button";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import { CreatorInvitationEligibilityDto } from "../../model/onboarding/creator-invitation-eligibility.dto";
import InviteTalentsModal, {
  InviteTalentsModalRef,
} from "../../components/modals/invite-talents-modal";
import StringUtils from "../../utilities/string.util";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import { EmpImage } from "../../components/shared/emp-image/emp-image";
import FormFieldUtils from "../../utilities/form-field.util";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import XView from "../../components/views/x-view";
import XIcon from "../../components/icon/x-icon";

interface Props {}

interface InvitationEligibilityExtended
  extends CreatorInvitationEligibilityDto {
  isVisible: boolean;
}
export const AgencyCreatorDetailsPage = (prop: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const topLevelPath = useRef<string>();

  const intl = useIntl();
  const { creatorId } = useParams();
  const [currentCreatorId, setCurrentCreatorId] = useState<string>();
  const [creator, setCreator] = useState<UserDto>();
  const [smCreatorBrief, setSmCreatorBrief] = useState<SmBriefRespDto[]>([]);
  const [creatorAgencyInvitations, setCreatorAgencyInvitations] = useState<
    CreatorAgencyInvitationDto[]
  >([]);
  const [serviceOptions, setServiceOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [interestOptions, setInterestOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [creatorInfoCompleted, setCreatorInfoCompleted] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>();
  const [invitationEligibility, setInvitationEligibility] =
    useState<InvitationEligibilityExtended>();
  const inviteTalentModalRef = useRef<InviteTalentsModalRef>();
  const currAgencyOrgId = useRef<string>();

  /**
   * Fetches the creator by ID and updates the state with the response data.
   * @returns {Promise<void>} A Promise that resolves once the creator data is fetched and set.
   */
  const fetchCreatorById = useCallback(async () => {
    const resp = await UserApi.fetchCreatorById(creatorId!);
    setCreator(resp.data);
  }, [creatorId]);

  /**
   * Fetches the creator's social media specifications and updates the state with the response data.
   * Sets the selected platform based on the first available social media brief.
   * @returns {Promise<void>} A Promise that resolves once the social media data is fetched and set.
   */
  const fetchCreatorSocialMedia = useCallback(async () => {
    const resp = await SocialMediaApi.getSocialMediaSpecByCreatorId(creatorId!);
    for (let smBrief of resp.data) {
      if (smBrief.isAvailable) {
        setSelectedPlatform(smBrief.platform);
        break;
      } else {
        setSelectedPlatform(null);
      }
    }
    setSmCreatorBrief(resp.data);
  }, [creatorId]);

  /**
   * Checks the completion status of the creator's information and updates the state accordingly.
   * Fetches the creator's information if the status is "complete" and maps the options to the state.
   * @returns {Promise<void>} A Promise that resolves once the creator info status is checked and updated.
   */
  const checkCreatorInfoStatus = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.checkCreatorInfoCompletion(creatorId!);
      if (resp.data.statusMessage === "incomplete") {
        setCreatorInfoCompleted(false);
      } else if (resp.data.statusMessage === "complete") {
        setCreatorInfoCompleted(true);
        const creatorInfoResp = await CreatorInfoApi.fetchCreatorInfo(
          creatorId!
        );

        // Map to options
        setServiceOptions(creatorInfoResp.data.services);
        setInterestOptions(creatorInfoResp.data.interests);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [creatorId]);

  const getCreatorAgencies = useCallback(async () => {
    try {
      const resp = await UserApi.fetchCreatorAgenciesById(creatorId!);
      const creatorAgencies = resp.data;
      setCreatorAgencyInvitations(creatorAgencies);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator agencies"
      );
    }
  }, [creatorId]);

  const getInvitationEligibility = useCallback(async () => {
    try {
      const resp = await OnboardingApi.getCreatorInvitationEligibility(
        creatorId!
      );
      const eligibilityObj = resp.data;
      const isVisible =
        eligibilityObj.reason !== "not-agency" &&
        eligibilityObj.reason !== "creator-joined";

      setInvitationEligibility({
        isVisible: isVisible,
        ...eligibilityObj,
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch invitation eligibility"
      );
    }
  }, [creatorId]);

  /**
   * Fetches the user details and organization data.
   */
  const fetchAgencyOrg = useCallback(async () => {
    try {
      const response = await UserApi.fetchUser();
      const organisations = response.data.organisation!;
      currAgencyOrgId.current = organisations.find(
        (elem) => elem.type === "agency"
      )?.id;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    setSelectedPlatform(null);
    topLevelPath.current = location.pathname.split("/")[1];
    fetchCreatorById();
    fetchCreatorSocialMedia();
    checkCreatorInfoStatus();
    getCreatorAgencies();
    getInvitationEligibility();
    fetchAgencyOrg();
    setCurrentCreatorId(creatorId);
  }, [
    fetchCreatorById,
    fetchCreatorSocialMedia,
    checkCreatorInfoStatus,
    getCreatorAgencies,
    getInvitationEligibility,
    fetchAgencyOrg,
    location,
    creatorId,
  ]);

  // Setting the fragment
  useEffect(() => {
    if (smCreatorBrief.length === 0) return;
    const fragment = window.location.hash.slice(1);
    if (fragment) {
      const creatorSm = smCreatorBrief.find(
        (elem) => elem.platform === fragment
      );
      if (creatorSm) setSelectedPlatform(creatorSm.platform);
    }
  }, [smCreatorBrief]);

  /**
   * Renders platform icons based on the provided social media briefs.
   * @param {SmBriefRespDto[]} smBriefs - The social media briefs to render icons for.
   * @returns {JSX.Element} The JSX element representing the rendered platform icons.
   */
  const renderPlatformIcons = (smBriefs: SmBriefRespDto[]): JSX.Element => {
    return (
      <div className="platform-wrapper">
        {smBriefs.map((elem) => {
          return (
            <Fragment key={elem.platform}>
              {elem.platform === SOCIAL_MEDIA_TYPE.FACEBOOK &&
                elem.isAvailable && (
                  <FacebookIcon
                    size={16}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                )}
              {elem.platform === SOCIAL_MEDIA_TYPE.INSTAGRAM &&
                elem.isAvailable && (
                  <InstagramIcon
                    size={16}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                )}
              {elem.platform === SOCIAL_MEDIA_TYPE.TIKTOK &&
                elem.isAvailable && (
                  <TikTokIcon size={16} backgroundColor={Color.NEUTRAL[500]} />
                )}
              {elem.platform === SOCIAL_MEDIA_TYPE.X && elem.isAvailable && (
                <XIcon size={16} backgroundColor={Color.NEUTRAL[500]} />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const socialMediaOnClick = (platform: string) => {
    setSelectedPlatform(platform);
  };

  const recommendPartnershipStatus = (context: string) => {
    if (context === INVITATION_TYPE.AFFILIATE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeAffiliateOption",
      });
    else if (context === INVITATION_TYPE.EXCLUSIVE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeExclusiveOption",
      });
    throw new EmpException("Invalid invitation type");
  };

  return (
    <div className="emp-page-wrapper no-padding">
      <InviteTalentsModal
        ref={inviteTalentModalRef}
        onSave={() => {
          inviteTalentModalRef.current?.dismiss();
          setInvitationEligibility({
            isVisible: false,
            isEligible: false,
            reason: "invited",
          });
        }}
      />
      <div className="emp-page-content no-padding emp-agency-creator-details-page">
        <div className="content">
          <div className="header-wrapper header-wrapper-flex">
            <EmpIconButton
              buttonStyle="secondary"
              onSubmit={() => {
                navigate(-1);
              }}
              icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[600]} />}
            />
            {creator && <h1 className="page-header">{creator?.fullName}</h1>}
          </div>
          <div className="profile-info-wrapper">
            {creator && (
              <div className="profile-section">
                <div className="emp-card no-padding profile-card-border">
                  <div className="banner"></div>
                  <div className="pic-section">
                    {creator.imageType === "url" && (
                      <img
                        className="profile-pic"
                        srcSet={creator.imageResource}
                        alt="Creator"
                      />
                    )}
                    {creator.imageType === "avatar" && (
                      <div
                        className="avatar"
                        style={{ background: creator.imageResource }}
                      >
                        <span>{creator.initials}</span>
                      </div>
                    )}
                    <span className="creator-name-lbl mt-2">
                      {creator.fullName}
                    </span>
                  </div>
                  <div className="info-section mb-8">
                    <EmpContent
                      label={"COUNTRY"}
                      value={
                        <div className="country-wrapper">
                          <img
                            className="img-flag"
                            alt="country"
                            src={
                              COUNTRY_CONSTANTS[creator.creator.country][
                                "flag_1x1"
                              ]
                            }
                          />
                          <span>
                            {COUNTRY_CONSTANTS[creator.creator.country].name}
                          </span>
                        </div>
                      }
                    />

                    <div className="creator-info-wrapper">
                      <div className="creator-gender">
                        <EmpContent
                          label={"GENDER"}
                          value={StringUtils.capitalizeWords(
                            creator.creator.gender
                          )}
                        />
                      </div>
                      <div className="creator-age">
                        <EmpContent
                          label={"AGE"}
                          value={creator.creator.age.toString()}
                        />
                      </div>
                    </div>

                    {smCreatorBrief && (
                      <EmpContent
                        label={"PLATFORM"}
                        value={renderPlatformIcons(smCreatorBrief)}
                      />
                    )}
                    {!creatorInfoCompleted && (
                      <>
                        <EmpContent
                          label={"INTERESTS"}
                          value={"No Categories"}
                        />
                        <EmpContent
                          label={"SERVICES AVAILABLE"}
                          value={"Not specified"}
                        />
                      </>
                    )}

                    {creatorInfoCompleted && (
                      <>
                        <div className="interest-wrapper">
                          <EmpContent
                            label={"INTERESTS"}
                            value={
                              <div className="pills-wrapper">
                                {interestOptions.map((elem) => {
                                  return (
                                    <EmpRoundedPill
                                      key={elem.id}
                                      text={elem.label}
                                      clickable
                                    />
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                        <div className="service-wrapper">
                          <EmpContent
                            label={"SERVICES AVAILABLE"}
                            value={
                              <div className="pills-wrapper">
                                {serviceOptions.map((elem) => {
                                  return (
                                    <EmpRoundedPill
                                      key={elem.id}
                                      text={elem.label}
                                      clickable
                                    />
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                      </>
                    )}

                    {creatorAgencyInvitations && (
                      <>
                        {creatorAgencyInvitations.length === 0 && (
                          <EmpContent
                            label={"REPRESENTATIVE"}
                            value="Freelance Creator"
                          />
                        )}
                        {creatorAgencyInvitations.length > 0 && (
                          <EmpContent
                            label={"REPRESENTATIVE"}
                            value={
                              <div className="agency-wrapper">
                                {creatorAgencyInvitations.map((elem) => {
                                  return (
                                    <Fragment key={elem.id}>
                                      <div className="agency-card">
                                        <img
                                          className="agency-logo"
                                          srcSet={elem.agency.logo}
                                          alt={`${elem.agency.companyName} logo`}
                                        />
                                        <div className="agency-content">
                                          <div className="name-wrapper">
                                            <EmpLink
                                              onSubmit={async () => {
                                                if (!topLevelPath.current)
                                                  return;

                                                if (
                                                  topLevelPath.current ===
                                                  "agency"
                                                ) {
                                                  if (
                                                    elem.agency.id ===
                                                    currAgencyOrgId.current
                                                  ) {
                                                    navigate("/agency/profile");
                                                    return;
                                                  }
                                                  navigate(
                                                    `/agency/profile/${elem.agency.id}`
                                                  );
                                                } else if (
                                                  topLevelPath.current ===
                                                  "creator"
                                                ) {
                                                  navigate(
                                                    `/creator/agency/${elem.agency.id}`
                                                  );
                                                } else if (
                                                  topLevelPath.current ===
                                                  "brand"
                                                ) {
                                                  navigate(
                                                    `/brand/agency/${elem.agency.id}`
                                                  );
                                                }
                                              }}
                                              text={elem.agency.companyName}
                                            />
                                          </div>
                                          <div className="mt-1">
                                            <EmpPill
                                              backgroundColor={
                                                Color.NEUTRAL[150]
                                              }
                                              color={Color.NEUTRAL[700]}
                                              text={recommendPartnershipStatus(
                                                elem.invitationType
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="social-media-section">
              {/* Agency Invitation Selector */}
              {creator &&
                invitationEligibility &&
                invitationEligibility.isVisible && (
                  <div className="emp-card agency-invitation-card">
                    {invitationEligibility.isEligible && (
                      <h2 className="emp-card-header">
                        <FormattedMessage id="creatorDetailPage_agency_inviteCreatorHeader" />
                      </h2>
                    )}
                    {invitationEligibility.isEligible && (
                      <span className="block emp-card-description mt-2">
                        <FormattedMessage id="creatorDetailPage_agency_inviteCreatorDesc" />
                      </span>
                    )}

                    {!invitationEligibility.isEligible &&
                      invitationEligibility.reason === "creator-exclusive" && (
                        <h2 className="emp-card-header">
                          <FormattedMessage id="creatorDetailPage_agency_exclusiveCreatorHeader" />
                        </h2>
                      )}
                    {!invitationEligibility.isEligible &&
                      invitationEligibility.reason === "creator-exclusive" && (
                        <span className="block emp-card-description mt-2">
                          <FormattedMessage id="creatorDetailPage_agency_exclusiveCreatorDesc" />
                        </span>
                      )}

                    {invitationEligibility.isEligible && (
                      <EmpButton
                        onSubmit={() => {
                          inviteTalentModalRef.current?.show(
                            "existing",
                            creator.fullName,
                            creator.id
                          );
                        }}
                        buttonHeight="sm"
                        className="mt-2"
                        isFullWidth={false}
                        text={
                          <FormattedMessage id="creatorDetailPage_agency_inviteCreatorBtn" />
                        }
                      />
                    )}
                  </div>
                )}

              {/* Social Media Selector */}
              <div className="emp-card no-padding social-media-selector-card social-card-border">
                {smCreatorBrief.map((elem, index) => {
                  return (
                    <Fragment key={elem.platform}>
                      {elem.isAvailable && (
                        <button
                          onClick={() => {
                            socialMediaOnClick(elem.platform);
                          }}
                          className={`emp-button-reset social-media-option ${
                            elem.platform === selectedPlatform ? "active" : ""
                          }`}
                        >
                          <div className={`picture-section`}>
                            <EmpImage
                              src={elem.pictureUrl}
                              alt={`${elem.name} ${elem.platform} dp`}
                              fallback={EMPTY_PROFILE_IMG_URL}
                            />
                            <div className="social-media-bubble">
                              {elem.platform === SOCIAL_MEDIA_TYPE.FACEBOOK && (
                                <FacebookIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform ===
                                SOCIAL_MEDIA_TYPE.INSTAGRAM && (
                                <InstagramIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.TIKTOK && (
                                <TikTokIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.X && (
                                <XIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                            </div>
                          </div>
                          <div className="info-section">
                            <span className="handle-name-lbl">{elem.name}</span>
                            <span className="metric-lbl">
                              <FormattedMessage id="creatorDetailPage_socialMediaFollowers" />
                              : {FormFieldUtils.toCompact(elem.followers)}
                            </span>
                          </div>
                        </button>
                      )}
                      {!elem.isAvailable && (
                        <div key={elem.platform} className={`empty-option`}>
                          <div className={`picture-section`}>
                            <div className="placeholder">
                              {elem.platform === SOCIAL_MEDIA_TYPE.FACEBOOK && (
                                <FacebookIcon
                                  backgroundColor={Color.NEUTRAL[250]}
                                  size={20}
                                />
                              )}
                              {elem.platform ===
                                SOCIAL_MEDIA_TYPE.INSTAGRAM && (
                                <InstagramIcon
                                  backgroundColor={Color.NEUTRAL[250]}
                                  size={20}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.TIKTOK && (
                                <TikTokIcon
                                  backgroundColor={Color.NEUTRAL[250]}
                                  size={20}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.X && (
                                <XIcon
                                  backgroundColor={Color.NEUTRAL[250]}
                                  size={20}
                                />
                              )}
                            </div>
                          </div>
                          <div className="info-section">
                            <span className="handle-name-lbl">
                              {elem.platform}
                            </span>
                            <span className="metric-lbl">
                              <FormattedMessage id="creatorDetailPage_socialMediaNotConnected" />
                            </span>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              {selectedPlatform === SOCIAL_MEDIA_TYPE.FACEBOOK &&
                currentCreatorId && <FacebookView id={currentCreatorId} />}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.INSTAGRAM &&
                currentCreatorId && <InstagramView id={currentCreatorId} />}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.TIKTOK &&
                currentCreatorId && <TiktokView id={currentCreatorId} />}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.X && currentCreatorId && (
                <XView id={currentCreatorId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
