import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { PlatformReferralSpecsDto } from "../../model/support-referral/platform-referral/platform-referral-specs.resp.dto";
import { EmpInterceptor } from "../emp-interceptor";

const PlatformReferralApi = {
  fetchPlatformReferralDetails: async (
    referralCode: string
  ): Promise<ApiResponse<PlatformReferralSpecsDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<PlatformReferralSpecsDto[]>>(
      ENDPOINTS.referralAndSupportMsvc.platformReferral.getPlatformReferralDetails(
        referralCode
      )
    );
    return response.data;
  },
};
export default PlatformReferralApi;
