import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LockIcon from "../../components/icon/lock-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import "./creator-access-page.scss";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import { CreatorQuickAccessDetailedDto } from "../../model/onboarding/creator-quick-access-detailed.dto";
import EmpLink from "../../components/shared/emp-link/emp-link";
import AlertSquareIcon from "../../components/icon/alert-square";
import { Color } from "../../utilities/colors";
import { FormattedMessage, useIntl } from "react-intl";
import EmpLanguagePicker from "../../components/shared/emp-language-picker/emp-language-picker";
import { fetchAuthSession, getCurrentUser, signIn } from "aws-amplify/auth";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;

export const CreatorAccessPage = () => {
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const signInButtonRef = useRef<EmpButtonRef>();
  const [creatorAccessDetails, setCreatorAccessDetails] =
    useState<CreatorQuickAccessDetailedDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const hiddenEmailTextFieldRef = useRef<HTMLInputElement>(null);

  const formControlLabels = useMemo(() => {
    return {
      passwordPlaceholder: intl.formatMessage({
        id: "quickAccessSignInPage_passwordPlaceholder",
      }),
    };
  }, [intl]);

  const [form, setForm] = useState<IFormGroup>({
    password: new FormControl("text", [
      new RequiredValidator("Password is required"),
    ]),
  });

  async function checkIfSignedIn() {
    try {
      const session = await fetchAuthSession({ forceRefresh: true });
      if (session) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  const fetchLink = useCallback(async () => {
    try {
      const creatorAccessResp =
        await OnboardingApi.getCreatorQuickLinkInfoByLinkId(id!);
      const userId = creatorAccessResp.data.userId;

      if (await checkIfSignedIn()) {
        const cognitoUserId = (await getCurrentUser()).userId;
        if (cognitoUserId === userId) {
          navigate("/creator/home");
          return;
        }
      }
      hiddenEmailTextFieldRef.current!.value = creatorAccessResp.data.email;
      setCreatorAccessDetails(creatorAccessResp.data);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "Quick link does not exist") {
          navigate("/");
          return;
        }
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      setIsLoading(false);
      signInButtonRef.current?.setButtonState("default");
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchLink();
  }, [fetchLink]);

  /**
   * This function is executed when the user clicks on the submit button. It will check with the
   * server to see if the user is required to verify their email before proceeding with the sign-up process.
   *
   * @returns void
   */
  const submitOnClick = async (): Promise<void> => {
    try {
      signInButtonRef.current?.setButtonState("loading");
      if (!creatorAccessDetails) return;
      const email = creatorAccessDetails.email;
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      await signIn({ username: email, password: form.password.getValue() });
      if (await checkIfSignedIn()) {
        navigate("/creator/home");
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === "NotAuthorizedException") {
          setErrorMessage("Incorrect email or password");
          return;
        }
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      signInButtonRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="emp-creator-access-page">
      {isLoading && (
        <>
          <div className="emp-spinner large"></div>
        </>
      )}
      {!isLoading && (
        <>
          <img
            className="emplifive-logo"
            alt="emplifive logo"
            srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/emplifive-logo-black.svg"
          />
          <div className="onboarding-wrapper mt-4">
            <div className="form-section">
              <div className="language-picker-wrapper">
                <EmpLanguagePicker bordered />
              </div>

              <div className="inner mt-4">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  {creatorAccessDetails && (
                    <motion.div
                      variants={fadeInVariants}
                      initial="hidden"
                      animate="visible"
                      className="centralized-div"
                      transition={{ duration: 0.2, delay: 1 * animationDelay }}
                    >
                      {creatorAccessDetails.imageType === "avatar" && (
                        <div
                          className="avatar"
                          style={{
                            background: creatorAccessDetails.imageResource,
                          }}
                        >
                          <span className="initials">
                            {creatorAccessDetails.initials}
                          </span>
                        </div>
                      )}
                      {creatorAccessDetails.imageType === "url" && (
                        <img
                          className="profile-pic"
                          srcSet={creatorAccessDetails.imageResource}
                          alt="Creator"
                        />
                      )}

                      <div className="mt-3">
                        <span className="header-text">
                          <FormattedMessage
                            id="quickAccessSignInPage_header"
                            values={{
                              name: (
                                <span className="highlighted">
                                  {creatorAccessDetails.fullName}
                                </span>
                              ),
                            }}
                          />{" "}
                        </span>
                      </div>
                      <span className="secondary-lbl mt-1">
                        <FormattedMessage
                          id="quickAccessSignInPage_desc"
                          values={{
                            signIn: (
                              <EmpLink
                                onSubmit={() => {
                                  navigate("/");
                                }}
                                text={
                                  <FormattedMessage id="quickAccessSignInPage_signInLink" />
                                }
                              />
                            ),
                          }}
                        />
                      </span>
                    </motion.div>
                  )}
                  <div className="form-wrapper mt-6">
                    <input type={"hidden"} ref={hiddenEmailTextFieldRef} />
                    <motion.div
                      variants={fadeInVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ duration: 0.2, delay: 2 * animationDelay }}
                    >
                      <EmpTextInput
                        id={"password"}
                        autocomplete={false}
                        formControl={form.password}
                        type="password"
                        leftIconComponent={LockIcon}
                        placeholder={formControlLabels.passwordPlaceholder}
                      />
                    </motion.div>
                  </div>
                  {errorMessage && (
                    <div className="emp-error-message-wrapper mt-3">
                      <AlertSquareIcon
                        backgroundColor={Color.RED[600]}
                        size={16}
                        bottom={1}
                      />
                      <span>{errorMessage}</span>
                    </div>
                  )}
                  <motion.div
                    style={{ width: "100%" }}
                    variants={fadeInVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.2, delay: 5 * animationDelay }}
                  >
                    <EmpButton
                      onSubmit={() => {
                        submitOnClick();
                      }}
                      className="mt-6"
                      isFullWidth
                      ref={signInButtonRef}
                      text={
                        <FormattedMessage id="quickAccessSignInPage_signInBtn" />
                      }
                    />
                  </motion.div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
