import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import EmpContent from "../../components/shared/emp-content/emp-content";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import KycApi from "../../api/payment-msvc/kyc.api";
import ToastUtils from "../../utilities/toast-utils";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";
import InfoCircleIcon from "../../components/icon/info-circle-icon";
import { Color } from "../../utilities/colors";
import SocialMediaApi from "../../api/social-integration-msvc/social-media.api";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../../components/shared/emp-checkbox/emp-checkbox";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;

interface Props {
  kycInfo?: KycDto;
  onSave: () => void;
  editProfessionalDetails: () => void;
  editPersonalInfo: () => void;
  editPayment: () => void;
}

export const KycCreatorSummary = (props: Props) => {
  const {
    kycInfo,
    onSave,
    editProfessionalDetails,
    editPersonalInfo,
    editPayment,
  } = props;
  const intl = useIntl();
  const submitApplicationBtnRef = useRef<EmpButtonRef>();
  const retractApplicationBtnRef = useRef<EmpButtonRef>();
  const [isKycCompleted, setIsKycCompleted] = useState<boolean>(true);

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());
  const [fieldDisputeSectionList, setFieldDisputeSectionList] = useState<
    string[]
  >([]);

  const [socialMediaSpecs, setSocialMediaSpecs] = useState<SmBriefRespDto[]>(
    []
  );

  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const getUserSocialMediaMetrics = useCallback(async () => {
    try {
      const resp = await SocialMediaApi.getSocialMediaSpecs();
      setSocialMediaSpecs(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  }, []);

  useEffect(() => {
    getUserSocialMediaMetrics();
  }, [getUserSocialMediaMetrics]);

  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
      const uniqueSectionSet = new Set(
        data.fieldDisputes.map((elem) => elem.section)
      );
      setFieldDisputeSectionList(Array.from(uniqueSectionSet));
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);

  useEffect(() => {
    if (!kycInfo) return;
    if (kycInfo.status === "rejected") {
      fetchKycRejection();
    }
    setIsKycCompleted(kycInfo.hasCompleted);
  }, [kycInfo, fetchKycRejection]);

  const submitKycApplication = async () => {
    try {
      submitApplicationBtnRef.current?.setButtonState("loading");
      const resp = await KycApi.submitKyc();
      if (resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_summary_successToastHeader",
          }),
          intl.formatMessage({ id: "kycFormSection_summary_successToastDesc" })
        );
        onSave();
      }
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(err, "Please try again later");
    } finally {
      submitApplicationBtnRef.current?.setButtonState("default");
    }
  };

  const retractKycApplication = async () => {
    try {
      retractApplicationBtnRef.current?.setButtonState("loading");
      const resp = await KycApi.retractKyc();
      if (resp.data.status === "success") {
        ToastUtils.success(
          "KYC Application retracted",
          "You may submit the application again."
        );
        onSave();
      }
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(err, "Please try again later");
    } finally {
      retractApplicationBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="kyc-form">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
      >
        {kycInfo && kycInfo.hasCompleted && (
          <>
            <h2 className="form-header">
              <FormattedMessage id="kycInfoSection_summary_submittedHeader" />
            </h2>
            <p className="emp-paragraph mt-3">
              <FormattedMessage id="kycInfoSection_summary_submittedDesc" />
            </p>

            {kycInfo.status === "in-review" && (
              <div className="form-header-card mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_reviewCardHeader" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_reviewCardDesc" />
                </p>
                <EmpButton
                  className="mt-2"
                  buttonHeight="sm"
                  text={
                    <FormattedMessage id="kycFormSection_summary_amendFormBtn" />
                  }
                  ref={retractApplicationBtnRef}
                  onSubmit={() => {
                    retractKycApplication();
                  }}
                  isFullWidth={false}
                />
              </div>
            )}
            {kycInfo.status === "approved" && (
              <div className="form-header-card approved mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_approvedCardTitle" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_approvedCardDesc" />
                </p>
              </div>
            )}
          </>
        )}
        {kycInfo && !kycInfo.hasCompleted && (
          <>
            <h2 className="form-header">
              <FormattedMessage id="kycFormSection_summary_reviewHeader" />
            </h2>
            <p className="emp-paragraph mt-3">
              <FormattedMessage id="kycFormSection_summary_reviewDesc" />
            </p>
            {kycInfo.status === "rejected" && (
              <div className="form-header-card rejected mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_rejectedCardTitle" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_rejectedCardDesc" />
                </p>
                {kycRejection && (
                  <EmpContent
                    className="mt-3"
                    label={"REVIEWER COMMENTS"}
                    value={
                      <p className="emp-paragraph">{kycRejection.remarks}</p>
                    }
                  />
                )}
              </div>
            )}
          </>
        )}
      </motion.div>
      {kycInfo && (
        <div className="mt-6 form-control-wrapper">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_professionalDetailsHeader" />
                  </span>
                </div>
                <EmpButton
                  buttonHeight="sm"
                  buttonStyle={"primary"}
                  isFullWidth={false}
                  disabled={isKycCompleted}
                  onSubmit={() => {
                    editProfessionalDetails();
                  }}
                  text={<FormattedMessage id="cta_edit" />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_industry`)}
                  label={
                    <FormattedMessage id="kycFormSection_industryContentLabel" />
                  }
                  value={kycInfo.kycCreatorInfo?.industry ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_socialMediaAccountSubheader" />
                </span>
              </div>
              <div className="pb-4 ph-4">
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_socialMedia`)}
                  label={
                    <FormattedMessage id="kycFormSection_connectedSocialMediaContentLabel" />
                  }
                  value={
                    <>
                      {socialMediaSpecs.map((elem, index) => {
                        return (
                          <div
                            key={elem.platform}
                            className="platform-card mt-2"
                          >
                            {/* Empty State */}
                            {!elem.isAvailable && (
                              <div className="platform-logo">
                                {elem.platform === "Facebook" && (
                                  <FacebookIcon
                                    size={28}
                                    backgroundColor={Color.NEUTRAL[300]}
                                  />
                                )}
                                {elem.platform === "Instagram" && (
                                  <InstagramIcon
                                    size={28}
                                    backgroundColor={Color.NEUTRAL[300]}
                                  />
                                )}
                                {elem.platform === "TikTok" && (
                                  <TikTokIcon
                                    size={28}
                                    backgroundColor={Color.NEUTRAL[300]}
                                  />
                                )}
                              </div>
                            )}
                            {!elem.isAvailable && (
                              <div className="content">
                                <span className="status-lbl">
                                  <FormattedMessage id="bindSocialMediaStep_notConnectedLabel" />
                                </span>
                              </div>
                            )}

                            {/* Filled State */}
                            {elem.isAvailable && (
                              <>
                                <div className="left-elem">
                                  <div className="social-media-img-wrapper">
                                    <div className="social-media-logo-placeholder">
                                      {elem.platform === "Facebook" && (
                                        <FacebookIcon
                                          size={12}
                                          backgroundColor={Color.NEUTRAL[0]}
                                        />
                                      )}
                                      {elem.platform === "Instagram" && (
                                        <InstagramIcon
                                          size={12}
                                          backgroundColor={Color.NEUTRAL[0]}
                                        />
                                      )}
                                      {elem.platform === "TikTok" && (
                                        <TikTokIcon
                                          size={12}
                                          backgroundColor={Color.NEUTRAL[0]}
                                        />
                                      )}
                                    </div>
                                    <img
                                      alt={`${elem.name} profile`}
                                      className="social-media-image"
                                      src={elem.pictureUrl}
                                    />
                                  </div>
                                  <div className="content">
                                    <span className="status-lbl">
                                      {elem.name}
                                    </span>
                                    <span className="action-lbl">
                                      <FormattedMessage id="bindSocialMediaStep_socialMediaFollowers" />
                                      : {elem.followers}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  }
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_personalInformationHeader" />
                  </span>
                </div>

                <EmpButton
                  buttonHeight="sm"
                  buttonStyle={"primary"}
                  disabled={isKycCompleted}
                  onSubmit={() => {
                    editPersonalInfo();
                  }}
                  isFullWidth={false}
                  text={<FormattedMessage id="cta_edit" />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_firstNameContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_firstName`)}
                  value={kycInfo.kycCreatorInfo?.firstName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_lastNameContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_lastName`)}
                  value={kycInfo.kycCreatorInfo?.lastName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_emailContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_email`)}
                  value={kycInfo.kycCreatorInfo?.email ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_mobileNumberContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_mobileNumber`)}
                  value={
                    kycInfo.kycCreatorInfo?.mobileNumber
                      ? `${kycInfo.kycCreatorInfo?.dialCode} ${kycInfo.kycCreatorInfo?.mobileNumber}`
                      : "-"
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_identificationNoContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycCreatorInfo_identificationNumber`
                  )}
                  value={kycInfo.kycCreatorInfo?.identificationNumber ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_nationalityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_nationality`)}
                  value={kycInfo.kycCreatorInfo?.nationality ?? "-"}
                />

                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_streetAddressContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_streetAddress`)}
                  value={kycInfo.kycCreatorInfo?.streetAddress ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_cityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_city`)}
                  value={kycInfo.kycCreatorInfo?.city ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_postalCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycCreatorInfo_postalCode`)}
                  value={kycInfo.kycCreatorInfo?.postalCode ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_identificationDocSubheader" />
                </span>
              </div>
              <div className="review-info-section">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_proofOfIdentificationContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycCreatorInfo_identificationDocumentName`
                  )}
                  value={
                    kycInfo.kycCreatorInfo?.identificationDocumentName ?? "-"
                  }
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_identificationDocSubheader" />
                  </span>
                  {fieldDisputeSectionList.includes("kycPayment") && (
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.RED[400]}
                    />
                  )}
                </div>
                <EmpButton
                  disabled={isKycCompleted}
                  buttonHeight="sm"
                  buttonStyle={"primary"}
                  isFullWidth={false}
                  onSubmit={() => {
                    editPayment();
                  }}
                  text={<FormattedMessage id="cta_edit" />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_firstNameContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_firstName`)}
                  value={kycInfo.kycPayment?.firstName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_lastNameContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_lastName`)}
                  value={kycInfo.kycPayment?.lastName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountHolderContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountHolderName`)}
                  value={kycInfo.kycPayment?.accountHolderName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_countryCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_countryCode`)}
                  value={kycInfo.kycPayment?.countryCode ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_streetAddressContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_streetAddress`)}
                  value={kycInfo.kycPayment?.streetAddress ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_cityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_city`)}
                  value={kycInfo.kycPayment?.city ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_stateContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_state`)}
                  value={kycInfo.kycPayment?.state ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_postalCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_postalCode`)}
                  value={kycInfo.kycPayment?.postalCode ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_bankInformationSubheader" />
                </span>
              </div>
              <div className="review-info-section">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_entityTypeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_entityType`)}
                  value={kycInfo.kycPayment?.entityType ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountTypeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountType`)}
                  value={kycInfo.kycPayment?.accountType ?? "-"}
                />

                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountNumberContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountNumber`)}
                  value={kycInfo.kycPayment?.accountNumber ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_bankCountryCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_bankCountryCode`)}
                  value={kycInfo.kycPayment?.bankCountryCode ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_swiftCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_swiftCode`)}
                  value={kycInfo.kycPayment?.swiftCode ?? "-"}
                />
              </div>
            </div>
          </motion.div>

          {kycInfo && !kycInfo.hasCompleted && (
            <section className="acknowledgement-wrapper mt-4">
              <div className="card">
                <div className="intro-wrapper">
                  {/* <InfoCircleIcon backgroundColor={Color.PRIMARY[600]} size={16} /> */}
                  <span>
                    <FormattedMessage id="kycFormSection_summary_declarationHeader" />
                  </span>
                </div>
                <div className="control-wrapper mt-2">
                  <div className="checkbox-wrapper">
                    <EmpCheckbox
                      onChange={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                      ref={ackCheckboxRef}
                      id={"acknowledgement"}
                    />
                  </div>
                  <div className="checkbox-text-wrapper">
                    <div
                      onClick={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                    >
                      <span className={ackIsChecked ? "checked" : ""}>
                        <FormattedMessage id="kycFormSection_summary_declarationDesc" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {kycInfo && !kycInfo.hasCompleted && (
            <motion.div
              className="review-btn-wrapper"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
            >
              <EmpButton
                ref={submitApplicationBtnRef}
                isFullWidth={false}
                text={
                  <FormattedMessage id="kycFormSection_summary_submitApplication" />
                }
                disabled={!ackIsChecked}
                onSubmit={submitKycApplication}
              />
            </motion.div>
          )}
        </div>
      )}
    </div>
  );
};
