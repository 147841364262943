import "./brand-unbind-modal.scss";

import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import EmpException from "../../../exception/empException";
import { UserDto } from "../../../model/user-management/user.dto";
import UserUtils from "../../../utilities/user-utils";
import { AppContext } from "../../../context/app.context";
import SocialMediaManagementApi from "../../../api/smm-msvc/social-media-management.api";
import ToastUtils from "../../../utilities/toast-utils";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import { Color } from "../../../utilities/colors";
import XIcon from "../../../components/icon/x-icon";

export interface BrandUnbindModalRef {
  show: (instagramConnectedPage: SmConnectionBriefRespDto) => void;
  dismiss: () => void;
}

interface Props {
  fetchSocialMediaConnections: () => void;
  onDismiss?: () => void;
}

const BrandUnbindModal = forwardRef((props: Props, ref) => {
  const { fetchSocialMediaConnections } = props;

  const unbindBtnRef = useRef<EmpButtonRef>();
  const userRef = useRef<UserDto>();

  const [visible, setVisible] = useState<boolean>(false);
  const [socialConnected, setSocialConnected] =
    useState<SmConnectionBriefRespDto | null>();
  const [platform, setPlatform] = useState<string>("");

  const { user: userContext } = useContext(AppContext);

  useImperativeHandle(ref, () => ({
    show: (socialMediaConnectedPage: SmConnectionBriefRespDto | null) => {
      setSocialConnected(socialMediaConnectedPage);
      setPlatform(socialMediaConnectedPage?.platform || "");
      setVisible(true);
    },
    dismiss: () => {
      setSocialConnected(null);
      setVisible(false);
      setPlatform("");
    },
  }));

  const dismiss = () => {
    setSocialConnected(null);
    setVisible(false);
    setPlatform("");
  };

  const validateOrganisationMembership = (user: UserDto) => {
    // Check if 'organisation' is defined and has at least one item
    if (!user.organisation || user.organisation.length === 0) {
      throw new EmpException("Not an organisation");
    }
  };

  const onUnlink = async () => {
    try {
      unbindBtnRef.current?.setButtonState("loading");

      if (!userRef.current) {
        const user = await UserUtils.fetchUser(userContext);
        userRef.current = user;
      }
      if (!socialConnected) throw new EmpException("No social connected");

      const { socialMediaUserId } = socialConnected;
      const user = userRef.current!;
      validateOrganisationMembership(user);

      if (user.organisation) {
        const brandId = user.organisation[0].id;

        const resp = await SocialMediaManagementApi.unlinkSocialMedia(
          brandId,
          platform,
          socialMediaUserId
        );
        if (resp.data.status === "success") {
          ToastUtils.success(
            "Successfully Unlinked",
            `Your ${platform} account has been unlinked`
          );
          unbindBtnRef.current?.setButtonState("default");
          fetchSocialMediaConnections();
        }
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to unlink ${platform}`
      );
      unbindBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={
        <FormattedMessage
          id="brandUnbindModal_header"
          values={{ selection: platform }}
        />
      }
      showFooter={false}
      showFooterBorder={false}
      onClose={() => {
        dismiss();
      }}
      showHeaderBorder={false}
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-brand-unbind-modal">
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="brandUnbindModal_title" />
          </h2>
          <p className="description mb-1">
            <FormattedMessage id="brandUnbindModal_desc1" />
          </p>
          <p className="description mt-0">
            <FormattedMessage id="brandUnbindModal_desc2" />
          </p>
        </div>

        {/* Facebook Pages Mapper */}

        <div className="profile-wrapper">
          <div className={`picture-section`}>
            <img
              src={socialConnected?.pictureUrl || EMPTY_PROFILE_IMG_URL}
              alt={`${socialConnected?.name} ${socialConnected?.platform} dp`}
            />
            <div
              className={`social-media-bubble ${socialConnected?.platform.toLowerCase()}`}
            >
              {socialConnected?.platform.toLowerCase() === "facebook" && (
                <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
              )}
              {socialConnected?.platform.toLowerCase() === "instagram" && (
                <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
              )}
              {socialConnected?.platform.toLowerCase() === "tiktok" && (
                <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
              )}
              {socialConnected?.platform.toLowerCase() === "x" && (
                <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
              )}
            </div>
          </div>
          <div className="info-section">
            <span className="handle-name-lbl">{socialConnected?.name}</span>
            <span className="metric-lbl">
              Followers: {socialConnected?.followers}
            </span>
          </div>
        </div>

        <div className="footer-btn-wrapper">
          <EmpButton
            text={<FormattedMessage id="cta_cancel" />}
            isFullWidth={false}
            buttonStyle="secondary"
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            onSubmit={() => onUnlink()}
            isFullWidth={false}
            text={"Unlink"}
            buttonHeight="md"
            ref={unbindBtnRef}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default BrandUnbindModal;
