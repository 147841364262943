import { useCallback, useEffect, useState } from "react";
import { MediaSnapshotDto } from "../../model/campaign/media-snapshot.dto";
import "./pow-modal-listing.scss";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import {
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE_DISPLAY,
  PROOF_OF_WORK_MEDIA_TYPE,
} from "../../constants/app.constants";
import EyeIcon from "../icon/eye-icon";
import FacebookIcon from "../icon/facebook-icon";
import FileCheckIcon from "../icon/file-check-icon";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import { Color } from "../../utilities/colors";
import { DateUtil } from "../../utilities/date";
import EmpContent from "../shared/emp-content/emp-content";
import EmpLink from "../shared/emp-link/emp-link";
import { EmpImage } from "../shared/emp-image/emp-image";
import { ProfileSnapshotDto } from "../../model/campaign/profile-snapshot.dto";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import BrokenLinkIcon from "../icon/broken-link-icon";
import EmpPill from "../shared/EmpPill/EmpPill";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  mediaSnapshots: MediaSnapshotDto[];
  profileSnapshot?: ProfileSnapshotDto;
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
}

const picOrVideoType = [
  PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE,
  PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO,
];

export const FacebookPowFallback = (props: Props) => {
  const {
    mediaSnapshots,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  } = props;
  const [imageItem, setImageItem] = useState<MediaSnapshotDto>();
  const [captionItem, setCaptionItem] = useState<MediaSnapshotDto>();
  useEffect(() => {
    const foundCaptionItem = mediaSnapshots.find(
      (elem) => elem.type === "text"
    );
    setCaptionItem(foundCaptionItem);

    const foundImageItem = mediaSnapshots.find((elem) => elem.type === "image");
    setImageItem(foundImageItem);
  }, [mediaSnapshots]);

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        {picOrVideoType.includes(evidenceRecord.mediaType) && imageItem && (
          <img
            className="preview-image"
            alt={"Facebook Post"}
            src={imageItem.mediaUrl}
          />
        )}
        {evidenceRecord.mediaType === PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT &&
          captionItem && (
            <div className="preview-text">
              <p className="text">{captionItem.caption}</p>
            </div>
          )}
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE && (
                <span className="card-title">Facebook Image Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO && (
                <span className="card-title">Facebook Video Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
                <span className="card-title">Facebook Post </span>
              )}
              <div className="metrics-bar mt-2">
                <div className="metric-wrapper">
                  <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  {evidenceRecord.metricSnapshot?.impression ?? "-"}
                </div>
                <div className="metric-wrapper">
                  <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>
                    {evidenceRecord.metricSnapshot?.reactionCount ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  {evidenceRecord.metricSnapshot?.commentCount ?? "-"}
                </div>
                <div className="metric-wrapper">
                  <div className="download-btn-wrapper">
                    <EmpLink
                      href={evidenceRecord.mediaUrl}
                      newTab
                      text={"Link to Post"}
                      showNewTabIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={BrokenLinkIcon}
              {...PILL_COLORS.gray}
              text={"Archived"}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, imageItem, captionItem]);

  const renderPowCard = useCallback(() => {
    return (
      <div className="image-wrapper">
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
            <div className="social-media-platform-badge">
              <FacebookIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
        </div>
        <div className="post-wrapper">
          {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
            <div
              className={`additional-deliverable-wrapper-badge ${
                evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT
                  ? "anchor-bottom"
                  : ""
              }`}
            >
              <span className="badge-text">
                +{noOfOtherEvidenceRecords - 1}
              </span>
              <FileCheckIcon
                strokeWidth={2.5}
                size={12}
                backgroundColor={Color.NEUTRAL[300]}
              />
            </div>
          )}
          {picOrVideoType.includes(evidenceRecord.mediaType) && imageItem && (
            <img
              className="attachment-img"
              alt="Uploaded evidence"
              src={imageItem.mediaUrl}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT &&
            captionItem && (
              <div className=" attachment-text ">
                <p className="emp-paragraph">{captionItem.caption}</p>
              </div>
            )}
        </div>

        <div className="sm-metrics-section">
          <div className="metrics-bar">
            <div className="metric-wrapper">
              <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.reactionCount ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [
    evidenceRecord,
    imageItem,
    captionItem,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  ]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
            <div className="social-media-platform-badge">
              <FacebookIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          {picOrVideoType.includes(evidenceRecord.mediaType) && imageItem && (
            <img
              className="sm-image"
              alt="Uploaded evidence"
              src={imageItem.mediaUrl}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT &&
            captionItem && (
              <p className="sm-paragraph ">{captionItem.caption}</p>
            )}
        </div>
        <div className="sm-metrics">
          <div className="metric-wrapper">
            <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{evidenceRecord.metricSnapshot?.reactionCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <EyeIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, imageItem, captionItem, profileSnapshot]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {picOrVideoType.includes(evidenceRecord.mediaType) && imageItem && (
          <EmpImage alt="Proof of work preview" src={imageItem.mediaUrl} />
        )}
        {evidenceRecord.mediaType ===
          PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
          <div className="text-container">Text</div>
        )}
      </>
    );
  }, [imageItem, evidenceRecord]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
