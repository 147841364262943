import "./exclusive-partnership-confirmation-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { empDelay } from "../../utilities/delay";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { FormattedMessage } from "react-intl";

export interface ExclusivePartnershipConfirmationModalRef {
  show: (
    creator: UserDto,
    agencyInvitation: CreatorAgencyInvitationDto
  ) => void;
  dismiss: () => void;
}

interface Props {
  onSave: (agencyInvitation: CreatorAgencyInvitationDto) => void;
}

const ExclusivePartnershipConfirmationModal = forwardRef(
  (props: Props, ref) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [agency, setAgency] = useState<OrganisationRespDto>();
    const [creator, setCreator] = useState<UserDto>();
    const [agencyInvitation, setAgencyInvitation] =
      useState<CreatorAgencyInvitationDto>();

    useImperativeHandle(ref, () => {
      return {
        show,
        dismiss,
      };
    });

    const show = async (
      creator: UserDto,
      agencyInvitation: CreatorAgencyInvitationDto
    ) => {
      setAgencyInvitation(agencyInvitation);
      setAgency(agencyInvitation.agency);
      setCreator(creator);
      setVisible(true);
    };

    const dismiss = async () => {
      setVisible(false);
      await empDelay(300);
      setAgencyInvitation(undefined);
      setAgency(undefined);
    };

    return (
      <EmpModal
        visible={visible}
        setVisible={setVisible}
        showHeader={false}
        showFooter={false}
        showFooterBorder={false}
        onClose={dismiss}
        showHeaderBorder={false}
        size={"sm"}
      >
        {/* This is body */}
        <div>
          {agency && creator && (
            <div className="exclusive-partnership-confirmation-modal">
              <div className="content-section">
                <div className="disclaimer-section">
                  <div className="picture-wrapper">
                    <img
                      className="org-image"
                      alt={agency.companyName}
                      src={agency.logo}
                    />
                    <span className="plus">+</span>
                    {creator.imageType === "avatar" && (
                      <div
                        className="avatar"
                        style={{ background: creator.imageResource }}
                      >
                        <span className="initials">{creator.initials}</span>
                      </div>
                    )}
                    {creator.imageType === "url" && (
                      <img
                        className="profile"
                        alt={creator.fullName}
                        src={creator.imageResource}
                      />
                    )}
                  </div>
                  <span className="heading">
                    <FormattedMessage
                      id="exclusivePartnershipDisclaimerModal_header"
                      values={{
                        agency: (
                          <span className="highlighted">
                            {agency.companyName}
                          </span>
                        ),
                      }}
                    />{" "}
                  </span>
                  <p className="disclaimer-description">
                    {" "}
                    <FormattedMessage
                      id="exclusivePartnershipDisclaimerModal_desc"
                      values={{ agency: agency.companyName }}
                    />
                  </p>
                </div>
                <div className={"button-wrapper"}>
                  <EmpButton
                    buttonStyle={"secondary"}
                    isFullWidth={false}
                    onSubmit={() => {
                      dismiss();
                    }}
                    className="mt-8"
                    text={<FormattedMessage id="cta_cancel" />}
                  />

                  <EmpButton
                    isFullWidth={false}
                    onSubmit={() => {
                      props.onSave(agencyInvitation!);
                      dismiss();
                    }}
                    className="mt-8"
                    text={
                      <FormattedMessage id="exclusivePartnershipDisclaimerModal_proceedToJoinBtn" />
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* This is footer */}
        <div></div>
      </EmpModal>
    );
  }
);

export default ExclusivePartnershipConfirmationModal;
