import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import CreatorInfoApi from "../../../api/user-msvc/creator-info.api";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import UserApi from "../../../api/user-msvc/user.api";
import CreatorJoinAgencyConfirmationModal, {
  CreatorJoinAgencyConfirmationModalRef,
} from "../../../components/modals/creator-join-agency-confirmation-modal";
import CreatorLeaveAgencyConfirmationModal, {
  CreatorLeaveAgencyConfirmationModalRef,
} from "../../../components/modals/creator-leave-agency-confirmation-modal";
import ExclusivePartnershipConfirmationModal, {
  ExclusivePartnershipConfirmationModalRef,
} from "../../../components/modals/exclusive-partnership-confirmation-modal";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { INVITATION_TYPE } from "../../../constants/app.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import EmpException from "../../../exception/empException";
import { CreatorAgencyInvitationDto } from "../../../model/onboarding/creator-agency-invitation-resp.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import "./creator-partnering-agencies-card.scss";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

interface Props {
  onSave: () => void;
  creator: UserDto;
}

export const CreatorPartneringAgenciesCard = (props: Props) => {
  const { creator } = props;
  const [agencyInvitations, setAgencyInvitations] = useState<
    CreatorAgencyInvitationDto[]
  >([]);
  const creatorJoinAgencyConfirmationModalRef =
    useRef<CreatorJoinAgencyConfirmationModalRef>();
  const creatorLeaveAgencyConfirmationModalRef =
    useRef<CreatorLeaveAgencyConfirmationModalRef>();
  const exclusivePartnershipConfirmationModalRef =
    useRef<ExclusivePartnershipConfirmationModalRef>();

  const navigate = useNavigate();

  const getAgencyInvitation = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.getCreatorPartnerships(creator.id);
      setAgencyInvitations(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to get agency invitations"
      );
    }
  }, []);

  useEffect(() => {
    getAgencyInvitation();
  }, [getAgencyInvitation]);

  /**
   * Accepts the agency invitation with the specified ID and displays a success toast message.
   *
   * @param {CreatorAgencyInvitationDto} creatorAgencyInvitation - agency invitation.
   * @param {boolean} skipConfirmation - called from the modal directly
   * @returns {Promise<void>} - A Promise that resolves once the invitation is accepted.
   * @throws {Error} - If there's an error while accepting the invitation.
   */
  const acceptAgencyInvitation = async (
    creatorAgencyInvitation: CreatorAgencyInvitationDto,
    skipConfirmation?: boolean
  ) => {
    try {
      const canSkipConfirmation = skipConfirmation ?? false;
      if (!canSkipConfirmation) {
        if (requiresConfirmation(creatorAgencyInvitation)) return;
      }
      const resp = await OnboardingApi.creatorAcceptInvitation({
        creatorAgencyInvitationId: creatorAgencyInvitation.id,
      });
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Invitation Accepted",
          `You have accepted the invitation to join ${creatorAgencyInvitation.agency.companyName}`
        );
        getAgencyInvitation();
        props.onSave();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to accept invitation"
      );
    }
  };

  /**
   * Rejects the agency invitation with the specified ID and displays a success toast message.
   *
   * @param {string} invitationId - The ID of the agency invitation.
   * @param {string} agencyName - The name of the agency.
   * @returns {Promise<void>} - A Promise that resolves once the invitation is rejected.
   * @throws {Error} - If there's an error while rejecting the invitation.
   */
  const rejectAgencyInvitation = async (
    invitationId: string,
    agencyName: string
  ) => {
    try {
      const resp = await OnboardingApi.creatorRejectInvitation({
        creatorAgencyInvitationId: invitationId,
      });
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Invitation Rejected",
          `You have rejected the invitation to join ${agencyName}`
        );
        getAgencyInvitation();
        props.onSave();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to reject invitation"
      );
    }
  };

  /**
   * Leaves the agency.
   *
   * @param {CreatorAgencyInvitationDto} creatorAgencyInvitation - The creator agency invitation.
   * @returns {Promise<void>} A promise that resolves once the agency is left successfully.
   */
  const leaveAgency = async (
    creatorAgencyInvitation: CreatorAgencyInvitationDto
  ) => {
    try {
      const resp = await UserApi.creatorLeaveAgency(
        creatorAgencyInvitation.agency.id
      );
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Left Agency",
          `You have successfully left ${creatorAgencyInvitation.agency.companyName}`
        );
        getAgencyInvitation();
        props.onSave();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to leave agency");
    }
  };

  const requiresConfirmation = (
    selectedAgency: CreatorAgencyInvitationDto
  ): boolean => {
    if (selectedAgency.invitationType === INVITATION_TYPE.EXCLUSIVE) {
      if (
        agencyInvitations.findIndex(
          (elem) =>
            elem.invitationType === INVITATION_TYPE.AFFILIATE &&
            elem.status === "ACCEPTED"
        ) > -1
      ) {
        creatorJoinAgencyConfirmationModalRef.current?.show(selectedAgency);
        return true;
      }
      exclusivePartnershipConfirmationModalRef.current?.show(
        creator,
        selectedAgency
      );
      return true;
    }
    return false;
  };

  const recommendPartnershipStatus = (context: string) => {
    if (context === INVITATION_TYPE.AFFILIATE)
      return (
        <FormattedMessage id="partneringAgenciesCard_affiliateInvitation" />
      );
    else if (context === INVITATION_TYPE.EXCLUSIVE)
      return (
        <FormattedMessage id="partneringAgenciesCard_exclusiveInvitation" />
      );
    throw new EmpException("Invalid invitation type");
  };

  // Use effect to initialize subscription for language.
  useEffect(() => {
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.PARTNERSHIP,
      getAgencyInvitation
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [getAgencyInvitation]);

  return (
    <>
      <CreatorJoinAgencyConfirmationModal
        ref={creatorJoinAgencyConfirmationModalRef}
        onSave={(creatorAcceptInvitation) => {
          acceptAgencyInvitation(creatorAcceptInvitation, true);
        }}
      />
      <CreatorLeaveAgencyConfirmationModal
        ref={creatorLeaveAgencyConfirmationModalRef}
        onSave={(creatorAcceptInvitation) => {
          leaveAgency(creatorAcceptInvitation);
        }}
      />

      <ExclusivePartnershipConfirmationModal
        ref={exclusivePartnershipConfirmationModalRef}
        onSave={(creatorAcceptInvitation) => {
          acceptAgencyInvitation(creatorAcceptInvitation, true);
        }}
      />

      {
        <div className="dashboard-card talent-agency">
          {agencyInvitations.length > 0 && (
            <>
              <h3 className="card-header">
                <FormattedMessage id="partneringAgenciesCard_header" />
              </h3>
              <p className="card-description mt-2">
                <FormattedMessage id="partneringAgenciesCard_header" />
              </p>
            </>
          )}
          {agencyInvitations.length === 0 && (
            <>
              <h3 className="card-header">
                <FormattedMessage id="partneringAgenciesCard_freelanceHeader" />
              </h3>
              <p className="card-description mt-2">
                <FormattedMessage id="partneringAgenciesCard_freelanceDesc" />
              </p>
            </>
          )}

          {agencyInvitations.length > 0 &&
            agencyInvitations.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`ta-card ${index > 0 ? "mt-4" : "mt-2"}`}
                >
                  <img
                    className="ta-logo"
                    srcSet={elem.agency.logo}
                    alt={`${elem.agency.companyName} logo`}
                  />
                  <div className="content">
                    <div className="name-wrapper">
                      <span className="name-lbl">
                        {elem.agency.companyName}
                      </span>
                      <EmpPill
                        {...PILL_COLORS.primary}
                        text={recommendPartnershipStatus(elem.invitationType)}
                      />
                    </div>
                    <div className="mt-1">
                      {elem.status === "VALID" && (
                        <span className="invited-date">
                          <FormattedMessage
                            id="partneringAgenciesCard_invitedDate"
                            values={{
                              date: DateUtil.toReadableDateWithTime(
                                elem.createdDate
                              ),
                            }}
                          />
                        </span>
                      )}
                    </div>
                    {elem.status === "VALID" && (
                      <div className="mt-1">
                        <EmpLink
                          text={<FormattedMessage id="cta_accept" />}
                          onSubmit={() => {
                            acceptAgencyInvitation(elem);
                          }}
                        />
                        <span className="pipe-lbl mh-1">|</span>
                        <EmpLink
                          linkStyle="danger"
                          text={<FormattedMessage id="cta_reject" />}
                          onSubmit={() => {
                            rejectAgencyInvitation(
                              elem.id,
                              elem.agency.companyName
                            );
                          }}
                        />
                      </div>
                    )}
                    {elem.status === "ACCEPTED" && (
                      <div className="mt-1">
                        <EmpLink
                          text={<FormattedMessage id="cta_view" />}
                          onSubmit={() => {
                            navigate(`/creator/agency/${elem.agency.id}`);
                          }}
                        />
                        <span className="pipe-lbl mh-1">|</span>
                        <EmpLink
                          onSubmit={() => {
                            creatorLeaveAgencyConfirmationModalRef.current?.show(
                              elem
                            );
                          }}
                          linkStyle="danger"
                          text={
                            <FormattedMessage id="partneringAgenciesCard_leaveAgencyLink" />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      }
    </>
  );
};
