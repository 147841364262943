import { useCallback, useEffect, useState } from "react";
import { MediaSnapshotDto } from "../../model/campaign/media-snapshot.dto";
import "./pow-modal-listing.scss";
import {
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE_DISPLAY,
  PROOF_OF_WORK_MEDIA_TYPE,
} from "../../constants/app.constants";
import { DateUtil } from "../../utilities/date";
import EyeIcon from "../icon/eye-icon";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import PlayIcon from "../icon/play-icon";
import EmpContent from "../shared/emp-content/emp-content";
import EmpLink from "../shared/emp-link/emp-link";
import { Color } from "../../utilities/colors";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import InstagramIcon from "../icon/instagram-icon";
import FileCheckIcon from "../icon/file-check-icon";
import { EmpImage } from "../shared/emp-image/emp-image";
import { ProfileSnapshotDto } from "../../model/campaign/profile-snapshot.dto";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import BrokenLinkIcon from "../icon/broken-link-icon";
import ShareIcon from "../icon/share-icon";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  mediaSnapshots: MediaSnapshotDto[];
  profileSnapshot?: ProfileSnapshotDto;
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
}

const feedTypes = [
  PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
  PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
];
const storyTypes = [
  PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
  PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
];

export const InstagramPowFallback = (props: Props) => {
  const {
    mediaSnapshots,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  } = props;
  const [imageItem, setImageItem] = useState<MediaSnapshotDto>();
  const [videoItem, setVideoItem] = useState<MediaSnapshotDto>();

  useEffect(() => {
    const foundVideoItem = mediaSnapshots.find((elem) => elem.type === "video");
    setVideoItem(foundVideoItem);

    const foundImageItem = mediaSnapshots.find((elem) => elem.type === "image");
    setImageItem(foundImageItem);
  }, [mediaSnapshots]);

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        {imageItem && (
          <img
            className="preview-image"
            alt={"Fallback Preview"}
            src={imageItem.mediaUrl}
          />
        )}
        {videoItem && (
          <video className="preview-image" src={videoItem.mediaUrl} />
        )}
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE && (
                <span className="card-title">Instagram Image Feed Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO && (
                <span className="card-title">Instagram Reel Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE && (
                <span className="card-title">Instagram Story (Picture) </span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO && (
                <span className="card-title">Instagram Story (Video) </span>
              )}

              {feedTypes.includes(evidenceRecord.mediaType) && (
                <div className="metrics-bar mt-2">
                  {evidenceRecord.mediaType ===
                    PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO && (
                    <>
                      <div className="metric-wrapper">
                        <PlayIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>
                          {evidenceRecord.metricSnapshot?.playCount ?? 0}
                        </span>
                      </div>
                    </>
                  )}
                  <div className="metric-wrapper">
                    <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>
                      {evidenceRecord.metricSnapshot?.impression ?? 0}
                    </span>
                  </div>
                  <div className="metric-wrapper">
                    <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                    <span>{evidenceRecord.metricSnapshot?.likeCount ?? 0}</span>
                  </div>
                  <div className="metric-wrapper">
                    <MessageIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[600]}
                    />
                    <span>
                      {evidenceRecord.metricSnapshot?.commentCount ?? 0}
                    </span>
                  </div>
                  <div className="metric-wrapper">
                    <div className="download-btn-wrapper">
                      <EmpLink
                        href={evidenceRecord.mediaUrl}
                        newTab
                        text={"Link to Post"}
                        showNewTabIcon
                      />
                    </div>
                  </div>
                </div>
              )}
              {storyTypes.includes(evidenceRecord.mediaType) && (
                <div className="metrics-bar mt-2">
                  <div className="metric-wrapper">
                    <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>
                      {evidenceRecord.metricSnapshot?.impression ?? 0}
                    </span>
                  </div>
                  <div className="metric-wrapper">
                    <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>{evidenceRecord.metricSnapshot?.likeCount ?? 0}</span>
                  </div>
                  <div className="metric-wrapper">
                    <MessageIcon
                      backgroundColor={Color.NEUTRAL[600]}
                      size={14}
                    />
                    <span>
                      {evidenceRecord.metricSnapshot?.commentCount ?? 0}
                    </span>
                  </div>
                  <div className="metric-wrapper">
                    <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                    <span>
                      {evidenceRecord.metricSnapshot?.shareCount ?? 0}
                    </span>
                  </div>
                  <div className="metric-wrapper">
                    <div className="download-btn-wrapper">
                      <EmpLink
                        href={evidenceRecord.mediaUrl}
                        newTab
                        text={"Download Post Media"}
                        showNewTabIcon
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {storyTypes.includes(evidenceRecord.mediaType) && (
            <div className="listing-pills-wrapper">
              <EmpPill
                {...PILL_COLORS.primary}
                text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
              />
              <EmpPill
                Icon={BrokenLinkIcon}
                {...PILL_COLORS.gray}
                text={"Story Expired"}
              />
            </div>
          )}

          {feedTypes.includes(evidenceRecord.mediaType) && (
            <div className="listing-pills-wrapper">
              <EmpPill
                {...PILL_COLORS.primary}
                text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
              />
              <EmpPill
                Icon={BrokenLinkIcon}
                {...PILL_COLORS.gray}
                text={"Archived"}
              />
            </div>
          )}
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [videoItem, imageItem, evidenceRecord]);

  const renderPowCard = useCallback(() => {
    return (
      <div className="image-wrapper">
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
            <div className="social-media-platform-badge">
              <InstagramIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
        </div>
        <div className="post-wrapper">
          {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
            <div className={`additional-deliverable-wrapper-badge`}>
              <span className="badge-text">
                +{noOfOtherEvidenceRecords - 1}
              </span>
              <FileCheckIcon
                strokeWidth={2.5}
                size={12}
                backgroundColor={Color.NEUTRAL[300]}
              />
            </div>
          )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) &&
            imageItem && (
              <img
                className="attachment-img"
                alt="Uploaded evidence"
                src={imageItem.mediaUrl}
              />
            )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
          ].includes(evidenceRecord.mediaType) &&
            videoItem && (
              <video
                muted
                loop
                className="attachment-video"
                src={videoItem.mediaUrl}
              />
            )}
        </div>
        <div className="sm-metrics-section">
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <div className="metrics-bar">
              {PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO && (
                <div className="metric-wrapper">
                  <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{evidenceRecord.metricSnapshot?.playCount ?? "-"}</span>
                </div>
              )}
              <div className="metric-wrapper">
                <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {evidenceRecord.metricSnapshot?.commentCount ?? "-"}
                </span>
              </div>
            </div>
          )}
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) && (
            <div className="metrics-bar">
              <div className="metric-wrapper">
                <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{evidenceRecord.metricSnapshot?.impression ?? 0}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{evidenceRecord.metricSnapshot?.likeCount ?? 0}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{evidenceRecord.metricSnapshot?.commentCount ?? 0}</span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
                <span>{evidenceRecord.metricSnapshot?.shareCount ?? 0}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    imageItem,
    videoItem,
    evidenceRecord,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  ]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
            <div className="social-media-platform-badge">
              <InstagramIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
          ].includes(evidenceRecord.mediaType) &&
            imageItem && (
              <img
                className="sm-image"
                alt="Uploaded evidence"
                src={imageItem.mediaUrl}
              />
            )}

          {[
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO,
            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
          ].includes(evidenceRecord.mediaType) &&
            videoItem && (
              <div className="sm-video-wrapper">
                <video
                  className="sm-video"
                  muted
                  controls
                  autoPlay
                  loop
                  src={videoItem.mediaUrl}
                />
              </div>
            )}
        </div>
        {feedTypes.includes(evidenceRecord.mediaType) && (
          <div className="sm-metrics">
            {PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO && (
              <div className="metric-wrapper">
                <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecord.metricSnapshot?.playCount ?? "-"}</span>
              </div>
            )}
            <div className="metric-wrapper">
              <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.impression ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
            </div>
          </div>
        )}
        {storyTypes.includes(evidenceRecord.mediaType) && (
          <div className="sm-metrics">
            <div className="metric-wrapper">
              <PlayIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{evidenceRecord.metricSnapshot?.impression ?? 0}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{evidenceRecord.metricSnapshot?.likeCount ?? 0}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{evidenceRecord.metricSnapshot?.commentCount ?? 0}</span>
            </div>
            <div className="metric-wrapper">
              <ShareIcon backgroundColor={Color.NEUTRAL[600]} size={14} />
              <span>{evidenceRecord.metricSnapshot?.shareCount ?? 0}</span>
            </div>
          </div>
        )}
      </>
    );
  }, [evidenceRecord, imageItem, videoItem, profileSnapshot]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {imageItem && (
          <EmpImage alt="Proof of work preview" src={imageItem.mediaUrl} />
        )}
        {videoItem && (
          <video width="320" height="240">
            <source src={videoItem.mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </>
    );
  }, [imageItem, videoItem]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
