import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";

export class NumericRangeValidator extends BaseValidator implements IValidator {
  minNumber;
  maxNumber;
  customMinErrorMsg?: string = undefined;
  customMaxErrorMsg?: string = undefined;

  constructor(
    minNumber: number,
    maxNumber: number,
    customMinErrorMsg?: string,
    customMaxErrorMsg?: string
  ) {
    super();
    this.minNumber = minNumber;
    this.maxNumber = maxNumber;
    this.customMinErrorMsg = customMinErrorMsg;
    this.customMaxErrorMsg = customMaxErrorMsg;
  }

  /**
   * @purpose Perform valdiation based on supplied min value and max value
   * @param formControl: IFormControl
   * @returns True if valid | False if invalid
   */
  validate(formControl: FormControl): boolean {
    const DEFAULT_MIN_ERR = `Please choose a value larger than ${this.minNumber}.`;
    const DEFAULT_MAX_ERR = `Please choose a value smaller than ${this.maxNumber}.`;

    const minErrMsg = this.customMinErrorMsg ?? DEFAULT_MIN_ERR;
    const maxErrMsg = this.customMaxErrorMsg ?? DEFAULT_MAX_ERR;

    const value = formControl.getValue();
    const evaluable = value ? Number(value.trim()) : 0;

    if (evaluable < this.minNumber) {
      this.hasError(formControl, minErrMsg);
      return false;
    } else if (evaluable > this.maxNumber) {
      this.hasError(formControl, maxErrMsg);
      return false;
    }
    this.noError(formControl);
    return true;
  }
}
