import "./brand-event-post-modal.scss";

import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DateRangePicker, Tooltip, Whisper } from "rsuite";
import { motion } from "framer-motion";
import _debounce from "lodash/debounce";
import { FormattedMessage } from "react-intl";
import jstz from "jstz";

import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";
import { AppContext } from "../../../context/app.context";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import {
  EMPTY_PROFILE_IMG_URL,
  FILE_TYPE,
} from "../../../constants/app.constants";
import { Color } from "../../../utilities/colors";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import EmpRoundedPill from "../../../components/shared/emp-rounded-pill/emp-rounded-pill";
import XIcon from "../../../components/icon/x-icon";
import BrandPostPreviewModal from "./brand-post-preview-modal";
import FileUtils from "../../../utilities/file-util";
import EmpDatePicker from "../../../components/shared/emp-date-picker/emp-date-picker";
import AlertCircleIcon from "../../../components/icon/alert-circle";
import { postUtils } from "../utils/smm-posts-utils";
import ToastUtils from "../../../utilities/toast-utils";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import EmpDateInput from "../../../components/shared/emp-time-input/emp-time-input";
import XCloseIcon from "../../../components/icon/x-close-icon";
import EmpButtonTab from "../../../components/shared/emp-button-tab/emp-button-tab";
import { SmPostType } from "../../../model/smm/smm-post.dto";
import PlayIcon from "../../../components/icon/play-icon";
import TikTokConfigPostModal, {
  TikTokConfigPostModalRef,
} from "./tiktok/tiktok-config-post-modal";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { EmpExpandToggleBtn } from "../../../components/shared/emp-expand-toggle-btn/emp-expand-toggle-btn";
import EmpDropdownBtn from "../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import InfoCircleIcon from "../../../components/icon/info-circle-icon";
import SocialMediaManagementTikTokApi from "../../../api/smm-msvc/smm-tiktok";
import moment from "moment";

export interface BrandEventPostModalRef {
  show: () => void;
  dismiss: () => void;
}

interface Props {
  getPost: () => Promise<void>;
  connects: SmConnectionBriefRespDto[];
}

interface MultiFileState {
  [key: string]: File[];
}

type PlatformType = "facebook" | "instagram" | "tiktok" | "x";

const platformTitleMap: Record<PlatformType, any> = {
  facebook: {
    post: "Facebook Feed Post",
    reel: "Facebook Reel Post",
    story: "Facebook Story Post",
  },
  instagram: {
    post: "Instagram Feed Post",
    reel: "Instagram Reel Post",
    story: "Instagram Story Post",
  },
  tiktok: {
    post: "TikTok Post",
    "tiktok-video": "TikTok Video Post",
    "tiktok-image": "TikTok Image Post",
  },
  x: {
    post: "Tweet",
    tweet: "Tweet",
    thread: "Thread",
    poll: "Poll Question",
  },
};

const platformSupportMap: Record<PlatformType, SmPostType[]> = {
  facebook: ["post", "reel", "story"],
  instagram: ["post", "reel", "story"],
  tiktok: ["post"], // Note: TikTok treats 'reel' as a 'post'
  x: ["post"], // Note: x treats 'Tweet' as a 'Post'
};

const hashtags = ["Newpost", "Viral", "Crickets"];
const BrandEventPostModal = forwardRef((props: Props, ref) => {
  const { getPost, connects } = props;

  const discardBtnRef = useRef<EmpButtonRef>();
  const postBtnRef = useRef<EmpButtonRef>();
  const saveToDraftBtnRef = useRef<EmpButtonRef>();
  const captureSectionRef = useRef<HTMLDivElement>(null);
  const tiktokConfigPostModalRef = useRef<TikTokConfigPostModalRef>(null);

  const { user: userContext } = useContext(AppContext);

  const [visible, setVisible] = useState<boolean>(false);
  const [isSchedulePost, setIsSchedulePost] = useState(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isClosedEditText, setIsClosedEditText] = useState<boolean>(false);
  const [isMultiEdit, setIsMultiEdit] = useState<boolean>(false);
  const [isUpdatedTikTokSettings, setIsUpdatedTikTokSettings] = useState(false);
  const [isReachingLimit, setIsReachingLimit] = useState(false);

  const [, setPollValue] = useState("");
  const [pollLengthValue, setPollLengthValue] = useState("");
  const [pollOptions, setPollOptions] = useState(2);
  const [errorConnectsId, setErrorConnectsId] = useState<string[]>([]);
  const [, setPreviewDescription] = useState<string>("");
  const [selectedConnects, setSelectedConnects] = useState<
    SmConnectionBriefRespDto[]
  >([]);
  const [currentConnect, setCurrentConnect] =
    useState<SmConnectionBriefRespDto>();
  const [postType, setPostType] = useState<SmPostType | null>(null);
  const [updatedPostType, setUpdatePostType] = useState<number>(0);
  const [comparedTimezone, setComparedTimezone] = useState<Date>(new Date());

  // form state
  const [generalForm, setGeneralForm] = useState<IFormGroup>({
    postType: new FormControl("text", [], ""),

    description: new FormControl("text", [
      new LengthValidator(
        0,
        300,
        "",
        "Post description must not exceed 300 characters"
      ),
    ]),

    pollOption: new FormControl(
      "text",
      [
        new RequiredValidator("Poll Option 1 is required"),
        new LengthValidator(
          0,
          25,
          "",
          "Poll Option must not exceed 25 characters"
        ),
      ],
      ""
    ),
    pollOption2: new FormControl(
      "text",
      [new RequiredValidator("Poll Option 2 is required")],
      ""
    ),
    pollOption3: new FormControl(
      "text",
      [
        new LengthValidator(
          0,
          25,
          "",
          "Poll Option must not exceed 25 characters"
        ),
      ],
      ""
    ),
    pollOption4: new FormControl(
      "text",
      [
        new LengthValidator(
          0,
          25,
          "",
          "Poll Option must not exceed 25 characters"
        ),
      ],
      ""
    ),
    pollOptionSize: new FormControl("text", [], "2"),

    pollLengthDays: new FormControl("text", [], "0"),
    pollLengthHours: new FormControl("text", [], "0"),
    pollLengthMinutes: new FormControl("text", [], "0"),

    platform: new FormControl("text", [
      new RequiredValidator("Please select a platform"),
    ]),
    dateOfPosting: new FormControl("date", [new RequiredValidator()]),

    timeOfPosting: new FormControl("date", [new RequiredValidator()]),

    privacy_level: new FormControl("text", [
      new RequiredValidator("Please select a privacy level"),
    ]),
    disable_duet: new FormControl("boolean", []),
    disable_comment: new FormControl("boolean", []),
    disable_stitch: new FormControl("boolean", []),
    brand_content_toggle: new FormControl("boolean", []),
    brand_organic_toggle: new FormControl("boolean", []),
  });

  const defaultForm = useMemo<Record<string, IFormGroup>>(
    () =>
      connects.reduce(
        (
          acc: Record<string, IFormGroup>,
          connect: SmConnectionBriefRespDto
        ) => {
          acc[connect.socialMediaUserId] = {
            postType: new FormControl("text", [], ""),

            description: new FormControl("text", [
              new LengthValidator(
                0,
                300,
                "",
                "Post description must not exceed 300 characters"
              ),
            ]),

            pollOption: new FormControl(
              "text",
              [
                new RequiredValidator("Poll Option 1 is required"),
                new LengthValidator(
                  0,
                  25,
                  "",
                  "Poll Option must not exceed 25 characters"
                ),
              ],
              ""
            ),
            pollOption2: new FormControl(
              "text",
              [new RequiredValidator("Poll Option 2 is required")],
              ""
            ),
            pollOption3: new FormControl(
              "text",
              [
                new LengthValidator(
                  0,
                  25,
                  "",
                  "Poll Option must not exceed 25 characters"
                ),
              ],
              ""
            ),
            pollOption4: new FormControl(
              "text",
              [
                new LengthValidator(
                  0,
                  25,
                  "",
                  "Poll Option must not exceed 25 characters"
                ),
              ],
              ""
            ),
            pollOptionSize: new FormControl("text", [], "2"),

            pollLengthDays: new FormControl("text", [], "0"),
            pollLengthHours: new FormControl("text", [], "0"),
            pollLengthMinutes: new FormControl("text", [], "0"),

            platform: new FormControl("text", [
              new RequiredValidator("Please select a platform"),
            ]),
            dateOfPosting: new FormControl("date", [new RequiredValidator()]),

            timeOfPosting: new FormControl("date", [new RequiredValidator()]),

            privacy_level: new FormControl("text", [
              new RequiredValidator("Please select a privacy level"),
            ]),
            disable_duet: new FormControl("boolean", []),
            disable_comment: new FormControl("boolean", []),
            disable_stitch: new FormControl("boolean", []),
            brand_content_toggle: new FormControl("boolean", []),
            brand_organic_toggle: new FormControl("boolean", []),
          };
          return acc;
        },
        {}
      ) as Record<string, IFormGroup>,
    [connects]
  );

  const [form, setForm] = useState<IFormGroup[]>([]);

  // form state for Twitter/X
  const [threadForm, setThreadForm] = useState<IFormGroup[]>([
    {
      tweet: new FormControl(
        "text",
        [
          new LengthValidator(
            0,
            280,
            "",
            "Tweet must not exceed 280 characters"
          ),
        ],
        ""
      ),
    },
  ]);
  const [threadVideo, setThreadVideo] = useState<Record<string, File[][]>>();
  const [threadImage, setThreadImage] = useState<Record<string, File[][]>>();
  const [isThreadFileUpload, setIsThreadFileUpload] = useState<boolean>(false);

  const [multiThreadForm, setMultiThreadForm] = useState<
    Record<string, IFormGroup[]>
  >({});

  // form state for Twitter/X

  const currentConnectForm = useMemo(() => {
    if (!currentConnect)
      return defaultForm[selectedConnects[0]?.socialMediaUserId];
    return (
      form.find(
        (control) =>
          control.socialMediaUserId.getValue() ===
          currentConnect.socialMediaUserId
      ) || defaultForm[currentConnect.socialMediaUserId]
    );
  }, [currentConnect, defaultForm, form, selectedConnects]);

  const getFormById = useCallback(
    (id: string) => {
      if (isMultiEdit) {
        return (
          form.find((control) => control.socialMediaUserId.getValue() === id) ||
          defaultForm[id]
        );
      }
      return defaultForm[id];
    },
    [defaultForm, form, isMultiEdit]
  );

  const isPostTypeAvailable = useCallback(
    (type: SmPostType) => {
      if (selectedConnects.length > 0) {
        const platformArr = selectedConnects.map((connect) => {
          return connect.platform.toLowerCase() as PlatformType;
        });
        return platformArr.every((platform) => {
          return platformSupportMap[platform]?.includes(type);
        });
      }
      return false;
    },
    [selectedConnects]
  );

  const postTypeOptions = useMemo(() => {
    if (!selectedConnects || !currentConnect) return [];

    const currentPlatform =
      currentConnect.platform.toLowerCase() as PlatformType;

    const isDisablePostType = (postTypeValue: string) => {
      if (!isMultiEdit) {
        if (postTypeValue === "poll") {
          return true;
        } else {
          return !isPostTypeAvailable(postTypeValue as SmPostType);
        }
      }
    };

    const handleChangePostType = (postType: SmPostType) => {
      defaultForm[currentConnect?.socialMediaUserId]?.postType.setValue(
        postType
      );
      setUpdatePostType((prev) => prev + 1);
      if (isMultiEdit) {
        currentConnectForm?.postType.setValue(postType);
      } else {
        connects.forEach((connect) => {
          const platform = connect.platform.toLowerCase() as PlatformType;
          const isBasicPostType =
            postType === "post" ||
            postType === "tiktok-video" ||
            postType === "tweet";
          const formattedPostType = formatPostType(
            platform,
            isBasicPostType ? "post" : postType
          );
          defaultForm[connect.socialMediaUserId]?.postType.setValue(
            formattedPostType
          );
        });
      }
    };

    const isSelect = (postType: SmPostType) => {
      if (isMultiEdit) {
        return currentConnectForm?.postType.getValue() === postType;
      }
      return (
        defaultForm[currentConnect?.socialMediaUserId]?.postType.getValue() ===
        postType
      );
    };

    switch (currentPlatform) {
      case "tiktok":
        if (isMultiEdit) {
          return [
            {
              text: "Video Post",
              value: "tiktok-video",
              action: () => {
                handleChangePostType("tiktok-video");
              },
              isSelected: isSelect("tiktok-video"),
            },
            {
              text: "Photo Post",
              value: "tiktok-image",
              action: () => {
                handleChangePostType("tiktok-image");
              },
              isSelected: isSelect("tiktok-image"),
            },
          ];
        }
        return [
          {
            text: "TikTok Post",
            value: "tiktok-video",
            action: () => {
              handleChangePostType("tiktok-video");
            },
            isSelected: isSelect("tiktok-video"),
          },
        ];
      case "x":
        return [
          {
            text: "Tweet",
            value: "tweet",
            action: () => {
              handleChangePostType("tweet");
            },
            isSelected: isSelect("tweet"),
          },
          {
            text: "Poll",
            value: "poll",
            action: () => {
              handleChangePostType("poll");
            },
            isSelected: isSelect("poll"),
            isDisabled: isDisablePostType("poll"),
          },
        ];
      default:
        return [
          {
            text: "Feed Post",
            value: "post",
            action: () => {
              handleChangePostType("post");
            },
            isSelected: isSelect("post"),
            isDisabled: isDisablePostType("post"),
          },
          {
            text: "Reel",
            value: "reel",
            action: () => {
              handleChangePostType("reel");
            },
            isSelected: isSelect("reel"),
            isDisabled: isDisablePostType("reel"),
          },
          {
            text: "Story",
            value: "story",
            action: () => {
              handleChangePostType("story");
            },
            isSelected: isSelect("story"),
            isDisabled: isDisablePostType("story"),
          },
        ];
    }
  }, [
    currentConnect,
    currentConnectForm,
    isMultiEdit,
    isPostTypeAvailable,
    postType,
    selectedConnects,
  ]);

  // file upload state (for unMultiEdit mode)
  const [uploadedFileType, setUploadedFileType] =
    useState<(typeof FILE_TYPE)[keyof typeof FILE_TYPE]>();
  const [video, setVideo] = useState<File[]>();
  const [imageFiles, setImageFiles] = useState<File[]>();

  const [multiVideo, setMultiVideo] = useState<Record<string, File[]>>({});
  const [multiImage, setMultiImage] = useState<Record<string, File[]>>({});

  // form functions
  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.resetToInitial(generalForm);
    setGeneralForm(generalForm);
    setForm([]);

    connects.forEach((connect) => {
      const form = defaultForm[connect.socialMediaUserId];
      if (form) {
        FormGroupUtil.resetToInitial(form);
      }
    });

    setThreadForm([
      {
        tweet: new FormControl(
          "text",
          [
            new LengthValidator(
              0,
              280,
              "",
              "Tweet must not exceed 280 characters"
            ),
          ],
          ""
        ),
      },
    ]);

    setMultiThreadForm({});
  };

  // file upload functions (for UI and validation)
  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      // Reset the upload state if it's not multi-edit mode
      const files: FileList | null = event.target.files;
      if (!files || files.length === 0) return;
      const videoFiles = FileUtils.getAllVideo(files);
      const imageFiles = FileUtils.findImage(files);

      if (videoFiles.length > 1) {
        ToastUtils.error("Error", "You can only upload one video file");
        return;
      }

      // Reset tiktok settings when media is changed
      setIsUpdatedTikTokSettings(false);

      isMultiEdit || resetUploadState();

      if (isMultiEdit) {
        const updateVideoFiles = (files: File[]) => {
          setMultiVideo((prevMultiVideo) => ({
            ...prevMultiVideo,
            [currentConnect?.socialMediaUserId as string]: files,
          }));
        };
        const updateImageFiles = (files: File[]) => {
          setMultiImage((prevMultiImage) => ({
            ...prevMultiImage,
            [currentConnect?.socialMediaUserId as string]: files,
          }));
        };

        const videoValid =
          videoFiles.length > 0 &&
          validateAndSetFiles(videoFiles, updateVideoFiles, "Video", 10 * 1024); // 10GB in MB

        // Validate and set image files
        const imageValid =
          imageFiles.length > 0 &&
          validateAndSetFiles(imageFiles, updateImageFiles, "Image", 2); // 2MB

        if (!videoValid) {
          setMultiVideo((prevMultiVideo) => ({
            ...prevMultiVideo,
            [currentConnect?.socialMediaUserId as string]: [],
          }));
        }
        if (!imageValid) {
          setMultiImage((prevMultiImage) => ({
            ...prevMultiImage,
            [currentConnect?.socialMediaUserId as string]: [],
          }));
        }

        if (videoValid || imageValid) {
          // Assuming a preference for displaying image type if available
          setUploadedFileType(
            FileUtils.getFileType(imageFiles[0] || videoFiles[0])
          );
        }
        return;
      }

      // const platformsSet = new Set(
      //   selectedConnects.map((connect) => connect.platform.toLowerCase())
      // );

      // // Check if either "facebook" or "instagram" are included when postType is "story"
      // if (platformsSet.has("tiktok")) {
      //   if (imageFiles.length === 1) {
      //     ToastUtils.error(
      //       "Error",
      //       "You need to upload at least 2 images for TikTok post"
      //     );
      //     return;
      //   }
      // }
      const customSetVideo = (file: File[]) => {
        setVideo(file);
        const newArr: File[][] = [];
        newArr[0] = file;
        setThreadVideo((prevThreadVideo) => ({
          ...prevThreadVideo,
          [currentConnect?.socialMediaUserId as string]: newArr,
        }));
      };
      const customSetImageFiles = (file: File[]) => {
        setImageFiles(file);
        const newArr: File[][] = [];
        newArr[0] = file;

        setThreadImage((prevThreadImage) => ({
          ...prevThreadImage,
          [currentConnect?.socialMediaUserId as string]: newArr,
        }));
      };

      // Validate and set video files
      const videoValid =
        videoFiles.length > 0 &&
        validateAndSetFiles(videoFiles, customSetVideo, "Video", 10 * 1024); // 10GB in MB

      // Validate and set image files
      const imageValid =
        imageFiles.length > 0 &&
        validateAndSetFiles(imageFiles, customSetImageFiles, "Image", 2); // 2MB

      if (videoValid || imageValid) {
        // Assuming a preference for displaying image type if available
        setUploadedFileType(
          FileUtils.getFileType(imageFiles[0] || videoFiles[0])
        );
      }
    },
    [isMultiEdit, currentConnect]
  );

  function resetUploadState() {
    setVideo(undefined);
    setImageFiles(undefined);
    setUploadedFileType(undefined);

    if (!isMultiEdit) {
      resetXUploadState();
    }
  }

  function validateAndSetFiles(
    files: File[],
    setFunction: Function,
    fileType: string,
    maxSizeInMB: number
  ): boolean {
    let isValid = true;
    files.forEach((file) => {
      if (!FileUtils.isFileSizeSmallerThanMB(file, maxSizeInMB)) {
        ToastUtils.error(
          "Error",
          `${fileType} size must not exceed ${maxSizeInMB}MB`
        );
        isValid = false;
        return; // Exit loop on first invalid file
      }
    });

    if (isValid) {
      setFunction(files);
    }

    return isValid;
  }

  // file upload for twitter/x (thread)
  const handleFileXUpload = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number
    ): Promise<void> => {
      // Reset the upload state if it's not multi-edit mode
      isMultiEdit || resetXUploadState();

      const files: FileList | null = event.target.files;
      if (!files || files.length === 0) return;
      if (files.length > 5) {
        ToastUtils.error("Error", "You can only upload up to 5 files");
        return;
      }

      const videoFiles = FileUtils.getAllVideo(files);
      const imageFiles = FileUtils.findImage(files);

      if (videoFiles.length > 1) {
        ToastUtils.error("Error", "You can only upload one video file");
        return;
      }
      if (isMultiEdit) {
        const updateVideoFiles = (files: File[]) => {
          const newArr: File[][] = threadVideo
            ? threadVideo[currentConnect?.socialMediaUserId as string]
            : [];
          newArr[index] = files;
          setThreadVideo((prevThreadVideo) => ({
            ...prevThreadVideo,
            [currentConnect?.socialMediaUserId as string]: newArr,
          }));
        };
        const updateImageFiles = (files: File[]) => {
          const newArr: File[][] = threadImage
            ? threadImage[currentConnect?.socialMediaUserId as string]
            : [];
          newArr[index] = files;
          setThreadImage((prevThreadImage) => ({
            ...prevThreadImage,
            [currentConnect?.socialMediaUserId as string]: newArr,
          }));
        };

        const videoValid =
          videoFiles.length > 0 &&
          validateAndSetFiles(videoFiles, updateVideoFiles, "Video", 10 * 1024); // 10GB in MB

        // Validate and set image files
        const imageValid =
          imageFiles.length > 0 &&
          validateAndSetFiles(imageFiles, updateImageFiles, "Image", 2); // 2MB

        if (!videoValid) {
          const newArr: File[][] =
            (threadVideo as Record<string, File[][]>)[
              currentConnect?.socialMediaUserId as string
            ] || [];
          newArr[index] = [];
          setThreadVideo((prevMultiVideo) => ({
            ...prevMultiVideo,
            [currentConnect?.socialMediaUserId as string]: newArr,
          }));
        }
        if (!imageValid) {
          const newArr: File[][] =
            (threadImage as Record<string, File[][]>)[
              currentConnect?.socialMediaUserId as string
            ] || [];
          newArr[index] = [];
          setThreadImage((prevMultiImage) => ({
            ...prevMultiImage,
            [currentConnect?.socialMediaUserId as string]: newArr,
          }));
        }
        setIsThreadFileUpload(!isThreadFileUpload);
        return;
      }

      // Validate and set video files
      const videoValid =
        videoFiles.length > 0 &&
        validateAndSetFiles(videoFiles, setVideo, "Video", 10 * 1024); // 10GB in MB

      // Validate and set image files
      const imageValid =
        imageFiles.length > 0 &&
        validateAndSetFiles(imageFiles, setImageFiles, "Image", 2); // 2MB

      if (videoValid || imageValid) {
        // Assuming a preference for displaying image type if available
        setUploadedFileType(
          FileUtils.getFileType(imageFiles[0] || videoFiles[0])
        );
      }
    },
    [currentConnect, isMultiEdit, isThreadFileUpload, threadImage, threadVideo]
  );

  function resetXUploadState() {
    setThreadVideo(undefined);
    setThreadImage(undefined);
    setUploadedFileType(undefined);
  }

  // handle view functions
  const handleSelectionChange = (connect: SmConnectionBriefRespDto) => {
    const itemId = connect.socialMediaUserId;
    if (currentConnect?.socialMediaUserId === itemId) {
      return;
    }

    const newConnects = selectedConnects.some(
      (item) => item.socialMediaUserId === itemId
    )
      ? selectedConnects
      : [...selectedConnects, connect];
    setSelectedConnects(newConnects);
    setCurrentConnect(connect);

    if (isMultiEdit) return;

    // update post type
    const platform = connect.platform.toLowerCase() as PlatformType;
    if (platform === "tiktok" || platform === "x") {
      setUpdatePostType((prev) => prev + 1);
      connects.forEach((connect) => {
        const formattedPostType = formatPostType(
          connect.platform.toLowerCase() as PlatformType,
          "post"
        );
        defaultForm[connect.socialMediaUserId]?.postType.setValue(
          formattedPostType
        );
      });
    }
  };

  const handleUnselectConnect = (connect: SmConnectionBriefRespDto) => {
    const itemId = connect.socialMediaUserId;
    const newConnects = selectedConnects.filter(
      (item) => item.socialMediaUserId !== itemId
    );
    if (selectedConnects.length > 0) {
      setCurrentConnect(newConnects[0]);
    } else {
      setCurrentConnect(undefined);
    }
    setSelectedConnects(newConnects);
    return;
  };

  const isTextInputAvailable = useMemo(() => {
    if (selectedConnects.length === 0) return false;

    if (updatedPostType === 0) return false;

    if (
      (currentConnect?.platform.toLowerCase() === "facebook" ||
        currentConnect?.platform.toLowerCase() === "instagram") &&
      currentConnectForm?.postType.getValue() === "story"
    )
      return false;

    return true;
  }, [currentConnect, currentConnectForm, selectedConnects, updatedPostType]);

  const dismiss = () => {
    setVisible(false);
    setSelectedConnects([]);
    setCurrentConnect(undefined);
    setPostType(null);
    setPollOptions(2);
    setPollLengthValue("");
    setPollValue("");
    setVideo(undefined);
    setImageFiles(undefined);
    setMultiVideo({});
    setMultiImage({});

    setThreadVideo({});
    setThreadImage({});
    setIsThreadFileUpload(false);

    setUploadedFileType(undefined);
    setPreviewDescription("");
    setIsSchedulePost(false);
    setIsUploading(false);
    setIsClosedEditText(false);
    setIsMultiEdit(false);
    setIsUpdatedTikTokSettings(false);
    setErrorConnectsId([]);

    setUpdatePostType(0);

    resetForm();

    // refresh post to get the latest data
    getPost();
  };

  const { handleCreatePost, getScheduledTime, handleCreateMultiplePosts } =
    postUtils({
      form: generalForm,
      postType,
      userContext,
      selectedConnects,
      captureSectionRef,
      video,
      imageFiles,
      isMultiEdit,
      multiVideo,
      multiImage,
      multiForm: form,
      saveToDraftBtnRef,
      postBtnRef,
      discardBtnRef,
      setIsUploading,
      dismiss,

      threadForm,
      threadVideo,
      threadImage,
      multiThreadForm,

      commonForm: defaultForm,
    });

  // const handleAddHashtag = (hashtag: string) => {
  //   if (!postType || !isTextInputAvailable) return;

  //   if (isMultiEdit) {
  //     const fromDescription = currentConnectForm?.description!;
  //     fromDescription.setValue(
  //       fromDescription.getValue() + " #" + hashtag + " "
  //     );
  //     handleChangePreviewDescription(descriptionValue);

  //     return;
  //   }
  //   generalForm.description.setValue(
  //     generalForm.description.getValue() + " #" + hashtag + " "
  //   );
  //   handleChangePreviewDescription(descriptionValue);
  // };

  const handleChangePreviewDescription = _debounce((value: FormControl) => {
    if (isMultiEdit) {
      if (currentConnectForm?.description) {
        currentConnectForm.description.validate();
      }
    } else {
      connects.forEach((connect) => {
        const form = defaultForm[connect.socialMediaUserId];
        if (form.description) {
          form.description.setValue(value.getValue());
          form.description.validate();
        }
      });
      generalForm.description.setValue(value.getValue());
      threadForm[0].tweet.setValue(value.getValue());
      threadForm[0].tweet.validate();
    }

    setPreviewDescription(value.getValue());
  }, 200);

  const getDesc = () => {
    if (isMultiEdit) {
      return currentConnectForm?.description;
    }
    return descriptionValue;
  };

  const handleChangePollContent = _debounce((value: FormControl) => {
    // for rerendering the poll options
    setPollValue(value.getValue());
    value.validate();
  }, 200);

  const createInitialMultiFile = useCallback(() => {
    const initialState: MultiFileState = {};
    connects.forEach((connect) => {
      initialState[connect.socialMediaUserId] = [];
    });
    return initialState;
  }, [connects]);

  const createInitialMultiThreadFile = useCallback(() => {
    const initialState: Record<string, File[][]> = {};
    connects.forEach((connect) => {
      if (connect.platform.toLowerCase() === "x") {
        initialState[connect.socialMediaUserId] = [];
      }
    });
    return initialState;
  }, [connects]);
  const cloneMediaToAllUsers = useCallback(() => {
    // Clone the video array to all keys in multiVideo

    setMultiVideo(() => {
      const newMultiVideo = createInitialMultiFile();
      Object.keys(newMultiVideo).forEach((key) => {
        newMultiVideo[key] = [...(video || [])]; // Use a spread to copy the array, fallback to empty if video is undefined
      });
      return newMultiVideo;
    });

    // Clone the image array to all keys in multiImage
    setMultiImage(() => {
      const newMultiImage = createInitialMultiFile();
      Object.keys(newMultiImage).forEach((key) => {
        newMultiImage[key] = [...(imageFiles || [])]; // Use a spread to copy the array, fallback to empty if imageFiles is undefined
      });
      return newMultiImage;
    });

    const newImageArr = imageFiles ? [imageFiles] : [];

    const newVideoArr = video ? [video] : [];

    setThreadImage(() => {
      const newThreadImage = createInitialMultiThreadFile();
      Object.keys(newThreadImage).forEach((key) => {
        newThreadImage[key] = newImageArr;
      });
      return newThreadImage;
    });

    setThreadVideo(() => {
      const newThreadVideo = createInitialMultiThreadFile();
      Object.keys(newThreadVideo).forEach((key) => {
        newThreadVideo[key] = newVideoArr;
      });
      return newThreadVideo;
    });
  }, [createInitialMultiFile, createInitialMultiThreadFile, imageFiles, video]);

  const formatPostType = useCallback(
    (platform: PlatformType, postType: string) => {
      let formattedPostType;
      if (platform === "tiktok") {
        formattedPostType = postType === "post" ? "tiktok-video" : postType;
      } else if (platform === "x") {
        formattedPostType = postType === "post" ? "tweet" : postType;
      } else {
        formattedPostType = postType;
      }

      return formattedPostType;
    },
    []
  );

  const handleChangeMultiEdit = useCallback(() => {
    setIsMultiEdit(!isMultiEdit);
    setForm(
      Array.from({ length: connects.length }, (elem, index) => {
        const platform = connects[index].platform.toLowerCase() as PlatformType;
        return {
          ..._.cloneDeep(generalForm),
          socialMediaUserId: new FormControl(
            "text",
            [],
            connects[index].socialMediaUserId
          ),
          platform: new FormControl("text", [], platform),
          postType: defaultForm[connects[index].socialMediaUserId].postType,
        };
      })
    );

    setMultiThreadForm(
      connects.reduce(
        (
          acc: Record<string, IFormGroup[]>,
          connect: SmConnectionBriefRespDto
        ) => {
          if (connect.platform.toLowerCase() === "x") {
            acc[connect.socialMediaUserId] = _.cloneDeep(threadForm);
          }
          return acc;
        },
        {}
      )
    );

    cloneMediaToAllUsers();
  }, [
    isMultiEdit,
    connects,
    cloneMediaToAllUsers,
    generalForm,
    defaultForm,
    threadForm,
  ]);

  const descriptionValue = useMemo(() => {
    return currentConnectForm?.description;
  }, [currentConnectForm]);

  const updateComparedTimezone = useCallback(() => {
    const date = new Date(generalForm.dateOfPosting?.getValue());
    const time = new Date(generalForm.timeOfPosting?.getValue());
    date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
    setComparedTimezone(date);
  }, [generalForm.dateOfPosting, generalForm.timeOfPosting]);

  const handleDateChange = () => {
    if (generalForm.dateOfPosting.getValue() === null) {
      setIsSchedulePost(false);
      return;
    }
    setIsSchedulePost(true);
    updateComparedTimezone();
    // validate(generalForm.dateOfPosting);
  };
  const handleTimeChange = _debounce(() => {
    if (isSchedulePost) {
      updateComparedTimezone();
      return;
    }
    generalForm.dateOfPosting.setValue(new Date());
    setIsSchedulePost(true);
    updateComparedTimezone();
  }, 200);

  const isSubmitAvailable = useCallback(() => {
    if (updatedPostType === 0 || selectedConnects.length === 0) {
      return false;
    }

    const hasVideo = isMultiEdit
      ? Object.values(multiVideo).some((video) => video.length > 0)
      : video && video.length > 0;
    const hasImage = isMultiEdit
      ? Object.values(multiImage).some((image) => image.length > 0)
      : imageFiles && imageFiles.length > 0;

    const isAvailable = selectedConnects.every((connect) => {
      const platform = connect.platform.toLowerCase() as PlatformType;
      const thisForm = getFormById(connect.socialMediaUserId);
      const postType = thisForm?.postType.getValue() as SmPostType;
      const length =
        platform === "x"
          ? isMultiEdit
            ? multiThreadForm[connect.socialMediaUserId][0].tweet.getValue()
            : threadForm[0].tweet.getValue().length
          : thisForm?.description.getValue().length;
      switch (platform) {
        case "instagram":
          if (postType === "reel") {
            return hasVideo;
          }
          return hasImage || hasVideo;

        case "tiktok":
          return hasImage || hasVideo;

        default:
          if (postType === "story" && length === 0) {
            return hasImage || hasVideo;
          }
          if (postType === "reel") {
            return hasVideo;
          }
          if (length === 0) {
            return hasImage || hasVideo;
          }

          return true;
      }
    });

    return isAvailable;
  }, [
    updatedPostType,
    selectedConnects,
    isMultiEdit,
    multiVideo,
    video,
    multiImage,
    imageFiles,
    getFormById,
    multiThreadForm,
    threadForm,
  ]);

  const inputFileValidation = useMemo(() => {
    if (selectedConnects.length === 0 || updatedPostType === 0) return "";
    const imageType = "image/jpeg, image/jpg, image/png,";
    const videoType = "video/*";
    let accept = "";

    if (currentConnectForm.postType.getValue() === "reel") {
      accept = videoType;
    } else if (currentConnectForm.postType.getValue() === "story") {
      accept = `${imageType}, ${videoType}`;
    } else {
      accept = `${imageType}, ${videoType}`;
    }

    return accept;
  }, [currentConnectForm, selectedConnects, updatedPostType]);

  const isMultipleFileUpload = useMemo(() => {
    if (postType === "reel" || postType === "story") {
      return false;
    }
    return true;
  }, [postType]);

  const handleDiscardPost = async () => {
    dismiss();
  };

  const renderPostTitle = useCallback(
    (value?: string) => {
      const titleObj =
        platformTitleMap[
          currentConnect?.platform.toLowerCase() as PlatformType
        ];
      if (!titleObj) return <></>;
      return (
        <h3 className="mb-2">
          {value
            ? value
            : currentConnect?.platform.toLowerCase() === "tiktok" &&
              !isMultiEdit
            ? "TikTok Post"
            : titleObj[currentConnectForm?.postType.getValue() as SmPostType]}
        </h3>
      );
    },
    [currentConnect, currentConnectForm, isMultiEdit]
  );

  const renderPreviewFile = useCallback(
    (isThread: boolean, threadIndex?: number) => {
      if (!currentConnect) return <></>;
      const { socialMediaUserId } = currentConnect!;
      let videoFilesArr;
      let imageFilesArr;
      if (isThread) {
        videoFilesArr = threadVideo
          ? threadVideo[currentConnect?.socialMediaUserId as string][
              threadIndex as number
            ]
          : [];
        imageFilesArr = threadImage
          ? threadImage[currentConnect?.socialMediaUserId as string][
              threadIndex as number
            ]
          : [];
      } else {
        videoFilesArr = isMultiEdit ? multiVideo[socialMediaUserId] : video;
        imageFilesArr = isMultiEdit
          ? multiImage[socialMediaUserId]
          : imageFiles;
      }

      const hasVideo = videoFilesArr && videoFilesArr.length > 0;
      const hasImages = imageFilesArr && imageFilesArr.length > 0;

      if (!hasVideo && !hasImages) return null;

      const handleFileRemove = (
        fileType: "video" | "image",
        fileIndex: number,
        isThread?: boolean,
        threadIndex?: number
      ) => {
        const socialMediaUserId = currentConnect?.socialMediaUserId as string;

        const removeFromThread = () => {
          const stateSetter =
            fileType === "video" ? setThreadVideo : setThreadImage;
          stateSetter((prevState) => {
            const newState = { ...prevState };
            const threadArr =
              newState[socialMediaUserId][threadIndex as number];
            newState[socialMediaUserId][threadIndex as number] =
              threadArr.filter((_, index) => index !== fileIndex);
            return newState;
          });
        };

        const removeFromMultiEdit = () => {
          if (fileType === "video") {
            setMultiVideo((prevMultiVideo) => ({
              ...prevMultiVideo,
              [socialMediaUserId]: [],
            }));
          } else {
            setMultiImage((prevMultiImage) => ({
              ...prevMultiImage,
              [socialMediaUserId]: prevMultiImage[socialMediaUserId].filter(
                (_, index) => index !== fileIndex
              ),
            }));
          }
        };

        const removeFromMainState = () => {
          const stateSetter = fileType === "video" ? setVideo : setImageFiles;
          stateSetter((prevState) =>
            prevState?.filter((_, index) => index !== fileIndex)
          );
        };

        if (isThread) {
          removeFromThread();
        } else if (isMultiEdit) {
          removeFromMultiEdit();
        } else {
          removeFromMainState();
        }
      };

      return (
        <div className="preview-file-wrapper">
          <div className="preview-file">
            {hasVideo && (
              <div className="video-preview">
                <video
                  src={URL.createObjectURL((videoFilesArr as File[])[0])}
                ></video>
                <div className="play-wrapper">
                  <PlayIcon backgroundColor={Color.NEUTRAL[100]} size={16} />
                </div>
                <div
                  className="x-button"
                  onClick={() => {
                    handleFileRemove("video", 0, isThread, threadIndex);
                  }}
                >
                  <XCloseIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                </div>
              </div>
            )}
            {hasImages &&
              (imageFilesArr as File[]).map((file, index) => (
                <div className="image-preview" key={index}>
                  <img src={URL.createObjectURL(file)} alt="preview" />
                  <div
                    className="x-button"
                    onClick={() => {
                      handleFileRemove("image", index, isThread, threadIndex);
                    }}
                  >
                    <XCloseIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    },
    [
      currentConnect,
      imageFiles,
      isMultiEdit,
      multiImage,
      multiVideo,
      threadImage,
      threadVideo,
      video,
    ]
  );

  const renderPollInput = useCallback(() => {
    if (!currentConnect) return <></>;

    const formattedPollOptions = isMultiEdit
      ? +currentConnectForm?.pollOptionSize.getValue() || 2
      : pollOptions;

    const formattedDescription = isMultiEdit
      ? currentConnectForm?.description
      : defaultForm[currentConnect?.socialMediaUserId]?.description;

    const pollFormControlMap: Record<number, FormControl> = {
      1: isMultiEdit
        ? currentConnectForm?.pollOption
        : defaultForm[currentConnect?.socialMediaUserId]?.pollOption,
      2: isMultiEdit
        ? currentConnectForm?.pollOption2
        : defaultForm[currentConnect?.socialMediaUserId]?.pollOption2,
      3: isMultiEdit
        ? currentConnectForm?.pollOption3
        : defaultForm[currentConnect?.socialMediaUserId]?.pollOption3,
      4: isMultiEdit
        ? currentConnectForm?.pollOption4
        : defaultForm[currentConnect?.socialMediaUserId]?.pollOption4,
    };

    const pollFormLengthMap: Record<string, FormControl> = {
      minutes: isMultiEdit
        ? currentConnectForm?.pollLengthMinutes
        : defaultForm[currentConnect?.socialMediaUserId]?.pollLengthMinutes,
      hours: isMultiEdit
        ? currentConnectForm?.pollLengthHours
        : defaultForm[currentConnect?.socialMediaUserId]?.pollLengthHours,
      days: isMultiEdit
        ? currentConnectForm?.pollLengthDays
        : defaultForm[currentConnect?.socialMediaUserId]?.pollLengthDays,
    };

    const pollOptionSize = isMultiEdit
      ? currentConnectForm?.pollOptionSize
      : defaultForm[currentConnect?.socialMediaUserId]?.pollOptionSize;

    const handleAddPollOption = () => {
      if (formattedPollOptions < 4) {
        setPollOptions(formattedPollOptions + 1);
        pollOptionSize.setValue((formattedPollOptions + 1).toString());
      }
    };

    const handleRemovePollOption = () => {
      if (formattedPollOptions > 2) {
        setPollOptions(formattedPollOptions - 1);
        pollOptionSize.setValue((formattedPollOptions - 1).toString());
      }
    };

    const handleChangePollLength = (type: string, value: number) => {
      const formattedValue = value.toString();
      const typeMap: Record<string, () => void> = {
        days: () => {
          if (value === 7) {
            pollFormLengthMap["minutes"].setValue("0");
            pollFormLengthMap["hours"].setValue("0");
            pollFormLengthMap["days"].setValue("7");
            setPollLengthValue("7 Days");
          } else {
            pollFormLengthMap["days"].setValue(formattedValue);
            setPollLengthValue(`${value} Days`);
          }
        },
        hours: () => {
          pollFormLengthMap["hours"].setValue(formattedValue);
          setPollLengthValue(`${value} Hours`);
        },
        minutes: () => {
          pollFormLengthMap["minutes"].setValue(formattedValue);
          setPollLengthValue(`${value} Minutes`);
        },
      };

      if (typeMap[type]) {
        typeMap[type]();
      } else {
        console.warn(`Unknown type: ${type}`);
      }
    };

    const menuButtonSpecs: Record<string, any> = {
      days: Array.from({ length: 8 }, (_, index) => {
        return {
          context: index,
          label: `${index}`,
          action: () => {
            handleChangePollLength("days", index);
          },
        };
      }),
      hours: Array.from({ length: 24 }, (_, index) => {
        return {
          context: index,
          label: `${index}`,
          action: () => {
            handleChangePollLength("hours", index);
          },
        };
      }),
      minutes: Array.from({ length: 60 }, (_, index) => {
        return {
          context: index,
          label: `${index}`,
          action: () => {
            handleChangePollLength("minutes", index);
          },
        };
      }),
    };
    const renderPollOptions = () => {
      return Array.from({ length: formattedPollOptions }, (_, index) => (
        <div key={index} className="poll-option-wrapper">
          <EmpTextInput
            labelText={`Choice ${index + 1}`}
            formControl={pollFormControlMap[index + 1]}
            placeholder={`Choice ${index + 1}`}
            multiline
            id={`poll-option-${index}`}
            onChange={handleChangePollContent}
            characterCount={25}
            preventEnter={true}
          />
          <div className="poll-btn-wrapper">
            {formattedPollOptions > 2 && formattedPollOptions === index + 1 && (
              <EmpExpandToggleBtn
                onClick={handleRemovePollOption}
                isExpanded={true}
              />
            )}
            {formattedPollOptions >= 2 && formattedPollOptions < 4 && (
              <EmpExpandToggleBtn
                onClick={handleAddPollOption}
                isExpanded={false}
              />
            )}
          </div>
        </div>
      ));
    };

    return (
      <div className="text-content-section">
        <div className="top-section">
          {renderPostTitle()}

          <div className="poll-input-wrapper mb-2">
            <EmpTextInput
              labelText="Question"
              formControl={formattedDescription}
              placeholder="What's your poll question?"
              onChange={handleChangePreviewDescription}
              multiline
              id="poll-question"
              characterCount={280}
              preventEnter={true}
            />
            <div className="poll-options mb-2">{renderPollOptions()}</div>
            <h3 className="mb-1">Poll Length</h3>
            <p className="mb-2">
              Poll length refers to how long this post will be available for
              poll for.
            </p>
            <div className="poll-length mb-2">
              <div>
                <label>Days</label>
                <EmpDropdownBtn
                  text={
                    !!pollFormLengthMap["days"].getValue()
                      ? pollFormLengthMap["days"].getValue() + " Days"
                      : "Select Days"
                  }
                  menuButtonSpecs={menuButtonSpecs["days"]}
                  buttonStyle="outline-secondary"
                  rightIcon={ChevronDownIcon}
                />
              </div>
              <div>
                <label>Hours</label>
                <EmpDropdownBtn
                  text={
                    !!pollFormLengthMap["hours"].getValue()
                      ? pollFormLengthMap["hours"].getValue() + " Hours"
                      : "Select Hours"
                  }
                  menuButtonSpecs={menuButtonSpecs["hours"]}
                  rightIcon={ChevronDownIcon}
                  buttonStyle={
                    pollLengthValue === "7 Days"
                      ? "secondary"
                      : "outline-secondary"
                  }
                  isDisabled={pollLengthValue === "7 Days"}
                />
              </div>
              <div>
                <label>Minutes</label>
                <EmpDropdownBtn
                  text={
                    !!pollFormLengthMap["minutes"].getValue()
                      ? pollFormLengthMap["minutes"].getValue() + " Minutes"
                      : "Select Minutes"
                  }
                  menuButtonSpecs={menuButtonSpecs["minutes"]}
                  rightIcon={ChevronDownIcon}
                  buttonStyle={
                    pollLengthValue === "7 Days"
                      ? "secondary"
                      : "outline-secondary"
                  }
                  isDisabled={pollLengthValue === "7 Days"}
                />
              </div>
            </div>
          </div>
          {/* <label className="suggested-hashtag">
        Suggested #hashtag
      </label>
      <div className="hashtag-list">
        {hashtags.map((hashtag, index) => (
          <span
            key={index}
            onClick={() => handleAddHashtag(hashtag)}
          >
            <EmpRoundedPill
              clickable={!!postType}
              backgroundColor={Color.PRIMARY[100]}
              color={Color.PRIMARY[500]}
              key={index}
              text={"#" + hashtag}
            />
          </span>
        ))}
      </div> */}
        </div>
      </div>
    );
  }, [
    currentConnect,
    currentConnectForm,
    defaultForm,
    handleChangePollContent,
    handleChangePreviewDescription,
    isMultiEdit,
    pollLengthValue,
    pollOptions,
    renderPostTitle,
    updatedPostType,
  ]);

  const renderTweetInput = useCallback(() => {
    if (!currentConnect) return <></>;
    const handleChangeThread = _debounce(
      (value: FormControl, isMainThread?: boolean) => {
        if (isMainThread) {
          currentConnectForm.description.setValue(value.getValue());
        }
        setPreviewDescription(value.getValue());
        value.validate();
      },
      200
    );

    const removeThreadFile = (index: number) => {
      const newThreadImage = { ...threadImage };
      const newThreadVideo = { ...threadVideo };
      newThreadImage[currentConnect.socialMediaUserId] = newThreadImage[
        currentConnect.socialMediaUserId
      ].filter((_, i) => i !== index);
      newThreadVideo[currentConnect.socialMediaUserId] = newThreadVideo[
        currentConnect.socialMediaUserId
      ].filter((_, i) => i !== index);
      setThreadImage(newThreadImage);
      setThreadVideo(newThreadVideo);
    };

    const handleRemoveThread = (index: number) => {
      if (isMultiEdit) {
        setMultiThreadForm((prevMultiThreadForm) => {
          return {
            ...prevMultiThreadForm,
            [currentConnect?.socialMediaUserId as string]: prevMultiThreadForm[
              currentConnect?.socialMediaUserId as string
            ].filter((_, i) => i !== index),
          };
        });
        if (
          threadImage &&
          threadImage[currentConnect?.socialMediaUserId as string]
        ) {
          setThreadImage((prevThreadImage) => {
            return {
              ...prevThreadImage,
              [currentConnect?.socialMediaUserId as string]: prevThreadImage![
                currentConnect?.socialMediaUserId as string
              ].filter((_, i) => i !== index),
            };
          });
        }
        if (
          threadVideo &&
          threadVideo[currentConnect?.socialMediaUserId as string]
        ) {
          setThreadVideo((prevThreadVideo) => {
            return {
              ...prevThreadVideo,
              [currentConnect?.socialMediaUserId as string]: prevThreadVideo![
                currentConnect?.socialMediaUserId as string
              ].filter((_, i) => i !== index),
            };
          });
        }
      } else {
        setThreadForm((prevThreadForm) => {
          return prevThreadForm.filter((_, i) => i !== index);
        });
      }

      removeThreadFile(index);
    };

    const formattedThreadForm = isMultiEdit
      ? multiThreadForm[currentConnect?.socialMediaUserId]
      : threadForm;

    const handleAddThread = () => {
      if (isMultiEdit) {
        setMultiThreadForm((prevMultiThreadForm) => {
          return {
            ...prevMultiThreadForm,
            [currentConnect?.socialMediaUserId as string]: [
              ...prevMultiThreadForm[
                currentConnect?.socialMediaUserId as string
              ],
              {
                tweet: new FormControl(
                  "text",
                  [
                    new LengthValidator(
                      0,
                      280,
                      "",
                      "Tweet must not exceed 280 characters"
                    ),
                  ],
                  ""
                ),
              },
            ],
          };
        });
      } else {
        setThreadForm((prevThreadForm) => {
          return [
            ...prevThreadForm,
            {
              tweet: new FormControl(
                "text",
                [
                  new LengthValidator(
                    0,
                    280,
                    "",
                    "Tweet must not exceed 280 characters"
                  ),
                ],
                ""
              ),
            },
          ];
        });
      }
    };

    return formattedThreadForm.map((IForm: IFormGroup, index: number) => (
      <>
        <div className={`text-content-section ${index !== 0 ? "mt-3" : ""}`}>
          <div className="top-section">
            <div className="thread-post-title">
              {renderPostTitle(index === 0 ? "Main Tweet" : "Post " + index)}
              {formattedThreadForm.length > 1 && (
                <EmpButton
                  onSubmit={() => handleRemoveThread(index)}
                  text={
                    <XCloseIcon
                      backgroundColor={Color.NEUTRAL[500]}
                      size={20}
                    />
                  }
                  buttonHeight="sm"
                  isFullWidth={false}
                  buttonStyle="secondary"
                />
              )}
            </div>
            <EmpTextInput
              id={`tweet-${index}`}
              key={index}
              formControl={IForm.tweet}
              characterCount={280}
              preventEnter={true}
              multiline
              textAreaAdaptiveHeight
              disabled={!isTextInputAvailable}
              onChange={() => handleChangeThread(IForm.tweet, index === 0)}
              onFocus={() => handleChangeThread(IForm.tweet, index === 0)}
              placeholder="Tell us about your marketing initiative..."
              isChangeValueFromParent
            />
          </div>
          {renderPreviewFile(true, index)}
          <div className="event-post-bottom-section">
            <div className="file-input-wrapper">
              <input
                className="upload-hidden"
                type="file"
                id="logo-upload"
                onChange={(e) => handleFileXUpload(e, index)}
                name="thread-files[]"
                multiple={true}
                accept="image/jpeg, image/jpg, image/png, video/*"
                onClick={(e) => {
                  e.currentTarget.value = "";
                }}
              />
              <p className="upload-text">Upload Media</p>
            </div>
          </div>
        </div>
        {formattedThreadForm.length - 1 === index &&
          (isMultiEdit ? (
            <div className="append-tweet-btn mt-1">
              <EmpButton
                onSubmit={handleAddThread}
                text="Append Tweet"
                buttonHeight="sm"
                isFullWidth={false}
                buttonStyle="secondary"
              />
            </div>
          ) : (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={
                <Tooltip>
                  To create a thread post, you need to turn off Multi-Edit Mode.
                </Tooltip>
              }
            >
              <span>
                <EmpButton
                  onSubmit={() => {}}
                  text="Append Tweet"
                  buttonHeight="sm"
                  isFullWidth={false}
                  disabled={true}
                />
              </span>
            </Whisper>
          ))}
      </>
    ));
  }, [
    currentConnect,
    isMultiEdit,
    multiThreadForm,
    threadForm,
    threadImage,
    threadVideo,
    renderPostTitle,
    isTextInputAvailable,
    renderPreviewFile,
    handleFileXUpload,
  ]);

  const renderWarningMessage = (id: string, value?: any) => (
    <div className="brand-post-pill-wrapper mt-2 mb-2">
      <EmpPill
        backgroundColor={Color.RED[50]}
        color={Color.RED[500]}
        text={<FormattedMessage id={id} values={value} />}
        icon={<AlertCircleIcon backgroundColor={Color.RED[500]} size={16} />}
        size="sm"
        fullWidth={true}
      />
    </div>
  );

  const renderSchedulePostWarningMessage = useCallback(() => {
    if (!isSchedulePost) return null;
    return (
      <div className="brand-post-pill-wrapper mt-1 mb-2">
        <EmpPill
          backgroundColor={"transparent"}
          color={Color.NEUTRAL[400]}
          text={`*Post will publish on ${moment(comparedTimezone).format(
            "ddd, DD MMM YYYY HH:mm (UTCZ)"
          )} (${jstz.determine().name()})`}
          size="sm"
        />
      </div>
    );
  }, [comparedTimezone, isSchedulePost]);

  const checkPlatforms = (platformArr: string[], platformsToCheck: string[]) =>
    platformArr.some((platform) => platformsToCheck.includes(platform));

  const checkMedia = (
    hasImage: boolean | undefined,
    hasVideo: boolean | undefined,
    platformArr: string[],
    platformsToCheck: string[]
  ) => checkPlatforms(platformArr, platformsToCheck) && hasImage && hasVideo;

  const renderMixedMediaWarningMessage = useCallback(() => {
    const platformsToCheck = ["facebook", "tiktok"];
    const warningId = "brandPostEventModal_mixedMediaWarning";

    const getMediaStatus = (connect: any) => {
      const platform = connect.platform.toLowerCase();
      const hasImage = multiImage[connect.socialMediaUserId]?.length > 0;
      const hasVideo = multiVideo[connect.socialMediaUserId]?.length > 0;
      return { platform, hasImage, hasVideo };
    };

    if (!isMultiEdit) {
      const platformArr = selectedConnects.map((connect) =>
        connect.platform.toLowerCase()
      );
      const hasImage = imageFiles && imageFiles?.length > 0;
      const hasVideo = video && video?.length > 0;

      if (checkMedia(hasImage, hasVideo, platformArr, platformsToCheck)) {
        return renderWarningMessage(warningId, { platform: "" });
      }

      return null;
    }

    if (currentConnect) {
      const { platform, hasImage, hasVideo } = getMediaStatus(currentConnect);

      if (checkMedia(hasImage, hasVideo, [platform], platformsToCheck)) {
        const platformName = platform === "facebook" ? "Facebook" : "Tiktok";
        return renderWarningMessage(warningId, { platform: platformName });
      }
    }

    return null;
  }, [
    isMultiEdit,
    currentConnect,
    multiImage,
    multiVideo,
    selectedConnects,
    imageFiles,
    video,
  ]);

  const renderMediaTypeWarningMessage = useCallback(() => {
    const platformsToCheck = ["instagram", "tiktok"];
    const warningId = "brandPostEventModal_mediaTypeWarning";

    const getImageStatus = (connect: any) => {
      const platform = connect.platform.toLowerCase();
      const hasImage = multiImage[connect.socialMediaUserId]?.length > 0;
      return { platform, hasImage };
    };

    const checkImageFormat = (images: any[]) =>
      images.some((file) => file.name.endsWith(".png"));

    if (!isMultiEdit) {
      const platformArr = selectedConnects.map((connect) =>
        connect.platform.toLowerCase()
      );
      const hasImage = imageFiles && imageFiles?.length > 0;

      if (
        checkPlatforms(platformArr, platformsToCheck) &&
        hasImage &&
        checkImageFormat(imageFiles)
      ) {
        return renderWarningMessage(warningId, { format: "png" });
      }

      return null;
    }

    if (currentConnect) {
      const { platform, hasImage } = getImageStatus(currentConnect);

      if (
        checkPlatforms([platform], platformsToCheck) &&
        hasImage &&
        checkImageFormat(multiImage[currentConnect.socialMediaUserId])
      ) {
        return renderWarningMessage(warningId, { format: "png" });
      }
    }

    return null;
  }, [isMultiEdit, currentConnect, multiImage, selectedConnects, imageFiles]);

  const renderMissedFileWarningMessage = useCallback(() => {
    const warningId = "brandPostEventModal_missedFileWarning";

    if (!isMultiEdit) {
      const platformArr = selectedConnects.map((connect) =>
        connect.platform.toLowerCase()
      );
      const hasImage = imageFiles && imageFiles?.length > 0;
      const hasVideo = video && video?.length > 0;

      if (
        checkPlatforms(platformArr, ["instagram", "tiktok"]) &&
        !hasImage &&
        !hasVideo
      ) {
        return renderWarningMessage(warningId, { platform: "" });
      }

      return null;
    }

    if (currentConnect) {
      const platform = currentConnect.platform.toLowerCase();
      const hasImage = multiImage[currentConnect.socialMediaUserId]?.length > 0;
      const hasVideo = multiVideo[currentConnect.socialMediaUserId]?.length > 0;

      if (
        checkPlatforms([platform], ["instagram", "tiktok"]) &&
        !hasImage &&
        !hasVideo
      ) {
        return renderWarningMessage(warningId, { platform });
      }
    }

    return null;
  }, [
    isMultiEdit,
    currentConnect,
    multiImage,
    multiVideo,
    selectedConnects,
    imageFiles,
    video,
  ]);

  const renderTurnOffMultiEditMessage = useCallback(() => {
    if (isMultiEdit || errorConnectsId.length === 0) return <></>;

    return (
      <p className="platform-error-message">
        Not all social media platforms are compatible with the shared content.
        You may <span>turn off&nbsp;</span>
        multi-edit mode to accommodate to different social media platform
        requirements.
      </p>
    );
  }, [errorConnectsId.length, isMultiEdit]);

  const validateConnects = useCallback(() => {
    if (selectedConnects.length < 2) {
      setErrorConnectsId([]);
      return;
    }

    const getCurrentFiles = (files: any, multiFiles: any) =>
      isMultiEdit
        ? multiFiles[currentConnect?.socialMediaUserId as string]
        : files;

    const imgFiles = getCurrentFiles(imageFiles, multiImage);
    const vidFiles = getCurrentFiles(video, multiVideo);

    const platforms = selectedConnects.map((connect) =>
      connect.platform.toLowerCase()
    );
    const hasPlatform = (platform: string) => platforms.includes(platform);

    const platformIds = (platform: string) =>
      selectedConnects
        .filter((connect) => connect.platform.toLowerCase() === platform)
        .map((connect) => connect.socialMediaUserId);

    const checkFileFormat = (files: any[], format: string) =>
      files?.some((file) => file.name.endsWith(format));

    let errorIds: string[] = [];

    if (isMultiEdit) {
      if (!vidFiles || !imgFiles) {
        const newErrorIds = errorConnectsId.filter(
          (id) => id !== currentConnect?.socialMediaUserId
        );
        setErrorConnectsId(newErrorIds);
        return;
      }
    } else {
      if (vidFiles && vidFiles.length && imgFiles && imgFiles.length) {
        if (hasPlatform("facebook")) {
          errorIds.push(...platformIds("facebook"));
        }
        if (hasPlatform("tiktok")) {
          errorIds.push(...platformIds("tiktok"));
        }
      }

      if (hasPlatform("tiktok") && !(imgFiles?.length || vidFiles?.length)) {
        errorIds.push(...platformIds("tiktok"));
      }

      if (hasPlatform("instagram") && !(imgFiles?.length || vidFiles?.length)) {
        errorIds.push(...platformIds("instagram"));
      }

      ["instagram", "tiktok"].forEach((platform) => {
        if (hasPlatform(platform) && checkFileFormat(imgFiles, ".png")) {
          errorIds.push(...platformIds(platform));
        }
      });
    }

    setErrorConnectsId(errorIds);
  }, [
    connects,
    currentConnect?.socialMediaUserId,
    imageFiles,
    isMultiEdit,
    multiImage,
    multiVideo,
    selectedConnects,
    video,
  ]);

  const renderPostInput = useCallback(() => {
    if (!currentConnect) return null;

    return (
      <EmpTextInput
        id="description"
        multiline
        textAreaAdaptiveHeight
        disabled={!isTextInputAvailable}
        formControl={descriptionValue}
        onChange={() => handleChangePreviewDescription(descriptionValue)}
        onFocus={() => handleChangePreviewDescription(descriptionValue)}
        rows={5}
        characterCount={300}
        preventEnter={true}
        placeholder="Tell us about your marketing initiative..."
        isChangeValueFromParent
      />
    );
  }, [
    currentConnect,
    currentConnectForm,
    descriptionValue,
    handleChangePreviewDescription,
    isMultiEdit,
    isTextInputAvailable,
    postType,
    renderPollInput,
  ]);

  const renderTextContentSection = useCallback(() => {
    if (currentConnect) {
      const form = defaultForm[currentConnect?.socialMediaUserId];
      const isXPlatformTweetPost =
        form?.postType?.getValue() === "tweet" &&
        currentConnect?.platform.toLowerCase() === "x";
      const isTweetPost =
        isMultiEdit && currentConnectForm?.postType?.getValue() === "tweet";

      if (isMultiEdit && (isXPlatformTweetPost || isTweetPost)) {
        return renderTweetInput();
      }

      const isPollPost =
        isMultiEdit && currentConnectForm?.postType?.getValue() === "poll";

      const isXPlatformPollPost =
        form?.postType?.getValue() === "poll" &&
        currentConnect?.platform.toLowerCase() === "x";

      if (isPollPost || isXPlatformPollPost) {
        return renderPollInput();
      }
    }

    return (
      <div className="text-content-section">
        <div className="top-section">
          {renderPostTitle()}
          {renderPostInput()}
          {/* <label className="suggested-hashtag">
          Suggested #hashtag
        </label>
        <div className="hashtag-list">
          {hashtags.map((hashtag, index) => (
            <span
              key={index}
              onClick={() => handleAddHashtag(hashtag)}
            >
              <EmpRoundedPill
                clickable={!!postType}
                backgroundColor={Color.PRIMARY[100]}
                color={Color.PRIMARY[500]}
                key={index}
                text={"#" + hashtag}
              />
            </span>
          ))}
        </div> */}
        </div>
        {renderPreviewFile(false)}
        <div className="event-post-bottom-section">
          <div className="file-input-wrapper">
            <input
              className="upload-hidden"
              type="file"
              id="logo-upload"
              onChange={handleFileUpload}
              onClick={(e) => {
                e.currentTarget.value = "";
              }}
              name="files[]"
              multiple={isMultipleFileUpload}
              accept={inputFileValidation}
            />
            <p className="upload-text">Upload Media</p>
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentConnect,
    currentConnectForm,
    defaultForm,
    handleFileUpload,
    inputFileValidation,
    isMultiEdit,
    isMultipleFileUpload,
    renderPollInput,
    renderPostInput,
    renderPostTitle,
    renderPreviewFile,
    renderTweetInput,
    updatedPostType,
  ]);

  const handleFormSubmit = (saveAsDraft?: boolean) => {
    if (postBtnRef.current?.getButtonState() === "loading") return;
    if (!saveAsDraft) {
      if (
        selectedConnects.some(
          (connect) => connect.platform.toLowerCase() === "tiktok"
        ) &&
        !isUpdatedTikTokSettings
      ) {
        const tiktokConnects = selectedConnects.filter(
          (connect) => connect.platform.toLowerCase() === "tiktok"
        );
        tiktokConfigPostModalRef.current?.show(
          tiktokConnects as SmConnectionBriefRespDto[]
        );
        return;
      }
    }

    handleCallCreatePost(saveAsDraft);
  };

  const handleCallCreatePost = useCallback(
    (saveAsDraft?: boolean) => {
      const schedulePostType = isSchedulePost ? "schedule" : "immediately";

      if (selectedConnects.length > 1 || isMultiEdit) {
        handleCreateMultiplePosts(schedulePostType, saveAsDraft);
      } else {
        handleCreatePost(schedulePostType, saveAsDraft);
      }
    },
    [
      handleCreateMultiplePosts,
      handleCreatePost,
      isMultiEdit,
      isSchedulePost,
      selectedConnects.length,
    ]
  );

  useEffect(() => {
    validateConnects();
  }, [validateConnects]);

  // get daily post limitation for tiktok
  useEffect(() => {
    const getDailyPostLimitation = async () => {
      if (!userContext?.organisation) return;
      const brandId = userContext.organisation[0].id;
      const date = isSchedulePost ? getScheduledTime() : 0;
      const response =
        await SocialMediaManagementTikTokApi.getDailyPostLimitation(
          date,
          brandId,
          currentConnect?.socialMediaUserId!
        );
      if (response.status === "success") {
        const { data } = response;
        const isLimitReached = data.isLimitReached;
        if (isLimitReached) {
          setIsReachingLimit(true);
        } else {
          setIsReachingLimit(false);
        }
      }
    };

    if (
      currentConnect &&
      currentConnect.platform.toLowerCase() === "tiktok" &&
      userContext
    ) {
      getDailyPostLimitation();
    } else {
      setIsReachingLimit(false);
    }
  }, [currentConnect, isSchedulePost, userContext]);

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
    dismiss: () => {
      setVisible(false);
    },
  }));

  return (
    <>
      <EmpModal
        customClassName="brand-event-post-modal"
        visible={visible}
        setVisible={setVisible}
        showHeader={false}
        showFooter={true}
        showFooterBorder={true}
        onClose={() => {
          dismiss();
        }}
        size={"lg"}
      >
        {/* This is body */}
        <div className="content-wrapper">
          {selectedConnects.length > 0 && updatedPostType > 0 && (
            <BrandPostPreviewModal
              captureSectionRef={captureSectionRef}
              videoFiles={
                isMultiEdit
                  ? multiVideo[currentConnect?.socialMediaUserId as string]
                  : video
              }
              imageFiles={
                isMultiEdit
                  ? multiImage[currentConnect?.socialMediaUserId as string]
                  : imageFiles
              }
              postType={currentConnectForm?.postType?.getValue() as SmPostType}
              fileUploadedType={uploadedFileType}
              selectedConnects={selectedConnects}
              currentSelectedConnect={currentConnect}
              description={descriptionValue.getValue()}
              pollOptions={+currentConnectForm?.pollOptionSize.getValue()}
              generalForm={currentConnectForm}
              multiForm={form}
              isMultiEdit={isMultiEdit}
              threadForm={
                isMultiEdit && currentConnect
                  ? multiThreadForm[currentConnect?.socialMediaUserId]
                  : undefined
              }
              threadVideo={threadVideo}
              threadImage={threadImage}
            />
          )}
          {isUploading && (
            <EmpLoaderV2
              isLoading={true}
              loadingText="Wait a moment for the upload to complete"
              background="dark"
            />
          )}
          <div className="event-post-modal-header">
            <h1>Create Post</h1>
          </div>
          <p className="">
            Select the social media accounts you want to post to.
          </p>
          <div className="connects-wrapper pt-2 mt-1">
            {connects.map((connect, index: number) => (
              <div
                className={`profile-wrapper ${
                  selectedConnects.some(
                    (selectedConnects) =>
                      selectedConnects.socialMediaUserId ===
                      connect.socialMediaUserId
                  )
                    ? "active"
                    : ""
                } ${
                  currentConnect?.socialMediaUserId ===
                  connect.socialMediaUserId
                    ? "current"
                    : ""
                } ${connect?.is_expired === true ? "expired" : ""}`}
                onClick={() => {
                  if (connect?.is_expired) return;
                  handleSelectionChange(connect);
                }}
                key={index}
              >
                <div
                  className={`picture-section ${
                    errorConnectsId.some(
                      (errorId) => errorId === connect.socialMediaUserId
                    )
                      ? "error"
                      : ""
                  }`}
                >
                  <img
                    src={connect?.pictureUrl || EMPTY_PROFILE_IMG_URL}
                    alt={`${connect?.name} ${connect?.platform} dp`}
                  />
                  <div
                    className={`social-media-bubble ${connect?.platform.toLowerCase()}`}
                  >
                    {connect?.platform.toLowerCase() === "facebook" && (
                      <FacebookIcon
                        backgroundColor={Color.NEUTRAL[0]}
                        size={12}
                      />
                    )}
                    {connect?.platform.toLowerCase() === "instagram" && (
                      <InstagramIcon
                        backgroundColor={Color.NEUTRAL[0]}
                        size={12}
                      />
                    )}
                    {connect?.platform.toLowerCase() === "tiktok" && (
                      <TikTokIcon
                        backgroundColor={Color.NEUTRAL[0]}
                        size={12}
                      />
                    )}
                    {connect?.platform.toLowerCase() === "x" && (
                      <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                    )}
                  </div>
                  <div className="error-icon">!</div>
                </div>
                <div className="info-section">
                  <span className="handle-name-lbl">{connect?.name}</span>
                  <span className="metric-lbl">
                    {connect?.followers} Followers
                  </span>
                </div>
                {currentConnect?.socialMediaUserId ===
                  connect.socialMediaUserId && (
                  <div
                    className="x-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnselectConnect(connect);
                    }}
                  >
                    <XCloseIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                  </div>
                )}
              </div>
            ))}
          </div>

          {selectedConnects.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              {isReachingLimit && (
                <div className="brand-post-pill-wrapper mt-2 mb-2">
                  <EmpPill
                    backgroundColor={Color.RED[50]}
                    color={Color.RED[500]}
                    text={`You have reached the daily post limit for ${currentConnect?.name} TikTok account. Try again later.`}
                    icon={
                      <AlertCircleIcon
                        backgroundColor={Color.RED[500]}
                        size={16}
                      />
                    }
                    size="sm"
                  />
                </div>
              )}
              {renderTurnOffMultiEditMessage()}
              {!isMultiEdit && (
                <div className="multi-edit-section">
                  <div>
                    <h3 className="mb-1">Multi-Edit Mode</h3>
                    <p>
                      You may enter content that are universal across all
                      platform first. You may turn it off later once you want to
                      edit individual posts
                    </p>
                  </div>
                  <div className="multi-edit-btn">
                    <EmpButton
                      text={"Turn off"}
                      isFullWidth={false}
                      buttonStyle="secondary"
                      buttonHeight="md"
                      onSubmit={() => handleChangeMultiEdit()}
                    />
                  </div>
                </div>
              )}
              {isMultiEdit && !isClosedEditText && (
                <div className="enabled-multi-edit-section">
                  <div>
                    <h3 className="mb-1">Edit Individual Platforms</h3>
                    <p>
                      You can now edit content separately for each social media
                      platforms.
                    </p>
                  </div>
                  <div
                    className="close-btn"
                    onClick={() => {
                      setIsClosedEditText(true);
                    }}
                  >
                    <XCloseIcon
                      backgroundColor={Color.NEUTRAL[500]}
                      size={16}
                    />
                  </div>
                </div>
              )}
              <div className="mb-4 post-tab">
                <EmpButtonTab options={postTypeOptions} />
              </div>
              {renderTextContentSection()}
              {renderMixedMediaWarningMessage()}
              {renderMissedFileWarningMessage()}
              {renderMediaTypeWarningMessage()}
              {selectedConnects.some((connect) => {
                return connect.platform.toLowerCase() === "tiktok";
              }) &&
                !isUpdatedTikTokSettings && (
                  <div className="brand-post-pill-wrapper mt-2 mb-2">
                    <EmpPill
                      backgroundColor={Color.NEUTRAL[100]}
                      color={Color.NEUTRAL[500]}
                      text="You may configure content disclosure, privacy level and interaction ability on the next step"
                      icon={
                        <AlertCircleIcon
                          backgroundColor={Color.NEUTRAL[500]}
                          size={16}
                        />
                      }
                      size="sm"
                    />
                  </div>
                )}
              <div className="date-picker-wrapper">
                <h3 className="mb-2">
                  Post Schedule&nbsp;
                  <span className="optional-text">(Optional)</span>
                </h3>
                <div>
                  <EmpDatePicker
                    disabledDate={DateRangePicker.beforeToday}
                    formControl={generalForm.dateOfPosting}
                    placeholder={`Schedule`}
                    onChange={handleDateChange}
                  />
                  <EmpDateInput
                    formControl={generalForm.timeOfPosting}
                    onChange={handleTimeChange}
                  />
                </div>
              </div>
              {renderSchedulePostWarningMessage()}
            </motion.div>
          )}
        </div>

        {/* This is footer */}
        <div className="footer-btn-wrapper">
          <div className="left-side">
            <EmpButton
              ref={discardBtnRef}
              text={"Discard post"}
              isFullWidth={false}
              buttonStyle="danger"
              onSubmit={handleDiscardPost}
            />
            <EmpButton
              onSubmit={() => handleFormSubmit(true)}
              isFullWidth={false}
              text={"Save to draft"}
              buttonHeight="md"
              buttonStyle="secondary"
              ref={saveToDraftBtnRef}
              disabled={!isSubmitAvailable()}
            />
          </div>
          <div className="right-side">
            <EmpButton
              onSubmit={() => handleFormSubmit()}
              isFullWidth={false}
              text={isSchedulePost ? "Schedule Post(s)" : "Post Immediately"}
              buttonHeight="md"
              ref={postBtnRef}
              disabled={!isSubmitAvailable()}
            />
          </div>
        </div>
      </EmpModal>

      {selectedConnects.some((connect) => {
        return connect.platform.toLowerCase() === "tiktok";
      }) &&
        !isUpdatedTikTokSettings && (
          <TikTokConfigPostModal
            ref={tiktokConfigPostModalRef}
            generalForm={generalForm}
            isMultiEdit={isMultiEdit}
            multiForm={form}
            setIsUpdatedTikTokSettings={setIsUpdatedTikTokSettings}
            videoFiles={video}
            imageFiles={imageFiles}
            multiImage={multiImage}
            multiVideo={multiVideo}
            fnSubmit={handleCallCreatePost}
            defaultForm={defaultForm}
          />
        )}
    </>
  );
});

export default BrandEventPostModal;
