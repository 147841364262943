import React, { useEffect, useRef, useState } from "react";
import {
  DialCodeType,
  DIAL_CODE_CONSTANTS,
  DIAL_CODE_CONSTANTS_INTL,
} from "../../../constants/dial-code.constants";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import ChevronDownIcon from "../../icon/chevron-down";
import SearchIcon from "../../icon/search-icon";
import "./emp-mobile-number-select.scss";
import _debounce from "lodash/debounce";
import { empDelay } from "../../../utilities/delay";
import ChevronUpIcon from "../../icon/chevron-up";
import { IntlShape } from "react-intl";
// Require `PhoneNumberFormat`.
const PNF = require("google-libphonenumber").PhoneNumberFormat;

// Get an instance of `PhoneNumberUtil`.
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

interface Props {
  className?: string;
  style?: React.CSSProperties;
  intl?: IntlShape;
  labelText?: string;
  value?: string;
  placeholder?: string;
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;

  // EmpTextInput Form Integration
  id: string;
  formControl: FormControl;
  formValidation?: () => void;

  // Callbacks to the parent component
  onChange?: (formControl: FormControl) => void;
  onKeypress?: (key: string) => void;
}

const EmpMobileNumberSelect = (props: Props) => {
  const {
    onChange,
    required,
    disabled,
    style,
    className,
    id,
    labelText,
    placeholder,
    initialValue,
    formControl,
    intl,
  } = props;
  const value = initialValue ? initialValue : "";

  const formControlRef = useRef<HTMLDivElement>(null);
  const multiSelectRef = useRef<HTMLDivElement>(null);

  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const isExpandedRef = useRef<boolean>(false);
  const isRequired = required ? required : false;

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFocused, setFocused] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const [selectedDialCode, setSelectedDialCode] = useState<DialCodeType>();
  const [filteredDialCode, setFilteredDialCode] = useState<DialCodeType[]>([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchTerm = useRef<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const referencedDialCodeRef = useRef<DialCodeType[]>(DIAL_CODE_CONSTANTS);

  useEffect(() => {
    if (intl) {
      referencedDialCodeRef.current = DIAL_CODE_CONSTANTS_INTL(intl);
    }
    // Setting the country dropdown.
    setFilteredDialCode(referencedDialCodeRef.current);
    const code = formControl.getValue().code ?? "";
    const defaultDial = referencedDialCodeRef.current.find(
      (elem) => elem.code === "SG"
    );
    const countryDial = referencedDialCodeRef.current.find(
      (elem) => elem.code === code
    );
    setSelectedDialCode(countryDial ?? defaultDial);
    const mobileNumber = formControl.getValue().mobileNumber ?? "";

    // Setting the input field
    const result = formatPhoneNumber(
      mobileNumber,
      countryDial?.code ?? defaultDial!.code
    );
    if (result.isValid) {
      inputRef.current!.value = result.phoneNumber;
    } else inputRef.current!.value = mobileNumber;
  }, [formControl.resetFlag]);

  useEffect(() => {
    if (!intl) return;
    referencedDialCodeRef.current = DIAL_CODE_CONSTANTS_INTL(intl);
    setFilteredDialCode(referencedDialCodeRef.current);
    searchTerm.current = "";
  }, [intl]);

  // This function is called when the component is mounted and whenever the
  // isExpanded prop changes.
  useEffect(() => {
    const setSearchValue = async () => {
      await empDelay(20);
      if (searchInputRef.current) {
        searchInputRef.current.value = searchTerm.current;
        searchInputRef.current.focus();
      }
    };
    isExpandedRef.current = isExpanded;
    if (isExpanded) {
      setSearchValue();
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!multiSelectRef.current) return;
      if (
        !multiSelectRef.current.contains(event.target as Node) &&
        !optionsWrapperRef.current?.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formControlRef]);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  const handleOnChange = (elem: DialCodeType) => {
    setExpanded(false);
    setSelectedDialCode(elem);

    formControl.setValue({
      code: elem.code,
      mobileNumber: formControl.getValue().mobileNumber,
      dialCode: elem.dial_code,
    });
    if (onChange) onChange(formControl);
  };

  function formatPhoneNumber(
    inputPhoneNumber: string,
    code: string
  ): { isValid: boolean; phoneNumber: string } {
    try {
      if (!selectedDialCode || inputPhoneNumber.length < 1)
        return { isValid: false, phoneNumber: inputPhoneNumber };
      // Parse the inputPhoneNumber
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
        inputPhoneNumber,
        code
      ); // 'SG' for Singapore
      if (phoneUtil.isValidNumber(parsedPhoneNumber)) {
        // Format the phone number
        const formattedNumber = phoneUtil.format(
          parsedPhoneNumber,
          PNF.NATIONAL
        );
        return { isValid: true, phoneNumber: formattedNumber };
      }
      return { isValid: false, phoneNumber: inputPhoneNumber };
    } catch (e) {
      if (e instanceof Error) {
        if (
          e.message === "The string supplied did not seem to be a phone number"
        ) {
          return { isValid: false, phoneNumber: inputPhoneNumber };
        } else console.error(e);
      }
    }
    // Return the input as is if it's not a valid phone number
    return { isValid: false, phoneNumber: inputPhoneNumber };
  }

  const textOnChange = (mobileNumber: string) => {
    const formattedNumber = formatPhoneNumber(
      mobileNumber,
      selectedDialCode?.code ?? ""
    );
    if (formattedNumber.isValid) {
      if (!inputRef.current) return;
      inputRef.current.value = formattedNumber.phoneNumber;
    }
    formControl.setValue({
      code: selectedDialCode?.code ?? undefined,
      mobileNumber,
      dialCode: selectedDialCode?.dial_code ?? undefined,
    });
    if (onChange) onChange(formControl);
  };

  function handleInputChange(value: string) {
    searchTerm.current = value;
    const filteredDialCodes = referencedDialCodeRef.current.filter(
      (elem) =>
        elem.name.toLowerCase().includes(value.toLowerCase()) ||
        elem.dial_code.includes(value)
    );
    setFilteredDialCode(filteredDialCodes);
  }
  const debouncedHandleInputChange = _debounce(handleInputChange, 200);

  return (
    <div className={`emp-mobile-select-wrapper ${className}`} style={style}>
      <label className="block emp-multi-select-label" htmlFor={id}>
        {labelText} {isRequired && <span className="required">*</span>}
      </label>

      {/* Pasted Text input */}
      <div
        className={`emp-text-input-wrapper ${isFocused ? "focused" : ""} ${disabled ? "disabled" : ""}`}
      >
        <div
          ref={multiSelectRef}
          className="dial-code-wrapper"
          onClick={() => {
            setExpanded(true);
          }}
        >
          {!selectedDialCode && (
            <div className="emp-left-icon-wrapper">
              <div className="empty-flag">
                <span>?</span>
              </div>
            </div>
          )}
          {selectedDialCode && (
            <div className="emp-left-icon-wrapper">
              <img
                className="img-flag"
                alt="selected country"
                src={selectedDialCode.flag_4x3}
              />
              <span className="dial-code">{selectedDialCode.dial_code}</span>
              <div>
                {!isExpanded && (
                  <ChevronDownIcon
                    size={18}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                )}
                {isExpanded && (
                  <ChevronUpIcon
                    size={18}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <input
          ref={inputRef}
          className={`emp-text-input left-icon`}
          placeholder={placeholder}
          defaultValue={value}
          type="text"
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          // Callbacks
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            textOnChange(event.target.value);
          }}
        />
      </div>
      {isExpanded && (
        <div ref={optionsWrapperRef} className="emp-multi-select-section">
          <div className="search-input-wrapper">
            <SearchIcon size={16} backgroundColor={Color.NEUTRAL[300]} />
            <input
              ref={searchInputRef}
              className="search-input"
              type="text"
              placeholder="Search by countries..."
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                debouncedHandleInputChange(event.target.value);
              }}
            />
          </div>

          <div className="checkbox-section">
            {filteredDialCode.map((elem, index) => {
              return (
                <EmpMultiSelectItem
                  key={elem.code}
                  handleOnChange={handleOnChange}
                  elem={elem}
                />
              );
            })}
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            top={-0.5}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
export default EmpMobileNumberSelect;

interface MultiSelectItemProp {
  isEmpty?: boolean;
  handleOnChange: (elem: DialCodeType) => void;
  elem: DialCodeType;
}

const EmpMultiSelectItem = (props: MultiSelectItemProp) => {
  const isEmpty = props.isEmpty ?? false;
  const { handleOnChange, elem } = props;

  const checkboxWrapperOnClick = () => {
    try {
      handleOnChange(elem);
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    }
  };

  return (
    <div
      onClick={() => {
        checkboxWrapperOnClick();
      }}
      className={`checkbox-wrapper`}
    >
      {isEmpty && (
        <div className="empty-option-section">
          <span>No Mobile Country Code</span>
        </div>
      )}
      {!isEmpty && (
        <div className="country-option-wrapper">
          <img className="img-flag" alt="country" src={elem.flag_4x3} />
          <span className="country-label">{elem.dial_code}</span>
          <span className="country-label">{elem.name}</span>
        </div>
      )}
    </div>
  );
};
