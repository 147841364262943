import "./edit-profile-pictures-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import EditIcon from "../../components/icon/edit-icon";
import EmpException from "../../exception/empException";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../components/shared/emp-cropper/emp-cropper";
import FileUtils from "../../utilities/file-util";
import { SaveProfilePicturesReqDto } from "../../model/profile/save-profile-pictures-req.dto";
import ProfileApi from "../../api/user-msvc/profile.api";
import ToastUtils from "../../utilities/toast-utils";
import AlertSquareIcon from "../../components/icon/alert-square";
import { FormattedMessage } from "react-intl";
import { Form } from "react-router-dom";

export interface EditProfilePicturesModalRef {
  show: (profile: AgencyProfileDto, organisation: OrganisationRespDto) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const EditProfilePicturesModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [profile, setProfile] = useState<AgencyProfileDto>();
  const [organisation, setOrganisation] = useState<OrganisationRespDto>();

  const logoOverlayRef = useRef<HTMLDivElement>(null);
  const bannerOverlayRef = useRef<HTMLDivElement>(null);

  const logoCropperRef = useRef<EmpCropperRef>();
  const bannerCropperRef = useRef<EmpCropperRef>();
  const logoIsModified = useRef<boolean>(false);
  const bannerIsModified = useRef<boolean>(false);
  const [logo, setLogo] = useState<string>();
  const [banner, setBanner] = useState<string>();

  const saveBtnRef = useRef<EmpButtonRef>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    profile: AgencyProfileDto,
    organisation: OrganisationRespDto
  ) => {
    setLogo(organisation.logo);
    setBanner(profile.bannerPicture);
    setOrganisation(organisation);
    setProfile(profile);
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    bannerIsModified.current = false;
    logoIsModified.current = false;
    setVisible(false);
  };

  const resetForm = () => {
    // Resetting the form
    setProfile(undefined);
    setOrganisation(undefined);
  };

  const logoHoverEvent = (type: "mouse-in" | "mouse-out"): void => {
    if (!logoOverlayRef.current)
      throw new EmpException("Overlay element not found");
    if (type === "mouse-in") {
      logoOverlayRef.current.style.opacity = "1";
    } else if (type === "mouse-out") {
      logoOverlayRef.current.style.opacity = "0";
    }
  };

  const bannerHoverEvent = (type: "mouse-in" | "mouse-out"): void => {
    if (!bannerOverlayRef.current)
      throw new EmpException("Overlay element not found");
    if (type === "mouse-in") {
      bannerOverlayRef.current.style.opacity = "1";
    } else if (type === "mouse-out") {
      bannerOverlayRef.current.style.opacity = "0";
    }
  };

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    origin: "logo" | "banner"
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);

    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 1MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await FileUtils.readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image

    if (origin === "logo") {
      if (logoCropperRef.current === undefined) return;
      logoCropperRef.current.open(base64);
    } else if (origin === "banner") {
      if (bannerCropperRef.current === undefined) return;
      bannerCropperRef.current.open(base64);
    }
  };

  /**
   * Saves the display pictures (banner and logo) for the profile.
   *
   * @throws {EmpException} If unable to get the organization and profile.
   * @throws {EmpExceptionHandler} If there is an error during the saving of the profile pictures.
   */
  const saveDisplayPicture = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!profile || !organisation)
        throw new EmpException("Unable to get organiation and profile");
      const request: SaveProfilePicturesReqDto = {
        bannerBase64: bannerIsModified.current ? banner : undefined,
        logoBase64: logoIsModified.current ? logo : undefined,
        organisationId: organisation.id,
        profileId: profile.id,
      };
      const resp = await ProfileApi.saveProfilePicture(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Saved", "Profile Pictures Updated");
        props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save profile pictures"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-profile-pictures-modal">
        <EmpCropper
          onCrop={(base64: string) => {
            setLogo(base64);
            logoIsModified.current = true;
          }}
          ref={logoCropperRef}
        />
        <EmpCropper
          onCrop={(base64: string) => {
            setBanner(base64);
            bannerIsModified.current = true;
          }}
          ref={bannerCropperRef}
          aspect={9}
          cropShape="rect"
        />
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="uploadAgencyPic_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="uploadAgencyPic_desc" />
          </p>
        </div>

        <div className="edit-picture-section mt-2">
          {logo && (
            <div className="profile-pic-section">
              <span className="picture-label">
                <FormattedMessage id="uploadAgencyPic_agencyLogoHeader" />
              </span>
              <p className="description mt-1 mb-2">
                <FormattedMessage id="uploadAgencyPic_agencyLogoDesc" />
              </p>
              <label
                htmlFor="logo-upload"
                onMouseEnter={() => {
                  logoHoverEvent("mouse-in");
                }}
                onMouseLeave={() => {
                  logoHoverEvent("mouse-out");
                }}
                className="logo-wrapper"
              >
                <div ref={logoOverlayRef} className="hover-overlay">
                  <EditIcon backgroundColor={Color.NEUTRAL[0]} />
                </div>
                <img className="logo-img" alt="organisation logo" src={logo} />
              </label>
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="logo-upload"
                onChange={(e) => {
                  handleFileUpload(e, "logo");
                }}
                name="myfile"
              ></input>
              {imageErrorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{imageErrorMessage}</span>
                </div>
              )}
            </div>
          )}

          {banner && (
            <div className="profile-pic-section">
              <span className="picture-label">
                <FormattedMessage id="uploadAgencyPic_agencyBannerHeader" />
              </span>
              <p className="description mt-1 mb-2">
                <FormattedMessage id="uploadAgencyPic_agencyBannerDesc" />
              </p>
              <label
                htmlFor="banner-upload"
                onMouseEnter={() => {
                  bannerHoverEvent("mouse-in");
                }}
                onMouseLeave={() => {
                  bannerHoverEvent("mouse-out");
                }}
                className="banner-wrapper"
              >
                <div ref={bannerOverlayRef} className="hover-overlay">
                  <div className="hover-info-wrapper">
                    <EditIcon size={16} backgroundColor={Color.NEUTRAL[0]} />{" "}
                    <span>Edit Banner</span>
                  </div>
                </div>
                <img
                  className="banner-img"
                  alt="organisation logo"
                  src={banner}
                />
              </label>
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="banner-upload"
                onChange={(e) => {
                  handleFileUpload(e, "banner");
                }}
                name="myBanner"
              ></input>
            </div>
          )}
        </div>
        <hr className="mv-5" />

        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={<FormattedMessage id="cta_cancel" />}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            disabled={!bannerIsModified.current && !logoIsModified.current}
            ref={saveBtnRef}
            isFullWidth={false}
            text={<FormattedMessage id="cta_save" />}
            onSubmit={() => {
              saveDisplayPicture();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default EditProfilePicturesModal;
