import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DeliverableConversationDto } from "../../../model/campaign/deliverable-conversation.dto";
import { DateUtil } from "../../../utilities/date";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import "./seller-task-chat-view.scss";
import UploadChatAttachmentModal, {
  UploadChatAttachmentModalRef,
} from "../../../components/modals/upload-chat-attachment-modal";
import { DeliverableMessageDto } from "../../../model/campaign/deliverable-message.dto";
import UserUtils from "../../../utilities/user-utils";
import { AppContext } from "../../../context/app.context";
import {
  ATTACHMENT_TYPE,
  PLATFORM_ROLES,
} from "../../../constants/app.constants";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import { DeliverableMessageWIdDto } from "../../../model/campaign/deliverable-message-w-id.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { EmpLoaderRef } from "../../../components/shared/emp-loader/emp-loader";
import EmpException from "../../../exception/empException";
import { EmpCampaignAccessControlView } from "../../../components/shared/emp-campaign-access-control-view/emp-campaign-access-control-view";
import { SellerChatMobile } from "./mobile/seller-chat-mobile";
import { SellerChatDesktop } from "./desktop/seller-chat-desktop";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";

interface Props {
  task: TaskDto;
}

export const SellerTaskChatView = (props: Props) => {
  const task = props.task;
  const taskId = props.task.id;

  const [deliverableConversations, setDeliverableConversations] =
    useState<DeliverableConversationDto[]>();
  const [selectedConversation, setSelectedConversation] =
    useState<DeliverableConversationDto>();

  const [chatIsLoading, setChatIsLoading] = useState(true);
  const chatLoaderRef = useRef<EmpLoaderRef>();
  const uploadChatAttachmentRef = useRef<UploadChatAttachmentModalRef>();

  const { user: userContext } = useContext(AppContext);
  const representative = useRef<{ id: string; role: string }>();

  const [accessControlGranted, setAccessControlGranted] = useState(false);
  const [accessControlVisible, setAccessControlVisible] = useState(true);

  const mobileBreakpoint = useEmpBreakpoint(520);

  const getConversations = useCallback(async () => {
    try {
      if (!representative.current)
        throw new EmpException("No representative found");
      const resp = await OngoingTaskApi.fetchSellerConversations(
        taskId,
        representative.current!.id
      );
      setDeliverableConversations(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch conversations"
      );
    }
  }, [taskId]);

  const getRepresentative = useCallback(async () => {
    const user = await UserUtils.fetchUser(userContext);
    if (user.role === "talent") {
      representative.current = {
        id: user.id,
        role: PLATFORM_ROLES.CREATOR,
      };
    } else if (
      user.organisation &&
      user.organisation.length > 0 &&
      user.organisation![0].type === PLATFORM_ROLES.AGENCY
    ) {
      representative.current = {
        id: user.organisation![0].id,
        role: PLATFORM_ROLES.AGENCY,
      };
    }
  }, [userContext]);

  useEffect(() => {
    if (!accessControlGranted) return;
    const runTasks = async () => {
      await getRepresentative();
      getConversations();
    };
    runTasks();
  }, [userContext, getConversations, getRepresentative, accessControlGranted]);

  useEffect(() => {
    if (!accessControlGranted) return;
  }, [deliverableConversations, accessControlGranted]);

  useEffect(() => {
    if (chatIsLoading) chatLoaderRef.current?.show();
    else chatLoaderRef.current?.hide();
  }, [chatIsLoading]);

  return (
    <div className="seller-task-chat-view" style={{ position: "relative" }}>
      {accessControlVisible && (
        <EmpCampaignAccessControlView
          backgroundColor="gray"
          resourceType="ongoing-task-chat"
          role="seller"
          requestSpecs={{
            taskId: task.id,
            campaignId: task.campaign.id,
          }}
          onGranted={() => {
            setAccessControlGranted(true);
          }}
          onUnmount={() => {
            setAccessControlVisible(false);
          }}
        />
      )}

      {accessControlGranted && (
        <>
          {mobileBreakpoint && representative.current! && (
            <SellerChatMobile
              task={task}
              onRefresh={() => getConversations()}
              conversations={deliverableConversations}
              representativeId={representative.current.id}
            />
          )}

          {!mobileBreakpoint && representative.current! && (
            <SellerChatDesktop
              task={task}
              onRefresh={() => getConversations()}
              conversations={deliverableConversations}
              representativeId={representative.current.id}
            />
          )}
        </>
      )}
    </div>
  );
};
