export interface EmpLanguage {
  locale: string;
  label: string;
  flag: string;
}
export const LANGUAGE_CONSTANTS = [
  {
    locale: "en",
    label: "English",
    flag: "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/us.svg",
  },
  {
    locale: "zh",
    label: "简体中文",
    flag: "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cn.svg",
  },
  // {
  //     locale: "th",
  //     label: "ภาษาไทย",
  //     flag: "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/th.svg",
  // },
  {
    locale: "id",
    label: "Bahasa Indonesia",
    flag: "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/id.svg",
  },
];
