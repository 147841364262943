import { useCallback } from "react";

import XApi from "../../../../api/social-integration-msvc/x.api";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../../utilities/toast-utils";
import { LocalStorageUtil } from "../../../../utilities/cache/local-storage-util";
import { SmRedirectDefinitionLocalStorage } from "../../../sm-redirect-page/sm-redirect";

export type BindXAccountFn = (
  twitterCode: string,
  setLoader?: React.Dispatch<React.SetStateAction<"x" | "none" | "tiktok">>,
  refreshFn?: () => void,
  replacedUrl?: string
) => Promise<void>;

export function useXLogin() {
  const xLogin = async (
    redirectUrl: string,
    metadata?: { [key: string]: string }
  ) => {
    try {
      const redirectDefinition: SmRedirectDefinitionLocalStorage = {
        value: redirectUrl,
        metadata: metadata,
        platform: "X",
      };
      LocalStorageUtil.setItem("sm-redirect-definition", redirectDefinition);
      const resp = await XApi.auth();
      window.location.href = resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to Twitter"
      );
    }
  };

  // Twitter/X Detection
  const bindXAccount = useCallback(
    async (
      twitterCode: string,
      setLoader?: React.Dispatch<React.SetStateAction<"x" | "none" | "tiktok">>,
      refreshFn?: () => void,
      replacedUrl?: string
    ) => {
      try {
        const response = await XApi.getToken(twitterCode);
        if (response.data.status === "success") {
          ToastUtils.success(
            "Account Binded",
            "You have binded your X account successfully."
          );
          if (refreshFn) refreshFn();
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to Twitter account"
        );
      } finally {
        if (setLoader) setLoader("none");
        if (replacedUrl) window.history.replaceState("", "", replacedUrl);
      }
    },
    []
  );

  return {
    xLogin,
    bindXAccount,
  };
}
