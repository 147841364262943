import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import RateCardApi from "../../../api/social-integration-msvc/rate-card.api";
import SettingsApi from "../../../api/user-msvc/settings.api";
import RateCardAcceptConfirmationModal, {
  RateCardAcceptConfirmationModalRef,
} from "../../../components/modals/rate-card-accept-confirmation-modal";
import RateCardRejectConfirmationModal, {
  RateCardRejectConfirmationModalRef,
} from "../../../components/modals/rate-card-reject-confirmation-modal";
import RatePrivacySettingModal, {
  RateCardPrivacyModalRef,
} from "../../../components/modals/rate-privacy-setting-modal";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpLoader, {
  EmpLoaderRef,
} from "../../../components/shared/emp-loader/emp-loader";
import { RateCardRequestCard } from "../../../components/shared/emp-rate-cards-request-card/emp-rate-cards-request-card";
import { RateCardRequestDto } from "../../../model/social-media/rate-card-request.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import "./rate-card-request-view.scss";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";

interface Props {
  onChange: () => void;
}

export const RateCardRequestView = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rateCardsRequests, setRateCardRequests] =
    useState<RateCardRequestDto[]>();
  const [isRatePrivacyEnabled, setRatePrivacyEnabled] = useState<boolean>();
  const rateCardAcceptConfirmationModalRef =
    useRef<RateCardAcceptConfirmationModalRef>();
  const rateCardRejectConfirmationModalRef =
    useRef<RateCardRejectConfirmationModalRef>();
  const rateCardPrivacySettingRef = useRef<RateCardPrivacyModalRef>();

  const [form, setForm] = useState<IFormGroup>({});

  useEffect(() => {
    if (isRatePrivacyEnabled !== undefined && rateCardsRequests)
      setIsLoading(false);
  }, [isRatePrivacyEnabled, rateCardsRequests]);

  const fetchRateCardPrivacySetting = useCallback(async () => {
    try {
      const resp = await SettingsApi.fetchRatePrivacy();
      const ratePrivacy = resp.data;
      const value = Boolean(ratePrivacy.value === "Y");
      setRatePrivacyEnabled(value);
      form.ratePrivacy = new FormControl("boolean", [], value);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch rate card privacy settings"
      );
    }
  }, [form]);

  const fetchRateCardRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await RateCardApi.getPendingBrandRateCardRequests();
      setRateCardRequests(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch brand rate card request"
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRateCardRequest();
    fetchRateCardPrivacySetting();

    const subscriptionCallback = () => {
      fetchRateCardRequest();
    };

    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.RATE_CARD_REQUEST_UPDATE,
      subscriptionCallback
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [fetchRateCardRequest, fetchRateCardPrivacySetting]);

  useEffect(() => {
    if (isLoading === true) empLoaderRef.current?.show();
    if (isLoading === false) empLoaderRef.current?.hide();
  }, [isLoading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  return (
    <div className="emp-rate-card-request-view">
      <RateCardAcceptConfirmationModal
        ref={rateCardAcceptConfirmationModalRef}
        onSave={() => {
          fetchRateCardRequest();
          props.onChange();
        }}
      />
      <RateCardRejectConfirmationModal
        ref={rateCardRejectConfirmationModalRef}
        onSave={() => {
          fetchRateCardRequest();
          props.onChange();
        }}
      />
      <RatePrivacySettingModal
        onSave={() => fetchRateCardPrivacySetting()}
        ref={rateCardPrivacySettingRef}
      />
      <EmpLoader ref={empLoaderRef} />

      <div className="mt-4">
        <div className="header-wrapper">
          <div className="right-elem">
            <h1>
              <FormattedMessage id="rateCardRequestView_header" />
            </h1>
            <p className="page-description">
              <FormattedMessage id="rateCardRequestView_desc" />
            </p>
          </div>
        </div>
      </div>

      {!isLoading && !isRatePrivacyEnabled && (
        <div className="privacy-intro-card mb-4">
          <div className="grid-h one"></div>
          <div className="grid-h two"></div>
          <div className="grid-h three"></div>
          <div className="grid-v one"></div>
          <div className="grid-v two"></div>
          <div className="grid-v three"></div>
          <div className="image-wrapper">
            <img
              alt="rate-privacy"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/rate-privacy.png"
            />
          </div>
          <h2 className="title-lbl">
            <FormattedMessage id="rateCardRequestView_disabledRateCardHeader" />
          </h2>
          <p className="description-lbl mt-3">
            <FormattedMessage
              id="rateCardRequestView_disabledRateCardDesc"
              values={{
                cta: (
                  <EmpLink
                    onSubmit={() => {
                      rateCardPrivacySettingRef.current?.show();
                    }}
                    text={<FormattedMessage id="cta_here" />}
                  ></EmpLink>
                ),
              }}
            />
          </p>
        </div>
      )}

      <section className="rate-cards-request-section">
        {!isLoading && rateCardsRequests && rateCardsRequests.length === 0 && (
          <div className="campaign-empty-state-overlay">
            <div className="info-wrapper mt-3">
              <img
                className="empty-img"
                alt="Empty Campaign Icon"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
              />
              <h2 className="header">
                <FormattedMessage id="rateCardRequestView_emptyHeader" />
              </h2>
              <p className="description">
                <FormattedMessage id="rateCardRequestView_emptyDesc" />
              </p>
            </div>
          </div>
        )}
        {!isLoading &&
          rateCardsRequests &&
          rateCardsRequests.length > 0 &&
          rateCardsRequests.map((elem) => {
            return (
              <RateCardRequestCard
                key={elem.id}
                onAccept={() => {
                  rateCardAcceptConfirmationModalRef.current?.show(
                    elem.brandOrgId,
                    elem.brand.companyName,
                    elem.creatorId,
                    elem.creator.fullName,
                    elem.id
                  );
                }}
                onReject={() => {
                  rateCardRejectConfirmationModalRef.current?.show(
                    elem.brandOrgId,
                    elem.brand.companyName,
                    elem.creatorId,
                    elem.creator.fullName,
                    elem.id
                  );
                }}
                data={elem}
              />
            );
          })}

        {!isLoading &&
          rateCardsRequests &&
          rateCardsRequests.length === 0 &&
          [1, 2, 3].map((elem) => {
            return <RateCardRequestCard key={elem} mode="skeleton" />;
          })}
      </section>
    </div>
  );
};
