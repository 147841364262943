import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import OrganisationApi from "../../../api/user-msvc/organisation.api";
import UserApi from "../../../api/user-msvc/user.api";
import EmpAvatar from "../../../components/shared/emp-avatar/emp-avatar";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import {
  PAGE_PERMS,
  PermsUtils,
} from "../../../constants/permissions.constants";
import { AppContext } from "../../../context/app.context";
import { OrganisationUsersRespDto } from "../../../model/organisation/organisation-users-resp.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import TranslationUtil from "../../../utilities/translation.util";
import AddMemberModal, { AddMemberModalRef } from "./add-member-modal";
import "./manage-team-view.scss";

export const ManageTeamView = () => {
  const [user, setUser] = useState<UserDto>();
  const addMemberModalRef = useRef<AddMemberModalRef>();
  const [organisationUsers, setOrganisationUsers] = useState<
    OrganisationUsersRespDto[]
  >([]);
  const intl = useIntl();
  const { user: userContext } = useContext(AppContext);

  const fetchUsersInOrganisation = useCallback(async () => {
    try {
      const response = await OrganisationApi.fetchAllUserInOrganisation();
      setOrganisationUsers(response.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching users in the organisation."
      );
    }
  }, []);

  /**
   * Retrieves details for the current user.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = userContext ? undefined : await UserApi.fetchUser();
      setUser(response?.data ?? userContext!);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      await fetchUserDetails();
      await fetchUsersInOrganisation();
    };
    fetchDetails();
  }, [fetchUsersInOrganisation, fetchUserDetails]);

  const invitationStatus = (onboardingUser: OrganisationUsersRespDto) => {
    if (onboardingUser.type !== "invitation") return;
    if (onboardingUser.invitationStatus === "VALID")
      return " ∙ Pending Invitation";
    else if (onboardingUser.invitationStatus === "INVALID")
      return " ∙ Accepted";
    return " ∙ Unknown";
  };

  return (
    <div className="emp-manage-team-view">
      <AddMemberModal
        ref={addMemberModalRef}
        onSave={() => {
          fetchUsersInOrganisation();
        }}
      />
      <div className="view-header-wrapper">
        <div className="text-wrapper">
          <h1>
            <FormattedMessage id="settingsManageTeamView_header" />
          </h1>
          <p className="page-description">
            <FormattedMessage id="settingsManageTeamView_desc" />
          </p>
        </div>
        {user && PermsUtils.hasPerms(user, PAGE_PERMS.MANAGE_TEAM.CREATE) && (
          <EmpButton
            onSubmit={() => {
              addMemberModalRef.current?.show();
            }}
            isFullWidth={false}
            text={
              <FormattedMessage id="settingsManageTeamView_addTeamMemberBtn" />
            }
          />
        )}
      </div>
      {/* Organisational Admin */}
      <div className="accounts-section">
        <span className="accounts-label">
          <FormattedMessage id="settingsManageTeamView_youLabel" />
        </span>

        {user && (
          <div className="account-card">
            <div className="profile-image">
              <EmpAvatar size={40} user={user} />
              <div className="profile-details">
                <div className="h-stack" style={{ alignItems: "center" }}>
                  <span className="name-lbl">{user.fullName}</span>
                  <div className="ml-2">
                    <EmpPill
                      backgroundColor={Color.NEUTRAL[150]}
                      color={Color.NEUTRAL[800]}
                      text={TranslationUtil.translateMemberRole(
                        intl,
                        PermsUtils.cognitoRoleToReadableRole(user.role)
                      )}
                    />
                  </div>
                </div>
                <span className="email-lbl">{user.email}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="accounts-section">
        <span className="accounts-label">
          <FormattedMessage id="settingsManageTeamView_teamMembersLabel" /> |{" "}
          {organisationUsers.length - 1}
        </span>
        {/* Empty state for member accounts */}

        {organisationUsers.length <= 1 && (
          <div className="account-card empty-state">
            <img
              className="empty-img"
              alt="No Team Members"
              srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
            />
            <div className="empty-cta">
              <span className="title">
                <FormattedMessage id="settingsManageTeamView_emptyTeamMembersHeader" />
              </span>
              <span className="description">
                <FormattedMessage id="settingsManageTeamView_emptyTeamMembersDesc" />
              </span>
            </div>
          </div>
        )}

        {organisationUsers.length > 1 &&
          organisationUsers.map((elem, index) => {
            if (user?.id === elem.id) return <div key={elem.id}></div>;
            return (
              <div key={elem.id} className="account-card">
                <div className="profile-image">
                  <EmpAvatar size={40} user={elem} />
                  <div className="profile-details">
                    <div className="h-stack" style={{ alignItems: "center" }}>
                      <span className="name-lbl">
                        {elem.fullName}{" "}
                        {elem.type === "invitation" && (
                          <span className="invitation-status">
                            {invitationStatus(elem)}
                          </span>
                        )}
                      </span>
                      {elem.type === "user" && (
                        <div className="ml-2">
                          <EmpPill
                            backgroundColor={Color.NEUTRAL[150]}
                            color={Color.NEUTRAL[800]}
                            text={TranslationUtil.translateMemberRole(
                              intl,
                              elem.role
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <span className="email-lbl">{elem.email}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
