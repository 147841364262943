import "./task-deliverable-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { TaskDto } from "../../model/campaign/task.dto";
import EmpButton from "../shared/emp-button/emp-button";
import { SOCIAL_MEDIA_TYPE } from "../../constants/app.constants";
import TikTokIcon from "../icon/tiktok-icon";
import { Color } from "../../utilities/colors";
import FacebookIcon from "../icon/facebook-icon";
import InstagramIcon from "../icon/instagram-icon";
import XCloseIcon from "../icon/x-close-icon";
import EmpEditorView from "../shared/emp-editor/emp-editor-viewer";

export interface TaskDeliverableModalRef {
  show: (task: TaskDto) => void;
  dismiss: () => void;
}

interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const TaskDeliverableModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [task, setTask] = useState<TaskDto>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (task: TaskDto) => {
    setTask(task);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setTask(undefined);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        {task && (
          <div className="emp-task-deliverable-modal">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            <div className="tdm-content-section">
              <div className="task-name-wrapper">
                {task.platform === SOCIAL_MEDIA_TYPE.TIKTOK && (
                  <TikTokIcon backgroundColor={Color.NEUTRAL[700]} />
                )}
                {task.platform === SOCIAL_MEDIA_TYPE.FACEBOOK && (
                  <FacebookIcon backgroundColor={Color.NEUTRAL[700]} />
                )}
                {task.platform === SOCIAL_MEDIA_TYPE.INSTAGRAM && (
                  <InstagramIcon backgroundColor={Color.NEUTRAL[700]} />
                )}
                <h1 className="task-name">{task.name}</h1>
              </div>

              {task.deliverables.map((elem, index) => {
                return (
                  <motion.div
                    key={elem.id}
                    variants={fadeInVariants}
                    className="deliverable-wrapper"
                    initial="hidden"
                    animate={"visible"}
                    transition={{
                      duration: animationDuration,
                      delay: (2 + index) * animationDelay,
                    }}
                  >
                    <span className="deliverable-indicator">
                      DELIVERABLE {index + 1}
                    </span>
                    <h3 className="deliverable-name-lbl">{elem.name}</h3>
                    <div className="mt-1">
                      <EmpEditorView value={elem.description} />
                    </div>
                  </motion.div>
                );
              })}

              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                style={{ display: "flex", flexDirection: "row-reverse" }}
                transition={{
                  duration: animationDuration,
                  delay: 4 * animationDelay,
                }}
              >
                <EmpButton
                  isFullWidth={false}
                  onSubmit={() => {
                    dismiss();
                  }}
                  className="mt-4"
                  text={"Close"}
                />
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default TaskDeliverableModal;
