import axios from "axios";
import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { Paginatable } from "../../model/common/paginatable";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { CreatorListingItemDto } from "../../model/user-management/creator-listing-item-resp.dto";
import { CreatorListingQueryDto } from "../../model/user-management/creator-listing-query-req.dto";
import { UpdateTalentPicReqDto } from "../../model/user-management/update-talent-pic-req.dto";
import { UserImgReqDto } from "../../model/user-management/user-img-req.dto";
import { UserProfilePicsRespDto } from "../../model/user-management/user-profile-pics-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { UserRespDto } from "../../model/user/user-resp.dto";
import { EmpInterceptor } from "../emp-interceptor";
import { UpdateUserOnboardingStateDto } from "../../model/user-management/update-user-onboarding-state.dto";

// Configure Error Interceptor
const UserApi = {
  fetchUser: async (): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.root
    );
    return response.data;
  },

  updateUserAttribute: async (
    request: UserRespDto
  ): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.post<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.root,
      request
    );
    return response.data;
  },

  updateUserImage: async (
    request: UserImgReqDto
  ): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.put<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.image,
      request
    );
    return response.data;
  },

  getUserImage: async (): Promise<ApiResponse<UserProfilePicsRespDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<ApiResponse<UserProfilePicsRespDto>>(
      ENDPOINTS.userMicroservice.user.creatorImage
    );
    return response.data;
  },
  updateTalentImage: async (
    request: UpdateTalentPicReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.user.creatorImage,
      request
    );
    return response.data;
  },
  fetchCreatorAgenciesById: async (
    creatorId: string
  ): Promise<ApiResponse<CreatorAgencyInvitationDto[]>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<
      ApiResponse<CreatorAgencyInvitationDto[]>
    >(ENDPOINTS.userMicroservice.user.creatorAgency(creatorId));
    return response.data;
  },
  fetchCreatorById: async (
    creatorId: string
  ): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.creator(creatorId)
    );
    return response.data;
  },
  fetchCreatorByEmail: async (email: string): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.creatorByEmail(email)
    );
    return response.data;
  },
  creatorListing: async (
    request: CreatorListingQueryDto
  ): Promise<ApiResponse<Paginatable<CreatorListingItemDto[]>>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.post<
      ApiResponse<Paginatable<CreatorListingItemDto[]>>
    >(ENDPOINTS.userMicroservice.user.creatorListing, request);
    return response.data;
  },
  fetchOrganisationRoot: async (): Promise<ApiResponse<UserDto>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.get<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.user.orgRoot
    );
    return response.data;
  },
  checkIfUserExist: async (email: string): Promise<ApiResponse<ApiStatus>> => {
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.user.checkUserExist(email)
    );
    return response.data;
  },

  /**
   * Leave the agency as a creator.
   *
   * @param {string} organisationId - The ID of the organisation/agency to leave.
   * @returns {Promise<ApiResponse<ApiStatus>>} - A promise resolving to the API response with the status of the operation.
   */
  creatorLeaveAgency: async (
    organisationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axiosAuth = await EmpInterceptor.axiosClient();
    const response = await axiosAuth.delete<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.user.leaveAgency(organisationId)
    );
    return response.data;
  },
};
export default UserApi;
