import { EVIDENCE_TYPE_DISPLAY } from "../constants/app.constants";

export class CampaignAnalyticsUtils {
  static mapEvidenceName = (evidenceType: string) => {
    switch (evidenceType) {
      case "sm-link":
        return EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK;
      case "sm-image":
        return EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_IMAGE;
      case "attachment":
        return EVIDENCE_TYPE_DISPLAY.ATTACHMENT;
      default:
        return "Not Accounted";
    }
  };
}
