import "./subscription-alert-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PlanSelectionView from "../views/plan-selection-view";
import { motion, useAnimation } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { useLocation, useNavigate } from "react-router-dom";
import EmpAnimatedLoader from "../shared/emp-animated-loader/emp-animated-loader";
import SelectPaymentMethodView from "../views/select-payment-method-view";
import { CardDto } from "../../model/payment/payment/card.dto";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { ENV_CONSTANTS, getEnv } from "../../constants/envConstants";
import PaymentFailModal, { PaymentFailModalRef } from "./payment-fail-modal";

export interface AgencyToBrandTransitionModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

type StepType = "plan-selection" | "payment-method";
interface SelectedPlan {
  id: string;
  type: "trial" | "price" | "proceed";
}
const AgencyToBrandTransitionModal = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const controls = useAnimation();
  const [visible, setVisible] = useState<boolean>(false);
  useState<boolean>(false);
  const appBaseUrl = useRef<string>(ENV_CONSTANTS[getEnv()].appUrl);
  const hasDismissedRef = useRef(true);

  const paymentFailModalRef = useRef<PaymentFailModalRef>();
  const [step, setStep] = useState<StepType>("plan-selection");
  const cardsRef = useRef<CardDto[]>();
  const selectedPlanRef = useRef<SelectedPlan>();

  const dismiss = useCallback(async () => {
    controls.start("hidden");
    setStep("plan-selection");
    await empDelay(300);
    setVisible(false);
  }, [controls]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (hasDismissed?: boolean) => {
    hasDismissedRef.current = hasDismissed === undefined ?? true;
    setVisible(true);
  };

  useEffect(() => {
    if (visible) {
      controls.start("visible");
    }
  }, [visible, controls]);

  return (
    <>
      <PaymentFailModal ref={paymentFailModalRef} />
      {visible && (
        <motion.div
          initial={"hidden"}
          variants={fadeInVariant}
          animate={controls}
          transition={{ duration: 0.2 }}
          className="emp-subscription-alert-modal"
        >
          <div className="content-section">
            <div className="left-section">
              <img
                className="logo"
                alt="Emplifive"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/emplifive-logo-white.svg"
              />
              <div className="pattern-wrapper">
                <div className="image-wrapper">
                  <img
                    alt="Megaphone"
                    className="megaphone-img"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/megaphone.png"
                  />
                </div>
                <img
                  className="pattern"
                  alt="pattern"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/patterns//emplifive-pattern-1.svg"
                />
              </div>
              <h1 className="cta-header">
                Unlock <span className="highlighted">Brand</span> Access
                Features
              </h1>
              <p className="cta-description">
                You will gain access to campaigns, and you will be able to hire
                other creators for the job.
              </p>
            </div>
            <div className="right-section">
              {hasDismissedRef && (
                <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
                  <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
                </div>
              )}
              {step === "plan-selection" && (
                <PlanSelectionView
                  callbackUrl={`${appBaseUrl.current}/agency/home?emp-callback=subscription`}
                  hasFreeOption={true}
                  toPaymentMethod={(
                    cards: CardDto[],
                    selectedPlan: SelectedPlan
                  ) => {
                    cardsRef.current = cards;
                    selectedPlanRef.current = selectedPlan;
                    setStep("payment-method");
                  }}
                />
              )}
              {step === "payment-method" &&
                cardsRef.current &&
                selectedPlanRef.current && (
                  <SelectPaymentMethodView
                    callbackUrl={`${appBaseUrl.current}/agency/home?emp-callback=subscription`}
                    cards={cardsRef.current}
                    selectedPlan={selectedPlanRef.current}
                    onBack={() => {
                      setStep("plan-selection");
                    }}
                    onDismiss={() => {
                      dismiss();
                    }}
                    onSuccess={() => {
                      navigate("/brand/home");
                    }}
                  />
                )}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
});

export default AgencyToBrandTransitionModal;
