import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { ApiStatusWithPayload } from "../../model/api/api-status-with-payload";
import { BlockchainNetworkDto } from "../../model/user/wallet-address/blockchain-networks.dto";
import { CreateWalletAddressDto } from "../../model/user/wallet-address/create-wallet-address.dto";
import { GetWalletAddressDto } from "../../model/user/wallet-address/get-wallet-address.dto";
import { EmpInterceptor } from "../emp-interceptor";

const WalletAddressApi = {
  upsertWalletAddress: async (
    request: CreateWalletAddressDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.walletAddress.base,
      request
    );
    return response.data;
  },

  fetchAvailableNetworks: async (): Promise<
    ApiResponse<BlockchainNetworkDto[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<BlockchainNetworkDto[]>>(
      ENDPOINTS.userMicroservice.walletAddress.fetchAvailableNetworks
    );
    return response.data;
  },

  fetchWalletAddress: async (): Promise<
    ApiResponse<ApiStatusWithPayload<GetWalletAddressDto>>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<ApiStatusWithPayload<GetWalletAddressDto>>
    >(ENDPOINTS.userMicroservice.walletAddress.base);
    return response.data;
  },
};
export default WalletAddressApi;
