import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import SearchIcon from "../../components/icon/search-icon";
import EmpBreadcrumb, {
  EmpBreadcrumbSpecs,
} from "../../components/shared/emp-breadcrumb/emp-breadcrumb";
import EmpMultiSelectV2 from "../../components/shared/emp-multi-select-v2/emp-multi-select-v2";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import {
  getCountryOptionWithHtml,
  searchAgeRangeOptions,
  searchGenderOptions,
} from "../../constants/selectConstants";
import EmpException from "../../exception/empException";
import { TaskDto } from "../../model/campaign/task.dto";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import "./brand-task-recruitment-page.scss";
import { SelectOption } from "../../model/common/selectOption";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import TikTokIcon from "../../components/icon/tiktok-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import FacebookIcon from "../../components/icon/facebook-icon";
import { Color } from "../../utilities/colors";
import { EmpSmRecruitmentCard } from "../../components/shared/emp-sm-recruitment-card/emp-sm-recruitment-card";
import { CreatorRecruitmentCardDto } from "../../model/user/creator-recruitment-card.dto";
import _debounce from "lodash/debounce";
import { QueryCreatorRecruitmentCardDto } from "../../model/user/query-creator-recruitment-card.dto";
import EmpPagination, {
  EmpPaginationProps,
} from "../../components/shared/EmpPagination/EmpPagination";
import { TASK_TYPE } from "../../constants/app.constants";
import {
  EmpToggleViewSwitch,
  EmpToggleViewType,
} from "../../components/shared/emp-toggle-view-switch/emp-toggle-view-switch";
import { SmRecruitmentTable } from "../../components/table/sm-recruitment-table";
import { SelectOptionWithHtml } from "../../model/common/select-option-with-html";
import useSubscriptionGuard from "../../hooks/useSubscriptionGuard";
import { useCountGridColumns } from "../../hooks/useCountGridColumns";
import XIcon from "../../components/icon/x-icon";

const breadcrumbsData = [{ link: "/brand/campaigns", text: "Campaign" }];

type TaskType = "Facebook" | "Instagram" | "TikTok" | "General";
export const BrandTaskRecruitmentPage = () => {
  const navigate = useNavigate();

  const gridElemRef = useRef<HTMLDivElement>(null);
  const gridColumnCount = useCountGridColumns(gridElemRef);

  console.log("Shes happy with that", gridColumnCount);

  const { hasSubscription } = useSubscriptionGuard();
  const taskTypeRef = useRef<TaskType>();
  const { taskId, campaignId } = useParams();
  const [task, setTask] = useState<TaskDto>();
  const [cards, setCards] = useState<CreatorRecruitmentCardDto[]>([]);
  const [breadcrumbs, setBreadcrumbs] =
    useState<EmpBreadcrumbSpecs[]>(breadcrumbsData);
  const [loading, setLoading] = useState<boolean>(true);
  const [numRecords, setNumRecords] = useState<number>(0);

  const [viewMode, setViewMode] = useState<EmpToggleViewType>("list");
  const [breadcrumbLoading, setBreadcrumbLoading] = useState<boolean>(true);
  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const query = useRef<QueryCreatorRecruitmentCardDto>({});

  // Filter options
  const [interestOptions, setInterestOptions] = useState<SelectOption[]>([]);
  const [serviceOptions, setServiceOptions] = useState<SelectOption[]>([]);
  const countryWithHtmlOptions = useRef<SelectOptionWithHtml[]>(
    getCountryOptionWithHtml()
  );

  const [form, setForm] = useState<IFormGroup>({
    search: new FormControl("text", []),
    interests: new FormControl("checkbox-group", []),
    services: new FormControl("checkbox-group", []),
    country: new FormControl("checkbox-group", []),
    sortBy: new FormControl("text", [], "sort-by-join-date"),
    gender: new FormControl("text", [], "all"),
    age: new FormControl("text", [], "all"),
  });

  const fetchCreatorExtendedInfo = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.fetchCreatorInfoOptions();
      const creatorInfo = resp.data;
      setInterestOptions(
        creatorInfo.interests.map((elem) => {
          return {
            value: elem.id.toString(),
            label: elem.label,
          };
        })
      );
      setServiceOptions(
        creatorInfo.services.map((elem) => {
          return {
            value: elem.id.toString(),
            label: elem.label,
          };
        })
      );
    } catch (e) {
      console.log(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info"
      );
    }
  }, []);

  const fetchCreatorRecruitmentCards = async (
    taskType: string,
    queryable: QueryCreatorRecruitmentCardDto
  ) => {
    try {
      setLoading(true);
      const request: QueryCreatorRecruitmentCardDto = {
        countries: form.country.getValue(),
        creatorInterest: form.interests.getValue(),
        creatorServices: form.services.getValue(),
        keyword: form.search.getValue(),
        gender: form.gender.getValue(),
        ageRange: form.age.getValue(),
        pageNo: queryable?.pageNo ?? 1,
        pageSize: queryable?.pageSize ?? 10,
      };
      query.current = request;

      const resp = await CreatorInfoApi.fetchCreatorRecruitmentListing(
        taskType,
        request
      );
      const payload = resp.data.payload!;
      setPaginable({
        currentPage: payload.currentPage,
        totalRecord: payload.totalRecord,
        pageSize: payload.pageSize,
      });
      setNumRecords(payload.totalRecord);
      setCards(payload.records);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to creator recruitment cards"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchTaskById = useCallback(async () => {
    try {
      if (!taskId || !campaignId) throw new EmpException("No task id");
      const resp = await CampaignApi.fetchTaskById(campaignId, taskId);
      const task = resp.data;
      const newBreadcrumbs = [...breadcrumbsData];
      newBreadcrumbs.push({
        link: `/brand/campaign-details/${campaignId}#Task`,
        text: task.campaign.name,
      });
      newBreadcrumbs.push({
        link: `/brand/campaign-details/${campaignId}/task/${taskId}#Recruitment`,
        text: task.name,
      });
      newBreadcrumbs.push({
        link: `/brand/task-recruitment/${campaignId}/task/${taskId}`,
        text: "Recruitment",
      });

      taskTypeRef.current = resp.data.platform as TaskType;
      setBreadcrumbs(newBreadcrumbs);
      setTask(task);
      // form.country.forceUpdateValue(task.countries);
      setForm({ ...form });
      fetchCreatorRecruitmentCards(task.platform, {});
      await empDelay(500);
      setBreadcrumbLoading(false);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch task");
    }
  }, [taskId, campaignId]);

  const taskTypeString = useMemo(() => {
    if (!task) return;
    if (!taskTypeRef.current) return "Social Media";
    if (taskTypeRef.current === "General") return "Social Media";
    return taskTypeRef.current;
  }, [task]);

  useEffect(() => {
    fetchTaskById();
    fetchCreatorExtendedInfo();
  }, [fetchTaskById, fetchCreatorExtendedInfo]);

  const GUTTER_WIDTH = 10;
  const MIN_CARD_WIDTH = 300;

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const [cardWidth, setCardWidth] = useState<number>(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);

    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );
    setCardWidth(cardWidth);
    setShimmerCards(Array.from(Array(maxCards * 1).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  useEffect(() => {
    // computeDetailsCardWidth();
  }, [viewMode]);

  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="bg-tint"></div>
      <div className="emp-page-content-no-padding emp-brand-task-recruitment-page">
        <div className="mt-3">
          <EmpBreadcrumb
            items={breadcrumbs}
            loading={breadcrumbLoading}
            numLoadingItem={3}
          />
        </div>
        <div className="header-wrapper header-space-between ">
          {taskTypeRef.current === TASK_TYPE.TIKTOK && (
            <TikTokIcon backgroundColor={Color.NEUTRAL[100]} />
          )}
          {taskTypeRef.current === TASK_TYPE.FACEBOOK && (
            <FacebookIcon backgroundColor={Color.NEUTRAL[100]} />
          )}
          {taskTypeRef.current === TASK_TYPE.INSTAGRAM && (
            <InstagramIcon backgroundColor={Color.NEUTRAL[100]} />
          )}
          {taskTypeRef.current === TASK_TYPE.X && (
            <XIcon backgroundColor={Color.NEUTRAL[100]} />
          )}
          {task && <h1 className="page-header">{task.name} Recruitment</h1>}
        </div>
        {taskTypeRef.current && (
          <section className="filter-section">
            <div className="search-control-section">
              <div className="search-control">
                <EmpTextInput
                  disabled={!hasSubscription}
                  labelText={`Search ${taskTypeString} Accounts`}
                  id={"search"}
                  onKeypress={(e) => {
                    if (e === "Enter") {
                      fetchCreatorRecruitmentCards(
                        taskTypeRef.current!,
                        query.current
                      );
                    }
                  }}
                  onChange={() => {}}
                  formControl={form.search}
                  placeholder={"Search Creator Names"}
                  leftIconComponent={SearchIcon}
                />
              </div>
            </div>
            <div className="filter-control-section mt-2">
              <div className="control">
                <EmpMultiSelectV2
                  disabled={!hasSubscription}
                  label={"Countries"}
                  formControl={form.country}
                  menuItems={countryWithHtmlOptions.current}
                  hasSearch
                  onChange={() =>
                    fetchCreatorRecruitmentCards(
                      taskTypeRef.current!,
                      query.current
                    )
                  }
                  placeholder={"Filter Countries"}
                />
              </div>
              <div className="control">
                <EmpMultiSelectV2
                  disabled={!hasSubscription}
                  label={"Creator Interest"}
                  formControl={form.interests}
                  onChange={() =>
                    fetchCreatorRecruitmentCards(
                      taskTypeRef.current!,
                      query.current
                    )
                  }
                  menuItems={interestOptions}
                  placeholder={"Filter Interests"}
                />
              </div>
              <div className="control">
                <EmpMultiSelectV2
                  disabled={!hasSubscription}
                  label={"Creator Services"}
                  formControl={form.services}
                  onChange={() =>
                    fetchCreatorRecruitmentCards(
                      taskTypeRef.current!,
                      query.current
                    )
                  }
                  menuItems={serviceOptions}
                  placeholder={"Filter Services"}
                />
              </div>

              <div className="control">
                <EmpSelect
                  disabled={!hasSubscription}
                  labelText="Gender"
                  hidePlaceholder
                  formControl={form.gender}
                  onChange={() =>
                    fetchCreatorRecruitmentCards(
                      taskTypeRef.current!,
                      query.current
                    )
                  }
                  selectOptions={searchGenderOptions}
                  placeholder={"Gender"}
                  id={"gender"}
                />
              </div>
              <div className="control">
                <EmpSelect
                  disabled={!hasSubscription}
                  labelText="Age"
                  hidePlaceholder
                  formControl={form.age}
                  onChange={() =>
                    fetchCreatorRecruitmentCards(
                      taskTypeRef.current!,
                      query.current
                    )
                  }
                  selectOptions={searchAgeRangeOptions}
                  placeholder={"age"}
                  id={"age"}
                />
              </div>
            </div>
          </section>
        )}

        <div ref={resizeDivRef} className="resize-wrapper"></div>
        <div className="records-wrapper">
          <div className="records-title-wrapper">
            {!loading && (
              <span className="records-title mt-4">
                Showing {numRecords} Creator{numRecords > 1 ? "s" : ""}{" "}
              </span>
            )}
            {loading && (
              <div className="records-title-loader emp-shimmer mt-4"></div>
            )}
          </div>

          {hasSubscription && (
            <EmpPagination
              onChange={(queryable) => {
                fetchCreatorRecruitmentCards(taskTypeRef.current!, queryable);
              }}
              queryable={query.current}
              pagination={paginable}
            />
          )}
          <EmpToggleViewSwitch
            view={viewMode}
            onChange={(view) => {
              setViewMode(view);
            }}
          />
        </div>

        <section
          ref={gridElemRef}
          className={`recruitment-card-section ${
            viewMode === "grid" ? "pv-5" : ""
          }`}
        >
          {viewMode === "grid" && (
            <>
              {!loading &&
                numRecords > 0 &&
                cards.map((elem) => {
                  return (
                    <EmpSmRecruitmentCard
                      onClick={(context) => {
                        if (context === "card")
                          navigate(
                            `/brand/creator-invitation/${
                              elem.id
                            }/campaign/${campaignId}/task/${taskId!}#${
                              elem.platform
                            }`
                          );
                      }}
                      key={elem.id}
                      card={elem}
                    />
                  );
                })}
              {!loading && numRecords === 0 && (
                <div className="blank-overlay">
                  <div className="info-wrapper">
                    <img
                      className="empty-img"
                      alt="No talents found"
                      srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                    />
                    <h2 className="header">No Creators found</h2>
                    <p className="description">
                      Please try another search query.
                    </p>
                  </div>
                </div>
              )}
              {!loading &&
                numRecords === 0 &&
                shimmerCard.map((elem) => {
                  return <EmpSmRecruitmentCard key={elem} mode={"skeleton"} />;
                })}
              {!loading &&
                numRecords > 0 &&
                !hasSubscription &&
                Array.from(
                  { length: gridColumnCount + (gridColumnCount - 3) },
                  (_, i) => i + 1
                ).map((elem) => {
                  return <EmpSmRecruitmentCard key={elem} mode={"skeleton"} />;
                })}
              {loading &&
                shimmerCard.map((elem) => {
                  return <EmpSmRecruitmentCard key={elem} mode={"loading"} />;
                })}
            </>
          )}
        </section>
        <section className="recruitment-list-section">
          {viewMode === "list" && taskTypeRef.current && paginable && (
            <section className="pv-5">
              <SmRecruitmentTable
                onClick={(context, record) => {
                  if (context === "card")
                    navigate(
                      `/brand/creator-invitation/${
                        record.id
                      }/campaign/${campaignId}/task/${taskId!}#${
                        record.platform
                      }`
                    );
                }}
                rawRecords={cards}
                hasRestriction={!hasSubscription}
                taskType={taskTypeRef.current}
              />
            </section>
          )}
        </section>
      </div>
    </div>
  );
};
