import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableSeperator,
} from "../../components/shared/EmpTable/EmpTable";
import { OrganisationTalentInvitationStatus } from "../../model/user/organisation-talent-invitation-status.dto";
import { DateUtil } from "../../utilities/date";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";

interface Props {}
const RecentCreatorsCard = (props: Props) => {
  const [recentCreators, setRecentCreators] =
    useState<(OrganisationTalentInvitationStatus | EmpTableSeperator)[]>();
  const navigate = useNavigate();
  const intl = useIntl();

  const talentInvitationContentSpec: EmpTableContentSpec<OrganisationTalentInvitationStatus>[] =
    useMemo(() => {
      return [
        {
          title: intl.formatMessage({
            id: "agencyHomePage_recentCreatorActivityCreatorNameHeader",
          }),
          dataIndex: "creatorName",
          render: (record) => {
            if (record.hasAccount)
              return (
                <div className="creator-record-row">
                  {record.creatorImageType === "url" && (
                    <img
                      className="profile"
                      alt={record.creatorName}
                      src={record.creatorImageResource}
                    />
                  )}
                  {record.creatorImageType === "avatar" && (
                    <div
                      className="profile avatar"
                      style={{ background: record.creatorImageResource }}
                    >
                      <span className="initials">{record.creatorInitials}</span>
                    </div>
                  )}
                  {record.hasCompletedOnboarding && (
                    <EmpLink
                      onSubmit={() => {
                        navigate(`/agency/creator-details/${record.id}`);
                      }}
                      text={record.creatorName}
                    />
                  )}
                  {!record.hasCompletedOnboarding && (
                    <span> {record.creatorName}</span>
                  )}
                </div>
              );
            else
              return (
                <div className="creator-record-row">
                  <img
                    className="profile"
                    alt={record.creatorName}
                    src={record.creatorImageResource}
                  />
                  <span> {record.creatorName}</span>
                </div>
              );
          },
        },
        {
          title: intl.formatMessage({
            id: "agencyHomePage_recentCreatorActivityDateHeader",
          }),
          dataIndex: "createdDate",
          render: (record) => {
            return (
              <span>{DateUtil.toReadableDate(record.invitationSentDate)}</span>
            );
          },
        },
      ];
    }, [intl, navigate]);

  const fetchAgencyCreators = useCallback(async (): Promise<void> => {
    try {
      const resp = await OrganisationApi.fetchTalentInvitationStatus();
      const recentCreators = resp.data;
      // Split up by status
      const invitedCreators = recentCreators.filter(
        (elem) => elem.status === "Pending"
      );
      const joinedCreators = recentCreators.filter(
        (elem) => elem.status === "Joined"
      );

      const tableRecords: (
        | OrganisationTalentInvitationStatus
        | EmpTableSeperator
      )[] = [];
      if (invitedCreators.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: (
            <FormattedMessage
              id="agencyHomePage_recentCreatorActivityInvitationSection"
              values={{
                value: invitedCreators.length,
                count: invitedCreators.length === 1 ? 1 : "other",
              }}
            />
          ),
        });
        tableRecords.push(...invitedCreators);
      }
      if (joinedCreators.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: (
            <FormattedMessage
              id="agencyHomePage_recentCreatorActivityAcceptanceSection"
              values={{
                value: joinedCreators.length,
                count: joinedCreators.length === 1 ? 1 : "other",
              }}
            />
          ),
        });
        tableRecords.push(...joinedCreators);
      }

      setRecentCreators(tableRecords);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to fetch existing creators"
      );
      throw error;
    }
  }, []);

  useEffect(() => {
    fetchAgencyCreators();
  }, [fetchAgencyCreators]);

  return (
    <div className="emp-card no-padding agency-map-chart mt-3 recent-creators-card">
      <div className="header-section">
        <h2 className="card-header-lbl">
          <FormattedMessage id="agencyHomePage_recentCreatorActivityCardHeader" />
        </h2>
        <p className="emp-paragraph mt-2">
          <FormattedMessage id="agencyHomePage_recentCreatorActivityCardDesc" />
        </p>
      </div>
      {recentCreators && (
        <EmpTable
          paddingSize="sm"
          contentColumns={talentInvitationContentSpec}
          data={recentCreators}
          rowKey={"id"}
        />
      )}
    </div>
  );
};

export default RecentCreatorsCard;
