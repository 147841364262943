import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ReferralCodeDetailsRespDto } from "../../model/support-referral/referral-code/referal-code-details-resp.dto";
import { EmpInterceptor } from "../emp-interceptor";

const ReferralCodeApi = {
  fetchReferralCode: async (
    subjectId: string
  ): Promise<ApiResponse<ReferralCodeDetailsRespDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ReferralCodeDetailsRespDto>>(
      ENDPOINTS.referralAndSupportMsvc.referralCode.getReferralCode(subjectId)
    );
    return response.data;
  },
};
export default ReferralCodeApi;
