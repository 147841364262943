import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import FormGroup from "rsuite/esm/FormGroup";
import SettingsApi from "../../../api/user-msvc/settings.api";
import RatePrivacySettingModal from "../../../components/modals/rate-privacy-setting-modal";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpSwitch from "../../../components/shared/emp-switch/emp-switch";
import { AGENCY_PRIVACY_SETTING_NAME } from "../../../constants/app.constants";
import { AppContext } from "../../../context/app.context";
import { UserDto } from "../../../model/user-management/user.dto";
import { AgencyPrivacySettingsDto } from "../../../model/user/agency-privacy-settings.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import ToastUtils from "../../../utilities/toast-utils";
import UserUtils from "../../../utilities/user-utils";
import "./agency-privacy-settings-view.scss";

export const AgencyPrivacySettingsView = () => {
  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();
  const [agencyPrivacySettings, setAgencyPrivacySettings] = useState<
    AgencyPrivacySettingsDto[]
  >([]);
  const [formIsPristine, setFormIsPristine] = useState(true);

  const [form, setForm] = useState<IFormGroup>({
    // rateCardPrivacy: new FormControl("boolean", [], true),
  });

  const fetchAgencyPrivacySettings = useCallback(async () => {
    try {
      const resp = await SettingsApi.fetchAgencyPrivacySettings();
      const agencyPrivacySettingsResp = resp.data;
      setAgencyPrivacySettings(agencyPrivacySettingsResp);
      setForm(() => {
        const newForm: IFormGroup = {};
        for (const agencyPrivacySetting of agencyPrivacySettingsResp) {
          if (
            agencyPrivacySetting.settingName ===
            AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY
          ) {
            newForm[AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY] = new FormControl(
              "boolean",
              [],
              agencyPrivacySetting.value === "Y"
            );
          }
        }
        setFormIsPristine(FormGroupUtil.isPristine(newForm));
        return newForm;
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch agency privacy settings"
      );
    }
  }, []);

  const saveSettings = async () => {
    const agencyPrivacySettingsMap = new Map<
      string,
      AgencyPrivacySettingsDto
    >();
    agencyPrivacySettings.forEach((elem) =>
      agencyPrivacySettingsMap.set(elem.settingName, elem)
    );
    try {
      if (AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY in form) {
        if (
          agencyPrivacySettingsMap.has(AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY)
        ) {
          const elem = agencyPrivacySettingsMap.get(
            AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY
          );
          const toggleValue = form[
            AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY
          ].getValue() as boolean;
          elem!.value = toggleValue ? "Y" : "N";
        }
      }
      const resp = await SettingsApi.updateAgencyPrivacySettings({
        settings: agencyPrivacySettings,
      });
      if (resp.status === "success") {
        ToastUtils.success("Success", "Privacy Settings Saved");
      }
      fetchAgencyPrivacySettings();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to save settings");
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
    fetchAgencyPrivacySettings();
  }, [userContext, fetchAgencyPrivacySettings]);

  const formControlOnChange = () => {
    setFormIsPristine(FormGroupUtil.isPristine(form));
  };

  return (
    <div className="emp-agency-privacy-settings-view">
      <div className="view-header-wrapper">
        <div>
          <h1>
            <FormattedMessage id="settingsPrivacyView_header" />
          </h1>
          <p className="page-description">
            <FormattedMessage id="settingsPrivacyView_desc" />
          </p>
        </div>
      </div>
      {form[AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY] && (
        <div className="setting-item">
          <div className="info-wrapper">
            <span className="info-header">
              <FormattedMessage id="settingsPrivacyView_ratePrivacyLabel" />
            </span>
            <p className="info-description">
              <FormattedMessage id="settingsPrivacyView_ratePrivacyDesc" />
            </p>
          </div>

          <div className="switch-wrapper">
            <EmpSwitch
              onChange={() => {
                formControlOnChange();
              }}
              id={"rateCardPrivacy"}
              formControl={form[AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY]}
            />
          </div>
        </div>
      )}

      <div className="mt-2 save-btn-wrapper">
        <EmpButton
          disabled={formIsPristine}
          isFullWidth={false}
          onSubmit={() => {
            saveSettings();
          }}
          text={<FormattedMessage id="cta_save" />}
        />
      </div>
    </div>
  );
};
