import { useContext, useEffect, useRef, useState } from "react";
import RefreshIcon from "../../../components/icon/refresh-icon";
import {
  SellerRecruitmentKanban,
  SellerRecruitmentKanbanRef,
} from "../../../components/kanban/recruitment-kanban/seller-recruitment-kanban";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import { AppContext } from "../../../context/app.context";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import UserUtils from "../../../utilities/user-utils";
import "./seller-task-recruitment-view.scss";
import { LatestNegotiationSingleDto } from "../../../model/campaign/latest-negotiation-single.dto";

interface Props {
  task?: TaskDto;
  sellerLatestNegotiation: LatestNegotiationSingleDto;
  fetchTask: () => void;
  fetchLatestNegotiation: () => void;
}

export const SellerTaskRecruitmentView = (props: Props) => {
  const { task: taskProp, fetchLatestNegotiation } = props;
  const [task, setTask] = useState<TaskDto>();
  const { user: userContext } = useContext(AppContext);
  const [agencyUser, setAgencyUser] = useState<UserDto>();

  const [sellerLatestNegotiation, setSellerLatestNegotiation] =
    useState<LatestNegotiationSingleDto>(props.sellerLatestNegotiation);

  const sellerRecruitmentKanbanRef = useRef<SellerRecruitmentKanbanRef>();

  // Wrapper element to compute expandable size
  useEffect(() => {
    if (props.sellerLatestNegotiation) {
      setSellerLatestNegotiation(props.sellerLatestNegotiation);
      sellerRecruitmentKanbanRef.current?.setKanbanLoadingState(false);
    }
  }, [props.sellerLatestNegotiation]);

  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
    }
  }, [taskProp]);

  useEffect(() => {
    const getOrganisation = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setAgencyUser(user);
    };
    getOrganisation();
  }, [userContext]);

  return (
    <div className="emp-seller-task-recruitment-view">
      {task && (
        <>
          <section className="task-details-section">
            <div className="task-name-wrapper">
              <div className="info-wrapper">
                <h2 className="section-label">Recruiting Progress Board</h2>
                <EmpIconButton
                  onSubmit={() => {
                    fetchLatestNegotiation();
                    sellerRecruitmentKanbanRef.current?.setKanbanLoadingState(
                      true
                    );
                  }}
                  buttonStyle={"secondary"}
                  icon={<RefreshIcon backgroundColor={Color.NEUTRAL[500]} />}
                />
              </div>
            </div>
          </section>
          <section className="kanban-section">
            {agencyUser && (
              <SellerRecruitmentKanban
                ref={sellerRecruitmentKanbanRef}
                task={task}
                user={agencyUser}
                sellerLatestNegotiation={sellerLatestNegotiation}
                fetchLatestNegotiation={() => {
                  fetchLatestNegotiation();
                  sellerRecruitmentKanbanRef.current?.setKanbanLoadingState(
                    true
                  );
                }}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
};
