import _debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import "./seller-submit-draft-view.scss";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import { EmpDraftCard } from "../../../components/shared/emp-draft-card/emp-draft-card";
import StringUtils from "../../../utilities/string.util";
import DraftApi from "../../../api/campaign-msvc/draft.api";
import { DraftDto } from "../../../model/campaign/draft.dto";
import { ExtendedDraftDto } from "../../../model/campaign/extended-draft.dto";
import ViewDraftReviewModal, {
  ViewDraftReviewModalRef,
} from "../../../components/modals/view-draft-review-modal";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import useSelfAdjustingCards from "../../../hooks/useSelfAdjustingCards";

interface Props {
  task: TaskDto;
  user: UserDto;
}

export const SellerSubmitDraftView = (props: Props) => {
  const { task, user } = props;
  // const submitDraftForReviewRef = useRef<SubmitDraftForReviewModalRef>();
  const viewDraftReviewRef = useRef<ViewDraftReviewModalRef>();
  const [loading, setLoading] = useState(true);

  const [mappedExtendedDraftList, setMappedExtendedDraftList] =
    useState<Map<string, ExtendedDraftDto[]>>();

  const GUTTER_WIDTH = 10;
  const MIN_CARD_WIDTH = 240;
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const { cardWidth, shimmerCard } = useSelfAdjustingCards(
    MIN_CARD_WIDTH,
    GUTTER_WIDTH,
    resizeDivRef,
    1
  );

  const fetchSellerOngoingDeliverables = useCallback(async (task: TaskDto) => {
    try {
      setLoading(true);
      const ongoingDeliverableResp =
        await OngoingTaskApi.fetchSellerDeliverables(task.id);
      const latestDraftsResp = await DraftApi.fetchDraftAsSeller(task.id);
      const latestDraftMap = new Map<string, DraftDto>();
      latestDraftsResp.data.forEach((elem) => {
        latestDraftMap.set(`${elem.deliverableId}_${elem.creatorUserId}`, elem);
      });
      const extendedDraftList: ExtendedDraftDto[] =
        ongoingDeliverableResp.data.map((elem) => {
          const draft = latestDraftMap.get(elem.id);
          return {
            id: elem.id,
            campaignId: elem.campaignId,
            taskId: elem.taskId,
            deliverableId: elem.deliverableId,
            status: elem.status,
            representativeId: elem.representativeId,
            representativeRole: elem.representativeRole,
            creator: elem.creator,
            agencyRepresentative: elem.agencyRepresentative,
            brand: elem.brand,
            createdDate: elem.createdDate,
            updatedDate: elem.updatedDate,
            revisionsLeft: elem.revisionsLeft,
            remarks: elem.remarks,
            draft,
          };
        });

      extendedDraftList.sort((a, b) => {
        if (a.draft && b.draft) {
          return (
            new Date(b.draft.updatedDate).getTime() -
            new Date(a.draft.updatedDate).getTime()
          );
        } else if (a.draft) {
          return -1;
        } else if (b.draft) {
          return 1;
        } else {
          return 0;
        }
      });

      const map = new Map<string, ExtendedDraftDto[]>();
      extendedDraftList.forEach((elem) => {
        if (!map.has(elem.deliverableId)) {
          map.set(elem.deliverableId, [elem]);
        } else {
          map.get(elem.deliverableId)!.push(elem);
        }
      });
      setMappedExtendedDraftList(map);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch deliverables"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSellerOngoingDeliverables(task);
  }, [task, fetchSellerOngoingDeliverables]);

  return (
    <div className="emp-seller-submit-draft-view">
      {task && (
        <section className="task-details-section">
          <ViewDraftReviewModal
            ref={viewDraftReviewRef}
            onSave={() => {
              fetchSellerOngoingDeliverables(task);
            }}
          />
          <div className="task-name-wrapper">
            <div className="info-wrapper">
              <h2 className="section-label">Submit Draft for Review</h2>
              <EmpPill
                text={StringUtils.capitalizeWords(task.status)}
                backgroundColor={Color.PRIMARY[100]}
                color={Color.PRIMARY[600]}
              />
            </div>
          </div>
        </section>
      )}

      <section ref={resizeDivRef} className="deliverable-section">
        {task.deliverables.map((elem, index) => {
          return (
            <div key={elem.id} className="deliverable-wrapper">
              <span className="deliverable-indicator">
                DELIVERABLE {index + 1}
              </span>
              <h3 className="deliverable-name-lbl">{elem.name}</h3>
              <EmpEditorView value={elem.description} />
              {elem.draftRequired && (
                <section className="deliverable-cards-section mt-2">
                  {!loading &&
                    mappedExtendedDraftList &&
                    mappedExtendedDraftList.has(elem.id) &&
                    mappedExtendedDraftList.get(elem.id)!.map((draft) => {
                      return (
                        <EmpDraftCard
                          key={draft.id}
                          role="seller"
                          onCreate={() => {
                            viewDraftReviewRef.current?.show(
                              draft,
                              task,
                              "create"
                            );
                          }}
                          onResubmit={() => {
                            viewDraftReviewRef.current?.show(
                              draft,
                              task,
                              "resubmit"
                            );
                          }}
                          cardWidth={cardWidth}
                          onView={() => {
                            viewDraftReviewRef.current?.show(
                              draft,
                              task,
                              "edit"
                            );
                          }}
                          deliverableDraft={draft}
                          mode={"default"}
                        />
                      );
                    })}
                  {loading &&
                    shimmerCard.map((elem) => {
                      return (
                        <EmpDraftCard
                          key={elem}
                          cardWidth={cardWidth}
                          role={"seller"}
                          mode={"skeleton"}
                        />
                      );
                    })}
                </section>
              )}
              {!elem.draftRequired && (
                <div className="emp-card draft-not-required-card mt-2">
                  <span className="header-lbl">
                    Draft Submission not required
                  </span>
                  <p className="emp-paragraph mt-1">
                    {task.organisation.companyName} does not require you to
                    submit any draft for this Task. You may go ahead and submit
                    proof of work.
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </div>
  );
};
