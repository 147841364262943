import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import { Color } from "../../../utilities/colors";
import { ViewType } from "../sign-up-page";
import "./role-selection-view.scss";

interface Props {
  onViewChange: (viewType: ViewType) => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const ANIMATION_DELAY = 0.1;

export const RoleSelectionView = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="emp-role-selection-view">
      <div className="back-btn-wrapper">
        <EmpIconButton
          buttonStyle="secondary"
          icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[600]} />}
          onSubmit={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="title-wrapper">
        <img
          className="emplifive-logo"
          alt="emplifive logo"
          srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
        />
        <h1>
          <FormattedMessage id="selectRole_header" />
        </h1>
      </div>

      <div className="platform-role-wrapper mt-3">
        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: ANIMATION_DELAY * 0 }}
          onClick={() => {
            props.onViewChange("brand-sign-up");
            window.location.hash = "brand-sign-up";
          }}
          className="platform-role-card cbz-card-background-gradient"
        >
          <img
            alt="brand"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/brands-profile.png"
            className="platform-role-img"
          />
          <div>
            <h2 className="platform-role-lbl">
              <FormattedMessage id="selectRole_brandTitle" />
            </h2>
            <p className="platform-role-description mt-1">
              <FormattedMessage id="selectRole_brandDesc" />
            </p>
          </div>
        </motion.div>

        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          transition={{ delay: ANIMATION_DELAY * 1 }}
          onClick={(e: React.MouseEvent) => {
            window.location.hash = "agency-interest";
            props.onViewChange("agency-interest");
          }}
          className="platform-role-card cbz-card-background-gradient"
        >
          <img
            alt="agency"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/agency-profile.png"
            className="platform-role-img"
          />
          <div>
            <h2 className="platform-role-lbl">
              <FormattedMessage id="selectRole_agencyTitle" />
            </h2>
            <p className="platform-role-description mt-1">
              <FormattedMessage id="selectRole_agencyDesc" />
            </p>
          </div>
        </motion.div>

        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          onClick={() => {
            props.onViewChange("creator-sign-up");
            window.location.hash = "creator-sign-up";
          }}
          transition={{ delay: ANIMATION_DELAY * 2 }}
          className="platform-role-card cbz-card-background-gradient"
        >
          <img
            alt="creator"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creators-profile.png"
            className="platform-role-img"
          />
          <div>
            <h2 className="platform-role-lbl">
              <FormattedMessage id="selectRole_creatorTitle" />
            </h2>
            <p className="platform-role-description mt-1">
              <FormattedMessage id="selectRole_creatorDesc" />
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
