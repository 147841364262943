import "./upload-file-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import UploadIcon from "../icon/upload-icon";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import FileUtils from "../../utilities/file-util";
import FileIcon from "../icon/file-icon";
import AlertSquareIcon from "../icon/alert-square";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { SaveProfileAttachmentReqDto } from "../../model/profile/save-profile-attachment-req.dto";
import EmpException from "../../exception/empException";
import ToastUtils from "../../utilities/toast-utils";
import ProfileApi from "../../api/user-msvc/profile.api";
import { FormattedMessage, useIntl } from "react-intl";

export interface UploadFileModalRef {
  show: (organisationId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const UploadFileModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [fileSpecs, setFileSpecs] = useState<File>();
  const [isSubmitted, setSubmitted] = useState(false);
  const organisationIdRef = useRef<string>();

  const [form, setForm] = useState<IFormGroup>({
    filename: new FormControl("text", [
      new RequiredValidator("File name is mandatory"),
      new LengthValidator(
        0,
        100,
        undefined,
        "File name should not exceed 100 characters"
      ),
    ]),
  });

  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (organisationId: string) => {
    organisationIdRef.current = organisationId;
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const submitOnClick = async () => {
    try {
      if (!organisationIdRef.current)
        throw new EmpException("Unable to get organisation id");
      setSubmitted(true);
      saveBtnRef.current?.setButtonState("loading");
      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!fileSpecs || !formIsValid) {
        setErrorMessage("Please upload a file");
        return;
      }
      // Create a file and save it to the database;
      const request: SaveProfileAttachmentReqDto = {
        base64: await FileUtils.readFileAsBase64(fileSpecs),
        filename: fileSpecs.name,
        organisationId: organisationIdRef.current,
        label: form.filename.getValue(),
      };
      const resp = await ProfileApi.saveProfileAttachment(
        organisationIdRef.current,
        request
      );

      if (resp.data.status === "success") {
        props.onSave();
        dismiss();
        ToastUtils.success("Uploaded", "Profile Attachment Created");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save rates. Please try again"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setFileSpecs(undefined);
    setErrorMessage(undefined);
    setForm({ ...form });
  };

  /**
   * Handles the change event for an input form control.
   * @param formControl - The form control to handle the change event for.
   * @returns void
   */
  const inputOnChange = (formControl: FormControl) => {
    if (!isSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isPDF = file.type === "application/pdf";
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 20);
    if (!isPDF) {
      setErrorMessage("Please upload a PDF file.");
      return;
    }
    if (!isSmallEnough) {
      setErrorMessage("File size must be less than or equal to 20MB.");
      return;
    }
    setErrorMessage(undefined);
    setFileSpecs(file);

    // Do something with the file, such as upload it
    // const base64 = await readFileAsBase64(file);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-upload-file-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="uploadFileModal_header" />
          </h2>
          <p className="description"></p>
        </div>

        <div className="content-section">
          <div>
            <EmpTextInput
              labelText={intl.formatMessage({
                id: "uploadFileModal_fileNameInputLabel",
              })}
              required
              id={"filename"}
              onChange={inputOnChange}
              formControl={form.filename}
              placeholder={intl.formatMessage({
                id: "uploadFileModal_fileNameInputPlaceholder",
              })}
              description={intl.formatMessage({
                id: "uploadFileModal_fileNameInputDesc",
              })}
            />
          </div>
          <div className="uploader-section mt-8">
            <span className="upload-label">
              <FormattedMessage id="uploadFileModal_uploadInputLabel" />{" "}
              <span className="required">*</span>
            </span>
            <label className="file-upload-zone mt-2" htmlFor={"logo-upload"}>
              {!fileSpecs && (
                <>
                  <UploadIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
                  <span className="title">
                    <FormattedMessage id="uploadFileModal_uploadInputHeader" />{" "}
                  </span>
                  <p className="specs">
                    <FormattedMessage id="uploadFileModal_uploadInputDesc" />
                  </p>
                </>
              )}
              {fileSpecs && (
                <>
                  <FileIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
                  <span className="title">{fileSpecs.name}</span>
                  <p className="specs">
                    {FileUtils.convertBytesToReadableSize(fileSpecs.size)}
                  </p>
                </>
              )}
            </label>
            <input
              className="upload-hidden"
              accept="application/pdf"
              type="file"
              id="logo-upload"
              onChange={handleFileUpload}
              name="myfile"
            ></input>
            {errorMessage && (
              <div className="emp-error-message-wrapper">
                <AlertSquareIcon
                  backgroundColor={Color.RED[600]}
                  size={16}
                  bottom={1}
                />
                <span>{errorMessage}</span>
              </div>
            )}
          </div>
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={<FormattedMessage id="cta_cancel" />}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            isFullWidth={false}
            text={<FormattedMessage id="cta_upload" />}
            onSubmit={() => {
              submitOnClick();
            }}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default UploadFileModal;
