import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import EmailOtpAPI from "../../../api/user-msvc/email-otp.api";
import UserApi from "../../../api/user-msvc/user.api";
import AlertSquareIcon from "../../../components/icon/alert-square";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import EmailIcon from "../../../components/icon/email-icon";
import LockIcon from "../../../components/icon/lock-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import EmpException from "../../../exception/empException";
import { Color } from "../../../utilities/colors";
import { empDelay } from "../../../utilities/delay";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EmpExceptionHandlerBuilder from "../../../utilities/errorUtils/empExceptionHandlerBuilder";
import { EmailValidator } from "../../../utilities/formUtils/emailValidator";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import { PasswordUtil } from "../../../utilities/password.util";
import ToastUtils from "../../../utilities/toast-utils";
import { ViewType } from "../sign-up-page";
import "./brand-sign-up-view.scss";
import {
  autoSignIn,
  signOut as awsSignOut,
  signUp as awsSignUp,
  signInWithRedirect,
} from "aws-amplify/auth";
import EmpGoogleButton from "../../../components/shared/emp-google-button/emp-google-button";
import { AUTH_CUSTOM_STATES } from "../../../constants/app.constants";
import useOnboarding from "../../../hooks/useOnboarding";

interface Props {
  onViewChange: (viewType: ViewType) => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const ANIMATION_DELAY = 0.1;

interface VerificationFormCtx {
  email: string;
}

export const BrandSignUpView = (props: Props) => {
  const intl = useIntl();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const referralCode = urlSearchParams.get("code") ?? undefined;
  const onboardId = urlSearchParams.get("onboard") ?? undefined;

  const { mapUserOnboarding } = useOnboarding();

  const submitButtonRef = useRef<EmpButtonRef>();
  const [currentView, setCurrentView] = useState<"form" | "otp">("form");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationFormCtx, setVerificationFormCtx] =
    useState<VerificationFormCtx>({ email: "buto-gmail.com" });
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState<IFormGroup>({
    email: new FormControl("text", [
      new RequiredValidator("Email is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Email must not exceed 100 characters"
      ),
      new EmailValidator(),
    ]),
    password: new FormControl("text", [
      new RequiredValidator("Password is required"),
    ]),
    confirmPassword: new FormControl("text", [
      new RequiredValidator("Confirm Password is required"),
    ]),
  });

  const validate = (formControl: FormControl) => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  useEffect(() => {
    awsSignOut();
  }, []);

  const checkIfUserExist = async (email: string): Promise<string> => {
    try {
      const response = await UserApi.checkIfUserExist(email);
      return response.data.statusMessage;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error checking if user exists"
      );
      return "false";
    }
  };

  const verifySignUpInputs = async () => {
    try {
      setHasSubmitted(true);

      // Validating the form inputs
      submitButtonRef.current?.setButtonState("loading");
      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      const result = PasswordUtil.validatePasswordInput(
        intl,
        form.password.getValue(),
        form.confirmPassword.getValue()
      );
      if (result.hasError) {
        form.confirmPassword.errorMessage = result.errorMsg;
        form.confirmPassword.hasError = true;
      }
      if (!formIsValid || result.hasError) {
        setForm({ ...form });
        return;
      }

      const email = form.email.getValue();
      const hasUser = await checkIfUserExist(email);
      if (hasUser === "true") {
        setErrorMessage("User exists. Please choose another email address");
        return;
      } else setErrorMessage(undefined);

      // Send an email otp
      const response = await EmailOtpAPI.requestEmailOtpByEmail({
        email: form.email.getValue(),
      });
      if (response.data.status === "success") {
        setCurrentView("otp");
        setCounter(30);
        setVerificationFormCtx({
          email: form.email.getValue(),
        });
      }
    } catch (e) {
      console.log(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to create a submission"
      );
    } finally {
      submitButtonRef.current?.setButtonState("default");
    }
  };

  // OTP View Section
  const [counter, setCounter] = useState(0);
  const [otpButtonDisabled, setOtpButtonDisabled] = useState(true);
  const [otpFormIsValid, setOtpFormIsValid] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>();
  const verifyOtpButtonRef = useRef<EmpButtonRef>();

  const [otpForm] = useState<IFormGroup>({
    otp: new FormControl("text", [new RequiredValidator("Otp is required")]),
  });

  useEffect(() => {
    /**
     * Manages the number of seconds that the user is required to wait before initiating another send OTP request
     */
    const startOtpCounter = async (): Promise<void> => {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(intervalId);
            setOtpButtonDisabled(false);
          }
          return prevCounter - 1;
        });
      }, 1000);
    };
    if (counter === 30) {
      startOtpCounter();
      setOtpButtonDisabled(true);
    }
  }, [counter]);

  const requestOtp = async (email: string): Promise<void> => {
    try {
      const response = await EmailOtpAPI.requestEmailOtpByEmail({ email });
      if (response.status === "success")
        ToastUtils.success(`Email OTP sent`, `Email OTP sent to ${email}`);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to request for new OTP"
      );
    }
  };

  const verifyOtp = async (): Promise<void> => {
    try {
      verifyOtpButtonRef.current?.setButtonState("loading");
      const response = await EmailOtpAPI.verifyEmailOtpByEmail({
        email: verificationFormCtx.email,
        otp: otpForm.otp.getValue(),
        role: "brand",
      });
      if (
        response.data.status === "error" &&
        response.data.statusMessage === "incorrect otp"
      ) {
        if (response.data.statusMessage === "incorrect otp")
          setOtpErrorMessage("You have given an incorrect OTP");
        else if (response.data.statusMessage === "expired")
          setOtpErrorMessage("OTP is expired. We have sent you a new one");
        return;
      } else if (response.data.status === "success") {
        // Continue with the sign-up
        setOtpErrorMessage(undefined);
        await signUp();
      }
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      verifyOtpButtonRef.current?.setButtonState("default");
    }
  };

  /**
   * Performs the sign-up process for a user.
   * @async
   * @returns {Promise<void>} A promise that resolves once the sign-up process is complete.
   * @throws {EmpException} If the sign-in fails, an EmpException is thrown with a corresponding error message.
   */
  const signUp = async (): Promise<void> => {
    try {
      const { isSignUpComplete, nextStep } = await awsSignUp({
        username: form.email.getValue(),
        password: form.password.getValue(),
        options: {
          userAttributes: {},
          autoSignIn: true,
        },
      });
      if (isSignUpComplete && nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
        await autoSignIn();
        if (referralCode || onboardId) {
          await mapUserOnboarding(onboardId, referralCode);
        }
        return;
      }

      throw new EmpException(
        "Sign In Failed",
        "Please contact system administrator"
      );
    } catch (e) {
      console.log("error signing up:", e);
      if (!(e instanceof Error)) return;
      if (e.name === "UsernameExistsException")
        ToastUtils.error("Email taken", "Please choose another email address");
    }
  };

  const otpFormOnChange = () => {
    const isValid = FormGroupUtil.validate(otpForm);
    setOtpFormIsValid(isValid);
  };
  return (
    <div className="emp-brand-signup-view">
      <div className="form-wrapper">
        {currentView === "form" && (
          <>
            <div className="back-btn-wrapper">
              <EmpIconButton
                buttonStyle="secondary"
                icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[600]} />}
                onSubmit={() => {
                  props.onViewChange("role-selection");
                }}
              />
            </div>

            <div className="title-wrapper">
              <img
                className="emplifive-logo"
                alt="emplifive logo"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
              />
              <h1 className="mt-4">Unlock the power of KOL-Driven Campaign</h1>
              {/* <p>Please enter the following details, and our team will be with you shortly.</p> */}
            </div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              style={{ width: "100%" }}
              transition={{ delay: ANIMATION_DELAY * 0 }}
            >
              <EmpTextInput
                id={"email"}
                className="mt-8"
                placeholder={"Enter your email address"}
                labelText="Email"
                type="email"
                onKeypress={(key) => {
                  if (key === "Enter") verifySignUpInputs();
                }}
                leftIconComponent={EmailIcon}
                onChange={validate}
                formControl={form.email}
              />
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              style={{ width: "100%" }}
              transition={{ delay: ANIMATION_DELAY * 1 }}
            >
              <EmpTextInput
                id={"password"}
                placeholder={"Enter password"}
                labelText="Password"
                className="mt-2"
                type="password"
                showPassword
                onKeypress={(key) => {
                  if (key === "Enter") verifySignUpInputs();
                }}
                leftIconComponent={LockIcon}
                onChange={validate}
                formControl={form.password}
              />
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              style={{ width: "100%" }}
              transition={{ delay: ANIMATION_DELAY * 2 }}
            >
              <EmpTextInput
                id={"confirmPassword"}
                placeholder={"Enter confirm password"}
                labelText="Confirm Password"
                className="mt-2"
                type="password"
                showPassword
                enterKeyHint="done"
                onKeypress={(key) => {
                  if (key === "Enter") verifySignUpInputs();
                }}
                leftIconComponent={LockIcon}
                onChange={validate}
                formControl={form.confirmPassword}
              />
            </motion.div>

            {errorMessage && (
              <div className="emp-error-message-wrapper mt-3">
                <AlertSquareIcon
                  backgroundColor={Color.RED[600]}
                  size={16}
                  bottom={1}
                />
                <span>{errorMessage}</span>
              </div>
            )}
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: ANIMATION_DELAY * 3 }}
            >
              <p className="mt-2 description">
                By clicking on the “Sign Up”, you confirm that you have read and
                agree to Emplifive’s{" "}
                <a
                  href="https://emplifive.com/legal#terms-of-service"
                  rel="noreferrer"
                  target="_blank"
                  className="highlight"
                >
                  Terms of Use
                </a>
              </p>

              <p className="mt-6 description">
                Review CreatorFi's{" "}
                <a
                  href="https://emplifive.com/legal#privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="highlight"
                >
                  Privacy Policy
                </a>{" "}
                to learn how Emplifive collects and use your personal
                information
              </p>
            </motion.div>
            <EmpGoogleButton
              className="mt-3"
              buttonHeight="lg"
              onSubmit={() => {
                signInWithRedirect({
                  provider: "Google",
                  customState: JSON.stringify({
                    origin: AUTH_CUSTOM_STATES.BRAND_SSO,
                    referralCode: referralCode,
                    onboardId: onboardId,
                  }),
                });
              }}
            />
            <div className="button-wrapper-row">
              <EmpButton
                className="mt-2"
                buttonHeight="lg"
                ref={submitButtonRef}
                onSubmit={verifySignUpInputs}
                text={<FormattedMessage id="cta_signup" />}
              />
            </div>
          </>
        )}
        {currentView === "otp" && verificationFormCtx && (
          <>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.2, delay: 0 * ANIMATION_DELAY }}
            >
              <div className="header-wrapper">
                <EmpIconButton
                  buttonStyle="secondary"
                  icon={
                    <ChevronLeftIcon backgroundColor={Color.NEUTRAL[500]} />
                  }
                  onSubmit={() => {
                    setCurrentView("form");
                    otpForm.otp.forceUpdateValue("");
                  }}
                />
                <span className="block ml-3 header">Verify your email</span>
              </div>
              <p className="description mt-4">
                We have sent an email to{" "}
                <span className="highlight">{verificationFormCtx.email}</span>{" "}
                to confirm your identity
              </p>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              style={{ width: "100%" }}
              animate="visible"
              transition={{ duration: 0.2, delay: 1 * ANIMATION_DELAY }}
            >
              <EmpTextInput
                id={"verification-code"}
                formControl={otpForm.otp}
                labelText="Verification Code"
                onChange={() => {
                  otpFormOnChange();
                }}
                type="number"
                suppressErrorMessage
                required
                placeholder="Enter Verification Code"
              />
              {otpErrorMessage && (
                <div className="emp-error-message-wrapper mt-3">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{otpErrorMessage}</span>
                </div>
              )}

              {otpButtonDisabled && (
                <div className="loader-wrapper mt-2">
                  <div className="emp-spinner small"></div>
                  <span className="block ml-1 color-gray-500">
                    Wait for {counter}s before requesting for a new OTP
                  </span>
                </div>
              )}
            </motion.div>

            <motion.div
              style={{ width: "100%", display: "flex" }}
              className="mt-4"
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.2, delay: 2 * ANIMATION_DELAY }}
            >
              <EmpButton
                onSubmit={() => {
                  requestOtp(verificationFormCtx.email);
                }}
                className="mr-2"
                isFullWidth
                buttonStyle="secondary"
                disabled={otpButtonDisabled}
                text={"Resend OTP"}
              />
              <EmpButton
                onSubmit={() => {
                  verifyOtp();
                }}
                className="ml-2"
                isFullWidth
                disabled={!otpFormIsValid}
                ref={verifyOtpButtonRef}
                text={"Verify"}
              />
            </motion.div>
          </>
        )}
      </div>
    </div>
  );
};
