import _debounce from "lodash/debounce";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import BeneficiaryApi from "../../api/payment-msvc/beneficiary.api";
import {
  accountCurrencyConstants,
  bankCountryCode,
  countryCodes,
  getCountryOptionWithHtml,
} from "../../constants/selectConstants";
import { UpdateBeneficiaryDto } from "../../model/payment/beneficiary/update-beneficiary.dto";
import { AwBeneficiaryDto } from "../../model/payment/kyc/aw-beneficiary.dto";
import { isAwValidateBeneficiaryErrDto } from "../../model/payment/kyc/aw-validate-beneficiary-err.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpSelect from "../shared/emp-select/emp-select";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import "./edit-payment-modal.scss";
import { getAirwallexErrorConstants } from "../../constants/airwallex-error.constants";
import { FormattedMessage, useIntl } from "react-intl";
import { isApiStatus } from "../../model/api/api-status";

export interface EditPaymentModalRef {
  show: (beneficiaryDto: AwBeneficiaryDto, mode: "org" | "user") => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const countryOptions = getCountryOptionWithHtml();

const EditPaymentModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const [orgFormIsPristine, setOrgFormIsPristine] = useState(true);
  const [userFormIsPristine, setUserFormIsPristine] = useState(true);
  const [hasSubmitted, setSubmitted] = useState(false);
  const submitBtnRef = useRef<EmpButtonRef>();
  const [modalMode, setModalMode] = useState<"org" | "user">("org");

  const airwallexErrorConstants = useMemo(() => {
    return getAirwallexErrorConstants(intl);
  }, [intl]);

  const [orgForm, setOrgForm] = useState<IFormGroup>({
    legalBusinessName: new FormControl("text", [new RequiredValidator()], ""),
    accountType: new FormControl("text", [new RequiredValidator()], ""),
    countryCode: new FormControl("text", [new RequiredValidator()], ""),
    streetAddress: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 200)],
      ""
    ),
    city: new FormControl(
      "text",
      [new RequiredValidator("Postal code is required")],
      ""
    ),
    postalCode: new FormControl(
      "text",
      [new RequiredValidator("Postal code is required")],
      ""
    ),
    accountHolderName: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 200)],
      ""
    ),
    accountCurrency: new FormControl("text", [new RequiredValidator()], ""),
    accountNumber: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 17)],
      ""
    ),
    bankCountryCode: new FormControl("text", [new RequiredValidator()], ""),
    swiftCode: new FormControl(
      "text",
      [new RequiredValidator("Address is required")],
      ""
    ),
  });

  const [userForm, setUserForm] = useState<IFormGroup>({
    firstName: new FormControl("text", [new RequiredValidator()], ""),
    lastName: new FormControl("text", [new RequiredValidator()], ""),
    accountType: new FormControl("text", [new RequiredValidator()], ""),
    countryCode: new FormControl("text", [new RequiredValidator()], ""),
    streetAddress: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 200)],
      ""
    ),
    city: new FormControl(
      "text",
      [new RequiredValidator("Postal code is required")],
      ""
    ),
    postalCode: new FormControl(
      "text",
      [new RequiredValidator("Postal code is required")],
      ""
    ),
    accountHolderName: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 200)],
      ""
    ),
    accountCurrency: new FormControl("text", [new RequiredValidator()], ""),
    accountNumber: new FormControl(
      "text",
      [new RequiredValidator(), new LengthValidator(0, 17)],
      ""
    ),
    bankCountryCode: new FormControl("text", [new RequiredValidator()], ""),
    swiftCode: new FormControl(
      "text",
      [new RequiredValidator("Address is required")],
      ""
    ),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    beneficiaryDto: AwBeneficiaryDto,
    mode: "org" | "user"
  ) => {
    setVisible(true);
    setModalMode(mode);
    if (mode === "org") {
      orgForm.legalBusinessName.forceUpdateValue(
        beneficiaryDto.beneficiary.company_name
      );
      orgForm.accountType.forceUpdateValue(
        beneficiaryDto.beneficiary.entity_type
      );
      orgForm.countryCode.forceUpdateValue(
        beneficiaryDto.beneficiary.address.country_code
      );
      orgForm.streetAddress.forceUpdateValue(
        beneficiaryDto.beneficiary.address.street_address
      );
      orgForm.city.forceUpdateValue(beneficiaryDto.beneficiary.address.city!);
      orgForm.postalCode.forceUpdateValue(
        beneficiaryDto.beneficiary.address.postcode!
      );
      orgForm.accountHolderName.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_name
      );
      orgForm.accountCurrency.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_currency
      );
      orgForm.accountNumber.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_number
      );
      orgForm.bankCountryCode.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.bank_country_code
      );
      orgForm.swiftCode.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.swift_code
      );
      setOrgForm({ ...orgForm });
    } else if (mode === "user") {
      userForm.firstName.forceUpdateValue(
        beneficiaryDto.beneficiary.first_name!
      );
      userForm.lastName.forceUpdateValue(beneficiaryDto.beneficiary.last_name!);
      userForm.accountType.forceUpdateValue(
        beneficiaryDto.beneficiary.entity_type
      );
      userForm.countryCode.forceUpdateValue(
        beneficiaryDto.beneficiary.address.country_code
      );
      userForm.streetAddress.forceUpdateValue(
        beneficiaryDto.beneficiary.address.street_address
      );
      userForm.city.forceUpdateValue(beneficiaryDto.beneficiary.address.city!);
      userForm.postalCode.forceUpdateValue(
        beneficiaryDto.beneficiary.address.postcode!
      );
      userForm.accountHolderName.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_name
      );
      userForm.accountCurrency.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_currency
      );
      userForm.accountNumber.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.account_number
      );
      userForm.bankCountryCode.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.bank_country_code
      );
      userForm.swiftCode.forceUpdateValue(
        beneficiaryDto.beneficiary.bank_details.swift_code
      );
      setUserForm({ ...userForm });
    }
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const resetForm = () => {
    FormGroupUtil.reset(orgForm);
    setOrgForm({ ...orgForm });

    FormGroupUtil.reset(userForm);
    setUserForm({ ...userForm });
  };

  const orgFormOnChange = (formControl: FormControl): void => {
    setOrgFormIsPristine(FormGroupUtil.isPristine(orgForm));
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setOrgForm({ ...orgForm });
  };

  const userFormOnChange = (formControl: FormControl): void => {
    setUserFormIsPristine(FormGroupUtil.isPristine(userForm));
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setUserForm({ ...userForm });
  };

  const submitOrgUpdate = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(orgForm);
      setOrgForm({ ...orgForm });
      if (!isValid) return;

      const request: UpdateBeneficiaryDto = {
        legalBusinessName: orgForm.legalBusinessName.getValue(),
        countryCode: orgForm.countryCode.getValue(),
        streetAddress: orgForm.streetAddress.getValue(),
        postalCode: orgForm.postalCode.getValue(),
        city: orgForm.city.getValue(),
        accountHolderName: orgForm.accountHolderName.getValue(),
        accountCurrency: orgForm.accountCurrency.getValue(),
        accountNumber: orgForm.accountNumber.getValue(),
        bankCountryCode: orgForm.bankCountryCode.getValue(),
        swiftCode: orgForm.swiftCode.getValue(),
      };

      const resp = await BeneficiaryApi.updateBeneficiary(request);
      if (isAwValidateBeneficiaryErrDto(resp.data)) {
        ToastUtils.error(
          "Some Fields Are Invalid",
          "Please Correct the values"
        );
        for (let error of resp.data.errors) {
          if (!(error.code in airwallexErrorConstants)) continue;

          // Include more error messages when necessary
          if (error.source === "beneficiary.bank_details.swift_code") {
            orgForm.swiftCode.errorMessage =
              airwallexErrorConstants[error.code];
            orgForm.swiftCode.hasError = true;
          }
        }
        setOrgForm({ ...orgForm });
      } else if (isApiStatus(resp.data)) {
        ToastUtils.success(
          "Updated Completed",
          "You have updated your payment records"
        );
        props.onSave();
        dismiss();
      }
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when saving org beneficiary"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  const submitUserUpdate = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(userForm);
      setOrgForm({ ...userForm });
      if (!isValid) return;

      const request: UpdateBeneficiaryDto = {
        firstName: userForm.firstName.getValue(),
        lastName: userForm.lastName.getValue(),
        countryCode: userForm.countryCode.getValue(),
        streetAddress: userForm.streetAddress.getValue(),
        postalCode: userForm.postalCode.getValue(),
        city: userForm.city.getValue(),
        accountHolderName: userForm.accountHolderName.getValue(),
        accountCurrency: userForm.accountCurrency.getValue(),
        accountNumber: userForm.accountNumber.getValue(),
        bankCountryCode: userForm.bankCountryCode.getValue(),
        swiftCode: userForm.swiftCode.getValue(),
      };

      const resp = await BeneficiaryApi.updateBeneficiary(request);
      if (isAwValidateBeneficiaryErrDto(resp.data)) {
        ToastUtils.error(
          "Some Fields Are Invalid",
          "Please Correct the values"
        );
        for (let error of resp.data.errors) {
          if (!(error.code in airwallexErrorConstants)) continue;

          // Include more error messages when necessary
          if (error.source === "beneficiary.bank_details.swift_code") {
            orgForm.swiftCode.errorMessage =
              airwallexErrorConstants[error.code];
            orgForm.swiftCode.hasError = true;
          }
        }
        setUserForm({ ...userForm });
      } else if (isApiStatus(resp.data)) {
        ToastUtils.success(
          intl.formatMessage({ id: "editPayoutModal_editSuccessHeader" }),
          intl.formatMessage({ id: "editPayoutModal_editSuccessDesc" })
        );
        props.onSave();
        dismiss();
      }
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when saving org beneficiary"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={<FormattedMessage id="settingsPaymentView_header" />}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      bodyPadding={false}
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-edit-payment-modal">
        <div className="subsection mb-4">
          <FormattedMessage id="editPayoutModal_payoutBankDetailsHeader" />
        </div>
        {modalMode === "org" && (
          <section className="form-section">
            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  letterSpacing={1}
                  id={"accountNumber"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountNumberLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountNumberDesc",
                  })}
                  required
                  placeholder="e.g, 39138913129"
                  onChange={orgFormOnChange}
                  formControl={orgForm.accountNumber}
                />
              </div>
              <div className="split-control ">
                <EmpTextInput
                  id={"accountHolderName"}
                  placeholder="e.g, Company Group"
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountHolderLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountHolderDesc",
                  })}
                  required
                  onChange={orgFormOnChange}
                  formControl={orgForm.accountHolderName}
                />
              </div>
            </div>
            <div className="split-wrapper">
              <div className="split-control">
                <EmpSelect
                  id={"accountCurrency"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_accountCurrencyPlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountCurrencyLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountCurrencyDesc",
                  })}
                  required
                  onChange={orgFormOnChange}
                  formControl={orgForm.accountCurrency}
                  selectOptions={accountCurrencyConstants}
                />
              </div>
              <div className="split-control ">
                <EmpSelect
                  id={"bankCountryCode"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutBankCountryCodeHeader",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutBankCountryCodeDesc",
                  })}
                  required
                  onChange={orgFormOnChange}
                  selectOptions={bankCountryCode}
                  formControl={orgForm.bankCountryCode}
                />
              </div>
            </div>
            <EmpTextInput
              id={"swiftCode"}
              placeholder="e.g, VHAUS33"
              labelText={intl.formatMessage({
                id: "editPayoutModal_payoutSwiftCodeLabel",
              })}
              description={intl.formatMessage({
                id: "editPayoutModal_payoutSwiftCodeDesc",
              })}
              required
              onChange={orgFormOnChange}
              formControl={orgForm.swiftCode}
            />
          </section>
        )}

        {modalMode === "user" && (
          <section className="form-section">
            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  letterSpacing={1}
                  id={"accountNumber"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountNumberLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountNumberDesc",
                  })}
                  required
                  placeholder="e.g, 39138913129"
                  onChange={userFormOnChange}
                  formControl={userForm.accountNumber}
                />
              </div>
              <div className="split-control ">
                <EmpTextInput
                  id={"accountHolderName"}
                  placeholder="e.g, Company Group"
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountHolderLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountHolderDesc",
                  })}
                  required
                  onChange={userFormOnChange}
                  formControl={userForm.accountHolderName}
                />
              </div>
            </div>
            <div className="split-wrapper">
              <div className="split-control">
                <EmpSelect
                  id={"accountCurrency"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_accountCurrencyPlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountCurrencyLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutAccountCurrencyDesc",
                  })}
                  required
                  onChange={userFormOnChange}
                  formControl={userForm.accountCurrency}
                  selectOptions={accountCurrencyConstants}
                />
              </div>
              <div className="split-control ">
                <EmpSelect
                  id={"bankCountryCode"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutBankCountryCodeHeader",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutBankCountryCodeDesc",
                  })}
                  required
                  onChange={userFormOnChange}
                  selectOptions={bankCountryCode}
                  formControl={userForm.bankCountryCode}
                />
              </div>
            </div>
            <EmpTextInput
              id={"swiftCode"}
              placeholder="e.g, VHAUS33"
              labelText={intl.formatMessage({
                id: "editPayoutModal_payoutSwiftCodeLabel",
              })}
              description={intl.formatMessage({
                id: "editPayoutModal_payoutSwiftCodeDesc",
              })}
              required
              onChange={userFormOnChange}
              formControl={userForm.swiftCode}
            />
          </section>
        )}
        <div className="subsection mb-4 mt-2">
          <FormattedMessage id="editPayoutModal_payoutBeneficiaryDetailsHeader" />
        </div>
        {modalMode === "org" && (
          <section className="form-section">
            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  id={"legalBusinessName"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutCompanyNameLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutCompanyNameDesc",
                  })}
                  required
                  placeholder="Enter Legal Business Name"
                  onChange={orgFormOnChange}
                  formControl={orgForm.legalBusinessName}
                />
              </div>
              <div className="split-control">
                <EmpSelect
                  id={"countryCode"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutCountryCodeLabel",
                  })}
                  description={intl.formatMessage({
                    id: "editPayoutModal_payoutCountryCodeDesc",
                  })}
                  required
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_countryCodePlaceholder",
                  })}
                  onChange={orgFormOnChange}
                  formControl={orgForm.countryCode}
                  selectOptions={countryCodes}
                />
              </div>
            </div>
            <EmpTextInput
              id={"streetAddress"}
              labelText={intl.formatMessage({
                id: "editPayoutModal_payoutStreetAddressLabel",
              })}
              description={intl.formatMessage({
                id: "editPayoutModal_payoutStreetAddressDesc",
              })}
              multiline
              rows={2}
              textAreaAdaptiveHeight
              required
              placeholder={intl.formatMessage({
                id: "editPayoutModal_streetAddressPlaceholder",
              })}
              onChange={orgFormOnChange}
              formControl={orgForm.streetAddress}
            />

            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  id={"city"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_cityPlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutCityLabel",
                  })}
                  required
                  formControl={orgForm.city}
                />
              </div>
              <div className="split-control ">
                <EmpTextInput
                  id={"postalCode"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_postalCodePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutPostalCodeLabel",
                  })}
                  required
                  formControl={orgForm.postalCode}
                />
              </div>
            </div>
          </section>
        )}

        {modalMode === "user" && (
          <section className="form-section">
            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  id={"firstName"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_firstNamePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutFirstNameLabel",
                  })}
                  required
                  formControl={userForm.firstName}
                />
              </div>
              <div className="split-control ">
                <EmpTextInput
                  id={"lastName"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_lastNamePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutLastNameLabel",
                  })}
                  required
                  formControl={userForm.lastName}
                />
              </div>
            </div>

            <EmpSelect
              id={"countryCode"}
              labelText={intl.formatMessage({
                id: "editPayoutModal_payoutCountryCodeLabel",
              })}
              description={intl.formatMessage({
                id: "editPayoutModal_payoutCountryCodeDesc",
              })}
              required
              placeholder={intl.formatMessage({
                id: "editPayoutModal_countryCodePlaceholder",
              })}
              onChange={userFormOnChange}
              formControl={userForm.countryCode}
              selectOptions={countryCodes}
            />

            <EmpTextInput
              id={"streetAddress"}
              labelText={intl.formatMessage({
                id: "editPayoutModal_payoutStreetAddressLabel",
              })}
              description={intl.formatMessage({
                id: "editPayoutModal_payoutStreetAddressDesc",
              })}
              multiline
              rows={2}
              textAreaAdaptiveHeight
              required
              placeholder={intl.formatMessage({
                id: "editPayoutModal_streetAddressPlaceholder",
              })}
              onChange={userFormOnChange}
              formControl={userForm.streetAddress}
            />

            <div className="split-wrapper">
              <div className="split-control">
                <EmpTextInput
                  id={"city"}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutCityLabel",
                  })}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_cityPlaceholder",
                  })}
                  required
                  formControl={userForm.city}
                />
              </div>
              <div className="split-control ">
                <EmpTextInput
                  id={"postalCode"}
                  placeholder={intl.formatMessage({
                    id: "editPayoutModal_postalCodePlaceholder",
                  })}
                  labelText={intl.formatMessage({
                    id: "editPayoutModal_payoutPostalCodeLabel",
                  })}
                  required
                  formControl={userForm.postalCode}
                />
              </div>
            </div>
          </section>
        )}
        <div className="button-wrapper mt-4">
          {modalMode === "org" && (
            <EmpButton
              onSubmit={() => {
                submitOrgUpdate();
              }}
              ref={submitBtnRef}
              disabled={orgFormIsPristine}
              isFullWidth={false}
              text={
                <FormattedMessage id="editPayoutModal_updatePaymentDetailsBtn" />
              }
            />
          )}

          {modalMode === "user" && (
            <EmpButton
              onSubmit={submitUserUpdate}
              ref={submitBtnRef}
              disabled={userFormIsPristine}
              isFullWidth={false}
              text={
                <FormattedMessage id="editPayoutModal_updatePaymentDetailsBtn" />
              }
            />
          )}
        </div>
      </div>
      <div></div>
    </EmpModal>
  );
});

export default EditPaymentModal;
