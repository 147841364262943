import "./invite-task-modal.scss";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import { UserDto } from "../../model/user-management/user.dto";
import { empDelay } from "../../utilities/delay";
import { TaskDto } from "../../model/campaign/task.dto";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import TaskInvitationOfferView from "./invite-task-modal-view/task-invitation-offer-view";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import {
  CreatorTaskRepDto,
  TaskRepresentativeDto,
} from "../../model/user/creator-task-rep.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import TaskInvitationVariedOfferView from "./invite-task-modal-view/task-invitation-varied-offer-view";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import { LatestNegotiationDto } from "../../model/campaign/latest-negotiation.dto";
import TaskRepSelectionView from "./invite-task-modal-view/task-rep-selection-view";

export interface InviteTaskModalRef {
  show: (
    task: TaskDto,
    brandOrg: OrganisationRespDto,
    creator: UserDto
  ) => void;
  dismiss: () => void;
}

interface Props {}

type ModalView =
  | "offer-view"
  | "varied-offer-view"
  | "rep-selection-view"
  | "loading";

const InviteTaskModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [task, setTask] = useState<TaskDto>();
  const [brandOrg, setBrandOrg] = useState<OrganisationRespDto>();
  const [creatorTaskRep, setCreatorTaskRep] = useState<CreatorTaskRepDto>();
  const [creator, setCreator] = useState<UserDto>();

  const [modalView, setModalView] = useState<ModalView>("loading");

  const [selectedTaskRepresentatives, setSelectedTaskRepresentatives] =
    useState<TaskRepresentativeDto[]>([]);
  const [latestNegotiation, setLatestNegotiation] =
    useState<LatestNegotiationDto[]>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  /**
   * Retrieves the latest negotiation data for a specific campaign and task.
   *
   * @param {string} campaignId - The ID of the campaign.
   * @param {string} taskId - The ID of the task.
   * @returns {Promise<void>} - A promise that resolves when the data is fetched and updated.
   * @throws {Error} - If an error occurs while fetching or updating the data.
   */
  const getLatestNegotiation = async (campaignId: string, taskId: string) => {
    try {
      const resp = await NegotiationApi.getLatestNegotiation(
        campaignId,
        taskId
      );
      setLatestNegotiation(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch latest negotiation for this task"
      );
    }
  };

  // If agency ID is included, this is a managed talent
  const show = async (
    task: TaskDto,
    brandOrg: OrganisationRespDto,
    creator: UserDto
  ) => {
    try {
      setVisible(true);
      setTask(task);
      setBrandOrg(brandOrg);
      setCreator(creator);

      // Get latest negotiation object
      getLatestNegotiation(task.id, task.campaign.id);

      const resp = await CreatorInfoApi.getCreatorTaskRepresentatives(
        creator.id
      );
      const creatorTaskRep = resp.data;

      setCreatorTaskRep(creatorTaskRep);
      if (creatorTaskRep.taskRepresentative.length > 1) {
        setModalView("rep-selection-view");
      } else if (creatorTaskRep.taskRepresentative.length === 1) {
        setSelectedTaskRepresentatives(creatorTaskRep.taskRepresentative);
        setModalView("offer-view");
      } else {
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error displaying task invitation modal"
      );
    }
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setTask(undefined);
    setModalView("loading");
    setLatestNegotiation(undefined);
  };

  const taskRepSelectionViewOnClick = (
    returnedSelectedTaskRepresentatives: TaskRepresentativeDto[]
  ) => {
    setSelectedTaskRepresentatives(returnedSelectedTaskRepresentatives);
    setModalView("offer-view");
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"md"}
    >
      {/* This is body */}
      <div>
        <div className="emp-invite-task-modal">
          {/* Task Invitation Offer View */}
          <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
            <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
          </div>
          {task && brandOrg && creator && modalView === "offer-view" && (
            <TaskInvitationOfferView
              onComplete={dismiss}
              onBackClick={() => {
                setModalView("rep-selection-view");
              }}
              onVariedOfferViewClick={() => {
                setModalView("varied-offer-view");
              }}
              brandOrg={brandOrg}
              task={task}
              creator={creator}
              taskRepresentatives={selectedTaskRepresentatives}
            />
          )}
          {task &&
            brandOrg &&
            creator &&
            latestNegotiation &&
            modalView === "varied-offer-view" && (
              <TaskInvitationVariedOfferView
                onComplete={dismiss}
                onBackClick={() => {
                  setModalView("offer-view");
                }}
                brandOrg={brandOrg}
                task={task}
                creator={creator}
                latestNegotiations={latestNegotiation}
                taskRepresentatives={selectedTaskRepresentatives}
              />
            )}

          {task &&
            creatorTaskRep &&
            latestNegotiation &&
            modalView === "rep-selection-view" && (
              <TaskRepSelectionView
                onNext={taskRepSelectionViewOnClick}
                creatorTaskRep={creatorTaskRep}
                latestNegotiations={latestNegotiation}
              />
            )}

          {modalView === "loading" && (
            <div className="loading-view">
              <div className="emp-loader">
                <span className="label">Loading...</span>
                <div className="emp-progress mt-2">
                  <div className="indeterminate"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default InviteTaskModal;
