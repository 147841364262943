import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const MessageWithPhoneIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.7">
          <path
            d="M7 0C3.13129 0 0 3.13075 0 7C0 8.22779 0.320221 9.42697 0.928299 10.4846L0.0263824 13.2854C-0.0365295 13.4807 0.0151673 13.6947 0.160217 13.8398C0.303879 13.9834 0.517395 14.0371 0.714569 13.9736L3.51538 13.0717C4.57303 13.6798 5.7722 14 7 14C10.8687 14 14 10.8692 14 7C14 3.13129 10.8692 0 7 0ZM7 12.9062C5.89097 12.9062 4.80993 12.5968 3.87373 12.0114C3.73904 11.9272 3.57178 11.9045 3.41615 11.9546L1.39432 12.6057L2.04544 10.5838C2.09479 10.4305 2.07396 10.263 1.98851 10.1263C1.40318 9.19006 1.09375 8.10902 1.09375 7C1.09375 3.74331 3.74331 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74331 12.9062 7C12.9062 10.2567 10.2567 12.9062 7 12.9062Z"
            fill={backgroundColor}
          />
          <path
            d="M9.63128 8.33023L8.42301 8.00364C8.19227 7.94137 7.95004 8.05289 7.84758 8.26884C7.79039 8.3894 7.65018 8.44306 7.52498 8.39691C7.08689 8.23506 6.68811 7.9745 6.3569 7.64328C6.02591 7.31228 5.76513 6.91349 5.60328 6.47517C5.55713 6.34997 5.61078 6.20975 5.73135 6.15256C5.9473 6.05011 6.05881 5.80787 5.99654 5.57712L5.66974 4.36883C5.5929 4.08464 5.28288 3.9219 5.01239 4.03761C4.74433 4.15221 4.48289 4.32533 4.29299 4.58964C3.51641 5.6681 4.41841 7.56665 5.42773 8.57224C6.4333 9.58158 8.33204 10.4836 9.41025 9.707C9.67456 9.5171 9.84767 9.25544 9.96227 8.98759C10.0782 8.71709 9.91546 8.40707 9.63128 8.33023Z"
            fill={backgroundColor}
          />
        </g>
        <defs>
          <clipPath id="clip0_2104_4422">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
export default MessageWithPhoneIcon;
