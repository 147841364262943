import { useEffect, useState } from "react";
import { Color } from "../../../utilities/colors";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-secondary-tabbar.scss";

export interface EmpTab {
  text: string;
  display: string;
  action?: (text: string) => void;
  isSelected: boolean;
  toastNum?: number;
  toastIndicator?: boolean;
  comingSoon?: boolean;
  disabled?: boolean;
}

interface Props {
  tabs: EmpTab[];
  onChange?: (selectedTab: string) => void;
}

const EmpSecondaryTabBar = (props: Props) => {
  const { tabs, onChange } = props;
  const [tabItems, setTabItems] = useState<EmpTab[]>(tabs);

  useEffect(() => {
    setTabItems(props.tabs);
  }, [props.tabs]);

  const tabOnSelect = (selectedIndex: number) => {
    // Unselect selected item
    const tabToDeselectIndex = tabItems.findIndex((elem) => elem.isSelected);
    if (tabToDeselectIndex > -1 && tabToDeselectIndex !== selectedIndex) {
      tabItems[tabToDeselectIndex].isSelected = false;
      tabItems[selectedIndex].isSelected = true;
      if (onChange) onChange(tabItems[selectedIndex].text);
    }
    setTabItems([...tabItems]);
  };

  return (
    <div className="emp-secondary-tabbar">
      <div className="emp-tabbar-wrapper">
        <div className="tabbar-underline-wrapper">
          <div className="tabbar-underline"></div>
          {tabItems.map((elem, index) => {
            return (
              <div
                onClick={() => {
                  if (elem.disabled !== undefined && elem.disabled === true)
                    return;
                  tabOnSelect(index);
                }}
                key={elem.text}
                className={`tab ${elem.isSelected ? "active" : ""}`}
              >
                <div className="tab-content-wrapper ">
                  <span className="tab-content">{elem.display}</span>
                  {elem.toastIndicator && (
                    <div className="toast-wrapper">!</div>
                  )}
                  {elem.toastNum && (
                    <div className="toast-wrapper">{elem.toastNum}</div>
                  )}
                  {elem.comingSoon && (
                    <EmpPill
                      text={"COMING SOON"}
                      color={"#FF1D23"}
                      backgroundColor={Color.RED[100]}
                      size="sm"
                    />
                  )}
                </div>
                {elem.isSelected && <div className="underline"></div>}
              </div>
            );
          })}
          <div className="blank-tab-space"></div>
        </div>
      </div>
    </div>
  );
};
export default EmpSecondaryTabBar;
