import { useContext, useMemo, useState } from "react";
import "./brand-payment-view.scss";
import EmpTable, {
  EmpTableCollapsible,
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { useNavigate } from "react-router-dom";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { FormattedMessage, useIntl } from "react-intl";
import { PaymentRecordDto } from "../../../model/payment/payment/payment-record.dto";
import FormFieldUtils from "../../../utilities/form-field.util";
import { DateUtil } from "../../../utilities/date";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import { AppContext } from "../../../context/app.context";
import { UserDto } from "../../../model/user-management/user.dto";
import { PermsUtils } from "../../../constants/permissions.constants";
import EmpDownloadButton from "../../../components/shared/emp-invoice-download-button/emp-invoice-download-button";

type CurrentViewType = "brand" | "agency";

export const BrandPaymentView = () => {
  const intl = useIntl();
  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();

  const navigate = useNavigate();
  const [data, setData] = useState<(PaymentRecordDto | EmpTableCollapsible)[]>(
    []
  );
  const [isLoading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState<CurrentViewType>("brand");

  const paymentContentSpec: EmpTableContentSpec<PaymentRecordDto>[] =
    useMemo(() => {
      return [
        {
          title: "",
          dataIndex: "collapsible-btn",
          hasCollapsible: (record) => {
            return record.type === "campaign-payment";
          },
        },
        {
          title: "Initiator",
          dataIndex: "initiator",
          render: (record) => {
            return (
              <div className="campaign-payment-user">
                <div className="profile-wrapper">
                  {record.initiator.initiatorType === "organisation" && (
                    <img
                      className="profile"
                      src={record.initiator.organisation!.logo}
                      alt={record.initiator.organisation!.companyName}
                    />
                  )}
                  {record.initiator.initiatorType === "user" &&
                    record.initiator.user!.imageType === "url" && (
                      <img
                        className="profile"
                        src={record.initiator.user!.imageResource}
                        alt={record.initiator.user!.fullName}
                      />
                    )}
                  {record.initiator.initiatorType === "user" &&
                    record.initiator.user!.imageType === "avatar" && (
                      <div
                        className="profile"
                        style={{
                          background: record.initiator.user!.imageResource,
                        }}
                      >
                        <span className="initials">
                          {record.initiator.user!.initials}
                        </span>
                      </div>
                    )}
                  {record.initiator.initiatorType === "system" && (
                    <img
                      className="profile"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/emplifive-system-logo.png"
                      alt={"Emplifive"}
                    />
                  )}
                </div>
                <div className="info-wrapper">
                  {record.initiator.initiatorType === "user" && (
                    <span className="creator-name">
                      {record.initiator.user!.fullName}
                    </span>
                  )}
                  {record.initiator.initiatorType === "organisation" && (
                    <span className="creator-name">
                      {record.initiator.organisation!.companyName}
                    </span>
                  )}
                  {record.initiator.initiatorType === "system" && (
                    <span className="creator-name">System</span>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "settingsPaymentView_descriptionHeader",
          }),
          dataIndex: "description",
          width: 240,
          render: (record) => {
            return <div className="description-cell">{record.description}</div>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_amountHeader" }),
          dataIndex: "amount",
          render: (record) => {
            return <>${FormFieldUtils.toMoney(Number(record.amount))}</>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_statusHeader" }),
          dataIndex: "status",
          render: (record) => {
            return (
              <>
                {record.status === "PAID" && (
                  <EmpPill text={"Paid"} {...PILL_COLORS["green"]} />
                )}
              </>
            );
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_dateHeader" }),
          dataIndex: "date",
          render: (record) => {
            return <>{DateUtil.toReadableDateWithTime(record.createdDate)}</>;
          },
        },
        {
          title: intl.formatMessage({ id: "settingsPaymentView_actionHeader" }),
          dataIndex: "action",
          render: (record) => {
            if (record.type === "subscription") {
              return (
                <>
                  {record.generatedInvoiceId && (
                    <EmpDownloadButton
                      generatedInvoiceId={record.generatedInvoiceId}
                    />
                  )}
                  <EmpButton
                    buttonHeight="sm"
                    buttonStyle="secondary"
                    isFullWidth={false}
                    text={"To Subscription"}
                    onSubmit={() => {
                      navigate("/brand/settings#subscription");
                    }}
                  />
                </>
              );
            } else if (record.type === "campaign-payment") {
              return (
                <>
                  {record.generatedInvoiceId && (
                    <EmpDownloadButton
                      generatedInvoiceId={record.generatedInvoiceId}
                    />
                  )}
                  <EmpButton
                    buttonHeight="sm"
                    buttonStyle="secondary"
                    isFullWidth={false}
                    text={"To Task"}
                    onSubmit={() => {
                      navigate(
                        `/brand/campaign-details/${
                          record.campaignPayment!.campaignId
                        }/task/${record.campaignPayment!.taskId}`
                      );
                    }}
                  />
                </>
              );
            }
            return <></>;
          },
        },
      ];
    }, [intl, navigate]);

  const hasDualRole: boolean = useMemo(() => {
    if (!user) return false;
    return PermsUtils.hasDualRole(user);
  }, [user]);

  return (
    <div className="emp-settings-brand-payment-view ">
      <div className="view-header-wrapper mb-2">
        <div className="text-wrapper">
          <div className="text-status-wrapper">
            <h1 className="section-header">Payment Transactions</h1>
          </div>
          <p className="emp-paragraph">
            Easily track and view details of your spendings from campaigns or
            service marketplace transactions
          </p>
        </div>
      </div>

      {hasDualRole && (
        <div className="tabbed-option-wrapper mb-2">
          <div
            onClick={() => {
              if (currentView === "brand") return;
              setCurrentView("brand");
            }}
            className={`tabbed-option ${
              currentView === "brand" ? "active" : ""
            }`}
          >
            <span>Brand</span>
          </div>
          <div
            onClick={() => {
              if (currentView === "agency") return;
              setCurrentView("agency");
            }}
            className={`tabbed-option ${
              currentView === "agency" ? "active" : ""
            }`}
          >
            <span>Agency</span>
          </div>
        </div>
      )}
      <div className="card-wrapper">
        {currentView === "brand" && (
          <div className="emp-card no-padding">
            <EmpTable
              shimmerLoading={{
                width: [100, 120, 80, 50, 120, 100],
                overlayDesign: (
                  <>
                    <EmpLoaderV2 isLoading={true} />
                  </>
                ),
              }}
              loading={isLoading}
              showEmptyState={true}
              contentColumns={paymentContentSpec}
              data={[]}
              tableInCard
              emptyStateDescription={
                <FormattedMessage id="settingsPaymentView_tableEmptyDesc" />
              }
              emptyStateHeader={
                <FormattedMessage id="settingsPaymentView_tableEmptyHeader" />
              }
              rowKey={"id"}
            />
          </div>
        )}

        {currentView === "agency" && (
          <div className="emp-card no-padding">
            <EmpTable
              shimmerLoading={{
                width: [100, 120, 80, 50, 120, 100],
                overlayDesign: (
                  <div className="table-overlay-wrapper">
                    <img
                      alt="No tasks"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/gray-loudspeaker.png"
                    />
                    <span className="header">No Payout Records</span>
                    <span className="description">
                      No Payout Transactions Found
                    </span>
                  </div>
                ),
              }}
              loading={true}
              showEmptyState={false}
              contentColumns={paymentContentSpec}
              data={[]}
              tableInCard
              rowKey={"id"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
