import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PermsUtils } from "../../../constants/permissions.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import { AppContext } from "../../../context/app.context";
import EmpException from "../../../exception/empException";
import useEmpGuard from "../../../hooks/useEmpGuard";
import { UserDto } from "../../../model/user-management/user.dto";
import AgencyToBrandTransitionModal, {
  AgencyToBrandTransitionModalRef,
} from "../../modals/agency-to-brand-transition-modal";
import EmpLanguagePicker from "../../shared/emp-language-picker/emp-language-picker";
import { EmpNotification } from "../../shared/emp-notification/emp-notification";
import EmpProfileDropdownBtn, {
  MenuButtonSpec,
} from "../../shared/emp-profile-dropdown-btn/emp-profile-dropdown-btn";
import "./emp-agency-template.scss";
import dashboardActiveImg from "../../../assets/icons/navbar-header-icons/dashboard-active.png";
import dashboardImg from "../../../assets/icons/navbar-header-icons/dashboard-inactive.png";
import creatorActiveImg from "../../../assets/icons/navbar-header-icons/person-active.png";
import creatorImg from "../../../assets/icons/navbar-header-icons/person-inactive.png";
import orgActiveImg from "../../../assets/icons/navbar-header-icons/org-active.png";
import orgImg from "../../../assets/icons/navbar-header-icons/org-inactive.png";
import campaignActiveImg from "../../../assets/icons/navbar-header-icons/campaign-active.png";
import campaignImg from "../../../assets/icons/navbar-header-icons/campaign-inactive.png";
import { FormattedMessage, useIntl } from "react-intl";
import { SearchBar } from "../../shared/emp-search/emp-search-bar";
import UserUtils from "../../../utilities/user-utils";
import { AblyProvider } from "ably/react";
import * as Ably from "ably";
import { getEnvVar } from "../../../constants/envConstants";
import { EmpGlobalNotification } from "../../../model/common/global-notification";
import { signOut as awsSignOut } from "aws-amplify/auth";
import { PLATFORM_ROLES } from "../../../constants/app.constants";

const menuToPathBinding: { [key: string]: string } = {
  dashboard: "/agency/home",
  campaign: "/agency/campaigns-new",
  creators: "/agency/creators",
  "my-profile": "/agency/profile",
};

interface Props {
  children: JSX.Element;
}

type MenuTabType = "dashboard" | "campaign" | "creators" | "my-profile";
const EmpAgencyTemplate = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);
  const [selectedMenuTab, setSelectedMenuTab] = useState<MenuTabType>();
  const agencyToBrandRef = useRef<AgencyToBrandTransitionModalRef>();
  const intl = useIntl();
  const menuButtonSpecs: MenuButtonSpec<any>[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: "kebabMenu_settings" }),
        context: undefined,
        action: (record) => {
          settingsOnClick();
        },
      },
      {
        label: intl.formatMessage({ id: "kebabMenu_referral" }),
        context: undefined,
        action: (record) => {
          referralsOnClick();
        },
      },
      // M2 ACTIVATION
      {
        label: intl.formatMessage({ id: "kebabMenu_switch" }),
        context: undefined,
        action: (record) => {
          transitToBrandOnClick();
        },
      },
      {
        label: intl.formatMessage({ id: "kebebMenu_logout" }),
        context: undefined,
        action: (record) => {
          signOut();
        },
      },
    ];
  }, [intl]);

  const [user, setUser] = useState<UserDto>();
  const userRef = useRef<UserDto>();
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  // User context from the App Provider
  const { user: userContext, setUser: setUserContext } = useContext(AppContext);

  const ablyClient = useMemo(() => {
    return new Ably.Realtime.Promise({
      authUrl: `${getEnvVar().userMsvc}/realtime`,
    });
  }, []);

  useEffect(() => {
    if (
      ablyClient &&
      user &&
      user.organisation &&
      user.organisation.length > 0
    ) {
      const channel = ablyClient.channels.get("is_online");
      channel.presence.enter(user.organisation[0].id);
      const globalNotificationChannel = ablyClient.channels.get(
        `global_${user.organisation[0].id}`
      );
      globalNotificationChannel.subscribe((message) => {
        const notification = message.data as EmpGlobalNotification;
        PubSub.publish(PUB_SUB_TOPICS.GLOBAL_NOTIFICATION, notification);
      });
      return () => {
        channel.presence.leave();
        globalNotificationChannel.unsubscribe();
      };
    }
  }, [user, ablyClient]);

  const hasDualRole: boolean = useMemo(() => {
    if (!user) return false;
    return PermsUtils.hasDualRole(user);
  }, [user]);

  const fetchUserDetails = useCallback(async () => {
    const userResp = await UserUtils.fetchUser(userContext);
    setUser(userResp);
    const roleClassification = PermsUtils.getUserRole(userResp);
    // Navigate to creator dashboard here
    if (roleClassification === "creator") {
      navigate("/creator/home");
    }
  }, [userContext, navigate]);

  useEffect(() => {
    fetchUserDetails();
    // Running the subscription callback
    const subscriptionCallback = () => {
      fetchUserDetails();
    };
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.USER,
      subscriptionCallback
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [fetchUserDetails]);

  /**
   * User Profile Menu interactions
   */

  /**
   * An asynchronous function that signs out the current authenticated user and redirects them to the home page.
   */
  async function signOut() {
    try {
      await awsSignOut();
      setUserContext!(null);
      navigate("/sign-in");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const transitToBrandOnClick = () => {
    if (
      !userRef.current?.organisation ||
      !userRef.current.organisation[0].organisationType.find(
        (elem) => elem.type === PLATFORM_ROLES.BRAND
      )
    ) {
      agencyToBrandRef.current?.show();
      return;
    }
    navigate("/brand/home");
  };

  const settingsOnClick = () => {
    navigate("/agency/settings");
  };

  const referralsOnClick = () => {
    navigate("/agency/referrals");
  };

  useEffect(() => {
    const homeKeywords = "/home";
    const campaignKeywords = "/campaigns";
    const creatorKeywords = "/creators";
    const profileKeywords = "/profile";

    const path = location.pathname;
    if (path.includes(homeKeywords)) {
      setSelectedMenuTab("dashboard");
    } else if (path.includes(campaignKeywords)) {
      setSelectedMenuTab("campaign");
    } else if (path.includes(creatorKeywords)) {
      setSelectedMenuTab("creators");
    } else if (path.includes(profileKeywords)) {
      setSelectedMenuTab("my-profile");
    }
  }, [location]);

  const menuItemOnClick = (menuItem: MenuTabType) => {
    try {
      setSelectedMenuTab(menuItem);
      navigate(menuToPathBinding[menuItem]);
    } catch (e) {
      console.error(e);
    }
  };

  // This moves the underline
  useEffect(() => {
    if (!selectedMenuTab) return;

    if (!underlineElemRef.current)
      throw new EmpException("Unable to get underline element");
    const menuElem = menuItemRef.current[selectedMenuTab];
    if (!menuElem)
      throw new EmpException("Unable to retreieve target menu element");

    const width = `${menuElem.clientWidth}px`;
    const left = `${menuElem.offsetLeft}px`;
    underlineElemRef.current.style.width = width;
    underlineElemRef.current.style.left = left;
  }, [selectedMenuTab, intl]);

  const menuItemRef = useRef<{ [key: string]: HTMLDivElement }>({});
  const underlineElemRef = useRef<HTMLDivElement>(null);

  return (
    <AblyProvider client={ablyClient}>
      <div className="emp-service-provider-template-wrapper">
        <AgencyToBrandTransitionModal
          ref={agencyToBrandRef}
          onSave={() => {}}
        />
        <div className="emp-horizontal-navbar">
          <div className="top-bar-wrapper">
            <div className="top-bar-content">
              <div className="left-elems">
                <img
                  className="logo"
                  alt="emplifive logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
                <SearchBar />
              </div>
              <div className="right-elems">
                {user && (
                  <EmpNotification userType="organisation" user={user} />
                )}
                {user && (
                  <EmpProfileDropdownBtn
                    role={hasDualRole ? "Agency" : undefined}
                    user={user}
                    menuButtonSpecs={menuButtonSpecs}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="menu-items-wrapper">
            <div className="menu-items-content">
              <div ref={underlineElemRef} className="underline"></div>
              <div
                onClick={() => {
                  menuItemOnClick("dashboard");
                }}
                onAuxClick={() => {
                  window.open("/agency/home", "_blank");
                }}
                ref={(el) =>
                  (menuItemRef.current["dashboard"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "dashboard" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "dashboard" && (
                    <img
                      className="icon"
                      src={dashboardActiveImg}
                      alt="Dashboard"
                    />
                  )}
                  {selectedMenuTab !== "dashboard" && (
                    <img className="icon" src={dashboardImg} alt="Dashboard" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_dashboard" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("campaign");
                }}
                onAuxClick={() => {
                  window.open("/agency/campaigns-new", "_blank");
                }}
                ref={(el) =>
                  (menuItemRef.current["campaign"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "campaign" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "campaign" && (
                    <img
                      className="icon"
                      src={campaignActiveImg}
                      alt="Campaign"
                    />
                  )}
                  {selectedMenuTab !== "campaign" && (
                    <img className="icon" src={campaignImg} alt="Campaign" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_campaigns" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("creators");
                }}
                onAuxClick={() => {
                  window.open("/agency/creators", "_blank");
                }}
                ref={(el) =>
                  (menuItemRef.current["creators"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "creators" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "creators" && (
                    <img
                      className="icon"
                      src={creatorActiveImg}
                      alt="Creators"
                    />
                  )}
                  {selectedMenuTab !== "creators" && (
                    <img className="icon" src={creatorImg} alt="Creators" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_creators" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("my-profile");
                }}
                onAuxClick={() => {
                  window.open("/agency/profile", "_blank");
                }}
                ref={(el) =>
                  (menuItemRef.current["my-profile"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "my-profile" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "my-profile" && (
                    <img className="icon" src={orgActiveImg} alt="My Profile" />
                  )}
                  {selectedMenuTab !== "my-profile" && (
                    <img className="icon" src={orgImg} alt="My Profile" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_myAgency" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </AblyProvider>
  );
};
export default EmpAgencyTemplate;
