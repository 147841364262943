import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const BookMarkIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
        height: "fit-content",
      }}
    >
      <svg
        width={size}
        height={size + 2}
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.84778 0.396666C9.74617 0.248517 9.60936 0.140176 9.43762 0.0714492C9.32825 0.0239183 9.21362 0.000111119 9.0938 0.000111119V0H0.906203C0.786432 0 0.671915 0.0238072 0.562515 0.0713381C0.390671 0.140009 0.253907 0.248489 0.152361 0.396554C0.0508416 0.54462 0 0.708464 0 0.888282V11.1117C0 11.2916 0.0508416 11.4554 0.152361 11.6036C0.253907 11.7516 0.390671 11.8601 0.562515 11.9287C0.671806 11.9762 0.786432 12 0.906203 12C1.15108 12 1.36712 11.9127 1.55467 11.7384L5.00003 8.37531L8.44539 11.7385C8.62771 11.9074 8.84386 11.992 9.09377 11.992C9.22402 11.992 9.33862 11.971 9.43759 11.9287C9.60933 11.86 9.74615 11.7516 9.84775 11.6034C9.94927 11.4554 10 11.2915 10 11.1117V0.888393C10 0.708575 9.94919 0.544731 9.84778 0.396666ZM9.00005 10.8657L5.69537 7.64562L5.00003 6.97158L4.30474 7.64562L1.00009 10.8657V1.01532H9.00005V10.8657Z"
          fill={backgroundColor}
        />
      </svg>
    </div>
  );
};
export default BookMarkIcon;
