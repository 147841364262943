import { useNavigate } from "react-router-dom";
import useEmpGuard from "../../hooks/useEmpGuard";
import { AgencyKycView } from "./agency-kyc-view";
import { CreatorKycView } from "./creator-kyc-view";
import "./kyc-page.scss";

interface Props {
  role: "agency" | "brand" | "creator";
}

export const KycPage = (props: Props) => {
  const { role } = props;
  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);
  return (
    <>
      {role === "agency" && <AgencyKycView />}
      {role === "creator" && <CreatorKycView />}
    </>
  );
};
