import "./apply-task-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { UserDto } from "../../model/user-management/user.dto";
import UserApi from "../../api/user-msvc/user.api";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { TaskDto } from "../../model/campaign/task.dto";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";
import EmpLink from "../shared/emp-link/emp-link";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "./task-deliverable-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import RateCardReadonlyModal, {
  RateCardReadonlyModalRef,
} from "./rate-card-readonly-modal";
import { EmpSocialMediaPlatform } from "./rate-card-modal";
import { CreateTaskApplicationDto } from "../../model/campaign/create-task-application.dto";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import ToastUtils from "../../utilities/toast-utils";
import EmpSwitch from "../shared/emp-switch/emp-switch";
import { EmpCoinIcon } from "../shared/emp-coin-icon/emp-coin-icon";

export interface ApplyTaskModalRef {
  show: (
    task: TaskDto,
    mode: "creator" | "agency",
    user: UserDto,
    creatorId?: string
  ) => void;
  dismiss: () => void;
}

interface Props {
  onSave?: () => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

type ModalModeType = "creator" | "agency" | "none";

const ApplyTaskModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const modeRef = useRef<ModalModeType>("none");
  const [managedCreator, setManagedCreator] = useState<UserDto>();
  const [currentUser, setCurrentUser] = useState<UserDto>();
  const [task, setTask] = useState<TaskDto>();

  const rateCardReadonlyModalRef = useRef<RateCardReadonlyModalRef>();
  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();
  const submitBtnRef = useRef<EmpButtonRef>();

  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const [form, setForm] = useState<IFormGroup>({
    rate: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
    isFreeEngagement: new FormControl("boolean", [], false),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (
    task: TaskDto,
    mode: "creator" | "agency",
    user: UserDto,
    creatorId?: string
  ) => {
    setCurrentUser(user);
    setTask(task);
    modeRef.current = mode;
    if (mode === "agency") {
      if (!creatorId) {
        console.error("creator Id is mandatory if modal mode is 'agency'");
        return;
      }
      fetchManagedCreator(creatorId);
    }
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    FormGroupUtil.reset(form);
    setForm({ ...form });
    setManagedCreator(undefined);
    setCurrentUser(undefined);
    setTask(undefined);
  };

  const fetchManagedCreator = async (creatorId: string) => {
    try {
      const resp = await UserApi.fetchCreatorById(creatorId);
      setManagedCreator(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator specifications"
      );
    }
  };

  const onSubmit = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      if (!currentUser || !task || modeRef.current === "none") return;

      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const request: CreateTaskApplicationDto = {
        brandOrgId: task.organisation.id,
        creatorUserId:
          modeRef.current === "agency" ? managedCreator!.id : currentUser.id,
        campaignId: task.campaign.id,
        taskId: task.id,
        remarks: form.remarks.getValue(),
        agencyOrgId:
          modeRef.current === "agency"
            ? currentUser.organisation![0].id
            : undefined,
        representativeId:
          modeRef.current === "agency"
            ? currentUser.organisation![0].id
            : currentUser.id,
        representativeRole: modeRef.current,
        amount: form.rate.getValue(),
      };
      const resp = await NegotiationApi.createApplication(request);
      if (resp.status === "success") {
        ToastUtils.success("Applied", "We have received your application.");
        if (props.onSave) props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to submit application. Please try again later."
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        {task && (
          <div className="emp-apply-task-modal">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            <TaskDeliverableModal ref={taskDeliverableModalRef} />
            <RateCardReadonlyModal ref={rateCardReadonlyModalRef} />
            {modeRef.current === "creator" && currentUser && (
              <motion.div
                className="profile-photo-wrapper"
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 0.2, delay: 0 * animationDelay }}
              >
                {currentUser.imageType === "url" && (
                  <img
                    alt="Profile"
                    className="profile-photo"
                    src={currentUser.imageResource}
                  />
                )}
                {currentUser.imageType === "none" && (
                  <img
                    alt="Empty profile"
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                    }
                  />
                )}
                {currentUser.imageType === "avatar" && (
                  <div
                    style={{ background: currentUser.imageResource }}
                    className="avatar"
                  >
                    <span className="initials">{currentUser.initials}</span>
                  </div>
                )}
                <span className="plus-label">+</span>
                <img
                  alt="Campaign brand"
                  className="profile-photo org-photo"
                  src={task.organisation.logo}
                />
              </motion.div>
            )}

            {modeRef.current === "agency" && currentUser && managedCreator && (
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                className="profile-photo-wrapper"
                animate={"visible"}
                transition={{ duration: 0.2, delay: 0 * animationDelay }}
              >
                <div className="agency-photo-wrapper">
                  {managedCreator.imageType === "url" && (
                    <img
                      alt="Profile"
                      className="profile-photo"
                      src={managedCreator.imageResource}
                    />
                  )}
                  {managedCreator.imageType === "none" && (
                    <img
                      alt="Empty profile"
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {managedCreator.imageType === "avatar" && (
                    <div
                      style={{ background: managedCreator.imageResource }}
                      className="avatar"
                    >
                      <span className="initials">
                        {managedCreator.initials}
                      </span>
                    </div>
                  )}
                  <div className="agency-badge-wrapper">
                    <img
                      alt="Profile"
                      className="profile-photo org-photo"
                      src={currentUser.organisation![0].logo}
                    />
                  </div>
                </div>

                <span className="plus-label">+</span>
                <img
                  alt="Campaign brand"
                  className="profile-photo org-photo"
                  src={task.organisation.logo}
                />
              </motion.div>
            )}

            <div className="content-section">
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 1 * animationDelay,
                }}
                className="task-name-lbl"
              >
                Apply to join {task.name}
              </motion.span>
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
                className="description mt-4"
              >
                Please read the{" "}
                <EmpLink
                  onSubmit={() => {
                    taskDeliverableModalRef.current?.show(task);
                  }}
                  text={"deliverables"}
                />{" "}
                thoroughly before applying. You may refer to your{" "}
                <EmpLink
                  onSubmit={() => {
                    if (modeRef.current === "creator" && currentUser) {
                      rateCardReadonlyModalRef.current?.show(
                        currentUser.id,
                        task.platform as EmpSocialMediaPlatform
                      );
                    }
                    if (
                      modeRef.current === "agency" &&
                      managedCreator &&
                      currentUser
                    ) {
                      rateCardReadonlyModalRef.current?.show(
                        managedCreator.id,
                        task.platform as EmpSocialMediaPlatform,
                        currentUser.organisation![0].id
                      );
                    }
                  }}
                  text={"rate card"}
                />{" "}
                for a reasonable offer
              </motion.p>

              {/* <div className="modal-setting-item">
                <div className="switch-info">
                  <span className="switch-label">Free Engagement</span>
                  <p className="switch-description">
                    Some description of what paid engagement is
                  </p>
                </div>
                <div className="switch-wrapper">
                  <EmpSwitch
                    onChange={(fc) => {
                      // formControlOnChange(fc);
                    }}
                    id={"isFreeEngagement"}
                    formControl={form.isFreeEngagement}
                  />
                </div>
              </div>
              <article className="fence-card">
                <img
                  alt="paid feature"
                  src="https://cdn3d.iconscout.com/3d/premium/thumb/spark-11405296-9169400.png?f=webp"
                />
                <div className="info-wrapper">
                  <span className="block title">This is a paid feature!</span>
                  <p className="emp-paragraph mt-1">
                    You can send out <span className="emp-highlighted">8</span>{" "}
                    more free invitations to creator
                  </p>
                </div>
              </article> */}
              <motion.div
                variants={fadeInVariants}
                className="mt-4"
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
              >
                <EmpTextInput
                  id={"rate"}
                  labelText="Your Rate"
                  leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
                  description="If the brand is agreeable with the rate, you will be set to participate in this campaign task. "
                  required
                  formControl={form.rate}
                  placeholder={"00.00"}
                />
              </motion.div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className="mt-3"
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
              >
                <EmpTextInput
                  id={"remarks"}
                  labelText="Remarks (Optional)"
                  multiline
                  textAreaAdaptiveHeight
                  tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                  formControl={form.remarks}
                  rows={2}
                  characterCount={300}
                  placeholder={
                    "You may explain how you are a suitable candidate to execute this task."
                  }
                />
              </motion.div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className="mt-4"
                style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
                transition={{
                  duration: animationDuration,
                  delay: 4 * animationDelay,
                }}
              >
                <EmpButton
                  isFullWidth={false}
                  onSubmit={() => {
                    dismiss();
                  }}
                  buttonStyle="secondary"
                  text={"Cancel"}
                />

                <EmpButton
                  ref={submitBtnRef}
                  isFullWidth={false}
                  onSubmit={() => {
                    onSubmit();
                  }}
                  text={"Submit Application"}
                />
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ApplyTaskModal;
