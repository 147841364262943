import { useEffect, useState } from "react";
import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import "./brand-creators-listing-page.scss";
import { BrandCreatorsListingView } from "./brand-creators-listing-view";

export const BrandCreatorsListingPage = () => {
  const [tabs, setTabs] = useState<EmpTab[]>([
    {
      display: "Creators",
      text: "Creators",
      isSelected: false,
    },
    {
      display: "Agencies",
      text: "Agencies",
      isSelected: false,
      comingSoon: true,
      disabled: true,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    let fragment = window.location.hash.slice(1);
    setTabs((prevTabs) => {
      let modifiedTab = prevTabs;
      if (fragment) {
        fragment = fragment.replaceAll("%20", " ");
        const fragmentHash = prevTabs.find((elem) => elem.text === fragment);
        if (!fragmentHash)
          modifiedTab = prevTabs.map((tab, index) =>
            index === 0 ? { ...tab, isSelected: true } : tab
          );
        else fragmentHash.isSelected = true;
      } else {
        modifiedTab = prevTabs.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      }
      return [...modifiedTab];
    });
  }, []);

  return (
    <>
      <div className="emp-page-wrapper emp-responsive">
        <div className="bg-tint"></div>
        <div className="emp-page-content-no-padding emp-brand-creators-listing-page">
          <div className="header-wrapper header-space-between ">
            <h1 className="page-header">View Platform Creators</h1>
          </div>
          <EmpSecondaryTabBar
            tabs={tabs}
            onChange={(tabLabel: string) => {
              setSelectedTab(tabLabel);
              window.location.hash = tabLabel;
            }}
          />
          <div className="listing-view-wrapper">
            {selectedTab === "Creators" && <BrandCreatorsListingView />}
          </div>
        </div>
      </div>
    </>
  );
};
