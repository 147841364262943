import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import { NEGOTIATION_STATUS } from "../../../constants/app.constants";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import InfoCircleIcon from "../../icon/info-circle-icon";
import PlusIcon from "../../icon/plus-icon";
import TaskResponseModal, {
  TaskResponseModalRef,
} from "../../modals/task-response-modal";
import EmpButton from "../../shared/emp-button/emp-button";
import EmpLink from "../../shared/emp-link/emp-link";
import EmpLoader, { EmpLoaderRef } from "../../shared/emp-loader/emp-loader";
import EmpPill from "../../shared/EmpPill/EmpPill";
import "../emp-kanban.scss";
import "./recruitment-kanban.scss";
import { LatestNegotiationSingleDto } from "../../../model/campaign/latest-negotiation-single.dto";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task: TaskDto;
  brandLatestNegotiation: LatestNegotiationSingleDto;
  fetchLatestNegotiation: () => void;
}

type ClassifiedNegotiations = {
  applied: LatestNegotiationExtendedDto[];
  invited: LatestNegotiationExtendedDto[];
  rejected: LatestNegotiationExtendedDto[];
  negotiating: LatestNegotiationExtendedDto[];
  accepted: LatestNegotiationExtendedDto[];
  reviewDeliverables: LatestNegotiationExtendedDto[];
};

export interface BrandRecruitmentKanbanRef {
  setKanbanLoadingState: (show: boolean) => void;
}

export const BrandRecruitmentKanban = forwardRef((props: Props, ref) => {
  const { task, fetchLatestNegotiation } = props;
  const navigate = useNavigate();
  const taskResponseModalRef = useRef<TaskResponseModalRef>();

  const [latestNegotiations, setLatestNegotiations] = useState<
    LatestNegotiationExtendedDto[]
  >(props.brandLatestNegotiation.latestNegotiation);

  useEffect(() => {
    setLatestNegotiations(props.brandLatestNegotiation.latestNegotiation);
  }, [props.brandLatestNegotiation]);

  useImperativeHandle(ref, () => {
    return {
      setKanbanLoadingState,
    };
  });

  const empLoaderRef = useRef<EmpLoaderRef>();
  const setKanbanLoadingState = useCallback((show: boolean) => {
    if (show) {
      empLoaderRef.current?.show();
    } else {
      empLoaderRef.current?.hide();
    }
  }, []);

  const [classifiedNegotiations, setClassifiedNegotiations] =
    useState<ClassifiedNegotiations>({
      applied: [],
      invited: [],
      rejected: [],
      negotiating: [],
      accepted: [],
      reviewDeliverables: [],
    });

  useEffect(() => {
    // Splitting the cards.
    if (!props.brandLatestNegotiation.hasNegotiation) {
      setClassifiedNegotiations({
        applied: [],
        invited: [],
        rejected: [],
        negotiating: [],
        accepted: [],
        reviewDeliverables: [],
      });
      return;
    }
    const myClassifiedNegotiations: ClassifiedNegotiations = {
      invited: latestNegotiations.filter(
        (elem) => elem.status === NEGOTIATION_STATUS.INVITED
      ),
      applied: latestNegotiations.filter(
        (elem) => elem.status === NEGOTIATION_STATUS.APPLIED
      ),
      rejected: latestNegotiations.filter((elem) =>
        [
          NEGOTIATION_STATUS.DUP_REJECT,
          NEGOTIATION_STATUS.BRAND_REJECT,
          NEGOTIATION_STATUS.SELLER_REJECT,
        ].includes(elem.status)
      ),
      negotiating: latestNegotiations.filter(
        (elem) =>
          elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE ||
          elem.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE
      ),
      accepted: latestNegotiations.filter(
        (elem) =>
          elem.status === NEGOTIATION_STATUS.SELLER_ACCEPT ||
          elem.status === NEGOTIATION_STATUS.BRAND_ACCEPT
      ),
      reviewDeliverables: latestNegotiations.filter(
        (elem) => elem.status === NEGOTIATION_STATUS.REVIEW_DELIVERABLES
      ),
    };
    setClassifiedNegotiations(myClassifiedNegotiations);
  }, [latestNegotiations]);

  return (
    <div className="emp-kanban">
      <TaskResponseModal
        onSave={fetchLatestNegotiation}
        ref={taskResponseModalRef}
      />
      <EmpLoader ref={empLoaderRef} background="transparent" />
      <div className="kanban-body-container">
        <div className="kanban-column">
          <div className="kanban-header">
            <span className="title-lbl">
              Invited
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    Invite your creators to participate in the campaign task,
                    view their rate cards and suggest how much you'd like to pay
                    them for their services
                  </Tooltip>
                }
              >
                <div className="emp-tooltip-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={14}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                </div>
              </Whisper>
            </span>
            <div
              className="plus-icon-hidden-placeholder"
              onClick={() => {
                navigate(
                  `/brand/task-recruitment/${task.campaign.id}/task/${task.id}`
                );
              }}
            >
              <div className="plus-icon-wrapper">
                <PlusIcon backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </div>
          </div>
          {classifiedNegotiations.invited.length === 0 && (
            <div className="empty-state-wrapper">
              <img
                alt="Empty column"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
              />
              <span className="empty-state-title">
                No Invited Creators or Agencies
              </span>
              <p className="empty-state-description">
                Invite Creators or Agencies to join your task.
              </p>
              <EmpButton
                buttonHeight="sm"
                isFullWidth={false}
                text={"Invite Creators"}
                onSubmit={() => {
                  navigate(
                    `/brand/task-recruitment/${task.campaign.id}/task/${task.id}`
                  );
                }}
              />
            </div>
          )}
          {classifiedNegotiations.invited.length > 0 &&
            classifiedNegotiations.invited.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                >
                  <div className="profile-section">
                    <div className="image-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                    <div className="details-wrapper">
                      <div className="name-lbl">
                        {elem.creatorRepresentative.fullName}
                      </div>
                      <div className="representative-wrapper">
                        {elem.representativeRole === "creator" && "Creator"}
                        {elem.representativeRole === "agency" &&
                          elem.agencyRepresentative.companyName}
                        <EmpPill
                          {...PILL_COLORS.primary}
                          text={
                            elem.representativeRole === "agency"
                              ? "Agency"
                              : "Creator"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <p className="kanban-description mt-2">
                    You have invited this creator to join this task for{" "}
                    <span className="emp-highlighted">
                      ${elem.amount}{" "}
                      <EmpCoinIcon
                        iconValue={task.paymentMode}
                        mode="icon-only"
                      />
                    </span>
                  </p>
                  <div className="mt-1">
                    <EmpPill
                      {...PILL_COLORS.gray}
                      size={"sm"}
                      text={DateUtil.toReadableDate(elem.createdDate)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="kanban-column">
          <div className="kanban-header">
            <span className="title-lbl">
              Applied
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    {" "}
                    Your creators are confirmed and ready to start the task,
                    once you are ready, get started by making payment first!
                  </Tooltip>
                }
              >
                <div className="emp-tooltip-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={14}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                </div>
              </Whisper>
            </span>
          </div>
          {classifiedNegotiations.applied.length === 0 && (
            <div className="empty-state-wrapper">
              <img
                alt="Empty column"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
              />
              <span className="empty-state-title">
                No Applied Creators or Agencies
              </span>
              <p className="empty-state-description">
                There are no creators or agencies that has applied to your task
                on their own.
              </p>
            </div>
          )}
          {classifiedNegotiations.applied.length > 0 &&
            classifiedNegotiations.applied.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                >
                  <div className="profile-section">
                    <div className="image-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                    <div className="details-wrapper">
                      <div className="name-lbl">
                        {elem.creatorRepresentative.fullName}
                      </div>
                      <div className="representative-wrapper">
                        {elem.representativeRole === "creator" && "Creator"}
                        {elem.representativeRole === "agency" &&
                          elem.agencyRepresentative.companyName}
                        <EmpPill
                          {...PILL_COLORS.primary}
                          text={
                            elem.representativeRole === "agency"
                              ? "Agency"
                              : "Creator"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {elem.representativeRole === "agency" && (
                    <p className="kanban-description mt-2">
                      {elem.agencyRepresentative.companyName} has applied on
                      behalf of &nbsp;
                      <EmpLink
                        text={elem.creatorRepresentative.fullName}
                        onSubmit={() => {
                          navigate(
                            `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                          );
                        }}
                      />{" "}
                      to join this task for{" "}
                      <span className="emp-highlighted">
                        ${elem.amount}{" "}
                        <EmpCoinIcon
                          iconValue={task.paymentMode}
                          mode="icon-only"
                        />
                      </span>
                    </p>
                  )}
                  {elem.representativeRole === "creator" && (
                    <p className="kanban-description mt-2">
                      <EmpLink
                        newTab
                        text={elem.creatorRepresentative.fullName}
                        onSubmit={() => {
                          navigate(
                            `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                          );
                        }}
                      />{" "}
                      has applied to join this task for{" "}
                      <span className="emp-highlighted">
                        ${elem.amount}{" "}
                        <EmpCoinIcon
                          iconValue={task.paymentMode}
                          mode="icon-only"
                        />
                      </span>
                    </p>
                  )}
                  <div className="mt-2 actions-wrapper">
                    <EmpPill
                      {...PILL_COLORS.gray}
                      size={"sm"}
                      text={DateUtil.toReadableDate(elem.createdDate)}
                    />
                    <EmpButton
                      isFullWidth={false}
                      text={"View"}
                      buttonHeight="sm"
                      onSubmit={() => {
                        taskResponseModalRef.current?.show(
                          task,
                          [elem],
                          "application",
                          "brand"
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="kanban-column">
          <div className="kanban-header">
            <span className="title-lbl">
              Rejected
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    {" "}
                    Your creators are confirmed and ready to start the task,
                    once you are ready, get started by making payment first!
                  </Tooltip>
                }
              >
                <div className="emp-tooltip-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={14}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                </div>
              </Whisper>
            </span>
          </div>
          <div className="vertical-spacer"></div>
          {classifiedNegotiations.rejected.length === 0 && (
            <div className="empty-state-wrapper">
              <img
                alt="Empty column"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
              />
              <span className="empty-state-title">No Rejections</span>
              <p className="empty-state-description">
                You, creators, or agencies have not rejected the offer to take
                part in this task.
              </p>
            </div>
          )}
          {classifiedNegotiations.rejected.length > 0 &&
            classifiedNegotiations.rejected.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                >
                  <div className="profile-section">
                    <div className="image-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                    <div className="details-wrapper">
                      <div className="name-lbl">
                        {elem.creatorRepresentative.fullName}
                      </div>
                      <div className="representative-wrapper">
                        {elem.representativeRole === "creator" && "Creator"}
                        {elem.representativeRole === "agency" &&
                          elem.agencyRepresentative.companyName}
                        <EmpPill
                          {...PILL_COLORS.primary}
                          text={
                            elem.representativeRole === "agency"
                              ? "Agency"
                              : "Creator"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {elem.status === NEGOTIATION_STATUS.DUP_REJECT && (
                    <p className="kanban-description mt-2">
                      System has rejected this offer since{" "}
                      <EmpLink
                        text={elem.creatorRepresentative.fullName}
                        onSubmit={() => {
                          navigate(
                            `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                          );
                        }}
                      />{" "}
                      will be participating in this task.
                    </p>
                  )}
                  {elem.status === NEGOTIATION_STATUS.SELLER_REJECT &&
                    elem.representativeRole === "agency" && (
                      <p className="kanban-description mt-2">
                        {" "}
                        Agency {elem.agencyRepresentative.companyName} has
                        rejected your offer for{" "}
                        <EmpLink
                          text={elem.creatorRepresentative.fullName}
                          onSubmit={() => {
                            navigate(
                              `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                            );
                          }}
                        />{" "}
                        to join this task.
                      </p>
                    )}
                  {elem.status === NEGOTIATION_STATUS.SELLER_REJECT &&
                    elem.representativeRole === "creator" && (
                      <p className="kanban-description mt-2">
                        {" "}
                        <EmpLink
                          text={elem.creatorRepresentative.fullName}
                          onSubmit={() => {
                            navigate(
                              `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                            );
                          }}
                        />{" "}
                        has rejected your offer to join this task.
                      </p>
                    )}
                  {elem.status === NEGOTIATION_STATUS.BRAND_REJECT && (
                    <p className="kanban-description mt-2">
                      You have rejected{" "}
                      <EmpLink
                        text={elem.creatorRepresentative.fullName}
                        onSubmit={() => {
                          navigate(
                            `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                          );
                        }}
                      />{" "}
                      offer to join this task.
                    </p>
                  )}

                  <div className="mt-1">
                    <EmpPill
                      {...PILL_COLORS.gray}
                      size={"sm"}
                      text={DateUtil.toReadableDate(elem.createdDate)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="kanban-column">
          <div className="kanban-header">
            <span className="title-lbl">
              Counteroffers
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    {" "}
                    Your creators are confirmed and ready to start the task,
                    once you are ready, get started by making payment first!
                  </Tooltip>
                }
              >
                <div className="emp-tooltip-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={14}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                </div>
              </Whisper>
            </span>
          </div>
          <div className="vertical-spacer"></div>
          {classifiedNegotiations.negotiating.length === 0 && (
            <div className="empty-state-wrapper">
              <img
                alt="Empty column"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
              />
              <span className="empty-state-title">No Counteroffers</span>
              <p className="empty-state-description">
                You, creators, or agencies have not renegotiated on an offer to
                take part in this task
              </p>
            </div>
          )}
          {classifiedNegotiations.negotiating.length > 0 &&
            classifiedNegotiations.negotiating.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                >
                  <div className="profile-section">
                    <div className="image-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                    <div className="details-wrapper">
                      <div className="name-lbl">
                        {elem.creatorRepresentative.fullName}
                      </div>
                      <div className="representative-wrapper">
                        {elem.representativeRole === "creator" && "Creator"}
                        {elem.representativeRole === "agency" &&
                          elem.agencyRepresentative.companyName}
                        <EmpPill
                          {...PILL_COLORS.primary}
                          text={
                            elem.representativeRole === "agency"
                              ? "Agency"
                              : "Creator"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE &&
                    elem.representativeRole === "creator" && (
                      <p className="kanban-description mt-2">
                        <EmpLink
                          text={elem.creatorRepresentative.fullName}
                          onSubmit={() => {
                            navigate(
                              `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                            );
                          }}
                        />{" "}
                        has sent a counteroffer of{" "}
                        <span className="emp-highlighted">
                          ${elem.amount}{" "}
                          <EmpCoinIcon
                            iconValue={task.paymentMode}
                            mode="icon-only"
                          />
                        </span>
                        .
                      </p>
                    )}
                  {elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE &&
                    elem.representativeRole === "agency" && (
                      <p className="kanban-description mt-2">
                        Agency {elem.agencyRepresentative.companyName} has sent
                        a counteroffer of{" "}
                        <span className="emp-highlighted">
                          ${elem.amount}{" "}
                          <EmpCoinIcon
                            iconValue={task.paymentMode}
                            mode="icon-only"
                          />
                        </span>
                        .
                      </p>
                    )}
                  {elem.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE &&
                    elem.representativeRole === "creator" && (
                      <p className="kanban-description mt-2">
                        You have sent a counteroffer to{" "}
                        <EmpLink
                          text={elem.creatorRepresentative.fullName}
                          onSubmit={() => {
                            navigate(
                              `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                            );
                          }}
                        />{" "}
                        for{" "}
                        <span className="emp-highlighted">
                          ${elem.amount}{" "}
                          <EmpCoinIcon
                            iconValue={task.paymentMode}
                            mode="icon-only"
                          />
                        </span>
                        .
                      </p>
                    )}
                  {elem.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE &&
                    elem.representativeRole === "agency" && (
                      <p className="kanban-description mt-2">
                        You have sent a counteroffer to{" "}
                        {elem.agencyRepresentative.companyName} for{" "}
                        <EmpLink
                          text={elem.creatorRepresentative.fullName}
                          onSubmit={() => {
                            navigate(
                              `/brand/creator-invitation/${elem.creatorUserId}/campaign/${task.campaign.id}/task/${task.id}`
                            );
                          }}
                        />{" "}
                        to join the task for{" "}
                        <span className="emp-highlighted">
                          ${elem.amount}{" "}
                          <EmpCoinIcon
                            iconValue={task.paymentMode}
                            mode="icon-only"
                          />
                        </span>
                        .
                      </p>
                    )}
                  <div className="mt-2 actions-wrapper">
                    <EmpPill
                      {...PILL_COLORS.gray}
                      size={"sm"}
                      text={DateUtil.toReadableDate(elem.createdDate)}
                    />
                    {elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE && (
                      <EmpButton
                        isFullWidth={false}
                        text={"View"}
                        buttonHeight="sm"
                        onSubmit={() => {
                          taskResponseModalRef.current?.show(
                            task,
                            [elem],
                            "counteroffer",
                            "brand"
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {/* Deliverable Review Columns */}
        {classifiedNegotiations.reviewDeliverables.length > 0 && (
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Review Deliverable
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Since you have updated your deliverable after creator's
                      acceptance, the creator must review the changes and
                      confirm whether to continue with the task.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.reviewDeliverables.length > 0 &&
              classifiedNegotiations.reviewDeliverables.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creatorRepresentative.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creatorRepresentative.fullName}
                            src={elem.creatorRepresentative.imageResource}
                          />
                        )}
                        {elem.creatorRepresentative.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{
                              background:
                                elem.creatorRepresentative.imageResource,
                            }}
                          >
                            <span className="initials">
                              {elem.creatorRepresentative.initials}
                            </span>
                          </div>
                        )}
                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">
                          {elem.creatorRepresentative.fullName}
                        </div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            {...PILL_COLORS.primary}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <p className="kanban-description mt-2">
                      Awaiting the{" "}
                      <EmpLink text={elem.creatorRepresentative.fullName} /> to
                      accept the revised deliverables for{" "}
                      <span className="emp-highlighted">
                        ${elem.amount}{" "}
                        <EmpCoinIcon
                          iconValue={task.paymentMode}
                          mode="icon-only"
                        />
                      </span>
                      .
                    </p>
                    <div className="mt-2 actions-wrapper">
                      <EmpPill
                        {...PILL_COLORS.gray}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        <div className="kanban-column">
          <div className="kanban-header">
            <span className="title-lbl">
              Ready to start
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    {" "}
                    Your creators are confirmed and ready to start the task,
                    once you are ready, get started by making payment first!
                  </Tooltip>
                }
              >
                <div className="emp-tooltip-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={14}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                </div>
              </Whisper>
            </span>
          </div>
          <div className="vertical-spacer"></div>
          {classifiedNegotiations.accepted.length === 0 && (
            <div className="empty-state-wrapper">
              <img
                alt="Empty column"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
              />
              <span className="empty-state-title">
                No Creators ready to start
              </span>
              <p className="empty-state-description">
                You, creators, or agencies have accepted an offer to take part
                in this task
              </p>
            </div>
          )}
          {classifiedNegotiations.accepted.length > 0 &&
            classifiedNegotiations.accepted.map((elem, index) => {
              return (
                <div
                  key={elem.id}
                  className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                >
                  <div className="profile-section">
                    <div className="image-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>
                    <div className="details-wrapper">
                      <div className="name-lbl">
                        {elem.creatorRepresentative.fullName}
                      </div>
                      <div className="representative-wrapper">
                        {elem.representativeRole === "creator" && "Creator"}
                        {elem.representativeRole === "agency" &&
                          elem.agencyRepresentative.companyName}
                        <EmpPill
                          {...PILL_COLORS.primary}
                          text={
                            elem.representativeRole === "agency"
                              ? "Agency"
                              : "Creator"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <p className="kanban-description mt-2">
                    This creator is ready to start on this task for{" "}
                    <span className="emp-highlighted">
                      ${elem.amount}{" "}
                      <EmpCoinIcon
                        iconValue={task.paymentMode}
                        mode="icon-only"
                      />
                    </span>
                  </p>
                  <div className="mt-2 actions-wrapper">
                    <EmpPill
                      {...PILL_COLORS.gray}
                      size={"sm"}
                      text={DateUtil.toReadableDate(elem.createdDate)}
                    />
                    <EmpButton
                      isFullWidth={false}
                      text={"Amend Price"}
                      buttonHeight="sm"
                      onSubmit={() => {
                        taskResponseModalRef.current?.show(
                          task,
                          [elem],
                          "counteroffer-only",
                          "brand"
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});
