import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const AudioIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_2)">
          <path
            d="M23.5931 0.278392C23.335 0.0578455 22.9939 -0.0388108 22.6575 0.0142517L7.94789 2.33681C7.38352 2.42592 6.96778 2.91244 6.96778 3.48385V8.12902V15.4838H4.64517C2.08383 15.4839 0 17.394 0 19.7419C0 22.0898 2.08383 24 4.64517 24C7.20652 24 9.29034 22.0898 9.29034 19.7419V16.6451V9.12131L21.6774 7.16545V13.5484H19.3549C16.7935 13.5484 14.7097 15.4585 14.7097 17.8065C14.7097 20.1544 16.7935 22.0645 19.3549 22.0645C21.9162 22.0645 24 20.1543 24 17.8065V14.7097V5.80646V1.16128C24 0.821674 23.8513 0.499033 23.5931 0.278392Z"
            fill={backgroundColor}
          />
        </g>
      </svg>
    </div>
  );
};
export default AudioIcon;
