import { EmpTableEmptyRow } from "../components/shared/EmpTable/EmpTable";

const EmptyRowUtils = {
  getEmptyRow(number: number): EmpTableEmptyRow[] {
    const results: EmpTableEmptyRow[] = [];
    for (let i = 0; i < number; i++) {
      results.push({
        type: "emp-empty-row",
        rowKey: `${i}`,
      });
    }
    return results;
  },
};
export default EmptyRowUtils;
