import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CreateEmailOtpByEmailDto } from "../../model/email-otp/create-email-otp-by-email-req.dto";
import { EmailOtpByEmailReqDto } from "../../model/email-otp/email-otp-by-email-req.dto";
import { CheckVerificationStatusReqDto } from "../../model/onboarding/check-verification-status-req.dto";
import { EmailOtpReqDto } from "../../model/onboarding/email-otp-req.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const EmailOtpAPI = {
  verifyEmailOtp: async (
    request: EmailOtpReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.emailOtp.verifyEmailOtp,
      request
    );
    return response.data;
  },

  requestEmailOtp: async (
    request: CheckVerificationStatusReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.emailOtp.requestEmailOtp,
      request
    );
    return response.data;
  },

  verifyEmailOtpByEmail: async (
    request: EmailOtpByEmailReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.emailOtp.verifyEmailOtpByEmail,
      request
    );
    return response.data;
  },

  requestEmailOtpByEmail: async (
    request: CreateEmailOtpByEmailDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.emailOtp.requestEmailOtpByEmail,
      request
    );
    return response.data;
  },
};
export default EmailOtpAPI;
