import { useCallback, useContext, useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import UserApi from "../api/user-msvc/user.api";
import { AppContext } from "../context/app.context";
import { AUTH_CUSTOM_STATES } from "../constants/app.constants";
import { useNavigate } from "react-router-dom";
import { PermsUtils } from "../constants/permissions.constants";
import { UserDto } from "../model/user-management/user.dto";
import ToastUtils from "../utilities/toast-utils";
import EmpException from "../exception/empException";
import useOnboarding from "./useOnboarding";
import SupportApi from "../api/referral-and-support-msvc/support.api";
import EmpExceptionHandler from "../utilities/errorUtils/empExceptionHandler";
import { ApiStatus } from "../model/api/api-status";
import CreatorInfoApi from "../api/user-msvc/creator-info.api";
import OrganisationApi from "../api/user-msvc/organisation.api";

interface CustomStateInfo {
  origin: string;
  referralCode: string;
  onboardId: string;
  managedCreatorOrgId: string;
  invitationId: string;
}

const useEmpAuthListener = () => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [signedInCalled, setSignedInCalled] = useState(false);
  const [customOAuthState, setCustomOAuthState] = useState<string>();

  const { mapUserOnboarding } = useOnboarding();

  /**
   * Navigates to the onboarding path if the user is unassigned, otherwise navigates to the initial route.
   *
   * @function
   * @param {UserDto} user - The user object.
   * @param {string} onboardingPath - The onboarding path to navigate to.
   */
  const navigateToOnboardingIfUnassigned = useCallback(
    (user: UserDto, onboardingPath: string) => {
      if (user.role === "unknown" || user.userOnboardingState !== "COMPLETED") {
        navigate(onboardingPath);
      } else {
        const redirectUrl = PermsUtils.initialRoute(user);
        navigate(redirectUrl);
      }
    },
    [navigate]
  );

  /**
   * Sets up SSO for a managed creator using the invitation ID.
   *
   * @async
   * @function
   * @param {string} invitationId - The ID of the invitation.
   * @returns {Promise<ApiStatus>} - A promise that resolves to the API status.
   * @throws {EmpException} - Throws an EmpException if the setup fails.
   */
  const setupSSOManagedCreator = useCallback(
    async (invitationId: string): Promise<ApiStatus> => {
      try {
        const resp = await CreatorInfoApi.setupSSOManagedCreator(invitationId);
        return resp.data;
      } catch (err) {
        console.error("Failed to setup SSO Managed Creator");
        throw new EmpException("Failed to setup SSO Managed Creator");
      }
    },
    []
  );

  /**
   * Sets up SSO for an invited organization member using the invitation ID.
   *
   * @async
   * @function
   * @param {string} invitationId - The ID of the invitation.
   * @returns {Promise<ApiStatus>} - A promise that resolves to the API status.
   * @throws {EmpException} - Throws an EmpException if the setup fails.
   */
  const setupSSOInvitedOrgMember = useCallback(
    async (invitationId: string): Promise<ApiStatus> => {
      try {
        const resp = await OrganisationApi.setupOnboardedOrgMemberInvitation(
          invitationId
        );
        return resp.data;
      } catch (err) {
        console.error("Failed to setup SSO Managed Creator");
        throw new EmpException("Failed to setup SSO Managed Creator");
      }
    },
    []
  );
  /**
   * Consumes the managed creator invitation for the given organization ID.
   *
   * @async
   * @function
   * @param {string} organisationId - The ID of the organization.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const consumeManagedCreatorInvitation = useCallback(
    async (organisationId: string) => {
      try {
        const resp = await SupportApi.setupAgencyInvitedCreatorReferral({
          organisationId,
        });
        if (resp.data.status === "success") {
          ToastUtils.success(
            "Association Complete",
            `You have been associated with your agency.`
          );
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Agency Association Failed"
        );
      }
    },
    []
  );
  /**
   * Fetches the user and sets the user state.
   *
   * @async
   * @function
   * @returns {Promise<UserDto>} - A promise that resolves to the user object.
   */
  const fetchAndSetUser = useCallback(async () => {
    const userResp = await UserApi.fetchUser();
    const user = userResp.data;
    if (setUser) setUser(user);
    return user;
  }, [setUser]);

  /**
   * Handles the SSO for a managed creator.
   *
   * @async
   * @function
   * @param {CustomStateInfo} customStateObj - The custom state object.
   * @param {UserDto} user - The user object.
   * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the operation was successful.
   */
  const handleManagedCreatorSSO = useCallback(
    async (customStateObj: CustomStateInfo, user: UserDto) => {
      if (
        customStateObj?.origin === AUTH_CUSTOM_STATES.MANAGED_CREATOR_SSO &&
        customStateObj?.managedCreatorOrgId &&
        customStateObj?.invitationId
      ) {
        const apiResp = await setupSSOManagedCreator(
          customStateObj.invitationId
        );
        if (apiResp.status === "success") {
          const userResp = await UserApi.fetchUser();
          if (setUser) setUser(userResp.data);
          await consumeManagedCreatorInvitation(
            customStateObj.managedCreatorOrgId
          );
          navigate("/creator/onboard");
          return true;
        } else if (
          apiResp.status === "error" &&
          apiResp.statusMessage === "role-already-assigned"
        ) {
          ToastUtils.success("Account Exists!", "Welcome to Emplifive.");
          navigate(PermsUtils.initialRoute(user));
          return true;
        }
      }
      return false;
    },
    [consumeManagedCreatorInvitation, navigate, setupSSOManagedCreator, setUser]
  );

  /**
   * Handles the SSO for an invited organization member.
   *
   * @async
   * @function
   * @param {CustomStateInfo} customStateObj - The custom state object.
   * @param {UserDto} user - The user object.
   * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the operation was successful.
   */
  const handleOrgInvitationSSO = useCallback(
    async (customStateObj: CustomStateInfo, user: UserDto) => {
      if (
        customStateObj?.origin === AUTH_CUSTOM_STATES.ORG_INVITATION_SSO &&
        customStateObj?.invitationId
      ) {
        const apiResp = await setupSSOInvitedOrgMember(
          customStateObj.invitationId
        );
        if (apiResp.status === "success") {
          console.log("Successfully processed org invitation record");
          const userResp = await UserApi.fetchUser();
          if (setUser) setUser(userResp.data);
          navigate(PermsUtils.initialRoute(userResp.data));
          return true;
        } else if (
          apiResp.status === "error" &&
          apiResp.statusMessage === "role-already-assigned"
        ) {
          ToastUtils.success("Account Exists!", "Welcome to Emplifive.");
          navigate(PermsUtils.initialRoute(user));
          return true;
        }
      }
      return false;
    },
    [navigate, setUser, setupSSOInvitedOrgMember]
  );

  /**
   * Handles the redirection logic based on the custom state and user object.
   *
   * @function
   * @param {CustomStateInfo} customStateObj - The custom state object.
   * @param {UserDto} user - The user object.
   */
  const handleRedirection = useCallback(
    (customStateObj: CustomStateInfo, user: UserDto) => {
      if (
        [AUTH_CUSTOM_STATES.CREATOR_SSO].includes(customStateObj?.origin ?? "")
      ) {
        navigateToOnboardingIfUnassigned(user, "/creator/onboard");
      } else if (customStateObj?.origin === AUTH_CUSTOM_STATES.BRAND_SSO) {
        navigateToOnboardingIfUnassigned(user, "/brand/onboard");
      } else if (customStateObj?.origin === AUTH_CUSTOM_STATES.AGENCY_SSO) {
        navigateToOnboardingIfUnassigned(
          user,
          `/agency/onboard?onboardingId=${customStateObj.onboardId ?? ""}`
        );
      } else {
        navigate(PermsUtils.initialRoute(user));
      }
    },
    [navigate, navigateToOnboardingIfUnassigned]
  );

  /**
   * Handles the sign-in process, including fetching the user, handling custom states, and redirection.
   *
   * @async
   * @function
   * @param {string} [customState] - The custom state string.
   */
  const handleSignIn = useCallback(
    async (customState?: string) => {
      try {
        const user = await fetchAndSetUser();
        const customStateObj = customState
          ? JSON.parse(customState)
          : undefined;

        if (customStateObj?.onboardId || customStateObj?.referralCode) {
          await mapUserOnboarding(
            customStateObj.onboardId,
            customStateObj.referralCode
          );
        }
        if (await handleManagedCreatorSSO(customStateObj, user)) return;
        if (await handleOrgInvitationSSO(customStateObj, user)) return;

        handleRedirection(customStateObj, user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    [
      fetchAndSetUser,
      handleManagedCreatorSSO,
      handleOrgInvitationSSO,
      handleRedirection,
      mapUserOnboarding,
    ]
  );

  useEffect(() => {
    const authListener = ({ payload }: { payload: any }) => {
      switch (payload.event) {
        case "signedIn":
          console.log("[Amplify] User signed in", payload);
          setSignedInCalled(true);
          break;
        case "signedOut":
          console.log("[Amplify] User signed out");
          setSignedInCalled(false);
          setCustomOAuthState(undefined);
          break;
        case "signInWithRedirect_failure":
          console.log(
            "failure while trying to resolve signInWithRedirect API."
          );
          break;
        case "customOAuthState":
          console.log(
            "[Amplify] User signed in via custom oauth state",
            payload
          );
          setCustomOAuthState(payload.data);
          break;
      }
    };
    const cancelAuthHubListener = Hub.listen("auth", authListener);

    return () => {
      cancelAuthHubListener();
    };
  }, []);

  useEffect(() => {
    if (signedInCalled) {
      setTimeout(() => {
        if (customOAuthState) {
          handleSignIn(customOAuthState);
        } else {
          handleSignIn();
        }
        setSignedInCalled(false);
        setCustomOAuthState(undefined);
      }, 500); // Adjust the time window as needed
    }
  }, [signedInCalled, customOAuthState, handleSignIn]);
};

export default useEmpAuthListener;
