import { useEffect, useRef, useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import InfoCircleIcon from "../../../components/icon/info-circle-icon";
import RefreshIcon from "../../../components/icon/refresh-icon";
import {
  SellerOngoingKanban,
  SellerOngoingKanbanRef,
} from "../../../components/kanban/recruitment-kanban/seller-ongoing-kanban";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import "./seller-task-ongoing-progress-wall-view.scss";

interface Props {
  task: TaskDto;
  fetchTask: () => void;
  toDraft: () => void;
}

export const SellerTaskOngoingProgressWall = (props: Props) => {
  const { task: taskProp, toDraft } = props;
  const [selectedDeliverableId, setSelectedDeliverableId] = useState<string>(
    taskProp.deliverables[0].id
  );
  const [task, setTask] = useState<TaskDto>();
  const kanbanRef = useRef<SellerOngoingKanbanRef>();

  // Wrapper element to compute expandable size
  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
    }
  }, [taskProp]);

  return (
    <div className="emp-seller-task-ongoing-progress-wall-view">
      {task && (
        <>
          <section className="task-details-section">
            <div className="task-name-wrapper">
              <div className="info-wrapper">
                <h2 className="section-label">Deliverable Progress Wall </h2>
                <EmpIconButton
                  onSubmit={() => {
                    kanbanRef.current?.fetchTaskNegotiations();
                  }}
                  buttonStyle={"secondary"}
                  icon={<RefreshIcon backgroundColor={Color.NEUTRAL[500]} />}
                />
              </div>
            </div>
          </section>
          <div className="tabbed-option-wrapper mb-2">
            {task.deliverables.map((elem, index) => {
              return (
                <div
                  onClick={() => {
                    if (selectedDeliverableId === elem.id) return;
                    setSelectedDeliverableId(elem.id);
                  }}
                  key={elem.id}
                  className={`tabbed-option ${selectedDeliverableId === elem.id ? "active" : ""}`}
                >
                  <span>Deliverable {index + 1}</span>

                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>{elem.description}</Tooltip>}
                  >
                    <div className="emp-tooltip-wrapper">
                      <InfoCircleIcon
                        strokeWidth={2.5}
                        size={14}
                        backgroundColor={
                          selectedDeliverableId === elem.id
                            ? Color.NEUTRAL[600]
                            : Color.NEUTRAL[400]
                        }
                      />
                    </div>
                  </Whisper>
                </div>
              );
            })}
          </div>
          <section className="kanban-section">
            <SellerOngoingKanban
              toDraft={toDraft}
              deliverableId={selectedDeliverableId}
              task={task}
              ref={kanbanRef}
            />
          </section>
        </>
      )}
    </div>
  );
};
