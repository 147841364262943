import "./emp-date-picker.scss";

import { forwardRef, useEffect, useState } from "react";
import { DatePicker, DatePickerProps, Tooltip, Whisper } from "rsuite";

import { DisabledDateFunction } from "rsuite/esm/DateRangePicker";
import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import InfoCircleIcon from "../../icon/info-circle-icon";

interface Props extends Omit<DatePickerProps, "disabledDate" | "onChange"> {
  label?: string;
  isRequired?: boolean;
  formControl: FormControl;
  placeholder: string;
  onChange?: (values: FormControl) => void;
  disabledDate?: (() => DisabledDateFunction) | undefined;
  hasSearch?: boolean;
  hasTime?: boolean;
  tooltip?: string;
}

export interface EmpDatePickerRef {
  reset: () => void;
}

const EmpDatePicker = forwardRef((props: Props, ref) => {
  const {
    label,
    isRequired = false,
    tooltip: tooltipProp = undefined,
    placeholder,
    onChange,
    formControl,
    disabledDate,
    hasTime = false,
    hasSearch,
    onOpen,
    ...rest
  } = props;
  const [value, setValue] = useState<Date | null>();
  const tooltip = tooltipProp ? <Tooltip>{tooltipProp}</Tooltip> : <></>;

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  // Initialize the component when values has already been pre-populated.
  useEffect(() => {
    setValue(
      formControl.getValue()?.length === 0 || formControl.getValue() === null
        ? null
        : new Date(formControl.getValue())
    );
  }, [formControl, formControl.value]);

  return (
    <div>
      {label && (
        <label>
          {label}
          {isRequired && <span className="required">*</span>}
          {tooltipProp && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={tooltip}
            >
              <div className="emp-tooltip-wrapper">
                <InfoCircleIcon
                  size={14}
                  backgroundColor={Color.NEUTRAL[500]}
                />
              </div>
            </Whisper>
          )}
        </label>
      )}
      <DatePicker
        block
        onChange={(value: Date | null) => {
          if (value === null) {
            formControl.setValue(null);
            setValue(null);
          }
          formControl.setValue(value);
          setValue(value!);
          if (onChange) onChange(formControl);
        }}
        onClean={() => {
          formControl.setValue(null);
          setValue(null);
          if (onChange) onChange(formControl);
        }}
        value={value}
        oneTap
        format={hasTime ? "MM dd yyyy - HH:mm aa" : "dd MMM yyyy"}
        showMeridian
        placement="topStart"
        appearance="default"
        shouldDisableDate={disabledDate ? disabledDate() : undefined}
        placeholder={placeholder}
        {...rest}
      />

      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
});

export default EmpDatePicker;
