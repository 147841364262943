import { IntlShape } from "react-intl";

export type DialCodeType = {
  name: string;
  dial_code: string;
  code: string;
  flag_1x1: string;
  flag_4x3: string;
};

export const DIAL_CODE_CONSTANTS: DialCodeType[] = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/af.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/af.svg",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ax.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ax.svg",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/al.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/al.svg",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dz.svg",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/as.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/as.svg",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ad.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ad.svg",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ao.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ao.svg",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ai.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ai.svg",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aq.svg",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ag.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ag.svg",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ar.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ar.svg",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/am.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/am.svg",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aw.svg",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/au.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/au.svg",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/at.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/at.svg",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/az.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/az.svg",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bs.svg",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bh.svg",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bd.svg",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bb.svg",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/by.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/by.svg",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/be.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/be.svg",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bz.svg",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bj.svg",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bm.svg",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bt.svg",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bo.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ba.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ba.svg",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bw.svg",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/br.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/br.svg",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/io.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/io.svg",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bn.svg",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bg.svg",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bf.svg",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bi.svg",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kh.svg",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cm.svg",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ca.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ca.svg",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cv.svg",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ky.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ky.svg",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cf.svg",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/td.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/td.svg",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cl.svg",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cn.svg",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cx.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cx.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cc.svg",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/co.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/co.svg",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/km.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/km.svg",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cg.svg",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cd.svg",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ck.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ck.svg",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cr.svg",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ci.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ci.svg",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hr.svg",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cu.svg",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cy.svg",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cz.svg",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dk.svg",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dj.svg",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dm.svg",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/do.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/do.svg",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ec.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ec.svg",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eg.svg",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sv.svg",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gq.svg",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/er.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/er.svg",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ee.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ee.svg",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/et.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/et.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fk.svg",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fo.svg",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fj.svg",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fi.svg",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fr.svg",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gf.svg",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pf.svg",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ga.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ga.svg",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gm.svg",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ge.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ge.svg",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/de.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/de.svg",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gh.svg",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gi.svg",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gr.svg",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gl.svg",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gd.svg",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gp.svg",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gu.svg",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gt.svg",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gg.svg",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gn.svg",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gw.svg",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gy.svg",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ht.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ht.svg",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/va.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/va.svg",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hn.svg",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hk.svg",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hu.svg",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/is.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/is.svg",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/in.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/in.svg",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/id.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/id.svg",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ir.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ir.svg",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/iq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/iq.svg",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ie.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ie.svg",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/im.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/im.svg",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/il.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/il.svg",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/it.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/it.svg",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jm.svg",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jp.svg",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/je.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/je.svg",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jo.svg",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kz.svg",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ke.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ke.svg",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ki.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ki.svg",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kp.svg",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kr.svg",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kw.svg",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kg.svg",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/la.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/la.svg",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lv.svg",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lb.svg",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ls.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ls.svg",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lr.svg",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ly.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ly.svg",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/li.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/li.svg",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lt.svg",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lu.svg",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mo.svg",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mk.svg",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mg.svg",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mw.svg",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/my.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/my.svg",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mv.svg",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ml.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ml.svg",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mt.svg",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mh.svg",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mq.svg",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mr.svg",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mu.svg",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/yt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/yt.svg",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mx.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mx.svg",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fm.svg",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/md.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/md.svg",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mc.svg",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mn.svg",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/me.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/me.svg",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ms.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ms.svg",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ma.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ma.svg",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mz.svg",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mm.svg",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/na.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/na.svg",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nr.svg",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/np.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/np.svg",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nl.svg",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nc.svg",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nz.svg",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ni.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ni.svg",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ne.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ne.svg",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ng.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ng.svg",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nu.svg",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nf.svg",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mp.svg",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/no.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/no.svg",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/om.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/om.svg",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pk.svg",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pw.svg",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ps.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ps.svg",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pa.svg",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pg.svg",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/py.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/py.svg",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pe.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pe.svg",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ph.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ph.svg",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pn.svg",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pl.svg",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pt.svg",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pr.svg",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/qa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/qa.svg",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ro.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ro.svg",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ru.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ru.svg",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rw.svg",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/re.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/re.svg",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bl.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sh.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kn.svg",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lc.svg",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mf.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pm.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vc.svg",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ws.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ws.svg",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sm.svg",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/st.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/st.svg",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sa.svg",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sn.svg",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rs.svg",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sc.svg",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sl.svg",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sg.svg",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sk.svg",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/si.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/si.svg",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sb.svg",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/so.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/so.svg",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/za.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/za.svg",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ss.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ss.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gs.svg",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/es.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/es.svg",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lk.svg",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sd.svg",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sr.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sj.svg",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sz.svg",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/se.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/se.svg",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ch.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ch.svg",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sy.svg",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tw.svg",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tj.svg",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tz.svg",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/th.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/th.svg",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tl.svg",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tg.svg",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tk.svg",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/to.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/to.svg",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tt.svg",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tn.svg",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tr.svg",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tm.svg",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tc.svg",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tv.svg",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ug.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ug.svg",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ua.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ua.svg",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ae.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ae.svg",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gb.svg",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/us.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/us.svg",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uy.svg",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uz.svg",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vu.svg",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ve.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ve.svg",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vn.svg",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vg.svg",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vi.svg",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/wf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/wf.svg",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ye.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ye.svg",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zm.svg",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zw.svg",
  },
];

export const DIAL_CODE_CONSTANTS_INTL = (intl: IntlShape): DialCodeType[] => {
  return [
    {
      name: intl.formatMessage({ id: "countryOption_AF" }),
      dial_code: "+93",
      code: "AF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/af.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/af.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AX" }),
      dial_code: "+358",
      code: "AX",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ax.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ax.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AL" }),
      dial_code: "+355",
      code: "AL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/al.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/al.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DZ" }),
      dial_code: "+213",
      code: "DZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AS" }),
      dial_code: "+1684",
      code: "AS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/as.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/as.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AD" }),
      dial_code: "+376",
      code: "AD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ad.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ad.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AO" }),
      dial_code: "+244",
      code: "AO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ao.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ao.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AI" }),
      dial_code: "+1264",
      code: "AI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ai.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ai.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AQ" }),
      dial_code: "+672",
      code: "AQ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aq.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AG" }),
      dial_code: "+1268",
      code: "AG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ag.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ag.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AR" }),
      dial_code: "+54",
      code: "AR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ar.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ar.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AM" }),
      dial_code: "+374",
      code: "AM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/am.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/am.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AW" }),
      dial_code: "+297",
      code: "AW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AU" }),
      dial_code: "+61",
      code: "AU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/au.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/au.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AT" }),
      dial_code: "+43",
      code: "AT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/at.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/at.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AZ" }),
      dial_code: "+994",
      code: "AZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/az.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/az.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BS" }),
      dial_code: "+1242",
      code: "BS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bs.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BH" }),
      dial_code: "+973",
      code: "BH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bh.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BD" }),
      dial_code: "+880",
      code: "BD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bd.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BB" }),
      dial_code: "+1246",
      code: "BB",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bb.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BY" }),
      dial_code: "+375",
      code: "BY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/by.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/by.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BE" }),
      dial_code: "+32",
      code: "BE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/be.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/be.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BZ" }),
      dial_code: "+501",
      code: "BZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BJ" }),
      dial_code: "+229",
      code: "BJ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bj.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BM" }),
      dial_code: "+1441",
      code: "BM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BT" }),
      dial_code: "+975",
      code: "BT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BO" }),
      dial_code: "+591",
      code: "BO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bo.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BA" }),
      dial_code: "+387",
      code: "BA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ba.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ba.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BW" }),
      dial_code: "+267",
      code: "BW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BR" }),
      dial_code: "+55",
      code: "BR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/br.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/br.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IO" }),
      dial_code: "+246",
      code: "IO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/io.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/io.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BN" }),
      dial_code: "+673",
      code: "BN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BG" }),
      dial_code: "+359",
      code: "BG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BF" }),
      dial_code: "+226",
      code: "BF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BI" }),
      dial_code: "+257",
      code: "BI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bi.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KH" }),
      dial_code: "+855",
      code: "KH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kh.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CM" }),
      dial_code: "+237",
      code: "CM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CA" }),
      dial_code: "+1",
      code: "CA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ca.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ca.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CV" }),
      dial_code: "+238",
      code: "CV",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cv.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KY" }),
      dial_code: "+ 345",
      code: "KY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ky.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ky.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CF" }),
      dial_code: "+236",
      code: "CF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TD" }),
      dial_code: "+235",
      code: "TD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/td.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/td.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CL" }),
      dial_code: "+56",
      code: "CL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CN" }),
      dial_code: "+86",
      code: "CN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CX" }),
      dial_code: "+61",
      code: "CX",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cx.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cx.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CC" }),
      dial_code: "+61",
      code: "CC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CO" }),
      dial_code: "+57",
      code: "CO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/co.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/co.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KM" }),
      dial_code: "+269",
      code: "KM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/km.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/km.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CG" }),
      dial_code: "+242",
      code: "CG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CD" }),
      dial_code: "+243",
      code: "CD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cd.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CK" }),
      dial_code: "+682",
      code: "CK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ck.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ck.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CR" }),
      dial_code: "+506",
      code: "CR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CI" }),
      dial_code: "+225",
      code: "CI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ci.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ci.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_HR" }),
      dial_code: "+385",
      code: "HR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CU" }),
      dial_code: "+53",
      code: "CU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CY" }),
      dial_code: "+357",
      code: "CY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cy.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CZ" }),
      dial_code: "+420",
      code: "CZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DK" }),
      dial_code: "+45",
      code: "DK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DJ" }),
      dial_code: "+253",
      code: "DJ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dj.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DM" }),
      dial_code: "+1767",
      code: "DM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DO" }),
      dial_code: "+1849",
      code: "DO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/do.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/do.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_EC" }),
      dial_code: "+593",
      code: "EC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ec.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ec.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_EG" }),
      dial_code: "+20",
      code: "EG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SV" }),
      dial_code: "+503",
      code: "SV",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sv.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GQ" }),
      dial_code: "+240",
      code: "GQ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gq.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ER" }),
      dial_code: "+291",
      code: "ER",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/er.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/er.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_EE" }),
      dial_code: "+372",
      code: "EE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ee.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ee.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ET" }),
      dial_code: "+251",
      code: "ET",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/et.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/et.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FK" }),
      dial_code: "+500",
      code: "FK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FO" }),
      dial_code: "+298",
      code: "FO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fo.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FJ" }),
      dial_code: "+679",
      code: "FJ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fj.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FI" }),
      dial_code: "+358",
      code: "FI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fi.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FR" }),
      dial_code: "+33",
      code: "FR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GF" }),
      dial_code: "+594",
      code: "GF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PF" }),
      dial_code: "+689",
      code: "PF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GA" }),
      dial_code: "+241",
      code: "GA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ga.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ga.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GM" }),
      dial_code: "+220",
      code: "GM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GE" }),
      dial_code: "+995",
      code: "GE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ge.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ge.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_DE" }),
      dial_code: "+49",
      code: "DE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/de.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/de.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GH" }),
      dial_code: "+233",
      code: "GH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gh.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GI" }),
      dial_code: "+350",
      code: "GI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gi.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GR" }),
      dial_code: "+30",
      code: "GR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GL" }),
      dial_code: "+299",
      code: "GL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GD" }),
      dial_code: "+1473",
      code: "GD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gd.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GP" }),
      dial_code: "+590",
      code: "GP",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gp.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GU" }),
      dial_code: "+1671",
      code: "GU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GT" }),
      dial_code: "+502",
      code: "GT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GG" }),
      dial_code: "+44",
      code: "GG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GN" }),
      dial_code: "+224",
      code: "GN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GW" }),
      dial_code: "+245",
      code: "GW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GY" }),
      dial_code: "+595",
      code: "GY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gy.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_HT" }),
      dial_code: "+509",
      code: "HT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ht.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ht.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VA" }),
      dial_code: "+379",
      code: "VA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/va.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/va.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_HN" }),
      dial_code: "+504",
      code: "HN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_HK" }),
      dial_code: "+852",
      code: "HK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_HU" }),
      dial_code: "+36",
      code: "HU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IS" }),
      dial_code: "+354",
      code: "IS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/is.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/is.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IN" }),
      dial_code: "+91",
      code: "IN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/in.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/in.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ID" }),
      dial_code: "+62",
      code: "ID",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/id.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/id.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IR" }),
      dial_code: "+98",
      code: "IR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ir.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ir.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IQ" }),
      dial_code: "+964",
      code: "IQ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/iq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/iq.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IE" }),
      dial_code: "+353",
      code: "IE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ie.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ie.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IM" }),
      dial_code: "+44",
      code: "IM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/im.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/im.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IL" }),
      dial_code: "+972",
      code: "IL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/il.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/il.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_IT" }),
      dial_code: "+39",
      code: "IT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/it.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/it.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_JM" }),
      dial_code: "+1876",
      code: "JM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_JP" }),
      dial_code: "+81",
      code: "JP",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jp.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_JE" }),
      dial_code: "+44",
      code: "JE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/je.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/je.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_JO" }),
      dial_code: "+962",
      code: "JO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jo.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KZ" }),
      dial_code: "+77",
      code: "KZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KE" }),
      dial_code: "+254",
      code: "KE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ke.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ke.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KI" }),
      dial_code: "+686",
      code: "KI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ki.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ki.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KP" }),
      dial_code: "+850",
      code: "KP",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kp.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KR" }),
      dial_code: "+82",
      code: "KR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KW" }),
      dial_code: "+965",
      code: "KW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KG" }),
      dial_code: "+996",
      code: "KG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LA" }),
      dial_code: "+856",
      code: "LA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/la.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/la.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LV" }),
      dial_code: "+371",
      code: "LV",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lv.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LB" }),
      dial_code: "+961",
      code: "LB",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lb.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LS" }),
      dial_code: "+266",
      code: "LS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ls.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ls.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LR" }),
      dial_code: "+231",
      code: "LR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LY" }),
      dial_code: "+218",
      code: "LY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ly.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ly.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LI" }),
      dial_code: "+423",
      code: "LI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/li.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/li.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LT" }),
      dial_code: "+370",
      code: "LT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LU" }),
      dial_code: "+352",
      code: "LU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MO" }),
      dial_code: "+853",
      code: "MO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mo.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MK" }),
      dial_code: "+389",
      code: "MK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MG" }),
      dial_code: "+261",
      code: "MG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MW" }),
      dial_code: "+265",
      code: "MW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MY" }),
      dial_code: "+60",
      code: "MY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/my.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/my.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MV" }),
      dial_code: "+960",
      code: "MV",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mv.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ML" }),
      dial_code: "+223",
      code: "ML",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ml.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ml.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MT" }),
      dial_code: "+356",
      code: "MT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MH" }),
      dial_code: "+692",
      code: "MH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mh.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MQ" }),
      dial_code: "+596",
      code: "MQ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mq.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MR" }),
      dial_code: "+222",
      code: "MR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MU" }),
      dial_code: "+230",
      code: "MU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_YT" }),
      dial_code: "+262",
      code: "YT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/yt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/yt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MX" }),
      dial_code: "+52",
      code: "MX",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mx.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mx.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_FM" }),
      dial_code: "+691",
      code: "FM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MD" }),
      dial_code: "+373",
      code: "MD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/md.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/md.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MC" }),
      dial_code: "+377",
      code: "MC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MN" }),
      dial_code: "+976",
      code: "MN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ME" }),
      dial_code: "+382",
      code: "ME",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/me.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/me.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MS" }),
      dial_code: "+1664",
      code: "MS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ms.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ms.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MA" }),
      dial_code: "+212",
      code: "MA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ma.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ma.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MZ" }),
      dial_code: "+258",
      code: "MZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MM" }),
      dial_code: "+95",
      code: "MM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NA" }),
      dial_code: "+264",
      code: "NA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/na.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/na.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NR" }),
      dial_code: "+674",
      code: "NR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NP" }),
      dial_code: "+977",
      code: "NP",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/np.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/np.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NL" }),
      dial_code: "+31",
      code: "NL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NC" }),
      dial_code: "+687",
      code: "NC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NZ" }),
      dial_code: "+64",
      code: "NZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NI" }),
      dial_code: "+505",
      code: "NI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ni.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ni.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NE" }),
      dial_code: "+227",
      code: "NE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ne.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ne.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NG" }),
      dial_code: "+234",
      code: "NG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ng.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ng.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NU" }),
      dial_code: "+683",
      code: "NU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NF" }),
      dial_code: "+672",
      code: "NF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MP" }),
      dial_code: "+1670",
      code: "MP",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mp.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_NO" }),
      dial_code: "+47",
      code: "NO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/no.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/no.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_OM" }),
      dial_code: "+968",
      code: "OM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/om.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/om.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PK" }),
      dial_code: "+92",
      code: "PK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PW" }),
      dial_code: "+680",
      code: "PW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PS" }),
      dial_code: "+970",
      code: "PS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ps.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ps.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PA" }),
      dial_code: "+507",
      code: "PA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pa.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PG" }),
      dial_code: "+675",
      code: "PG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PY" }),
      dial_code: "+595",
      code: "PY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/py.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/py.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PE" }),
      dial_code: "+51",
      code: "PE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pe.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pe.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PH" }),
      dial_code: "+63",
      code: "PH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ph.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ph.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PN" }),
      dial_code: "+872",
      code: "PN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PL" }),
      dial_code: "+48",
      code: "PL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PT" }),
      dial_code: "+351",
      code: "PT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PR" }),
      dial_code: "+1939",
      code: "PR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_QA" }),
      dial_code: "+974",
      code: "QA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/qa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/qa.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_RO" }),
      dial_code: "+40",
      code: "RO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ro.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ro.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_RU" }),
      dial_code: "+7",
      code: "RU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ru.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ru.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_RW" }),
      dial_code: "+250",
      code: "RW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_RE" }),
      dial_code: "+262",
      code: "RE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/re.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/re.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_BL" }),
      dial_code: "+590",
      code: "BL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SH" }),
      dial_code: "+290",
      code: "SH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sh.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_KN" }),
      dial_code: "+1869",
      code: "KN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LC" }),
      dial_code: "+1758",
      code: "LC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_MF" }),
      dial_code: "+590",
      code: "MF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_PM" }),
      dial_code: "+508",
      code: "PM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VC" }),
      dial_code: "+1784",
      code: "VC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_WS" }),
      dial_code: "+685",
      code: "WS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ws.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ws.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SM" }),
      dial_code: "+378",
      code: "SM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ST" }),
      dial_code: "+239",
      code: "ST",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/st.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/st.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SA" }),
      dial_code: "+966",
      code: "SA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sa.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SN" }),
      dial_code: "+221",
      code: "SN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_RS" }),
      dial_code: "+381",
      code: "RS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rs.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SC" }),
      dial_code: "+248",
      code: "SC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SL" }),
      dial_code: "+232",
      code: "SL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SG" }),
      dial_code: "+65",
      code: "SG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SK" }),
      dial_code: "+421",
      code: "SK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SI" }),
      dial_code: "+386",
      code: "SI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/si.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/si.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SB" }),
      dial_code: "+677",
      code: "SB",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sb.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SO" }),
      dial_code: "+252",
      code: "SO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/so.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/so.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ZA" }),
      dial_code: "+27",
      code: "ZA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/za.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/za.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SS" }),
      dial_code: "+211",
      code: "SS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ss.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ss.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GS" }),
      dial_code: "+500",
      code: "GS",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gs.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ES" }),
      dial_code: "+34",
      code: "ES",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/es.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/es.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_LK" }),
      dial_code: "+94",
      code: "LK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SD" }),
      dial_code: "+249",
      code: "SD",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sd.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SR" }),
      dial_code: "+597",
      code: "SR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SJ" }),
      dial_code: "+47",
      code: "SJ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sj.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SZ" }),
      dial_code: "+268",
      code: "SZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SE" }),
      dial_code: "+46",
      code: "SE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/se.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/se.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_CH" }),
      dial_code: "+41",
      code: "CH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ch.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ch.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_SY" }),
      dial_code: "+963",
      code: "SY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sy.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TW" }),
      dial_code: "+886",
      code: "TW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tw.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TJ" }),
      dial_code: "+992",
      code: "TJ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tj.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TZ" }),
      dial_code: "+255",
      code: "TZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TH" }),
      dial_code: "+66",
      code: "TH",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/th.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/th.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TL" }),
      dial_code: "+670",
      code: "TL",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tl.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TG" }),
      dial_code: "+228",
      code: "TG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TK" }),
      dial_code: "+690",
      code: "TK",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tk.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TO" }),
      dial_code: "+676",
      code: "TO",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/to.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/to.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TT" }),
      dial_code: "+1868",
      code: "TT",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tt.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TN" }),
      dial_code: "+216",
      code: "TN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TR" }),
      dial_code: "+90",
      code: "TR",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tr.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TM" }),
      dial_code: "+993",
      code: "TM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TC" }),
      dial_code: "+1649",
      code: "TC",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tc.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_TV" }),
      dial_code: "+688",
      code: "TV",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tv.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_UG" }),
      dial_code: "+256",
      code: "UG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ug.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ug.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_UA" }),
      dial_code: "+380",
      code: "UA",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ua.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ua.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_AE" }),
      dial_code: "+971",
      code: "AE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ae.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ae.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_GB" }),
      dial_code: "+44",
      code: "GB",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gb.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_US" }),
      dial_code: "+1",
      code: "US",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/us.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/us.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_UY" }),
      dial_code: "+598",
      code: "UY",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uy.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_UZ" }),
      dial_code: "+998",
      code: "UZ",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uz.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VU" }),
      dial_code: "+678",
      code: "VU",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vu.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VE" }),
      dial_code: "+58",
      code: "VE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ve.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ve.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VN" }),
      dial_code: "+84",
      code: "VN",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vn.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VG" }),
      dial_code: "+1284",
      code: "VG",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vg.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_VI" }),
      dial_code: "+1340",
      code: "VI",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vi.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_WF" }),
      dial_code: "+681",
      code: "WF",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/wf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/wf.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_YE" }),
      dial_code: "+967",
      code: "YE",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ye.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ye.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ZM" }),
      dial_code: "+260",
      code: "ZM",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zm.svg",
    },
    {
      name: intl.formatMessage({ id: "countryOption_ZW" }),
      dial_code: "+263",
      code: "ZW",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zw.svg",
    },
  ];
};
