import { useCallback, useEffect, useState } from "react";
import { Editable, Slate, withReact } from "slate-react";
import { Range, Node } from "slate";
import _debounce from "lodash/debounce";
import "./emp-editor.scss";
import { isKeyHotkey } from "is-hotkey";
import { createEditor, Transforms } from "slate";
import { Color } from "../../../utilities/colors";
import BoldIcon from "../../icon/bold-icon";
import DotpointsIcon from "../../icon/dotpoints-icon";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { Tooltip, Whisper } from "rsuite";
import InfoCircleIcon from "../../icon/info-circle-icon";
import AlertSquareIcon from "../../icon/alert-square";
import {
  BulletedNodeElement,
  ListNodeElement,
} from "./nodes/list-node-element";
import LinkNodeElement from "./nodes/link-node-element";
import ParagraphNodeElement from "./nodes/paragraph-node-element";
import LeafElement from "./nodes/leaf-element";
import { withEmpEditor } from "./utility/with-emp-editor";
import EmpMarkButton from "./toolbar/mark-button";
import EmpBlockButton from "./toolbar/block-button";
import { EditorUtilities } from "./utility/editor-utilities";
import EmpLinkButton from "./toolbar/link-button";
import LinkIcon from "../../icon/link-icon";
import { withHistory } from "slate-history";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

function countTextCharacters(value: any): number {
  let totalCharacters = 0;
  function traverse(currentNode: any) {
    if (Array.isArray(currentNode)) {
      currentNode.forEach((childNode) => traverse(childNode));
    } else if (currentNode && typeof currentNode === "object") {
      if (
        currentNode.hasOwnProperty("text") &&
        typeof currentNode.text === "string"
      ) {
        totalCharacters += currentNode.text.length;
      }
      if (currentNode.hasOwnProperty("children")) {
        traverse(currentNode.children);
      }
    }
  }
  traverse(value);
  return totalCharacters;
}

interface Props {
  characterCount?: number;
  labelText?: string | JSX.Element;
  placeholder?: string;
  formControl: FormControl;
  tooltip?: string | JSX.Element;
  required?: boolean;
  onChange?: (formControl: FormControl) => void;
  description?: string;
}
const EmpEditor = (props: Props) => {
  const {
    labelText,
    description,
    characterCount,
    placeholder,
    formControl,
    onChange,
  } = props;
  const isRequired = props.required ?? false;
  const tooltip = props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : <></>;
  const [editor] = useState(() =>
    withEmpEditor(withReact(withHistory(createEditor())))
  );
  const [isFocused, setFocused] = useState(false);
  const [currentCharacterCount, setCurrentCharacterCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isKeyHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    }
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    switch (event.key) {
      case "b": {
        event.preventDefault();
        EditorUtilities.toggleMark(editor, "bold");
        break;
      }
    }
  };

  useEffect(() => {
    const newValue = formControl.getValue() as Node[];
    // Get initial total nodes to prevent deleting affecting the loop
    let totalNodes = editor.children.length;

    if (newValue.length <= 0) return;

    // Remove every node except the last one
    // Otherwise SlateJS will return error as there's no content
    for (let i = 0; i < totalNodes - 1; i++) {
      Transforms.removeNodes(editor, {
        at: [totalNodes - i - 1],
      });
    }
    for (const block of newValue) {
      Transforms.insertNodes(editor, block, {
        at: [editor.children.length],
      });
    }
    // Remove the last node that was leftover from before
    Transforms.removeNodes(editor, {
      at: [0],
    });
  }, [formControl.resetFlag]);

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "bulleted-list":
        return <BulletedNodeElement {...props} />;
      case "list-item":
        return <ListNodeElement {...props} />;
      case "link":
        return <LinkNodeElement {...props} />;
      default:
        return <ParagraphNodeElement {...props} />;
    }
  }, []);

  const renderLeaf = useCallback((props: any) => {
    return <LeafElement {...props} />;
  }, []);

  const updateCharacterCount = _debounce((value: any) => {
    const characterCount = countTextCharacters(value);
    setCurrentCharacterCount(characterCount);
  }, 200);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  return (
    <div className="emp-editor-control">
      <label className={`${description ? "mb-1" : "mb-2"}`}>
        {labelText}
        {isRequired && <span className="required">*</span>}
        {props.tooltip && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={tooltip}
          >
            <div className="emp-tooltip-wrapper">
              <InfoCircleIcon size={14} backgroundColor={Color.NEUTRAL[500]} />
            </div>
          </Whisper>
        )}
      </label>
      {description && <p className="description">{description}</p>}
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={(value) => {
          const isAstChange = editor.operations.some(
            (op) => "set_selection" !== op.type
          );
          if (isAstChange) {
            if (characterCount) {
              updateCharacterCount(value);
            }
            formControl.setValue(value);
            if (onChange) onChange(formControl);
          }
        }}
      >
        <div className={`emp-editor ${isFocused ? "focused" : ""}`}>
          <div className="emp-editor-wrapper">
            <Editable
              style={{
                outline: "none",
              }}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
              onKeyUp={() => {
                EditorUtilities.detectList(editor);
              }}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className="toolbar">
            <div className="buttons-wrapper">
              <EmpMarkButton format="bold" icon={BoldIcon} />
              <EmpBlockButton format="bulleted-list" icon={DotpointsIcon} />
              <EmpLinkButton icon={LinkIcon} />
            </div>
            {characterCount && (
              <span className="emp-editor-character-count">{`${currentCharacterCount}/${characterCount}`}</span>
            )}
          </div>
        </div>
      </Slate>
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default EmpEditor;
