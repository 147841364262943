import React, { useEffect, useState } from "react";
import "./emp-button-tab.scss";

export interface EmpTab {
  text: string;
  value: string;
  action?: () => void;
  isSelected: boolean;
  isDisabled?: boolean;
}

interface EmpButtonTabProps {
  customClass?: string;
  options: EmpTab[];
}

const EmpButtonTab: React.FC<EmpButtonTabProps> = ({
  customClass,
  options,
}) => {
  const [buttonTab, setButtonTab] = useState<EmpTab[]>(options);

  const handleSelect = (EmpTab: EmpTab) => {
    const { action, isSelected, value, isDisabled } = EmpTab;
    if (isDisabled || isSelected) return;
    const newButtonTab = buttonTab.map((elem) => {
      elem.isSelected = elem.value === value;
      return elem;
    });
    setButtonTab(newButtonTab);
    if (action) {
      action();
    }
  };

  useEffect(() => {
    setButtonTab(options);
  }, [options]);

  return (
    <div className={`emp-button-tab ${customClass}`}>
      {buttonTab.map((elem) => {
        return (
          <button
            key={elem.text}
            className={`emp-tab-button ${elem.isSelected ? "active" : ""} ${
              elem.isDisabled ? "disabled" : ""
            }`}
            onClick={() => handleSelect(elem)}
          >
            {elem.text}
          </button>
        );
      })}
    </div>
  );
};

export default EmpButtonTab;
