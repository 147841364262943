import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import EmpBreadcrumb, {
  EmpBreadcrumbSpecs,
} from "../../components/shared/emp-breadcrumb/emp-breadcrumb";
import EmpException from "../../exception/empException";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import "./seller-task-details-page.scss";
import _debounce from "lodash/debounce";
import { TaskDto } from "../../model/campaign/task.dto";
import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import { SellerTaskSummaryView } from "./views/seller-task-summary-view";
import { SellerTaskRecruitmentView } from "./views/seller-task-recruitment-view";
import { AppContext } from "../../context/app.context";
import { UserDto } from "../../model/user-management/user.dto";
import UserUtils from "../../utilities/user-utils";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import { LatestNegotiationSingleDto } from "../../model/campaign/latest-negotiation-single.dto";
import { TASK_STATUS } from "../../constants/app.constants";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import { SellerTaskChatView } from "./views/seller-task-chat-view";
import { Color } from "../../utilities/colors";
import { SellerSubmitDraftView } from "./views/seller-submit-draft-view";
import { SellerSubmitEvidenceView } from "./views/seller-submit-evidence-view";
import { SellerTaskOngoingProgressWall } from "./views/seller-task-ongoing-progress-wall-view";
import { SellerTaskPaymentView } from "./views/seller-task-payment-view";
import { SellerOngoingTaskSummaryView } from "./views/seller-ongoing-task-summary-view";
import { EmpCampaignAccessControlView } from "../../components/shared/emp-campaign-access-control-view/emp-campaign-access-control-view";
import { SellerExclusiveTaskSummaryView } from "./views/seller-exclusive-task-summary-view";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import { SellerDeletedTaskView } from "./views/seller-deleted-task-view";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import HelpCircleIcon from "../../components/icon/help-circle-icon";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "../../components/modals/campaign-guide-modal";
import {
  ONGOING_TASK_GUIDE,
  RECRUITING_TASK_GUIDE,
} from "../../constants/campaign-guide";
import { SellerCampaignAnalyticsView } from "./views/seller-campaign-analytics-view";

const breadcrumbsData: EmpBreadcrumbSpecs[] = [];

export const TaskRecruitmentMenuBar = [
  {
    display: "Summary",
    text: "Summary",
    isSelected: false,
  },
  {
    display: "Progress Wall",
    text: "Progress Wall",
    isSelected: false,
  },
];

export const TaskOngoingMenuBar = [
  {
    display: "Summary",
    text: "Summary",
    isSelected: false,
  },
  {
    display: "Progress Wall",
    text: "Progress Wall",
    isSelected: false,
  },
  {
    display: "Chat",
    text: "Chat",
    isSelected: false,
  },
  {
    display: "Submit Draft",
    text: "Submit Draft",
    isSelected: false,
  },
  {
    display: "Proof of Work",
    text: "Proof of Work",
    isSelected: false,
  },
  {
    display: "Analytics",
    text: "Analytics",
    isSelected: false,
  },
  {
    display: "Payment",
    text: "Payment",
    isSelected: false,
  },
];

export const SellerTaskDetailsPage = () => {
  const location = useLocation();
  const userRoleRef = useRef<string>("");

  const { user: userContext } = useContext(AppContext);
  const userRef = useRef<UserDto>();

  const [accessControlGranted, setAccessControlGranted] = useState(false);
  const [accessControlVisible, setAccessControlVisible] = useState(true);
  const [displayMode, setDisplayMode] = useState<"default" | "exclusive">();

  const { taskId, campaignId } = useParams();
  const [tabs, setTabs] = useState<EmpTab[]>([]);
  const [task, setTask] = useState<TaskDto>();
  const [breadcrumbs, setBreadcrumbs] =
    useState<EmpBreadcrumbSpecs[]>(breadcrumbsData);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [sellerLatestNegotiation, setSellerLatestNegotiation] =
    useState<LatestNegotiationSingleDto>();

  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();

  useEffect(() => {
    if (!tabs) return;
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    if (!tabs) return;
    setInitialTabs(tabs);
  }, [location]);

  const setInitialTabs = (tab: EmpTab[]) => {
    let fragment = window.location.hash.slice(1);
    let modifiedTab = tab;
    // unset all tabs
    modifiedTab.forEach((elem) => (elem.isSelected = false));
    if (fragment) {
      fragment = fragment.replaceAll("%20", " ");
      const fragmentHash = tab.find((elem) => elem.text === fragment);
      if (!fragmentHash)
        modifiedTab = tab.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      else fragmentHash.isSelected = true;
    } else {
      modifiedTab = tab.map((tab, index) =>
        index === 0 ? { ...tab, isSelected: true } : tab
      );
    }
    setTabs([...modifiedTab]);
  };

  const fetchTaskNegotiationStatus = useCallback(async () => {
    try {
      if (!taskId || !campaignId) throw new EmpException("No task id");

      if (!userRef.current) await fetchUser();
      if (!userRef.current) throw new EmpException("No User Found");
      // Need to see the user role in this case;
      let representativeId = userRef.current.id;
      if (userRoleRef.current === "agency") {
        // Get the organisation id
        if (
          !userRef.current.organisation ||
          userRef.current.organisation.length === 0
        ) {
          throw new EmpException("Not an organisation");
        }
        representativeId = userRef.current.organisation[0].id;
      }
      const resp = await NegotiationApi.getLatestNegotiationByRepresentative(
        representativeId,
        campaignId,
        taskId
      );
      setSellerLatestNegotiation(resp.data);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch seller latest negotiation"
      );
    }
  }, [taskId, campaignId, userContext]);

  const fetchTaskById = useCallback(async () => {
    try {
      if (!taskId || !campaignId) throw new EmpException("No task id");
      const resp = await CampaignApi.fetchTaskById(campaignId, taskId);
      const task = resp.data;
      if (task.status === TASK_STATUS.RECRUITING) {
        setInitialTabs(TaskRecruitmentMenuBar);
      } else if (
        [TASK_STATUS.ONGOING, TASK_STATUS.COMPLETED].includes(task.status)
      ) {
        setInitialTabs(TaskOngoingMenuBar);
      }

      const newBreadcrumbs = [...breadcrumbsData];
      newBreadcrumbs.push({
        link: `/${userRoleRef.current}/campaign-details/${campaignId}#Task`,
        text: task.campaign.name,
      });
      newBreadcrumbs.push({
        link: `/${userRoleRef.current}/campaign-details/${campaignId}/task/${taskId}`,
        text: task.name,
      });
      setBreadcrumbs(newBreadcrumbs);
      setTask(task);

      await empDelay(500);
      setLoading(false);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch task");
    }
  }, [taskId, campaignId]);

  const fetchUser = useCallback(async () => {
    // Fetch user details from cache or from the server
    if (!userRef.current) {
      const user = await UserUtils.fetchUser(userContext);
      userRef.current = user;
    }
  }, []);

  useEffect(() => {
    fetchUser().then((res) => {
      fetchTaskById();
    });
    userRoleRef.current = location.pathname.split("/")[1];
  }, [fetchTaskById, fetchTaskNegotiationStatus, fetchUser, location]);

  // Function to pre-fetch latest negotiation status if creator or agency is clear to access
  // this resource.
  useEffect(() => {
    if (accessControlGranted && displayMode === "default") {
      fetchTaskNegotiationStatus();
    }
  }, [accessControlGranted, displayMode, fetchTaskNegotiationStatus]);

  return (
    <div className="emp-page-wrapper emp-responsive">
      {accessControlVisible && (
        <EmpCampaignAccessControlView
          resourceType="campaign-task-listing"
          role="seller"
          onGranted={(level) => {
            setDisplayMode(level);
            setAccessControlGranted(true);
          }}
          onUnmount={() => {
            setAccessControlVisible(false);
          }}
        />
      )}
      {!accessControlVisible && (
        <>
          <CampaignGuideModal ref={campaignGuideModalRef} />
          <div className="bg-tint"></div>
          <div
            className="emp-page-content-no-padding emp-seller-task-details-page"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflow: selectedTab === "Recruitment" ? "hidden" : "initial",
            }}
          >
            <div className="mt-3">
              <EmpBreadcrumb
                items={breadcrumbs}
                loading={loading}
                numLoadingItem={3}
              />
            </div>
            <div className="header-wrapper page-header-wrapper">
              {task && (
                <>
                  <h1 className="page-header">{task.name}</h1>
                  {task.status === TASK_STATUS.RECRUITING && (
                    <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
                  )}
                  {task.status === TASK_STATUS.ONGOING && (
                    <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
                  )}
                  {task.status === TASK_STATUS.COMPLETED && (
                    <EmpPill text={"Completed"} {...PILL_COLORS.green} />
                  )}
                  <EmpIconButton
                    onSubmit={() => {
                      let guide = undefined;
                      if (task.status === TASK_STATUS.RECRUITING) {
                        guide = RECRUITING_TASK_GUIDE;
                      } else if (task.status === TASK_STATUS.ONGOING) {
                        guide = ONGOING_TASK_GUIDE;
                      }
                      campaignGuideModalRef.current?.show(guide);
                    }}
                    buttonStyle="secondary"
                    suppressMobileView
                    icon={
                      <HelpCircleIcon
                        backgroundColor={Color.NEUTRAL[400]}
                        size={18}
                      />
                    }
                  />
                </>
              )}
            </div>

            {task && task?.status === TASK_STATUS.RECRUITING && (
              <>
                {displayMode === "exclusive" && (
                  <SellerExclusiveTaskSummaryView task={task} />
                )}
                {displayMode === "default" && (
                  <>
                    {tabs && (
                      <EmpSecondaryTabBar
                        tabs={tabs}
                        onChange={(tabLabel: string) => {
                          setSelectedTab(tabLabel);
                          window.location.hash = tabLabel;
                        }}
                      />
                    )}
                    <div
                      className="task-view-wrapper"
                      style={{
                        flex: 1,
                        overflow:
                          selectedTab === "Progress Wall"
                            ? "hidden"
                            : "initial",
                      }}
                    >
                      {selectedTab === "Summary" && sellerLatestNegotiation && (
                        <SellerTaskSummaryView
                          fetchTask={fetchTaskById}
                          task={task}
                          sellerLatestNegotiation={sellerLatestNegotiation}
                          fetchLatestNegotiation={fetchTaskNegotiationStatus}
                        />
                      )}

                      {selectedTab === "Progress Wall" &&
                        sellerLatestNegotiation && (
                          <SellerTaskRecruitmentView
                            fetchLatestNegotiation={fetchTaskNegotiationStatus}
                            fetchTask={fetchTaskById}
                            task={task}
                            sellerLatestNegotiation={sellerLatestNegotiation}
                          />
                        )}
                    </div>
                  </>
                )}
              </>
            )}
            {task &&
              [TASK_STATUS.ONGOING, TASK_STATUS.COMPLETED].includes(
                task.status
              ) && (
                <>
                  {tabs && (
                    <EmpSecondaryTabBar
                      tabs={tabs}
                      onChange={(tabLabel: string) => {
                        setSelectedTab(tabLabel);
                        window.location.hash = tabLabel;
                      }}
                    />
                  )}
                  <div
                    className="task-view-wrapper"
                    style={{
                      flex: 1,
                      overflow:
                        selectedTab === "Progress Wall" ? "hidden" : "initial",
                    }}
                  >
                    {selectedTab === "Summary" && (
                      <SellerOngoingTaskSummaryView
                        fetchTask={fetchTaskById}
                        task={task}
                      />
                    )}
                    {selectedTab === "Progress Wall" && (
                      <SellerTaskOngoingProgressWall
                        toDraft={() => {
                          setSelectedTab("Submit Draft");
                          window.location.hash = "Submit Draft";
                        }}
                        fetchTask={() => {}}
                        task={task}
                      />
                    )}
                    {selectedTab === "Chat" && (
                      <SellerTaskChatView task={task} />
                    )}
                    {selectedTab === "Submit Draft" && userRef.current && (
                      <SellerSubmitDraftView
                        task={task}
                        user={userRef.current}
                      />
                    )}
                    {selectedTab === "Proof of Work" && userRef.current && (
                      <SellerSubmitEvidenceView
                        task={task}
                        user={userRef.current}
                      />
                    )}
                    {selectedTab === "Analytics" && userRef.current && (
                      <SellerCampaignAnalyticsView
                        task={task}
                        user={userRef.current}
                      />
                    )}
                    {selectedTab === "Payment" && userRef.current && (
                      <SellerTaskPaymentView
                        task={task}
                        user={userRef.current}
                      />
                    )}
                  </div>
                </>
              )}
            {task && task.status === TASK_STATUS.DELETED && (
              <SellerDeletedTaskView task={task} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
