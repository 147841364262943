import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { AdminUserDto } from "../../model/support-referral/cs-org-assignment/admin-user.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const CsOrgAssignmentApi = {
  fetchOrgAssignmentByOrgId: async (
    orgId: string
  ): Promise<ApiResponse<AdminUserDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<AdminUserDto>>(
      ENDPOINTS.referralAndSupportMsvc.csOrgAssignment.fetchOrgAssignmentByOrgId(
        orgId
      )
    );
    return response.data;
  },
};
export default CsOrgAssignmentApi;
