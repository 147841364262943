import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import CampaignSummaryApi from "../../api/campaign-msvc/campaign-summary.api";
import AnnouncementIcon from "../../components/icon/announcement-icon";
import CoinsHandIcon from "../../components/icon/coins-hand-icon";
import FileAttachmentIcon from "../../components/icon/file-attachment-icon";
import FileCheckIcon from "../../components/icon/file-check-icon";
import UsersRightIcon from "../../components/icon/users-right-icon";
import PaymentFailModal from "../../components/modals/payment-fail-modal";
import EmpLoaderV2 from "../../components/shared/emp-loader-v2/emp-loader-v2";
import { PermsUtils } from "../../constants/permissions.constants";
import { AppContext } from "../../context/app.context";
import EmpException from "../../exception/empException";
import { BrandCampaignMetricsDto } from "../../model/campaign/brand-campaign-metrics.dto";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../utilities/user-utils";
import "./brand-home-page.scss";
import BrandActiveTaskTable from "./charts/brand-active-task-table";
import { BrandTaskBreakdownPieChart } from "./charts/brand-task-breakdown-pie-chart";

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const DELAY_MS = 0.2;
type ComponentType =
  | "profile"
  | "metric-tiles"
  | "active-tasks"
  | "status-piechart";

export const BrandHomePage = () => {
  const [campaignMetric, setCampaignMetric] =
    useState<BrandCampaignMetricsDto>();
  const [organisation, setOrganisation] = useState<OrganisationRespDto>();
  const [isLoading, setLoading] = useState(true);
  const [loadedComponents, setLoadedComponents] = useState<Set<ComponentType>>(
    new Set<ComponentType>()
  );

  // User context from the App Provider
  const { user: userContext } = useContext(AppContext);

  /**
   * Fetches the user details and organization data.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const userResp = await UserUtils.fetchUser(userContext);
      const isBrand = PermsUtils.isUserABrand(userResp);
      if (!isBrand) {
        throw new EmpException("Profile is not a brand");
      }
      const org = userResp.organisation![0];
      setOrganisation(org);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const getCampaignMetrics = useCallback(async () => {
    try {
      const resp = await CampaignSummaryApi.fetchCampaignMetrics();
      setCampaignMetric(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to get Campaign Metrics"
      );
    }
  }, []);

  useEffect(() => {
    getCampaignMetrics();
    fetchUserDetails();
    Promise.all([getCampaignMetrics, fetchUserDetails]).then(() => {
      loadedComponents.add("metric-tiles");
      loadedComponents.add("profile");
      setLoadedComponents(new Set(loadedComponents));
    });
  }, [getCampaignMetrics, fetchUserDetails]);

  useEffect(() => {
    console.log(loadedComponents);
  }, [loadedComponents]);

  useEffect(() => {
    if (loadedComponents.size === 4) {
      setLoading(false);
    }
  }, [loadedComponents]);

  // Campaign Card
  return (
    <div className="emp-page-wrapper emp-responsive">
      <EmpLoaderV2 isLoading={isLoading} />
      <div className="emp-page-content-no-padding emp-brand-home-page">
        <div className="content-wrapper">
          {organisation && (
            <motion.div
              className="emp-brand-home-page-intro-banner"
              initial="hidden"
              animate={!isLoading ? "visible" : "hidden"}
              transition={{ delay: 1 * DELAY_MS, duration: 0.2 }}
              variants={fadeInVariant}
            >
              <div className="banner-shading"></div>
              <div className="company-info-wrapper">
                <img
                  className="organistion-logo"
                  alt={organisation.companyName}
                  src={organisation.logo}
                />
                <h1 className="intro-lbl">
                  Hello {organisation.companyName} 👋
                </h1>
              </div>
            </motion.div>
          )}
          {campaignMetric && (
            <div className="metrics-tile-wrapper mt-8">
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 1.5 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numCampaigns}
                      separator={","}
                    />
                  </span>
                  <AnnouncementIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Campaigns</span>
              </motion.div>
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 2 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numRecruitingTasks}
                      separator={","}
                    />
                  </span>
                  <FileAttachmentIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Recruiting Tasks</span>
              </motion.div>
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 2.5 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numOngoingTasks}
                      separator={","}
                    />
                  </span>
                  <FileAttachmentIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Ongoing Tasks</span>
              </motion.div>
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 3 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numTaskApplications}
                      separator={","}
                    />
                  </span>
                  <UsersRightIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Task Applications</span>
              </motion.div>
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 3.5 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numTaskCounteroffers}
                      separator={","}
                    />
                  </span>
                  <CoinsHandIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Task Counteroffers</span>
              </motion.div>
              <motion.div
                className="metric-tile"
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 4 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <div className="details-wrapper">
                  <span className="metric-value">
                    <CountUp
                      duration={1.8}
                      end={campaignMetric.numDeliverablesInReview}
                      separator={","}
                    />
                  </span>
                  <FileCheckIcon
                    top={2}
                    size={24}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
                <span className="metric-header">Submitted Drafts</span>
              </motion.div>
            </div>
          )}
          <div className="split-columns">
            <motion.div
              className="task-status-chart-card"
              initial="hidden"
              animate={!isLoading ? "visible" : "hidden"}
              variants={fadeInVariant}
              transition={{ delay: 5 * DELAY_MS, duration: 0.2 }}
            >
              <BrandTaskBreakdownPieChart
                onLoaded={() => {
                  loadedComponents.add("status-piechart");
                  setLoadedComponents(new Set(loadedComponents));
                }}
              />
            </motion.div>
            <motion.div
              className="active-tasks-card"
              initial="hidden"
              animate={!isLoading ? "visible" : "hidden"}
              variants={fadeInVariant}
              transition={{ delay: 6 * DELAY_MS, duration: 0.2 }}
            >
              <BrandActiveTaskTable
                onLoaded={() => {
                  loadedComponents.add("active-tasks");
                  setLoadedComponents(new Set(loadedComponents));
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
