import { useCallback, useEffect, useRef, useState } from "react";
import "./creator-home-page.scss";
import { UserDto } from "../../model/user-management/user.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import { CreatorHomeSocialMediaCard } from "./dashboard-cards/creator-home-social-media-card";
import EditIcon from "../../components/icon/edit-icon";
import { Color } from "../../utilities/colors";
import ProfilePictureModal, {
  ProfilePictureModalRef,
} from "./profile-picture-modal";
import { CreatorHomeExtendedInfoCard } from "./dashboard-cards/creator-home-extended-info-card";
import UserApi from "../../api/user-msvc/user.api";
import { CreatorPartneringAgenciesCard } from "./dashboard-cards/creator-partnering-agencies-card";
import UserUtils from "../../utilities/user-utils";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import ToastUtils from "../../utilities/toast-utils";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import CopyIcon from "../../components/icon/copy-icon";
import { FormattedMessage } from "react-intl";
import { CreatorHomeActiveTaskTable } from "./dashboard-cards/creator-home-active-task-table";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import CampaignGuideModal from "../../components/modals/campaign-guide-modal";

export const CreatorHomePage = () => {
  const [user, setUser] = useState<UserDto>();
  const profilePictureModalRef = useRef<ProfilePictureModalRef>();
  const isMobile = useEmpBreakpoint(520);

  /**
   * Fetch user details and update the user state.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await UserApi.fetchUser();
      setUser(response.data);
      UserUtils.updateUser();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const getQuickAccessLink = useCallback(async () => {
    try {
      const response = await OnboardingApi.getCreatorQuickLinkInfoByUserId();
      form.link.forceUpdateValue(response.data.link);
      setForm({ ...form });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    getQuickAccessLink();
    fetchUserDetails();
  }, [fetchUserDetails]);

  // Copy to clipboard card
  const [form, setForm] = useState<IFormGroup>({
    link: new FormControl("text", []),
  });

  // Campaign Card
  return (
    <div className="emp-page-wrapper emp-responsive emp-white-bg-on-mobile">
      <ProfilePictureModal
        ref={profilePictureModalRef}
        onSave={() => {
          fetchUserDetails();
        }}
      />
      <div className="emp-page-content no-padding emp-creator-home-page emp-white-bg-on-mobile">
        <div className="context-section">
          <div className="colored-bg-section"></div>
          <div className="common-info-section">
            {/* Image Wrapper */}
            {user && (
              <div className="image-card">
                {user.imageType === "url" && (
                  <img alt="Profile" src={user.imageResource} />
                )}
                {user.imageType === "avatar" && (
                  <div
                    style={{ background: user.imageResource }}
                    className="avatar"
                  >
                    <span className="initials">{user.initials}</span>
                  </div>
                )}
                <div
                  onClick={() => {
                    profilePictureModalRef.current?.show(user);
                  }}
                  className="edit-btn"
                >
                  <EditIcon
                    top={-1}
                    backgroundColor={Color.NEUTRAL[300]}
                    size={16}
                  />
                </div>
              </div>
            )}
            {/* Name and Email */}
            {user && (
              <div className="info-wrapper">
                <span className="name-lbl">{user.fullName}</span>
                <span className="email-lbl mt-1">{user.email}</span>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-section mt-3">
          <div className="left-section">
            {/* Social Media Connection */}
            <CreatorHomeSocialMediaCard />

            {/* Creator Extended Info Section */}
            <CreatorHomeExtendedInfoCard />

            {/* Affliated Talent Agencies */}
            {user && (
              <CreatorPartneringAgenciesCard
                creator={user}
                onSave={() => {
                  fetchUserDetails();
                }}
              />
            )}
          </div>
          <div className="right-section">
            {/* Copy to Clipboard Card */}
            <div className="dashboard-card copy-to-clipboard">
              <h3 className="card-header">
                <FormattedMessage id="quickAccessCard_header" />
              </h3>
              <p className="card-description mt-2">
                <FormattedMessage id="quickAccessCard_desc" />
              </p>

              <div className="wrapper mt-4">
                <EmpTextInput
                  id={"link"}
                  labelText={
                    <FormattedMessage id="quickAccessCard_linkInputLabel" />
                  }
                  formControl={form.link}
                  disabled
                />
                <div className="button-wrapper">
                  <EmpIconButton
                    onSubmit={() => {
                      ToastUtils.success("Copied", "Quick Access Link Copied");
                      navigator.clipboard.writeText(form.link.getValue());
                    }}
                    buttonStyle="secondary"
                    icon={
                      <CopyIcon
                        backgroundColor={Color.NEUTRAL[500]}
                        size={isMobile ? 20 : 16}
                      />
                    }
                  />
                </div>
              </div>
            </div>

            {/* Campaign Activity */}
            {user && <CreatorHomeActiveTaskTable user={user} />}
          </div>
        </div>
      </div>
    </div>
  );
};
