import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpModal from "../../shared/emp-modal/emp-modal";
import XCloseIcon from "../../icon/x-close-icon";
import { Color } from "../../../utilities/colors";
import "./evidence-record-modal.scss";
import EmpButton from "../../shared/emp-button/emp-button";
import { TaskDto } from "../../../model/campaign/task.dto";
import { EvidenceRecordDto } from "../../../model/campaign/evidence-record.dto";
import { FormattedMessage } from "react-intl";
import {
  ATTACHMENT_TYPE,
  EVIDENCE_TYPE,
  GENERAL_TASK_MEDIA_TYPE,
  PROOF_OF_WORK_MEDIA_TYPE,
  SOCIAL_MEDIA_TYPE,
  TASK_TYPE,
} from "../../../constants/app.constants";
import { DateUtil } from "../../../utilities/date";
import EmpContent from "../../shared/emp-content/emp-content";
import EmpLink from "../../shared/emp-link/emp-link";
import DeleteModal, { DeleteModalRef } from "../delete-modal";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import {
  InstagramMedia,
  XTweet,
  FacebookPost,
  TiktokVideo,
} from "../../../model/campaign/social-media.dto";
import FileUtils from "../../../utilities/file-util";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import Barchart1Icon from "../../icon/barchart-1-icon";
import ChevronLeftIcon from "../../icon/chevron-left";
import ChevronRightIcon from "../../icon/chevron-right";
import CursorClickIcon from "../../icon/cursor-click-icon";
import EyeIcon from "../../icon/eye-icon";
import FacebookIcon from "../../icon/facebook-icon";
import FileIcon from "../../icon/file-icon";
import HeartIcon from "../../icon/heart-icon";
import InstagramIcon from "../../icon/instagram-icon";
import MessageIcon from "../../icon/message-icon";
import PlayIcon from "../../icon/play-icon";
import RepeatIcon from "../../icon/repeat-icon";
import ShareIcon from "../../icon/share-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import XIcon from "../../icon/x-icon";
import EmpPill from "../../shared/EmpPill/EmpPill";
import XTweetPollWidget from "../../views/x-tweet-poll-widget";
import Slider from "react-slick";
import { FacebookPow } from "../../proof-of-work-media/facebook-pow";
import { FacebookPowFallback } from "../../proof-of-work-media/facebook-pow-fallback";
import { InstagramPow } from "../../proof-of-work-media/instagram-pow";
import { InstagramPowFallback } from "../../proof-of-work-media/instagram-pow-fallback";
import { TikTokFallbackPow } from "../../proof-of-work-media/tiktok-fallback-pow";
import { TikTokPow } from "../../proof-of-work-media/tiktok-pow";
import { XPowFallback } from "../../proof-of-work-media/x-pow-fallback";
import { CampaignAnalyticsUtils } from "../../../utilities/campaign-analytics.util";
import CheckIcon from "../../icon/check-icon";
import BrokenLinkIcon from "../../icon/broken-link-icon";
import FormFieldUtils from "../../../utilities/form-field.util";

export interface EvidenceRecordModalRef {
  show: (evidenceRecord: EvidenceRecordDto) => void;
  dismiss: () => void;
}

interface Props {
  task: TaskDto;
  role: "brand" | "seller";
}

const EvidenceRecordModal = forwardRef((props: Props, ref) => {
  const { task, role } = props;
  const deleteModalRef = useRef<DeleteModalRef>();
  const sliderRef = useRef<Slider | null>(null);
  const [sliderDotIndex, setSliderDotIndex] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [subjectIsHovered, setSubjectIsHovered] = useState<boolean>(false);

  const [evidenceRecordDto, setEvidenceRecordDto] =
    useState<EvidenceRecordDto>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (displayEvidence: EvidenceRecordDto) => {
    setEvidenceRecordDto(displayEvidence);
    setVisible(true);
  };

  const dismiss = async () => {
    setEvidenceRecordDto(undefined);
    setVisible(false);
  };

  const handleSliderNavigation = (direction: "left" | "right") => {
    if (!sliderRef.current) {
      return;
    }
    if (direction === "left") {
      sliderRef.current.slickPrev();
    } else if (direction === "right") {
      sliderRef.current.slickNext();
    }
  };

  const renderSmLinkEvidence = useCallback((): JSX.Element => {
    if (!evidenceRecordDto) return <></>;
    const socialMediaObj = evidenceRecordDto.socialMediaObject!;
    const hasSocialMediaObject = evidenceRecordDto.hasSocialMediaObject;
    return (
      <>
        {evidenceRecordDto.platform === TASK_TYPE.INSTAGRAM && (
          <>
            {hasSocialMediaObject ? (
              <InstagramPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as InstagramMedia}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <InstagramPowFallback
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                type={"pow-modal-details"}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}

        {evidenceRecordDto.platform === TASK_TYPE.FACEBOOK && (
          <>
            {hasSocialMediaObject ? (
              <FacebookPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as FacebookPost}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <FacebookPowFallback
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                type={"pow-modal-details"}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}
        {evidenceRecordDto.platform === TASK_TYPE.TIKTOK && (
          <>
            {hasSocialMediaObject ? (
              <TikTokPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as TiktokVideo}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <TikTokFallbackPow
                type={"pow-modal-details"}
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}
        {evidenceRecordDto.platform === TASK_TYPE.X && (
          <XPowFallback
            type={"pow-modal-details"}
            profileSnapshot={evidenceRecordDto.profileSnapshot}
            mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
            evidenceRecord={evidenceRecordDto}
          />
        )}
      </>
    );
  }, [evidenceRecordDto]);

  const renderSmImageEvidence = useCallback((): JSX.Element => {
    if (evidenceRecordDto) {
      return (
        <>
          <div className="sm-content slider-enabled">
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-left-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("left");
                }}
              >
                <ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-right-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("right");
                }}
              >
                <ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            <Slider
              dots={false}
              arrows={false}
              infinite={true}
              adaptiveHeight={true}
              speed={500}
              beforeChange={(_, newIndex) => {
                setSliderDotIndex(newIndex);
              }}
              slidesToShow={1}
              ref={sliderRef}
              slidesToScroll={1}
            >
              {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map((elem) => {
                return (
                  <img
                    key={elem.reactKey}
                    className="sm-image"
                    alt="Uploaded evidence"
                    src={elem.url}
                  />
                );
              })}
            </Slider>
            {evidenceRecordDto.files.length > 1 && (
              <div className="sm-pagination-dots-wrapper">
                {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map(
                  (elem, index) => {
                    return (
                      <div
                        key={elem.reactKey}
                        className="sm-dot-clickable-area"
                        onClick={() => {
                          if (!sliderRef.current || sliderDotIndex === index)
                            return;
                          sliderRef.current.slickGoTo(index);
                        }}
                      >
                        <div
                          className={`sm-dot ${
                            sliderDotIndex === index ? "active" : ""
                          }`}
                        ></div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
          {(task.platform === TASK_TYPE.FACEBOOK ||
            (task.platform === TASK_TYPE.GENERAL &&
              evidenceRecordDto.mediaType ===
                GENERAL_TASK_MEDIA_TYPE.FACEBOOK_POST)) && (
            <div className="sm-metrics">
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.reactionCount}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.commentCount}</span>
              </div>
            </div>
          )}
          {(task.platform === TASK_TYPE.TIKTOK ||
            (task.platform === TASK_TYPE.GENERAL &&
              evidenceRecordDto.mediaType ===
                GENERAL_TASK_MEDIA_TYPE.TIKTOK_VIDEO)) && (
            <div className="sm-metrics">
              <div className="metric-wrapper">
                <PlayIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                  )}
                </span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                  )}
                </span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.commentCount}</span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.shareCount}</span>
              </div>
            </div>
          )}
          {(task.platform === TASK_TYPE.X ||
            (task.platform === TASK_TYPE.GENERAL &&
              evidenceRecordDto.mediaType ===
                GENERAL_TASK_MEDIA_TYPE.X_TWEET)) && (
            <div className="sm-metrics">
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecordDto.metricSnapshot!.commentCount}</span>
              </div>
              <div className="metric-wrapper">
                <RepeatIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecordDto.metricSnapshot!.shareCount}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                  )}
                </span>
              </div>
              <div className="metric-wrapper">
                <Barchart1Icon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                  )}
                </span>
              </div>
            </div>
          )}
          {((task.platform === TASK_TYPE.INSTAGRAM &&
            evidenceRecordDto.mediaType === "picture") ||
            (task.platform === TASK_TYPE.GENERAL &&
              evidenceRecordDto.mediaType ===
                GENERAL_TASK_MEDIA_TYPE.INSTAGRAM_POST)) && (
            <div className="sm-metrics">
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                  )}
                </span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.commentCount}</span>
              </div>
              {evidenceRecordDto.metricSnapshot!.viewCount && (
                <div className="metric-wrapper">
                  <PlayIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
          {((task.platform === TASK_TYPE.INSTAGRAM &&
            evidenceRecordDto.mediaType.startsWith("story")) ||
            (task.platform === TASK_TYPE.GENERAL &&
              evidenceRecordDto.mediaType ===
                GENERAL_TASK_MEDIA_TYPE.INSTAGRAM_STORY)) && (
            <div className="sm-metrics">
              <div className="metric-wrapper">
                <PlayIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                  )}
                </span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.reactionCount}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>{evidenceRecordDto.metricSnapshot!.commentCount}</span>
              </div>
            </div>
          )}
        </>
      );
    }
    return <></>;
  }, [evidenceRecordDto, sliderDotIndex, task]);

  const renderFileAttachment = useCallback((): JSX.Element => {
    if (!evidenceRecordDto) return <></>;
    if (evidenceRecordDto.files.length === 0) return <></>;
    const firstFile = evidenceRecordDto.files[0];
    return (
      <div className="sm-content slider-enabled">
        {firstFile.fileType === ATTACHMENT_TYPE.IMAGE && (
          <>
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-left-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("left");
                }}
              >
                <ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-right-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("right");
                }}
              >
                <ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            <Slider
              dots={false}
              arrows={false}
              infinite={true}
              adaptiveHeight={true}
              speed={500}
              beforeChange={(oldIndex, newIndex) => {
                setSliderDotIndex(newIndex);
              }}
              slidesToShow={1}
              ref={sliderRef}
              slidesToScroll={1}
            >
              {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map((elem) => {
                return (
                  <div
                    className="attachment-image"
                    onMouseLeave={() => {
                      setSubjectIsHovered(false);
                    }}
                    onMouseEnter={() => {
                      setSubjectIsHovered(true);
                    }}
                  >
                    <div
                      className={`attachment-overlay ${
                        subjectIsHovered ? "hovered" : ""
                      }`}
                    >
                      <div className="overlay-content-wrapper">
                        <span className="overlay-filename">
                          {firstFile.name}
                        </span>
                        <EmpPill {...PILL_COLORS.primary} text={"Video"} />
                        <div className="download-wrapper">
                          <EmpLink
                            linkStyle="white"
                            text={"Download"}
                            href={firstFile.url}
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      key={elem.reactKey}
                      className="sm-image"
                      alt="Uploaded evidence"
                      src={elem.url}
                    />
                  </div>
                );
              })}
            </Slider>
            {evidenceRecordDto.files.length > 1 && (
              <div className="sm-pagination-dots-wrapper">
                {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map(
                  (elem, index) => {
                    return (
                      <div
                        key={elem.reactKey}
                        className="sm-dot-clickable-area"
                        onClick={() => {
                          if (!sliderRef.current || sliderDotIndex === index)
                            return;
                          sliderRef.current.slickGoTo(index);
                        }}
                      >
                        <div
                          className={`sm-dot ${
                            sliderDotIndex === index ? "active" : ""
                          }`}
                        ></div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </>
        )}
        {firstFile.fileType === ATTACHMENT_TYPE.FILE && (
          <div className="attachment-file">
            <FileIcon size={22} backgroundColor={Color.NEUTRAL[500]} />
            <span className="filename">
              {firstFile.name}{" "}
              <span className="size">
                ({FileUtils.convertBytesToReadableSize(firstFile.size)})
              </span>
            </span>
            <EmpLink text={"Download File"} href={firstFile.url} />
          </div>
        )}
        {firstFile.fileType === ATTACHMENT_TYPE.VIDEO && (
          <div className="attachment-video">
            <div
              className="attachment-video"
              onMouseLeave={() => {
                setSubjectIsHovered(false);
              }}
              onMouseEnter={() => {
                setSubjectIsHovered(true);
              }}
            >
              <div
                className={`attachment-overlay ${
                  subjectIsHovered ? "hovered" : ""
                }`}
              >
                <div className="overlay-content-wrapper">
                  <span className="overlay-filename">{firstFile.name}</span>
                  <EmpPill {...PILL_COLORS.primary} text={"Video"} />
                  <div className="download-wrapper">
                    <EmpLink
                      linkStyle="white"
                      text={"Download"}
                      href={firstFile.url}
                    />
                  </div>
                </div>
              </div>
              <video src={firstFile.url} controls loop>
                {" "}
              </video>
            </div>
          </div>
        )}
      </div>
    );
  }, [evidenceRecordDto, sliderDotIndex, subjectIsHovered]);
  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      verticalPadding={false}
      showHeaderBorder={false}
      modalGlobalElement={
        <button onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </button>
      }
      size={"sm"}
    >
      <div className="emp-evidence-record-modal">
        <div className="modal-shine-border">
          {evidenceRecordDto && (
            <section className="evidence-details-view">
              <DeleteModal
                ref={deleteModalRef}
                onSave={() => {}}
                suppressToast
              />
              <div className="header-section ph-5 pv-3">
                <div className="info-wrapper">
                  <h2 className="section-title">View Proof of Work</h2>
                  <p className="emp-paragraph mt-2">
                    View Proof of work that you have submitted
                  </p>
                </div>
              </div>
              <section>
                {evidenceRecordDto.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && renderSmLinkEvidence()}
                {evidenceRecordDto.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE && renderSmImageEvidence()}
                {evidenceRecordDto.evidenceType === EVIDENCE_TYPE.ATTACHMENT &&
                  renderFileAttachment()}
                <div className="details-section mt-4">
                  <div className="split-form-control">
                    <EmpContent
                      label={"SUBMISSION TYPE"}
                      value={CampaignAnalyticsUtils.mapEvidenceName(
                        evidenceRecordDto.evidenceType
                      )}
                    />
                  </div>
                  {evidenceRecordDto.evidenceType ===
                    EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
                    <div className="split-form-control">
                      <EmpContent
                        label={"STATUS"}
                        value={
                          <>
                            {evidenceRecordDto.hasSocialMediaObject &&
                              evidenceRecordDto.platform !==
                                SOCIAL_MEDIA_TYPE.X && (
                                <EmpPill
                                  Icon={CheckIcon}
                                  {...PILL_COLORS.green}
                                  text={"Live Data"}
                                  tooltip={`This data is pulled directly from the ${evidenceRecordDto.platform} API. Refresh the page to access the most up-to-date information.`}
                                />
                              )}

                            {evidenceRecordDto.platform ===
                              SOCIAL_MEDIA_TYPE.X && (
                              <EmpPill
                                Icon={CheckIcon}
                                {...PILL_COLORS.green}
                                text={`Updated ${DateUtil.toPeriod(
                                  evidenceRecordDto.metricSnapshot!.updatedDate
                                )}`}
                                tooltip={`The metrics for this post were last updated ${DateUtil.toPeriod(
                                  evidenceRecordDto.metricSnapshot!.updatedDate
                                )}. To view the most recent Twitter data, you can trigger a refresh for the latest metrics.`}
                              />
                            )}
                            {!evidenceRecordDto.hasSocialMediaObject &&
                              evidenceRecordDto.platform !==
                                SOCIAL_MEDIA_TYPE.X && (
                                <>
                                  {![
                                    PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                                    PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                                  ].includes(evidenceRecordDto.mediaType) && (
                                    <EmpPill
                                      {...PILL_COLORS.gray}
                                      Icon={BrokenLinkIcon}
                                      text={"Archived"}
                                      tooltip="This user's social media account used for submitting proof of work has been disconnected. The metrics shown here were last updated at least 12 hours prior to the disconnection. For detailed metrics, please click the link to view the original post."
                                    />
                                  )}
                                  {[
                                    PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                                    PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                                  ].includes(evidenceRecordDto.mediaType) && (
                                    <EmpPill
                                      {...PILL_COLORS.gray}
                                      Icon={BrokenLinkIcon}
                                      text={"Story Expired"}
                                      tooltip="Instagram Stories are available for 24 hours and has now expired. We took a final snapshot of its metrics one hour before expiration."
                                    />
                                  )}
                                </>
                              )}
                          </>
                        }
                      />
                    </div>
                  )}
                  <div className="split-form-control">
                    <EmpContent
                      label={"CREATED DATE"}
                      value={DateUtil.toReadableDateWithTime(
                        evidenceRecordDto.createdDate
                      )}
                    />
                  </div>
                  {evidenceRecordDto.evidenceType ===
                    EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
                    <div className="split-form-control">
                      <EmpContent
                        label={"LAST REFRESHED"}
                        value={
                          evidenceRecordDto.hasSocialMediaObject
                            ? "Just now"
                            : DateUtil.toPeriod(
                                evidenceRecordDto.metricSnapshot!.updatedDate
                              )
                        }
                      />
                    </div>
                  )}
                  <div className="split-form-control">
                    <EmpContent
                      label={"REMARKS"}
                      value={
                        evidenceRecordDto.remarks.length === 0
                          ? "Submittor has not added any remarks"
                          : evidenceRecordDto.remarks
                      }
                    />
                  </div>
                  {[
                    EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE,
                    EVIDENCE_TYPE.SOCIAL_MEDIA_LINK,
                  ].includes(evidenceRecordDto.evidenceType) && (
                    <>
                      {![
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                      ].includes(evidenceRecordDto.mediaType) && (
                        <div className="split-form-control">
                          <EmpContent
                            label={"LINK TO POST"}
                            value={
                              evidenceRecordDto.mediaUrl ? (
                                <EmpLink
                                  newTab
                                  showNewTabIcon
                                  text={"Link to post"}
                                  href={evidenceRecordDto.mediaUrl}
                                />
                              ) : (
                                "No Link Provided"
                              )
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </section>
              <div
                className="mt-6 ph-5 pv-3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 10,
                  borderTop: `1px solid ${Color.NEUTRAL[800]}`,
                }}
              >
                <EmpButton
                  isFullWidth={false}
                  text={<FormattedMessage id="cta_done" />}
                  onSubmit={dismiss}
                />
              </div>
            </section>
          )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default EvidenceRecordModal;
