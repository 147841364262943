import { EXCEPTION_TYPE } from "../constants/exceptionType";

class EmpException extends Error {
  type: string;
  constructor(message: string, exceptionType?: string) {
    super(message);
    this.type = exceptionType ?? EXCEPTION_TYPE.GENERIC_ERROR;
    this.name = "Emp Exception";
  }
}
export default EmpException;
