import React, { useEffect, useRef, useState } from "react";
import EmpException from "../../../exception/empException";
import { SelectOption } from "../../../model/common/selectOption";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import ChevronDownIcon from "../../icon/chevron-down";
import EmpCheckbox, { EmpCheckboxRef } from "../emp-checkbox/emp-checkbox";
import "./emp-multi-select.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;

  labelText?: string;
  value?: string;
  placeholder?: string;
  initialValue?: string;
  selectOptions: SelectOption[];
  required?: boolean;

  // EmpTextInput Form Integration
  id: string;
  formControl: FormControl;
  formValidation?: () => void;

  // Callbacks to the parent component
  onChange?: (formControl: FormControl) => void;
}
const EmpMultiSelect = (props: Props) => {
  const {
    onChange,
    required,
    selectOptions,
    style,
    className,
    id,
    labelText,
    placeholder,
    initialValue,
    formControl,
  } = props;
  // const value = initialValue ? initialValue : ""

  const formControlRef = useRef<HTMLDivElement>(null);
  const hiddenValueRef = useRef<HTMLSpanElement>(null);
  const multiSelectRef = useRef<HTMLDivElement>(null);

  const isExpandedRef = useRef<boolean>(false);
  const isRequired = required ? required : false;

  const [fullFormattedMessage, setFullFormattedMessage] = useState<string>();
  const [displayMessage, setDisplayMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const valueList = useRef<Set<string>>(
    initialValue ? new Set<string>(initialValue) : new Set<string>()
  );
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    const resize = () => {
      adjustDisplayMessage();
    };
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  // This function is called when the component is mounted and whenever the
  // isExpanded prop changes.
  useEffect(() => {
    isExpandedRef.current = isExpanded;
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!multiSelectRef.current) return;

      if (
        multiSelectRef.current &&
        !multiSelectRef.current.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formControlRef]);

  const valueFormatter = () => {
    const formattedString: string[] = [];
    valueList.current.forEach((elem) => {
      formattedString.push(
        selectOptions.find((options) => options.value === elem)?.label ?? ""
      );
    });
    setFullFormattedMessage(formattedString.join(", "));
  };

  useEffect(() => {
    adjustDisplayMessage();
  }, [fullFormattedMessage]);

  const adjustDisplayMessage = () => {
    if (!hiddenValueRef.current || !formControlRef.current)
      throw new Error("Unable to get value");
    let message = "";
    if (
      hiddenValueRef.current.clientWidth >
      formControlRef.current?.clientWidth - 30
    ) {
      message = `${valueList.current.size} options selected.`;
    } else {
      message = fullFormattedMessage!;
    }
    setDisplayMessage(message);
  };

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  const handleOnChange = (value: boolean, id: string) => {
    if (value) {
      valueList.current.add(id);
    } else {
      valueList.current.delete(id);
    }
    valueFormatter();
    formControl.setValue(Array.from(valueList.current));

    // // Propagate this change to the parent component
    if (onChange) onChange(formControl);
  };

  return (
    <div
      ref={multiSelectRef}
      className={`emp-multi-select-wrapper ${className}`}
      style={style}
    >
      <label className="block emp-multi-select-label" htmlFor={id}>
        {labelText} {isRequired && <span className="required">*</span>}
      </label>
      <div
        onClick={() => {
          setExpanded(true);
        }}
        ref={formControlRef}
        className="emp-form-control"
      >
        <div className="emp-select-icon">
          <ChevronDownIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
        </div>
        <div style={{ width: "100%" }} className="emp-multi-select">
          {fullFormattedMessage && (
            <span className="multi-select-value">{displayMessage}</span>
          )}
          {!fullFormattedMessage && (
            <span className="placeholder">{placeholder}</span>
          )}
          <span ref={hiddenValueRef} className="hidden-value">
            {fullFormattedMessage}
          </span>
        </div>
      </div>
      {/* Multi option select area */}
      {isExpanded && (
        <div className="emp-multi-select-section">
          {selectOptions.map((elem) => {
            return (
              <EmpMultiSelectItem
                valueList={valueList.current}
                key={elem.value}
                handleOnChange={handleOnChange}
                elem={elem}
              />
            );
          })}
        </div>
      )}
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[400]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
export default EmpMultiSelect;

interface MultiSelectItemProp {
  handleOnChange: (isSelected: boolean, value: string) => void;
  elem: SelectOption;
  valueList: Set<string>;
}

const EmpMultiSelectItem = (props: MultiSelectItemProp) => {
  const { handleOnChange, elem, valueList } = props;
  const checkboxRef = useRef<EmpCheckboxRef>();

  const isSelected = useRef<boolean>(valueList.has(elem.value));

  // Determine whether the component should be checked or not
  useEffect(() => {
    try {
      if (!checkboxRef.current)
        throw new EmpException("Unable to get checkbox component");
      if (valueList.has(elem.value)) {
        checkboxRef.current.setChecked(true);
      }
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    }
  }, []);

  const checkboxWrapperOnClick = () => {
    try {
      if (!checkboxRef.current)
        throw new EmpException("Unable to get checkbox component");

      const selectedState = isSelected.current ? false : true;
      checkboxRef.current.setChecked(selectedState);
      isSelected.current = selectedState;
      handleOnChange(selectedState, elem.value);
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    }
  };

  return (
    <div
      onClick={() => {
        checkboxWrapperOnClick();
      }}
      className="checkbox-wrapper"
    >
      <EmpCheckbox
        ref={checkboxRef}
        disableControl
        id={elem.value}
        labelText={elem.label}
      />
      <div className="click-zone"></div>
    </div>
  );
};
