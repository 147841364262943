import { motion } from "framer-motion";
import _debounce from "lodash/debounce";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import InstagramApi from "../../api/social-integration-msvc/instagram.api";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import { PermsUtils } from "../../constants/permissions.constants";
import { AppContext } from "../../context/app.context";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { IgMediaInfoRespDto } from "../../model/instagram/ig-media-info-resp.dto";
import { RateCardDetailsDto } from "../../model/social-media/rate-card-details-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import { DateUtil } from "../../utilities/date";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { ReactKeyUtil } from "../../utilities/react-key.util";
import StringUtils from "../../utilities/string.util";
import TranslationUtil from "../../utilities/translation.util";
import UserUtils from "../../utilities/user-utils";
import EyeIcon from "../icon/eye-icon";
import HeartIcon from "../icon/heart-icon";
import HeartsIcon from "../icon/hearts-icon";
import ImageIcon from "../icon/image-icon";
import InstagramIcon from "../icon/instagram-icon";
import MessageIcon from "../icon/message-icon";
import SettingsIcon from "../icon/settings-icon";
import UsersIcon from "../icon/users-icon";
import RateCardModal, { RateCardModalRef } from "../modals/rate-card-modal";
import RateCardReadonlyModal, {
  RateCardReadonlyModalRef,
} from "../modals/rate-card-readonly-modal";
import RateCardRequestModal, {
  RateCardRequestModalRef,
} from "../modals/rate-privacy-request-modal";
import RatePrivacySettingModal, {
  RateCardPrivacyModalRef,
} from "../modals/rate-privacy-setting-modal";
import EmpButton from "../shared/emp-button/emp-button";
import EmpContent from "../shared/emp-content/emp-content";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import EmpLink from "../shared/emp-link/emp-link";
import EmpPill from "../shared/EmpPill/EmpPill";
import "./instagram-view.scss";
import { Tooltip, Whisper } from "rsuite";
import InfoCircleIcon from "../icon/info-circle-icon";
import FormFieldUtils from "../../utilities/form-field.util";
import { useCountGridColumns } from "../../hooks/useCountGridColumns";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import PlayIcon from "../icon/play-icon";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import EmpLoaderV2 from "../shared/emp-loader-v2/emp-loader-v2";

interface Props {
  id: string;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const DELAY = 0.1;

const InstagramView = (props: Props) => {
  const { id } = props;
  const intl = useIntl();
  const location = useLocation();

  const [user, setUser] = useState<UserDto>();
  const { user: userContext } = useContext(AppContext);
  const roleRef = useRef<string>("");

  const isMobile = useEmpBreakpoint(500);
  const [igBasicProfile, setIgBasicProfile] = useState<IgMediaInfoRespDto>();
  const [igNextPageUrl, setIgNextPageUrl] = useState<string>();
  const [isInfiniteScrollLoading, setInfiniteScrollLoading] = useState(false);

  // Multiple rate cards that can be viewed by brands
  const [myRateCard, setMyRateCard] = useState<RateCardDetailsDto>();

  // Hidden rate card notice for agencies
  const [externalRatecards, setExternalRatecards] = useState<
    RateCardDetailsDto[]
  >([]);

  const rateCardReadonlyModalRef = useRef<RateCardReadonlyModalRef>();
  const rateCardModalRef = useRef<RateCardModalRef>();
  const rateCardPrivacySettingRef = useRef<RateCardPrivacyModalRef>();
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const lastElementRef = useRef<HTMLDivElement>(null);

  const hasMoreRef = useRef(false);
  const [isDataMounted, setDataMounted] = useState(false);
  const shouldFetchMoreMedia = useInfiniteScroll(
    isDataMounted,
    lastElementRef,
    hasMoreRef
  );

  const fetchIgPaginatedMedia = useCallback(
    async (paginatedUrl: string) => {
      try {
        setInfiniteScrollLoading(true);
        setDataMounted(false);
        const resp = await InstagramApi.getPaginatedMedia(id!, paginatedUrl);
        const data = resp.data;

        if (igBasicProfile) {
          igBasicProfile.media = [...igBasicProfile.media, ...data.data];
        }
        setIgBasicProfile({ ...(igBasicProfile as IgMediaInfoRespDto) });
        setIgNextPageUrl(data.nextPage ?? undefined);
        hasMoreRef.current = Boolean(data.nextPage);
        setDataMounted(true);
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Unable to fetch Ig Paginated Media"
        );
      } finally {
        setInfiniteScrollLoading(false);
      }
    },
    [igBasicProfile, id]
  );

  useEffect(() => {
    console.log({ shouldFetchMoreMedia });
    if (shouldFetchMoreMedia && igNextPageUrl) {
      fetchIgPaginatedMedia(igNextPageUrl);
    }
  }, [shouldFetchMoreMedia, fetchIgPaginatedMedia, igNextPageUrl]);

  const setMyRateCards = (rateCardDetails: RateCardDetailsDto[]) => {
    const rateCardBelongsToMe = rateCardDetails.find((elem) => elem.isMe);
    setMyRateCard(rateCardBelongsToMe);
  };

  const determineExternalRateCards = (
    rateCardDetails: RateCardDetailsDto[]
  ) => {
    const externalOrgRateCards = [];
    for (let rateCardDetail of rateCardDetails) {
      if (!rateCardDetail.isMe && rateCardDetail.isViewable) {
        externalOrgRateCards.push(rateCardDetail);
      }
    }
    setExternalRatecards(externalOrgRateCards);
  };

  useEffect(() => {
    roleRef.current = location.pathname.split("/")[1];
    const fetchUserDetails = async () => {
      setUser(await UserUtils.fetchUser(userContext));
    };
    const fetchInstagramProfile = async () => {
      const resp = await InstagramApi.basicProfile(id);
      setIgBasicProfile(resp.data);
      setIgNextPageUrl(resp.data.nextPage);
      hasMoreRef.current = Boolean(resp.data.nextPage);
      setDataMounted(true);
    };
    fetchRateCard();
    fetchInstagramProfile();
    fetchUserDetails();
  }, [id]);

  const fetchRateCard = useCallback(async () => {
    try {
      const resp = await RateCardApi.getRateCardWRole(
        id,
        "Instagram",
        roleRef.current
      );
      const rateCardDetails = resp.data;
      setMyRateCards(rateCardDetails);
      determineExternalRateCards(rateCardDetails);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [id]);

  const rateCardRequestModalRef = useRef<RateCardRequestModalRef>();

  /**
   * Get the rate card description based on the user's role.
   * @returns {string} The rate card description.
   */
  const getRateCardDescription = useCallback(
    (rateCardPrivacy: boolean) => {
      if (!user) return <span></span>;
      const role = PermsUtils.getUserRole(user);
      if (role === "agency" && rateCardPrivacy)
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_agency_rateCardCardPrivacyOnDesc"
            />
          </>
        );
      if (role === "agency" && !rateCardPrivacy)
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_agency_rateCardCardPrivacyOffDesc"
            />
          </>
        );
      else if (role === "creator")
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_creator_rateCardCardDesc"
            />
          </>
        );
      return <span></span>;
    },
    [user]
  );

  const renderRateCardOwner = (rateCardOwner: string): JSX.Element => {
    if (rateCardOwner === "agency")
      return <FormattedMessage id="sm_rateCardOwnerAgency" />;
    else if (rateCardOwner === "creator")
      return <FormattedMessage id="sm_rateCardOwnerCreator" />;
    return <></>;
  };

  const externalRateCardGridRef = useRef<HTMLDivElement>(null);
  const gridColumns = useCountGridColumns(externalRateCardGridRef);

  const remainingColumns = useMemo(() => {
    return gridColumns - (externalRatecards.length % gridColumns);
  }, [externalRatecards, gridColumns]);

  return (
    <div className="emp-instagram-view ">
      <RateCardReadonlyModal ref={rateCardReadonlyModalRef} />
      <RateCardModal
        ref={rateCardModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RateCardRequestModal
        ref={rateCardRequestModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RatePrivacySettingModal
        onSave={() => fetchRateCard()}
        ref={rateCardPrivacySettingRef}
      />
      <div className="social-media-label mt-3">
        <InstagramIcon size={18} backgroundColor={Color.NEUTRAL[200]} />
        <h1>Instagram</h1>
      </div>
      {igBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 0, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smInstagram_followersLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCompact(igBasicProfile.followers)}
              </span>
              <div className="icon-wrapper">
                {" "}
                <UsersIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 1, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smInstagram_postsLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCommify(igBasicProfile.mediaCount)}
              </span>
              <div className="icon-wrapper">
                <ImageIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 2, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <EyeIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">
                  <FormattedMessage id="smInstagram_impressionsLabel" />
                </span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Total number of times the user's post have been viewed in
                      the past 28 days
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>
              <span className="value-lbl">
                {FormFieldUtils.toCompact(igBasicProfile.impressions)}
              </span>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 3, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <HeartsIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">
                  <FormattedMessage id="smInstagram_reachLabel" />
                </span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Total number of unique users in the past 28 days who have
                      viewed at least one of the user's post. Repeat views and
                      views across different posts are counted as a single view.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>

              <span className="value-lbl">
                {FormFieldUtils.toCompact(igBasicProfile.reach)}
              </span>
            </motion.div>
          </div>
        </div>
      )}
      {!igBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
          </div>
        </div>
      )}
      {myRateCard && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_rateCardHeader" />
          </h1>
        </div>
      )}
      {myRateCard && (
        <div className={`emp-card rates-section`}>
          <div className="header-wrapper">
            <div>
              <div className="title-wrapper">
                <h2 className="emp-card-header">
                  <FormattedMessage id="sm_rateCardCardHeader" />
                </h2>
                {myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardHiddenPill" />
                      }
                    />
                  )}
                {!myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardVisiblePill" />
                      }
                    />
                  )}
              </div>

              {myRateCard.isEditable && (
                <p className="emp-card-description">
                  {getRateCardDescription(myRateCard.isRateCardPrivacy)}
                </p>
              )}
            </div>
            {!isMobile && myRateCard.isEditable && (
              <div className="rates-btn-wrapper">
                {roleRef.current === "agency" && (
                  <EmpIconButton
                    onSubmit={() => {
                      rateCardPrivacySettingRef.current?.show();
                    }}
                    buttonStyle="secondary"
                    icon={<SettingsIcon backgroundColor={Color.NEUTRAL[500]} />}
                  />
                )}
                <EmpButton
                  text={<FormattedMessage id="sm_rateCardEditRatesBtn" />}
                  onSubmit={() => {
                    rateCardModalRef.current?.show(id, "Instagram");
                  }}
                  isFullWidth={false}
                  buttonHeight="sm"
                />
              </div>
            )}
            {!myRateCard.isEditable && (
              <EmpButton
                text={"View Detailed Rates"}
                isFullWidth={false}
                buttonHeight="sm"
              />
            )}
          </div>
          <div className="rates-wrapper mt-2">
            {myRateCard.rateCards.map((rc, index) => {
              return (
                <div className="rate" key={rc.rateId}>
                  {rc.origin === "Template" && (
                    <EmpContent
                      label={TranslationUtil.translateRateCardName(
                        intl,
                        "Instagram",
                        rc.rateName
                      )}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {rc.origin === "Custom" && (
                    <EmpContent
                      label={rc.rateName}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {!isMobile && index < myRateCard.rateCards.length - 1 && (
                    <div className="divider"></div>
                  )}
                </div>
              );
            })}
          </div>
          {isMobile && myRateCard.isEditable && (
            <div className="mt-4">
              <EmpButton
                text={"Edit Rates"}
                onSubmit={() => {
                  rateCardModalRef.current?.show(id, "Instagram");
                }}
                isFullWidth={true}
                buttonHeight="sm"
              />
            </div>
          )}
        </div>
      )}

      {externalRatecards.length > 0 && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_externalRateCardHeader" />
          </h1>
        </div>
      )}
      <div className="external-rates-section" ref={externalRateCardGridRef}>
        {externalRatecards.length > 0 &&
          ReactKeyUtil.addReactKey(externalRatecards).map((elem) => {
            if (
              elem.isRateCardPrivacy === false ||
              (elem.isRateCardPrivacy === true &&
                elem.rateCardRequestStatus === "approved")
            ) {
              return (
                <div
                  key={elem.reactKey}
                  className="emp-card no-padding external-rate-card"
                >
                  <div className="header-section">
                    {elem.rateCardOwner === "agency" && (
                      <img
                        className="pic"
                        alt="external subject logo"
                        src={elem.organisation.logo}
                      />
                    )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "url" && (
                        <img
                          className="pic"
                          alt="external subject logo"
                          src={elem.creator.imageResource}
                        />
                      )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "avatar" && (
                        <div
                          className="avatar"
                          style={{ background: elem.creator.imageResource }}
                        >
                          <span>{elem.creator.initials}</span>
                        </div>
                      )}
                    <div className="details-section">
                      {elem.rateCardOwner === "creator" && (
                        <span className="name-lbl">
                          {elem.creator.fullName}
                        </span>
                      )}
                      {elem.rateCardOwner === "agency" && (
                        <span className="name-lbl">
                          {elem.organisation.companyName}
                        </span>
                      )}

                      <EmpPill
                        text={renderRateCardOwner(elem.rateCardOwner)}
                        backgroundColor={Color.PRIMARY[100]}
                        color={Color.PRIMARY[600]}
                      />
                    </div>
                  </div>
                  {elem.rateCardExpiryDate && (
                    <div
                      className="rates-section"
                      style={{
                        borderBottom: `1px solid ${Color.NEUTRAL[100]}`,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <p className="emp-paragraph">
                        Expires {DateUtil.toPeriod(elem.rateCardExpiryDate)}
                      </p>
                    </div>
                  )}
                  <div className="rates-breakdown-section mt-4">
                    {elem.rateCards
                      .filter((rc) => rc.origin === "Template")
                      .map((rc) => {
                        return (
                          <Fragment key={rc.rateId}>
                            <EmpContent
                              label={TranslationUtil.translateRateCardName(
                                intl,
                                "X",
                                rc.rateName
                              )}
                              value={
                                rc.price > 0 ? (
                                  `${rc.price.toFixed(2)} USD`
                                ) : (
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: Color.NEUTRAL[400],
                                    }}
                                  >
                                    <FormattedMessage id="sm_notSetLabel" />
                                  </span>
                                )
                              }
                            />
                          </Fragment>
                        );
                      })
                      .slice(0, 2)}
                  </div>
                  <div className="btn-section">
                    <EmpButton
                      buttonHeight="sm"
                      buttonStyle="secondary"
                      className="mt-4"
                      onSubmit={() => {
                        rateCardReadonlyModalRef.current?.show(
                          id,
                          "Instagram",
                          elem.organisation?.id
                        );
                      }}
                      text={<FormattedMessage id="sm_rateCardMoreRatesBtn" />}
                    />
                  </div>
                </div>
              );
            } else if (elem.isRateCardPrivacy === true) {
              return (
                <div
                  key={elem.reactKey}
                  className="emp-card no-padding external-rate-card"
                >
                  <div className="header-section">
                    {elem.rateCardOwner === "agency" && (
                      <img
                        className="pic"
                        alt="external subject logo"
                        src={elem.organisation.logo}
                      />
                    )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "url" && (
                        <img
                          className="pic"
                          alt="external subject logo"
                          src={elem.creator.imageResource}
                        />
                      )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "avatar" && (
                        <div
                          className="avatar"
                          style={{ background: elem.creator.imageResource }}
                        >
                          <span>{elem.creator.initials}</span>
                        </div>
                      )}
                    <div className="details-section">
                      {elem.rateCardOwner === "creator" && (
                        <span className="name-lbl">
                          {elem.creator.fullName}
                        </span>
                      )}
                      {elem.rateCardOwner === "agency" && (
                        <span className="name-lbl">
                          {elem.organisation.companyName}
                        </span>
                      )}

                      <EmpPill
                        text={StringUtils.convertHyphensToTitleCase(
                          elem.rateCardOwner
                        )}
                        backgroundColor={Color.PRIMARY[100]}
                        color={Color.PRIMARY[600]}
                      />
                    </div>
                  </div>
                  <div className="rates-breakdown-section mt-4 hidden">
                    {elem.rateCardRequestStatus === "hidden" && (
                      <span>Agency has chosen to hide its rates</span>
                    )}
                    {elem.rateCardRequestStatus === "pending" && (
                      <span>
                        You have requested to view the rates from the agency.
                        Please wait for their approval
                      </span>
                    )}
                    {elem.rateCardRequestStatus === "rejected" && (
                      <span>
                        Your request to view this rate card has been rejected by
                        the Agency
                      </span>
                    )}
                    {elem.rateCardRequestStatus === "expired" && (
                      <span>
                        Your request to view rate card has already expired
                      </span>
                    )}
                  </div>
                  <div className="btn-section">
                    <EmpButton
                      buttonHeight="sm"
                      className="mt-4"
                      onSubmit={() => {
                        rateCardRequestModalRef.current?.show(
                          elem.organisation.id,
                          elem.creator.id
                        );
                      }}
                      text={"Request for rates"}
                    />
                  </div>
                </div>
              );
            }
          })}

        {!isMobile &&
          externalRatecards.length > 0 &&
          Array.from(Array(remainingColumns).keys()).map((elem) => {
            return (
              <div key={elem} className="external-empty-rate-card">
                <span className="label">
                  <FormattedMessage id="sm_externalRateCardEmptyCard" />
                </span>
              </div>
            );
          })}
      </div>

      <div className="social-media-label  mt-3">
        <h1>
          <FormattedMessage id="sm_recentPostHeader" />
        </h1>
      </div>
      {igBasicProfile && (
        <div className="social-media-post-section mt-2">
          {igBasicProfile.media.map((elem, index) => {
            return (
              <motion.a
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.2 }}
                href={elem.permalink}
                target="_blank"
                rel="noreferrer"
                key={elem.permalink}
                className={`ig-card`}
              >
                <div className="img-wrapper">
                  <div className="post-type-pill">
                    <EmpPill
                      text={StringUtils.toTitleCase(elem.mediaProductType)}
                      {...PILL_COLORS.primary}
                    />
                  </div>

                  {(elem.mediaType === "IMAGE" ||
                    elem.mediaType === "CAROUSEL_ALBUM") && (
                    <img src={elem.mediaUrl} alt={`instagram post ${index}`} />
                  )}
                  {elem.mediaType === "VIDEO" && (
                    <video muted loop src={elem.mediaUrl}></video>
                  )}
                </div>
                <div className="info-section">
                  <div className="metrics-wrapper">
                    {elem.mediaProductType === "FEED" && (
                      <div className="metric">
                        <EyeIcon
                          backgroundColor={Color.NEUTRAL[300]}
                          size={16}
                        />
                        <span className="metric-value">
                          {elem.impressionCount}
                        </span>
                      </div>
                    )}
                    {elem.mediaProductType === "REELS" && (
                      <div className="metric">
                        <PlayIcon
                          backgroundColor={Color.NEUTRAL[300]}
                          size={16}
                        />
                        <span className="metric-value">{elem.playCount}</span>
                      </div>
                    )}
                    <div className="metric">
                      <HeartIcon
                        backgroundColor={Color.NEUTRAL[300]}
                        size={16}
                      />
                      <span className="metric-value">
                        {FormFieldUtils.toCompact(elem.likeCount)}
                      </span>
                    </div>
                    <div className="metric">
                      <MessageIcon
                        backgroundColor={Color.NEUTRAL[300]}
                        size={16}
                      />
                      <span className="metric-value">
                        {FormFieldUtils.toCommify(elem.commentsCount)}
                      </span>
                    </div>
                  </div>
                  <p className="caption">{elem.caption}</p>
                </div>
              </motion.a>
            );
          })}
          {/* Last Element to track for infinite scrolling */}
          <div ref={lastElementRef}></div>
        </div>
      )}
      {isInfiniteScrollLoading && (
        <div className="infinite-scroll-loader-wrapper ">
          <EmpLoaderV2 isLoading={true} loadingText="Fetching more Posts..." />
        </div>
      )}
      {/* Empty State */}
      {!igBasicProfile && (
        <div className="social-media-post-section mt-2">
          {shimmerCard.map((elem, index) => {
            return <div key={elem} className={`empty-card emp-shimmer`}></div>;
          })}
        </div>
      )}
    </div>
  );
};
export default InstagramView;
