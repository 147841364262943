import { Location, useNavigate } from "react-router-dom";
import { AUTH_ERR_CODES } from "../constants/app.constants";
import { useCallback, useContext } from "react";
import { signOut as awsSignOut, getCurrentUser } from "aws-amplify/auth";
import { AppContext } from "../context/app.context";

type AuthErrorMessageType = {
  title: string;
  description: string;
};

type AuthErrorCode = (typeof AUTH_ERR_CODES)[keyof typeof AUTH_ERR_CODES];
const ERROR_MESSAGE_MAPPER: { [key in AuthErrorCode]: AuthErrorMessageType } = {
  [AUTH_ERR_CODES.EMAIL_NOT_WHITELISTED]: {
    title: "Account not whitelisted.",
    description:
      "Your email address is not on our approved list. Please contact support or use a different email to create a new account.",
  },
  [AUTH_ERR_CODES.USER_EXIST]: {
    title: "Account Already Exists",
    description:
      "An account with this email address already exists. Please log in with your existing account or use the reset password option to regain access.",
  },
};
const useEmpAuth = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);
  const getErrorCodeToMessageMapper = useCallback(
    (errorCode: AuthErrorCode) => {
      return ERROR_MESSAGE_MAPPER[errorCode];
    },
    []
  );

  const getErrorCode = useCallback((location: Location) => {
    const searchParams = new URLSearchParams(location.search);
    const errorDescription = searchParams.get("error_description");

    if (errorDescription) {
      const match = errorDescription.match(/AUTH\d{3}/);
      return match ? match[0] : null;
    }

    return null;
  }, []);

  /**
   * Signs out the user by calling the AWS sign out function, setting the user state to null,
   * and navigating to the sign-in page. Logs an error if the sign-out process fails.
   *
   * @function
   * @name signOut
   * @returns {Promise<void>} - A promise that resolves when the sign-out process is complete.
   *
   * @throws {Error} If the sign-out process fails, the error is logged to the console.
   */
  const signOut = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      const isSsoUser = currentUser.username.startsWith("google");
      await awsSignOut();
      setUser!(null);
      if (!isSsoUser) {
        navigate("/sign-in");
      }
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }, [setUser, navigate]);
  return { getErrorCode, getErrorCodeToMessageMapper, signOut };
};

export default useEmpAuth;
