import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosError, AxiosInstance } from "axios";
import EmpBadRequestException from "../exception/empBadRequestException";
import EmpException from "../exception/empException";
import EmpInternalServerError from "../exception/empInternalServerError";
import { ApiResponse } from "../model/api/api-response";
import { PUB_SUB_TOPICS } from "../constants/pubSubTopics";

const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  TOO_MANY_REQUESTS: 429,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

/* author : Felix Wang
 * description: This is a global error interceptor class that handles exceptions from different status codes.
 * This utility class writes interceptior logic ontop the "AxiosStatic" object.
 * You can utilize this class by calling```OneNSApiErrorInterceptor.configureInterceptor(axios)```
 */
export class EmpInterceptor {
  static async getAccessToken(): Promise<string> {
    try {
      const resp = await fetchAuthSession();
      const accessToken = resp.tokens?.idToken?.toString();
      return `Bearer ${accessToken}`;
    } catch (e) {
      console.log(e);
      throw new Error("Unable to retrieve access token.");
    }
  }

  static async axiosClientNoAuth(): Promise<AxiosInstance> {
    let instance: AxiosInstance = axios.create();

    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        console.log(error);
        // NetworkError -- this occurs when target host is unreachable
        if (error instanceof Error) {
          // NetworkError -- this occurs when the target host is unreachable
          if (error.message === "Network Error") {
            throw new EmpException("Unable to connect");
          }
        }
        const status = error.response!.status;
        const data = error.response!.data;

        if (status === HTTP_CODE.BAD_REQUEST) {
          console.log("Bad Request");
          const badRequest = data as ApiResponse<undefined>;
          throw new EmpBadRequestException(badRequest.error!.message);
        }
        if (status === HTTP_CODE.INTERNAL_SERVER_ERROR) {
          console.log("Internal Server Error");
          const internalServerError = data as ApiResponse<undefined>;
          throw new EmpInternalServerError(
            internalServerError.error!.message,
            internalServerError.error!.title
          );
        }
        if (status === HTTP_CODE.TOO_MANY_REQUESTS) {
          console.log("Too Many Requests");
          const tooManyRequest = data as ApiResponse<undefined>;
          throw new EmpInternalServerError(
            tooManyRequest.error!.message,
            "Too Many Requests"
          );
        }
        if (status === HTTP_CODE.FORBIDDEN) {
          console.log("Forbidden!");
          return null;
        }
        throw new EmpException("An Unexpected Error Occurred");
      }
    );
    return instance;
  }

  static async axiosClient(): Promise<AxiosInstance> {
    let instance: AxiosInstance = axios.create();
    const token = await EmpInterceptor.getAccessToken();

    // Set the default instance.
    instance.interceptors.request.use((config) => {
      config.headers!.Authorization = token;
      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        console.log(error);
        // NetworkError -- this occurs when target host is unreachable
        if (error instanceof Error) {
          // NetworkError -- this occurs when the target host is unreachable
          if (error.message === "Network Error") {
            throw new EmpException("Unable to connect");
          }
        }
        const status = error.response!.status;
        const data = error.response!.data;

        if (status === HTTP_CODE.BAD_REQUEST) {
          console.log("Bad Request");
          const badRequest = data as ApiResponse<undefined>;
          throw new EmpBadRequestException(badRequest.error!.message);
        }
        if (status === HTTP_CODE.INTERNAL_SERVER_ERROR) {
          console.log("Internal Server Error");
          const internalServerError = data as ApiResponse<undefined>;
          throw new EmpInternalServerError(
            internalServerError.error!.message,
            internalServerError.error!.title
          );
        }
        if (status === HTTP_CODE.TOO_MANY_REQUESTS) {
          console.log("Too Many Requests");
          const tooManyRequest = data as ApiResponse<undefined>;
          throw new EmpInternalServerError(
            tooManyRequest.error!.message,
            "Too Many Requests"
          );
        }
        if (status === HTTP_CODE.FORBIDDEN) {
          console.log("Forbidden!");
          PubSub.publish(PUB_SUB_TOPICS.VIEW_PLANS);
          return null;
        }
        throw new EmpException("An Unexpected Error Occurred");
      }
    );
    return instance;
  }
  

  /**
   * Static function throw any error from 'configure interceptor'
   * @param e - Might be data
   */
  static handleError(e: any) {
    if (e instanceof EmpException) {
      throw e;
    }
  }
}
