import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DeliverableConversationDto } from "../../../model/campaign/deliverable-conversation.dto";
import { DateUtil } from "../../../utilities/date";
import "./brand-task-chat-view.scss";
import { UploadChatAttachmentModalRef } from "../../../components/modals/upload-chat-attachment-modal";
import { OrganisationRespDto } from "../../../model/user/organisation-resp.dto";
import UserUtils from "../../../utilities/user-utils";
import { AppContext } from "../../../context/app.context";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import { DeliverableMessageWIdDto } from "../../../model/campaign/deliverable-message-w-id.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { EmpLoaderRef } from "../../../components/shared/emp-loader/emp-loader";
import { BrandChatMobile } from "./mobile/brand-chat-mobile";
import { BrandChatDesktop } from "./desktop/brand-chat-desktop";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";

interface Props {
  task: TaskDto;
}

export const BrandTaskChatView = (props: Props) => {
  const task = props.task;
  const taskId = props.task.id;

  const [deliverableConversations, setDeliverableConversations] =
    useState<DeliverableConversationDto[]>();
  const { user: userContext } = useContext(AppContext);
  const organisation = useRef<OrganisationRespDto>();

  const mobileBreakpoint = useEmpBreakpoint(520);

  const getConversations = useCallback(async () => {
    try {
      const resp = await OngoingTaskApi.fetchBrandConversations(taskId);
      setDeliverableConversations(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch conversations"
      );
    }
  }, [taskId]);

  useEffect(() => {
    const fetchOrganisation = async () => {
      const org = await UserUtils.fetchOrganisation(userContext);
      organisation.current = org;
    };
    fetchOrganisation();
    getConversations();
  }, [userContext, getConversations]);

  return (
    <div className="brand-task-chat-view">
      {!mobileBreakpoint && organisation.current! && (
        <BrandChatDesktop
          task={task}
          onRefresh={() => getConversations()}
          conversations={deliverableConversations}
          brandOrgId={organisation.current.id}
        />
      )}

      {mobileBreakpoint && organisation.current! && (
        <BrandChatMobile
          task={task}
          onRefresh={() => getConversations()}
          conversations={deliverableConversations}
          brandOrgId={organisation.current.id}
        />
      )}
    </div>
  );
};
