import { useState, useRef, useCallback, useEffect } from "react";
import CampaignApi from "../../../api/campaign-msvc/campaign.api";
import SearchIcon from "../../../components/icon/search-icon";
import { EmpCampaignCard } from "../../../components/shared/emp-campaign-card/emp-campaign-card";
import EmpMultiFilter, {
  MultiFilterOptionRef,
} from "../../../components/shared/emp-multi-filter/emp-multi-filter";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import EmpPagination, {
  EmpPaginationProps,
} from "../../../components/shared/EmpPagination/EmpPagination";
import {
  campaignSortOptions,
  campaignObjectiveOptions,
} from "../../../constants/selectConstants";
import { CampaignDto } from "../../../model/campaign/campaign.dto";
import { QueryCampaignDto } from "../../../model/campaign/query-campaign.dto";
import { EmpQueryable } from "../../../model/common/tableFilters";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import "./browse-campaign-view.scss";
import _debounce from "lodash/debounce";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountGridColumns } from "../../../hooks/useCountGridColumns";

export const BrowseCampaignView = () => {
  const navigate = useNavigate();
  const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const currentFilteredObjectives = useRef<string[]>([]);
  const objectiveMultiFilterRef = useRef<MultiFilterOptionRef>();

  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const query = useRef<EmpQueryable>({});
  const location = useLocation();
  const userRoleRef = useRef<string>("");

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
    sortBy: new FormControl("text", [], "sort-by-updated-desc"),
  });

  const resizeDivRef = useRef<HTMLDivElement>(null);
  // Determining Responsive card width

  const gridColumns = useCountGridColumns(resizeDivRef);

  const [shimmerCard, setShimmerCards] = useState<number[]>([]);
  const totalRecordsRef = useRef<number>();

  useEffect(() => {
    setShimmerCards(Array.from(Array(gridColumns * 2).keys()));
  }, [gridColumns]);

  /**
   * Fetches brand campaigns from the API and updates the state with the fetched data.
   * @returns {Promise<void>} A Promise that resolves when the campaigns are fetched and the state is updated.
   */
  const queryCampaigns = useCallback(
    async (queryable?: QueryCampaignDto) => {
      try {
        setLoading(true);

        // Set up pagination and query params
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("pageNo", `${queryable?.pageNo ?? 1}`);
        searchParams.set("pageSize", `${queryable?.pageSize ?? 20}`);
        if (form.search.getValue().length > 0) {
          searchParams.set("search", form.search.getValue());
        }
        navigate(
          `${window.location.pathname}?${searchParams.toString()}${
            window.location.hash
          }`
        );

        query.current = queryable as EmpQueryable;
        // objectiveMultiFilterRef.current?.reset();

        const resp = await CampaignApi.queryCampaigns({
          search: form.search.getValue(),
          sortBy: form.sortBy.getValue(),
          objectives: currentFilteredObjectives.current,
          pageNo: queryable?.pageNo ?? 1,
          pageSize: queryable?.pageSize ?? 20,
        });

        setPaginable({
          currentPage: resp.data.currentPage,
          totalRecord: resp.data.totalRecord,
          pageSize: resp.data.pageSize,
        });
        totalRecordsRef.current = resp.data.totalRecord;
        setFilteredCampaigns(resp.data.records);
      } catch (e) {
        setFilteredCampaigns([]);
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch campaigns"
        );
      } finally {
        setLoading(false);
      }
    },
    [form.search]
  );

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo");
    const pageSize = searchParams.get("pageSize");
    const search = searchParams.get("search");

    if (search) {
      updateFormSearch(search);
    }
    queryCampaigns({
      pageNo: pageNo ? Number(pageNo) : 1,
      pageSize: pageSize ? Number(pageSize) : 20,
    });

    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [queryCampaigns, updateFormSearch]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  // Campaign Card
  return (
    <div className="emp-browse-campaign-view">
      <div className="controls-section">
        <div className="search-textbox">
          <EmpTextInput
            labelText="Search Campaign"
            id={"search"}
            onKeypress={(e) => {
              if (e === "Enter") {
                queryCampaigns(query.current);
              }
            }}
            formControl={form.search}
            placeholder={"Search Campaign Keywords"}
            leftIconComponent={SearchIcon}
          />
        </div>
        <div className="sort-by-textbox">
          <EmpSelect
            labelText="Campaign Sort"
            id={"sortBy"}
            onChange={() => {
              queryCampaigns(query.current);
            }}
            formControl={form.sortBy}
            placeholder={"Search Campaign Keywords"}
            selectOptions={campaignSortOptions}
          />
        </div>
        <EmpMultiFilter
          ref={objectiveMultiFilterRef}
          menuItems={campaignObjectiveOptions}
          controlLabel={"Filter Objectives"}
          onChange={(values: string[]) => {
            currentFilteredObjectives.current = values;
            queryCampaigns(query.current);
          }}
        />
      </div>
      <div className="cards-section-wrapper">
        {filteredCampaigns !== undefined &&
          totalRecordsRef.current !== undefined && (
            <span className="records-title">
              {totalRecordsRef.current} Campaign
              {totalRecordsRef.current > 1 ? "s" : ""} found
            </span>
          )}
        <EmpPagination
          onChange={(queryable) =>
            queryCampaigns(queryable as QueryCampaignDto)
          }
          pagination={paginable}
          queryable={query.current}
        />
      </div>

      <div className="campaign-card-section" ref={resizeDivRef}>
        {!isLoading &&
          filteredCampaigns.length > 0 &&
          filteredCampaigns.map((elem) => {
            return (
              <EmpCampaignCard
                redirectUrl={`/${userRoleRef.current}/campaign-details/${elem.id}`}
                showOrg
                key={elem.id}
                campaign={elem}
              />
            );
          })}
        {!isLoading && filteredCampaigns.length === 0 && (
          <div className="blank-overlay">
            <div className="info-wrapper">
              <img
                className="empty-img"
                alt="No talents found"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
              />
              <h2 className="header">No campaigns found</h2>
              <p className="description">
                Please try another search query. Looking to create a new
                campaign? Click on the button below
              </p>
            </div>
          </div>
        )}
        {!isLoading &&
          filteredCampaigns.length === 0 &&
          shimmerCard.map((elem) => {
            return (
              <div className="blank-card">
                <div className="blank-card-wrapper">
                  <div className="top-section">
                    <div className="shimmer-block shimmer-color"></div>
                  </div>
                  <div className="bottom-section">
                    <div className="name-block shimmer-color"></div>
                    <div className="metrics">
                      <div className="metric-block-wrapper">
                        <div className="metric-title-block-1 shimmer-color"></div>
                        <div className="metric-value-block-1 shimmer-color"></div>
                      </div>
                      <div className="metric-block-wrapper">
                        <div className="metric-title-block-2 shimmer-color"></div>
                        <div className="metric-value-block-2 shimmer-color"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {isLoading &&
          shimmerCard.map((elem) => {
            return <div key={elem} className="loading-card emp-shimmer"></div>;
          })}
      </div>
    </div>
  );
};
