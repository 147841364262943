import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmailIcon from "../../components/icon/email-icon";
import LockIcon from "../../components/icon/lock-icon";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmpException from "../../exception/empException";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import "./agency-sign-up-page.scss";
import _debounce from "lodash/debounce";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import AlertSquareIcon from "../../components/icon/alert-square";
import { Color } from "../../utilities/colors";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import EmailOtpAPI from "../../api/user-msvc/email-otp.api";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { InitialOnboardingRespDto } from "../../model/onboarding/initial-onboarding-resp.dto";
import { FormattedMessage, useIntl } from "react-intl";
import { PasswordUtil } from "../../utilities/password.util";
import {
  fetchAuthSession,
  signUp as awsSignUp,
  signOut as awsSignOut,
  autoSignIn,
  signInWithRedirect,
} from "aws-amplify/auth";
import EmpGoogleButton from "../../components/shared/emp-google-button/emp-google-button";
import { AUTH_CUSTOM_STATES } from "../../constants/app.constants";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;

type FormType = "signup-mode" | "verification-mode";

export const AgencySignUpPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const intl = useIntl();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [formMode, setFormMode] = useState<FormType>("signup-mode");
  const hiddenFormRef = useRef<HTMLDivElement>(null);
  const signUpButtonRef = useRef<EmpButtonRef>();

  // OTP Form State Variables //
  interface VerificationFormCtx {
    email: string;
  }
  const [otpFormIsValid, setOtpFormIsValid] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>();
  const [counter, setCounter] = useState(0);
  const [onboardingInfo, setOnboardingInfo] =
    useState<InitialOnboardingRespDto>();
  const [otpButtonDisabled, setOtpButtonDisabled] = useState(true);
  const [verificationFormCtx, setVerificationFormCtx] =
    useState<VerificationFormCtx>();
  const verifyOtpButtonRef = useRef<EmpButtonRef>();

  const formControlLabels = useMemo(() => {
    return {
      emailLabel: intl.formatMessage({ id: "onboardPage_emailInputLabel" }),
      emailPlaceholder: intl.formatMessage({
        id: "onboardPage_emailInputPlaceholder",
      }),
      passwordLabel: intl.formatMessage({
        id: "onboardPage_passwordInputLabel",
      }),
      passwordPlaceholder: intl.formatMessage({
        id: "onboardPage_passwordInputPlaceholder",
      }),
      confirmPasswordLabel: intl.formatMessage({
        id: "onboardPage_confirmPasswordInputLabel",
      }),
      confirmPasswordPlaceholder: intl.formatMessage({
        id: "onboardPage_confirmPasswordInputPlaceholder",
      }),
      verificationCodeLabel: intl.formatMessage({
        id: "onboardPage_verificationCodeInputLabel",
      }),
      verificationCodePlaceholder: intl.formatMessage({
        id: "onboardPage_verificationCodeInputPlaceholder",
      }),
    };
  }, [intl]);

  const [form, setForm] = useState<IFormGroup>({
    email: new FormControl("text", [
      new RequiredValidator("Email is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Email must not exceed 100 characters"
      ),
      new EmailValidator(),
    ]),
    password: new FormControl("text", [
      new RequiredValidator("Password is required"),
    ]),
    confirmPassword: new FormControl("text", [
      new RequiredValidator("Confirm Password required"),
    ]),
  });

  const [otpForm] = useState<IFormGroup>({
    otp: new FormControl("text", [new RequiredValidator("Otp is required")]),
  });

  const checkIfSignedIn = useCallback(async () => {
    try {
      const currentSession = await fetchAuthSession({ forceRefresh: true });
      if (!currentSession.tokens?.idToken) return false;
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        if (await checkIfSignedIn()) {
          await awsSignOut();
        }
        if (!id) throw new EmpException("Unable to retrieve onboarding detail");
        const response = await OnboardingApi.fetchInitialDetails(
          "admin-onboard",
          id
        );
        const onboardingDetails = response.data;
        if (onboardingDetails.isExpired) {
          ToastUtils.error("Link expired", "Please request for a new link");
          return;
        }
        setOnboardingInfo(onboardingDetails);
        // Onboarding object will be present
        form.email.forceUpdateValue(onboardingDetails.onboardingObj.email);
        setForm({ ...form });
        await empDelay(100);
        setIsLoaded(true);
      } catch (e) {
        if (e instanceof Error) {
          new EmpExceptionHandlerBuilder()
            .handleCommonlHttpErrors()
            .handleGenericError()
            .build()
            .process(e);
          return;
        }
        ToastUtils.error("An Error Occurred", "Please try again");
      }
    };
    load();
  }, [checkIfSignedIn]);

  /**
   * This function is executed when the user clicks on the submit button. It will check with the
   * server to see if the user is required to verify their email before proceeding with the sign-up process.
   *
   * @returns void
   */
  const submitOnClick = async (): Promise<void> => {
    try {
      signUpButtonRef.current?.setButtonState("loading");
      if (!id) throw new EmpException("Unable to retrieve onboarding detail");
      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      const result = PasswordUtil.validatePasswordInput(
        intl,
        form.password.getValue(),
        form.confirmPassword.getValue()
      );
      if (result.hasError) {
        form.confirmPassword.errorMessage = result.errorMsg;
        form.confirmPassword.hasError = true;
      }
      if (!formIsValid || result.hasError) {
        setForm({ ...form });
        return;
      }
      const response = await OnboardingApi.checkVerificationEmailStatus({
        id: id,
        email: form.email.getValue(),
      });
      if (
        response.data.status === "success" &&
        response.data.statusMessage === "proceed"
      ) {
        await signUp();
      } else if (
        response.data.status === "success" &&
        response.data.statusMessage === "verification-required"
      ) {
        // display email otp
        setFormMode("verification-mode");
        setVerificationFormCtx({ email: form.email.getValue() });
        // Start Otp Counter
        setCounter(30);
        requestOtp(form.email.getValue());
      }
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      signUpButtonRef.current?.setButtonState("default");
    }
  };

  const requestOtp = async (email: string): Promise<void> => {
    try {
      const response = await EmailOtpAPI.requestEmailOtp({
        email,
        id: id!,
      });
      if (response.status === "success")
        ToastUtils.success(`Email OTP sent`, `Email OTP sent to ${email}`);
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const verifyOtp = async (): Promise<void> => {
    try {
      verifyOtpButtonRef.current?.setButtonState("loading");
      const response = await EmailOtpAPI.verifyEmailOtp({
        otp: otpForm.otp.getValue(),
        id: id!,
      });
      if (
        response.data.status === "error" &&
        response.data.statusMessage === "incorrect otp"
      ) {
        if (response.data.statusMessage === "incorrect otp")
          setOtpErrorMessage("You have given an incorrect OTP");
        else if (response.data.statusMessage === "expired")
          setOtpErrorMessage("OTP is expired. We have sent you a new one");
        return;
      } else if (response.data.status === "success") {
        // Continue with the sign-up
        setOtpErrorMessage(undefined);
        await signUp();
      }
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    } finally {
      verifyOtpButtonRef.current?.setButtonState("default");
    }
  };

  const otpFormOnChange = () => {
    const isValid = FormGroupUtil.validate(otpForm);
    setOtpFormIsValid(isValid);
  };

  const signUp = async (): Promise<void> => {
    try {
      const { isSignUpComplete, nextStep } = await awsSignUp({
        username: form.email.getValue(),
        password: form.password.getValue(),
        options: {
          userAttributes: {},
          autoSignIn: true,
        },
      });

      if (isSignUpComplete && nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
        await autoSignIn();
        await mapUserOnboarding();
      }

      throw new EmpException(
        "Sign In Failed",
        "Please contact system administrator"
      );
    } catch (e) {
      if (!(e instanceof Error)) return;
      if (e.name === "UsernameExistsException")
        ToastUtils.error("Email taken", "Please choose another email address");
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Please contact system administrator"
      );
    }
  };

  /**
   * Maps user onboarding using the specified onboarding ID.
   * @throws {EmpException} Throws an EmpException if unable to map user onboarding.
   * @returns {Promise<void>} A promise that resolves when the user onboarding is successfully mapped.
   */
  const mapUserOnboarding = async () => {
    try {
      const resp = await OnboardingApi.mapUserOnboarding({
        onboardingId: id!,
      });
      if (resp.data.status === "success") {
        ToastUtils.success("Registered", "Sign up complete");
      }
    } catch (e) {
      throw new EmpException("Unable to map user onboarding");
    }
  };

  useEffect(() => {
    /**
     * Manages the number of seconds that the user is required to wait before initiating another send OTP request
     */
    const startOtpCounter = async (): Promise<void> => {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(intervalId);
            setOtpButtonDisabled(false);
          }
          return prevCounter - 1;
        });
      }, 1000);
    };
    if (counter === 30) {
      startOtpCounter();
      setOtpButtonDisabled(true);
    }
  }, [counter]);

  return (
    <div className="emp-agency-sign-up-page">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
      </div>
      <div className="emp-onboarding-wrapper">
        <div className="onboarding-wrapper">
          <div className="form-section">
            {showLoader && (
              <motion.div
                variants={fadeInVariants}
                initial="visible"
                animate={isLoaded ? "hidden" : "visible"}
                transition={{
                  duration: 0.2,
                }}
                onAnimationComplete={(definition) => {
                  if (definition === "hidden") setShowLoader(false);
                }}
                className="loader-overlay"
              >
                <div className="emp-spinner large"></div>
              </motion.div>
            )}
            {formMode === "signup-mode" && (
              <div className="inner">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                  autoComplete="new-password"
                >
                  <motion.div
                    variants={fadeInVariants}
                    initial="hidden"
                    animate={isLoaded ? "visible" : "hidden"}
                    transition={{ duration: 0.2, delay: 0 * animationDelay }}
                  >
                    <img
                      className="logo"
                      alt="creatorfi logo"
                      srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                    />
                    <p className="description mt-8">
                      <FormattedMessage
                        id="onboardPage_desc"
                        values={{
                          name: (
                            <span className="highlight">
                              {onboardingInfo?.onboardingObj.name}
                            </span>
                          ),
                        }}
                      />
                    </p>
                  </motion.div>
                  <div className="form-wrapper mt-8">
                    <motion.div
                      variants={fadeInVariants}
                      initial="hidden"
                      animate={isLoaded ? "visible" : "hidden"}
                      transition={{ duration: 0.2, delay: 1 * animationDelay }}
                    >
                      <EmpTextInput
                        id={"email"}
                        formControl={form.email}
                        leftIconComponent={EmailIcon}
                        autocomplete={false}
                        labelText={formControlLabels.emailLabel}
                        required
                        placeholder={formControlLabels.emailPlaceholder}
                      />
                    </motion.div>

                    <motion.div
                      variants={fadeInVariants}
                      initial="hidden"
                      animate={isLoaded ? "visible" : "hidden"}
                      transition={{ duration: 0.2, delay: 2 * animationDelay }}
                    >
                      <EmpTextInput
                        id={"password"}
                        autocomplete={false}
                        formControl={form.password}
                        type="password"
                        leftIconComponent={LockIcon}
                        labelText={formControlLabels.passwordLabel}
                        required
                        placeholder={formControlLabels.passwordPlaceholder}
                      />
                    </motion.div>

                    <motion.div
                      variants={fadeInVariants}
                      initial="hidden"
                      animate={isLoaded ? "visible" : "hidden"}
                      transition={{ duration: 0.2, delay: 3 * animationDelay }}
                    >
                      <EmpTextInput
                        id={"confirmPassword"}
                        autocomplete={false}
                        formControl={form.confirmPassword}
                        leftIconComponent={LockIcon}
                        type="password"
                        labelText={formControlLabels.confirmPasswordLabel}
                        required
                        placeholder={
                          formControlLabels.confirmPasswordPlaceholder
                        }
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    variants={fadeInVariants}
                    initial="hidden"
                    animate={isLoaded ? "visible" : "hidden"}
                    transition={{ duration: 0.2, delay: 4 * animationDelay }}
                  >
                    <p className="mt-2 description">
                      <FormattedMessage
                        id="onboardPage_terms"
                        values={{
                          termsOfUse: (
                            <a
                              href="https://emplifive.com/legal#terms-of-service"
                              rel="noreferrer"
                              target="_blank"
                              className="highlight"
                            >
                              <FormattedMessage id="termsOfUseLink" />
                            </a>
                          ),
                        }}
                      />
                    </p>
                    <p className="mt-6 description">
                      <FormattedMessage
                        id="onboardPage_privacyPolicy"
                        values={{
                          privacyPolicy: (
                            <a
                              href="https://emplifive.com/legal#privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                              className="highlight"
                            >
                              <FormattedMessage id="privacyPolicyLink" />
                            </a>
                          ),
                        }}
                      />
                    </p>
                  </motion.div>
                  <motion.div
                    style={{ width: "100%" }}
                    variants={fadeInVariants}
                    initial="hidden"
                    animate={isLoaded ? "visible" : "hidden"}
                    transition={{ duration: 0.2, delay: 5 * animationDelay }}
                  >
                    <EmpGoogleButton
                      className="mt-6"
                      isFullWidth
                      onSubmit={() => {
                        signInWithRedirect({
                          provider: "Google",
                          customState: JSON.stringify({
                            origin: AUTH_CUSTOM_STATES.AGENCY_SSO,
                            referralCode: undefined,
                            onboardId: id,
                          }),
                        });
                      }}
                    />
                    <EmpButton
                      onSubmit={() => {
                        submitOnClick();
                      }}
                      className="mt-4"
                      isFullWidth
                      ref={signUpButtonRef}
                      text={<FormattedMessage id="onboardPage_signUpBtn" />}
                    />
                  </motion.div>
                </form>
              </div>
            )}
            {formMode === "verification-mode" && verificationFormCtx && (
              <div className="inner">
                <motion.div
                  variants={fadeInVariants}
                  initial="hidden"
                  animate={isLoaded ? "visible" : "hidden"}
                  transition={{ duration: 0.2, delay: 0 * animationDelay }}
                >
                  <div className="header-wrapper">
                    <EmpIconButton
                      buttonStyle="secondary"
                      icon={<ChevronLeftIcon />}
                      onSubmit={() => setFormMode("signup-mode")}
                    />
                    <span className="block ml-3 header">
                      <FormattedMessage id="onboardPage_verificationHeader" />
                    </span>
                  </div>

                  <p className="description mt-4">
                    <FormattedMessage
                      id="onboardPage_verificationDesc"
                      values={{
                        email: (
                          <span className="highlight">
                            {verificationFormCtx.email}
                          </span>
                        ),
                      }}
                    />
                  </p>
                </motion.div>
                <div className="form-wrapper mt-4">
                  <motion.div
                    variants={fadeInVariants}
                    initial="hidden"
                    animate={isLoaded ? "visible" : "hidden"}
                    transition={{ duration: 0.2, delay: 1 * animationDelay }}
                  >
                    <EmpTextInput
                      id={"verification-code"}
                      formControl={otpForm.otp}
                      labelText="Verification Code"
                      onChange={() => {
                        otpFormOnChange();
                      }}
                      suppressErrorMessage
                      required
                      placeholder="Enter Verification Code"
                    />
                    {otpErrorMessage && (
                      <div className="emp-error-message-wrapper mt-3">
                        <AlertSquareIcon
                          backgroundColor={Color.RED[600]}
                          size={16}
                          bottom={1}
                        />
                        <span>{otpErrorMessage}</span>
                      </div>
                    )}

                    {otpButtonDisabled && (
                      <div className="loader-wrapper mt-2">
                        <div className="emp-spinner small"></div>
                        <span className="block ml-1 color-gray-500">
                          <FormattedMessage
                            id="onboardPage_verificationCodeResendCodeDesc"
                            values={{ second: counter }}
                          />
                        </span>
                      </div>
                    )}
                  </motion.div>
                </div>
                <motion.div
                  style={{ width: "100%", display: "flex" }}
                  className="mt-4"
                  variants={fadeInVariants}
                  initial="hidden"
                  animate={isLoaded ? "visible" : "hidden"}
                  transition={{ duration: 0.2, delay: 2 * animationDelay }}
                >
                  <EmpButton
                    onSubmit={() => {
                      requestOtp(verificationFormCtx.email);
                    }}
                    className="mr-2"
                    isFullWidth
                    buttonStyle="secondary"
                    disabled={otpButtonDisabled}
                    text={
                      <FormattedMessage id="onboardPage_verificationCodeResendCodeBtn" />
                    }
                  />
                  <EmpButton
                    onSubmit={() => {
                      verifyOtp();
                    }}
                    className="ml-2"
                    isFullWidth
                    disabled={!otpFormIsValid}
                    ref={verifyOtpButtonRef}
                    text={<FormattedMessage id="cta_proceed" />}
                  />
                </motion.div>
              </div>
            )}
          </div>
          <div className="info-section">
            <span className="title">
              <FormattedMessage id="onboardPage_agency_promoHeader" />
            </span>
            <p className="description mt-4">
              <FormattedMessage id="onboardPage_agency_promoDesc" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
