import { useCallback } from "react";
import ToastUtils from "../utilities/toast-utils";
import EmpException from "../exception/empException";
import OnboardingApi from "../api/user-msvc/onboarding.api";

const useOnboarding = () => {
  /**
   * Maps user onboarding using the specified onboarding ID.
   * @throws {EmpException} Throws an EmpException if unable to map user onboarding.
   * @returns {Promise<void>} A promise that resolves when the user onboarding is successfully mapped.
   */
  const mapUserOnboarding = useCallback(
    async (onboardId?: string, code?: string) => {
      try {
        const resp = await OnboardingApi.mapUserOnboarding({
          onboardingId: onboardId,
          referralCode: code,
        });
        if (resp.data.status === "success") {
          ToastUtils.success("Registered", "Sign up complete");
        }
      } catch (e) {
        throw new EmpException("Unable to map user onboarding");
      }
    },
    []
  );

  return { mapUserOnboarding };
};
export default useOnboarding;
