import { useSlate } from "slate-react";
import { IconProps } from "../../../../model/common/icon";
import { Color } from "../../../../utilities/colors";
import { EditorUtilities } from "../utility/editor-utilities";

interface EmpButtonProps {
  format: string;
  icon: React.FC<IconProps>;
}

const EmpBlockButton = (props: EmpButtonProps) => {
  const { icon: Icon, format } = props;
  const editor = useSlate();
  const isSelected = EditorUtilities.isBlockActive(editor, format);

  return (
    <div
      className={`elem-modifier-btn ${isSelected ? "selected" : ""}`}
      onMouseDown={(event) => {
        event.preventDefault();
        EditorUtilities.toggleBlock(editor, format);
      }}
    >
      <Icon backgroundColor={Color.NEUTRAL[500]} size={14} />
    </div>
  );
};
export default EmpBlockButton;
