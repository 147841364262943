import "./emp-time-input.scss";

import { forwardRef, useEffect, useState } from "react";
import { DateInput, DateInputProps, Tooltip, Whisper } from "rsuite";

import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import InfoCircleIcon from "../../icon/info-circle-icon";

interface Props extends Omit<DateInputProps, "onChange"> {
  label?: string;
  isRequired?: boolean;
  formControl: FormControl;
  onChange?: (values: FormControl) => void;
  hasSearch?: boolean;
  tooltip?: string;
}

export interface EmpDateInputRef {
  reset: () => void;
}

const EmpDateInput = forwardRef((props: Props, ref) => {
  const {
    label,
    isRequired = false,
    tooltip: tooltipProp = undefined,
    onChange,
    formControl,
    hasSearch,
    ...rest
  } = props;
  const [value, setValue] = useState<Date | null>();
  const tooltip = tooltipProp ? <Tooltip>{tooltipProp}</Tooltip> : <></>;

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  // Initialize the component when values has already been pre-populated.
  useEffect(() => {
    if (
      formControl.getValue()?.length === 0 ||
      formControl.getValue() === null
    ) {
      formControl.setValue(new Date());
      setValue(new Date());
      return;
    }
    setValue(new Date(formControl.getValue()));
  }, [formControl, formControl.value]);

  return (
    <div>
      {label && (
        <label>
          {label}
          {isRequired && <span className="required">*</span>}
          {tooltipProp && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={tooltip}
            >
              <div className="emp-tooltip-wrapper">
                <InfoCircleIcon
                  size={14}
                  backgroundColor={Color.NEUTRAL[500]}
                />
              </div>
            </Whisper>
          )}
        </label>
      )}
      <DateInput
        onChange={(value: Date | null) => {
          if (value === null || isNaN(value.getTime())) {
            formControl.setValue(null);
            setValue(null);
            if (onChange) onChange(formControl);
            return;
          }
          formControl.setValue(value);
          setValue(value!);
          if (onChange) onChange(formControl);
        }}
        placeholder="HH:MM"
        value={value}
        format={"HH:mm"}
        {...rest}
      />

      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
});

export default EmpDateInput;
