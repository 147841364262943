import { useEffect, useRef, useState } from "react";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import { CreateTaskInvitationDto } from "../../../model/campaign/create-task-invitation.dto";
import { LatestNegotiationDto } from "../../../model/campaign/latest-negotiation.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { TaskRepresentativeDto } from "../../../model/user/creator-task-rep.dto";
import { OrganisationRespDto } from "../../../model/user/organisation-resp.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import StringUtils from "../../../utilities/string.util";
import ToastUtils from "../../../utilities/toast-utils";
import ChevronLeftIcon from "../../icon/chevron-left";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import EmpPill from "../../shared/EmpPill/EmpPill";
import "../invite-task-modal.scss";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task: TaskDto;
  taskRepresentatives: TaskRepresentativeDto[];
  brandOrg: OrganisationRespDto;
  creator: UserDto;
  latestNegotiations: LatestNegotiationDto[];
  onComplete: () => void;
  onBackClick: () => void;
}

interface TaskRepresentativeWForm extends TaskRepresentativeDto {
  formControl: FormControl;
}

const TaskInvitationVariedOfferView = (props: Props) => {
  const submitBtnRef = useRef<EmpButtonRef>();
  const {
    task,
    taskRepresentatives,
    brandOrg,
    creator,
    onComplete,
    latestNegotiations,
  } = props;
  const [taskRepresentativeWForm, setTaskRepresentativeWForm] = useState<
    TaskRepresentativeWForm[]
  >([]);

  const [form, setForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [
      new LengthValidator(
        10,
        400,
        "Remarks must be at least 10 characters",
        "Invitation remarks must not exceed 400 characters"
      ),
    ]),
  });

  useEffect(() => {
    if (!taskRepresentatives || !latestNegotiations) return;

    const negotiationMap = new Map<string, LatestNegotiationDto>();
    latestNegotiations.forEach((elem) =>
      negotiationMap.set(elem.representativeId, elem)
    );

    const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;
    const mappedTaskRepresentativeWForm = taskRepresentatives.map((elem) => {
      const amount = negotiationMap.get(elem.id)?.amount;
      return {
        ...elem,
        formControl: new FormControl(
          "text",
          [
            new RequiredValidator("Please specify a rate"),
            new PatternValidator(numberRegex, "Please provide a numeric value"),
            new NumericRangeValidator(
              0,
              40000,
              "Rate must be more than zero",
              "Rate must not exceed 40,000"
            ),
          ],
          amount ?? "0"
        ),
      };
    });
    setTaskRepresentativeWForm(mappedTaskRepresentativeWForm);
  }, [taskRepresentatives, latestNegotiations]);

  const onSubmit = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      const taskOfferFormGroup = taskRepresentativeWForm.reduce(
        (prevValue: IFormGroup, elem, index) => {
          prevValue[index.toString()] = elem.formControl;
          return prevValue;
        },
        {}
      );
      const taskOfferFormGroupIsValid =
        FormGroupUtil.validate(taskOfferFormGroup);
      const formIsValid = FormGroupUtil.validate(form);

      setTaskRepresentativeWForm([...taskRepresentativeWForm]);
      setForm({ ...form });

      if (!formIsValid || !taskOfferFormGroupIsValid) return;

      const invitationNegotiations = taskRepresentativeWForm.map((elem) => {
        return {
          agencyOrgId:
            elem.representativeRole === "agency" ? elem.agency.id : undefined,
          representativeId:
            elem.representativeRole === "agency"
              ? elem.agency.id
              : elem.creator.id,
          representativeRole: elem.representativeRole,
          amount: Number(elem.formControl.getValue()),
        };
      });
      // Construct the object
      const request: CreateTaskInvitationDto = {
        brandOrgId: brandOrg.id,
        creatorUserId: creator.id,
        invitationNegotiations: invitationNegotiations,
        campaignId: task.campaign.id,
        taskId: task.id,
        remarks: form.remarks.getValue(),
      };
      const resp = await NegotiationApi.createNegotiations(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Invitation Sent",
          "We have sent invitations to the respective representatives"
        );
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when sending invitations"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="task-invitation-varied-offer-view">
      <div className="view-header-wrapper">
        <div
          onClick={() => {
            props.onBackClick();
          }}
          className="back-btn"
        >
          <ChevronLeftIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <span className="view-header shift-left">Make a varied Offer</span>
      </div>

      <p className="view-description mt-2">
        This creator is affiliated with multiple agencies. You may select one or
        more people to work with. Please note that when you choose to go ahead
        with one of them, the other guys will be rejected.
      </p>
      <div className="rep-offer-section mt-4">
        {taskRepresentativeWForm.map((elem, index) => {
          return (
            <div key={elem.id} className={`rep-form-row`}>
              {elem.representativeType === "user" && (
                <>
                  {elem.creator.imageType === "url" && (
                    <img
                      className={`profile-photo`}
                      alt={elem.creator.fullName}
                      src={elem.creator.imageResource}
                    />
                  )}
                  {elem.creator.imageType === "avatar" && (
                    <div className={`avatar`}>
                      <span className="initials">{elem.creator.initials}</span>
                    </div>
                  )}
                </>
              )}
              {elem.representativeType === "organisation" && (
                <>
                  <img
                    className={`profile-photo org-photo`}
                    alt={elem.agency.companyName}
                    src={elem.agency.logo}
                  />
                </>
              )}
              <div className="details-section">
                <div className="rep-name-section">
                  {elem.representativeType === "user" && (
                    <span className="rep-name-lbl">
                      {elem.creator.fullName}
                    </span>
                  )}
                  {elem.representativeType === "organisation" && (
                    <span className="rep-name-lbl">
                      {elem.agency.companyName}
                    </span>
                  )}
                </div>
                <div className="mt-1">
                  <EmpPill
                    backgroundColor={Color.PRIMARY[100]}
                    color={Color.PRIMARY[600]}
                    text={StringUtils.capitalizeWords(elem.representativeRole)}
                  />
                </div>
              </div>
              <div className="form-section">
                <EmpTextInput
                  id={"rate"}
                  leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
                  leftIconSize={12}
                  formControl={elem.formControl}
                  placeholder={"00.00"}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4">
        <EmpTextInput
          id={"remarks"}
          labelText="Remarks (Optional)"
          multiline
          textAreaAdaptiveHeight
          tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
          formControl={form.remarks}
          rows={3}
          characterCount={300}
          placeholder={
            "You may explain how you are a suitable candidate to execute this task."
          }
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <EmpButton
          ref={submitBtnRef}
          isFullWidth={false}
          onSubmit={onSubmit}
          className="mt-4"
          text={"Proceed"}
        />
      </div>
    </div>
  );
};
export default TaskInvitationVariedOfferView;
