import { motion } from "framer-motion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IconProps } from "../../../model/common/icon";
import { Color } from "../../../utilities/colors";
import "./emp-button.scss";

export type ButtonStyleType =
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "outline-primary"
  | "outline-secondary";
interface Props {
  text: string | JSX.Element;
  buttonStyle?: ButtonStyleType;
  onSubmit?: () => void;
  disabled?: boolean;
  isFullWidth?: boolean;
  className?: string;
  leftIcon?: React.FC<IconProps>;
  rightIcon?: React.FC<IconProps>;
  state?: EmpButtonVariantTypes;
  buttonHeight?: "sm" | "md" | "lg";
}

const buttonTextVariants = {
  default: { opacity: 1 },
  loading: { opacity: 0 },
};

const spinnerVariants = {
  default: { opacity: 0 },
  loading: { opacity: 1 },
};
type EmpButtonVariantTypes = "default" | "loading";

export interface EmpButtonRef {
  setButtonState: (buttonState: EmpButtonVariantTypes) => void;
  getButtonState: () => EmpButtonVariantTypes;
  click: () => void;
}
const EmpButton = forwardRef((props: Props, ref) => {
  const { text, onSubmit, leftIcon: LeftIcon, rightIcon: RightIcon } = props;
  const state = props.state ?? "default";
  const [buttonVariant, setButtonVariant] =
    useState<EmpButtonVariantTypes>("default");
  const [buttonStyle, setButtonStyle] = useState<ButtonStyleType>(
    props.buttonStyle ? props.buttonStyle : "primary"
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const fullWidth = props.isFullWidth ?? true;
  const buttonHeight = props.buttonHeight ?? "md";

  useEffect(() => {
    const isDisabled = props.disabled ?? false;
    setDisabled(isDisabled);
  }, [props.disabled]);

  useEffect(() => {
    setButtonVariant(state);
  }, [state]);

  useEffect(() => {
    setButtonStyle(props.buttonStyle ? props.buttonStyle : "primary");
  }, [props.buttonStyle]);

  useImperativeHandle(ref, () => {
    return {
      setButtonState,
      click,
      getButtonState,
    };
  });

  const setButtonState = (buttonState: EmpButtonVariantTypes) => {
    setButtonVariant(buttonState);
  };

  const getButtonState = () => {
    return buttonVariant;
  };

  const click = () => {
    buttonRef.current?.click();
  };

  const heightStyleMapper = (buttonHeight: "sm" | "md" | "lg") => {
    return `height-${buttonHeight}`;
  };

  const iconColor = () => {
    if (["primary", "danger", "success"].includes(buttonStyle))
      return Color.NEUTRAL[300];
    else if (buttonStyle === "secondary" || buttonStyle === "outline-secondary")
      return Color.NEUTRAL[100];
    else return Color.PRIMARY[500];
  };

  const iconSize = () => {
    if (buttonHeight === "sm") return 14;
    else if (buttonHeight === "md") return 16;
    else return 18;
  };
  return (
    <div
      className={`${fullWidth ? "full-width" : "fit-content"} ${
        props.className ?? ""
      }`}
    >
      <button
        ref={buttonRef}
        disabled={disabled}
        className={`emp-button ${buttonVariant} ${buttonStyle} ${heightStyleMapper(
          buttonHeight
        )}`}
        onClick={() => {
          if (onSubmit) onSubmit();
        }}
      >
        <motion.div
          className="emp-loader"
          variants={spinnerVariants}
          initial="default"
          animate={buttonVariant}
          transition={{ duration: 0.1 }}
        >
          <div className="emp-spinner"></div>
        </motion.div>
        <div className="emp-button-content">
          {LeftIcon && (
            <motion.div
              variants={buttonTextVariants}
              initial="default"
              animate={buttonVariant}
              transition={{ duration: 0.1 }}
            >
              <LeftIcon size={iconSize()} backgroundColor={iconColor()} />
            </motion.div>
          )}
          <motion.span
            variants={buttonTextVariants}
            initial="default"
            animate={buttonVariant}
            transition={{ duration: 0.1 }}
          >
            {text}
          </motion.span>
          {RightIcon && (
            <motion.div
              variants={buttonTextVariants}
              initial="default"
              animate={buttonVariant}
              transition={{ duration: 0.1 }}
            >
              <RightIcon size={iconSize()} backgroundColor={iconColor()} />
            </motion.div>
          )}
        </div>
      </button>
    </div>
  );
});
export default EmpButton;
