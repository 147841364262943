import { useEffect, useState } from "react";

const useEmpBreakpoint = (size: number) => {
  const [breakpoint, setBreakpoint] = useState(false);

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth < size) setBreakpoint(true);
      else setBreakpoint(false);
    };
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [size]);

  return breakpoint;
};

export default useEmpBreakpoint;
