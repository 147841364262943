import {
  ATTACHMENT_TYPE,
  DELIVERABLE_STATUS,
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE,
  TASK_TYPE,
} from "../../../constants/app.constants";
import { EvidenceRecordDto } from "../../../model/campaign/evidence-record.dto";
import { ExtendedEvidenceDraftDto } from "../../../model/campaign/extended-evidence-draft.dto";
import {
  FacebookPost,
  InstagramMedia,
  TiktokVideo,
} from "../../../model/campaign/social-media.dto";
import { Color } from "../../../utilities/colors";
import FileUtils from "../../../utilities/file-util";
import StringUtils from "../../../utilities/string.util";
import Barchart1Icon from "../../icon/barchart-1-icon";
import EyeIcon from "../../icon/eye-icon";
import FileCheckIcon from "../../icon/file-check-icon";
import FileIcon from "../../icon/file-icon";
import HeartIcon from "../../icon/heart-icon";
import MessageIcon from "../../icon/message-icon";
import PlayIcon from "../../icon/play-icon";
import ShareIcon from "../../icon/share-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import { FacebookPow } from "../../proof-of-work-media/facebook-pow";
import { FacebookPowFallback } from "../../proof-of-work-media/facebook-pow-fallback";
import { InstagramPow } from "../../proof-of-work-media/instagram-pow";
import { InstagramPowFallback } from "../../proof-of-work-media/instagram-pow-fallback";
import { TikTokFallbackPow } from "../../proof-of-work-media/tiktok-fallback-pow";
import { TikTokPow } from "../../proof-of-work-media/tiktok-pow";
import { XPowFallback } from "../../proof-of-work-media/x-pow-fallback";
import EmpButton from "../emp-button/emp-button";
import { EmpImage } from "../emp-image/emp-image";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-evidence-card.scss";

interface Props {
  evidenceItem?: ExtendedEvidenceDraftDto;
  cardWidth?: number;
  mode?: "default" | "skeleton";
  role: "brand" | "seller";
  onCreate?: () => void;
  onView?: () => void;
  toDraft?: () => void;
  onChat?: () => void;
}

export const EmpEvidenceCard = (props: Props) => {
  const { evidenceItem, onCreate, onView, toDraft, onChat, role } = props;
  const mode = props.mode ?? "default";
  const width = props.cardWidth ?? 240;

  const renderEvidencePreview = (
    evidenceRecords: EvidenceRecordDto[],
    numRecords: number
  ): JSX.Element => {
    if (evidenceRecords.length === 0) return <></>;
    const record = evidenceRecords[0];
    // ***** ----- RENDERING CARD FOR FACEOOK -------- *****/
    if (
      record.platform === TASK_TYPE.FACEBOOK &&
      record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const facebookObj = record.socialMediaObject as FacebookPost;
      return (
        <>
          {record.hasSocialMediaObject ? (
            <FacebookPow
              type={"pow-card"}
              socialMediaObject={facebookObj}
              noOfOtherEvidenceRecords={numRecords}
              evidenceRecord={record}
            />
          ) : (
            <FacebookPowFallback
              type={"pow-card"}
              profileSnapshot={record.profileSnapshot}
              noOfOtherEvidenceRecords={numRecords}
              mediaSnapshots={record.mediaSnapshots!}
              evidenceRecord={record}
            />
          )}
        </>
      );
    }

    // ***** ----- RENDERING CARD FOR TIKTOK -------- *****/
    else if (
      record.platform === TASK_TYPE.TIKTOK &&
      record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const tiktokObj = record.socialMediaObject as TiktokVideo;
      return (
        <>
          {record.hasSocialMediaObject ? (
            <TikTokPow
              type={"pow-card"}
              socialMediaObject={tiktokObj}
              noOfOtherEvidenceRecords={numRecords}
              evidenceRecord={record}
            />
          ) : (
            <TikTokFallbackPow
              type={"pow-card"}
              profileSnapshot={record.profileSnapshot}
              noOfOtherEvidenceRecords={numRecords}
              mediaSnapshots={record.mediaSnapshots!}
              evidenceRecord={record}
            />
          )}
        </>
      );
    }

    // ***** ----- RENDERING CARD FOR INSTAGRAM -------- *****/
    else if (
      record.platform === TASK_TYPE.INSTAGRAM &&
      record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const instagramObj = record.socialMediaObject as InstagramMedia;
      const hasSocialMediaObject = record.hasSocialMediaObject;
      return (
        <>
          {hasSocialMediaObject ? (
            <InstagramPow
              type={"pow-card"}
              socialMediaObject={instagramObj}
              noOfOtherEvidenceRecords={numRecords}
              evidenceRecord={record}
            />
          ) : (
            <InstagramPowFallback
              type={"pow-card"}
              profileSnapshot={record.profileSnapshot}
              noOfOtherEvidenceRecords={numRecords}
              mediaSnapshots={record.mediaSnapshots!}
              evidenceRecord={record}
            />
          )}
        </>
      );
    }

    // ***** ----- RENDERING CARD FOR X -------- *****/
    else if (
      record.platform === TASK_TYPE.X &&
      record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      return (
        <XPowFallback
          type="pow-card"
          mediaSnapshots={record.mediaSnapshots!}
          profileSnapshot={record.profileSnapshot}
          evidenceRecord={record}
          noOfOtherEvidenceRecords={numRecords}
        />
      );
    }

    // *** SM IMAGE *** //
    else if (record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE) {
      return (
        <>
          <div className="image-wrapper">
            <div className="post-wrapper">
              {numRecords > 1 && (
                <div className={`additional-deliverable-wrapper-badge`}>
                  <span className="badge-text">+{numRecords - 1}</span>
                  <FileCheckIcon
                    strokeWidth={2.5}
                    size={12}
                    backgroundColor={Color.NEUTRAL[300]}
                  />
                </div>
              )}
              <EmpImage
                className="attachment-img"
                src={record.files[0]?.url}
                fallback={EMPTY_PROFILE_IMG_URL}
              />
            </div>
            <div className="sm-metrics-section">
              <div className="metrics-bar">
                {record.platform === TASK_TYPE.TIKTOK && (
                  <>
                    <div className="metric-wrapper">
                      <PlayIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.viewCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <HeartIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.likeCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <MessageIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.commentCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <ShareIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.shareCount ?? "-"}</span>
                    </div>
                  </>
                )}

                {record.platform === TASK_TYPE.FACEBOOK && (
                  <>
                    <div className="metric-wrapper">
                      <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                      <span>{record.metricSnapshot?.impression ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <HeartIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.reactionCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <MessageIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.commentCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <ShareIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.shareCount ?? "-"}</span>
                    </div>
                  </>
                )}

                {record.platform === TASK_TYPE.X && (
                  <>
                    <div className="metric-wrapper">
                      <Barchart1Icon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.viewCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <HeartIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.likeCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <MessageIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.commentCount ?? "-"}</span>
                    </div>
                    <div className="metric-wrapper">
                      <ShareIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{record.metricSnapshot?.shareCount ?? "-"}</span>
                    </div>
                  </>
                )}

                {record.platform === TASK_TYPE.INSTAGRAM &&
                  record.mediaType === "instagram-post" && (
                    <>
                      <div className="metric-wrapper">
                        <PlayIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{record.metricSnapshot?.viewCount ?? "-"}</span>
                      </div>
                      <div className="metric-wrapper">
                        <HeartIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{record.metricSnapshot?.likeCount ?? "-"}</span>
                      </div>
                      <div className="metric-wrapper">
                        <MessageIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>
                          {record.metricSnapshot?.commentCount ?? "-"}
                        </span>
                      </div>
                      <div className="metric-wrapper">
                        <ShareIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{record.metricSnapshot?.shareCount ?? "-"}</span>
                      </div>
                    </>
                  )}

                {record.platform === TASK_TYPE.INSTAGRAM &&
                  record.mediaType === "instagram-story" && (
                    <>
                      <div className="metric-wrapper">
                        <PlayIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{record.metricSnapshot?.viewCount ?? "-"}</span>
                      </div>
                      <div className="metric-wrapper">
                        <HeartIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>
                          {record.metricSnapshot?.reactionCount ?? "-"}
                        </span>
                      </div>
                      <div className="metric-wrapper">
                        <MessageIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>
                          {record.metricSnapshot?.commentCount ?? "-"}
                        </span>
                      </div>
                      <div className="metric-wrapper">
                        <ShareIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{record.metricSnapshot?.shareCount ?? "-"}</span>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </>
      );
    }
    // *** ATTACHMENT *** //
    else if (record.evidenceType === EVIDENCE_TYPE.ATTACHMENT) {
      if (record.files.length > 0) {
        const previewFile = record.files[0];
        return (
          <div className="image-wrapper">
            <div className="sm-top-section">
              <div className="attachment-info-wrapper">
                <span className="attachment-header">File Attachment</span>
                <span>
                  <span className="attachment-filename">
                    {previewFile.name}{" "}
                  </span>
                  <span className="attachment-filename">
                    ({FileUtils.convertBytesToReadableSize(previewFile.size)})
                  </span>
                </span>
              </div>
            </div>
            <div className="post-wrapper">
              {numRecords > 1 && (
                <div className={`additional-deliverable-wrapper-badge`}>
                  <span className="badge-text">+{numRecords - 1}</span>
                  <FileCheckIcon
                    strokeWidth={2.5}
                    size={12}
                    backgroundColor={Color.NEUTRAL[300]}
                  />
                </div>
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.FILE && (
                <div className="preview-file">
                  <FileIcon backgroundColor={Color.NEUTRAL[300]} size={30} />
                  <span className="preview-filename block">
                    {previewFile.name}
                  </span>
                  <span className="preview-size block">
                    ({FileUtils.convertBytesToReadableSize(previewFile.size)})
                  </span>
                </div>
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.IMAGE && (
                <EmpImage
                  className="preview-image"
                  src={previewFile.url}
                  alt="Evidence"
                  fallback={EMPTY_PROFILE_IMG_URL}
                />
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.VIDEO && (
                <video
                  loop
                  controls={false}
                  className="preview-image"
                  src={previewFile.url}
                ></video>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div className="image-wrapper">
            <div className="sm-top-section">
              <div className="attachment-info-wrapper">
                <span className="attachment-header">Text Submission</span>
              </div>
            </div>
            <div className="post-wrapper">
              <div className="empty-submission">
                <p className="emp-paragraph">{record.remarks}</p>
              </div>
            </div>
          </div>
        );
      }
    }
    return <span>Unprocessed</span>;
  };

  return (
    <>
      {mode === "default" && evidenceItem && (
        <div
          className="emp-evidence-card emp-card no-padding"
          style={{ width: `${width}px` }}
        >
          {evidenceItem.status === DELIVERABLE_STATUS.APPROVED &&
            !evidenceItem.evidence && (
              <div className="image-wrapper padded">
                <img
                  className="empty-draft-img"
                  alt="no draft submitted"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-draft.png"
                />
                <span className="empty-draft-header">
                  Proof of Work Not Submitted
                </span>
                <p className="empty-draft-description  mt-2">
                  Submit proof of work for task payouts eligibility.
                </p>
              </div>
            )}

          {evidenceItem.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
            !evidenceItem.evidence && (
              <div className="image-wrapper padded">
                <img
                  className="empty-draft-img"
                  alt="no draft submitted"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/denied.png"
                />
                <span className="empty-draft-header mt-3">
                  Draft not Submitted Yet
                </span>
                <p className="empty-draft-description mt-2">
                  Get draft approval before submitting proof of work.
                </p>
              </div>
            )}

          {evidenceItem.status === DELIVERABLE_STATUS.IN_REVIEW &&
            !evidenceItem.evidence && (
              <div className="image-wrapper padded">
                <img
                  className="empty-draft-img"
                  alt="no draft submitted"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/denied.png"
                />
                <span className="empty-draft-header">Draft under review</span>
                <p className="empty-draft-description  mt-2">
                  Please wait for brand approval before submitting proof of
                  work.
                </p>
              </div>
            )}

          {evidenceItem.status === DELIVERABLE_STATUS.COMPLETED &&
            evidenceItem.evidence &&
            renderEvidencePreview(
              evidenceItem.evidence!.evidenceRecords,
              evidenceItem.evidence!.numRecords
            )}

          {/* {[DELIVERABLE_STATUS.IN_REVIEW, DELIVERABLE_STATUS.APPROVED, DELIVERABLE_STATUS.COMPLETED].includes(evidenceItem.status) && evidenceItem.evidence && <>
                {evidenceItem.evidence.remarks.length > 0 && <div className="remarks-section">
                    <p>{evidenceItem.evidence.remarks}</p>
                </div>}
                {evidenceItem.evidence.fileType === FILE_TYPE.IMAGE && <img referrerPolicy="no-referrer"className="attachment-img" alt="Uploaded draft" src={evidenceItem.evidence.attachmentUrl} />}
                {evidenceItem.evidence.fileType === FILE_TYPE.VIDEO && <video className="attachment-video"  muted loop controls={false} src={evidenceItem.evidence.attachmentUrl} />}
                {evidenceItem.evidence.fileType === FILE_TYPE.FILE && <div className="attachment-file-wrapper">
                    <FileIcon size={24} backgroundColor={Color.NEUTRAL[300]} />
                    <span className="title">{evidenceItem.evidence.attachmentName}</span>
                    <span className="specs">{FileUtils.convertBytesToReadableSize(evidenceItem.evidence.attachmentSize)}</span>
                </div>}
            </>} */}

          <div className="details-wrapper">
            {evidenceItem.creator.imageType === "url" && (
              <img
                className="profile"
                alt={evidenceItem.creator.fullName}
                src={evidenceItem.creator.imageResource}
              />
            )}
            {evidenceItem.creator.imageType === "avatar" && (
              <div
                className="profile"
                style={{ background: evidenceItem.creator.imageResource }}
              >
                <span className="initials">
                  {evidenceItem.creator.initials}
                </span>
              </div>
            )}
            <div className="info-wrapper">
              <span className="name-lbl">{evidenceItem.creator.fullName}</span>
              <div className="mt-1 pill-wrapper ">
                {[DELIVERABLE_STATUS.PENDING_DRAFT].includes(
                  evidenceItem.status
                ) && (
                  <EmpPill
                    size="sm"
                    text={StringUtils.convertHyphensToTitleCase(
                      evidenceItem.status
                    )}
                    backgroundColor={Color.NEUTRAL[100]}
                    color={Color.NEUTRAL[500]}
                  />
                )}
                {[DELIVERABLE_STATUS.IN_REVIEW].includes(
                  evidenceItem.status
                ) && (
                  <EmpPill
                    size="sm"
                    text={StringUtils.convertHyphensToTitleCase(
                      evidenceItem.status
                    )}
                    backgroundColor={Color.PRIMARY[100]}
                    color={Color.PRIMARY[600]}
                  />
                )}
                {[
                  DELIVERABLE_STATUS.APPROVED,
                  DELIVERABLE_STATUS.COMPLETED,
                ].includes(evidenceItem.status) && (
                  <EmpPill
                    size="sm"
                    text={StringUtils.convertHyphensToTitleCase(
                      evidenceItem.status
                    )}
                    backgroundColor={Color.GREEN[100]}
                    color={Color.GREEN[500]}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            {[
              DELIVERABLE_STATUS.PENDING_DRAFT,
              DELIVERABLE_STATUS.IN_REVIEW,
            ].includes(evidenceItem.status) &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  buttonStyle="secondary"
                  text={"To Drafts"}
                  onSubmit={() => {
                    if (toDraft) toDraft();
                  }}
                  buttonHeight="sm"
                />
              )}

            {evidenceItem.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
              role === "brand" && (
                <EmpButton
                  buttonStyle="secondary"
                  className="mt-2"
                  text={"Chat"}
                  onSubmit={() => {
                    if (onChat) onChat();
                  }}
                  buttonHeight="sm"
                />
              )}

            {evidenceItem.status === DELIVERABLE_STATUS.IN_REVIEW &&
              role === "brand" && (
                <EmpButton
                  className="mt-2"
                  text={"Review Draft"}
                  onSubmit={() => {
                    if (toDraft) toDraft();
                  }}
                  buttonHeight="sm"
                />
              )}

            {evidenceItem.status === DELIVERABLE_STATUS.APPROVED &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  text={"Proof of Work"}
                  onSubmit={() => {
                    if (onCreate) onCreate();
                  }}
                  buttonHeight="sm"
                />
              )}

            {evidenceItem.status === DELIVERABLE_STATUS.COMPLETED &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  text={"Manage Proof of Work"}
                  onSubmit={() => {
                    if (onCreate) onCreate();
                  }}
                  buttonHeight="sm"
                />
              )}
            {evidenceItem.status === DELIVERABLE_STATUS.COMPLETED &&
              role === "brand" && (
                <EmpButton
                  className="mt-2"
                  text={"Review Proof of Work"}
                  onSubmit={() => {
                    if (onCreate) onCreate();
                  }}
                  buttonHeight="sm"
                />
              )}
          </div>
        </div>
      )}

      {mode === "skeleton" && (
        <div
          className="emp-evidence-card emp-card no-padding skeleton-wrapper"
          style={{ width: `${width}px` }}
        >
          <div className="image-wrapper">
            <div className="top-section">
              <div className="profile-image emp-shimmer"></div>
              <div className="name emp-shimmer"></div>
            </div>
            <div className="middle-section emp-shimmer"></div>
          </div>
          <div className="details-section">
            <div className="details-wrapper-skeleton">
              <div className="profile-image-2 emp-shimmer"></div>
              <div>
                <div className="name-2 emp-shimmer"></div>
                <div className="status emp-shimmer"></div>
              </div>
            </div>
            <div className="fake-button emp-shimmer mt-4"></div>
          </div>
        </div>
      )}
    </>
  );
};
