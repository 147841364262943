import _debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import "./brand-evidence-view.scss";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import { useLocation } from "react-router-dom";
import StringUtils from "../../../utilities/string.util";
import DraftApi from "../../../api/campaign-msvc/draft.api";
import { DraftDto } from "../../../model/campaign/draft.dto";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import { ExtendedEvidenceDraftDto } from "../../../model/campaign/extended-evidence-draft.dto";
import { EvidenceDto } from "../../../model/campaign/evidence.dto";
import { EmpEvidenceCard } from "../../../components/shared/emp-evidence-card/emp-evidence-card";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import useSelfAdjustingCards from "../../../hooks/useSelfAdjustingCards";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import ReviewEvidenceModal, {
  ReviewEvidenceModalRef,
} from "../../../components/modals/review-evidence-modal";
import { XLoader } from "../../../components/loaders/x-loader";
import { SOCIAL_MEDIA_TYPE } from "../../../constants/app.constants";

interface Props {
  task: TaskDto;
  user: UserDto;
}

export const BrandEvidenceView = (props: Props) => {
  const location = useLocation();
  const { task: taskProp, user } = props;

  const [task, setTask] = useState<TaskDto>(taskProp);
  const [loading, setLoading] = useState(true);

  const reviewEvidenceModalRef = useRef<ReviewEvidenceModalRef>();
  const [ongoingDeliverableMap, setOngoingDeliverableMap] =
    useState<Map<string, OngoingDeliverableExtendedDto>>();
  const brandOrgIdRef = useRef<string>();
  const [mappedExtendedEvidenceList, setMappedExtendedEvidenceList] =
    useState<Map<string, ExtendedEvidenceDraftDto[]>>();

  const GUTTER_WIDTH = 10;
  const MIN_CARD_WIDTH = 240;
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const { cardWidth, shimmerCard } = useSelfAdjustingCards(
    MIN_CARD_WIDTH,
    GUTTER_WIDTH,
    resizeDivRef,
    1
  );

  const fetchBrandOngoingDeliverables = useCallback(async (task: TaskDto) => {
    try {
      setLoading(true);
      const brandOrgId = task.campaign.organisationId;
      brandOrgIdRef.current = brandOrgId;

      const ongoingDeliverableResp =
        await OngoingTaskApi.fetchBrandDeliverables(task.id);
      const latestDraftsResp = await DraftApi.fetchDraftAsBrand(task.id);
      const evidenceResp = await EvidenceApi.fetchEvidenceAsBrand(task.id);

      const ongoingDeliverableMap = new Map<
        string,
        OngoingDeliverableExtendedDto
      >();
      ongoingDeliverableResp.data.forEach((elem) => {
        ongoingDeliverableMap.set(elem.id, elem);
      });
      setOngoingDeliverableMap(ongoingDeliverableMap);
      const latestDraftMap = new Map<string, DraftDto>();
      latestDraftsResp.data.forEach((elem) => {
        latestDraftMap.set(`${elem.deliverableId}_${elem.creatorUserId}`, elem);
      });

      const evidenceMap = new Map<string, EvidenceDto>();
      evidenceResp.data.forEach((elem) => {
        evidenceMap.set(`${elem.id}`, elem);
      });
      const extendedDraftList: ExtendedEvidenceDraftDto[] =
        ongoingDeliverableResp.data.map((elem) => {
          const draft = latestDraftMap.get(elem.id);
          const evidence = evidenceMap.get(elem.id);

          return {
            id: elem.id,
            campaignId: elem.campaignId,
            taskId: elem.taskId,
            deliverableId: elem.deliverableId,
            status: elem.status,
            representativeId: elem.representativeId,
            representativeRole: elem.representativeRole,
            creator: elem.creator,
            agencyRepresentative: elem.agencyRepresentative,
            brand: elem.brand,
            createdDate: elem.createdDate,
            updatedDate: elem.updatedDate,
            revisionsLeft: elem.revisionsLeft,
            remarks: elem.remarks,
            draft: draft && {
              id: draft.id,
              status: draft.status,
              fileType: draft.draftAttachments[0]?.fileType,
              attachmentUrl: draft.draftAttachments[0]?.attachmentUrl,
              attachmentSize: draft.draftAttachments[0]?.attachmentSize,
              attachmentName: draft.draftAttachments[0]?.attachmentName,
              brandResponse: draft.brandResponse,
              remarks: draft.remarks,
              createdDate: draft.createdDate,
              updatedDate: draft.updatedDate,
            },
            evidence: evidence && {
              id: evidence.id,
              taskId: evidence.taskId,
              representativeId: evidence.representativeId,
              representativeRole: evidence.representativeRole,
              agencyOrgId: evidence.agencyOrgId,
              numRecords: evidence.numRecords,
              creatorUserId: evidence.creatorUserId,
              status: evidence.status,
              evidenceRecords: evidence.evidenceRecords,
              createdDate: evidence.createdDate,
              updatedDate: evidence.createdDate,
            },
          };
        });

      extendedDraftList.sort((a, b) => {
        if (a.evidence && b.evidence) {
          return (
            new Date(b.evidence.updatedDate).getTime() -
            new Date(a.evidence.updatedDate).getTime()
          );
        } else if (a.evidence) {
          return -1;
        } else if (b.evidence) {
          return 1;
        } else {
          return 0;
        }
      });

      const map = new Map<string, ExtendedEvidenceDraftDto[]>();
      extendedDraftList.forEach((elem) => {
        if (!map.has(elem.deliverableId)) {
          map.set(elem.deliverableId, [elem]);
        } else {
          map.get(elem.deliverableId)!.push(elem);
        }
      });
      setMappedExtendedEvidenceList(map);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch deliverables"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
      fetchBrandOngoingDeliverables(taskProp);
    }
  }, [taskProp, fetchBrandOngoingDeliverables]);

  const onChat = (deliverableId: string, creatorUserId: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    // Set a query parameter
    urlParams.set("deliverableId", deliverableId);
    urlParams.set("creatorUserId", creatorUserId);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParams}`
    );
    // Let fragment hash
    window.location.hash = "Chat";
  };

  const toDraft = () => {
    window.location.hash = "Submit Draft";
  };

  return (
    <div className="emp-brand-evidence-view">
      {loading && task.platform === SOCIAL_MEDIA_TYPE.X && (
        <XLoader
          title={"Tweet Storm Brewing"}
          description="We will not take more than 2 mins"
        />
      )}
      <section className="task-details-section">
        <ReviewEvidenceModal
          ref={reviewEvidenceModalRef}
          onSave={() => {
            fetchBrandOngoingDeliverables(task);
          }}
        />
        <div className="task-name-wrapper">
          <div className="info-wrapper">
            <h2 className="section-label">View Proof of Work</h2>
            <EmpPill
              text={StringUtils.capitalizeWords(task.status)}
              backgroundColor={Color.PRIMARY[100]}
              color={Color.PRIMARY[600]}
            />
          </div>
        </div>
      </section>

      <section className="deliverable-section" ref={resizeDivRef}>
        {task.deliverables.map((elem, index) => {
          return (
            <div key={elem.id} className="deliverable-wrapper">
              <span className="deliverable-indicator">
                PROOF OF WORK FOR DELIVERABLE {index + 1}
              </span>
              <h3 className="deliverable-name-lbl">{elem.name}</h3>
              <EmpEditorView value={elem.description} />

              <section className="deliverable-cards-section mt-2">
                {/* Loaded Cards */}
                {!loading &&
                  mappedExtendedEvidenceList &&
                  ongoingDeliverableMap &&
                  mappedExtendedEvidenceList.has(elem.id) &&
                  mappedExtendedEvidenceList.get(elem.id)!.map((evidence) => {
                    return (
                      <EmpEvidenceCard
                        cardWidth={cardWidth}
                        key={evidence.id}
                        role="brand"
                        onCreate={() => {
                          reviewEvidenceModalRef.current?.show(
                            brandOrgIdRef.current!,
                            evidence.id,
                            task,
                            ongoingDeliverableMap.get(evidence.id)!
                          );
                        }}
                        onView={() => {}}
                        onChat={() => {
                          onChat(evidence.id, evidence.creator.id);
                        }}
                        toDraft={() => {
                          toDraft();
                        }}
                        evidenceItem={evidence}
                        mode={"default"}
                      />
                    );
                  })}

                {/* Loading Shimmer Cards */}
                {loading &&
                  shimmerCard.map((elem) => {
                    return (
                      <EmpEvidenceCard
                        key={elem}
                        cardWidth={cardWidth}
                        role={"brand"}
                        mode={"skeleton"}
                      />
                    );
                  })}
              </section>
            </div>
          );
        })}
      </section>
    </div>
  );
};
