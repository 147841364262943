/* eslint-disable */

import { empDelay } from "../../utilities/delay";

class FacebookUtils {
  FbClient = undefined;

  BRAND_FB_PERMISSIONS =
    "pages_read_engagement,email,read_insights,pages_show_list,business_management,pages_manage_posts,pages_read_user_content";
  CREATOR_FB_PERMISSIONS =
    "pages_read_engagement,email,read_insights,pages_show_list,business_management";

  BRAND_IG_PERMISSIONS =
    "pages_read_engagement,email,instagram_basic,pages_show_list,business_management,instagram_manage_insights,pages_manage_posts,instagram_content_publish,pages_read_user_content";
  CREATOR_IG_PERMISSIONS =
    "pages_read_engagement,email,instagram_basic,pages_show_list,business_management,instagram_manage_insights";

  constructor() {
    FacebookUtils.instance = this;
  }

  async loadFbClient() {
    for (let i = 0; i < 300; i++) {
      if (typeof FB !== "undefined") {
        this.FbClient = FB;
        break;
      }
      await empDelay(100);
    }
  }

  async getLoginStatus() {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.getLoginStatus((response) => {
        if (response.status === "connected") {
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  async logout() {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.logout((response) => {
        resolve(response);
      });
    });
  }

  async instagramLogin(actor) {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.login(
        (response) => {
          if (response.status === "connected") {
            resolve(this.getLoginStatus());
          } else {
            console.error("Error signing into Instagram");
            reject(response);
          }
        },
        {
          scope:
            actor === "brand"
              ? this.BRAND_IG_PERMISSIONS
              : this.CREATOR_IG_PERMISSIONS,
        }
      );
    });
  }

  async login(actor) {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.login(
        (response) => {
          if (response.status === "connected") {
            resolve(this.getLoginStatus());
          } else {
            console.error("Error binding to Facebook Account");
            reject(response);
          }
        },
        {
          scope:
            actor === "brand"
              ? this.BRAND_FB_PERMISSIONS
              : this.CREATOR_FB_PERMISSIONS,
        }
      );
      // Page Public Metadata Access
    });
  }

  //pages_show_list/

  async getFacebookPages(userId) {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.api(
        `/${userId}/accounts`,
        "GET",
        {
          fields:
            "name,access_token,picture.width(200).height(200),about,followers_count",
        },
        function (response) {
          resolve(response);
        }
      );
    });
  }

  async getInstagramConnectedAccount(userId) {
    if (this.FbClient === undefined) await this.loadFbClient();
    return new Promise((resolve, reject) => {
      this.FbClient.api(
        `/${userId}/accounts`,
        "GET",
        {
          fields:
            "name,access_token,connected_instagram_account{id,profile_picture_url,name,follows_count,followers_count}",
        },
        function (response) {
          resolve(response);
        }
      );
    });
  }
}
export default FacebookUtils;
