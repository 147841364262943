import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { EmpInterceptor } from "../emp-interceptor";

export type CompleteMultipartUploadRequestDto = {
  key: string;
  uploadId: string;
  parts: {
    ETag: string;
    PartNumber: number;
  }[];
};

export type CompleteMultipartUploadResponseDto = {
  data: {
    key: string;
    url: string;
  };
};

const CampaignFileUploaderApi = {
  createMultipartUpload: async (key: string): Promise<ApiResponse<any>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.fileUploader.createMultipartUpload,
      { key }
    );
    return response.data;
  },

  generatePresignedUrl: async (
    partNumber: number,
    uploadId: string,
    key: string
  ): Promise<ApiResponse<any>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.fileUploader.generatePresignedUrl,
      { params: { partNumber, uploadId, key } }
    );
    return response.data;
  },

  completeMultipart: async (
    data: CompleteMultipartUploadRequestDto
  ): Promise<ApiResponse<CompleteMultipartUploadResponseDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<
      ApiResponse<CompleteMultipartUploadResponseDto>
    >(ENDPOINTS.campaignMsvc.fileUploader.completeMultipartUpload, data);

    return response.data;
  },
};
export default CampaignFileUploaderApi;
