import { motion, useAnimation } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Color } from "../../../utilities/colors";
import { empDelay } from "../../../utilities/delay";
import ChevronDownIcon from "../../icon/chevron-down";
import XCloseIcon from "../../icon/x-close-icon";
import ResizeObserver from "resize-observer-polyfill";
import "./emp-modal.scss";

type ModalSizeType = "xs" | "sm" | "md" | "lg" | "xl";
export interface Props {
  background?: string;
  customClassName?: string;
  children: React.ReactElement[];
  header?: string | JSX.Element;
  /** Size of the modal **/
  size?: ModalSizeType;
  showHeader?: boolean;
  showHeaderBorder?: boolean;
  showFooter?: boolean;
  showFooterBorder?: boolean;
  bodyPadding?: boolean;
  verticalPadding?: boolean;
  responsiveSheet?: boolean;
  shouldOverflow?: boolean;

  modalGlobalElement?: JSX.Element;

  /** Control visibility of the modal */
  visible: boolean;
  zIndex?: number;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onMounted?: () => void;
  onClose?: () => void;
}

const modalVariant = {
  hidden: { y: 60, opacity: 0, transition: { duration: 0.2 } },
  visible: { y: 0, opacity: 1, transition: { duration: 0.2 } },
};

const overlayVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const EmpModal = (props: Props) => {
  const control = useAnimation();
  const { customClassName, children, visible, onMounted } = props;
  const background = props.background ?? Color.GRADIENT_CARD_MD;

  const zIndex = props.zIndex ?? 50;
  // Setting default props
  const header = props.header ?? "Modal Title";
  const [modalSize] = useState(props.size ?? "md");


  const [modalVisible, setModalVisible] = useState(visible);
  const [isScrollable, setIsScrollable] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const showHeader = props.showHeader ?? true;
  const showFooter = props.showFooter ?? true;
  const modalGlobalElement = props.modalGlobalElement ?? <></>;
  const shouldOverflow = props.shouldOverflow ?? true;

  const responsiveSheet = props.responsiveSheet ?? false;
  const showHeaderBorder = props.showHeaderBorder ?? true;
  const showFooterBorder = props.showFooterBorder ?? true;
  const bodyPadding = props.bodyPadding ?? true;
  const verticalPadding = props.verticalPadding ?? true;

  const modalBodyRef = useRef<HTMLDivElement>(null);

  // Animate in Effect
  useEffect(() => {
    if (!modalVisible) return;
    console.log("sending mount signal");
    if (onMounted) onMounted();
  }, [modalVisible]);

  useEffect(() => {
    control.start("visible");
  }, [modalVisible]);

  // Animate out Effect
  useEffect(() => {
    if (!visible) {
      // Hide modal after animation has finished playing
      dismissModal();
    } else if (visible) {
      setModalVisible(true);
      control.start("visible");
    }
  }, [visible]);

  const dismissModal = async () => {
    control.start("hidden");
    await empDelay(300);
    setModalVisible(false);
  };

  useEffect(() => {
    const element = modalBodyRef.current;
    if (modalVisible && element) {
      const { scrollHeight, scrollTop, clientHeight } = element;
      setIsScrollable(scrollHeight > clientHeight);
      setHasScrolled(
        scrollHeight - scrollTop - clientHeight < 10 || scrollTop > 30
      );
    }
    const handleScroll = () => {
      if (element) {
        const { scrollHeight, scrollTop, clientHeight } = element;
        setHasScrolled(
          scrollHeight - scrollTop - clientHeight < 10 || scrollTop > 30
        );
      }
    };
    const observer = new ResizeObserver(() => {
      handleScroll();
    });
    element?.addEventListener("scroll", handleScroll);
    if (element) observer.observe(element);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
      if (element) observer.unobserve(element);
    };
  }, [modalVisible]);

  return (
    <>
      {modalVisible && (
        <motion.div
          variants={overlayVariant}
          initial={"hidden"}
          animate={control}
          transition={{ default: { ease: "easeOut" } }}
          className={`emp-modal-overlay ${modalSize} ${customClassName} ${
            responsiveSheet ? "responsive-sheet" : ""
          }`}
          style={{ zIndex }}
        >
          <motion.div
            style={{ background: background }}
            variants={modalVariant}
            initial={"hidden"}
            animate={control}
            transition={{ default: { ease: "easeOut" } }}
            className={`emp-modal-card ${modalSize} ${
              verticalPadding ? "vertical-padding" : ""
            } ${responsiveSheet ? "responsive-sheet" : ""}`}
          >
            {modalGlobalElement}

            {isScrollable && !hasScrolled && (
              <div className="scroll-down-indicator">
                <div className="wrapper">
                  <span className="scroll-lbl">
                    <FormattedMessage id={"cta_scrollDown"} />
                  </span>
                  <ChevronDownIcon
                    size={22}
                    backgroundColor={Color.NEUTRAL[700]}
                  />
                </div>
              </div>
            )}

            {/* Modal Header Section */}
            {showHeader && (
              <div
                className={`emp-modal-header ${
                  showHeaderBorder && "emp-modal-bottom-border"
                }`}
              >
                <h3 className="emp-modal-header-text">{header}</h3>
                <div
                  className="emp-dismiss-wrapper"
                  onClick={() => {
                    if (props.onClose) props.onClose();
                  }}
                >
                  <div className="emp-dismiss-icon">
                    <XCloseIcon />
                  </div>
                </div>
              </div>
            )}
            {/* Modal Body Section  */}
            <div
              ref={modalBodyRef}
              style={{ overflow: shouldOverflow ? "auto" : "hidden" }}
              className={`emp-modal-body ${bodyPadding ? "" : "no-h-padding"} ${
                showFooterBorder && "emp-modal-bottom-border"
              } ${showHeader ? "pt-5" : ""} ${showFooter ? "pb-5" : ""} ${
                responsiveSheet ? "responsive-sheet" : ""
              }`}
            >
              {children[0]}
            </div>
            {/* Modal Footer Section  */}
            {showFooter && (
              <div className="emp-modal-footer">{children[1]}</div>
            )}
          </motion.div>
        </motion.div>
      )}
    </>
  );
};
export default EmpModal;
