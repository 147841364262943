import "./rate-card-accept-confirmation-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import EmpException from "../../exception/empException";
import ToastUtils from "../../utilities/toast-utils";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";

export interface RateCardRejectConfirmationModalRef {
  show: (
    brandOrgId: string,
    brandName: string,
    creatorId: string,
    creatorName: string,
    rateCardRequestId: string
  ) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

interface ModalParams {
  brandOrgId: string;
  brandName: string;
  creatorId: string;
  creatorName: string;
  rateCardRequestId: string;
}

const RateCardRejectConfirmationModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const modalParamsRef = useRef<ModalParams>();
  const rejectBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    brandOrgId: string,
    brandName: string,
    creatorId: string,
    creatorName: string,
    rateCardRequestId: string
  ) => {
    modalParamsRef.current = {
      brandOrgId,
      brandName,
      creatorId,
      creatorName,
      rateCardRequestId,
    };
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    modalParamsRef.current = undefined;
    await empDelay(300);
  };

  const onSubmit = async () => {
    try {
      rejectBtnRef.current?.setButtonState("loading");
      if (!modalParamsRef.current)
        throw new EmpException("No information provided");
      const resp = await RateCardApi.updateRateCardRequest({
        rateCardRequestId: modalParamsRef.current.rateCardRequestId,
        creatorId: modalParamsRef.current.creatorId,
        brandOrgId: modalParamsRef.current.brandOrgId,
        status: "rejected",
      });
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Rejected",
          "The brand will be informed of this approval"
        );
        PubSub.publish(PUB_SUB_TOPICS.RATE_CARD_REQUEST_UPDATE);
        dismiss();
        props.onSave();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when approving this request."
      );
    } finally {
      rejectBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={true}
      header={"Confirmation to reject rate card reveal"}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        <div className="emp-rate-card-accept-confirmation-modal">
          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate={"visible"}
            transition={{ duration: 0.2, delay: 0 * animationDelay }}
          ></motion.div>
          {modalParamsRef.current && (
            <div className="content-section">
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 2 * animationDelay,
                }}
                className="emp-paragraph"
              >
                I decline my consent for{" "}
                <span className="emp-highlighted">
                  {modalParamsRef.current.brandName}
                </span>{" "}
                to access and view the rates pertaining to{" "}
                <span className="emp-highlighted">
                  {modalParamsRef.current.creatorName}
                </span>
                .
              </motion.p>

              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                className={"button-wrapper mt-3"}
                transition={{
                  duration: animationDuration,
                  delay: 4 * animationDelay,
                }}
              >
                <EmpButton
                  buttonStyle={"secondary"}
                  isFullWidth={false}
                  onSubmit={() => {
                    dismiss();
                  }}
                  text={"Cancel"}
                />

                <EmpButton
                  ref={rejectBtnRef}
                  isFullWidth={false}
                  onSubmit={onSubmit}
                  text={"Reject"}
                />
              </motion.div>
            </div>
          )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default RateCardRejectConfirmationModal;
