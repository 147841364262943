import React, { useEffect, useState } from "react";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
}
export const EmpImage = ({ fallback, ...imgProps }: Props) => {
  const [imgSrc, setImgSrc] = useState(imgProps.src ?? imgProps.srcSet);

  useEffect(() => {
    setImgSrc(imgProps.src ?? imgProps.srcSet);
  }, [imgProps]);
  const handleError = () => {
    setImgSrc(fallback ?? "");
  };

  return (
    <img
      {...imgProps}
      src={imgSrc}
      alt={imgProps.alt}
      onError={handleError}
      referrerPolicy="no-referrer"
    />
  );
};
