const LeafElement = (props: any) => {
  return (
    <span
      className="slate-leaf"
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? "600" : "400" }}
    >
      {props.children}
    </span>
  );
};

export default LeafElement;
