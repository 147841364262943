import { RefObject, useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";

export const useCountGridColumns = (
  containerRef: RefObject<HTMLDivElement>,
  defaultCount: number = 1
): number => {
  const [visibleCols, setVisibleCols] = useState(defaultCount);

  const calcColumns = useCallback(() => {
    if (!containerRef.current) return;
    const containerComputerStyle = window.getComputedStyle(
      containerRef.current
    );
    const nCols = containerComputerStyle
      .getPropertyValue("grid-template-columns")
      .replace(" 0px", "")
      .split(" ").length;

    setVisibleCols(nCols);
  }, [containerRef]);

  // eslint-disable-next-line
  const debounceFn: () => void = useCallback(
    _debounce(calcColumns, 300, { leading: true }),
    []
  );

  useEffect(() => {
    if (!containerRef.current) return;
    calcColumns();
    const resizeObserver = new ResizeObserver(() => {
      debounceFn();
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [containerRef, calcColumns, debounceFn]);

  return visibleCols;
};
