import { useEffect, useMemo, useRef, useState } from "react";
import {
  NEGOTIATION_STATUS,
  PLATFORM_ROLES,
} from "../../constants/app.constants";
import { NegotiationLogDto } from "../../model/campaign/negotiation-log.dto";
import { Color } from "../../utilities/colors";
import { DateUtil } from "../../utilities/date";
import StringUtils from "../../utilities/string.util";
import TaskResponseModal, {
  TaskResponseModalRef,
} from "../modals/task-response-modal";
import EmpLink from "../shared/emp-link/emp-link";
import EmpPill from "../shared/EmpPill/EmpPill";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableProps,
} from "../shared/EmpTable/EmpTable";
import "./negotiation-log-table.scss";

interface Props {
  rawRecords: NegotiationLogDto[];
  perspective: "brand" | "agency" | "creator";
}

export const NegotiationLogTable = (props: Props) => {
  const { rawRecords, perspective } = props;
  const [records, setRecords] = useState<NegotiationLogDto[]>();
  const [tableProps, setTableProps] =
    useState<EmpTableProps<NegotiationLogDto>>();
  const taskResponseModalRef = useRef<TaskResponseModalRef>();

  useEffect(() => {
    setTableProps({
      rowKey: "id",
      data: rawRecords,
      contentColumns: contentSpec,
    });
    setRecords(rawRecords);
  }, [rawRecords]);

  useEffect(() => {
    console.log(records);
  }, [records]);

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<NegotiationLogDto>[] = useMemo(() => {
    return [
      {
        title: "Initiator",
        dataIndex: "initiator",
        render: (record: NegotiationLogDto) => {
          if (record.initiatorRole === "brand") {
            return (
              <div className="initiator-col">
                <div className="profile-wrapper">
                  <img
                    className="profile org"
                    alt={record.brand.companyName}
                    src={record.brand.logo}
                  />
                  {record.creator.imageType === "url" && (
                    <img
                      className="profile profile-badge"
                      alt={record.creator.fullName}
                      src={record.creator.imageResource}
                    />
                  )}
                  {record.creator.imageType === "avatar" && (
                    <div
                      className="profile profile-badge"
                      style={{ background: record.creator.imageResource }}
                    >
                      <span>{record.creator.initials}</span>
                    </div>
                  )}
                </div>
                <span>{record.brand.companyName}</span>
              </div>
            );
          }
          if (record.initiatorRole === "agency") {
            return (
              <div className="initiator-col">
                <div className="profile-wrapper">
                  <img
                    className="profile org"
                    alt={record.agencyRepresentative.companyName}
                    src={record.agencyRepresentative.logo}
                  />
                  {record.creator.imageType === "url" && (
                    <img
                      className="profile profile-badge"
                      alt={record.creator.fullName}
                      src={record.creator.imageResource}
                    />
                  )}
                  {record.creator.imageType === "avatar" && (
                    <div
                      className="profile profile-badge"
                      style={{ background: record.creator.imageResource }}
                    >
                      <span>{record.creator.initials}</span>
                    </div>
                  )}
                </div>
                <span>{record.agencyRepresentative.companyName}</span>
              </div>
            );
          } else {
            return (
              <div className="initiator-col">
                <div className="profile-wrapper">
                  {record.creator.imageType === "url" && (
                    <img
                      className="profile"
                      alt={record.creator.fullName}
                      src={record.creator.imageResource}
                    />
                  )}
                  {record.creator.imageType === "avatar" && (
                    <div
                      className="profile"
                      style={{ background: record.creator.imageResource }}
                    >
                      <span>{record.creator.initials}</span>
                    </div>
                  )}
                </div>
                <span>{record.creator.fullName}</span>
              </div>
            );
          }
        },
      },
      {
        title: "Timestamp",
        dataIndex: "createdDate",
        render: (record: NegotiationLogDto) => {
          return <>{DateUtil.toReadableDateWithTime(record.createdDate)}</>;
        },
      },
      {
        title: "Message",
        dataIndex: "formattedMessage",
        render: (record: NegotiationLogDto) => {
          return formatMessage(record);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record: NegotiationLogDto) => {
          return (
            <EmpPill
              backgroundColor={Color.PRIMARY[100]}
              color={Color.PRIMARY[600]}
              text={StringUtils.convertHyphensToTitleCase(record.status)}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        // render: (record: NegotiationLogDto) => {
        //     if (record.hasAction) return <EmpLink text={"Respond"} />
        //     return <></>
        // }
        render: (record: NegotiationLogDto) => {
          return <></>;
        },
      },
    ];
  }, []);

  const formatMessage = (negotiationLog: NegotiationLogDto): JSX.Element => {
    // ACCEPTED MESSAGE FORMATTING
    if (
      negotiationLog.status === NEGOTIATION_STATUS.SELLER_ACCEPT &&
      negotiationLog.initiatorRole === PLATFORM_ROLES.CREATOR
    ) {
      return (
        <span className="negotiation-text">
          <EmpLink text={negotiationLog.creator.fullName} /> has accepted the
          offer to join task.
        </span>
      );
    }
    if (
      negotiationLog.status === NEGOTIATION_STATUS.SELLER_ACCEPT &&
      negotiationLog.initiatorRole === PLATFORM_ROLES.AGENCY
    ) {
      if (perspective === "agency")
        return (
          <span className="negotiation-text">
            You have accepted on behalf of&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;on the offer to join task.
          </span>
        );
      return (
        <span className="negotiation-text">
          <EmpLink text={negotiationLog.agencyRepresentative.companyName} />
          &nbsp;has accepted on behalf of&nbsp;
          <EmpLink text={negotiationLog.creator.fullName} />
          &nbsp;on the offer to join task.
        </span>
      );
    }
    if (negotiationLog.status === NEGOTIATION_STATUS.BRAND_ACCEPT) {
      if (perspective === "brand")
        return (
          <span className="negotiation-text">
            You have accepted&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;to join task.
          </span>
        );
      return (
        <span className="negotiation-text">
          <span className="emp-highlighted">
            {negotiationLog.brand.companyName}
          </span>{" "}
          has accepted&nbsp;
          <EmpLink text={negotiationLog.creator.fullName} />
          &nbsp;to join task.
        </span>
      );
    }

    // BRAND COUNTEROFFER
    if (
      negotiationLog.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE &&
      negotiationLog.initiatorRole === PLATFORM_ROLES.BRAND
    ) {
      if (perspective === "brand")
        return (
          <span className="negotiation-text">
            You have submitted a counteroffer for&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;to join.
          </span>
        );
      if (perspective === "agency")
        return (
          <span className="negotiation-text">
            <span className="emp-highlighted">
              {negotiationLog.brand.companyName}
            </span>
            &nbsp; has submitted a counteroffer for&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;to join.
          </span>
        );
      if (perspective === "creator")
        return (
          <span className="negotiation-text">
            <span className="emp-highlighted">
              {negotiationLog.brand.companyName}
            </span>
            &nbsp; has submitted a counteroffer.
          </span>
        );
    }

    // SELLER COUNTEROFFER
    if (negotiationLog.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE) {
      if (perspective === "brand" && negotiationLog.initiatorRole === "agency")
        return (
          <span className="negotiation-text">
            You have received a counteroffer from&nbsp;
            <EmpLink text={negotiationLog.agencyRepresentative.companyName} />,
            representing&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />.
          </span>
        );
      if (perspective === "brand" && negotiationLog.initiatorRole === "creator")
        return (
          <span className="negotiation-text">
            You have received a counteroffer from&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />.
          </span>
        );
      if (perspective === "agency" || perspective === "creator")
        return (
          <span className="negotiation-text">
            You have submitted a counteroffer.
          </span>
        );
    }
    if (
      negotiationLog.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE &&
      negotiationLog.initiatorRole === PLATFORM_ROLES.BRAND
    ) {
      return (
        <span className="negotiation-text">
          You have submitted a counteroffer for&nbsp;
          <EmpLink text={negotiationLog.creator.fullName} />
          &nbsp;to join.
        </span>
      );
    }

    // INVITE
    if (negotiationLog.status === NEGOTIATION_STATUS.INVITED) {
      if (
        perspective === "brand" &&
        negotiationLog.representativeRole === "agency"
      )
        return (
          <span className="negotiation-text">
            You have invited&nbsp;
            <EmpLink text={negotiationLog.agencyRepresentative.companyName} />,
            representing&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} /> to join the task.
          </span>
        );
      if (
        perspective === "brand" &&
        negotiationLog.representativeRole === "creator"
      )
        return (
          <span className="negotiation-text">
            You have invited&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;to join the task.
          </span>
        );
      if (perspective === "agency" || perspective === "creator")
        return (
          <span className="negotiation-text">
            <EmpLink text={negotiationLog.brand.companyName} />
            &nbsp;have invited you to join his task.
          </span>
        );
    }

    // INVITE
    if (negotiationLog.status === NEGOTIATION_STATUS.APPLIED) {
      if (
        perspective === "brand" &&
        negotiationLog.representativeRole === "agency"
      )
        return (
          <span className="negotiation-text">
            <EmpLink text={negotiationLog.agencyRepresentative.companyName} />
            &nbsp;has applied on bahelf of&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} /> to join the task.
          </span>
        );
      if (
        perspective === "brand" &&
        negotiationLog.representativeRole === "creator"
      )
        return (
          <span className="negotiation-text">
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;has applied to join the task.
          </span>
        );
      if (perspective === "agency")
        return (
          <span className="negotiation-text">
            You have applied on behalf of&nbsp;
            <EmpLink text={negotiationLog.creator.fullName} />
            &nbsp;has applied to join his task.
          </span>
        );
      if (perspective === "creator")
        return (
          <span className="negotiation-text">
            You have applied to join his task.
          </span>
        );
    }

    return <>This is unprocessed message</>;
  };

  return (
    <div className="negotiation-log-table">
      <TaskResponseModal onSave={() => {}} ref={taskResponseModalRef} />
      {tableProps && (
        <EmpTable
          contentColumns={tableProps.contentColumns}
          data={tableProps.data}
          rowKey={tableProps.rowKey}
        />
      )}
    </div>
  );
};
