import "./creator-has-exclusive-agency-modal.scss";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { UserDto } from "../../model/user-management/user.dto";
import UserApi from "../../api/user-msvc/user.api";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import { FormattedMessage } from "react-intl";
import EmpException from "../../exception/empException";

export interface CreatorHasExclusiveAgencyModalRef {
  show: (creatorIdentifier: "email" | "creatorId", identifier: string) => void;
  dismiss: () => void;
}

interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const CreatorHasExclusiveAgencyModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [creator, setCreator] = useState<UserDto>();
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    creatorIdentifier: "email" | "creatorId",
    identifier: string
  ) => {
    setVisible(true);
    fetchCreatorInfo(creatorIdentifier, identifier);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setCreator(undefined);
  };

  const fetchCreatorInfo = useCallback(
    async (creatorIdentifier: "email" | "creatorId", identifier: string) => {
      try {
        if (creatorIdentifier === "creatorId") {
          const resp = await UserApi.fetchCreatorById(identifier);
          setCreator(resp.data);
        } else if (creatorIdentifier === "email") {
          const resp = await UserApi.fetchCreatorByEmail(identifier);
          setCreator(resp.data);
        } else throw new EmpException("Invalid creator identifier");
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator specifications"
        );
      }
    },
    []
  );

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"xs"}
    >
      {/* This is body */}
      <div>
        {creator && (
          <div className="emp-creator-has-exclusive-agency-modal">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 0.2, delay: 0 * animationDelay }}
            >
              {creator.imageType === "url" && (
                <img
                  alt="Profile"
                  className="profile-photo"
                  src={creator.imageResource}
                />
              )}
              {creator.imageType === "none" && (
                <img
                  alt="Upload Image"
                  src={
                    "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  }
                />
              )}
              {creator.imageType === "avatar" && (
                <div
                  style={{ background: creator.imageResource }}
                  className="avatar"
                >
                  <span className="initials">{creator.initials}</span>
                </div>
              )}
            </motion.div>
            <div className="content-section">
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 1 * animationDelay,
                }}
                className="invitation-failed-lbl"
              >
                <FormattedMessage id="exclusiveInvitationFailedModal_header" />
              </motion.span>
              <motion.span
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 2 * animationDelay,
                }}
                className="heading"
              >
                <FormattedMessage
                  id="exclusiveInvitationFailedModal_subtitle"
                  values={{ name: creator.fullName }}
                />
              </motion.span>
              <motion.p
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 3 * animationDelay,
                }}
                className="description mt-8"
              >
                <FormattedMessage id="exclusiveInvitationFailedModal_desc" />
              </motion.p>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{
                  duration: animationDuration,
                  delay: 4 * animationDelay,
                }}
              >
                <EmpButton
                  onSubmit={() => {
                    dismiss();
                  }}
                  className="mt-8"
                  text={<FormattedMessage id="cta_ack" />}
                />
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CreatorHasExclusiveAgencyModal;
