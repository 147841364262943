import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import _debounce from "lodash/debounce";
import { TaskDto } from "../../../model/campaign/task.dto";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { ATTACHMENT_TYPE, TASK_TYPE } from "../../../constants/app.constants";
import EmpException from "../../../exception/empException";
import { Color } from "../../../utilities/colors";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import EmpSelect from "../../shared/emp-select/emp-select";
import {
  instagramMediaTypeOptions,
  platformOptions,
} from "../../../constants/selectConstants";
import EmpDatePicker from "../../shared/emp-date-picker/emp-date-picker";
import UploadIcon from "../../icon/upload-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import AlertSquareIcon from "../../icon/alert-square";
import FileUtils from "../../../utilities/file-util";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import {
  CreateEvidenceDto,
  SocialMediaImageEvidence,
} from "../../../model/campaign/create-evidence.dto";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import ToastUtils from "../../../utilities/toast-utils";
import { DateRangePicker } from "rsuite";
import { motion } from "framer-motion";
import EmpLoaderV2 from "../../shared/emp-loader-v2/emp-loader-v2";
import { uploadCampaignFileToS3 } from "../../../utilities/campaign-file-uploader.util";

interface Props {
  task: TaskDto;
  ongoingDeliverable: OngoingDeliverableExtendedDto;
  onEvidenceCreated: () => void;
  back: () => void;
}

type IgMediaType = "post" | "story" | "none";

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

/**
 * SubmitEvidenceSmImageView component handles the submission of social media image evidence.
 * It allows users to upload images, validate them, and submit evidence for various social media platforms.
 *
 * @component
 *
 * @param {Props} prop - The properties passed to the component.
 * @param {Task} prop.task - The task associated with the evidence submission.
 * @param {OngoingDeliverable} prop.ongoingDeliverable - The ongoing deliverable details.
 * @param {Function} prop.onEvidenceCreated - Callback function to be called when evidence is successfully created.
 * @param {Function} prop.back - Callback function to navigate back.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <SubmitEvidenceSmImageView
 *   task={task}
 *   ongoingDeliverable={ongoingDeliverable}
 *   onEvidenceCreated={handleEvidenceCreated}
 *   back={handleBack}
 * />
 */
const SubmitEvidenceSmImageView = (prop: Props) => {
  const { task, ongoingDeliverable, onEvidenceCreated, back } = prop;
  const intl = useIntl();
  const [file, setFile] = useState<File>();
  const [imageFiles, setImageFiles] = useState<File[]>();
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const submitEvidenceBtnRef = useRef<EmpButtonRef>();
  const [isConverting, setConverting] = useState(false);
  const [currentPlatform, setCurrentPlatform] = useState<string>();
  const [defaultForm, setDefaultForm] = useState<IFormGroup>({
    followers: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    link: new FormControl("text", [
      new RequiredValidator(intl.formatMessage({ id: "validation_required" })),
      new PatternValidator(
        /^(https?:\/\/)(www\.)?([\w-]+\.)+[a-z]{2,}(\/\S*)?$/,
        intl.formatMessage({ id: "validation_invalidSocialMediaUrl" })
      ),
    ]),
    remarks: new FormControl("text", []),
    dateOfPosting: new FormControl("date", [new RequiredValidator()]),
  });
  const [instagramSelectForm] = useState<IFormGroup>({
    mediaType: new FormControl("text", []),
  });
  const [platformSelectForm] = useState<IFormGroup>({
    platform: new FormControl("text", []),
  });
  const [facebookForm, setFacebookForm] = useState<IFormGroup>({
    reactions: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    comments: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    impressions: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    shares: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
  });
  const [xForm, setXForm] = useState<IFormGroup>({
    viewCount: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    likeCount: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    retweetCount: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    replyCount: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
  });

  const [instagramFeedForm, setInstagramFeedForm] = useState<IFormGroup>({
    likes: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    comments: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    views: new FormControl("text", [
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    shares: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
  });
  const [instagramStoryForm, setInstagramStoryForm] = useState<IFormGroup>({
    views: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    reactions: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    comments: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    shares: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
  });
  const [tiktokForm, setTikTokForm] = useState<IFormGroup>({
    views: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    likes: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    comments: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
    shares: new FormControl("text", [
      new RequiredValidator(),
      new PatternValidator(/^-?\d*$/, "Numbers only"),
    ]),
  });

  useEffect(() => {}, [task]);

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFile(undefined);
    setImageFiles(undefined);
    setErrorMessage(undefined);
    const files: FileList | null = event.target.files;
    if (!files || files.length === 0) return;
    const processedImageFiles: File[] = [];
    const imageFiles = FileUtils.findImage(files);
    for (let image of imageFiles) {
      if (!FileUtils.isFileSizeSmallerThanMB(image, 3)!) {
        setErrorMessage("Image size must not exceed 3Mb");
      }
      if (image.type === "image/heic") {
        setConverting(true);
        const heicFile = await FileUtils.convertHeicToJpeg(image);
        processedImageFiles.push(heicFile);
        setConverting(false);
      } else {
        processedImageFiles.push(image);
      }
    }
    setImageFiles(processedImageFiles);
    setHasFiles(true);
  };

  const isSubmittingEvidenceRef = useRef<boolean>(false);

  const onSubmitEvidence = async () => {
    try {
      if (isSubmittingEvidenceRef.current || !currentPlatform) return;
      isSubmittingEvidenceRef.current = true;
      submitEvidenceBtnRef.current?.setButtonState("loading");
      // Do validation on the submitted file.
      let imageHasErrors = false;
      if (!imageFiles || imageFiles?.length === 0) {
        setErrorMessage("Please upload at least one image ");
        imageHasErrors = true;
      }
      const defaultFormHasErrors = !FormGroupUtil.validate(defaultForm);
      setDefaultForm({ ...defaultForm });

      // Return early if image and shared form has errors.
      if (imageHasErrors || defaultFormHasErrors) {
        return;
      }
      // Processing the uploaded files first.
      const files: {
        s3Url: string;
        name: string;
        size: number;
        fileType: string;
      }[] = [];
      for (let image of imageFiles!) {
        const s3Url = await uploadCampaignFileToS3(
          image,
          "evidence-attachments"
        );
        files.push({
          s3Url,
          name: image.name,
          size: image.size,
          fileType: ATTACHMENT_TYPE.IMAGE,
        });
      }

      const socialMediaImagePartial: Partial<SocialMediaImageEvidence> = {
        postLink: defaultForm.link.getValue(),
        dateOfPosting: defaultForm.dateOfPosting.getValue(),
        platform: currentPlatform,
        files: files,
      };

      const requestWithCommonValues: Partial<CreateEvidenceDto> = {
        representativeId: ongoingDeliverable.representativeId,
        representativeRole: ongoingDeliverable.representativeRole,
        taskId: ongoingDeliverable.taskId,
        creatorId: ongoingDeliverable.creatorUserId,
        agencyOrgId: ongoingDeliverable.agencyOrgId,
        deliverableId: ongoingDeliverable.deliverableId,
        evidenceRecord: {
          platform: currentPlatform!,
          remarks: defaultForm.remarks.getValue(),
          followerCount: Number(defaultForm.followers.getValue()),
          evidenceType: "sm-image",
        },
      };

      // Validating Facebook Evidences
      if (currentPlatform === TASK_TYPE.FACEBOOK) {
        const smFormHasErrors = !FormGroupUtil.validate(facebookForm);
        setFacebookForm({ ...facebookForm });
        if (smFormHasErrors) {
          return;
        }
        socialMediaImagePartial.metrics = {
          reactionCount: Number(facebookForm.reactions.getValue()),
          commentCount: Number(facebookForm.comments.getValue()),
          shareCount: Number(facebookForm.shares.getValue()),
          impressionCount: Number(facebookForm.impressions.getValue()),
        };
        socialMediaImagePartial.mediaType = "facebook-post";
        socialMediaImagePartial.generalMediaType = "facebook-post";
      } else if (currentPlatform === TASK_TYPE.TIKTOK) {
        const smFormHasErrors = !FormGroupUtil.validate(tiktokForm);
        setTikTokForm({ ...tiktokForm });
        if (smFormHasErrors) {
          return;
        }
        socialMediaImagePartial.metrics = {
          viewCount: Number(tiktokForm.views.getValue()),
          likeCount: Number(tiktokForm.likes.getValue()),
          commentCount: Number(tiktokForm.comments.getValue()),
          shareCount: Number(tiktokForm.shares.getValue()),
        };
        socialMediaImagePartial.mediaType = "tiktok-video";
        socialMediaImagePartial.generalMediaType = "tiktok-video";
      } else if (currentPlatform === TASK_TYPE.X) {
        const smFormHasErrors = !FormGroupUtil.validate(xForm);
        setXForm({ ...xForm });
        if (smFormHasErrors) {
          return;
        }

        socialMediaImagePartial.metrics = {
          viewCount: Number(xForm.viewCount.getValue()),
          likeCount: Number(xForm.likeCount.getValue()),
          commentCount: Number(xForm.replyCount.getValue()),
          retweetCount: Number(xForm.retweetCount.getValue()),
        };
        socialMediaImagePartial.mediaType = "x-tweet";
        socialMediaImagePartial.generalMediaType = "x-tweet";
      } else if (
        currentPlatform === TASK_TYPE.INSTAGRAM &&
        instagramMediaType === "post"
      ) {
        const smFormHasErrors = !FormGroupUtil.validate(instagramFeedForm);
        setInstagramFeedForm({ ...instagramFeedForm });
        if (smFormHasErrors) return;

        socialMediaImagePartial.metrics = {
          likeCount: Number(instagramFeedForm.likes.getValue()),
          commentCount: Number(instagramFeedForm.comments.getValue()),
          viewCount:
            instagramFeedForm.views.getValue() === ""
              ? undefined
              : Number(instagramFeedForm.views.getValue()),
          shareCount: Number(instagramFeedForm.shares.getValue()),
        };
        socialMediaImagePartial.mediaType = "instagram-post";
        socialMediaImagePartial.generalMediaType = "instagram-post";
      } else if (
        currentPlatform === TASK_TYPE.INSTAGRAM &&
        instagramMediaType === "story"
      ) {
        const smFormHasErrors = !FormGroupUtil.validate(instagramStoryForm);
        setInstagramStoryForm({ ...instagramStoryForm });
        if (smFormHasErrors) return;

        socialMediaImagePartial.metrics = {
          viewCount: Number(instagramStoryForm.views.getValue()),
          reactionCount: Number(instagramStoryForm.reactions.getValue()),
          commentCount: Number(instagramStoryForm.comments.getValue()),
          shareCount: Number(instagramStoryForm.shares.getValue()),
        };
        socialMediaImagePartial.mediaType = "instagram-story";
        socialMediaImagePartial.generalMediaType = "instagram-story";
      } else {
        throw new EmpException("Unrecognized Platform");
      }

      // Construct the request here
      requestWithCommonValues.evidenceRecord!.socialMediaImage =
        socialMediaImagePartial as SocialMediaImageEvidence;
      const request = requestWithCommonValues as CreateEvidenceDto;
      const resp = await EvidenceApi.createEvidence(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Evidence Submitted",
          "Thank you for updating the evidence"
        );
        onEvidenceCreated();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error submitting evidence"
      );
    } finally {
      isSubmittingEvidenceRef.current = false;
      submitEvidenceBtnRef.current?.setButtonState("default");
    }
  };

  // Instagram Section
  const [instagramMediaType, setInstagramMediaType] =
    useState<IgMediaType>("none");

  const instagramMediaTypeOnChange = (formControl: FormControl) => {
    if (formControl.getValue() === "post") {
      setInstagramMediaType("post");
    } else if (formControl.getValue() === "story") {
      setInstagramMediaType("story");
    } else if (formControl.getValue === undefined)
      setInstagramMediaType("none");
    else throw new EmpException("Unrecgonized Instagram Media Type");
  };

  const platformSelectOnChange = (formControl: FormControl) => {
    const platform = formControl.getValue();
    setCurrentPlatform(platform);
  };

  /**
   * Generates a description for file upload instructions based on the current social media platform and media type.
   * @returns {string} A string containing the upload instructions specific to the platform and media type.
   * If the platform is not recognized, an empty string is returned.
   */
  const fileUploadDescription = useMemo(() => {
    if (currentPlatform === TASK_TYPE.INSTAGRAM) {
      if (instagramMediaType === "post") {
        return "Upload a screenshot of the Instagram post, clearly showing the image/video, caption, and engagement metrics such as likes, comments, shares, and views (for videos). Ensure the metrics are fully visible.";
      } else if (instagramMediaType === "story") {
        return "Upload a screenshot of the Instagram Story, displaying any visible metrics like views, interactions (swipe-ups, poll results), and emoji reactions. Make sure the date and time of the Story are also visible.";
      }
    } else if (currentPlatform === TASK_TYPE.FACEBOOK) {
      return "Upload a screenshot of your Facebook post, including the image/video, caption, and key engagement metrics like reactions (likes, love, etc.), comments, shares, and views (for videos).";
    } else if (currentPlatform === TASK_TYPE.TIKTOK) {
      return "Upload a screenshot of the TikTok video, showing the video itself along with key metrics like likes, comments, shares, and views. If applicable, include information on audience engagement such as reactions or duets.";
    } else if (currentPlatform === TASK_TYPE.X) {
      // formerly Twitter
      return "Upload a screenshot of your X (formerly Twitter) post, showing the tweet content, including media (if applicable), and metrics like likes, retweets, replies, and views. Ensure the metrics and engagement are clearly visible.";
    }
    return "";
  }, [currentPlatform, instagramMediaType]);

  return (
    <div className="sm-image-view view-form-section">
      {isConverting && (
        <motion.div
          variants={fadeInVariant}
          initial="hidden"
          animate="visible"
          className="heic-conversion-overlay"
        >
          <EmpLoaderV2 isLoading={true} loadingText="Converting HEIC..." />
        </motion.div>
      )}
      <h2 className="section-title">Social Media Image Upload</h2>
      <p className="emp-paragraph mt-1">
        Upload your image proof of work here as part of the task submission.
      </p>
      <section></section>
      {/* GENERAL TASK */}
      <section className="mt-4">
        <EmpSelect
          labelText="Social Media Platform"
          required
          onChange={platformSelectOnChange}
          selectOptions={platformOptions}
          placeholder="Select a Social Media Platform"
          id={"socialMediaPlatform"}
          formControl={platformSelectForm.platform}
        />
      </section>

      {currentPlatform && (
        <section className="mt-4">
          {currentPlatform === TASK_TYPE.INSTAGRAM && (
            <EmpSelect
              labelText="Instagram Media Type"
              required
              onChange={instagramMediaTypeOnChange}
              selectOptions={instagramMediaTypeOptions}
              placeholder="Select Instagram Media Type"
              id={"mediaType"}
              formControl={instagramSelectForm.mediaType}
            />
          )}

          {((currentPlatform === TASK_TYPE.INSTAGRAM &&
            instagramMediaType !== "none") ||
            currentPlatform !== TASK_TYPE.INSTAGRAM) && (
            <section>
              <div className="split-form">
                <div className="split-form-control">
                  <EmpTextInput
                    id={"link"}
                    required
                    labelText="Post Link"
                    formControl={defaultForm.link}
                    placeholder={`Enter ${currentPlatform} post link here`}
                  />
                </div>
                <div className="split-form-control">
                  <EmpDatePicker
                    disabledDate={DateRangePicker.afterToday}
                    isRequired
                    label="Date of Posting"
                    formControl={defaultForm.dateOfPosting}
                    placeholder={`Please select date of posting`}
                  />
                </div>
              </div>
              <div className="uploader-section mt-2">
                <label className="upload-label">
                  Upload Image <span className="required">*</span>
                </label>
                <p className="emp-paragraph">{fileUploadDescription}</p>
                {!hasFiles && (
                  <>
                    <label
                      className="file-upload-zone mt-2"
                      htmlFor={"logo-upload"}
                    >
                      <UploadIcon
                        size={35}
                        backgroundColor={Color.NEUTRAL[300]}
                      />
                      <span className="title">
                        Click here to upload your image attachments
                      </span>
                      <p className="specs">
                        Please upload up to 4 images, ensuring that each image
                        is no larger than 2MB in size.
                      </p>
                    </label>
                  </>
                )}
                {hasFiles && (
                  <div className="uploaded-attachment-wrapper mt-2">
                    {imageFiles && (
                      <div className="images-wrapper">
                        {imageFiles.map((file, index) => {
                          return (
                            <img
                              key={file.name}
                              alt={`attachment ${index}`}
                              src={URL.createObjectURL(file)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {hasFiles && (
                  <label className="mt-1" htmlFor={"logo-upload"}>
                    <EmpLink text={"Replace this upload"} />
                  </label>
                )}
                <input
                  className="upload-hidden"
                  type="file"
                  id="logo-upload"
                  onChange={handleFileUpload}
                  name="files[]"
                  multiple
                ></input>
                {errorMessage && (
                  <div className="emp-error-message-wrapper">
                    <AlertSquareIcon
                      backgroundColor={Color.RED[600]}
                      size={16}
                      bottom={1}
                    />
                    <span>{errorMessage}</span>
                  </div>
                )}
              </div>
              <div className="split-form-control mt-3">
                <EmpTextInput
                  id={"followers"}
                  required
                  labelText="Follower Count"
                  description="Number of followers helps determine this post's engagement rate."
                  formControl={defaultForm.followers}
                  placeholder={`Enter Follower Count`}
                />
              </div>
              {currentPlatform === TASK_TYPE.FACEBOOK && (
                <Fragment>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"reactions"}
                        required
                        labelText="Reactions"
                        formControl={facebookForm.reactions}
                        placeholder={`Number of reactions`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"comments"}
                        required
                        labelText="Comments"
                        formControl={facebookForm.comments}
                        placeholder={`Number of Comments`}
                      />
                    </div>
                  </div>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"impressions"}
                        required
                        labelText="Impressions"
                        formControl={facebookForm.impressions}
                        placeholder={`Impressions Counts`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"shares"}
                        optional
                        labelText="Shares"
                        formControl={facebookForm.shares}
                        placeholder={`Number of Shares`}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              {currentPlatform === TASK_TYPE.TIKTOK && (
                <Fragment>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"views"}
                        required
                        labelText="Views"
                        formControl={tiktokForm.views}
                        placeholder={`Number of views`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"likes"}
                        required
                        labelText="Likes"
                        formControl={tiktokForm.likes}
                        placeholder={`Number of likes`}
                      />
                    </div>
                  </div>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"comments"}
                        required
                        labelText="Comments"
                        formControl={tiktokForm.comments}
                        placeholder={`Number of comments`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"shares"}
                        required
                        labelText="Shares"
                        formControl={tiktokForm.shares}
                        placeholder={`Number of shares`}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
              {currentPlatform === TASK_TYPE.X && (
                <Fragment>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"views"}
                        required
                        labelText="Views"
                        formControl={xForm.viewCount}
                        placeholder={`Number of views`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"likes"}
                        required
                        labelText="Likes"
                        formControl={xForm.likeCount}
                        placeholder={`Number of likes`}
                      />
                    </div>
                  </div>
                  <div className="split-form mt-3">
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"reply"}
                        required
                        labelText="Replies"
                        formControl={xForm.replyCount}
                        placeholder={`Number of replies`}
                      />
                    </div>
                    <div className="split-form-control">
                      <EmpTextInput
                        id={"retweets"}
                        required
                        labelText="Retweets"
                        formControl={xForm.retweetCount}
                        placeholder={`Number of retweets`}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              {currentPlatform === TASK_TYPE.INSTAGRAM &&
                instagramMediaType === "post" && (
                  <Fragment>
                    <div className="split-form mt-3">
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"likes"}
                          required
                          labelText="Likes"
                          formControl={instagramFeedForm.likes}
                          placeholder={`Number of likes`}
                        />
                      </div>
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"comments"}
                          required
                          labelText="Comments"
                          formControl={instagramFeedForm.comments}
                          placeholder={`Number of Comments`}
                        />
                      </div>
                    </div>
                    <div className="split-form mt-3">
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"views"}
                          labelText="Views"
                          optional
                          formControl={instagramFeedForm.views}
                          placeholder={`Number of Views`}
                        />
                      </div>
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"shares"}
                          optional
                          labelText="Shares"
                          formControl={instagramFeedForm.shares}
                          placeholder={`Number of Shares`}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              {instagramMediaType === "story" &&
                currentPlatform === TASK_TYPE.INSTAGRAM && (
                  <Fragment>
                    <div className="split-form mt-3">
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"views"}
                          required
                          labelText="Views"
                          formControl={instagramStoryForm.views}
                          placeholder={`Number of Views`}
                        />
                      </div>
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"reactions"}
                          optional
                          labelText="Reactions"
                          formControl={instagramStoryForm.reactions}
                          placeholder={`Number of Reactions`}
                        />
                      </div>
                    </div>

                    <div className="split-form mt-3">
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"comments"}
                          required
                          labelText="Comments"
                          formControl={instagramStoryForm.comments}
                          placeholder={`Number of Comments`}
                        />
                      </div>
                      <div className="split-form-control">
                        <EmpTextInput
                          id={"shares"}
                          optional
                          labelText="Shares"
                          formControl={instagramStoryForm.shares}
                          placeholder={`Number of Shares`}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              <EmpTextInput
                id={"remarks"}
                multiline
                required
                rows={3}
                textAreaAdaptiveHeight
                labelText="Remarks"
                formControl={defaultForm.remarks}
                placeholder={`Enter your remarks here`}
              />
            </section>
          )}
        </section>
      )}

      <div
        className="mt-3"
        style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
      >
        <EmpButton
          isFullWidth={false}
          buttonStyle="secondary"
          text={<FormattedMessage id="cta_back" />}
          onSubmit={() => {
            back();
          }}
        />

        <EmpButton
          ref={submitEvidenceBtnRef}
          isFullWidth={false}
          text={intl.formatMessage({ id: "button_submitEvidence" })}
          onSubmit={onSubmitEvidence}
        />
      </div>
    </div>
  );
};

export default SubmitEvidenceSmImageView;
