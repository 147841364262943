import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import { TaskDto } from "../../../model/campaign/task.dto";
import {
  ATTACHMENT_TYPE,
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE,
  EVIDENCE_TYPE_DISPLAY,
  GENERAL_TASK_MEDIA_TYPE,
  TASK_TYPE,
} from "../../../constants/app.constants";
import HeartIcon from "../../icon/heart-icon";
import MessageIcon from "../../icon/message-icon";
import ShareIcon from "../../icon/share-icon";
import { Color } from "../../../utilities/colors";
import { EvidenceRecordDto } from "../../../model/campaign/evidence-record.dto";
import {
  FacebookPost,
  InstagramMedia,
  TiktokVideo,
  XTweet,
} from "../../../model/campaign/social-media.dto";
import PlayIcon from "../../icon/play-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import { EvidenceDto } from "../../../model/campaign/evidence.dto";
import EyeIcon from "../../icon/eye-icon";
import CursorClickIcon from "../../icon/cursor-click-icon";
import { EmpImage } from "../../shared/emp-image/emp-image";
import EmpContent from "../../shared/emp-content/emp-content";
import { DateUtil } from "../../../utilities/date";
import FileCheckIcon from "../../icon/file-check-icon";
import DeleteModal, { DeleteModalRef } from "../delete-modal";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import ToastUtils from "../../../utilities/toast-utils";
import FileIcon from "../../icon/file-icon";
import FileUtils from "../../../utilities/file-util";
import RepeatIcon from "../../icon/repeat-icon";
import Barchart1Icon from "../../icon/barchart-1-icon";
import ChevronRightIcon from "../../icon/chevron-right";
import { XPowFallback } from "../../proof-of-work-media/x-pow-fallback";
import { InstagramPow } from "../../proof-of-work-media/instagram-pow";
import { InstagramPowFallback } from "../../proof-of-work-media/instagram-pow-fallback";
import { FacebookPow } from "../../proof-of-work-media/facebook-pow";
import { FacebookPowFallback } from "../../proof-of-work-media/facebook-pow-fallback";
import { TikTokFallbackPow } from "../../proof-of-work-media/tiktok-fallback-pow";
import { TikTokPow } from "../../proof-of-work-media/tiktok-pow";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { CampaignAnalyticsUtils } from "../../../utilities/campaign-analytics.util";
import EmpPill from "../../shared/EmpPill/EmpPill";
import { Tooltip, Whisper } from "rsuite";
import FormFieldUtils from "../../../utilities/form-field.util";

interface Props {
  task: TaskDto;
  evidence: EvidenceDto;
  role: "seller" | "brand";
  onCreateEvidence?: () => void;
  onEvidenceUpdate?: () => void;
  onEvidenceSelected: (evidenceRecord: EvidenceRecordDto) => void;
}

interface SocialMediaProfile {
  picture: string;
  name: string;
}

const SubmitEvidenceListingView = (prop: Props) => {
  const intl = useIntl();
  const {
    task,
    evidence,
    onCreateEvidence,
    onEvidenceSelected,
    onEvidenceUpdate,
    role,
  } = prop;

  // This view will not even be visible if there are no proof of work records
  const [evidenceRecords] = useState<EvidenceRecordDto[]>(
    evidence!.evidenceRecords
  );
  const deleteModalRef = useRef<DeleteModalRef>();

  const deleteEvidenceResource = async (id: string) => {
    try {
      const resp = await EvidenceApi.deleteEvidenceRecord(id);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Proof of work deleted",
          "Your proof of work record has been deleted"
        );
        if (onEvidenceUpdate) onEvidenceUpdate();
      }
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Deleting Proof of work Resource"
      );
    }
  };

  const renderListingCardContent = (evidenceRecord: EvidenceRecordDto) => {
    if (
      evidenceRecord.platform === TASK_TYPE.TIKTOK &&
      evidenceRecord.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const tiktokObj = evidenceRecord.socialMediaObject as TiktokVideo;
      return (
        <button
          key={evidenceRecord.id}
          className="emp-button-reset listing-card"
          onClick={() => {
            onEvidenceSelected(evidenceRecord);
          }}
        >
          <>
            {evidenceRecord.hasSocialMediaObject ? (
              <TikTokPow
                type={"pow-modal-listing"}
                socialMediaObject={tiktokObj}
                evidenceRecord={evidenceRecord}
              />
            ) : (
              <TikTokFallbackPow
                type={"pow-modal-listing"}
                mediaSnapshots={evidenceRecord.mediaSnapshots!}
                evidenceRecord={evidenceRecord}
              />
            )}
          </>
        </button>
      );
    } else if (
      evidenceRecord.platform === TASK_TYPE.FACEBOOK &&
      evidenceRecord.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const facebookMedia = evidenceRecord.socialMediaObject as FacebookPost;
      return (
        <button
          key={evidenceRecord.id}
          className="emp-button-reset listing-card"
          onClick={(e: React.MouseEvent) => {
            onEvidenceSelected(evidenceRecord);
          }}
        >
          {evidenceRecord.hasSocialMediaObject ? (
            <FacebookPow
              type={"pow-modal-listing"}
              socialMediaObject={facebookMedia}
              evidenceRecord={evidenceRecord}
            />
          ) : (
            <FacebookPowFallback
              type={"pow-modal-listing"}
              mediaSnapshots={evidenceRecord.mediaSnapshots!}
              evidenceRecord={evidenceRecord}
            />
          )}
        </button>
      );
    } else if (
      evidenceRecord.platform === TASK_TYPE.X &&
      evidenceRecord.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      return (
        <button
          key={evidenceRecord.id}
          className="emp-button-reset listing-card"
          onClick={(e: React.MouseEvent) => {
            onEvidenceSelected(evidenceRecord);
          }}
        >
          {evidenceRecord.mediaSnapshots && (
            <XPowFallback
              type={"pow-modal-listing"}
              mediaSnapshots={evidenceRecord.mediaSnapshots}
              evidenceRecord={evidenceRecord}
            />
          )}
        </button>
      );
    } else if (
      evidenceRecord.platform === TASK_TYPE.INSTAGRAM &&
      evidenceRecord.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK
    ) {
      const instagramObj = evidenceRecord.socialMediaObject as InstagramMedia;
      return (
        <button
          key={evidenceRecord.id}
          className="emp-button-reset listing-card"
          onClick={(e: React.MouseEvent) => {
            onEvidenceSelected(evidenceRecord);
          }}
        >
          {evidenceRecord.hasSocialMediaObject ? (
            <InstagramPow
              type={"pow-modal-listing"}
              socialMediaObject={instagramObj}
              evidenceRecord={evidenceRecord}
            />
          ) : (
            <InstagramPowFallback
              type={"pow-modal-listing"}
              mediaSnapshots={evidenceRecord.mediaSnapshots!}
              evidenceRecord={evidenceRecord}
            />
          )}
        </button>
      );
    } else if (
      evidenceRecord.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE
    ) {
      return (
        <button
          key={evidenceRecord.id}
          className="emp-button-reset listing-card"
          onClick={(e: React.MouseEvent) => {
            onEvidenceSelected(evidenceRecord);
          }}
        >
          <div className="image-relative-wrapper">
            {evidenceRecord.files.length > 1 && (
              <div className={`additional-deliverable-wrapper-badge`}>
                <span className="badge-text">
                  +{evidenceRecord.files.length - 1}
                </span>
                <FileCheckIcon
                  strokeWidth={2.5}
                  size={12}
                  backgroundColor={Color.NEUTRAL[300]}
                />
              </div>
            )}
            <EmpImage
              className="preview-image attachment"
              src={evidenceRecord.files[0]?.url}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
          </div>
          <div className="info-section">
            <div className="top-section-wrapper">
              <div className="top-section-info-wrapper">
                <span className="card-title">
                  {evidenceRecord.platform} Image
                </span>
                <div className="metrics-bar mt-2">
                  {evidenceRecord.platform === TASK_TYPE.TIKTOK && (
                    <>
                      <Whisper speaker={<Tooltip>Number of Views</Tooltip>}>
                        <div className="metric-wrapper">
                          <PlayIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.viewCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
                        <div className="metric-wrapper">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {evidenceRecord.metricSnapshot?.likeCount ?? "-"}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
                        <div className="metric-wrapper">
                          <MessageIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.commentCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                        <div className="metric-wrapper">
                          <ShareIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.shareCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                    </>
                  )}

                  {evidenceRecord.platform === TASK_TYPE.FACEBOOK && (
                    <>
                      <Whisper
                        speaker={
                          <Tooltip>
                            Number of Impressions
                            <br />
                            <br />
                            Impressions on Facebook refer to the number of times
                            a post is shown on users' screens, regardless of
                            whether they interact with it.
                          </Tooltip>
                        }
                      >
                        <div className="metric-wrapper">
                          <EyeIcon
                            size={16}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.impression ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Reactions</Tooltip>}>
                        <div className="metric-wrapper">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.reactionCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>

                      <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
                        <div className="metric-wrapper">
                          <MessageIcon
                            size={16}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.commentCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                        <div className="metric-wrapper">
                          <ShareIcon
                            size={16}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.shareCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                    </>
                  )}

                  {evidenceRecord.platform === TASK_TYPE.INSTAGRAM &&
                    evidenceRecord.mediaType === "instagram-post" && (
                      <>
                        <Whisper
                          speaker={
                            <Tooltip>
                              Number of Impressions
                              <br />
                              <br />
                              Impressions refer to the total number of times the
                              content is shown to users, regardless of whether
                              they engage with it or not. It counts every view,
                              even if it's by the same person multiple times.
                            </Tooltip>
                          }
                        >
                          <div className="metric-wrapper">
                            <EyeIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.viewCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
                          <div className="metric-wrapper">
                            <HeartIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.likeCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper
                          speaker={<Tooltip>Number of Comments</Tooltip>}
                        >
                          <div className="metric-wrapper">
                            <MessageIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.commentCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                          <div className="metric-wrapper">
                            <ShareIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.shareCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                      </>
                    )}

                  {evidenceRecord.platform === TASK_TYPE.INSTAGRAM &&
                    evidenceRecord.mediaType === "instagram-story" && (
                      <>
                        <Whisper speaker={<Tooltip>Number of Plays</Tooltip>}>
                          <div className="metric-wrapper">
                            <PlayIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.viewCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper
                          speaker={<Tooltip>Number of Reactions</Tooltip>}
                        >
                          <div className="metric-wrapper">
                            <HeartIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.reactionCount ??
                                  0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper
                          speaker={<Tooltip>Number of Comments</Tooltip>}
                        >
                          <div className="metric-wrapper">
                            <MessageIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.commentCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                        <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                          <div className="metric-wrapper">
                            <ShareIcon
                              size={14}
                              backgroundColor={Color.NEUTRAL[600]}
                            />
                            <span>
                              {FormFieldUtils.toCommify(
                                evidenceRecord.metricSnapshot!.shareCount ?? 0
                              )}
                            </span>
                          </div>
                        </Whisper>
                      </>
                    )}
                  {evidenceRecord.platform === TASK_TYPE.X && (
                    <>
                      <Whisper speaker={<Tooltip>Number of Views</Tooltip>}>
                        <div className="metric-wrapper">
                          <PlayIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.viewCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
                        <div className="metric-wrapper">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {evidenceRecord.metricSnapshot?.likeCount ?? "-"}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
                        <div className="metric-wrapper">
                          <MessageIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.commentCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                      <Whisper speaker={<Tooltip>Number of Retweets</Tooltip>}>
                        <div className="metric-wrapper">
                          <ShareIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>
                            {FormFieldUtils.toCommify(
                              evidenceRecord.metricSnapshot!.shareCount ?? 0
                            )}
                          </span>
                        </div>
                      </Whisper>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="listing-pills-wrapper">
              <EmpPill
                {...PILL_COLORS.primary}
                text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_IMAGE}
              />
            </div>
            <div className="content-wrapper mt-2">
              <EmpContent
                labelDisableOverflow
                label={"SUBMITTED DATE"}
                value={DateUtil.toReadableDateWithTime(
                  evidenceRecord.createdDate
                )}
              />
              <EmpContent
                labelDisableOverflow
                label={"REMARKS"}
                value={
                  <span className="remarks-span">
                    {evidenceRecord.remarks?.length > 0
                      ? evidenceRecord.remarks
                      : "No Remarks"}
                  </span>
                }
              />
            </div>
          </div>
        </button>
      );
    } else if (evidenceRecord.evidenceType === EVIDENCE_TYPE.ATTACHMENT) {
      if (evidenceRecord.files.length === 0) {
        return (
          <button
            key={evidenceRecord.id}
            className="emp-button-reset listing-card"
            onClick={(e: React.MouseEvent) => {
              onEvidenceSelected(evidenceRecord);
            }}
          >
            <div className="image-relative-wrapper">
              {evidenceRecord.files.length > 1 && (
                <div className={`additional-deliverable-wrapper-badge`}>
                  <span className="badge-text">
                    +{evidenceRecord.files.length - 1}
                  </span>
                  <FileCheckIcon
                    strokeWidth={2.5}
                    size={12}
                    backgroundColor={Color.NEUTRAL[300]}
                  />
                </div>
              )}
              <div className="preview-file-attachment">
                <FileIcon />
              </div>
            </div>
            <div className="info-section">
              <div className="top-section-wrapper">
                <div className="top-section-info-wrapper">
                  <span className="card-title">Text Submission</span>
                  <div className="metrics-bar mt-2">
                    <p className="emp-paragraph remarks-paragraph">
                      {evidenceRecord.remarks}
                    </p>
                  </div>
                </div>
              </div>
              <div className="listing-pills-wrapper">
                <EmpPill
                  {...PILL_COLORS.primary}
                  text={EVIDENCE_TYPE_DISPLAY.ATTACHMENT}
                />
              </div>
              <div className="content-wrapper mt-2">
                <EmpContent
                  labelDisableOverflow
                  label={"SUBMITTED DATE"}
                  value={DateUtil.toReadableDateWithTime(
                    evidenceRecord.createdDate
                  )}
                />
              </div>
            </div>
          </button>
        );
      } else {
        const previewFile = evidenceRecord.files[0];
        return (
          <button
            key={evidenceRecord.id}
            className="emp-button-reset listing-card"
            onClick={(e: React.MouseEvent) => {
              onEvidenceSelected(evidenceRecord);
            }}
          >
            <div className="image-relative-wrapper">
              {evidenceRecord.files.length > 1 && (
                <div className={`additional-deliverable-wrapper-badge`}>
                  <span className="badge-text">
                    +{evidenceRecord.files.length - 1}
                  </span>
                  <FileCheckIcon
                    strokeWidth={2.5}
                    size={12}
                    backgroundColor={Color.NEUTRAL[300]}
                  />
                </div>
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.FILE && (
                <div className="preview-file-attachment">
                  <FileIcon />
                  <span className="preview-file-name">{previewFile.name}</span>
                </div>
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.IMAGE && (
                <EmpImage
                  className="preview-image attachment"
                  src={previewFile.url}
                  fallback={EMPTY_PROFILE_IMG_URL}
                />
              )}
              {previewFile.fileType === ATTACHMENT_TYPE.VIDEO && (
                <video className="preview-image" src={previewFile.url}></video>
              )}
            </div>
            <div className="info-section">
              <div className="top-section-wrapper">
                <div className="top-section-info-wrapper">
                  <span className="card-title">Attachment</span>
                  <div className="metrics-bar mt-2">
                    <span className="file-metadata">
                      {previewFile.name} (
                      {FileUtils.convertBytesToReadableSize(previewFile.size)})
                    </span>
                    <div className="download-btn-wrapper">
                      <EmpLink text={"Download"} href={previewFile.url} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="listing-pills-wrapper">
                <EmpPill
                  {...PILL_COLORS.primary}
                  text={EVIDENCE_TYPE_DISPLAY.ATTACHMENT}
                />
              </div>

              <div className="content-wrapper mt-2">
                <EmpContent
                  labelDisableOverflow
                  label={"SUBMITTED DATE"}
                  value={DateUtil.toReadableDateWithTime(
                    evidenceRecord.createdDate
                  )}
                />
                <EmpContent
                  labelDisableOverflow
                  label={"REMARKS"}
                  value={
                    <span className="remarks-span">
                      {evidenceRecord.remarks?.length > 0
                        ? evidenceRecord.remarks
                        : "No Remarks"}
                    </span>
                  }
                />
              </div>
            </div>
            <div className="chevron-button-section">
              <ChevronRightIcon backgroundColor={Color.NEUTRAL[600]} />
            </div>
          </button>
        );
      }
    }
    return <></>;
  };

  return (
    <section className="listing-view">
      <DeleteModal ref={deleteModalRef} onSave={() => {}} suppressToast />
      <div className="header-wrapper mb-4">
        <h2 className="header">Submitted Proof of Work</h2>
        {role === "seller" && (
          <EmpButton
            text={"Create Submission"}
            buttonHeight="sm"
            isFullWidth={false}
            onSubmit={onCreateEvidence}
          />
        )}
      </div>
      <section className="card-section">
        {evidenceRecords.map((elem) => {
          return renderListingCardContent(elem);
        })}
      </section>
    </section>
  );
};
export default SubmitEvidenceListingView;
