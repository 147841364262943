import { useEffect, useRef, useState } from "react";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
}

export const DeleteTaskNotification = (props: Props) => {
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {notification.sender.senderType === "organisation" && (
        <img
          alt={notification.sender.senderName}
          className="organisation-img"
          src={notification.sender.imageResource}
        />
      )}

      <div className="notification-content">
        <div className="content-wrapper">
          <span className="info-text">
            {notification.sender.senderName} has deleted task:{" "}
            {notification.metadata.taskName}. Consequently, your involvement has
            been terminated.
          </span>

          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
          <div className="button-wrapper"></div>
        </div>
      </div>
    </div>
  );
};
