import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const XIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3263 1.90393H21.6998L14.3297 10.3274L23 21.7899H16.2112L10.894 14.838L4.80995 21.7899H1.43443L9.31743 12.78L1 1.90393H7.96111L12.7674 8.25826L18.3263 1.90393ZM17.1423 19.7707H19.0116L6.94539 3.81706H4.93946L17.1423 19.7707Z"
          fill={backgroundColor}
        />
      </svg>
    </div>
  );
};
export default XIcon;
