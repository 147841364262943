import "./x-tweet-poll-widget.scss";
import { useMemo } from "react";
import { XPollDto } from "../../model/x/x-poll.dto";

interface Props {
  poll: XPollDto;
}

const XTweetPollWidget = (props: Props) => {
  const { poll } = props;

  const totalPollCount = useMemo(() => {
    if (!poll) return 1;

    const totalcount = poll.choices.reduce((prev, current) => {
      return prev + current.count;
    }, 0);
    return Math.max(totalcount, 1);
  }, [poll]);

  const highestPollOption = useMemo(() => {
    if (!poll) return "";
    const option = poll.choices.reduce(
      (prev, current) => {
        return current.count > prev.count ? current : prev;
      },
      { label: "", count: -1 }
    );
    if (option.count === 0) return;
    return option.label;
  }, [poll]);

  return (
    <div className="emp-x-tweet-poll-widget">
      {poll.choices.map((poll, index) => {
        return (
          <article key={index} className="poll-bar-article">
            <div className="poll-bar-wrapper">
              <div className={`poll-label`}>
                <span
                  className={`label ${
                    poll.label === highestPollOption ? "highest-result" : ""
                  }`}
                >
                  {poll.label}
                </span>
              </div>
              <div
                className={`poll-bar ${
                  poll.label === highestPollOption ? "highest-result" : ""
                }`}
                style={{
                  width: `${(poll.count / totalPollCount) * 100}%`,
                }}
              ></div>
            </div>
            <span
              className={`poll-percentage ${
                poll.label === highestPollOption ? "highest-result" : ""
              }`}
            >{`${(poll.count / totalPollCount) * 100}%`}</span>
          </article>
        );
      })}
    </div>
  );
};
export default XTweetPollWidget;
