import { useEffect, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";

import EmpButton from "../../../../components/shared/emp-button/emp-button";
import PlusIcon from "../../../../components/icon/plus-icon";
import FacebookIcon from "../../../../components/icon/facebook-icon";
import InstagramIcon from "../../../../components/icon/instagram-icon";
import TikTokIcon from "../../../../components/icon/tiktok-icon";
import { Color } from "../../../../utilities/colors";
import BrandSocialMediaManagerModal, {
  BrandSocialMediaManagerModalRef,
} from "../../modal/brand-social-media-manager-modal";
import { SmConnectionBriefRespDto } from "../../../../model/smm/smm-connection-brief-resp.dto";
import { EMPTY_PROFILE_IMG_URL } from "../../../../constants/app.constants";
import { EmpLoaderRef } from "../../../../components/shared/emp-loader/emp-loader";
import XIcon from "../../../../components/icon/x-icon";
import InfoCircleIcon from "../../../../components/icon/info-circle-icon";
import BrandSocialMediaManagerCalendarTab from "../brand-social-media-manager-calendar-tab";

type BrandSocialMediaManagerOverviewTabProps = {
  connects: SmConnectionBriefRespDto[];
  fetchSocialMediaConnections: () => Promise<void>;
  loading: boolean;
};

const BrandSocialMediaManagerOverviewTab: React.FC<
  BrandSocialMediaManagerOverviewTabProps
> = ({ connects, fetchSocialMediaConnections, loading }) => {
  const BrandSocialMediaManagerModalRef =
    useRef<BrandSocialMediaManagerModalRef>();

  const renderConnects = useMemo(() => {
    if (connects.length === 0)
      return (
        <EmpButton
          onSubmit={() => BrandSocialMediaManagerModalRef.current?.show()}
          isFullWidth={false}
          text={"Connect"}
          buttonHeight="md"
          leftIcon={PlusIcon}
        />
      );

    return (
      <>
        {connects.map((connect, index: number) => (
          <div
            className={`profile-wrapper ${
              connect.is_expired ? "is-expired" : ""
            }`}
            key={index}
          >
            <div className={`picture-section`}>
              <img
                src={connect?.pictureUrl || EMPTY_PROFILE_IMG_URL}
                alt={`${connect?.name} ${connect?.platform} `}
              />
              <div
                className={`social-media-bubble ${connect?.platform.toLowerCase()}`}
              >
                {connect?.platform.toLowerCase() === "facebook" && (
                  <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "instagram" && (
                  <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "tiktok" && (
                  <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
                {connect?.platform.toLowerCase() === "x" && (
                  <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                )}
              </div>
            </div>
            <div className="info-section">
              <span className="handle-name-lbl">{connect?.name}</span>
              <span className="metric-lbl">{connect?.followers} Followers</span>
            </div>
          </div>
        ))}
        <EmpButton
          onSubmit={() => BrandSocialMediaManagerModalRef.current?.show()}
          isFullWidth={false}
          text={"Manage Connections"}
          buttonHeight="md"
          leftIcon={PlusIcon}
          buttonStyle="secondary"
        />
      </>
    );
  }, [connects]);

  const renderExpiredWarning = useMemo(() => {
    if (connects.length === 0) return <></>;
    const expiredConnects: SmConnectionBriefRespDto[] = connects.filter(
      (connect) => connect.is_expired
    );
    if (expiredConnects.length === 0) return <></>;

    return (
      <div className="expired-warning">
        <InfoCircleIcon backgroundColor={Color.RED[500]} size={16} />
        <p className="emp-paragraph">
          <FormattedMessage id="brandSocialMediaOverviewTab_expiredWarning" />
        </p>
      </div>
    );
  }, [connects]);

  useEffect(() => {
    if (loading === true) empLoaderRef.current?.show();
    if (loading === false) empLoaderRef.current?.hide();
  }, [loading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  return (
    <>
      <BrandSocialMediaManagerModal
        fetchSocialMediaConnections={fetchSocialMediaConnections}
        connects={connects}
        ref={BrandSocialMediaManagerModalRef}
      />

      {/* <div className="mt-6 mb-4"> */}
      <div className="mt-2 mb-4">
        <div className="connect-section emp-card">
          <h2 className="header">
            <FormattedMessage id="brandSocialMediaOverviewTab_connectHeader" />
          </h2>
          <p className="emp-paragraph mb-2">
            <FormattedMessage id="brandSocialMediaOverviewTab_connectDesc" />
          </p>
          {!loading && renderExpiredWarning}
          {!loading && <div className="connects-wrapper">{renderConnects}</div>}
          {loading && <div className="connects-wrapper loading emp-shimmer " />}
        </div>
      </div>
      <BrandSocialMediaManagerCalendarTab
        connects={connects}
        loading={loading}
        fetchSocialMediaConnections={fetchSocialMediaConnections}
      />
    </>
  );
};

export default BrandSocialMediaManagerOverviewTab;
