import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./update-negotiation-changelog-modal.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import { LatestNegotiationDto } from "../../model/campaign/latest-negotiation.dto";
import { NEGOTIATION_STATUS } from "../../constants/app.constants";
import EmpPill from "../shared/EmpPill/EmpPill";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import EmpException from "../../exception/empException";
import ChevronRightIcon from "../icon/chevron-right";
import { UpdateTaskDto } from "../../model/campaign/update-task.dto";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import { UpdateTaskWNegotiationDto } from "../../model/campaign/update-task-w-negotiation.dto";
import ToastUtils from "../../utilities/toast-utils";
import { EmpCoinIcon } from "../shared/emp-coin-icon/emp-coin-icon";
import { TaskDto } from "../../model/campaign/task.dto";

export interface UpdateNegotiationChangelogModalRef {
  show: (
    latestNegotiationList: LatestNegotiationExtendedDto[],
    updateTaskDto: UpdateTaskDto,
    campaignId: string,
    taskId: string
  ) => void;
  dismiss: () => void;
}

interface Props {
  onComplete: () => void;
  task: TaskDto;
}

const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

type UpdateTaskDetail = {
  campaignId: string;
  taskId: string;
  updateTaskDto: UpdateTaskDto;
};

interface NegotiationWithAmtForm extends LatestNegotiationExtendedDto {
  amountForm: FormControl;
}

const UpdateNegotiationChangelogModal = forwardRef((props: Props, ref) => {
  const { onComplete, task } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [latestNegotiations, setLatestNegotiations] =
    useState<NegotiationWithAmtForm[]>();
  const updateTaskDetailRef = useRef<UpdateTaskDetail>();

  const submitBtnRef = useRef<EmpButtonRef>();
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    latestNegotiationList: LatestNegotiationExtendedDto[],
    updateTaskDto: UpdateTaskDto,
    campaignId: string,
    taskId: string
  ) => {
    try {
      updateTaskDetailRef.current = {
        updateTaskDto,
        campaignId,
        taskId,
      };
      const transformedNegotiationList: NegotiationWithAmtForm[] = [];
      for (const negotiation of latestNegotiationList) {
        transformedNegotiationList.push({
          ...negotiation,
          amountForm: new FormControl(
            "text",
            [
              new RequiredValidator("Please specify a rate"),
              new PatternValidator(
                numberRegex,
                "Please provide a numeric value"
              ),
              new NumericRangeValidator(
                0,
                40000,
                "Rate must be more than zero",
                "Rate must not exceed 40,000"
              ),
            ],
            negotiation.amount
          ),
        });
      }
      console.log("transformed negotiation list", transformedNegotiationList);
      setLatestNegotiations(transformedNegotiationList);
      setVisible(true);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error occurred in update negotiation changelog modal"
      );
    }
  };

  const dismiss = async () => {
    setVisible(false);
    setLatestNegotiations(undefined);
    await empDelay(300);
  };

  const continueWithDeliverableSubmission = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      if (!latestNegotiations || !updateTaskDetailRef.current)
        throw new EmpException("No negotiations found");

      // validate all form fields.
      let formValid = true;
      latestNegotiations.forEach((elem) => {
        const isValid = elem.amountForm.validate();
        formValid = formValid && isValid;
      });
      setLatestNegotiations([...latestNegotiations]);

      // Submitting the form.
      if (!formValid) return;

      const request: UpdateTaskWNegotiationDto = {
        ...updateTaskDetailRef.current.updateTaskDto,
        updatedNegotiation: latestNegotiations.map((negotiation) => {
          return {
            status: negotiation.status,
            negotiationId: negotiation.id,
            newAmount: Number(negotiation.amountForm.getValue()),
          };
        }),
      };

      // Update the modal
      const resp = await CampaignApi.updateTaskWithDeliverableUpdate(
        updateTaskDetailRef.current.campaignId,
        updateTaskDetailRef.current.taskId,
        request
      );
      const apiStatus = resp.data;
      if (
        apiStatus.status === "success" &&
        apiStatus.statusMessage === "updated"
      ) {
        ToastUtils.success(
          "Update Successful",
          "Task has been sucessfully updated"
        );
        setVisible(false);
        onComplete();
      }
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(error, "Error editing task");
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"lg"}
    >
      <div>
        <div className="emp-update-negotiation-changelog-modal">
          <div className="modal-header-wrapper">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            <span className="intro-subtitle">UPDATE TASK DELIVERABLE</span>
            <h1 className="title">Revise Creator Negotiation</h1>
            <p className="emp-paragraph">
              Since you have updated the deliverables, you have the option to
              revise the payment amount for creators or maintain the current
              amount.
            </p>
          </div>

          {latestNegotiations && (
            <section className="representatives-wrapper mt-1">
              <div className="header-wrapper">
                <div className="representative-column">
                  <span className="header-text">Representative</span>
                </div>
                <div className="status-column">
                  <span className="header-text">Status</span>
                </div>
                <div className="amount-column">
                  <span className="header-text">Adjusted Amount</span>
                </div>
              </div>
              <div className="body-wrapper">
                {latestNegotiations.map((elem) => {
                  return (
                    <div className="record-row" key={elem.id}>
                      <div className="representative-column">
                        <div className="profile-wrapper">
                          {elem.creatorRepresentative.imageType === "url" && (
                            <img
                              className="profile"
                              alt={elem.creatorRepresentative.fullName}
                              src={elem.creatorRepresentative.imageResource}
                            />
                          )}
                          {elem.creatorRepresentative.imageType ===
                            "avatar" && (
                            <div
                              className="profile"
                              style={{
                                background:
                                  elem.creatorRepresentative.imageResource,
                              }}
                            >
                              <span className="initials">
                                {elem.creatorRepresentative.initials}
                              </span>
                            </div>
                          )}
                          {elem.representativeRole === "agency" && (
                            <img
                              className="profile profile-badge"
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          )}
                        </div>
                        <div className="info-wrapper ml-3">
                          <span className="creator-name">
                            {elem.creatorRepresentative.fullName}
                          </span>
                          <span className="representative-lbl mt-1">
                            {elem.representativeRole === "agency"
                              ? `Managed by ${elem.agencyRepresentative.companyName}`
                              : "Freelance Creator"}
                          </span>
                        </div>
                      </div>
                      <div className="status-column">
                        {[
                          NEGOTIATION_STATUS.DUP_REJECT,
                          NEGOTIATION_STATUS.BRAND_REJECT,
                          NEGOTIATION_STATUS.SELLER_REJECT,
                        ].includes(elem.status) && (
                          <EmpPill
                            text={"Rejected"}
                            backgroundColor={Color.RED[100]}
                            color={Color.RED[600]}
                          />
                        )}
                        {elem.status ===
                          NEGOTIATION_STATUS.REVIEW_DELIVERABLES && (
                          <EmpPill
                            text={"Review Deliverable"}
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                          />
                        )}
                        {elem.status === NEGOTIATION_STATUS.INVITED && (
                          <EmpPill
                            text={"Invited"}
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                          />
                        )}
                        {[
                          NEGOTIATION_STATUS.SELLER_ACCEPT,
                          NEGOTIATION_STATUS.BRAND_ACCEPT,
                        ].includes(elem.status) && (
                          <>
                            <EmpPill
                              text={"Accepted"}
                              backgroundColor={Color.GREEN[100]}
                              color={Color.GREEN[600]}
                            />
                            <ChevronRightIcon
                              backgroundColor={Color.NEUTRAL[200]}
                            />
                            <EmpPill
                              text={"Review Deliverable"}
                              backgroundColor={Color.PRIMARY[100]}
                              color={Color.PRIMARY[600]}
                            />
                          </>
                        )}
                        {(elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE ||
                          elem.status ===
                            NEGOTIATION_STATUS.BRAND_NEGOTIATE) && (
                          <EmpPill
                            text={"Counteroffer"}
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                          />
                        )}
                      </div>
                      <div className="amount-column">
                        <EmpTextInput
                          leftComponent={
                            <EmpCoinIcon iconValue={task.paymentMode} />
                          }
                          leftIconSize={11}
                          placeholder={"00.00"}
                          id={elem.id}
                          formControl={elem.amountForm}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          )}

          <section className="button-wrapper mt-4">
            <EmpButton
              isFullWidth={false}
              ref={submitBtnRef}
              onSubmit={continueWithDeliverableSubmission}
              text={"Confirm Changes"}
            />
          </section>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default UpdateNegotiationChangelogModal;
