import { FormattedMessage, useIntl } from "react-intl";
import EmpButton from "../../shared/emp-button/emp-button";
import _debounce from "lodash/debounce";
import { TaskDto } from "../../../model/campaign/task.dto";
import { EvidenceRecordDto } from "../../../model/campaign/evidence-record.dto";
import {
  ATTACHMENT_TYPE,
  EVIDENCE_TYPE,
  GENERAL_TASK_MEDIA_TYPE,
  PROOF_OF_WORK_MEDIA_TYPE,
  SOCIAL_MEDIA_TYPE,
  TASK_TYPE,
} from "../../../constants/app.constants";
import {
  FacebookPost,
  InstagramMedia,
  TiktokVideo,
} from "../../../model/campaign/social-media.dto";
import { Color } from "../../../utilities/colors";
import HeartIcon from "../../icon/heart-icon";
import MessageIcon from "../../icon/message-icon";
import EmpContent from "../../shared/emp-content/emp-content";
import { DateUtil } from "../../../utilities/date";
import TrashIcon from "../../icon/trash-icon";
import ChevronLeftIcon from "../../icon/chevron-left";
import EmpLink from "../../shared/emp-link/emp-link";
import PlayIcon from "../../icon/play-icon";
import ShareIcon from "../../icon/share-icon";
import Slider from "react-slick";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import { useRef, useState } from "react";
import ChevronRightIcon from "../../icon/chevron-right";
import DeleteModal, { DeleteModalRef } from "../delete-modal";
import ToastUtils from "../../../utilities/toast-utils";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FileIcon from "../../icon/file-icon";
import EmpPill from "../../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import FileUtils from "../../../utilities/file-util";
import RepeatIcon from "../../icon/repeat-icon";
import Barchart1Icon from "../../icon/barchart-1-icon";
import { InstagramPow } from "../../proof-of-work-media/instagram-pow";
import { InstagramPowFallback } from "../../proof-of-work-media/instagram-pow-fallback";
import { XPowFallback } from "../../proof-of-work-media/x-pow-fallback";
import { FacebookPow } from "../../proof-of-work-media/facebook-pow";
import { FacebookPowFallback } from "../../proof-of-work-media/facebook-pow-fallback";
import { TikTokPow } from "../../proof-of-work-media/tiktok-pow";
import { TikTokFallbackPow } from "../../proof-of-work-media/tiktok-fallback-pow";
import { CampaignAnalyticsUtils } from "../../../utilities/campaign-analytics.util";
import BrokenLinkIcon from "../../icon/broken-link-icon";
import CheckIcon from "../../icon/check-icon";
import EyeIcon from "../../icon/eye-icon";
import StringUtils from "../../../utilities/string.util";
import FormFieldUtils from "../../../utilities/form-field.util";
import { Tooltip, Whisper } from "rsuite";

interface Props {
  task: TaskDto;
  evidenceRecordDto: EvidenceRecordDto;
  role: "brand" | "seller";
  onEvidenceUpdate?: () => void;
  back: () => void;
}

const SubmitEvidenceDetailsView = (prop: Props) => {
  const intl = useIntl();
  const { task, evidenceRecordDto, onEvidenceUpdate, back, role } = prop;
  const sliderRef = useRef<Slider | null>(null);
  const [sliderDotIndex, setSliderDotIndex] = useState(0);
  const deleteModalRef = useRef<DeleteModalRef>();

  const [subjectIsHovered, setSubjectIsHovered] = useState<boolean>(false);

  const deleteEvidenceResource = async (id: string) => {
    try {
      const resp = await EvidenceApi.deleteEvidenceRecord(id);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Proof of work Deleted",
          "This proof of work entry has been deleted"
        );
        if (onEvidenceUpdate) onEvidenceUpdate();
      }
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Deleting Proof of Work"
      );
    }
  };

  const handleSliderNavigation = (direction: "left" | "right") => {
    if (!sliderRef.current) {
      return;
    }
    if (direction === "left") {
      sliderRef.current.slickPrev();
    } else if (direction === "right") {
      sliderRef.current.slickNext();
    }
  };

  const renderSmLinkEvidence = (): JSX.Element => {
    const socialMediaObj = evidenceRecordDto.socialMediaObject!;
    const hasSocialMediaObject = evidenceRecordDto.hasSocialMediaObject;
    return (
      <>
        {evidenceRecordDto.platform === TASK_TYPE.INSTAGRAM && (
          <>
            {hasSocialMediaObject ? (
              <InstagramPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as InstagramMedia}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <InstagramPowFallback
                type={"pow-modal-details"}
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}

        {evidenceRecordDto.platform === TASK_TYPE.FACEBOOK && (
          <>
            {hasSocialMediaObject ? (
              <FacebookPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as FacebookPost}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <FacebookPowFallback
                type={"pow-modal-details"}
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}
        {evidenceRecordDto.platform === TASK_TYPE.TIKTOK && (
          <>
            {hasSocialMediaObject ? (
              <TikTokPow
                type={"pow-modal-details"}
                socialMediaObject={socialMediaObj! as TiktokVideo}
                evidenceRecord={evidenceRecordDto}
              />
            ) : (
              <TikTokFallbackPow
                type={"pow-modal-details"}
                profileSnapshot={evidenceRecordDto.profileSnapshot}
                mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
                evidenceRecord={evidenceRecordDto}
              />
            )}
          </>
        )}
        {evidenceRecordDto.platform === TASK_TYPE.X && (
          <XPowFallback
            type={"pow-modal-details"}
            profileSnapshot={evidenceRecordDto.profileSnapshot}
            mediaSnapshots={evidenceRecordDto.mediaSnapshots!}
            evidenceRecord={evidenceRecordDto}
          />
        )}
      </>
    );
  };

  const renderSmImageEvidence = (): JSX.Element => {
    return (
      <>
        <div className="sm-content slider-enabled">
          {evidenceRecordDto.files.length > 1 && (
            <button
              className="emp-button-reset sm-left-arrow-wrapper"
              onClick={() => {
                handleSliderNavigation("left");
              }}
            >
              <ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />
            </button>
          )}
          {evidenceRecordDto.files.length > 1 && (
            <button
              className="emp-button-reset sm-right-arrow-wrapper"
              onClick={() => {
                handleSliderNavigation("right");
              }}
            >
              <ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />
            </button>
          )}
          <Slider
            dots={false}
            arrows={false}
            infinite={true}
            adaptiveHeight={true}
            speed={500}
            beforeChange={(_, newIndex) => {
              setSliderDotIndex(newIndex);
            }}
            slidesToShow={1}
            ref={sliderRef}
            slidesToScroll={1}
          >
            {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map((elem) => {
              return (
                <div key={elem.reactKey}>
                  <div className="attachment-wrapper">
                    <img
                      className="sm-image"
                      alt="Uploaded evidence"
                      src={elem.url}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
          {evidenceRecordDto.files.length > 1 && (
            <div className="sm-pagination-dots-wrapper">
              {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map(
                (elem, index) => {
                  return (
                    <div
                      key={elem.reactKey}
                      className="sm-dot-clickable-area"
                      onClick={() => {
                        if (!sliderRef.current || sliderDotIndex === index)
                          return;
                        sliderRef.current.slickGoTo(index);
                      }}
                    >
                      <div
                        className={`sm-dot ${
                          sliderDotIndex === index ? "active" : ""
                        }`}
                      ></div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
        {evidenceRecordDto.platform === TASK_TYPE.FACEBOOK && (
          <div className="sm-metrics">
            <Whisper
              speaker={
                <Tooltip>
                  Number of Impressions
                  <br />
                  <br />
                  Impressions on Facebook refer to the number of times a post is
                  shown on users' screens, regardless of whether they interact
                  with it.
                </Tooltip>
              }
            >
              <div className="metric-wrapper">
                <EyeIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.impression ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Reactions</Tooltip>}>
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.reactionCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.commentCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
              <div className="metric-wrapper">
                <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.shareCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
          </div>
        )}
        {evidenceRecordDto.platform === TASK_TYPE.TIKTOK && (
          <div className="sm-metrics">
            <Whisper speaker={<Tooltip>Number of Views</Tooltip>}>
              <div className="metric-wrapper">
                <PlayIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
              <div className="metric-wrapper">
                <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
              <div className="metric-wrapper">
                <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.commentCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
              <div className="metric-wrapper">
                <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.shareCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
          </div>
        )}
        {task.platform === TASK_TYPE.X && (
          <div className="sm-metrics">
            <Whisper speaker={<Tooltip>Number of Views</Tooltip>}>
              <div className="metric-wrapper">
                <Barchart1Icon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.commentCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
            <Whisper speaker={<Tooltip>Number of Retweets</Tooltip>}>
              <div className="metric-wrapper">
                <RepeatIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {FormFieldUtils.toCommify(
                    evidenceRecordDto.metricSnapshot!.shareCount ?? 0
                  )}
                </span>
              </div>
            </Whisper>
          </div>
        )}
        {task.platform === TASK_TYPE.INSTAGRAM &&
          evidenceRecordDto.mediaType === "picture" && (
            <div className="sm-metrics">
              {evidenceRecordDto.metricSnapshot!.viewCount && (
                <Whisper
                  speaker={
                    <Tooltip>
                      Number of Impressions
                      <br />
                      <br />
                      Impressions refer to the total number of times the content
                      is shown to users, regardless of whether they engage with
                      it or not. It counts every view, even if it's by the same
                      person multiple times.
                    </Tooltip>
                  }
                >
                  <div className="metric-wrapper">
                    <EyeIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                    <span>
                      {FormFieldUtils.toCommify(
                        evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                      )}
                    </span>
                  </div>
                </Whisper>
              )}
              <Whisper speaker={<Tooltip>Number of Likes</Tooltip>}>
                <div className="metric-wrapper">
                  <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.likeCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
              <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
                <div className="metric-wrapper">
                  <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.commentCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
              <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                <div className="metric-wrapper">
                  <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.shareCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
            </div>
          )}
        {task.platform === TASK_TYPE.INSTAGRAM &&
          evidenceRecordDto.mediaType.startsWith("story") && (
            <div className="sm-metrics">
              <Whisper speaker={<Tooltip>Number of Plays</Tooltip>}>
                <div className="metric-wrapper">
                  <PlayIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.viewCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
              <Whisper speaker={<Tooltip>Number of Reactions</Tooltip>}>
                <div className="metric-wrapper">
                  <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>{evidenceRecordDto.metricSnapshot!.reactionCount}</span>
                </div>
              </Whisper>
              <Whisper speaker={<Tooltip>Number of Comments</Tooltip>}>
                <div className="metric-wrapper">
                  <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.commentCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
              <Whisper speaker={<Tooltip>Number of Shares</Tooltip>}>
                <div className="metric-wrapper">
                  <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>
                    {FormFieldUtils.toCommify(
                      evidenceRecordDto.metricSnapshot!.shareCount ?? 0
                    )}
                  </span>
                </div>
              </Whisper>
            </div>
          )}
      </>
    );
  };

  const renderFileAttachment = (): JSX.Element => {
    if (evidenceRecordDto.files.length === 0) return <></>;
    const firstFile = evidenceRecordDto.files[0];
    return (
      <div className="sm-content slider-enabled">
        {firstFile.fileType === ATTACHMENT_TYPE.IMAGE && (
          <>
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-left-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("left");
                }}
              >
                <ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            {evidenceRecordDto.files.length > 1 && (
              <button
                className="emp-button-reset sm-right-arrow-wrapper"
                onClick={() => {
                  handleSliderNavigation("right");
                }}
              >
                <ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />
              </button>
            )}
            <Slider
              dots={false}
              arrows={false}
              infinite={true}
              adaptiveHeight={true}
              speed={500}
              beforeChange={(oldIndex, newIndex) => {
                setSliderDotIndex(newIndex);
              }}
              slidesToShow={1}
              ref={sliderRef}
              slidesToScroll={1}
            >
              {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map((elem) => {
                return (
                  <button
                    key={elem.reactKey}
                    className="emp-button-reset attachment-image"
                    onMouseLeave={() => {
                      setSubjectIsHovered(false);
                    }}
                    onMouseEnter={() => {
                      setSubjectIsHovered(true);
                    }}
                  >
                    <div
                      className={`attachment-overlay ${
                        subjectIsHovered ? "hovered" : ""
                      }`}
                    >
                      <div className="overlay-content-wrapper">
                        <span className="overlay-filename">
                          {firstFile.name}
                        </span>
                        <EmpPill {...PILL_COLORS.primary} text={"Video"} />
                        <div className="download-wrapper">
                          <EmpLink
                            linkStyle="white"
                            text={"Download"}
                            href={firstFile.url}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="attachment-wrapper">
                      <img
                        key={elem.reactKey}
                        className="sm-image"
                        alt="Uploaded evidence"
                        src={elem.url}
                      />
                    </div>
                  </button>
                );
              })}
            </Slider>
            {evidenceRecordDto.files.length > 1 && (
              <div className="sm-pagination-dots-wrapper">
                {ReactKeyUtil.addReactKey(evidenceRecordDto.files).map(
                  (elem, index) => {
                    return (
                      <div
                        key={elem.reactKey}
                        className="sm-dot-clickable-area"
                        onClick={() => {
                          if (!sliderRef.current || sliderDotIndex === index)
                            return;
                          sliderRef.current.slickGoTo(index);
                        }}
                      >
                        <div
                          className={`sm-dot ${
                            sliderDotIndex === index ? "active" : ""
                          }`}
                        ></div>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </>
        )}
        {firstFile.fileType === ATTACHMENT_TYPE.FILE && (
          <div className="attachment-file">
            <FileIcon size={22} backgroundColor={Color.NEUTRAL[500]} />
            <span className="filename">
              {firstFile.name}{" "}
              <span className="size">
                ({FileUtils.convertBytesToReadableSize(firstFile.size)})
              </span>
            </span>
            <EmpLink text={"Download File"} href={firstFile.url} />
          </div>
        )}
        {firstFile.fileType === ATTACHMENT_TYPE.VIDEO && (
          <div
            className="attachment-video"
            onMouseLeave={() => {
              setSubjectIsHovered(false);
            }}
            onMouseEnter={() => {
              setSubjectIsHovered(true);
            }}
          >
            <div
              className={`attachment-overlay ${
                subjectIsHovered ? "hovered" : ""
              }`}
            >
              <div className="overlay-content-wrapper">
                <span className="overlay-filename">{firstFile.name}</span>
                <EmpPill {...PILL_COLORS.primary} text={"Video"} />
                <div className="download-wrapper">
                  <EmpLink
                    linkStyle="white"
                    text={"Download"}
                    href={firstFile.url}
                  />
                </div>
              </div>
            </div>
            <video src={firstFile.url} controls loop>
              {" "}
            </video>
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="evidence-details-view">
      <DeleteModal ref={deleteModalRef} onSave={() => {}} suppressToast />
      <div className="header-section ph-5 pv-3">
        <div className="info-wrapper">
          <h2 className="section-title">View Proof of Work</h2>
          <p className="emp-paragraph mt-2">
            View Proof of work that you have submitted
          </p>
        </div>
        {role === "seller" && (
          <EmpButton
            leftIcon={TrashIcon}
            buttonHeight="sm"
            onSubmit={() => {
              deleteModalRef.current?.show(
                "Image Evidence",
                "Image Evidence",
                deleteEvidenceResource,
                evidenceRecordDto.id
              );
            }}
            buttonStyle="secondary"
            isFullWidth={false}
            text={"Delete"}
          />
        )}
      </div>
      <section>
        {evidenceRecordDto.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK &&
          renderSmLinkEvidence()}
        {evidenceRecordDto.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE &&
          renderSmImageEvidence()}
        {evidenceRecordDto.evidenceType === EVIDENCE_TYPE.ATTACHMENT &&
          renderFileAttachment()}
        <div className="details-section mt-4">
          <div className="split-form-control">
            <EmpContent
              label={"SUBMISSION TYPE"}
              value={CampaignAnalyticsUtils.mapEvidenceName(
                evidenceRecordDto.evidenceType
              )}
            />
          </div>
          {evidenceRecordDto.evidenceType ===
            EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
            <div className="split-form-control">
              <EmpContent
                label={"STATUS"}
                value={
                  <>
                    {evidenceRecordDto.hasSocialMediaObject &&
                      evidenceRecordDto.platform !== SOCIAL_MEDIA_TYPE.X && (
                        <EmpPill
                          Icon={CheckIcon}
                          {...PILL_COLORS.green}
                          text={"Live Data"}
                          tooltip={`This data is pulled directly from the ${evidenceRecordDto.platform} API. Refresh the page to access the most up-to-date information.`}
                        />
                      )}
                    {evidenceRecordDto.platform === SOCIAL_MEDIA_TYPE.X && (
                      <EmpPill
                        Icon={CheckIcon}
                        {...PILL_COLORS.green}
                        text={`Updated ${DateUtil.toPeriod(
                          evidenceRecordDto.metricSnapshot!.updatedDate
                        )}`}
                        tooltip={`The metrics for this post were last updated ${DateUtil.toPeriod(
                          evidenceRecordDto.metricSnapshot!.updatedDate
                        )}. To view the most recent Twitter data, you can trigger a refresh for the latest metrics.`}
                      />
                    )}
                    {!evidenceRecordDto.hasSocialMediaObject &&
                      evidenceRecordDto.platform !== SOCIAL_MEDIA_TYPE.X && (
                        <>
                          {![
                            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                          ].includes(evidenceRecordDto.mediaType) && (
                            <EmpPill
                              {...PILL_COLORS.gray}
                              Icon={BrokenLinkIcon}
                              text={"Archived"}
                              tooltip="This user's social media account used for submitting proof of work has been disconnected. The metrics shown here were last updated at least 12 hours prior to the disconnection. For detailed metrics, please click the link to view the original post."
                            />
                          )}
                          {[
                            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                            PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                          ].includes(evidenceRecordDto.mediaType) && (
                            <EmpPill
                              {...PILL_COLORS.gray}
                              Icon={BrokenLinkIcon}
                              text={"Story Expired"}
                              tooltip="Instagram Stories are available for 24 hours and has now expired. We took a final snapshot of its metrics one hour before expiration."
                            />
                          )}
                        </>
                      )}
                  </>
                }
              />
            </div>
          )}
          <div className="split-form-control">
            <EmpContent
              label={"CREATED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecordDto.createdDate
              )}
            />
          </div>
          {evidenceRecordDto.evidenceType ===
            EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
            <div className="split-form-control">
              <EmpContent
                label={"LAST REFRESHED"}
                value={
                  evidenceRecordDto.hasSocialMediaObject
                    ? "Just now"
                    : DateUtil.toPeriod(
                        evidenceRecordDto.metricSnapshot!.updatedDate
                      )
                }
              />
            </div>
          )}
          <div className="split-form-control">
            <EmpContent
              label={"REMARKS"}
              value={
                evidenceRecordDto.remarks.length === 0
                  ? "Submittor has not added any remarks"
                  : evidenceRecordDto.remarks
              }
            />
          </div>
          {[
            EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE,
            EVIDENCE_TYPE.SOCIAL_MEDIA_LINK,
          ].includes(evidenceRecordDto.evidenceType) && (
            <>
              {![
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
              ].includes(evidenceRecordDto.mediaType) && (
                <div className="split-form-control">
                  <EmpContent
                    label={"LINK TO POST"}
                    value={
                      evidenceRecordDto.mediaUrl ? (
                        <EmpLink
                          newTab
                          showNewTabIcon
                          text={"Link to post"}
                          href={evidenceRecordDto.mediaUrl}
                        />
                      ) : (
                        "No Link Provided"
                      )
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <div
        className="mt-6 ph-5 pt-3"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
          borderTop: `1px solid ${Color.NEUTRAL[800]}`,
        }}
      >
        <EmpButton
          isFullWidth={false}
          text={<FormattedMessage id="cta_done" />}
          onSubmit={() => {
            back();
          }}
        />
      </div>
    </section>
  );
};

export default SubmitEvidenceDetailsView;
