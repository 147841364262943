import "./instagram-connect-info-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import { motion } from "framer-motion";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import FacebookIcon from "../../../components/icon/facebook-icon";
import { Color } from "../../../utilities/colors";
import InstagramIcon from "../../../components/icon/instagram-icon";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import { FormattedMessage } from "react-intl";

export interface InstagramConnectInfoModalRef {
  show: () => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const InstagramConnectInfoModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async () => {
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        <div className="emp-instagram-connect-info-modal">
          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate={"visible"}
            transition={{ duration: 0.2, delay: 0 * animationDelay }}
          >
            <span className="modal-header">
              <FormattedMessage id="instagramConnectInfoModal_header" />
            </span>
          </motion.div>
          <div className="content-section">
            <section className="info-section-wrapper">
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 0.2, delay: 1 * animationDelay }}
                className="info"
              >
                <div className="left-elem">
                  <div className="circle facebook">
                    <FacebookIcon
                      backgroundColor={Color.NEUTRAL[0]}
                      size={30}
                    />
                  </div>
                </div>
                <div className="right-elem">
                  <span className="title">
                    <FormattedMessage id="instagramConnectInfoModal_step1Header" />
                  </span>
                  <p className="description">
                    <FormattedMessage id="instagramConnectInfoModal_step1Desc" />
                  </p>
                </div>
              </motion.div>

              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 0.2, delay: 2 * animationDelay }}
                className="info"
              >
                <div className="left-elem">
                  <div className="circle instagram">
                    <InstagramIcon
                      backgroundColor={Color.NEUTRAL[0]}
                      size={30}
                    />
                  </div>
                  <div className="circle facebook fb-page">
                    <img
                      alt="facebook page"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/facebook-page-icon.png"
                    />
                  </div>
                </div>
                <div className="right-elem">
                  <span className="title">
                    <FormattedMessage id="instagramConnectInfoModal_step2Header" />
                  </span>
                  <p className="description">
                    <FormattedMessage id="instagramConnectInfoModal_step2Desc" />
                  </p>
                </div>
              </motion.div>

              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={"visible"}
                transition={{ duration: 0.2, delay: 3 * animationDelay }}
                className="info"
              >
                <div className="left-elem">
                  <div className="circle emplifive">
                    <img
                      alt="emplifive"
                      src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/emplifive-logohead.png"
                    />
                  </div>
                </div>
                <div className="right-elem">
                  <span className="title">
                    <FormattedMessage id="instagramConnectInfoModal_step3Header" />
                  </span>
                  <p className="description">
                    <FormattedMessage id="instagramConnectInfoModal_step3Desc" />
                  </p>
                </div>
              </motion.div>
            </section>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              className={"button-wrapper mt-4"}
              transition={{
                duration: animationDuration,
                delay: 5 * animationDelay,
              }}
            >
              <EmpButton
                buttonStyle={"secondary"}
                isFullWidth={false}
                onSubmit={() => {
                  dismiss();
                }}
                text={<FormattedMessage id="cta_cancel" />}
              />

              <EmpButton
                isFullWidth={false}
                onSubmit={() => {
                  props.onSave();
                  dismiss();
                }}
                text={<FormattedMessage id="cta_proceed" />}
              />
            </motion.div>
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default InstagramConnectInfoModal;
