import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TASK_TYPE } from "../../constants/app.constants";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableEmptyRow,
  EmpTableProps,
  EmpTableRef,
} from "../shared/EmpTable/EmpTable";
import "./sm-recruitment-table.scss";
import { CreatorRecruitmentCardDto } from "../../model/user/creator-recruitment-card.dto";
import EmpRoundedPill from "../shared/emp-rounded-pill/emp-rounded-pill";
import { Tooltip, Whisper } from "rsuite";
import EmpLink from "../shared/emp-link/emp-link";
import StringUtils from "../../utilities/string.util";
import EmpButton from "../shared/emp-button/emp-button";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import FormFieldUtils from "../../utilities/form-field.util";
import EmptyRowUtils from "../../utilities/empty-row.util";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";

interface Props {
  rawRecords: CreatorRecruitmentCardDto[];
  taskType: string;
  onClick?: (
    context: "card" | "invite-btn",
    record: CreatorRecruitmentCardDto
  ) => void;
  hasRestriction: boolean;
}

export const SmRecruitmentTable = (props: Props) => {
  const { rawRecords, taskType, hasRestriction } = props;
  const [placeholderRecords, setPlaceholderRecords] = useState<
    EmpTableEmptyRow[]
  >([]);
  const empTableRef = useRef<EmpTableRef>();
  const [tableProps, setTableProps] =
    useState<EmpTableProps<CreatorRecruitmentCardDto>>();

  useEffect(() => {
    setTableProps({
      rowKey: "id",
      data: rawRecords,
      contentColumns: contentSpec,
    });

    if (hasRestriction) {
      setPlaceholderRecords(EmptyRowUtils.getEmptyRow(3));
    }
  }, [rawRecords, hasRestriction]);

  const [overlayHeight, setOverlayHeight] = useState(0);
  useEffect(() => {
    const recordsHeight = empTableRef.current?.getRecordHeight(3, "bottom");
    setOverlayHeight(recordsHeight ?? 0);
  }, [tableProps]);

  const calculateTotalFollowers = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return FormFieldUtils.toCompact(record.facebookMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.INSTAGRAM)
        return FormFieldUtils.toCompact(
          record.instagramMetrics?.followers ?? 0
        );
      else if (record.platform === TASK_TYPE.TIKTOK)
        return FormFieldUtils.toCompact(record.tiktokMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.X)
        return FormFieldUtils.toCompact(record.xMetrics?.followers ?? 0);
      else if (record.platform === TASK_TYPE.GENERAL)
        return FormFieldUtils.toCompact(
          (record.facebookMetrics?.followers ?? 0) +
            (record.instagramMetrics?.followers ?? 0) +
            (record.tiktokMetrics?.followers ?? 0)
        );
    },
    []
  );

  const calculateAvgImpressions = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return FormFieldUtils.toCompact(
          record.facebookMetrics?.avgImpressions ?? 0
        );
      else if (record.platform === TASK_TYPE.INSTAGRAM)
        return FormFieldUtils.toCompact(
          record.instagramMetrics?.avgImpressions ?? 0
        );
      else if (record.platform === TASK_TYPE.X)
        return FormFieldUtils.toCompact(record.xMetrics?.avgImpressions ?? 0);
      else if (record.platform === TASK_TYPE.TIKTOK)
        return FormFieldUtils.toCompact(
          record.tiktokMetrics?.avgImpressions ?? 0
        );
      else if (
        record.platform === TASK_TYPE.GENERAL &&
        record.facebookMetrics?.avgImpressions === undefined &&
        record.instagramMetrics?.avgImpressions === undefined &&
        record.tiktokMetrics?.avgImpressions === undefined
      )
        return "Unknown";
      else if (record.platform === TASK_TYPE.GENERAL)
        return FormFieldUtils.toCompact(
          (record.facebookMetrics?.avgImpressions ?? 0) +
            (record.instagramMetrics?.avgImpressions ?? 0) +
            (record.tiktokMetrics?.avgImpressions ?? 0) +
            (record.xMetrics?.avgImpressions ?? 0)
        );
    },
    []
  );

  const calculateAverageEngagementAcrossMultiplePlatform = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      const metricKeys = ["facebookMetrics", "tiktokMetrics", "xMetrics"];
      const { total, count } = metricKeys.reduce(
        (acc, key) => {
          const metricKey = key as
            | "facebookMetrics"
            | "tiktokMetrics"
            | "xMetrics";
          const engagement = record[metricKey]?.avgEngagements;
          if (engagement !== undefined) {
            acc.total += engagement;
            acc.count += 1;
          }
          return acc;
        },
        { total: 0, count: 0 }
      );
      // Return the average or 0 if no valid metrics were found
      return count === 0 ? 0 : total / count;
    },
    []
  );

  const calculateAverageEngagement = useCallback(
    (record: CreatorRecruitmentCardDto) => {
      if (record.platform === TASK_TYPE.FACEBOOK)
        return record.facebookMetrics?.avgEngagements ?? 0;
      else if (record.platform === TASK_TYPE.TIKTOK)
        return record.tiktokMetrics?.avgEngagements ?? 0;
      else if (record.platform === TASK_TYPE.X)
        return record.xMetrics?.avgEngagements ?? 0;
      else if (
        record.platform === TASK_TYPE.GENERAL &&
        record.facebookMetrics?.avgEngagements === undefined &&
        record.tiktokMetrics?.avgEngagements === undefined &&
        record.xMetrics?.avgEngagements === undefined
      )
        return "Unknown";
      else if (record.platform === TASK_TYPE.GENERAL)
        return calculateAverageEngagementAcrossMultiplePlatform(record);
      return 0;
    },
    [calculateAverageEngagementAcrossMultiplePlatform]
  );

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<CreatorRecruitmentCardDto>[] =
    useMemo(() => {
      const basicStructure = [
        {
          title: "Creator",
          dataIndex: "creator",
          render: (record: CreatorRecruitmentCardDto) => {
            let representative = <></>;
            if (record.representatives.length === 0)
              representative = <>Freelancer</>;
            else if (record.representatives.length > 0) {
              representative = (
                <div>
                  {record.representatives.length === 1 && (
                    <>
                      Managed by{" "}
                      <EmpLink text={record.representatives[0].companyName} />
                    </>
                  )}
                  {record.representatives.length > 1 && (
                    <>
                      Managed by {`${record.representatives.length} Agencies`}
                    </>
                  )}
                </div>
              );
            }
            return (
              <div className="initiator-col">
                <div className="profile-wrapper">
                  {record.imageType === "url" && (
                    <img
                      referrerPolicy="no-referrer"
                      className="profile"
                      alt={record.displayedName}
                      src={record.imageResource}
                    />
                  )}
                  {record.imageType === "avatar" && (
                    <div
                      className="profile"
                      style={{ background: record.imageResource }}
                    >
                      <span className="initials">{record.initials}</span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="name-wrapper">
                    <EmpLink
                      text={record.displayedName}
                      onSubmit={() => {
                        props.onClick && props.onClick("card", record);
                      }}
                    />
                    {record.country && (
                      <img
                        referrerPolicy="no-referrer"
                        className="img-flag"
                        alt="country"
                        src={COUNTRY_CONSTANTS[record.country]["flag_1x1"]}
                      />
                    )}
                  </div>
                  {representative}
                </div>
              </div>
            );
          },
        },
        {
          title: "Gender",
          dataIndex: "gender",
          render: (record: CreatorRecruitmentCardDto) => {
            return (
              <>{StringUtils.capitalizeWords(record.gender ?? "Not Set")}</>
            );
          },
        },
        {
          title: "Followers",
          dataIndex: "follower",
          render: (record: CreatorRecruitmentCardDto) => {
            return (
              <>
                {calculateTotalFollowers(record)}
                &nbsp;Followers
              </>
            );
          },
        },
        {
          title: "Avg Impressions",
          dataIndex: "avgImpressions",
          render: (record: CreatorRecruitmentCardDto) => {
            return <>{calculateAvgImpressions(record)}</>;
          },
        },
        {
          title: "Services",
          dataIndex: "services",
          render: (record: CreatorRecruitmentCardDto) => {
            const numRenderedPills = 2;
            const pillsRemaining = record.services.length - numRenderedPills;
            const renderedInterest = record.services.slice(0, numRenderedPills);
            const tooltipContent = record.services
              .slice(numRenderedPills, record.services.length)
              .map((elem) => elem.label)
              .join(", ");

            return (
              <div className="pill-wrapper">
                {renderedInterest.map((tag, index) => {
                  return (
                    <Fragment key={tag.id}>
                      {index < renderedInterest.length - 1 && (
                        <EmpRoundedPill
                          clickable={false}
                          key={tag.id}
                          text={tag.label}
                        />
                      )}
                      {index === renderedInterest.length - 1 && (
                        <div className="pill-h-wrapper">
                          <EmpRoundedPill
                            clickable={false}
                            key={tag.id}
                            text={tag.label}
                          />
                          {pillsRemaining > 0 && (
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{tooltipContent}</Tooltip>}
                            >
                              <div className="no-wrap">
                                <EmpRoundedPill
                                  clickable={false}
                                  text={`+${pillsRemaining}`}
                                />
                              </div>
                            </Whisper>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Interests",
          dataIndex: "interest",
          render: (record: CreatorRecruitmentCardDto) => {
            const numRenderedPills = 2;
            const pillsRemaining = record.interests.length - numRenderedPills;
            const renderedInterest = record.interests.slice(
              0,
              numRenderedPills
            );
            const tooltipContent = record.interests
              .slice(numRenderedPills, record.interests.length)
              .map((elem) => elem.label)
              .join(", ");

            return (
              <div className="pill-wrapper">
                {renderedInterest.map((tag, index) => {
                  return (
                    <Fragment key={tag.id}>
                      {index < renderedInterest.length - 1 && (
                        <EmpRoundedPill
                          clickable={false}
                          key={tag.id}
                          text={tag.label}
                        />
                      )}
                      {index === renderedInterest.length - 1 && (
                        <div className="pill-h-wrapper">
                          <EmpRoundedPill
                            clickable={false}
                            key={tag.id}
                            text={tag.label}
                          />
                          {pillsRemaining > 0 && (
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={<Tooltip>{tooltipContent}</Tooltip>}
                            >
                              <div className="no-wrap">
                                <EmpRoundedPill
                                  clickable={false}
                                  text={`+${pillsRemaining}`}
                                />
                              </div>
                            </Whisper>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          render: (record: CreatorRecruitmentCardDto) => {
            return (
              <EmpButton
                isFullWidth={false}
                onSubmit={() => {
                  props.onClick && props.onClick("card", record);
                }}
                buttonHeight="sm"
                text={"View"}
              />
            );
          },
        },
      ];

      if (
        [
          TASK_TYPE.FACEBOOK,
          TASK_TYPE.TIKTOK,
          TASK_TYPE.GENERAL,
          TASK_TYPE.X,
        ].includes(taskType)
      ) {
        basicStructure.splice(3, 0, {
          title: "Avg Engagement",
          dataIndex: "avgEngagement",
          render: (record: CreatorRecruitmentCardDto) => {
            const engagement = calculateAverageEngagement(record);
            return (
              <>
                {engagement !== "Unknown" && `${engagement.toFixed(2)}%`}
                {engagement === "Unknown" && <>Unknown</>}
              </>
            );
          },
        });
      } else if ([TASK_TYPE.INSTAGRAM, TASK_TYPE.GENERAL].includes(taskType)) {
        basicStructure.splice(3, 0, {
          title: "Avg Reach",
          dataIndex: "avgReach",
          render: (record: CreatorRecruitmentCardDto) => {
            return (
              <>
                {FormFieldUtils.toCompact(
                  record.instagramMetrics?.avgReach ?? 0
                )}
              </>
            );
          },
        });
      }
      return basicStructure;
    }, [
      calculateAverageEngagement,
      calculateTotalFollowers,
      calculateAvgImpressions,
    ]);

  return (
    <div className="emp-sm-recruitment-table">
      {tableProps && (
        <EmpTable
          ref={empTableRef}
          style={{ width: "100%" }}
          contentColumns={tableProps.contentColumns}
          data={[...tableProps.data, ...placeholderRecords]}
          rowKey={tableProps.rowKey}
        />
      )}
      {hasRestriction && (
        <div className="restriction-overlay" style={{ height: overlayHeight }}>
          <h3 className="title">Feature Locked!</h3>
          <p className="emp-paragraph mt-3">
            To view all creator profiles on Emplifive, please upgrade your free
            plan.
          </p>
          <EmpButton
            isFullWidth={false}
            className="mt-5"
            text={"View Plans"}
            onSubmit={() => {
              PubSub.publish(PUB_SUB_TOPICS.VIEW_PLANS);
            }}
          />
        </div>
      )}
    </div>
  );
};
