import { forwardRef, useRef, useState } from "react";
import { CardDto } from "../../model/payment/payment/card.dto";
import { EmpImage } from "../shared/emp-image/emp-image";
import visaImg from "../../assets/images/payment/visa.svg";
import mastercardImg from "../../assets/images/payment/mastercard.svg";
import jcbImage from "../../assets/images/payment/jcb.svg";
import americanExpressImg from "../../assets/images/payment/american_express.svg";
import "./select-payment-method-view.scss";
import { EMPTY_PROFILE_IMG_URL } from "../../constants/app.constants";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpAnimatedLoader from "../shared/emp-animated-loader/emp-animated-loader";
import PaymentFailModal, {
  PaymentFailModalRef,
} from "../modals/payment-fail-modal";

interface SelectedPlan {
  id: string;
  type: "trial" | "price" | "proceed";
}

interface Props {
  cards: CardDto[];
  selectedPlan: SelectedPlan;
  callbackUrl: string;
  redirectToBrandAfterSubscription?: boolean;
  onBack: () => void;
  onDismiss: () => void;
  onSuccess?: () => void;
}

const SelectPaymentMethodView = forwardRef((props: Props, ref) => {
  const { cards, selectedPlan, onDismiss, onBack, callbackUrl, onSuccess } =
    props;
  const redirectToBrandAfterSubscription =
    props.redirectToBrandAfterSubscription ?? false;
  const paymentMethodBtnRef = useRef<EmpButtonRef>();
  const [selectedPaymentConsent, setSelectedPaymentConsent] =
    useState<string>();
  const [isLoading, setLoading] = useState(false);
  const paymentFailModalRef = useRef<PaymentFailModalRef>();

  const recommendCardImg = (brand: string) => {
    if (brand === "visa") return visaImg;
    else if (brand === "mastercard") return mastercardImg;
    else if (brand === "american express") return americanExpressImg;
    else if (brand === "jcb") return jcbImage;
  };

  return (
    <div className="emp-subscription-payment-view ">
      <PaymentFailModal ref={paymentFailModalRef} />
      <EmpAnimatedLoader isVisible={isLoading} />
      <section className="intro-section">
        <h2 className="section-title">Select Payment Method</h2>
        <p className="emp-paragraph mt-2">
          Please select a payment method for you to pay this subscription.
        </p>
      </section>
      <section className="payment-method-section">
        <EmpButton
          ref={paymentMethodBtnRef}
          onSubmit={() => {}}
          text={"Add New Payment Method"}
          buttonStyle="secondary"
        />
        {cards.map((elem) => {
          return (
            <div
              onClick={() => {}}
              className={`payment-method-card ${
                elem.paymentConsentId === selectedPaymentConsent
                  ? "selected"
                  : ""
              }`}
              key={elem.paymentConsentId}
            >
              <div
                className={`svg-wrapper ${
                  elem.paymentConsentId === selectedPaymentConsent
                    ? "selected"
                    : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 800 800"
                  opacity="0.96"
                >
                  <defs>
                    <linearGradient
                      x1="50%"
                      y1="0%"
                      x2="50%"
                      y2="100%"
                      id="rrreflection-grad"
                    >
                      <stop
                        stopColor="hsl(205, 69%, 60%)"
                        stopOpacity="1"
                        offset="45%"
                      ></stop>
                      <stop
                        stopColor="hsl(205, 69%, 80%)"
                        stopOpacity="1"
                        offset="100%"
                      ></stop>
                    </linearGradient>
                    <linearGradient
                      x1="50%"
                      y1="0%"
                      x2="50%"
                      y2="100%"
                      id="rrreflection-grad-2"
                    >
                      <stop
                        stopColor="hsl(205, 69%, 80%)"
                        stopOpacity="1"
                        offset="0%"
                      ></stop>
                      <stop
                        stopColor="hsl(205, 69%, 60%)"
                        stopOpacity="1"
                        offset="45%"
                      ></stop>
                    </linearGradient>
                    <linearGradient
                      gradientTransform="rotate(270)"
                      x1="50%"
                      y1="0%"
                      x2="50%"
                      y2="100%"
                      id="rrreflection-grad-3"
                    >
                      <stop
                        stopColor="hsl(205, 69%, 60%)"
                        stopOpacity="1"
                        offset="45%"
                      ></stop>
                      <stop
                        stopColor="hsl(205, 69%, 80%)"
                        stopOpacity="1"
                        offset="100%"
                      ></stop>
                    </linearGradient>
                    <linearGradient
                      gradientTransform="rotate(270)"
                      x1="50%"
                      y1="0%"
                      x2="50%"
                      y2="100%"
                      id="rrreflection-grad-4"
                    >
                      <stop
                        stopColor="hsl(205, 69%, 80%)"
                        stopOpacity="1"
                        offset="0%"
                      ></stop>
                      <stop
                        stopColor="hsl(205, 69%, 60%)"
                        stopOpacity="1"
                        offset="45%"
                      ></stop>
                    </linearGradient>
                  </defs>
                  <g
                    strokeWidth="1.5"
                    stroke="url(#rrreflection-grad)"
                    fill="none"
                  >
                    <circle r="351" cx="50%" cy="0"></circle>
                    <circle r="338" cx="50%" cy="0"></circle>
                    <circle r="325" cx="50%" cy="0"></circle>
                    <circle r="312" cx="50%" cy="0"></circle>
                    <circle r="299" cx="50%" cy="0"></circle>
                    <circle r="286" cx="50%" cy="0"></circle>
                    <circle r="273" cx="50%" cy="0"></circle>
                    <circle r="260" cx="50%" cy="0"></circle>
                    <circle r="247" cx="50%" cy="0"></circle>
                    <circle r="234" cx="50%" cy="0"></circle>
                    <circle r="221" cx="50%" cy="0"></circle>
                    <circle r="208" cx="50%" cy="0"></circle>
                    <circle r="195" cx="50%" cy="0"></circle>
                    <circle r="182" cx="50%" cy="0"></circle>
                    <circle r="169" cx="50%" cy="0"></circle>
                    <circle r="156" cx="50%" cy="0"></circle>
                    <circle r="143" cx="50%" cy="0"></circle>
                    <circle r="130" cx="50%" cy="0"></circle>
                    <circle r="117" cx="50%" cy="0"></circle>
                    <circle r="104" cx="50%" cy="0"></circle>
                    <circle r="91" cx="50%" cy="0"></circle>
                    <circle r="78" cx="50%" cy="0"></circle>
                    <circle r="65" cx="50%" cy="0"></circle>
                    <circle r="52" cx="50%" cy="0"></circle>
                    <circle r="39" cx="50%" cy="0"></circle>
                    <circle r="26" cx="50%" cy="0"></circle>
                    <circle r="13" cx="50%" cy="0"></circle>
                  </g>
                  <g
                    strokeWidth="1.5"
                    stroke="url(#rrreflection-grad-2)"
                    fill="none"
                  >
                    <circle r="351" cx="50%" cy="100%"></circle>
                    <circle r="338" cx="50%" cy="100%"></circle>
                    <circle r="325" cx="50%" cy="100%"></circle>
                    <circle r="312" cx="50%" cy="100%"></circle>
                    <circle r="299" cx="50%" cy="100%"></circle>
                    <circle r="286" cx="50%" cy="100%"></circle>
                    <circle r="273" cx="50%" cy="100%"></circle>
                    <circle r="260" cx="50%" cy="100%"></circle>
                    <circle r="247" cx="50%" cy="100%"></circle>
                    <circle r="234" cx="50%" cy="100%"></circle>
                    <circle r="221" cx="50%" cy="100%"></circle>
                    <circle r="208" cx="50%" cy="100%"></circle>
                    <circle r="195" cx="50%" cy="100%"></circle>
                    <circle r="182" cx="50%" cy="100%"></circle>
                    <circle r="169" cx="50%" cy="100%"></circle>
                    <circle r="156" cx="50%" cy="100%"></circle>
                    <circle r="143" cx="50%" cy="100%"></circle>
                    <circle r="130" cx="50%" cy="100%"></circle>
                    <circle r="117" cx="50%" cy="100%"></circle>
                    <circle r="104" cx="50%" cy="100%"></circle>
                    <circle r="91" cx="50%" cy="100%"></circle>
                    <circle r="78" cx="50%" cy="100%"></circle>
                    <circle r="65" cx="50%" cy="100%"></circle>
                    <circle r="52" cx="50%" cy="100%"></circle>
                    <circle r="39" cx="50%" cy="100%"></circle>
                    <circle r="26" cx="50%" cy="100%"></circle>
                    <circle r="13" cx="50%" cy="100%"></circle>
                  </g>
                  <g
                    strokeWidth="1.5"
                    stroke="url(#rrreflection-grad-3)"
                    fill="none"
                  >
                    <circle r="351" cx="0" cy="50%"></circle>
                    <circle r="338" cx="0" cy="50%"></circle>
                    <circle r="325" cx="0" cy="50%"></circle>
                    <circle r="312" cx="0" cy="50%"></circle>
                    <circle r="299" cx="0" cy="50%"></circle>
                    <circle r="286" cx="0" cy="50%"></circle>
                    <circle r="273" cx="0" cy="50%"></circle>
                    <circle r="260" cx="0" cy="50%"></circle>
                    <circle r="247" cx="0" cy="50%"></circle>
                    <circle r="234" cx="0" cy="50%"></circle>
                    <circle r="221" cx="0" cy="50%"></circle>
                    <circle r="208" cx="0" cy="50%"></circle>
                    <circle r="195" cx="0" cy="50%"></circle>
                    <circle r="182" cx="0" cy="50%"></circle>
                    <circle r="169" cx="0" cy="50%"></circle>
                    <circle r="156" cx="0" cy="50%"></circle>
                    <circle r="143" cx="0" cy="50%"></circle>
                    <circle r="130" cx="0" cy="50%"></circle>
                    <circle r="117" cx="0" cy="50%"></circle>
                    <circle r="104" cx="0" cy="50%"></circle>
                    <circle r="91" cx="0" cy="50%"></circle>
                    <circle r="78" cx="0" cy="50%"></circle>
                    <circle r="65" cx="0" cy="50%"></circle>
                    <circle r="52" cx="0" cy="50%"></circle>
                    <circle r="39" cx="0" cy="50%"></circle>
                    <circle r="26" cx="0" cy="50%"></circle>
                    <circle r="13" cx="0" cy="50%"></circle>
                  </g>
                  <g
                    strokeWidth="1.5"
                    stroke="url(#rrreflection-grad-4)"
                    fill="none"
                  >
                    <circle r="351" cx="100%" cy="50%"></circle>
                    <circle r="338" cx="100%" cy="50%"></circle>
                    <circle r="325" cx="100%" cy="50%"></circle>
                    <circle r="312" cx="100%" cy="50%"></circle>
                    <circle r="299" cx="100%" cy="50%"></circle>
                    <circle r="286" cx="100%" cy="50%"></circle>
                    <circle r="273" cx="100%" cy="50%"></circle>
                    <circle r="260" cx="100%" cy="50%"></circle>
                    <circle r="247" cx="100%" cy="50%"></circle>
                    <circle r="234" cx="100%" cy="50%"></circle>
                    <circle r="221" cx="100%" cy="50%"></circle>
                    <circle r="208" cx="100%" cy="50%"></circle>
                    <circle r="195" cx="100%" cy="50%"></circle>
                    <circle r="182" cx="100%" cy="50%"></circle>
                    <circle r="169" cx="100%" cy="50%"></circle>
                    <circle r="156" cx="100%" cy="50%"></circle>
                    <circle r="143" cx="100%" cy="50%"></circle>
                    <circle r="130" cx="100%" cy="50%"></circle>
                    <circle r="117" cx="100%" cy="50%"></circle>
                    <circle r="104" cx="100%" cy="50%"></circle>
                    <circle r="91" cx="100%" cy="50%"></circle>
                    <circle r="78" cx="100%" cy="50%"></circle>
                    <circle r="65" cx="100%" cy="50%"></circle>
                    <circle r="52" cx="100%" cy="50%"></circle>
                    <circle r="39" cx="100%" cy="50%"></circle>
                    <circle r="26" cx="100%" cy="50%"></circle>
                    <circle r="13" cx="100%" cy="50%"></circle>
                  </g>
                </svg>
              </div>
              <div className="card-header-wrapper">
                <h2>Payment Method</h2>
              </div>

              <div className="card-number-wrapper mt-2">
                <span className="card-number-lbl">
                  **** **** **** {elem.last4}
                </span>
                <EmpImage
                  className="card-brand-height"
                  src={recommendCardImg(elem.brand)}
                  alt={elem.brand}
                  fallback={EMPTY_PROFILE_IMG_URL}
                />
              </div>
              <span className="name-lbl">{elem.name}</span>
            </div>
          );
        })}
      </section>
    </div>
  );
});

export default SelectPaymentMethodView;
