import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { Color } from "../../utilities/colors";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpCheckboxGroup from "../shared/emp-checkbox-group/emp-checkbox-group";
import EmpEditor from "../shared/emp-editor/emp-editor";
import "./manage-deliverable-modal.scss";
import EmpException from "../../exception/empException";
import { Descendant } from "slate";

type ModalMode = "edit" | "create";
export interface ManageDeliverableModalRef {
  show: (
    mode: ModalMode,
    index?: number,
    deliverableForm?: DeliverableForm
  ) => void;
  hide: () => void;
}

interface DeliverableForm {
  name: string;
  description: string;
  draftRequired: boolean;
}

interface DeliverableFormOutput {
  name: string;
  description: Descendant[];
  draftRequired: string[];
}

interface Props {
  onSave: (
    details: { mode: ModalMode; index?: number } & DeliverableFormOutput
  ) => void;
}

const ManageDeliverableModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const editIndexRef = useRef<number>();
  const modalModeRef = useRef<ModalMode>("create");

  // Form is set to empty at first
  const [form, setForm] = useState<IFormGroup>({
    name: new FormControl("text", [
      new RequiredValidator("Deliverable name is required"),
      new LengthValidator(
        10,
        50,
        "Deliverable name must be at least 10 characters",
        "Deliverable name must not exceed 50 characters"
      ),
    ]),
    description: new FormControl("rich-text", [
      new RequiredValidator("Description is required"),
      new LengthValidator(
        100,
        1000,
        "Description must be at least 100 characters",
        "Description must not exceed 1000 characters"
      ),
    ]),
    draftRequired: new FormControl("text", [], ["draftRequired"]),
  });

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    mode: ModalMode,
    index?: number,
    deliverableForm?: DeliverableForm
  ) => {
    modalModeRef.current = mode;
    if (mode === "edit") {
      if (!deliverableForm || index === undefined) {
        throw new EmpException(
          "No deliverable form data supplied for edit mode"
        );
      }
      editIndexRef.current = index;
      form.name.forceUpdateValue(deliverableForm.name);
      form.description.forceUpdateValue(
        JSON.parse(deliverableForm.description)
      );
      form.draftRequired.forceUpdateValue(
        deliverableForm.draftRequired ? ["draftRequired"] : ""
      );
    }
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };
  const createDeliverable = () => {
    try {
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;
      if (modalModeRef.current === "create") {
        props.onSave({
          mode: "create",
          name: form.name.getValue(),
          description: form.description.getValue(),
          draftRequired: form.draftRequired.getValue(),
        });
      } else if (modalModeRef.current === "edit") {
        props.onSave({
          mode: "edit",
          index: editIndexRef.current,
          name: form.name.getValue(),
          description: form.description.getValue(),
          draftRequired: form.draftRequired.getValue(),
        });
      }
      dismiss();
    } catch (e) {
      console.error(e);
    }
  };

  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.reset(form);
    form.draftRequired.forceUpdateValue(["draftRequired"]);
    setForm({ ...form });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-create-deliverable-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            {modalModeRef.current === "create"
              ? "Create another deliverable"
              : "Modify Deliverable"}
          </h2>
          <p className="description">
            You can create multiple deliverables within a single task
          </p>
        </div>
        <div className="mt-4 ph-5">
          <EmpTextInput
            id={"name"}
            labelText="Deliverable Name"
            required
            tooltip="Enter a memorable and descriptive name for your task. A unique campaign name helps you easily identify and manage your marketing efforts."
            formControl={form.name}
            onChange={validate}
            placeholder={"e.g Instagram Marketing Task"}
          />
        </div>
        <div className="ph-5 mt-4">
          <EmpEditor
            labelText="Description"
            placeholder="Enter Deliverable Description here"
            formControl={form.description}
            required
            onChange={validate}
            characterCount={500}
            description="Specify the task deliverables with comprehensive details, ensuring clarity on the requirements and acceptance criteria for the creator."
          />
        </div>
        <div className="ph-5 mt-4">
          <EmpCheckboxGroup
            labelText="Deliverable Requirement"
            id={"draftRequired"}
            formControl={form.draftRequired}
            checkboxOptions={[
              {
                label:
                  "I will require the creator to submit a draft for approval.",
                value: "draftRequired",
              },
            ]}
          />
        </div>

        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <div className="custom-rate-btn-wrapper">
            <EmpButton
              buttonStyle={"secondary"}
              text={"Cancel"}
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>

          <div className="custom-rate-btn-wrapper">
            <EmpButton
              text={
                modalModeRef.current === "create"
                  ? "Create Deliverable"
                  : "Save Changes"
              }
              onSubmit={() => {
                createDeliverable();
              }}
            />
          </div>
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ManageDeliverableModal;
