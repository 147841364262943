import "./x-loader.scss";
import XIcon from "../icon/x-icon";

interface Props {
  title?: string;
  description?: string;
  positioning?: "fixed" | "absolute";
}

export const XLoader = (props: Props) => {
  const title = props.title ?? "Fetching X Details";
  const positioning = props.positioning ?? "fixed";
  const description = props.description;
  return (
    <div
      className={`emp-x-loader-overlay ${
        positioning === "absolute" ? "emp-x-loader-absolute" : ""
      }`}
    >
      <div className="emp-loader">
        <XIcon />
        <span className="label mt-2 ">{title}</span>
        {description && <p className="emp-paragraph mt-1">{description}</p>}
        <div className="emp-progress mt-3">
          <div className="indeterminate"></div>
        </div>
      </div>
    </div>
  );
};
