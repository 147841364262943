import { useState } from "react";
import { FormattedMessage } from "react-intl";
import BrokenLinkIcon from "../../../components/icon/broken-link-icon";
import { Color } from "../../../utilities/colors";
import "./unlink-btn.scss";

interface Props {
  onSubmit: () => void;
}
export const UnlinkBtn = (props: Props) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      onMouseLeave={() => setOnHover(false)}
      onMouseEnter={() => {
        setOnHover(true);
      }}
      onClick={() => {
        props.onSubmit();
      }}
      className="unlink-btn"
    >
      <BrokenLinkIcon
        size={16}
        backgroundColor={onHover ? Color.NEUTRAL[600] : Color.NEUTRAL[400]}
      />
      <span className={`unlink-lbl ${onHover ? "hovered" : ""}`}>
        <FormattedMessage id="bindSocialMediaStep_unlinkBtn" />
      </span>
    </div>
  );
};
