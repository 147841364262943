import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import { SaveProfileAttachmentReqDto } from "../../model/profile/save-profile-attachment-req.dto";
import { SaveProfileNameReqDto } from "../../model/profile/save-profile-name-req.dto";
import { SaveProfilePicturesReqDto } from "../../model/profile/save-profile-pictures-req.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const ProfileApi = {
  fetchProfile: async (
    organisationId: string
  ): Promise<ApiResponse<AgencyProfileDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<AgencyProfileDto>>(
      ENDPOINTS.userMicroservice.profile.fetchProfile(organisationId)
    );
    return response.data;
  },

  updateAboutUs: async (
    organisationId: string,
    aboutUs: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.profile.updateAboutUs(organisationId),
      { aboutUs }
    );
    return response.data;
  },

  saveProfileAttachment: async (
    organisationId: string,
    request: SaveProfileAttachmentReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.profile.saveProfileAttachment(organisationId),
      request
    );
    return response.data;
  },

  saveCompanyName: async (
    organisationId: string,
    request: SaveProfileNameReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.profile.updateCompanyName(organisationId),
      request
    );
    return response.data;
  },

  deleteProfileAttachment: async (
    profileAttachmentId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.profile.deleteProfileAttachment(
        profileAttachmentId
      )
    );
    return response.data;
  },

  saveProfilePicture: async (
    request: SaveProfilePicturesReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.profile.saveProfilePicture,
      request
    );
    return response.data;
  },
};
export default ProfileApi;
