import { Color } from "../../../utilities/colors";
import InfoCircleIcon from "../../icon/info-circle-icon";
import "./emp-content.scss";

interface Props {
  label: string | JSX.Element;
  mode?: "default" | "skeleton";
  orientation?: "vertical" | "horizontal";
  hasError?: boolean;
  skeletonSpecs?: {
    useShimmer?: boolean;
    headerWidth: number | string;
    valueWidth: number | string;
  };
  labelDisableOverflow?: boolean;
  value: string | JSX.Element;
  className?: string;
}

export const EmpContent = (props: Props) => {
  const mode = props.mode ?? "default";
  const className = props.className ?? "";
  const hasError = props.hasError ?? false;
  const orientation = props.orientation ?? "vertical";
  const skeletonHeaderWidth = props.skeletonSpecs?.headerWidth ?? 80;
  const skeletonValueWidth = props.skeletonSpecs?.valueWidth ?? 120;
  const useShimmer = props.skeletonSpecs?.useShimmer ?? false;
  const labelDisableOverflow = props.labelDisableOverflow ?? false;

  return (
    <>
      {mode === "default" && (
        <div
          className={`emp-content ${className}`}
          style={{
            flexDirection: orientation === "vertical" ? "column" : "row",
          }}
        >
          <div
            className="emp-content__label"
            style={{ whiteSpace: labelDisableOverflow ? "nowrap" : "normal" }}
          >
            {props.label}
            {hasError && (
              <InfoCircleIcon size={14} backgroundColor={Color.RED[400]} />
            )}
          </div>
          <div className="emp-content__value">{props.value}</div>
        </div>
      )}

      {mode === "skeleton" && (
        <div
          className={`emp-content ${className} `}
          style={{
            flexDirection: orientation === "vertical" ? "column" : "row",
          }}
        >
          <div
            className={`emp-content__label skeleton ${
              useShimmer ? "emp-shimmer" : "skeleton-default-color"
            }`}
            style={{ width: skeletonHeaderWidth }}
          ></div>
          <div
            className={`emp-content__value skeleton ${
              useShimmer ? "emp-shimmer" : "skeleton-default-color"
            }`}
            style={{ width: skeletonValueWidth }}
          ></div>
        </div>
      )}
    </>
  );
};
export default EmpContent;
