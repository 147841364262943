import { useEffect, useState } from "react";
import { ToastEnum } from "../../../constants/toast";
import { Color } from "../../../utilities/colors";
import CheckCircleBrokenIcon from "../../icon/check-circle-broken-icon";
import InfoHexagonIcon from "../../icon/info-hexagon";
import "./emp-toast.scss";

export interface IToastProps {
  title: string;
  description: string;
  id: string;
  duration?: number;
  type: ToastEnum;
  onComplete: (id: string) => void;
}

const EmpToast = (props: IToastProps) => {
  const { type } = props;

  const activeDuration = 5000;
  const animationDuration = 300;
  // const [isVisible, setVisible] = useState(true)
  const [animCss, setAnimCss] = useState("");

  const dismissToast = (): void => {
    setAnimCss("");
    setTimeout(() => {
      props.onComplete(props.id);
    }, animationDuration);
  };

  // After the set duration is up, dismiss this toast
  useEffect(() => {
    // Play the appear animation 100ms after this component is rendered
    setTimeout(() => {
      setAnimCss("appear");
    }, 100);

    // Dismiss Toast after Active Duration seconds
    setTimeout(() => {
      dismissToast();
    }, activeDuration);
  }, []);

  return (
    <div className={`emp-toast ${animCss}`}>
      <div className="toast-wrapper">
        {type === ToastEnum.SUCCESS && (
          <div className="icon-wrapper success">
            <CheckCircleBrokenIcon backgroundColor={Color.NEUTRAL[0]} />
          </div>
        )}

        {type === ToastEnum.ERROR && (
          <div className="icon-wrapper error">
            <InfoHexagonIcon backgroundColor={Color.NEUTRAL[0]} />
          </div>
        )}
        <div className="ml-2">
          <p className="title">{props.title}</p>
          <p className="description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};
export default EmpToast;
