import { useNavigate } from "react-router-dom";
import { Color } from "../../../utilities/colors";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import ChevronRightIcon from "../../icon/chevron-right";
import "./emp-breadcrumb.scss";

export interface EmpBreadcrumbSpecs {
  link: string;
  text: string;
}

interface EmpBreadcrumbProps {
  items: EmpBreadcrumbSpecs[];
  loading?: boolean;
  numLoadingItem?: number;
}

const EmpBreadcrumb = (props: EmpBreadcrumbProps) => {
  const { items } = props;

  const loading = props.loading ?? false;
  const numLoadingItem = props.numLoadingItem ?? 1;

  const navigate = useNavigate();
  const breadcrumOnClick = (breadcrumb: EmpBreadcrumbSpecs) => {
    navigate(breadcrumb.link);
  };

  return (
    <div className="emp-breadcrumb">
      {!loading &&
        ReactKeyUtil.addReactKey(items).map((elem, index) => {
          return (
            <div
              onClick={() => {
                breadcrumOnClick(elem);
              }}
              key={elem.reactKey}
              className="crumb-wrapper"
            >
              <div
                className={`crumb ${index === items.length - 1 ? "active" : ""}`}
              >
                {elem.text}
              </div>
              {index !== items.length - 1 && (
                <ChevronRightIcon backgroundColor={Color.NEUTRAL[400]} />
              )}
            </div>
          );
        })}

      {loading &&
        Array.from({ length: numLoadingItem }, (_, index) => index + 1).map(
          (elem, index) => {
            return (
              <div key={elem} className="crumb-wrapper">
                <div
                  className={`skeletal-crumb emp-shimmer-translucent ${index === items.length - 1 ? "active" : ""}`}
                ></div>
                {index !== items.length - 1 && (
                  <ChevronRightIcon backgroundColor={"rgba(255,255,255,0.6"} />
                )}
              </div>
            );
          }
        )}
    </div>
  );
};
export default EmpBreadcrumb;
