import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import EmpButton from "../emp-button/emp-button";
import "./emp-subscription-access-control-view.scss";

interface Props {
  subscriptionAccessControlView: string;
  onOpenSubscriptionModal: () => void;
}
const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const DURATION = 0.2;
const DELAY_MS = 0.1;

export const EmpSubscriptionAccessControlView = (props: Props) => {
  const { subscriptionAccessControlView, onOpenSubscriptionModal } = props;
  const [isVisible, setVisible] = useState(false);
  const [viewMode, setViewMode] = useState("none");
  useEffect(() => {
    if (subscriptionAccessControlView === "hidden") setVisible(false);
    else {
      setViewMode(subscriptionAccessControlView);
      setVisible(true);
    }
  }, [subscriptionAccessControlView]);
  const [wrapperAnimState, setWrapperAnimState] = useState<
    "visible" | "hidden"
  >("visible");

  return (
    <>
      {isVisible && (
        <motion.div
          animate={wrapperAnimState}
          initial={"visible"}
          variants={fadeInVariants}
          transition={{ duration: 0.3 }}
          className={`emp-subscription-access-control-view`}
        >
          {viewMode === "loading" && (
            <div className="loading-wrapper">
              <img
                alt="Emplifive Logo"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creatorfi-icon.png"
              />
              <div className="emp-progress mt-2">
                <div className="indeterminate"></div>
              </div>
              <span className="loading-lbl mt-2">LOADING</span>
            </div>
          )}
          {viewMode === "denied" && (
            <div className="content-wrapper">
              <motion.img
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: 0 * DELAY_MS }}
                alt="Emplifive Logo"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/subscribe.png"
              />
              <motion.span
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: 1 * DELAY_MS }}
                className="title-lbl mt-3"
              >
                You have no active subscriptions
              </motion.span>
              <motion.p
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: 2 * DELAY_MS }}
                className="mt-3 emp-paragraph mt-2"
              >
                You will need to have a subscription to continue using our
                features.
              </motion.p>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInVariants}
                transition={{ duration: DURATION, delay: 3 * DELAY_MS }}
              >
                <EmpButton
                  className="mt-3"
                  text={"Subscribe to a plan"}
                  onSubmit={onOpenSubscriptionModal}
                />
              </motion.div>
            </div>
          )}

          <div></div>
        </motion.div>
      )}
    </>
  );
};
