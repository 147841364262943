import { useCallback, useEffect, useRef, useState } from "react";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import SearchIcon from "../../components/icon/search-icon";
import EmpMultiFilter, {
  MultiFilterOptionRef,
} from "../../components/shared/emp-multi-filter/emp-multi-filter";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import {
  campaignSortOptions,
  taskStatusOptions,
} from "../../constants/selectConstants";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import _debounce from "lodash/debounce";
import "./brand-campaign-page.scss";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import { EmpTaskCard } from "../../components/shared/emp-task-card/emp-task-card";
import { useLocation, useNavigate } from "react-router-dom";
import { TaskCardDto } from "../../model/campaign/task-card.dto";
import EmpPagination, {
  EmpPaginationProps,
} from "../../components/shared/EmpPagination/EmpPagination";
import { EmpQueryable } from "../../model/common/tableFilters";
import { QueryTaskCardDto } from "../../model/campaign/query-task-card.dto";
import { empDelay } from "../../utilities/delay";

interface Props {}

const BrandBrowseTaskView = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);

  const [taskCards, setTaskCards] = useState<TaskCardDto[]>([]);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const objectiveMultiFilterRef = useRef<MultiFilterOptionRef>();

  const userRoleRef = useRef<string>("");
  const statusFilterRef = useRef<string[]>(["recruiting", "ongoing"]);

  const [numRecords, setNumRecords] = useState<number>(0);
  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const query = useRef<EmpQueryable>({});

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
    sortBy: new FormControl("text", [], "sort-by-updated-desc"),
  });

  const queryTasks = useCallback(async (queryable?: QueryTaskCardDto) => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("pageNo", `${queryable?.pageNo ?? 1}`);
      searchParams.set("pageSize", `${queryable?.pageSize ?? 20}`);
      if (form.search.getValue().length > 0) {
        searchParams.set("search", form.search.getValue());
      }
      navigate(
        `${window.location.pathname}?${searchParams.toString()}${
          window.location.hash
        }`
      );
      query.current = queryable as EmpQueryable;
      const resp = await CampaignApi.queryTaskInBrand({
        status: statusFilterRef.current,
        search: form.search.getValue(),
        sortBy: form.sortBy.getValue(),
        pageNo: queryable?.pageNo ?? 1,
        pageSize: queryable?.pageSize ?? 20,
      });
      setPaginable({
        currentPage: resp.data.currentPage,
        totalRecord: resp.data.totalRecord,
        pageSize: resp.data.pageSize,
      });
      setNumRecords(resp.data.totalRecord);
      setTaskCards(resp.data.records);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch tasks");
    } finally {
      await empDelay(200);
      setLoading(false);
    }
  }, []);

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo");
    const pageSize = searchParams.get("pageSize");
    const search = searchParams.get("search");
    if (search) {
      updateFormSearch(search);
    }
    queryTasks({
      pageNo: pageNo ? Number(pageNo) : 1,
      pageSize: pageSize ? Number(pageSize) : 20,
    });
    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [queryTasks, updateFormSearch]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  return (
    <div className="mt-4">
      <div className="controls-section">
        <div className="form-control-section">
          <div className="search-textbox">
            <EmpTextInput
              labelText="Search Task"
              id={"search"}
              onKeypress={(e) => {
                if (e === "Enter") {
                  queryTasks(query.current);
                }
              }}
              formControl={form.search}
              placeholder={"Search Task Keywords"}
              leftIconComponent={SearchIcon}
            />
          </div>
          <div className="sort-by-textbox">
            <EmpSelect
              labelText="Sort"
              id={"sortBy"}
              onChange={() => {
                queryTasks();
              }}
              formControl={form.sortBy}
              placeholder={"Sort Options"}
              selectOptions={campaignSortOptions}
            />
          </div>

          <EmpMultiFilter
            ref={objectiveMultiFilterRef}
            menuItems={taskStatusOptions}
            controlLabel={"Filter Task Status"}
            label={"Task Status"}
            hasAllOption
            allByDefault
            onChange={(values: string[]) => {
              statusFilterRef.current = values;
              queryTasks();
            }}
          />
        </div>
      </div>
      <div className="cards-section-wrapper">
        {!isLoading && numRecords > 0 && (
          <span className="records-title">
            {numRecords} Task{numRecords > 1 ? "s" : ""} found
          </span>
        )}
        {!isLoading && numRecords === 0 && (
          <span className="records-title">No Tasks found</span>
        )}
        <EmpPagination
          onChange={(queryable) => queryTasks(queryable as QueryTaskCardDto)}
          pagination={paginable}
          queryable={query.current}
        />
      </div>

      <div className="task-card-section" ref={resizeDivRef}>
        {!isLoading &&
          taskCards.length > 0 &&
          taskCards.map((elem) => {
            return (
              <EmpTaskCard
                key={elem.id}
                role="brand"
                campaignRedirectUrl={`/brand/campaign-details/${elem.campaign.id}`}
                redirectUrl={`/brand/campaign-details/${elem.campaign.id}/task/${elem.id}`}
                taskCardInfo={elem}
              />
            );
          })}
        {!isLoading && taskCards.length === 0 && (
          <div className="blank-overlay">
            <div className="info-wrapper">
              <img
                className="empty-img"
                alt="No talents found"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
              />
              <h2 className="header">No Task found</h2>
              <p className="description">Please try another search query.</p>
            </div>
          </div>
        )}
        {!isLoading &&
          taskCards.length === 0 &&
          [1, 2, 3, 4].map((elem) => {
            return <EmpTaskCard key={elem} mode={"skeleton"} />;
          })}
        {isLoading &&
          [1, 2, 3, 4].map((elem) => {
            return <EmpTaskCard key={elem} mode={"skeleton"} />;
          })}
      </div>
    </div>
  );
};

export default BrandBrowseTaskView;
