import { useCallback, useEffect, useRef, useState } from "react";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import { FacebookPageDto } from "../../../model/social-media/facebook-page.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import FacebookUtils from "../facebook";
import FacebookPageSelectionModal, {
  FacebookPageSelectionModalRef,
} from "./facebook-page-selection-modal";
import "./creator-home-social-media-card.scss";
import { SmBriefRespDto } from "../../../model/social-media/sm-brief-resp.dto";
import { UnlinkBtn } from "./unlink-btn";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import SocialMediaApi from "../../../api/social-integration-msvc/social-media.api";
import TikTokApi from "../../../api/social-integration-msvc/tiktok.api";
import { TikTokLoader } from "../../../components/loaders/tiktok-loader";
import { InstagramConnectedAccountDto } from "../../../model/social-media/instagram-connected-account.dto";
import InstagramAccountSelectionModal, {
  InstagramAccountSelectionModalRef,
} from "./instagram-account-selection-modal";
import InstagramConnectInfoModal, {
  InstagramConnectInfoModalRef,
} from "./instagram-connect-info-modal";
import { FormattedMessage } from "react-intl";
import EmpInternalServerError from "../../../exception/empInternalServerError";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import {
  EMPTY_PROFILE_IMG_URL,
  SM_REDIRECT,
  SOCIAL_MEDIA_TYPE,
} from "../../../constants/app.constants";
import FormFieldUtils from "../../../utilities/form-field.util";
import XIcon from "../../../components/icon/x-icon";
import { useXLogin } from "./hooks/useXLogin";
import { XLoader } from "../../../components/loaders/x-loader";
import { empDelay } from "../../../utilities/delay";
import { useTikTokLogin } from "./hooks/useTikTokLogin";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

interface FacebookLoginAttributes {
  id: string;
  access_token: string;
}

export const CreatorHomeSocialMediaCard = () => {
  const facebookUtil = new FacebookUtils();
  const facebookPageSelectionModalRef = useRef<FacebookPageSelectionModalRef>();
  const instagramAccountSelectionModalRef =
    useRef<InstagramAccountSelectionModalRef>();
  const instagramConnectInfoModalRef = useRef<InstagramConnectInfoModalRef>();

  const { tiktokLogin, bindTikTokAccount } = useTikTokLogin();
  const { xLogin, bindXAccount } = useXLogin();

  const [socialMediaSpecs, setSocialMediaSpecs] = useState<SmBriefRespDto[]>(
    []
  );
  const [anyPlatformConnected, setAnyPlatformConnected] =
    useState<boolean>(true);
  const [smIsLoading, setSmIsLoading] = useState<"tiktok" | "x" | "none">(
    "none"
  );

  //facebook test
  useEffect(() => {
    // Fetch Facebook Metrics
    const getUserSocialMediaMetrics = async () => {
      const resp = await SocialMediaApi.getSocialMediaSpecs();
      setSocialMediaSpecs(resp.data);
      setAnyPlatformConnected(checkIfAnyPlatformsConnected(resp.data));
    };
    getUserSocialMediaMetrics();
  }, []);

  const getUserSocialMediaMetrics = useCallback(async () => {
    try {
      const resp = await SocialMediaApi.getSocialMediaSpecs();
      setSocialMediaSpecs(resp.data);
      setAnyPlatformConnected(checkIfAnyPlatformsConnected(resp.data));
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to get Social Media Metrics"
      );
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    if (tikTokCode) {
      setSmIsLoading("tiktok");
      bindTikTokAccount(
        tikTokCode,
        setSmIsLoading,
        getUserSocialMediaMetrics,
        "creator/home"
      );
    }

    const xCode = urlParams.get("x_code");
    if (xCode) {
      setSmIsLoading("x");
      bindXAccount(
        xCode,
        setSmIsLoading,
        getUserSocialMediaMetrics,
        "creator/home"
      );
    }
  }, [bindTikTokAccount, bindXAccount, getUserSocialMediaMetrics]);

  const bindPlatform = (platform: string) => {
    if (platform === "Facebook") facebookLogin();
    else if (platform === "Instagram")
      instagramConnectInfoModalRef.current?.show();
    else if (platform === "TikTok") {
      tiktokLogin(SM_REDIRECT.CREATOR_HOME);
    } else if (platform === SOCIAL_MEDIA_TYPE.X) {
      xLogin(SM_REDIRECT.CREATOR_HOME);
    }
  };

  const checkIfAnyPlatformsConnected = (
    socialMediaBriefs: SmBriefRespDto[]
  ) => {
    for (let socialMediaBrief of socialMediaBriefs) {
      if (socialMediaBrief.isAvailable) return true;
    }
    return false;
  };

  const facebookLogin = async () => {
    try {
      const response = await facebookUtil.login();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookPageResp = (await facebookUtil.getFacebookPages(
          userId
        )) as FacebookPageDto;

        if (facebookPageResp.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Your account does not have a Facebook Page"
          );
          return;
        }
        if (facebookPageResp.data.length > 0) {
          facebookPageSelectionModalRef.current?.show(
            facebookPageResp,
            "Facebook"
          );
          return;
        }
        saveFacebookLogin(facebookPageResp.data[0], "Facebook");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const instagramLogin = async () => {
    try {
      const response = await facebookUtil.instagramLogin();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookInstagramConnectedPage =
          (await facebookUtil.getInstagramConnectedAccount(
            userId
          )) as InstagramConnectedAccountDto;
        if (facebookInstagramConnectedPage.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Instagram needs to bind with a Facebook Page"
          );
          return;
        } //
        if (facebookInstagramConnectedPage.data.length > 0) {
          instagramAccountSelectionModalRef.current?.show(
            facebookInstagramConnectedPage,
            "Instagram"
          );
          return;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const saveFacebookLogin = async (
    fbPageSpec: FacebookLoginAttributes,
    platform: string
  ) => {
    try {
      const resp = await SocialMediaApi.smLogin({
        socialMediaPlatform: platform,
        accessToken: fbPageSpec.access_token,
        socialMediaUserId: fbPageSpec.id,
      });
      if (resp.data.status === "success") {
        ToastUtils.success("Success", "Succesfully binded your FB account");
        if (platform === "Facebook") {
          facebookPageSelectionModalRef.current?.dismiss();
        } else if (platform === "Instagram") {
          instagramAccountSelectionModalRef.current?.dismiss();
        }
        facebookLogout();
        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to bind Facebook Page"
      );
    }
  };

  const facebookLogout = async () => {
    await facebookUtil.logout();
  };

  const onUnlink = async (platform: string) => {
    try {
      const resp = await SocialMediaApi.unlinkSocialMedia(platform);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Successfully Unlinked",
          `Your ${platform} account has been unlinked`
        );
        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to unlink ${platform}`
      );
    }
  };

  return (
    <>
      <div
        className={`dashboard-card emp-home-social-media-card ${
          anyPlatformConnected ? "" : "empty-state"
        }`}
      >
        {smIsLoading === "tiktok" && <TikTokLoader />}
        {smIsLoading === "x" && <XLoader />}
        <FacebookPageSelectionModal
          ref={facebookPageSelectionModalRef}
          onSave={saveFacebookLogin}
          onDismiss={facebookLogout}
        />
        <InstagramAccountSelectionModal
          ref={instagramAccountSelectionModalRef}
          onSave={saveFacebookLogin}
          onDismiss={facebookLogout}
        />

        <InstagramConnectInfoModal
          ref={instagramConnectInfoModalRef}
          onSave={() => {
            instagramLogin();
          }}
        />
        {!anyPlatformConnected && (
          <EmpPill {...PILL_COLORS.red} text={"Action Required"} />
        )}
        <h3 className="card-header mt-2">
          <FormattedMessage id="bindSocialMediaStep_header" />
        </h3>
        <p className="card-description mt-2">
          <FormattedMessage id="bindSocialMediaStep_desc" />
        </p>

        {socialMediaSpecs.map((elem) => {
          return (
            <div key={elem.platform} className="platform-card mt-4">
              {/* Empty State */}
              {!elem.isAvailable && (
                <div className="platform-logo">
                  {elem.platform === "Facebook" && (
                    <FacebookIcon
                      size={28}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  )}
                  {elem.platform === "Instagram" && (
                    <InstagramIcon
                      size={28}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  )}
                  {elem.platform === "TikTok" && (
                    <TikTokIcon
                      size={28}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  )}
                  {elem.platform === "X" && (
                    <XIcon size={28} backgroundColor={Color.NEUTRAL[300]} />
                  )}
                </div>
              )}
              {!elem.isAvailable && (
                <div className="content">
                  <span className="status-lbl">
                    <FormattedMessage id="bindSocialMediaStep_notConnectedLabel" />
                  </span>
                  <span className="action-lbl">
                    <FormattedMessage
                      id="bindSocialMediaStep_platformCta"
                      values={{
                        cta: (
                          <EmpLink
                            onSubmit={() => {
                              bindPlatform(elem.platform);
                            }}
                            text={<FormattedMessage id="cta_here" />}
                          />
                        ),
                        platform: elem.platform,
                      }}
                    />
                  </span>
                </div>
              )}

              {/* Filled State */}
              {elem.isAvailable && (
                <>
                  <div className="left-elem">
                    <div className="social-media-img-wrapper">
                      <div className="social-media-logo-placeholder">
                        {elem.platform === "Facebook" && (
                          <FacebookIcon
                            size={12}
                            backgroundColor={Color.NEUTRAL[0]}
                          />
                        )}
                        {elem.platform === "Instagram" && (
                          <InstagramIcon
                            size={12}
                            backgroundColor={Color.NEUTRAL[0]}
                          />
                        )}
                        {elem.platform === "TikTok" && (
                          <TikTokIcon
                            size={12}
                            backgroundColor={Color.NEUTRAL[0]}
                          />
                        )}
                        {elem.platform === "X" && (
                          <XIcon size={12} backgroundColor={Color.NEUTRAL[0]} />
                        )}
                      </div>
                      <EmpImage
                        alt={`${elem.name} profile`}
                        className="social-media-image"
                        src={elem.pictureUrl}
                        fallback={EMPTY_PROFILE_IMG_URL}
                      />
                    </div>
                    <div className="content">
                      <span className="status-lbl">{elem.name}</span>
                      <span className="action-lbl">
                        <FormattedMessage id="bindSocialMediaStep_socialMediaFollowers" />
                        : {FormFieldUtils.toCompact(elem.followers)}
                      </span>
                    </div>
                  </div>
                  <div className="right-elem">
                    <UnlinkBtn
                      onSubmit={() => {
                        onUnlink(elem.platform);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
