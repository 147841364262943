import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";
import { useState } from "react";

const XCloseIcon = (props: IconProps) => {
  const {
    size,
    top,
    left,
    right,
    bottom,
    backgroundColor,
    hoverColor,
    hoverEnabled,
    stroke,
  } = useEmpIcon(props);

  const transitionStyle: React.CSSProperties = {
    transition: "all 200ms",
  };
  const [renderedBackgroundColor, setRenderedBackgroundColor] =
    useState<string>(backgroundColor);

  const onHover = () => {
    if (!hoverEnabled) return;
    setRenderedBackgroundColor(hoverColor);
  };

  const onHoverEnd = () => {
    if (!hoverEnabled) return;
    setRenderedBackgroundColor(backgroundColor);
  };

  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onHoverEnd}
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={transitionStyle}
          d="M18 6L6 18M6 6L18 18"
          stroke={renderedBackgroundColor}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default XCloseIcon;
