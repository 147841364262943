export const BulletedNodeElement = (props: any) => {
  return (
    <ul className="slate-ul" {...props.attributes}>
      {props.children}
    </ul>
  );
};
export const ListNodeElement = (props: any) => {
  return (
    <li {...props.attributes}>
      <div className="list-wrapper">{props.children}</div>
    </li>
  );
};
