import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { CampaignAnalyticsDetailsDto } from "../../model/campaign/campaign-analytics-details.dto";
import { FetchCampaignAnalyticsDetailsQuery } from "../../model/campaign/fetch-camapign-analytics-details.query";
import StringUtils from "../../utilities/string.util";
import { EmpInterceptor } from "../emp-interceptor";

const CampaignAnalyticsDetailsApi = {
  fetch: async (
    taskId: string,
    role: "brand" | "seller",
    query: FetchCampaignAnalyticsDetailsQuery
  ): Promise<ApiResponse<CampaignAnalyticsDetailsDto[]>> => {
    const q = query as { [key: string]: string[] | undefined };
    const builtQueryString = StringUtils.buildCommaSeparatedQueryString(q);
    console.log(builtQueryString);
    let queryString = "";
    if (builtQueryString && builtQueryString.length > 0) {
      queryString = `?${builtQueryString}`;
    }
    console.log(queryString);
    const axios = await EmpInterceptor.axiosClient();
    const url = `${ENDPOINTS.campaignMsvc.campaignAnalyticsDetails.fetchDetails(
      taskId,
      role
    )}${queryString}`;
    console.log(url);

    console.log(url);
    const response = await axios.get<
      ApiResponse<CampaignAnalyticsDetailsDto[]>
    >(url);
    return response.data;
  },
};
export default CampaignAnalyticsDetailsApi;
