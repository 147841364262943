import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { ApiStatusWithPayload } from "../../model/api/api-status-with-payload";
import { CheckVerificationStatusReqDto } from "../../model/onboarding/check-verification-status-req.dto";
import { InitialOrganisationInviteRespDto } from "../../model/organisation/initial-organisation-invite-resp.dto";
import { OrganisationUsersRespDto } from "../../model/organisation/organisation-users-resp.dto";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { OrganisationTalentInvitationStatus } from "../../model/user/organisation-talent-invitation-status.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const OrganisationApi = {
  fetchInitialDetails: async (
    id: string
  ): Promise<ApiResponse<InitialOrganisationInviteRespDto>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.get<
      ApiResponse<InitialOrganisationInviteRespDto>
    >(
      ENDPOINTS.userMicroservice.organisation.fetchInitialInvitationDetails(id)
    );
    return response.data;
  },

  fetchAllUserInOrganisation: async (): Promise<
    ApiResponse<OrganisationUsersRespDto[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<OrganisationUsersRespDto[]>>(
      ENDPOINTS.userMicroservice.organisation.getUsersInOrganisation
    );
    return response.data;
  },

  checkVerificationEmailStatus: async (
    request: CheckVerificationStatusReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.organisation.checkVerificationEmailStatus,
      request
    );
    return response.data;
  },

  fetchTalentInvitationStatus: async (): Promise<
    ApiResponse<OrganisationTalentInvitationStatus[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<OrganisationTalentInvitationStatus[]>
    >(ENDPOINTS.userMicroservice.organisation.fetchTalentInvitation);
    return response.data;
  },
  fetchAgencyCreators: async (
    platform?: string
  ): Promise<ApiResponse<SmCreatorRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    let queryParam = "";
    if (platform) {
      queryParam += `?platform=${platform}`;
    }
    const response = await axios.get<ApiResponse<SmCreatorRespDto[]>>(
      `${ENDPOINTS.userMicroservice.organisation.fetchAgencyCreators}${queryParam}`
    );
    return response.data;
  },

  fetchAgencyCreatorsByOrganisationId: async (
    organisationId: string
  ): Promise<ApiResponse<ApiStatusWithPayload<SmCreatorRespDto[] | null>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<ApiStatusWithPayload<SmCreatorRespDto[] | null>>
    >(
      `${ENDPOINTS.userMicroservice.organisation.fetchAgencyCreatorsByAgencyId(
        organisationId
      )}`
    );
    return response.data;
  },

  dismissCreator: async (
    creatorId: string,
    orgId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.organisation.dismissCreator(orgId, creatorId)
    );
    return response.data;
  },

  fetchAgencyOrgById: async (
    agencyId: string
  ): Promise<ApiResponse<OrganisationRespDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<OrganisationRespDto>>(
      ENDPOINTS.userMicroservice.organisation.fetchAgencyOrgById(agencyId)
    );
    return response.data;
  },

  grantBrandRoleToAgency: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.organisation.grantBrandRoleToAgency
    );
    return response.data;
  },

  setupOnboardedOrgMemberInvitation: async (
    invitationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.organisation.orgOnboardedMemberInvitation(
        invitationId
      )
    );
    return response.data;
  },
};
export default OrganisationApi;
