import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { TtMediaInfoDto } from "../../model/tiktok/tt-media-info-resp.dto";
import { TtMediaWithProfileDto } from "../../model/tiktok/tt-media-w-profile.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const TikTokApi = {
  auth: async (): Promise<ApiResponse<string>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<string>>(
      `${ENDPOINTS.userMicroservice.tiktok.auth}?origin=${origin}`
    );
    return response.data;
  },
  getToken: async (code: string): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      `${ENDPOINTS.userMicroservice.tiktok.getToken}?origin=${origin}`,
      { code }
    );
    return response.data;
  },
  basicProfile: async (
    talentId: string
  ): Promise<ApiResponse<TtMediaInfoDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<TtMediaInfoDto>>(
      ENDPOINTS.userMicroservice.tiktok.basicProfile(talentId)
    );
    return response.data;
  },
  getMediaById: async (
    creatorId: string,
    mediaId: string
  ): Promise<ApiResponse<TtMediaWithProfileDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<TtMediaWithProfileDto>>(
      ENDPOINTS.userMicroservice.tiktok.getMediaById(creatorId, mediaId)
    );
    return response.data;
  },
};
export default TikTokApi;
