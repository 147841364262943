import { Color } from "../utilities/colors";

export const PILL_COLORS: {
  [key: string]: { backgroundColor: string; color: string };
} = {
  primary: {
    color: Color.PRIMARY[400],
    backgroundColor: Color.hexToRgba(Color.PRIMARY[400], 0.2),
  },
  amber: {
    color: "#FF6F00",
    backgroundColor: Color.hexToRgba("#FF6F00", 0.2),
  },
  gray: {
    color: Color.NEUTRAL[150],
    backgroundColor: Color.NEUTRAL[700],
  },
  green: {
    color: Color.GREEN[300],
    backgroundColor: Color.hexToRgba(Color.GREEN[300], 0.2),
  },
  red: {
    color: Color.RED[400],
    backgroundColor: "rgba(255, 74, 79, 0.2)",
  },
};
