import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpContent from "../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import "./creator-details-page.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpRoundedPill from "../../components/shared/emp-rounded-pill/emp-rounded-pill";
import InstagramView from "../../components/views/instagram-view";
import FacebookView from "../../components/views/facebook-view";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import UserApi from "../../api/user-msvc/user.api";
import SocialMediaApi from "../../api/social-integration-msvc/social-media.api";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import { INVITATION_TYPE } from "../../constants/app.constants";
import EmpException from "../../exception/empException";
import TiktokView from "../../components/views/tiktok-view";
import EmpLink from "../../components/shared/emp-link/emp-link";
import { InviteTalentsModalRef } from "../../components/modals/invite-talents-modal";
import StringUtils from "../../utilities/string.util";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import FormFieldUtils from "../../utilities/form-field.util";

interface Props {}

export const CreatorDetailsPage = (prop: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const topLevelPath = useRef<string>();

  const { id } = useParams();
  const [creator, setCreator] = useState<UserDto>();
  const [smCreatorBrief, setSmCreatorBrief] = useState<SmBriefRespDto[]>([]);
  const [creatorAgencyInvitations, setCreatorAgencyInvitations] = useState<
    CreatorAgencyInvitationDto[]
  >([]);
  const [serviceOptions, setServiceOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [interestOptions, setInterestOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [creatorInfoCompleted, setCreatorInfoCompleted] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>();
  const inviteTalentModalRef = useRef<InviteTalentsModalRef>();
  const currAgencyOrgId = useRef<string>();

  /**
   * Fetches the creator by ID and updates the state with the response data.
   * @returns {Promise<void>} A Promise that resolves once the creator data is fetched and set.
   */
  const fetchCreatorById = useCallback(async () => {
    const resp = await UserApi.fetchCreatorById(id!);
    setCreator(resp.data);
  }, [id]);

  /**
   * Fetches the creator's social media specifications and updates the state with the response data.
   * Sets the selected platform based on the first available social media brief.
   * @returns {Promise<void>} A Promise that resolves once the social media data is fetched and set.
   */
  const fetchCreatorSocialMedia = useCallback(async () => {
    const resp = await SocialMediaApi.getSocialMediaSpecByCreatorId(id!);
    for (let smBrief of resp.data) {
      if (smBrief.isAvailable) {
        setSelectedPlatform(smBrief.platform);
        break;
      }
    }
    setSmCreatorBrief(resp.data);
  }, [id]);

  /**
   * Checks the completion status of the creator's information and updates the state accordingly.
   * Fetches the creator's information if the status is "complete" and maps the options to the state.
   * @returns {Promise<void>} A Promise that resolves once the creator info status is checked and updated.
   */
  const checkCreatorInfoStatus = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.checkCreatorInfoCompletion(id!);
      if (resp.data.statusMessage === "incomplete") {
        setCreatorInfoCompleted(false);
      } else if (resp.data.statusMessage === "complete") {
        setCreatorInfoCompleted(true);
        const creatorInfoResp = await CreatorInfoApi.fetchCreatorInfo(id!);

        // Map to options
        setServiceOptions(creatorInfoResp.data.services);
        setInterestOptions(creatorInfoResp.data.interests);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [id]);

  const getCreatorAgencies = useCallback(async () => {
    try {
      const resp = await UserApi.fetchCreatorAgenciesById(id!);
      const creatorAgencies = resp.data;
      setCreatorAgencyInvitations(creatorAgencies);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator agencies"
      );
    }
  }, [id]);

  useEffect(() => {
    topLevelPath.current = location.pathname.split("/")[1];
    fetchCreatorById();
    fetchCreatorSocialMedia();
    checkCreatorInfoStatus();
    getCreatorAgencies();
  }, [
    fetchCreatorById,
    fetchCreatorSocialMedia,
    checkCreatorInfoStatus,
    getCreatorAgencies,
    location,
  ]);

  // Setting the fragment
  useEffect(() => {
    if (smCreatorBrief.length === 0) return;
    const fragment = window.location.hash.slice(1);
    if (fragment) {
      const creatorSm = smCreatorBrief.find(
        (elem) => elem.platform === fragment
      );
      if (creatorSm) setSelectedPlatform(creatorSm.platform);
    }
  }, [smCreatorBrief]);

  /**
   * Renders platform icons based on the provided social media briefs.
   * @param {SmBriefRespDto[]} smBriefs - The social media briefs to render icons for.
   * @returns {JSX.Element} The JSX element representing the rendered platform icons.
   */
  const renderPlatformIcons = (smBriefs: SmBriefRespDto[]): JSX.Element => {
    return (
      <div className="platform-wrapper">
        {smBriefs.map((elem) => {
          return (
            <Fragment key={elem.platform}>
              {elem.platform === "Facebook" && elem.isAvailable && (
                <FacebookIcon size={16} backgroundColor={Color.NEUTRAL[500]} />
              )}
              {elem.platform === "Instagram" && elem.isAvailable && (
                <InstagramIcon size={16} backgroundColor={Color.NEUTRAL[500]} />
              )}
              {elem.platform === "TikTok" && elem.isAvailable && (
                <TikTokIcon size={16} backgroundColor={Color.NEUTRAL[500]} />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const socialMediaOnClick = (platform: string) => {
    setSelectedPlatform(platform);
  };

  const recommendPartnershipStatus = (context: string) => {
    if (context === INVITATION_TYPE.AFFILIATE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeAffiliateOption",
      });
    else if (context === INVITATION_TYPE.EXCLUSIVE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeExclusiveOption",
      });
    throw new EmpException("Invalid invitation type");
  };

  return (
    <div className="emp-page-wrapper no-padding">
      <div className="emp-page-content no-padding emp-creator-details-page">
        <div className="content">
          {creator && (
            <div className="profile-section">
              <div className="emp-card no-padding">
                <div className="banner"></div>
                <div className="pic-section">
                  {creator.imageType === "url" && (
                    <img
                      className="profile-pic"
                      srcSet={creator.imageResource}
                      alt="Creator"
                    />
                  )}
                  {creator.imageType === "avatar" && (
                    <div
                      className="avatar"
                      style={{ background: creator.imageResource }}
                    >
                      <span>{creator.initials}</span>
                    </div>
                  )}
                  <span className="creator-name-lbl mt-2">
                    {creator.fullName}
                  </span>
                </div>
                <div className="info-section mb-8">
                  <EmpContent
                    label={
                      <FormattedMessage id="creatorDetailPage_profileCountryLabel" />
                    }
                    value={
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[creator.creator.country][
                              "flag_1x1"
                            ]
                          }
                        />
                        <span>
                          {COUNTRY_CONSTANTS[creator.creator.country].name}
                        </span>
                      </div>
                    }
                  />

                  <div className="creator-info-wrapper mt-4">
                    <div className="creator-gender">
                      <EmpContent
                        label={
                          <FormattedMessage id="creatorDetailPage_profileGenderLabel" />
                        }
                        value={TranslationUtil.translateGender(
                          intl,
                          creator.creator.gender
                        )}
                      />
                    </div>
                    <div className="creator-age">
                      <EmpContent
                        label={
                          <FormattedMessage id="creatorDetailPage_profileAgeLabel" />
                        }
                        value={creator.creator.age.toString()}
                      />
                    </div>
                  </div>

                  {!creatorInfoCompleted && (
                    <>
                      <EmpContent
                        className="mt-4"
                        label={
                          <FormattedMessage id="creatorDetailPage_profileInterestsLabel" />
                        }
                        value={"No Categories"}
                      />
                      <EmpContent
                        className="mt-4"
                        label={
                          <FormattedMessage id="creatorDetailPage_profileServicesAvailableLabel" />
                        }
                        value={"Not specified"}
                      />
                    </>
                  )}

                  {creatorInfoCompleted && (
                    <>
                      <EmpContent
                        className="mt-4"
                        label={
                          <FormattedMessage id="creatorDetailPage_profileInterestsLabel" />
                        }
                        value={
                          <div className="pills-wrapper">
                            {interestOptions.map((elem) => {
                              return (
                                <EmpRoundedPill
                                  key={elem.id}
                                  text={TranslationUtil.translateCreatorInterest(
                                    intl,
                                    elem.id,
                                    elem.label
                                  )}
                                  clickable
                                />
                              );
                            })}
                          </div>
                        }
                      />
                      <EmpContent
                        className="mt-4"
                        label={
                          <FormattedMessage id="creatorDetailPage_profileServicesAvailableLabel" />
                        }
                        value={
                          <div className="pills-wrapper">
                            {serviceOptions.map((elem) => {
                              return (
                                <EmpRoundedPill
                                  key={elem.id}
                                  text={TranslationUtil.translateCreatorService(
                                    intl,
                                    elem.id,
                                    elem.label
                                  )}
                                  clickable
                                />
                              );
                            })}
                          </div>
                        }
                      />
                    </>
                  )}

                  {creatorAgencyInvitations && (
                    <>
                      {creatorAgencyInvitations.length === 0 && (
                        <EmpContent
                          className="mt-4"
                          label={
                            <FormattedMessage id="creatorDetailPage_profileRepresentativeLabel" />
                          }
                          value="Freelance Creator"
                        />
                      )}
                      {creatorAgencyInvitations.length > 0 && (
                        <EmpContent
                          className="mt-4"
                          label={
                            <FormattedMessage id="creatorDetailPage_profileRepresentativeLabel" />
                          }
                          value={
                            <>
                              <div className="agency-wrapper">
                                {creatorAgencyInvitations.map((elem) => {
                                  return (
                                    <Fragment key={elem.id}>
                                      <div className="agency-card">
                                        <img
                                          className="agency-logo"
                                          srcSet={elem.agency.logo}
                                          alt={`${elem.agency.companyName} logo`}
                                        />
                                        <div className="agency-content">
                                          <div className="name-wrapper">
                                            <EmpLink
                                              onSubmit={async () => {
                                                if (!topLevelPath.current)
                                                  return;

                                                if (
                                                  topLevelPath.current ===
                                                  "agency"
                                                ) {
                                                  if (
                                                    elem.agency.id ===
                                                    currAgencyOrgId.current
                                                  ) {
                                                    navigate("/agency/profile");
                                                    return;
                                                  }
                                                  navigate(
                                                    `/agency/profile/${elem.agency.id}`
                                                  );
                                                } else if (
                                                  topLevelPath.current ===
                                                  "creator"
                                                ) {
                                                  navigate(
                                                    `/creator/agency/${elem.agency.id}`
                                                  );
                                                }
                                              }}
                                              text={elem.agency.companyName}
                                            />
                                          </div>
                                          <div className="mt-1">
                                            <EmpPill
                                              backgroundColor={
                                                Color.NEUTRAL[150]
                                              }
                                              color={Color.NEUTRAL[700]}
                                              text={recommendPartnershipStatus(
                                                elem.invitationType
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </>
                          }
                        />
                      )}
                    </>
                  )}
                  {smCreatorBrief && (
                    <EmpContent
                      className="mt-4"
                      label={
                        <FormattedMessage id="creatorDetailPage_profilePlatformLabel" />
                      }
                      value={renderPlatformIcons(smCreatorBrief)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="social-media-section">
            {/* Social Media Selector */}
            <div className="emp-card no-padding social-media-selector-card">
              {smCreatorBrief.map((elem, index) => {
                return (
                  <Fragment key={elem.platform}>
                    {elem.isAvailable && (
                      <div
                        onClick={() => {
                          socialMediaOnClick(elem.platform);
                        }}
                        className={`social-media-option ${elem.platform === selectedPlatform ? "active" : ""}`}
                      >
                        <div className={`picture-section`}>
                          <img
                            src={elem.pictureUrl}
                            alt={`${elem.name} ${elem.platform} dp`}
                          />
                          <div className="social-media-bubble">
                            {elem.platform === "Facebook" && (
                              <FacebookIcon
                                backgroundColor={Color.NEUTRAL[0]}
                                size={12}
                              />
                            )}
                            {elem.platform === "Instagram" && (
                              <InstagramIcon
                                backgroundColor={Color.NEUTRAL[0]}
                                size={12}
                              />
                            )}
                            {elem.platform === "TikTok" && (
                              <TikTokIcon
                                backgroundColor={Color.NEUTRAL[0]}
                                size={12}
                              />
                            )}
                          </div>
                        </div>
                        <div className="info-section">
                          <span className="handle-name-lbl">{elem.name}</span>
                          <span className="metric-lbl">
                            <FormattedMessage id="creatorDetailPage_socialMediaFollowers" />
                            : {FormFieldUtils.toCompact(elem.followers)}
                          </span>
                        </div>
                      </div>
                    )}
                    {!elem.isAvailable && (
                      <div key={elem.platform} className={`empty-option`}>
                        <div className={`picture-section`}>
                          <div className="placeholder">
                            {elem.platform === "Facebook" && (
                              <FacebookIcon
                                backgroundColor={Color.NEUTRAL[250]}
                                size={20}
                              />
                            )}
                            {elem.platform === "Instagram" && (
                              <InstagramIcon
                                backgroundColor={Color.NEUTRAL[250]}
                                size={20}
                              />
                            )}
                            {elem.platform === "TikTok" && (
                              <TikTokIcon
                                backgroundColor={Color.NEUTRAL[250]}
                                size={20}
                              />
                            )}
                          </div>
                        </div>
                        <div className="info-section">
                          <span className="handle-name-lbl">
                            {elem.platform}
                          </span>
                          <span className="metric-lbl">
                            <FormattedMessage id="creatorDetailPage_socialMediaNotConnected" />
                          </span>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
            {selectedPlatform === "Facebook" && <FacebookView id={id!} />}
            {selectedPlatform === "Instagram" && <InstagramView id={id!} />}
            {selectedPlatform === "TikTok" && <TiktokView id={id!} />}
          </div>
        </div>
      </div>
    </div>
  );
};
