import { useMemo } from "react";

import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto"; // Adjust based on actual path and type
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import XIcon from "../../../components/icon/x-icon";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import { Color } from "../../../utilities/colors";
import maxConnectedImg from "../../../assets/images/delete-image.png";
import useUser from "../../../hooks/useUser";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";

interface UseSocialMediaConnectionProps {
  connects: SmConnectionBriefRespDto[];
  bindPlatform: (platform: string) => void;
  onUnlink: (connect: SmConnectionBriefRespDto) => void;
}

const platformConnectionArr = [
  {
    platform: "Facebook",
    icon: <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={20} />,
  },
  {
    platform: "Instagram",
    icon: <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={20} />,
  },
  {
    platform: "TikTok",
    icon: <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={20} />,
  },
  {
    platform: "X",
    icon: <XIcon backgroundColor={Color.NEUTRAL[0]} size={20} />,
  },
];

export function useSocialMediaConnection({
  connects,
  bindPlatform,
  onUnlink,
}: UseSocialMediaConnectionProps): {
  renderCurrentConnected: JSX.Element;
  renderNewConnection: JSX.Element;
} {
  const { user } = useUser();

  const renderCurrentConnected = useMemo(() => {
    if (connects.length === 0) {
      return (
        <div className="max-connected-card-section">
          <span className="description">No Connections Found</span>
        </div>
      );
    }
    return (
      <div className="profile-card-section">
        {connects.map((connect, index: number) => (
          <div className="profile-card-wrapper" key={index}>
            <div className="profile-wrapper">
              <div className={`picture-section`}>
                <img
                  src={connect?.pictureUrl || EMPTY_PROFILE_IMG_URL}
                  alt={`${connect?.name} ${connect?.platform} dp`}
                />
                <div
                  className={`social-media-bubble ${connect?.platform.toLowerCase()}`}
                >
                  {connect?.platform.toLowerCase() === "facebook" && (
                    <FacebookIcon
                      backgroundColor={Color.NEUTRAL[0]}
                      size={12}
                    />
                  )}
                  {connect?.platform.toLowerCase() === "instagram" && (
                    <InstagramIcon
                      backgroundColor={Color.NEUTRAL[0]}
                      size={12}
                    />
                  )}
                  {connect?.platform.toLowerCase() === "tiktok" && (
                    <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                  )}
                  {connect?.platform.toLowerCase() === "x" && (
                    <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                  )}
                </div>
              </div>
              <div className="info-section">
                <span className="handle-name-lbl">{connect?.name}</span>
                <span className="metric-lbl">
                  {connect?.followers} Followers
                </span>
              </div>
            </div>
            <EmpButton
              onSubmit={() => onUnlink(connect)}
              isFullWidth={false}
              text={"Disconnect"}
              buttonHeight="md"
            />
          </div>
        ))}
      </div>
    );
  }, [connects, onUnlink]);

  const renderNewConnection = useMemo(() => {
    if (!user?.subscription?.hasSubscription) {
      return (
        <div className="max-connected-card-section">
          <img alt="Max Account Connected" src={maxConnectedImg} />
          <span className="header">Feature Locked!</span>
          <span className="description">
            To connect social media accounts, please upgrade your free plan.
          </span>
          <EmpButton
            isFullWidth={false}
            className="mt-3"
            text={"View Plans"}
            onSubmit={() => {
              PubSub.publish(PUB_SUB_TOPICS.VIEW_PLANS);
            }}
          />
        </div>
      );
    }
    if (connects?.length < 5)
      return (
        <div className="profile-card-section">
          {platformConnectionArr.map((connect: any, index: number) => (
            <div className="profile-card-wrapper" key={index}>
              <div className="profile-wrapper">
                <div
                  className={`platform-icon-wrapper ${connect?.platform.toLowerCase()}`}
                >
                  {connect?.icon}
                </div>
                <div className="info-section">
                  <span className="handle-name-lbl">
                    Connect to {connect?.platform}
                  </span>
                </div>
              </div>
              <EmpButton
                onSubmit={() => bindPlatform(connect?.platform)}
                isFullWidth={false}
                text={"Connect"}
                buttonHeight="md"
              />
            </div>
          ))}
        </div>
      );
    return (
      <div className="max-connected-card-section">
        <img alt="Max Account Connected" src={maxConnectedImg} />
        <span className="header">Max Account Connected</span>
        <span className="description">
          Please unlink a social media account before connecting to a new one
        </span>
      </div>
    );
  }, [bindPlatform, connects?.length, user]);

  return { renderCurrentConnected, renderNewConnection };
}
