import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { TaskDto } from "../../../model/campaign/task.dto";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "../task-deliverable-modal";
import "../invite-task-modal.scss";
import { TaskRepresentativeDto } from "../../../model/user/creator-task-rep.dto";
import { OrganisationRespDto } from "../../../model/user/organisation-resp.dto";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import ChevronLeftIcon from "../../icon/chevron-left";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import { CreateTaskInvitationDto } from "../../../model/campaign/create-task-invitation.dto";
import ToastUtils from "../../../utilities/toast-utils";
import { UserDto } from "../../../model/user-management/user.dto";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

interface Props {
  task: TaskDto;
  taskRepresentatives: TaskRepresentativeDto[];
  brandOrg: OrganisationRespDto;
  creator: UserDto;
  onVariedOfferViewClick: () => void;
  onBackClick: () => void;
  onComplete: () => void;
}

const TaskInvitationOfferView = (props: Props) => {
  const { task, brandOrg, creator, taskRepresentatives, onComplete } = props;

  const submitBtnRef = useRef<EmpButtonRef>();
  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();
  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const [form, setForm] = useState<IFormGroup>({
    amount: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const onSubmit = async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");
      const formIsValid = FormGroupUtil.validate(form);

      if (!formIsValid) return;
      const invitationNegotiations = taskRepresentatives.map((elem) => {
        return {
          agencyOrgId:
            elem.representativeRole === "agency" ? elem.agency.id : undefined,
          representativeId:
            elem.representativeRole === "agency"
              ? elem.agency.id
              : elem.creator.id,
          representativeRole: elem.representativeRole,
          amount: form.amount.getValue(),
        };
      });
      // Construct the object
      const request: CreateTaskInvitationDto = {
        brandOrgId: brandOrg.id,
        creatorUserId: creator.id,
        invitationNegotiations: invitationNegotiations,
        campaignId: task.campaign.id,
        taskId: task.id,
        remarks: form.remarks.getValue(),
      };
      const resp = await NegotiationApi.createNegotiations(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Invitation Sent",
          "We have sent invitations to the respective representatives"
        );
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when sending invitations"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div>
      <div className="view-header-wrapper">
        <div
          className="back-btn with-text"
          onClick={() => {
            props.onBackClick();
          }}
        >
          <ChevronLeftIcon backgroundColor={Color.NEUTRAL[500]} />
          <span className="btn-text">Back</span>
        </div>
      </div>
      <TaskDeliverableModal ref={taskDeliverableModalRef} />
      <section className="profle-photo-section">
        <div className="profile-photo-wrapper">
          <img
            alt="Profile"
            className="profile-photo org-photo"
            src={brandOrg.logo}
          />
          <span className="plus-label">+</span>
          <div className="creator-rep-wrapper">
            {taskRepresentatives.map((elem, index) => {
              return (
                <div className={`${index > 0 ? "overlap" : ""}`}>
                  {elem.representativeType === "user" && (
                    <>
                      {elem.creator.imageType === "url" && (
                        <img
                          alt="Profile"
                          className="profile-photo"
                          src={elem.creator.imageResource}
                        />
                      )}
                      {elem.creator.imageType === "none" && (
                        <img
                          alt="Empty profile"
                          src={
                            "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                          }
                        />
                      )}
                      {elem.creator.imageType === "avatar" && (
                        <div
                          style={{ background: elem.creator.imageResource }}
                          className="avatar"
                        >
                          <span className="initials">
                            {elem.creator.initials}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {elem.representativeType === "organisation" && (
                    <>
                      <img
                        alt="Campaign brand"
                        className="profile-photo org-photo"
                        src={elem.agency.logo}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div className="content-section">
        <motion.span
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 1 * animationDelay,
          }}
          className="task-name-lbl"
        >
          Invite Creator{taskRepresentatives.length > 1 ? "s" : ""} to join{" "}
          {task.name}
        </motion.span>

        <motion.p
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
          className="description mt-4"
        >
          If the creator/agency is agreeable with the offer, they will be set to
          participate in this campaign task.
        </motion.p>

        <motion.div
          variants={fadeInVariants}
          className="mt-4"
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
        >
          <EmpTextInput
            id={"rate"}
            labelText="Your Rate"
            leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
            description="Propose a fair compensation by consulting the creator's rate cards (if available), or by evaluating their social media engagement metrics. "
            required
            formControl={form.amount}
            placeholder={"00.00"}
          />
        </motion.div>

        <motion.div
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          className="mt-3"
          transition={{
            duration: animationDuration,
            delay: 3 * animationDelay,
          }}
        >
          <EmpTextInput
            id={"remarks"}
            labelText="Remarks (Optional)"
            multiline
            textAreaAdaptiveHeight
            tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
            formControl={form.remarks}
            rows={2}
            characterCount={300}
            placeholder={
              "You may elaborate more about the scope of work, or any price justifications"
            }
          />
        </motion.div>
      </div>
      <motion.div
        variants={fadeInVariants}
        initial="hidden"
        animate={"visible"}
        style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}
        transition={{ duration: animationDuration, delay: 4 * animationDelay }}
      >
        {taskRepresentatives.length > 1 && (
          <EmpButton
            isFullWidth={false}
            onSubmit={() => {
              props.onVariedOfferViewClick();
            }}
            buttonStyle="secondary"
            className="mt-4"
            text={"Set Varied Rates"}
          />
        )}

        <EmpButton
          ref={submitBtnRef}
          isFullWidth={false}
          onSubmit={onSubmit}
          className="mt-4"
          text={`Send Invite${taskRepresentatives.length > 1 ? "s" : ""}`}
        />
      </motion.div>
    </div>
  );
};
export default TaskInvitationOfferView;
