import { motion } from "framer-motion";
import { PurpleGridBackground } from "./purple-grid-background";

const pulseVariant = {
  pulse: {
    // y: [0, 10, 0],
    scale: [0.9, 1, 0.9],
    transition: {
      duration: 2,
      ease: "linear",
      repeat: Infinity,
    },
  },
};

const coinVariant = {
  move: {
    x: [-200, 0],
    transition: {
      duration: 0.4,
      ease: "linear",
      repeat: Infinity,
    },
  },
  move1: {
    x: [-200, 0],
    transition: {
      duration: 0.5,
      ease: "linear",
      repeat: Infinity,
      delay: 0.4,
    },
  },
  move2: {
    x: [-200, 0],
    transition: {
      duration: 0.3,
      ease: "linear",
      repeat: Infinity,
      delay: 0.2,
    },
  },
};

const coinVariantReversed = {
  move: {
    x: [200, 0],
    transition: {
      duration: 0.5,
      ease: "easeOut",
      repeat: Infinity,
    },
  },
  move1: {
    x: [200, 0],
    transition: {
      duration: 0.5,
      ease: "linear",
      repeat: Infinity,
      delay: 0.4,
    },
  },
  move2: {
    x: [200, 0],
    transition: {
      duration: 0.3,
      ease: "linear",
      repeat: Infinity,
      delay: 0.2,
    },
  },
};

export const ManageWalletAnimation = () => {
  return (
    <motion.div
      className="emp-manage-wallet-animation"
    >
      <PurpleGridBackground />
      <div className="abs-section">
        <div className="left-section">
          <motion.img
            variants={coinVariant}
            initial="move"
            animate="move"
            className="coin first-coin"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
          <motion.img
            variants={coinVariant}
            initial="move1"
            animate="move1"
            className="coin second-coin"
            transition={{ delay: 0.3 }}
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
          <motion.img
            variants={coinVariant}
            initial="move2"
            animate="move2"
            className="coin third-coin"
            transition={{ delay: 0.4 }}
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
        </div>
        <div className="right-section">
          <motion.img
            variants={coinVariantReversed}
            initial="move1"
            animate="move1"
            className="coin first-coin"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
          <motion.img
            variants={coinVariantReversed}
            initial="move2"
            animate="move2"
            className="coin second-coin"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
          <motion.img
            variants={coinVariantReversed}
            initial="move"
            animate="move"
            className="coin third-coin"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-coin.png"
            alt="coin"
          />
        </div>
      </div>
      <motion.img
        variants={pulseVariant}
        animate="pulse"
        className="wallet-img"
        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/purple-wallet.png"
        alt="happy wallet"
      />
    </motion.div>
  );
};
