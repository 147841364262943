import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./creator-settings-payment-view.scss";
import EmpTable, {
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { FormattedMessage, useIntl } from "react-intl";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpManageWalletModal, {
  EmpManageWalletModalRef,
} from "../../../components/modals/manage-wallet-modal";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import useWalletAddressManager, {
  WalletInformation,
} from "../../../hooks/useWalletAddressManager";

export const CreatorSettingsPaymentView = () => {
  const intl = useIntl();
  const { fetchWalletInformation } = useWalletAddressManager();
  const manageWalletModalRef = useRef<EmpManageWalletModalRef>();
  const paymentContentSpec: EmpTableContentSpec<any>[] = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: "settingsPaymentView_transactionIdHeader",
        }),
        dataIndex: "talentName",
      },
      {
        title: intl.formatMessage({ id: "settingsPaymentView_dateHeader" }),
        dataIndex: "date",
      },
      {
        title: intl.formatMessage({ id: "settingsPaymentView_amountHeader" }),
        dataIndex: "amount",
      },
      {
        title: intl.formatMessage({ id: "settingsPaymentView_statusHeader" }),
        dataIndex: "status",
      },
      {
        title: intl.formatMessage({
          id: "settingsPaymentView_descriptionHeader",
        }),
        dataIndex: "description",
      },
      {
        title: intl.formatMessage({ id: "settingsPaymentView_senderHeader" }),
        dataIndex: "senderName",
      },
      {
        title: intl.formatMessage({ id: "settingsPaymentView_actionHeader" }),
        dataIndex: "action",
      },
    ];
  }, [intl]);
  const [walletInfo, setWalletInfo] = useState<WalletInformation | null>();

  const fetchWalletInfo = useCallback(async () => {
    const resp = await fetchWalletInformation();
    setWalletInfo(resp);
  }, [fetchWalletInformation]);

  useEffect(() => {
    fetchWalletInfo();
  }, [fetchWalletInfo]);
  return (
    <div className="emp-creator-settings-payment-view">
      <EmpManageWalletModal
        ref={manageWalletModalRef}
        onSave={fetchWalletInfo}
      />
      <div className="view-header-wrapper">
        <div className="text-wrapper">
          <div className="text-status-wrapper">
            <h1 className="section-header">
              <FormattedMessage id="settingsPaymentView_header" />
            </h1>
            {walletInfo && walletInfo.hasWallet && (
              <EmpPill size="sm" {...PILL_COLORS.green} text={"Wallet Added"} />
            )}
            {walletInfo && !walletInfo.hasWallet && (
              <EmpPill
                size="sm"
                {...PILL_COLORS.amber}
                text={"No Wallet Added"}
              />
            )}
          </div>
          <p className="emp-paragraph">
            <FormattedMessage id="settingsPaymentView_desc" />
          </p>
        </div>
      </div>
      {walletInfo && (
        <div className="card-wrapper">
          <div className="svg-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 800 800"
              opacity="0.96"
            >
              <defs>
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad"
                >
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="100%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-2"
                >
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="0%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  gradientTransform="rotate(270)"
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-3"
                >
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="100%"
                  ></stop>
                </linearGradient>
                <linearGradient
                  gradientTransform="rotate(270)"
                  x1="50%"
                  y1="0%"
                  x2="50%"
                  y2="100%"
                  id="rrreflection-grad-4"
                >
                  <stop
                    stopColor="hsl(205, 69%, 80%)"
                    stopOpacity="1"
                    offset="0%"
                  ></stop>
                  <stop
                    stopColor="hsl(205, 69%, 60%)"
                    stopOpacity="1"
                    offset="45%"
                  ></stop>
                </linearGradient>
              </defs>
              <g strokeWidth="1.5" stroke="url(#rrreflection-grad)" fill="none">
                <circle r="351" cx="50%" cy="0"></circle>
                <circle r="338" cx="50%" cy="0"></circle>
                <circle r="325" cx="50%" cy="0"></circle>
                <circle r="312" cx="50%" cy="0"></circle>
                <circle r="299" cx="50%" cy="0"></circle>
                <circle r="286" cx="50%" cy="0"></circle>
                <circle r="273" cx="50%" cy="0"></circle>
                <circle r="260" cx="50%" cy="0"></circle>
                <circle r="247" cx="50%" cy="0"></circle>
                <circle r="234" cx="50%" cy="0"></circle>
                <circle r="221" cx="50%" cy="0"></circle>
                <circle r="208" cx="50%" cy="0"></circle>
                <circle r="195" cx="50%" cy="0"></circle>
                <circle r="182" cx="50%" cy="0"></circle>
                <circle r="169" cx="50%" cy="0"></circle>
                <circle r="156" cx="50%" cy="0"></circle>
                <circle r="143" cx="50%" cy="0"></circle>
                <circle r="130" cx="50%" cy="0"></circle>
                <circle r="117" cx="50%" cy="0"></circle>
                <circle r="104" cx="50%" cy="0"></circle>
                <circle r="91" cx="50%" cy="0"></circle>
                <circle r="78" cx="50%" cy="0"></circle>
                <circle r="65" cx="50%" cy="0"></circle>
                <circle r="52" cx="50%" cy="0"></circle>
                <circle r="39" cx="50%" cy="0"></circle>
                <circle r="26" cx="50%" cy="0"></circle>
                <circle r="13" cx="50%" cy="0"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-2)"
                fill="none"
              >
                <circle r="351" cx="50%" cy="100%"></circle>
                <circle r="338" cx="50%" cy="100%"></circle>
                <circle r="325" cx="50%" cy="100%"></circle>
                <circle r="312" cx="50%" cy="100%"></circle>
                <circle r="299" cx="50%" cy="100%"></circle>
                <circle r="286" cx="50%" cy="100%"></circle>
                <circle r="273" cx="50%" cy="100%"></circle>
                <circle r="260" cx="50%" cy="100%"></circle>
                <circle r="247" cx="50%" cy="100%"></circle>
                <circle r="234" cx="50%" cy="100%"></circle>
                <circle r="221" cx="50%" cy="100%"></circle>
                <circle r="208" cx="50%" cy="100%"></circle>
                <circle r="195" cx="50%" cy="100%"></circle>
                <circle r="182" cx="50%" cy="100%"></circle>
                <circle r="169" cx="50%" cy="100%"></circle>
                <circle r="156" cx="50%" cy="100%"></circle>
                <circle r="143" cx="50%" cy="100%"></circle>
                <circle r="130" cx="50%" cy="100%"></circle>
                <circle r="117" cx="50%" cy="100%"></circle>
                <circle r="104" cx="50%" cy="100%"></circle>
                <circle r="91" cx="50%" cy="100%"></circle>
                <circle r="78" cx="50%" cy="100%"></circle>
                <circle r="65" cx="50%" cy="100%"></circle>
                <circle r="52" cx="50%" cy="100%"></circle>
                <circle r="39" cx="50%" cy="100%"></circle>
                <circle r="26" cx="50%" cy="100%"></circle>
                <circle r="13" cx="50%" cy="100%"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-3)"
                fill="none"
              >
                <circle r="351" cx="0" cy="50%"></circle>
                <circle r="338" cx="0" cy="50%"></circle>
                <circle r="325" cx="0" cy="50%"></circle>
                <circle r="312" cx="0" cy="50%"></circle>
                <circle r="299" cx="0" cy="50%"></circle>
                <circle r="286" cx="0" cy="50%"></circle>
                <circle r="273" cx="0" cy="50%"></circle>
                <circle r="260" cx="0" cy="50%"></circle>
                <circle r="247" cx="0" cy="50%"></circle>
                <circle r="234" cx="0" cy="50%"></circle>
                <circle r="221" cx="0" cy="50%"></circle>
                <circle r="208" cx="0" cy="50%"></circle>
                <circle r="195" cx="0" cy="50%"></circle>
                <circle r="182" cx="0" cy="50%"></circle>
                <circle r="169" cx="0" cy="50%"></circle>
                <circle r="156" cx="0" cy="50%"></circle>
                <circle r="143" cx="0" cy="50%"></circle>
                <circle r="130" cx="0" cy="50%"></circle>
                <circle r="117" cx="0" cy="50%"></circle>
                <circle r="104" cx="0" cy="50%"></circle>
                <circle r="91" cx="0" cy="50%"></circle>
                <circle r="78" cx="0" cy="50%"></circle>
                <circle r="65" cx="0" cy="50%"></circle>
                <circle r="52" cx="0" cy="50%"></circle>
                <circle r="39" cx="0" cy="50%"></circle>
                <circle r="26" cx="0" cy="50%"></circle>
                <circle r="13" cx="0" cy="50%"></circle>
              </g>
              <g
                strokeWidth="1.5"
                stroke="url(#rrreflection-grad-4)"
                fill="none"
              >
                <circle r="351" cx="100%" cy="50%"></circle>
                <circle r="338" cx="100%" cy="50%"></circle>
                <circle r="325" cx="100%" cy="50%"></circle>
                <circle r="312" cx="100%" cy="50%"></circle>
                <circle r="299" cx="100%" cy="50%"></circle>
                <circle r="286" cx="100%" cy="50%"></circle>
                <circle r="273" cx="100%" cy="50%"></circle>
                <circle r="260" cx="100%" cy="50%"></circle>
                <circle r="247" cx="100%" cy="50%"></circle>
                <circle r="234" cx="100%" cy="50%"></circle>
                <circle r="221" cx="100%" cy="50%"></circle>
                <circle r="208" cx="100%" cy="50%"></circle>
                <circle r="195" cx="100%" cy="50%"></circle>
                <circle r="182" cx="100%" cy="50%"></circle>
                <circle r="169" cx="100%" cy="50%"></circle>
                <circle r="156" cx="100%" cy="50%"></circle>
                <circle r="143" cx="100%" cy="50%"></circle>
                <circle r="130" cx="100%" cy="50%"></circle>
                <circle r="117" cx="100%" cy="50%"></circle>
                <circle r="104" cx="100%" cy="50%"></circle>
                <circle r="91" cx="100%" cy="50%"></circle>
                <circle r="78" cx="100%" cy="50%"></circle>
                <circle r="65" cx="100%" cy="50%"></circle>
                <circle r="52" cx="100%" cy="50%"></circle>
                <circle r="39" cx="100%" cy="50%"></circle>
                <circle r="26" cx="100%" cy="50%"></circle>
                <circle r="13" cx="100%" cy="50%"></circle>
              </g>
            </svg>
          </div>
          <div className="payout-wrapper">
            <div className="bank-name-wrapper">
              {walletInfo.hasWallet && (
                <EmpImage
                  fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/bank.png"
                  className="bank-logo"
                  alt={"Crypto Chain"}
                  src={walletInfo.wallet!.blockchainNetwork.icon}
                />
              )}
              <h2 className="bank-name-header">
                {" "}
                {walletInfo.hasWallet
                  ? walletInfo.wallet!.blockchainNetwork.displayName
                  : "Payout Wallet"}
              </h2>
            </div>
            <span className="bank-account-number mt-2">
              {walletInfo.hasWallet
                ? walletInfo.wallet!.walletAddress
                : "Please add your payout wallet"}
            </span>
          </div>
          <EmpButton
            text={walletInfo.hasWallet ? "Edit" : "Add Wallet"}
            isFullWidth={false}
            onSubmit={() => {
              manageWalletModalRef.current?.show(walletInfo);
            }}
          />
        </div>
      )}
      <div className="view-header-wrapper mt-6">
        <h1 className="section-header">
          <FormattedMessage id="settingsPaymentView_recentTransactionHeader" />
        </h1>
      </div>
      <div className="card-wrapper">
        <div className="emp-card no-padding">
          <EmpTable
            contentColumns={paymentContentSpec}
            data={[]}
            emptyStateDescription={
              <FormattedMessage id="settingsPaymentView_tableEmptyDesc" />
            }
            emptyStateHeader={
              <FormattedMessage id="settingsPaymentView_tableEmptyHeader" />
            }
            rowKey={"id"}
          />
        </div>
      </div>
    </div>
  );
};
