import "./emp-kebab-menu.scss";
import VerticalDotsIcon from "../../icon/vertical-dots-icon";
import { Color } from "../../../utilities/colors";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ReactKeyUtil } from "../../../utilities/react-key.util";

interface MenuButtonSpec<T> {
  action: (record: T) => void;
  context: T;
  label: string;
  style?: "default" | "danger";
}
interface Props<T> {
  menuButtonSpecs: MenuButtonSpec<T>[];
}
const MENU_ITEM_HEIGHT = 30;
const BUTTON_WIDTH = 30;
const BUTTON_HEIGHT = 30;
const GUTTER_WIDTH = 10;
const MAX_MENU_ITEMS = 8;

const buttonStyle: React.CSSProperties = {
  width: BUTTON_WIDTH,
  height: BUTTON_HEIGHT,
};

const menuButtonStyle: React.CSSProperties = {
  height: MENU_ITEM_HEIGHT,
};
const EmpKebabMenu = <T extends {}>(props: Props<T>) => {
  const { menuButtonSpecs } = props;
  const hiddenMenu = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const isSelectedRef = useRef(false);
  const [isSelected, setSelected] = useState(false);

  const [menuWrapperStyle, setMenuWrapperStyle] = useState<React.CSSProperties>(
    {}
  );
  const [placementStyle, setPlacementStyle] = useState<React.CSSProperties>({});

  const handleClickOutside = (event: MouseEvent) => {
    // This function is called when the user clicks outside of the menu,
    // and is used to close the menu.
    if (!isSelectedRef.current) return;
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setSelected(false);
    }
  };

  /**
   * This function is called when the user clicks on a menu item.
   * It should navigate to the link specified in the spec.
   * @param spec
   */
  const menuItemOnClick = (spec: MenuButtonSpec<T>) => {
    spec.action(spec.context);
    setSelected(false);
  };

  // This function is called when the component is mounted and whenever the
  // isSelected prop changes.
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    // Calculating the width of the dropdown menu
    const width = hiddenMenu.current?.clientWidth;
    setMenuWrapperStyle({
      width: width,
      maxHeight: MENU_ITEM_HEIGHT * MAX_MENU_ITEMS,
    });
    setPlacementStyle({
      top: 0,
      left: -width! - GUTTER_WIDTH,
    });
  }, []);

  const iconOnClick = () => {
    if (!isSelected) {
      setSelected(true);
    }
  };

  return (
    <>
      <div
        ref={menuRef}
        onClick={() => iconOnClick()}
        className="emp-kebab-menu"
      >
        <button className="emp-kebab-button" style={buttonStyle}>
          <VerticalDotsIcon backgroundColor={Color.NEUTRAL[400]} />
        </button>

        {isSelected && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className="emp-kebab-menu-wrapper"
            style={{ ...menuWrapperStyle, ...placementStyle }}
          >
            {ReactKeyUtil.addReactKey(menuButtonSpecs).map((elem) => {
              return (
                <div
                  onClick={() => menuItemOnClick(elem)}
                  key={elem.reactKey}
                  className={`emp-kebab-menu-btn ${elem.style ? elem.style : "default"}`}
                  style={menuButtonStyle}
                >
                  {elem.label}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>

      {/* Hidden Menu Item to Compute Width */}
      <div ref={hiddenMenu} className="emp-kebab-menu-wrapper hidden">
        {ReactKeyUtil.addReactKey(menuButtonSpecs).map((elem) => {
          return (
            <div
              key={elem.reactKey}
              className="emp-kebab-menu-btn"
              style={menuButtonStyle}
            >
              {elem.label}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EmpKebabMenu;
