import "./custom-rate-card-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import EmpButton from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import { RateCardRespDto } from "../../model/social-media/rate-card-resp.dto";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import UsdIcon from "../../components/icon/usd-icon";
import RandomUtils from "../../utilities/random.util";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { FormattedMessage, useIntl } from "react-intl";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";

export interface CustomRateCardModalRef {
  show: (context: "add-on" | "rate", parentId?: number) => void;
  hide: () => void;
}

interface Props {
  onSave: (rateCardDto: RateCardRespDto, context: "add-on" | "rate") => void;
}

const CustomRateCardModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const contextRef = useRef<"rate" | "add-on">("rate");
  const parentIdRef = useRef<number>();
  const intl = useIntl();

  const numericRegex = /^\d+(\.\d+)?$/;
  const only2DecimalPlaceRegex = /^\d+(\.\d{1,10})?$/;

  // Form is set to empty at first
  const [form, setForm] = useState<IFormGroup>({
    rateName: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_rateNameRequired" })
      ),
      new LengthValidator(
        0,
        50,
        undefined,
        intl.formatMessage(
          { id: "validation_rateNameMaxLength" },
          { char: "50" }
        )
      ),
    ]),
    price: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_priceRequired" })
      ),
      new NumericRangeValidator(
        0,
        50000,
        "Budget must be more than zero",
        "Rate must not exceed 40,000"
      ),
      new PatternValidator(
        numericRegex,
        intl.formatMessage({ id: "validation_numericPatternValidator" })
      ),
      new PatternValidator(
        only2DecimalPlaceRegex,
        intl.formatMessage(
          { id: "validation_numericDecimalsPatternValidator" },
          { point: "2" }
        )
      ),
    ]),
    description: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_descriptionRequired" })
      ),
      new LengthValidator(
        0,
        80,
        undefined,
        intl.formatMessage(
          { id: "validation_descriptionMaxLength" },
          { char: "80" }
        )
      ),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (context: "add-on" | "rate", parentId?: number) => {
    contextRef.current = context;
    if (context === "add-on") {
      if (parentId === undefined)
        throw new Error("No parentId supplied for add-on rate");
      parentIdRef.current = parentId;
    }
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  const addCustomRateCard = () => {
    try {
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const randomNumber =
        Math.floor(RandomUtils.secureRandom() * (9000000 - 1000000 + 1)) +
        1000000;
      const rateCard: RateCardRespDto = {
        rateId: randomNumber,
        origin: "Custom",
        rateName: form.rateName.getValue(),
        description: form.description.getValue(),
        addOnRates: [],
        price: Number(form.price.getValue()),
      };

      if (contextRef.current === "add-on") {
        rateCard.parentId = parentIdRef.current;
      }
      props.onSave(rateCard, contextRef.current);
      dismiss();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-custom-rate-card-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="addCustomRateModal_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="addCustomRateModal_desc" />
          </p>
        </div>
        <div className="custom-rate-card-section mt-4 ph-5">
          <div className="rate-name-section">
            <EmpTextInput
              placeholder={intl.formatMessage({
                id: "addCustomRateModal_rateNameInputPlaceholder",
              })}
              id={"rateName"}
              labelText={intl.formatMessage({
                id: "addCustomRateModal_rateNameInputLabel",
              })}
              required
              formControl={form.rateName}
            />
          </div>
          <div className="price-section">
            <EmpTextInput
              leftIconComponent={UsdIcon}
              leftIconSize={11}
              labelText={intl.formatMessage({
                id: "addCustomRateModal_priceInputLabel",
              })}
              id={"price"}
              required
              placeholder={"00.00"}
              formControl={form.price}
            />
          </div>
        </div>
        <div className="ph-5">
          <EmpTextInput
            placeholder={intl.formatMessage({
              id: "addCustomRateModal_descriptionInputPlaceholder",
            })}
            id={"description"}
            labelText={intl.formatMessage({
              id: "addCustomRateModal_descriptionInputLabel",
            })}
            required
            multiline
            characterCount={80}
            rows={2}
            textAreaAdaptiveHeight
            formControl={form.description}
          />
        </div>

        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <div className="custom-rate-btn-wrapper">
            <EmpButton
              buttonStyle={"secondary"}
              text={<FormattedMessage id="cta_cancel" />}
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>

          <div className="custom-rate-btn-wrapper">
            <EmpButton
              text={
                <FormattedMessage id="addCustomRateModal_addCustomRateBtn" />
              }
              onSubmit={() => {
                addCustomRateCard();
              }}
            />
          </div>
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CustomRateCardModal;
