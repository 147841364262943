import { motion } from "framer-motion";
import {
  SavedWalletInformation,
  WalletInformation,
} from "../../../hooks/useWalletAddressManager";
import EmpButton from "../../shared/emp-button/emp-button";
import { PurpleGridBackground } from "./purple-grid-background";
import ConfettiExplosion from "react-confetti-explosion";

interface Props {
  savedWalletInformation: SavedWalletInformation;
  onDismiss: () => void;
}

const pulseVariant = {
  pulse: {
    y: [0, 10, 0],
    transition: {
      duration: 2,
      ease: "linear",
      repeat: Infinity,
    },
  },
};
export const ManageWalletCompletedView = (props: Props) => {
  const { savedWalletInformation, onDismiss } = props;

  return (
    <div className="completed-view">
      <section className="banner-section">
        <ConfettiExplosion
          force={0.4}
          duration={2200}
          particleCount={30}
          width={400}
          zIndex={100}
        />
        <motion.img
          variants={pulseVariant}
          animate="pulse"
          className="token-img"
          alt="Saved token"
          src={savedWalletInformation.blockchainNetwork.icon}
        />

        <PurpleGridBackground />
      </section>
      <section className="success-section">
        <h2 className="heading">Wallet Address Updated!</h2>
        <p className="emp-paragraph mt-3">
          Completed Tasks will be paid out to this wallet.
        </p>
        <EmpButton
          className="mt-4"
          text={"Continue!"}
          onSubmit={onDismiss}
          isFullWidth={false}
        />
      </section>
    </div>
  );
};
