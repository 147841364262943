import React, { useEffect, useState } from "react";
import { CheckboxOption } from "../../../model/common/checkboxOption";
import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import EmpCheckbox from "../emp-checkbox/emp-checkbox";
import "./emp-checkbox-group.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  labelText?: string;
  initialValue?: string[];
  description?: string;
  required?: boolean;
  disableControl?: boolean;

  // Form Integration
  id: string;
  formControl: FormControl;
  checkboxOptions: CheckboxOption[];
  formValidation?: () => void;

  // Callbacks
  onChange?: (formControl: FormControl) => void;
}
const EmpCheckboxGroup = (props: Props) => {
  const {
    onChange,
    required,
    style,
    description,
    className,
    id,
    labelText,
    initialValue,
    formControl,
    checkboxOptions,
  } = props;
  // const valueList = useRef<Set<string>>(initialValue ? new Set<string>(initialValue) : new Set<string>())
  const disableControl = props.disableControl ?? false;
  const [valueList, setValueList] = useState<Set<string>>(
    initialValue ? new Set<string>(initialValue) : new Set<string>()
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const isRequired = required ? required : false;

  useEffect(() => {
    const formValue = formControl.getValue() as string[];
    if (formValue.length === 0 || !formValue) return;

    // Setting defualt values for checkbox
    setValueList(new Set<string>(formValue));
    // valueList.current = new Set<string>(formValue);
  }, [formControl]);

  useEffect(() => {
    const formValue = formControl.getValue() as string[];
    if (!formValue) return;

    // Setting defualt values for checkbox
    // valueList.current = new Set<string>(formValue);
    setValueList(new Set<string>(formValue));
  }, [formControl.resetFlag]);

  const handleOnChange = (value: boolean, id: string) => {
    // Remove from list if its id already exists,
    if (value) {
      valueList.add(id);
    } else {
      valueList.delete(id);
    }
    setValueList(new Set(valueList));
    formControl.setValue(Array.from(valueList));
    if (onChange) onChange(formControl);
  };

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  return (
    <div className={`emp-checkbox-group-wrapper ${className}`} style={style}>
      <label className="block" htmlFor={id}>
        {labelText} {isRequired && <span className="required">*</span>}
      </label>
      {description && (
        <p>
          How would you describe your business or area of interest? Select all
          that apply.
        </p>
      )}
      {checkboxOptions.map((elem) => {
        return (
          <EmpCheckbox
            disableControl={disableControl}
            onChange={(value) => {
              handleOnChange(value, elem.value);
            }}
            initialValue={valueList.has(elem.value)}
            key={elem.value}
            id={elem.value}
            labelText={elem.label}
          />
        );
      })}
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[400]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
export default EmpCheckboxGroup;
