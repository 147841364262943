import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  SubscriptionCancellationModal,
  SubscriptionCancellationModalRef,
} from "../../../components/modals/subscription-cancellation-modal";
import {
  SubscriptionChangeModal,
  SubscriptionChangeModalRef,
} from "../../../components/modals/subscription-change-modal";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import { AppContext } from "../../../context/app.context";
import { ProductDto } from "../../../model/payment/product/product.dto";
import { SubscriptionDto } from "../../../model/subscription/subscription.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import UserUtils from "../../../utilities/user-utils";
import "./subscription-view.scss";

import visaImg from "../../../assets/images/payment/visa.svg";
import mastercardImg from "../../../assets/images/payment/mastercard.svg";
import jcbImage from "../../../assets/images/payment/jcb.svg";
import americanExpressImg from "../../../assets/images/payment/american_express.svg";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import { useLocation } from "react-router-dom";
import EmpTable, {
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import { useIntl } from "react-intl";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import { InvoiceRecordDto } from "../../../model/subscription/invoice-record.dto";
import moment from "moment";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import EmpKebabMenu from "../../../components/shared/emp-kebab-menu/emp-kebab-menu";

export const SubscriptionView = () => {
  const intl = useIntl();

  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();
  useState<SubscriptionDto>();

  const subscriptionCancellationModalRef =
    useRef<SubscriptionCancellationModalRef>();
  const subscriptionChangeModalRef = useRef<SubscriptionChangeModalRef>();
  const updatePaymentMethodBtnRef = useRef<EmpButtonRef>();
  const invoiceContentSpec: EmpTableContentSpec<InvoiceRecordDto>[] =
    useMemo(() => {
      const generateInvoiceName = (
        periodStart: string,
        periodEnd: string,
        periodUnit: string
      ) => {
        if (periodUnit === "MONTH") {
          const startMonth = moment(periodStart).format("MMM");
          const endMonth = moment(periodEnd).format("MMM");
          return `Usage from ${startMonth} till ${endMonth}`;
        }
        return "Unplanned Scenario";
      };

      return [
        {
          title: "Invoice",
          dataIndex: "invoiceDto.id",
          render: (record: InvoiceRecordDto) => {
            return (
              <>
                {generateInvoiceName(
                  record.invoiceDto.createdAt,
                  record.invoiceDto.periodEndAt,
                  record.price.periodUnit
                )}
              </>
            );
          },
        },
        {
          title: "Item",
          dataIndex: "invoiceDto.paidAt",
          render: (record: InvoiceRecordDto) => {
            return <>{record.price.name}</>;
          },
        },
        {
          title: "Payment Date",
          dataIndex: "paidAt",
          render: (record: InvoiceRecordDto) => {
            return (
              <>{DateUtil.toReadableDateWithTime(record.invoiceDto.paidAt)}</>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (record: InvoiceRecordDto) => {
            return (
              <EmpPill
                text={record.invoiceDto.status}
                {...PILL_COLORS.primary}
              />
            );
          },
        },
        {
          title: "Total Amount",
          dataIndex: "totalAmount",
          render: (record: InvoiceRecordDto) => {
            return (
              <>${FormFieldUtils.toMoney(record.invoiceDto.totalAmount)} USD</>
            );
          },
        },
      ];
    }, [intl]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  const recommendCardImg = (brand: string) => {
    if (brand === "visa") return visaImg;
    else if (brand === "mastercard") return mastercardImg;
    else if (brand === "american express") return americanExpressImg;
    else if (brand === "jcb") return jcbImage;
  };

  return (
    <div className="emp-subscription-view">
      <SubscriptionCancellationModal
        ref={subscriptionCancellationModalRef}
        onSave={() => {}}
      />
      <SubscriptionChangeModal
        ref={subscriptionChangeModalRef}
        onSave={() => {}}
      />

      <div className="view-header-wrapper">
        <div>
          <h1>Manage Subscription</h1>
          <p className="page-description">
            Customize, update, and adjust your plan, payments, and features in
            one place. Seamlessly upgrade, downgrade, or modify your
            subscription to fit your changing needs.
          </p>
        </div>
      </div>

      {user && (
        <div className="card-wrapper">
          <div className="split-cards-wrapper">
            <>
              <div className="emp-card card">
                <div className="card-header-wrapper">
                  <h2>Current Plan</h2>
                  <EmpKebabMenu
                    menuButtonSpecs={[
                      {
                        label: "Change Plan",
                        context: {},
                        action: () => {
                          return;
                        },
                      },
                      {
                        label: "Cancel Plan",
                        context: {},
                        style: "danger",
                        action: () => {
                          subscriptionCancellationModalRef.current?.show();
                        },
                      },
                    ]}
                  />
                </div>
                <span>Blub Blub Plan Name</span>
                <div className="details-wrapper mt-4">
                  <EmpContent label={"AMOUNT"} value={`0.5 Eth`} />
                  <EmpContent
                    label={"NEXT BILLING DATE"}
                    value={"1 Jan 2050"}
                  />
                </div>
              </div>

              <div className="emp-card card billing-card">
                <div className="svg-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 800 800"
                    opacity="0.96"
                  >
                    <defs>
                      <linearGradient
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="rrreflection-grad"
                      >
                        <stop
                          stopColor="hsl(205, 69%, 60%)"
                          stopOpacity="1"
                          offset="45%"
                        ></stop>
                        <stop
                          stopColor="hsl(205, 69%, 80%)"
                          stopOpacity="1"
                          offset="100%"
                        ></stop>
                      </linearGradient>
                      <linearGradient
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="rrreflection-grad-2"
                      >
                        <stop
                          stopColor="hsl(205, 69%, 80%)"
                          stopOpacity="1"
                          offset="0%"
                        ></stop>
                        <stop
                          stopColor="hsl(205, 69%, 60%)"
                          stopOpacity="1"
                          offset="45%"
                        ></stop>
                      </linearGradient>
                      <linearGradient
                        gradientTransform="rotate(270)"
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="rrreflection-grad-3"
                      >
                        <stop
                          stopColor="hsl(205, 69%, 60%)"
                          stopOpacity="1"
                          offset="45%"
                        ></stop>
                        <stop
                          stopColor="hsl(205, 69%, 80%)"
                          stopOpacity="1"
                          offset="100%"
                        ></stop>
                      </linearGradient>
                      <linearGradient
                        gradientTransform="rotate(270)"
                        x1="50%"
                        y1="0%"
                        x2="50%"
                        y2="100%"
                        id="rrreflection-grad-4"
                      >
                        <stop
                          stopColor="hsl(205, 69%, 80%)"
                          stopOpacity="1"
                          offset="0%"
                        ></stop>
                        <stop
                          stopColor="hsl(205, 69%, 60%)"
                          stopOpacity="1"
                          offset="45%"
                        ></stop>
                      </linearGradient>
                    </defs>
                    <g
                      strokeWidth="1.5"
                      stroke="url(#rrreflection-grad)"
                      fill="none"
                    >
                      <circle r="351" cx="50%" cy="0"></circle>
                      <circle r="338" cx="50%" cy="0"></circle>
                      <circle r="325" cx="50%" cy="0"></circle>
                      <circle r="312" cx="50%" cy="0"></circle>
                      <circle r="299" cx="50%" cy="0"></circle>
                      <circle r="286" cx="50%" cy="0"></circle>
                      <circle r="273" cx="50%" cy="0"></circle>
                      <circle r="260" cx="50%" cy="0"></circle>
                      <circle r="247" cx="50%" cy="0"></circle>
                      <circle r="234" cx="50%" cy="0"></circle>
                      <circle r="221" cx="50%" cy="0"></circle>
                      <circle r="208" cx="50%" cy="0"></circle>
                      <circle r="195" cx="50%" cy="0"></circle>
                      <circle r="182" cx="50%" cy="0"></circle>
                      <circle r="169" cx="50%" cy="0"></circle>
                      <circle r="156" cx="50%" cy="0"></circle>
                      <circle r="143" cx="50%" cy="0"></circle>
                      <circle r="130" cx="50%" cy="0"></circle>
                      <circle r="117" cx="50%" cy="0"></circle>
                      <circle r="104" cx="50%" cy="0"></circle>
                      <circle r="91" cx="50%" cy="0"></circle>
                      <circle r="78" cx="50%" cy="0"></circle>
                      <circle r="65" cx="50%" cy="0"></circle>
                      <circle r="52" cx="50%" cy="0"></circle>
                      <circle r="39" cx="50%" cy="0"></circle>
                      <circle r="26" cx="50%" cy="0"></circle>
                      <circle r="13" cx="50%" cy="0"></circle>
                    </g>
                    <g
                      strokeWidth="1.5"
                      stroke="url(#rrreflection-grad-2)"
                      fill="none"
                    >
                      <circle r="351" cx="50%" cy="100%"></circle>
                      <circle r="338" cx="50%" cy="100%"></circle>
                      <circle r="325" cx="50%" cy="100%"></circle>
                      <circle r="312" cx="50%" cy="100%"></circle>
                      <circle r="299" cx="50%" cy="100%"></circle>
                      <circle r="286" cx="50%" cy="100%"></circle>
                      <circle r="273" cx="50%" cy="100%"></circle>
                      <circle r="260" cx="50%" cy="100%"></circle>
                      <circle r="247" cx="50%" cy="100%"></circle>
                      <circle r="234" cx="50%" cy="100%"></circle>
                      <circle r="221" cx="50%" cy="100%"></circle>
                      <circle r="208" cx="50%" cy="100%"></circle>
                      <circle r="195" cx="50%" cy="100%"></circle>
                      <circle r="182" cx="50%" cy="100%"></circle>
                      <circle r="169" cx="50%" cy="100%"></circle>
                      <circle r="156" cx="50%" cy="100%"></circle>
                      <circle r="143" cx="50%" cy="100%"></circle>
                      <circle r="130" cx="50%" cy="100%"></circle>
                      <circle r="117" cx="50%" cy="100%"></circle>
                      <circle r="104" cx="50%" cy="100%"></circle>
                      <circle r="91" cx="50%" cy="100%"></circle>
                      <circle r="78" cx="50%" cy="100%"></circle>
                      <circle r="65" cx="50%" cy="100%"></circle>
                      <circle r="52" cx="50%" cy="100%"></circle>
                      <circle r="39" cx="50%" cy="100%"></circle>
                      <circle r="26" cx="50%" cy="100%"></circle>
                      <circle r="13" cx="50%" cy="100%"></circle>
                    </g>
                    <g
                      strokeWidth="1.5"
                      stroke="url(#rrreflection-grad-3)"
                      fill="none"
                    >
                      <circle r="351" cx="0" cy="50%"></circle>
                      <circle r="338" cx="0" cy="50%"></circle>
                      <circle r="325" cx="0" cy="50%"></circle>
                      <circle r="312" cx="0" cy="50%"></circle>
                      <circle r="299" cx="0" cy="50%"></circle>
                      <circle r="286" cx="0" cy="50%"></circle>
                      <circle r="273" cx="0" cy="50%"></circle>
                      <circle r="260" cx="0" cy="50%"></circle>
                      <circle r="247" cx="0" cy="50%"></circle>
                      <circle r="234" cx="0" cy="50%"></circle>
                      <circle r="221" cx="0" cy="50%"></circle>
                      <circle r="208" cx="0" cy="50%"></circle>
                      <circle r="195" cx="0" cy="50%"></circle>
                      <circle r="182" cx="0" cy="50%"></circle>
                      <circle r="169" cx="0" cy="50%"></circle>
                      <circle r="156" cx="0" cy="50%"></circle>
                      <circle r="143" cx="0" cy="50%"></circle>
                      <circle r="130" cx="0" cy="50%"></circle>
                      <circle r="117" cx="0" cy="50%"></circle>
                      <circle r="104" cx="0" cy="50%"></circle>
                      <circle r="91" cx="0" cy="50%"></circle>
                      <circle r="78" cx="0" cy="50%"></circle>
                      <circle r="65" cx="0" cy="50%"></circle>
                      <circle r="52" cx="0" cy="50%"></circle>
                      <circle r="39" cx="0" cy="50%"></circle>
                      <circle r="26" cx="0" cy="50%"></circle>
                      <circle r="13" cx="0" cy="50%"></circle>
                    </g>
                    <g
                      strokeWidth="1.5"
                      stroke="url(#rrreflection-grad-4)"
                      fill="none"
                    >
                      <circle r="351" cx="100%" cy="50%"></circle>
                      <circle r="338" cx="100%" cy="50%"></circle>
                      <circle r="325" cx="100%" cy="50%"></circle>
                      <circle r="312" cx="100%" cy="50%"></circle>
                      <circle r="299" cx="100%" cy="50%"></circle>
                      <circle r="286" cx="100%" cy="50%"></circle>
                      <circle r="273" cx="100%" cy="50%"></circle>
                      <circle r="260" cx="100%" cy="50%"></circle>
                      <circle r="247" cx="100%" cy="50%"></circle>
                      <circle r="234" cx="100%" cy="50%"></circle>
                      <circle r="221" cx="100%" cy="50%"></circle>
                      <circle r="208" cx="100%" cy="50%"></circle>
                      <circle r="195" cx="100%" cy="50%"></circle>
                      <circle r="182" cx="100%" cy="50%"></circle>
                      <circle r="169" cx="100%" cy="50%"></circle>
                      <circle r="156" cx="100%" cy="50%"></circle>
                      <circle r="143" cx="100%" cy="50%"></circle>
                      <circle r="130" cx="100%" cy="50%"></circle>
                      <circle r="117" cx="100%" cy="50%"></circle>
                      <circle r="104" cx="100%" cy="50%"></circle>
                      <circle r="91" cx="100%" cy="50%"></circle>
                      <circle r="78" cx="100%" cy="50%"></circle>
                      <circle r="65" cx="100%" cy="50%"></circle>
                      <circle r="52" cx="100%" cy="50%"></circle>
                      <circle r="39" cx="100%" cy="50%"></circle>
                      <circle r="26" cx="100%" cy="50%"></circle>
                      <circle r="13" cx="100%" cy="50%"></circle>
                    </g>
                  </svg>
                </div>
                <div className="card-header-wrapper">
                  <h2>Payment Method</h2>
                  <EmpButton
                    ref={updatePaymentMethodBtnRef}
                    onSubmit={() => {
                      return;
                    }}
                    isFullWidth={false}
                    buttonHeight="sm"
                    buttonStyle="secondary"
                    text={"Update"}
                  />
                </div>

                <div className="card-number-wrapper mt-4">
                  <span className="card-number-lbl">
                    **** **** **** {"1234"}
                  </span>
                  <EmpImage
                    className="card-brand-height"
                    src={recommendCardImg("visa")}
                    alt={"Visa Card"}
                    fallback={EMPTY_PROFILE_IMG_URL}
                  />
                </div>
                <span className="name-lbl">Tan Tock Seng</span>
              </div>
            </>
          </div>
        </div>
      )}
      <section className="invoice-section mt-10">
        <h2>Invoices</h2>
        <p className="emp-paragraph mt-2">
          Find your subscription payment information in the table provided. For
          additional assistance, your customer success officer can be contacted{" "}
          <EmpLink text={"here"} />
        </p>
        <div className="emp-card no-padding mt-3">
          <EmpTable
            shimmerLoading={{
              width: [100, 140, 80, 50, 50],
              overlayDesign: (
                <>
                  <EmpLoaderV2 isLoading={true} />
                </>
              ),
            }}
            loading={false}
            showEmptyState={true}
            contentColumns={invoiceContentSpec}
            data={[]}
            rowKey={"id"}
          />
        </div>
      </section>
    </div>
  );
};
