import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import { SubscriptionDto } from "../../model/subscription/subscription.dto";
import { Color } from "../../utilities/colors";
import "./subscription-change-modal.scss";
import { ProductDto } from "../../model/payment/product/product.dto";

export interface SubscriptionChangeModalRef {
  show: (subscription: SubscriptionDto, product: ProductDto) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

export const SubscriptionChangeModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductDto>();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const subscriptionRef = useRef<SubscriptionDto>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (subscription: SubscriptionDto) => {
    subscriptionRef.current = subscription;
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    setErrorMessage(undefined);
    props.onSave();
  };

  const anyPriceIsSelected: boolean = useMemo(() => {
    return Boolean(selectedPlan && selectedPlan !== "");
  }, [selectedPlan]);

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-subscription-change-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <h2 className="title">Change Subscription Plan</h2>
          <section className="mt-4">
            {!isLoading &&
              products &&
              products.prices.map((price) => {
                return (
                  <button
                    onClick={() => {
                      setSelectedPlan(price.id);
                    }}
                    key={price.id}
                    className={`emp-anchor-reset plan-selection-card mt-2 ${
                      price.id === selectedPlan ? "selected" : ""
                    }`}
                  >
                    <div className="plan-details-wrapper">
                      <div
                        className={`knob ${
                          price.id === selectedPlan ? "selected" : ""
                        }`}
                      >
                        <div
                          className={`knob-ellipsis ${
                            price.id === selectedPlan ? "selected" : ""
                          }`}
                        ></div>
                      </div>
                      <div className="plan-name-wrapper">
                        <span className="plan-name-lbl">{price.name}</span>
                      </div>

                      <span className="plan-price-lbl">
                        $
                        {(price.unitAmount / price.recurring.period).toFixed(2)}
                        USD/Month
                      </span>
                    </div>
                  </button>
                );
              })}
            {isLoading &&
              [0, 1].map((plan) => {
                return (
                  <div
                    key={plan}
                    className="plan-selection-card-skeleton mt-2 emp-shimmer"
                  ></div>
                );
              })}
          </section>
          {errorMessage && (
            <div className="error-message-wrapper mt-4">
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            disabled={!anyPriceIsSelected}
            isFullWidth={false}
            text={"Confirm Change"}
            onSubmit={() => {}}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
