import { TaskDto } from "../../model/campaign/task.dto";
import { Color } from "../../utilities/colors";
import ChevronDownIcon from "../icon/chevron-down";
import FacebookIcon from "../icon/facebook-icon";
import InstagramIcon from "../icon/instagram-icon";
import TikTokIcon from "../icon/tiktok-icon";
import XIcon from "../icon/x-icon";
import EmpButton from "../shared/emp-button/emp-button";
import EmpContent from "../shared/emp-content/emp-content";
import "./campaign-sm-update-status.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XProofOfWorkApi from "../../api/campaign-msvc/x-proof-of-work.api";
import useClickOutside from "../../hooks/useClickOutside";
import ChevronUpIcon from "../icon/chevron-up";
import XCloseIcon from "../icon/x-close-icon";
import { SmMetricsRefreshStatus } from "../../model/campaign/sm-metrics-refresh-status.dto";
import { SOCIAL_MEDIA_TYPE } from "../../constants/app.constants";
import { DateUtil } from "../../utilities/date";

interface Props {
  task: TaskDto;
  role: "brand" | "seller";
}
const CampaignSmUpdateStatus = (props: Props) => {
  const { task, role } = props;

  const [pollingModeActivated, setPollingModeActivated] = useState(false);

  const [metricsStatusList, setMetricsStatusList] =
    useState<SmMetricsRefreshStatus[]>();

  const getMetricsStatus = useCallback(async () => {
    try {
      const resp = await XProofOfWorkApi.getStatus(task.id, role);
      const msl = resp.data;
      setMetricsStatusList(msl);

      const refreshingStatusExists = msl?.some(
        (elem) => elem.status === "refreshing"
      );
      setPollingModeActivated(refreshingStatusExists);
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error fetching metrics status"
      );
    }
  }, [role, task]);

  const renderPlatformIcon = useCallback((icon: string) => {
    switch (icon) {
      case SOCIAL_MEDIA_TYPE.FACEBOOK:
        return FacebookIcon;
      case SOCIAL_MEDIA_TYPE.INSTAGRAM:
        return InstagramIcon;
      case SOCIAL_MEDIA_TYPE.X:
        return XIcon;
      case SOCIAL_MEDIA_TYPE.TIKTOK:
        return TikTokIcon;
    }
    return XCloseIcon;
  }, []);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (pollingModeActivated) {
      console.log("polling mode activated");
      intervalIdRef.current = setInterval(() => {
        getMetricsStatus();
      }, 15000);
    } else {
      console.log("polling mode deactivated");
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [pollingModeActivated, getMetricsStatus]);

  useEffect(() => {
    getMetricsStatus();
  }, [getMetricsStatus]);
  return (
    <>
      {metricsStatusList && metricsStatusList.length > 0 && (
        <EmpContent
          label={"LAST UPDATED"}
          value={
            <div className="sm-pills-wrapper">
              {metricsStatusList.map((elem) => {
                return (
                  <StatusPill
                    key={elem.platform}
                    onRefresh={() => {
                      getMetricsStatus();
                    }}
                    taskId={task.id}
                    role={role}
                    Icon={renderPlatformIcon(elem.platform)}
                    time={elem.lastRefresh}
                    status={elem.status}
                    statusClass={
                      !elem.lastRefresh ||
                      (elem.diffInMilliseconds ?? 0) < 1800000
                        ? "success"
                        : "warn"
                    }
                    dropdownSpecs={{
                      title: `Update ${elem.platform} Metrics`,
                      refreshesRemaining: elem.refreshesLeft,
                      platform: elem.platform,
                    }}
                    hasDropdown={elem.platform === SOCIAL_MEDIA_TYPE.X}
                  />
                );
              })}
            </div>
          }
        />
      )}
    </>
  );
};
export default CampaignSmUpdateStatus;

interface StatusPillProps {
  role: "brand" | "seller";
  taskId: string;
  Icon: React.ComponentType<{ size: number; backgroundColor: string }>;
  time: string;
  statusClass: string;
  status: string;
  dropdownSpecs: {
    title: string;
    refreshesRemaining: number;
    platform: string;
  };
  hasDropdown: boolean;
  onRefresh: () => void;
}

const StatusPill: React.FC<StatusPillProps> = ({
  taskId,
  role,
  Icon,
  time,
  status,
  statusClass,
  dropdownSpecs,
  hasDropdown,
  onRefresh,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const pillRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const timeLabel = useMemo(() => {
    if (!time) return "Just now";
    else {
      return DateUtil.toPeriod(time);
    }
  }, [time]);

  useClickOutside([pillRef, dropdownMenuRef], () => {
    if (dropdownSpecs) {
      if (isDropdownVisible) setDropdownVisible(false);
    }
  });

  const refreshMetrics = useCallback(async () => {
    try {
      setLoading(true);
      await XProofOfWorkApi.refreshMetrics(taskId, role);
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error refrshing metrics"
      );
    } finally {
      setLoading(false);
      onRefresh();
    }
  }, [role, taskId, onRefresh]);

  return (
    <div className="pill-wrapper">
      <button
        ref={pillRef}
        className={`emp-button-reset pill ${
          hasDropdown ? "has-dropdown clickable" : ""
        }`}
        onClick={() => {
          if (dropdownSpecs) setDropdownVisible(true);
        }}
      >
        <div className="status-section">
          {status === "refreshing" && (
            <div className="emp-spinner medium"></div>
          )}
          <Icon size={16} backgroundColor={Color.NEUTRAL[300]} />
          {timeLabel}
          {status === "available" && (
            <div className={`status ${statusClass}`}></div>
          )}
          {status === "refreshing" && <div className={`status warn`}></div>}
        </div>
        {hasDropdown && (
          <>
            <div className="dropdown-section">
              {isDropdownVisible ? (
                <ChevronUpIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              ) : (
                <ChevronDownIcon
                  size={14}
                  backgroundColor={Color.NEUTRAL[300]}
                />
              )}
            </div>
          </>
        )}
      </button>
      {isDropdownVisible && hasDropdown && (
        <div className="dropdown-menu" ref={dropdownMenuRef}>
          <button
            className="emp-button-reset close-btn"
            onClick={() => {
              setDropdownVisible(false);
            }}
          >
            <XCloseIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
          </button>
          <div className="padded-section bordered">
            <h2 className="mb-2">
              {status === "available" && <>{dropdownSpecs.title}</>}
              {status === "refreshing" && <>Refresh in Progress</>}
            </h2>
          </div>
          {isLoading && (
            <div className="emp-progress">
              <div className="indeterminate"></div>
            </div>
          )}
          <div className="padded-section mt-2 bordered">
            <p className="emp-paragraph mb-2">
              {status === "available" && (
                <>
                  {dropdownSpecs.refreshesRemaining > 0 && (
                    <>
                      {dropdownSpecs.refreshesRemaining} Refresh
                      {dropdownSpecs.refreshesRemaining > 1 ? "es" : ""}{" "}
                      Remaining
                    </>
                  )}
                  {dropdownSpecs.refreshesRemaining === 0 && (
                    <>No Refreshes Remaining</>
                  )}
                </>
              )}
              {status === "refreshing" && (
                <>
                  Updates may take up to 3 minutes. Refresh the page to verify
                  if it’s finished.
                </>
              )}
            </p>
          </div>
          <div className="padded-section mt-2">
            {status === "refreshing" && (
              <EmpButton
                buttonHeight="sm"
                buttonStyle="secondary"
                className="mt-2"
                onSubmit={() => {
                  setDropdownVisible(false);
                }}
                text={"Got it!"}
              />
            )}
            {status === "available" && dropdownSpecs.refreshesRemaining > 0 && (
              <EmpButton
                state={isLoading ? "loading" : "default"}
                onSubmit={refreshMetrics}
                buttonHeight="sm"
                className="mt-2"
                text={"Refresh"}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
