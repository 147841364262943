import { FILE_TYPE } from "../constants/app.constants";
import heic2any from "heic2any";
import { AxiosInstance } from "axios";
import { EmpFileChunk } from "../model/common/file-chunk";
import { ApiResponse } from "../model/api/api-response";
import { ApiStatus } from "../model/api/api-status";
import { v4 } from "uuid";
import JSZip from "jszip";

const FileUtils = {
  /**
   * This function reads a file as a base64 string.
   * @param file - The file to be read.
   * @returns A promise that resolves with a base64 string.
   */
  async readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64);
      };
    });
  },

  /**
   * Checks if the file size is greater than a specified MB.
   * @param file - The file to check the size of.
   * @param fileSizeLimit - The file size indicated in Mb
   * @returns A boolean indicating whether the file size is smaller than 'n' MB
   */
  isFileSizeSmallerThanMB(file: File, fileSizeLimit: number): boolean {
    const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB
    return fileSizeInMB < fileSizeLimit;
  },

  /**
   * Converts a byte size to a human-readable format.
   * @param bytes - The byte size to convert.
   * @returns The human-readable size string (e.g., "1.23 MB").
   */
  convertBytesToReadableSize(bytes: number): string {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "0 Bytes";
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const formattedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
    return `${formattedSize} ${sizes[i]}`;
  },

  /**
   * Determine the type of a given File object (image, video, or file) based on its MIME type.
   *
   * @param {File} file - The File object to determine the type for.
   * @returns {typeof FILE_TYPE[keyof typeof FILE_TYPE]} The type of the file: "image", "video", or "file".
   */
  getFileType(file: File): (typeof FILE_TYPE)[keyof typeof FILE_TYPE] {
    if (file.type.includes("image")) {
      return FILE_TYPE.IMAGE;
    } else if (file.type.includes("video")) {
      return FILE_TYPE.VIDEO;
    } else {
      return FILE_TYPE.FILE;
    }
  },

  /**
   * Extracts the file type (extension) from a given URL.
   *
   * @param {string} url - The URL from which to extract the file type.
   * @returns {string | null} - The file type (e.g., 'jpg', 'png') or null if not found.
   */
  getFileTypeFromUrl(url: string): string | null {
    try {
      // Decode the URL to handle any special characters like %2F (which represents "/")
      const decodedUrl = decodeURIComponent(url);

      // Extract the file extension from the URL
      const fileType = decodedUrl.split(".").pop()?.split("?")[0];

      return fileType || null;
    } catch (error) {
      console.error("Invalid URL", error);
      return null;
    }
  },

  findImage(fileList: FileList): File[] {
    const extractedImage: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.includes("image")) {
        extractedImage.push(file);
      }
    }
    return extractedImage;
  },

  findVideo(fileList: FileList): number {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.includes("video")) {
        return i;
      }
    }
    return -1;
  },

  getAllVideo(fileList: FileList): File[] {
    const extractedVideo: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.includes("video")) {
        extractedVideo.push(file);
      }
    }
    return extractedVideo;
  },

  findNonVideoOrImage(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (!file.type.includes("video") && !file.type.includes("image")) {
        return i;
      }
    }
    return -1;
  },
  /**
   * Downloads a file from the provided URL.
   * @param {string} fileName - The name of the file to be downloaded.
   * @param {string} fileUrl - The URL of the file to be downloaded.
   */
  handleFileDownload(fileName: string, fileUrl: string) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  async handleZippedDownloads(fileUrls: string[]) {
    let zip: JSZip = new JSZip();

    // Fetch all files and add them to the zip
    const fetchFiles = fileUrls.map(async (url, index) => {
      const response = await fetch(url);
      const blob = await response.blob(); // Convert the response to a blob
      const fileName = "emplifive_draft_attachments";
      zip.file(fileName, blob);
    });

    await Promise.all(fetchFiles); // Wait for all files to be fetched

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Trigger download
    this.handleFileDownload(
      "emplifive_draft_attachments.zip",
      URL.createObjectURL(zipBlob)
    );
  },

  /**
   * Converts Heic image to Jpeg image
   */
  async convertHeicToJpeg(heicFile: File) {
    try {
      const heicBuffer = await heicFile.arrayBuffer();
      const heicBlob = new Blob([heicBuffer], { type: "image/heic" });

      // Convert HEIC content to JPEG using heic2any
      const jpegBlobs: Blob | Blob[] = await heic2any({
        blob: heicBlob,
        toType: "image/jpeg",
      });
      let concatenatedBlob: Blob;
      if (Array.isArray(jpegBlobs)) {
        concatenatedBlob = new Blob(jpegBlobs, { type: "image/jpeg" });
      } else {
        concatenatedBlob = jpegBlobs;
      }
      // Create a new File object with the concatenated Blob and file name
      const jpegFile = new File([concatenatedBlob], `${heicFile.name}.jpg`, {
        type: "image/jpeg",
      });

      return jpegFile;
    } catch (error) {
      console.error("Error converting HEIC to JPEG:", error);
      throw error;
    }
  },

  async processChunks<T extends EmpFileChunk>(
    request: Partial<T>,
    axios: AxiosInstance,
    endpoint: string,
    method: "post" | "put",
    file?: File,
    setLoadingProgress?: React.Dispatch<React.SetStateAction<number>>,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<ApiResponse<ApiStatus> | null> {
    // Run this if there is no file.

    if (setLoadingProgress) {
      setLoadingProgress(0);
    }
    const axiosMethod = method === "post" ? axios.post : axios.put;
    if (!file) {
      const formData = new FormData();
      formData.append("jsonData", JSON.stringify(request));
      const apiResponse = await axiosMethod<ApiResponse<ApiStatus>>(
        endpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct Content-Type header
          },
        }
      );
      return apiResponse.data;
    }

    // Run this if there is a file
    let uploadId;
    const CHUNK_SIZE = 8 * 1024 * 1024; // 8MB chunk size
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let start = 0;
    let uniqueId = v4();

    if (totalChunks > 1 && setIsLoading) {
      setIsLoading(true);
    }

    let response: ApiResponse<ApiStatus> | null = null;

    for (let i = 0; i < totalChunks; i++) {
      let chunkSize = CHUNK_SIZE;

      // Determine chunk size dynamically based on the scenarios
      if (i === totalChunks - 1) {
        // Last chunk
        const remainingFileSize = file.size - start;
        if (remainingFileSize > CHUNK_SIZE) {
          // Case when last chunk is larger than 16MB
          chunkSize = CHUNK_SIZE;
        } else {
          // Case when last chunk is smaller than or equal to 8MB
          chunkSize = remainingFileSize;
        }
      }
      const chunk = file.slice(start, start + chunkSize);
      start += chunkSize;
      const formData = new FormData();
      request.fileChunk = {
        uniqueId: uniqueId,
        index: i,
        totalChunks: totalChunks,
        uploadId,
      };
      formData.append("chunk", chunk);
      formData.append("jsonData", JSON.stringify(request));
      try {
        const apiResp = await axiosMethod<ApiResponse<ApiStatus>>(
          endpoint,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (setLoadingProgress) {
          setLoadingProgress(((i + 1) / totalChunks) * 100);
        }
        response = apiResp.data;
        if (response.data.statusMessage.startsWith("upload-in-progress")) {
          uploadId = response.data.statusMessage.split("::")[1];
        }
      } catch (error) {
        console.error("Error uploading chunk:", error);
      }
    }
    return response;
  },

  getFileExtension(filename: string) {
    return filename.split(".").pop();
  },

  base64ToFile(base64: string, filename: string) {
    const arr = base64.split(",");
    const match = arr[0].match(/:(.*?);/);
    const mime = match ? match[1] : "";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
};
export default FileUtils;
