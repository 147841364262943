import { motion } from "framer-motion";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";
const pulseVariant = {
  pulse: {
    scale: [1, 1.6, 1],
    opacity: [0.1, 0.3, 0.1],
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
    },
  },
};

export const PurpleGridBackground = () => {
  return (
    <div className="purple-grid-background">
      <motion.svg
        initial="pulse"
        animate="pulse"
        variants={pulseVariant}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 800 800"
        width="800"
        style={{ transformOrigin: "center" }}
        height="800"
        strokeWidth={1}
      >
        <g strokeWidth="3.5" stroke="hsl(261, 60%, 38%)" fill="none">
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="-4.289473684210526"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="-16.666666666666668"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="62.377192982456144"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="50"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="129.04385964912282"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="116.66666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="195.71052631578948"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="183.33333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="262.37719298245617"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="250.00000000000003"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="329.04385964912285"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="316.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="395.71052631578954"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="383.33333333333337"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="462.3771929824562"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="450.00000000000006"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="529.0438596491229"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="516.6666666666667"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="595.7105263157895"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="583.3333333333334"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="662.3771929824561"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="650"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="729.0438596491227"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="716.6666666666666"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="-16.666666666666668"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="-4.289473684210526"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="50"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="62.377192982456144"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="116.66666666666667"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="129.04385964912282"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="183.33333333333334"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="195.71052631578948"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="250.00000000000003"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="262.37719298245617"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="316.6666666666667"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="329.04385964912285"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="383.33333333333337"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="395.71052631578954"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="450.00000000000006"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="462.3771929824562"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="516.6666666666667"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="529.0438596491229"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="583.3333333333334"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="595.7105263157895"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="650"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="662.3771929824561"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="716.6666666666666"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="729.0438596491227"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="33.333333333333336"
            height="8.578947368421051"
            x="783.3333333333333"
            y="795.7105263157894"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
          <rect
            width="8.578947368421051"
            height="33.333333333333336"
            x="795.7105263157894"
            y="783.3333333333333"
            stroke="none"
            fill="hsl(261, 60%, 38%)"
            rx="4.289473684210526"
          ></rect>
        </g>
      </motion.svg>
    </div>
  );
};
