import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatusWithPayload } from "../../model/api/api-status-with-payload";
import { IgMediaInfoRespDto } from "../../model/instagram/ig-media-info-resp.dto";
import { IgMediaWPagination } from "../../model/instagram/ig-media-w-pagination.dto";
import { IgMediaWithProfileDto } from "../../model/instagram/ig-media-w-profile.dto";
import { IgMediaDto } from "../../model/instagram/ig-media.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const InstagramApi = {
  basicProfile: async (
    talentId: string
  ): Promise<ApiResponse<IgMediaInfoRespDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<IgMediaInfoRespDto>>(
      ENDPOINTS.userMicroservice.instagram.basicProfile(talentId)
    );
    return response.data;
  },
  getStory: async (creatorId: string): Promise<ApiResponse<IgMediaDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<IgMediaDto[]>>(
      ENDPOINTS.userMicroservice.instagram.getStory(creatorId)
    );
    return response.data;
  },
  getMediaPostById: async (
    creatorId: string,
    mediaId: string
  ): Promise<ApiResponse<ApiStatusWithPayload<IgMediaWithProfileDto>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<ApiStatusWithPayload<IgMediaWithProfileDto>>
    >(
      ENDPOINTS.userMicroservice.instagram.getMediaPostById(creatorId, mediaId)
    );
    return response.data;
  },
  getMediaStoryById: async (
    creatorId: string,
    mediaId: string
  ): Promise<ApiResponse<ApiStatusWithPayload<IgMediaWithProfileDto>>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<ApiStatusWithPayload<IgMediaWithProfileDto>>
    >(
      ENDPOINTS.userMicroservice.instagram.getMediaStoryById(creatorId, mediaId)
    );
    return response.data;
  },

  getPaginatedMedia: async (
    creatorId: string,
    paginatedUrl: string
  ): Promise<ApiResponse<IgMediaWPagination>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<IgMediaWPagination>>(
      ENDPOINTS.userMicroservice.instagram.paginatedMedia(creatorId),
      { paginatedUrl }
    );
    return response.data;
  },
};
export default InstagramApi;
