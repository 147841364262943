import { v4 } from "uuid";

export class ReactKeyUtil {
  static addReactKey = <T>(iterable: T[]): (T & { reactKey: string })[] => {
    return iterable.map((elem) => {
      return {
        reactKey: v4(),
        ...elem,
      };
    });
  };
}
