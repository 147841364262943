import { EXCEPTION_TYPE } from "../../constants/exceptionType";
import EmpBadRequestException from "../../exception/empBadRequestException";
import EmpException from "../../exception/empException";
import EmpInternalServerError from "../../exception/empInternalServerError";
import ToastUtils from "../toast-utils";
import { EmpExceptionHandlerBuilder } from "./empExceptionHandlerBuilder";

type ErrorFunctionType = { fn: (e: Error) => void };
class EmpExceptionHandler {
  private handlerQueue: ErrorFunctionType[] = [];

  static handleHttpRequestError(e: any, errorMessage: string): void {
    if (e instanceof Error) {
      new EmpExceptionHandlerBuilder()
        .handleCommonlHttpErrors()
        .handleGenericError()
        .build()
        .process(e);
    } else {
      ToastUtils.error(errorMessage, "Please try again");
    }
  }

  static builder(): EmpExceptionHandlerBuilder {
    return new EmpExceptionHandlerBuilder();
  }

  /**
   * Adds a handler to the handler queue.
   *
   * @param {HandlerCodeType} handlerCode - The code of the handler to be added.
   * @param {string} [errMsg] - An optional error message to be passed to the handler.
   * @returns {void}
   */
  addToHandlerQueue = (exceptionType: string) => {
    if (exceptionType === EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION) {
      this.handlerQueue.push({ fn: this.handle400 });
    } else if (exceptionType === EXCEPTION_TYPE.INTERNAL_SERVER_ERROR) {
      this.handlerQueue.push({ fn: this.handle500 });
    } else if (exceptionType === EXCEPTION_TYPE.GENERIC_ERROR) {
      this.handlerQueue.push({ fn: this.handleGenericError });
    }
  };

  /**
   * Validates the given error object using the functions in the handler queue.
   * @param {Error} errorObj - The error object to be processed.
   * @returns {void}
   */
  process = (errorObj: Error) => {
    if (errorObj === undefined) {
      console.error("No Error object defined");
      return;
    }
    for (let errorFn of this.handlerQueue) {
      errorFn.fn(errorObj);
    }
  };

  private handle400(e: Error): void {
    if (!(e instanceof EmpBadRequestException)) return;
    const exception = e;
    ToastUtils.error(e.title, exception.message);
  }

  private handle500(e: Error): void {
    if (!(e instanceof EmpInternalServerError)) return;
    const exception = e as EmpBadRequestException;
    ToastUtils.error(e.title, exception.message);
  }

  private handleGenericError(e: Error): void {
    if (!(e instanceof EmpException)) return;
    const exception = e;
    if (e.type !== EXCEPTION_TYPE.GENERIC_ERROR) return;
    ToastUtils.error("Error", exception.message);
  }
}

export default EmpExceptionHandler;
