import { Descendant } from "slate";
import { IValidator } from "./baseValidation";

export type FormType =
  | "text"
  | "checkbox-group"
  | "date-range"
  | "date"
  | "boolean"
  | "mobile-number"
  | "rich-text"
  | "coin";

export type MobileNumberControlValueType = {
  dialCode: string;
  mobileNumber: string;
  code: string;
};

export type CoinControlValueType = {
  value: string;
  ticker: string;
};

export interface IFormControl {
  hasError: boolean;
  value: any;
  errorMessage: string;
  validatonClsList: IValidator[];
  formType: FormType;
  resetFlag: number;
}

export class FormControl implements IFormControl {
  formType;
  initialValue;
  hasError;
  value;
  errorMessage;
  validatonClsList;
  resetFlag = 0;

  constructor(
    formType: FormType,
    validatonClsList?: IValidator[],
    value?: any
  ) {
    this.hasError = false;
    this.formType = formType;

    if (formType === "boolean") {
      this.value = value ?? false;
      this.initialValue = value ?? false;
    } else {
      this.value = value ? value : "";
      this.initialValue = value ? value : "";
    }

    this.errorMessage = "";
    this.validatonClsList = validatonClsList ? validatonClsList : [];

    // Perform initial validation for this form control
  }

  setValue(value: any): void {
    this.value = value;
  }

  getValue(): any {
    return this.value;
  }

  isPristine(): boolean {
    return this.initialValue === this.value;
  }
  /**
   * @purpose Validates current form control, and indicate whether if there is an error or not
   * @returns True, if there is no error, false otherwise
   */
  validate(): boolean {
    for (let validatorCls of this.validatonClsList) {
      if (!validatorCls.validate(this)) return false;
    }
    return true;
  }

  /**
   * Validates using one validator class using a specified index
   * @param index
   * @returns
   */
  validateByIndex(index: number): boolean {
    if (index > this.validatonClsList.length - 1)
      throw new Error("No Validator Class at this index");
    return this.validatonClsList[index].validate(this);
  }

  /**
   * @purpose Validates current form control, and indicate whether if there is a change in state
   * @returns True, if there is a change in validation status, false otherwise
   */
  validateTrackDiff(): boolean {
    const prevHasError = this.hasError;
    const prevErrorMessage = this.errorMessage;
    for (let validatorCls of this.validatonClsList) {
      if (!validatorCls.validate(this)) break;
    }
    return (
      this.hasError !== prevHasError || this.errorMessage !== prevErrorMessage
    );
  }

  // Reset all values to empty
  reset(): void {
    this.initialValue = "";
    this.hasError = false;
    this.value = "";
    this.errorMessage = "";
    this.resetFlag += 1;
  }

  // Reset all values to empty
  forceUpdateValue(
    value:
      | string
      | string[]
      | { startDate: Date; endDate: Date }
      | boolean
      | MobileNumberControlValueType
      | Descendant[]
      | CoinControlValueType
  ): void {
    this.initialValue = value;
    this.hasError = false;
    this.value = value;
    this.errorMessage = "";
    this.resetFlag += 1;
  }

  // Reset all values to initial value
  resetToInitial(): void {
    this.hasError = false;
    this.value = this.initialValue;
    this.errorMessage = "";
    this.resetFlag += 1;
  }

  details(): void {
    console.log("hasError", this.hasError);
    console.log("value", this.value);
    console.log("errorMessage", this.errorMessage);
  }
}
