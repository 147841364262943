import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import {
  SmUploadMediaResponseDto,
  SmUploaderCompleteMultipartUploadRequestDto,
  SmUploaderCompleteMultipartUploadResponseDto,
  SmUploaderCreateMultipartResponseDto,
  SmUploaderMediaValidationRequestDto,
} from "../../model/smm/smm-uploader.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SocialMediaUploaderApi = {
  uploadImage: async (
    post_type: string,
    files: FormData,
    platforms: string[]
  ): Promise<ApiResponse<string[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<string[]>>(
      ENDPOINTS.smmManagement.uploader.image,
      files,
      {
        params: { post_type, platforms },
      }
    );
    return response.data;
  },

  uploadVideo: async (
    post_type: string,
    files: any,
    platforms: string[]
  ): Promise<ApiResponse<string[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<string[]>>(
      ENDPOINTS.smmManagement.uploader.video,
      files,
      { params: { post_type, platforms } }
    );
    return response.data;
  },

  uploadMedia: async (
    files: any
  ): Promise<ApiResponse<SmUploadMediaResponseDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<SmUploadMediaResponseDto[]>>(
      ENDPOINTS.smmManagement.uploader.media,
      files
    );
    return response.data;
  },

  validateMedia: async (
    post_type: string,
    platforms: string[],
    data: SmUploaderMediaValidationRequestDto
  ): Promise<ApiResponse<any>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<any>>(
      ENDPOINTS.smmManagement.uploader.validate,
      data,
      { params: { post_type, platforms } }
    );
    return response.data;
  },

  delete: async (): Promise<ApiResponse<string>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete(
      ENDPOINTS.smmManagement.uploader.delete
    );
    return response.data;
  },

  createMultipart: async (
    key: string
  ): Promise<ApiResponse<SmUploaderCreateMultipartResponseDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<any>>(
      ENDPOINTS.smmManagement.uploader.createMultipartUpload,
      { key }
    );
    return response.data;
  },

  generatePresignedUrl: async (
    partNumber: number,
    uploadId: string,
    key: string
  ): Promise<ApiResponse<any>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<any>>(
      ENDPOINTS.smmManagement.uploader.generatePresignedUrl,
      { params: { partNumber, uploadId, key } }
    );
    return response.data;
  },

  completeMultipart: async (
    data: SmUploaderCompleteMultipartUploadRequestDto
  ): Promise<ApiResponse<SmUploaderCompleteMultipartUploadResponseDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<
      ApiResponse<SmUploaderCompleteMultipartUploadResponseDto>
    >(ENDPOINTS.smmManagement.uploader.completeMultipartUpload, data);

    return response.data;
  },
};

export default SocialMediaUploaderApi;
