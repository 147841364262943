import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import {
  SocialXPollPostRequestDto,
  SocialXPostRequestDto,
  SocialXThreadPostRequestDto,
} from "../../model/smm/smm-x.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SocialMediaManagementXApi = {
  createTweet: async (
    id: string,
    socialMediaUserId: string,
    req: SocialXPostRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.x.createTweet(id, socialMediaUserId),
      req
    );
    return response.data;
  },

  createThread: async (
    id: string,
    socialMediaUserId: string,
    req: SocialXThreadPostRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.x.createThread(id, socialMediaUserId),
      req
    );
    return response.data;
  },

  createPoll: async (
    id: string,
    socialMediaUserId: string,
    req: SocialXPollPostRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.x.createPoll(id, socialMediaUserId),
      req
    );
    return response.data;
  },
};

export default SocialMediaManagementXApi;
