import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import SearchIcon from "../../components/icon/search-icon";
import EmpMultiSelectV2 from "../../components/shared/emp-multi-select-v2/emp-multi-select-v2";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import {
  getCountryOptionWithHtml,
  searchAgeRangeOptions,
  searchGenderOptions,
} from "../../constants/selectConstants";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import "./brand-creators-listing-page.scss";
import { SelectOption } from "../../model/common/selectOption";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import _debounce from "lodash/debounce";
import { QueryCreatorRecruitmentCardDto } from "../../model/user/query-creator-recruitment-card.dto";
import EmpPagination, {
  EmpPaginationProps,
} from "../../components/shared/EmpPagination/EmpPagination";
import { CreatorListingTable } from "../../components/table/creator-listing-table";
import { CreatorCardDto } from "../../model/user/creator-card.dto";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";
import { SelectOptionWithHtml } from "../../model/common/select-option-with-html";

export const BrandCreatorsListingView = () => {
  const navigate = useNavigate();

  const [cards, setCards] = useState<CreatorCardDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [numRecords, setNumRecords] = useState<number>(0);
  const [paginable, setPaginable] = useState<EmpPaginationProps>();
  const [hasRestriction, setHasRestriction] = useState(true);

  const query = useRef<QueryCreatorRecruitmentCardDto>({});

  // Filter options
  const [interestOptions, setInterestOptions] = useState<SelectOption[]>([]);
  const [serviceOptions, setServiceOptions] = useState<SelectOption[]>([]);

  const countryWithHtmlOptions = useRef<SelectOptionWithHtml[]>(
    getCountryOptionWithHtml()
  );

  const [form, setForm] = useState<IFormGroup>({
    search: new FormControl("text", []),
    interests: new FormControl("checkbox-group", []),
    services: new FormControl("checkbox-group", []),
    country: new FormControl("checkbox-group", []),
    sortBy: new FormControl("text", [], "sort-by-join-date"),
    gender: new FormControl("text", [], "all"),
    age: new FormControl("text", [], "all"),
  });

  const fetchCreatorExtendedInfo = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.fetchCreatorInfoOptions();
      const creatorInfo = resp.data;
      setInterestOptions(
        creatorInfo.interests.map((elem) => {
          return {
            value: elem.id.toString(),
            label: elem.label,
          };
        })
      );
      setServiceOptions(
        creatorInfo.services.map((elem) => {
          return {
            value: elem.id.toString(),
            label: elem.label,
          };
        })
      );
    } catch (e) {
      console.log(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info"
      );
    }
  }, []);

  const fetchCreatorCards = useCallback(
    async (queryable: QueryCreatorRecruitmentCardDto) => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("pageNo", `${queryable?.pageNo ?? 1}`);
        searchParams.set("pageSize", `${queryable?.pageSize ?? 20}`);
        if (form.search.getValue().length > 0) {
          searchParams.set("search", form.search.getValue());
        }
        navigate(
          `${window.location.pathname}?${searchParams.toString()}${
            window.location.hash
          }`
        );

        const request: QueryCreatorRecruitmentCardDto = {
          countries: form.country.getValue(),
          creatorInterest: form.interests.getValue(),
          creatorServices: form.services.getValue(),
          keyword: form.search.getValue(),
          gender: form.gender.getValue(),
          ageRange: form.age.getValue(),
          pageNo: queryable?.pageNo ?? 1,
          pageSize: queryable?.pageSize ?? 20,
        };
        query.current = request;
        const resp = await CreatorInfoApi.getCreatorListing(request);
        const payload = resp.data.payload!;
        setHasRestriction(resp.data.statusMessage === "free");
        setPaginable({
          currentPage: payload.currentPage,
          totalRecord: payload.totalRecord,
          pageSize: payload.pageSize,
        });
        setNumRecords(payload.totalRecord);
        setCards(payload.records);
      } catch (e) {
        console.error(e);
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to creator recruitment cards"
        );
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  const executeInitialOperation = useCallback(() => {
    fetchCreatorExtendedInfo();
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo");
    const pageSize = searchParams.get("pageSize");
    const search = searchParams.get("search");

    if (search) {
      updateFormSearch(search);
    }
    fetchCreatorCards({
      pageNo: pageNo ? Number(pageNo) : 1,
      pageSize: pageSize ? Number(pageSize) : 20,
    });
  }, [fetchCreatorCards, fetchCreatorExtendedInfo, updateFormSearch]);

  useEffect(() => {
    executeInitialOperation();
    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [executeInitialOperation]);

  // Subscribe to any search update from the search modal.
  useEffect(() => {
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.BRAND_CREATOR_SEARCH,
      executeInitialOperation
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [executeInitialOperation]);

  const resizeDivRef = useRef<HTMLDivElement>(null);
  return (
    <div className="creator-listing-view">
      <section className="filter-section">
        <div className="filter-control-section mt-2">
          <div className="search-control">
            <EmpTextInput
              disabled={hasRestriction}
              labelText={`Search Creators`}
              id={"search"}
              onKeypress={(e) => {
                if (e === "Enter") {
                  fetchCreatorCards(query.current);
                }
              }}
              formControl={form.search}
              placeholder={"Search Creators on CreatorFi"}
              leftIconComponent={SearchIcon}
            />
          </div>
          <div className="control">
            <EmpMultiSelectV2
              disabled={hasRestriction}
              label={"Countries"}
              formControl={form.country}
              hasSearch
              menuItems={countryWithHtmlOptions.current}
              onChange={() => fetchCreatorCards(query.current)}
              placeholder={"Filter Countries"}
            />
          </div>
          <div className="control">
            <EmpMultiSelectV2
              disabled={hasRestriction}
              label={"Creator Interest"}
              formControl={form.interests}
              onChange={() => fetchCreatorCards(query.current)}
              menuItems={interestOptions}
              placeholder={"Filter Interests"}
            />
          </div>
          <div className="control">
            <EmpMultiSelectV2
              disabled={hasRestriction}
              label={"Creator Services"}
              formControl={form.services}
              onChange={() => fetchCreatorCards(query.current)}
              menuItems={serviceOptions}
              placeholder={"Filter Services"}
            />
          </div>
          <div className="control">
            <EmpSelect
              disabled={hasRestriction}
              hidePlaceholder
              labelText="Gender"
              formControl={form.gender}
              onChange={() => fetchCreatorCards(query.current)}
              selectOptions={searchGenderOptions}
              placeholder={"Gender"}
              id={"gender"}
            />
          </div>
          <div className="age-control">
            <EmpSelect
              disabled={hasRestriction}
              hidePlaceholder
              labelText="Age"
              formControl={form.age}
              onChange={() => fetchCreatorCards(query.current)}
              selectOptions={searchAgeRangeOptions}
              placeholder={"age"}
              id={"age"}
            />
          </div>
        </div>
      </section>

      <div ref={resizeDivRef} className="resize-wrapper"></div>
      <div className="records-wrapper mt-2">
        {!loading && (
          <span className="records-title">
            Showing {numRecords} Creator{numRecords > 1 ? "s" : ""}{" "}
          </span>
        )}
        {loading && !hasRestriction && (
          <div className="records-title-loader emp-shimmer"></div>
        )}
        {!hasRestriction && (
          <EmpPagination
            onChange={(queryable) =>
              fetchCreatorCards(queryable as QueryCreatorRecruitmentCardDto)
            }
            pagination={paginable}
            queryable={query.current}
          />
        )}
      </div>

      <section className="recruitment-card-section pv-5">
        <CreatorListingTable
          hasRestriction={hasRestriction}
          onClick={(context, record) => {
            if (context === "profile") {
              navigate(`/brand/creator-details/${record.id}`);
            }
          }}
          isLoading={loading}
          rawRecords={cards}
        />
      </section>
    </div>
  );
};
