import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";

export class EmailValidator extends BaseValidator implements IValidator {
  customEmailRequiredMsg?: string = undefined;
  customEmailInvalidMsg?: string = undefined;
  customEmailTooLongMsg?: string = undefined;

  constructor(
    customEmailRequiredMsg?: string,
    customEmailInvalidMsg?: string,
    customEmailTooLongMsg?: string
  ) {
    super();
    this.customEmailRequiredMsg = customEmailRequiredMsg;
    this.customEmailInvalidMsg = customEmailInvalidMsg;
    this.customEmailTooLongMsg = customEmailTooLongMsg;
  }

  /**
   * @purpose Perform email address validation
   * @param value Evaluated String
   * @returns True if valid | False if invalid
   */
  validate(formControl: FormControl): boolean {
    const requiredMsg =
      this.customEmailRequiredMsg ?? VALIDATION_MESSAGE.IS_REQUIRED;
    const invalidMsg =
      this.customEmailInvalidMsg ?? VALIDATION_MESSAGE.EMAIL_INVALID;
    const tooLongMsg =
      this.customEmailTooLongMsg ?? VALIDATION_MESSAGE.EMAIL_TOO_LONG;

    const regex =
      /^([0-9a-zA-Z]([-_.\\+]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
    const value = formControl.getValue();
    const evaluable = value ? value.trim() : "";

    if (evaluable.length <= 0) {
      this.hasError(formControl, requiredMsg);
      return false;
    } else if (!regex.test(evaluable)) {
      this.hasError(formControl, invalidMsg);
      return false;
    } else if (evaluable.length > 100) {
      this.hasError(formControl, tooLongMsg);
      return false;
    } else {
      this.noError(formControl);
      return true;
    }
  }
}
