import { FormattedMessage } from "react-intl";
import "./coming-soon-page.scss";

export const ComingSoonPage = () => {
  return (
    <div className="emp-page-wrapper">
      <div className="emp-page-content no-padding emp-coming-soon-page">
        <div className="coming-soon-wrapper mt-6">
          <div className="title-section">
            <h2>
              <FormattedMessage id="campaignComingSoon_header" />
            </h2>
            <p>
              <FormattedMessage id="campaignComingSoon_desc" />
            </p>
          </div>
          <div className="image-section">
            <img
              className="campaign-teaser-img"
              srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/campaign-teaser.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
