import { EXCEPTION_TYPE } from "../constants/exceptionType";
import EmpException from "./empException";

class EmpBadRequestException extends EmpException {
  type: string;
  title = "Bad Request";

  constructor(message: string) {
    super(message);
    this.type = EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION;
    this.name = "Emp Bad Request Exception";
  }
}
export default EmpBadRequestException;
