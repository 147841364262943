import { easeIn, easeOut } from "framer-motion";

export const CampaignGuideKvVariants = {
  GUIDE_DESCRIPTION_CONTAINER: {
    entryRight: {
      transition: {
        staggerChildren: 0.1,
        ease: easeOut,
      },
    },
    hideRight: {},
  },

  GUIDE_DESCRIPTION_CHILD: {
    entryRight: {
      opacity: 1,
      transition: { duration: 0.4 },
    },
    hideRight: { opacity: 0 },
  },
  CAMPAIGN_KV_VARIANT: {
    hide: {
      y: 75,
      opacity: 0,
      transition: { duration: 0.3, ease: easeOut },
    },
    show: {
      y: 10,
      opacity: 1,
      transition: { duration: 0.6, ease: easeOut },
    },
  },
  TASK_KV_CONTAINER_VARIANT: {
    hide: {},
    show: {
      transition: {
        staggerChildren: 0.1,
        ease: easeOut,
      },
    },
  },
  TASK_KV_VARIANT: {
    hide: {
      y: 75,
      opacity: 0,
      transition: { duration: 0.2, ease: easeOut },
    },
    show: {
      y: 10,
      opacity: 1,
      transition: { duration: 0.6, ease: easeOut },
    },
  },

  ENTRY_BOTTOM_VARIANT: {
    hide: {
      y: 75,
      opacity: 0,
      transition: { duration: 0.3, ease: easeOut },
    },
    show: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: easeOut },
    },
  },
  FADE_VARIANT: {
    hide: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
  },
  BTN_ENTRY: {
    hide: {
      y: 75,
      transition: { duration: 0.3, ease: easeOut },
    },
    show: {
      y: 0,
    },
  },

  EMPTY_CONTAINER_VARIANT: {
    hide: {},
    show: {},
  },
  ONGOING_DRAFT_LEFT_CARD_VARIANT: {
    hide: {
      x: "100%",
      opacity: 0,
      transition: { duration: 0.1 },
    },
    show: {
      x: "0%",
      opacity: 1,
      transition: { delay: 0.2, duration: 0.6 },
    },
  },
  ONGOING_DRAFT_RIGHT_CARD_VARIANT: {
    hide: {
      x: "-100%",
      opacity: 0,
      transition: { duration: 0.1 },
    },
    show: {
      x: "0%",
      opacity: 1,
      transition: { delay: 0.2, duration: 0.6 },
    },
  },
  ONGOING_POW_OPTIONS_CONTAINER_VARIANT: {
    hide: {},
    show: {
      transition: {
        staggerChildren: 0.1,
        ease: easeOut,
      },
    },
  },
  ONGOING_POW_OPTIONS_VARIANT: {
    hide: {
      y: 75,
      opacity: 0,
      transition: { duration: 0.2, ease: easeOut },
    },
    show: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: easeOut },
    },
  },
  ONGOING_PROGRESSWALL_CONTAINER_VARIANT: {
    hide: {},
    show: {
      transition: {
        staggerChildren: 0.1,
        ease: easeOut,
      },
    },
  },
  ONGOING_PROGRESSWALL_VARIANT: {
    hide: {
      y: 125,
      opacity: 0,
      transition: { duration: 0.2, ease: easeOut },
    },
    show: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: easeOut },
    },
  },
};
