import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _debounce from "lodash/debounce";
import "./facebook-view.scss";
import { motion } from "framer-motion";
import FacebookApi from "../../api/social-integration-msvc/facebook.api";
import { FbMediaInfoRespDto } from "../../model/facebook/fb-media-info-resp.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EyeIcon from "../icon/eye-icon";
import FacebookIcon from "../icon/facebook-icon";
import HeartIcon from "../icon/heart-icon";
import HeartsIcon from "../icon/hearts-icon";
import ImageIcon from "../icon/image-icon";
import MessageIcon from "../icon/message-icon";
import UsersIcon from "../icon/users-icon";
import RateCardModal, { RateCardModalRef } from "../modals/rate-card-modal";
import EmpButton from "../shared/emp-button/emp-button";
import EmpContent from "../shared/emp-content/emp-content";
import { Color } from "../../utilities/colors";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import { RateCardDetailsDto } from "../../model/social-media/rate-card-details-resp.dto";
import RateCardReadonlyModal, {
  RateCardReadonlyModalRef,
} from "../modals/rate-card-readonly-modal";
import EmpPill from "../shared/EmpPill/EmpPill";
import { DateUtil } from "../../utilities/date";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { PermsUtils } from "../../constants/permissions.constants";
import { AppContext } from "../../context/app.context";
import { UserDto } from "../../model/user-management/user.dto";
import UserUtils from "../../utilities/user-utils";
import EmpLink from "../shared/emp-link/emp-link";
import { useLocation } from "react-router-dom";
import StringUtils from "../../utilities/string.util";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import SettingsIcon from "../icon/settings-icon";
import RatePrivacySettingModal, {
  RateCardPrivacyModalRef,
} from "../modals/rate-privacy-setting-modal";
import { ReactKeyUtil } from "../../utilities/react-key.util";
import RateCardRequestModal, {
  RateCardRequestModalRef,
} from "../modals/rate-privacy-request-modal";
import PlayIcon from "../icon/play-icon";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import InfoCircleIcon from "../icon/info-circle-icon";
import { Tooltip, Whisper } from "rsuite";
import FormFieldUtils from "../../utilities/form-field.util";

interface Props {
  id: string;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const DELAY = 0.1;

const FacebookView = (props: Props) => {
  const { id } = props;
  const intl = useIntl();
  const location = useLocation();

  const [user, setUser] = useState<UserDto>();
  const { user: userContext } = useContext(AppContext);
  const roleRef = useRef<string>("");

  const isMobile = useEmpBreakpoint(500);
  const [fbBasicProfile, setFbBasicProfile] = useState<FbMediaInfoRespDto>();

  // Multiple rate cards that can be viewed by brands
  const [myRateCard, setMyRateCard] = useState<RateCardDetailsDto>();

  // Hidden rate card notice for agencies
  const [externalRatecards, setExternalRatecards] = useState<
    RateCardDetailsDto[]
  >([]);
  const rateCardReadonlyModalRef = useRef<RateCardReadonlyModalRef>();
  const rateCardModalRef = useRef<RateCardModalRef>();
  const rateCardPrivacySettingRef = useRef<RateCardPrivacyModalRef>();
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const setMyRateCards = (rateCardDetails: RateCardDetailsDto[]) => {
    const rateCardBelongsToMe = rateCardDetails.find((elem) => elem.isMe);
    setMyRateCard(rateCardBelongsToMe);
  };

  const determineExternalRateCards = (
    rateCardDetails: RateCardDetailsDto[]
  ) => {
    const externalOrgRateCards = [];
    for (let rateCardDetail of rateCardDetails) {
      if (!rateCardDetail.isMe && rateCardDetail.isViewable) {
        externalOrgRateCards.push(rateCardDetail);
      }
    }
    setExternalRatecards(externalOrgRateCards);
  };

  const fetchRateCard = useCallback(async () => {
    try {
      const resp = await RateCardApi.getRateCardWRole(
        id,
        "Facebook",
        roleRef.current
      );
      const rateCardDetails = resp.data;
      setMyRateCards(rateCardDetails);
      determineExternalRateCards(rateCardDetails);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [id]);

  const fetchFacebookInfo = useCallback(async () => {
    try {
      const resp = await FacebookApi.basicProfile(id);
      setFbBasicProfile(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [id]);

  useEffect(() => {
    roleRef.current = location.pathname.split("/")[1];
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchFacebookInfo();
    fetchRateCard();
    fetchUserDetails();
  }, [fetchFacebookInfo, fetchRateCard, id, userContext, location.pathname]);

  const GUTTER_WIDTH = 10;
  const MIN_CARD_WIDTH = 210;
  const MIN_RATE_CARD_WIDTH = 210;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [externalRateCardWidth, setExternalCardWidth] = useState<number>(0);
  const [maxCards, setMaxCards] = useState<number>(0);
  const [maxExternalRateCards, setMaxExternalRateCards] = useState<number>(0);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const rateCardRequestModalRef = useRef<RateCardRequestModalRef>();

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );
    setCardWidth(cardWidth);
    setMaxCards(maxCards);

    const maxRateCards = Math.max(
      Math.floor(rowWidth / MIN_RATE_CARD_WIDTH),
      1
    );
    const rateCardWidth = Math.floor(
      (rowWidth - (maxRateCards - 1) * GUTTER_WIDTH) / maxRateCards
    );
    setExternalCardWidth(rateCardWidth);
    setMaxExternalRateCards(maxRateCards);

    setShimmerCards(Array.from(Array(maxCards * 2).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  /**
   * Get the rate card description based on the user's role.
   * @returns {string} The rate card description.
   */
  const getRateCardDescription = useCallback(
    (rateCardPrivacy: boolean) => {
      if (!user) return <span></span>;
      const role = PermsUtils.getUserRole(user);
      if (role === "agency" && rateCardPrivacy)
        return (
          <>
            {" "}
            <FormattedMessage id="sm_agency_rateCardCardPrivacyOnDesc" />
          </>
        );
      if (role === "agency" && !rateCardPrivacy)
        return (
          <>
            <FormattedMessage id="sm_agency_rateCardCardPrivacyOffDesc" />
          </>
        );
      else if (role === "creator")
        return (
          <>
            {" "}
            <FormattedMessage id="sm_creator_rateCardCardDesc" />
          </>
        );
      return <span></span>;
    },
    [user]
  );

  const renderRateCardOwner = (rateCardOwner: string): JSX.Element => {
    if (rateCardOwner === "agency")
      return <FormattedMessage id="sm_rateCardOwnerAgency" />;
    else if (rateCardOwner === "creator")
      return <FormattedMessage id="sm_rateCardOwnerCreator" />;
    return <></>;
  };

  const remainingColumns = useMemo(() => {
    const numColumnsLeft =
      (maxExternalRateCards -
        (externalRatecards.length % maxExternalRateCards)) %
      maxExternalRateCards;
    return numColumnsLeft;
  }, [externalRatecards, maxExternalRateCards]);

  return (
    <div className="emp-facebook-view ">
      <RateCardReadonlyModal ref={rateCardReadonlyModalRef} />
      <RateCardModal
        ref={rateCardModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RateCardRequestModal
        ref={rateCardRequestModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RatePrivacySettingModal
        onSave={() => fetchRateCard()}
        ref={rateCardPrivacySettingRef}
      />

      <div ref={resizeDivRef} style={{ flex: 1 }}></div>
      <div className="social-media-label mt-3">
        <FacebookIcon size={18} backgroundColor={Color.NEUTRAL[200]} />
        <h1>Facebook</h1>
      </div>
      {fbBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 0, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smFacebook_followersLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCompact(fbBasicProfile.followers)}
              </span>
              <div className="icon-wrapper">
                {" "}
                <UsersIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 1, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smFacebook_postsLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCommify(fbBasicProfile.mediaCount)}
              </span>
              <div className="icon-wrapper">
                <ImageIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 2, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <EyeIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">
                  <FormattedMessage id="smFacebook_impressionsLabel" />
                </span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>Total Impressions for Past 28 Days</Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>
              <span className="value-lbl">
                {FormFieldUtils.toCompact(fbBasicProfile.impressions)}
              </span>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 3, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <HeartsIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">
                  <FormattedMessage id="smFacebook_engagementsLabel" />
                </span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>Engagement for Past 28 Days</Tooltip>}
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>
              <span className="value-lbl">
                {fbBasicProfile.engagement.toFixed(2)}%
              </span>
            </motion.div>
          </div>
        </div>
      )}
      {!fbBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
          </div>
        </div>
      )}

      {myRateCard && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_rateCardHeader" />
          </h1>
        </div>
      )}
      {myRateCard && (
        <div className={`emp-card rates-section`}>
          <div className="header-wrapper">
            <div>
              <div className="title-wrapper">
                <h2 className="emp-card-header">
                  <FormattedMessage id="sm_rateCardCardHeader" />
                </h2>
                {myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardHiddenPill" />
                      }
                    />
                  )}
                {!myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardVisiblePill" />
                      }
                    />
                  )}
              </div>

              {myRateCard.isEditable && (
                <p className="emp-card-description">
                  {getRateCardDescription(myRateCard.isRateCardPrivacy)}
                </p>
              )}
            </div>
            {!isMobile && myRateCard.isEditable && (
              <div className="rates-btn-wrapper">
                {roleRef.current === "agency" && (
                  <EmpIconButton
                    onSubmit={() => {
                      rateCardPrivacySettingRef.current?.show();
                    }}
                    buttonStyle="secondary"
                    icon={<SettingsIcon backgroundColor={Color.NEUTRAL[500]} />}
                  />
                )}
                <EmpButton
                  text={<FormattedMessage id="sm_rateCardEditRatesBtn" />}
                  onSubmit={() => {
                    rateCardModalRef.current?.show(id, "Facebook");
                  }}
                  isFullWidth={false}
                  buttonHeight="sm"
                />
              </div>
            )}
            {!myRateCard.isEditable && (
              <EmpButton
                text={"View Detailed Rates"}
                isFullWidth={false}
                buttonHeight="sm"
              />
            )}
          </div>
          <div className="rates-wrapper mt-2">
            {myRateCard.rateCards.map((rc, index) => {
              return (
                <div className="rate" key={rc.rateId}>
                  {rc.origin === "Template" && (
                    <EmpContent
                      label={TranslationUtil.translateRateCardName(
                        intl,
                        "Facebook",
                        rc.rateName
                      )}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {rc.origin === "Custom" && (
                    <EmpContent
                      label={rc.rateName}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {!isMobile && index < myRateCard.rateCards.length - 1 && (
                    <div className="divider"></div>
                  )}
                </div>
              );
            })}
          </div>
          {isMobile && myRateCard.isEditable && (
            <div className="mt-4">
              <EmpButton
                text={"Edit Rates"}
                onSubmit={() => {
                  rateCardModalRef.current?.show(id, "Facebook");
                }}
                isFullWidth={true}
                buttonHeight="sm"
              />
            </div>
          )}
        </div>
      )}

      {externalRatecards.length > 0 && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_externalRateCardHeader" />
          </h1>
        </div>
      )}
      {externalRatecards.length > 0 && (
        <div className="external-rates-section">
          <>
            {ReactKeyUtil.addReactKey(externalRatecards).map((elem) => {
              if (
                elem.isRateCardPrivacy === false ||
                (elem.isRateCardPrivacy === true &&
                  elem.rateCardRequestStatus === "approved")
              ) {
                return (
                  <div
                    key={elem.reactKey}
                    style={{ width: externalRateCardWidth }}
                    className="emp-card no-padding external-rate-card"
                  >
                    <div className="header-section">
                      {elem.rateCardOwner === "agency" && (
                        <>
                          <img
                            className="pic"
                            alt="external subject logo"
                            src={elem.organisation.logo}
                          />
                        </>
                      )}
                      {elem.rateCardOwner === "creator" &&
                        elem.creator.imageType === "url" && (
                          <>
                            <img
                              className="pic"
                              alt="external subject logo"
                              src={elem.creator.imageResource}
                            />
                          </>
                        )}
                      {elem.rateCardOwner === "creator" &&
                        elem.creator.imageType === "avatar" && (
                          <>
                            <div
                              className="avatar"
                              style={{ background: elem.creator.imageResource }}
                            >
                              <span>{elem.creator.initials}</span>
                            </div>
                          </>
                        )}
                      <div className="details-section">
                        {elem.rateCardOwner === "creator" && (
                          <span className="name-lbl">
                            {elem.creator.fullName}
                          </span>
                        )}
                        {elem.rateCardOwner === "agency" && (
                          <span className="name-lbl">
                            {elem.organisation.companyName}
                          </span>
                        )}

                        <EmpPill
                          text={renderRateCardOwner(elem.rateCardOwner)}
                          backgroundColor={Color.PRIMARY[100]}
                          color={Color.PRIMARY[600]}
                        />
                      </div>
                    </div>
                    {elem.rateCardExpiryDate && (
                      <div
                        className="rates-section"
                        style={{
                          borderBottom: `1px solid ${Color.NEUTRAL[100]}`,
                          paddingTop: 8,
                          paddingBottom: 8,
                        }}
                      >
                        <p className="emp-paragraph">
                          Expires{" "}
                          {DateUtil.toReadableDate(elem.rateCardExpiryDate)}
                        </p>
                      </div>
                    )}
                    <div className="rates-breakdown-section mt-4">
                      {elem.rateCards
                        .filter((rc) => rc.origin === "Template")
                        .map((rc) => {
                          return (
                            <Fragment key={rc.rateId}>
                              <EmpContent
                                label={TranslationUtil.translateRateCardName(
                                  intl,
                                  "Facebook",
                                  rc.rateName
                                )}
                                value={
                                  rc.price > 0 ? (
                                    `${rc.price.toFixed(2)} USD`
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        color: Color.NEUTRAL[400],
                                      }}
                                    >
                                      <FormattedMessage id="sm_notSetLabel" />
                                    </span>
                                  )
                                }
                              />
                            </Fragment>
                          );
                        })
                        .slice(0, 2)}
                    </div>
                    <div className="btn-section">
                      <EmpButton
                        buttonHeight="sm"
                        buttonStyle="secondary"
                        className="mt-4"
                        onSubmit={() => {
                          rateCardReadonlyModalRef.current?.show(
                            id,
                            "Facebook",
                            elem.organisation?.id
                          );
                        }}
                        text={<FormattedMessage id="sm_rateCardMoreRatesBtn" />}
                      />
                    </div>
                  </div>
                );
              } else if (elem.isRateCardPrivacy === true) {
                return (
                  <div
                    key={elem.reactKey}
                    style={{ width: externalRateCardWidth }}
                    className="emp-card no-padding external-rate-card"
                  >
                    <div className="header-section">
                      {elem.rateCardOwner === "agency" && (
                        <>
                          <img
                            className="pic"
                            alt="external subject logo"
                            src={elem.organisation.logo}
                          />
                        </>
                      )}
                      {elem.rateCardOwner === "creator" &&
                        elem.creator.imageType === "url" && (
                          <>
                            <img
                              className="pic"
                              alt="external subject logo"
                              src={elem.creator.imageResource}
                            />
                          </>
                        )}
                      {elem.rateCardOwner === "creator" &&
                        elem.creator.imageType === "avatar" && (
                          <>
                            <div
                              className="avatar"
                              style={{ background: elem.creator.imageResource }}
                            >
                              <span>{elem.creator.initials}</span>
                            </div>
                          </>
                        )}
                      <div className="details-section">
                        {elem.rateCardOwner === "creator" && (
                          <span className="name-lbl">
                            {elem.creator.fullName}
                          </span>
                        )}
                        {elem.rateCardOwner === "agency" && (
                          <span className="name-lbl">
                            {elem.organisation.companyName}
                          </span>
                        )}
                        <EmpPill
                          text={StringUtils.convertHyphensToTitleCase(
                            elem.rateCardOwner
                          )}
                          backgroundColor={Color.PRIMARY[100]}
                          color={Color.PRIMARY[300]}
                        />
                      </div>
                    </div>
                    <div className="rates-breakdown-section mt-4 hidden">
                      {elem.rateCardRequestStatus === "hidden" && (
                        <span>Agency has chosen to hide its rates</span>
                      )}
                      {elem.rateCardRequestStatus === "pending" && (
                        <span>
                          You have requested to view the rates from the agency.
                          Please wait for their approval
                        </span>
                      )}
                      {elem.rateCardRequestStatus === "rejected" && (
                        <span>
                          Your request to view this rate card has been rejected
                          by the Agency
                        </span>
                      )}
                      {elem.rateCardRequestStatus === "expired" && (
                        <span>
                          Your request to view rate card has already expired
                        </span>
                      )}
                    </div>
                    <div className="btn-section">
                      <EmpButton
                        buttonHeight="sm"
                        className="mt-4"
                        onSubmit={() => {
                          rateCardRequestModalRef.current?.show(
                            elem.organisation.id,
                            elem.creator.id
                          );
                        }}
                        text={"Request for rates"}
                      />
                    </div>
                  </div>
                );
              }
            })}

            {!isMobile &&
              maxExternalRateCards > 0 &&
              Array.from(Array(remainingColumns).keys()).map((elem) => {
                return (
                  <div
                    key={elem}
                    style={{ width: externalRateCardWidth }}
                    className="external-empty-rate-card"
                  >
                    <span className="label">
                      <FormattedMessage id="sm_externalRateCardEmptyCard" />
                    </span>
                  </div>
                );
              })}
          </>
        </div>
      )}

      <div className="social-media-label  mt-3">
        <h1>
          <FormattedMessage id="sm_recentPostHeader" />
        </h1>
      </div>
      {fbBasicProfile && (
        <div className="social-media-post-section mt-2">
          {fbBasicProfile.media.map((elem, index) => {
            return (
              <motion.a
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: DELAY * index, duration: 0.4 }}
                href={elem.permalink}
                target="_blank"
                rel="noreferrer"
                key={elem.permalink}
                style={{ width: cardWidth }}
                className={`fb-card ${
                  (index + 1) % maxCards !== 0 && "mr-10px"
                }`}
              >
                {["added_video", "added_photos", "shared_story"].includes(
                  elem.mediaType
                ) && (
                  <div className="img-wrapper">
                    {elem.mediaType === "added_video" && (
                      <div className="play-wrapper">
                        <div className="play-circle">
                          <PlayIcon
                            left={2}
                            size={30}
                            backgroundColor={Color.NEUTRAL[200]}
                          />
                        </div>
                      </div>
                    )}
                    <img src={elem.mediaUrl} alt={`facebook post ${index}`} />
                  </div>
                )}
                {elem.mediaType === "mobile_status_update" && (
                  <div className="text-wrapper">
                    <div className="post-profile-section">
                      <img src={fbBasicProfile.picture} />
                      <div className="details-wrapper">
                        <span className="name-lbl">{fbBasicProfile.name}</span>
                        <span className="date-lbl">
                          {DateUtil.toReadableDateWithTime(elem.timestamp)}
                        </span>
                      </div>
                    </div>
                    <div className="caption-wrapper">
                      <p className="caption">{elem.caption}</p>
                    </div>
                  </div>
                )}
                <div className="info-section">
                  <div className="metrics-wrapper">
                    <div className="metric">
                      <HeartIcon
                        backgroundColor={Color.NEUTRAL[300]}
                        size={16}
                      />
                      <span className="metric-value">
                        {FormFieldUtils.toCompact(elem.likeCount)}
                      </span>
                    </div>
                    <div className="metric">
                      <MessageIcon
                        backgroundColor={Color.NEUTRAL[300]}
                        size={16}
                      />
                      <span className="metric-value">
                        {FormFieldUtils.toCommify(elem.commentsCount)}
                      </span>
                    </div>
                  </div>
                  {["added_video", "added_photos", "shared_story"].includes(
                    elem.mediaType
                  ) && <p className="caption">{elem.caption}</p>}
                </div>
              </motion.a>
            );
          })}
        </div>
      )}

      {/* Empty State */}
      {!fbBasicProfile && (
        <div className="social-media-post-section mt-2">
          {shimmerCard.map((elem, index) => {
            return (
              <div
                style={{ width: cardWidth }}
                key={elem}
                className={`empty-card emp-shimmer ${
                  (index + 1) % maxCards !== 0 && "mr-10px"
                }`}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default FacebookView;
