import "./brand-account-selection-modal.scss";

import {
  forwardRef,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpException from "../../../exception/empException";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import {
  FacebookPageSpec as InstagramPageSpec,
  InstagramConnectedAccountDto,
  InstagramConnectedAccount,
} from "../../../model/social-media/instagram-connected-account.dto";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import { ReactComponent as FlagIcon } from "../../../assets/icons/flag-icon.svg";
import {
  FacebookPageDto,
  FacebookPageSpec,
} from "../../../model/social-media/facebook-page.dto";
import { SmConnectionBriefRespDto } from "../../../model/smm/smm-connection-brief-resp.dto";

export interface BrandSmmAccountSelectionModalRef {
  show: (
    instagramConnectedPage: InstagramConnectedAccountDto | FacebookPageDto
  ) => void;
  dismiss: () => void;
}

interface Props {
  platform: string;
  socialConnected: SmConnectionBriefRespDto[];
  onSave: (
    fbPageSpec: InstagramPageSpec | FacebookPageSpec,
    platform: string
  ) => void;
  onDismiss: () => void;
}

const platformTextFormatted: { [key: string]: string } = {
  Facebook: "Facebook Pages",
  Instagram: "Instagram Business",
};

const BrandSmmAccountSelectionModal = forwardRef((props: Props, ref) => {
  const { socialConnected, platform } = props;

  const saveBtnRefs = useRef<(EmpButtonRef | null)[]>([]);

  const saveBtnRef = useCallback((el: EmpButtonRef | null, index: number) => {
    saveBtnRefs.current[index] = el;
  }, []);

  const [visible, setVisible] = useState<boolean>(false);

  const [selectedPage, setSelectedPage] = useState<
    InstagramConnectedAccountDto | FacebookPageDto | null
  >(null);

  useImperativeHandle(ref, () => ({
    show: (
      socialMediaConnectedPage: SetStateAction<
        InstagramConnectedAccountDto | FacebookPageDto | null
      >
    ) => {
      setSelectedPage(socialMediaConnectedPage);
      setVisible(true);
    },
    dismiss: () => {
      setVisible(false);
      setSelectedPage(null);
    },
  }));

  const dismiss = () => {
    setVisible(false);
    setSelectedPage(null);
  };

  const onSave = useCallback(
    (index: number) => {
      try {
        saveBtnRefs.current[index]?.setButtonState("loading");
        const spec = selectedPage?.data[index];
        if (!spec) throw new EmpException("Unable to get page spec");
        return props.onSave(spec, platform);
      } catch (e) {
        if (e instanceof Error)
          EmpExceptionHandler.builder().handleGenericError().build().process(e);
        saveBtnRefs.current[index]?.setButtonState("default");
      }
    },
    [platform, props, selectedPage?.data]
  );

  const isConnected = useCallback(
    (id: string) =>
      socialConnected.some(
        (account) =>
          account.socialMediaUserId === id && account.platform === platform
      ),
    [socialConnected, platform]
  );

  const renderPageItem = (
    elem: InstagramPageSpec | FacebookPageSpec,
    index: number
  ) => {
    const isInstagram = platform === "Instagram";
    if (isInstagram && !(elem as InstagramPageSpec).connected_instagram_account)
      return <></>;

    const accountInfo = isInstagram
      ? (elem as InstagramPageSpec).connected_instagram_account
      : elem;

    const followersCount = isInstagram
      ? accountInfo?.followers_count
      : elem?.followers_count;

    return (
      <div key={elem.id}>
        <div className="page-name-wrapper">
          <div className="flag-icon-wrapper">
            <FlagIcon />
          </div>
          <span className="page-name-lbl">
            {isInstagram ? elem?.name : "Page"}
          </span>
        </div>

        <div className={`page-item ${isConnected(elem.id) ? "active" : ""}`}>
          <div className="left-elem">
            <img
              className="picture"
              alt="Page Icon"
              src={
                (accountInfo as InstagramConnectedAccount)
                  ?.profile_picture_url ||
                (elem as FacebookPageSpec).picture?.data?.url ||
                EMPTY_PROFILE_IMG_URL
              }
            />
            <div className="text-section">
              <span className="name">
                {isInstagram
                  ? (elem as InstagramPageSpec)?.connected_instagram_account
                      ?.name
                  : accountInfo?.name}
              </span>
              <span className="about">
                <FormattedMessage id="instagramAccountSelection_followers" />:{" "}
                {followersCount}
              </span>
            </div>
          </div>
          <div className="right-elem">
            {isConnected(elem.id) ? (
              <EmpButton
                isFullWidth={false}
                text="Connected"
                buttonStyle="secondary"
                disabled
              />
            ) : (
              <EmpButton
                isFullWidth={false}
                ref={(el: EmpButtonRef | null) => saveBtnRef(el, index)}
                text="Connect"
                onSubmit={() => onSave(index)}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={
        <FormattedMessage
          id="brandAccountSelectionModal_header"
          values={{ selection: platformTextFormatted[platform] }}
        />
      }
      showFooter={false}
      showFooterBorder={false}
      onClose={() => {
        dismiss();
      }}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-brand-smm-account-modal">
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage
              id="brandAccountSelectionModal_title"
              values={{ selection: platformTextFormatted[platform] }}
            />
          </h2>
        </div>

        {/* Facebook Pages Mapper */}

        {selectedPage && (
          <div className="pages-wrapper">
            {selectedPage.data.map((elem, index) =>
              renderPageItem(elem, index)
            )}
          </div>
        )}
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default BrandSmmAccountSelectionModal;
