import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import OnboardingApi from "../../../api/user-msvc/onboarding.api";
import UserApi from "../../../api/user-msvc/user.api";
import AlertSquareIcon from "../../../components/icon/alert-square";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpCountrySelect from "../../../components/shared/emp-country-select/emp-country-select";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../../components/shared/emp-cropper/emp-cropper";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import {
  getGenderOptions,
  userRoleOptions,
} from "../../../constants/selectConstants";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import { CreatorOnboardingDetailsReqDto } from "../../../model/onboarding/creator-onboarding-details-req.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EmpExceptionHandlerBuilder from "../../../utilities/errorUtils/empExceptionHandlerBuilder";
import FileUtils from "../../../utilities/file-util";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import "./creator-basic-info-step.scss";
import EmpDatePicker from "../../../components/shared/emp-date-picker/emp-date-picker";
import { DateRangePicker } from "rsuite";
import { DisabledDateFunction } from "rsuite/esm/DateRangePicker";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import SupportApi from "../../../api/referral-and-support-msvc/support.api";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
interface StepProps {
  nextStep: (intent: "done" | "skip") => void;
}
export const CreatorBasicInfoStep = (props: StepProps) => {
  const intl = useIntl();

  const genderOptions = useMemo(() => {
    return getGenderOptions(intl);
  }, [intl]);

  const formControlLabel = useMemo(() => {
    return {
      nameLabel: intl.formatMessage({
        id: "personalDetailStep_nameInputLabel",
      }),
      namePlaceholder: intl.formatMessage({
        id: "personalDetailStep_nameInputPlaceholder",
      }),
      genderLabel: intl.formatMessage({
        id: "personalDetailStep_genderInputLabel",
      }),
      genderPlaceholder: intl.formatMessage({
        id: "personalDetailStep_genderInputPlaceholder",
      }),
      ageLabel: intl.formatMessage({ id: "personalDetailStep_ageInputLabel" }),
      countryLabel: intl.formatMessage({
        id: "personalDetailStep_countryInputLabel",
      }),
      countryPlaceholder: intl.formatMessage({
        id: "personalDetailStep_countryInputPlaceholder",
      }),
    };
  }, [intl]);

  const isMobile = useEmpBreakpoint(500);
  const [isLoaded, setIsLoaded] = useState(true);
  const isLogoModifiedRef = useRef(false);
  const [user, setUser] = useState<UserDto>();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const fetchUserDetails = useCallback(async () => {
    try {
      const userSession = await getCurrentUser();
      const response = await UserApi.fetchCreatorById(userSession.userId);
      const userResp = response.data;
      setUser(userResp);
      const { gender, country } = userResp.creator;
      if (!form) return;
      form.fullName.forceUpdateValue(userResp.fullName);
      form.gender.forceUpdateValue(gender);
      form.country.forceUpdateValue(country);
      setForm({ ...form });
      setIsLoaded(true);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const [logo, setLogo] = useState<string>();
  const [form, setForm] = useState<IFormGroup>();

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        country: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_countryRequired" })
            ),
            new LengthValidator(
              0,
              100,
              undefined,
              intl.formatMessage(
                { id: "validation_maxLengthDynamic" },
                { char: "100" }
              )
            ),
          ],
          prevForm?.country.getValue() ?? ""
        ),
        fullName: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_nameRequired" })
            ),
            new LengthValidator(
              0,
              200,
              undefined,
              intl.formatMessage(
                { id: "validation_maxLengthDynamic" },
                { char: "200" }
              )
            ),
          ],
          prevForm?.fullName.getValue() ?? ""
        ),
        gender: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_genderRequired" })
            ),
            new LengthValidator(
              0,
              200,
              undefined,
              intl.formatMessage(
                { id: "validation_maxLengthDynamic" },
                { char: "200" }
              )
            ),
          ],
          prevForm?.gender.getValue() ?? ""
        ),
        dateOfBirth: new FormControl(
          "date",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_dateOfBirthRequired" })
            ),
          ],
          prevForm?.dateOfBirth.getValue() ?? ""
        ),
      };
      if (hasSubmitted) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const cropperRef = useRef<EmpCropperRef>();
  const [imageErrorMessage, setImageErrorMessage] = useState<
    string | JSX.Element
  >();

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);

    if (!isImage) {
      setImageErrorMessage(
        <FormattedMessage id="validation_profileImageRequired" />
      );
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage(
        <FormattedMessage
          id="validation_dynamicFileSize"
          values={{ size: 3 }}
        />
      );
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await FileUtils.readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    if (cropperRef.current === undefined) return;
    cropperRef.current.open(base64);
  };

  /**
   * Finds a user's platform referral by the subject referral code.
   * @param {string} referralCode - The referral code of the subject.
   * @returns {Promise<PlatformReferral>} - The user's platform referral.
   */
  const setupCreatorReferral = async () => {
    const resp = await SupportApi.setupCreatorReferral();
    if (resp.data.status === "success") {
      ToastUtils.success("Completed", "Setup referral successfully");
    }
  };

  const onSubmit = async () => {
    try {
      if (!form) return;
      setHasSubmitted(true);
      console.log(form);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      const imageIsValid = !(logo === undefined && user!.imageType === "none");
      if (logo === undefined && user!.imageType === "none") {
        setImageErrorMessage(
          <FormattedMessage id="validation_profileImageRequired" />
        );
      }
      if (!imageIsValid || !isValid) return;
      const request: CreatorOnboardingDetailsReqDto = {
        isImageModified: isLogoModifiedRef.current,
        image: logo,
        fullName: form.fullName.getValue(),
        country: form.country.getValue(),
        gender: form.gender.getValue(),
        dateOfBirth: form.dateOfBirth.getValue(),
      };
      const resp = await OnboardingApi.saveCreatorSpec(request);
      if (resp.data.status === "success") {
        await setupCreatorReferral();
        await fetchAuthSession({ forceRefresh: true });
        props.nextStep("done");
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const disabledDate = useMemo<DisabledDateFunction>(() => {
    return (date: Date) => {
      const today = new Date();
      const maxDate = new Date();
      maxDate.setFullYear(today.getFullYear() - 16);
      return date.getTime() > maxDate.getTime();
    };
  }, []); // Empty dependency array ensures the function is only created once

  return (
    <div className="emp-creator-basic-info-step">
      <EmpCropper
        onCrop={(base64: string) => {
          setLogo(base64);
          isLogoModifiedRef.current = true;
        }}
        ref={cropperRef}
      />
      {form && (
        <div className="form-wrapper">
          <div className="header-section">
            <span className="step-lbl">
              <FormattedMessage id="personalDetailStep_header" />
            </span>
          </div>
          <motion.div
            className="header-wrapper"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 0 * animationDelay }}
          >
            <p className="description mt-4">
              <FormattedMessage id="personalDetailStep_desc" />
            </p>
          </motion.div>
          <div className="body-section">
            <motion.div
              className="image-upload-group"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 1 * animationDelay }}
            >
              {user && (
                <>
                  {logo && <img alt="Profile" src={logo} />}
                  {!logo && user.imageType === "url" && (
                    <img alt="Profile" src={user.imageResource} />
                  )}
                  {!logo && user.imageType === "none" && (
                    <img
                      referrerPolicy="no-referrer"
                      alt="Upload Image"
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {!logo && user.imageType === "avatar" && (
                    <div
                      style={{ background: user.imageResource }}
                      className="avatar"
                    >
                      <span className="initials">{user.initials}</span>
                    </div>
                  )}
                </>
              )}
              <label className="logo-upload" htmlFor={"logo-upload"}>
                <FormattedMessage id="cta_uploadLogo" />
              </label>
              {imageErrorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{imageErrorMessage}</span>
                </div>
              )}
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="logo-upload"
                onChange={handleFileUpload}
                name="myfile"
              ></input>
            </motion.div>
            <motion.div
              style={{ position: "relative", zIndex: 2 }}
              className="mt-6"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 2 * animationDelay }}
            >
              <EmpTextInput
                id={"fullName"}
                formControl={form.fullName}
                labelText={formControlLabel.nameLabel}
                required
                placeholder={formControlLabel.namePlaceholder}
                onChange={formOnChange}
              />
            </motion.div>
            <motion.div
              className="mt-6 split-form-group-wrapper"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 3 * animationDelay }}
            >
              <div className="gender-wrapper">
                <EmpSelect
                  id={"gender"}
                  formControl={form.gender}
                  labelText={formControlLabel.genderLabel}
                  required
                  placeholder={formControlLabel.genderPlaceholder}
                  selectOptions={genderOptions}
                  onChange={formOnChange}
                />
              </div>

              <div className="age-wrapper">
                {/* <EmpTextInput
                            id={"age"}
                            formControl={form.age}
                            labelText={formControlLabel.ageLabel}
                            required
                            placeholder="16 - 99"
                            onChange={formOnChange} /> */}

                <EmpDatePicker
                  disabledDate={() => disabledDate}
                  isRequired
                  label="Date of Birth"
                  formControl={form.dateOfBirth}
                  placeholder={`Select Date of Birth`}
                />
              </div>
            </motion.div>
            <motion.div
              style={{ position: "relative", zIndex: 2 }}
              className="mt-4"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 4 * animationDelay }}
            >
              <EmpCountrySelect
                labelText="Country"
                required
                placeholder={formControlLabel.countryPlaceholder}
                id={formControlLabel.countryLabel}
                formControl={form.country}
                onChange={formOnChange}
              />
            </motion.div>
            <div className="btn-section">
              <motion.div
                className="mt-10 btn-wrapper"
                variants={fadeInVariants}
                initial="hidden"
                animate={isLoaded ? "visible" : "hidden"}
                transition={{ duration: 0.2, delay: 5 * animationDelay }}
              >
                <EmpButton
                  buttonHeight={isMobile ? "lg" : "sm"}
                  text={<FormattedMessage id="cta_nextStep" />}
                  onSubmit={() => {
                    onSubmit();
                  }}
                />
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
