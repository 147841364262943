import { useCallback, useEffect, useRef, useState } from "react";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import "./creator-home-extended-info-card.scss";
import CreatorInfoApi from "../../../api/user-msvc/creator-info.api";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { Color } from "../../../utilities/colors";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import CreatorInfoSelectionModal, {
  CreatorInfoSelectionModalRef,
} from "./creator-info-selection-modal";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpRoundedPill from "../../../components/shared/emp-rounded-pill/emp-rounded-pill";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../../utilities/translation.util";
import { getCurrentUser } from "aws-amplify/auth";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

export const CreatorHomeExtendedInfoCard = () => {
  const intl = useIntl();
  const [creatorInfoCompleted, setCreatorInfoCompleted] = useState(false);
  const creatorInfoSelectionModalRef = useRef<CreatorInfoSelectionModalRef>();
  const [serviceOptions, setServiceOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [interestOptions, setInterestOptions] = useState<
    { label: string; id: number }[]
  >([]);

  const userIdRef = useRef<string>();

  const checkCreatorInfoStatus = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();

      userIdRef.current = currentUser.userId;
      const resp = await CreatorInfoApi.checkCreatorInfoCompletion(
        currentUser.userId
      );
      if (resp.data.statusMessage === "incomplete") {
        setCreatorInfoCompleted(false);
      } else if (resp.data.statusMessage === "complete") {
        setCreatorInfoCompleted(true);
        const creatorInfoResp = await CreatorInfoApi.fetchCreatorInfo(
          currentUser.userId
        );

        // Map to options
        setServiceOptions(creatorInfoResp.data.services);
        setInterestOptions(creatorInfoResp.data.interests);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, []);

  useEffect(() => {
    checkCreatorInfoStatus();
  }, [checkCreatorInfoStatus]);

  return (
    <>
      <CreatorInfoSelectionModal
        ref={creatorInfoSelectionModalRef}
        onSave={() => {
          checkCreatorInfoStatus();
        }}
      />
      {creatorInfoCompleted && (
        <div className="dashboard-card creator-extended-info">
          <div className="header-wrapper">
            <h3 className="card-header">
              <FormattedMessage id="creatorInfoCard_header" />
            </h3>
            <EmpButton
              onSubmit={() => {
                creatorInfoSelectionModalRef.current?.show(
                  "edit",
                  userIdRef.current
                );
              }}
              buttonStyle="secondary"
              buttonHeight="sm"
              text={<FormattedMessage id="cta_edit" />}
              isFullWidth={false}
            />
          </div>


          <div className="mt-3">
            <EmpContent
              label={<FormattedMessage id="creatorInfoCard_serviceHeader" />}
              value={
                <div className="pills-wrapper">
                  {serviceOptions.map((elem) => {
                    return (
                      <EmpRoundedPill
                        key={elem.id}
                        text={TranslationUtil.translateCreatorService(
                          intl,
                          elem.id,
                          elem.label
                        )}
                        clickable
                      />
                    );
                  })}
                </div>
              }
            />
          </div>

          <div className="mt-4">
            <EmpContent
              label={<FormattedMessage id="creatorInfoCard_interestHeader" />}
              value={
                <div className="pills-wrapper">
                  {interestOptions.map((elem) => {
                    return (
                      <EmpRoundedPill
                        key={elem.id}
                        text={TranslationUtil.translateCreatorInterest(
                          intl,
                          elem.id,
                          elem.label
                        )}
                        clickable
                      />
                    );
                  })}
                </div>
              }
            />
          </div>
        </div>
      )}
      {!creatorInfoCompleted && (
        <div className="dashboard-card creator-extended-info empty-state mt-4">
          <EmpPill
            {...PILL_COLORS.red}
            text={<FormattedMessage id="creatorInfoCard_cta" />}
          />
          <h3 className="card-header mt-2">
            <FormattedMessage id="creatorInfoCard_emptyHeader" />
          </h3>
          <p className="card-description mt-2">
            <FormattedMessage id="creatorInfoCard_emptyDesc" />
          </p>
          <div className="button-wrapper">
            <EmpButton
              isFullWidth={false}
              onSubmit={() => {
                creatorInfoSelectionModalRef.current?.show("add");
              }}
              className="mt-4"
              buttonStyle="primary"
              text={<FormattedMessage id="cta_letsGo" />}
            />
          </div>
        </div>
      )}
    </>
  );
};
