import { UserDto } from "../../../model/user-management/user.dto";
import "./emp-avatar.scss";

interface Props {
  user: Partial<UserDto>;
  size?: number;
}

const DEFAULT_SIZE = 36;

const EmpAvatar = (props: Props) => {
  const { user } = props;
  const size = props.size ?? DEFAULT_SIZE;

  return (
    <div style={{ width: size, height: size }} className="emp-avatar">
      {user.imageType === "avatar" && (
        <div
          style={{
            backgroundImage: user.imageResource,
            width: size,
            height: size,
            fontSize: size - 22,
          }}
          className="display-img"
        >
          {user.initials}
        </div>
      )}
      {user.imageType === "url" && (
        <img
          style={{ width: size, height: size }}
          src={user.imageResource}
          alt={`${user.fullName}'s Display`}
        />
      )}
    </div>
  );
};
export default EmpAvatar;
