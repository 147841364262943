import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import SupportApi from "../../api/referral-and-support-msvc/support.api";
import OnboardingApi from "../../api/user-msvc/onboarding.api";
import AlertSquareIcon from "../../components/icon/alert-square";
import EmpButton from "../../components/shared/emp-button/emp-button";
import EmpCountrySelect from "../../components/shared/emp-country-select/emp-country-select";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../components/shared/emp-cropper/emp-cropper";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { OnboardingDetailsReqDto } from "../../model/onboarding/onboarding-details-req.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import FileUtils from "../../utilities/file-util";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import "./agency-onboarding-page.scss";
import useEmpAuth from "../../hooks/useEmpAuth";
import useUser from "../../hooks/useUser";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;

export const AgencyOnboardingPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { refreshUser, user } = useUser();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const onboardingId = urlSearchParams.get("onboardingId") ?? undefined;

  const [isLoaded, setIsLoaded] = useState(true);
  const isLogoModifiedRef = useRef(true);
  const [hasSubmitted, setSubmitted] = useState(false);
  const { signOut } = useEmpAuth();

  const [existingImage, setExistingImage] = useState<string>();
  const [base64Logo, setBase64Logo] = useState<string>();
  const [currentImage, setCurrentImage] = useState<string>();

  const [form, setForm] = useState<IFormGroup>();

  const formControlLabel = useMemo(() => {
    return {
      companyNameLabel: intl.formatMessage({
        id: "agencyOnboardingPage_companyNameInputLabel",
      }),
      companyNamePlaceholder: intl.formatMessage({
        id: "agencyOnboardingPage_companyNameInputPlaceholder",
      }),
      addressLabel: intl.formatMessage({
        id: "agencyOnboardingPage_addressInputLabel",
      }),
      addressPlaceholder: intl.formatMessage({
        id: "agencyOnboardingPage_addressInputPlaceholder",
      }),
      postalCodeLabel: intl.formatMessage({
        id: "agencyOnboardingPage_postalCodeInputLabel",
      }),
      postalCodePlaceholder: intl.formatMessage({
        id: "agencyOnboardingPage_postalCodeInputPlaceholder",
      }),
    };
  }, [intl]);

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        companyName: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_companyNameRequired" })
            ),
            new LengthValidator(
              0,
              100,
              undefined,
              "Company Name must not exceed 100 characters"
            ),
          ],
          prevForm?.companyName.getValue() ?? ""
        ),
        country: new FormControl(
          "text",
          [new RequiredValidator("Country is required")],
          prevForm?.country.getValue() ?? ""
        ),
        address: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_addressNameRequired" })
            ),
            new LengthValidator(
              0,
              200,
              undefined,
              "Address must not exceed 200 characters"
            ),
          ],
          prevForm?.address.getValue() ?? ""
        ),
        postalCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_postalCodeRequired" })
            ),
            new LengthValidator(
              0,
              10,
              undefined,
              "Postal code must not exceed 10 characters"
            ),
          ],
          prevForm?.postalCode.getValue() ?? ""
        ),
      };
      if (hasSubmitted) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const translateForm = useCallback(() => {
    const translatedForm = {
      companyName: new FormControl(
        "text",
        [
          new RequiredValidator(
            intl.formatMessage({ id: "validation_companyNameRequired" })
          ),
          new LengthValidator(
            0,
            100,
            undefined,
            "Company Name must not exceed 100 characters"
          ),
        ],
        form?.companyName.getValue() ?? ""
      ),
      country: new FormControl(
        "text",
        [
          new RequiredValidator(
            intl.formatMessage({ id: "validation_countryRequired" })
          ),
        ],
        form?.country.getValue() ?? ""
      ),
      address: new FormControl(
        "text",
        [
          new RequiredValidator(
            intl.formatMessage({ id: "validation_addressNameRequired" })
          ),
          new LengthValidator(
            0,
            200,
            undefined,
            "Address must not exceed 200 characters"
          ),
        ],
        form?.address.getValue() ?? ""
      ),
      postalCode: new FormControl(
        "text",
        [
          new RequiredValidator(
            intl.formatMessage({ id: "validation_postalCodeRequired" })
          ),
          new LengthValidator(
            0,
            10,
            undefined,
            "Postal code must not exceed 10 characters"
          ),
        ],
        form?.postalCode.getValue() ?? ""
      ),
    };
    if (hasSubmitted) FormGroupUtil.validate(translatedForm);
    return translatedForm;
  }, [intl, hasSubmitted]);

  const cropperRef = useRef<EmpCropperRef>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>();

  useEffect(() => {
    const form = translateForm();
    setForm({ ...form });
  }, [intl, translateForm]);

  useEffect(() => {
    const fetchOnboardingDetails = async () => {
      try {
        const form = translateForm();
        console.log(user);
        if (!onboardingId || onboardingId.length === 0) {
          console.log(user);
          form.postalCode.forceUpdateValue("");
          form.address.forceUpdateValue("");
          form.companyName.forceUpdateValue(user?.fullName ?? "");
          form.country.forceUpdateValue("");
          setForm({ ...form });
          setExistingImage(user?.imageResource ?? undefined);
          setCurrentImage(user?.imageResource ?? undefined);
          return;
        }

        const resp = await OnboardingApi.getAgencySpec(onboardingId);
        const onboardingSpec = resp.data;
        form.postalCode.forceUpdateValue(onboardingSpec.postalCode ?? "");
        form.address.forceUpdateValue(onboardingSpec.address ?? "");
        form.companyName.forceUpdateValue(onboardingSpec.companyName ?? "");
        form.country.forceUpdateValue(onboardingSpec.country ?? "");
        setForm({ ...form });
        setExistingImage(onboardingSpec.logo);
        setCurrentImage(onboardingSpec.logo);
      } catch (e) {
        if (e instanceof Error) {
          new EmpExceptionHandlerBuilder()
            .handleCommonlHttpErrors()
            .handleGenericError()
            .build()
            .process(e);
          return;
        }
        ToastUtils.error("An Error Occurred", "Please try again");
      }
    };
    console.log("onboardingId", onboardingId);
    if (!user) return;
    fetchOnboardingDetails();
  }, [user, onboardingId]);

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);

    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 3MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    if (cropperRef.current === undefined) return;
    cropperRef.current.open(base64);
  };

  const onSubmit = async () => {
    try {
      if (!form) return;
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const request: OnboardingDetailsReqDto = {
        isImageModified: isLogoModifiedRef.current,
        image: existingImage,
        imageBase64: base64Logo,
        country: form.country.getValue(),
        companyName: form.companyName.getValue(),
        address: form.address.getValue(),
        postalCode: form.postalCode.getValue(),
      };
      await OnboardingApi.saveAgencySpec(request);
      await setupAgencyReferral();
      await refreshUser();
      navigate("/agency/home");
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const setupAgencyReferral = async () => {
    const resp = await SupportApi.setupAgencyReferral();
    if (resp.data.status === "success") {
    }
  };

  /**
   * This function reads a file as a base64 string.
   * @param file - The file to be read.
   * @returns A promise that resolves with a base64 string.
   */
  const readFileAsBase64 = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64);
      };
    });

  return (
    <div className="emp-agency-onboarding-page">
      <EmpCropper
        onCrop={(base64: string) => {
          setBase64Logo(base64);
          setCurrentImage(base64);
          isLogoModifiedRef.current = true;
        }}
        ref={cropperRef}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingRight: 20,
          paddingTop: 20,
        }}
      ></div>
      {form && (
        <div className="emp-onboarding-info-wrapper">
          <div className="form-wrapper mt-8">
            <motion.div
              className="header-wrapper"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 0 * animationDelay }}
            >
              <img
                className="logo"
                alt="emplifive logo"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
              />
              <p className="description mt-4">
                <FormattedMessage id="agencyOnboardingPage_desc" />
              </p>
            </motion.div>
            <motion.div
              className="image-upload-group"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 1 * animationDelay }}
            >
              {currentImage && <img alt="logo" src={currentImage} />}
              {!currentImage && (
                <div className="empty-div">
                  <span>
                    <FormattedMessage id="agencyOnboardingPage_noLogo" />
                  </span>
                </div>
              )}
              <label className="logo-upload" htmlFor={"logo-upload"}>
                <FormattedMessage id="agencyOnboardingPage_uploadLogo" />
              </label>
              {imageErrorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{imageErrorMessage}</span>
                </div>
              )}
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="logo-upload"
                onChange={handleFileUpload}
                name="myfile"
              ></input>
            </motion.div>
            <motion.div
              className="mt-6"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 2 * animationDelay }}
            >
              <EmpTextInput
                id={"email"}
                formControl={form.companyName}
                labelText={formControlLabel.companyNameLabel}
                required
                placeholder={formControlLabel.companyNamePlaceholder}
              />
            </motion.div>
            <motion.div
              style={{ position: "relative", zIndex: 3 }}
              className="mt-3"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 2 * animationDelay }}
            >
              <EmpCountrySelect
                labelText="Country"
                placeholder="Enter Country"
                id={"country"}
                formControl={form.country}
              />
            </motion.div>
            <motion.div
              className="mt-4"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 3 * animationDelay }}
            >
              <EmpTextInput
                id={"address"}
                formControl={form.address}
                labelText={formControlLabel.addressLabel}
                required
                placeholder={formControlLabel.addressPlaceholder}
              />
            </motion.div>

            <motion.div
              className="mt-4"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 4 * animationDelay }}
            >
              <EmpTextInput
                id={"postalCode"}
                formControl={form.postalCode}
                labelText={formControlLabel.postalCodeLabel}
                required
                placeholder={formControlLabel.postalCodePlaceholder}
              />
            </motion.div>

            <motion.div
              className="mt-10"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 5 * animationDelay }}
            >
              <EmpButton
                text={<FormattedMessage id="cta_letsGo" />}
                onSubmit={() => {
                  onSubmit();
                }}
              />
            </motion.div>

            <motion.div
              className="mt-3"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 6 * animationDelay }}
            >
              <EmpButton
                text={<FormattedMessage id="agencyOnboardingPage_signOutBtn" />}
                buttonStyle="secondary"
                onSubmit={() => {
                  signOut();
                }}
              />
            </motion.div>
          </div>
        </div>
      )}
    </div>
  );
};
