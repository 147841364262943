import { useCallback, useEffect, useState } from "react";
import { MediaSnapshotDto } from "../../model/campaign/media-snapshot.dto";
import "./pow-modal-listing.scss";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import { Color } from "../../utilities/colors";
import TikTokIcon from "../icon/tiktok-icon";
import FileCheckIcon from "../icon/file-check-icon";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import PlayIcon from "../icon/play-icon";
import ShareIcon from "../icon/share-icon";
import { DateUtil } from "../../utilities/date";
import EmpContent from "../shared/emp-content/emp-content";
import EmpLink from "../shared/emp-link/emp-link";
import { EmpImage } from "../shared/emp-image/emp-image";
import { ProfileSnapshotDto } from "../../model/campaign/profile-snapshot.dto";
import {
  EMPTY_PROFILE_IMG_URL,
  EVIDENCE_TYPE_DISPLAY,
} from "../../constants/app.constants";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import BrokenLinkIcon from "../icon/broken-link-icon";
import EmpPill from "../shared/EmpPill/EmpPill";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  mediaSnapshots: MediaSnapshotDto[];
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
  profileSnapshot?: ProfileSnapshotDto;
}

export const TikTokFallbackPow = (props: Props) => {
  const {
    mediaSnapshots,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
    profileSnapshot,
  } = props;
  const [imageItem, setImageItem] = useState<MediaSnapshotDto>();
  const [captionItem, setCaptionItem] = useState<MediaSnapshotDto>();
  useEffect(() => {
    const foundCaptionItem = mediaSnapshots.find(
      (elem) => elem.type === "text"
    );
    setCaptionItem(foundCaptionItem);

    const foundImageItem = mediaSnapshots.find((elem) => elem.type === "image");
    setImageItem(foundImageItem);
  }, [mediaSnapshots]);

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        {imageItem && (
          <img
            className="preview-image"
            alt={"TikTok Video"}
            src={imageItem.mediaUrl}
          />
        )}
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              <span className="card-title">TikTok Video</span>
              <div className="metrics-bar mt-2">
                <div className="metric-wrapper">
                  <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{evidenceRecord.metricSnapshot?.viewCount ?? "-"}</span>
                </div>
                <div className="metric-wrapper">
                  <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
                </div>
                <div className="metric-wrapper">
                  <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>
                    {evidenceRecord.metricSnapshot?.commentCount ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>
                    {evidenceRecord.metricSnapshot?.shareCount ?? "-"}
                  </span>
                </div>
                <div className="metric-wrapper">
                  <div className="download-btn-wrapper">
                    <EmpLink
                      href={evidenceRecord.mediaUrl}
                      newTab
                      text={"Link to Post"}
                      showNewTabIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={BrokenLinkIcon}
              {...PILL_COLORS.gray}
              text={"Archived"}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, imageItem]);

  const renderPowCard = useCallback(() => {
    return (
      <>
        <div className="image-wrapper">
          <div className="sm-top-section">
            <div className="avatar-wrapper">
              <EmpImage
                className="social-media-avatar"
                alt={profileSnapshot?.username ?? "Unknown"}
                src={profileSnapshot?.picture ?? undefined}
                fallback={EMPTY_PROFILE_IMG_URL}
              />
              <div className="social-media-platform-badge">
                <TikTokIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
              </div>
            </div>
            <div className="info-section">
              <span className="name-lbl">
                {profileSnapshot?.username ?? "Unknown"}
              </span>
            </div>
          </div>
          <div className="post-wrapper">
            {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
              <div className={`additional-deliverable-wrapper-badge`}>
                <span className="badge-text">
                  +{noOfOtherEvidenceRecords - 1}
                </span>
                <FileCheckIcon
                  strokeWidth={2.5}
                  size={12}
                  backgroundColor={Color.NEUTRAL[300]}
                />
              </div>
            )}
            {imageItem && (
              <img
                className="attachment-img"
                alt="Uploaded evidence"
                src={imageItem.mediaUrl}
              />
            )}
          </div>
          <div className="sm-metrics-section">
            <div className="metrics-bar">
              <div className="metric-wrapper">
                <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecord.metricSnapshot?.viewCount ?? "-"}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>
                  {evidenceRecord.metricSnapshot?.commentCount ?? "-"}
                </span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                {evidenceRecord.metricSnapshot?.shareCount ?? "-"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, imageItem, noOfOtherEvidenceRecords, profileSnapshot]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <EmpImage
              className="social-media-avatar"
              alt={profileSnapshot?.username ?? "Unknown"}
              src={profileSnapshot?.picture ?? undefined}
              fallback={EMPTY_PROFILE_IMG_URL}
            />
            <div className="social-media-platform-badge">
              <TikTokIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">
              {profileSnapshot?.username ?? "Unknown"}
            </span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          {imageItem && (
            <img
              className="sm-image"
              alt="Uploaded evidence"
              src={imageItem.mediaUrl}
            />
          )}
        </div>

        <div className="sm-metrics">
          <div className="metric-wrapper">
            <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.viewCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.likeCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{evidenceRecord.metricSnapshot?.commentCount ?? "-"}</span>
          </div>
          <div className="metric-wrapper">
            <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            {evidenceRecord.metricSnapshot?.shareCount ?? "-"}
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, imageItem, profileSnapshot]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {imageItem && (
          <EmpImage alt="Proof of work preview" src={imageItem.mediaUrl} />
        )}
      </>
    );
  }, [imageItem]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
