import { motion } from "framer-motion";
import { useRef, useState, useMemo, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormattedMessage, useIntl } from "react-intl";
import LeadApi from "../../../api/admin-msvc/lead.api";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpMultiSelect from "../../../components/shared/emp-multi-select/emp-multi-select";
import EmpSelect from "../../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import {
  getServiceProviderOptions,
  industryOptions,
} from "../../../constants/selectConstants";
import EmpException from "../../../exception/empException";
import { CreateLeadFeedbackDTO } from "../../../model/lead/create-lead-feedback-dto";
import { CreateLeadDto } from "../../../model/lead/create-lead-req.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { EmailValidator } from "../../../utilities/formUtils/emailValidator";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import { ViewType } from "../sign-up-page";
import "./agency-interest-view.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  onViewChange: (viewType: ViewType) => void;
  mode: "unauthenticated-lead" | "sso-lead";
  prefilledDetails?: {
    name: string;
    email: string;
    id: string;
  };
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const ANIMATION_DELAY = 0.1;

export const AgencyInterestView = (props: Props) => {
  const { mode, prefilledDetails } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const referralCode = urlSearchParams.get("code") ?? undefined;
  const submitButtonRef = useRef<EmpButtonRef>();
  const [currentView, setCurrentView] = useState<"form" | "complete">("form");
  const [leadFeedback, setLeadFeedback] = useState<CreateLeadFeedbackDTO>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (mode === "sso-lead") {
      // Setting the form
      if (!prefilledDetails)
        throw new EmpException("Prefilled Details Required");

      setForm((prevForm) => {
        prevForm.fullName.forceUpdateValue(prefilledDetails.name);
        prevForm.email.forceUpdateValue(prefilledDetails.email);
        return { ...prevForm };
      });
    }
  }, [mode, prefilledDetails]);

  const serviceProviderOptions = useMemo(() => {
    return getServiceProviderOptions(intl);
  }, [intl]);

  const formLabelPlaceholder = useMemo(() => {
    return {
      fullNameLabel: intl.formatMessage({
        id: "agencyInterestView_fullNameLabel",
      }),
      fullNamePlaceholder: intl.formatMessage({
        id: "agencyInterestView_fullNameLabel",
      }),
      companyNameLabel: intl.formatMessage({
        id: "agencyInterestView_companyNameLabel",
      }),
      companyNamePlaceholder: intl.formatMessage({
        id: "agencyInterestView_companyNamePlaceholder",
      }),
      emailLabel: intl.formatMessage({ id: "agencyInterestView_emailLabel" }),
      emailPlaceholder: intl.formatMessage({
        id: "agencyInterestView_emailPlaceholder",
      }),
      websiteLabel: intl.formatMessage({
        id: "agencyInterestView_websiteLabel",
      }),
      websitePlaceholder: intl.formatMessage({
        id: "agencyInterestView_websitePlaceholder",
      }),
      industryLabel: intl.formatMessage({
        id: "agencyInterestView_industryLabel",
      }),
      industryPlaceholder: intl.formatMessage({
        id: "agencyInterestView_industryPlaceholder",
      }),
      jobPositionLabel: intl.formatMessage({
        id: "agencyInterestView_jobPositionLabel",
      }),
      jobPositionPlaceholder: intl.formatMessage({
        id: "agencyInterestView_jobPositionPlaceholder",
      }),
      remarksLabel: intl.formatMessage({
        id: "agencyInterestView_remarksLabel",
      }),
      remarksPlaceholder: intl.formatMessage({
        id: "agencyInterestView_remarksPlaceholder",
      }),
      serviceProvidedLabel: intl.formatMessage({
        id: "agencyInterestView_serviceProvidedLabel",
      }),
      serviceProvidedPlaceholder: intl.formatMessage({
        id: "agencyInterestView_serviceProvidedPlaceholder",
      }),
    };
  }, [intl]);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [form, setForm] = useState<IFormGroup>({
    fullName: new FormControl("text", [
      new RequiredValidator("Full name is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Full name must not exceed 100 characters"
      ),
    ]),

    companyName: new FormControl("text", [
      new RequiredValidator("Company name is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Company name must not exceed 100 characters"
      ),
    ]),

    email: new FormControl("text", [
      new RequiredValidator("Email is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Email must not exceed 100 characters"
      ),
      new EmailValidator(),
    ]),
    website: new FormControl("text", [
      new PatternValidator(
        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
        "Invalid URL"
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        "Website must not exceed 100 characters"
      ),
    ]),
    jobPosition: new FormControl("text", [
      new RequiredValidator("Job Position is required"),
      new LengthValidator(
        0,
        50,
        undefined,
        "Job Position must not exceed 50 characters"
      ),
    ]),
    industry: new FormControl("text", [
      new RequiredValidator("Please select the industry"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Industry must not exceed 30 characters"
      ),
    ]),
    sendEmail: new FormControl("checkbox-group", []),
    services: new FormControl("checkbox-group", [
      new RequiredValidator("Please enter at least one domain of serivice"),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        0,
        500,
        undefined,
        "Remarks must not exceed 300 characters"
      ),
    ]),
  });

  const validate = (formControl: FormControl) => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const submitLead = async () => {
    try {
      setHasSubmitted(true);
      if (!executeRecaptcha) {
        throw new EmpException("Recaptcha is not ready");
      }
      const token = await executeRecaptcha("SendEnterpriseInterest");

      submitButtonRef.current?.setButtonState("loading");
      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!formIsValid) return;

      const leadRequest: CreateLeadDto = {
        token,
        fullName: form.fullName.getValue(),
        companyName: form.companyName.getValue(),
        email: form.email.getValue(),
        website: form.website.getValue(),
        industry: form.industry.getValue(),
        jobPosition: form.jobPosition.getValue(),
        remarks: form.remarks.getValue(),
        source: "sign-in",
        serviceIds: form.services.getValue(),
        type: "agency",
        mode: mode,
        userId: mode === "sso-lead" ? prefilledDetails!.id : undefined,
        referralCode,
      };

      console.log(leadRequest);
      const response = await LeadApi.createAgencyLead(leadRequest);
      if (response.status === "success") {
        setLeadFeedback(response.data);
        ToastUtils.success(
          "Interest Submitted",
          "We have received your submission."
        );
        setCurrentView("complete");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to create a submission"
      );
    } finally {
      submitButtonRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="emp-agency-interest-view">
      <div className="form-wrapper ">
        <div className="back-btn-wrapper">
          <EmpIconButton
            buttonStyle="secondary"
            icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[600]} />}
            onSubmit={() => {
              props.onViewChange("role-selection");
            }}
          />
        </div>
        {currentView === "form" && (
          <>
            <div className="title-wrapper">
              <img
                className="creatorfi-logo"
                alt="creatorfi logo"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
              />
              <h1 className="mt-4">
                <FormattedMessage id="agencyInterestView_header" />
              </h1>
              <p className="emp-paragraph">
                <FormattedMessage id="agencyInterestView_desc" />
              </p>
            </div>
            <div className="form-row mt-10">
              <div className="left-group">
                <EmpTextInput
                  id={"full-name"}
                  placeholder={formLabelPlaceholder.fullNamePlaceholder}
                  labelText={formLabelPlaceholder.fullNameLabel}
                  formControl={form.fullName}
                  onChange={(formControl) => validate(formControl)}
                  required
                />
              </div>
              <div className="right-group">
                <EmpTextInput
                  id={"company-name"}
                  placeholder={formLabelPlaceholder.companyNamePlaceholder}
                  labelText={formLabelPlaceholder.companyNameLabel}
                  formControl={form.companyName}
                  onChange={(formControl) => validate(formControl)}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="left-group">
                <EmpTextInput
                  id={"email"}
                  placeholder={formLabelPlaceholder.emailPlaceholder}
                  labelText={formLabelPlaceholder.emailLabel}
                  formControl={form.email}
                  onChange={(formControl) => validate(formControl)}
                  required
                />
              </div>
              <div className="right-group">
                <EmpTextInput
                  id={"website"}
                  placeholder={formLabelPlaceholder.websitePlaceholder}
                  labelText={formLabelPlaceholder.websiteLabel}
                  formControl={form.website}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="left-group">
                <EmpTextInput
                  id={"jobPosition"}
                  required
                  placeholder={formLabelPlaceholder.jobPositionPlaceholder}
                  labelText={formLabelPlaceholder.jobPositionLabel}
                  onChange={(formControl) => validate(formControl)}
                  formControl={form.jobPosition}
                />
              </div>
              <div className="right-group">
                <EmpSelect
                  id={"industry"}
                  required
                  placeholder={formLabelPlaceholder.industryPlaceholder}
                  labelText={formLabelPlaceholder.industryLabel}
                  selectOptions={industryOptions}
                  onChange={(formControl) => validate(formControl)}
                  formControl={form.industry}
                />
              </div>
            </div>
            <EmpTextInput
              id={"remarks"}
              placeholder={formLabelPlaceholder.remarksPlaceholder}
              labelText={formLabelPlaceholder.remarksLabel}
              characterCount={500}
              multiline
              rows={2}
              formControl={form.remarks}
            />

            <EmpMultiSelect
              labelText={formLabelPlaceholder.serviceProvidedLabel}
              selectOptions={serviceProviderOptions}
              id={"services"}
              placeholder={formLabelPlaceholder.serviceProvidedPlaceholder}
              onChange={(formControl) => validate(formControl)}
              formControl={form.services}
            />

            <p className="recaptcha-description">
              <FormattedMessage
                id="agencyInterestView_footer"
                values={{
                  privacy: (
                    <a
                      href="https://policies.google.com/privacy"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage id="privacyPolicyLink" />
                    </a>
                  ),
                  termsOfUse: (
                    <a
                      href="https://policies.google.com/terms"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage id="termsOfUseLink" />
                    </a>
                  ),
                }}
              />
            </p>
            <div className="button-wrapper-row">
              <EmpButton
                className="mt-2"
                ref={submitButtonRef}
                onSubmit={submitLead}
                isFullWidth={false}
                text={<FormattedMessage id="cta_submit" />}
              />
            </div>
          </>
        )}
        {currentView === "complete" && leadFeedback && (
          <>
            <div className="title-wrapper">
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: ANIMATION_DELAY * 0 }}
              >
                <img
                  className="creatorfi-logo"
                  alt="creatorfi logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
              </motion.div>
              <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: ANIMATION_DELAY * 1 }}
              >
                <h1 className="mt-4" style={{ textAlign: "center" }}>
                  <FormattedMessage id="agencyInterestView_feedbackTitle" />
                </h1>
                <p style={{ textAlign: "center" }}>
                  <FormattedMessage
                    id="agencyInterestView_feedbackDesc"
                    values={{ email: leadFeedback?.email }}
                  />{" "}
                </p>
              </motion.div>
            </div>

            <motion.div
              className="button-wrapper-row centered"
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: ANIMATION_DELAY * 2 }}
            >
              <EmpButton
                className="mt-2"
                ref={submitButtonRef}
                onSubmit={() => {
                  navigate("/sign-in");
                }}
                isFullWidth={false}
                text={<FormattedMessage id="cta_home" />}
              />
            </motion.div>
          </>
        )}
      </div>
    </div>
  );
};
