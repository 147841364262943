import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import StringUtils from "../../../utilities/string.util";
import "./seller-task-payment-view.scss";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import AnnouncementIcon from "../../../components/icon/announcement-icon";
import FileAttachmentIcon from "../../../components/icon/file-attachment-icon";
import { CampaignPayoutRecordDto } from "../../../model/payment/payment/campaign-payout-record.dto";
import {
  CAMPAIGN_RESOURCE_TYPE,
  TASK_PAYOUT_STATUS,
  TASK_STATUS,
} from "../../../constants/app.constants";
import EmpTable, {
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import FormFieldUtils from "../../../utilities/form-field.util";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import { DateUtil } from "../../../utilities/date";
import CampaignSummaryApi from "../../../api/campaign-msvc/campaign-summary.api";
import EmpException from "../../../exception/empException";
import { TaskDeliverableCompletionBreakdownDto } from "../../../model/campaign/task-deliverable-completion-breakdown.dto";
import { EmpCampaignAccessControlView } from "../../../components/shared/emp-campaign-access-control-view/emp-campaign-access-control-view";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "../../../components/modals/campaign-guide-modal";
import HelpCircleIcon from "../../../components/icon/help-circle-icon";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import { SELLER_PAYMENT_PROCESS_GUIDE } from "../../../constants/campaign-guide";
import CampaignPayoutApi from "../../../api/campaign-msvc/campaign-payout.api";
import PayoutApi from "../../../api/campaign-msvc/campaign-payout.api";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task: TaskDto;
  user: UserDto;
}

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

interface PaymentMetrics {
  totalTaskAmount: number;
  numCreatorsEligibleForPayouts: number;
  amountHeldOnEmplifive: number;
  amountDisbursed: number;
}

interface PayoutRecord extends CampaignPayoutRecordDto {
  pendingCount: number;
  completedCount: number;
  totalDeliverables: number;
}

const DELAY_MS = 0.2;
export const SellerTaskPaymentView = (props: Props) => {
  const { task, user } = props;
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const payoutContentSpec: EmpTableContentSpec<PayoutRecord>[] = useMemo(() => {
    return [
      {
        title: "Recipient",
        dataIndex: "creator.representative",
        render: (record: PayoutRecord) => {
          return (
            <div className="representative-wrapper">
              <div className="profile-wrapper">
                {record.creatorRepresentative.imageType === "url" && (
                  <img
                    className="profile"
                    src={record.creatorRepresentative.imageResource}
                    alt={record.creatorRepresentative.fullName}
                  />
                )}
                {record.creatorRepresentative.imageType === "avatar" && (
                  <div
                    className="profile"
                    style={{
                      background: record.creatorRepresentative.imageResource,
                    }}
                  >
                    <span className="initials">
                      {record.creatorRepresentative.initials}
                    </span>
                  </div>
                )}
                {record.representativeRole === "agency" && (
                  <img
                    className="profile profile-badge"
                    src={record.agencyRepresentative!.logo}
                    alt={record.agencyRepresentative!.companyName}
                  />
                )}
              </div>
              <div className="info-wrapper ml-3">
                {record.representativeRole === "agency" && (
                  <EmpLink text={record.agencyRepresentative!.companyName} />
                )}
                {record.representativeRole === "agency" && (
                  <span className="block subtitle-lbl">
                    Manages {record.creatorRepresentative.fullName}
                  </span>
                )}

                {record.representativeRole === "creator" && (
                  <EmpLink text={record.creatorRepresentative.fullName} />
                )}
                {record.representativeRole === "creator" && (
                  <span className="block subtitle-lbl">Freelancer</span>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: "Deliverable Completion",
        dataIndex: "deliverableCompletion",
        render: (record: PayoutRecord) => {
          return (
            <div className="completion-wrapper">
              <span className="task-completion-label">
                {record.completedCount}/{record.totalDeliverables} Completed
              </span>
              <div className="bar-wrapper">
                {Array.from({ length: record.completedCount }, (_, i) => i).map(
                  (elem) => {
                    return (
                      <div
                        key={elem}
                        className="bar completed"
                        style={{ flex: 1 }}
                      ></div>
                    );
                  }
                )}
                {Array.from({ length: record.pendingCount }, (_, i) => i).map(
                  (elem) => {
                    return (
                      <div
                        key={elem}
                        className="bar pending"
                        style={{ flex: 1 }}
                      ></div>
                    );
                  }
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "payoutAmount",
        render: (record: PayoutRecord) => {
          return (
            <div style={{ display: "flex", gap: 4 }}>
              {FormFieldUtils.formatNumber(Number(record.amount))}{" "}
              <EmpCoinIcon
                iconValue={task.paymentMode}
                mode="icon-only"
                size={20}
                rounded
              />
            </div>
          );
        },
      },
      {
        title: "Payout Status",
        dataIndex: "payout-status",
        render: (record: PayoutRecord) => {
          return (
            <>
              {record.status === TASK_PAYOUT_STATUS.AWAITING_COMPLETION && (
                <EmpPill {...PILL_COLORS.amber} text={"Awaiting Completion"} />
              )}
              {record.status === TASK_PAYOUT_STATUS.OBSERVATION_WINDOW && (
                <EmpPill {...PILL_COLORS.amber} text={"Observation Window"} />
              )}
              {record.status === TASK_PAYOUT_STATUS.PAYOUT_COMPLETED && (
                <EmpPill {...PILL_COLORS.green} text={"Payout Completed"} />
              )}
              {record.status === TASK_PAYOUT_STATUS.PAYOUT_FAILED && (
                <EmpPill {...PILL_COLORS.red} text={"Payout Failed"} />
              )}
              {record.status === TASK_PAYOUT_STATUS.PAYOUT_REFUNDED && (
                <EmpPill {...PILL_COLORS.green} text={"Payout Refunded"} />
              )}
            </>
          );
        },
      },
      {
        title: "Observation Period",
        dataIndex: "observation-period",
        render: (record: PayoutRecord) => {
          return (
            <>
              {record.status !== TASK_PAYOUT_STATUS.AWAITING_COMPLETION && (
                <span>
                  Until {DateUtil.toReadableDate(record.observationEndDate)}
                </span>
              )}
              {record.status === TASK_PAYOUT_STATUS.AWAITING_COMPLETION && (
                <span>Deliverables not completed</span>
              )}
            </>
          );
        },
      },
      {
        title: "Payout Date",
        dataIndex: "payout-date",
        render: (record: PayoutRecord) => {
          return (
            <>
              {record.status === TASK_PAYOUT_STATUS.PAYOUT_COMPLETED && (
                <span>
                  Paid at {DateUtil.toReadableDate(record.payoutCompletedDate)}
                </span>
              )}
              {record.status === TASK_PAYOUT_STATUS.OBSERVATION_WINDOW && (
                <span>
                  Scheduled at{" "}
                  {DateUtil.toReadableDate(record.observationEndDate)}
                </span>
              )}
              {![
                TASK_PAYOUT_STATUS.PAYOUT_COMPLETED,
                TASK_PAYOUT_STATUS.OBSERVATION_WINDOW,
              ].includes(record.status) && <span>No Payment Made</span>}
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "blank",
        render: (record: PayoutRecord) => {
          return <></>;
        },
      },
    ];
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [paymentMetrics, setPaymentMetrics] = useState<PaymentMetrics>();
  const [payoutRecords, setPayoutRecords] = useState<PayoutRecord[]>([]);
  const [accessControlGranted, setAccessControlGranted] = useState(false);
  const [accessControlVisible, setAccessControlVisible] = useState(true);

  const fetchBrandTaskPayoutRecords = useCallback(async (): Promise<
    CampaignPayoutRecordDto[]
  > => {
    try {
      const resp = await PayoutApi.fetchPayoutSummary(task.id, "seller");
      const payoutRecords = resp.data;
      return payoutRecords;
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Fetching Brand Task Payout Records"
      );
      throw new EmpException("Error Fetching Brand Task Payout");
    } finally {
      setLoading(false);
    }
  }, [task.id]);

  const fetchDeliverableStatus = useCallback(async (): Promise<
    TaskDeliverableCompletionBreakdownDto[]
  > => {
    try {
      const resp =
        await CampaignSummaryApi.fetchTaskDeliverableBreakdownBySeller(task.id);
      return resp.data;
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Fetching Brand Task Payout Records"
      );
      throw new EmpException("Unable to fetch deliverable status");
    } finally {
      setLoading(false);
    }
  }, [task.id]);

  const prepareTableRecords = useCallback(async () => {
    try {
      setLoading(true);
      Promise.all([
        fetchDeliverableStatus(),
        fetchBrandTaskPayoutRecords(),
      ]).then(([deliverableStatusList, payoutRecords]) => {
        const deliverableStatusMap = new Map<
          string,
          TaskDeliverableCompletionBreakdownDto
        >();
        deliverableStatusList.forEach((elem) =>
          deliverableStatusMap.set(elem.creatorUserId, elem)
        );
        const records: PayoutRecord[] = [];
        payoutRecords.forEach((elem) => {
          let breakdown;
          if (deliverableStatusMap.has(elem.creatorRepresentative.id)) {
            const mapValue = deliverableStatusMap.get(
              elem.creatorRepresentative.id
            )!;
            breakdown = {
              pendingCount: mapValue.pendingCount,
              completedCount: mapValue.completedCount,
              totalDeliverables: mapValue.totalDeliverables,
            };
          } else {
            breakdown = {
              pendingCount: -1,
              completedCount: 0,
              totalDeliverables: 0,
            };
          }
          records.push({
            ...elem,
            ...breakdown,
          });
        });
        setPayoutRecords(records);

        // Preparing the payment metrics
        const metrics: PaymentMetrics = {
          totalTaskAmount: parseFloat(
            payoutRecords
              .reduce((accumulator, current) => {
                return accumulator + Number(current.amount);
              }, 0)
              .toFixed(6)
          ),
          numCreatorsEligibleForPayouts: payoutRecords.reduce(
            (accumulator, current) => {
              if (current.status !== TASK_PAYOUT_STATUS.AWAITING_COMPLETION) {
                return accumulator + 1;
              }
              return accumulator;
            },
            0
          ),
          amountHeldOnEmplifive: parseFloat(
            payoutRecords
              .reduce((accumulator, current) => {
                if (current.status !== TASK_PAYOUT_STATUS.PAYOUT_COMPLETED) {
                  return accumulator + Number(current.amount);
                }
                return accumulator;
              }, 0)
              .toFixed(6)
          ),
          amountDisbursed: parseFloat(
            payoutRecords
              .reduce((accumulator, current) => {
                if (current.status === TASK_PAYOUT_STATUS.PAYOUT_COMPLETED) {
                  return accumulator + Number(current.amount);
                }
                return accumulator;
              }, 0)
              .toFixed(6)
          ),
        };
        setPaymentMetrics(metrics);
      });
    } catch (error) {
      console.error(error);
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Fetching Brand Task Payout Records"
      );
    } finally {
      setLoading(false);
    }
  }, [fetchBrandTaskPayoutRecords, fetchDeliverableStatus]);

  useEffect(() => {
    if (accessControlGranted) {
      prepareTableRecords();
    }
  }, [prepareTableRecords, accessControlGranted]);

  return (
    <div className="emp-seller-task-payment-view">
      <CampaignGuideModal ref={campaignGuideModalRef} />
      {accessControlVisible && (
        <EmpCampaignAccessControlView
          backgroundColor="gray"
          resourceType={CAMPAIGN_RESOURCE_TYPE.ONGOING_TASK_PAYMENT}
          role="seller"
          requestSpecs={{
            taskId: task.id,
            campaignId: task.campaign.id,
          }}
          onGranted={() => {
            setAccessControlGranted(true);
          }}
          onUnmount={() => {
            setAccessControlVisible(false);
          }}
        />
      )}
      <section className="task-details-section">
        <div className="task-name-wrapper">
          <div className="info-wrapper">
            <h2 className="section-label">Task Payment</h2>
            {task.status === TASK_STATUS.ONGOING && (
              <EmpPill
                text={StringUtils.capitalizeWords(task.status)}
                {...PILL_COLORS.primary}
              />
            )}
            {task.status === TASK_STATUS.COMPLETED && (
              <EmpPill
                text={StringUtils.capitalizeWords(task.status)}
                {...PILL_COLORS.green}
              />
            )}
            <EmpIconButton
              onSubmit={() => {
                campaignGuideModalRef.current?.show(
                  SELLER_PAYMENT_PROCESS_GUIDE
                );
              }}
              buttonStyle="secondary"
              suppressMobileView
              icon={
                <HelpCircleIcon
                  backgroundColor={Color.NEUTRAL[400]}
                  size={18}
                />
              }
            />
          </div>
        </div>
      </section>

      {accessControlGranted && (
        <>
          {!isLoading && paymentMetrics && (
            <section className="payment-section mt-4">
              <div className="metrics-tile-wrapper">
                <motion.div
                  className="metric-tile"
                  initial="hidden"
                  animate={"visible"}
                  transition={{ delay: 1.5 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                >
                  <div className="details-wrapper">
                    <div className="metric-wrapper">
                      <span className="metric-value">
                        {paymentMetrics.totalTaskAmount}
                      </span>
                      <EmpCoinIcon
                        iconValue={task.paymentMode}
                        rounded
                        mode="icon-only"
                        size={30}
                      />
                    </div>

                    <AnnouncementIcon
                      top={2}
                      size={24}
                      backgroundColor={Color.NEUTRAL[400]}
                    />
                  </div>
                  <span className="metric-header">Total Task Amount</span>
                </motion.div>
                <motion.div
                  className="metric-tile"
                  initial="hidden"
                  animate={"visible"}
                  transition={{ delay: 2 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                >
                  <div className="details-wrapper">
                    <span className="metric-value">
                      {paymentMetrics.numCreatorsEligibleForPayouts}
                    </span>
                    <FileAttachmentIcon
                      top={2}
                      size={24}
                      backgroundColor={Color.NEUTRAL[400]}
                    />
                  </div>
                  <span className="metric-header">No. Eligible Creators</span>
                </motion.div>
                <motion.div
                  className="metric-tile"
                  initial="hidden"
                  animate={"visible"}
                  transition={{ delay: 2.5 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                >
                  <div className="details-wrapper">
                    <div className="metric-wrapper">
                      <span className="metric-value">
                        {paymentMetrics.amountHeldOnEmplifive}
                      </span>
                      <EmpCoinIcon
                        iconValue={task.paymentMode}
                        rounded
                        mode="icon-only"
                        size={30}
                      />
                    </div>
                    <FileAttachmentIcon
                      top={2}
                      size={24}
                      backgroundColor={Color.NEUTRAL[400]}
                    />
                  </div>
                  <span className="metric-header">
                    Amount Held By Emplifive
                  </span>
                </motion.div>
                <motion.div
                  className="metric-tile"
                  initial="hidden"
                  animate={"visible"}
                  transition={{ delay: 3 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                >
                  <div className="details-wrapper">
                    <span className="metric-value">
                      {paymentMetrics.amountDisbursed}
                    </span>
                    <FileAttachmentIcon
                      top={2}
                      size={24}
                      backgroundColor={Color.NEUTRAL[400]}
                    />
                  </div>
                  <span className="metric-header">Amount Disbursed</span>
                </motion.div>
              </div>
            </section>
          )}

          <section className="payouts-table-section mt-8">
            <h2>Payout Details</h2>
            <p className="emp-paragraph mt-2">
              Please refer to the table below for detailed information on the
              creator status, payable amount, payout schedules, and observation
              window. If you need additional assistance, feel free to reach out
              to your designated Customer Success Manager. Alternatively, you
              can access our comprehensive self-help documentation here for more
              guidance.
            </p>
            <div className="payouts-table emp-card no-padding mt-4">
              <EmpTable
                contentColumns={payoutContentSpec}
                data={payoutRecords}
                rowKey={"id"}
              />
            </div>
          </section>
        </>
      )}
    </div>
  );
};
