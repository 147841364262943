import { IntlShape } from "react-intl";
import StringUtils from "./string.util";

class TranslationUtil {
  static getCount(amount: number): string | number {
    if (amount === 0) return 0;
    else if (amount === 1) return 1;
    else return "other";
  }

  static translateRateCardName(
    intl: IntlShape,
    platform: string,
    rateName: string
  ) {
    const facebookMap: { [key: string]: string } = {
      "Static Post": intl.formatMessage({
        id: "facebookTemplateRate_staticPostHeader",
      }),
      "Carousel Image": intl.formatMessage({
        id: "facebookTemplateRate_carouselImageHeader",
      }),
      Story: intl.formatMessage({ id: "facebookTemplateRate_storyHeader" }),
      "Story Series": intl.formatMessage({
        id: "facebookTemplateRate_storySeriesHeader",
      }),
      "Video Post": intl.formatMessage({
        id: "facebookTemplateRate_videoPostHeader",
      }),
    };
    const instagramMap: { [key: string]: string } = {
      "Static Post": intl.formatMessage({
        id: "instagramTemplateRate_staticPostHeader",
      }),
      "Image Carousel Post": intl.formatMessage({
        id: "instagramTemplateRate_imageCarouselPostHeader",
      }),
      "Video Carousel Post": intl.formatMessage({
        id: "instagramTemplateRate_videoCarouselPostHeader",
      }),
      "Video Post": intl.formatMessage({
        id: "instagramTemplateRate_videoPostHeader",
      }),
      "Story Post": intl.formatMessage({
        id: "instagramTemplateRate_storyHeader",
      }),
      "Story Series": intl.formatMessage({
        id: "instagramTemplateRate_storySeriesHeader",
      }),
      "Reel Video": intl.formatMessage({
        id: "instagramTemplateRate_reelVideoHeader",
      }),
      "Livestream (30 mins)": intl.formatMessage({
        id: "instagramTemplateRate_livestreamHeader",
      }),
    };
    const tiktokMap: { [key: string]: string } = {
      "Video Post (1 min)": intl.formatMessage({
        id: "tiktokTemplateRate_videoPostHeader",
      }),
      "Livestream (30 mins)": intl.formatMessage({
        id: "tiktokTemplateRate_livestreamPromotionHeader",
      }),
      "Dedicated Link on bio (7 days)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink1Header",
      }),
      "Dedicated Link on bio (1 month)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink2Header",
      }),
      "Dedicated Link on bio (3 months)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink3Header",
      }),
    };

    const xMap: { [key: string]: string } = {
      "Standard Tweet": intl.formatMessage({
        id: "xTemplateRate_standardTweetHeader",
      }),
      "Extended Tweet": intl.formatMessage({
        id: "xTemplateRate_extendedTweetHeader",
      }),
      Retweet: intl.formatMessage({
        id: "xTemplateRate_retweetHeader",
      }),
      "Basic Tweet Thread": intl.formatMessage({
        id: "xTemplateRate_basicTweetThreadHeader",
      }),
      "Graphic Tweet Thread": intl.formatMessage({
        id: "xTemplateRate_graphicTweetThreadHeader",
      }),
      "Video Tweet": intl.formatMessage({
        id: "xTemplateRate_videoTweetHeader",
      }),
      "Twitter Space Session": intl.formatMessage({
        id: "xTemplateRate_twitterSpaceSessionHeader",
      }),
      "Live Stream": intl.formatMessage({
        id: "xTemplateRate_liveStreamHeader",
      }),
      "Bio Feature": intl.formatMessage({
        id: "xTemplateRate_bioFeatureHeader",
      }),
      "Quote Retweet": intl.formatMessage({
        id: "xTemplateRate_quoteRetweetHeader",
      }),
    };

    if (platform === "Facebook") {
      return facebookMap[rateName] ?? "Undefined rate name";
    } else if (platform === "Instagram") {
      return instagramMap[rateName] ?? "Undefined rate name";
    } else if (platform === "TikTok") {
      return tiktokMap[rateName] ?? "Undefined rate name";
    } else if (platform === "X") {
      return xMap[rateName] ?? "Undefined rate name";
    }
    return "undefined rate name";
  }

  static translateRateCardDescription(
    intl: IntlShape,
    platform: string,
    rateName: string
  ) {
    const facebookMap: { [key: string]: string } = {
      "Static Post": intl.formatMessage({
        id: "facebookTemplateRate_staticPostDesc",
      }),
      "Carousel Image": intl.formatMessage({
        id: "facebookTemplateRate_carouselImageDesc",
      }),
      Story: intl.formatMessage({ id: "facebookTemplateRate_storyDesc" }),
      "Story Series": intl.formatMessage({
        id: "facebookTemplateRate_storySeriesDesc",
      }),
      "Video Post": intl.formatMessage({
        id: "facebookTemplateRate_videoPostDesc",
      }),
    };
    const instagramMap: { [key: string]: string } = {
      "Static Post": intl.formatMessage({
        id: "instagramTemplateRate_staticPostDesc",
      }),
      "Image Carousel Post": intl.formatMessage({
        id: "instagramTemplateRate_imageCarouselPostDesc",
      }),
      "Video Carousel Post": intl.formatMessage({
        id: "instagramTemplateRate_videoCarouselPostDesc",
      }),
      "Video Post": intl.formatMessage({
        id: "instagramTemplateRate_videoPostDesc",
      }),
      "Story Post": intl.formatMessage({
        id: "instagramTemplateRate_storyDesc",
      }),
      "Story Series": intl.formatMessage({
        id: "instagramTemplateRate_storySeriesDesc",
      }),
      "Reel Video": intl.formatMessage({
        id: "instagramTemplateRate_reelVideoDesc",
      }),
      "Livestream (30 mins)": intl.formatMessage({
        id: "instagramTemplateRate_livestreamDesc",
      }),
    };
    const tiktokMap: { [key: string]: string } = {
      "Video Post (1 min)": intl.formatMessage({
        id: "tiktokTemplateRate_videoPostDesc",
      }),
      "Livestream (30 mins)": intl.formatMessage({
        id: "tiktokTemplateRate_livestreamPromotionDesc",
      }),
      "Dedicated Link on bio (7 days)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink1Desc",
      }),
      "Dedicated Link on bio (1 month)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink2Desc",
      }),
      "Dedicated Link on bio (3 months)": intl.formatMessage({
        id: "tiktokTemplateRate_dedicatedLink3Desc",
      }),
    };

    const xMap: { [key: string]: string } = {
      "Standard Tweet": intl.formatMessage({
        id: "xTemplateRate_standardTweetDesc",
      }),
      "Extended Tweet": intl.formatMessage({
        id: "xTemplateRate_extendedTweetDesc",
      }),
      Retweet: intl.formatMessage({
        id: "xTemplateRate_retweetDesc",
      }),
      "Basic Tweet Thread": intl.formatMessage({
        id: "xTemplateRate_basicTweetThreadDesc",
      }),
      "Graphic Tweet Thread": intl.formatMessage({
        id: "xTemplateRate_graphicTweetThreadDesc",
      }),
      "Video Tweet": intl.formatMessage({
        id: "xTemplateRate_videoTweetDesc",
      }),
      "Twitter Space Session": intl.formatMessage({
        id: "xTemplateRate_twitterSpaceSessionDesc",
      }),
      "Live Stream": intl.formatMessage({
        id: "xTemplateRate_liveStreamDesc",
      }),
      "Bio Feature": intl.formatMessage({
        id: "xTemplateRate_bioFeatureDesc",
      }),
      "Quote Retweet": intl.formatMessage({
        id: "xTemplateRate_quoteRetweetDesc",
      }),
    };

    if (platform === "Facebook") {
      return facebookMap[rateName] ?? "Undefined rate name";
    } else if (platform === "Instagram") {
      return instagramMap[rateName] ?? "Undefined rate name";
    } else if (platform === "TikTok") {
      return tiktokMap[rateName] ?? "Undefined rate name";
    } else if (platform === "X") {
      return xMap[rateName] ?? "Undefined rate name";
    }
    return "undefined rate name";
  }

  static translateMemberRole(intl: IntlShape, role: string) {
    const roleMap: { [key: string]: string } = {
      Root: intl.formatMessage({
        id: "inviteTeamMemberModal_accessPrivilegeOptionRoot",
      }),
      Administrator: intl.formatMessage({
        id: "inviteTeamMemberModal_accessPrivilegeOptionAdministrator",
      }),
      Maintainer: intl.formatMessage({
        id: "inviteTeamMemberModal_accessPrivilegeOptionManager",
      }),
      Member: intl.formatMessage({
        id: "inviteTeamMemberModal_accessPrivilegeOptionMember",
      }),
    };
    return roleMap[role] ?? role;
  }

  static translateCreatorInterest(
    intl: IntlShape,
    interestId: number,
    fallback: string
  ) {
    const interestMap: { [key: number]: any } = {
      3: intl.formatMessage({ id: "creatorInterest_fashionOption" }),
      4: intl.formatMessage({ id: "creatorInterest_travelOption" }),
      5: intl.formatMessage({ id: "creatorInterest_adventureOption" }),
      6: intl.formatMessage({ id: "creatorInterest_foodOption" }),
      7: intl.formatMessage({ id: "creatorInterest_photographyOption" }),
      8: intl.formatMessage({ id: "creatorInterest_gamingOption" }),
      9: intl.formatMessage({ id: "creatorInterest_beautyOption" }),
      10: intl.formatMessage({ id: "creatorInterest_technologyOption" }),
      11: intl.formatMessage({ id: "creatorInterest_DIYOption" }),
      12: intl.formatMessage({ id: "creatorInterest_interiorDesignOption" }),
      13: intl.formatMessage({ id: "creatorInterest_musicOption" }),
      14: intl.formatMessage({ id: "creatorInterest_sportsOption" }),
      15: intl.formatMessage({ id: "creatorInterest_literatureOption" }),
      16: intl.formatMessage({ id: "creatorInterest_artOption" }),
      17: intl.formatMessage({ id: "creatorInterest_parentingOption" }),
      18: intl.formatMessage({ id: "creatorInterest_healthOption" }),
      19: intl.formatMessage({ id: "creatorInterest_sustainabilityOption" }),
      20: intl.formatMessage({ id: "creatorInterest_comedyOption" }),
      21: intl.formatMessage({
        id: "creatorInterest_personalDevelopmentOption",
      }),
      22: intl.formatMessage({ id: "creatorInterest_scienceOption" }),
    };
    return interestMap[interestId] ?? fallback;
  }

  static translateCreatorService(
    intl: IntlShape,
    serviceId: number,
    fallback: string
  ) {
    const serviceMap: { [key: number]: any } = {
      1: intl.formatMessage({ id: "creatorService_productReviewsOption" }),
      2: intl.formatMessage({ id: "creatorService_podcastingOption" }),
      3: intl.formatMessage({ id: "creatorService_eventHostingOption" }),
      4: intl.formatMessage({ id: "creatorService_liveStreamingOption" }),
      5: intl.formatMessage({ id: "creatorService_videoProductionOption" }),
      6: intl.formatMessage({ id: "creatorService_bloggingOption" }),
      7: intl.formatMessage({ id: "creatorService_onlineTeachingOption" }),
      8: intl.formatMessage({ id: "creatorService_socialMediaPostOption" }),
      10: intl.formatMessage({ id: "creatorService_unboxingOption" }),
      9: intl.formatMessage({ id: "creatorService_eventAppearanceOption" }),
    };
    return serviceMap[serviceId] ?? fallback;
  }

  static translateGender(intl: IntlShape, gender: string) {
    const genderMap: { [key: string]: any } = {
      male: intl.formatMessage({ id: "genderOption_male" }),
      female: intl.formatMessage({ id: "genderOption_female" }),
    };
    return genderMap[gender] ?? StringUtils.capitalizeWords(gender);
  }
}
export default TranslationUtil;
