import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EmpTable, {
  EmpTableCollapsible,
  EmpTableContentSpec,
  EmpTableProps,
  EmpTableRef,
} from "../shared/EmpTable/EmpTable";
import "./campaign-analytics-breakdown-table.scss";
import {
  AggregatedMetrics,
  CampaignAnalyticsDetailsDto,
  EvidenceRecordsMetrics,
} from "../../model/campaign/campaign-analytics-details.dto";
import EmpLink from "../shared/emp-link/emp-link";
import {
  ATTACHMENT_TYPE,
  EVIDENCE_TYPE,
  PLATFORM_ROLES,
  PROOF_OF_WORK_MEDIA_TYPE,
  SOCIAL_MEDIA_TYPE,
} from "../../constants/app.constants";
import FormFieldUtils from "../../utilities/form-field.util";
import EmpButton from "../shared/emp-button/emp-button";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import { EmpImage } from "../shared/emp-image/emp-image";
import EvidenceRecordModal, {
  EvidenceRecordModalRef,
} from "../modals/evidence-record-modal";
import { TaskDto } from "../../model/campaign/task.dto";
import FacebookIcon from "../icon/facebook-icon";
import { Color } from "../../utilities/colors";
import InstagramIcon from "../icon/instagram-icon";
import TikTokIcon from "../icon/tiktok-icon";
import XIcon from "../icon/x-icon";
import { DeliverableDto } from "../../model/campaign/deliverable.dto";
import SingleDeliverableModal, {
  SingleDeliverableModalRef,
} from "../modals/single-deliverable-modal";
import { InstagramPow } from "../proof-of-work-media/instagram-pow";
import { InstagramPowFallback } from "../proof-of-work-media/instagram-pow-fallback";
import {
  FacebookPost,
  InstagramMedia,
  TiktokVideo,
} from "../../model/campaign/social-media.dto";
import { FacebookPow } from "../proof-of-work-media/facebook-pow";
import { FacebookPowFallback } from "../proof-of-work-media/facebook-pow-fallback";
import { TikTokPow } from "../proof-of-work-media/tiktok-pow";
import { TikTokFallbackPow } from "../proof-of-work-media/tiktok-fallback-pow";
import { XPowFallback } from "../proof-of-work-media/x-pow-fallback";
import BrokenLinkIcon from "../icon/broken-link-icon";
import { CampaignAnalyticsUtils } from "../../utilities/campaign-analytics.util";
import { CampaignPayoutDetailsDto } from "../../model/payment/payment/campaign-payout-details.dto";
import FileIcon from "../icon/file-icon";
import { isNotNullOrUndefined } from "../../utilities/helpers";
import CheckIcon from "../icon/check-icon";
import { DateUtil } from "../../utilities/date";

interface Props {
  rawRecords?: CampaignAnalyticsDetailsDto[];
  isLoading: boolean;
  progressLoading: boolean;
  payoutRecords?: CampaignPayoutDetailsDto;
  displayCurrency?: string;
  role: "brand" | "seller";
  task: TaskDto;
}

export const CampaignAnalyticsBreakdownTable = (props: Props) => {
  const {
    rawRecords,
    task,
    isLoading,
    progressLoading,
    role,
    displayCurrency,
    payoutRecords,
  } = props;

  const deliverableRef = useRef<DeliverableDto[]>(task.deliverables);
  const empTableRef = useRef<EmpTableRef>();

  // Modal Reference
  const evidenceRecordModalRef = useRef<EvidenceRecordModalRef>();
  const singleDeliveraleModalRef = useRef<SingleDeliverableModalRef>();

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<CampaignAnalyticsDetailsDto>[] =
    useMemo(() => {
      const basicStructure: EmpTableContentSpec<CampaignAnalyticsDetailsDto>[] =
        [
          {
            title: "",
            dataIndex: "collapsible-btn",
            hasCollapsible: (record) => {
              return record.status === "evidence-available";
            },
          },
          {
            title: "Creator",
            dataIndex: "creator",
            render: (record: CampaignAnalyticsDetailsDto) => {
              return (
                <div className="creator-cell">
                  <div className="image-wrapper">
                    {record.creator.imageType === "url" && (
                      <img
                        className="display-picture"
                        src={record.creator.imageResource}
                        alt={record.creator.fullName}
                      />
                    )}
                    {record.creator.imageType === "avatar" && (
                      <div
                        className="display-picture"
                        style={{ background: record.creator.imageResource }}
                      >
                        {record.creator.initials}
                      </div>
                    )}
                  </div>
                  <div className="details-wrapper">
                    <EmpLink text={record.creator.fullName} />
                    <span className="emp-paragraph">
                      {record.representativeRole === PLATFORM_ROLES.CREATOR
                        ? "Freelance"
                        : `Managed by ${record.agency!.companyName}`}
                    </span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Amount",
            dataIndex: "amount",
            render: (record: CampaignAnalyticsDetailsDto) => {
              return (
                <>
                  {role === "seller" && (
                    <div>
                      {record.amount !== -1 ? (
                        <>{record.amount}</>
                      ) : (
                        <>Hidden</>
                      )}
                    </div>
                  )}
                  {role === "brand" && payoutRecords && displayCurrency && (
                    <>
                      {displayCurrency === "USD" && (
                        <div>{record.amount ? <>{record.amount}</> : "-"}</div>
                      )}
                      {displayCurrency !== "USD" && (
                        <div>{record.amount ? <>{record.amount}</> : "-"}</div>
                      )}
                    </>
                  )}
                </>
              );
            },
          },
          {
            title: "Total Interactions",
            dataIndex: "interactions",
            render: (record: CampaignAnalyticsDetailsDto) => {
              return (
                <div>
                  {record.aggregatedMetrics?.totalInteractionCount
                    ? FormFieldUtils.toCommify(
                        record.aggregatedMetrics.totalInteractionCount
                      )
                    : "-"}
                </div>
              );
            },
          },
          {
            title: "Engagement",
            dataIndex: "engagements",
            render: (record: CampaignAnalyticsDetailsDto) => {
              const engagement = record.aggregatedMetrics?.engagement;
              if (isNotNullOrUndefined(engagement)) {
                return (
                  <div>
                    {engagement! >= 0 && <>{engagement!.toFixed(2)}%</>}
                    {engagement! < 0 && <>Unobtainable</>}
                  </div>
                );
              }
              return <>-</>;
            },
          },
          {
            title: "CPE",
            dataIndex: "cost-per-engagement",
            render: (record: CampaignAnalyticsDetailsDto) => {
              const cpv = record.aggregatedMetrics?.costPerEngagement;
              if (role === "seller" && cpv) {
                if (cpv === -1) return <>Hidden</>;
                else {
                  return <>{FormFieldUtils.formatNumber(cpv)}</>;
                }
              } else if (
                role === "brand" &&
                payoutRecords &&
                displayCurrency &&
                cpv
              ) {
                return <div>{FormFieldUtils.formatNumber(cpv)}</div>;
              } else {
                return <>-</>;
              }
            },
          },
          {
            title: "Impressions",
            dataIndex: "impressions",
            render: (record: CampaignAnalyticsDetailsDto) => {
              return (
                <>
                  {record.aggregatedMetrics?.impression
                    ? FormFieldUtils.toCommify(
                        record.aggregatedMetrics.impression
                      )
                    : "-"}
                </>
              );
            },
          },
          {
            title: "CPI",
            dataIndex: "cost-per-impression",

            render: (record: CampaignAnalyticsDetailsDto) => {
              const cpv = record.aggregatedMetrics?.costPerImpression;
              if (role === "seller" && cpv) {
                if (cpv === -1) return <>Hidden</>;
                else {
                  return <>{FormFieldUtils.formatNumber(cpv)}</>;
                }
              } else if (
                role === "brand" &&
                payoutRecords &&
                displayCurrency &&
                cpv
              ) {
                return <div>{FormFieldUtils.formatNumber(cpv)}</div>;
              } else {
                return <>-</>;
              }
            },
          },
        ];

      const hasValue = (values: (number | undefined)[]) => {
        return values.some((value) => value !== undefined && value !== null);
      };

      const addMetricToStructure = (
        title: string,
        dataIndex: string,
        metricKey: keyof AggregatedMetrics
      ) => {
        // Filter records that has aggregated metrics
        if (!rawRecords) return;
        const filteredRecords = rawRecords.filter(
          (elem) => elem.aggregatedMetrics
        );
        if (
          hasValue(
            filteredRecords.map((elem) => elem.aggregatedMetrics![metricKey])
          )
        ) {
          basicStructure.push({
            title,
            dataIndex,
            render: (record: CampaignAnalyticsDetailsDto) => {
              if (!record.aggregatedMetrics) return <>-</>;
              return (
                <>
                  {record.aggregatedMetrics[metricKey]
                    ? FormFieldUtils.toCommify(
                        record.aggregatedMetrics[metricKey] as number
                      )
                    : "-"}
                </>
              );
            },
          });
        }
      };
      addMetricToStructure("Total Views", "views", "totalViewCount");
      addMetricToStructure("Total Likes", "likes", "totalLikeCount");
      addMetricToStructure("Total Comments", "comments", "totalCommentCount");
      addMetricToStructure("Total Shares", "shares", "totalShareCount");

      basicStructure.push({
        title: "",
        dataIndex: "empty",
        render: () => {
          return <></>;
        },
      });

      return basicStructure;
    }, [rawRecords, payoutRecords, displayCurrency, role]);

  const [tableProps, setTableProps] = useState<
    EmpTableProps<CampaignAnalyticsDetailsDto>
  >({
    rowKey: "creatorUserId",
    data: [],
    contentColumns: contentSpec,
  });
  useEffect(() => {
    const data: (CampaignAnalyticsDetailsDto | EmpTableCollapsible)[] = [];
    if (!rawRecords) return;
    for (const record of rawRecords) {
      data.push(record);
      if (record.status !== "evidence-available") continue;
      data.push({
        type: "emp-table-collapsible",
        rowKey: record.creatorUserId,
        content: (
          <EvidenceRecordSubTable
            deliverables={deliverableRef.current}
            onViewEvidenceRecord={(evidenceRecord) => {
              evidenceRecordModalRef.current?.show(evidenceRecord);
            }}
            onViewDeliverable={(deliverableDto) => {
              singleDeliveraleModalRef.current?.show(deliverableDto);
            }}
            data={record.evidenceRecordMetrics}
          />
        ),
      });
    }

    setTableProps({
      rowKey: "creatorUserId",
      data: data,
      contentColumns: contentSpec,
    });
  }, [rawRecords, contentSpec]);

  return (
    <div className="emp-campaign-analytics-breakdown-table">
      <EvidenceRecordModal
        ref={evidenceRecordModalRef}
        task={task}
        role={"brand"}
      />
      <SingleDeliverableModal ref={singleDeliveraleModalRef} />
      <EmpTable
        loading={isLoading}
        tableInCard
        ref={empTableRef}
        style={{ width: "100%" }}
        collapsibleExpandedByDefault
        contentColumns={tableProps.contentColumns}
        showEmptyState={false}
        progressLoading={progressLoading}
        shimmerLoading={{
          width: [30, 100, 60, 80, 50, 40, 50, 80, 120, 80],
          overlayDesign: <></>,
        }}
        data={tableProps.data}
        rowKey={tableProps.rowKey}
      />
    </div>
  );
};

interface subtableProps {
  data: EvidenceRecordsMetrics[];
  deliverables: DeliverableDto[];
  onViewEvidenceRecord: (evidenceRecord: EvidenceRecordDto) => void;
  onViewDeliverable: (deliverable: DeliverableDto) => void;
}
const EvidenceRecordSubTable = (props: subtableProps) => {
  const { data, onViewEvidenceRecord, deliverables, onViewDeliverable } = props;

  // Table Column Content Specs
  const renderEvidenceCell = useCallback(
    (record: EvidenceRecordDto): JSX.Element => {
      const renderSmLinkMedia = (): JSX.Element => {
        if (record.platform === SOCIAL_MEDIA_TYPE.INSTAGRAM) {
          return (
            <>
              {record.hasSocialMediaObject ? (
                <InstagramPow
                  type={"ca-table"}
                  socialMediaObject={record.socialMediaObject as InstagramMedia}
                  evidenceRecord={record}
                />
              ) : (
                <InstagramPowFallback
                  type={"ca-table"}
                  mediaSnapshots={record.mediaSnapshots!}
                  evidenceRecord={record}
                />
              )}
            </>
          );
        } else if (record.platform === SOCIAL_MEDIA_TYPE.FACEBOOK) {
          return (
            <>
              {record.hasSocialMediaObject ? (
                <FacebookPow
                  type={"ca-table"}
                  socialMediaObject={record.socialMediaObject as FacebookPost}
                  evidenceRecord={record}
                />
              ) : (
                <FacebookPowFallback
                  type={"ca-table"}
                  mediaSnapshots={record.mediaSnapshots!}
                  evidenceRecord={record}
                />
              )}
            </>
          );
        } else if (record.platform === SOCIAL_MEDIA_TYPE.TIKTOK) {
          return (
            <>
              {record.hasSocialMediaObject ? (
                <TikTokPow
                  type={"ca-table"}
                  socialMediaObject={record.socialMediaObject as TiktokVideo}
                  evidenceRecord={record}
                />
              ) : (
                <TikTokFallbackPow
                  type={"ca-table"}
                  mediaSnapshots={record.mediaSnapshots!}
                  evidenceRecord={record}
                />
              )}
            </>
          );
        } else if (record.platform === SOCIAL_MEDIA_TYPE.X) {
          return (
            <XPowFallback
              type={"ca-table"}
              mediaSnapshots={record.mediaSnapshots!}
              evidenceRecord={record}
            />
          );
        }

        if (
          record.platform === SOCIAL_MEDIA_TYPE.INSTAGRAM &&
          record.socialMediaObject?.mediaType === "video"
        ) {
          return <video src={record.socialMediaObject.picture}></video>;
        } else if (
          record.platform === SOCIAL_MEDIA_TYPE.FACEBOOK &&
          record.socialMediaObject?.mediaType === "text"
        ) {
          return <div className="text-container">Text</div>;
        } else {
          return (
            <EmpImage
              alt="Proof of work preview"
              src={record.socialMediaObject?.picture}
            />
          );
        }
      };

      // Render Column Definition
      return (
        <div className="evidence-record-cell">
          {record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
            <>{renderSmLinkMedia()}</>
            // Instagram is an exception. if mediaType is Video, it needs to be a video
          )}
          {record.evidenceType === EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE && (
            <>
              {[ATTACHMENT_TYPE.IMAGES, ATTACHMENT_TYPE.IMAGE].includes(
                record.files[0].fileType ?? ""
              ) && (
                <EmpImage
                  alt="Proof of work preview"
                  src={record.files[0].url}
                />
              )}
              {record.files[0].fileType === ATTACHMENT_TYPE.VIDEO && (
                <video src={record.files[0].url}></video>
              )}
            </>
          )}

          {record.evidenceType === EVIDENCE_TYPE.ATTACHMENT && (
            <div className="attachment-display">
              <FileIcon size={20} backgroundColor={Color.PRIMARY[500]} />
            </div>
          )}

          {/* Social Media Icon Indicator */}
          {[
            EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE,
            EVIDENCE_TYPE.SOCIAL_MEDIA_LINK,
          ].includes(record.evidenceType) && (
            <div className={`sm-icon-wrapper ${record.platform ?? ""}`}>
              {record.platform === SOCIAL_MEDIA_TYPE.FACEBOOK && (
                <FacebookIcon size={14} backgroundColor={Color.NEUTRAL[100]} />
              )}
              {record.platform === SOCIAL_MEDIA_TYPE.INSTAGRAM && (
                <InstagramIcon size={14} backgroundColor={Color.NEUTRAL[100]} />
              )}
              {record.platform === SOCIAL_MEDIA_TYPE.TIKTOK && (
                <TikTokIcon size={14} backgroundColor={Color.NEUTRAL[100]} />
              )}
              {record.platform === SOCIAL_MEDIA_TYPE.X && (
                <XIcon size={14} backgroundColor={Color.NEUTRAL[100]} />
              )}
            </div>
          )}
        </div>
      );
    },
    []
  );

  const contentSpec: EmpTableContentSpec<EvidenceRecordsMetrics>[] =
    useMemo(() => {
      const columnSpecsList: EmpTableContentSpec<EvidenceRecordsMetrics>[] = [
        {
          title: "Post",
          dataIndex: "evidence",
          render: (record: EvidenceRecordsMetrics) => {
            return renderEvidenceCell(record.evidenceRecord);
          },
        },
        {
          title: "Submission Details",
          dataIndex: "submission-details",
          render: (record) => {
            const deliverableIndex = deliverables.findIndex(
              (elem) => elem.id === record.deliverableId
            );
            return (
              <div className="submission-details-cell">
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_LINK && (
                  <EmpPill
                    {...PILL_COLORS.primary}
                    text={CampaignAnalyticsUtils.mapEvidenceName(
                      record.evidenceRecord.evidenceType
                    )}
                  />
                )}
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_IMAGE && (
                  <EmpPill
                    {...PILL_COLORS.amber}
                    text={CampaignAnalyticsUtils.mapEvidenceName(
                      record.evidenceRecord.evidenceType
                    )}
                  />
                )}
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.ATTACHMENT && (
                  <EmpPill
                    {...PILL_COLORS.amber}
                    text={CampaignAnalyticsUtils.mapEvidenceName(
                      record.evidenceRecord.evidenceType
                    )}
                  />
                )}
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_LINK &&
                  !record.evidenceRecord.hasSocialMediaObject &&
                  record.evidenceRecord.platform !== SOCIAL_MEDIA_TYPE.X && (
                    <>
                      {![
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                      ].includes(record.evidenceRecord.mediaType) && (
                        <EmpPill
                          {...PILL_COLORS.gray}
                          Icon={BrokenLinkIcon}
                          text={"Archived"}
                          tooltip="This user's social media account used for submitting proof of work has been disconnected. The metrics shown here was archived at least 12 hours prior to the disconnection. For detailed metrics, please click the link to view the original post."
                        />
                      )}
                      {[
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO,
                        PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE,
                      ].includes(record.evidenceRecord.mediaType) && (
                        <EmpPill
                          {...PILL_COLORS.gray}
                          Icon={BrokenLinkIcon}
                          text={"Story Expired"}
                          tooltip="Instagram Stories are available for 24 hours and has now expired. We took a final snapshot of its metrics one hour before expiration."
                        />
                      )}
                    </>
                  )}
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_LINK &&
                  record.evidenceRecord.platform === SOCIAL_MEDIA_TYPE.X && (
                    <EmpPill
                      Icon={CheckIcon}
                      {...PILL_COLORS.green}
                      text={`Updated ${DateUtil.toPeriod(
                        record.evidenceRecord.metricSnapshot!.updatedDate
                      )}`}
                      tooltip={`The metrics for this post were last updated ${DateUtil.toPeriod(
                        record.evidenceRecord.metricSnapshot!.updatedDate
                      )}. To view the most recent Twitter data, you can trigger a refresh for the latest metrics.`}
                    />
                  )}
                {record.evidenceRecord.evidenceType ===
                  EVIDENCE_TYPE.SOCIAL_MEDIA_LINK &&
                  record.evidenceRecord.hasSocialMediaObject && (
                    <EmpPill
                      Icon={CheckIcon}
                      {...PILL_COLORS.green}
                      text={"Live Data"}
                      tooltip={`This data is pulled directly from the ${record.evidenceRecord.platform} API. Refresh the page to access the most up-to-date information.`}
                    />
                  )}
                <EmpLink
                  text={`${deliverables[deliverableIndex].name}`}
                  onSubmit={() => {
                    if (deliverableIndex > -1) {
                      onViewDeliverable(deliverables[deliverableIndex]);
                    }
                  }}
                />
              </div>
            );
          },
        },
        {
          title: "Followers",
          dataIndex: "followers",
          render: (record: EvidenceRecordsMetrics) => {
            if (
              record.followerCount === -1 &&
              record.evidenceRecord.evidenceType !== EVIDENCE_TYPE.ATTACHMENT
            ) {
              return <>Unobtainable</>;
            } else if (
              record.followerCount === -1 &&
              record.evidenceRecord.evidenceType === EVIDENCE_TYPE.ATTACHMENT
            ) {
              return <>-</>;
            } else
              return <>{FormFieldUtils.toCommify(record.followerCount ?? 0)}</>;
          },
        },
      ];
      const hasValue = (values: (number | undefined)[]) => {
        return values.some((value) => value !== undefined && value !== null);
      };

      const addMetricToStructure = (
        title: string,
        dataIndex: string,
        metricKey:
          | "engagement"
          | "likeCount"
          | "commentCount"
          | "shareCount"
          | "viewCount"
          | "totalInteractionCount"
          | "impression"
      ) => {
        if (hasValue(data.map((elem) => elem[metricKey]))) {
          columnSpecsList.push({
            title,
            dataIndex,
            render: (record: EvidenceRecordsMetrics) => {
              if (
                record[metricKey] === null ||
                record[metricKey] === undefined ||
                record[metricKey] === -1
              )
                return <>-</>;
              return (
                <>
                  {metricKey === "engagement" && (
                    <>
                      {isNotNullOrUndefined(record[metricKey]) &&
                        record[metricKey]! >= 0 && (
                          <>{record[metricKey]!.toFixed(2)}%</>
                        )}
                      {isNotNullOrUndefined(record[metricKey]) &&
                        record[metricKey]! < 0 && <>Unobtainable</>}
                    </>
                  )}
                  {metricKey !== "engagement" && (
                    <div>
                      {FormFieldUtils.toCommify(record[metricKey] as number)}
                    </div>
                  )}
                </>
              );
            },
          });
        }
      };
      addMetricToStructure(
        "Total Interactions",
        "total-interaction-count",
        "totalInteractionCount"
      );
      addMetricToStructure("Engagement", "engagement", "engagement");
      addMetricToStructure("Impressions", "impressions", "impression");
      addMetricToStructure("Views", "views", "viewCount");
      addMetricToStructure("Likes", "likes", "likeCount");
      addMetricToStructure("Comments", "comments", "commentCount");
      addMetricToStructure("Shares", "shares", "shareCount");

      columnSpecsList.push({
        title: "Action",
        dataIndex: "action",
        render: (record: EvidenceRecordsMetrics) => {
          return (
            <EmpButton
              isFullWidth={false}
              buttonHeight="sm"
              text={"View"}
              onSubmit={() => {
                onViewEvidenceRecord(record.evidenceRecord);
              }}
            />
          );
        },
      });

      columnSpecsList.push({
        title: "",
        dataIndex: "empty",
        render: () => {
          return <></>;
        },
      });
      return columnSpecsList;
    }, [
      data,
      onViewEvidenceRecord,
      renderEvidenceCell,
      deliverables,
      onViewDeliverable,
    ]);

  return (
    <EmpTable
      tableInCard
      collapsibleExpandedByDefault
      style={{ marginLeft: 45, borderLeft: `1px solid ${Color.NEUTRAL[800]}` }}
      contentColumns={contentSpec}
      data={data}
      rowKey="activityName"
    />
  );
};
