import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import { AppContext } from "../../context/app.context";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { Color } from "../../utilities/colors";
import "./creator-settings-page.scss";
import { CreatorAccountView } from "./views/creator-account-view";
import { CreatorSettingsPaymentView } from "./views/creator-settings-payment-view";
import { signOut as awsSignOut } from "aws-amplify/auth";

export const CreatorSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useEmpBreakpoint(500);
  const [currentView, setView] = useState<"account" | "payment" | "menu">(
    "account"
  );

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    const fragment = window.location.hash;
    if (fragment !== "") {
      if (fragment === "#account") {
        setView("account");
      } else if (fragment === "#payment") {
        setView("payment");
      } else {
        if (!isMobile) setView("account");
        else setView("menu");
      }
    } else {
      if (!isMobile) setView("account");
      else setView("menu");
    }
  }, [isMobile, location]);

  const { setUser: setUserContext } = useContext(AppContext);

  const onSignOut = async () => {
    try {
      await awsSignOut({ global: true });
      setUserContext!(null);
      navigate("/sign-in");
    } catch (error) {
      if (error instanceof Error) {
        awsSignOut();
        navigate("/sign-in");
      }
    }
  };
  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="emp-page-content settings-page no-padding">
        <div className="header-wrapper mt-6">
          {isMobile && currentView !== "menu" && (
            <EmpIconButton
              buttonStyle="secondary"
              icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[600]} />}
              onSubmit={() => {
                setView("menu");
              }}
            />
          )}
          <h1>
            <FormattedMessage id="settingsPage_header" />
          </h1>
        </div>

        <div className="content">
          {currentView !== "menu" && !isMobile && (
            <div className="menu-wrapper">
              <div
                onClick={() => {
                  setView("account");
                  window.location.hash = "account";
                }}
                className={`settings-menu-item ${
                  currentView === "account" ? "active" : ""
                }`}
              >
                <FormattedMessage id="settingsPage_accountMenuItem" />
              </div>
              <div
                onClick={() => {
                  setView("payment");
                  window.location.hash = "payment";
                }}
                className={`settings-menu-item ${
                  currentView === "payment" ? "active" : ""
                }`}
              >
                <FormattedMessage id="settingsPage_paymentMenuItem" />
              </div>
              <div
                onClick={() => {
                  onSignOut();
                }}
                className="settings-menu-item logout"
              >
                <FormattedMessage id="settingsPage_logoutMenuItem" />
              </div>
            </div>
          )}
          {currentView === "menu" && isMobile && (
            <div className="menu-wrapper">
              <div
                onClick={() => {
                  setView("account");
                  window.location.hash = "account";
                }}
                className={`settings-menu-item `}
              >
                <FormattedMessage id="settingsPage_accountMenuItem" />
              </div>
              <div
                onClick={() => {
                  setView("payment");
                  window.location.hash = "payment";
                }}
                className={`settings-menu-item `}
              >
                <FormattedMessage id="settingsPage_paymentMenuItem" />
              </div>
              <div
                onClick={() => {
                  onSignOut();
                }}
                className="settings-menu-item logout"
              >
                <FormattedMessage id="settingsPage_logoutMenuItem" />
              </div>
            </div>
          )}
          {currentView !== "menu" && (
            <div className="setting-view-wrapper">
              {currentView === "account" && <CreatorAccountView />}
              {currentView === "payment" && <CreatorSettingsPaymentView />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
