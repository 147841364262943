import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { FbMediaInfoRespDto } from "../../model/facebook/fb-media-info-resp.dto";
import { FbMediaWithProfileDto } from "../../model/facebook/fb-media-w-profile.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const FacebookApi = {
  basicProfile: async (
    talentId: string
  ): Promise<ApiResponse<FbMediaInfoRespDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<FbMediaInfoRespDto>>(
      ENDPOINTS.userMicroservice.facebook.basicProfile(talentId)
    );
    return response.data;
  },

  getMediaById: async (
    creatorId: string,
    mediaId: string
  ): Promise<ApiResponse<FbMediaWithProfileDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<FbMediaWithProfileDto>>(
      ENDPOINTS.userMicroservice.facebook.getMediaById(creatorId, mediaId)
    );
    return response.data;
  },
};
export default FacebookApi;
