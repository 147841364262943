import axios from "axios";
import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { CreateLeadFeedbackDTO } from "../../model/lead/create-lead-feedback-dto";
import { CreateLeadDto } from "../../model/lead/create-lead-req.dto";

const LeadApi = {
  createAgencyLead: async (
    request: CreateLeadDto
  ): Promise<ApiResponse<CreateLeadFeedbackDTO>> => {
    const response = await axios.post<ApiResponse<CreateLeadFeedbackDTO>>(
      ENDPOINTS.adminMicroservice.leads.createServiceProviderLead,
      request
    );
    return response.data;
  },
};
export default LeadApi;
