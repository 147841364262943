import "./emp-sidebar.scss";

type MenuItem = {
  name: string;
  onClick: () => void;
};

type EmpSidebarProps = {
  title?: string;
  menuItems: MenuItem[];
  activeItem: string;
  isSticky?: boolean;
};

const EmpSidebar = ({
  title,
  menuItems,
  activeItem,
  isSticky,
}: EmpSidebarProps) => {
  return (
    <div className={`emp-sidebar ${isSticky ? "sticky" : ""}`}>
      {title && (
        <>
          <div className="header">{title}</div>
          <div className="divider" />
        </>
      )}
      <div className="menu">
        {menuItems.map((item) => (
          <div
            key={item.name}
            className={`menu-item ${item.name === activeItem ? "active" : ""}`}
            onClick={item.onClick}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmpSidebar;
