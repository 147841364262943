import { IntlShape } from "react-intl";

type CountryType = {
  capital?: string;
  continent?: string;
  code: string;
  flag_1x1: string;
  flag_4x3: string;
  iso: boolean;
  lat: number;
  lng: number;
  name: string;
};
export type CountryOption = { [key: string]: CountryType };
export const COUNTRY_CONSTANTS: CountryOption = {
  af: {
    capital: "Kabul",
    code: "af",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/af.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/af.svg",
    lat: 33.93911,
    lng: 67.709953,
    iso: true,
    name: "Afghanistan",
  },
  ax: {
    capital: "Mariehamn",
    code: "ax",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ax.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ax.svg",
    iso: true,
    lat: 60.1167,
    lng: 19.9,
    name: "Aland Islands",
  },
  al: {
    capital: "Tirana",
    code: "al",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/al.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/al.svg",
    lat: 41.3275,
    lng: 19.8187,
    iso: true,
    name: "Albania",
  },
  dz: {
    capital: "Algiers",
    code: "dz",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dz.svg",
    lat: 36.7528,
    lng: 3.0422,
    iso: true,
    name: "Algeria",
  },
  as: {
    capital: "Pago Pago",
    code: "as",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/as.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/as.svg",
    iso: true,
    lat: -14.2756,
    lng: -170.7028,
    name: "American Samoa",
  },
  ad: {
    capital: "Andorra la Vella",
    code: "ad",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ad.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ad.svg",
    lat: 42.5063,
    lng: 1.5218,
    iso: true,
    name: "Andorra",
  },
  ao: {
    capital: "Luanda",
    code: "ao",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ao.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ao.svg",
    lat: -8.8137,
    lng: 13.2441,
    iso: true,
    name: "Angola",
  },
  ai: {
    capital: "The Valley",
    code: "ai",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ai.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ai.svg",
    lat: 18.2206,
    lng: -63.0686,
    iso: true,
    name: "Anguilla",
  },
  aq: {
    code: "aq",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aq.svg",
    lat: -90.0,
    lng: 0.0,
    iso: true,
    name: "Antarctica",
  },
  ag: {
    capital: "St. John's",
    code: "ag",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ag.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ag.svg",
    lat: 17.0608,
    lng: -61.7964,
    iso: true,
    name: "Antigua and Barbuda",
  },
  ar: {
    capital: "Buenos Aires",
    code: "ar",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ar.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ar.svg",
    lat: -34.6118,
    lng: -58.4173,
    iso: true,
    name: "Argentina",
  },
  am: {
    capital: "Yerevan",
    code: "am",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/am.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/am.svg",
    lat: 40.1792,
    lng: 44.4991,
    iso: true,
    name: "Armenia",
  },
  aw: {
    capital: "Oranjestad",
    code: "aw",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aw.svg",
    lat: 12.5211,
    lng: -69.9683,
    iso: true,
    name: "Aruba",
  },
  au: {
    capital: "Canberra",
    code: "au",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/au.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/au.svg",
    lat: -35.3081,
    lng: 149.1244,
    iso: true,
    name: "Australia",
  },
  at: {
    capital: "Vienna",
    code: "at",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/at.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/at.svg",
    lat: 48.2082,
    lng: 16.3738,
    iso: true,
    name: "Austria",
  },
  az: {
    capital: "Baku",
    code: "az",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/az.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/az.svg",
    lat: 40.4093,
    lng: 49.8671,
    iso: true,
    name: "Azerbaijan",
  },
  bs: {
    capital: "Nassau",
    code: "bs",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bs.svg",
    lat: 25.0343,
    lng: -77.3963,
    iso: true,
    name: "Bahamas",
  },
  bh: {
    capital: "Manama",
    code: "bh",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bh.svg",
    lat: 26.2235,
    lng: 50.587,
    iso: true,
    name: "Bahrain",
  },
  bd: {
    capital: "Dhaka",
    code: "bd",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bd.svg",
    lat: 23.8103,
    lng: 90.4125,
    iso: true,
    name: "Bangladesh",
  },
  bb: {
    capital: "Bridgetown",
    code: "bb",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bb.svg",
    lat: 13.0975,
    lng: -59.6167,
    iso: true,
    name: "Barbados",
  },
  by: {
    capital: "Minsk",
    code: "by",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/by.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/by.svg",
    lat: 53.9045,
    lng: 27.5615,
    iso: true,
    name: "Belarus",
  },
  be: {
    capital: "Brussels",
    code: "be",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/be.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/be.svg",
    lat: 50.8503,
    lng: 4.3517,
    iso: true,
    name: "Belgium",
  },
  bz: {
    capital: "Belmopan",
    code: "bz",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bz.svg",
    lat: 17.2534,
    lng: -88.769,
    iso: true,
    name: "Belize",
  },
  bj: {
    capital: "Porto-Novo",
    code: "bj",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bj.svg",
    lat: 6.3528,
    lng: 2.4258,
    iso: true,
    name: "Benin",
  },
  bm: {
    capital: "Hamilton",
    code: "bm",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bm.svg",
    lat: 32.2948,
    lng: -64.781,
    iso: true,
    name: "Bermuda",
  },
  bt: {
    capital: "Thimphu",
    code: "bt",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bt.svg",
    lat: 27.4661,
    lng: 89.6419,
    iso: true,
    name: "Bhutan",
  },
  bo: {
    capital: "Sucre",
    code: "bo",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bo.svg",
    lat: -19.0208,
    lng: -65.2622,
    iso: true,
    name: "Bolivia",
  },

  ba: {
    capital: "Sarajevo",
    code: "ba",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ba.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ba.svg",
    lat: 43.8563,
    lng: 18.4131,
    iso: true,
    name: "Bosnia and Herzegovina",
  },
  bw: {
    capital: "Gaborone",
    code: "bw",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bw.svg",
    lat: -24.6583,
    lng: 25.9122,
    iso: true,
    name: "Botswana",
  },
  bv: {
    code: "bv",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bv.svg",
    lat: -54.4232,
    lng: 3.4139,
    iso: true,
    name: "Bouvet Island",
  },
  br: {
    capital: "Brasília",
    code: "br",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/br.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/br.svg",
    lat: -15.7801,
    lng: -47.9292,
    iso: true,
    name: "Brazil",
  },
  io: {
    capital: "Diego Garcia",
    code: "io",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/io.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/io.svg",
    lat: -7.3133,
    lng: 72.4248,
    iso: true,
    name: "British Indian Ocean Territory",
  },
  bn: {
    capital: "Bandar Seri Begawan",
    code: "bn",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bn.svg",
    lat: 4.9031,
    lng: 114.9398,
    iso: true,
    name: "Brunei Darussalam",
  },
  bg: {
    capital: "Sofia",
    code: "bg",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bg.svg",
    lat: 42.6977,
    lng: 23.3219,
    iso: true,
    name: "Bulgaria",
  },
  bf: {
    capital: "Ouagadougou",
    code: "bf",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bf.svg",
    lat: 12.3714,
    lng: -1.5197,
    iso: true,
    name: "Burkina Faso",
  },
  bi: {
    capital: "Bujumbura",
    code: "bi",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bi.svg",
    lat: -3.3764,
    lng: 29.3599,
    iso: true,
    name: "Burundi",
  },
  cv: {
    capital: "Praia",
    code: "cv",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cv.svg",
    lat: 16.5388,
    lng: -23.0418,
    iso: true,
    name: "Cabo Verde",
  },
  kh: {
    capital: "Phnom Penh",
    code: "kh",
    continent: "Asia",
    lat: 11.5564,
    lng: 104.9282,
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kh.svg",
    iso: true,
    name: "Cambodia",
  },
  cm: {
    capital: "Yaoundé",
    code: "cm",
    continent: "Africa",
    lat: 3.848,
    lng: 11.5021,
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cm.svg",
    iso: true,
    name: "Cameroon",
  },
  ca: {
    capital: "Ottawa",
    code: "ca",
    continent: "North America",
    lat: 45.4215,
    lng: -75.6919,
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ca.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ca.svg",
    iso: true,
    name: "Canada",
  },

  ky: {
    capital: "George Town",
    code: "ky",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ky.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ky.svg",
    lat: 19.3133,
    lng: -81.2546,
    iso: true,
    name: "Cayman Islands",
  },
  cf: {
    capital: "Bangui",
    code: "cf",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cf.svg",
    lat: 6.6111,
    lng: 20.9394,
    iso: true,
    name: "Central African Republic",
  },

  td: {
    capital: "N'Djamena",
    code: "td",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/td.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/td.svg",
    lat: 12.6392,
    lng: 15.315,
    iso: true,
    name: "Chad",
  },
  cl: {
    capital: "Santiago",
    code: "cl",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cl.svg",
    lat: -33.4489,
    lng: -70.6693,
    iso: true,
    name: "Chile",
  },
  cn: {
    capital: "Beijing",
    code: "cn",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cn.svg",
    lat: 39.9042,
    lng: 116.4074,
    iso: true,
    name: "China",
  },
  cx: {
    capital: "Flying Fish Cove",
    code: "cx",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cx.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cx.svg",
    lat: -10.4918,
    lng: 105.6312,
    iso: true,
    name: "Christmas Island",
  },
  cc: {
    capital: "West Island",
    code: "cc",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cc.svg",
    lat: -12.1642,
    lng: 96.8166,
    iso: true,
    name: "Cocos (Keeling) Islands",
  },
  co: {
    capital: "Bogotá",
    code: "co",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/co.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/co.svg",
    lat: 4.5709,
    lng: -74.2973,
    iso: true,
    name: "Colombia",
  },
  km: {
    capital: "Moroni",
    code: "km",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/km.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/km.svg",
    lat: -11.6455,
    lng: 43.3336,
    iso: true,
    name: "Comoros",
  },
  ck: {
    capital: "Avarua",
    code: "ck",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ck.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ck.svg",
    lat: -21.2075,
    lng: -159.7735,
    iso: true,
    name: "Cook Islands",
  },
  cr: {
    capital: "San José",
    code: "cr",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cr.svg",
    lat: 9.9281,
    lng: -84.0907,
    iso: true,
    name: "Costa Rica",
  },
  hr: {
    capital: "Zagreb",
    code: "hr",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hr.svg",
    lat: 45.815,
    lng: 15.9819,
    iso: true,
    name: "Croatia",
  },
  cu: {
    capital: "Havana",
    code: "cu",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cu.svg",
    lat: 23.1136,
    lng: -82.3666,
    iso: true,
    name: "Cuba",
  },
  cy: {
    capital: "Nicosia",
    code: "cy",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cy.svg",
    lat: 35.1264,
    lng: 33.4299,
    iso: true,
    name: "Cyprus",
  },
  cz: {
    capital: "Prague",
    code: "cz",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cz.svg",
    lat: 50.0755,
    lng: 14.4378,
    iso: true,
    name: "Czech Republic",
  },
  ci: {
    capital: "Yamoussoukro",
    code: "ci",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ci.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ci.svg",
    lat: 6.2596,
    lng: -5.8123,
    iso: true,
    name: "Côte d'Ivoire",
  },
  cd: {
    capital: "Kinshasa",
    code: "cd",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cd.svg",
    lat: -4.4419,
    lng: 15.2663,
    iso: true,
    name: "Democratic Republic of the Congo",
  },
  dk: {
    capital: "Copenhagen",
    code: "dk",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dk.svg",
    lat: 55.6761,
    lng: 12.5683,
    iso: true,
    name: "Denmark",
  },
  dj: {
    capital: "Djibouti",
    code: "dj",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dj.svg",
    lat: 11.8251,
    lng: 42.5903,
    iso: true,
    name: "Djibouti",
  },
  dm: {
    capital: "Roseau",
    code: "dm",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dm.svg",
    lat: 15.301,
    lng: -61.387,
    iso: true,
    name: "Dominica",
  },
  do: {
    capital: "Santo Domingo",
    code: "do",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/do.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/do.svg",
    lat: 18.4861,
    lng: -69.9312,
    iso: true,
    name: "Dominican Republic",
  },
  ec: {
    capital: "Quito",
    code: "ec",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ec.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ec.svg",
    lat: -0.2295,
    lng: -78.5243,
    iso: true,
    name: "Ecuador",
  },
  eg: {
    capital: "Cairo",
    code: "eg",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eg.svg",
    lat: 30.0444,
    lng: 31.2357,
    iso: true,
    name: "Egypt",
  },
  sv: {
    capital: "San Salvador",
    code: "sv",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sv.svg",
    lat: 13.6929,
    lng: -89.2182,
    iso: true,
    name: "El Salvador",
  },

  gq: {
    capital: "Malabo",
    code: "gq",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gq.svg",
    lat: 3.7509,
    lng: 8.7386,
    iso: true,
    name: "Equatorial Guinea",
  },
  er: {
    capital: "Asmara",
    code: "er",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/er.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/er.svg",
    lat: 15.3229,
    lng: 38.9251,
    iso: true,
    name: "Eritrea",
  },
  ee: {
    capital: "Tallinn",
    code: "ee",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ee.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ee.svg",
    iso: true,
    name: "Estonia",
    lat: 59.437,
    lng: 24.7536,
  },
  sz: {
    capital: "Lobamba, Mbabane",
    code: "sz",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sz.svg",
    iso: true,
    name: "Eswatini",
    lat: -26.5225,
    lng: 31.4659,
  },
  et: {
    capital: "Addis Ababa",
    code: "et",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/et.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/et.svg",
    iso: true,
    name: "Ethiopia",
    lat: 9.145,
    lng: 40.4897,
  },
  fk: {
    capital: "Stanley",
    code: "fk",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fk.svg",
    iso: true,
    name: "Falkland Islands",
    lat: -51.7963,
    lng: -59.5236,
  },
  fo: {
    capital: "Tórshavn",
    code: "fo",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fo.svg",
    iso: true,
    name: "Faroe Islands",
    lat: 62.0079,
    lng: -6.7761,
  },
  fm: {
    capital: "Palikir",
    code: "fm",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fm.svg",
    iso: true,
    name: "Federated States of Micronesia",
    lat: 7.4256,
    lng: 150.5508,
  },
  fj: {
    capital: "Suva",
    code: "fj",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fj.svg",
    iso: true,
    name: "Fiji",
    lat: -18.1248,
    lng: 178.4501,
  },
  fi: {
    capital: "Helsinki",
    code: "fi",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fi.svg",
    iso: true,
    name: "Finland",
    lat: 61.9241,
    lng: 25.7482,
  },
  fr: {
    capital: "Paris",
    code: "fr",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fr.svg",
    iso: true,
    name: "France",
    lat: 46.6034,
    lng: 1.8883,
  },
  gf: {
    capital: "Cayenne",
    code: "gf",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gf.svg",
    iso: true,
    name: "French Guiana",
    lat: 3.9339,
    lng: -53.1258,
  },
  pf: {
    capital: "Papeete",
    code: "pf",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pf.svg",
    iso: true,
    name: "French Polynesia",
    lat: -17.6797,
    lng: -149.4068,
  },
  tf: {
    capital: "Saint-Pierre, Réunion",
    code: "tf",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tf.svg",
    iso: true,
    name: "French Southern Territories",
    lat: -49.2804,
    lng: 69.3486,
  },
  ga: {
    capital: "Libreville",
    code: "ga",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ga.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ga.svg",
    iso: true,
    name: "Gabon",
    lat: -0.8037,
    lng: 11.6094,
  },

  gm: {
    capital: "Banjul",
    code: "gm",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gm.svg",
    iso: true,
    name: "Gambia",
    lat: 13.4432,
    lng: -15.3101,
  },
  ge: {
    capital: "Tbilisi",
    code: "ge",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ge.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ge.svg",
    lat: 41.7151,
    lng: 44.8271,
    iso: true,
    name: "Georgia",
  },
  de: {
    capital: "Berlin",
    code: "de",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/de.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/de.svg",
    lat: 52.52,
    lng: 13.405,
    iso: true,
    name: "Germany",
  },
  gh: {
    capital: "Accra",
    code: "gh",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gh.svg",
    lat: 5.56,
    lng: -0.2057,
    iso: true,
    name: "Ghana",
  },
  gi: {
    capital: "Gibraltar",
    code: "gi",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gi.svg",
    lat: 36.1377,
    lng: -5.3454,
    iso: true,
    name: "Gibraltar",
  },
  gr: {
    capital: "Athens",
    code: "gr",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gr.svg",
    lat: 37.9838,
    lng: 23.7275,
    iso: true,
    name: "Greece",
  },
  gl: {
    capital: "Nuuk",
    code: "gl",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gl.svg",
    lat: 64.1814,
    lng: -51.6941,
    iso: true,
    name: "Greenland",
  },
  gd: {
    capital: "St. George's",
    code: "gd",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gd.svg",
    lat: 12.0522,
    lng: -61.749,
    iso: true,
    name: "Grenada",
  },
  gp: {
    capital: "Basse-Terre",
    code: "gp",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gp.svg",
    lat: 16.265,
    lng: -61.5509,
    iso: true,
    name: "Guadeloupe",
  },
  gu: {
    capital: "Hagåtña",
    code: "gu",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gu.svg",
    lat: 13.4443,
    lng: 144.7937,
    iso: true,
    name: "Guam",
  },
  gt: {
    capital: "Guatemala City",
    code: "gt",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gt.svg",
    lat: 14.6349,
    lng: -90.5069,
    iso: true,
    name: "Guatemala",
  },
  gg: {
    capital: "Saint Peter Port",
    code: "gg",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gg.svg",
    lat: 49.4482,
    lng: -2.5895,
    iso: true,
    name: "Guernsey",
  },
  gn: {
    capital: "Conakry",
    code: "gn",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gn.svg",
    lat: 9.5095,
    lng: -13.712,
    iso: true,
    name: "Guinea",
  },
  gw: {
    capital: "Bissau",
    code: "gw",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gw.svg",
    lat: 11.8037,
    lng: -15.1804,
    iso: true,
    name: "Guinea-Bissau",
  },
  gy: {
    capital: "Georgetown",
    code: "gy",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gy.svg",
    lat: 4.8604,
    lng: -58.9302,
    iso: true,
    name: "Guyana",
  },
  ht: {
    capital: "Port-au-Prince",
    code: "ht",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ht.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ht.svg",
    lat: 18.5944,
    lng: -72.3074,
    iso: true,
    name: "Haiti",
  },
  hm: {
    code: "hm",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hm.svg",
    lat: -53.0818,
    lng: 73.5042,
    iso: true,
    name: "Heard Island and McDonald Islands",
  },
  va: {
    capital: "Vatican City",
    code: "va",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/va.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/va.svg",
    lat: 41.9029,
    lng: 12.4534,
    iso: true,
    name: "Holy See",
  },
  hn: {
    capital: "Tegucigalpa",
    code: "hn",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hn.svg",
    lat: 14.0723,
    lng: -87.1921,
    iso: true,
    name: "Honduras",
  },
  hk: {
    capital: "Hong Kong",
    code: "hk",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hk.svg",
    lat: 22.3193,
    lng: 114.1694,
    iso: true,
    name: "Hong Kong",
  },
  hu: {
    capital: "Budapest",
    code: "hu",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hu.svg",
    lat: 47.1625,
    lng: 19.5033,
    iso: true,
    name: "Hungary",
  },
  is: {
    capital: "Reykjavik",
    code: "is",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/is.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/is.svg",
    lat: 64.1466,
    lng: -21.9426,
    iso: true,
    name: "Iceland",
  },
  in: {
    capital: "New Delhi",
    code: "in",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/in.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/in.svg",
    lat: 28.6139,
    lng: 77.209,
    iso: true,
    name: "India",
  },
  id: {
    capital: "Jakarta",
    code: "id",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/id.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/id.svg",
    lat: -6.2088,
    lng: 106.8456,
    iso: true,
    name: "Indonesia",
  },
  ir: {
    capital: "Tehran",
    code: "ir",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ir.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ir.svg",
    lat: 35.6895,
    lng: 51.594,
    iso: true,
    name: "Iran",
  },
  iq: {
    capital: "Baghdad",
    code: "iq",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/iq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/iq.svg",
    lat: 33.3152,
    lng: 44.3661,
    iso: true,
    name: "Iraq",
  },
  ie: {
    capital: "Dublin",
    code: "ie",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ie.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ie.svg",
    lat: 53.3498,
    lng: -6.2603,
    iso: true,
    name: "Ireland",
  },
  im: {
    capital: "Douglas",
    code: "im",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/im.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/im.svg",
    lat: 54.2361,
    lng: -4.5481,
    iso: true,
    name: "Isle of Man",
  },
  il: {
    capital: "Jerusalem",
    code: "il",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/il.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/il.svg",
    lat: 31.7683,
    lng: 35.2137,
    iso: true,
    name: "Israel",
  },
  it: {
    capital: "Rome",
    code: "it",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/it.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/it.svg",
    lat: 41.9028,
    lng: 12.4964,
    iso: true,
    name: "Italy",
  },
  jm: {
    capital: "Kingston",
    code: "jm",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jm.svg",
    lat: 17.9714,
    lng: -76.7928,
    iso: true,
    name: "Jamaica",
  },
  jp: {
    capital: "Tokyo",
    code: "jp",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jp.svg",
    lat: 35.682839,
    lng: 139.759455,
    iso: true,
    name: "Japan",
  },
  je: {
    capital: "Saint Helier",
    code: "je",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/je.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/je.svg",
    lat: 49.1859,
    lng: -2.1071,
    iso: true,
    name: "Jersey",
  },
  jo: {
    capital: "Amman",
    code: "jo",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jo.svg",
    lat: 31.9522,
    lng: 35.2332,
    iso: true,
    name: "Jordan",
  },
  kz: {
    capital: "Astana",
    code: "kz",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kz.svg",
    lat: 51.1605,
    lng: 71.4704,
    iso: true,
    name: "Kazakhstan",
  },
  ke: {
    capital: "Nairobi",
    code: "ke",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ke.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ke.svg",
    lat: -1.2921,
    lng: 36.8219,
    iso: true,
    name: "Kenya",
  },
  ki: {
    capital: "South Tarawa",
    code: "ki",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ki.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ki.svg",
    lat: 1.3292,
    lng: 172.9762,
    iso: true,
    name: "Kiribati",
  },
  kw: {
    capital: "Kuwait City",
    code: "kw",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kw.svg",
    lat: 29.3759,
    lng: 47.9774,
    iso: true,
    name: "Kuwait",
  },
  kg: {
    capital: "Bishkek",
    code: "kg",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kg.svg",
    lat: 42.8746,
    lng: 74.5698,
    iso: true,
    name: "Kyrgyzstan",
  },
  la: {
    capital: "Vientiane",
    code: "la",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/la.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/la.svg",
    lat: 17.9757,
    lng: 102.6331,
    iso: true,
    name: "Laos",
  },
  lv: {
    capital: "Riga",
    code: "lv",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lv.svg",
    lat: 56.9496,
    lng: 24.1052,
    iso: true,
    name: "Latvia",
  },
  lb: {
    capital: "Beirut",
    code: "lb",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lb.svg",
    lat: 33.8938,
    lng: 35.5018,
    iso: true,
    name: "Lebanon",
  },
  ls: {
    capital: "Maseru",
    code: "ls",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ls.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ls.svg",
    lat: -29.6099,
    lng: 28.233,
    iso: true,
    name: "Lesotho",
  },
  lr: {
    capital: "Monrovia",
    code: "lr",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lr.svg",
    lat: 6.2907,
    lng: -10.7605,
    iso: true,
    name: "Liberia",
  },
  ly: {
    capital: "Tripoli",
    code: "ly",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ly.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ly.svg",
    lat: 32.8872,
    lng: 13.1913,
    iso: true,
    name: "Libya",
  },
  li: {
    capital: "Vaduz",
    code: "li",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/li.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/li.svg",
    lat: 47.166,
    lng: 9.5554,
    iso: true,
    name: "Liechtenstein",
  },
  lt: {
    capital: "Vilnius",
    code: "lt",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lt.svg",
    lat: 55.1694,
    lng: 23.8813,
    iso: true,
    name: "Lithuania",
  },
  lu: {
    capital: "Luxembourg City",
    code: "lu",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lu.svg",
    lat: 49.61,
    lng: 6.1296,
    iso: true,
    name: "Luxembourg",
  },
  mo: {
    capital: "Macau",
    code: "mo",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mo.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mo.svg",
    lat: 22.1987,
    lng: 113.5439,
    iso: true,
    name: "Macau",
  },
  mg: {
    capital: "Antananarivo",
    code: "mg",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mg.svg",
    lat: -18.8792,
    lng: 47.5079,
    iso: true,
    name: "Madagascar",
  },
  mw: {
    capital: "Lilongwe",
    code: "mw",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mw.svg",
    lat: -13.9632,
    lng: 33.7741,
    iso: true,
    name: "Malawi",
  },
  my: {
    capital: "Kuala Lumpur",
    code: "my",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/my.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/my.svg",
    lat: 3.139,
    lng: 101.6869,
    iso: true,
    name: "Malaysia",
  },
  mv: {
    capital: "Malé",
    code: "mv",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mv.svg",
    lat: 4.1755,
    lng: 73.5093,
    iso: true,
    name: "Maldives",
  },
  ml: {
    capital: "Bamako",
    code: "ml",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ml.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ml.svg",
    lat: 12.6392,
    lng: -7.9934,
    iso: true,
    name: "Mali",
  },
  mt: {
    capital: "Valletta",
    code: "mt",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mt.svg",
    lat: 35.8989,
    lng: 14.5146,
    iso: true,
    name: "Malta",
  },
  mh: {
    capital: "Majuro",
    code: "mh",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mh.svg",
    lat: 7.1164,
    lng: 171.1859,
    iso: true,
    name: "Marshall Islands",
  },
  mq: {
    capital: "Fort-de-France",
    code: "mq",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mq.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mq.svg",
    lat: 14.6415,
    lng: -61.0242,
    iso: true,
    name: "Martinique",
  },
  mr: {
    capital: "Nouakchott",
    code: "mr",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mr.svg",
    lat: 18.0665,
    lng: -15.99,
    iso: true,
    name: "Mauritania",
  },
  mu: {
    capital: "Port Louis",
    code: "mu",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mu.svg",
    lat: -20.348404,
    lng: 57.552152,
    iso: true,
    name: "Mauritius",
  },
  yt: {
    capital: "Mamoudzou",
    code: "yt",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/yt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/yt.svg",
    lat: -12.8275,
    lng: 45.1662,
    iso: true,
    name: "Mayotte",
  },
  mx: {
    capital: "Mexico City",
    code: "mx",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mx.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mx.svg",
    lat: 19.4326,
    lng: -99.1332,
    iso: true,
    name: "Mexico",
  },
  md: {
    capital: "Chișinău",
    code: "md",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/md.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/md.svg",
    lat: 47.0167,
    lng: 28.8497,
    iso: true,
    name: "Moldova",
  },
  mc: {
    capital: "Monaco",
    code: "mc",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mc.svg",
    lat: 43.7384,
    lng: 7.4246,
    iso: true,
    name: "Monaco",
  },
  mn: {
    capital: "Ulaanbaatar",
    code: "mn",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mn.svg",
    lat: 47.9204,
    lng: 106.9085,
    iso: true,
    name: "Mongolia",
  },
  me: {
    capital: "Podgorica",
    code: "me",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/me.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/me.svg",
    lat: 42.7087,
    lng: 19.3744,
    iso: true,
    name: "Montenegro",
  },
  ms: {
    capital: "Little Bay, Brades, Plymouth",
    code: "ms",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ms.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ms.svg",
    lat: 16.7425,
    lng: -62.1874,
    iso: true,
    name: "Montserrat",
  },
  ma: {
    capital: "Rabat",
    code: "ma",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ma.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ma.svg",
    lat: 34.0209,
    lng: -6.8417,
    iso: true,
    name: "Morocco",
  },
  mz: {
    capital: "Maputo",
    code: "mz",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mz.svg",
    lat: -25.9667,
    lng: 32.5833,
    iso: true,
    name: "Mozambique",
  },
  mm: {
    capital: "Naypyidaw",
    code: "mm",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mm.svg",
    lat: 19.745,
    lng: 96.1297,
    iso: true,
    name: "Myanmar",
  },
  na: {
    capital: "Windhoek",
    code: "na",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/na.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/na.svg",
    lat: -22.5597,
    lng: 17.0836,
    iso: true,
    name: "Namibia",
  },
  nr: {
    capital: "Yaren District",
    code: "nr",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nr.svg",
    lat: -0.5477,
    lng: 166.9209,
    iso: true,
    name: "Nauru",
  },
  np: {
    capital: "Kathmandu",
    code: "np",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/np.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/np.svg",
    lat: 27.7172,
    lng: 85.324,
    iso: true,
    name: "Nepal",
  },
  nl: {
    capital: "Amsterdam",
    code: "nl",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nl.svg",
    lat: 52.3676,
    lng: 4.9041,
    iso: true,
    name: "Netherlands",
  },
  nc: {
    capital: "Nouméa",
    code: "nc",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nc.svg",
    lat: -22.2711,
    lng: 166.4411,
    iso: true,
    name: "New Caledonia",
  },
  nz: {
    capital: "Wellington",
    code: "nz",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nz.svg",
    lat: -41.2865,
    lng: 174.7762,
    iso: true,
    name: "New Zealand",
  },
  ni: {
    capital: "Managua",
    code: "ni",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ni.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ni.svg",
    lat: 12.8654,
    lng: -85.2072,
    iso: true,
    name: "Nicaragua",
  },
  ne: {
    capital: "Niamey",
    code: "ne",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ne.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ne.svg",
    lat: 13.5128,
    lng: 2.1121,
    iso: true,
    name: "Niger",
  },
  ng: {
    capital: "Abuja",
    code: "ng",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ng.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ng.svg",
    lat: 9.082,
    lng: 8.6753,
    iso: true,
    name: "Nigeria",
  },
  nu: {
    capital: "Alofi",
    code: "nu",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nu.svg",
    lat: -19.0531,
    lng: -169.8752,
    iso: true,
    name: "Niue",
  },
  nf: {
    capital: "Kingston",
    code: "nf",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nf.svg",
    lat: -29.0408,
    lng: 167.9547,
    iso: true,
    name: "Norfolk Island",
  },
  kp: {
    capital: "Pyongyang",
    code: "kp",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kp.svg",
    lat: 39.0392,
    lng: 125.7625,
    iso: true,
    name: "North Korea",
  },
  mk: {
    capital: "Skopje",
    code: "mk",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mk.svg",
    lat: 41.9973,
    lng: 21.428,
    iso: true,
    name: "North Macedonia",
  },
  mp: {
    capital: "Saipan",
    code: "mp",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mp.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mp.svg",
    lat: 15.1804,
    lng: 145.7497,
    iso: true,
    name: "Northern Mariana Islands",
  },
  no: {
    capital: "Oslo",
    code: "no",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/no.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/no.svg",
    lat: 59.9139,
    lng: 10.7522,
    iso: true,
    name: "Norway",
  },
  om: {
    capital: "Muscat",
    code: "om",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/om.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/om.svg",
    lat: 23.6102,
    lng: 58.5922,
    iso: true,
    name: "Oman",
  },
  pk: {
    capital: "Islamabad",
    code: "pk",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pk.svg",
    lat: 33.6844,
    lng: 73.0479,
    iso: true,
    name: "Pakistan",
  },
  pw: {
    capital: "Ngerulmud",
    code: "pw",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pw.svg",
    lat: 7.5004,
    lng: 134.6248,
    iso: true,
    name: "Palau",
  },
  pa: {
    capital: "Panama City",
    code: "pa",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pa.svg",
    lat: 8.9824,
    lng: -79.5199,
    iso: true,
    name: "Panama",
  },
  pg: {
    capital: "Port Moresby",
    code: "pg",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pg.svg",
    lat: -9.4647,
    lng: 147.1925,
    iso: true,
    name: "Papua New Guinea",
  },
  py: {
    capital: "Asunción",
    code: "py",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/py.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/py.svg",
    lat: -25.2637,
    lng: -57.5759,
    iso: true,
    name: "Paraguay",
  },
  pe: {
    capital: "Lima",
    code: "pe",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pe.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pe.svg",
    lat: 14.5995,
    lng: 120.9842,
    iso: true,
    name: "Peru",
  },
  ph: {
    capital: "Manila",
    code: "ph",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ph.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ph.svg",
    lat: 14.5995,
    lng: 120.9842,
    iso: true,
    name: "Philippines",
  },
  pn: {
    capital: "Adamstown",
    code: "pn",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pn.svg",
    lat: -25.0657,
    lng: -130.1014,
    iso: true,
    name: "Pitcairn",
  },
  pl: {
    capital: "Warsaw",
    code: "pl",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pl.svg",
    lat: 52.2297,
    lng: 21.0122,
    iso: true,
    name: "Poland",
  },
  pt: {
    capital: "Lisbon",
    code: "pt",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pt.svg",
    lat: 38.7223,
    lng: -9.1393,
    iso: true,
    name: "Portugal",
  },
  pr: {
    capital: "San Juan",
    code: "pr",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pr.svg",
    lat: 18.4655,
    lng: -66.1057,
    iso: true,
    name: "Puerto Rico",
  },
  qa: {
    capital: "Doha",
    code: "qa",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/qa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/qa.svg",
    lat: 25.2769,
    lng: 51.5209,
    iso: true,
    name: "Qatar",
  },
  cg: {
    capital: "Brazzaville",
    code: "cg",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cg.svg",
    lat: -4.2634,
    lng: 15.2429,
    iso: true,
    name: "Republic of the Congo",
  },
  ro: {
    capital: "Bucharest",
    code: "ro",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ro.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ro.svg",
    lat: 44.4268,
    lng: 26.1025,
    iso: true,
    name: "Romania",
  },
  ru: {
    capital: "Moscow",
    code: "ru",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ru.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ru.svg",
    lat: 55.7558,
    lng: 37.6176,
    iso: true,
    name: "Russia",
  },
  rw: {
    capital: "Kigali",
    code: "rw",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rw.svg",
    lat: -1.9536,
    lng: 30.0606,
    iso: true,
    name: "Rwanda",
  },
  re: {
    capital: "Saint-Denis",
    code: "re",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/re.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/re.svg",
    lat: -20.8823,
    lng: 55.4508,
    iso: true,
    name: "Réunion",
  },
  bl: {
    capital: "Gustavia",
    code: "bl",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bl.svg",
    lat: 17.9023,
    lng: -62.8521,
    iso: true,
    name: "Saint Barthélemy",
  },
  sh: {
    capital: "Jamestown",
    code: "sh",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sh.svg",
    lat: -15.941,
    lng: -5.717,
    iso: true,
    name: "Saint Helena, Ascension and Tristan da Cunha",
  },
  kn: {
    capital: "Basseterre",
    code: "kn",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kn.svg",
    lat: 17.3026,
    lng: -62.7177,
    iso: true,
    name: "Saint Kitts and Nevis",
  },
  lc: {
    capital: "Castries",
    code: "lc",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lc.svg",
    lat: 14.0101,
    lng: -60.9877,
    iso: true,
    name: "Saint Lucia",
  },
  mf: {
    capital: "Marigot",
    code: "mf",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mf.svg",
    lat: 18.0731,
    lng: -63.0822,
    iso: true,
    name: "Saint Martin",
  },
  pm: {
    capital: "Saint-Pierre",
    code: "pm",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pm.svg",
    lat: 46.7804,
    lng: -56.1763,
    iso: true,
    name: "Saint Pierre and Miquelon",
  },
  vc: {
    capital: "Kingstown",
    code: "vc",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vc.svg",
    lat: 13.2528,
    lng: -61.1971,
    iso: true,
    name: "Saint Vincent and the Grenadines",
  },
  ws: {
    capital: "Apia",
    code: "ws",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ws.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ws.svg",
    lat: -13.759,
    lng: -172.1046,
    iso: true,
    name: "Samoa",
  },
  sm: {
    capital: "San Marino",
    code: "sm",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sm.svg",
    lat: 43.9424,
    lng: 12.4578,
    iso: true,
    name: "San Marino",
  },
  st: {
    capital: "São Tomé",
    code: "st",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/st.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/st.svg",
    lat: 0.1864,
    lng: 6.6131,
    iso: true,
    name: "Sao Tome and Principe",
  },
  sa: {
    capital: "Riyadh",
    code: "sa",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sa.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sa.svg",
    lat: 24.7136,
    lng: 46.6753,
    iso: true,
    name: "Saudi Arabia",
  },
  sn: {
    capital: "Dakar",
    code: "sn",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sn.svg",
    lat: 14.4974,
    lng: -14.4524,
    iso: true,
    name: "Senegal",
  },
  rs: {
    capital: "Belgrade",
    code: "rs",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rs.svg",
    lat: 44.0165,
    lng: 21.0059,
    iso: true,
    name: "Serbia",
  },
  sc: {
    capital: "Victoria",
    code: "sc",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sc.svg",
    lat: -4.6796,
    lng: 55.492,
    iso: true,
    name: "Seychelles",
  },
  sl: {
    capital: "Freetown",
    code: "sl",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sl.svg",
    lat: 8.4606,
    lng: -11.7799,
    iso: true,
    name: "Sierra Leone",
  },
  sg: {
    capital: "Singapore",
    code: "sg",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sg.svg",
    lat: 1.3521,
    lng: 103.8198,
    iso: true,
    name: "Singapore",
  },
  sk: {
    capital: "Bratislava",
    code: "sk",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sk.svg",
    lat: 48.669,
    lng: 19.699,
    iso: true,
    name: "Slovakia",
  },
  si: {
    capital: "Ljubljana",
    code: "si",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/si.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/si.svg",
    lat: 46.1512,
    lng: 14.9955,
    iso: true,
    name: "Slovenia",
  },
  sb: {
    capital: "Honiara",
    code: "sb",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sb.svg",
    lat: -9.7448,
    lng: 160.0662,
    iso: true,
    name: "Solomon Islands",
  },
  so: {
    capital: "Mogadishu",
    code: "so",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/so.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/so.svg",
    lat: 5.1521,
    lng: 46.1996,
    iso: true,
    name: "Somalia",
  },
  za: {
    capital: "Pretoria",
    code: "za",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/za.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/za.svg",
    lat: -30.5595,
    lng: 22.9375,
    iso: true,
    name: "South Africa",
  },
  gs: {
    capital: "King Edward Point",
    code: "gs",
    continent: "Antarctica",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gs.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gs.svg",
    iso: true,
    lat: -54.4296,
    lng: -36.5874,
    name: "South Georgia and the South Sandwich Islands",
  },
  kr: {
    capital: "Seoul",
    code: "kr",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kr.svg",
    lat: 35.9078,
    lng: 127.7669,
    iso: true,
    name: "South Korea",
  },
  ss: {
    capital: "Juba",
    code: "ss",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ss.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ss.svg",
    lat: 7.8624,
    lng: 29.6949,
    iso: true,
    name: "South Sudan",
  },
  es: {
    capital: "Madrid",
    code: "es",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/es.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/es.svg",
    lat: 40.4637,
    lng: -3.7492,
    iso: true,
    name: "Spain",
  },
  lk: {
    capital: "Sri Jayawardenepura Kotte, Colombo",
    code: "lk",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lk.svg",
    lat: 7.8731,
    lng: 80.7718,
    iso: true,
    name: "Sri Lanka",
  },
  ps: {
    capital: "Ramallah",
    code: "ps",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ps.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ps.svg",
    lat: 31.9522,
    lng: 35.2332,
    iso: true,
    name: "State of Palestine",
  },
  sd: {
    capital: "Khartoum",
    code: "sd",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sd.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sd.svg",
    lat: 12.8628,
    lng: 30.2176,
    iso: true,
    name: "Sudan",
  },
  sr: {
    capital: "Paramaribo",
    code: "sr",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sr.svg",
    lat: 3.9193,
    lng: -56.0278,
    iso: true,
    name: "Suriname",
  },
  sj: {
    capital: "Longyearbyen",
    code: "sj",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sj.svg",
    lat: 77.5536,
    lng: 23.6703,
    iso: true,
    name: "Svalbard and Jan Mayen",
  },
  se: {
    capital: "Stockholm",
    code: "se",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/se.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/se.svg",
    lat: 60.1282,
    lng: 18.6435,
    iso: true,
    name: "Sweden",
  },
  ch: {
    capital: "Bern",
    code: "ch",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ch.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ch.svg",
    lat: 46.8182,
    lng: 8.2275,
    iso: true,
    name: "Switzerland",
  },
  sy: {
    capital: "Damascus",
    code: "sy",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sy.svg",
    lat: 34.8021,
    lng: 38.9968,
    iso: true,
    name: "Syria",
  },
  tw: {
    capital: "Taipei",
    code: "tw",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tw.svg",
    lat: 23.6978,
    lng: 120.9605,
    iso: true,
    name: "Taiwan",
  },
  tj: {
    capital: "Dushanbe",
    code: "tj",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tj.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tj.svg",
    lat: 38.861,
    lng: 71.2761,
    iso: true,
    name: "Tajikistan",
  },
  tz: {
    capital: "Dodoma",
    code: "tz",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tz.svg",
    lat: -6.369,
    lng: 34.8888,
    iso: true,
    name: "Tanzania",
  },
  th: {
    capital: "Bangkok",
    code: "th",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/th.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/th.svg",
    lat: 15.87,
    lng: 100.9925,
    iso: true,
    name: "Thailand",
  },
  tl: {
    capital: "Dili",
    code: "tl",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tl.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tl.svg",
    lat: -8.8742,
    lng: 125.7275,
    iso: true,
    name: "Timor-Leste",
  },
  tg: {
    capital: "Lomé",
    code: "tg",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tg.svg",
    lat: 8.6195,
    lng: 0.8248,
    iso: true,
    name: "Togo",
  },
  tk: {
    capital: "Nukunonu, Atafu,Tokelau",
    code: "tk",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tk.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tk.svg",
    lat: -9.2005,
    lng: -171.8484,
    iso: true,
    name: "Tokelau",
  },
  to: {
    capital: "Nukuʻalofa",
    code: "to",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/to.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/to.svg",
    lat: -21.1789,
    lng: -175.1982,
    iso: true,
    name: "Tonga",
  },
  tt: {
    capital: "Port of Spain",
    code: "tt",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tt.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tt.svg",
    lat: 10.6918,
    lng: -61.2225,
    iso: true,
    name: "Trinidad and Tobago",
  },
  tn: {
    capital: "Tunis",
    code: "tn",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tn.svg",
    lat: 33.8869,
    lng: 9.5375,
    iso: true,
    name: "Tunisia",
  },
  tr: {
    capital: "Ankara",
    code: "tr",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tr.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tr.svg",
    lat: 39.9334,
    lng: 32.8597,
    iso: true,
    name: "Turkey",
  },
  tm: {
    capital: "Ashgabat",
    code: "tm",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tm.svg",
    lat: 38.9697,
    lng: 59.5563,
    iso: true,
    name: "Turkmenistan",
  },
  tc: {
    capital: "Cockburn Town",
    code: "tc",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tc.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tc.svg",
    lat: 21.694,
    lng: -71.7979,
    iso: true,
    name: "Turks and Caicos Islands",
  },
  tv: {
    capital: "Funafuti",
    code: "tv",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tv.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tv.svg",
    lat: -8.5178,
    lng: 179.2185,
    iso: true,
    name: "Tuvalu",
  },
  ug: {
    capital: "Kampala",
    code: "ug",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ug.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ug.svg",
    lat: 0.3136,
    lng: 32.5811,
    iso: true,
    name: "Uganda",
  },
  ua: {
    capital: "Kiev",
    code: "ua",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ua.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ua.svg",
    lat: 50.4501,
    lng: 30.5234,
    iso: true,
    name: "Ukraine",
  },
  ae: {
    capital: "Abu Dhabi",
    code: "ae",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ae.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ae.svg",
    lat: 24.4667,
    lng: 54.3667,
    iso: true,
    name: "United Arab Emirates",
  },
  gb: {
    capital: "London",
    code: "gb",
    continent: "Europe",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gb.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gb.svg",
    lat: 51.5099,
    lng: -0.118,
    iso: true,
    name: "United Kingdom",
  },
  um: {
    capital: "Washington, D.C.",
    code: "um",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/um.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/um.svg",
    lat: 38.8951,
    lng: -77.0369,
    iso: true,
    name: "United States Minor Outlying Islands",
  },
  us: {
    capital: "Washington, D.C.",
    code: "us",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/us.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/us.svg",
    lat: 38.8951,
    lng: -77.0369,
    iso: true,
    name: "United States of America",
  },
  uy: {
    capital: "Montevideo",
    code: "uy",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uy.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uy.svg",
    lat: -34.9011,
    lng: -56.1918,
    iso: true,
    name: "Uruguay",
  },
  uz: {
    capital: "Tashkent",
    code: "uz",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uz.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uz.svg",
    lat: 41.2995,
    lng: 69.2401,
    iso: true,
    name: "Uzbekistan",
  },
  vu: {
    capital: "Port Vila",
    code: "vu",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vu.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vu.svg",
    lat: -17.7404,
    lng: 168.3213,
    iso: true,
    name: "Vanuatu",
  },
  ve: {
    capital: "Caracas",
    code: "ve",
    continent: "South America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ve.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ve.svg",
    lat: 10.4961,
    lng: -66.8815,
    iso: true,
    name: "Venezuela",
  },
  vn: {
    capital: "Hanoi",
    code: "vn",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vn.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vn.svg",
    lat: 21.0285,
    lng: 105.8542,
    iso: true,
    name: "Vietnam",
  },
  vg: {
    capital: "Road Town",
    code: "vg",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vg.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vg.svg",
    lat: 18.4167,
    lng: -64.6167,
    iso: true,
    name: "Virgin Islands (British)",
  },
  vi: {
    capital: "Charlotte Amalie",
    code: "vi",
    continent: "North America",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vi.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vi.svg",
    lat: 18.3358,
    lng: -64.8963,
    iso: true,
    name: "Virgin Islands (U.S.)",
  },
  wf: {
    capital: "Mata-Utu",
    code: "wf",
    continent: "Oceania",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/wf.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/wf.svg",
    lat: -13.2818,
    lng: -176.1748,
    iso: true,
    name: "Wallis and Futuna",
  },
  eh: {
    capital: "Laayoune",
    code: "eh",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eh.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eh.svg",
    lat: 24.2155,
    lng: -12.8858,
    iso: true,
    name: "Western Sahara",
  },
  ye: {
    capital: "Sana'a",
    code: "ye",
    continent: "Asia",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ye.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ye.svg",
    lat: 15.3695,
    lng: 44.191,
    iso: true,
    name: "Yemen",
  },
  zm: {
    capital: "Lusaka",
    code: "zm",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zm.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zm.svg",
    lat: -15.4167,
    lng: 28.2833,
    iso: true,
    name: "Zambia",
  },
  zw: {
    capital: "Harare",
    code: "zw",
    continent: "Africa",
    flag_1x1:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zw.svg",
    flag_4x3:
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zw.svg",
    lat: -17.8292,
    lng: 31.0522,
    iso: true,
    name: "Zimbabwe",
  },
};

export const COUNTRY_CONSTANTS_INTL = (
  intl: IntlShape
): { [key: string]: CountryType } => {
  return {
    af: {
      capital: "Kabul",
      code: "af",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/af.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/af.svg",
      lat: 33.93911,
      lng: 67.709953,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AF" }),
    },
    ax: {
      capital: "Mariehamn",
      code: "ax",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ax.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ax.svg",
      iso: true,
      lat: 60.1167,
      lng: 19.9,
      name: intl.formatMessage({ id: "countryOption_AX" }),
    },
    al: {
      capital: "Tirana",
      code: "al",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/al.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/al.svg",
      lat: 41.3275,
      lng: 19.8187,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AL" }),
    },
    dz: {
      capital: "Algiers",
      code: "dz",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dz.svg",
      lat: 36.7528,
      lng: 3.0422,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DZ" }),
    },
    as: {
      capital: "Pago Pago",
      code: "as",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/as.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/as.svg",
      iso: true,
      lat: -14.2756,
      lng: -170.7028,
      name: intl.formatMessage({ id: "countryOption_AS" }),
    },
    ad: {
      capital: "Andorra la Vella",
      code: "ad",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ad.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ad.svg",
      lat: 42.5063,
      lng: 1.5218,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AD" }),
    },
    ao: {
      capital: "Luanda",
      code: "ao",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ao.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ao.svg",
      lat: -8.8137,
      lng: 13.2441,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AO" }),
    },
    ai: {
      capital: "The Valley",
      code: "ai",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ai.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ai.svg",
      lat: 18.2206,
      lng: -63.0686,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AI" }),
    },
    aq: {
      code: "aq",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aq.svg",
      lat: -90,
      lng: 0,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AQ" }),
    },
    ag: {
      capital: "St. John's",
      code: "ag",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ag.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ag.svg",
      lat: 17.0608,
      lng: -61.7964,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AG" }),
    },
    ar: {
      capital: "Buenos Aires",
      code: "ar",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ar.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ar.svg",
      lat: -34.6118,
      lng: -58.4173,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AR" }),
    },
    am: {
      capital: "Yerevan",
      code: "am",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/am.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/am.svg",
      lat: 40.1792,
      lng: 44.4991,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AM" }),
    },
    aw: {
      capital: "Oranjestad",
      code: "aw",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/aw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/aw.svg",
      lat: 12.5211,
      lng: -69.9683,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AW" }),
    },
    au: {
      capital: "Canberra",
      code: "au",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/au.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/au.svg",
      lat: -35.3081,
      lng: 149.1244,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AU" }),
    },
    at: {
      capital: "Vienna",
      code: "at",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/at.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/at.svg",
      lat: 48.2082,
      lng: 16.3738,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AT" }),
    },
    az: {
      capital: "Baku",
      code: "az",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/az.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/az.svg",
      lat: 40.4093,
      lng: 49.8671,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AZ" }),
    },
    bs: {
      capital: "Nassau",
      code: "bs",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bs.svg",
      lat: 25.0343,
      lng: -77.3963,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BS" }),
    },
    bh: {
      capital: "Manama",
      code: "bh",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bh.svg",
      lat: 26.2235,
      lng: 50.587,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BH" }),
    },
    bd: {
      capital: "Dhaka",
      code: "bd",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bd.svg",
      lat: 23.8103,
      lng: 90.4125,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BD" }),
    },
    bb: {
      capital: "Bridgetown",
      code: "bb",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bb.svg",
      lat: 13.0975,
      lng: -59.6167,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BB" }),
    },
    by: {
      capital: "Minsk",
      code: "by",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/by.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/by.svg",
      lat: 53.9045,
      lng: 27.5615,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BY" }),
    },
    be: {
      capital: "Brussels",
      code: "be",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/be.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/be.svg",
      lat: 50.8503,
      lng: 4.3517,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BE" }),
    },
    bz: {
      capital: "Belmopan",
      code: "bz",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bz.svg",
      lat: 17.2534,
      lng: -88.769,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BZ" }),
    },
    bj: {
      capital: "Porto-Novo",
      code: "bj",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bj.svg",
      lat: 6.3528,
      lng: 2.4258,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BJ" }),
    },
    bm: {
      capital: "Hamilton",
      code: "bm",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bm.svg",
      lat: 32.2948,
      lng: -64.781,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BM" }),
    },
    bt: {
      capital: "Thimphu",
      code: "bt",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bt.svg",
      lat: 27.4661,
      lng: 89.6419,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BT" }),
    },
    bo: {
      capital: "Sucre",
      code: "bo",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bo.svg",
      lat: -19.0208,
      lng: -65.2622,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BO" }),
    },

    ba: {
      capital: "Sarajevo",
      code: "ba",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ba.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ba.svg",
      lat: 43.8563,
      lng: 18.4131,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BA" }),
    },
    bw: {
      capital: "Gaborone",
      code: "bw",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bw.svg",
      lat: -24.6583,
      lng: 25.9122,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BW" }),
    },
    bv: {
      code: "bv",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bv.svg",
      lat: -54.4232,
      lng: 3.4139,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BV" }),
    },
    br: {
      capital: "Brasília",
      code: "br",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/br.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/br.svg",
      lat: -15.7801,
      lng: -47.9292,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BR" }),
    },
    io: {
      capital: "Diego Garcia",
      code: "io",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/io.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/io.svg",
      lat: -7.3133,
      lng: 72.4248,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IO" }),
    },
    bn: {
      capital: "Bandar Seri Begawan",
      code: "bn",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bn.svg",
      lat: 4.9031,
      lng: 114.9398,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BN" }),
    },
    bg: {
      capital: "Sofia",
      code: "bg",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bg.svg",
      lat: 42.6977,
      lng: 23.3219,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BG" }),
    },
    bf: {
      capital: "Ouagadougou",
      code: "bf",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bf.svg",
      lat: 12.3714,
      lng: -1.5197,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BF" }),
    },
    bi: {
      capital: "Bujumbura",
      code: "bi",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bi.svg",
      lat: -3.3764,
      lng: 29.3599,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BI" }),
    },
    cv: {
      capital: "Praia",
      code: "cv",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cv.svg",
      lat: 16.5388,
      lng: -23.0418,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CV" }),
    },
    kh: {
      capital: "Phnom Penh",
      code: "kh",
      continent: "Asia",
      lat: 11.5564,
      lng: 104.9282,
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kh.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KH" }),
    },
    cm: {
      capital: "Yaoundé",
      code: "cm",
      continent: "Africa",
      lat: 3.848,
      lng: 11.5021,
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cm.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CM" }),
    },
    ca: {
      capital: "Ottawa",
      code: "ca",
      continent: "North America",
      lat: 45.4215,
      lng: -75.6919,
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ca.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ca.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CA" }),
    },

    ky: {
      capital: "George Town",
      code: "ky",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ky.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ky.svg",
      lat: 19.3133,
      lng: -81.2546,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KY" }),
    },
    cf: {
      capital: "Bangui",
      code: "cf",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cf.svg",
      lat: 6.6111,
      lng: 20.9394,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CF" }),
    },

    td: {
      capital: "N'Djamena",
      code: "td",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/td.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/td.svg",
      lat: 12.6392,
      lng: 15.315,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TD" }),
    },
    cl: {
      capital: "Santiago",
      code: "cl",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cl.svg",
      lat: -33.4489,
      lng: -70.6693,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CL" }),
    },
    cn: {
      capital: "Beijing",
      code: "cn",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cn.svg",
      lat: 39.9042,
      lng: 116.4074,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CN" }),
    },
    cx: {
      capital: "Flying Fish Cove",
      code: "cx",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cx.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cx.svg",
      lat: -10.4918,
      lng: 105.6312,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CX" }),
    },
    cc: {
      capital: "West Island",
      code: "cc",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cc.svg",
      lat: -12.1642,
      lng: 96.8166,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CC" }),
    },
    co: {
      capital: "Bogotá",
      code: "co",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/co.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/co.svg",
      lat: 4.5709,
      lng: -74.2973,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CO" }),
    },
    km: {
      capital: "Moroni",
      code: "km",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/km.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/km.svg",
      lat: -11.6455,
      lng: 43.3336,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KM" }),
    },
    ck: {
      capital: "Avarua",
      code: "ck",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ck.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ck.svg",
      lat: -21.2075,
      lng: -159.7735,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CK" }),
    },
    cr: {
      capital: "San José",
      code: "cr",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cr.svg",
      lat: 9.9281,
      lng: -84.0907,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CR" }),
    },
    hr: {
      capital: "Zagreb",
      code: "hr",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hr.svg",
      lat: 45.815,
      lng: 15.9819,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HR" }),
    },
    cu: {
      capital: "Havana",
      code: "cu",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cu.svg",
      lat: 23.1136,
      lng: -82.3666,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CU" }),
    },
    cy: {
      capital: "Nicosia",
      code: "cy",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cy.svg",
      lat: 35.1264,
      lng: 33.4299,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CY" }),
    },
    cz: {
      capital: "Prague",
      code: "cz",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cz.svg",
      lat: 50.0755,
      lng: 14.4378,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CZ" }),
    },
    ci: {
      capital: "Yamoussoukro",
      code: "ci",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ci.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ci.svg",
      lat: 6.2596,
      lng: -5.8123,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CI" }),
    },
    cd: {
      capital: "Kinshasa",
      code: "cd",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cd.svg",
      lat: -4.4419,
      lng: 15.2663,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CD" }),
    },
    dk: {
      capital: "Copenhagen",
      code: "dk",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dk.svg",
      lat: 55.6761,
      lng: 12.5683,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DK" }),
    },
    dj: {
      capital: "Djibouti",
      code: "dj",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dj.svg",
      lat: 11.8251,
      lng: 42.5903,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DJ" }),
    },
    dm: {
      capital: "Roseau",
      code: "dm",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/dm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/dm.svg",
      lat: 15.301,
      lng: -61.387,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DM" }),
    },
    do: {
      capital: "Santo Domingo",
      code: "do",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/do.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/do.svg",
      lat: 18.4861,
      lng: -69.9312,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DO" }),
    },
    ec: {
      capital: "Quito",
      code: "ec",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ec.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ec.svg",
      lat: -0.2295,
      lng: -78.5243,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_EC" }),
    },
    eg: {
      capital: "Cairo",
      code: "eg",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eg.svg",
      lat: 30.0444,
      lng: 31.2357,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_EG" }),
    },
    sv: {
      capital: "San Salvador",
      code: "sv",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sv.svg",
      lat: 13.6929,
      lng: -89.2182,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SV" }),
    },
    gq: {
      capital: "Malabo",
      code: "gq",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gq.svg",
      lat: 3.7509,
      lng: 8.7386,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GQ" }),
    },
    er: {
      capital: "Asmara",
      code: "er",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/er.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/er.svg",
      lat: 15.3229,
      lng: 38.9251,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ER" }),
    },
    ee: {
      capital: "Tallinn",
      code: "ee",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ee.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ee.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_EE" }),
      lat: 59.437,
      lng: 24.7536,
    },
    sz: {
      capital: "Lobamba, Mbabane",
      code: "sz",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sz.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SZ" }),
      lat: -26.5225,
      lng: 31.4659,
    },
    et: {
      capital: "Addis Ababa",
      code: "et",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/et.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/et.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ET" }),
      lat: 9.145,
      lng: 40.4897,
    },
    fk: {
      capital: "Stanley",
      code: "fk",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fk.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FK" }),
      lat: -51.7963,
      lng: -59.5236,
    },
    fo: {
      capital: "Tórshavn",
      code: "fo",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fo.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FO" }),
      lat: 62.0079,
      lng: -6.7761,
    },
    fm: {
      capital: "Palikir",
      code: "fm",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fm.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FM" }),
      lat: 7.4256,
      lng: 150.5508,
    },
    fj: {
      capital: "Suva",
      code: "fj",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fj.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FJ" }),
      lat: -18.1248,
      lng: 178.4501,
    },
    fi: {
      capital: "Helsinki",
      code: "fi",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fi.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FI" }),
      lat: 61.9241,
      lng: 25.7482,
    },
    fr: {
      capital: "Paris",
      code: "fr",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/fr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/fr.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_FR" }),
      lat: 46.6034,
      lng: 1.8883,
    },
    gf: {
      capital: "Cayenne",
      code: "gf",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gf.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GF" }),
      lat: 3.9339,
      lng: -53.1258,
    },
    pf: {
      capital: "Papeete",
      code: "pf",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pf.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PF" }),
      lat: -17.6797,
      lng: -149.4068,
    },
    tf: {
      capital: "Saint-Pierre, Réunion",
      code: "tf",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tf.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TF" }),
      lat: -49.2804,
      lng: 69.3486,
    },
    ga: {
      capital: "Libreville",
      code: "ga",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ga.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ga.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GA" }),
      lat: -0.8037,
      lng: 11.6094,
    },
    gm: {
      capital: "Banjul",
      code: "gm",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gm.svg",
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GM" }),
      lat: 13.4432,
      lng: -15.3101,
    },
    ge: {
      capital: "Tbilisi",
      code: "ge",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ge.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ge.svg",
      lat: 41.7151,
      lng: 44.8271,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GE" }),
    },
    de: {
      capital: "Berlin",
      code: "de",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/de.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/de.svg",
      lat: 52.52,
      lng: 13.405,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_DE" }),
    },
    gh: {
      capital: "Accra",
      code: "gh",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gh.svg",
      lat: 5.56,
      lng: -0.2057,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GH" }),
    },
    gi: {
      capital: "Gibraltar",
      code: "gi",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gi.svg",
      lat: 36.1377,
      lng: -5.3454,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GI" }),
    },
    gr: {
      capital: "Athens",
      code: "gr",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gr.svg",
      lat: 37.9838,
      lng: 23.7275,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GR" }),
    },
    gl: {
      capital: "Nuuk",
      code: "gl",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gl.svg",
      lat: 64.1814,
      lng: -51.6941,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GL" }),
    },
    gd: {
      capital: "St. George's",
      code: "gd",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gd.svg",
      lat: 12.0522,
      lng: -61.749,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GD" }),
    },
    gp: {
      capital: "Basse-Terre",
      code: "gp",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gp.svg",
      lat: 16.265,
      lng: -61.5509,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GP" }),
    },
    gu: {
      capital: "Hagåtña",
      code: "gu",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gu.svg",
      lat: 13.4443,
      lng: 144.7937,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GU" }),
    },
    gt: {
      capital: "Guatemala City",
      code: "gt",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gt.svg",
      lat: 14.6349,
      lng: -90.5069,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GT" }),
    },
    gg: {
      capital: "Saint Peter Port",
      code: "gg",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gg.svg",
      lat: 49.4482,
      lng: -2.5895,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GG" }),
    },
    gn: {
      capital: "Conakry",
      code: "gn",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gn.svg",
      lat: 9.5095,
      lng: -13.712,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GN" }),
    },
    gw: {
      capital: "Bissau",
      code: "gw",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gw.svg",
      lat: 11.8037,
      lng: -15.1804,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GW" }),
    },
    gy: {
      capital: "Georgetown",
      code: "gy",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gy.svg",
      lat: 4.8604,
      lng: -58.9302,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GY" }),
    },
    ht: {
      capital: "Port-au-Prince",
      code: "ht",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ht.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ht.svg",
      lat: 18.5944,
      lng: -72.3074,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HT" }),
    },
    hm: {
      code: "hm",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hm.svg",
      lat: -53.0818,
      lng: 73.5042,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HM" }),
    },
    va: {
      capital: "Vatican City",
      code: "va",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/va.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/va.svg",
      lat: 41.9029,
      lng: 12.4534,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VA" }),
    },
    hn: {
      capital: "Tegucigalpa",
      code: "hn",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hn.svg",
      lat: 14.0723,
      lng: -87.1921,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HN" }),
    },
    hk: {
      capital: "Hong Kong",
      code: "hk",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hk.svg",
      lat: 22.3193,
      lng: 114.1694,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HK" }),
    },
    hu: {
      capital: "Budapest",
      code: "hu",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/hu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/hu.svg",
      lat: 47.1625,
      lng: 19.5033,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_HU" }),
    },
    is: {
      capital: "Reykjavik",
      code: "is",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/is.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/is.svg",
      lat: 64.1466,
      lng: -21.9426,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IS" }),
    },
    in: {
      capital: "New Delhi",
      code: "in",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/in.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/in.svg",
      lat: 28.6139,
      lng: 77.209,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IN" }),
    },
    id: {
      capital: "Jakarta",
      code: "id",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/id.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/id.svg",
      lat: -6.2088,
      lng: 106.8456,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ID" }),
    },
    ir: {
      capital: "Tehran",
      code: "ir",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ir.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ir.svg",
      lat: 35.6895,
      lng: 51.594,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IR" }),
    },
    iq: {
      capital: "Baghdad",
      code: "iq",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/iq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/iq.svg",
      lat: 33.3152,
      lng: 44.3661,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IQ" }),
    },
    ie: {
      capital: "Dublin",
      code: "ie",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ie.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ie.svg",
      lat: 53.3498,
      lng: -6.2603,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IE" }),
    },
    im: {
      capital: "Douglas",
      code: "im",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/im.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/im.svg",
      lat: 54.2361,
      lng: -4.5481,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IM" }),
    },
    il: {
      capital: "Jerusalem",
      code: "il",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/il.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/il.svg",
      lat: 31.7683,
      lng: 35.2137,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IL" }),
    },
    it: {
      capital: "Rome",
      code: "it",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/it.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/it.svg",
      lat: 41.9028,
      lng: 12.4964,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_IT" }),
    },
    jm: {
      capital: "Kingston",
      code: "jm",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jm.svg",
      lat: 17.9714,
      lng: -76.7928,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_JM" }),
    },
    jp: {
      capital: "Tokyo",
      code: "jp",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jp.svg",
      lat: 35.682839,
      lng: 139.759455,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_JP" }),
    },
    je: {
      capital: "Saint Helier",
      code: "je",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/je.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/je.svg",
      lat: 49.1859,
      lng: -2.1071,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_JE" }),
    },
    jo: {
      capital: "Amman",
      code: "jo",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/jo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/jo.svg",
      lat: 31.9522,
      lng: 35.2332,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_JO" }),
    },
    kz: {
      capital: "Astana",
      code: "kz",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kz.svg",
      lat: 51.1605,
      lng: 71.4704,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KZ" }),
    },
    ke: {
      capital: "Nairobi",
      code: "ke",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ke.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ke.svg",
      lat: -1.2921,
      lng: 36.8219,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KE" }),
    },
    ki: {
      capital: "South Tarawa",
      code: "ki",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ki.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ki.svg",
      lat: 1.3292,
      lng: 172.9762,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KI" }),
    },

    kw: {
      capital: "Kuwait City",
      code: "kw",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kw.svg",
      lat: 29.3759,
      lng: 47.9774,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KW" }),
    },
    kg: {
      capital: "Bishkek",
      code: "kg",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kg.svg",
      lat: 42.8746,
      lng: 74.5698,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KG" }),
    },
    la: {
      capital: "Vientiane",
      code: "la",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/la.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/la.svg",
      lat: 17.9757,
      lng: 102.6331,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LA" }),
    },
    lv: {
      capital: "Riga",
      code: "lv",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lv.svg",
      lat: 56.9496,
      lng: 24.1052,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LV" }),
    },
    lb: {
      capital: "Beirut",
      code: "lb",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lb.svg",
      lat: 33.8938,
      lng: 35.5018,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LB" }),
    },
    ls: {
      capital: "Maseru",
      code: "ls",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ls.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ls.svg",
      lat: -29.6099,
      lng: 28.233,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LS" }),
    },
    lr: {
      capital: "Monrovia",
      code: "lr",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lr.svg",
      lat: 6.2907,
      lng: -10.7605,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LR" }),
    },
    ly: {
      capital: "Tripoli",
      code: "ly",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ly.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ly.svg",
      lat: 32.8872,
      lng: 13.1913,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LY" }),
    },
    li: {
      capital: "Vaduz",
      code: "li",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/li.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/li.svg",
      lat: 47.166,
      lng: 9.5554,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LI" }),
    },
    lt: {
      capital: "Vilnius",
      code: "lt",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lt.svg",
      lat: 55.1694,
      lng: 23.8813,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LT" }),
    },
    lu: {
      capital: "Luxembourg City",
      code: "lu",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lu.svg",
      lat: 49.61,
      lng: 6.1296,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LU" }),
    },
    mo: {
      capital: "Macau",
      code: "mo",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mo.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mo.svg",
      lat: 22.1987,
      lng: 113.5439,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MO" }),
    },
    mg: {
      capital: "Antananarivo",
      code: "mg",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mg.svg",
      lat: -18.8792,
      lng: 47.5079,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MG" }),
    },
    mw: {
      capital: "Lilongwe",
      code: "mw",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mw.svg",
      lat: -13.9632,
      lng: 33.7741,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MW" }),
    },
    my: {
      capital: "Kuala Lumpur",
      code: "my",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/my.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/my.svg",
      lat: 3.139,
      lng: 101.6869,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MY" }),
    },
    mv: {
      capital: "Malé",
      code: "mv",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mv.svg",
      lat: 4.1755,
      lng: 73.5093,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MV" }),
    },
    ml: {
      capital: "Bamako",
      code: "ml",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ml.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ml.svg",
      lat: 12.6392,
      lng: -7.9934,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ML" }),
    },
    mt: {
      capital: "Valletta",
      code: "mt",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mt.svg",
      lat: 35.8989,
      lng: 14.5146,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MT" }),
    },
    mh: {
      capital: "Majuro",
      code: "mh",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mh.svg",
      lat: 7.1164,
      lng: 171.1859,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MH" }),
    },
    mq: {
      capital: "Fort-de-France",
      code: "mq",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mq.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mq.svg",
      lat: 14.6415,
      lng: -61.0242,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MQ" }),
    },
    mr: {
      capital: "Nouakchott",
      code: "mr",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mr.svg",
      lat: 18.0665,
      lng: -15.99,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MR" }),
    },
    mu: {
      capital: "Port Louis",
      code: "mu",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mu.svg",
      lat: -20.348404,
      lng: 57.552152,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MU" }),
    },
    yt: {
      capital: "Mamoudzou",
      code: "yt",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/yt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/yt.svg",
      lat: -12.8275,
      lng: 45.1662,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_YT" }),
    },
    mx: {
      capital: "Mexico City",
      code: "mx",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mx.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mx.svg",
      lat: 19.4326,
      lng: -99.1332,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MX" }),
    },
    md: {
      capital: "Chișinău",
      code: "md",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/md.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/md.svg",
      lat: 47.0167,
      lng: 28.8497,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MD" }),
    },
    mc: {
      capital: "Monaco",
      code: "mc",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mc.svg",
      lat: 43.7384,
      lng: 7.4246,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MC" }),
    },
    mn: {
      capital: "Ulaanbaatar",
      code: "mn",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mn.svg",
      lat: 47.9204,
      lng: 106.9085,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MN" }),
    },
    me: {
      capital: "Podgorica",
      code: "me",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/me.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/me.svg",
      lat: 42.7087,
      lng: 19.3744,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ME" }),
    },
    ms: {
      capital: "Little Bay, Brades, Plymouth",
      code: "ms",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ms.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ms.svg",
      lat: 16.7425,
      lng: -62.1874,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MS" }),
    },
    ma: {
      capital: "Rabat",
      code: "ma",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ma.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ma.svg",
      lat: 34.0209,
      lng: -6.8417,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MA" }),
    },
    mz: {
      capital: "Maputo",
      code: "mz",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mz.svg",
      lat: -25.9667,
      lng: 32.5833,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MZ" }),
    },
    mm: {
      capital: "Naypyidaw",
      code: "mm",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mm.svg",
      lat: 19.745,
      lng: 96.1297,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MM" }),
    },
    na: {
      capital: "Windhoek",
      code: "na",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/na.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/na.svg",
      lat: -22.5597,
      lng: 17.0836,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NA" }),
    },
    nr: {
      capital: "Yaren District",
      code: "nr",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nr.svg",
      lat: -0.5477,
      lng: 166.9209,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NR" }),
    },
    np: {
      capital: "Kathmandu",
      code: "np",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/np.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/np.svg",
      lat: 27.7172,
      lng: 85.324,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NP" }),
    },
    nl: {
      capital: "Amsterdam",
      code: "nl",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nl.svg",
      lat: 52.3676,
      lng: 4.9041,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NL" }),
    },
    nc: {
      capital: "Nouméa",
      code: "nc",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nc.svg",
      lat: -22.2711,
      lng: 166.4411,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NC" }),
    },
    nz: {
      capital: "Wellington",
      code: "nz",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nz.svg",
      lat: -41.2865,
      lng: 174.7762,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NZ" }),
    },
    ni: {
      capital: "Managua",
      code: "ni",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ni.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ni.svg",
      lat: 12.8654,
      lng: -85.2072,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NI" }),
    },
    ne: {
      capital: "Niamey",
      code: "ne",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ne.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ne.svg",
      lat: 13.5128,
      lng: 2.1121,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NE" }),
    },
    ng: {
      capital: "Abuja",
      code: "ng",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ng.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ng.svg",
      lat: 9.082,
      lng: 8.6753,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NG" }),
    },
    nu: {
      capital: "Alofi",
      code: "nu",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nu.svg",
      lat: -19.0531,
      lng: -169.8752,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NU" }),
    },
    nf: {
      capital: "Kingston",
      code: "nf",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/nf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/nf.svg",
      lat: -29.0408,
      lng: 167.9547,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NF" }),
    },
    kp: {
      capital: "Pyongyang",
      code: "kp",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kp.svg",
      lat: 39.0392,
      lng: 125.7625,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KP" }),
    },
    mk: {
      capital: "Skopje",
      code: "mk",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mk.svg",
      lat: 41.9973,
      lng: 21.428,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MK" }),
    },

    mp: {
      capital: "Saipan",
      code: "mp",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mp.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mp.svg",
      lat: 15.1804,
      lng: 145.7497,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MP" }),
    },
    no: {
      capital: "Oslo",
      code: "no",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/no.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/no.svg",
      lat: 59.9139,
      lng: 10.7522,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_NO" }),
    },
    om: {
      capital: "Muscat",
      code: "om",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/om.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/om.svg",
      lat: 23.6102,
      lng: 58.5922,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_OM" }),
    },
    pk: {
      capital: "Islamabad",
      code: "pk",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pk.svg",
      lat: 33.6844,
      lng: 73.0479,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PK" }),
    },
    pw: {
      capital: "Ngerulmud",
      code: "pw",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pw.svg",
      lat: 7.5004,
      lng: 134.6248,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PW" }),
    },
    pa: {
      capital: "Panama City",
      code: "pa",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pa.svg",
      lat: 8.9824,
      lng: -79.5199,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PA" }),
    },
    pg: {
      capital: "Port Moresby",
      code: "pg",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pg.svg",
      lat: -9.4647,
      lng: 147.1925,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PG" }),
    },
    py: {
      capital: "Asunción",
      code: "py",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/py.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/py.svg",
      lat: -25.2637,
      lng: -57.5759,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PY" }),
    },
    pe: {
      capital: "Lima",
      code: "pe",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pe.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pe.svg",
      lat: 14.5995,
      lng: 120.9842,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PE" }),
    },
    ph: {
      capital: "Manila",
      code: "ph",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ph.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ph.svg",
      lat: 14.5995,
      lng: 120.9842,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PH" }),
    },
    pn: {
      capital: "Adamstown",
      code: "pn",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pn.svg",
      lat: -25.0657,
      lng: -130.1014,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PN" }),
    },
    pl: {
      capital: "Warsaw",
      code: "pl",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pl.svg",
      lat: 52.2297,
      lng: 21.0122,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PL" }),
    },
    pt: {
      capital: "Lisbon",
      code: "pt",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pt.svg",
      lat: 38.7223,
      lng: -9.1393,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PT" }),
    },
    pr: {
      capital: "San Juan",
      code: "pr",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pr.svg",
      lat: 18.4655,
      lng: -66.1057,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PR" }),
    },
    qa: {
      capital: "Doha",
      code: "qa",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/qa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/qa.svg",
      lat: 25.2769,
      lng: 51.5209,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_QA" }),
    },
    cg: {
      capital: "Brazzaville",
      code: "cg",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/cg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/cg.svg",
      lat: -4.2634,
      lng: 15.2429,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CG" }),
    },
    ro: {
      capital: "Bucharest",
      code: "ro",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ro.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ro.svg",
      lat: 44.4268,
      lng: 26.1025,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_RO" }),
    },
    ru: {
      capital: "Moscow",
      code: "ru",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ru.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ru.svg",
      lat: 55.7558,
      lng: 37.6176,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_RU" }),
    },
    rw: {
      capital: "Kigali",
      code: "rw",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rw.svg",
      lat: -1.9536,
      lng: 30.0606,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_RW" }),
    },
    re: {
      capital: "Saint-Denis",
      code: "re",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/re.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/re.svg",
      lat: -20.8823,
      lng: 55.4508,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_RE" }),
    },
    bl: {
      capital: "Gustavia",
      code: "bl",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/bl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/bl.svg",
      lat: 17.9023,
      lng: -62.8521,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_BL" }),
    },
    sh: {
      capital: "Jamestown",
      code: "sh",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sh.svg",
      lat: -15.941,
      lng: -5.717,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SH" }),
    },
    kn: {
      capital: "Basseterre",
      code: "kn",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kn.svg",
      lat: 17.3026,
      lng: -62.7177,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KN" }),
    },
    lc: {
      capital: "Castries",
      code: "lc",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lc.svg",
      lat: 14.0101,
      lng: -60.9877,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LC" }),
    },
    mf: {
      capital: "Marigot",
      code: "mf",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/mf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/mf.svg",
      lat: 18.0731,
      lng: -63.0822,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_MF" }),
    },
    pm: {
      capital: "Saint-Pierre",
      code: "pm",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/pm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/pm.svg",
      lat: 46.7804,
      lng: -56.1763,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PM" }),
    },
    vc: {
      capital: "Kingstown",
      code: "vc",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vc.svg",
      lat: 13.2528,
      lng: -61.1971,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VC" }),
    },
    ws: {
      capital: "Apia",
      code: "ws",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ws.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ws.svg",
      lat: -13.759,
      lng: -172.1046,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_WS" }),
    },
    sm: {
      capital: "San Marino",
      code: "sm",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sm.svg",
      lat: 43.9424,
      lng: 12.4578,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SM" }),
    },
    st: {
      capital: "São Tomé",
      code: "st",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/st.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/st.svg",
      lat: 0.1864,
      lng: 6.6131,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ST" }),
    },
    sa: {
      capital: "Riyadh",
      code: "sa",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sa.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sa.svg",
      lat: 24.7136,
      lng: 46.6753,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SA" }),
    },
    sn: {
      capital: "Dakar",
      code: "sn",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sn.svg",
      lat: 14.4974,
      lng: -14.4524,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SN" }),
    },
    rs: {
      capital: "Belgrade",
      code: "rs",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/rs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/rs.svg",
      lat: 44.0165,
      lng: 21.0059,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_RS" }),
    },
    sc: {
      capital: "Victoria",
      code: "sc",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sc.svg",
      lat: -4.6796,
      lng: 55.492,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SC" }),
    },
    sl: {
      capital: "Freetown",
      code: "sl",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sl.svg",
      lat: 8.4606,
      lng: -11.7799,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SL" }),
    },
    sg: {
      capital: "Singapore",
      code: "sg",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sg.svg",
      lat: 1.3521,
      lng: 103.8198,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SG" }),
    },
    sk: {
      capital: "Bratislava",
      code: "sk",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sk.svg",
      lat: 48.669,
      lng: 19.699,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SK" }),
    },
    si: {
      capital: "Ljubljana",
      code: "si",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/si.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/si.svg",
      lat: 46.1512,
      lng: 14.9955,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SI" }),
    },
    sb: {
      capital: "Honiara",
      code: "sb",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sb.svg",
      lat: -9.7448,
      lng: 160.0662,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SB" }),
    },
    so: {
      capital: "Mogadishu",
      code: "so",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/so.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/so.svg",
      lat: 5.1521,
      lng: 46.1996,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SO" }),
    },
    za: {
      capital: "Pretoria",
      code: "za",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/za.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/za.svg",
      lat: -30.5595,
      lng: 22.9375,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ZA" }),
    },
    gs: {
      capital: "King Edward Point",
      code: "gs",
      continent: "Antarctica",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gs.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gs.svg",
      iso: true,
      lat: -54.4296,
      lng: -36.5874,
      name: intl.formatMessage({ id: "countryOption_GS" }),
    },
    kr: {
      capital: "Seoul",
      code: "kr",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/kr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/kr.svg",
      lat: 35.9078,
      lng: 127.7669,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_KR" }),
    },
    ss: {
      capital: "Juba",
      code: "ss",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ss.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ss.svg",
      lat: 7.8624,
      lng: 29.6949,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SS" }),
    },
    es: {
      capital: "Madrid",
      code: "es",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/es.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/es.svg",
      lat: 40.4637,
      lng: -3.7492,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ES" }),
    },
    lk: {
      capital: "Sri Jayawardenepura Kotte, Colombo",
      code: "lk",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/lk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/lk.svg",
      lat: 7.8731,
      lng: 80.7718,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_LK" }),
    },
    ps: {
      capital: "Ramallah",
      code: "ps",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ps.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ps.svg",
      lat: 31.9522,
      lng: 35.2332,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_PS" }),
    },
    sd: {
      capital: "Khartoum",
      code: "sd",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sd.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sd.svg",
      lat: 12.8628,
      lng: 30.2176,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SD" }),
    },
    sr: {
      capital: "Paramaribo",
      code: "sr",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sr.svg",
      lat: 3.9193,
      lng: -56.0278,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SR" }),
    },
    sj: {
      capital: "Longyearbyen",
      code: "sj",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sj.svg",
      lat: 77.5536,
      lng: 23.6703,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SJ" }),
    },
    se: {
      capital: "Stockholm",
      code: "se",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/se.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/se.svg",
      lat: 60.1282,
      lng: 18.6435,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SE" }),
    },
    ch: {
      capital: "Bern",
      code: "ch",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ch.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ch.svg",
      lat: 46.8182,
      lng: 8.2275,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_CH" }),
    },
    sy: {
      capital: "Damascus",
      code: "sy",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/sy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/sy.svg",
      lat: 34.8021,
      lng: 38.9968,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_SY" }),
    },
    tw: {
      capital: "Taipei",
      code: "tw",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tw.svg",
      lat: 23.6978,
      lng: 120.9605,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TW" }),
    },
    tj: {
      capital: "Dushanbe",
      code: "tj",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tj.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tj.svg",
      lat: 38.861,
      lng: 71.2761,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TJ" }),
    },
    tz: {
      capital: "Dodoma",
      code: "tz",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tz.svg",
      lat: -6.369,
      lng: 34.8888,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TZ" }),
    },
    th: {
      capital: "Bangkok",
      code: "th",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/th.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/th.svg",
      lat: 15.87,
      lng: 100.9925,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TH" }),
    },
    tl: {
      capital: "Dili",
      code: "tl",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tl.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tl.svg",
      lat: -8.8742,
      lng: 125.7275,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TL" }),
    },
    tg: {
      capital: "Lomé",
      code: "tg",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tg.svg",
      lat: 8.6195,
      lng: 0.8248,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TG" }),
    },
    tk: {
      capital: "Nukunonu, Atafu,Tokelau",
      code: "tk",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tk.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tk.svg",
      lat: -9.2005,
      lng: -171.8484,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TK" }),
    },
    to: {
      capital: "Nukuʻalofa",
      code: "to",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/to.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/to.svg",
      lat: -21.1789,
      lng: -175.1982,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TO" }),
    },
    tt: {
      capital: "Port of Spain",
      code: "tt",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tt.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tt.svg",
      lat: 10.6918,
      lng: -61.2225,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TT" }),
    },
    tn: {
      capital: "Tunis",
      code: "tn",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tn.svg",
      lat: 33.8869,
      lng: 9.5375,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TN" }),
    },
    tr: {
      capital: "Ankara",
      code: "tr",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tr.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tr.svg",
      lat: 39.9334,
      lng: 32.8597,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TR" }),
    },
    tm: {
      capital: "Ashgabat",
      code: "tm",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tm.svg",
      lat: 38.9697,
      lng: 59.5563,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TM" }),
    },
    tc: {
      capital: "Cockburn Town",
      code: "tc",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tc.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tc.svg",
      lat: 21.694,
      lng: -71.7979,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TC" }),
    },
    tv: {
      capital: "Funafuti",
      code: "tv",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/tv.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/tv.svg",
      lat: -8.5178,
      lng: 179.2185,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_TV" }),
    },
    ug: {
      capital: "Kampala",
      code: "ug",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ug.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ug.svg",
      lat: 0.3136,
      lng: 32.5811,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_UG" }),
    },
    ua: {
      capital: "Kiev",
      code: "ua",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ua.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ua.svg",
      lat: 50.4501,
      lng: 30.5234,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_UA" }),
    },
    ae: {
      capital: "Abu Dhabi",
      code: "ae",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ae.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ae.svg",
      lat: 24.4667,
      lng: 54.3667,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_AE" }),
    },
    gb: {
      capital: "London",
      code: "gb",
      continent: "Europe",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/gb.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/gb.svg",
      lat: 51.5099,
      lng: -0.118,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_GB" }),
    },
    um: {
      capital: "Washington, D.C.",
      code: "um",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/um.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/um.svg",
      lat: 38.8951,
      lng: -77.0369,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_UM" }),
    },
    us: {
      capital: "Washington, D.C.",
      code: "us",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/us.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/us.svg",
      lat: 38.8951,
      lng: -77.0369,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_US" }),
    },
    uy: {
      capital: "Montevideo",
      code: "uy",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uy.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uy.svg",
      lat: -34.9011,
      lng: -56.1918,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_UY" }),
    },
    uz: {
      capital: "Tashkent",
      code: "uz",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/uz.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/uz.svg",
      lat: 41.2995,
      lng: 69.2401,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_UZ" }),
    },
    vu: {
      capital: "Port Vila",
      code: "vu",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vu.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vu.svg",
      lat: -17.7404,
      lng: 168.3213,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VU" }),
    },
    ve: {
      capital: "Caracas",
      code: "ve",
      continent: "South America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ve.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ve.svg",
      lat: 10.4961,
      lng: -66.8815,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VE" }),
    },
    vn: {
      capital: "Hanoi",
      code: "vn",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vn.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vn.svg",
      lat: 21.0285,
      lng: 105.8542,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VN" }),
    },
    vg: {
      capital: "Road Town",
      code: "vg",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vg.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vg.svg",
      lat: 18.4167,
      lng: -64.6167,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VG" }),
    },
    vi: {
      capital: "Charlotte Amalie",
      code: "vi",
      continent: "North America",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/vi.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/vi.svg",
      lat: 18.3358,
      lng: -64.8963,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_VI" }),
    },
    wf: {
      capital: "Mata-Utu",
      code: "wf",
      continent: "Oceania",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/wf.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/wf.svg",
      lat: -13.2818,
      lng: -176.1748,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_WF" }),
    },
    eh: {
      capital: "Laayoune",
      code: "eh",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/eh.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/eh.svg",
      lat: 24.2155,
      lng: -12.8858,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_EH" }),
    },
    ye: {
      capital: "Sana'a",
      code: "ye",
      continent: "Asia",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/ye.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/ye.svg",
      lat: 15.3695,
      lng: 44.191,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_YE" }),
    },
    zm: {
      capital: "Lusaka",
      code: "zm",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zm.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zm.svg",
      lat: -15.4167,
      lng: 28.2833,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ZM" }),
    },
    zw: {
      capital: "Harare",
      code: "zw",
      continent: "Africa",
      flag_1x1:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/1x1/zw.svg",
      flag_4x3:
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/country/4x3/zw.svg",
      lat: -17.8292,
      lng: 31.0522,
      iso: true,
      name: intl.formatMessage({ id: "countryOption_ZW" }),
    },
  };
};
