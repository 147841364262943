import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import UserApi from "../api/user-msvc/user.api";
import { PermsUtils } from "../constants/permissions.constants";
import userCache from "../utilities/cache/user.cache";

const useEmpGuard = (
  protection: "PROTECTED" | "PUBLIC",
  navigate: NavigateFunction
) => {
  useEffect(() => {
    validateAccess(protection);
  }, []);

  /**
   * A function that validates access based on the protection level provided.
   *
   * @async
   * @param {string} protection The protection level to validate access against, either "PUBLIC" or "PROTECTED".
   * @returns {Promise<void>} A promise that resolves when the function is complete.
   *
   * @throws {Error} If an error occurs while checking the authenticated user, the error will be thrown.
   */
  const validateAccess = async (protection: string) => {
    try {
      const authSession = await fetchAuthSession({ forceRefresh: true });
      if (!authSession.tokens?.idToken) {
        throw new Error("User is unauthenticated");
      }
      const response = await UserApi.fetchUser();
      const user = response.data;
      if (protection === "PUBLIC") {
        const roleClassification = PermsUtils.getUserRole(response.data);
        if (roleClassification === "brand") {
          navigate("/brand/home");
          if (user.userOnboardingState === "brand:onboarding") {
            navigate("/brand/onboard");
          }
        } else if (roleClassification === "creator") {
          if (user.userOnboardingState === "creator:onboarding") {
            navigate("/creator/onboard");
          }
          navigate("/creator/home");
        } else if (roleClassification === "agency") {
          navigate("/agency/home");
          if (user.userOnboardingState === "agency:onboarding") {
            navigate("/brand/onboard");
          }
        } else if (user.role === "unknown") {
          navigate("/external/role-selection");
        }
      }
    } catch (e) {
      // User has no access
      if (protection === "PROTECTED") navigate("/sign-in");
    }
  };
};

export default useEmpGuard;
