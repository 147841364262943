import EmpException from "../exception/empException";
import { UserDto } from "../model/user-management/user.dto";
import EmpExceptionHandlerBuilder from "../utilities/errorUtils/empExceptionHandlerBuilder";
import { USER_ROLES } from "./app.constants";

export const PAGE_PERMS = {
  MANAGE_TEAM: {
    CREATE: "manage-team:create",
  },
  AGENCY_PROFILE: {
    UPDATE_PIC: "agency-profile:update-pic",
    UPDATE_BIO: "agency-profile:update-bio",
  },
  PERSONAL_PROFILE: {
    UPDATE_BIO: "personal-profile: update-bio",
  },
  PAYMENT: {
    UPDATE: "payment:update",
  },
};

const adminPerms: string[] = [
  PAGE_PERMS.MANAGE_TEAM.CREATE,
  PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO,
  PAGE_PERMS.AGENCY_PROFILE.UPDATE_PIC,
  PAGE_PERMS.PAYMENT.UPDATE,
  PAGE_PERMS.PERSONAL_PROFILE.UPDATE_BIO,
];
const rootPerms: string[] = [
  PAGE_PERMS.MANAGE_TEAM.CREATE,
  PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO,
  PAGE_PERMS.AGENCY_PROFILE.UPDATE_PIC,
  PAGE_PERMS.PAYMENT.UPDATE,
];
const maintainerPerms: string[] = [
  PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO,
  PAGE_PERMS.PERSONAL_PROFILE.UPDATE_BIO,
];
const memberPerms: string[] = [PAGE_PERMS.PERSONAL_PROFILE.UPDATE_BIO];

const PERMS = {
  "org-member": memberPerms,
  "org-maintainer": maintainerPerms,
  "org-admin": adminPerms,
  agency: rootPerms,
  brand: rootPerms,
};

type PermsType = "org-member" | "org-maintainer" | "agency" | "org-admin";

export class PermsUtils {
  static getPerms = (user: UserDto | null) => {
    try {
      if (user === null) throw new EmpException("Unable to get perms");
      const role = user.role as PermsType;
      return PERMS[role];
    } catch (e) {
      if (e instanceof Error)
        throw new EmpExceptionHandlerBuilder()
          .handleGenericError()
          .build()
          .process(e);
    }
  };

  static hasPerms = (user: UserDto | null, perm: string): boolean => {
    // Fetch user object if it is null
    try {
      if (user === null) throw new EmpException("Unable to get perms");
      const role = user.role as PermsType;
      return PERMS[role].includes(perm);
    } catch (e) {
      console.log(e);
      if (e instanceof Error)
        throw new EmpExceptionHandlerBuilder()
          .handleGenericError()
          .build()
          .process(e);
      return false;
    }
  };

  /**
   * Checks if a user has dual roles within their organisation.
   * This function determines if a user is associated with an organisation that has multiple types (e.g., serving as both a provider and a consumer).
   *
   * @param {UserDto} user - The user object to check for dual roles.
   * @returns {boolean} Returns true if the user is part of an organisation with more than one type; otherwise, returns false.
   **/
  static hasDualRole = (user: UserDto): boolean => {
    if (!user.organisation || user.organisation.length === 0) return false;
    const organisation = user.organisation[0];
    if (organisation.organisationType.length > 1) return true;
    return false;
  };

  static initialRoute = (user: UserDto): string => {
    if (user.role === "unknown") return "/external/role-selection";
    // Route for Creator.
    if (
      user.role === "talent" &&
      user.userOnboardingState === "creator:onboarding"
    ) {
      return "/creator/onboard";
    } else if (
      user.role === "talent" &&
      user.userOnboardingState === "COMPLETED"
    ) {
      return "/creator/home";
    }

    // Routing for Agency
    if (
      user.role === "agency" &&
      (user.userOnboardingState === "agency:onboarding" ||
        user.userOnboardingState.startsWith("info:"))
    ) {
      let onboardingId;
      if (user.userOnboardingState.startsWith("info:")) {
        onboardingId = user.userOnboardingState.split(":")[1];
      }
      return `/agency/onboard?onboardingId=${onboardingId ?? ""}`;
    }
    // Routing for Brand
    if (
      user.role === "brand" &&
      user.userOnboardingState === "brand:onboarding"
    ) {
      return "/brand/onboard";
    }
    // For organisation
    if (user.userOnboardingState === "COMPLETED") {
      if (user.organisation && user.organisation?.length === 0)
        throw new EmpException("error!");
      const orgTypes = user.organisation![0].organisationType;
      if (orgTypes.find((elem) => elem.type === "agency"))
        return "/agency/home";
      else if (orgTypes.find((elem) => elem.type === "brand"))
        return "/brand/home";
      else throw new Error("Unmatched route");
    }

    const role = user.userOnboardingState.split(":")[0];
    if (role === "brand") return "/brand/home";
    else return "/agency/home";
  };

  // static getUserRole = (user: UserDto): "creator" | "agency" | "brand" => {
  //     const AGENCY = [USER_ROLES.ORG_ADMIN, USER_ROLES.ORG_MAINTAINER, USER_ROLES.ORG_MEMBER, USER_ROLES.AGENCY]
  //     if (AGENCY.includes(user.role)) return "agency";
  //     else if (user.role === "talent") return "creator";
  //     else if (user.role === "brand") return "brand";
  //     throw new EmpException("Invalid");
  // }

  static getUserRole = (
    user: UserDto
  ): "creator" | "agency" | "brand" | "unknown" => {
    if (user.role === "unknown") return "unknown";
    else if (user.role === "talent") return "creator";
    if (user.userOnboardingState === "COMPLETED") {
      if (user.organisation && user.organisation?.length === 0)
        throw new EmpException("error!");
      const orgTypes = user.organisation![0].organisationType;
      if (orgTypes.find((elem) => elem.type === "agency")) {
        return "agency";
      } else if (orgTypes.find((elem) => elem.type === "brand")) return "brand";
      else throw new Error("Unidentified role");
    }
    const roleIdentified = user.userOnboardingState.split(":")[0];
    if (roleIdentified === "brand") return "brand";
    else return "agency";
  };

  static isUserABrand = (user: UserDto): boolean => {
    if (user.role === "talent") return false;
    if (user.userOnboardingState === "COMPLETED") {
      if (user.organisation && user.organisation?.length === 0)
        throw new EmpException("error!");
      const orgTypes = user.organisation![0].organisationType;
      if (orgTypes.find((elem) => elem.type === "brand")) {
        return true;
      }
    }
    return false;
  };

  /**
   * Converts a human-readable role string to a Cognito role string.
   * @param readableRole - The human-readable role string to convert.
   * @returns The corresponding Cognito role string.
   * @throws An EmpBusinessException if the role string is invalid.
   */
  static cognitoRoleToReadableRole(cognitoRole: string): string {
    switch (cognitoRole) {
      case USER_ROLES.AGENCY:
      case USER_ROLES.BRAND:
        return "Root";
      case USER_ROLES.ORG_MAINTAINER:
        return "Manager";
      case USER_ROLES.ORG_MEMBER:
        return "Member";
      case USER_ROLES.ORG_ADMIN:
        return "Administrator";
      default:
        throw new EmpException("Invalid Permission");
    }
  }
}
