import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import {
  SocialInstagramCarouselPostDto,
  SocialInstagramReelDto,
  SocialInstagramPostDto,
  SocialInstagramStoryDto,
} from "../../model/smm/smm-instagram.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SocialMediaManagementInstagramApi = {
  getInstagramMedia: async (
    id: string,
    socialMediaUserId: string,
    type: string,
    after?: string,
    before?: string,
    size?: number
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.instagram.getInstagramMedia(
        id,
        socialMediaUserId
      ),
      { params: { after, before, size, type } }
    );
    return response.data;
  },

  createSinglePost: async (
    id: string,
    socialMediaUserId: string,
    feedData: SocialInstagramPostDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.instagram.createSinglePost(id, socialMediaUserId),
      feedData
    );
    return response.data;
  },

  createReel: async (
    id: string,
    socialMediaUserId: string,
    reelData: SocialInstagramReelDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post(
      ENDPOINTS.smmManagement.instagram.createReel(id, socialMediaUserId),
      reelData
    );
    return response.data;
  },

  createCarousel: async (
    id: string,
    socialMediaUserId: string,
    data: SocialInstagramCarouselPostDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post(
      ENDPOINTS.smmManagement.instagram.createCarousel(id, socialMediaUserId),
      data
    );
    return response.data;
  },

  createStory: async (
    id: string,
    socialMediaUserId: string,
    storyData: SocialInstagramStoryDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post(
      ENDPOINTS.smmManagement.instagram.createStory(id, socialMediaUserId),
      storyData
    );
    return response.data;
  },
};

export default SocialMediaManagementInstagramApi;
