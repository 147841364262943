import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { CampaignPayoutRecordDto } from "../../model/payment/payment/campaign-payout-record.dto";
import { EmpInterceptor } from "../emp-interceptor";

const PayoutApi = {
  fetchPayoutSummary: async (
    taskId: string,
    role: "brand" | "seller"
  ): Promise<ApiResponse<CampaignPayoutRecordDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CampaignPayoutRecordDto[]>>(
      ENDPOINTS.campaignMsvc.payout.getSummary(taskId, role)
    );
    return response.data;
  },
};
export default PayoutApi;
