import { motion } from "framer-motion";
import React from "react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Color } from "../../../utilities/colors";
import CheckIcon from "../../icon/check-icon";
import ChevronDownIcon from "../../icon/chevron-down";
import ChevronUpIcon from "../../icon/chevron-up";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-multi-filter.scss";
import { SelectOptionWithHtml } from "../../../model/common/select-option-with-html";

interface Props {
  menuItems: SelectOptionWithHtml[];
  label?: string | JSX.Element;
  controlLabel?: string;
  onChange?: (values: string[], isAllSelected: boolean) => void;
  onBlur?: (values: string[], isAllSelected: boolean) => void;
  hasAllOption?: boolean;
  allByDefault?: boolean;
  allText?: string;
}

const fadeInVariants = {
  hidden: { opacity: 0, bottom: -40 },
  visible: { opacity: 1, bottom: -10 },
};

interface MultiFilterOption extends SelectOptionWithHtml {
  isSelected: boolean;
}

export interface MultiFilterOptionRef {
  reset: () => void;
}

const EmpMultiFilter = forwardRef((props: Props, ref) => {
  const { menuItems, onChange, onBlur, label } = props;
  const allText = props.allText ?? "All";
  const controlLabel = props.controlLabel ?? "Multi Filter";
  const [selectedItemStack, setSelectedItemStack] = useState<
    MultiFilterOption[]
  >([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const hasAllOption = props.hasAllOption ?? false;
  const allByDefault = props.allByDefault ?? false;

  const [options, setOptions] = useState<MultiFilterOption[]>([]);

  useEffect(() => {
    const selectedOptions = menuItems.map((elem) => {
      return { ...elem, isSelected: false };
    });
    if (hasAllOption) {
      selectedOptions.unshift({
        isSelected: allByDefault,
        value: "all",
        label: allText,
      });
      if (allByDefault) {
        setSelectedItemStack([
          {
            isSelected: true,
            value: "all",
            label: allText,
          },
        ]);
      }
    }
    setOptions(selectedOptions);
  }, [hasAllOption, allByDefault, menuItems]);

  const [isActive, setActive] = useState<boolean>(false);

  const reset = () => {
    setSelectedItemStack([]);
    setActive(false);
  };

  useImperativeHandle(ref, () => {
    return {
      reset,
    };
  });

  const selectOption = (option: MultiFilterOption) => {
    const updatedState = !option.isSelected;

    if (!hasAllOption) {
      if (updatedState === true) {
        selectedItemStack.push(option);
      } else {
        const index = selectedItemStack.findIndex(
          (elem) => elem.label === option.label
        );
        if (index >= 0) {
          selectedItemStack.splice(index, 1);
        }
      }
      setSelectedItemStack([...selectedItemStack]);

      // Propagate to the parent element if onChange prop is being activated.
      if (onChange) {
        const selectedValues = selectedItemStack.map((elem) => elem.value);
        onChange(selectedValues, isAllSelected);
      }

      option.isSelected = updatedState;
      setOptions([...options]);
    }
    // if all option is enabled
    else {
      // If switches away from all. Then deselect all.
      if (isAllSelected && option.value !== "all") {
        setSelectedItemStack([option]);
        options.forEach((elem) => {
          if (elem.value !== option.value) {
            elem.isSelected = false;
          } else {
            elem.isSelected = true;
          }
        });
        if (onChange) {
          const selectedValues = [option.value];
          onChange(selectedValues, isAllSelected);
        }
      } else if (!isAllSelected && option.value === "all") {
        setSelectedItemStack([option]);
        options.forEach((elem) => {
          if (elem.value !== "all") {
            elem.isSelected = false;
          } else {
            elem.isSelected = true;
          }
        });
        if (onChange) {
          const selectedValues = options
            .filter((elem) => elem.value !== "all")
            .map((elem) => elem.value);
          onChange(selectedValues, isAllSelected);
        }
      } else {
        if (updatedState === true) {
          selectedItemStack.push(option);
        } else {
          const index = selectedItemStack.findIndex(
            (elem) => elem.label === option.label
          );
          if (index >= 0) {
            selectedItemStack.splice(index, 1);
          }
        }
        setSelectedItemStack([...selectedItemStack]);

        // Propagate to the parent element if onChange prop is being activated.
        if (onChange) {
          const selectedValues = selectedItemStack.map((elem) => elem.value);
          onChange(selectedValues, isAllSelected);
        }
        option.isSelected = updatedState;
      }
      setOptions([...options]);
    }
  };

  const isAllSelected: boolean = useMemo(() => {
    if (!hasAllOption) return false;
    return Boolean(
      options.find((elem) => elem.value === "all" && elem.isSelected)
    );
  }, [options, hasAllOption]);

  const hasSelection: boolean = useMemo(() => {
    return Boolean(options.find((elem) => elem.isSelected));
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isActive || !menuRef.current) return;
      if (!menuRef.current.contains(event.target as Node)) {
        setActive(false);
        console.log(selectedItemStack);

        if (onBlur) {
          onBlur(
            selectedItemStack.map((elem) => elem.value),
            isAllSelected
          );
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive, selectedItemStack, onBlur, isAllSelected]);

  return (
    <div className="emp-multi-filter">
      <label className="mb-2">{label}</label>
      <div
        onClick={() => {
          if (!isActive) setActive(true);
        }}
        ref={menuRef}
        className={`emp-multi-filter-wrapper ${
          isActive || hasSelection ? "active" : ""
        }`}
      >
        <div className={`multi-filter-wrapper`}>
          {!hasSelection && (
            <span
              className={`emp-disable-text-selection multi-filter-label ${
                isActive ? "active" : ""
              }`}
            >
              {controlLabel}
            </span>
          )}
          {hasSelection &&
            selectedItemStack.length > 0 &&
            selectedItemStack[0].html && (
              <span
                className={`emp-disable-text-selection multi-filter-label active`}
              >
                {selectedItemStack[0].html}
              </span>
            )}
          {hasSelection &&
            selectedItemStack.length > 0 &&
            !selectedItemStack[0].html && (
              <span
                className={`emp-disable-text-selection multi-filter-label active`}
              >
                {selectedItemStack[0].label}
              </span>
            )}
          {hasSelection && selectedItemStack.length > 1 && (
            <EmpPill text={`+${selectedItemStack.length - 1}`} />
          )}
          {!isActive && (
            <ChevronDownIcon
              backgroundColor={
                hasSelection ? Color.PRIMARY[600] : Color.NEUTRAL[500]
              }
            />
          )}
          {isActive && <ChevronUpIcon backgroundColor={Color.PRIMARY[600]} />}
        </div>

        {isActive && (
          <motion.div
            variants={fadeInVariants}
            initial={"hidden"}
            animate="visible"
            transition={{ duration: 0.2 }}
            className="dropdown-menu"
          >
            {options.map((elem) => {
              return (
                <div
                  key={elem.value}
                  onClick={() => {
                    selectOption(elem);
                  }}
                  className="dropdown-item"
                >
                  <div
                    className={`checkbox ${elem.isSelected ? "selected" : ""}`}
                  >
                    {elem.isSelected && (
                      <CheckIcon size={12} backgroundColor={Color.NEUTRAL[0]} />
                    )}
                  </div>
                  {!elem.html && (
                    <span className="emp-disable-text-selection">
                      {elem.label}
                    </span>
                  )}
                  {elem.html && <>{elem.html}</>}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </div>
  );
});

export default EmpMultiFilter;
