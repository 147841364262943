import { useCallback } from "react";
import EmpExceptionHandler from "../utilities/errorUtils/empExceptionHandler";
import CampaignAnalyticsSummaryApi from "../api/campaign-msvc/campaign-analytics-summary.api";
import { CampaignAnalyticsSummaryDto } from "../model/campaign/campaign-analytics-summary.dto";
import EmpException from "../exception/empException";
import { CampaignAnalyticsDetailsDto } from "../model/campaign/campaign-analytics-details.dto";
import CampaignAnalyticsDetailsApi from "../api/campaign-msvc/campaign-analytics-details.api";
import { FetchCampaignAnalyticsDetailsQuery } from "../model/campaign/fetch-camapign-analytics-details.query";

const useCampaignAnalytics = (taskId: string, role: "seller" | "brand") => {
  const fetchSummary =
    useCallback(async (): Promise<CampaignAnalyticsSummaryDto> => {
      try {
        const resp = await CampaignAnalyticsSummaryApi.fetch(taskId, role);
        return resp.data;
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Error fetching campaign analytic summary"
        );
        throw new EmpException("Error fetching campaign analytic summary");
      }
    }, [taskId, role]);

  const fetchDetails = useCallback(
    async (
      query: FetchCampaignAnalyticsDetailsQuery
    ): Promise<CampaignAnalyticsDetailsDto[]> => {
      try {
        const resp = await CampaignAnalyticsDetailsApi.fetch(
          taskId,
          role,
          query
        );
        return resp.data;
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Error fetching campaign analytic details"
        );
        throw new EmpException("Error fetching campaign details summary");
      }
    },
    [taskId, role]
  );

  return { fetchSummary, fetchDetails };
};
export default useCampaignAnalytics;
