import "../../styles/shared/draft-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import FileUtils from "../../utilities/file-util";
import AlertSquareIcon from "../icon/alert-square";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { FILE_TYPE } from "../../constants/app.constants";
import { TaskDto } from "../../model/campaign/task.dto";
import DraftApi from "../../api/campaign-msvc/draft.api";
import ToastUtils from "../../utilities/toast-utils";
import { ExtendedDraftDto } from "../../model/campaign/extended-draft.dto";
import { DateUtil } from "../../utilities/date";
import {
  SaveDraftDto,
  UploadedFile,
} from "../../model/campaign/save-draft.dto";
import { empDelay } from "../../utilities/delay";
import {
  ProgressType,
  uploadCampaignFileToS3,
} from "../../utilities/campaign-file-uploader.util";
import PlusIcon from "../icon/plus-icon";
import { v4 } from "uuid";
import TrashIcon from "../icon/trash-icon";
import EmpLink from "../shared/emp-link/emp-link";
import StringUtils from "../../utilities/string.util";

type ModalModeType = "create" | "edit" | "resubmit";
export interface ViewDraftReviewModalRef {
  show: (draft: ExtendedDraftDto, task: TaskDto, mode: ModalModeType) => void;
  hide: () => void;
}

export interface FileUploadStatus {
  id: string;
  fileName: string;
  file?: File;
  fileType: string;
  fileSize: number;
  status: "to-be-uploaded" | "uploaded" | "failed" | "existing";
  s3Location?: string;
}

interface Props {
  onSave: () => void;
}

const ViewDraftReviewModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [progressTracker, setProgressTracker] = useState<ProgressType>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [deliverableDraft, setDeliverableDraft] = useState<ExtendedDraftDto>();
  const [task, setTask] = useState<TaskDto>();
  const [fileUploadStatusList, setFileUploadStatusList] = useState<
    FileUploadStatus[]
  >([]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [removedFiles, setRemovedFiles] = useState<string[]>();
  const [currentProcessedFile, setCurrentProcessedFile] =
    useState<FileUploadStatus>();
  const [modalMode, setModalMode] = useState<ModalModeType>();

  // Remarks Form Control
  const [form, setForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [new RequiredValidator()]),
  });

  const saveBtnRef = useRef<EmpButtonRef>();
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    draft: ExtendedDraftDto,
    task: TaskDto,
    mode: ModalModeType
  ) => {
    setDeliverableDraft(draft);
    setTask(task);
    setModalMode(mode);
    const existingDraftAttachments = draft.draft?.draftAttachments;

    if (existingDraftAttachments && ["edit", "resubmit"].includes(mode)) {
      const existingAttachments: FileUploadStatus[] =
        existingDraftAttachments.map((attachment) => {
          return {
            id: attachment.id,
            fileName: attachment.attachmentName,
            fileType: attachment.fileType,
            fileSize: attachment.attachmentSize,
            status: "existing",
            s3Location: attachment.attachmentUrl,
          };
        });
      setFileUploadStatusList(existingAttachments);
      form.remarks.setValue(draft.draft?.remarks);
    }

    setVisible(true);
  };
  const dismiss = () => {
    resetForm();
    setVisible(false);
    setCurrentProcessedFile(undefined);
    setFileUploadStatusList([]);
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  // This needs to be processed sequentially.
  const processFileUpload = useCallback(
    async (processedFile: FileUploadStatus) => {
      console.log("Uploading a file", processedFile);
      const file = processedFile.file;
      const s3Location = await uploadCampaignFileToS3(
        file!,
        "draft-attachments",
        {
          setProgress: setProgressTracker,
          id: processedFile.id,
        }
      );
      setFileUploadStatusList((prev) => {
        // find by id
        const element = prev.find((f) => f.id === processedFile.id);
        if (element) {
          element.status = "uploaded";
          element.s3Location = s3Location;
        }
        return [...prev];
      });
      setCurrentProcessedFile((prev) => {
        return undefined;
      });
    },
    []
  );

  // useEffect to trigger file processing when files are added to the queue
  useEffect(() => {
    if (!currentProcessedFile) {
      const filetoBeProcessed = fileUploadStatusList.find(
        (file) => file.status === "to-be-uploaded"
      );
      if (filetoBeProcessed) {
        setIsProcessing(true);
        processFileUpload(filetoBeProcessed);
      } else {
        setIsProcessing(false);
      }
    }
  }, [currentProcessedFile, fileUploadStatusList, processFileUpload]);

  const convertFile = async (file: File): Promise<File> => {
    const fileType = FileUtils.getFileType(file);
    if (
      [FILE_TYPE.IMAGE, FILE_TYPE.IMAGES].includes(fileType) &&
      file.type === "image/heic"
    ) {
      const jpegFile = await FileUtils.convertHeicToJpeg(file);
      return jpegFile;
    }
    return file;
  };

  const validateFile = (file: File): boolean => {
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 600);
    if (!isSmallEnough) {
      setErrorMessage("File size needs to be smaller than 600Mb");
      return false;
    }
    return true;
  };

  const handleFileUploadV2 = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files: FileList | null = event.target.files;

    const newlyUploadedFiles: FileUploadStatus[] = [];
    if (files === null) return;
    for (const file of files) {
      if (!validateFile(file)) {
        continue;
      }
      const convertedFile = await convertFile(file);
      // Do the uploading, and say the status here.
      newlyUploadedFiles.push({
        id: v4(),
        fileName: file.name,
        file: convertedFile,
        fileSize: file.size,
        status: "to-be-uploaded",
        fileType: FileUtils.getFileType(convertedFile),
      });
    }
    const updatedFileUploadStatusList = [
      ...fileUploadStatusList,
      ...newlyUploadedFiles,
    ];
    setFileUploadStatusList(updatedFileUploadStatusList);
  };

  const removeAttachment = useCallback((fileStatus: FileUploadStatus) => {
    if (fileStatus.status === "existing") {
      setRemovedFiles((prev) => {
        if (!prev) {
          return [fileStatus.id];
        }
        return [...prev, fileStatus.id];
      });
    }

    setFileUploadStatusList((prev) => {
      return prev.filter((f) => f.id !== fileStatus.id);
    });
  }, []);

  /**
   * Saves a draft of a deliverable with the provided information.
   *
   * @async
   * @function
   * @returns {Promise<void>} A Promise that resolves when the draft is saved.
   * @throws {Error} If there is an error during the save process.
   */
  const onSubmit = async (): Promise<void> => {
    try {
      if (isProcessing) {
        alert("Please wait for the file to finish uploading");
      }
      saveBtnRef.current?.setButtonState("loading");
      if (!task || !deliverableDraft || !modalMode) return;
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      let resubmittedFiles: UploadedFile[] = [];
      if (modalMode === "resubmit") {
        resubmittedFiles = fileUploadStatusList
          .filter((file) => file.status === "existing")
          .map((file) => {
            return {
              fileType: file.fileType,
              s3Url: file.s3Location!,
              name: file.fileName,
              size: file.fileSize,
            };
          });
      }

      // Construct newly added to be submitted
      const newlyAddedFiles = fileUploadStatusList
        .filter((file) => file.status === "uploaded")
        .map((file) => {
          return {
            fileType: file.fileType,
            s3Url: file.s3Location!,
            name: file.fileName,
            size: file.fileSize,
          };
        });

      const request: SaveDraftDto = {
        taskId: deliverableDraft.taskId,
        deliverableId: deliverableDraft.deliverableId,
        representativeId: deliverableDraft.representativeId,
        creatorId: deliverableDraft.creator.id,
        addedFiles: [...resubmittedFiles, ...newlyAddedFiles],
        removedFiles: removedFiles ? removedFiles.map((id) => ({ id })) : [],
        remarks: form.remarks.getValue(),
      };

      let resp;
      if (modalMode === "edit") {
        const draftId = deliverableDraft.draft!.id;
        resp = await DraftApi.updateDraft(request, draftId);
      } else if (["create", "resubmit"].includes(modalMode)) {
        resp = await DraftApi.createDraft(request);
      }
      if (resp && resp.data.status === "success") {
        ToastUtils.success(
          "Draft Saved",
          "Please wait for the Brand's approval before proceeeding"
        );
        props.onSave();
        dismiss();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send attachment"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-draft-modal">
        <button
          onClick={() => dismiss()}
          className="emp-button-reset dismiss-icon-wrapper"
        >
          <XCloseIcon backgroundColor={Color.NEUTRAL[300]} />
        </button>
        <div className="text-content-section mb-2 section-padding">
          {modalMode === "edit" && (
            <h2 className="title">View Draft Submission</h2>
          )}
          {modalMode === "resubmit" && (
            <h2 className="title">Resubmit Draft</h2>
          )}
          {modalMode === "create" && <h2 className="title">New Draft</h2>}
          {deliverableDraft && (
            <>
              {modalMode === "edit" && (
                <p className="emp-paragraph mt-2">
                  View your draft submitted that you have submitted on{" "}
                  <span className="emp-highlighted">
                    {DateUtil.toReadableDateWithTime(
                      deliverableDraft.draft!.createdDate
                    )}
                  </span>
                </p>
              )}
              {modalMode === "resubmit" && (
                <p className="emp-paragraph mt-2">
                  Your draft has been declined by the brand. We encourage you to
                  review their feedback and adjust your submission as per their
                  suggestions.
                </p>
              )}
              {modalMode === "create" && (
                <p className="emp-paragraph mt-2">
                  Submit a draft to the brand for their approval before you can
                  proceed to submit the proof of work.
                </p>
              )}
            </>
          )}

          {deliverableDraft && modalMode === "resubmit" && (
            <div className="brand-remarks-section mb-6">
              <div className="header-wrapper">
                <span>Reviewer Remarks</span>
              </div>
              <p
                className="emp-paragraph mt-2"
                dangerouslySetInnerHTML={{
                  __html: StringUtils.stringToHtmlRepresentation(
                    deliverableDraft.draft?.brandResponse ??
                      "No remarks from Brand"
                  ),
                }}
              ></p>
            </div>
          )}

          {deliverableDraft &&
            modalMode === "edit" &&
            deliverableDraft.draft?.brandResponse && (
              <div className="brand-remarks-section mb-6">
                <div className="header-wrapper">
                  <span>Latest Reviewer Remarks</span>
                </div>
                <p
                  className="emp-paragraph mt-2"
                  dangerouslySetInnerHTML={{
                    __html: StringUtils.stringToHtmlRepresentation(
                      deliverableDraft.draft?.brandResponse ??
                        "No remarks from Brand"
                    ),
                  }}
                ></p>
              </div>
            )}
        </div>
        <div className="content-section section-padding mt-2">
          <div className="upload-section-label-wrapper">
            <span className="upload-section-label block">
              Upload Attachment <span className="required">*</span>
            </span>
          </div>

          {deliverableDraft && (
            <>
              <section className="attachment-section mt-2">
                {fileUploadStatusList.map((item) => {
                  return (
                    <div className="attachment-item" key={item.id}>
                      <div className="media-wrapper">
                        <button
                          className="emp-button-reset delete-button"
                          onClick={() => {
                            removeAttachment(item);
                          }}
                        >
                          <TrashIcon
                            backgroundColor={Color.NEUTRAL[300]}
                            size={12}
                          />
                        </button>
                        {item.status === "to-be-uploaded" && (
                          <div className="processing-state"></div>
                        )}
                        <div className="loader">
                          {progressTracker &&
                            progressTracker.id === item.id &&
                            item.status === "to-be-uploaded" && (
                              <div
                                className="bar"
                                style={{
                                  width:
                                    (progressTracker.downloadedBytes /
                                      progressTracker.totalBytes) *
                                      100 +
                                    "%",
                                }}
                              ></div>
                            )}
                          {(item.status === "uploaded" ||
                            item.status === "existing") && (
                            <div className="bar max"></div>
                          )}
                        </div>

                        {item.fileType === FILE_TYPE.IMAGE && (
                          <img
                            alt={`Uploaded`}
                            src={
                              item.status === "existing"
                                ? item.s3Location
                                : URL.createObjectURL(item.file!)
                            }
                          />
                        )}
                        {item.fileType === FILE_TYPE.VIDEO && (
                          <video
                            preload={"none"}
                            autoPlay={false}
                            className="uploaded-video"
                            src={
                              item.status === "existing"
                                ? item.s3Location
                                : URL.createObjectURL(item.file!)
                            }
                          ></video>
                        )}
                        {item.fileType === FILE_TYPE.FILE && (
                          <div className="file-info">
                            <span className="file-extension">
                              {FileUtils.getFileExtension(item.fileName)}
                            </span>
                            <span className="file-name mt-1">
                              {item.fileName}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="info-section">
                        <div className="context-section">
                          <span>{item.fileType}</span>
                          <span>
                            {FileUtils.convertBytesToReadableSize(
                              item.fileSize
                            )}
                          </span>
                        </div>
                        {item.status === "existing" && (
                          <EmpLink
                            onSubmit={() => {
                              FileUtils.handleFileDownload(
                                item.fileName,
                                item.s3Location!
                              );
                            }}
                            className="download-link"
                            text={"Download File"}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                <label
                  className="attachment-item upload-btn"
                  htmlFor={"logo-upload"}
                >
                  <PlusIcon backgroundColor={Color.NEUTRAL[400]} size={30} />
                  <span className="upload-text">Upload Here</span>
                </label>
              </section>

              <input
                className="upload-hidden"
                type="file"
                id="logo-upload"
                onChange={handleFileUploadV2}
                name="files[]"
                multiple
              ></input>
              {errorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{errorMessage}</span>
                </div>
              )}

              <div className="mt-3">
                <EmpTextInput
                  id="remarks"
                  description="Add remarks or captions here."
                  labelText="Remarks"
                  required
                  formControl={form.remarks}
                  multiline
                  textAreaAdaptiveHeight
                  rows={3}
                  placeholder="Enter remarks..."
                />
              </div>
            </>
          )}
        </div>
        <div
          className="mt-6 section-padding"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            isFullWidth={false}
            text={"Save"}
            ref={saveBtnRef}
            onSubmit={() => {
              onSubmit();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ViewDraftReviewModal;
