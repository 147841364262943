import React, { useEffect, useRef, useState } from "react";
import { IntlShape } from "react-intl";
import {
  COUNTRY_CONSTANTS,
  CountryOption,
  COUNTRY_CONSTANTS_INTL,
} from "../../../constants/countries.contants";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import ChevronDownIcon from "../../icon/chevron-down";
import SearchIcon from "../../icon/search-icon";
import "./emp-country-select.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  description?: string;
  labelText?: string;
  intl?: IntlShape;
  value?: string;
  placeholder?: string;
  initialValue?: string;
  required?: boolean;
  disabled?: boolean;

  // EmpTextInput Form Integration
  id: string;
  formControl: FormControl;
  formValidation?: () => void;

  // Callbacks to the parent component
  onChange?: (formControl: FormControl) => void;
  onKeypress?: (key: string) => void;
}

const countryOption: CountryOption = COUNTRY_CONSTANTS;

type CountrySelectionOption = {
  flagImg: string;
  label: string;
  value: string;
  isSelected: boolean;
  isHovered: boolean;
};
const EmpCountrySelect = (props: Props) => {
  const {
    onChange,
    required,
    disabled,
    style,
    className,
    id,
    intl,
    labelText,
    placeholder,
    initialValue,
    formControl,
    description,
  } = props;
  const value = initialValue ? initialValue : "";

  const formControlRef = useRef<HTMLDivElement>(null);
  const multiSelectRef = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const scrollViewBoundaries = useRef<{ top: number; bottom: number }>({
    top: 0,
    bottom: 200,
  });

  const isExpandedRef = useRef<boolean>(false);
  const isRequired = required ? required : false;

  const [countryOptions, setCountryOptions] = useState<
    CountrySelectionOption[]
  >([]);
  const [filteredOptions, setFilteredOption] = useState<
    CountrySelectionOption[]
  >([]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isFocused, setFocused] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [hasFlag, setHasFlag] = useState(false);
  const [displayedFlag, setDisplayedFlag] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);
  const referencedCountriesRef = useRef<CountryOption>(countryOption);

  // useEffect(() => {

  // }, [formControl.resetFlag])

  useEffect(() => {
    const options: CountrySelectionOption[] = [];
    referencedCountriesRef.current = countryOption;
    if (intl) {
      referencedCountriesRef.current = COUNTRY_CONSTANTS_INTL(intl);
    }
    for (const [key, value] of Object.entries(referencedCountriesRef.current)) {
      options.push({
        flagImg: value.flag_4x3,
        label: value.name,
        value: key,
        isSelected: false,
        isHovered: false,
      });
    }

    const countryValue = formControl.getValue();
    const selectedCountry = options.find((elem) => elem.value === countryValue);
    if (selectedCountry) {
      inputRef.current!.value = selectedCountry.label;
      setHasFlag(true);
      setDisplayedFlag(selectedCountry.flagImg);
    }

    setCountryOptions(options);
    setFilteredOption(options);
  }, [intl, formControl.resetFlag]);

  // This function is called when the component is mounted and whenever the
  // isExpanded prop changes.
  useEffect(() => {
    isExpandedRef.current = isExpanded;
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!multiSelectRef.current) return;

      if (
        multiSelectRef.current &&
        !multiSelectRef.current.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formControlRef]);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  const handleOnChange = (elem: CountrySelectionOption) => {
    const prevSelectedCountry = countryOptions.find((el) => el.isSelected);
    if (prevSelectedCountry) {
      prevSelectedCountry.isSelected = false;
    }

    const selectedCountry = countryOptions.find(
      (el) => el.value === elem.value
    );
    if (selectedCountry) {
      selectedCountry.isSelected = true;
    }

    setCountryOptions([...countryOptions]);

    setDisplayedFlag(elem.flagImg);
    inputRef.current!.value = elem.label;
    formControl.setValue(elem.value);
    setHasFlag(true);
    setExpanded(false);
    // // Propagate this change to the parent component
    if (onChange) onChange(formControl);
  };

  const textOnChange = (searchStr: string) => {
    const filtered = countryOptions.filter((option) =>
      option.label.toLowerCase().includes(searchStr.toLowerCase())
    );
    if (filtered.length > 0) {
      filtered[0].isHovered = true;
    }
    setFilteredOption([...filtered]);

    const country = countryOptions.find(
      (elem) => elem.label.toLowerCase() === searchStr.toLowerCase()
    );
    if (country) {
      handleOnChange(country);
    }
  };

  const textOnKeyPress = (key: string) => {
    if (key === "Enter" || key === "Unidentified") {
      if (filteredOptions.length === 0) return;
      const hoveredIndex = filteredOptions.findIndex((elem) => elem.isHovered);
      const selectedIndex = hoveredIndex === -1 ? 0 : hoveredIndex;
      const option = filteredOptions[selectedIndex];
      handleOnChange(option);
    } else if (key === "ArrowUp") {
      const hoveredIndex = filteredOptions.findIndex((elem) => elem.isHovered);
      if (hoveredIndex === 0) return;
      else if (hoveredIndex === -1 && filteredOptions.length > 0) {
        filteredOptions[0].isHovered = true;
      } else {
        filteredOptions[hoveredIndex].isHovered = false;
        filteredOptions[hoveredIndex - 1].isHovered = true;
      }
      setFilteredOption([...filteredOptions]);
      isOptionInView(hoveredIndex - 1);
    } else if (key === "ArrowDown") {
      const hoveredIndex = filteredOptions.findIndex((elem) => elem.isHovered);
      if (hoveredIndex === filteredOptions.length - 1) return;
      else if (hoveredIndex === -1 && filteredOptions.length > 0) {
        filteredOptions[0].isHovered = true;
      } else {
        filteredOptions[hoveredIndex].isHovered = false;
        filteredOptions[hoveredIndex + 1].isHovered = true;
      }
      setFilteredOption([...filteredOptions]);
      isOptionInView(hoveredIndex + 1);
    } else {
      setExpanded(true);
      setHasFlag(false);
      formControl.setValue("");
    }
  };

  const isOptionInView = (elemIndex: number) => {
    const elemHeight = 34;
    const scrollElemHeight = 200;

    if (!optionsWrapperRef.current) return;
    const elemYOffset = elemHeight * (elemIndex + 1);

    if (
      elemYOffset > scrollViewBoundaries.current.top &&
      elemYOffset < scrollViewBoundaries.current.bottom
    )
      return;

    // Scroll down
    if (elemYOffset > scrollViewBoundaries.current.top) {
      optionsWrapperRef.current.scrollTop = elemYOffset - scrollElemHeight;
      scrollViewBoundaries.current = {
        top: elemYOffset - scrollElemHeight,
        bottom: elemYOffset,
      };
    }
    if (elemYOffset < scrollViewBoundaries.current.bottom) {
      optionsWrapperRef.current.scrollTop = elemYOffset - elemHeight;
      scrollViewBoundaries.current = {
        top: elemYOffset - elemHeight,
        bottom: elemYOffset - elemHeight + scrollElemHeight,
      };
    }
  };

  return (
    <div
      ref={multiSelectRef}
      className={`emp-country-select-wrapper ${className}`}
      style={style}
    >
      <label
        className={`block emp-multi-select-label ${
          description ? "mb-1" : "mb-2"
        }`}
        htmlFor={id}
      >
        {labelText} {isRequired && <span className="required">*</span>}
      </label>
      {description && <p>{description}</p>}

      {/* Pasted Text input */}
      <div
        className={`emp-text-input-wrapper ${isFocused ? "focused" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {!hasFlag && (
          <div className="emp-left-icon-wrapper">
            <div className="empty-flag">
              <span>?</span>
            </div>
          </div>
        )}
        {hasFlag && (
          <div className="emp-left-icon-wrapper">
            <img
              className="img-flag"
              alt="selected country"
              src={displayedFlag}
            />
          </div>
        )}
        <input
          ref={inputRef}
          className={`emp-text-input left-icon`}
          placeholder={placeholder}
          defaultValue={value}
          onKeyDown={(e) => {
            textOnKeyPress(e.key);
          }}
          type="text"
          onFocus={() => {
            setFocused(true);
            setExpanded(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          // Callbacks
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            textOnChange(event.target.value);
          }}
        />
        <div className="emp-right-icon-wrapper">
          {!isFocused && (
            <ChevronDownIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
          )}
          {isFocused && (
            <SearchIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
          )}
        </div>
      </div>
      {filteredOptions.length > 0 && isExpanded && (
        <div ref={optionsWrapperRef} className="emp-multi-select-section">
          {filteredOptions.map((elem, index) => {
            return (
              <EmpMultiSelectItem
                key={elem.value}
                handleOnChange={handleOnChange}
                elem={elem}
              />
            );
          })}
        </div>
      )}
      {filteredOptions.length === 0 && isExpanded && (
        <div className="emp-multi-select-section">
          <EmpMultiSelectItem
            handleOnChange={handleOnChange}
            isEmpty
            elem={{
              flagImg: "",
              label: "",
              value: "",
              isSelected: false,
              isHovered: false,
            }}
          />
        </div>
      )}
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[400]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
export default EmpCountrySelect;

interface MultiSelectItemProp {
  isEmpty?: boolean;
  handleOnChange: (elem: CountrySelectionOption) => void;
  elem: CountrySelectionOption;
}

const EmpMultiSelectItem = (props: MultiSelectItemProp) => {
  const isEmpty = props.isEmpty ?? false;
  const { handleOnChange, elem } = props;

  const checkboxWrapperOnClick = () => {
    try {
      handleOnChange(elem);
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    }
  };

  const recommendOptionStyle = () => {
    if (elem.isSelected) return "selected";
    else if (elem.isHovered) return "hovered";
    return "";
  };
  return (
    <div
      onClick={() => {
        checkboxWrapperOnClick();
      }}
      className={`checkbox-wrapper ${recommendOptionStyle()}`}
    >
      {isEmpty && (
        <div className="empty-option-section">
          <span>No Countries</span>
        </div>
      )}
      {!isEmpty && (
        <div className="country-option-wrapper">
          <img className="img-flag" alt="country" src={elem.flagImg} />
          <span className="country-label">{elem.label}</span>
        </div>
      )}
    </div>
  );
};
