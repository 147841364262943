import _debounce from "lodash/debounce";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import ChevronUpIcon from "../../../components/icon/chevron-up";
import AgencyInviteCreatorToTaskModal, {
  AgencyInviteCreatorToTaskModalRef,
} from "../../../components/modals/agency-invite-creator-to-task-modal";
import ApplyTaskModal, {
  ApplyTaskModalRef,
} from "../../../components/modals/apply-task-modal";
import TaskResponseModal, {
  TaskResponseModalRef,
} from "../../../components/modals/task-response-modal";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { EmpTableContentSpec } from "../../../components/shared/EmpTable/EmpTable";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import { PermsUtils } from "../../../constants/permissions.constants";
import { AppContext } from "../../../context/app.context";
import { LatestNegotiationSingleDto } from "../../../model/campaign/latest-negotiation-single.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import StringUtils from "../../../utilities/string.util";
import UserUtils from "../../../utilities/user-utils";
import "./seller-task-summary-view.scss";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import {
  EMPTY_PROFILE_IMG_URL,
  NEGOTIATION_STATUS,
} from "../../../constants/app.constants";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import ChevronRightIcon from "../../../components/icon/chevron-right";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import { useNavigate } from "react-router-dom";
import KycApi from "../../../api/payment-msvc/kyc.api";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ConfigurePayoutsAlertModal, {
  ConfigurePayoutsAlertModaRef,
} from "../../../components/modals/configure-payouts-alert-modal";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import ReviewDeliverableChangelogModal, {
  ReviewDeliverableChangelogModalRef,
} from "../../../components/modals/review-deliverable-changelog-modal";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task?: TaskDto;
  sellerLatestNegotiation: LatestNegotiationSingleDto;
  fetchTask: () => void;
  fetchLatestNegotiation: () => void;
}

interface NegotiationBreakdown {
  acceptedNegotiations: LatestNegotiationExtendedDto[];
  invitedNegotiations: LatestNegotiationExtendedDto[];
  appliedNegotiations: LatestNegotiationExtendedDto[];
  counterofferNegotiations: LatestNegotiationExtendedDto[];
  reviewDeliverableNegotiations: LatestNegotiationExtendedDto[];
}
export const SellerTaskSummaryView = (props: Props) => {
  const { fetchLatestNegotiation } = props;

  const [sellerLatestNegotiation, setSellerLatestNegotiation] =
    useState<LatestNegotiationSingleDto>(props.sellerLatestNegotiation);
  const { user: userContext } = useContext(AppContext);
  const { task } = props;
  const [user, setUser] = useState<UserDto>();
  const [userRole, setUserRole] = useState<string>();

  const [deliverableIsExpanded, setDeliverableIsExpanded] = useState(true);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [kycIncomplete, setKycIncomplete] = useState(false);

  const [
    sellerLatestNegotiationBreakdown,
    setSellerLatestNegotiationBreakdown,
  ] = useState<NegotiationBreakdown>();
  useEffect(() => {
    // Breakdown the sellers latest negotiation
    if (props.sellerLatestNegotiation.hasNegotiation) {
      const acceptedNegotiations = [];
      const invitedNegotiations = [];
      const appliedNegotiations = [];
      const counterofferNegotiations = [];
      const reviewDeliverableNegotiations = [];
      for (const elem of props.sellerLatestNegotiation.latestNegotiation) {
        switch (elem.status) {
          case NEGOTIATION_STATUS.SELLER_ACCEPT:
          case NEGOTIATION_STATUS.BRAND_ACCEPT:
            acceptedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.INVITED:
            invitedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.APPLIED:
            appliedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.BRAND_NEGOTIATE:
            counterofferNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.REVIEW_DELIVERABLES:
            reviewDeliverableNegotiations.push(elem);
            break;
          default:
            break;
        }
      }
      setSellerLatestNegotiationBreakdown({
        acceptedNegotiations,
        invitedNegotiations,
        appliedNegotiations,
        counterofferNegotiations,
        reviewDeliverableNegotiations,
      });
    } else {
      setSellerLatestNegotiationBreakdown({
        acceptedNegotiations: [],
        invitedNegotiations: [],
        appliedNegotiations: [],
        counterofferNegotiations: [],
        reviewDeliverableNegotiations: [],
      });
    }

    setSellerLatestNegotiation(props.sellerLatestNegotiation);
  }, [props.sellerLatestNegotiation]);

  // Wrapper element to compute expandable size
  const deliverableInfoRef = useRef<HTMLDivElement>(null);
  const applyTaskModalRef = useRef<ApplyTaskModalRef>();
  const configurePayoutsAlertModalRef = useRef<ConfigurePayoutsAlertModaRef>();
  const taskResponseModalRef = useRef<TaskResponseModalRef>();
  const reviewDeliverableChangelogModalRef =
    useRef<ReviewDeliverableChangelogModalRef>();
  const agencyInviteCreatorToTaskModalRef =
    useRef<AgencyInviteCreatorToTaskModalRef>();

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<any>[] = useMemo(() => {
    return [
      {
        title: "Organisation Name",
        dataIndex: "companyName",
      },
      {
        title: "Date Joined",
        dataIndex: "createdDate",
      },
      {
        title: "Type",
        dataIndex: "type",
      },
      {
        title: "Commission Rate",
        dataIndex: "commissionRate",
      },
      {
        title: "Actions",
        dataIndex: "action",
      },
    ];
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUserRole(PermsUtils.getUserRole(user));
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  const GUTTER_WIDTH = 20;
  const MIN_CARD_WIDTH = 180;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [deliverableInfoHeight, setDeliverableInfoHeight] = useState<number>(0);

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cards = Math.min(maxCards, 5);
    const cardWidth = Math.floor(
      (rowWidth - (cards - 1) * GUTTER_WIDTH) / cards
    );
    setCardWidth(cardWidth);
  };

  function calculateTotalOuterHeight(divRef: HTMLDivElement) {
    let totalHeight = 0;

    // Helper function to include margin
    function getOuterHeight(el: HTMLElement) {
      let style = window.getComputedStyle(el);
      return (
        el.offsetHeight +
        parseInt(style.marginTop) +
        parseInt(style.marginBottom)
      );
    }

    // Loop through the immediate children of divRef
    Array.from(divRef.children).forEach((child) => {
      const c = child as HTMLElement;
      totalHeight += getOuterHeight(c);
    });

    return totalHeight;
  }

  const updateDeliverableSectionHeight = () => {
    const height = calculateTotalOuterHeight(deliverableInfoRef.current!);
    setDeliverableInfoHeight(height);
  };

  // eslint-disable-next-line
  const debounceFn2: () => void = useCallback(
    _debounce(updateDeliverableSectionHeight, 300, { leading: true }),
    []
  );
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
      debounceFn2();
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn, task]);

  const renderCountries = (countries: string[]): JSX.Element => {
    const maxDisplayCountries = 3;
    const extra =
      countries.length > maxDisplayCountries
        ? countries.length - maxDisplayCountries
        : undefined;
    const renderableCountries = countries.slice(0, maxDisplayCountries);
    return (
      <div className="country-wrapper">
        {renderableCountries.map((elem) => {
          return (
            <img
              key={COUNTRY_CONSTANTS[elem].name}
              className="country"
              alt={COUNTRY_CONSTANTS[elem].name}
              src={COUNTRY_CONSTANTS[elem].flag_1x1}
            />
          );
        })}
        {extra && <div className="extra">{extra}</div>}
      </div>
    );
  };

  const renderHiredCreators = (
    latestNegotiation: LatestNegotiationSingleDto
  ): JSX.Element => {
    const maxDisplayCreators = 3;
    if (!latestNegotiation.hasNegotiation)
      return <>No confirmed creators yet...</>;
    const hiredCreators = latestNegotiation.latestNegotiation.filter((elem) =>
      [
        NEGOTIATION_STATUS.BRAND_ACCEPT,
        NEGOTIATION_STATUS.SELLER_ACCEPT,
      ].includes(elem.status)
    );
    const extra =
      hiredCreators.length > maxDisplayCreators
        ? hiredCreators.length - maxDisplayCreators
        : undefined;
    if (hiredCreators.length > 0) {
      return (
        <div className="country-wrapper">
          {hiredCreators.map((elem) => {
            return (
              <div>
                {elem.creatorRepresentative.imageType === "url" && (
                  <img
                    alt="Profile"
                    className={`profile-photo`}
                    src={elem.creatorRepresentative.imageResource}
                  />
                )}
                {elem.creatorRepresentative.imageType === "none" && (
                  <img
                    alt="Empty profile"
                    className={`profile-photo`}
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                    }
                  />
                )}
                {elem.creatorRepresentative.imageType === "avatar" && (
                  <div
                    style={{
                      background: elem.creatorRepresentative.imageResource,
                    }}
                    className={`avatar`}
                  >
                    <span className="initials">
                      {elem.creatorRepresentative.initials}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
          {extra && <div className="extra">{extra}</div>}
        </div>
      );
    } else {
      return <div>No Creators</div>;
    }
  };

  const renderMetricCreators = (
    acceptedNegotiations: LatestNegotiationExtendedDto[],
    showAmount?: boolean
  ): JSX.Element => {
    const canShowAmount = showAmount ?? false;
    const maxDisplayCreators = 1;
    if (acceptedNegotiations.length === 0)
      return <span className="metric-description">No Creators</span>;
    const creators = acceptedNegotiations.map(
      (elem) => elem.creatorRepresentative
    );
    const extra =
      creators.length > maxDisplayCreators
        ? creators.length - maxDisplayCreators
        : undefined;

    const totalAmount = acceptedNegotiations.reduce(
      (prev, currentValue) => prev + Number(currentValue.amount),
      0
    );

    if (creators.length > 0) {
      return (
        <div className="creator-wrapper mt-2">
          {creators.map((elem, index) => {
            if (index < maxDisplayCreators)
              return (
                <Fragment key={elem.id}>
                  {elem.imageType === "url" && (
                    <img
                      alt="Profile"
                      className={`profile-photo`}
                      src={elem.imageResource}
                    />
                  )}
                  {elem.imageType === "none" && (
                    <img
                      alt="Empty profile"
                      className={`profile-photo`}
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {elem.imageType === "avatar" && (
                    <div
                      style={{ background: elem.imageResource }}
                      className={`avatar`}
                    >
                      <span className="initials">{elem.initials}</span>
                    </div>
                  )}
                </Fragment>
              );
            else return <></>;
          })}
          {extra && <div className="extra">+{extra}</div>}
          {canShowAmount && (
            <>
              <div className="pipe"></div>
              <span className="amount">
                ${FormFieldUtils.toMoney(totalAmount)}
              </span>
            </>
          )}
        </div>
      );
    } else {
      return <span className="metric-description">No Creators</span>;
    }
  };

  return (
    <div className="emp-seller-task-summary-view">
      <ConfigurePayoutsAlertModal ref={configurePayoutsAlertModalRef} />

      <ApplyTaskModal
        ref={applyTaskModalRef}
        onSave={() => {
          fetchLatestNegotiation();
        }}
      />

      <AgencyInviteCreatorToTaskModal
        ref={agencyInviteCreatorToTaskModalRef}
        onSave={() => {
          fetchLatestNegotiation();
        }}
      />
      <TaskResponseModal
        ref={taskResponseModalRef}
        onSave={() => {
          fetchLatestNegotiation();
        }}
      />

      {sellerLatestNegotiationBreakdown?.reviewDeliverableNegotiations && (
        <ReviewDeliverableChangelogModal
          onSave={fetchLatestNegotiation}
          ref={reviewDeliverableChangelogModalRef}
          latestNegotiations={
            sellerLatestNegotiationBreakdown.reviewDeliverableNegotiations
          }
        />
      )}
      {/* Payout Card Banner */}
      {kycIncomplete && (
        <div className="payout-card-banner mb-4">
          <div className="banner-info-wrapper">
            <span className="banner-title">Configure Your Payouts!</span>
            <p className="banner-description">
              Before you can accept or undertake any campaign tasks, it is
              mandatory to complete our Know Your Customer (KYC) process and
              link your bank account for receiving payouts.{" "}
            </p>
            <EmpButton
              text={"Configure Payouts"}
              onSubmit={() => {
                navigate(`/${userRole}/settings#payment`);
              }}
              className="mt-3"
              buttonStyle="secondary"
              isFullWidth={false}
            />
          </div>
          <img
            alt="Bank Payouts"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/bank.png"
          />
        </div>
      )}

      {/* Task Summary Metrics Wrapper */}
      {task &&
        user &&
        sellerLatestNegotiationBreakdown &&
        userRole === "agency" && (
          <section className="metrics-section mb-6">
            {/* Apply New Creators */}
            <button
              className="emp-button-reset metric-card clickable"
              onClick={() => {
                if (kycIncomplete) {
                  configurePayoutsAlertModalRef.current?.show();
                  return;
                }
                agencyInviteCreatorToTaskModalRef.current?.show(task!, user!);
              }}
            >
              <div className="info-wrapper">
                <img
                  className="metric-img"
                  alt="invite-creator"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creator.png"
                />
                <div>
                  <span className="metric-title">Invite your Creators</span>
                  {sellerLatestNegotiationBreakdown.appliedNegotiations
                    .length === 0 && (
                    <span className="metric-description">
                      Invite candidates to join your task
                    </span>
                  )}
                  {sellerLatestNegotiationBreakdown.appliedNegotiations.length >
                    0 &&
                    renderMetricCreators(
                      sellerLatestNegotiationBreakdown.appliedNegotiations
                    )}
                </div>
              </div>
              <div className="chevron-wrapper">
                <ChevronRightIcon
                  size={18}
                  strokeWidth={2.5}
                  backgroundColor={Color.NEUTRAL[400]}
                />
              </div>
            </button>
            {/* Invitations */}
            <button
              className={`emp-button-reset metric-card ${
                sellerLatestNegotiationBreakdown.invitedNegotiations.length > 0
                  ? "clickable"
                  : ""
              }`}
              onClick={() => {
                if (
                  sellerLatestNegotiationBreakdown.invitedNegotiations
                    .length === 0
                )
                  return;
                if (kycIncomplete) {
                  configurePayoutsAlertModalRef.current?.show();
                  return;
                }
                taskResponseModalRef.current?.show(
                  task,
                  sellerLatestNegotiationBreakdown.invitedNegotiations,
                  "invitation",
                  "seller"
                );
              }}
            >
              <div className="info-wrapper">
                <div className="creator-image-wrapper">
                  {sellerLatestNegotiationBreakdown.invitedNegotiations
                    .length === 0 && (
                    <img
                      className="creator-image"
                      alt="Applied Creator"
                      src={EMPTY_PROFILE_IMG_URL}
                    />
                  )}
                  {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                    0 && (
                    <>
                      {sellerLatestNegotiationBreakdown.invitedNegotiations[0]
                        .creatorRepresentative.imageType === "url" && (
                        <img
                          alt="Profile"
                          className={`creator-image`}
                          src={
                            sellerLatestNegotiationBreakdown
                              .invitedNegotiations[0].creatorRepresentative
                              .imageResource
                          }
                        />
                      )}
                      {sellerLatestNegotiationBreakdown.invitedNegotiations[0]
                        .creatorRepresentative.imageType === "none" && (
                        <img
                          alt="Empty profile"
                          className={`creator-image`}
                          src={
                            "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                          }
                        />
                      )}
                      {sellerLatestNegotiationBreakdown.invitedNegotiations[0]
                        .creatorRepresentative.imageType === "avatar" && (
                        <div
                          style={{
                            background:
                              sellerLatestNegotiationBreakdown
                                .invitedNegotiations[0].creatorRepresentative
                                .imageResource,
                          }}
                          className={`creator-image`}
                        >
                          <span className="initials">
                            {
                              sellerLatestNegotiationBreakdown
                                .invitedNegotiations[0].creatorRepresentative
                                .initials
                            }
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                    1 && (
                    <div className="badge">
                      <span className="value">
                        +
                        {sellerLatestNegotiationBreakdown.invitedNegotiations
                          .length - 1}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  {sellerLatestNegotiationBreakdown.invitedNegotiations
                    .length === 0 && (
                    <span className="metric-title">No Invitations</span>
                  )}
                  {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                    0 && (
                    <span className="metric-title">
                      {
                        sellerLatestNegotiationBreakdown.invitedNegotiations
                          .length
                      }{" "}
                      Invitation
                      {sellerLatestNegotiationBreakdown.invitedNegotiations
                        .length > 1
                        ? "s"
                        : ""}
                    </span>
                  )}
                  {sellerLatestNegotiationBreakdown.invitedNegotiations
                    .length === 0 && (
                    <span className="metric-description">
                      No invitation for your creators yet.
                    </span>
                  )}
                  {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                    0 && (
                    <span className="metric-description">
                      Respond to Brand's Invitation
                    </span>
                  )}
                </div>
              </div>
              {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                0 && (
                <div className="chevron-wrapper">
                  <ChevronRightIcon
                    size={18}
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
              )}
            </button>
            {/* Counteroffers */}
            <button
              className={`emp-button-reset metric-card ${
                sellerLatestNegotiationBreakdown.counterofferNegotiations
                  .length > 0
                  ? "clickable"
                  : ""
              }`}
              onClick={() => {
                if (
                  sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length === 0
                )
                  return;
                if (kycIncomplete) {
                  configurePayoutsAlertModalRef.current?.show();
                  return;
                }
                taskResponseModalRef.current?.show(
                  task,
                  sellerLatestNegotiationBreakdown.counterofferNegotiations,
                  "counteroffer",
                  "seller"
                );
              }}
            >
              <div className="info-wrapper">
                <div className="creator-image-wrapper">
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length === 0 && (
                    <img
                      className="creator-image"
                      alt="Applied Creator"
                      src={EMPTY_PROFILE_IMG_URL}
                    />
                  )}
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length > 0 && (
                    <>
                      {sellerLatestNegotiationBreakdown
                        .counterofferNegotiations[0].creatorRepresentative
                        .imageType === "url" && (
                        <img
                          alt="Profile"
                          className={`creator-image`}
                          src={
                            sellerLatestNegotiationBreakdown
                              .counterofferNegotiations[0].creatorRepresentative
                              .imageResource
                          }
                        />
                      )}
                      {sellerLatestNegotiationBreakdown
                        .counterofferNegotiations[0].creatorRepresentative
                        .imageType === "none" && (
                        <img
                          alt="Empty profile"
                          className={`creator-image`}
                          src={
                            "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                          }
                        />
                      )}
                      {sellerLatestNegotiationBreakdown
                        .counterofferNegotiations[0].creatorRepresentative
                        .imageType === "avatar" && (
                        <div
                          style={{
                            background:
                              sellerLatestNegotiationBreakdown
                                .counterofferNegotiations[0]
                                .creatorRepresentative.imageResource,
                          }}
                          className={`creator-image`}
                        >
                          <span className="initials">
                            {
                              sellerLatestNegotiationBreakdown
                                .counterofferNegotiations[0]
                                .creatorRepresentative.initials
                            }
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length === 0 && (
                    <span className="metric-title">No Counteroffers</span>
                  )}
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length > 0 && (
                    <span className="metric-title">
                      {
                        sellerLatestNegotiationBreakdown
                          .counterofferNegotiations.length
                      }{" "}
                      Counteroffer
                      {sellerLatestNegotiationBreakdown.appliedNegotiations
                        .length > 1
                        ? "s"
                        : ""}
                    </span>
                  )}
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length === 0 && (
                    <span className="metric-description">
                      No Counteroffers received
                    </span>
                  )}
                  {sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length > 0 && (
                    <span className="metric-description">
                      Respond to Brand's Counteroffer
                    </span>
                  )}
                </div>
              </div>
              {sellerLatestNegotiationBreakdown.counterofferNegotiations
                .length > 0 && (
                <div className="chevron-wrapper">
                  <ChevronRightIcon
                    size={18}
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
              )}
            </button>
            {/* Review Deliverables */}
            {sellerLatestNegotiationBreakdown.reviewDeliverableNegotiations
              .length > 0 && (
              <button
                className={`emp-button-reset metric-card clickable`}
                onClick={() => {
                  reviewDeliverableChangelogModalRef.current?.show(
                    task,
                    "seller"
                  );
                }}
              >
                <div className="info-wrapper">
                  <div className="creator-image-wrapper">
                    {sellerLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations[0].creatorRepresentative
                      .imageType === "url" && (
                      <img
                        alt="Profile"
                        className={`creator-image`}
                        src={
                          sellerLatestNegotiationBreakdown
                            .reviewDeliverableNegotiations[0]
                            .creatorRepresentative.imageResource
                        }
                      />
                    )}
                    {sellerLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations[0].creatorRepresentative
                      .imageType === "none" && (
                      <img
                        alt="Empty profile"
                        className={`creator-image`}
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {sellerLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations[0].creatorRepresentative
                      .imageType === "avatar" && (
                      <div
                        style={{
                          background:
                            sellerLatestNegotiationBreakdown
                              .reviewDeliverableNegotiations[0]
                              .creatorRepresentative.imageResource,
                        }}
                        className={`creator-image`}
                      >
                        <span className="initials">
                          {
                            sellerLatestNegotiationBreakdown
                              .reviewDeliverableNegotiations[0]
                              .creatorRepresentative.initials
                          }
                        </span>
                      </div>
                    )}
                    {sellerLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations.length > 1 && (
                      <div className="badge">
                        <span className="value">
                          +
                          {sellerLatestNegotiationBreakdown
                            .reviewDeliverableNegotiations.length - 1}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <span className="metric-title">
                      {
                        sellerLatestNegotiationBreakdown
                          .reviewDeliverableNegotiations.length
                      }{" "}
                      Deliverable Review
                      {sellerLatestNegotiationBreakdown
                        .reviewDeliverableNegotiations.length > 1
                        ? "s"
                        : ""}
                    </span>
                    <span className="metric-description">
                      Review Updated Deliverables
                    </span>
                  </div>
                </div>
                <div className="chevron-wrapper">
                  <ChevronRightIcon
                    size={18}
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[400]}
                  />
                </div>
              </button>
            )}
            {/* Start Work */}
            <button className={`emp-button-reset metric-card`}>
              <div className="info-wrapper">
                <img
                  className="metric-img handshake-img"
                  alt="invite-creator"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/handshake.png"
                />
                <div>
                  <span className="metric-title">Confirmed Creators</span>
                  {renderMetricCreators(
                    sellerLatestNegotiationBreakdown.acceptedNegotiations,
                    true
                  )}
                </div>
              </div>
            </button>
          </section>
        )}

      {task && (
        <section className="task-details-section">
          <div className="task-info-wrapper">
            <div className="task-name-wrapper">
              <div className="info-wrapper">
                <h2 className="section-label">{task.name}</h2>
                <EmpPill
                  text={task.status}
                  backgroundColor={Color.PRIMARY[100]}
                  color={Color.PRIMARY[600]}
                />
              </div>
              <p className="task-description mt-2">{task.description}</p>
            </div>

            {userRole === "creator" && sellerLatestNegotiationBreakdown && (
              <>
                {sellerLatestNegotiationBreakdown.acceptedNegotiations
                  .length === 0 &&
                  sellerLatestNegotiationBreakdown.appliedNegotiations
                    .length === 0 &&
                  sellerLatestNegotiationBreakdown.counterofferNegotiations
                    .length === 0 &&
                  sellerLatestNegotiationBreakdown.invitedNegotiations
                    .length === 0 &&
                  sellerLatestNegotiationBreakdown.reviewDeliverableNegotiations
                    .length === 0 && (
                    <button
                      className="emp-button-reset task-status-button clickable"
                      onClick={() => {
                        if (kycIncomplete) {
                          configurePayoutsAlertModalRef.current?.show();
                          return;
                        }
                        applyTaskModalRef.current?.show(
                          task,
                          "creator",
                          user!,
                          user!.id
                        );
                      }}
                    >
                      <div className="info-wrapper">
                        <img
                          className="metric-img"
                          alt="invite-creator"
                          src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creator.png"
                        />
                        <div>
                          <span className="metric-title">Apply for Task</span>
                          <span className="metric-description">
                            State your price!
                          </span>
                        </div>
                      </div>
                      <div className="chevron-wrapper">
                        <ChevronRightIcon
                          size={18}
                          strokeWidth={2.5}
                          backgroundColor={Color.NEUTRAL[400]}
                        />
                      </div>
                    </button>
                  )}

                {sellerLatestNegotiationBreakdown.appliedNegotiations.length >
                  0 && (
                  <button className="emp-button-reset task-status-button">
                    <div className="info-wrapper">
                      <img
                        className="metric-img hourglass-img"
                        alt="invite-creator"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/hourglass.png"
                      />
                      <div>
                        <span className="metric-title">Applied for task</span>
                        <span className="metric-description">
                          Awaiting Brand's Response
                        </span>
                      </div>
                    </div>
                  </button>
                )}

                {sellerLatestNegotiationBreakdown.invitedNegotiations.length >
                  0 && (
                  <button
                    className="emp-button-reset task-status-button clickable"
                    onClick={() => {
                      if (kycIncomplete) {
                        configurePayoutsAlertModalRef.current?.show();
                        return;
                      }
                      taskResponseModalRef.current?.show(
                        task,
                        sellerLatestNegotiationBreakdown.invitedNegotiations,
                        "invitation",
                        "seller"
                      );
                    }}
                  >
                    <div className="info-wrapper">
                      <img
                        className="metric-img"
                        alt="invite-creator"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creator.png"
                      />
                      <div>
                        <span className="metric-title">You are invited!</span>
                        <span className="metric-description">
                          Respond to invitation
                        </span>
                      </div>
                    </div>
                    <div className="chevron-wrapper">
                      <ChevronRightIcon
                        size={18}
                        strokeWidth={2.5}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                  </button>
                )}

                {sellerLatestNegotiationBreakdown.counterofferNegotiations
                  .length > 0 && (
                  <button
                    className="emp-button-reset task-status-button clickable"
                    onClick={() => {
                      if (kycIncomplete) {
                        configurePayoutsAlertModalRef.current?.show();
                        return;
                      }
                      taskResponseModalRef.current?.show(
                        task,
                        sellerLatestNegotiationBreakdown.counterofferNegotiations,
                        "counteroffer",
                        "seller"
                      );
                    }}
                  >
                    <div className="info-wrapper">
                      <img
                        className="metric-img"
                        alt="invite-creator"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/money.png"
                      />
                      <div>
                        <span className="metric-title">
                          Brand has Counteroffered
                        </span>
                        <span className="metric-description">
                          Respond to this Counteroffer
                        </span>
                      </div>
                    </div>
                    <div className="chevron-wrapper">
                      <ChevronRightIcon
                        size={18}
                        strokeWidth={2.5}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                  </button>
                )}

                {sellerLatestNegotiationBreakdown.acceptedNegotiations.length >
                  0 && (
                  <button className="emp-button-reset task-status-button">
                    <div className="info-wrapper">
                      <img
                        className="metric-img"
                        alt="invite-creator"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/handshake.png"
                      />
                      <div>
                        <span className="metric-title">You are confirmed!</span>
                        <span className="metric-description">
                          Please wait for Brand to start
                        </span>
                      </div>
                    </div>
                  </button>
                )}

                {sellerLatestNegotiationBreakdown.reviewDeliverableNegotiations
                  .length > 0 && (
                  <button
                    className="emp-button-reset task-status-button clickable"
                    onClick={() => {
                      reviewDeliverableChangelogModalRef.current?.show(
                        task,
                        "seller"
                      );
                    }}
                  >
                    <div className="info-wrapper">
                      <img
                        className="metric-img"
                        alt="invite-creator"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/checklist.webp"
                      />
                      <div>
                        <span className="metric-title">
                          Deliverables Updated
                        </span>
                        <span className="metric-description">
                          Please review to proceed with task.
                        </span>
                      </div>
                    </div>
                    <div className="chevron-wrapper">
                      <ChevronRightIcon
                        size={18}
                        strokeWidth={2.5}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                  </button>
                )}
              </>
            )}
          </div>
          <div ref={resizeDivRef} className="task-details mt-6">
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"TASK CREATED BY"}
                value={
                  <div className="brand-wrapper">
                    <EmpImage
                      className="logo"
                      fallback={EMPTY_PROFILE_IMG_URL}
                      src={task.organisation.logo}
                      alt={task.organisation.companyName}
                    />
                    <span className="company-name">
                      {task.organisation.companyName}
                    </span>
                  </div>
                }
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"EST TASK RUNNING DATE"}
                value={`${DateUtil.toReadableDate(
                  task.startDate
                )} to ${DateUtil.toReadableDate(task.endDate)}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"MODE OF PAYMENT"}
                value={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <EmpCoinIcon
                      mode="icon-only"
                      iconValue={task.paymentMode}
                    />
                    {task.paymentMode}
                  </div>
                }
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"OBJECTIVE"}
                value={`${StringUtils.convertHyphensToTitleCase(
                  task.objective
                )}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"COUNTRIES"}
                value={renderCountries(task.countries)}
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"HIRED CREATORS"}
                value={renderHiredCreators(sellerLatestNegotiation)}
              />
            </div>
          </div>
        </section>
      )}
      {task && (
        <section className="deliverable-section">
          <div className="title-wrapper">
            <h2 className="section-label">
              {task.deliverables.length} Deliverable
              {task.deliverables.length > 1 ? "s" : ""}
            </h2>
            <div className="link-wrapper">
              {task.deliverables.length > 0 && (
                <EmpLink
                  onSubmit={() => {
                    setDeliverableIsExpanded(!deliverableIsExpanded);
                  }}
                  text={
                    deliverableIsExpanded ? (
                      <>
                        Hide{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronUpIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        Show Deliverables{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronDownIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    )
                  }
                />
              )}
            </div>
          </div>
          <div
            className="collapsible-section"
            style={{
              height: deliverableIsExpanded ? deliverableInfoHeight : 0,
            }}
          >
            <div style={{ boxSizing: "border-box" }} ref={deliverableInfoRef}>
              {task.deliverables.map((elem, index) => {
                return (
                  <div key={elem.id} className="deliverable-wrapper">
                    <span className="deliverable-indicator">
                      DELIVERABLE {index + 1}
                    </span>
                    <h3 className="deliverable-name-lbl mb-2">{elem.name}</h3>
                    <EmpEditorView value={elem.description} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
      {/* <section className="events-section">
            <h2 className="section-label">Pending Actions</h2>

            <div className="mt-4 table-wrapper">
                <EmpTable
                    emptyStateHeader="No Events"
                    emptyStateDescription="No events has occurred for this task"
                    contentColumns={contentSpec}
                    data={[]}
                    rowKey={"id"} />
            </div>

        </section> */}
    </div>
  );
};
