import { useCallback } from "react";
import ToastUtils from "../utilities/toast-utils";
import EmpExceptionHandler from "../utilities/errorUtils/empExceptionHandler";
import { CreateWalletAddressDto } from "../model/user/wallet-address/create-wallet-address.dto";
import WalletAddressApi from "../api/user-msvc/wallet-address.api";
import { GetWalletAddressDto } from "../model/user/wallet-address/get-wallet-address.dto";
import { BlockchainNetworkDto } from "../model/user/wallet-address/blockchain-networks.dto";

export type WalletInformation = {
  hasWallet: boolean;
  wallet?: GetWalletAddressDto;
};

export type SavedWalletInformation = {
  walletAddress: string;
  blockchainNetwork: BlockchainNetworkDto;
};
const useWalletAddressManager = () => {
  /**
   * Sends a request to the server to create or update a wallet address.
   *
   * This function makes an API call to upsert a wallet address using the provided request data.
   * If the operation is successful, a success message is displayed; otherwise, it handles and logs the error.
   *
   * @async
   * @function upsertWalletAddress
   * @param {CreateWalletAddressDto} request - The data required to create or update the wallet address.
   * @returns {Promise<void>} Resolves when the API request is complete.
   * @throws Will catch and handle any errors that occur during the API request.
   */
  const upsertWalletAddress = useCallback(
    async (request: CreateWalletAddressDto) => {
      try {
        const apiStatus = await WalletAddressApi.upsertWalletAddress(request);
        if (apiStatus.data.status === "success") {
          ToastUtils.success("Saved!", "We have captured your wallet details");
        }
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Error Saving Wallet Address"
        );
      }
    },
    []
  );

  /**
   * Fetches the available blockchain networks from the server.
   *
   * This function makes an API call to retrieve a list of available blockchain networks that can be
   * used to create or update a wallet address. If the request fails, it handles the error and returns null.
   *
   * @async
   * @function fetchAvailableNetworks
   * @returns {Promise<Object|null>} A promise that resolves to the response data containing available networks,
   * or null if the request fails.
   * @throws Will catch and handle any errors that occur during the API request.
   */
  const fetchAvailableNetworks = useCallback(async () => {
    try {
      const { data: response } =
        await WalletAddressApi.fetchAvailableNetworks();
      return response;
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error Saving Wallet Address"
      );
      return null;
    }
  }, []);

  const fetchWalletInformation =
    useCallback(async (): Promise<WalletInformation | null> => {
      try {
        const { data: response } = await WalletAddressApi.fetchWalletAddress();
        const hasWallet = response.statusMessage === "added";
        return {
          hasWallet,
          wallet: response.payload,
        };
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Error Fetching Wallet Information"
        );
        return null;
      }
    }, []);
  return {
    fetchAvailableNetworks,
    upsertWalletAddress,
    fetchWalletInformation,
  };
};
export default useWalletAddressManager;
