import { motion } from "framer-motion";
import { values } from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tooltip, Whisper } from "rsuite";
import { SelectOptionWithHtml } from "../../../model/common/select-option-with-html";
import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import CheckIcon from "../../icon/check-icon";
import ChevronDownIcon from "../../icon/chevron-down";
import ChevronUpIcon from "../../icon/chevron-up";
import InfoCircleIcon from "../../icon/info-circle-icon";
import SearchIcon from "../../icon/search-icon";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-multi-select-v2.scss";

interface Props {
  label: string;
  isRequired?: boolean;
  formControl: FormControl;
  menuItems: SelectOptionWithHtml[];
  placeholder: string;
  placement?: "top" | "bottom";
  onChange?: (values: FormControl) => void;
  hasSearch?: boolean;
  tooltip?: string;
  widthMode?: "fit-content" | "100%";
  disabled?: boolean;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

interface MultiSelectOption extends SelectOptionWithHtml {
  isSelected: boolean;
}

export interface MultiSelectV2OptionRef {
  reset: () => void;
}

type PlacementType = "top" | "bottom";

const placementCss: { [key in PlacementType]: React.CSSProperties } = {
  top: {
    top: 10,
    transform: "translateY(-100%)", // Corrected the translateY value
  },
  bottom: {
    bottom: -10,
    transform: "translateY(100%)",
  },
};

const EmpMultiSelectV2 = forwardRef((props: Props, ref) => {
  const { placeholder, menuItems, label, onChange, formControl } = props;
  const disabled = props.disabled ?? false;

  const widthCss = useMemo(() => {
    const widthMode = props.widthMode ?? "fit-content";
    if (widthMode === "100%") return { width: "100%" };
    return { width: "fit-content" };
  }, [props.widthMode]);

  const hasSearch = props.hasSearch ?? false;
  const placement = props.placement ?? "bottom";
  const isRequired = props.isRequired ?? false;
  const tooltip = props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : <></>;

  const [selectedItemStack, setSelectedItemStack] = useState<
    MultiSelectOption[]
  >([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchKeywordRef = useRef<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();

  const [options, setOptions] = useState<MultiSelectOption[]>(() => {
    const selectedOptions = menuItems.map((elem) => {
      return { ...elem, isSelected: false };
    });
    return selectedOptions;
  });

  // const [filteredOptions, setFilteredOptions] = useState<MultiSelectOption[]>(() => {
  //     const selectedOptions = menuItems.map(elem => { return { ...elem, isSelected: false } })
  //     return selectedOptions;
  // })

  const [filteredOptions, setFilteredOptions] = useState<MultiSelectOption[]>(
    []
  );

  useEffect(() => {
    const selectedOptions = filteredOptions.filter((elem) => elem.isSelected);
  }, [filteredOptions]);

  const [isActive, setActive] = useState<boolean>(false);

  const reset = () => {
    setSelectedItemStack([]);
    setActive(false);
  };

  useImperativeHandle(ref, () => {
    return {
      reset,
    };
  });

  /**
   * Toggles the selection state of the given option.
   * @function
   * @param {MultiSelectOption} option - The option to be selected or deselected.
   * @returns {void}
   */
  const selectOption = (option: MultiSelectOption) => {
    const updatedState = !option.isSelected;

    if (updatedState === true) {
      selectedItemStack.push(option);
    } else {
      const index = selectedItemStack.findIndex(
        (elem) => elem.label === option.label
      );
      if (index >= 0) {
        selectedItemStack.splice(index, 1);
      }
    }
    setSelectedItemStack([...selectedItemStack]);

    const selectedValues = selectedItemStack.map((elem) => elem.value);
    formControl.setValue(selectedValues);
    // Propagate to the parent element if onChange prop is being activated.
    if (onChange) {
      onChange(formControl);
    }
    option.isSelected = updatedState;
    searchKeywordRef.current = "";
    if (hasSearch) {
      inputRef.current!.value = "";
      inputRef.current!.focus();
    }
    setOptions([...options]);
  };

  /**
   * Filters the options based on the search keyword and sorts them.
   * Selected options are placed at the top and sorted by label in ascending order.
   * @function
   * @returns {void}
   */
  const filterOptions = useCallback(() => {
    const searchKeyword = searchKeywordRef.current;
    const filtered = options.filter((elem) =>
      elem.label
        .toLowerCase()
        .trim()
        .includes(searchKeyword.toLowerCase().trim())
    );
    // Set those selected ones at the top

    const selectedObjects = filtered.filter((elem) => elem.isSelected);
    const unselectedObjects = filtered.filter((elem) => !elem.isSelected);
    const sortedSelectedObjects = selectedObjects.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    const finalFiltered = sortedSelectedObjects.concat(unselectedObjects);
    setFilteredOptions(finalFiltered);
  }, [options]);

  /**
   * Clears the selection of items and resets all options to have isSelected = false.
   * @function
   * @returns {void}
   */
  const clearSelection = () => {
    setSelectedItemStack([]);
    const resettedOptions = options.map((elem) => {
      return { ...elem, isSelected: false };
    });
    setOptions(resettedOptions);
  };

  // Detects when a click happens outside this component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isActive || !menuRef.current) return;
      if (!menuRef.current.contains(event.target as Node)) {
        setActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive]);

  // Auto focus the search textbox when the dropdown is opened
  useEffect(() => {
    if (isActive && hasSearch) inputRef.current!.focus();
  }, [isActive, hasSearch]);

  // Run the filterOptions function when the options state has been changed
  useEffect(() => {
    filterOptions();
  }, [options, filterOptions]);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  useEffect(() => {
    setOptions(
      menuItems.map((elem) => {
        return { ...elem, isSelected: false };
      })
    );
    setFilteredOptions(
      menuItems.map((elem) => {
        return { ...elem, isSelected: false };
      })
    );
  }, [menuItems]);

  // Initialize the component when values has already been pre-populated.
  useEffect(() => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((elem) => {
        if (formControl.value.includes(elem.value)) elem.isSelected = true;
        return elem;
      });
      const selectedOptions = updatedOptions.filter((elem) => elem.isSelected);
      setSelectedItemStack([...selectedOptions]);
      searchKeywordRef.current = "";
      return updatedOptions;
    });
  }, [formControl.value]);

  // useEffect(() => {
  //     setOptions(prevOptions => {
  //         const updatedOptions = prevOptions.map(elem => {
  //             if (formControl.value.includes(elem.value))
  //                 elem.isSelected = true;
  //             return elem;
  //         });
  //         const selectedOptions = updatedOptions.filter(elem => elem.isSelected);
  //         setSelectedItemStack(selectedOptions);
  //         searchKeywordRef.current = "";
  //         return updatedOptions;
  //     });
  // }, [formControl.resetFlag])

  return (
    <>
      <label>
        {label}
        {isRequired && <span className="required">*</span>}
        {props.tooltip && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={tooltip}
          >
            <div className="emp-tooltip-wrapper">
              <InfoCircleIcon size={14} backgroundColor={Color.NEUTRAL[500]} />
            </div>
          </Whisper>
        )}
      </label>
      <div
        onClick={() => {
          if (!isActive && !disabled) setActive(true);
        }}
        ref={menuRef}
        className={`emp-multi-select-v2 ${disabled ? "disabled" : ""}`}
      >
        <div className="outer-wrapper">
          <div className={`multi-select-wrapper`}>
            {selectedItemStack.length === 0 && (
              <span
                className={`emp-disable-text-selection multi-filter-label ${
                  isActive ? "active" : ""
                }`}
              >
                {placeholder}
              </span>
            )}
            {selectedItemStack.length > 0 && selectedItemStack[0].html && (
              <span className="multi-filter-label active">
                {selectedItemStack[0].html}
              </span>
            )}
            {selectedItemStack.length > 0 && !selectedItemStack[0].html && (
              <span
                className={`emp-disable-text-selection multi-filter-label active`}
              >
                {selectedItemStack[0].label}
              </span>
            )}
            {selectedItemStack.length > 1 && (
              <EmpPill
                backgroundColor={Color.PRIMARY[100]}
                color={Color.PRIMARY[600]}
                text={`+${selectedItemStack.length - 1}`}
              />
            )}
          </div>
          {!isActive && (
            <ChevronDownIcon size={18} backgroundColor={Color.NEUTRAL[300]} />
          )}
          {isActive && (
            <ChevronUpIcon size={18} backgroundColor={Color.NEUTRAL[300]} />
          )}
        </div>
        {isActive && (
          <motion.div
            variants={fadeInVariants}
            initial={"hidden"}
            animate="visible"
            transition={{ duration: 0.2 }}
            className="dropdown-menu"
            style={{ ...placementCss[placement], ...widthCss }}
          >
            {hasSearch && (
              <div className="top-bar">
                <div className={`emp-text-input-wrapper`}>
                  <div className="emp-left-icon-wrapper">
                    <SearchIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  </div>
                  <input
                    ref={inputRef}
                    className={`emp-text-input left-icon`}
                    placeholder="Search"
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter" && filteredOptions.length > 0) {
                        selectOption(filteredOptions[0]);
                      } else if (event.key === "Escape") {
                        setActive(false);
                      }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      searchKeywordRef.current = event.target.value;
                      filterOptions();
                    }}
                  />
                </div>
              </div>
            )}
            <div className="option-wrapper">
              {filteredOptions.map((elem) => {
                return (
                  <div
                    key={elem.value}
                    onClick={() => {
                      selectOption(elem);
                    }}
                    className="dropdown-item"
                  >
                    <div
                      className={`checkbox ${
                        elem.isSelected ? "selected" : ""
                      }`}
                    >
                      {elem.isSelected && (
                        <CheckIcon
                          size={12}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      )}
                    </div>
                    {!elem.html && (
                      <span className="emp-disable-text-selection">
                        {elem.label}
                      </span>
                    )}
                    {elem.html && <>{elem.html}</>}
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                clearSelection();
              }}
              className="bottom-bar"
            >
              <div className="clear-selection">Clear all</div>
              <span className="indicator">
                {selectedItemStack.length}/{options.length}
              </span>
            </div>
          </motion.div>
        )}
      </div>
      {errorMessage && (
        <div className="emp-error-message-wrapper">
          <AlertSquareIcon
            backgroundColor={Color.RED[600]}
            size={16}
            bottom={1}
          />
          <span>{errorMessage}</span>
        </div>
      )}
    </>
  );
});

export default EmpMultiSelectV2;
