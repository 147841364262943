import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CheckVerificationStatusReqDto } from "../../model/onboarding/check-verification-status-req.dto";
import { CreatorAgencyInvitationActionDto } from "../../model/onboarding/creator-agency-invitation-action.dto";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import { CreatorInvitationEligibilityDto } from "../../model/onboarding/creator-invitation-eligibility.dto";
import { CreatorOnboardingDetailsReqDto } from "../../model/onboarding/creator-onboarding-details-req.dto";
import { CreatorQuickAccessDetailedDto } from "../../model/onboarding/creator-quick-access-detailed.dto";
import { CreatorQuickAccessDto } from "../../model/onboarding/creator-quick-access.dto";
import { InitialOnboardingRespDto } from "../../model/onboarding/initial-onboarding-resp.dto";
import { MapUserOnboardingRespDto } from "../../model/onboarding/map-user-onboarding-resp.dto";
import { OnboardingDetailsReqDto } from "../../model/onboarding/onboarding-details-req.dto";
import { OnboardingSpecRespDto } from "../../model/onboarding/onboarding-spec-resp.dto";
import { OrganisationResendInvitationReqDto } from "../../model/onboarding/organisation-resend-invitation-req.dto";
import { OrganisationRevokeInvitationReqDto } from "../../model/onboarding/organisation-revoke-invitation-req.dto";
import { OrganisationInviteReqDto } from "../../model/organisation/organisation-invite-req.dto";
import { NewCreatorInvitationDto } from "../../model/organisation/new-creator-invitation-req.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { EmpInterceptor } from "../emp-interceptor";
import { ExistingCreatorInvitationDto } from "../../model/organisation/existing-creator-invitation-req.dto";
import StringUtils from "../../utilities/string.util";

// Configure Error Interceptor
const OnboardingApi = {
  fetchInitialDetails: async (
    intent: string,
    id: string
  ): Promise<ApiResponse<InitialOnboardingRespDto>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.get<ApiResponse<InitialOnboardingRespDto>>(
      ENDPOINTS.userMicroservice.onboarding.fetchInitialOnboardingDetails(
        intent,
        id
      )
    );
    return response.data;
  },

  checkVerificationEmailStatus: async (
    request: CheckVerificationStatusReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.checkVerificationEmailStatus,
      request
    );
    return response.data;
  },

  getAgencySpec: async (
    onboardingId?: string
  ): Promise<ApiResponse<OnboardingSpecRespDto>> => {
    const url = StringUtils.generateGetURL({
      href: `${ENDPOINTS.userMicroservice.onboarding.getAgencySpec}`,
      params: {
        onboardingId,
      },
    });
    console.log(url);
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<OnboardingSpecRespDto>>(url);
    return response.data;
  },

  saveAgencySpec: async (
    request: OnboardingDetailsReqDto
  ): Promise<ApiResponse<UserDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<UserDto>>(
      ENDPOINTS.userMicroservice.onboarding.saveAgencySpec,
      request
    );
    return response.data;
  },

  saveBrandSpec: async (
    request: OnboardingDetailsReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.saveBrandSpec,
      request
    );
    return response.data;
  },

  saveCreatorSpec: async (
    request: CreatorOnboardingDetailsReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.saveCreatorSpec,
      request
    );
    return response.data;
  },

  inviteUserToOrg: async (
    request: OrganisationInviteReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.inviteMember,
      request
    );
    return response.data;
  },
  inviteNewCreatorToAgy: async (
    request: NewCreatorInvitationDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.inviteNewCreator,
      request
    );
    return response.data;
  },
  inviteExistingCreatorToAgy: async (
    request: ExistingCreatorInvitationDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.inviteExistingCreator,
      request
    );
    return response.data;
  },
  checkTalentOnboarding: async (
    id: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.fetchCreatorOnboardingStatus(id)
    );
    return response.data;
  },
  resendInvitation: async (
    request: OrganisationResendInvitationReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.resendCreatorInvitation,
      request
    );
    return response.data;
  },
  revokeInvitation: async (
    request: OrganisationRevokeInvitationReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.revokeCreatorInvitation,
      request
    );
    return response.data;
  },
  creatorAcceptInvitation: async (
    request: CreatorAgencyInvitationActionDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.creatorAcceptInvitation,
      request
    );
    return response.data;
  },
  creatorRejectInvitation: async (
    request: CreatorAgencyInvitationActionDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.creatorRejectInvitation,
      request
    );
    return response.data;
  },
  mapUserOnboarding: async (
    request: MapUserOnboardingRespDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.mapUserOnboarding,
      request
    );
    return response.data;
  },
  getCreatorQuickLinkInfoByLinkId: async (
    link: string
  ): Promise<ApiResponse<CreatorQuickAccessDetailedDto>> => {
    const axios = await EmpInterceptor.axiosClientNoAuth();
    const response = await axios.get<
      ApiResponse<CreatorQuickAccessDetailedDto>
    >(
      ENDPOINTS.userMicroservice.onboarding.getCreatorQuickLinkInfoByLinkId(
        link
      )
    );
    return response.data;
  },
  getCreatorQuickLinkInfoByUserId: async (): Promise<
    ApiResponse<CreatorQuickAccessDto>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<CreatorQuickAccessDto>>(
      ENDPOINTS.userMicroservice.onboarding.getCreatorQuickLinkInfoByUserId
    );
    return response.data;
  },
  getCreatorInvitationEligibility: async (
    creatorId: string
  ): Promise<ApiResponse<CreatorInvitationEligibilityDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<CreatorInvitationEligibilityDto>
    >(
      ENDPOINTS.userMicroservice.onboarding.getCreatorInvitationEligibility(
        creatorId
      )
    );
    return response.data;
  },
  sendWelcomeNotification: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.onboarding.sendWelcomeNotification,
      {}
    );
    return response.data;
  },
};
export default OnboardingApi;
