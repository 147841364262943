import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./view-agency-profile-page.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import EmpContent from "../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import EmpLink from "../../components/shared/emp-link/emp-link";
import { Color } from "../../utilities/colors";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _debounce from "lodash/debounce";
import FileIcon from "../../components/icon/file-icon";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import ProfileApi from "../../api/user-msvc/profile.api";
import FileUtils from "../../utilities/file-util";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { TASK_TYPE } from "../../constants/app.constants";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import EmpButton from "../../components/shared/emp-button/emp-button";
import { FormattedMessage } from "react-intl";
import { getCurrentUser } from "aws-amplify/auth";

export const ViewAgencyProfilePage = () => {
  const location = useLocation();
  const topLevelPath = useRef<string>();

  const { id: agencyId } = useParams();
  const isMobile = useEmpBreakpoint(500);

  const navigate = useNavigate();
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const [organisation, setOrganisation] = useState<OrganisationRespDto>();
  const [profile, setProfile] = useState<AgencyProfileDto>();
  const [talents, setTalents] = useState<SmCreatorRespDto[]>([]);

  const [isAboutUsEmpty, setAboutUsEmpty] = useState(false);
  const [isAboutUsExpanded, setAboutUsExpanded] = useState(false);
  const [isSeeMoreVisible, setSeeMoreVisible] = useState<boolean>();
  const aboutUsParaRef = useRef<any>(null);

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const [showCreatorSection, setShowCreatorSection] = useState(false);

  const isMobileRef = useRef<boolean>(isMobile);

  useEffect(() => {
    isMobileRef.current = isMobile;
    computeDetailsCardWidth();
  }, [isMobile]);
  /**
   * Fetches the user details and organization data.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const resp = await OrganisationApi.fetchAgencyOrgById(agencyId!);
      setOrganisation(resp.data);
      fetchProfile(agencyId!);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const fetchProfile = async (organisationId: string) => {
    try {
      const response = await ProfileApi.fetchProfile(organisationId);
      const profile = response.data;
      setProfile(profile);
      setAboutUsEmpty(
        profile.profileAttachments.length === 0 && profile.aboutUs.length === 0
      );
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  const fetchTalents = async () => {
    try {
      const response =
        await OrganisationApi.fetchAgencyCreatorsByOrganisationId(agencyId!);
      if (
        response.data.statusMessage === "no-access-to-excl" ||
        response.data.statusMessage === "no-access-to-agency"
      ) {
        setShowCreatorSection(false);
        return;
      }
      const payload = response.data.payload as SmCreatorRespDto[];
      setShowCreatorSection(true);
      setTalents(payload);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  useEffect(() => {
    topLevelPath.current = location.pathname.split("/")[1];
    fetchUserDetails();
    fetchTalents();
  }, []);

  const setSeeMoreOption = (el: HTMLParagraphElement) => {
    if (el === null) return;
    const isCollapsible = el.scrollHeight > el.clientHeight;
    setSeeMoreVisible(isCollapsible);
  };

  const GUTTER_WIDTH = 12;

  const computeDetailsCardWidth = () => {
    const minCardWidth = isMobileRef.current ? 150 : 210;
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / minCardWidth), 1);

    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );

    setCardWidth(cardWidth);
    setShimmerCards(Array.from(Array(maxCards * 2).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="emp-page-content-no-padding view-agency-profile-page">
        <div className="context-section">
          <div className="mobile-back-btn">
            <EmpButton
              onSubmit={() => {
                navigate(-1);
              }}
              buttonStyle="secondary"
              isFullWidth={false}
              text={<FormattedMessage id="cta_back" />}
              buttonHeight={"sm"}
            />
          </div>

          {profile && (
            <div
              className="colored-bg-section"
              style={{ backgroundImage: `url("${profile.bannerPicture}")` }}
            >
              <div className="anchored-back-btn">
                <EmpIconButton
                  buttonStyle="secondary"
                  outline={Color.NEUTRAL[300]}
                  onSubmit={() => {
                    navigate(-1);
                  }}
                  icon={
                    <ChevronLeftIcon
                      strokeWidth={2.5}
                      size={20}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  }
                />
              </div>
            </div>
          )}
          <div className="common-info-section">
            {/* Image Wrapper */}
            {organisation && profile && (
              <div className="image-card">
                <img alt="Profile" src={organisation.logo} />
              </div>
            )}
            {/* Name and Email */}
            {organisation && (
              <div className="info-wrapper">
                <span className="name-lbl">{organisation.companyName}</span>
                <span className="email-lbl mt-1">{organisation?.email}</span>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-section mt-3">
          <div className="left-section">
            <div className="emp-card">
              <h2 className="emp-card-header">Basic Information</h2>
              {/* Details Section */}
              {organisation && (
                <div className="details-wrapper mt-4">
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardEmailLabel" />
                    }
                    value={organisation.email}
                  />
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardCountryLabel" />
                    }
                    value={
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[organisation.country]["flag_1x1"]
                          }
                        />
                        <span>
                          {COUNTRY_CONSTANTS[organisation.country].name}
                        </span>
                      </div>
                    }
                  />
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardAddressLabel" />
                    }
                    value={`${organisation.address} ${organisation.postalCode}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="right-section">
            {profile && !isAboutUsEmpty && (
              <div className="emp-card about-us-card">
                <div className="header-wrapper">
                  <h2 className="emp-card-header">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardHeader" />
                  </h2>
                </div>
                <p
                  ref={(el: HTMLParagraphElement) => {
                    aboutUsParaRef.current = el;
                    setSeeMoreOption(el);
                  }}
                  className={`emp-card-description ${
                    isAboutUsExpanded ? "" : "truncated"
                  }`}
                >
                  {profile.aboutUs}
                </p>
                {isSeeMoreVisible !== undefined && isSeeMoreVisible && (
                  <EmpLink
                    onSubmit={() => {
                      setAboutUsExpanded(!isAboutUsExpanded);
                    }}
                    text={"See more"}
                  />
                )}

                {/* Uploaded Files Section  */}
                <div className="uploaded-file-wrapper mt-4">
                  {profile.profileAttachments.map((elem) => {
                    return (
                      <div key={elem.id} className="uploaded-file-card">
                        <div className="image-section">
                          <FileIcon backgroundColor={Color.NEUTRAL[0]} />
                        </div>
                        <div className="text-section">
                          <span className="file-title">{elem.label}</span>
                          <div style={{ marginTop: 4 }}>
                            <EmpLink
                              onSubmit={() => {
                                FileUtils.handleFileDownload(
                                  elem.fileName,
                                  elem.url
                                );
                              }}
                              text={<FormattedMessage id="cta_download" />}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {profile && isAboutUsEmpty && (
              <div className="emp-card no-padding about-us-card-empty">
                <div className="header-wrapper">
                  <h2 className="emp-card-header">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardHeader" />
                  </h2>
                  <p className="emp-card-description">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardEmptyDesc" />
                  </p>
                </div>
                <div className="about-us-section-wrapper">
                  <img
                    className="about-us-empty-img"
                    alt="Empty about us"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/about-us-empty.png"
                  />
                </div>
              </div>
            )}
            <div ref={resizeDivRef}></div>
            {showCreatorSection && (
              <Fragment>
                <div className="social-media-label mt-3">
                  <h1>
                    <FormattedMessage id="agencyProfilePage_creatorSectionHeader" />
                  </h1>
                </div>
                {/* Onboarded Talents Section  */}
                <div className="onboarded-talents">
                  {talents.length > 0 &&
                    talents.map((elem, index) => {
                      return (
                        <div
                          onClick={async () => {
                            // If user clicks on his own profile
                            if (elem.id === (await getCurrentUser()).userId) {
                              navigate(`/creator/profile`);
                            } else if (topLevelPath.current) {
                              navigate(
                                `/${topLevelPath.current}/creator-details/${elem.id}`
                              );
                            }
                          }}
                          key={elem.id}
                          className={`talent-card`}
                          style={{ width: cardWidth }}
                        >
                          <div className="details-wrapper ">
                            {elem.imageType === "url" && (
                              <img
                                className="profile-img"
                                src={elem.imageResource}
                              />
                            )}
                            {elem.imageType === "avatar" && (
                              <div
                                className="profile-avatar"
                                style={{ background: elem.imageResource }}
                              >
                                <span>{elem.initials}</span>
                              </div>
                            )}
                            <div className="account-details-wrapper ml-2">
                              <div className="name">{elem.name}</div>
                            </div>
                          </div>
                          <div className="metrics-wrapper mt-4">
                            <div className="metric-left">
                              <EmpContent
                                label={
                                  <FormattedMessage id="agencyProfilePage_creatorCardImpressionsLabel" />
                                }
                                value={elem.impressions.toString()}
                              />
                            </div>
                            <div className="metric-right">
                              <EmpContent
                                label={
                                  <FormattedMessage id="agencyProfilePage_creatorCardCountryLabel" />
                                }
                                value={
                                  <div className="country-wrapper">
                                    <img
                                      className="img-flag"
                                      alt="country"
                                      src={
                                        COUNTRY_CONSTANTS[elem.country][
                                          "flag_1x1"
                                        ]
                                      }
                                    />
                                    <span>
                                      {COUNTRY_CONSTANTS[elem.country].name}
                                    </span>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          <div className="social-media-wrapper mt-3">
                            {elem.platforms.length > 0 && (
                              <>
                                {elem.platforms.includes(
                                  TASK_TYPE.FACEBOOK
                                ) && (
                                  <FacebookIcon
                                    size={15}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                )}
                                {elem.platforms.includes(
                                  TASK_TYPE.INSTAGRAM
                                ) && (
                                  <InstagramIcon
                                    size={15}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                )}
                                {elem.platforms.includes(TASK_TYPE.TIKTOK) && (
                                  <TikTokIcon
                                    size={15}
                                    backgroundColor={Color.NEUTRAL[400]}
                                  />
                                )}
                              </>
                            )}
                            {elem.platforms.length === 0 && (
                              <>
                                <span>
                                  <FormattedMessage id="agencyMyCreatorsView_creatorCardNoSocialMediaAccountsLabel" />
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {talents.length === 0 && (
                    <div className="talent-empty-state">
                      <div className="overlay">
                        <div className="info-wrapper">
                          <img
                            className="empty-img"
                            alt="No creators found"
                            srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                          />
                          <h2 className="header">
                            <FormattedMessage id="agencyProfilePage_emptyHeader" />
                          </h2>
                          <p className="description">
                            <FormattedMessage id="agencyProfilePage_emptyDesc" />
                          </p>
                        </div>
                      </div>
                      {shimmerCard.map((elem, index) => {
                        return (
                          <div
                            key={elem}
                            className={`talent-placeholder-card`}
                            style={{ width: cardWidth }}
                          >
                            <div className="details-wrapper ">
                              <div className="profile shimmer-color"></div>
                              <div className="account-details-wrapper ml-2">
                                <div className="name shimmer-color"></div>
                                <div className="email shimmer-color"></div>
                              </div>
                            </div>
                            <div className="metrics-wrapper mt-4">
                              <div className="metric pr-2">
                                <div className="title shimmer-color"></div>
                                <div className="value shimmer-color"></div>
                              </div>
                              <div className="metric pl-2">
                                <div className="title shimmer-color"></div>
                                <div className="value shimmer-color"></div>
                              </div>
                            </div>
                            <div className="social-media-wrapper mt-6">
                              <div className="icon shimmer-color"></div>
                              <div className="icon shimmer-color"></div>
                              <div className="icon shimmer-color"></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
