import "./facebook-page-selection-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
  FacebookPageDto,
  FacebookPageSpec,
} from "../../../model/social-media/facebook-page.dto";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpException from "../../../exception/empException";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormattedMessage } from "react-intl";

export interface FacebookPageSelectionModalRef {
  show: (facebookPagesData: FacebookPageDto, platform: string) => void;
  dismiss: () => void;
}

interface Props {
  onSave: (fbPageSpec: FacebookPageSpec, platform: string) => void;
  onDismiss: () => void;
}

const FacebookPageSelectionModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [facebookPagesData, setFacebookPagesData] = useState<FacebookPageDto>();
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const platformRef = useRef<string>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (facebookPagesData: FacebookPageDto, platform: string) => {
    setFacebookPagesData(facebookPagesData);
    platformRef.current = platform;
    setVisible(true);
  };
  const dismiss = () => {
    setVisible(false);
    setHasSelected(false);
    setSelectedIndex(-1);
    setFacebookPagesData(undefined);
  };

  const pageItemOnClick = (index: number) => {
    setSelectedIndex(index);
    setHasSelected(true);
  };

  const onSave = () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!facebookPagesData) throw new EmpException("Unable to get page spec");
      const spec = facebookPagesData.data[selectedIndex];
      props.onSave(spec, platformRef.current!);
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={<FormattedMessage id="facebookPageSelection_header" />}
      showFooter={false}
      showFooterBorder={false}
      onClose={() => {
        dismiss();
      }}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-facebook-pages-modal">
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="facebookPageSelection_title" />
          </h2>
          <p className="description">
            <FormattedMessage id="facebookPageSelection_desc" />
          </p>
        </div>

        {/* Facebook Pages Mapper */}

        {facebookPagesData && (
          <div className="pages-wrapper">
            {facebookPagesData.data.map((elem, index) => {
              return (
                <div
                  onClick={() => {
                    pageItemOnClick(index);
                  }}
                  key={elem.id}
                  className={`page-item ${index === selectedIndex ? "active" : ""}`}
                >
                  <div className="left-elem">
                    <img
                      className="picture"
                      alt="page icon"
                      src={elem.picture.data.url}
                    />
                    <div className="text-section">
                      <span className="name">{elem.name}</span>
                      <span className="about">{elem.about}</span>
                    </div>
                  </div>
                  <div className="right-elem">
                    <div
                      className={`placeholder ${index === selectedIndex ? "active" : ""}`}
                    >
                      {index === selectedIndex && <div className="knob"></div>}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="footer-btn-wrapper mt-4">
          <div className="mr-2">
            <EmpButton
              isFullWidth={false}
              buttonStyle={"secondary"}
              text={<FormattedMessage id="cta_cancel" />}
              onSubmit={() => {
                props.onDismiss();
                dismiss();
              }}
            />
          </div>

          <EmpButton
            isFullWidth={false}
            disabled={!hasSelected}
            ref={saveBtnRef}
            text={
              <FormattedMessage id="facebookPageSelection_bindAccountBtn" />
            }
            onSubmit={onSave}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default FacebookPageSelectionModal;
