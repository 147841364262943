export const PUB_SUB_TOPICS = {
  ALERT: "alert",
  USER: "user",
  LANGUAGE: "language",
  PARTNERSHIP: "partnership",
  REALTIME: "realtime",
  GLOBAL_NOTIFICATION: "global-notification",
  BRAND_CREATOR_SEARCH: "brand-creator-search",
  RATE_CARD_REQUEST_UPDATE: "rate-card-request-update",
  VIEW_PLANS: "view-plans"
};
