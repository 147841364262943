import { useEffect, useState } from "react";
import "./emp-coin-icon.scss";
import {
  CRYPTO_COIN_CONSTANTS,
  CryptoCoinType,
} from "../../../constants/crypto-coin.contants";

interface Props {
  iconValue: string;
  mode?: "icon-and-text" | "icon-only";
  size?: number;
  rounded?: boolean;
  className?: string;
  top?: number;
}

export const EmpCoinIcon = (props: Props) => {
  const { iconValue, className, top } = props;
  const size = props.size ?? 16;
  const rounded = props.rounded ?? false;
  const mode = props.mode ?? "icon-and-text";

  const [cryptoDefinition, setCryptoDefinition] = useState<CryptoCoinType>();
  useEffect(() => {
    const crypto = CRYPTO_COIN_CONSTANTS.find(
      (elem) => elem.ticker === iconValue
    );
    setCryptoDefinition(crypto);
  }, [iconValue]);
  return (
    <div
      className={`emp-coin-icon ${className ?? ""}`}
      style={{ top: top ?? 0 }}
    >
      {cryptoDefinition ? (
        <>
          <img
            style={{
              height: size,
              width: size,
              borderRadius: rounded ? "100px" : undefined,
            }}
            alt={cryptoDefinition.name}
            src={cryptoDefinition.image}
          />
          {mode === "icon-and-text" && <span>{cryptoDefinition.ticker}</span>}
        </>
      ) : (
        <>
          <div
            style={{
              height: size,
              width: size,
              borderRadius: rounded ? "100px" : undefined,
            }}
            className="no-icon"
          >
            ?
          </div>
          {mode === "icon-and-text" && <span>{"UDF"}</span>}
        </>
      )}
    </div>
  );
};
