import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CreateCampaignDisputeDto } from "../../model/campaign/create-campaign-dispute.dto";
import { EmpInterceptor } from "../emp-interceptor";

const CampaignDisputeApi = {
  createCampaignDisputeAsBrand: async (
    request: CreateCampaignDisputeDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.campaignDispute.createCampaignDisputeAsBrand,
      request
    );
    return response.data;
  },
};
export default CampaignDisputeApi;
