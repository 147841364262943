import { IntlShape } from "react-intl";
import { DIAL_CODE_CONSTANTS } from "../../constants/dial-code.constants";
import EmpException from "../../exception/empException";
import { BaseValidator, IValidator } from "./baseValidation";
import { IFormControl, MobileNumberControlValueType } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";

// Get an instance of `PhoneNumberUtil`.
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

export class MobileCountryValidator
  extends BaseValidator
  implements IValidator
{
  customErrorMessage: string = VALIDATION_MESSAGE.IS_REQUIRED;

  intl: IntlShape;

  /**
   * @purpose Override error message if necessary
   * @param errorMessage Custom error message that
   * overrides default Required Validator error message
   */
  constructor(intl: IntlShape) {
    super();
    this.intl = intl;
  }

  validate(formControl: IFormControl): boolean {
    if (formControl.formType !== "mobile-number") {
      throw new EmpException("Incorrect usage of mobile country validator.");
    }
    return this.validateMobileNumber(formControl);
  }

  validateMobileNumber(formControl: IFormControl): boolean {
    const defaultEmptyMobileErrorMessage = this.intl.formatMessage({
      id: "validation_mobileRequired",
    });
    const defaultMobileCountryFormatErrorMessage = (code: string) =>
      this.intl.formatMessage(
        { id: "validation_mobileFormatWCode" },
        { country: this.intl.formatMessage({ id: `countryOption_${code}` }) }
      );
    const defaultMobileFormatErrorMessage = this.intl.formatMessage({
      id: "validation_mobileFormat",
    });

    const evaluable = formControl.value as MobileNumberControlValueType;
    if (evaluable.mobileNumber.length <= 0 || !evaluable.dialCode) {
      this.hasError(formControl, defaultEmptyMobileErrorMessage);
      return false;
    }
    try {
      const code = DIAL_CODE_CONSTANTS.find(
        (elem) => elem.code === evaluable.code
      )?.code;
      if (!code) {
        this.hasError(formControl, defaultMobileFormatErrorMessage);
        return false;
      }
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
        evaluable.mobileNumber,
        code.toUpperCase()
      );
      const isValid = phoneUtil.isValidNumber(parsedPhoneNumber);
      if (!isValid) {
        const dialCode = DIAL_CODE_CONSTANTS.find((elem) => elem.code === code);
        if (dialCode)
          this.hasError(
            formControl,
            defaultMobileCountryFormatErrorMessage(dialCode.code)
          );
        else this.hasError(formControl, defaultMobileFormatErrorMessage);
        return false;
      }
    } catch (e) {
      console.error(e);
      const countryDial = DIAL_CODE_CONSTANTS.find(
        (elem) => elem.code === evaluable.code
      );
      if (countryDial)
        this.hasError(
          formControl,
          defaultMobileCountryFormatErrorMessage(countryDial.code)
        );
      else this.hasError(formControl, defaultMobileFormatErrorMessage);
      return false;
    }

    this.noError(formControl);
    return true;
  }

  validateDateRange(formControl: IFormControl): boolean {
    const evaluable = formControl.value as { startDate: Date; endDate: Date };
    if (!evaluable.startDate || !evaluable.endDate) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }

  validateText(formControl: IFormControl): boolean {
    const value = formControl.value;
    const evaluable = value ? value.trim() : "";

    if (evaluable.length <= 0 || evaluable === "") {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }
}
