import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CreatorCompleteOnboardingDto } from "../../model/support-referral/support/creator-complete-onboarding.dto";
import { AgencyInvitedCreatorCompleteReferralDto } from "../../model/support-referral/support/agency-invited-creator-complete-referral.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const SupportApi = {
  setupAgencyReferral: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.referralAndSupportMsvc.support.agencyCompleteOnboard
    );
    return response.data;
  },
  setupBrandReferral: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.referralAndSupportMsvc.support.brandCompleteOnboard
    );
    return response.data;
  },
  setupCreatorReferral: async (): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.referralAndSupportMsvc.support.creatorCompleteOnboard
    );
    return response.data;
  },
  setupAgencyInvitedCreatorReferral: async (
    request: AgencyInvitedCreatorCompleteReferralDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.referralAndSupportMsvc.support.agencyInvitedCreatorComplete,
      request
    );
    return response.data;
  },
};
export default SupportApi;
