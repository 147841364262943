import { IconProps } from "../../../model/common/icon";
import useEmpIcon from "../../../hooks/useEmpIcon";
import { EmpSortType } from "../../../model/common/tableFilters";
import { useEffect, useState } from "react";
import { Color } from "../../../utilities/colors";

interface SortIconProps extends IconProps {
  sortState?: EmpSortType;
}

const EmpSortIcon = (props: SortIconProps) => {
  const { size, top, left, right, bottom } = useEmpIcon(props);
  const [sortState, setSortState] = useState<EmpSortType>(
    props.sortState ? props.sortState : "neutral"
  );

  // Track changes from parent component
  useEffect(() => {
    if (props.sortState === undefined) return;
    setSortState(props.sortState);
  }, [props.sortState]);

  const renderNeutralIcon = (): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <title>sort</title>
        <path
          fill={Color.NEUTRAL[400]}
          d="M18 21L14 17H17V7H14L18 3L22 7H19V17H22M2 19V17H12V19M2 13V11H9V13M2 7V5H6V7H2Z"
        />
      </svg>
    );
  };

  const renderAscending = (): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <title>sort-descending</title>
        <path
          fill={Color.PRIMARY[500]}
          d="M19 7H22L18 3L14 7H17V21H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z"
        />
      </svg>
    );
  };

  const renderDescending = (): JSX.Element => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
      >
        <title>sort-ascending</title>
        <path
          fill={Color.PRIMARY[500]}
          d="M19 17H22L18 21L14 17H17V3H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z"
        />
      </svg>
    );
  };

  const renderIcon = (): JSX.Element => {
    if (sortState === "neutral") {
      return renderNeutralIcon();
    } else if (sortState === "asc") {
      return renderAscending();
    } else if (sortState === "desc") {
      return renderDescending();
    }
    throw new Error("Invalid sort state");
  };

  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
      }}
    >
      {renderIcon()}
    </div>
  );
};
export default EmpSortIcon;
