import "./agency-home-page.scss";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { TASK } from "../../constants/app.constants";
import EmpException from "../../exception/empException";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import EmpLink from "../../components/shared/emp-link/emp-link";
import AgencyTaskApi from "../../api/user-msvc/agency-task.api";
import { AgencyTaskStatusRespDto } from "../../model/agency-task/agency-task-status-resp.dto";
import ToastUtils from "../../utilities/toast-utils";
import UserApi from "../../api/user-msvc/user.api";
import ProfileApi from "../../api/user-msvc/profile.api";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import AnnouncementIcon from "../../components/icon/announcement-icon";
import { Color } from "../../utilities/colors";
import UsersRightIcon from "../../components/icon/users-right-icon";
import CoinsHandIcon from "../../components/icon/coins-hand-icon";
import AgencyMapChart from "./agency-map-card";
import CountUp from "react-countup";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import RecentCreatorsCard from "./recent-creators-card";
import { FormattedMessage, useIntl } from "react-intl";
import AgencyActiveTasksTable from "./agency-active-tasks-table";
import { motion } from "framer-motion";
import EmpLoaderV2 from "../../components/shared/emp-loader-v2/emp-loader-v2";

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const DELAY_MS = 0.2;
type ComponentType =
  | "profile"
  | "metric-tiles"
  | "active-tasks"
  | "creator-map"
  | "recent-creators"
  | "task-checklist";
export const AgencyHomePage = () => {
  const navigate = useNavigate();
  const [organisation, setOrganisation] = useState<OrganisationRespDto>();
  const [task, setTask] = useState<AgencyTaskStatusRespDto[]>([]);
  const progressRef = useRef<number>(100);
  const [profile, setProfile] = useState<AgencyProfileDto>();
  const [creators, setCreators] = useState<SmCreatorRespDto[]>();
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState(true);

  const intl = useIntl();

  const [loadedComponents, setLoadedComponents] = useState<Set<ComponentType>>(
    new Set<ComponentType>()
  );

  /**
   * Fetches the user details and organization data.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await UserApi.fetchUser();
      const organisations = response.data.organisation!;
      if (organisations?.length === 0)
        throw new EmpException("Unable to fetch organisation");
      const organisation = organisations[0];
      setOrganisation(organisation);
      taskCompletion(organisation.id);
      fetchProfile(organisation.id);

      Promise.all([setOrganisation, taskCompletion, fetchProfile]).then(() => {
        loadedComponents.add("task-checklist");
        loadedComponents.add("profile");
        loadedComponents.add("metric-tiles");
        loadedComponents.add("creator-map");
        setLoadedComponents(new Set(loadedComponents));
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const taskCompletion = async (organisationId: string) => {
    try {
      const response = await AgencyTaskApi.fetchAgencyTask(organisationId);
      const tasks = response.data;
      setTask(tasks);
      const completedTasks = tasks.filter((elem) => elem.isCompleted);
      progressRef.current = 100 - (completedTasks.length / tasks.length) * 100;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  const fetchAgencyCreators = useCallback(async () => {
    try {
      const response = await OrganisationApi.fetchAgencyCreators();
      // process the map data
      const creators = response.data;
      setCreators(creators);
      loadedComponents.add("recent-creators");
      setLoadedComponents(new Set(loadedComponents));
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to fetch existing creators"
      );
      throw error;
    }
  }, []);

  useEffect(() => {
    if (loadedComponents.size === 6) {
      setLoading(false);
    }
  }, [loadedComponents]);

  useEffect(() => {
    fetchUserDetails();
    fetchAgencyCreators();
  }, [fetchUserDetails, fetchAgencyCreators]);

  const fetchProfile = async (organisationId: string) => {
    try {
      const response = await ProfileApi.fetchProfile(organisationId);
      const profile = response.data;
      setProfile(profile);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  const cardOnClick = (taskId: number) => {
    if (taskId === TASK.COMPLETE_PROFILE) {
      navigate("/agency/profile");
    } else if (taskId === TASK.INVITE_CREATORS) {
      navigate("/agency/creators");
    } else if (taskId === TASK.TEAM_ACCOUNTS) {
      navigate("/agency/settings#manage-team");
    } else if (taskId === TASK.PAYMENT_INFO) {
      navigate("/agency/settings#payment");
    }
  };

  const skipTask = async (taskId: number) => {
    try {
      if (!organisation) throw new EmpException("No organisation data");
      const resp = await AgencyTaskApi.markAgencyTaskAsComplete(
        organisation?.id,
        taskId
      );
      if (resp.data.status === "success") {
        ToastUtils.success("Task Completed", "Marked task as complete");
        taskCompletion(organisation.id);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred marking task as complete"
      );
    }
  };

  return (
    <div>
      <div className="emp-page-wrapper emp-responsive">
        <EmpLoaderV2 isLoading={isLoading} />
        <div className="emp-page-content no-padding emp-home-page">
          {profile && organisation && (
            <motion.div
              initial="hidden"
              variants={fadeInVariant}
              animate={!isLoading ? "visible" : "hidden"}
              transition={{ delay: 0 * DELAY_MS, duration: 0.2 }}
              className="emp-home-page-intro-banner"
              style={{
                backgroundImage: `url("https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/opal-prismatic-1.png")`,
              }}
            >
              <div className="banner-shading"></div>
              <div className="company-info-wrapper">
                <img
                  className="organistion-logo"
                  alt={organisation.companyName}
                  src={organisation.logo}
                />
                <h1 className="intro-lbl">
                  Hello {organisation.companyName} 👋
                </h1>
              </div>
            </motion.div>
          )}
          <div className="dashboard-section">
            <div className="left-col">
              {creators && (
                <div className="metrics-tile-wrapper">
                  <motion.div
                    className="metric-tile"
                    initial="hidden"
                    animate={!isLoading ? "visible" : "hidden"}
                    transition={{ delay: 1 * DELAY_MS, duration: 0.2 }}
                    variants={fadeInVariant}
                  >
                    <div className="details-wrapper">
                      <span className="metric-value">
                        <CountUp
                          duration={1.8}
                          end={creators.length}
                          separator={","}
                        />
                      </span>
                      <AnnouncementIcon
                        top={2}
                        size={24}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                    <span className="metric-header">
                      <FormattedMessage id="agencyHomePage_metricTile1" />
                    </span>
                  </motion.div>
                  <motion.div
                    className="metric-tile"
                    initial="hidden"
                    animate={!isLoading ? "visible" : "hidden"}
                    transition={{ delay: 1.5 * DELAY_MS, duration: 0.2 }}
                    variants={fadeInVariant}
                  >
                    <div className="details-wrapper">
                      <span className="metric-value">
                        $<CountUp duration={1.8} end={0.0} separator={","} />
                      </span>
                      <AnnouncementIcon
                        top={2}
                        size={24}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                    <span className="metric-header">
                      <FormattedMessage id="agencyHomePage_metricTile2" />
                    </span>
                  </motion.div>
                  <motion.div
                    className="metric-tile"
                    initial="hidden"
                    animate={!isLoading ? "visible" : "hidden"}
                    transition={{ delay: 2 * DELAY_MS, duration: 0.2 }}
                    variants={fadeInVariant}
                  >
                    <div className="details-wrapper">
                      <span className="metric-value">
                        <CountUp duration={1.8} end={0} separator={","} />
                      </span>
                      <UsersRightIcon
                        top={2}
                        size={24}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                    <span className="metric-header">
                      <FormattedMessage id="agencyHomePage_metricTile3" />
                    </span>
                  </motion.div>
                  <motion.div
                    className="metric-tile"
                    initial="hidden"
                    animate={!isLoading ? "visible" : "hidden"}
                    transition={{ delay: 2.5 * DELAY_MS, duration: 0.2 }}
                    variants={fadeInVariant}
                  >
                    <div className="details-wrapper">
                      <span className="metric-value">
                        <CountUp duration={1.8} end={0} separator={","} />
                      </span>
                      <CoinsHandIcon
                        top={2}
                        size={24}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                    </div>
                    <span className="metric-header">
                      <FormattedMessage id="agencyHomePage_metricTile4" />
                    </span>
                  </motion.div>
                </div>
              )}
              <motion.div
                initial="hidden"
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 4 * DELAY_MS, duration: 0.2 }}
                variants={fadeInVariant}
              >
                <AgencyActiveTasksTable
                  onLoaded={() => {
                    loadedComponents.add("active-tasks");
                    setLoadedComponents(new Set(loadedComponents));
                  }}
                />
              </motion.div>

              {creators && (
                <motion.div
                  initial="hidden"
                  animate={!isLoading ? "visible" : "hidden"}
                  transition={{ delay: 5 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                >
                  <AgencyMapChart creators={creators} onLoaded={() => {}} />
                </motion.div>
              )}
            </div>
            <div className="right-col">
              {task && (
                <motion.div
                  initial="hidden"
                  animate={!isLoading ? "visible" : "hidden"}
                  transition={{ delay: 3 * DELAY_MS, duration: 0.2 }}
                  variants={fadeInVariant}
                  className="emp-card no-padding completion-cards-card"
                >
                  <div className="completion-section">
                    <span className="card-header-lbl">
                      <FormattedMessage id="agencyHomePage_taskListCardHeader" />
                    </span>
                    <div className="details-section">
                      <div className="progress-wrapper">
                        <div className="progress-value-wrapper">
                          <span className="value-lbl">
                            {100 - progressRef.current}%
                          </span>
                        </div>
                        <svg
                          className="circle-container"
                          viewBox="-4 -4 40 40"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            className="circle-container__background"
                            r="16"
                            cx="16"
                            cy="16"
                          ></circle>
                          <circle
                            className="circle-container__progress"
                            r="16"
                            cx="16"
                            cy="16"
                            strokeDashoffset={progressRef.current}
                          ></circle>
                        </svg>
                      </div>
                      <div className="info-wrapper">
                        <span className="description-lbl mt-1">
                          <FormattedMessage id="agencyHomePage_taskListCardDesc" />
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Completion Cards */}
                  <div
                    ref={resizeDivRef}
                    className="completion-cards-section mt-3"
                  >
                    {task.map((elem) => {
                      return (
                        <div key={elem.taskId} className="completion-card">
                          {elem.isCompleted && <div className="muted"></div>}
                          <div
                            className="task-wrapper"
                            onClick={() => {
                              if (elem.isCompleted) return;
                              cardOnClick(elem.taskId);
                            }}
                          >
                            <div className="icon-section">
                              <img alt={`todo: ${elem.name}`} src={elem.icon} />
                            </div>
                            <div className="info-section">
                              <span className="header-lbl">
                                <>
                                  {elem.taskId === 1 && (
                                    <FormattedMessage id="agencyHomePage_paymentTaskHeader" />
                                  )}
                                  {elem.taskId === 2 && (
                                    <FormattedMessage id="agencyHomePage_profileTaskHeader" />
                                  )}
                                  {elem.taskId === 3 && (
                                    <FormattedMessage id="agencyHomePage_createAccountsTaskHeader" />
                                  )}
                                  {elem.taskId === 4 && (
                                    <FormattedMessage id="agencyHomePage_inviteCreatorsTaskHeader" />
                                  )}
                                  {elem.taskId === 5 && (
                                    <FormattedMessage id="agencyHomePage_infoTaskHeader" />
                                  )}
                                  {![1, 2, 3, 4, 5].includes(elem.taskId) &&
                                    elem.name}
                                </>
                                {elem.isCompleted && <span>✅</span>}
                              </span>
                              <span className="description-lbl">
                                {elem.taskId === 1 && (
                                  <FormattedMessage id="agencyHomePage_paymentTaskDesc" />
                                )}
                                {elem.taskId === 2 && (
                                  <FormattedMessage id="agencyHomePage_profileTaskDesc" />
                                )}
                                {elem.taskId === 3 && (
                                  <FormattedMessage id="agencyHomePage_createAccountsTaskDesc" />
                                )}
                                {elem.taskId === 4 && (
                                  <FormattedMessage id="agencyHomePage_inviteCreatorsTaskDesc" />
                                )}
                                {elem.taskId === 5 && (
                                  <FormattedMessage id="agencyHomePage_infoTaskDesc" />
                                )}
                                {![1, 2, 3, 4, 5].includes(elem.taskId) &&
                                  elem.description}
                              </span>
                            </div>
                          </div>
                          <div className="actions-wrapper">
                            {elem.isSkippable && !elem.isCompleted && (
                              <div className="mt-1">
                                <EmpLink
                                  text={"Irrelevant for me"}
                                  onSubmit={() => {
                                    skipTask(elem.taskId);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </motion.div>
              )}
              <motion.div
                animate={!isLoading ? "visible" : "hidden"}
                transition={{ delay: 6 * DELAY_MS, duration: 0.2 }}
                initial="hidden"
                variants={fadeInVariant}
              >
                <RecentCreatorsCard />
              </motion.div>
            </div>
          </div>
          <div style={{ height: 300 }}></div>
        </div>
      </div>
    </div>
  );
};
