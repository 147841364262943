import _debounce from "lodash/debounce";
import "./x-tweet.scss";
import { motion } from "framer-motion";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import { Color } from "../../utilities/colors";
import ShareIcon from "../icon/share-icon";
import { DateUtil } from "../../utilities/date";
import FormFieldUtils from "../../utilities/form-field.util";
import Barchart1Icon from "../icon/barchart-1-icon";
import RepeatIcon from "../icon/repeat-icon";
import PlayIcon from "../icon/play-icon";
import { X_MEDIA_TYPE } from "../../constants/app.constants";
import { XBasicProfileDto } from "../../model/x/x-basic-profile.dto";
import { useMemo } from "react";
import { XTweetDto } from "../../model/x/x-tweet.dto";

type CardVariant = "default" | "selectable";

interface Props {
  xProfile: XBasicProfileDto;
  xTweet: XTweetDto;
  index: number;
  variant?: CardVariant;
  onSelect?: (tweetId: string) => void;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const DELAY = 0.1;

const XTweet = (props: Props) => {
  const { xTweet, xProfile, index, onSelect } = props;
  const variant = props.variant ?? "default";

  const totalPollCount = useMemo(() => {
    if (!xTweet.poll) return 1;

    const totalcount = xTweet.poll.choices.reduce((prev, current) => {
      return prev + current.count;
    }, 0);
    return Math.max(totalcount, 1);
  }, [xTweet]);

  const highestPollOption = useMemo(() => {
    if (!xTweet.poll) return "";
    const option = xTweet.poll.choices.reduce(
      (prev, current) => {
        return current.count > prev.count ? current : prev;
      },
      { label: "", count: -1 }
    );
    if (option.count === 0) return;
    return option.label;
  }, [xTweet]);

  return (
    <div className="emp-x-tweet">
      <motion.div
        variants={fadeInVariants}
        initial="hidden"
        animate="visible"
        transition={{ delay: DELAY * index, duration: 0.4 }}
        key={xTweet.permalink}
        className={`x-tweet`}
      >
        <div className="profile-wrapper">
          <img className="picture" src={xProfile.picture} alt={xProfile.name} />
          <div className="tweet-details-wrapper">
            <div className="profile-name-wrapper">
              <div className="profile-name-wrapper-2">
                <span className="profile-name">{xProfile.name}</span>
                {xProfile.verifiedType !== "none" && (
                  <img
                    className="verified-mark"
                    alt="x verified mark"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/x-verified.webp"
                  />
                )}
              </div>
              <span className="profile-handle">@{xProfile.handle}</span>
              <div className="date-wrapper">
                <span>{DateUtil.toReadableDate(xTweet.timestamp)}</span>
              </div>
            </div>
            <p className="caption">{xTweet.text}</p>
            {xTweet.media && xTweet.media.length > 0 && (
              <div className="image-grid">
                {xTweet.media.map((media, mediaIndex) => {
                  return (
                    <div
                      key={media.url}
                      className={`media-wrapper items-${xTweet.media!.length}`}
                    >
                      {media.type === X_MEDIA_TYPE.VIDEO && (
                        <div className="play-video-overlay">
                          <button
                            onClick={() => {
                              window.open(xTweet.permalink, "_blank");
                            }}
                            className="emp-button-reset play-circle"
                          >
                            <PlayIcon
                              size={24}
                              backgroundColor={Color.NEUTRAL[0]}
                            />
                          </button>
                        </div>
                      )}
                      <img alt={`post ${mediaIndex + 1}`} src={media.url} />
                    </div>
                  );
                })}
              </div>
            )}

            {xTweet.poll && xTweet.poll.choices.length > 0 && (
              <div className="poll-section">
                {xTweet.poll.choices.map((poll, index) => {
                  return (
                    <article key={index} className="poll-bar-article">
                      <div className="poll-bar-wrapper">
                        <div className={`poll-label`}>
                          <span
                            className={`label ${
                              poll.label === highestPollOption
                                ? "highest-result"
                                : ""
                            }`}
                          >
                            {poll.label}
                          </span>
                        </div>
                        <div
                          className={`poll-bar ${
                            poll.label === highestPollOption
                              ? "highest-result"
                              : ""
                          }`}
                          style={{
                            width: `${(poll.count / totalPollCount) * 100}%`,
                          }}
                        ></div>
                      </div>
                      <span
                        className={`poll-percentage ${
                          poll.label === highestPollOption
                            ? "highest-result"
                            : ""
                        }`}
                      >{`${(poll.count / totalPollCount) * 100}%`}</span>
                    </article>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="info-section">
          <div className="metrics-wrapper">
            <div className="metric">
              <MessageIcon backgroundColor={Color.NEUTRAL[300]} size={16} />
              <span className="metric-value">
                <span className="value-lbl">
                  {FormFieldUtils.toCompact(xTweet.replyCount)}
                </span>
              </span>
            </div>
            <div className="metric">
              <RepeatIcon backgroundColor={Color.NEUTRAL[300]} size={16} />
              <span className="metric-value">
                <span className="value-lbl">
                  {FormFieldUtils.toCompact(xTweet.retweetCount)}
                </span>
              </span>
            </div>
            <div className="metric">
              <HeartIcon backgroundColor={Color.NEUTRAL[300]} size={16} />
              <span className="metric-value">
                <span className="value-lbl">
                  {FormFieldUtils.toCommify(xTweet.likeCount)}
                </span>
              </span>
            </div>
            <div className="metric">
              <Barchart1Icon backgroundColor={Color.NEUTRAL[300]} size={16} />
              <span className="metric-value">
                <span className="value-lbl">
                  {FormFieldUtils.toCommify(xTweet.viewCount)}
                </span>
              </span>
            </div>
            <div className="link-to-post">
              {variant === "default" && (
                <a
                  className="link"
                  href={xTweet.permalink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="emp-highlighted">View Tweet</span>
                  <ShareIcon size={18} backgroundColor={Color.PRIMARY[400]} />
                </a>
              )}

              {variant === "selectable" && (
                <button
                  onClick={() => {
                    if (onSelect) onSelect(xTweet.permalink);
                  }}
                  className="emp-button-reset link"
                >
                  <span className="emp-highlighted">Select Tweet</span>
                  <ShareIcon size={18} backgroundColor={Color.PRIMARY[600]} />
                </button>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
export default XTweet;
