import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  SubscriptionCancellationModal,
  SubscriptionCancellationModalRef,
} from "../../../components/modals/subscription-cancellation-modal";
import {
  SubscriptionChangeModal,
  SubscriptionChangeModalRef,
} from "../../../components/modals/subscription-change-modal";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import { AppContext } from "../../../context/app.context";
import { SubscriptionDto } from "../../../model/subscription/subscription.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import UserUtils from "../../../utilities/user-utils";
import visaImg from "../../../assets/images/payment/visa.svg";
import mastercardImg from "../../../assets/images/payment/mastercard.svg";
import jcbImage from "../../../assets/images/payment/jcb.svg";
import americanExpressImg from "../../../assets/images/payment/american_express.svg";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import EmpTable, {
  EmpTableContentSpec,
} from "../../../components/shared/EmpTable/EmpTable";
import { useIntl } from "react-intl";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import { InvoiceRecordDto } from "../../../model/subscription/invoice-record.dto";
import moment from "moment";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import EmpLoaderV2 from "../../../components/shared/emp-loader-v2/emp-loader-v2";
import EmpKebabMenu from "../../../components/shared/emp-kebab-menu/emp-kebab-menu";
import SubscriptionAlertModal, {
  SubscriptionAlertModalRef,
} from "../../../components/modals/subscription-alert-modal";
import "./brand-subscription-view.scss";
import { Color } from "../../../utilities/colors";

export const BrandSubscriptionView = () => {
  const intl = useIntl();

  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();
  useState<SubscriptionDto>();

  const subscriptionAlertModalRef = useRef<SubscriptionAlertModalRef>();
  const subscriptionCancellationModalRef =
    useRef<SubscriptionCancellationModalRef>();
  const subscriptionChangeModalRef = useRef<SubscriptionChangeModalRef>();
  const updatePaymentMethodBtnRef = useRef<EmpButtonRef>();
  const invoiceContentSpec: EmpTableContentSpec<InvoiceRecordDto>[] =
    useMemo(() => {
      const generateInvoiceName = (
        periodStart: string,
        periodEnd: string,
        periodUnit: string
      ) => {
        if (periodUnit === "MONTH") {
          const startMonth = moment(periodStart).format("MMM");
          const endMonth = moment(periodEnd).format("MMM");
          return `Usage from ${startMonth} till ${endMonth}`;
        }
        return "Unplanned Scenario";
      };

      return [
        {
          title: "Invoice",
          dataIndex: "invoiceDto.id",
          render: (record: InvoiceRecordDto) => {
            return (
              <>
                {generateInvoiceName(
                  record.invoiceDto.createdAt,
                  record.invoiceDto.periodEndAt,
                  record.price.periodUnit
                )}
              </>
            );
          },
        },
        {
          title: "Item",
          dataIndex: "invoiceDto.paidAt",
          render: (record: InvoiceRecordDto) => {
            return <>{record.price.name}</>;
          },
        },
        {
          title: "Payment Date",
          dataIndex: "paidAt",
          render: (record: InvoiceRecordDto) => {
            return (
              <>{DateUtil.toReadableDateWithTime(record.invoiceDto.paidAt)}</>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (record: InvoiceRecordDto) => {
            return (
              <EmpPill
                text={record.invoiceDto.status}
                {...PILL_COLORS.primary}
              />
            );
          },
        },
        {
          title: "Card",
          dataIndex: "paymentCard",
          render: (record: InvoiceRecordDto) => {
            return (
              <>
                {!record.card && <>Card not available</>}
                {record.card && (
                  <div className="card-no-col">
                    <span>Ends with {record.card.last4}</span>
                    <img
                      src={recommendCardImg(record.card.brand)}
                      alt="Card Logo"
                      className="brand-logo"
                    />
                  </div>
                )}
              </>
            );
          },
        },
        {
          title: "Total Amount",
          dataIndex: "totalAmount",
          render: (record: InvoiceRecordDto) => {
            return (
              <>${FormFieldUtils.toMoney(record.invoiceDto.totalAmount)} USD</>
            );
          },
        },
      ];
    }, [intl]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  const recommendCardImg = (brand: string) => {
    if (brand === "visa") return visaImg;
    else if (brand === "mastercard") return mastercardImg;
    else if (brand === "american express") return americanExpressImg;
    else if (brand === "jcb") return jcbImage;
  };

  return (
    <div className="emp-brand-subscription-view">
      <SubscriptionAlertModal ref={subscriptionAlertModalRef} />
      <SubscriptionCancellationModal
        ref={subscriptionCancellationModalRef}
        onSave={() => {}}
      />
      <SubscriptionChangeModal
        ref={subscriptionChangeModalRef}
        onSave={() => {}}
      />

      <div className="view-header-wrapper">
        <div>
          <h1>Manage Subscription</h1>
          <p className="page-description">
            Customize, update, and adjust your plan, payments, and features in
            one place. Seamlessly upgrade, downgrade, or modify your
            subscription to fit your changing needs.
          </p>
        </div>
      </div>

      {user && (
        <div className="card-wrapper">
          <div className="split-cards-wrapper">
            <>
              <div className="emp-card card">
                <div className="card-header-wrapper">
                  <h2>Current Plan</h2>
                  <EmpKebabMenu
                    menuButtonSpecs={[
                      {
                        label: "Change Plan",
                        context: {},
                        action: () => {
                          return;
                        },
                      },
                      {
                        label: "Cancel Plan",
                        context: {},
                        style: "danger",
                        action: () => {
                          subscriptionCancellationModalRef.current?.show();
                        },
                      },
                    ]}
                  />
                </div>
                <span className="plan-name">Blub Blub Plan Name</span>
                <div className="details-wrapper mt-4">
                  <EmpContent label={"AMOUNT"} value={`0.5 Eth`} />
                  <EmpContent
                    label={"NEXT BILLING DATE"}
                    value={"1 Jan 2050"}
                  />
                </div>
              </div>

              <div className="emp-card card billing-card">
                <div className="card-header-wrapper">
                  <h2>Payment Method</h2>
                  <EmpButton
                    ref={updatePaymentMethodBtnRef}
                    onSubmit={() => {
                      return;
                    }}
                    isFullWidth={false}
                    buttonHeight="sm"
                    buttonStyle="secondary"
                    text={"Update"}
                  />
                </div>

                <div className="card-number-wrapper mt-4">
                  <span className="card-number-lbl">
                    **** **** **** {"1234"}
                  </span>
                  <EmpImage
                    className="card-brand-height"
                    src={recommendCardImg("visa")}
                    alt={"Visa Card"}
                    fallback={EMPTY_PROFILE_IMG_URL}
                  />
                </div>
                <span className="name-lbl">Tan Tock Seng</span>
              </div>
            </>
          </div>
        </div>
      )}
      <section className="invoice-section mt-10">
        <h2>Invoices</h2>
        <p className="emp-paragraph mt-2">
          Find your subscription payment information in the table provided. For
          additional assistance, your customer success officer can be contacted{" "}
          <EmpLink text={"here"} />
        </p>
        <div className="emp-card no-padding mt-3">
          <EmpTable
            shimmerLoading={{
              width: [100, 140, 80, 50, 50],
              overlayDesign: (
                <>
                  <EmpLoaderV2 isLoading={true} />
                </>
              ),
            }}
            loading={false}
            showEmptyState={true}
            contentColumns={invoiceContentSpec}
            data={[]}
            rowKey={"id"}
          />
        </div>
      </section>
    </div>
  );
};
