import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import "./create-campaign-modal.scss";
import "rsuite/dist/rsuite-no-reset.min.css";
import { DateRangePicker } from "rsuite";
import EmpSelect from "../shared/emp-select/emp-select";
import { campaignObjectiveOptions } from "../../constants/selectConstants";
import EmpException from "../../exception/empException";
import { DateRange } from "rsuite/esm/DateRangePicker";
import AlertSquareIcon from "../icon/alert-square";
import { Color } from "../../utilities/colors";
import FileUtils from "../../utilities/file-util";
import FileIcon from "../icon/file-icon";
import UploadIcon from "../icon/upload-icon";
import UserUtils from "../../utilities/user-utils";
import { AppContext } from "../../context/app.context";
import { UserDto } from "../../model/user-management/user.dto";
import { empDelay } from "../../utilities/delay";
import {
  analyse,
  calculateDarkerShade,
  calculateLighterShade,
  convertHtmlToJpgBase64,
  getColorBrightness,
  getShade,
} from "../../utilities/image-util";
import EditIcon from "../icon/edit-icon";
import { EmpCropper, EmpCropperRef } from "../shared/emp-cropper/emp-cropper";
import { CreateCampaignDto } from "../../model/campaign/create-campaign.dto";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import ToastUtils from "../../utilities/toast-utils";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpDateRangePicker from "../shared/emp-date-range-picker/emp-date-range-picker";
import { addDays, addMonths, endOfDay, startOfDay } from "date-fns";
import XCloseIcon from "../icon/x-close-icon";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import HelpCircleIcon from "../icon/help-circle-icon";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "./campaign-guide-modal";
import { RESTRUCTURE_CAMPAIGN_GUIDE } from "../../constants/campaign-guide";
import { FILE_TYPE } from "../../constants/app.constants";
import EmpLink from "../shared/emp-link/emp-link";

export interface CreateCampaignModalRef {
  show: () => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

type StepType = "step-1" | "step-2";

const CreateCampaignModal = forwardRef((props: Props, ref) => {
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const [fileErrorMessage, setFileErrorMessage] = useState<string>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>();

  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();

  const [fileSpecs, setFileSpecs] = useState<File>();
  const userPictureRef = useRef<HTMLImageElement>(null);

  const [imageBgColor, setImageBgColor] = useState<{
    altShade?: string;
    shade: string;
  }>();

  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const createCampaignBtnRef = useRef<EmpButtonRef>(null);
  const [currentStep, setCurrentStep] = useState<StepType>("step-1");

  const [form, setForm] = useState<IFormGroup>({
    name: new FormControl("text", [
      new RequiredValidator("Campaign Name is required"),
      new LengthValidator(
        0,
        30,
        undefined,
        "Campaign name must not exceed 30 characters"
      ),
    ]),
    description: new FormControl("text", [
      new RequiredValidator("Campaign description is required"),
      new LengthValidator(
        0,
        300,
        undefined,
        "Description must not exceed 300 characters"
      ),
    ]),
    objective: new FormControl("text", [
      new RequiredValidator("Please select an objective"),
    ]),
    dateRange: new FormControl("date-range", [
      new RequiredValidator("Please select a task date range"),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  function convertImageUrlToBase64(imageUrl: string): Promise<string> {
    const headers = new Headers();
    headers.append("Origin", "https://localhost:3100");

    return fetch(imageUrl, { headers })
      .then((response) => {
        return response.blob();
      })
      .then(
        (blob) =>
          new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result as string;
              resolve(base64String.split(",")[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  }
  const show = async () => {
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setLogo(undefined);
    setFileSpecs(undefined);
    setFileErrorMessage(undefined);
    setCurrentStep("step-1");
    setVisible(false);
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  const toStep2 = () => {
    try {
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      if (!isValid) return;
      setCurrentStep("step-2");
    } catch (e) {
      console.error(e);
      throw new EmpException("Unable to proceed to step 2");
    }
  };

  // ----------- Part 2 Form fileSpec -----------------------//

  const [logo, setLogo] = useState<string>();
  const defaultImageTemplateRef = useRef<HTMLDivElement>(null);
  const cropperRef = useRef<EmpCropperRef>();
  const isLogoModifiedRef = useRef(false);
  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isPDF = file.type === "application/pdf";
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 20);
    if (!isPDF) {
      setFileErrorMessage("Please upload a PDF file.");
      return;
    }
    if (!isSmallEnough) {
      setFileErrorMessage("File size must be less than or equal to 20MB.");
      return;
    }
    setFileErrorMessage(undefined);
    setFileSpecs(file);
  };

  useEffect(() => {
    const getColor = async () => {
      await empDelay(100);
      if (!user || !userPictureRef.current) return null;
      await empDelay(500);
      const color = await analyse(userPictureRef.current, 5);
      const shade = getShade(color);
      let altShade = "";
      if (shade === "light") {
        altShade = calculateLighterShade(color, 30);
      } else {
        altShade = calculateDarkerShade(color, 30);
      }
      setImageBgColor({
        altShade: altShade,
        shade: color,
      });
    };
    getColor();
    // oi();
  }, [user, visible]);

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 2);

    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 2MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await FileUtils.readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    cropperRef.current!.open(base64);
  };

  /**
   * This function reads a file as a base64 string.
   * @param file - The file to be read.
   * @returns A promise that resolves with a base64 string.
   */
  const readFileAsBase64 = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64);
      };
    });

  const createCampaign = async () => {
    try {
      createCampaignBtnRef.current?.setButtonState("loading");

      let picture: string | undefined = undefined;
      if (logo) {
        picture = logo;
      } else {
        picture = await convertHtmlToJpgBase64(
          defaultImageTemplateRef.current!
        );
      }
      const { startDate, endDate } = form.dateRange.getValue() as {
        startDate: Date;
        endDate: Date;
      };
      const request: CreateCampaignDto = {
        name: form.name.getValue(),
        description: form.description.getValue(),
        objective: form.objective.getValue(),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        picture: picture,
        supportingDocument: fileSpecs
          ? {
              fileType: FILE_TYPE.FILE,
              base64: await readFileAsBase64(fileSpecs),
              name: fileSpecs.name,
              size: fileSpecs.size,
            }
          : undefined,
      };
      const resp = await CampaignApi.createCampaign(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Campaign Created",
          "Your campaign has been created successfully"
        );
        dismiss();
        props.onSave();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to create new campaign"
      );
    } finally {
      createCampaignBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={"Create Campaign"}
      showFooter={false}
      showHeader={false}
      bodyPadding={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-create-campaign-modal">
        <CampaignGuideModal ref={campaignGuideModalRef} />
        <section className="emp-modal-common-header">
          <div className="emp-modal-hstack-wrapper">
            <h2>Create Campaign</h2>
            <EmpIconButton
              onSubmit={() => {
                campaignGuideModalRef.current?.show(RESTRUCTURE_CAMPAIGN_GUIDE);
              }}
              buttonStyle="secondary"
              suppressMobileView
              icon={
                <HelpCircleIcon
                  backgroundColor={Color.NEUTRAL[400]}
                  size={18}
                />
              }
            />
          </div>
        </section>

        <EmpCropper
          onCrop={(base64: string) => {
            setLogo(base64);
            isLogoModifiedRef.current = true;
          }}
          ref={cropperRef}
          aspect={1.667}
          cropShape="rect"
        />
        {/* Stepper Section */}
        <div className="campaign-modal-body mt-4">
          {user && user.imageType === "url" && (
            <img
              crossOrigin=""
              style={{ display: "none" }}
              alt="user avatar"
              ref={userPictureRef}
              src={`${user.imageResource}?_`}
            />
          )}
          <section className="stepper-section">
            <div className="step stepper-elem">
              <span
                className={`step-header stepper-text-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              >
                Step 1
              </span>
              <span
                className={`step-description stepper-text-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              >
                Campaign Basic Information
              </span>
              <div
                className={`bar stepper-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="step stepper-elem">
              <span
                className={`step-header stepper-text-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              >
                Step 2
              </span>
              <span
                className={`step-description stepper-text-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              >
                Supporting Documents (Optional)
              </span>
              <div
                className={`bar stepper-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              ></div>
            </div>
          </section>
          {currentStep === "step-1" && (
            <>
              <section className="form-section mt-8">
                <EmpTextInput
                  id={"name"}
                  labelText="Campaign Name"
                  required
                  tooltip="Enter a memorable and descriptive name for your campaign. A unique campaign name helps you easily identify and manage your marketing efforts."
                  formControl={form.name}
                  onChange={validate}
                  placeholder={"e.g Summer Sale Campaign"}
                />

                <EmpTextInput
                  id={"description"}
                  labelText="Description"
                  multiline
                  required
                  rows={2}
                  textAreaAdaptiveHeight
                  tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                  formControl={form.description}
                  onChange={validate}
                  characterCount={300}
                  placeholder={"Tell us about your marketing initiative..."}
                />
                <div className="split-wrapper">
                  <div className="split-control">
                    <EmpSelect
                      id={"objective"}
                      labelText="Objective"
                      required
                      formControl={form.objective}
                      onChange={validate}
                      placeholder={"Select Campaign Objective"}
                      selectOptions={campaignObjectiveOptions}
                    />
                  </div>
                  <div className="date-range-wrapper ">
                    <EmpDateRangePicker
                      formControl={form.dateRange}
                      label={"Projected Campaign Duration"}
                      ranges={[
                        {
                          label: "Two Weeks",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addDays(new Date(), 14)),
                          ],
                        },
                        {
                          label: "One Month",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addMonths(new Date(), 1)),
                          ],
                        },
                        {
                          label: "Two Months",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addMonths(new Date(), 2)),
                          ],
                        },
                      ]}
                      placeholder={"Projected Campaign Duration"}
                    />
                  </div>
                </div>
              </section>
              <div className="button-wrapper mt-4">
                <EmpButton
                  onSubmit={() => {
                    toStep2();
                  }}
                  ref={createCampaignBtnRef}
                  isFullWidth={false}
                  text={"Proceed next step"}
                />
              </div>
            </>
          )}
          {currentStep === "step-2" && (
            <>
              <section className="form-section step-2 mt-8">
                <div className="uploader-section">
                  <span className="upload-label">
                    Campaign Image <span className="required">*</span>
                  </span>

                  <label
                    className="image-upload-zone mt-2"
                    htmlFor={"logo-upload"}
                  >
                    <p className="image-description">
                      Please Upload a campaign image with a 3:5 ratio
                    </p>
                    <p className="image-description mt-1">
                      This is a default image. Click on the picture to upload
                      your own picture
                    </p>
                    <div className="image-upload-wrapper mt-3">
                      <div className="image-hover-box">
                        <EditIcon
                          size={18}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                        <span>Upload Image</span>
                      </div>
                      {user && !logo && (
                        <div
                          ref={defaultImageTemplateRef}
                          className="image-upload "
                          style={{
                            background: `radial-gradient(50% 50.00% at 50% 50.00%, ${imageBgColor?.altShade} 0%, ${imageBgColor?.shade} 100%)`,
                          }}
                        >
                          <img
                            src={`${user.imageResource}?_`}
                            alt={user.fullName}
                          />
                        </div>
                      )}
                      {logo && (
                        <img
                          className="campaign-image"
                          src={logo}
                          alt={"campaign"}
                        />
                      )}
                    </div>

                    {imageErrorMessage && (
                      <div className="emp-error-message-wrapper">
                        <AlertSquareIcon
                          backgroundColor={Color.RED[600]}
                          size={16}
                          bottom={1}
                        />
                        <span>{imageErrorMessage}</span>
                      </div>
                    )}
                  </label>
                  <input
                    className="upload-hidden"
                    accept="image/*"
                    type="file"
                    id="logo-upload"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                    name="myfile"
                  ></input>
                  {fileErrorMessage && (
                    <div className="emp-error-message-wrapper">
                      <AlertSquareIcon
                        backgroundColor={Color.RED[600]}
                        size={16}
                        bottom={1}
                      />
                      <span>{fileErrorMessage}</span>
                    </div>
                  )}
                </div>
                <div className="uploader-section mt-4">
                  <span className="upload-label">
                    Supporting Documents <span className="required">*</span>
                  </span>
                  <label
                    className="file-upload-zone mt-2"
                    htmlFor={"file-upload"}
                  >
                    {!fileSpecs && (
                      <>
                        <UploadIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[300]}
                        />
                        <span className="title">
                          Click here to upload a file
                        </span>
                        <p className="specs">
                          Only PDF files, with lesser than 20Mb in file size are
                          allowed.
                        </p>
                      </>
                    )}

                    {fileSpecs && (
                      <>
                        <FileIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[300]}
                        />
                        <span className="title">{fileSpecs.name}</span>
                        <p className="specs">
                          {FileUtils.convertBytesToReadableSize(fileSpecs.size)}
                        </p>
                      </>
                    )}
                  </label>
                  <input
                    className="upload-hidden"
                    accept="application/pdf"
                    type="file"
                    id="file-upload"
                    onChange={handleFileUpload}
                    name="myfile"
                  ></input>
                  {fileSpecs && (
                    <EmpLink
                      text={"Remove File"}
                      linkStyle="danger"
                      onSubmit={() => {
                        setFileSpecs(undefined);
                        setFileErrorMessage(undefined);
                      }}
                    />
                  )}
                  {fileErrorMessage && (
                    <div className="emp-error-message-wrapper">
                      <AlertSquareIcon
                        backgroundColor={Color.RED[600]}
                        size={16}
                        bottom={1}
                      />
                      <span>{fileErrorMessage}</span>
                    </div>
                  )}
                </div>
              </section>
              <div className="button-wrapper mt-4">
                <EmpButton
                  onSubmit={() => {
                    setCurrentStep("step-1");
                  }}
                  isFullWidth={false}
                  buttonStyle={"secondary"}
                  text={"Back"}
                />
                <EmpButton
                  onSubmit={() => {
                    createCampaign();
                  }}
                  ref={createCampaignBtnRef}
                  isFullWidth={false}
                  text={"Create Campaign"}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CreateCampaignModal;
