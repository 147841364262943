import { ReactNode, useEffect, useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import "./emp-icon-button.scss";

interface Props {
  buttonStyle?: "primary" | "secondary" | "translucent";
  onSubmit?: () => void;
  icon: ReactNode;
  tooltip?: string;
  outline?: string;
  suppressMobileView?: boolean;
}

const EmpIconButton = (props: Props) => {
  const { onSubmit } = props;
  const outline = props.outline ?? undefined;
  const suppressMobileView = props.suppressMobileView ?? false;
  const [buttonStyle, setButtonStyle] = useState<
    "primary" | "secondary" | "translucent"
  >(props.buttonStyle ? props.buttonStyle : "primary");

  const tooltip = props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : <></>;

  useEffect(() => {
    setButtonStyle(props.buttonStyle ? props.buttonStyle : "primary");
  }, [props.buttonStyle]);

  return (
    <>
      {props.tooltip && (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={tooltip}
        >
          <button
            style={{
              boxShadow: outline ? `0 0 0 1.5px ${outline}` : undefined,
            }}
            className={`emp-icon-button ${buttonStyle} ${suppressMobileView ? "" : "mobile-view"}`}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            {props.icon}
          </button>
        </Whisper>
      )}
      {!props.tooltip && (
        <button
          style={{ boxShadow: outline ? `0 0 0 1.5px ${outline}` : undefined }}
          className={`emp-icon-button ${buttonStyle} ${suppressMobileView ? "" : "mobile-view"}`}
          onClick={() => {
            if (onSubmit) onSubmit();
          }}
        >
          {props.icon}
        </button>
      )}
    </>
  );
};
export default EmpIconButton;
