import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import {
  EMPTY_PROFILE_IMG_URL,
  NOTIFICATION_RECORD,
} from "../../../constants/app.constants";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onView: () => void;
  onRefresh: () => void;
}

export const PostPublishedEventNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const userRoleRef = useRef<string>("");

  // const [isLoading, setLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  const viewOnClick = async () => {
    navigate(`/brand/social-media-manager#post-history`);
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  const navigateToPost = async () => {
    const postIds = notification.metadata.post.map(
      (post: any) => post.id
    ) as string[];
    navigate(
      `/brand/social-media-manager#post-history?postIds=${postIds.join(",")}`
    );
    props.onView();
  };

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) {
        return;
      }

      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  if (!notification.metadata.socialSpec) return null;
  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* ============================ NOTIFICATION DISPLAY IMG ================================== */}
      {/* ----------------------- Notification Type : Post --------------------------- */}
      {notification.event === NOTIFICATION_RECORD.SMM_POST_PUBLISHED && (
        <div className="organisation-img-wrapper">
          <img
            alt={notification.metadata.socialSpec.page_name}
            className="user-img"
            src={
              (notification.metadata.socialSpec.picture?.charAt(0) === "{"
                ? JSON.parse(notification.metadata.socialSpec.picture)?.data.url
                : notification.metadata.socialSpec.picture) ||
              EMPTY_PROFILE_IMG_URL
            }
          />
          {/* {notification.metadata.socialSpec.imageType ===
            "avatar" && (
            <div
              style={{
                background:
                JSON.parse(data.picture).data.url,
              }}
              className="user-avatar"
            >
              <span className="initials">
                {notification.metadata.socialSpec.initials}
              </span>
            </div>
          )} */}
        </div>
      )}

      {/* ----------------------- Notification Type : Task Started --------------------------- */}
      {notification.event === NOTIFICATION_RECORD.SMM_POST_PUBLISHED && (
        // <div className="organisation-img-wrapper">
        //   {/* The Brand Logo Image */}
        //   <img
        //     alt={notification.metadata.brand.companyName}
        //     className="organisation-img"
        //     src={notification.metadata.brand.logo}
        //   />
        // </div>
        <></>
      )}

      <div className="notification-content">
        <div className="content-wrapper">
          {notification.event === NOTIFICATION_RECORD.SMM_POST_PUBLISHED && (
            <>
              {userRoleRef.current === "brand" &&
                (notification.metadata.post?.[0].status === "failed" ? (
                  <span className="info-text">
                    Oh no! Your post(s)
                    <span className="emp-highlighted">
                      &nbsp;{notification.metadata.post?.[0].id}
                    </span>
                    {!!notification.metadata.post?.[0]?.scheduled_time
                      ? " scheduled "
                      : " posted "}
                    on&nbsp;
                    <span className="emp-highlighted">
                      {notification.metadata.post?.[0]?.scheduled_time
                        ? DateUtil.toReadableDateWithTime(
                            notification.metadata.post?.[0].scheduled_time *
                              1000 *
                              60
                          )
                        : DateUtil.toReadableDateWithTime(
                            notification.metadata.post?.[0].created_date
                          )}
                    </span>
                    &nbsp;has failed to publish! For more information,{" "}
                    <EmpLink text={" click here."} onSubmit={navigateToPost} />
                  </span>
                ) : (
                  <span className="info-text">
                    Your post(s)
                    <span className="emp-highlighted">
                      &nbsp;{notification.metadata.post?.[0].id}
                    </span>
                    {!!notification.metadata.post?.[0]?.scheduled_time
                      ? " scheduled "
                      : " posted "}
                    on&nbsp;
                    <span className="emp-highlighted">
                      {notification.metadata.post?.[0]?.scheduled_time
                        ? DateUtil.toReadableDateWithTime(
                            notification.metadata.post?.[0].scheduled_time *
                              1000 *
                              60
                          )
                        : DateUtil.toReadableDateWithTime(
                            notification.metadata.post?.[0].created_date
                          )}
                    </span>
                    &nbsp;has been successfully published! For more information,
                    <EmpLink text={" click here."} onSubmit={navigateToPost} />
                  </span>
                ))}
            </>
          )}

          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>

        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(
            notification.createdAt.toString()
          )}`}</span>

          <div className="button-wrapper">
            <EmpLink text={"View"} onSubmit={viewOnClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
