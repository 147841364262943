import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import KycApi from "../../api/payment-msvc/kyc.api";
import CheckIcon from "../../components/icon/check-icon";
import ChevronDownIcon from "../../components/icon/chevron-down";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import EmpButton from "../../components/shared/emp-button/emp-button";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import EmpLanguagePicker from "../../components/shared/emp-language-picker/emp-language-picker";
import EmpLoaderV2 from "../../components/shared/emp-loader-v2/emp-loader-v2";
import { KYC_PROGRESS } from "../../constants/app.constants";
import { AppContext } from "../../context/app.context";
import { isApiStatus } from "../../model/api/api-status";
import { KycProgressTableDto } from "../../model/payment/kyc/kyc-progress-table.dto";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { KycCreatorPersonalInfoStep } from "./kyc-creator-personal-info-step";
import { KycCreatorProfessionalDetailsStep } from "./kyc-creator-professional-details-step";
import { KycCreatorPaymentStep } from "./kyc-creator-payment-step";
import { KycCreatorSummary } from "./kyc-creator-summary";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;
const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

type StepType =
  | "creator-professional-details"
  | "creator-personal-info"
  | "payment"
  | "summary";

export const CreatorKycView = () => {
  const [currentStep, setCurrentStep] = useState<StepType>(
    "creator-professional-details"
  );
  const [stepperStatus, setStepperStatus] = useState({
    "creator-professional-details": "in-progress",
    "creator-personal-info": "pending",
    payment: "pending",
    summary: "pending",
  });
  const location = useLocation();
  const navigate = useNavigate();

  const [isScrollable, setIsScrollable] = useState(false);
  const [bottomScrollVisible, setBottomScrollVisible] = useState(false);
  const [topScrollVisible, setTopScrollVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bottomScrolledFinish, setBottomScrolledFinish] = useState(false);
  const [topScrolledFinish, setTopScrolledFinish] = useState(false);
  const formSectionRef = useRef<HTMLDivElement>(null);

  const setScrollingProperties = useCallback(
    async (formElem: HTMLDivElement) => {
      await empDelay(100);
      const { scrollHeight, scrollTop, clientHeight } = formElem;
      setIsScrollable(scrollHeight > clientHeight);
      setBottomScrolledFinish(scrollHeight - scrollTop - clientHeight < 40);
      setTopScrolledFinish(scrollTop < 20);
    },
    []
  );

  const [kycInfo, setKycInfo] = useState<KycDto>();

  const fetchKycInfo = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await KycApi.fetchKyc();
      if (isApiStatus(resp.data)) {
        if (resp.data.statusMessage === "no-record-found")
          setKycInfo(undefined);
        return;
      } else {
        // Find Step Fragment
        const fragment =
          window.location.hash.slice(1).length === 0
            ? undefined
            : (window.location.hash.slice(1) as StepType);
        console.log("fragment", fragment);
        const progressRecords = resp.data.completedSteps;
        if (!resp.data.hasCompleted) {
          setProgressTracker(progressRecords, fragment);
        } else setCurrentStep("summary");
        setKycInfo(resp.data);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch kyc info");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchKycInfo();
  }, [fetchKycInfo]);

  useEffect(() => {
    const formElem = formSectionRef.current;
    if (!formElem) return;
    setScrollingProperties(formElem);
    const handleScroll = () => {
      if (formElem) {
        const { scrollHeight, scrollTop, clientHeight } = formElem;
        setBottomScrolledFinish(scrollHeight - scrollTop - clientHeight < 40);
        setTopScrolledFinish(scrollTop < 20);
      }
    };
    const observer = new ResizeObserver(() => {
      handleScroll();
    });
    formElem.addEventListener("scroll", handleScroll);
    if (formElem) observer.observe(formElem);

    return () => {
      formElem?.removeEventListener("scroll", handleScroll);
      if (formElem) observer.unobserve(formElem);
    };
  }, [formSectionRef, setScrollingProperties]);

  // use state to trigger the animation
  useEffect(() => {
    const toggleVisibility = async () => {
      if (!bottomScrolledFinish) {
        setBottomScrollVisible(true);
      } else {
        await empDelay(200);
        setBottomScrollVisible(false);
      }
    };
    toggleVisibility();
  }, [bottomScrolledFinish]);

  useEffect(() => {
    const toggleVisibility = async () => {
      if (!topScrolledFinish) {
        setTopScrollVisible(true);
      } else {
        await empDelay(200);
        setTopScrollVisible(false);
      }
    };
    toggleVisibility();
  }, [topScrolledFinish]);

  const setProgressTracker = (
    progressRecords: KycProgressTableDto[],
    toStep?: StepType
  ) => {
    if (
      progressRecords.find(
        (elem) => elem.step === KYC_PROGRESS.PAYMENT_INFO.step
      )
    ) {
      stepperStatus["payment"] = "done";
      stepperStatus["creator-personal-info"] = "done";
      stepperStatus["creator-professional-details"] = "done";

      stepperStatus["summary"] = "in-progress";
      if (toStep && toStep !== "summary") {
        stepperStatus[toStep] = "in-progress";
        stepperStatus["summary"] = "pending";
      }

      setCurrentStep(toStep ?? "summary");
    } else if (
      progressRecords.find(
        (elem) => elem.step === KYC_PROGRESS.CREATOR_PERSONAL_INFO.step
      )
    ) {
      if (toStep === "summary") {
        return;
      }
      stepperStatus["creator-professional-details"] = "done";
      stepperStatus["creator-personal-info"] = "done";
      stepperStatus["payment"] = "in-progress";
      stepperStatus["summary"] = "pending";
      if (toStep) stepperStatus[toStep] = "in-progress";
      setCurrentStep(toStep ?? "payment");
    } else if (
      progressRecords.find(
        (elem) => elem.step === KYC_PROGRESS.CREATOR_PROFESSIONAL_DETAILS.step
      )
    ) {
      if (toStep === "summary" || toStep === "payment") {
        return;
      }

      stepperStatus["creator-professional-details"] = "done";
      stepperStatus["creator-personal-info"] = "in-progress";
      stepperStatus["payment"] = "pending";
      stepperStatus["summary"] = "pending";
      if (toStep) stepperStatus[toStep] = "in-progress";
      setCurrentStep(toStep ?? "creator-personal-info");
    } else {
      if (toStep) return;
      setCurrentStep("creator-professional-details");
    }
    setStepperStatus({ ...stepperStatus });
  };

  // useEffect(() => {
  //   window.location.hash = currentStep;
  // }, [currentStep]);

  // useEffect(() => {
  //   console.log("did i change?");
  // }, [location]);

  return (
    <div className="emp-kyc-page">
      <div className="info-section">
        <div className="info-section-content">
          <div className="back-btn-wrapper mb-1">
            <EmpButton
              isFullWidth={false}
              text={<FormattedMessage id="cta_back" />}
              onSubmit={() => {
                navigate(-1);
              }}
              buttonHeight="sm"
              buttonStyle="secondary"
            />
          </div>
          <div className="logo-wrapper">
            <h1 className="mobile-header">
              <FormattedMessage id="kycInfoSection_mobileHeader" />
            </h1>
            <div className="logo-and-back-wrapper">
              <EmpIconButton
                buttonStyle="secondary"
                onSubmit={() => {
                  const basePath = location.pathname.split("/")[1];
                  navigate(`/${basePath}/settings#payment`);
                }}
                icon={
                  <ChevronLeftIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[500]}
                  />
                }
              />
              <img
                className="logo"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/emplifive-logo-black.svg"
                alt="Emplifive logo"
              />
            </div>
            <EmpLanguagePicker bordered menuPlacement="right" />
          </div>

          <h1 className="header">
            <FormattedMessage id="kycInfoSection_header" />
          </h1>
          <p className="emp-paragraph mt-4">
            <FormattedMessage id="kycInfoSection_desc" />
          </p>

          {loading && <EmpLoaderV2 isLoading={loading} />}

          {!loading && (
            <div className="mt-6 step-wrapper">
              {(!kycInfo || !kycInfo!.hasCompleted) && (
                <>
                  <motion.div
                    onClick={() => {
                      setProgressTracker(
                        kycInfo!.completedSteps,
                        "creator-professional-details"
                      );
                    }}
                    className="step"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                    transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
                  >
                    {stepperStatus["creator-professional-details"] ===
                      "in-progress" && <div className="in-progress"></div>}
                    {stepperStatus["creator-professional-details"] ===
                      "pending" && (
                      <div className="unchecked">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[400]}
                        />
                      </div>
                    )}
                    {stepperStatus["creator-professional-details"] ===
                      "done" && (
                      <div className="done">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    {stepperStatus["creator-professional-details"] ===
                      "skip" && (
                      <div className="skipped">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    <span className="step-label">
                      <FormattedMessage id="kycInfoSection_creator_professionalDetailsStep" />
                    </span>
                  </motion.div>
                  <motion.div
                    onClick={async () => {
                      setProgressTracker(
                        kycInfo!.completedSteps,
                        "creator-personal-info"
                      );
                    }}
                    className="step"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                    transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
                  >
                    {stepperStatus["creator-personal-info"] ===
                      "in-progress" && <div className="in-progress"></div>}
                    {stepperStatus["creator-personal-info"] === "pending" && (
                      <div className="unchecked">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[400]}
                        />
                      </div>
                    )}
                    {stepperStatus["creator-personal-info"] === "done" && (
                      <div className="done">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    {stepperStatus["creator-personal-info"] === "skip" && (
                      <div className="skipped">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    <span className="step-label">
                      <FormattedMessage id="kycInfoSection_creator_personalInfo" />
                    </span>
                  </motion.div>
                  <motion.div
                    onClick={async () => {
                      setProgressTracker(kycInfo!.completedSteps, "payment");
                    }}
                    className="step"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                    transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
                  >
                    {stepperStatus["payment"] === "in-progress" && (
                      <div className="in-progress"></div>
                    )}
                    {stepperStatus["payment"] === "pending" && (
                      <div className="unchecked">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[400]}
                        />
                      </div>
                    )}
                    {stepperStatus["payment"] === "done" && (
                      <div className="done">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    {stepperStatus["payment"] === "skip" && (
                      <div className="skipped">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    <span className="step-label">
                      <FormattedMessage id="kycInfoSection_paymentDetailsStep" />
                    </span>
                  </motion.div>
                  <motion.div
                    onClick={() => {
                      setProgressTracker(kycInfo!.completedSteps, "summary");
                    }}
                    className="step"
                    initial="hidden"
                    animate="visible"
                    variants={fadeInVariants}
                    transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
                  >
                    {stepperStatus["summary"] === "in-progress" && (
                      <div className="in-progress"></div>
                    )}
                    {stepperStatus["summary"] === "pending" && (
                      <div className="unchecked">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[400]}
                        />
                      </div>
                    )}
                    {stepperStatus["summary"] === "done" && (
                      <div className="done">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    {stepperStatus["summary"] === "skip" && (
                      <div className="skipped">
                        <CheckIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[0]}
                        />
                      </div>
                    )}
                    <span className="step-label">
                      <FormattedMessage id="kycInfoSection_summaryStep" />
                    </span>
                  </motion.div>
                </>
              )}
              {kycInfo && kycInfo.hasCompleted && (
                <motion.div
                  className="step"
                  initial="hidden"
                  animate="visible"
                  variants={fadeInVariants}
                  transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
                >
                  <div className="done">
                    <CheckIcon size={14} backgroundColor={Color.NEUTRAL[0]} />
                  </div>
                  <span className="step-label">
                    <FormattedMessage id="kycInfoSection_submittedStep" />
                  </span>
                </motion.div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="form-section">
        <div className="form-section-content">
          {loading && <EmpLoaderV2 isLoading={loading} />}
          {!loading && (
            <>
              {isScrollable && bottomScrollVisible && (
                <motion.div
                  className="scroll-down-indicator"
                  initial="hidden"
                  variants={fadeInVariants}
                  transition={{ duration: 0.2 }}
                  animate={!bottomScrolledFinish ? "visible" : "hidden"}
                >
                  <div className="wrapper">
                    <span className="scroll-lbl">
                      <FormattedMessage id={"cta_scrollDown"} />
                    </span>
                    <ChevronDownIcon
                      size={22}
                      backgroundColor={Color.NEUTRAL[700]}
                    />
                  </div>
                </motion.div>
              )}
              {isScrollable && topScrollVisible && (
                <motion.div
                  className="scroll-up-indicator"
                  initial="hidden"
                  variants={fadeInVariants}
                  transition={{ duration: 0.2 }}
                  animate={!topScrolledFinish ? "visible" : "hidden"}
                ></motion.div>
              )}
              <div className="form-section-wrapper" ref={formSectionRef}>
                {currentStep === "creator-professional-details" && (
                  <KycCreatorProfessionalDetailsStep
                    kycInfo={kycInfo}
                    nextStep={() => {
                      fetchKycInfo();
                      setCurrentStep("creator-personal-info");
                      stepperStatus["creator-professional-details"] = "done";
                      stepperStatus["creator-personal-info"] = "in-progress";
                    }}
                  />
                )}
                {currentStep === "creator-personal-info" && (
                  <KycCreatorPersonalInfoStep
                    kycInfo={kycInfo}
                    nextStep={() => {
                      fetchKycInfo();
                      setCurrentStep("payment");
                      stepperStatus["creator-personal-info"] = "done";
                      stepperStatus["payment"] = "in-progress";
                    }}
                  />
                )}
                {currentStep === "payment" && (
                  <KycCreatorPaymentStep
                    kycInfo={kycInfo}
                    nextStep={() => {
                      fetchKycInfo();
                      setCurrentStep("summary");
                      stepperStatus["payment"] = "done";
                      stepperStatus["summary"] = "in-progress";
                    }}
                  />
                )}
                {currentStep === "summary" && (
                  <KycCreatorSummary
                    editProfessionalDetails={() => {
                      setProgressTracker(
                        kycInfo!.completedSteps,
                        "creator-professional-details"
                      );
                    }}
                    editPersonalInfo={() => {
                      setProgressTracker(
                        kycInfo!.completedSteps,
                        "creator-personal-info"
                      );
                    }}
                    editPayment={() => {
                      setProgressTracker(kycInfo!.completedSteps, "payment");
                    }}
                    kycInfo={kycInfo}
                    onSave={() => {
                      fetchKycInfo();
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
