import "./select-ig-post-modal.scss";
import {
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import InstagramApi from "../../api/social-integration-msvc/instagram.api";
import { IgMediaInfoRespDto } from "../../model/instagram/ig-media-info-resp.dto";
import useSelfAdjustingCards from "../../hooks/useSelfAdjustingCards";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import EmpLoaderV2 from "../shared/emp-loader-v2/emp-loader-v2";
import { empDelay } from "../../utilities/delay";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import StringUtils from "../../utilities/string.util";
import EyeIcon from "../icon/eye-icon";
import PlayIcon from "../icon/play-icon";

export interface SelectIgPostModalRef {
  show: (creatorId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: (igMediaId: string) => void;
}

const SelectIgPost = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const creatorIdRef = useRef<string>();

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const [eventFlag, setEventFlag] = useState(false);

  const [igBasicInfoDto, setIgBasicInfoDto] = useState<IgMediaInfoRespDto>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const delaySet = async () => {
      await empDelay(100);
      setEventFlag((prevFlag: boolean) => {
        return !prevFlag;
      });
    };
    delaySet();
  }, [visible]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchInstagramPosts = async (creatorId: string) => {
    try {
      setLoading(true);
      const resp = await InstagramApi.basicProfile(creatorId);
      setIgBasicInfoDto(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch Instagram Info"
      );
    } finally {
      setLoading(false);
    }
  };

  const show = async (creatorId: string) => {
    creatorIdRef.current = creatorId;
    fetchInstagramPosts(creatorId);
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"lg"}
    >
      {/* This is body */}
      <div className="emp-select-ig-post-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">Select Instagram Post</h2>
          <p className="description">
            Please select one of your Instagram posts to provide proof of work
            for your participation in this task.
          </p>
        </div>
        <EmpLoaderV2 isLoading={loading} />
        <div className="post-section">
          <div className="post-wrapper" ref={resizeDivRef}>
            {loading && (
              <>
                <div className={`empty-card emp-shimmer`}></div>
                <div className={`empty-card emp-shimmer`}></div>
                <div className={`empty-card emp-shimmer`}></div>
                <div className={`empty-card emp-shimmer`}></div>
                <div className={`empty-card emp-shimmer`}></div>
                <div className={`empty-card emp-shimmer`}></div>
              </>
            )}
            {!loading &&
              igBasicInfoDto?.media.map((elem) => {
                return (
                  <div
                    onClick={() => {
                      onSave(elem.id);
                      dismiss();
                    }}
                    key={elem.permalink}
                    className={`ig-card `}
                  >
                    <div className="img-wrapper">
                      <div className="post-type-pill">
                        <EmpPill
                          text={StringUtils.toTitleCase(elem.mediaProductType)}
                          {...PILL_COLORS.primary}
                        />
                      </div>
                      {(elem.mediaType === "IMAGE" ||
                        elem.mediaType === "CAROUSEL_ALBUM") && (
                        <img referrerPolicy="no-referrer"src={elem.mediaUrl} alt={`instagram post`} />
                      )}
                      {elem.mediaType === "VIDEO" && (
                        <video muted loop src={elem.mediaUrl}></video>
                      )}
                    </div>
                    <div className="info-section">
                      <div className="metrics-wrapper">
                        {elem.mediaProductType === "FEED" && (
                          <div className="metric">
                            <EyeIcon
                              backgroundColor={Color.NEUTRAL[600]}
                              size={16}
                            />
                            <span className="metric-value">
                              {elem.impressionCount}
                            </span>
                          </div>
                        )}
                        {elem.mediaProductType === "REELS" && (
                          <div className="metric">
                            <PlayIcon
                              backgroundColor={Color.NEUTRAL[600]}
                              size={16}
                            />
                            <span className="metric-value">
                              {elem.playCount}
                            </span>
                          </div>
                        )}
                        <div className="metric">
                          <HeartIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">{elem.likeCount}</span>
                        </div>
                        <div className="metric">
                          <MessageIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.commentsCount}
                          </span>
                        </div>
                      </div>
                      <p className="caption">{elem.caption}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default SelectIgPost;
