import { motion, useAnimation } from "framer-motion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Color } from "../../../utilities/colors";
import CheckIcon from "../../icon/check-icon";
import "./emp-checkbox.scss";

interface Props {
  id: string;
  labelText?: string;
  initialValue?: boolean;
  className?: string;
  expandedClickZone?: boolean;
  disableControl?: boolean;
  onChange?: (value: boolean) => void;
}

const checkboxVariant = {
  checked: {
    borderColor: Color.PRIMARY[500],
    borderWidth: 9,
    transition: { duration: 0.2 },
  },
  unchecked: { borderColor: Color.PRIMARY[500], borderWidth: 2 },
};

const checkiconVariant = {
  checked: { scale: 1, transition: { duration: 0.2 } },
  unchecked: { scale: 0, transition: { duration: 0.2 } },
};
const checkHoverVariant = {
  hover: { scale: 1, transition: { duration: 0.3 } },
  default: { scale: 0, transition: { duration: 0.3 } },
};

export interface EmpCheckboxRef {
  setChecked: (isChecked: boolean) => void;
}

const EmpCheckbox = forwardRef((props: Props, ref) => {
  const { onChange, className, id, labelText, initialValue } = props;

  // Animation to animate the check and uncheck transitions
  const hoverControl = useAnimation();

  const checkWrapperElemRef = useRef<HTMLDivElement>(null);
  const checkIconElemRef = useRef<HTMLDivElement>(null);
  const checkHoverElemRef = useRef<HTMLDivElement>(null);
  const checkInputElemRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<boolean>(initialValue ?? false);
  const expandedClickZone = props.expandedClickZone ?? false;
  const disableControl = props.disableControl ?? false;
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    setValue(initialValue ?? false);
  }, [initialValue]);

  useImperativeHandle(ref, () => {
    return {
      setChecked,
    };
  });

  const setChecked = (isChecked: boolean) => {
    if (!checkInputElemRef.current) return;

    checkInputElemRef.current.checked = isChecked;
    setValue(isChecked);
  };

  useEffect(() => {
    const hoverVariant = onHover ? "hover" : "default";
    hoverControl.start(hoverVariant);
  }, [onHover, hoverControl]);

  /**
   * Event handler function that is called when the checkbox's state changes.
   *
   * @param e - The event object.
   */
  const onCheckChange = (): void => {
    if (disableControl) return;
    const isChecked = value;
    if (onChange) onChange(!isChecked);
    setValue(!isChecked);
  };

  return (
    <div
      className={`emp-checkbox ${className ? className : ""}`}

      // onClick={()=>{expandedClickZone || onCheckChange()}}
    >
      <input
        ref={checkInputElemRef}
        className="emp-checkbox-input"
        id={id}
        type="checkbox"
        defaultChecked={false}
      />

      <label
        htmlFor={id}
        style={{ width: expandedClickZone ? "100%" : "fit-content" }}
        onClick={() => {
          onCheckChange();
        }}
      >
        <div className="check-wrapper">
          <motion.div
            ref={checkWrapperElemRef}
            className="check-box"
            animate={value ? "checked" : "unchecked"}
            variants={checkboxVariant}
            initial="unchecked"
            onMouseEnter={() => {
              setOnHover(true);
            }}
            onMouseLeave={() => {
              setOnHover(false);
            }}
          >
            <motion.div
              animate={value ? "checked" : "unchecked"}
              variants={checkiconVariant}
              initial="unchecked"
              ref={checkIconElemRef}
            >
              <CheckIcon size={12} backgroundColor="#ffffff" />
            </motion.div>
          </motion.div>
          <motion.div
            ref={checkHoverElemRef}
            animate={hoverControl}
            variants={checkHoverVariant}
            initial={"default"}
            className="check-hover"
          ></motion.div>
        </div>

        <span className={value ? "checked" : ""}>{labelText}</span>
      </label>
    </div>
  );
});
export default EmpCheckbox;
