import "./select-x-tweet-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpLoaderV2 from "../shared/emp-loader-v2/emp-loader-v2";
import { empDelay } from "../../utilities/delay";
import XApi from "../../api/social-integration-msvc/x.api";
import { XProfileWMediaDto } from "../../model/x/x-profile-w-media.dto";
import XTweet from "../views/x-tweet";
import { X_TWEET_FETCH_ORIGIN } from "../../constants/app.constants";

export interface SelectXTweetModalRef {
  show: (creatorId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: (xMediaId: string) => void;
}

const SelectXTweetModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const creatorIdRef = useRef<string>();

  const [xBasicInfoDto, setXBasicInfoDto] = useState<XProfileWMediaDto>();
  const [loading, setLoading] = useState<boolean>(true);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchTiktokPost = async (creatorId: string) => {
    try {
      setLoading(true);
      const resp = await XApi.getProfileWithTweets(
        creatorId,
        X_TWEET_FETCH_ORIGIN.PROOF_OF_WORK_SELECTION
      );
      setXBasicInfoDto(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch Tiktok Info"
      );
    } finally {
      setLoading(false);
    }
  };

  const show = async (creatorId: string) => {
    creatorIdRef.current = creatorId;
    fetchTiktokPost(creatorId);
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      shouldOverflow={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
    >
      {/* This is body */}
      <div className="emp-select-x-tweet-modal">
        <div className="text-content-section">
          <h2 className="title">Select Tweet</h2>
          <p className="description">
            Please select tweet to provide proof of work for your participation
            in this task. If you have multiple tweets, you can do a separate
            submission
          </p>
        </div>
        <EmpLoaderV2 isLoading={loading} />
        <div className="post-section">
          {loading &&
            [1, 2, 3].map(() => {
              return <div className="empty-card emp-shimmer"></div>;
            })}
          {!loading &&
            xBasicInfoDto &&
            xBasicInfoDto.tweets.map((elem, index) => {
              return (
                <XTweet
                  variant="selectable"
                  onSelect={(tweetUrl) => {
                    onSave(tweetUrl);
                    dismiss();
                  }}
                  key={index}
                  xProfile={xBasicInfoDto}
                  xTweet={elem}
                  index={0}
                />
              );
            })}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default SelectXTweetModal;
