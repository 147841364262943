import { FILE_TYPE } from "../constants/app.constants";
import { DeliverableMessageWIdDto } from "../model/campaign/deliverable-message-w-id.dto";

export class ChatUtil {
  static getLastMessage(message: DeliverableMessageWIdDto) {
    if (!message) return "";
    if (message.attachmentType !== "none") {
      if (message.attachmentType === FILE_TYPE.FILE) {
        return "File attachment";
      } else if (
        message.attachmentType === FILE_TYPE.IMAGES ||
        message.attachmentType === FILE_TYPE.IMAGE
      ) {
        return "Sent an image";
      } else if (message.attachmentType === FILE_TYPE.VIDEO) {
        return "Sent a video";
      }
    }
    return message.text ?? "No Messages...";
  }
}
