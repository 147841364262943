import _debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import CampaignApi from "../../../api/campaign-msvc/campaign.api";
import FileIcon from "../../../components/icon/file-icon";
import PlusIcon from "../../../components/icon/plus-icon";
import SearchIcon from "../../../components/icon/search-icon";
import CreateTaskModal, {
  CreateTaskModalRef,
} from "../../../components/modals/create-task-modal";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import { EmpImage } from "../../../components/shared/emp-image/emp-image";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpMultiFilter, {
  MultiFilterOptionRef,
} from "../../../components/shared/emp-multi-filter/emp-multi-filter";
import { EmpTaskCard } from "../../../components/shared/emp-task-card/emp-task-card";
import EmpTextInput from "../../../components/shared/emp-text-input/emp-text-input";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";
import { taskStatusOptions } from "../../../constants/selectConstants";
import { CampaignWOrgDto } from "../../../model/campaign/campaign-w-org.dto";
import { TaskCardDto } from "../../../model/campaign/task-card.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FileUtils from "../../../utilities/file-util";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import "./campaign-summary-view.scss";
import TrashIcon from "../../../components/icon/trash-icon";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import DeleteCampaignModal, {
  DeleteCampaignModalRef,
} from "../../../components/modals/delete-campaign-modal";

interface Props {
  campaign?: CampaignWOrgDto;
}
export const CampaignSummaryView = (props: Props) => {
  const { campaign: campaignProp } = props;
  const [isLoading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<CampaignWOrgDto>();
  const createTaskModalRef = useRef<CreateTaskModalRef>();
  const deleteCampaignModalRef = useRef<DeleteCampaignModalRef>();

  useEffect(() => {
    if (campaignProp) {
      setCampaign(campaignProp);
    }
  }, [campaignProp]);

  const [taskCards, setTaskCards] = useState<TaskCardDto[]>([]);
  const [filteredTaskCards, setFilteredTaskCards] = useState<TaskCardDto[]>([]);
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const statusFilterRef = useRef<string[]>(["recruiting", "ongoing"]);

  const objectiveMultiFilterRef = useRef<MultiFilterOptionRef>();

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
  });

  const fetchTasksInCampaign = useCallback(async (campaignId: string) => {
    try {
      const resp = await CampaignApi.fetchTasksInCampaign(campaignId, "brand");
      setTaskCards(resp.data);
      setFilteredTaskCards(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch campaign");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (campaignProp) {
      fetchTasksInCampaign(campaignProp.id);
    }
  }, [campaignProp, fetchTasksInCampaign]);

  const filterTasks = () => {
    const keyword = form.search.getValue();
    const newlyFilteredRecords = taskCards.filter((elem) => {
      return (
        elem.name.toLowerCase().includes(keyword.toLowerCase()) &&
        statusFilterRef.current.includes(elem.status)
      );
    });
    setFilteredTaskCards(newlyFilteredRecords);
  };

  return (
    <div className="emp-campaign-summary-view" ref={resizeDivRef}>
      {campaign && (
        <>
          <DeleteCampaignModal ref={deleteCampaignModalRef} />
          <CreateTaskModal
            ref={createTaskModalRef}
            onSave={() => {
              fetchTasksInCampaign(campaign.id);
            }}
          />
          <section className="campaign-details-section">
            <img
              className="campaign-image hide-on-mobile"
              alt={campaign.name}
              src={campaign.pictureUrl}
            />
            <div className="show-on-mobile campaign-image-mobile-wrapper">
              <div className="full-banner-anchor">
                <div className="full-banner-wrapper">
                  <div className="banner-shading">
                    <div className="campaign-name-wrapper">
                      <h2 className="campaign-name-lbl">{campaign.name}</h2>
                      <EmpIconButton
                        onSubmit={() => {
                          deleteCampaignModalRef.current?.show(campaign);
                        }}
                        suppressMobileView
                        buttonStyle="secondary"
                        icon={
                          <TrashIcon
                            backgroundColor={Color.NEUTRAL[400]}
                            size={18}
                          />
                        }
                      />
                    </div>
                  </div>
                  <img
                    className="campaign-image-mobile"
                    alt={campaign.name}
                    src={campaign.pictureUrl}
                  />
                </div>
              </div>
            </div>
            <div className="details-wrapper">
              <div className="campaign-name-wrapper hide-on-mobile">
                <h2 className="campaign-name-lbl">{campaign.name}</h2>
                <EmpIconButton
                  onSubmit={() => {
                    deleteCampaignModalRef.current?.show(campaign);
                  }}
                  suppressMobileView
                  buttonStyle="secondary"
                  icon={
                    <TrashIcon backgroundColor={Color.NEUTRAL[400]} size={18} />
                  }
                />
              </div>

              <p className="campaign-description">{campaign.description}</p>
              <div className="info-section">
                <EmpContent
                  label={"CAMPAIGN CREATED BY"}
                  value={
                    <div className="brand-wrapper">
                      <EmpImage
                        className="logo"
                        fallback={EMPTY_PROFILE_IMG_URL}
                        src={campaign.organisation.logo}
                        alt={campaign.organisation.companyName}
                      />
                      <span className="company-name">
                        {campaign.organisation.companyName}
                      </span>
                    </div>
                  }
                />
                <EmpContent
                  label={"CREATED ON"}
                  value={
                    <div className="mt-2">
                      {DateUtil.toReadableDateWithTime(campaign.createdDate)}{" "}
                    </div>
                  }
                />
                <EmpContent
                  label={"LAST UPDATED"}
                  value={
                    <div className="mt-2">
                      {DateUtil.toReadableDateWithTime(campaign.updatedDate)}{" "}
                    </div>
                  }
                />
                {campaign.supportingDocumentUrl && (
                  <div className="uploaded-file-card">
                    <div className="image-section">
                      <FileIcon backgroundColor={Color.NEUTRAL[0]} />
                    </div>
                    <div className="text-section">
                      <span className="file-title">Reference Document</span>
                      <div style={{ marginTop: 4 }}>
                        <EmpLink
                          onSubmit={() => {
                            FileUtils.handleFileDownload(
                              "Reference Document.pdf",
                              campaign.supportingDocumentUrl
                            );
                          }}
                          text={"Download"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="task-details-section">
            <h2>{campaign.name} Tasks</h2>
            <div className="controls-section mt-3">
              <div className="controls-wrapper">
                <div className="search-textbox">
                  <EmpTextInput
                    labelText="Search Tasks"
                    id={"search"}
                    onChange={() => {
                      filterTasks();
                    }}
                    formControl={form.search}
                    placeholder={"Search Tasks..."}
                    leftIconComponent={SearchIcon}
                  />
                </div>
                <EmpMultiFilter
                  ref={objectiveMultiFilterRef}
                  menuItems={taskStatusOptions}
                  controlLabel={"Filter Task Status"}
                  hasAllOption
                  allByDefault
                  onChange={(values: string[]) => {
                    statusFilterRef.current = values;
                    filterTasks();
                  }}
                />
              </div>
              <EmpButton
                className="mb-2"
                rightIcon={PlusIcon}
                text={"Create Task"}
                onSubmit={() => {
                  createTaskModalRef.current?.show(campaign.id, "create");
                }}
                isFullWidth={false}
              />
            </div>
            <div className="task-card-section mt-3">
              {filteredTaskCards.length > 0 &&
                filteredTaskCards.map((elem) => {
                  return (
                    <EmpTaskCard
                      role="brand"
                      showCampaignLink={false}
                      taskCardInfo={elem}
                      key={elem.id}
                      redirectUrl={`task/${elem.id}`}
                    />
                  );
                })}

              {filteredTaskCards.length === 0 && (
                <div className="blank-overlay">
                  <div className="info-wrapper">
                    <img
                      className="empty-img"
                      alt="No talents found"
                      srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                    />
                    <h2 className="header">No Created Tasks</h2>
                    <p className="description">
                      You may create a new task below
                    </p>
                    {campaignProp && (
                      <EmpButton
                        onSubmit={() => {
                          createTaskModalRef.current?.show(
                            campaignProp.id,
                            "create"
                          );
                        }}
                        isFullWidth={false}
                        text={"New Task"}
                      />
                    )}
                  </div>
                </div>
              )}

              {(filteredTaskCards.length === 0 || isLoading) &&
                [1, 2, 3, 4].map((elem) => {
                  return <EmpTaskCard key={elem} mode={"skeleton"} />;
                })}
            </div>
          </section>
        </>
      )}
    </div>
  );
};
