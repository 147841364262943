import "./campaign-guide-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import { useLocation } from "react-router-dom";
import EmpPill from "../shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import ChevronRightIcon from "../icon/chevron-right";
import { Color } from "../../utilities/colors";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import EmpButton from "../shared/emp-button/emp-button";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../icon/chevron-left";
import { CampaignGuideKvVariants } from "../../constants/campaign-guide-kv-variants";
import { empDelay } from "../../utilities/delay";
import ConfettiExplosion from "react-confetti-explosion";
import XCloseIcon from "../icon/x-close-icon";
import { Guide, GuideViewSpecs } from "../../constants/campaign-guide-specs";
import {
  RESTRUCTURE_CAMPAIGN_GUIDE,
  SELLER_PAYMENT_PROCESS_GUIDE,
  BRAND_PAYMENT_PROCESS_GUIDE,
  RECRUITING_TASK_GUIDE,
  ONGOING_TASK_GUIDE,
} from "../../constants/campaign-guide";

interface Props {}

const GUIDE_CONTAINER_VARIANT = {
  show: {
    transition: {
      delay: 0.6,
      staggerChildren: 0.03,
      ease: easeOut,
    },
  },
  hide: {},
};

const GUIDE_CONTENT_VARIANT = {
  show: { opacity: 1, x: 0, transition: { duration: 0.25, ease: easeOut } },
  hide: { opacity: 0, x: 80 },
};
const GUIDE_CHEVRON_VARIANT = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

const SELLER_GUIDE_ITEMS: Guide[] = [
  RESTRUCTURE_CAMPAIGN_GUIDE,
  SELLER_PAYMENT_PROCESS_GUIDE,
  RECRUITING_TASK_GUIDE,
  ONGOING_TASK_GUIDE,
];
const BRAND_GUIDE_ITEMS: Guide[] = [
  RESTRUCTURE_CAMPAIGN_GUIDE,
  BRAND_PAYMENT_PROCESS_GUIDE,
  RECRUITING_TASK_GUIDE,
  ONGOING_TASK_GUIDE,
];
type GUIDE_MAPPING = {
  [key: number]: Guide[];
};

const SELLER_GUIDE_RECOMMENDATION_MAPPING: GUIDE_MAPPING = {
  1: [SELLER_PAYMENT_PROCESS_GUIDE, RECRUITING_TASK_GUIDE],
  2: [RECRUITING_TASK_GUIDE, RESTRUCTURE_CAMPAIGN_GUIDE],
  3: [ONGOING_TASK_GUIDE, SELLER_PAYMENT_PROCESS_GUIDE],
  4: [SELLER_PAYMENT_PROCESS_GUIDE],
};

const BRAND_GUIDE_RECOMMENDATION_MAPPING: GUIDE_MAPPING = {
  1: [BRAND_PAYMENT_PROCESS_GUIDE, RECRUITING_TASK_GUIDE],
  2: [RECRUITING_TASK_GUIDE, RESTRUCTURE_CAMPAIGN_GUIDE],
  3: [ONGOING_TASK_GUIDE, BRAND_PAYMENT_PROCESS_GUIDE],
  4: [BRAND_PAYMENT_PROCESS_GUIDE],
};

export interface CampaignGuideModalRef {
  show: (guide?: Guide) => void;
  dismiss: () => void;
}

const CampaignGuideModal = forwardRef((props: Props, ref) => {
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);

  const userRoleRef = useRef(location.pathname.split("/")[1]);
  const guideRef = useRef(
    userRoleRef.current === "brand" ? BRAND_GUIDE_ITEMS : SELLER_GUIDE_ITEMS
  );

  const guideRecommendationRef = useRef(
    userRoleRef.current === "brand"
      ? BRAND_GUIDE_RECOMMENDATION_MAPPING
      : SELLER_GUIDE_RECOMMENDATION_MAPPING
  );

  const [modalMode, setModalMode] = useState<"menu" | "guide">("menu");
  const [currentGuide, setCurrentGuide] = useState<GuideViewSpecs>();
  const recommendedGuideRef = useRef<Guide[]>([]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (guide?: Guide) => {
    if (guide) {
      guideOnSelected(guide.id, guide.guideViewSpec);
    }
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
  };

  const guideOnSelected = (guideId: number, guideViewSpecs: GuideViewSpecs) => {
    if (guideViewSpecs) {
      recommendedGuideRef.current =
        guideId in guideRecommendationRef.current
          ? guideRecommendationRef.current[guideId]
          : [];
      setModalMode("guide");
      setCurrentGuide(guideViewSpecs);
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      bodyPadding={false}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      shouldOverflow={false}
      onClose={dismiss}
      showHeaderBorder={false}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
      size={"sm"}
    >
      <div className="emp-campaign-guide-modal">
        {modalMode === "menu" && (
          <>
            <section className="emp-modal-common-header">
              <div className="emp-modal-hstack-wrapper">
                <h2>Campaign Guide</h2>
              </div>
              <p className="emp-paragraph mt-2">
                Get up to speed on how to use the platform through our guides!
              </p>
            </section>

            <motion.section
              variants={GUIDE_CONTAINER_VARIANT}
              initial="hide"
              exit="hide"
              animate="show"
              className="guide-content-section"
            >
              {guideRef.current.map((elem) => {
                return (
                  <button
                    key={elem.id}
                    onClick={() => {
                      guideOnSelected(elem.id, elem.guideViewSpec);
                    }}
                    className="emp-button-reset guide-item"
                  >
                    <motion.div
                      variants={GUIDE_CONTENT_VARIANT}
                      className="guide-content-wrapper"
                    >
                      <div className="header-wrapper">
                        <h3>{elem.header}</h3>
                        {elem.isRecommended && (
                          <EmpPill
                            text={"Recommended"}
                            {...PILL_COLORS.primary}
                          />
                        )}
                      </div>
                      <p className="emp-paragraph mt-1">{elem.description}</p>
                    </motion.div>
                    <motion.div variants={GUIDE_CHEVRON_VARIANT}>
                      <ChevronRightIcon backgroundColor={Color.NEUTRAL[500]} />
                    </motion.div>
                  </button>
                );
              })}
            </motion.section>
          </>
        )}
        {modalMode === "guide" && currentGuide && (
          <GuideView
            recommendedGuides={recommendedGuideRef.current}
            onSwitchGuide={(guide) => setCurrentGuide(guide.guideViewSpec)}
            onBack={() => {
              setModalMode("menu");
            }}
            onClose={dismiss}
            guideViewSpecs={currentGuide}
          />
        )}
      </div>
      <div></div>
    </EmpModal>
  );
});

export default CampaignGuideModal;

interface GuideViewProps {
  guideViewSpecs: GuideViewSpecs;
  recommendedGuides: Guide[];
  onSwitchGuide: (guide: Guide) => void;
  onBack: () => void;
  onClose: () => void;
}

export const GuideView = (props: GuideViewProps) => {
  const {
    guideViewSpecs: guide,
    onBack,
    recommendedGuides,
    onSwitchGuide,
  } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const guideSectionRef = useRef<HTMLDivElement>(null);

  const isValid = useMemo(() => {
    return stepIndex + 1 <= guide.guideItems.length;
  }, [guide, stepIndex]);

  const [guideViewMode, setGuideViewMode] = useState<"guide" | "complete">(
    "guide"
  );
  const [doneImageSrc, setDoneImageSrc] = useState("");
  const [showBottomShadow, setShowBottomShadow] = useState(false);
  const [showTopShadow, setShowTopShadow] = useState(false);

  const renderStaticElemShadows = async (layoutChanged?: boolean) => {
    if (layoutChanged) {
      await empDelay(100);
    }
    if (guideSectionRef.current) {
      const element = guideSectionRef.current;
      const scrollable = element.scrollHeight > element.clientHeight;
      const scrollTop = element.scrollTop > 0;
      const sClientHeight = element.scrollHeight - element.scrollTop;
      const scrollBottom = Math.abs(sClientHeight - element.clientHeight) < 5;
      let TopShadow = scrollTop && scrollable;
      let BottomShadow = !scrollBottom && scrollable;
      setShowBottomShadow(BottomShadow);
      setShowTopShadow(TopShadow);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDoneImageSrc(
        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/checkmark-img.png"
      );
    };
    img.src =
      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/checkmark-img.png";
  }, []);

  useEffect(() => {
    renderStaticElemShadows(true);
    const guideView =
      stepIndex >= guide.guideItems.length ? "complete" : "guide";
    setGuideViewMode(() => {
      return guideView;
    });
  }, [stepIndex, guide]);

  useEffect(() => {
    const element = guideSectionRef.current;
    if (element) {
      element.addEventListener("scroll", () => renderStaticElemShadows());
      return () => {
        element.removeEventListener("scroll", () => renderStaticElemShadows());
      };
    }
  }, []);

  return (
    <div className="guide-view">
      <div
        className={`guide-view-header ${
          showTopShadow ? "added-drop-shadow" : ""
        }`}
      >
        <EmpIconButton
          onSubmit={onBack}
          buttonStyle="secondary"
          icon={
            <ChevronLeftIcon size={18} backgroundColor={Color.NEUTRAL[500]} />
          }
        />
        <div className="guide-view-header-wrapper">
          <span className="guide-title">{guide.guideName}</span>
          {isValid && guideViewMode === "guide" && (
            <h3 className="slide-title mt-1">
              {guide.guideItems[stepIndex].title}
            </h3>
          )}
          {guideViewMode === "complete" && (
            <h3 className="slide-title mt-1">Complete</h3>
          )}
        </div>
        <div className="guide-view-step-wrapper"></div>
      </div>
      {guideViewMode === "guide" && (
        <section ref={guideSectionRef} className="guide-section">
          {isValid && (
            <>
              <div className="guide-view-visuals-wrapper">
                <div className="guide-progress-wrapper">
                  <motion.div
                    initial={{ scaleX: 0 }}
                    animate={{
                      scaleX: (stepIndex + 1) / guide.guideItems.length,
                    }}
                    transition={{ duration: 0.8, ease: easeOut }}
                    className="progress"
                  ></motion.div>
                </div>
                {guide.guideItems.map((elem, index) => {
                  return (
                    <AnimatePresence>
                      {index === stepIndex && elem.visualContent}
                    </AnimatePresence>
                  );
                })}
              </div>

              {guide.guideItems.map((elem, index) => {
                return (
                  <>
                    {index === stepIndex && (
                      <motion.div
                        variants={
                          CampaignGuideKvVariants.GUIDE_DESCRIPTION_CONTAINER
                        }
                        initial="hideRight"
                        animate="entryRight"
                      >
                        <motion.h3
                          className="slide-header mb-2"
                          variants={
                            CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD
                          }
                        >
                          {elem.title}
                        </motion.h3>
                        {elem.description}
                      </motion.div>
                    )}
                  </>
                );
              })}
            </>
          )}
        </section>
      )}

      {guideViewMode === "complete" && (
        <motion.section
          className="guide-complete-section"
          variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CONTAINER}
          initial="hideRight"
          animate="entryRight"
        >
          <ConfettiExplosion
            force={0.4}
            duration={2200}
            particleCount={30}
            width={400}
            zIndex={100}
          />
          <motion.img
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="checkmark-img"
            alt="Guide complete"
            src={doneImageSrc}
          />
          <motion.h2
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="guide-complete-header"
          >
            Guide Complete
          </motion.h2>
          <motion.p
            variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
            className="emp-paragraph guide-complete-paragraph"
          >
            Goodjob! You’ve come to the end of this guide, you may refer to the
            other links to checkout our other guides.
          </motion.p>

          <div className="recommended-guide-section">
            {recommendedGuides.map((elem) => {
              return (
                <motion.button
                  onClick={() => {
                    onSwitchGuide(elem);
                    setStepIndex(0);
                  }}
                  variants={CampaignGuideKvVariants.GUIDE_DESCRIPTION_CHILD}
                  className="emp-button-reset guide-item bordered"
                >
                  <div className="guide-content-wrapper">
                    <div className="header-wrapper">
                      <h3>{elem.header}</h3>
                      {elem.isRecommended && (
                        <EmpPill
                          text={"Recommended"}
                          {...PILL_COLORS.primary}
                        />
                      )}
                    </div>
                    <p className="emp-paragraph mt-1">{elem.description}</p>
                  </div>
                  <ChevronRightIcon backgroundColor={Color.NEUTRAL[500]} />
                </motion.button>
              );
            })}
          </div>
        </motion.section>
      )}

      <div
        className={`guide-view-btn-section ${
          showBottomShadow ? "added-drop-shadow" : ""
        }`}
      >
        {stepIndex > 0 && (
          <EmpButton
            buttonStyle="secondary"
            isFullWidth={false}
            leftIcon={ChevronLeftIcon}
            onSubmit={() => {
              setStepIndex((prev) => prev - 1);
            }}
            text={"Previous"}
          />
        )}
        {stepIndex < guide.guideItems.length && (
          <EmpButton
            buttonStyle="primary"
            isFullWidth={false}
            rightIcon={ChevronRightIcon}
            onSubmit={() => {
              setStepIndex((prev) => prev + 1);
            }}
            text={"Next"}
          />
        )}
        {stepIndex >= guide.guideItems.length && (
          <EmpButton
            buttonStyle="secondary"
            isFullWidth={false}
            onSubmit={onBack}
            text={"Close"}
          />
        )}
        {stepIndex >= guide.guideItems.length && (
          <EmpButton
            buttonStyle="primary"
            isFullWidth={false}
            onSubmit={onBack}
            text={"View Guides"}
          />
        )}
      </div>
    </div>
  );
};
