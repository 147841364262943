import "./instagram-account-selection-modal.scss";
import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpException from "../../../exception/empException";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import {
  FacebookPageSpec,
  InstagramConnectedAccountDto,
} from "../../../model/social-media/instagram-connected-account.dto";
import FacebookIcon from "../../../components/icon/facebook-icon";
import { Color } from "../../../utilities/colors";
import { FormattedMessage } from "react-intl";
import { EMPTY_PROFILE_IMG_URL } from "../../../constants/app.constants";

export interface InstagramAccountSelectionModalRef {
  show: (
    facebookInstagramConnectedPage: InstagramConnectedAccountDto,
    platform: string
  ) => void;
  dismiss: () => void;
}

interface Props {
  onSave: (fbPageSpec: FacebookPageSpec, platform: string) => void;
  onDismiss: () => void;
}

const InstagramAccountSelectionModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [facebookInstagramConnectedPage, setFacebookInstagramConnectedPage] =
    useState<InstagramConnectedAccountDto>();
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const platformRef = useRef<string>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    facebookInstagramConnectedPage: InstagramConnectedAccountDto,
    platform: string
  ) => {
    setFacebookInstagramConnectedPage(facebookInstagramConnectedPage);
    platformRef.current = platform;
    setVisible(true);
  };
  const dismiss = () => {
    setVisible(false);
    setHasSelected(false);
    setSelectedIndex(-1);
    setFacebookInstagramConnectedPage(undefined);
  };

  const pageItemOnClick = (index: number) => {
    setSelectedIndex(index);
    setHasSelected(true);
  };

  const onSave = () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!facebookInstagramConnectedPage)
        throw new EmpException("Unable to get page spec");
      const spec = facebookInstagramConnectedPage.data[selectedIndex];
      props.onSave(spec, platformRef.current!);
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={<FormattedMessage id="instagramAccountSelection_header" />}
      showFooter={false}
      showFooterBorder={false}
      onClose={() => {
        dismiss();
      }}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-instagram-account-modal">
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="instagramAccountSelection_title" />
          </h2>
          <p className="description">
            <FormattedMessage id="instagramAccountSelection_desc" />
          </p>
        </div>

        {/* Facebook Pages Mapper */}

        {facebookInstagramConnectedPage && (
          <div className="pages-wrapper">
            {facebookInstagramConnectedPage.data.map((elem, index) => {
              if (elem.connected_instagram_account) {
                return (
                  <Fragment key={elem.id}>
                    <div className="page-name-wrapper">
                      <FacebookIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span className="page-name-lbl">{elem.name}</span>
                    </div>

                    <div
                      onClick={() => {
                        pageItemOnClick(index);
                      }}
                      key={elem.id}
                      className={`page-item ${
                        index === selectedIndex ? "active" : ""
                      }`}
                    >
                      <div className="left-elem">
                        <img
                          className="picture"
                          alt="connected ig account icon"
                          src={
                            elem.connected_instagram_account
                              .profile_picture_url || EMPTY_PROFILE_IMG_URL
                          }
                        />
                        <div className="text-section">
                          <span className="name">
                            {elem.connected_instagram_account.name}
                          </span>
                          <span className="about">
                            <FormattedMessage id="instagramAccountSelection_followers" />
                            : {elem.connected_instagram_account.followers_count}
                          </span>
                        </div>
                      </div>
                      <div className="right-elem">
                        <div
                          className={`placeholder ${
                            index === selectedIndex ? "active" : ""
                          }`}
                        >
                          {index === selectedIndex && (
                            <div className="knob"></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        )}
        <div className="footer-btn-wrapper mt-4">
          <div className="mr-2">
            <EmpButton
              isFullWidth={false}
              buttonStyle={"secondary"}
              text={<FormattedMessage id="cta_cancel" />}
              onSubmit={() => {
                props.onDismiss();
                dismiss();
              }}
            />
          </div>

          <EmpButton
            isFullWidth={false}
            disabled={!hasSelected}
            ref={saveBtnRef}
            text={
              <FormattedMessage id="instagramConnectInfoModal_bindAccountBtn" />
            }
            onSubmit={onSave}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default InstagramAccountSelectionModal;
