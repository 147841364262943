import { useEffect, useRef, useState } from "react";
import RefreshIcon from "../../../components/icon/refresh-icon";
import {
  BrandRecruitmentKanban,
  BrandRecruitmentKanbanRef,
} from "../../../components/kanban/recruitment-kanban/brand-recruitment-kanban";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import "./brand-task-recruitment-progress-wall-view.scss";
import { LatestNegotiationSingleDto } from "../../../model/campaign/latest-negotiation-single.dto";

interface Props {
  task?: TaskDto;
  brandLatestNegotiation: LatestNegotiationSingleDto;
  fetchTask: () => void;
  fetchLatestNegotiation: () => void;
}

export const BrandTaskRecruitmentProgressWallView = (props: Props) => {
  const { task: taskProp, fetchLatestNegotiation } = props;
  const [task, setTask] = useState<TaskDto>();
  const kanbanRef = useRef<BrandRecruitmentKanbanRef>();

  const [brandLatestNegotiation, setBrandLatestNegotiation] =
    useState<LatestNegotiationSingleDto>(props.brandLatestNegotiation);
  // Wrapper element to compute expandable size
  useEffect(() => {
    if (props.brandLatestNegotiation) {
      setBrandLatestNegotiation(props.brandLatestNegotiation);
      kanbanRef.current?.setKanbanLoadingState(false);
    }
  }, [props.brandLatestNegotiation]);

  // Wrapper element to compute expandable size
  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
    }
  }, [taskProp]);

  return (
    <div className="emp-brand-task-recruitment-view">
      {task && (
        <>
          <section className="task-details-section">
            <div className="task-name-wrapper">
              <div className="info-wrapper">
                <h2 className="section-label">Recruitment Progress Board </h2>
                <EmpIconButton
                  onSubmit={() => {
                    fetchLatestNegotiation();
                    kanbanRef.current?.setKanbanLoadingState(true);
                  }}
                  buttonStyle={"secondary"}
                  icon={<RefreshIcon backgroundColor={Color.NEUTRAL[500]} />}
                />
              </div>
            </div>
            {/* <p className="description-lbl mt-2">Recruit the first creator or agency to your task: Aquascaping Instagram task. Click here more information about the recruitment process</p> */}
          </section>
          <section className="kanban-section">
            <BrandRecruitmentKanban
              task={task}
              ref={kanbanRef}
              brandLatestNegotiation={brandLatestNegotiation}
              fetchLatestNegotiation={() => {
                fetchLatestNegotiation();
                kanbanRef.current?.setKanbanLoadingState(true);
              }}
            />
          </section>
        </>
      )}
    </div>
  );
};
