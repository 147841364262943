import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import EmpAnimatedLoader from "../../components/shared/emp-animated-loader/emp-animated-loader";
import EmpBreadcrumb, {
  EmpBreadcrumbSpecs,
} from "../../components/shared/emp-breadcrumb/emp-breadcrumb";
import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import { TASK_STATUS } from "../../constants/app.constants";
import { AppContext } from "../../context/app.context";
import EmpException from "../../exception/empException";
import { LatestNegotiationSingleDto } from "../../model/campaign/latest-negotiation-single.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../utilities/user-utils";
import "./brand-task-details-page.scss";
import { BrandEvidenceView } from "./views/brand-evidence-view";
import { BrandOngoingTaskSummaryView } from "./views/brand-ongoing-task-summary-view";
import { BrandReviewDraftView } from "./views/brand-review-draft-view";
import { BrandTaskChatView } from "./views/brand-task-chat-view";
import { BrandTaskOngoingProgressWall } from "./views/brand-task-ongoing-progress-wall-view";
import { BrandTaskRecruitmentProgressWallView } from "./views/brand-task-recruitment-progress-wall-view";
import { BrandRecruitingTaskSummaryView } from "./views/brand-recruiting-task-summary-view";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import { BrandDeletedTaskView } from "./views/brand-deleted-task-view";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import {
  ONGOING_TASK_GUIDE,
  RECRUITING_TASK_GUIDE,
} from "../../constants/campaign-guide";
import HelpCircleIcon from "../../components/icon/help-circle-icon";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "../../components/modals/campaign-guide-modal";
import { BrandTaskPaymentView } from "./views/brand-task-payment-view";
import { BrandCampaignAnalyticsView } from "./views/brand-campaign-analytics-view";

const breadcrumbsData = [{ link: "/brand/campaigns", text: "Campaign" }];

export const TaskRecruitmentMenuBar = [
  {
    display: "Summary",
    text: "Summary",
    isSelected: false,
  },
  {
    display: "Progress Wall",
    text: "Progress Wall",
    isSelected: false,
  },
];

export const TaskOngoingMenuBar = [
  {
    display: "Summary",
    text: "Summary",
    isSelected: false,
  },
  {
    display: "Progress Wall",
    text: "Progress Wall",
    isSelected: false,
  },
  {
    display: "Chat",
    text: "Chat",
    isSelected: false,
  },
  {
    display: "Review Drafts",
    text: "Review Drafts",
    isSelected: false,
  },
  {
    display: "Proof of Work",
    text: "View Proof of Work",
    isSelected: false,
  },
  {
    display: "Analytics",
    text: "Analytics",
    isSelected: false,
  },
  {
    display: "Payment",
    text: "Payment",
    isSelected: false,
  },
];

export const BrandTaskDetailsPage = () => {
  const location = useLocation();
  const { taskId, campaignId } = useParams();
  const [tabs, setTabs] = useState<EmpTab[]>();
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const { user: userContext } = useContext(AppContext);
  const userRef = useRef<UserDto>();
  const [task, setTask] = useState<TaskDto>();
  const [breadcrumbs, setBreadcrumbs] =
    useState<EmpBreadcrumbSpecs[]>(breadcrumbsData);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [brandLatestNegotiation, setBrandLatestNegotiation] =
    useState<LatestNegotiationSingleDto>();

  const [isAnimatedLoading, setAnimatedLoading] = useState(false);

  useEffect(() => {
    if (!tabs) return;
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    if (!tabs) return;
    setInitialTabs(tabs);
  }, [location]);

  const setInitialTabs = (tab: EmpTab[]) => {
    let fragment = window.location.hash.slice(1);
    let modifiedTab = tab;
    // unset all tabs
    modifiedTab.forEach((elem) => (elem.isSelected = false));
    if (fragment) {
      fragment = fragment.replaceAll("%20", " ");
      const fragmentHash = tab.find((elem) => elem.text === fragment);
      if (!fragmentHash)
        modifiedTab = tab.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      else fragmentHash.isSelected = true;
    } else {
      modifiedTab = tab.map((tab, index) =>
        index === 0 ? { ...tab, isSelected: true } : tab
      );
    }
    setTabs([...modifiedTab]);
  };

  /** Fetch latest negotiation objects for this page. */
  const fetchTaskNegotiationStatus = useCallback(async () => {
    try {
      if (!taskId || !campaignId) throw new EmpException("No task id");
      // Fetch user details from cache or from the server
      if (!userRef.current) {
        const user = await UserUtils.fetchUser(userContext);
        userRef.current = user;
      }
      // Need to see the user role in this case;
      if (
        !userRef.current.organisation ||
        userRef.current.organisation.length === 0
      ) {
        throw new EmpException("Not an organisation");
      }
      const brandOrgId = userRef.current.organisation[0].id;
      const resp = await NegotiationApi.getLatestNegotiationByBrandOrgId(
        brandOrgId,
        campaignId,
        taskId
      );
      setBrandLatestNegotiation(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch seller latest negotiation"
      );
    }
  }, [taskId, campaignId, userContext]);

  /** Fetching campaign task specifications */
  const fetchTaskById = useCallback(async () => {
    try {
      if (!taskId || !campaignId) throw new EmpException("No task id");
      const resp = await CampaignApi.fetchTaskById(campaignId, taskId);
      const task = resp.data;

      if (task.status === TASK_STATUS.RECRUITING) {
        setInitialTabs(TaskRecruitmentMenuBar);
      } else if (
        [TASK_STATUS.ONGOING, TASK_STATUS.COMPLETED].includes(task.status)
      ) {
        setInitialTabs(TaskOngoingMenuBar);
      }
      const newBreadcrumbs = [...breadcrumbsData];
      newBreadcrumbs.push({
        link: `/brand/campaign-details/${campaignId}#Task`,
        text: task.campaign.name,
      });
      newBreadcrumbs.push({
        link: `/brand/campaign-details/${campaignId}/task/${taskId}`,
        text: task.name,
      });
      setBreadcrumbs(newBreadcrumbs);
      setTask(task);
      await empDelay(500);
      setLoading(false);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch task");
    }
  }, [taskId, campaignId]);

  useEffect(() => {
    fetchTaskById();
    fetchTaskNegotiationStatus();
  }, [fetchTaskById, fetchTaskNegotiationStatus]);

  return (
    <>
      <div className="emp-page-wrapper emp-responsive">
        <CampaignGuideModal ref={campaignGuideModalRef} />
        <EmpAnimatedLoader isVisible={isAnimatedLoading} />

        <div className="bg-tint"></div>
        <div
          className="emp-page-content-no-padding emp-task-details-page"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: ["Progress Wall"].includes(selectedTab ?? "")
              ? "hidden"
              : "initial",
          }}
        >
          <div className="mt-3">
            <EmpBreadcrumb
              items={breadcrumbs}
              loading={loading}
              numLoadingItem={3}
            />
          </div>
          <div className="header-wrapper page-header-wrapper">
            {task && task.status !== TASK_STATUS.DELETED && (
              <>
                <h1 className="page-header">{task.name}</h1>
                {task.status === TASK_STATUS.RECRUITING && (
                  <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
                )}
                {task.status === TASK_STATUS.ONGOING && (
                  <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
                )}
                {task.status === TASK_STATUS.COMPLETED && (
                  <EmpPill text={"Completed"} {...PILL_COLORS.green} />
                )}
                <EmpIconButton
                  onSubmit={() => {
                    let guide = undefined;
                    if (task.status === TASK_STATUS.RECRUITING) {
                      guide = RECRUITING_TASK_GUIDE;
                    } else if (task.status === TASK_STATUS.ONGOING) {
                      guide = ONGOING_TASK_GUIDE;
                    }
                    campaignGuideModalRef.current?.show(guide);
                  }}
                  buttonStyle="secondary"
                  suppressMobileView
                  icon={
                    <HelpCircleIcon
                      backgroundColor={Color.NEUTRAL[400]}
                      size={18}
                    />
                  }
                />
              </>
            )}
          </div>
          {task && task?.status === TASK_STATUS.RECRUITING && (
            <>
              {tabs && (
                <EmpSecondaryTabBar
                  tabs={tabs}
                  onChange={(tabLabel: string) => {
                    setSelectedTab(tabLabel);
                    fetchTaskNegotiationStatus();
                    window.location.hash = tabLabel;
                  }}
                />
              )}
              <div
                className="task-view-wrapper"
                style={{
                  flex: 1,
                  overflow:
                    selectedTab === "Progress Wall" ? "hidden" : "initial",
                }}
              >
                {selectedTab === "Summary" && brandLatestNegotiation && (
                  <BrandRecruitingTaskSummaryView
                    fetchTask={fetchTaskById}
                    task={task}
                    brandLatestNegotiation={brandLatestNegotiation}
                    fetchLatestNegotiation={fetchTaskNegotiationStatus}
                  />
                )}

                {selectedTab === "Progress Wall" && brandLatestNegotiation && (
                  <BrandTaskRecruitmentProgressWallView
                    fetchLatestNegotiation={fetchTaskNegotiationStatus}
                    fetchTask={fetchTaskById}
                    task={task}
                    brandLatestNegotiation={brandLatestNegotiation}
                  />
                )}
              </div>
            </>
          )}
          {task &&
            [TASK_STATUS.ONGOING, TASK_STATUS.COMPLETED].includes(
              task.status
            ) && (
              <>
                {tabs && (
                  <EmpSecondaryTabBar
                    tabs={tabs}
                    onChange={(tabLabel: string) => {
                      setSelectedTab(tabLabel);
                      window.location.hash = tabLabel;
                    }}
                  />
                )}
                <div
                  className="task-view-wrapper"
                  style={{
                    flex: 1,
                    overflow:
                      selectedTab === "Progress Wall" ? "hidden" : "initial",
                  }}
                >
                  {selectedTab === "Summary" && (
                    <BrandOngoingTaskSummaryView
                      fetchTask={fetchTaskById}
                      task={task}
                    />
                  )}

                  {selectedTab === "Progress Wall" && (
                    <BrandTaskOngoingProgressWall
                      fetchTask={() => {}}
                      task={task}
                    />
                  )}
                  {selectedTab === "Chat" && <BrandTaskChatView task={task} />}
                  {selectedTab === "Review Drafts" && userRef.current && (
                    <BrandReviewDraftView task={task} user={userRef.current} />
                  )}
                  {selectedTab === "View Proof of Work" && userRef.current && (
                    <BrandEvidenceView task={task} user={userRef.current} />
                  )}
                  {selectedTab === "Analytics" && userRef.current && (
                    <BrandCampaignAnalyticsView
                      task={task}
                      user={userRef.current}
                    />
                  )}
                  {selectedTab === "Payment" && userRef.current && (
                    <BrandTaskPaymentView task={task} user={userRef.current} />
                  )}
                </div>
              </>
            )}

          {task && task.status === TASK_STATUS.DELETED && (
            <BrandDeletedTaskView task={task} />
          )}
        </div>
      </div>
    </>
  );
};
