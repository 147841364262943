import "./review-draft-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { TaskDto } from "../../model/campaign/task.dto";
import { ExtendedDraftDto } from "../../model/campaign/extended-draft.dto";
import { DateUtil } from "../../utilities/date";
import EmpContent from "../shared/emp-content/emp-content";
import DraftApi from "../../api/campaign-msvc/draft.api";
import { ReviewDraftDto } from "../../model/campaign/review-draft.dto";
import ToastUtils from "../../utilities/toast-utils";
import InfoCircleIcon from "../icon/info-circle-icon";
import CreateDisputeModal, {
  CreateDisputeModalRef,
} from "./create-dispute-modal";
import { useCountGridColumns } from "../../hooks/useCountGridColumns";
import Slider from "react-slick";
import { ReactKeyUtil } from "../../utilities/react-key.util";
import { FILE_TYPE } from "../../constants/app.constants";
import ChevronLeftIcon from "../icon/chevron-left";
import ChevronRightIcon from "../icon/chevron-right";
import FileUtils from "../../utilities/file-util";
import { el } from "@fullcalendar/core/internal-common";
import StringUtils from "../../utilities/string.util";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import FileDownloadIcon from "../icon/file-download-icon";

export interface ReviewDraftModalRef {
  show: (draft: ExtendedDraftDto, task: TaskDto) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const ReviewDraftModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [deliverableDraft, setDeliverableDraft] = useState<ExtendedDraftDto>();
  const [task, setTask] = useState<TaskDto>();
  const createDisputeModalRef = useRef<CreateDisputeModalRef>();
  const [sliderDotIndex, setSliderDotIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const [form, setForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [new RequiredValidator()]),
  });

  const approveBtnRef = useRef<EmpButtonRef>();
  const rejectBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (draft: ExtendedDraftDto, task: TaskDto) => {
    setDeliverableDraft(draft);
    setTask(task);
    setVisible(true);
  };
  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  const reviewDraft = async (decision: string) => {
    try {
      if (!task || !deliverableDraft) return;
      approveBtnRef.current?.setButtonState("loading");
      rejectBtnRef.current?.setButtonState("loading");

      const request: ReviewDraftDto = {
        taskId: deliverableDraft.taskId,
        deliverableId: deliverableDraft.deliverableId,
        decision: decision,
        brandResponse: form.remarks.getValue(),
      };
      const resp = await DraftApi.reviewDraft(
        deliverableDraft.draft!.id,
        task.campaign.organisationId,
        request
      );
      if (resp.data.status === "success") {
        if (decision === "approve") {
          ToastUtils.success(
            "Draft Approved",
            "The creator will be notified to proceed."
          );
        }
        if (decision === "reject") {
          ToastUtils.success(
            "Draft Rejected",
            "The creator will resubmit a new draft"
          );
        }

        props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send attachment"
      );
    } finally {
      approveBtnRef.current?.setButtonState("default");
      rejectBtnRef.current?.setButtonState("default");
    }
  };

  const handleSliderNavigation = (direction: "left" | "right") => {
    if (!sliderRef.current) {
      return;
    }
    if (direction === "left") {
      sliderRef.current.slickPrev();
    } else if (direction === "right") {
      sliderRef.current.slickNext();
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-review-draft-modal">
        <CreateDisputeModal
          onSave={() => {
            onSave();
          }}
          ref={createDisputeModalRef}
        />

        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section mb-2">
          <h2 className="title">Review Creator's Draft Submission</h2>
          {deliverableDraft && (
            <p className="emp-paragraph mt-2">
              Approve or reject the draft that was submitted on{" "}
              <span className="emp-highlighted">
                {DateUtil.toReadableDateWithTime(
                  deliverableDraft.draft!.createdDate
                )}
              </span>
            </p>
          )}
        </div>
        {deliverableDraft && (
          <section className="acknowledgement-wrapper mb-3">
            {deliverableDraft.revisionsLeft === 0 && (
              <div className="card danger">
                <div className="intro-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={16}
                    strokeWidth={2.5}
                    backgroundColor={Color.RED[500]}
                  />
                  <span className="description-text danger">
                    You've used all your revision options. If the work is still
                    not meeting your expectations, you may raise a dispute here.
                  </span>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <EmpButton
                    onSubmit={() => {
                      createDisputeModalRef.current?.show(deliverableDraft);
                    }}
                    className="mt-1"
                    buttonStyle="secondary"
                    isFullWidth={false}
                    buttonHeight="sm"
                    text={"Raise Dispute"}
                  />
                </div>
              </div>
            )}
            {deliverableDraft.revisionsLeft > 0 && (
              <div className="card">
                <div className="intro-wrapper">
                  <InfoCircleIcon
                    top={2}
                    size={16}
                    strokeWidth={2.5}
                    backgroundColor={Color.PRIMARY[600]}
                  />
                  <span className="description-text">
                    You have {deliverableDraft?.revisionsLeft} revision
                    {deliverableDraft?.revisionsLeft > 1 ? "s" : ""} remaining.
                    Please provide precise requirements and clearly communicate
                    the necessary changes to the creator or agency.
                  </span>
                </div>
              </div>
            )}
          </section>
        )}
        <div className="content-section">
          {deliverableDraft?.draft?.draftAttachments &&
            deliverableDraft?.draft?.draftAttachments.length > 0 && (
              <>
                <div className="slider-wrapper">
                  {deliverableDraft.draft.draftAttachments.length > 1 && (
                    <>
                      <button
                        className="emp-button-reset sm-left-arrow-wrapper"
                        onClick={() => {
                          handleSliderNavigation("left");
                        }}
                      >
                        <ChevronLeftIcon backgroundColor={Color.NEUTRAL[0]} />
                      </button>
                      <button
                        className="emp-button-reset sm-right-arrow-wrapper"
                        onClick={() => {
                          handleSliderNavigation("right");
                        }}
                      >
                        <ChevronRightIcon backgroundColor={Color.NEUTRAL[0]} />
                      </button>
                    </>
                  )}

                  <Slider
                    dots={false}
                    arrows={false}
                    infinite={true}
                    adaptiveHeight={true}
                    speed={500}
                    beforeChange={(_, newIndex) => {
                      setSliderDotIndex(newIndex);
                    }}
                    slidesToShow={1}
                    ref={sliderRef}
                    slidesToScroll={1}
                  >
                    {deliverableDraft.draft.draftAttachments.map((elem) => {
                      return (
                        <div key={elem.id}>
                          <div className="details-wrapper">
                            <div className="inner-wrapper">
                              <span className="file-header">
                                {StringUtils.capitalizeWords(elem.fileType)}{" "}
                                Submission
                              </span>
                              <div className="bottom-row">
                                <span className="filename">
                                  {elem.attachmentName}
                                </span>
                                <span>
                                  (
                                  {FileUtils.convertBytesToReadableSize(
                                    elem.attachmentSize
                                  )}
                                  )
                                </span>
                              </div>
                            </div>
                            <EmpIconButton
                              onSubmit={() => {
                                FileUtils.handleFileDownload(
                                  elem.attachmentName,
                                  elem.attachmentUrl
                                );
                              }}
                              buttonStyle="secondary"
                              icon={
                                <FileDownloadIcon
                                  size={16}
                                  backgroundColor={Color.NEUTRAL[500]}
                                />
                              }
                            />
                          </div>
                          <div className="attachment-wrapper">
                            {elem.fileType === FILE_TYPE.IMAGE && (
                              <img
                                className="attachment-img"
                                alt="Uploaded Draft"
                                src={elem.attachmentUrl}
                              />
                            )}
                            {elem.fileType === FILE_TYPE.VIDEO && (
                              <video
                                controls
                                autoPlay
                                src={elem.attachmentUrl}
                                className="attachment-video"
                              />
                            )}
                            {elem.fileType === FILE_TYPE.FILE && (
                              <div className="attachment-file">
                                <span className="file-extension">
                                  {FileUtils.getFileExtension(
                                    elem.attachmentName
                                  )}
                                </span>
                                <span className="file-name mt-1">
                                  {elem.attachmentName}
                                </span>
                                <span className="file-name mt-1">
                                  {FileUtils.convertBytesToReadableSize(
                                    elem.attachmentSize
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {deliverableDraft.draft.draftAttachments.length > 1 && (
                  <div className="sm-pagination-dots-wrapper">
                    {deliverableDraft.draft.draftAttachments.map(
                      (elem, index) => {
                        return (
                          <div
                            key={elem.id}
                            className="sm-dot-clickable-area"
                            onClick={() => {
                              if (
                                !sliderRef.current ||
                                sliderDotIndex === index
                              )
                                return;
                              sliderRef.current.slickGoTo(index);
                            }}
                          >
                            <div
                              className={`sm-dot ${
                                sliderDotIndex === index ? "active" : ""
                              }`}
                            ></div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </>
            )}

          {deliverableDraft && (
            <div className="mt-3">
              <EmpContent
                label={"REMARKS"}
                value={
                  <p
                    className="emp-content-value-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: StringUtils.stringToHtmlRepresentation(
                        deliverableDraft.draft?.remarks ?? "No Remarks"
                      ),
                    }}
                  ></p>
                }
              />
            </div>
          )}
        </div>
        <hr className="mv-4" />
        <div className="ph-5">
          <EmpTextInput
            id="remarks"
            labelText="Your Remarks"
            required
            formControl={form.remarks}
            multiline
            textAreaAdaptiveHeight
            rows={3}
            placeholder="Enter your remarks..."
          />
        </div>

        {deliverableDraft && (
          <div
            className="mt-6 ph-5"
            style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
          >
            <EmpButton
              isFullWidth={false}
              buttonStyle={"secondary"}
              text={"Cancel"}
              onSubmit={() => {
                dismiss();
              }}
            />
            <EmpButton
              isFullWidth={false}
              text={"Reject"}
              buttonStyle="danger"
              disabled={deliverableDraft?.revisionsLeft === 0}
              ref={rejectBtnRef}
              onSubmit={() => {
                reviewDraft("reject");
              }}
            />
            <EmpButton
              isFullWidth={false}
              text={"Approve"}
              buttonStyle="success"
              ref={approveBtnRef}
              onSubmit={() => {
                reviewDraft("approve");
              }}
            />
          </div>
        )}
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ReviewDraftModal;
