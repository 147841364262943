import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const TiktokShareIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
      }}
    >
      <svg
        viewBox="0 0 48 48"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
      >
        <g
          filter="url(#Icon_Color-Share_Shadow_Alt_2_svg__b)"
          clipPath="url(#Icon_Color-Share_Shadow_Alt_2_svg__a)"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.56 4.07a1.98 1.98 0 0 0-2.15-.42 1.95 1.95 0 0 0-1.21 1.8v8.34c-5.4.35-10.04 2.2-13.43 5.68C4.97 23.35 3 29.03 3 36.19c0 .79.48 1.5 1.22 1.8.73.3 1.58.13 2.14-.42 3.34-3.31 7.65-4.56 11.25-4.95 1.8-.2 3.37-.18 4.5-.1h.09v9.03c0 .78.46 1.48 1.18 1.79.72.3 1.56.16 2.13-.37l18.87-17.49a1.94 1.94 0 0 0 .04-2.8L25.56 4.07Z"
            fill={backgroundColor}
            fillOpacity="0.9"
            shapeRendering="crispEdges"
          ></path>
        </g>
      </svg>
    </div>
  );
};
export default TiktokShareIcon;
