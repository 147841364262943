import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import { TaskDto } from "../../model/campaign/task.dto";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import FormFieldUtils from "../../utilities/form-field.util";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../shared/emp-checkbox/emp-checkbox";
import EmpLink from "../shared/emp-link/emp-link";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./start-task-modal.scss";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import { BRAND_PAYMENT_PROCESS_GUIDE } from "../../constants/campaign-guide";
import HelpCircleIcon from "../icon/help-circle-icon";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "./campaign-guide-modal";
import ToastUtils from "../../utilities/toast-utils";
import { EmpCoinIcon } from "../shared/emp-coin-icon/emp-coin-icon";
import EmpSelect from "../shared/emp-select/emp-select";
import useCrypto from "../../hooks/useCrypto";
import { SelectOption } from "../../model/common/selectOption";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { FormGroupUtil } from "../../utilities/formUtils/formGroup";
import CampaignSummaryApi from "../../api/campaign-msvc/campaign-summary.api";
import { TaskPaymentSummaryDto } from "../../model/campaign/task-payment-summary.dto";
import { ThirdwebPayEmbedWrapper } from "./thirdweb-pay-embed-wrapper";
import {
  cryptoChainOptionsV2,
  testnetChainOptions,
} from "../../constants/selectConstants";
import { getEnv } from "../../constants/envConstants";

export interface StartTaskModalRef {
  show: (task: TaskDto) => void;
  dismiss: () => void;
}

interface Props {
  onStart: () => void;
}

// PRODUCTION FLAG
const CHAIN_OPTIONS = ["development", "staging", "staging-dev", "production"].includes(
  getEnv()
)
  ? [...cryptoChainOptionsV2, ...testnetChainOptions]
  : cryptoChainOptionsV2;

type ModalView =
  | "offer-view"
  | "varied-offer-view"
  | "rep-selection-view"
  | "loading";
const StartTaskModal = forwardRef((props: Props, ref) => {
  const { onStart } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [task, setTask] = useState<TaskDto>();
  const { getChainOptions, updateChainOption } = useCrypto();
  const [modalView, setModalView] = useState<ModalView>("loading");
  const [isLoading, setLoading] = useState(true);

  const submitBtnRef = useRef<EmpButtonRef>();
  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);
  const [taskPaymentDetails, setTaskPaymentDetails] =
    useState<TaskPaymentSummaryDto>();
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedChain, setSelectedChain] = useState("");

  const [form, setForm] = useState({
    chainOptions: new FormControl("text", [
      new RequiredValidator("Select Chain is required"),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchTaskPaymentDetails = async (task: TaskDto) => {
    try {
      setLoading(true);
      const resp = await CampaignSummaryApi.fetchPaymentSummary(task.id);
      setTaskPaymentDetails(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch task payment details"
      );
    } finally {
      setLoading(false);
    }
  };

  const startTaskOnClick = useCallback(async () => {
    try {
      submitBtnRef.current?.setButtonState("loading");

      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;
      await updateChainOption(task!.id, form.chainOptions.getValue());
      setShowPaymentModal(true);
      if (!task) return;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to start task");
      submitBtnRef.current?.setButtonState("default");
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  }, [form, task, updateChainOption]);

  const paymentCompleteCallback = useCallback(
    async (transactionHash: string) => {
      try {
        submitBtnRef.current?.setButtonState("loading");

        for (let i = 0; i < 30; i++) {
          const resp = await CampaignApi.tryStartTask(
            task!.campaign.id,
            task!.id,
            transactionHash
          );
          if (
            resp.data.status === "success" &&
            resp.data.statusMessage === "task-started"
          ) {
            ToastUtils.success(
              "Task Started",
              "Payment completed. Task has transited to Ongoing phase"
            );
            onStart();
            return;
          } else if (
            resp.data.status === "error" &&
            resp.data.statusMessage === "transaction-not-found"
          ) {
            console.log("Transaction not found yet...");
          }
          await new Promise((resolve) => setTimeout(resolve, 5000)); // wait for 5 seconds
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(e, "Unable to start task");
        submitBtnRef.current?.setButtonState("default");
      } finally {
        submitBtnRef.current?.setButtonState("default");
      }
    },
    [task, onStart]
  );

  // If agency ID is included, this is a managed talent
  const show = async (task: TaskDto) => {
    setTask(task);
    fetchTaskPaymentDetails(task);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setTask(undefined);
    setAckIsChecked(false);
    ackCheckboxRef.current?.setChecked(false);
    setModalView("loading");
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"md"}
    >
      <div>
        {showPaymentModal && taskPaymentDetails && selectedChain && task && (
          <ThirdwebPayEmbedWrapper
            onPaymentComplete={paymentCompleteCallback}
            paymentSpecs={{
              token: taskPaymentDetails.paymentMode,
              amount: taskPaymentDetails.totalAmount,
              chain: selectedChain,
            }}
            onClose={() => setShowPaymentModal(false)}
            task={task}
          />
        )}
        <CampaignGuideModal ref={campaignGuideModalRef} />
        <div className="emp-start-task-modal">
          <div className="modal-header-wrapper">
            <button
              onClick={() => dismiss()}
              className="emp-button-reset dismiss-icon-wrapper"
            >
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </button>
            {task && (
              <>
                <span className="intro-subtitle">STARTING TASK FOR</span>
                <div className="title-wrapper">
                  <h1 className="title">{task.name}</h1>
                  <EmpIconButton
                    onSubmit={() => {
                      campaignGuideModalRef.current?.show(
                        BRAND_PAYMENT_PROCESS_GUIDE
                      );
                    }}
                    buttonStyle="secondary"
                    suppressMobileView
                    icon={
                      <HelpCircleIcon
                        backgroundColor={Color.NEUTRAL[400]}
                        size={18}
                      />
                    }
                  />
                </div>
                <p className="emp-paragraph mt-3">
                  Before you start the task, you need to make payment to
                  Emplifive, to be held on hold. Once the task has been
                  completed, the funds will be disbursed to the respective
                  creators. For any unfinished tasks, it will be refunded to
                  you.
                </p>
              </>
            )}
          </div>
          {/* START: Creator Invoice Item Listing */}
          <section className="participants-wrapper mt-3">
            <div className="header-wrapper">
              <div className="participants-header">
                <span className="header-text">Participant</span>
              </div>
              <div className="amount-header">
                <span className="header-text">Amount</span>
              </div>
            </div>
            <div className="body-wrapper">
              {!isLoading &&
                task &&
                taskPaymentDetails?.representatives.map((elem) => {
                  return (
                    <div key={elem.creator.id} className="row-wrapper">
                      <div className="participants-wrapper">
                        <div className="profile-wrapper">
                          {elem.creator.imageType === "url" && (
                            <img
                              className="profile"
                              alt={elem.creator.fullName}
                              src={elem.creator.imageResource}
                            />
                          )}
                          {elem.creator.imageType === "avatar" && (
                            <div
                              className="profile"
                              style={{
                                background: elem.creator.imageResource,
                              }}
                            >
                              <span className="initials">
                                {elem.creator.initials}
                              </span>
                            </div>
                          )}
                          {elem.representativeRole === "agency" && (
                            <img
                              className="profile profile-badge"
                              alt={elem.agency?.companyName}
                              src={elem.agency?.logo}
                            />
                          )}
                        </div>
                        <div className="info-wrapper">
                          <span className="creator-name">
                            {elem.creator.fullName}
                          </span>
                          <span className="representative-lbl mt-1">
                            {elem.representativeRole === "agency"
                              ? `Managed by ${elem.agency?.companyName}`
                              : "Freelance Creator"}
                          </span>
                        </div>
                      </div>
                      <div className="amount-wrapper">
                        <EmpCoinIcon
                          iconValue={task.paymentMode}
                          mode="icon-only"
                          size={20}
                          rounded
                        />
                        <span className="amount-lbl">
                          {`${FormFieldUtils.formatNumber(
                            Number(elem.amount)
                          )}`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {(isLoading || !task) &&
                [1, 2].map((elem) => {
                  return (
                    <div className="row-wrapper" key={elem}>
                      <div className="participants-wrapper">
                        <div className="profile-wrapper">
                          <div className="profile emp-shimmer"></div>
                        </div>
                        <div className="info-wrapper">
                          <div className="creator-name-skeleton  emp-shimmer"></div>
                          <div className="representative-lbl-skeleton  emp-shimmer"></div>
                        </div>
                      </div>
                      <div className="amount-wrapper">
                        <div className="amount-lbl-skeleton emp-shimmer"></div>
                      </div>
                    </div>
                  );
                })}
              {taskPaymentDetails && task && (
                <div className="row-wrapper">
                  <div className="participants-wrapper">
                    <span className="amount-lbl">Total Amount</span>
                  </div>
                  <div className="amount-wrapper">
                    <EmpCoinIcon
                      iconValue={task.paymentMode}
                      mode="icon-only"
                      size={20}
                      rounded
                    />
                    <span className="amount-lbl">{`${FormFieldUtils.formatNumber(
                      Number(taskPaymentDetails?.totalAmount)
                    )}`}</span>
                  </div>
                </div>
              )}
            </div>
          </section>
          {/* Chain Wrapper */}
          <section className="chain-wrapper">
            <EmpSelect
              id={"payment-mode"}
              labelText="Chain"
              required
              onChange={(value) => {
                setSelectedChain(value.getValue());
              }}
              formControl={form.chainOptions}
              placeholder={"Select Chain"}
              selectOptions={CHAIN_OPTIONS}
            />
          </section>
          <section className="acknowledgement-wrapper mt-4">
            <div className="card">
              <div className="intro-wrapper">
                {/* <InfoCircleIcon backgroundColor={Color.PRIMARY[600]} size={16} /> */}
                <span>MANDATORY ACKNOWLEDGEMENT</span>
              </div>
              <div className="control-wrapper mt-2">
                <div className="checkbox-wrapper">
                  <EmpCheckbox
                    onChange={() => {
                      ackCheckboxRef.current?.setChecked(!ackIsChecked);
                      setAckIsChecked(!ackIsChecked);
                    }}
                    ref={ackCheckboxRef}
                    id={"acknowledgement"}
                  />
                </div>
                <div className="checkbox-text-wrapper">
                  <div
                    onClick={() => {
                      ackCheckboxRef.current?.setChecked(!ackIsChecked);
                      setAckIsChecked(!ackIsChecked);
                    }}
                  >
                    <span className={ackIsChecked ? "checked" : ""}>
                      I hereby acknowledge my understanding of the task
                      fulfillment process. By checking this box, I confirm my
                      awareness of the steps involved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* END: Creator Invoice Item Listing */}
          <section className="button-wrapper mt-4">
            <EmpButton
              disabled={!ackIsChecked}
              isFullWidth={false}
              ref={submitBtnRef}
              onSubmit={startTaskOnClick}
              text={"Proceed to Payment"}
            />
          </section>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default StartTaskModal;
