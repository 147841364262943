import { addDays, addMonths, endOfDay, startOfDay } from "date-fns";
import _debounce from "lodash/debounce";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import {
  campaignObjectiveOptions,
  cryptoModeOfPaymentOptions,
  getCountryOptionWithHtml,
  taskPlatforms,
} from "../../constants/selectConstants";
import EmpException from "../../exception/empException";
import { CreateTaskDto } from "../../model/campaign/create-task.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import { UpdateTaskDto } from "../../model/campaign/update-task.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import EditIcon from "../icon/edit-icon";
import TrashIcon from "../icon/trash-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpCheckboxGroup from "../shared/emp-checkbox-group/emp-checkbox-group";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../shared/emp-checkbox/emp-checkbox";
import EmpDateRangePicker from "../shared/emp-date-range-picker/emp-date-range-picker";
import EmpEditorView from "../shared/emp-editor/emp-editor-viewer";
import EmpEditor from "../shared/emp-editor/emp-editor";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpMultiSelectV2 from "../shared/emp-multi-select-v2/emp-multi-select-v2";
import EmpSelect from "../shared/emp-select/emp-select";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import ManageDeliverableModal, {
  ManageDeliverableModalRef,
} from "./manage-deliverable-modal";
import "./create-task-modal.scss";
import { Descendant } from "slate";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "./campaign-guide-modal";
import XCloseIcon from "../icon/x-close-icon";
import HelpCircleIcon from "../icon/help-circle-icon";
import { RESTRUCTURE_CAMPAIGN_GUIDE } from "../../constants/campaign-guide";
import { v4 } from "uuid";
import EmpContent from "../shared/emp-content/emp-content";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import UpdateNegotiationChangelogModal, {
  UpdateNegotiationChangelogModalRef,
} from "./update-negotiation-changelog-modal";

export interface CreateTaskModalRef {
  show: (
    campaignId: string,
    mode: "create" | "edit",
    taskObj?: TaskDto
  ) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

type DeliverableSpec = {
  id: string;
  name: string;
  description: string;
  draftRequired: boolean;
};

type StepType = "step-1" | "step-2";
const countryOptions = getCountryOptionWithHtml();

const CreateTaskModal = forwardRef((props: Props, ref) => {
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const updateNegotiationChangeModalRef =
    useRef<UpdateNegotiationChangelogModalRef>();
  const [task, setTask] = useState<TaskDto>();
  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const createCampaignBtnRef = useRef<EmpButtonRef>(null);

  const [currentStep, setCurrentStep] = useState<StepType>("step-1");
  const submitBtnRef = useRef<EmpButtonRef>();
  const campaignIdRef = useRef<string>();
  const taskIdRef = useRef<string>();
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");

  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);

  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const [form, setForm] = useState<IFormGroup>({
    name: new FormControl("text", [
      new RequiredValidator("Task Name is required"),
      new LengthValidator(
        10,
        100,
        "Task name must be at least 10 characters",
        "Task name must not exceed 100 characters"
      ),
    ]),

    description: new FormControl("text", [
      new RequiredValidator("Description is required"),
      new LengthValidator(
        80,
        1000,
        "Task description must be at least 80 characters",
        "Task description must not exceed 1000 characters"
      ),
    ]),

    objective: new FormControl("text", [
      new RequiredValidator("Please select an objective"),
    ]),
    platform: new FormControl("text", [
      new RequiredValidator("Please select a platform"),
    ]),
    paymentMode: new FormControl("text", [
      new RequiredValidator("Please select a payment mode"),
    ]),
    dateRange: new FormControl(
      "date-range",
      [new RequiredValidator("Please select a task date range")],
      {
        startDate: new Date("2023-11-01"),
        endDate: new Date("2024-01-04"),
      }
    ),
    country: new FormControl("checkbox-group", [
      new RequiredValidator("Please select at least one country"),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    campaignId: string,
    mode: "create" | "edit",
    taskObj?: TaskDto
  ) => {
    campaignIdRef.current = campaignId;
    setModalMode(mode);
    // Prefill form details if modal mode is empty
    if (mode === "edit") {
      if (!taskObj) throw new EmpException("task object is undefined");
      setTask(task);
      taskIdRef.current = taskObj.id;
      form.name.forceUpdateValue(taskObj.name);
      form.description.forceUpdateValue(taskObj.description);
      form.objective.forceUpdateValue(taskObj.objective);
      form.paymentMode.forceUpdateValue(taskObj.paymentMode);
      form.platform.forceUpdateValue(taskObj.platform);
      form.dateRange.forceUpdateValue({
        startDate: new Date(taskObj.startDate),
        endDate: new Date(taskObj.endDate),
      });
      form.country.forceUpdateValue(taskObj.countries);
      populateDeliverableForm(taskObj);
    }
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  const resetForm = () => {
    // Reset first page form
    FormGroupUtil.reset(form);
    setForm({ ...form });
    // Reset second step form
    performSync.current = true;
    setDeliverableState("initial");
    setCurrentStep("step-1");
  };

  const toStep2 = () => {
    try {
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      if (!isValid) return;
      setCurrentStep("step-2");
      if (modalMode === "edit") setDeliverableState("default");
    } catch (e) {
      console.error(e);
      throw new EmpException("Unable to proceed to step 2");
    }
  };

  // ---------- Step 2 Variables ------------------------------------- //
  type DeliverableStateType = "initial" | "default";
  const performSync = useRef(false);
  const manageDeliverableModalRef = useRef<ManageDeliverableModalRef>();
  interface DeliverableFormItem {
    sequence: number;
    type: "name" | "description" | "draftRequired";
    formControl: FormControl;
    initialText: string;
  }
  interface DeliverableGroup {
    key: number;
    name: DeliverableFormItem;
    description: DeliverableFormItem;
    draftRequired: DeliverableFormItem;
  }
  const [deliverableState, setDeliverableState] =
    useState<DeliverableStateType>("initial");
  const [deliverableGroupList, setDeliverableGroupList] = useState<
    DeliverableGroup[]
  >([]);

  const [deliverableDetails, setDeliverableDetails] = useState<
    DeliverableSpec[]
  >([]);

  const [firstDeliverableForm, setFirstDeliverableForm] = useState<IFormGroup>({
    name: new FormControl("text", [
      new RequiredValidator("Deliverable name is required"),
      new LengthValidator(
        10,
        50,
        "Deliverable name must be at least 10 characters",
        "Deliverable name must not exceed 50 characters"
      ),
    ]),
    description: new FormControl("rich-text", [
      new RequiredValidator("Description is required"),
      new LengthValidator(
        100,
        1000,
        "Description must be at least 100 characters",
        "Description must not exceed 1000 characters"
      ),
    ]),
    draftRequired: new FormControl("checkbox-group", [], ["draftRequired"]),
  });

  // This function will run when modal mode is "edit"
  const populateDeliverableForm = (task: TaskDto) => {
    const deliverableDetailsList: DeliverableSpec[] = [];
    task.deliverables.forEach((elem) => {
      deliverableDetailsList.push({
        id: elem.id,
        description: elem.description,
        draftRequired: Boolean(elem.draftRequired),
        name: elem.name,
      });
    });
    setDeliverableDetails(deliverableDetailsList);
    // performSync.current = true;
  };

  /**
   * Submits the first deliverable by performing the following steps:
   * 1. Validates the `deliverableForm` using FormGroupUtil.validate.
   * 2. Updates the `deliverableForm` state to trigger a re-render.
   * 3. If the form is not valid, the function returns without further actions.
   * 4. If the form is valid, sets the `deliverableState` to "default".
   * @function
   * @returns {void}
   */
  const submitFirstDeliverable = () => {
    const isValid = FormGroupUtil.validate(firstDeliverableForm);
    // performSync.current = true;

    setFirstDeliverableForm({ ...firstDeliverableForm });
    setDeliverableDetails(() => {
      return [
        {
          id: v4(),
          name: firstDeliverableForm.name.getValue(),
          description: JSON.stringify(
            firstDeliverableForm.description.getValue()
          ),
          draftRequired:
            firstDeliverableForm.draftRequired.getValue().length > 0,
        },
      ];
    });
    if (!isValid) return;
    setDeliverableState("default");
  };

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validateDeliverableForm = (formControl: FormControl): void => {
    formControl.validate();
    setFirstDeliverableForm({ ...firstDeliverableForm });
  };

  /**
   * Handles the creation of new deliverables with the provided name and description.
   * Creates new FormControl instances for the name and description,
   * and adds them to the deliverableForm with a corresponding sequence number.
   * @function
   * @param {Object} details - An object containing the name and description of the new deliverable.
   * @param {string} details.name - The name of the new deliverable.
   * @param {string} details.description - The description of the new deliverable.
   * @returns {void}
   */
  const handleNewDeliverable = (details: {
    name: string;
    description: Descendant[];
    draftRequired: string[];
    mode: "create" | "edit";
    index?: number;
  }): void => {
    if (details.mode === "create") {
      setDeliverableDetails((prev) => {
        prev.push({
          id: v4(),
          name: details.name,
          description: JSON.stringify(details.description),
          draftRequired: details.draftRequired.length > 0,
        });
        return [...prev];
      });
    } else if (details.mode === "edit") {
      console.log("overriding new value", details.description);
      setDeliverableDetails((prev) => {
        const index = details.index!;
        if (prev[index] === undefined) return prev;
        prev[index].name = details.name;
        prev[index].description = JSON.stringify(details.description);
        prev[index].draftRequired = details.draftRequired.length > 0;
        console.log(prev);
        return [...prev];
      });
    }
  };

  /**
   * @param {number} index - The index number of the deliverable to be removed.
   * @returns {void}
   */
  const removeDeliverable = (index: number): void => {
    if (deliverableDetails.length === 1) {
      return;
    }
    deliverableDetails.splice(index, 1);
    setDeliverableDetails([...deliverableDetails]);
  };

  /**
   * Submits the task data either for creation or update based on the modal mode
   * @returns {Promise<void>} A Promise that resolves after the task is successfully created or updated.
   * @throws {EmpException} If the campaign ID is missing or invalid.
   * @throws {EmpException} If the task ID is missing when the modal mode is "edit".
   * @throws {EmpExceptionHandler.handleHttpRequestError} If an error occurs during the API call.
   */
  async function submitTaskOnClick() {
    try {
      submitBtnRef.current?.setButtonState("loading");
      if (!campaignIdRef.current)
        throw new EmpException("No campaign id found");
      if (modalMode === "create") {
        await createTask(campaignIdRef.current);
      } else if (modalMode === "edit") {
        if (!taskIdRef.current) throw new EmpException("Task not found");
        await updateTask(campaignIdRef.current, taskIdRef.current);
      } else {
        ToastUtils.error("Task not created", "Please try again");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to perform task action"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  }

  /**
   * Creates a new task in the campaign.
   * @param {string} campaignId - The ID of the campaign in which the task will be created.
   * @returns {Promise<void>} A Promise that resolves after the task is successfully created.
   * @throws {EmpExceptionHandler.handleHttpRequestError} If an error occurs during the API call.
   */
  const createTask = async (campaignId: string) => {
    const { startDate, endDate } = form.dateRange.getValue() as {
      startDate: Date;
      endDate: Date;
    };
    const request: CreateTaskDto = {
      name: form.name.getValue(),
      description: form.description.getValue(),
      objective: form.objective.getValue(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      platform: form.platform.getValue(),
      countries: form.country.getValue(),
      budget: 0,
      paymentMode: form.paymentMode.getValue(),
      deliverables: deliverableDetails.map((item) => {
        return {
          name: item.name,
          description: item.description,
          draftRequired: item.draftRequired,
        };
      }),
    };
    const resp = await CampaignApi.createTask(campaignId, request);
    if (resp.data.status === "success") {
      ToastUtils.success("Task Created", "You have created a task");
      dismiss();
      props.onSave();
    }
  };

  /**
   * Updates an existing task in the campaign.
   * @param {string} campaignId - The ID of the campaign in which the task is located.
   * @param {string} taskId - The ID of the task to be updated.
   * @returns {Promise<void>} A Promise that resolves after the task is successfully updated.
   * @throws {EmpExceptionHandler.handleHttpRequestError} If an error occurs during the API call.
   */
  const updateTask = async (campaignId: string, taskId: string) => {
    const { startDate, endDate } = form.dateRange.getValue() as {
      startDate: Date;
      endDate: Date;
    };
    const request: UpdateTaskDto = {
      name: form.name.getValue(),
      description: form.description.getValue(),
      objective: form.objective.getValue(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      countries: form.country.getValue(),
      budget: 0,
      deliverables: deliverableDetails.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          draftRequired: item.draftRequired,
        };
      }),
    };
    const resp = await CampaignApi.updateTask(campaignId, taskId, request);
    if (
      resp.data.status === "success" &&
      resp.data.statusMessage === "updated"
    ) {
      ToastUtils.success("Task Updated", "You have updated this task");
      dismiss();
      props.onSave();
    } else if (
      resp.data.status === "success" &&
      resp.data.statusMessage === "deliverable-version-update"
    ) {
      const latestNegotiationList = resp.data
        .payload as LatestNegotiationExtendedDto[];
      updateNegotiationChangeModalRef.current?.show(
        latestNegotiationList,
        request,
        campaignId,
        taskId
      );
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      bodyPadding={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-create-task-modal">
        {task && (
          <UpdateNegotiationChangelogModal
            task={task}
            ref={updateNegotiationChangeModalRef}
            onComplete={() => {
              dismiss();
              props.onSave();
            }}
          />
        )}
        <CampaignGuideModal ref={campaignGuideModalRef} />
        <section className="emp-modal-common-header">
          <div className="emp-modal-hstack-wrapper">
            <h2>{modalMode === "create" ? "Create Task" : "Edit Task"}</h2>
            <EmpIconButton
              onSubmit={() => {
                campaignGuideModalRef.current?.show(RESTRUCTURE_CAMPAIGN_GUIDE);
              }}
              buttonStyle="secondary"
              suppressMobileView
              icon={
                <HelpCircleIcon
                  backgroundColor={Color.NEUTRAL[400]}
                  size={18}
                />
              }
            />
          </div>
        </section>

        {/* Stepper Section */}
        <div className="task-modal-wrapper">
          <section className="stepper-section">
            <div className="step stepper-elem">
              <span
                className={`step-header stepper-text-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              >
                Step 1
              </span>
              <span
                className={`step-description stepper-text-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              >
                Task Information
              </span>
              <div
                className={`bar stepper-elem ${
                  currentStep === "step-1" ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="step stepper-elem">
              <span
                className={`step-header stepper-text-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              >
                Step 2
              </span>
              <span
                className={`step-description stepper-text-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              >
                Specify Deliverables
              </span>
              <div
                className={`bar stepper-elem ${
                  currentStep === "step-2" ? "active" : ""
                }`}
              ></div>
            </div>
          </section>
          {currentStep === "step-1" && (
            <>
              <section className="form-section mt-8">
                <EmpTextInput
                  id={"name"}
                  labelText="Task Name"
                  required
                  tooltip="Enter a memorable and descriptive name for your task. A unique campaign name helps you easily identify and manage your marketing efforts."
                  formControl={form.name}
                  onChange={validate}
                  placeholder={"E.g Short Instagram Task"}
                />

                <EmpTextInput
                  id={"description"}
                  labelText="Description"
                  multiline
                  textAreaAdaptiveHeight
                  required
                  tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                  formControl={form.description}
                  onChange={validate}
                  rows={2}
                  characterCount={300}
                  placeholder={"Tell us about your marketing initiative..."}
                />

                <EmpSelect
                  id={"payment-mode"}
                  labelText="Token"
                  required
                  formControl={form.paymentMode}
                  onChange={validate}
                  placeholder={"Select Token"}
                  selectOptions={cryptoModeOfPaymentOptions}
                />
                <div className="split-wrapper">
                  <div className="split-control">
                    <EmpSelect
                      id={"objective"}
                      labelText="Objective"
                      required
                      formControl={form.objective}
                      onChange={validate}
                      placeholder={"Task Objective"}
                      selectOptions={campaignObjectiveOptions}
                    />
                  </div>
                  <div className="split-control ">
                    <EmpMultiSelectV2
                      menuItems={countryOptions}
                      hasSearch
                      label="Country"
                      widthMode="100%"
                      formControl={form.country}
                      isRequired
                      onChange={validate}
                      tooltip="Please select the country that you wish to target this campaign task"
                      placement="top"
                      placeholder={"Select Country"}
                    />
                  </div>
                </div>
                <div className="split-wrapper">
                  <div className="split-control">
                    <EmpSelect
                      id={"platfrom"}
                      labelText="Platform"
                      required
                      disabled={modalMode === "edit" ? true : false}
                      formControl={form.platform}
                      onChange={validate}
                      placeholder={"Select a platform"}
                      selectOptions={taskPlatforms}
                    />

                    {modalMode === "create" && (
                      <small>
                        Platform for this task cannot be modified after
                        creation.
                      </small>
                    )}
                  </div>
                  <div className="split-control ">
                    <EmpDateRangePicker
                      formControl={form.dateRange}
                      label={"Projected Task Duration"}
                      ranges={[
                        {
                          label: "Two Weeks",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addDays(new Date(), 14)),
                          ],
                        },
                        {
                          label: "One Month",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addMonths(new Date(), 1)),
                          ],
                        },
                        {
                          label: "Two Months",
                          value: [
                            startOfDay(new Date()),
                            endOfDay(addMonths(new Date(), 2)),
                          ],
                        },
                      ]}
                      placeholder={"Task Estimate Date"}
                    />
                  </div>
                </div>
              </section>
              <div className="button-wrapper mt-4">
                {modalMode === "edit" && (
                  <EmpButton
                    onSubmit={() => {
                      submitTaskOnClick();
                    }}
                    buttonStyle="secondary"
                    ref={createCampaignBtnRef}
                    isFullWidth={false}
                    text={"Save Changes"}
                  />
                )}

                <EmpButton
                  onSubmit={() => {
                    toStep2();
                  }}
                  ref={createCampaignBtnRef}
                  isFullWidth={false}
                  text={"Proceed next step"}
                />
              </div>
            </>
          )}
          {currentStep === "step-2" && (
            <>
              <ManageDeliverableModal
                ref={manageDeliverableModalRef}
                onSave={(details) => {
                  handleNewDeliverable(details);
                }}
              />
              {deliverableState === "initial" && (
                <section className="form-section step-2 mt-4">
                  <EmpTextInput
                    id={"name#1"}
                    labelText="Deliverable Name"
                    required
                    tooltip="Enter a memorable and descriptive name for your task. A unique campaign name helps you easily identify and manage your marketing efforts."
                    formControl={firstDeliverableForm.name}
                    onChange={validateDeliverableForm}
                    placeholder={"Create a static post"}
                  />

                  <EmpEditor
                    labelText="Description"
                    placeholder="Enter Deliverable Description here"
                    formControl={firstDeliverableForm.description}
                    onChange={validateDeliverableForm}
                    required
                    characterCount={1000}
                    description="Specify the task deliverables with comprehensive details, ensuring clarity on the requirements and acceptance criteria for the creator."
                  />

                  <EmpCheckboxGroup
                    className="mt-4"
                    labelText="Deliverable Requirement"
                    id={"draftRequired#1"}
                    formControl={firstDeliverableForm.draftRequired}
                    checkboxOptions={[
                      {
                        label:
                          "I will require the creator to submit a draft for approval.",
                        value: "draftRequired",
                      },
                    ]}
                  />
                </section>
              )}

              {deliverableState === "default" && (
                <section className="form-section step-2 mt-4">
                  {deliverableDetails.map((elem, index) => {
                    return (
                      <div className="deliverable-content" key={elem.id}>
                        <div className="deliverable-indicator-wrapper">
                          <div style={{ flex: 1 }}>
                            <span className="deliverable-number">
                              DELIVERABLE {index + 1}
                            </span>
                            <div className={`deliverable-name`}>
                              {elem.name}
                            </div>
                          </div>
                          <EmpIconButton
                            buttonStyle="secondary"
                            onSubmit={() => {
                              manageDeliverableModalRef.current?.show(
                                "edit",
                                index,
                                {
                                  name: elem.name,
                                  description: elem.description,
                                  draftRequired: elem.draftRequired,
                                }
                              );
                            }}
                            icon={
                              <EditIcon
                                size={18}
                                hoverColor={Color.NEUTRAL[500]}
                                backgroundColor={Color.NEUTRAL[500]}
                              />
                            }
                          />
                          {deliverableDetails.length > 1 && (
                            <EmpIconButton
                              onSubmit={() => {
                                removeDeliverable(index);
                              }}
                              buttonStyle="secondary"
                              icon={
                                <TrashIcon
                                  size={18}
                                  hoverColor={Color.NEUTRAL[500]}
                                  backgroundColor={Color.NEUTRAL[500]}
                                />
                              }
                            />
                          )}
                        </div>
                        <div className="mb-4">
                          <EmpEditorView value={elem.description} />
                        </div>
                        <EmpContent
                          label={"Draft Required?"}
                          value={elem.draftRequired ? "Yes" : "No"}
                        />
                      </div>
                    );
                  })}
                  <section className="acknowledgement-wrapper mt-4">
                    <div className="card">
                      <div className="intro-wrapper">
                        <span>MANDATORY ACKNOWLEDGEMENT</span>
                      </div>
                      <div className="control-wrapper mt-2">
                        <div className="checkbox-wrapper">
                          <EmpCheckbox
                            onChange={() => {
                              ackCheckboxRef.current?.setChecked(!ackIsChecked);
                              setAckIsChecked(!ackIsChecked);
                            }}
                            ref={ackCheckboxRef}
                            id={"acknowledgement"}
                          />
                        </div>
                        <div className="checkbox-text-wrapper">
                          <div
                            onClick={() => {
                              ackCheckboxRef.current?.setChecked(!ackIsChecked);
                              setAckIsChecked(!ackIsChecked);
                            }}
                          >
                            <span className={ackIsChecked ? "checked" : ""}>
                              By clicking on this, I understand and acknowledge
                              that I will not be able to make changes to the
                              already confirmed deliverables. However, I am
                              still able to modify the task details if i require
                              to do so.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
              )}
              <div className="button-wrapper mt-4">
                <EmpButton
                  onSubmit={() => {
                    setCurrentStep("step-1");
                  }}
                  isFullWidth={false}
                  buttonStyle={"secondary"}
                  text={"Back"}
                />

                {deliverableState === "default" && (
                  <EmpButton
                    onSubmit={() => {
                      manageDeliverableModalRef.current?.show("create");
                    }}
                    isFullWidth={false}
                    disabled={deliverableGroupList.length >= 3}
                    buttonStyle={"secondary"}
                    text={
                      deliverableGroupList.length >= 3
                        ? "Max 3 deliverables"
                        : "Add Deliverable"
                    }
                  />
                )}

                {deliverableState === "default" && (
                  <EmpButton
                    onSubmit={() => {
                      submitTaskOnClick();
                    }}
                    isFullWidth={false}
                    disabled={!ackIsChecked}
                    ref={submitBtnRef}
                    text={
                      modalMode === "create" ? "Create Task" : "Save Changes"
                    }
                  />
                )}

                {deliverableState === "initial" && (
                  <EmpButton
                    onSubmit={() => {
                      submitFirstDeliverable();
                    }}
                    isFullWidth={false}
                    text={"Create First Deliverable"}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div></div>
    </EmpModal>
  );
});

export default CreateTaskModal;
