import { ReactEditor } from "slate-react";
import {
  Element as SlateElement,
  Editor,
  Range,
  Point,
  BaseEditor,
  Transforms,
} from "slate";

export const withEmpEditor = (editor: Editor): BaseEditor & ReactEditor => {
  const {
    deleteBackward,
    insertText,
    isInline,
    isElementReadOnly,
    isSelectable,
  } = editor;

  // Cause the thing to be deleted immediately at once
  editor.isElementReadOnly = (element) =>
    element.type === "link" || isElementReadOnly(element);

  editor.isSelectable = (element) =>
    element.type !== "link" && isSelectable(element);

  editor.isInline = (element) =>
    ["link"].includes(element.type) || isInline(element);

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [listMatch] = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === "bulleted-list",
        })
      );

      const [listItemMatch] = Array.from(
        Editor.nodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type === "list-item",
        })
      );

      if (listMatch && listItemMatch) {
        // Only collapse if the bulleted list is the last element of the list.
        const [, path] = listItemMatch;
        const listChildrenLength = listMatch[0].children.length;

        if (path[1] === listChildrenLength - 1) {
          const start = Editor.start(editor, path);
          if (Point.equals(selection.anchor, start)) {
            const block: SlateElement = {
              type: "paragraph",
              children: [{ text: "" }],
            };

            // Create a new paragraph below the bullet list if it is not the last bullet
            if (path[1] > 0) {
              // Remove the list item node
              Transforms.removeNodes(editor, {
                at: path,
              });
              // Create a new paragrpaph node below the deleted list item node
              Transforms.insertNodes(editor, block, {
                at: [path[0] + 1],
              });
              // Focus to the newly created paragraph.
              Transforms.select(editor, [path[0] + 1]);
            }
            // Replace the bulleted list with a paragraph instead
            else {
              const block = { type: "paragraph", children: [] };
              Transforms.removeNodes(editor, {
                match: (n) =>
                  !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  n.type === "bulleted-list",
              });
              Transforms.insertNodes(editor, block, {
                at: [path[0]],
              });
              Transforms.select(editor, [path[0]]);
            }
            return;
          }
        }
      }
    }
    deleteBackward(...args);
  };

  editor.insertText = (text: string) => {
    const websiteRegex =
      /^(?:https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.+~#?&//=]*)$/;

    if (text.match(websiteRegex)) {
      const url = text;
      const { selection } = editor;
      const isCollapsed = selection && Range.isCollapsed(selection);
      const link = {
        type: "link",
        url,
        children: isCollapsed ? [{ text: url }] : [],
      };
      // To be selected

      if (isCollapsed) {
        const anchorPath = [...selection!.anchor.path];
        anchorPath[anchorPath.length - 1] += 2;
        Transforms.insertNodes(editor, link);
        Transforms.select(editor, anchorPath);
      } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: "end" });
      }
    } else {
      insertText(text);
    }
  };
  return editor as BaseEditor & ReactEditor;
};
