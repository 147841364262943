import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const InstagramShareIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
        alignItems: "center",
        height: "fit-content",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8404 0.156557C11.7645 0.0817196 11.6682 0.030852 11.5637 0.010254C11.4591 -0.0103441 11.3507 0.000237931 11.2521 0.0406712L0.34405 4.40101C0.241282 4.44169 0.153352 4.51269 0.0919028 4.60458C0.0304532 4.69647 -0.00158824 4.8049 6.05842e-05 4.91547C0.0016848 5.02602 0.0369254 5.13346 0.101057 5.22351C0.165189 5.31355 0.255211 5.38192 0.359111 5.41955L4.91851 7.08115L6.58036 11.642C6.61816 11.7457 6.68655 11.8355 6.77657 11.8994C6.86652 11.9634 6.97379 11.9984 7.08414 12H7.09224C7.20175 11.9994 7.30848 11.966 7.39865 11.904C7.48889 11.842 7.55848 11.7543 7.59833 11.6524L11.9609 0.745189C12.0006 0.646038 12.0103 0.537436 11.9889 0.432797C11.9675 0.328158 11.9159 0.232108 11.8404 0.156557ZM7.1166 9.92587L5.97588 6.79728L7.60645 5.16577C7.65852 5.11542 7.70004 5.0552 7.72861 4.98864C7.75711 4.92207 7.77217 4.85045 7.77276 4.77803C7.77335 4.70558 7.75957 4.63374 7.73213 4.56671C7.70471 4.49967 7.66421 4.43876 7.61302 4.38753C7.56178 4.33629 7.50095 4.29578 7.43391 4.26834C7.3669 4.2409 7.29507 4.2271 7.22271 4.22769C7.15029 4.2283 7.07872 4.24334 7.01218 4.27189C6.94564 4.30046 6.88547 4.342 6.83512 4.39407L5.20802 6.02445L2.08118 4.88419L10.4808 1.52385L7.1166 9.92587Z"
          fill={backgroundColor}
        />
      </svg>
    </div>
  );
};
export default InstagramShareIcon;
