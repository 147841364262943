import { useCallback, useEffect, useMemo, useState } from "react";
import "./creator-page.scss";
import { MyCreatorsView } from "./views/my-creators-view";
import { ExploreCreatorsView } from "./views/explore-creators-view";
import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import { RateCardRequestView } from "./views/rate-card-request-view";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import { FormattedMessage, useIntl } from "react-intl";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";

export const CreatorPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>("My Creators");
  const intl = useIntl();

  const [tabs, setTabs] = useState<EmpTab[]>([]);

  useEffect(() => {
    setTabs((prev) => {
      return [...prev];
    });
  }, [intl]);

  const TABS: EmpTab[] = useMemo(() => {
    return [
      {
        display: intl.formatMessage({ id: "agencyCreatorPage_myCreatorTab" }),
        text: "My Creators",
        isSelected: false,
      },
      {
        display: intl.formatMessage({
          id: "agencyCreatorPage_exploreCreatorsTab",
        }),
        text: "Explore Creators",
        isSelected: false,
      },
      {
        display: intl.formatMessage({
          id: "agencyCreatorPage_rateCardRequestTab",
        }),
        text: "Rate Card Request",
        isSelected: false,
      },
    ];
  }, [intl]);

  const getRateCardRequestToastNum = useCallback(async () => {
    try {
      const resp = await RateCardApi.getPendingBrandRateCardRequestCount();
      const toastNum = resp.data;
      let fragment = window.location.hash.slice(1);

      setTabs(() => {
        let tab = [...TABS];

        // If fragment exists, set the right tab.
        if (fragment) {
          fragment = fragment.replaceAll("%20", " ");
          const selectedTab = tab.find((elem) => elem.text === fragment);
          if (!selectedTab)
            tab = tab.map((tab, index) =>
              index === 0 ? { ...tab, isSelected: true } : tab
            );
          else selectedTab.isSelected = true;
          setSelectedTab(fragment);
        } else {
          tab = tab.map((tab, index) =>
            index === 0 ? { ...tab, isSelected: true } : tab
          );
        }
        // Set the toast number if there is an update.
        if (toastNum > 0) tab[tab.length - 1].toastNum = toastNum;
        else tab[tab.length - 1].toastNum = undefined;

        return [...tab];
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch rate card request toast number"
      );
      return 0;
    }
  }, []);

  const rateCardRequestOnUpdate = useCallback(async () => {
    const resp = await RateCardApi.getPendingBrandRateCardRequestCount();
    const toastNum = resp.data;
    setTabs((prev) => {
      let tab = [...prev];
      // Set the toast number if there is an update.
      if (toastNum > 0) tab[tab.length - 1].toastNum = toastNum;
      else tab[tab.length - 1].toastNum = undefined;
      return [...tab];
    });
  }, []);

  useEffect(() => {
    getRateCardRequestToastNum();
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.RATE_CARD_REQUEST_UPDATE,
      rateCardRequestOnUpdate
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [getRateCardRequestToastNum, TABS, rateCardRequestOnUpdate]);

  return (
    <div className="emp-page-wrapper no-padding">
      <div className="emp-page-content emp-creator-page">
        <div className="header-wrapper">
          <h1>
            {selectedTab === "My Creators" && (
              <FormattedMessage id="agencyCreatorPage_myCreatorTab" />
            )}
          </h1>
          <h1>
            {selectedTab === "Explore Creators" && (
              <FormattedMessage id="agencyCreatorPage_exploreCreatorsTab" />
            )}
          </h1>
          <h1>
            {selectedTab === "Rate Card Request" && (
              <FormattedMessage id="agencyCreatorPage_rateCardRequestTab" />
            )}
          </h1>
        </div>
        <div className="content">
          <EmpSecondaryTabBar
            tabs={tabs}
            onChange={(tabLabel: string) => {
              setSelectedTab(tabLabel);
              window.location.hash = tabLabel;
            }}
          />
          {/* <MyCreatorsView /> */}
          <div className="creator-view-wrapper">
            {selectedTab === "My Creators" && <MyCreatorsView />}
            {selectedTab === "Explore Creators" && <ExploreCreatorsView />}
            {selectedTab === "Rate Card Request" && (
              <RateCardRequestView
                onChange={() => getRateCardRequestToastNum()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
