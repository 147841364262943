import "./creator-info-selection-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpModal from "../../../components/shared/emp-modal/emp-modal";
import EmpException from "../../../exception/empException";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CreatorInfoApi from "../../../api/user-msvc/creator-info.api";
import { CreatorInfoOptionsRespDto } from "../../../model/user-management/creator-info-options-resp.dto";
import EmpRoundedPill, {
  EmpRoundedPillRef,
} from "../../../components/shared/emp-rounded-pill/emp-rounded-pill";
import { empDelay } from "../../../utilities/delay";
import _debounce from "lodash/debounce";
import { motion } from "framer-motion";
import ToastUtils from "../../../utilities/toast-utils";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../../utilities/translation.util";

export interface CreatorInfoSelectionModalRef {
  show: (modalMode: "add" | "edit", creatorId?: string) => void;
}

interface Props {
  onSave: () => void;
}

interface PillOption {
  value: number;
  isSelected: boolean;
}

interface SlideHeight {
  firstSlide: number;
  secondSlide: number;
}

const CreatorInfoSelectionModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [bodyVariant, setBodyVariant] = useState<string>("firstSlide");
  const [visible, setVisible] = useState<boolean>(false);
  const [creatorInfoOptions, setCreatorInfoOptions] =
    useState<CreatorInfoOptionsRespDto>();
  const modalModeRef = useRef<string>("add");
  const creatorIdRef = useRef<string>();

  // Service Pill tracker
  const [servicePills, setServicePills] = useState<PillOption[]>([]);
  const servicePillsRef = useRef<EmpRoundedPillRef[]>([]);

  // Interest Pill tracker
  const [interestPills, setInterestPills] = useState<PillOption[]>([]);
  const interestPillsRef = useRef<EmpRoundedPillRef[]>([]);

  // Interest Count Tracker
  const [selectedInterestCount, setSelectedInterestCount] = useState<number>(0);
  const [selectedServiceCount, setSelectedServicesCount] = useState<number>(0);

  const [slideHeights, setSlideHeights] = useState<SlideHeight>({
    firstSlide: 0,
    secondSlide: 0,
  });

  const sliderVariant = {
    firstSlide: { left: "0%", height: slideHeights.firstSlide },
    secondSlide: { left: "-100%", height: slideHeights.secondSlide },
  };

  // Slide refs
  const firstSlideRef = useRef<HTMLDivElement>(null);
  const secondSlideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!visible) return;
    const resize = async () => {
      empDelay(100);
      if (firstSlideRef?.current && secondSlideRef?.current) {
        computeHiddenFormHeight(
          firstSlideRef.current.clientHeight,
          secondSlideRef.current.clientHeight
        );
      }
    };

    const observeResize = async (observer: ResizeObserver) => {
      await empDelay(100);
      observer.observe(firstSlideRef.current!);
      observer.observe(secondSlideRef.current!);
    };

    const resizeObserver = new ResizeObserver(() => {
      debounceFn(
        firstSlideRef.current!.clientHeight,
        secondSlideRef.current!.clientHeight
      );
    });
    resize();
    observeResize(resizeObserver);
    return () => resizeObserver.disconnect();
  }, [visible]);

  const computeHiddenFormHeight = (
    firstSlideHeight: number,
    secondSlideHeight: number
  ) => {
    setSlideHeights({
      firstSlide: firstSlideHeight,
      secondSlide: secondSlideHeight,
    });
  };
  const debounceFn: (
    firstSlideHeight: number,
    secondSlideHeight: number
  ) => void = useCallback(
    _debounce(computeHiddenFormHeight, 150, { leading: true }),
    []
  );

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (modalMode: "add" | "edit", creatorId?: string) => {
    setVisible(true);
    modalModeRef.current = modalMode;
    creatorIdRef.current = creatorId;
    fetchCreatorInfoOptions();
  };

  const dismiss = async () => {
    setInterestPills([]);
    setServicePills([]);

    setSelectedServicesCount(0);
    setSelectedInterestCount(0);
    setVisible(false);
    await empDelay(200);
    setBodyVariant("firstSlide");
  };

  const fetchCreatorInfoOptions = async () => {
    try {
      const resp = await CreatorInfoApi.fetchCreatorInfoOptions();
      setCreatorInfoOptions(resp.data);

      if (modalModeRef.current === "edit") {
        if (!creatorIdRef.current) throw new EmpException("No Creator Id");
        const currentSelection = await CreatorInfoApi.fetchCreatorInfo(
          creatorIdRef.current
        );
        const { interests, services } = currentSelection.data;
        const interestIdList: number[] = interests.map((elem) => elem.id);
        const serviceIdList: number[] = services.map((elem) => elem.id);

        const servicePillOptions: PillOption[] = [];
        resp.data.services.forEach((elem) => {
          servicePillOptions.push({
            value: elem.id,
            isSelected: serviceIdList.includes(elem.id),
          });
        });
        setServicePills(servicePillOptions);

        const interestPillOptions: PillOption[] = [];
        resp.data.interests.forEach((elem) => {
          interestPillOptions.push({
            value: elem.id,
            isSelected: interestIdList.includes(elem.id),
          });
        });
        setInterestPills(interestPillOptions);
        aggregateSelectedOptions(servicePillOptions, setSelectedServicesCount);
        aggregateSelectedOptions(interestPillOptions, setSelectedInterestCount);
      } else if (modalModeRef.current === "add") {
        const servicePillOptions: PillOption[] = [];
        resp.data.services.forEach((elem) => {
          servicePillOptions.push({ value: elem.id, isSelected: false });
        });
        setServicePills(servicePillOptions);

        const interestPillOptions: PillOption[] = [];
        resp.data.interests.forEach((elem) => {
          interestPillOptions.push({ value: elem.id, isSelected: false });
        });
        setInterestPills(interestPillOptions);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info option"
      );
    }
  };

  const aggregateSelectedOptions = (
    options: PillOption[],
    dispatchAction: React.Dispatch<React.SetStateAction<number>>
  ): void => {
    const count = options.reduce((val, elem) => {
      return val + (elem.isSelected ? 1 : 0);
    }, 0);
    dispatchAction(count);
  };

  const servicePillOnSelect = (index: number, pillRef: EmpRoundedPillRef) => {
    pillRef.click();
    const boolValue = servicePills[index].isSelected;
    servicePills[index].isSelected = !boolValue;
    setServicePills([...servicePills]);
    aggregateSelectedOptions(servicePills, setSelectedServicesCount);
  };

  const interestPillOnSelect = (index: number, pillRef: EmpRoundedPillRef) => {
    pillRef.click();
    const boolValue = interestPills[index].isSelected;
    interestPills[index].isSelected = !boolValue;
    setInterestPills([...interestPills]);
    aggregateSelectedOptions(interestPills, setSelectedInterestCount);
  };

  const onSave = async () => {
    try {
      const serviceValues = servicePills
        .filter((elem) => elem.isSelected)
        .map((elem) => elem.value);
      const interestValues = interestPills
        .filter((elem) => elem.isSelected)
        .map((elem) => elem.value);

      const request = {
        services: serviceValues,
        interests: interestValues,
      };
      const resp = await CreatorInfoApi.saveCreatorInfo(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Creator Information Saved", "Saved");
        dismiss();
        props.onSave();
      }
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showFooter={false}
      showHeader={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      onClose={() => {
        dismiss();
      }}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-creator-info-modal">
        <motion.div
          variants={sliderVariant}
          initial={"firstSlide"}
          animate={bodyVariant}
          className="content-body"
          transition={{
            duration: 0.3,
            ease: "easeOut",
          }}
          style={{ width: "200%" }}
        >
          {/* First Slide */}
          <div className="swipe-slide" ref={firstSlideRef}>
            <div className="text-content-section">
              <h2 className="title">
                <FormattedMessage id="creatorInfoEditModal_header" />
              </h2>
              <p className="description">
                <FormattedMessage id="creatorInfoEditModal_serviceDesc" />
              </p>
            </div>

            {selectedServiceCount <= 4 && (
              <p className="description">
                <FormattedMessage
                  id="creatorInfoEditModal_servicesSelection"
                  values={{
                    selection: (
                      <span className="highlighted">
                        {selectedServiceCount}/4
                      </span>
                    ),
                  }}
                />
              </p>
            )}
            {selectedServiceCount > 4 && (
              <p className="error-message">
                <FormattedMessage id="creatorInfoEditModal_servicesErrorMsg" />
              </p>
            )}

            {creatorInfoOptions && servicePills.length > 0 && (
              <div className="pill-wrapper mt-4">
                {creatorInfoOptions.services.map((elem, index) => {
                  return (
                    <div
                      onClick={() => {
                        servicePillOnSelect(
                          index,
                          servicePillsRef.current[index]
                        );
                      }}
                      className="pill-btn"
                      key={elem.id}
                    >
                      <EmpRoundedPill
                        isSelected={servicePills[index].isSelected}
                        ref={(el: EmpRoundedPillRef) => {
                          servicePillsRef.current[index] = el;
                        }}
                        text={TranslationUtil.translateCreatorService(
                          intl,
                          elem.id,
                          elem.label
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <div className="footer-btn-wrapper mt-4">
              <div className="mr-2">
                <EmpButton
                  isFullWidth={false}
                  buttonStyle={"secondary"}
                  text={<FormattedMessage id="cta_cancel" />}
                  onSubmit={() => {
                    dismiss();
                  }}
                />
              </div>
              <EmpButton
                isFullWidth={false}
                text={<FormattedMessage id="cta_nextStep" />}
                disabled={
                  !(selectedServiceCount > 0 && selectedServiceCount <= 4)
                }
                onSubmit={() => {
                  setBodyVariant("secondSlide");
                }}
              />
            </div>
          </div>

          {/* Second Slide */}
          <div className="swipe-slide" ref={secondSlideRef}>
            <div className="text-content-section">
              <EmpIconButton
                onSubmit={() => setBodyVariant("firstSlide")}
                buttonStyle="secondary"
                icon={<ChevronLeftIcon />}
              />
              <h2 className="title mt-2">
                <FormattedMessage id="creatorInfoEditModal_header" />
              </h2>
              <p className="description">
                <FormattedMessage id="creatorInfoEditModal_interestsDesc" />
              </p>
            </div>
            {selectedInterestCount <= 4 && (
              <p className="description">
                <FormattedMessage
                  id="creatorInfoEditModal_interestsSelection"
                  values={{
                    selection: (
                      <span className="highlighted">
                        {selectedInterestCount}/4
                      </span>
                    ),
                  }}
                />{" "}
              </p>
            )}
            {selectedInterestCount > 4 && (
              <p className="error-message">
                <FormattedMessage id="creatorInfoEditModal_interestsErrorMsg" />
              </p>
            )}
            {creatorInfoOptions && interestPills.length > 0 && (
              <div className="pill-wrapper mt-4">
                {creatorInfoOptions.interests.map((elem, index) => {
                  return (
                    <div
                      onClick={() => {
                        interestPillOnSelect(
                          index,
                          interestPillsRef.current[index]
                        );
                      }}
                      className="pill-btn"
                      key={elem.id}
                    >
                      <EmpRoundedPill
                        isSelected={interestPills[index].isSelected}
                        ref={(el: EmpRoundedPillRef) => {
                          interestPillsRef.current[index] = el;
                        }}
                        text={TranslationUtil.translateCreatorInterest(
                          intl,
                          elem.id,
                          elem.label
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {/* Facebook Pages Mapper */}
            <div className="footer-btn-wrapper mt-4">
              <div className="mr-2">
                <EmpButton
                  isFullWidth={false}
                  buttonStyle={"secondary"}
                  text={<FormattedMessage id="cta_cancel" />}
                  onSubmit={() => {
                    dismiss();
                  }}
                />
              </div>
              <EmpButton
                isFullWidth={false}
                disabled={
                  !(selectedInterestCount > 0 && selectedInterestCount <= 4)
                }
                text={"Submit"}
                onSubmit={onSave}
              />
            </div>
          </div>
        </motion.div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CreatorInfoSelectionModal;
