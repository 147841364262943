export class LocalStorageUtil {
  /**
   * Sets an item in localStorage.
   *
   * @param {string} key - The key to set the item with.
   * @param {any} value - The value to be stored.
   * @returns {void}
   */
  static setItem(key: string, value: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error setting localStorage item:", error);
    }
  }
  /**
   * Gets an item from localStorage.
   *
   * @param {string} key - The key of the item to retrieve.
   * @param {T} [defaultValue] - The default value to return if the item doesn't exist.
   * @returns {T | null} The stored item or the default value if it doesn't exist.
   * @template T
   */
  static getItem<T>(key: string, defaultValue?: T): T | null {
    try {
      const storedItem = localStorage.getItem(key);
      return storedItem ? JSON.parse(storedItem) : defaultValue || null;
    } catch (error) {
      console.error("Error getting localStorage item:", error);
      return defaultValue || null;
    }
  }

  /**
   * Removes an item from localStorage.
   *
   * @param {string} key - The key of the item to remove.
   * @returns {void}
   */
  static removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing localStorage item:", error);
    }
  }

  /**
   * Updates or sets an item in localStorage. If the item already exists and both
   * the existing item and the new value are objects, they will be merged.
   *
   * @param {string} key - The key to set or update the item with.
   * @param {T} value - The value to be stored or merged.
   * @returns {void}
   * @template T
   */
  static upsertItem<T>(key: string, value: T): void {
    const existingItem = LocalStorageUtil.getItem(key);
    if (existingItem !== null) {
      // Merge the existing item with the new value (if it's an object)
      if (typeof existingItem === "object" && typeof value === "object") {
        LocalStorageUtil.setItem(key, { ...existingItem, ...value });
      } else {
        LocalStorageUtil.setItem(key, value);
      }
    } else {
      LocalStorageUtil.setItem(key, value);
    }
  }
}
