import { FormattedMessage } from "react-intl";
import TikTokIcon from "../icon/tiktok-icon";
import "./tiktok-loader.scss";

interface Props {}

export const TikTokLoader = (props: Props) => {
  return (
    <div className="emp-tiktok-loader-overlay">
      <div className="emp-loader">
        <TikTokIcon />
        <span className="label mt-2 ">
          <FormattedMessage id="tiktokLoading_text" />
        </span>
        <div className="emp-progress mt-3">
          <div className="indeterminate"></div>
        </div>
      </div>
    </div>
  );
};
