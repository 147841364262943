import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../shared/emp-checkbox/emp-checkbox";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./delete-campaign-modal.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CampaignDto } from "../../model/campaign/campaign.dto";
import {
  DeleteCampaignReasonDto,
  DeleteCampaignReasonTaskDto,
  DeleteCampaignRecommendationDto,
} from "../../model/campaign/delete-campaign-recommendation.dto";
import EmpPill from "../shared/EmpPill/EmpPill";
import TikTokIcon from "../icon/tiktok-icon";
import { TASK_STATUS, TASK_TYPE } from "../../constants/app.constants";
import FacebookIcon from "../icon/facebook-icon";
import InstagramIcon from "../icon/instagram-icon";
import TargetIcon from "../icon/target-icon";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import EmpException from "../../exception/empException";
import ToastUtils from "../../utilities/toast-utils";

export interface DeleteCampaignModalRef {
  show: (campaign: CampaignDto) => void;
  dismiss: () => void;
}

interface Props {}

const DeleteCampaignModal = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<CampaignDto>();

  const submitBtnRef = useRef<EmpButtonRef>();
  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);
  const [deleteCampaignRecommendation, setDeleteCampaignRecommendation] =
    useState<DeleteCampaignRecommendationDto>();

  const [dcr001, setDcr001] = useState<DeleteCampaignReasonDto>();
  const [dcr002, setDcr002] = useState<DeleteCampaignReasonDto>();
  const [hasReasons, setReasons] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (campaign: CampaignDto) => {
    try {
      setVisible(true);
      const resp = await CampaignApi.fetchCampaignDeletionRecommendation(
        campaign.id
      );
      const dcRecommendation = resp.data;
      if (dcRecommendation && dcRecommendation.reasons.length > 0) {
        setReasons(true);
      }
      setDeleteCampaignRecommendation(resp.data);
      setDcr001(
        dcRecommendation.reasons.find(
          (elem: DeleteCampaignReasonDto) => elem.code === "DCR_001"
        )
      );
      setDcr002(
        dcRecommendation.reasons.find(
          (elem: DeleteCampaignReasonDto) => elem.code === "DCR_002"
        )
      );
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to verify campaign deletion recommendation"
      );
    }

    setCampaign(campaign);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setAckIsChecked(false);
    ackCheckboxRef.current?.setChecked(false);
  };

  const deleteCampaign = async () => {
    try {
      if (!campaign) throw new EmpException("Campaign Id not found");
      submitBtnRef.current?.setButtonState("loading");
      const resp = await CampaignApi.deleteCampaign(campaign.id);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Campaign Deleted",
          `Campaign ${campaign.name} has been deleted successfully`
        );
        navigate("/brand/campaigns");
      } else {
        ToastUtils.success(
          "Error deleting campaign",
          `Campaign ${campaign.name} can not be deleted`
        );
      }
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error occurred when deleting tasks"
      );
    } finally {
      submitBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"md"}
    >
      <div>
        <div className="emp-delete-campaign-modal">
          <div className="modal-header-wrapper">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            {campaign && (
              <>
                <span className="intro-subtitle">DELETING CAMPAIGN</span>
                <h1 className="title">{campaign.name}</h1>
                {hasReasons && (
                  <p className="emp-paragraph mt-3">
                    This campaign cannot be deleted due to the reasons stated
                    below.
                  </p>
                )}
                {!hasReasons && (
                  <p className="emp-paragraph mt-3">
                    Are you sure you want to proceed with the deletion of
                    campaign {campaign.name}?
                  </p>
                )}
              </>
            )}
          </div>

          {dcr001 && campaign && (
            <section className="participants-wrapper mt-1">
              <div className="reason-wrapper">
                <span className="emp-paragraph">
                  <span className="reason-header">Reason: </span>All recruiting
                  tasks must be deleted before proceeding with the deletion of
                  the campaign.
                </span>
              </div>

              <div className="header-wrapper">
                <div className="participants-header">
                  <span className="header-text">Blocking Task</span>
                </div>
                <div className="status-header">
                  <span className="header-text">Status</span>
                </div>
              </div>
              <div className="body-wrapper">
                {dcr001.tasks.map((elem) => {
                  return (
                    <TaskRecord
                      task={elem}
                      campaign={campaign}
                      navigate={navigate}
                    />
                  );
                })}
              </div>
            </section>
          )}

          {dcr002 && campaign && (
            <section className="participants-wrapper mt-1">
              <div className="reason-wrapper">
                <span className="emp-paragraph">
                  <span className="reason-header">Reason: </span>All ongoing
                  tasks must be completed before proceeding with the deletion of
                  the campaign.
                </span>
              </div>
              <div className="header-wrapper">
                <div className="participants-header">
                  <span className="header-text">Blocking Task</span>
                </div>
                <div className="status-header">
                  <span className="header-text">Status</span>
                </div>
              </div>
              <div className="body-wrapper">
                {dcr002.tasks.map((elem) => {
                  return (
                    <TaskRecord
                      task={elem}
                      campaign={campaign}
                      navigate={navigate}
                    />
                  );
                })}
              </div>
            </section>
          )}
          {!hasReasons && (
            <section className="acknowledgement-wrapper mt-4">
              <div className="card">
                <div className="intro-wrapper">
                  <span>CONFIRMATION OF DELETION</span>
                </div>
                <div className="control-wrapper mt-2">
                  <div className="checkbox-wrapper">
                    <EmpCheckbox
                      onChange={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                      ref={ackCheckboxRef}
                      id={"acknowledgement"}
                    />
                  </div>
                  <div className="checkbox-text-wrapper">
                    <div
                      onClick={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                    >
                      <span className={ackIsChecked ? "checked" : ""}>
                        I acknowledge that once a campaign is deleted, the
                        action cannot be undone.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="button-wrapper mt-4">
            {!hasReasons && (
              <EmpButton
                disabled={!ackIsChecked}
                isFullWidth={false}
                buttonStyle="danger"
                ref={submitBtnRef}
                onSubmit={deleteCampaign}
                text={"Delete"}
              />
            )}
          </section>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default DeleteCampaignModal;

const TaskRecord = (props: {
  task: DeleteCampaignReasonTaskDto;
  navigate: NavigateFunction;
  campaign: CampaignDto;
}) => {
  const { task, navigate, campaign } = props;
  return (
    <button
      onClick={() => {
        navigate(`/brand/campaign-details/${campaign.id}/task/${task.id}`);
      }}
      className="task-record emp-button-reset"
    >
      <div className="row-wrapper">
        <div className="task-wrapper">
          <div className="task-pic-wrapper">
            <div className="task-platform-section">
              {task.platform === TASK_TYPE.FACEBOOK && (
                <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={16} />
              )}
              {task.platform === TASK_TYPE.TIKTOK && (
                <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={16} />
              )}
              {task.platform === TASK_TYPE.INSTAGRAM && (
                <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={16} />
              )}
              {task.platform === TASK_TYPE.GENERAL && (
                <TargetIcon backgroundColor={Color.NEUTRAL[0]} size={18} />
              )}
            </div>
            <img
              className="task-campaign-img-section"
              src={task.pictureUrl}
              alt="campaign"
            />
          </div>
          <div className="info-wrapper">
            <span className="creator-name">{task.name}</span>
            <span className="emp-paragraph task-description-lbl">
              {task.description}
            </span>
          </div>
        </div>
        <div className="status-section">
          {task.status === TASK_STATUS.ONGOING && (
            <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
          )}
          {task.status === TASK_STATUS.RECRUITING && (
            <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
          )}
        </div>
      </div>
    </button>
  );
};
