import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import CheckIcon from "../../../../components/icon/check-icon";
import { DeliverableConversationDto } from "../../../../model/campaign/deliverable-conversation.dto";
import { Color } from "../../../../utilities/colors";
import { DateUtil } from "../../../../utilities/date";
import { FormControl } from "../../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../../../utilities/formUtils/requiredValidator";
import "./brand-chat-desktop.scss";
import UploadChatAttachmentModal, {
  UploadChatAttachmentModalRef,
} from "../../../../components/modals/upload-chat-attachment-modal";
import { DeliverableMessageDto } from "../../../../model/campaign/deliverable-message.dto";
import { ATTACHMENT_TYPE } from "../../../../constants/app.constants";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import OngoingTaskApi from "../../../../api/campaign-msvc/ongoing-task.api";
import {
  DeliverableMessageWIdDto,
  OngoingConversationMessagesType,
} from "../../../../model/campaign/deliverable-message-w-id.dto";
import PaperclipIcon from "../../../../components/icon/paperclip-icon";
import { TaskDto } from "../../../../model/campaign/task.dto";
import EmpButton from "../../../../components/shared/emp-button/emp-button";
import { EmpLoaderRef } from "../../../../components/shared/emp-loader/emp-loader";
import FileUtils from "../../../../utilities/file-util";
import FileIcon from "../../../../components/icon/file-icon";
import { useAbly, usePresence } from "ably/react";
import { ChatUtil } from "../../../../utilities/chat.util";
import useOngoingMessages from "../../../../hooks/useOngoingMessages";
import EmpPill from "../../../../components/shared/EmpPill/EmpPill";
import { PILL_COLORS } from "../../../../constants/pill-mappers.constants";

interface Props {
  conversations?: DeliverableConversationDto[];
  brandOrgId: string;
  task: TaskDto;
  onRefresh: () => void;
}

export const BrandChatDesktop = (props: Props) => {
  // Set the selected deliverables
  const { conversations, brandOrgId, task } = props;
  const creatorIdRef = useRef<string | null>();
  const [loadedConversations, setLoadedConversations] = useState<
    DeliverableConversationDto[] | undefined
  >(conversations);
  const ably = useAbly();

  const [selectedConversation, setSelectedConversation] =
    useState<DeliverableConversationDto>();
  const [chatIsLoading, setChatIsLoading] = useState(true);
  const chatLoaderRef = useRef<EmpLoaderRef>();

  // Update the conversations item with sockets
  useEffect(() => {
    if (!conversations) return;
    setLoadedConversations(conversations);
    const unsubscribeFunctions: any[] = [];
    for (const conversation of conversations) {
      const channel = ably.channels.get(conversation.id);
      channel.subscribe((message) => {
        const newMessage = message.data as DeliverableMessageWIdDto;
        const editedConversation = conversations.find(
          (elem) => elem.id === conversation.id
        );
        if (!editedConversation) return;
        editedConversation.lastMessage = ChatUtil.getLastMessage(newMessage);
        if (newMessage.senderId !== brandOrgId) {
          editedConversation.unreadCount += 1;
        }
        setLoadedConversations([...conversations]);
      });
      // Add each unsubscribe function to the array
      unsubscribeFunctions.push(() => channel.unsubscribe());
    }
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [ably, selectedConversation, conversations]);

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      let conversation = conversations[0];
      const creatorId = creatorIdRef.current ?? undefined;
      if (creatorId) {
        conversation = conversations.find(
          (elem) => elem.creator.id === creatorIdRef.current
        )!;
      }
      setSelectedConversation(conversation);
    }
  }, [conversations]);

  useEffect(() => {
    if (chatIsLoading) chatLoaderRef.current?.show();
    else chatLoaderRef.current?.hide();
  }, [chatIsLoading]);

  return (
    <div className="brand-task-chat-view">
      <div className="emp-card mt-4 chat-card no-padding">
        <div className="contacts-section">
          <div className="title-section">
            <span className="title-lbl">Conversations</span>
          </div>
          <div className="conversation-section">
            {loadedConversations?.map((elem) => {
              return (
                <div
                  key={elem.id}
                  onClick={() => {
                    setSelectedConversation(elem);
                  }}
                  className={`conversation-item ${
                    elem.id === selectedConversation?.id ? "selected" : ""
                  }`}
                >
                  <div className="profile-wrapper">
                    {elem.creator.imageType === "url" && (
                      <img
                        className="profile"
                        alt={elem.creator.fullName}
                        src={elem.creator.imageResource}
                      />
                    )}
                    {elem.creator.imageType === "avatar" && (
                      <div
                        className="profile"
                        style={{ background: elem.creator.imageResource }}
                      >
                        <span className="initials">
                          {elem.creator.initials}
                        </span>
                      </div>
                    )}
                    {elem.representativeRole === "agency" && (
                      <img
                        className="profile profile-badge"
                        alt={elem.agencyRepresentative!.companyName}
                        src={elem.agencyRepresentative!.logo}
                      />
                    )}
                  </div>
                  <div className="info-wrapper">
                    <div className="deliverable-state-wrapper">
                      <span className="person-name-lbl">
                        {elem.creator.fullName}
                      </span>
                    </div>
                    <div className="message-context-wrapper mt-1">
                      {elem.hasMessage && (
                        <span className="last-message-lbl">
                          {elem.lastMessage}
                        </span>
                      )}
                      {!elem.hasMessage && (
                        <span className="last-message-lbl">No message yet</span>
                      )}
                    </div>
                  </div>
                  {elem.unreadCount > 0 && (
                    <div className="unread-badge">{elem.unreadCount}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {selectedConversation && (
          <ChatComponent
            task={task}
            onClearReadCount={() => {
              const selectedConvo = loadedConversations!.find(
                (elem) => elem.id === selectedConversation.id
              );
              if (!selectedConvo) return;
              selectedConvo.unreadCount = 0;
              setLoadedConversations([...loadedConversations!]);
            }}
            brandOrgId={brandOrgId}
            conversation={selectedConversation}
          />
        )}
      </div>
    </div>
  );
};

interface ChatComponentProps {
  brandOrgId: string;
  onClearReadCount: () => void;
  conversation: DeliverableConversationDto;
  task: TaskDto;
}
const ChatComponent = (props: ChatComponentProps) => {
  const { brandOrgId, conversation, onClearReadCount, task } = props;
  const ably = useAbly();

  const uploadChatAttachmentRef = useRef<UploadChatAttachmentModalRef>();
  const chatBodyElemRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const hasNewMessageFromSender = useRef(false);

  const [isUserOnline, setIsUserOnline] = useState(false);

  const loadedMessagesRef = useRef<number>(0);

  const [selectedConversationMessages, setSelectedConversationMessage] =
    useState<DeliverableMessageWIdDto[]>();

  const { formattedMessages } = useOngoingMessages(
    selectedConversationMessages
  );
  const hasMoreRef = useRef(false);
  const sendMessageInputElemRef = useRef<HTMLInputElement>(null);

  const [isloadingNextBatchLoader, setLoadingNextBatchLoader] = useState(false);
  const loadingNextMessageRef = useRef(false);

  const [form, setForm] = useState<IFormGroup>({
    message: new FormControl("text", [new RequiredValidator()]),
  });

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default "Enter" behavior (submitting form)
      sendMessage();
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }
    if (e.key === "Enter" && e.shiftKey) {
      const textArea = textareaRef.current!;
      const selectionStart = textArea.selectionStart;
      const selectionEnd = textArea.selectionEnd;
      const currentValue = textArea.value;
      const newValue =
        currentValue.substring(0, selectionStart) +
        "\n" +
        currentValue.substring(selectionEnd);
      textArea.value = newValue;
    }
  };

  /**
   * Automatically resizes a textarea element to match its content height.
   *
   * This function resets the textarea's height to 'auto' and then sets it to match the
   * scrollHeight, which adjusts the height to fit the content.
   *
   * @function
   * @returns {void}
   */
  function autoResizeTextArea() {
    textareaRef.current!.style.height = "auto"; // Reset the height to auto
    textareaRef.current!.style.height =
      textareaRef.current!.scrollHeight + "px"; // Set the height to match the content
  }

  const markAllMessagesForBrand = useCallback(
    async (conversationId: string) => {
      try {
        await OngoingTaskApi.markAllMessagesForBrand(conversationId);
        // clearing read count;
        onClearReadCount();
      } catch (error) {
        console.error("Unable to mark all messages", error);
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Unable to mark all messages as read"
        );
      }
    },
    []
  );

  const getMessageInConversation = useCallback(
    async (conversationId: string, offset?: number) => {
      try {
        loadingNextMessageRef.current = true;
        const recordsOffset = offset ?? 0;
        const resp = await OngoingTaskApi.fetchMessageInConversation(
          conversationId,
          recordsOffset
        );
        const messages = resp.data.messages;
        hasMoreRef.current = resp.data.hasMore;

        setSelectedConversationMessage((prev) => {
          if (!prev) return messages;
          return [...prev, ...messages];
        });
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch conversations"
        );
      } finally {
        loadingNextMessageRef.current = false;
        setLoadingNextBatchLoader(false);
      }
    },
    []
  );

  useEffect(() => {
    markAllMessagesForBrand(conversation.id);
    const newChannel = ably.channels.get(conversation.id);
    newChannel.subscribe((message) => {
      const newMessage = message.data as DeliverableMessageWIdDto;
      if (message.data.senderId !== brandOrgId) {
        hasNewMessageFromSender.current = true;
      }

      setSelectedConversationMessage((prev) => {
        let messages = prev ?? [];
        messages.unshift(newMessage);
        return [...messages];
      });
    });

    // Unsubscribe when the component unmounts or conversation.id changes
    return () => {
      newChannel.unsubscribe();
    };
  }, [ably, conversation, markAllMessagesForBrand]);

  useEffect(() => {
    setSelectedConversationMessage(undefined);
    loadedMessagesRef.current = 0;
    getMessageInConversation(conversation.id);
  }, [getMessageInConversation, conversation.id]);

  useEffect(() => {
    if (!chatBodyElemRef.current) return;
    const handleScroll = () => {
      if (loadingNextMessageRef.current || !hasMoreRef.current) return;

      const calcHeight =
        chatBodyElemRef.current!.scrollHeight -
        chatBodyElemRef.current!.clientHeight;
      const treshold =
        calcHeight - Math.abs(chatBodyElemRef.current!.scrollTop);
      if (treshold < 100) {
        getMessageInConversation(conversation!.id, loadedMessagesRef.current);
        setLoadingNextBatchLoader(true);
      }
    };

    // Add scroll event listener
    chatBodyElemRef.current.addEventListener("scroll", handleScroll);
    // Remove event listener on cleanup
    return () => {
      chatBodyElemRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [conversation.id]);

  const sendMessage = async () => {
    try {
      // Dont send message if form control has errors;
      if (FormGroupUtil.validate(form) === false) return;

      const request: DeliverableMessageDto = {
        representativeId: conversation.representativeId,
        representativeRole: conversation.representativeRole,
        taskId: task.id,
        agencyOrgId: conversation.agencyRepresentative?.id,
        brandOrgId: conversation.brand.id,
        creatorUserId: conversation.creator.id!,

        conversationId: conversation.id,
        senderId: conversation.brand.id,
        senderRole: "brand",
        recipientId: conversation.representativeId,
        recipientRole: conversation.representativeRole,
        text: form.message.getValue(),
        attachmentType: ATTACHMENT_TYPE.NONE,
      };
      // Don't make much sense to refetch the entire message body.
      await OngoingTaskApi.sendMessage(request);
      form.message.setValue("");
      textareaRef.current!.value = "";
      autoResizeTextArea();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to send message");
    }
  };

  useEffect(() => {
    loadedMessagesRef.current = selectedConversationMessages
      ? selectedConversationMessages.length
      : 0;
  }, [selectedConversationMessages]);

  // Check if user is online
  const { presenceData } = usePresence("is_online", brandOrgId);
  useEffect(() => {
    if (!conversation) return;
    if (
      presenceData.find((elem) => elem.data === conversation.representativeId)
    ) {
      setIsUserOnline(true);
    } else setIsUserOnline(false);
  }, [presenceData, conversation]);

  return (
    <div
      className="chat-section"
      onClick={() => {
        if (hasNewMessageFromSender.current) {
          onClearReadCount();
          hasNewMessageFromSender.current = false;
        }
      }}
    >
      <UploadChatAttachmentModal
        task={task}
        ref={uploadChatAttachmentRef}
        onSave={() => {}}
      />
      <div className="chat-header">
        <div className="chat-header-profile-wrapper">
          {conversation && (
            <div className="profile-wrapper">
              {conversation.creator.imageType === "url" && (
                <img
                  className="profile"
                  alt={conversation.creator.fullName}
                  src={conversation.creator.imageResource}
                />
              )}
              {conversation.creator.imageType === "avatar" && (
                <div
                  className="profile"
                  style={{ background: conversation.creator.imageResource }}
                >
                  <span className="initials">
                    {conversation.creator.initials}
                  </span>
                </div>
              )}
            </div>
          )}
          {conversation && (
            <div className="info-wrapper">
              <div className="person-name-wrapper">
                <span className="person-name-lbl">
                  {conversation.creator.fullName}
                </span>
                <div
                  className={`activity-status ${isUserOnline ? "online" : ""}`}
                ></div>
              </div>
              {isUserOnline && <span className="last-updated-lbl">Online</span>}
              {!isUserOnline && (
                <span className="last-updated-lbl">Offline</span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="chat-body" ref={chatBodyElemRef}>
        {/* <EmpLoader ref={chatLoaderRef} background="transparent" /> */}
        {selectedConversationMessages?.length === 0 && (
          <div className="empty-wrapper">
            <img
              alt="empty chat"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/chat-empty.png"
            />
            <h2>Start the Conversation</h2>
            <p className="emp-paragraph mt-2">
              Start the conversation! Say something to get the chat going.
            </p>
          </div>
        )}

        {formattedMessages.map((elem) => {
          return (
            <Fragment key={elem.id}>
              {elem.type === "message" && (
                <>
                  {brandOrgId === elem.senderId && (
                    <div
                      key={elem.id}
                      className="bubble-wrapper my-bubble-wrapper"
                    >
                      <div className="my-bubble">
                        {elem.attachmentType === ATTACHMENT_TYPE.IMAGES && (
                          <div className="image-wrapper">
                            {elem.imageFiles!.map((img, index) => {
                              return (
                                <div
                                  key={img.link}
                                  className={`image-inner-wrapper ${
                                    elem.imageFiles!.length % 2 > 0 &&
                                    index === elem.imageFiles!.length - 1
                                      ? "full-width"
                                      : "half-width"
                                  }`}
                                >
                                  <img
                                    className={`single-img ${
                                      elem.imageFiles!.length > 1
                                        ? "square-img"
                                        : ""
                                    }`}
                                    alt={`uploaded ${index}`}
                                    src={img.link}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {elem.attachmentType === ATTACHMENT_TYPE.VIDEO && (
                          <div className="video-wrapper">
                            <video
                              controls
                              muted
                              loop
                              src={elem.video!.link}
                            ></video>
                          </div>
                        )}
                        {elem.attachmentType === ATTACHMENT_TYPE.FILE && (
                          <div
                            className="file-wrapper"
                            onClick={() => {
                              FileUtils.handleFileDownload(
                                elem.file!.name,
                                elem.file!.link
                              );
                            }}
                          >
                            <div className="file-indicator my-bubble-file">
                              <FileIcon
                                backgroundColor={Color.PRIMARY[200]}
                                size={18}
                              />
                            </div>
                            <div className="file-info-wrapper my-bubble-file">
                              <span className="filename-lbl">
                                {elem.file!.name}
                              </span>
                              <span className="size-lbl">
                                {FileUtils.convertBytesToReadableSize(
                                  elem.file!.size
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        <span
                          dangerouslySetInnerHTML={{ __html: elem.text }}
                        ></span>
                        <div className="timestamp-wrapper">
                          <span>
                            {DateUtil.toReadable12HrTime(elem.createdAt)}
                          </span>
                          <CheckIcon
                            backgroundColor={Color.PRIMARY[150]}
                            size={12}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {conversation!.representativeId === elem.senderId && (
                    <div
                      key={elem.id}
                      className="bubble-wrapper recipient-bubble-wrapper"
                    >
                      <div className="recipient-bubble">
                        {elem.attachmentType === ATTACHMENT_TYPE.IMAGES && (
                          <div className="image-wrapper">
                            {elem.imageFiles!.map((img, index) => {
                              return (
                                <div
                                  key={img.link}
                                  className={`image-inner-wrapper ${
                                    elem.imageFiles!.length % 2 > 0 &&
                                    index === elem.imageFiles!.length - 1
                                      ? "full-width"
                                      : "half-width"
                                  }`}
                                >
                                  <img
                                    className="single-img"
                                    alt={`uploaded ${index}`}
                                    src={img.link}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {elem.attachmentType === ATTACHMENT_TYPE.VIDEO && (
                          <div className="video-wrapper">
                            <video
                              controls
                              muted
                              loop
                              src={elem.video!.link}
                            ></video>
                          </div>
                        )}
                        {elem.attachmentType === ATTACHMENT_TYPE.FILE && (
                          <div
                            className="file-wrapper"
                            onClick={() => {
                              FileUtils.handleFileDownload(
                                elem.file!.name,
                                elem.file!.link
                              );
                            }}
                          >
                            <div className="file-indicator recipient-bubble-file">
                              <FileIcon
                                backgroundColor={Color.NEUTRAL[600]}
                                size={18}
                              />
                            </div>
                            <div className="file-info-wrapper recipient-bubble-file">
                              <span className="filename-lbl">
                                {elem.file!.name}
                              </span>
                              <span className="size-lbl">
                                {FileUtils.convertBytesToReadableSize(
                                  elem.file!.size
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                        <span
                          dangerouslySetInnerHTML={{ __html: elem.text }}
                        ></span>
                        <div className="timestamp-wrapper">
                          <span>
                            {DateUtil.toReadable12HrTime(elem.createdAt)}
                          </span>
                          <CheckIcon
                            backgroundColor={Color.NEUTRAL[500]}
                            size={12}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {elem.type === "date" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <EmpPill text={elem.date} {...PILL_COLORS.gray} />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      <div className="chat-form-wrapper">
        {conversation && (
          <div
            className="attachment-btn"
            onClick={() => {
              uploadChatAttachmentRef.current?.show("brand", conversation);
            }}
          >
            <PaperclipIcon backgroundColor={Color.NEUTRAL[300]} size={18} />
          </div>
        )}

        <textarea
          style={{ height: 17 }}
          ref={textareaRef}
          rows={1}
          placeholder="Enter message..."
          onChange={(e) => {
            form.message.setValue(e.target.value);
          }}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onKeyUp={handleKeyUp}
          onInput={() => {
            autoResizeTextArea();
          }}
        />
        <EmpButton
          buttonHeight="sm"
          isFullWidth={false}
          text={"Send"}
          onSubmit={sendMessage}
        />
      </div>
    </div>
  );
};
