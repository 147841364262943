import "./manage-wallet-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpModal from "../../shared/emp-modal/emp-modal";
import { empDelay } from "../../../utilities/delay";
import XCloseIcon from "../../icon/x-close-icon";
import { Color } from "../../../utilities/colors";
import {
  SavedWalletInformation,
  WalletInformation,
} from "../../../hooks/useWalletAddressManager";
import { ManageWalletFormView } from "./manage-wallet-form-view";
import { ManageWalletCompletedView } from "./manage-wallet-completed-view";

export interface EmpManageWalletModalRef {
  show: (walletInformation: WalletInformation) => void;
  dismiss: () => void;
}

interface Props {
  onSave: () => void;
}

type ViewMode = "form" | "completed";

const EmpManageWalletModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ViewMode>("form");
  const [walletInformation, setWalletInformation] =
    useState<WalletInformation>();
  const [completedWalletInformation, setCompletedWalletInformation] =
    useState<SavedWalletInformation>();
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (walletInformation: WalletInformation) => {
    setWalletInformation(walletInformation);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setViewMode("form");
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      bodyPadding={false}
      verticalPadding={false}
      showFooter={false}
      showFooterBorder={false}
      shouldOverflow={false}
      onClose={dismiss}
      showHeader={false}
      showHeaderBorder={false}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
      size="sm"
    >
      {/* This is body */}
      <div className="emp-manage-wallet-modal">
        {walletInformation && viewMode === "form" && (
          <ManageWalletFormView
            walletInformation={walletInformation}
            onSave={(savedWalletInformation: SavedWalletInformation) => {
              setViewMode("completed");
              setCompletedWalletInformation(savedWalletInformation);
              onSave();
            }}
            onDismiss={dismiss}
          />
        )}
        {completedWalletInformation && viewMode === "completed" && (
          <ManageWalletCompletedView
            savedWalletInformation={completedWalletInformation}
            onDismiss={dismiss}
          />
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default EmpManageWalletModal;
