import "./select-facebook-post-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import useSelfAdjustingCards from "../../hooks/useSelfAdjustingCards";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import EmpLoaderV2 from "../shared/emp-loader-v2/emp-loader-v2";
import { empDelay } from "../../utilities/delay";
import FacebookApi from "../../api/social-integration-msvc/facebook.api";
import { FbMediaInfoRespDto } from "../../model/facebook/fb-media-info-resp.dto";
import EyeIcon from "../icon/eye-icon";
import ShareIcon from "../icon/share-icon";

export interface SelectFacebookPostModalRef {
  show: (creatorId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: (igMediaId: string) => void;
}

const SelectFacebookPostModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const creatorIdRef = useRef<string>();

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const [eventFlag, setEventFlag] = useState(false);
  const { cardWidth } = useSelfAdjustingCards(
    200,
    10,
    resizeDivRef,
    1,
    eventFlag
  );
  const [fbBasicInfoDto, setFbBasicInfoDto] = useState<FbMediaInfoRespDto>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const delaySet = async () => {
      await empDelay(100);
      setEventFlag((prevFlag: boolean) => {
        return !prevFlag;
      });
    };
    delaySet();
  }, [visible]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchFacebookPost = async (creatorId: string) => {
    try {
      setLoading(true);
      const resp = await FacebookApi.basicProfile(creatorId);
      setFbBasicInfoDto(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch Facebook Info"
      );
    } finally {
      setLoading(false);
    }
  };

  const show = async (creatorId: string) => {
    creatorIdRef.current = creatorId;
    fetchFacebookPost(creatorId);
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"lg"}
    >
      {/* This is body */}
      <div className="emp-select-facebook-post-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">Select Facebook Post</h2>
          <p className="description">
            Please select one of your Facebook posts to provide proof of work
            for your participation in this task.
          </p>
        </div>
        <EmpLoaderV2 isLoading={loading} />
        <div className="post-section">
          <div className="post-wrapper" ref={resizeDivRef}>
            {loading && (
              <>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
                <div
                  style={{ width: cardWidth }}
                  className={`empty-card emp-shimmer`}
                ></div>
              </>
            )}
            {!loading &&
              fbBasicInfoDto &&
              fbBasicInfoDto.media.map((elem) => {
                return (
                  <div
                    onClick={() => {
                      onSave(elem.id);
                      dismiss();
                    }}
                    key={elem.permalink}
                    style={{ width: cardWidth }}
                    className={`ig-card`}
                  >
                    {elem.mediaType !== "mobile_status_update" && (
                      <div className="img-wrapper">
                        <img
                          referrerPolicy="no-referrer"
                          src={elem.mediaUrl}
                          alt={`facebook post`}
                        />
                      </div>
                    )}
                    {elem.mediaType === "mobile_status_update" && (
                      <div className="text-wrapper">
                        <p className="caption">{elem.caption}</p>
                      </div>
                    )}
                    <div className="info-section">
                      <div className="metrics-wrapper">
                        <div className="metric">
                          <EyeIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span className="metric-value">
                            {elem.impressions}
                          </span>
                        </div>
                        <div className="metric">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span className="metric-value">{elem.likeCount}</span>
                        </div>
                        <div className="metric">
                          <MessageIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.commentsCount}
                          </span>
                        </div>
                        <div className="metric">
                          <ShareIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.shareCount}
                          </span>
                        </div>
                      </div>
                      <p className="caption">{elem.caption}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default SelectFacebookPostModal;
