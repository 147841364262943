import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { empDelay } from "../../utilities/delay";
import EmpButton from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./view-deliverable-comparison-modal.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { TaskDto } from "../../model/campaign/task.dto";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import { DeliverableChangelogComparison } from "../../model/campaign/deliverable-changelog-comparison.dto";
import EmpEditorView from "../shared/emp-editor/emp-editor-viewer";
import { NegotiationExtendedDto } from "../../model/campaign/negotiation-extended.dto";

export interface ViewDeliverableComparisonModalRef {
  show: (latestNegotiation: NegotiationExtendedDto) => void;
  dismiss: () => void;
}

interface Props {}

const ViewDeliverableComparisonModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });
  const [deliverableComparison, setDeliverableComparison] =
    useState<DeliverableChangelogComparison>();
  const fetchDeliverableChangelogComparison = useCallback(
    async (negotiationId: string) => {
      try {
        const resp =
          await NegotiationApi.getDeliverableChangelogComparison(negotiationId);
        console.log(resp.data);
        setDeliverableComparison(resp.data);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator info status"
        );
      }
    },
    []
  );

  const show = async (latestNegotiation: NegotiationExtendedDto) => {
    try {
      setVisible(true);
      await fetchDeliverableChangelogComparison(latestNegotiation.id);
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Error occurred in update negotiation changelog modal"
      );
    }
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      shouldOverflow={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"lg"}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
    >
      <div className="emp-view-deliverable-comparison-modal">
        <section className="emp-modal-common-header">
          <div className="emp-modal-hstack-wrapper">
            <h2>Deliverable Changelog</h2>
          </div>
          <p className="emp-paragraph mt-2">
            Review the table below to see the differences between the previous
            and updated deliverables.
          </p>
        </section>

        <section className="comparison-section">
          <div className="comparison-column">
            <div className="column-header-wrapper first">Before</div>
            <div className="column-body-wrapper first">
              {deliverableComparison &&
                deliverableComparison.oldVersionDeliverables.map(
                  (elem, index) => {
                    return (
                      <article className="deliverable-card">
                        <span key={index} className="deliverable-indicator">
                          DELIVERABLE {index + 1}
                        </span>
                        <h3 className="deliverable-name-lbl">{elem.name}</h3>
                        <div className="mt-1">
                          <EmpEditorView value={elem.description} />
                        </div>
                      </article>
                    );
                  }
                )}
              {!deliverableComparison &&
                [0, 1].map((elem) => {
                  return (
                    <article className="deliverable-card-shimmer">
                      <div className="deliverable-number-shimmer emp-shimmer"></div>
                      <div className="deliverable-name-shimmer emp-shimmer"></div>
                      <div className="deliverable-description-shimmer emp-shimmer"></div>
                    </article>
                  );
                })}
            </div>
          </div>
          <div className="comparison-column">
            <div className="column-header-wrapper">After</div>
            <div className="column-body-wrapper">
              {deliverableComparison &&
                deliverableComparison.latestVersionDeliverables.map(
                  (elem, index) => {
                    return (
                      <article className="deliverable-card">
                        <span key={index} className="deliverable-indicator">
                          DELIVERABLE {index + 1}
                        </span>
                        <h3 className="deliverable-name-lbl">{elem.name}</h3>
                        <div className="mt-1">
                          <EmpEditorView value={elem.description} />
                        </div>
                      </article>
                    );
                  }
                )}
              {!deliverableComparison &&
                [0, 1].map((elem) => {
                  return (
                    <article className="deliverable-card-shimmer">
                      <div className="deliverable-number-shimmer emp-shimmer"></div>
                      <div className="deliverable-name-shimmer emp-shimmer"></div>
                      <div className="deliverable-description-shimmer emp-shimmer"></div>
                    </article>
                  );
                })}
            </div>
          </div>
        </section>
        {/* Button Wrapper Section */}
        <section className="button-wrapper-section">
          <EmpButton
            buttonStyle="secondary"
            isFullWidth={false}
            onSubmit={dismiss}
            text={"Cancel"}
          />
        </section>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default ViewDeliverableComparisonModal;
