import { MutableRefObject, useCallback, useMemo, useState } from "react";

import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../../utilities/toast-utils";
import FacebookUtils from "../../facebook";
import SocialMediaApi from "../../../../api/social-integration-msvc/social-media.api";
import { SOCIAL_MEDIA_TYPE } from "../../../../constants/app.constants";
import { InstagramConnectedAccountDto } from "../../../../model/social-media/instagram-connected-account.dto";
import { InstagramAccountSelectionModalRef } from "../instagram-account-selection-modal";

export type FacebookLoginAttributes = {
  id: string;
  access_token: string;
};

export const useInstagramLogin = (
  instagramLoginModal: MutableRefObject<
    InstagramAccountSelectionModalRef | undefined
  >
) => {
  const facebookUtil = useMemo(() => new FacebookUtils(), []);
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * Logs the user out of their Facebook account.
   *
   * @function
   * @returns {Promise<void>} A promise that resolves when the logout process is complete.
   */
  const facebookLogout = useCallback(async () => {
    await facebookUtil.logout();
  }, [facebookUtil]);

  /**
   * Binds the user's Facebook account to the application using the provided Facebook login attributes.
   *
   * @function
   * @param {FacebookLoginAttributes} fbPageSpec - The attributes required for Facebook login.
   * @param {string} [platform] - Optional platform information.
   * @returns {Promise<boolean | void>} A promise that resolves to true if the account is successfully bound, or void if an error occurs.
   */
  const bindInstagramAccount = useCallback(
    async (fbPageSpec: FacebookLoginAttributes, platform?: string) => {
      try {
        setLoading(true);
        const resp = await SocialMediaApi.smLogin({
          socialMediaPlatform: SOCIAL_MEDIA_TYPE.INSTAGRAM,
          accessToken: fbPageSpec.access_token,
          socialMediaUserId: fbPageSpec.id,
        });
        if (resp.data.status === "success") {
          ToastUtils.success("Success", "Succesfully binded your FB account");
          instagramLoginModal.current?.dismiss();
          facebookLogout();
          return true;
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to bind Facebook Page"
        );
      } finally {
        setLoading(false);
      }
    },
    [facebookLogout, instagramLoginModal]
  );

  /**
   * Logs the user into their Facebook account and handles the binding process of their Facebook Page.
   *
   * @function
   * @returns {Promise<void>} A promise that resolves when the login and binding process is complete.
   */
  const instagramLogin = useCallback(async () => {
    try {
      setLoading(true);
      const response = await facebookUtil.instagramLogin("creator");
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookInstagramConnectedPage =
          (await facebookUtil.getInstagramConnectedAccount(
            userId
          )) as InstagramConnectedAccountDto;
        if (facebookInstagramConnectedPage.data.length === 0) {
          ToastUtils.error(
            "No Instagram Accounts Found",
            "Instagram needs to bind with a Facebook Page"
          );
          return;
        } //
        if (facebookInstagramConnectedPage.data.length > 0) {
          instagramLoginModal.current?.show(
            facebookInstagramConnectedPage,
            "Instagram"
          );
          return;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to Facebook"
      );
    } finally {
      setLoading(false);
    }
  }, [facebookUtil, instagramLoginModal]);

  return {
    isLoading,
    instagramLogin,
    bindInstagramAccount,
    facebookLogout,
  };
};
