import { ENV_CONSTANTS, getEnv } from "./envConstants";

const userMsvcUrl = ENV_CONSTANTS[getEnv()].userMsvc;
const adminMsvcUrl = ENV_CONSTANTS[getEnv()].adminMsvc;
const referralAndSupportMsvcUrl =
  ENV_CONSTANTS[getEnv()].referralAndSupportMsvc;
const paymentMsvcUrl = ENV_CONSTANTS[getEnv()].paymentMsvc;
const campaignMsvcUrl = ENV_CONSTANTS[getEnv()].campaignMsvc;
const notificationMsvcUrl = ENV_CONSTANTS[getEnv()].notificationMsvc;
const smmMsvcUrl = ENV_CONSTANTS[getEnv()].smmMsvc;

export const ENDPOINTS = {
  userMicroservice: {
    search: {
      searchUsers: (role: string) => `${userMsvcUrl}/search/${role}`,
    },
    onboarding: {
      fetchInitialOnboardingDetails: (intent: string, id: string) =>
        `${userMsvcUrl}/onboarding/${intent}/${id}/initial`,
      checkVerificationEmailStatus: `${userMsvcUrl}/onboarding/check-verification`,
      getAgencySpec: `${userMsvcUrl}/onboarding/agency/spec`,
      saveAgencySpec: `${userMsvcUrl}/onboarding/agency/spec`,
      saveBrandSpec: `${userMsvcUrl}/onboarding/brand/spec`,
      saveCreatorSpec: `${userMsvcUrl}/onboarding/creator/spec`,
      inviteMember: `${userMsvcUrl}/onboarding/member/invite`,
      inviteExistingCreator: `${userMsvcUrl}/onboarding/creator/invite/existing`,
      inviteNewCreator: `${userMsvcUrl}/onboarding/creator/invite/new`,
      resendCreatorInvitation: `${userMsvcUrl}/onboarding/creator/resend-invite`,
      revokeCreatorInvitation: `${userMsvcUrl}/onboarding/creator/revoke-invite`,
      creatorRejectInvitation: `${userMsvcUrl}/onboarding/creator/reject-invite`,
      creatorAcceptInvitation: `${userMsvcUrl}/onboarding/creator/accept-invite`,
      fetchCreatorOnboardingStatus: (id: string) =>
        `${userMsvcUrl}/onboarding/creator/invitation/${id}/status`,
      mapUserOnboarding: `${userMsvcUrl}/onboarding/map-user`,
      getCreatorQuickLinkInfoByUserId: `${userMsvcUrl}/onboarding/creator/quick-link`,
      getCreatorQuickLinkInfoByLinkId: (link: string) =>
        `${userMsvcUrl}/onboarding/creator/quick-link/${link}`,
      sendWelcomeNotification: `${userMsvcUrl}/onboarding/welcome-notification`,
      getCreatorInvitationEligibility: (creatorId: string) =>
        `${userMsvcUrl}/onboarding/creator/${creatorId}/invite-eligibility`,
    },
    emailOtp: {
      requestEmailOtp: `${userMsvcUrl}/email-otp/create`,
      verifyEmailOtp: `${userMsvcUrl}/email-otp/verify`,
      requestEmailOtpByEmail: `${userMsvcUrl}/email-otp/create/email`,
      verifyEmailOtpByEmail: `${userMsvcUrl}/email-otp/verify/email`,
    },
    walletAddress: {
      base: `${userMsvcUrl}/wallet-address`,
      fetchAvailableNetworks: `${userMsvcUrl}/wallet-address/networks`,
    },
    user: {
      root: `${userMsvcUrl}/user`,
      image: `${userMsvcUrl}/user/image`,
      creatorImage: `${userMsvcUrl}/user/creator/image`,
      creator: (creatorId: string) =>
        `${userMsvcUrl}/user/creator/${creatorId}`,
      creatorByEmail: (email: string) =>
        `${userMsvcUrl}/user/creator/email/${email}`,
      creatorAgency: (id: string) => `${userMsvcUrl}/user/creator/${id}/agency`,
      creatorListing: `${userMsvcUrl}/user/creator/listing`,
      orgRoot: `${userMsvcUrl}/user/org-root`,
      checkUserExist: (email: string) => `${userMsvcUrl}/user/email/${email}`,
      leaveAgency: (organisationId: string) =>
        `${userMsvcUrl}/user/creator/leave-agency/${organisationId}`,
    },
    organisation: {
      fetchInitialInvitationDetails: (id: string) =>
        `${userMsvcUrl}/organisation/${id}/initial`,
      checkVerificationEmailStatus: `${userMsvcUrl}/organisation/check-verification`,
      getUsersInOrganisation: `${userMsvcUrl}/organisation/all`,
      fetchTalentInvitation: `${userMsvcUrl}/organisation/talent/invitations`,
      fetchAgencyCreators: `${userMsvcUrl}/organisation/creators`,
      fetchAgencyCreatorsByAgencyId: (agencyOrgId: string) =>
        `${userMsvcUrl}/organisation/${agencyOrgId}/creators`,
      dismissCreator: (orgId: string, creatorId: string) =>
        `${userMsvcUrl}/organisation/${orgId}/talents/${creatorId}/dismiss`,
      fetchAgencyOrgById: (orgId: string) =>
        `${userMsvcUrl}/organisation/agency/${orgId}`,
      grantBrandRoleToAgency: `${userMsvcUrl}/organisation/agency/to-brand`,
      orgOnboardedMemberInvitation: (invitationId: string) =>
        `${userMsvcUrl}/organisation/invitation/${invitationId}`,
    },
    profile: {
      fetchProfile: (id: string) => `${userMsvcUrl}/profile/organisation/${id}`,
      updateAboutUs: (id: string) =>
        `${userMsvcUrl}/profile/organisation/${id}/about-us`,
      updateCompanyName: (id: string) =>
        `${userMsvcUrl}/profile/organisation/${id}/company-name`,
      saveProfileAttachment: (id: string) =>
        `${userMsvcUrl}/profile/organisation/${id}/profile-attachment`,
      deleteProfileAttachment: (id: string) =>
        `${userMsvcUrl}/profile/profile-attachment/${id}`,
      saveProfilePicture: `${userMsvcUrl}/profile/profile-pictures`,
    },

    agencyTask: {
      fetchAgencyTask: (id: string) =>
        `${userMsvcUrl}/agency-task/organisation/${id}`,
      markAgencyTaskAsComplete: (organisationId: string, taskId: number) =>
        `${userMsvcUrl}/agency-task/organisation/${organisationId}/task-id/${taskId}`,
    },
    task: {
      markTaskAsComplete: `${userMsvcUrl}/task/complete`,
      markTaskAsIncomplete: `${userMsvcUrl}/task/revert`,
    },
    creator: {
      checkCreatorInfoCompletion: (id: string) =>
        `${userMsvcUrl}/creator/status/${id}`,
      fetchCreatorInfoOptions: `${userMsvcUrl}/creator/options`,
      root: `${userMsvcUrl}/creator`,
      fetchCreator: (id: string) => `${userMsvcUrl}/creator/${id}/creator-info`,
      fetchCreatorListing: `${userMsvcUrl}/creator/listing`,
      fetchCreatorRecruitmentListing: (taskType: string) =>
        `${userMsvcUrl}/creator/recruitment/${taskType}`,
      getCreatorRecruitmentCardById: (userId: string) =>
        `${userMsvcUrl}/creator/${userId}/recruitment`,
      getCreatorPartnerships: (userId: string) =>
        `${userMsvcUrl}/creator/${userId}/partnership`,
      creatorPartnershipById: (invitationId: string) =>
        `${userMsvcUrl}/creator/partnership/${invitationId}`,
      getCreatorTaskRepresentatives: (userId: string) =>
        `${userMsvcUrl}/creator/${userId}/task-representatives`,
    },
    rateCard: {
      fetchRateCard: (userId: string, platform: string) =>
        `${userMsvcUrl}/rate-card/creator/${userId}/platform/${platform}`,
      fetchOneRateCard: (userId: string, platform: string) =>
        `${userMsvcUrl}/rate-card/creator/${userId}/platform/${platform}/edit`,
      fetchReadRateCard: (userId: string, orgId: string, platform: string) =>
        `${userMsvcUrl}/rate-card/creator/${userId}/organisation/${orgId}/platform/${platform}`,
      fetchRateCardWRole: (userId: string, platform: string, role: string) =>
        `${userMsvcUrl}/rate-card/creator/${userId}/platform/${platform}/role/${role}`,
      createRateCardRequest: `${userMsvcUrl}/rate-card/request`,
      root: `${userMsvcUrl}/rate-card`,
    },
    rateCardRequest: {
      root: `${userMsvcUrl}/rate-card-request`,
      getPendingBrandRequests: `${userMsvcUrl}/rate-card-request/pending`,
      getPendingBrandRequestsCount: `${userMsvcUrl}/rate-card-request/pending-count`,
    },
    socialMedia: {
      smLogin: `${userMsvcUrl}/social-media/talent/login`,
      getMetric: `${userMsvcUrl}/social-media/talent/get-metrics`,
      getSocialMediaSpec: `${userMsvcUrl}/social-media/creator/get-specs`,
      getSocialMediaSpecByCreatorId: (creatorId: string) =>
        `${userMsvcUrl}/social-media/creator/${creatorId}/get-specs`,
      unlinkSocialMedia: (platform: string) =>
        `${userMsvcUrl}/social-media/creator/unlink-social/${platform}`,
    },
    instagram: {
      basicProfile: (creatorId: string) =>
        `${userMsvcUrl}/instagram/profile/${creatorId}`,
      paginatedMedia: (creatorId: string) =>
        `${userMsvcUrl}/instagram/profile/${creatorId}/paginated-media`,
      getStory: (creatorId: string) =>
        `${userMsvcUrl}/instagram/profile/${creatorId}/story`,
      getMediaPostById: (creatorId: string, mediaId: string) =>
        `${userMsvcUrl}/instagram/profile/${creatorId}/post/media/${mediaId}`,
      getMediaStoryById: (creatorId: string, mediaId: string) =>
        `${userMsvcUrl}/instagram/profile/${creatorId}/story/media/${mediaId}`,
    },
    facebook: {
      basicProfile: (talentId: string) =>
        `${userMsvcUrl}/facebook/profile/${talentId}`,
      getMediaById: (creatorId: string, mediaId: string) =>
        `${userMsvcUrl}/facebook/profile/${creatorId}/media/${mediaId}`,
    },
    x: {
      auth: `${userMsvcUrl}/social-media/x/auth`,
      getToken: `${userMsvcUrl}/social-media/x/auth/token`,
      getProfileWithTweets: (id: string) =>
        `${userMsvcUrl}/social-media/x/profile/${id}`,
      getBasicProfile: (id: string) =>
        `${userMsvcUrl}/social-media/x/profile/${id}/basic`,
      getMultipleTweets: (creatorId: string, tweetUrls: string[]) =>
        `${userMsvcUrl}/social-media/x/profile/${creatorId}/tweet?${tweetUrls
          .map((elem) => `tweet_url=${elem}`)
          .join("&")}`,
    },
    tiktok: {
      auth: `${userMsvcUrl}/tiktok/auth`,
      getToken: `${userMsvcUrl}/tiktok/auth/token`,
      getMediaById: (creatorId: string, mediaId: string) =>
        `${userMsvcUrl}/tiktok/profile/${creatorId}/media/${mediaId}`,
      basicProfile: (talentId: string) =>
        `${userMsvcUrl}/tiktok/profile/${talentId}`,
    },
    plan: {
      base: `${userMsvcUrl}/plan`,
      fetchUserPlans: (userId: string) => `${userMsvcUrl}/plan/user/${userId}`,
    },
    subscription: {
      base: `${userMsvcUrl}/subscription`,
      checkStatus: `${userMsvcUrl}/subscription/check`,
    },
    settings: {
      base: `${userMsvcUrl}/setting`,
      agencyPrivacy: `${userMsvcUrl}/setting/agency-privacy`,
      fetchRatePrivacy: `${userMsvcUrl}/setting/agency-privacy/rate-privacy`,
    },
  },
  adminMicroservice: {
    leads: {
      createServiceProviderLead: `${adminMsvcUrl}/leads/service-provider`,
    },
  },
  paymentMicroservice: {
    kyc: {
      base: `${paymentMsvcUrl}/kyc`,
      saveKycProgress: (step: string) => `${paymentMsvcUrl}/kyc/step/${step}`,
      downloadKycAsUser: (documentType: string) =>
        `${paymentMsvcUrl}/kyc/download/${documentType}`,
      checkKycStatus: `${paymentMsvcUrl}/kyc/status`,
      submitKyc: `${paymentMsvcUrl}/kyc/submit`,
      retractKyc: `${paymentMsvcUrl}/kyc/retract`,
      getKycRejection: `${paymentMsvcUrl}/kyc/get-rejection`,
    },
    airwallexBeneficiary: {
      base: `${paymentMsvcUrl}/airwallex/beneficiary`,
    },

    airwallexPayment: {
      base: `${paymentMsvcUrl}/airwallex/payment`,
      prepareCreatePaymentConsent: `${paymentMsvcUrl}/airwallex/payment/payment-consent/prepare`,
      prepareUpdatePaymentConsent: `${paymentMsvcUrl}/airwallex/payment/payment-consent/prepare-update`,
      prepareCampaignTaskPaymentConsent: `${paymentMsvcUrl}/airwallex/payment/payment-consent/campaign-task/prepare`,
      updatePaymentConsent: `${paymentMsvcUrl}/airwallex/payment/payment-consent/update`,
      createSubscription: `${paymentMsvcUrl}/airwallex/payment/subscribe`,
      fetchSubscription: `${paymentMsvcUrl}/airwallex/payment/subscription`,
      updateSubscription: (priceId: string) =>
        `${paymentMsvcUrl}/airwallex/payment/subscription/update/price/${priceId}`,
      fetchSubscriptionInvoices: `${paymentMsvcUrl}/airwallex/payment/subscription/invoices`,
      fetchSubscriptionPaymentMethods: `${paymentMsvcUrl}/airwallex/payment/subscription/payment-methods`,
      fetchPaymentMethods: `${paymentMsvcUrl}/airwallex/payment/payment-methods`,
      fetchCancellationInfo: `${paymentMsvcUrl}/airwallex/payment/subscription/cancel/info`,
      cancelSubscription: `${paymentMsvcUrl}/airwallex/payment/subscription/cancel`,
      fetchCampaignPayoutRecords: `${paymentMsvcUrl}/airwallex/payment/subscription/cancel`,
      fetchAllBrandRecords: `${paymentMsvcUrl}/airwallex/payment/records/brand/all`,
    },
    airwallexInvoice: {
      downloadInvoice: (generatedInvoiceId: string) =>
        `${paymentMsvcUrl}/airwallex/invoice/${generatedInvoiceId}/download`,
    },
    campaignPayouts: {
      base: `${paymentMsvcUrl}/campaign-payouts`,
      fetchPayoutRecordAsBrand: (taskId: string) =>
        `${paymentMsvcUrl}/campaign-payouts/task/${taskId}/brand`,
      fetchPayoutRecordAsSeller: (taskId: string) =>
        `${paymentMsvcUrl}/campaign-payouts/task/${taskId}/seller`,
    },
    airwallexProduct: {
      base: `${paymentMsvcUrl}/airwallex/product`,
      available: `${paymentMsvcUrl}/airwallex/product/available`,
    },
  },
  referralAndSupportMsvc: {
    support: {
      brandCompleteOnboard: `${referralAndSupportMsvcUrl}/support/onboard/brand/complete`,
      agencyCompleteOnboard: `${referralAndSupportMsvcUrl}/support/onboard/agency/complete`,
      creatorCompleteOnboard: `${referralAndSupportMsvcUrl}/support/onboard/creator/complete`,
      agencyInvitedCreatorComplete: `${referralAndSupportMsvcUrl}/support/onboard/managed-creator/complete`,
    },
    csOrgAssignment: {
      fetchOrgAssignmentByOrgId: (orgId: string) =>
        `${referralAndSupportMsvcUrl}/cs-org-assignment/organisation/${orgId} `,
    },
    platformReferral: {
      base: `${referralAndSupportMsvcUrl}/platform-referral`,
      getPlatformReferralDetails: (referralCode: string) =>
        `${referralAndSupportMsvcUrl}/platform-referral/${referralCode}`,
    },
    referralCode: {
      base: `${referralAndSupportMsvcUrl}/referral-code`,
      getReferralCode: (userId: string) =>
        `${referralAndSupportMsvcUrl}/referral-code/${userId}`,
    },
  },
  campaignMsvc: {
    fileUploader: {
      base: `${campaignMsvcUrl}/file-uploader`,
      createMultipartUpload: `${campaignMsvcUrl}/file-uploader/create-multipart-upload`,
      generatePresignedUrl: `${campaignMsvcUrl}/file-uploader/generate-presigned-url`,
      completeMultipartUpload: `${campaignMsvcUrl}/file-uploader/complete-multipart-upload`,
    },
    payout: {
      base: `${campaignMsvcUrl}/payout`,
      getSummary: (task: string, role: string) =>
        `${campaignMsvcUrl}/payout/task/${task}/role/${role}/summary`,
    },
    crypto: {
      base: `${campaignMsvcUrl}/crypto`,
      getChainOptions: (taskId: string) =>
        `${campaignMsvcUrl}/crypto/task/${taskId}/chain-option`,
      putChainOption: (taskId: string) =>
        `${campaignMsvcUrl}/crypto/task/${taskId}/chain-option`,
    },
    urlUnfurl: {
      base: `${campaignMsvcUrl}/url-unfurl`,
    },
    search: {
      base: (role: string) => `${campaignMsvcUrl}/search/${role}`,
    },
    xProofOfWork: {
      base: `${campaignMsvcUrl}/proof-of-work/x/`,
      refreshMetrics: (task: string, role: string) =>
        `${campaignMsvcUrl}/proof-of-work/x/task/${task}/role/${role}/refresh`,
      fetchStatus: (task: string, role: string) =>
        `${campaignMsvcUrl}/proof-of-work/x/task/${task}/role/${role}/status`,
    },
    campaignAnalyticsSummary: {
      base: `${campaignMsvcUrl}/analytics/summary`,
      fetchSummary: (taskId: string, role: string) =>
        `${campaignMsvcUrl}/analytics/summary/task/${taskId}/role/${role}`,
    },
    campaignAnalyticsDetails: {
      base: `${campaignMsvcUrl}/analytics/details`,
      fetchDetails: (taskId: string, role: string) =>
        `${campaignMsvcUrl}/analytics/details/task/${taskId}/role/${role}`,
    },
    campaign: {
      base: `${campaignMsvcUrl}/campaign`,
      fetchById: (campaignId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}`,
      createTask: (campaignId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task`,
      queryTaskInBrand: `${campaignMsvcUrl}/campaign/task/query/brand`,
      fetchTasksInCampaign: (campaignId: string, role: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task/role/${role}`,
      fetchOneTask: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task/${taskId}`,
      updateTask: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task/${taskId}`,
      updateTaskWithDeliverableUpdate: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task/${taskId}/deliverable-update`,
      queryCampaign: `${campaignMsvcUrl}/campaign/query`,
      queryTask: `${campaignMsvcUrl}/campaign/task/query`,
      querySellerEngagementTasks: `${campaignMsvcUrl}/campaign/task/query/seller-engagements`,
      querySellerCompletedTasks: `${campaignMsvcUrl}/campaign/task/query/seller-completed`,
      queryBrandCompletedTasks: `${campaignMsvcUrl}/campaign/task/query/brand-completed`,
      queryCreatorExclusiveEngagementTasks: `${campaignMsvcUrl}/campaign/task/query/exclusive-engagements`,
      fetchTaskPaymentDetails: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/payment-details/campaign/${campaignId}/task/${taskId}`,
      startTask: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/start/campaign/${campaignId}/task/${taskId}`,
      deleteTask: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/task/${taskId}/delete`,
      fetchCampaignDeletionRecommendation: (campaignId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}/delete-recommendation`,
      deleteCampaign: (campaignId: string) =>
        `${campaignMsvcUrl}/campaign/${campaignId}`,
    },
    campaignSummary: {
      base: `${campaignMsvcUrl}/campaign-summary`,
      getMetrics: `${campaignMsvcUrl}/campaign-summary/metrics`,
      getOptions: (campaignId: string) =>
        `${campaignMsvcUrl}/campaign-summary/options/campaign/${campaignId}`,
      fetchBrandActiveTasks: (brandOrgId: string) =>
        `${campaignMsvcUrl}/campaign-summary/active-tasks/brand/${brandOrgId}`,
      fetchAgencyActiveTasks: (agencyOrgId: string) =>
        `${campaignMsvcUrl}/campaign-summary/active-tasks/agency/${agencyOrgId}`,
      fetchCreatorActiveTask: `${campaignMsvcUrl}/campaign-summary/active-tasks/creator`,

      getTaskBreakdown: `${campaignMsvcUrl}/campaign-summary/task-breakdown`,
      getTaskDeliverableBreakdownByBrand: (taskId: string) =>
        `${campaignMsvcUrl}/campaign-summary/task/${taskId}/deliverable-breakdown/brand`,
      getTaskDeliverableBreakdownBySeller: (taskId: string) =>
        `${campaignMsvcUrl}/campaign-summary/task/${taskId}/deliverable-breakdown/seller`,
      getOngoingTaskMetricsByBrand: (taskId: string) =>
        `${campaignMsvcUrl}/campaign-summary/task/${taskId}/ongoing/metrics/brand`,
      getOngoingTaskMetricsBySeller: (taskId: string, sellerId: string) =>
        `${campaignMsvcUrl}/campaign-summary/task/${taskId}/ongoing/metrics/seller/${sellerId}`,
      getPaymentSummary: (taskId: string) =>
        `${campaignMsvcUrl}/campaign-summary/task/${taskId}/payment-summary`,
    },
    campaignDispute: {
      createCampaignDisputeAsBrand: `${campaignMsvcUrl}/campaign-dispute/brand/create`,
      createCampaignDisputeAsSeller: `${campaignMsvcUrl}/campaign-dispute/seller/create`,
    },
    accessControl: {
      verifyAccessControlForBrand: `${campaignMsvcUrl}/access-control/brand`,
      verifyAccessControlForSeller: `${campaignMsvcUrl}/access-control/seller`,
    },
    negotiation: {
      base: `${campaignMsvcUrl}/negotiation`,
      createApplication: `${campaignMsvcUrl}/negotiation/application`,
      latestNegotiation: `${campaignMsvcUrl}/negotiation/latest`,
      latestNegotiationByRepresentative: (representativeId: string) =>
        `${campaignMsvcUrl}/negotiation/latest/representative/${representativeId}`,
      latestNegotiationByBrandOrg: (brandOrgId: string) =>
        `${campaignMsvcUrl}/negotiation/latest/brand-org/${brandOrgId}`,
      acceptNegotiation: `${campaignMsvcUrl}/negotiation/accept`,
      rejectNegotiation: `${campaignMsvcUrl}/negotiation/reject`,
      sellerCounteroffer: `${campaignMsvcUrl}/negotiation/seller/counteroffer`,
      brandCounteroffer: `${campaignMsvcUrl}/negotiation/brand/counteroffer`,
      negotiationById: (negotiationId: string) =>
        `${campaignMsvcUrl}/negotiation/id/${negotiationId}`,
      brandNegotiationLogs: (campaignId: string, taskId: string) =>
        `${campaignMsvcUrl}/negotiation/logs/brand/campaign/${campaignId}/task/${taskId}`,
      deliverableComparison: (negotiationId: string) =>
        `${campaignMsvcUrl}/negotiation/${negotiationId}/deliverable-comparison`,
    },
    ongoingTaskConversation: {
      base: `${campaignMsvcUrl}/ongoing-task-conversation`,
      brandConversations: (taskId: string) =>
        `${campaignMsvcUrl}/ongoing-task-conversation/${taskId}/brand/conversations`,
      sellerConversations: (taskId: string) =>
        `${campaignMsvcUrl}/ongoing-task-conversation/${taskId}/seller/conversations`,
      sendMessage: `${campaignMsvcUrl}/ongoing-task-conversation/send-message`,
      markAllMessagesForBrand: (conversationId: string) =>
        `${campaignMsvcUrl}/ongoing-task-conversation/${conversationId}/read-all/brand`,
      markAllMessagesForSeller: (conversationId: string) =>
        `${campaignMsvcUrl}/ongoing-task-conversation/${conversationId}/read-all/seller`,
      getMessagesInConversation: (conversationId: string) =>
        `${campaignMsvcUrl}/ongoing-task-conversation/${conversationId}`,
    },
    ongoingTask: {
      base: `${campaignMsvcUrl}/ongoing-task`,
      sellerDeliverables: (taskId: string) =>
        `${campaignMsvcUrl}/ongoing-task/${taskId}/seller/deliverables`,
      brandDeliverables: (taskId: string) =>
        `${campaignMsvcUrl}/ongoing-task/${taskId}/brand/deliverables`,
    },
    draft: {
      base: `${campaignMsvcUrl}/draft`,
      createDraftV2: `${campaignMsvcUrl}/draft/v2`,
      baseWithDraftId: (draftId: string) =>
        `${campaignMsvcUrl}/draft/${draftId}`,
      fetchDraftAsSeller: (taskId: string) =>
        `${campaignMsvcUrl}/draft/seller/task/${taskId}`,
      fetchDraftAsBrand: (taskId: string) =>
        `${campaignMsvcUrl}/draft/brand/task/${taskId}`,
      draftReview: (draftId: string, brandOrgId: string) =>
        `${campaignMsvcUrl}/draft/${draftId}/brand/${brandOrgId}/review`,
    },
    evidence: {
      base: `${campaignMsvcUrl}/evidence`,
      baseWithEvidenceId: (evidenceId: string) =>
        `${campaignMsvcUrl}/evidence/${evidenceId}`,
      fetchEvidenceAsSeller: (taskId: string) =>
        `${campaignMsvcUrl}/evidence/seller/task/${taskId}`,
      fetchEvidenceAsBrand: (taskId: string) =>
        `${campaignMsvcUrl}/evidence/brand/task/${taskId}`,
      fetchEvidenceByIdAsSeller: (evidenceId: string) =>
        `${campaignMsvcUrl}/evidence/${evidenceId}/seller`,
      fetchEvidenceByIdAsBrand: (evidenceId: string) =>
        `${campaignMsvcUrl}/evidence/${evidenceId}/brand`,
      deleteEvidence: (evidenceRecordId: string) =>
        `${campaignMsvcUrl}/evidence/record/${evidenceRecordId}`,
    },
  },
  notificationMsvc: {
    notification: {
      base: `${notificationMsvcUrl}/notification`,
      getNotifications: (recipientUserId: string) =>
        `${notificationMsvcUrl}/notification/recipient/${recipientUserId}`,
      markAllNotificationAsRead: (recipientUserId: string) =>
        `${notificationMsvcUrl}/notification/mark-all-read/recipient/${recipientUserId}`,
      markNotificationAsRead: (notificationId: string) =>
        `${notificationMsvcUrl}/notification/${notificationId}/mark-read`,
    },
  },
  userManagement: {
    updateUserAttribute: `${userMsvcUrl}/user-management`,
    completeUserAttribute: `${userMsvcUrl}/user-management/complete`,
    getUserDetail: `${userMsvcUrl}/user-management`,
  },

  smmManagement: {
    post: {
      createMultiplePost: (brandId: string) =>
        `${smmMsvcUrl}/post/v1/brands/${brandId}/posts`,
      getAllPost: `${smmMsvcUrl}/post/v1/brands/posts`,
      updatePost: `${smmMsvcUrl}/post/v1/brands/posts`,
      deletePost: `${smmMsvcUrl}/post/v1/brands/posts`,
    },

    getSocialMediaConnection: (brandId: string) =>
      `${smmMsvcUrl}/social-media/v1/brands/${brandId}/accounts`,
    smBind: (brandId: string) =>
      `${smmMsvcUrl}/social-media/v1/brands/${brandId}/account`,
    smUnBind: (
      brandId: string,
      socialMediaPlatform: string,
      socialUserId: string
    ) =>
      `${smmMsvcUrl}/social-media/v1/brands/${brandId}/account/${socialMediaPlatform}/${socialUserId}`,

    uploader: {
      image: `${smmMsvcUrl}/social-media/v1/uploader/images`,
      video: `${smmMsvcUrl}/social-media/v1/uploader/videos`,
      media: `${smmMsvcUrl}/social-media/v1/uploader/media`,
      validate: `${smmMsvcUrl}/social-media/v1/uploader/media/validation`,
      delete: `${smmMsvcUrl}/social-media/v1/uploader/file`,
      createMultipartUpload: `${smmMsvcUrl}/social-media/v1/uploader/create-multipart-upload`,
      generatePresignedUrl: `${smmMsvcUrl}/social-media/v1/uploader/generate-presigned-url`,
      completeMultipartUpload: `${smmMsvcUrl}/social-media/v1/uploader/complete-multipart-upload`,
    },

    facebook: {
      getFeeds: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/feeds`,
      getStories: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/stories`,
      getVideoReels: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/video_reels`,
      getMediaStatus: (
        id: string,
        socialMediaUserId: string,
        mediaId: string
      ) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/media/${mediaId}/status`,
      createFeed: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/feed`,
      createImagePost: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/image-post`,
      createVideoPost: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/video-post`,
      createReel: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/reel`,
      createStory: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/story`,
      deletePostById: (id: string, socialMediaUserId: string, postId: string) =>
        `${smmMsvcUrl}/facebook-post/v1/brands/${id}/facebook/${socialMediaUserId}/post/${postId}`,
    },

    instagram: {
      getInstagramMedia: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/instagram-post/v1/brands/${id}/instagram/${socialMediaUserId}/media`,
      createSinglePost: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/instagram-post/v1/brands/${id}/instagram/${socialMediaUserId}/single-post`,
      createReel: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/instagram-post/v1/brands/${id}/instagram/${socialMediaUserId}/reel`,
      createCarousel: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/instagram-post/v1/brands/${id}/instagram/${socialMediaUserId}/carousel`,
      createStory: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/instagram-post/v1/brands/${id}/instagram/${socialMediaUserId}/story`,
    },

    tiktok: {
      auth: `${smmMsvcUrl}/social-media/v1/brands/tiktok/auth`,
      getToken: `${smmMsvcUrl}/social-media/v1/brands/tiktok/auth/token`,
      basicProfile: (brandId: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/social-media/v1/brands/${brandId}/tiktok/${socialMediaUserId}`,
      getUserPrivacy: (brandId: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/tiktok-post/v1/brands/${brandId}/tiktok/${socialMediaUserId}/privacy-level-options`,
      createVideoPost: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/tiktok-post/v1/brands/${id}/tiktok/${socialMediaUserId}/video`,
      createImagePost: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/tiktok-post/v1/brands/${id}/tiktok/${socialMediaUserId}/images`,
      getDailyPostLimitation: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/tiktok-post/v1/brands/${id}/tiktok/${socialMediaUserId}/daily-post-limitation`,
    },

    x: {
      auth: `${smmMsvcUrl}/social-media/v1/brands/x/auth`,
      getToken: `${smmMsvcUrl}/social-media/v1/brands/x/auth/token`,
      createTweet: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/x-post/v1/brands/${id}/x/${socialMediaUserId}/tweet`,
      createThread: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/x-post/v1/brands/${id}/x/${socialMediaUserId}/thread-tweet`,
      createPoll: (id: string, socialMediaUserId: string) =>
        `${smmMsvcUrl}/x-post/v1/brands/${id}/x/${socialMediaUserId}/poll-tweet`,
    },
  },
};
