import "./single-deliverable-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import EmpButton from "../shared/emp-button/emp-button";
import { Color } from "../../utilities/colors";
import XCloseIcon from "../icon/x-close-icon";
import EmpEditorView from "../shared/emp-editor/emp-editor-viewer";
import { DeliverableDto } from "../../model/campaign/deliverable.dto";

export interface SingleDeliverableModalRef {
  show: (deliverable: DeliverableDto) => void;
  dismiss: () => void;
}

interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

const SingleDeliverableModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [deliverable, setDeliverable] = useState<DeliverableDto>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  // If agency ID is included, this is a managed talent
  const show = async (deliverable: DeliverableDto) => {
    setDeliverable(deliverable);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    setDeliverable(undefined);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        {deliverable && (
          <div className="emp-single-deliverable-modal">
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            <div className="tdm-content-section">
              <div className="task-name-wrapper">
                <h1 className="task-name">View Deliverable Details</h1>
              </div>
              <motion.div
                variants={fadeInVariants}
                className="deliverable-wrapper"
                initial="hidden"
                animate={"visible"}
                transition={{ duration: animationDuration }}
              >
                <span className="deliverable-indicator">DELIVERABLE</span>
                <h3 className="deliverable-name-lbl">{deliverable.name}</h3>
                <div className="mt-1">
                  <EmpEditorView value={deliverable.description} />
                </div>
              </motion.div>
            </div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              className="ph-5"
              style={{ display: "flex", flexDirection: "row-reverse" }}
              transition={{
                duration: animationDuration,
                delay: 4 * animationDelay,
              }}
            >
              <EmpButton
                isFullWidth={false}
                onSubmit={() => {
                  dismiss();
                }}
                className="mt-4"
                text={"Close"}
              />
            </motion.div>
          </div>
        )}
      </div>
      <div></div>
    </EmpModal>
  );
});

export default SingleDeliverableModal;
