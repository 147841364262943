import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import EmpBreadcrumb, {
  EmpBreadcrumbSpecs,
} from "../../components/shared/emp-breadcrumb/emp-breadcrumb";
import EmpSecondaryTabBar from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import EmpException from "../../exception/empException";
import { CampaignWOrgDto } from "../../model/campaign/campaign-w-org.dto";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import "./brand-campaign-details-page.scss";
import { CampaignSummaryView } from "./views/campaign-summary-view";

const breadcrumbsData = [{ link: "/brand/campaigns", text: "Campaign" }];

export const BrandCampaignDetailsPage = () => {
  const [tabs, setTabs] = useState([
    {
      display: "Summary",
      text: "Summary",
      isSelected: false,
    },
    {
      display: "Payment",
      text: "Payment",
      isSelected: false,
    },
  ]);

  const { id: campaignId } = useParams();
  const [campaign, setCampaign] = useState<CampaignWOrgDto>();
  const [selectedTab, setSelectedTab] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [breadcrumbs, setBreadcrumbs] = useState<EmpBreadcrumbSpecs[]>();

  useEffect(() => {
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    const fragment = window.location.hash.slice(1);
    setTabs((prevTabs) => {
      let modifiedTab = prevTabs;
      if (fragment) {
        const fragmentHash = prevTabs.find((elem) => elem.text === fragment);
        if (!fragmentHash)
          modifiedTab = prevTabs.map((tab, index) =>
            index === 0 ? { ...tab, isSelected: true } : tab
          );
        else fragmentHash.isSelected = true;
      } else {
        modifiedTab = prevTabs.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      }
      return [...modifiedTab];
    });
  }, []);

  const fetchCampaignById = useCallback(async () => {
    try {
      if (!campaignId) throw new EmpException("No campaign id");
      const resp = await CampaignApi.fetchCampaignById(campaignId);
      const campaign = resp.data;
      const newBreadcrumbs = [...breadcrumbsData];
      newBreadcrumbs.push({
        link: `/brand/campaign-details/${campaignId}`,
        text: campaign.name,
      });
      setBreadcrumbs(newBreadcrumbs);
      setCampaign(campaign);

      await empDelay(500);
      setLoading(false);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch campaign");
    }
  }, [campaignId]);

  useEffect(() => {
    fetchCampaignById();
  }, [fetchCampaignById]);

  // Campaign Card
  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="bg-tint"></div>
      <div className="emp-page-content-no-padding emp-campaign-details-page">
        {breadcrumbs && (
          <div className="mt-3">
            <EmpBreadcrumb
              items={breadcrumbs}
              loading={isLoading}
              numLoadingItem={2}
            />
          </div>
        )}

        <div className="campaign-view-wrapper">
          {selectedTab === "Summary" && (
            <CampaignSummaryView campaign={campaign} />
          )}
        </div>
      </div>
    </div>
  );
};
