import { EventContentArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import {
  useRef,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

import CalendarGridIcon from "../../../../components/icon/calendar-grid-icon";
import CalendarIcon from "../../../../components/icon/calendar-icon";
import CalendarListIcon from "../../../../components/icon/calendar-list-icon";
import ChevronDownIcon from "../../../../components/icon/chevron-down";
import ChevronLeftIcon from "../../../../components/icon/chevron-left";
import ChevronRightIcon from "../../../../components/icon/chevron-right";
import EmpButton from "../../../../components/shared/emp-button/emp-button";
import EmpDropdownBtn from "../../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import BrandEventPostModal, {
  BrandEventPostModalRef,
} from "../../modal/brand-event-post-modal";
import FacebookIcon from "../../../../components/icon/facebook-icon";
import InstagramIcon from "../../../../components/icon/instagram-icon";
import TikTokIcon from "../../../../components/icon/tiktok-icon";
import XIcon from "../../../../components/icon/x-icon";
import { IconProps } from "../../../../model/common/icon";
import { Color } from "../../../../utilities/colors";
import SocialMediaManagementApi from "../../../../api/smm-msvc/social-media-management.api";
import {
  SmGetPostsQueryDto,
  SmPostDto,
} from "../../../../model/smm/smm-post.dto";
import { AppContext } from "../../../../context/app.context";
import { UserDto } from "../../../../model/user-management/user.dto";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../../../utilities/user-utils";
import { useSearchParams } from "react-router-dom";
import { useSmPostList } from "../../hooks/useSmPostList";
import { SmConnectionBriefRespDto } from "../../../../model/smm/smm-connection-brief-resp.dto";
import PlayIcon from "../../../../components/icon/play-icon";
import EditIcon from "../../../../components/icon/edit-icon";
import EmpLoaderV2 from "../../../../components/shared/emp-loader-v2/emp-loader-v2";
import EmpException from "../../../../exception/empException";
import TiktokConfigPostModal from "../../modal/tiktok/tiktok-config-post-modal";
import DeleteModal, {
  DeleteModalRef,
} from "../../../../components/modals/delete-modal";
import { deletePostUtils } from "../../utils/smm-posts-utils";
import BrandEditPostModal, {
  BrandEditPostModalRef,
} from "../../modal/brand-edit-post-modal";

const viewOptions: { [key: string]: string } = {
  Month: "dayGridMonth",
  Week: "timeGridWeek",
};

const socialMediaOptions: { [key: string]: (props: IconProps) => JSX.Element } =
  {
    Facebook: FacebookIcon,
    Instagram: InstagramIcon,
    TikTok: TikTokIcon,
    X: XIcon,
  };

const platformIconFullColorMap: {
  [key: string]: JSX.Element;
} = {
  facebook: <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={12} />,
  instagram: <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={12} />,
  tiktok: <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={12} />,
  x: <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />,
};

interface Event {
  rawPostRecord: SmPostDto;
  postId: number;
  name: string;
  status: string;
  pictureUrl: string;
  media?: string;
  postType: string;
  title: string;
  start: string;
  platform: string;
}

type SmOverviewTabProps = {
  connects: SmConnectionBriefRespDto[];
  postIds?: string[];
  setPostIds?: (postIds: string[]) => void;
};

// Custom hook for managing calendar state
function useCalendarState(initialView: string, initialSocialView: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [timeView, setTimeView] = useState(initialView);
  const [socialView, setSocialView] = useState(initialSocialView);
  const [currentTitle, setCurrentTitle] = useState(
    moment().format("MMMM YYYY")
  );

  const [isListView, setIsListView] = useState(false);

  return {
    searchParams,
    setSearchParams,
    isLoading,
    setIsLoading,
    events,
    setEvents,
    timeView,
    setTimeView,
    socialView,
    setSocialView,
    currentTitle,
    setCurrentTitle,
    isListView,
    setIsListView,
  };
}

export const OverviewTab: React.FC<SmOverviewTabProps> = ({
  connects,
  postIds,
  setPostIds,
}) => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const BrandEventPostModalRef = useRef<BrandEventPostModalRef>();
  const deleteModalRef = useRef<DeleteModalRef>();
  const brandEditPostModalRef = useRef<BrandEditPostModalRef>();
  const userRef = useRef<UserDto>();

  const { user: userContext } = useContext(AppContext);

  const {
    searchParams,
    setSearchParams,
    isLoading,
    setIsLoading,
    events,
    setEvents,
    timeView,
    setTimeView,
    socialView,
    setSocialView,
    currentTitle,
    setCurrentTitle,
    isListView,
    setIsListView,
  } = useCalendarState("Month", "All");

  const isCreatePostAvailable = useMemo(() => {
    return connects.length > 0;
  }, [connects]);

  const getDayRange = () => {
    const calendarApi = calendarRef.current?.getApi();
    const timeView = searchParams.get("timeView");

    if (calendarApi) {
      const currentDate = calendarApi.getDate();

      if (timeView === "Week") {
        // First day of the week (Sunday)
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

        // Last day of the week (Saturday)
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

        return {
          startDate: startOfWeek,
          endDate: endOfWeek,
        };
      } else {
        // First day of the month
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          25
        );
        // Last day of the month
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          12
        );

        return {
          startDate: startOfMonth,
          endDate: endOfMonth,
        };
      }
    }
    return null;
  };

  const handleViewClick = (view: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      handleTimeViewClick(view);
      setTimeView(view);
      calendarApi.changeView(viewOptions[view]);
    }
  };

  const handleChevronClick = (direction: "left" | "right") => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      if (direction === "left") {
        calendarApi.prev();
        getPost();
      } else {
        calendarApi.next();
        getPost();
      }
    }
  };

  const handleDatesSet = useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const view = calendarApi.view;
      // Check if the view type is week
      if (view.type.includes("week") || view.type === "timeGridWeek") {
        // Assuming view has start and end date of the visible range
        const dayRange = getDayRange();

        const formattedStartDate = moment(dayRange?.startDate).format(
          "ddd, DD MMM YYYY"
        );
        const formattedEndDate = moment(dayRange?.endDate).format(
          "ddd, DD MMM YYYY"
        );
        const titleDate = `${formattedStartDate} - ${formattedEndDate}`;
        setCurrentTitle(titleDate);
      } else {
        // For other views, continue setting title as before
        const titleDate = view.title;
        setCurrentTitle(moment(titleDate).format("MMMM YYYY"));
      }
    }
  }, []);

  const handleChangeListView = (viewValue: boolean) => {
    setIsListView(viewValue);
  };

  const renderEventContent = useCallback((eventContent: EventContentArg) => {
    const isListView = eventContent.view.type === "listWeek";
    const { rawPostRecord, postId, name, status, platform, postType, media } =
      eventContent.event.extendedProps;
    const isDraft = status === "draft";
    const eventTime = moment(eventContent.event.start);

    const isArr = Array.isArray(media);

    let isVideo = isArr
      ? media[0].endsWith(".mp4") ||
        media[0].endsWith(".avi") ||
        media[0].endsWith(".mov")
      : media?.endsWith(".mp4") ||
        media?.endsWith(".avi") ||
        media?.endsWith(".mov");

    const mediaElement = media ? (
      isVideo ? (
        <div className="calendar-event-media">
          <video src={media} />
          <PlayIcon backgroundColor={Color.NEUTRAL[100]} size={16} />
        </div>
      ) : (
        <div className="calendar-event-media">
          <img src={media} alt="post" />
        </div>
      )
    ) : null;

    const renderButton = () => {
      // if (status === "done" || status === "failed")
      //   return (
      //     <EmpButton
      //       isFullWidth={false}
      //       onSubmit={() =>
      //         deleteModalRef.current?.show(
      //           "Delete Scheduled Post",
      //           "post",
      //           handleDeletePost,
      //           postId.toString()
      //         )
      //       }
      //       buttonHeight="sm"
      //       buttonStyle="danger"
      //       text={"Delete"}
      //       className="mr-1"
      //     />
      // );
      if (status === "scheduled" || status === "draft") {
        return (
          <EmpButton
            text={"Edit"}
            onSubmit={() => brandEditPostModalRef.current?.show(rawPostRecord)}
            isFullWidth={false}
            buttonHeight={"sm"}
            leftIcon={EditIcon}
          />
        );
      }
      return null;
    };

    return (
      <div className={`calendar-event-content-wrapper ${status}`}>
        <div className="card-highlight" />
        <div className="summarize-content">
          <div className="event-title-wrapper">
            <div className="text-wrapper">
              <b className="event-title">{postType}</b>
              <p className="event-desc">{eventContent.event.title}</p>
            </div>
            <div className="event-icons">
              <div className={`event-icon-wrapper ${platform.toLowerCase()}`}>
                {platformIconFullColorMap[platform.toLowerCase()]}
              </div>
            </div>
          </div>
          <div className="event-time">
            <span>{status}</span>
            {isDraft ? "-" : eventTime.format("hh:mm A")}
          </div>
        </div>

        <div className="calendar-event-detail">
          {mediaElement}
          <div className="detail-wrapper">
            <div className="text-wrapper">
              <b className="detail-title">{postType}</b>
              <p className="detail-desc">{eventContent.event.title}</p>
            </div>
            <div className="event-time">
              {isDraft ? "-" : eventTime.format("DD MMM YYYY - hh:mm A")}
            </div>
            <div className="detail-bottom-wrapper">
              <div className="event-icons">
                <div className={`event-icon-wrapper ${platform.toLowerCase()}`}>
                  {platformIconFullColorMap[platform.toLowerCase()]}
                </div>
                <span className="post-creator-name">@{name}</span>
              </div>
              {renderButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  const validateOrganisationMembership = (user: UserDto) => {
    // Check if 'organisation' is defined and has at least one item
    if (!user.organisation || user.organisation.length === 0) {
      throw new EmpException("Not an organisation");
    }
  };

  const getPost = useCallback(async () => {
    const dayRange = getDayRange();
    const { startDate, endDate } = dayRange || {};
    const platformName: string = searchParams.get("platform") || "";
    try {
      setIsLoading(true);
      if (!userRef.current) {
        const user = await UserUtils.fetchUser(userContext);
        userRef.current = user;
      }
      const user = userRef.current!;
      validateOrganisationMembership(user);

      const brandId = user.organisation![0].id;

      const platformNames = !!platformName ? [platformName] : undefined;
      const statuses = ["done", "in-progress", "failed", "scheduled"];

      const query: SmGetPostsQueryDto = {
        postIds,
        brandId,
        platformNames,
        statuses,
        startDate,
        endDate,
        limit: 200,
      };

      const { data: resp } = await SocialMediaManagementApi.post.getAllPost(
        query
      );
      const events: Event[] = resp.data.map((post) => {
        const formattedRecord = JSON.parse(post.metadata);
        const title = getPostDescription(formattedRecord);
        const {
          id: postId,
          platformName: platform,
          postType,
          status,
          scheduledTime,
          createdDate,
          updatedAt,
          socialSpec,
        } = post;

        const { name, pictureUrl } = socialSpec || {};

        const time: string | Date =
          scheduledTime !== null && scheduledTime !== 0
            ? new Date(scheduledTime * 60 * 1000).toString()
            : updatedAt || createdDate;

        const getMediaUrl = (): string => {
          const {
            image_urls,
            video_urls,
            video_url,
            photo_urls,
            media,
            media_url,
            media_items,
            link,
            file_url,
            thread,
          } = formattedRecord;

          if (link) return link;
          if (file_url) return file_url;
          if (image_urls?.length) return image_urls[0];
          if (video_urls?.length) return video_urls[0];
          if (photo_urls?.length) return photo_urls[0];
          if (video_url) return video_url;
          if (media_url) return media_url;
          if (media?.length) return media[0].url;
          if (media_items?.length) return media_items[0].url;
          if (thread?.length) {
            const firstMedia = thread[0].media;
            if (firstMedia?.length) return firstMedia[0].url;
          }
          return "";
        };

        return {
          rawPostRecord: post,
          postId,
          name,
          status,
          pictureUrl,
          media: getMediaUrl(),
          postType: formattedPostType(postType),
          title,
          start: moment(time).format(),
          platform,
        };
      });

      setEvents(events);

      if (postIds && postIds.length > 0) {
        setSocialView("All");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when fetching post history"
      );
    } finally {
      setIsLoading(false);
    }
  }, [socialView, searchParams, userContext, postIds]);

  const {
    handleTimeViewClick,
    handleSocialClick,
    formattedPostType,
    getPostDescription,
  } = useSmPostList({
    isLoading,
    setSearchParams,
    getPost,
    setSocialView,
    setTimeView,
    setPostIds,
  });

  const { handleDeletePost } = deletePostUtils({ getPost });

  useEffect(() => {
    getPost();
  }, [getPost, searchParams]);

  return (
    <>
      <BrandEventPostModal
        ref={BrandEventPostModalRef}
        connects={connects}
        getPost={getPost}
      />
      <DeleteModal ref={deleteModalRef} onSave={() => {}} suppressToast />
      <BrandEditPostModal ref={brandEditPostModalRef} getPost={getPost} />

      <div className="main-content">
        <div className="header-content-wrapper">
          <div className="left-side">
            <h3>{currentTitle}</h3>
            <span onClick={() => handleChevronClick("left")}>
              <ChevronLeftIcon backgroundColor={Color.NEUTRAL[500]} />
            </span>
            <span onClick={() => handleChevronClick("right")}>
              <ChevronRightIcon backgroundColor={Color.NEUTRAL[500]} />
            </span>
            {/* <div className="switch-view-wrapper">
              <span
                className={isListView ? "" : "active"}
                onClick={() => handleChangeListView(false)}
              >
                <CalendarGridIcon
                  backgroundColor={
                    isListView ? Color.PRIMARY[500] : Color.NEUTRAL[0]
                  }
                  size={14}
                />
              </span>
              <p>|</p>
              <span
                className={isListView ? "active" : ""}
                onClick={() => handleChangeListView(true)}
              >
                <CalendarListIcon
                  backgroundColor={
                    isListView ? Color.NEUTRAL[0] : Color.PRIMARY[500]
                  }
                  size={14}
                />
              </span>
            </div> */}
          </div>
          <div className="right-side">
            <EmpDropdownBtn
              text={socialView}
              buttonStyle="outline-secondary"
              menuButtonSpecs={[
                {
                  action: () => handleSocialClick(),
                  context: "All",
                  label: "All",
                },
                {
                  action: (record) => handleSocialClick(record),
                  context: "Facebook",
                  label: "Facebook",
                },
                {
                  action: (record) => handleSocialClick(record),
                  context: "Instagram",
                  label: "Instagram",
                },
                {
                  action: (record) => handleSocialClick(record),
                  context: "TikTok",
                  label: "TikTok",
                },
                {
                  action: (record) => handleSocialClick(record),
                  context: "X",
                  label: "X",
                },
              ]}
              leftIcon={socialMediaOptions[socialView]}
              rightIcon={ChevronDownIcon}
            />
            {/* <EmpDropdownBtn
              text={timeView}
              buttonStyle="outline-secondary"
              menuButtonSpecs={[
                {
                  action: (record) => handleViewClick(record),
                  context: "Month",
                  label: "Month",
                },
                {
                  action: (record) => handleViewClick(record),
                  context: "Week",
                  label: "Week",
                },
              ]}
              leftIcon={CalendarIcon}
              rightIcon={ChevronDownIcon}
            /> */}
            <EmpButton
              text={"Post"}
              onSubmit={() => BrandEventPostModalRef.current?.show()}
              disabled={!isCreatePostAvailable}
            />
          </div>
        </div>
        <div className="overview-calendar-wrapper">
          {isLoading && <EmpLoaderV2 isLoading={true} />}
          <FullCalendar
            ref={calendarRef}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            headerToolbar={false}
            editable={true}
            weekends={true}
            datesSet={handleDatesSet}
            events={events}
            eventClick={() => {}}
            eventContent={renderEventContent} // Using the memoized function
            eventDisplay="block"
            dayMaxEvents={3}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
};
