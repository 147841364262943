import {
  PayEmbed,
  TokenInfo,
} from "thirdweb/react";
import { thirdWebClient } from "../../thirdweb-client";
import { ethereum, bsc, sepolia, ChainOptions } from "thirdweb/chains";
import "./thirdweb-pay-embed-wrapper.scss";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { getEnvVar } from "../../constants/envConstants";
import { useEffect, useState } from "react";
import EmpException from "../../exception/empException";
import { TaskDto } from "../../model/campaign/task.dto";
import { THIRDWEB_TOKENS } from "../../constants/crypto-coin.contants";

interface Props {
  paymentSpecs: {
    token: string;
    amount: number;
    chain: string;
  };
  task: TaskDto;
  onClose: () => void;
  onPaymentComplete: (transactionHash: string) => void;
}
export const ThirdwebPayEmbedWrapper: React.FC<Props> = ({
  paymentSpecs,
  task,
  onClose,
  onPaymentComplete,
}) => {
  const [sellerAddress, setSellerAddress] = useState<string>();
  const [selectedTokenInfo, setSelectedTokenInfo] = useState<TokenInfo>();
  const [selectedChain, setSelectedChain] = useState<
    Readonly<
      ChainOptions & {
        rpc: string;
      }
    >
  >();

  useEffect(() => {
    const envSellerAddress = getEnvVar().sellerAddress;
    if (!envSellerAddress) throw new EmpException("No Seller Address Found");
    setSellerAddress(envSellerAddress);
    console.log(paymentSpecs);
    if (paymentSpecs.chain === "BSC") {
      setSelectedTokenInfo(
        THIRDWEB_TOKENS["BSC"].find(
          (token) => token.symbol === paymentSpecs.token
        )
      );
      setSelectedChain(bsc);
    } else if (paymentSpecs.chain === "ETH") {
      if (paymentSpecs.token === "ETH") {
        setSelectedTokenInfo(undefined);
      } else {
        setSelectedTokenInfo(
          THIRDWEB_TOKENS["ETH"].find(
            (token) => token.symbol === paymentSpecs.token
          )
        );
      }
      setSelectedChain(ethereum);
    } else if (paymentSpecs.chain === "SEPOLIA") {
      if (paymentSpecs.token === "ETH") {
        setSelectedTokenInfo(undefined);
      } else {
        const sepoliaTokenInfo = THIRDWEB_TOKENS["SEPOLIA"].find(
          (token) => token.symbol === paymentSpecs.token
        );
        console.log(sepoliaTokenInfo);
        setSelectedTokenInfo(sepoliaTokenInfo);
      }
      setSelectedChain(sepolia);
    }
  }, [paymentSpecs, task]);

  return (
    <div className="emp-thirdweb-pay-embed-wrapper">
      {sellerAddress && selectedChain && (
        <div className="embed-wrapper">
          <button className="emp-button-reset close-button" onClick={onClose}>
            <XCloseIcon backgroundColor={Color.NEUTRAL[600]} />
          </button>
          <PayEmbed
            client={thirdWebClient}
            theme={"dark"}
            payOptions={{
              mode: "direct_payment",
              buyWithFiat: false,
              paymentInfo: {
                amount: paymentSpecs.amount.toString(),
                chain: selectedChain,
                token: selectedTokenInfo,
                sellerAddress: sellerAddress,
              },
              metadata: {
                name: task.name,
                image:
                  "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creatorfi-product-img.png",
              },
              onPurchaseSuccess(info) {
                const cryptoInfo = info as any;
                onPaymentComplete(
                  cryptoInfo.status.destination.transactionHash
                );
                console.log(
                  "Purchase Success",
                  cryptoInfo.status.destination.transactionHash
                );
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
