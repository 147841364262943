import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { PermsUtils } from "../../../constants/permissions.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import EmpException from "../../../exception/empException";
import useEmpGuard from "../../../hooks/useEmpGuard";
import { UserDto } from "../../../model/user-management/user.dto";
import { EmpNotification } from "../../shared/emp-notification/emp-notification";
import EmpProfileDropdownBtn, {
  MenuButtonSpec,
} from "../../shared/emp-profile-dropdown-btn/emp-profile-dropdown-btn";
import dashboardActiveImg from "../../../assets/icons/navbar-header-icons/dashboard-active.png";
import dashboardImg from "../../../assets/icons/navbar-header-icons/dashboard-inactive.png";
import campaignActiveImg from "../../../assets/icons/navbar-header-icons/campaign-active.png";
import campaignImg from "../../../assets/icons/navbar-header-icons/campaign-inactive.png";
import creatorActiveImg from "../../../assets/icons/navbar-header-icons/person-active.png";
import creatorImg from "../../../assets/icons/navbar-header-icons/person-inactive.png";
import "./emp-brand-template.scss";
import { SearchBar } from "../../shared/emp-search/emp-search-bar";
import SubscriptionAlertModal, {
  SubscriptionAlertModalRef,
} from "../../modals/subscription-alert-modal";
import { EmpSubscriptionAccessControlView } from "../../shared/emp-subscription-access-control-view/emp-subscription-access-control-view";
import { AblyProvider } from "ably/react";
import * as Ably from "ably";
import { getEnvVar } from "../../../constants/envConstants";
import { EmpGlobalNotification } from "../../../model/common/global-notification";
import useUser from "../../../hooks/useUser";
import useEmpAuth from "../../../hooks/useEmpAuth";
import { USER_ROLES } from "../../../constants/app.constants";
import { ConnectButton, darkTheme } from "thirdweb/react";
import { thirdWebClient } from "../../../thirdweb-client";
import { createWallet } from "thirdweb/wallets";
import { Color } from "../../../utilities/colors";

const menuToPathBinding: { [key: string]: string } = {
  dashboard: "/brand/home",
  campaign: "/brand/campaigns",
  creators: "/brand/creators",
  social: "/brand/social-media-manager",
};

const wallets = [
  // @ts-ignore
  createWallet("io.metamask"),
  // @ts-ignore
  createWallet("com.coinbase.wallet"),
  // @ts-ignore
  createWallet("me.rainbow"),
  // @ts-ignore
  createWallet("io.rabby"),
  // @ts-ignore
  createWallet("io.zerion.wallet"),
];

interface Props {
  children: JSX.Element;
}
type MenuTabType = "dashboard" | "campaign" | "creators" | "social";

const EmpBrandTemplate = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const [selectedMenuTab, setSelectedMenuTab] = useState<MenuTabType>();
  const userRef = useRef<UserDto>();
  const subscriptionAlertModalRef = useRef<SubscriptionAlertModalRef>();

  const [subscriptionAccessControlView, setSubscriptionAccessControlView] =
    useState<"hidden" | "loading" | "denied">("hidden");
  const baseMenuButtonSpecs = useRef<MenuButtonSpec<any>[]>([
    {
      label: "Settings",
      context: undefined,
      action: (record) => {
        settingsOnClick();
      },
    },
    {
      label: "Referral",
      context: undefined,
      action: (record) => {
        referralsOnClick();
      },
    },
    {
      label: "Sign out",
      context: undefined,
      action: (record) => {
        signOut();
      },
    },
  ]);
  const [menuButtonSpecs, setMenuButtonSpecs] = useState<MenuButtonSpec<any>[]>(
    baseMenuButtonSpecs.current
  );

  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);

  const ablyClient = useMemo(() => {
    return new Ably.Realtime.Promise({
      authUrl: `${getEnvVar().userMsvc}/realtime`,
    });
  }, []);

  // User context from the App Provider
  const { user, refreshUser } = useUser();
  const { signOut: awsSignOut } = useEmpAuth();
  useEffect(() => {
    if (
      ablyClient &&
      user &&
      user.organisation &&
      user.organisation.length > 0
    ) {
      const channel = ablyClient.channels.get("is_online");
      channel.presence.enter(user.organisation[0].id);
      const globalNotificationChannel = ablyClient.channels.get(
        `global_${user.organisation[0].id}`
      );
      globalNotificationChannel.subscribe((message) => {
        const notification = message.data as EmpGlobalNotification;
        PubSub.publish(PUB_SUB_TOPICS.GLOBAL_NOTIFICATION, notification);
      });
      return () => {
        channel.presence.leave();
        globalNotificationChannel.unsubscribe();
      };
    }
  }, [user, ablyClient]);

  const hasDualRole: boolean = useMemo(() => {
    if (!user) return false;
    return PermsUtils.hasDualRole(user);
  }, [user]);

  useEffect(() => {
    if (user) {
      userRef.current = user;
      setSubscriptionAccessControlView("hidden");
    } else {
      setSubscriptionAccessControlView("loading");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!user) return;
    if (user.userOnboardingState !== "COMPLETED") {
      navigate("/brand/onboard");
    }
    const roleClassification = PermsUtils.getUserRole(user);
    const isBrand = PermsUtils.isUserABrand(user);
    if (!isBrand) {
      // Navigate to creator dashboard here
      if (roleClassification === "creator") {
        navigate("/creator/home");
        return;
      } else if (roleClassification === "agency") {
        navigate("/agency/home");
        return;
      }
    }

    setMenuButtonSpecs((_) => {
      const newBaseMenuButtonSpecs = [...baseMenuButtonSpecs.current];

      if (roleClassification === USER_ROLES.AGENCY) {
        newBaseMenuButtonSpecs.splice(1, 0, {
          label: "To Agency Profile",
          context: undefined,
          action: () => {
            navigate("/agency/home");
          },
        });
      }
      if (!user.subscription?.hasSubscription) {
        newBaseMenuButtonSpecs.splice(1, 0, {
          label: "Subscribe",
          context: undefined,
          action: () => {
            subscriptionAlertModalRef.current?.show();
          },
        });
      }
      return newBaseMenuButtonSpecs;
    });

    if (!user) {
      setSubscriptionAccessControlView("loading");
    }
  }, [navigate, user]);

  // Open an alert event listener when this component is loaded for the first time.
  // When component is destroyed, it will unsubscribe.
  useEffect(() => {
    const subscriptionCallback = () => {
      refreshUser();
    };

    const viewPlansSubsubcriptionCallback = () => {
      subscriptionAlertModalRef.current?.show();
    };
    const userSubscription = PubSub.subscribe(
      PUB_SUB_TOPICS.USER,
      subscriptionCallback
    );

    const viewPlansSubscription = PubSub.subscribe(
      PUB_SUB_TOPICS.VIEW_PLANS,
      viewPlansSubsubcriptionCallback
    );
    return () => {
      PubSub.unsubscribe(userSubscription);
      PubSub.unsubscribe(viewPlansSubscription);
    };
  }, [refreshUser]);

  /**
   * An asynchronous function that signs out the current authenticated user and redirects them to the home page.
   */
  async function signOut() {
    try {
      awsSignOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const settingsOnClick = () => {
    navigate("/brand/settings");
  };

  const referralsOnClick = () => {
    navigate("/brand/referrals");
  };

  const menuItemRef = useRef<{ [key: string]: HTMLDivElement }>({});
  const underlineElemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Trigger the blocking modal everytime.
    if (userRef.current) {
      if (
        !userRef.current.subscription ||
        !userRef.current.subscription.hasSubscription
      ) {
        // navigate("/brand/subscription-required")
        // subscriptionAlertModalRef.current?.show();
      }
    }

    const homeKeywords = "/home";
    const campaignKeywords = "/campaigns";
    const creatorKeywords = "/creators";
    const socialMediaManagerKeywords = "/social-media-manager";

    const path = location.pathname;
    if (path.includes(homeKeywords)) {
      setSelectedMenuTab("dashboard");
    } else if (path.includes(campaignKeywords)) {
      setSelectedMenuTab("campaign");
    } else if (path.includes(creatorKeywords)) {
      setSelectedMenuTab("creators");
    } else if (path.includes(socialMediaManagerKeywords)) {
      setSelectedMenuTab("social");
    }
  }, [location]);

  const menuItemOnClick = (menuItem: MenuTabType) => {
    try {
      setSelectedMenuTab(menuItem);
      navigate(menuToPathBinding[menuItem]);
    } catch (e) {
      console.error(e);
    }
  };

  // This moves the underline
  useEffect(() => {
    if (!selectedMenuTab) return;

    if (!underlineElemRef.current)
      throw new EmpException("Unable to get underline element");
    const menuElem = menuItemRef.current[selectedMenuTab];
    if (!menuElem)
      throw new EmpException("Unable to retreieve target menu element");

    const width = `${menuElem.clientWidth}px`;
    const left = `${menuElem.offsetLeft}px`;
    underlineElemRef.current.style.width = width;
    underlineElemRef.current.style.left = left;
  }, [selectedMenuTab, intl]);

  return (
    <AblyProvider client={ablyClient}>
      <div className="emp-brand-template-wrapper">
        <SubscriptionAlertModal ref={subscriptionAlertModalRef} />
        <div className="emp-horizontal-navbar">
          <div className="top-bar-wrapper">
            <div className="top-bar-content">
              <div className="left-elems">
                <img
                  className="logo"
                  alt="CreatorFi Logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
                <SearchBar />
              </div>
              <div className="right-elems">
                {user && (
                  <EmpNotification userType="organisation" user={user} />
                )}
                {user && (
                  <EmpProfileDropdownBtn
                    role={hasDualRole ? "Brand" : undefined}
                    user={user}
                    menuButtonSpecs={menuButtonSpecs}
                  />
                )}
                <ConnectButton
                  client={thirdWebClient}
                  wallets={wallets}
                  theme={darkTheme({
                    colors: {
                      primaryButtonBg: Color.NEUTRAL[1000],
                      primaryButtonText: "#ffffff",
                    },
                  })}
                  connectButton={{
                    label: "Connect Wallet",
                    style: {
                      border: `1px solid ${Color.NEUTRAL[700]}`,
                      width: "fit-content",
                      padding: "8px 20px",
                      fontSize: "14px",
                      minWidth: 0,
                      height: 40,
                      color: Color.NEUTRAL[300],
                    },
                  }}
                  connectModal={{
                    size: "wide",
                    title: "Connect Wallet",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="menu-items-wrapper">
            <div className="menu-items-content">
              <div ref={underlineElemRef} className="underline"></div>
              <div
                onClick={() => {
                  menuItemOnClick("dashboard");
                }}
                ref={(el) =>
                  (menuItemRef.current["dashboard"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "dashboard" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "dashboard" && (
                    <img
                      className="icon"
                      src={dashboardActiveImg}
                      alt="Dashboard"
                    />
                  )}
                  {selectedMenuTab !== "dashboard" && (
                    <img className="icon" src={dashboardImg} alt="Dashboard" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_dashboard" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("campaign");
                }}
                ref={(el) =>
                  (menuItemRef.current["campaign"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "campaign" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "campaign" && (
                    <img
                      className="icon"
                      src={campaignActiveImg}
                      alt="Campaign"
                    />
                  )}
                  {selectedMenuTab !== "campaign" && (
                    <img className="icon" src={campaignImg} alt="Campaign" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_myCampaigns" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("creators");
                }}
                onAuxClick={() => {
                  window.open("/agency/creators", "_blank");
                }}
                ref={(el) =>
                  (menuItemRef.current["creators"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "creators" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "creators" && (
                    <img
                      className="icon"
                      src={creatorActiveImg}
                      alt="Creators"
                    />
                  )}
                  {selectedMenuTab !== "creators" && (
                    <img className="icon" src={creatorImg} alt="Creators" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_creators" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="emp-template-access-control-wrapper">
          <EmpSubscriptionAccessControlView
            onOpenSubscriptionModal={() => {
              subscriptionAlertModalRef.current?.show();
            }}
            subscriptionAccessControlView={subscriptionAccessControlView}
          />
          {props.children}
        </div>
      </div>
    </AblyProvider>
  );
};
export default EmpBrandTemplate;
