import { useCallback } from "react";
import {
  DELIVERABLE_STATUS,
  FILE_TYPE,
} from "../../../constants/app.constants";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { ExtendedDraftDto } from "../../../model/campaign/extended-draft.dto";
import { Color } from "../../../utilities/colors";
import FileUtils from "../../../utilities/file-util";
import StringUtils from "../../../utilities/string.util";
import FileIcon from "../../icon/file-icon";
import RefreshIcon from "../../icon/refresh-icon";
import EmpButton from "../emp-button/emp-button";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-draft-card.scss";

interface Props {
  deliverableDraft?: ExtendedDraftDto;
  cardWidth?: number;
  mode?: "default" | "skeleton";
  role: "brand" | "seller";
  onCreate?: () => void;
  onResubmit?: () => void;
  onView?: () => void;
  onReview?: () => void;
  onChat?: () => void;
}

export const EmpDraftCard = (props: Props) => {
  const {
    deliverableDraft,
    onCreate,
    onView,
    onReview,
    onResubmit,
    onChat,
    role,
  } = props;
  const mode = props.mode ?? "default";
  const width = props.cardWidth ?? 240;
  const renderRevisionPill = (revisionLeft: number) => {
    return (
      <div className="revision-pill">
        <span>{revisionLeft}</span>
        <RefreshIcon
          backgroundColor={Color.NEUTRAL[500]}
          strokeWidth={3}
          size={10}
        />
      </div>
    );
  };

  const renderDraftMedia = useCallback(() => {
    const draftAttachment = deliverableDraft?.draft?.draftAttachments[0];
    if (!draftAttachment) {
      return (
        <div className="blank-bg">
          <p
            className="remarks-p"
            dangerouslySetInnerHTML={{
              __html: StringUtils.stringToHtmlRepresentation(
                deliverableDraft?.draft?.remarks ?? "-"
              ),
            }}
          ></p>
        </div>
      );
    } else {
      return (
        <>
          {draftAttachment.fileType === FILE_TYPE.IMAGE && (
            <img
              className="attachment-img"
              alt="Uploaded draft"
              src={draftAttachment.attachmentUrl}
            />
          )}
          {draftAttachment.fileType === FILE_TYPE.VIDEO && (
            <video
              className="attachment-video"
              muted
              loop
              controls={false}
              src={draftAttachment.attachmentUrl}
            />
          )}
          {draftAttachment.fileType === FILE_TYPE.FILE && (
            <div className="attachment-file-wrapper">
              <FileIcon size={24} backgroundColor={Color.NEUTRAL[300]} />
              <span className="title">{draftAttachment.attachmentName}</span>
              <span className="specs">
                {FileUtils.convertBytesToReadableSize(
                  draftAttachment.attachmentSize ?? 0
                )}
              </span>
            </div>
          )}
        </>
      );
    }
  }, [deliverableDraft]);

  return (
    <>
      {mode === "default" && deliverableDraft && (
        <div
          className="emp-draft-card emp-card no-padding"
          style={{ width: `${width}px` }}
        >
          {deliverableDraft.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
            !deliverableDraft.draft && (
              <div className="image-wrapper padding">
                <img
                  className="empty-draft-img"
                  alt="no draft submitted"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-draft.png"
                />
                <span className="empty-draft-header">No Draft Submitted</span>
                {role === "seller" && (
                  <p className="empty-draft-description  mt-2">
                    You need to submit a draft before posting deliverable
                  </p>
                )}
                {role === "brand" && (
                  <p className="empty-draft-description  mt-2">
                    Please wait for the creator or agency to submit a draft
                  </p>
                )}
              </div>
            )}

          {/* SUBMITTED SCENARIO */}
          {[
            DELIVERABLE_STATUS.IN_REVIEW,
            DELIVERABLE_STATUS.APPROVED,
            DELIVERABLE_STATUS.COMPLETED,
          ].includes(deliverableDraft.status) && (
            <div className="image-wrapper">
              {deliverableDraft.draft!.remarks.length > 0 &&
                deliverableDraft.draft!.draftAttachments.length > 0 && (
                  <div className="remarks-section">
                    <p>{deliverableDraft.draft!.remarks}</p>
                  </div>
                )}
              {renderDraftMedia()}
            </div>
          )}
          {/* REJECTION SCENARIO */}
          {deliverableDraft.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
            deliverableDraft.draft?.status === "rejected" && (
              <div className="image-wrapper">
                {deliverableDraft.draft!.brandResponse?.length > 0 && (
                  <div className="remarks-section">
                    <p>{deliverableDraft.draft!.brandResponse}</p>
                  </div>
                )}
                {renderDraftMedia()}
              </div>
            )}
          {/* DISPUTE SCENARIO */}
          {deliverableDraft.status === DELIVERABLE_STATUS.DISPUTE && (
            <div className="image-wrapper">
              {!deliverableDraft.draft && (
                <>
                  <img
                    className="empty-draft-img"
                    alt="no draft submitted"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-draft.png"
                  />
                  <span className="empty-draft-header">No Draft Submitted</span>
                  <p className="empty-draft-description  mt-2">
                    You need to submit a draft before posting deliverable
                  </p>
                </>
              )}
              {deliverableDraft.draft && <>{renderDraftMedia()}</>}
            </div>
          )}
          <div className="details-wrapper">
            {deliverableDraft.creator.imageType === "url" && (
              <img
                className="profile"
                alt={deliverableDraft.creator.fullName}
                src={deliverableDraft.creator.imageResource}
              />
            )}
            {deliverableDraft.creator.imageType === "avatar" && (
              <div
                className="profile"
                style={{ background: deliverableDraft.creator.imageResource }}
              >
                <span className="initials">
                  {deliverableDraft.creator.initials}
                </span>
              </div>
            )}
            <div className="info-wrapper">
              <span className="name-lbl">
                {deliverableDraft.creator.fullName}
              </span>
              <div className="mt-1 pill-wrapper ">
                {!deliverableDraft.draft &&
                  deliverableDraft.status ===
                    DELIVERABLE_STATUS.PENDING_DRAFT && (
                    <EmpPill
                      size="sm"
                      text={StringUtils.convertHyphensToTitleCase(
                        deliverableDraft.status
                      )}
                      backgroundColor={Color.NEUTRAL[100]}
                      color={Color.NEUTRAL[500]}
                    />
                  )}
                {[DELIVERABLE_STATUS.IN_REVIEW].includes(
                  deliverableDraft.status
                ) && (
                  <EmpPill
                    size="sm"
                    text={StringUtils.convertHyphensToTitleCase(
                      deliverableDraft.status
                    )}
                    backgroundColor={Color.PRIMARY[100]}
                    color={Color.PRIMARY[600]}
                  />
                )}
                {[
                  DELIVERABLE_STATUS.APPROVED,
                  DELIVERABLE_STATUS.COMPLETED,
                ].includes(deliverableDraft.status) && (
                  <EmpPill
                    size="sm"
                    text={StringUtils.convertHyphensToTitleCase(
                      deliverableDraft.status
                    )}
                    backgroundColor={Color.GREEN[100]}
                    color={Color.GREEN[500]}
                  />
                )}
                {deliverableDraft.draft?.status === "rejected" &&
                  deliverableDraft.status ===
                    DELIVERABLE_STATUS.PENDING_DRAFT && (
                    <EmpPill size="sm" text={"Rejected"} {...PILL_COLORS.red} />
                  )}
                {deliverableDraft.status === DELIVERABLE_STATUS.DISPUTE && (
                  <EmpPill size="sm" text={"Dispute"} {...PILL_COLORS.red} />
                )}
                {renderRevisionPill(deliverableDraft.revisionsLeft)}
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            {deliverableDraft.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
              !deliverableDraft.draft &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  text={"New Draft"}
                  onSubmit={() => {
                    if (onCreate) onCreate();
                  }}
                  buttonHeight="sm"
                />
              )}

            {deliverableDraft.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
              deliverableDraft.draft?.status === "rejected" &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  text={"Resubmit Draft"}
                  onSubmit={() => {
                    if (onResubmit) onResubmit();
                  }}
                  buttonHeight="sm"
                />
              )}

            {deliverableDraft.status === DELIVERABLE_STATUS.PENDING_DRAFT &&
              role === "brand" && (
                <EmpButton
                  buttonStyle="secondary"
                  className="mt-2"
                  text={"Chat"}
                  onSubmit={() => {
                    if (onChat) onChat();
                  }}
                  buttonHeight="sm"
                />
              )}

            {deliverableDraft.status === DELIVERABLE_STATUS.IN_REVIEW &&
              role === "seller" && (
                <EmpButton
                  className="mt-2"
                  buttonStyle="secondary"
                  text={"View Draft"}
                  onSubmit={() => {
                    if (onView) onView();
                  }}
                  buttonHeight="sm"
                />
              )}

            {deliverableDraft.status === DELIVERABLE_STATUS.IN_REVIEW &&
              role === "brand" && (
                <EmpButton
                  className="mt-2"
                  text={"Review"}
                  onSubmit={() => {
                    if (onReview) onReview();
                  }}
                  buttonHeight="sm"
                />
              )}
            {deliverableDraft.status === DELIVERABLE_STATUS.APPROVED &&
              role === "brand" && (
                <span className="emp-paragraph">
                  Creator has been cleared to submit the post.
                </span>
              )}
            {deliverableDraft.status === DELIVERABLE_STATUS.DISPUTE && (
              <span className="emp-paragraph">
                A party has submitted a dispute
              </span>
            )}
          </div>
        </div>
      )}

      {mode === "skeleton" && (
        <div
          className="emp-draft-card skeleton-wrapper emp-card no-padding"
          style={{ width: `${width}px` }}
        >
          <div className="image-wrapper emp-shimmer"></div>
          <div className="details-wrapper">
            <div className="profile-image-2 emp-shimmer"></div>
            <div className="info-wrapper">
              <div className="name-2 emp-shimmer"></div>
              <div className="status emp-shimmer"></div>
            </div>
          </div>
          <div className="ph-3 pb-2">
            <div className="fake-button emp-shimmer mt-2"></div>
          </div>
        </div>
      )}
    </>
  );
};
