import { useCallback, useEffect, useRef, useState } from "react";
import "./agency-profile-page.scss";
import { UserDto } from "../../model/user-management/user.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import EmpException from "../../exception/empException";
import EmpContent from "../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import EmpLink from "../../components/shared/emp-link/emp-link";
import { Color } from "../../utilities/colors";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import { useNavigate } from "react-router-dom";
import _debounce from "lodash/debounce";
import EditAboutUsModal, { EditAboutUsModalRef } from "./edit-about-us-modal";
import FileIcon from "../../components/icon/file-icon";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import { PLATFORM_ROLES } from "../../constants/app.constants";
import EmpButton from "../../components/shared/emp-button/emp-button";
import EditIcon from "../../components/icon/edit-icon";
import EditProfilePicturesModal, {
  EditProfilePicturesModalRef,
} from "./edit-profile-pictures-modal";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import ProfileApi from "../../api/user-msvc/profile.api";
import UserApi from "../../api/user-msvc/user.api";
import FileUtils from "../../utilities/file-util";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { PAGE_PERMS, PermsUtils } from "../../constants/permissions.constants";
import UserUtils from "../../utilities/user-utils";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";
import XCloseIcon from "../../components/icon/x-close-icon";
import CheckIcon from "../../components/icon/check-icon";
import { motion } from "framer-motion";
import { empDelay } from "../../utilities/delay";
import {
  EditNameConfirmationModal,
  EditNameConfirmationModalRef,
} from "./edit-name-confirmation-modal";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import { FormattedMessage } from "react-intl";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const AgencyProfilePage = () => {
  const navigate = useNavigate();
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const [orgUser, setOrgUser] = useState<UserDto>();
  const [organisation, setOrganisation] = useState<OrganisationRespDto>();
  const [user, setUser] = useState<UserDto>();
  const [profile, setProfile] = useState<AgencyProfileDto>();
  const [talents, setTalents] = useState<SmCreatorRespDto[]>([]);

  const [isAboutUsEmpty, setAboutUsEmpty] = useState(false);
  const [isAboutUsExpanded, setAboutUsExpanded] = useState(false);
  const [isSeeMoreVisible, setSeeMoreVisible] = useState<boolean>();
  const aboutUsParaRef = useRef<any>(null);

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  const editAboutUsModalRef = useRef<EditAboutUsModalRef>();
  const editProfilePictureModalRef = useRef<EditProfilePicturesModalRef>();

  const savedOrgName = useRef<string>("");
  const [orgNameEditableFocused, setOrgNameEditableFocused] = useState(false);

  const editNameConfirmationModalRef = useRef<EditNameConfirmationModalRef>();
  const nameEditableControlsRef = useRef<HTMLDivElement>(null);
  const nameEditableRef = useRef<HTMLDivElement>(null);

  const isMobile = useEmpBreakpoint(500);
  const isMobileRef = useRef<boolean>(isMobile);

  useEffect(() => {
    isMobileRef.current = isMobile;
    computeDetailsCardWidth();
  }, [isMobile]);

  /**
   * Fetches the user details and organization data.
   */
  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await UserApi.fetchUser();

      setUser(response.data);
      const organisations = response.data.organisation!;
      if (organisations?.length === 0)
        throw new EmpException("Unable to fetch organisation");
      for (let org of organisations) {
        if (org.type === PLATFORM_ROLES.AGENCY) {
          setOrganisation(org);
          savedOrgName.current = org.companyName;
          fetchProfile(org.id);
          break;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const fetchProfile = async (organisationId: string) => {
    try {
      const response = await ProfileApi.fetchProfile(organisationId);
      const profile = response.data;
      setProfile(profile);
      setAboutUsEmpty(
        profile.profileAttachments.length === 0 && profile.aboutUs.length === 0
      );
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  /**
   * Fetches the organization root user.
   */
  const fetchOrgRoot = useCallback(async () => {
    try {
      const response = await UserApi.fetchOrganisationRoot();
      setOrgUser(response.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  const fetchTalents = useCallback(async () => {
    try {
      const response = await OrganisationApi.fetchAgencyCreators();
      setTalents(response.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
    fetchOrgRoot();
    fetchTalents();
  }, [fetchOrgRoot, fetchUserDetails, fetchTalents]);

  const setSeeMoreOption = (el: HTMLParagraphElement) => {
    if (el === null) return;
    const isCollapsible = el.scrollHeight > el.clientHeight;
    setSeeMoreVisible(isCollapsible);
  };

  const GUTTER_WIDTH = 12;

  const computeDetailsCardWidth = () => {
    const minCardWidth = isMobileRef.current ? 150 : 210;
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / minCardWidth), 1);

    const cardWidth = Math.floor(
      (rowWidth - (maxCards - 1) * GUTTER_WIDTH) / maxCards
    );

    setCardWidth(cardWidth);
    setShimmerCards(Array.from(Array(maxCards * 2).keys()));
  };
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  /**
   * This function is triggered when the check button, or the 'enter' button is clicked when the user is
   * changing the company name
   * @returns void
   */
  const onUpdateCompanyName = () => {
    try {
      if (!organisation) throw new EmpException("No organisation");
      const updatedCompanyName = nameEditableRef.current?.innerHTML as string;
      if (updatedCompanyName === organisation.companyName) return;

      if (organisation!) {
        editNameConfirmationModalRef.current!.show({
          companyName: updatedCompanyName,
          organisationId: organisation!.id,
        });
        setOrgNameEditableFocused(false);
      }
    } catch (e) {
      if (e instanceof Error)
        new EmpExceptionHandlerBuilder()
          .handleGenericError()
          .build()
          .process(e);
    }
  };

  // Use Effect to update nameditable ref if there is an update
  useEffect(() => {
    if (organisation && nameEditableRef.current) {
      nameEditableRef.current.innerHTML = organisation.companyName;
    }
  }, [organisation]);

  const editableNameWrapperOnClick = async (e: any) => {
    if (!orgNameEditableFocused) return;
    const clickedElement = e.target as Node;
    if (
      clickedElement &&
      (clickedElement.contains(nameEditableControlsRef.current) ||
        clickedElement === nameEditableRef.current)
    ) {
      return;
    } else {
      setOrgNameEditableFocused(false);
    }
  };

  return (
    <div
      onClick={(e) => {
        editableNameWrapperOnClick(e);
      }}
      className="emp-page-wrapper emp-responsive"
    >
      {profile && (
        <EditNameConfirmationModal
          ref={editNameConfirmationModalRef}
          onSave={() => {
            UserUtils.updateUser();
            fetchProfile(profile.organisationId);
            fetchUserDetails();
          }}
        />
      )}
      {profile && (
        <EditProfilePicturesModal
          ref={editProfilePictureModalRef}
          onSave={() => {
            UserUtils.updateUser();
            fetchProfile(profile.organisationId);
            fetchUserDetails();
          }}
        />
      )}
      {profile && (
        <EditAboutUsModal
          ref={editAboutUsModalRef}
          onSave={() => {
            fetchProfile(profile.organisationId);
          }}
        />
      )}
      <div className="emp-page-content-no-padding emp-profile-page">
        <div className="context-section agency-card">
          {profile && <div className="colored-bg-section"></div>}
          <div className="common-info-section">
            {/* Image Wrapper */}
            {organisation && profile && (
              <div className="image-card">
                <img alt="Profile" src={organisation.logo} />
                {user &&
                  PermsUtils.hasPerms(
                    user,
                    PAGE_PERMS.AGENCY_PROFILE.UPDATE_PIC
                  ) && (
                    <button
                      className="emp-button-reset edit-btn"
                      onClick={() => {
                        editProfilePictureModalRef.current?.show(
                          profile,
                          organisation
                        );
                      }}
                    >
                      <EditIcon
                        top={-1}
                        backgroundColor={Color.NEUTRAL[300]}
                        size={16}
                      />
                    </button>
                  )}
              </div>
            )}
            {/* Name and Email */}
            {organisation && (
              <div className="info-wrapper">
                {user &&
                  PermsUtils.hasPerms(
                    user,
                    PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO
                  ) && (
                    <div className="editable-name-wrapper">
                      {/* Controls Wrapper` */}
                      {orgNameEditableFocused && (
                        <motion.div
                          ref={nameEditableControlsRef}
                          initial="hidden"
                          variants={fadeInVariants}
                          animate="visible"
                          className="controls-wrapper"
                        >
                          <EmpIconButton
                            buttonStyle="secondary"
                            icon={
                              <XCloseIcon
                                strokeWidth={3}
                                backgroundColor={Color.NEUTRAL[100]}
                              />
                            }
                          />
                          <EmpIconButton
                            onSubmit={() => {
                              onUpdateCompanyName();
                            }}
                            buttonStyle="secondary"
                            icon={
                              <CheckIcon backgroundColor={Color.NEUTRAL[100]} />
                            }
                          />
                        </motion.div>
                      )}
                      <div
                        suppressContentEditableWarning={true}
                        className="editable-name"
                        contentEditable
                        ref={nameEditableRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            onUpdateCompanyName();
                          }
                        }}
                        onFocus={async () => {
                          await empDelay(100);
                          setOrgNameEditableFocused(true);
                        }}
                      >
                        {organisation.companyName}
                      </div>
                    </div>
                  )}
                {user &&
                  !PermsUtils.hasPerms(
                    user,
                    PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO
                  ) && (
                    <span className="name-lbl">{organisation.companyName}</span>
                  )}
                <span className="email-lbl mt-1">{orgUser?.email}</span>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-section mt-3">
          <div className="left-section">
            <div className="emp-card agency-card">
              <h2 className="emp-card-header">
                <FormattedMessage id="agencyProfilePage_basicInformationCardHeader" />
              </h2>
              {/* Details Section */}
              {orgUser && organisation && (
                <div className="details-wrapper mt-4">
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardEmailLabel" />
                    }
                    value={orgUser?.email}
                  />
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardCountryLabel" />
                    }
                    value={
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[organisation.country]["flag_1x1"]
                          }
                        />
                        <span>
                          {COUNTRY_CONSTANTS[organisation.country].name}
                        </span>
                      </div>
                    }
                  />
                  <EmpContent
                    label={
                      <FormattedMessage id="agencyProfilePage_basicInformationCardAddressLabel" />
                    }
                    value={`${organisation.address} ${organisation.postalCode}`}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="right-section">
            {profile && !isAboutUsEmpty && (
              <div className="emp-card about-us-card agency-card">
                <div className="header-wrapper">
                  <h2 className="emp-card-header">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardHeader" />
                  </h2>
                  {user &&
                    PermsUtils.hasPerms(
                      user,
                      PAGE_PERMS.AGENCY_PROFILE.UPDATE_BIO
                    ) && (
                      <EmpLink
                        text={<FormattedMessage id="cta_edit" />}
                        onSubmit={() => {
                          editAboutUsModalRef.current?.show(profile);
                        }}
                      />
                    )}
                </div>
                <p
                  ref={(el: HTMLParagraphElement) => {
                    aboutUsParaRef.current = el;
                    setSeeMoreOption(el);
                  }}
                  className={`emp-card-description ${
                    isAboutUsExpanded ? "" : "truncated"
                  }`}
                >
                  {profile.aboutUs}
                </p>
                {isSeeMoreVisible !== undefined && isSeeMoreVisible && (
                  <EmpLink
                    onSubmit={() => {
                      setAboutUsExpanded(!isAboutUsExpanded);
                    }}
                    text={"See more"}
                  />
                )}

                {/* Uploaded Files Section  */}
                <div className="uploaded-file-wrapper mt-4">
                  {profile.profileAttachments.map((elem) => {
                    return (
                      <div key={elem.id} className="uploaded-file-card">
                        <div className="image-section">
                          <FileIcon backgroundColor={Color.NEUTRAL[0]} />
                        </div>
                        <div className="text-section">
                          <span className="file-title">{elem.label}</span>
                          <div style={{ marginTop: 4 }}>
                            <EmpLink
                              onSubmit={() => {
                                FileUtils.handleFileDownload(
                                  elem.fileName,
                                  elem.url
                                );
                              }}
                              text={<FormattedMessage id="cta_download" />}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {profile && isAboutUsEmpty && (
              <div className="emp-card no-padding about-us-card-empty">
                <div className="header-wrapper">
                  <h2 className="emp-card-header">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardHeader" />
                  </h2>
                  <p className="emp-card-description">
                    <FormattedMessage id="agencyProfilePage_aboutUsCardEmptyDesc" />
                  </p>
                  <EmpButton
                    isFullWidth={false}
                    text={
                      <FormattedMessage id="agencyProfilePage_aboutUsNewSubmissionBtn" />
                    }
                    onSubmit={() => {
                      editAboutUsModalRef.current?.show(profile);
                    }}
                  />
                </div>
                <div className="about-us-section-wrapper">
                  <img
                    className="about-us-empty-img"
                    alt="empty about us"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/about-us-empty.png"
                  />
                </div>
              </div>
            )}

            <div className="social-media-label mt-3">
              <h1>
                <FormattedMessage id="agencyProfilePage_creatorSectionHeader" />
              </h1>
            </div>
            {/* Onboarded Talents Section  */}
            <div ref={resizeDivRef}></div>
            <div className="onboarded-talents">
              {talents.length > 0 &&
                talents.map((elem, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/agency/creator-details/${elem.id}`);
                      }}
                      key={elem.id}
                      className={`talent-card`}
                      style={{ width: cardWidth }}
                    >
                      <div className="details-wrapper ">
                        {elem.imageType === "url" && (
                          <img
                            className="profile-img"
                            alt={elem.name}
                            src={elem.imageResource}
                          />
                        )}
                        {elem.imageType === "avatar" && (
                          <div
                            className="profile-avatar"
                            style={{ background: elem.imageResource }}
                          >
                            <span>{elem.initials}</span>
                          </div>
                        )}
                        <div className="account-details-wrapper ml-2">
                          <div className="name">{elem.name}</div>
                          <div className="email">{elem.email}</div>
                        </div>
                      </div>
                      <div className="metrics-wrapper mt-4">
                        <div className="metric-left">
                          <EmpContent
                            label={
                              <FormattedMessage id="agencyProfilePage_creatorCardImpressionsLabel" />
                            }
                            value={elem.impressions.toString()}
                          />
                        </div>
                        <div className="metric-right">
                          <EmpContent
                            label={
                              <FormattedMessage id="agencyProfilePage_creatorCardCountryLabel" />
                            }
                            value={
                              <div className="country-wrapper">
                                <img
                                  className="img-flag"
                                  alt="country"
                                  src={
                                    COUNTRY_CONSTANTS[elem.country]["flag_1x1"]
                                  }
                                />
                                <span>
                                  {COUNTRY_CONSTANTS[elem.country].name}
                                </span>
                              </div>
                            }
                          />
                        </div>
                      </div>
                      <div className="social-media-wrapper mt-3">
                        {elem.platforms.length > 0 && (
                          <>
                            {elem.platforms.includes("Facebook") && (
                              <FacebookIcon
                                size={15}
                                backgroundColor={Color.NEUTRAL[300]}
                              />
                            )}
                            {elem.platforms.includes("Instagram") && (
                              <InstagramIcon
                                size={15}
                                backgroundColor={Color.NEUTRAL[300]}
                              />
                            )}
                            {elem.platforms.includes("TikTok") && (
                              <TikTokIcon
                                size={15}
                                backgroundColor={Color.NEUTRAL[300]}
                              />
                            )}
                            {elem.platforms.includes("X") && (
                              <TikTokIcon
                                size={15}
                                backgroundColor={Color.NEUTRAL[300]}
                              />
                            )}
                          </>
                        )}
                        {elem.platforms.length === 0 && (
                          <span>
                            <FormattedMessage id="agencyMyCreatorsView_creatorCardNoSocialMediaAccountsLabel" />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}

              {talents.length === 0 && (
                <div className="talent-empty-state">
                  <div className="overlay">
                    <div className="info-wrapper">
                      <img
                        className="empty-img"
                        alt="No creators found"
                        srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                      />
                      <h2 className="header">
                        <FormattedMessage id="agencyProfilePage_emptyHeader" />
                      </h2>
                      <p className="description">
                        <FormattedMessage id="agencyProfilePage_emptyDesc" />
                      </p>
                    </div>
                  </div>
                  {shimmerCard.map((elem) => {
                    return (
                      <div
                        key={elem}
                        className={`talent-placeholder-card`}
                        style={{ width: cardWidth }}
                      >
                        <div className="details-wrapper ">
                          <div className="profile shimmer-color"></div>
                          <div className="account-details-wrapper ml-2">
                            <div className="name shimmer-color"></div>
                            <div className="email shimmer-color"></div>
                          </div>
                        </div>
                        <div className="metrics-wrapper mt-4">
                          <div className="metric pr-2">
                            <div className="title shimmer-color"></div>
                            <div className="value shimmer-color"></div>
                          </div>
                          <div className="metric pl-2">
                            <div className="title shimmer-color"></div>
                            <div className="value shimmer-color"></div>
                          </div>
                        </div>
                        <div className="social-media-wrapper mt-6">
                          <div className="icon shimmer-color"></div>
                          <div className="icon shimmer-color"></div>
                          <div className="icon shimmer-color"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
