import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CreateEvidenceDto } from "../../model/campaign/create-evidence.dto";
import { EvidenceDto } from "../../model/campaign/evidence.dto";
import { EmpInterceptor } from "../emp-interceptor";

const EvidenceApi = {
  fetchEvidenceAsSeller: async (
    taskId: string
  ): Promise<ApiResponse<EvidenceDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<EvidenceDto[]>>(
      ENDPOINTS.campaignMsvc.evidence.fetchEvidenceAsSeller(taskId)
    );
    return response.data;
  },
  fetchEvidenceAsBrand: async (
    taskId: string
  ): Promise<ApiResponse<EvidenceDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<EvidenceDto[]>>(
      ENDPOINTS.campaignMsvc.evidence.fetchEvidenceAsBrand(taskId)
    );
    return response.data;
  },
  createEvidence: async (
    request: CreateEvidenceDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.evidence.base,
      request
    );
    return response.data;
  },
  fetchEvidenceByIdAsSeller: async (
    evidenceId: string
  ): Promise<ApiResponse<EvidenceDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<EvidenceDto>>(
      ENDPOINTS.campaignMsvc.evidence.fetchEvidenceByIdAsSeller(evidenceId)
    );
    return response.data;
  },
  fetchEvidenceByIdAsBrand: async (
    evidenceId: string
  ): Promise<ApiResponse<EvidenceDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<EvidenceDto>>(
      ENDPOINTS.campaignMsvc.evidence.fetchEvidenceByIdAsBrand(evidenceId)
    );
    return response.data;
  },
  deleteEvidenceRecord: async (
    evidenceRecordId: string
  ): Promise<ApiResponse<EvidenceDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.delete<ApiResponse<EvidenceDto>>(
      ENDPOINTS.campaignMsvc.evidence.deleteEvidence(evidenceRecordId)
    );
    return response.data;
  },
};
export default EvidenceApi;
