import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KycApi from "../../api/payment-msvc/kyc.api";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpMobileNumberSelect from "../../components/shared/emp-mobile-number-select/emp-mobile-number-select";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { KYC_PROGRESS } from "../../constants/app.constants";
import { getNationalities } from "../../constants/selectConstants";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { MobileCountryValidator } from "../../utilities/formUtils/mobileCountryValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import UploadIcon from "../../components/icon/upload-icon";
import { Color } from "../../utilities/colors";
import FileUtils from "../../utilities/file-util";
import FileIcon from "../../components/icon/file-icon";
import AlertSquareIcon from "../../components/icon/alert-square";
import { SaveKycOrgShareholderInfoDto } from "../../model/payment/kyc/save-kyc-org-shareholder-info";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import EmpLink from "../../components/shared/emp-link/emp-link";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;

interface Props {
  kycInfo?: KycDto;
  nextStep: () => void;
}

export const KycOrgShareholderInfoStep = (props: Props) => {
  const { kycInfo, nextStep } = props;
  const intl = useIntl();
  const [form, setForm] = useState<IFormGroup>();
  const nextBtnRef = useRef<EmpButtonRef>();

  const [hasSubmitted, setSubmitted] = useState(false);
  // Ensures that this component will only be set once
  const prefillFlagRef = useRef(false);

  const [uploadedProofOfAddressFile, setUploadedProofOfAddressFile] =
    useState<File>();
  const [
    uploadedProofOfIdentificationFile,
    setUploadedProofOfIdentificationFile,
  ] = useState<File>();

  const [existingProofOfAddressFile, setExistingProofOfAddressFile] = useState<{
    size: number;
    name: string;
  }>();
  const [
    existingProofOfIdentificationFile,
    setExistingProofOfIdentificationFile,
  ] = useState<{
    size: number;
    name: string;
  }>();

  const nationalityOptions = useMemo(() => {
    return getNationalities(intl);
  }, [intl]);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);

  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());
  useEffect(() => {
    if (!kycInfo || !form || prefillFlagRef.current) return;
    if (kycInfo.status === "rejected") fetchKycRejection();

    if (kycInfo.kycStakeholderInfo?.identificationDocumentName) {
      setExistingProofOfIdentificationFile({
        size: kycInfo.kycStakeholderInfo.identificationDocumentSize!,
        name: kycInfo.kycStakeholderInfo.identificationDocumentName!,
      });
    }
    if (kycInfo.kycStakeholderInfo?.proofOfAddressDocumentName) {
      setExistingProofOfAddressFile({
        size: kycInfo.kycStakeholderInfo.proofOfAddressDocumentSize!,
        name: kycInfo.kycStakeholderInfo.proofOfAddressDocumentName!,
      });
    }

    setForm((prevForm: IFormGroup | undefined) => {
      if (!prevForm) return prevForm;
      prevForm.fullName.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.legalName ?? ""
      );
      prevForm.email.forceUpdateValue(kycInfo?.kycStakeholderInfo?.email ?? "");
      prevForm.mobileNumber.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.mobileNumber === undefined
          ? { mobileNumber: "", dialCode: "", code: "" }
          : {
              mobileNumber: kycInfo.kycStakeholderInfo.mobileNumber,
              dialCode: kycInfo.kycStakeholderInfo.dialCode!,
              code: kycInfo.kycStakeholderInfo.code!,
            }
      );
      prevForm.jobTitle.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.jobTitle ?? ""
      );
      prevForm.nationality.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.nationality ?? ""
      );
      prevForm.address.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.streetAddress ?? ""
      );
      prevForm.postalCode.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.postalCode ?? ""
      );
      prevForm.city.forceUpdateValue(kycInfo?.kycStakeholderInfo?.city ?? "");
      prevForm.identificationNumber.forceUpdateValue(
        kycInfo?.kycStakeholderInfo?.identificationNumber ?? ""
      );
      return { ...prevForm };
    });
    prefillFlagRef.current = true;
  }, [kycInfo, form]);

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        fullName: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_fullNameRequired" })
            ),
            new LengthValidator(
              0,
              150,
              undefined,
              intl.formatMessage(
                { id: "validation_jobTitleMaxLength" },
                { char: "150" }
              )
            ),
          ],
          prevForm?.fullName.getValue() ?? ""
        ),
        email: new FormControl(
          "text",
          [
            new EmailValidator(
              intl.formatMessage({ id: "validation_emailRequired" }),
              intl.formatMessage({ id: "validation_emailInvalid" }),
              intl.formatMessage({ id: "validation_emailTooLong" })
            ),
          ],
          prevForm?.email.getValue() ?? ""
        ),
        mobileNumber: new FormControl(
          "mobile-number",
          [new MobileCountryValidator(intl)],
          prevForm?.mobileNumber.getValue() ?? {
            mobileNumber: "",
            code: undefined,
            dialCode: "",
          }
        ),
        jobTitle: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_jobTitleRequired" })
            ),
            new LengthValidator(
              0,
              80,
              undefined,
              intl.formatMessage(
                { id: "validation_jobTitleMaxLength" },
                { char: "80" }
              )
            ),
          ],
          prevForm?.jobTitle.getValue() ?? ""
        ),
        nationality: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_nationalityRequired" })
            ),
          ],
          prevForm?.nationality.getValue() ?? ""
        ),
        address: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_streetAddressRequired" })
            ),
            new LengthValidator(
              0,
              150,
              undefined,
              intl.formatMessage(
                { id: "validation_streetAddressMaxLength" },
                { char: 150 }
              )
            ),
          ],
          prevForm?.address.getValue() ?? ""
        ),
        city: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_cityRequired" })
            ),
            new LengthValidator(
              0,
              100,
              undefined,
              intl.formatMessage(
                { id: "validation_cityMaxLength" },
                { char: 100 }
              )
            ),
          ],
          prevForm?.city.getValue() ?? ""
        ),
        postalCode: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_postalCodeRequired" })
            ),
            new LengthValidator(
              0,
              20,
              undefined,
              intl.formatMessage(
                { id: "validation_postalCodeMaxLength" },
                { char: 20 }
              )
            ),
          ],
          prevForm?.postalCode.getValue() ?? ""
        ),
        identificationNumber: new FormControl(
          "text",
          [
            new RequiredValidator(
              intl.formatMessage({ id: "validation_identificationNoRequired" })
            ),
            new LengthValidator(
              0,
              30,
              undefined,
              intl.formatMessage(
                { id: "validation_identificationNoLength" },
                { char: 30 }
              )
            ),
          ],
          prevForm?.identificationNumber.getValue() ?? ""
        ),
        proofOfIdentification: new FormControl(
          "text",
          [],
          prevForm?.proofOfIdentification.getValue() ?? ""
        ),
        proofOfAddress: new FormControl(
          "text",
          [],
          prevForm?.proofOfAddress.getValue() ?? ""
        ),
      };
      if (hasSubmitted) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmitted) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const onSubmit = async () => {
    try {
      nextBtnRef.current?.setButtonState("loading");
      if (!form) return;
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      let hasFile = true;
      if (!existingProofOfAddressFile && !uploadedProofOfAddressFile) {
        form.proofOfAddress.errorMessage = intl.formatMessage({
          id: "validation_proofOfAddressDocumentRequired",
        });
        form.proofOfAddress.hasError = true;
        hasFile = false;
      }
      if (
        !existingProofOfIdentificationFile &&
        !uploadedProofOfIdentificationFile
      ) {
        form.proofOfIdentification.errorMessage = intl.formatMessage({
          id: "validation_proofOfIdentificationDocumentRequired",
        });
        form.proofOfIdentification.hasError = true;
        hasFile = false;
      }
      if (!isValid || !hasFile) return;

      const request: SaveKycOrgShareholderInfoDto = {
        legalName: form.fullName.getValue(),
        email: form.email.getValue(),
        mobileNumber: form.mobileNumber.getValue().mobileNumber,
        dialCode: form.mobileNumber.getValue().dialCode,
        code: form.mobileNumber.getValue().code,
        jobTitle: form.jobTitle.getValue(),
        nationality: form.nationality.getValue(),
        streetAddress: form.address.getValue(),
        postalCode: form.postalCode.getValue(),
        city: form.city.getValue(),
        identificationNumber: form.identificationNumber.getValue(),

        hasProofOfIdentificationFile: Boolean(
          uploadedProofOfIdentificationFile
        ),
        proofOfIdentificationFile: Boolean(uploadedProofOfIdentificationFile)
          ? await FileUtils.readFileAsBase64(uploadedProofOfIdentificationFile!)
          : undefined,
        proofOfIdentificationFileName: Boolean(
          uploadedProofOfIdentificationFile
        )
          ? uploadedProofOfIdentificationFile!.name
          : undefined,

        hasProofOfAddressFile: Boolean(uploadedProofOfAddressFile),
        proofOfAddressFile: Boolean(uploadedProofOfAddressFile)
          ? await FileUtils.readFileAsBase64(uploadedProofOfAddressFile!)
          : undefined,
        proofOfAddressFileName: Boolean(uploadedProofOfAddressFile)
          ? uploadedProofOfAddressFile!.name
          : undefined,
      };
      const resp = await KycApi.saveKycProgress(
        KYC_PROGRESS.ORG_SHAREHOLDER_INFO.step,
        { details: request }
      );
      if (isApiStatus(resp.data) && resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_shareholder_successToastHeader",
          }),
          intl.formatMessage({
            id: "kycFormSection_shareholder_successToastDesc",
          })
        );
        nextStep();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save KYC progress"
      );
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    origin: "proof-of-address" | "proof-of-identification"
  ) => {
    try {
      if (!form) return;
      const file = event.target.files?.[0];
      if (!file) return;

      const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 5);
      if (!isSmallEnough) {
        if (origin === "proof-of-address") {
          form.proofOfAddress.errorMessage = intl.formatMessage(
            { id: "validation_documentTooLarge" },
            { size: 5 }
          );
          form.proofOfAddress.hasError = true;
          setUploadedProofOfAddressFile(undefined);
        } else if (origin === "proof-of-identification") {
          form.proofOfIdentification.errorMessage = intl.formatMessage(
            { id: "validation_documentTooLarge" },
            { size: 5 }
          );
          form.proofOfIdentification.hasError = true;
          setUploadedProofOfIdentificationFile(undefined);
        }
        return;
      }
      if (origin === "proof-of-address") {
        form.proofOfAddress.errorMessage = "";
        form.proofOfAddress.hasError = false;
        setUploadedProofOfAddressFile(file);
      } else if (origin === "proof-of-identification") {
        form.proofOfIdentification.errorMessage = "";
        form.proofOfIdentification.hasError = false;
        setUploadedProofOfIdentificationFile(file);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setForm({ ...form });
    }
  };

  return (
    <div className="kyc-form">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
      >
        <h2 className="form-header">
          <FormattedMessage id="kycFormSection_shareholder_header" />
        </h2>
        <p className="emp-paragraph mt-3">
          <FormattedMessage id="kycFormSection_shareholder_desc" />
        </p>
      </motion.div>
      {form && (
        <div className="mt-6 form-control-wrapper">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
          >
            <EmpTextInput
              id={"fullName"}
              labelText={intl.formatMessage({
                id: "kycFormSection_legalNameLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_legalNamePlaceholder",
              })}
              required
              description={intl.formatMessage({
                id: "kycFormSection_legalNameDesc",
              })}
              onChange={formOnChange}
              formControl={form.fullName}
            />
            {fieldDisputeMap.has("kycStakeholderInfo_legalName") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycStakeholderInfo_legalName")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
          >
            <EmpTextInput
              id={"email"}
              labelText={intl.formatMessage({
                id: "kycFormSection_emailLabel",
              })}
              required
              placeholder={intl.formatMessage({
                id: "kycFormSection_emailPlaceholder",
              })}
              onChange={formOnChange}
              formControl={form.email}
            />
            {fieldDisputeMap.has("kycStakeholderInfo_email") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycStakeholderInfo_email")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            style={{ zIndex: 2 }}
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 3 }}
          >
            <EmpMobileNumberSelect
              required
              labelText={intl.formatMessage({
                id: "kycFormSection_mobileNumberLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_mobileNumberPlaceholder",
              })}
              onChange={formOnChange}
              id={"mobileNumber"}
              formControl={form.mobileNumber}
            />

            {fieldDisputeMap.has("kycStakeholderInfo_mobileNumber") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get("kycStakeholderInfo_mobileNumber")!
                      .message
                  }
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 4 }}
          >
            <EmpTextInput
              id={"jobTitle"}
              labelText={intl.formatMessage({
                id: "kycFormSection_jobTitleLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_jobTitlePlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.jobTitle}
            />

            {fieldDisputeMap.has("kycStakeholderInfo_jobTitle") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycStakeholderInfo_jobTitle")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 5 }}
          >
            <EmpSelect
              id={"nationality"}
              required
              labelText={intl.formatMessage({
                id: "kycFormSection_nationalityLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_nationalityPlaceholder",
              })}
              onChange={formOnChange}
              selectOptions={nationalityOptions}
              formControl={form.nationality}
            />

            {fieldDisputeMap.has("kycStakeholderInfo_nationality") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get("kycStakeholderInfo_nationality")!
                      .message
                  }
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 6 }}
          >
            <EmpTextInput
              id={"address"}
              labelText={intl.formatMessage({
                id: "kycFormSection_streetAddressLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_streetAddressPlaceholder",
              })}
              required
              onChange={formOnChange}
              formControl={form.address}
            />

            {fieldDisputeMap.has("kycStakeholderInfo_streetAddress") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get("kycStakeholderInfo_streetAddress")!
                      .message
                  }
                </p>
              </div>
            )}
          </motion.div>

          <div className="split-forms">
            <motion.div
              className="split-form-wrapper"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 7 }}
            >
              <EmpTextInput
                id={"city"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_cityLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_cityPlaceholder",
                })}
                required
                formControl={form.city}
              />

              {fieldDisputeMap.has("kycStakeholderInfo_city") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {fieldDisputeMap.get("kycStakeholderInfo_city")!.message}
                  </p>
                </div>
              )}
            </motion.div>
            <motion.div
              className="split-form-wrapper"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 8 }}
            >
              <EmpTextInput
                id={"postalCode"}
                labelText={intl.formatMessage({
                  id: "kycFormSection_postalCodeLabel",
                })}
                placeholder={intl.formatMessage({
                  id: "kycFormSection_postalCodePlaceholder",
                })}
                required
                formControl={form.postalCode}
              />
              {fieldDisputeMap.has("kycStakeholderInfo_postalCode") && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get("kycStakeholderInfo_postalCode")!
                        .message
                    }
                  </p>
                </div>
              )}
            </motion.div>
          </div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 9 }}
          >
            <EmpTextInput
              id={"identificationNumber"}
              required
              labelText={intl.formatMessage({
                id: "kycFormSection_identificationNumberLabel",
              })}
              placeholder={intl.formatMessage({
                id: "kycFormSection_identificationNumberPlaceholder",
              })}
              onChange={formOnChange}
              formControl={form.identificationNumber}
            />

            {fieldDisputeMap.has("kycStakeholderInfo_identificationNumber") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {
                    fieldDisputeMap.get(
                      "kycStakeholderInfo_identificationNumber"
                    )!.message
                  }
                </p>
              </div>
            )}
          </motion.div>
        </div>
      )}

      {form && (
        <div className="mt-3">
          <div className="form-label-wrapper">
            <span>
              <FormattedMessage id="kycFormSection_personalInfoStep_verificationDocSubheader" />
            </span>
          </div>
          <div className="form-control-wrapper">
            <motion.div
              initial="hidden"
              animate="visible"
              className="mb-2"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 10 }}
            >
              <label htmlFor="proof-of-identification-upload">
                <div style={{ width: "100%" }}>
                  <span className="upload-section-label block">
                    <FormattedMessage id="kycFormSection_proofOfIdentificationLabel" />{" "}
                    <span className="required">*</span>
                  </span>
                  <p className="upload-description">
                    <FormattedMessage id="kycFormSection_proofOfIdentificationDesc" />
                  </p>

                  {!uploadedProofOfIdentificationFile &&
                    !existingProofOfIdentificationFile && (
                      <div className="upload-section">
                        <UploadIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[200]}
                        />
                        <span className="title">
                          <FormattedMessage id="cta_uploadFile" />
                        </span>
                        <p className="emp-paragraph">
                          <FormattedMessage id="kycFormSection_uploadNote" />
                        </p>
                      </div>
                    )}
                  {uploadedProofOfIdentificationFile && (
                    <div className="upload-section">
                      <FileIcon
                        size={35}
                        backgroundColor={Color.NEUTRAL[200]}
                      />
                      <span className="title">
                        {uploadedProofOfIdentificationFile.name}
                      </span>
                      <p className="emp-paragraph">
                        {FileUtils.convertBytesToReadableSize(
                          uploadedProofOfIdentificationFile.size
                        )}
                      </p>
                    </div>
                  )}
                  {!uploadedProofOfIdentificationFile &&
                    existingProofOfIdentificationFile && (
                      <div className="upload-section">
                        <FileIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[200]}
                        />
                        <span className="title">
                          {existingProofOfIdentificationFile.name}
                        </span>
                        <p className="emp-paragraph">
                          {FileUtils.convertBytesToReadableSize(
                            existingProofOfIdentificationFile.size
                          )}
                        </p>
                      </div>
                    )}
                </div>
              </label>
              {!uploadedProofOfIdentificationFile &&
                existingProofOfIdentificationFile && (
                  <EmpLink
                    onSubmit={() => {
                      KycApi.downloadKyc(
                        "proof-of-identification",
                        existingProofOfIdentificationFile.name
                      );
                    }}
                    text={"Download Attachment"}
                  />
                )}
              {form.proofOfIdentification.hasError && (
                <div className="emp-error-message-wrapper mt-3">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{form.proofOfIdentification.errorMessage}</span>
                </div>
              )}

              <input
                style={{ display: "none" }}
                accept="application/pdf, image/*"
                type="file"
                id="proof-of-identification-upload"
                onChange={(event) => {
                  handleFileUpload(event, "proof-of-identification");
                }}
                name="proof-of-identification-upload"
              ></input>

              {fieldDisputeMap.has(
                "kycStakeholderInfo_identificationDocumentName"
              ) && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get(
                        "kycStakeholderInfo_identificationDocumentName"
                      )!.message
                    }
                  </p>
                </div>
              )}
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              className="mb-2"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 11 }}
            >
              <label htmlFor="proof-of-address-upload">
                <div style={{ width: "100%" }}>
                  <span className="block upload-section-label">
                    <FormattedMessage id="kycFormSection_proofOfAddressLabel" />{" "}
                    <span className="required">*</span>
                  </span>
                  <p className="upload-description">
                    <FormattedMessage id="kycFormSection_proofOfAddressDesc" />
                  </p>
                  {!uploadedProofOfAddressFile &&
                    !existingProofOfAddressFile && (
                      <div className="upload-section">
                        <UploadIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[200]}
                        />
                        <span className="upload-section-label block">
                          <FormattedMessage id="kycFormSection_proofOfIdentificationLabel" />{" "}
                          <span className="required">*</span>
                        </span>
                        <p className="upload-description">
                          <FormattedMessage id="kycFormSection_proofOfIdentificationDesc" />
                        </p>
                      </div>
                    )}
                  {uploadedProofOfAddressFile && (
                    <div className="upload-section">
                      <FileIcon
                        size={35}
                        backgroundColor={Color.NEUTRAL[200]}
                      />
                      <span className="title">
                        {uploadedProofOfAddressFile.name}
                      </span>
                      <p className="emp-paragraph">
                        {FileUtils.convertBytesToReadableSize(
                          uploadedProofOfAddressFile.size
                        )}
                      </p>
                    </div>
                  )}
                  {!uploadedProofOfAddressFile &&
                    existingProofOfAddressFile && (
                      <div className="upload-section">
                        <FileIcon
                          size={35}
                          backgroundColor={Color.NEUTRAL[200]}
                        />
                        <span className="title">
                          {existingProofOfAddressFile.name}
                        </span>
                        <p className="emp-paragraph">
                          {FileUtils.convertBytesToReadableSize(
                            existingProofOfAddressFile.size
                          )}
                        </p>
                      </div>
                    )}
                </div>
              </label>
              {!uploadedProofOfAddressFile && existingProofOfAddressFile && (
                <EmpLink
                  onSubmit={() => {
                    KycApi.downloadKyc(
                      "proof-of-address",
                      existingProofOfAddressFile.name
                    );
                  }}
                  text={"Download Attachment"}
                />
              )}
              {form.proofOfAddress.hasError && (
                <div className="emp-error-message-wrapper mt-3">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{form.proofOfAddress.errorMessage}</span>
                </div>
              )}
              <input
                style={{ display: "none" }}
                accept="application/pdf, image/*"
                type="file"
                id="proof-of-address-upload"
                onChange={(event) => {
                  handleFileUpload(event, "proof-of-address");
                }}
                name="proof-of-address-upload"
              ></input>

              {fieldDisputeMap.has(
                "kycStakeholderInfo_proofOfAddressDocumentName"
              ) && (
                <div className="dispute-box">
                  <span className="reviewer-comment">
                    <FormattedMessage id="kycFormSection_reviewerComment" />
                  </span>
                  <p className="comment">
                    {
                      fieldDisputeMap.get(
                        "kycStakeholderInfo_proofOfAddressDocumentName"
                      )!.message
                    }
                  </p>
                </div>
              )}
            </motion.div>

            <div className="btn-wrapper">
              <EmpButton
                ref={nextBtnRef}
                isFullWidth={false}
                onSubmit={onSubmit}
                text={<FormattedMessage id="cta_nextStep" />}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
