import { motion } from "framer-motion";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccessControlApi from "../../../api/campaign-msvc/access-control.api";
import {
  CAMPAIGN_ACCESS_CONTROL,
  CAMPAIGN_RESOURCE_TYPE,
} from "../../../constants/app.constants";
import { AppContext } from "../../../context/app.context";
import EmpException from "../../../exception/empException";
import { UserDto } from "../../../model/user-management/user.dto";
import { empDelay } from "../../../utilities/delay";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../../utilities/user-utils";
import "./emp-campaign-access-control-view.scss";

interface Props {
  role: "brand" | "seller";
  backgroundColor?: "white" | "gray";
  resourceType: (typeof CAMPAIGN_RESOURCE_TYPE)[keyof typeof CAMPAIGN_RESOURCE_TYPE];
  requestSpecs?: {
    taskId: string;
    campaignId: string;
  };
  onGranted: (level: "default" | "exclusive") => void;
  onUnmount: () => void;
}
const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const DURATION = 0.2;
const DELAY_MS = 0.1;

type ViewType = "loading" | "CA_001" | "CA_002" | "CA_003";
export const EmpCampaignAccessControlView = (props: Props) => {
  const { resourceType, requestSpecs } = props;
  const backgroundColor = props.backgroundColor ?? "white";
  const location = useLocation();
  const [currentView, setCurrentView] = useState<ViewType>("loading");
  const { role, onGranted, onUnmount } = props;
  const { user: userContext } = useContext(AppContext);
  const userRef = useRef<UserDto>();
  const roleRef = useRef<string>("");

  const [wrapperAnimState, setWrapperAnimState] = useState<
    "visible" | "hidden"
  >("visible");

  const unmountCurrentView = useCallback(async () => {
    setWrapperAnimState("hidden");
    await empDelay(300);
    onUnmount();
  }, [onUnmount]);

  const verifyAccessControl = useCallback(async () => {
    try {
      if (!userRef.current) {
        const user = await UserUtils.fetchUser(userContext);
        userRef.current = user;
      }
      let sellerId = "";
      if (userRef.current.role === "talent") {
        sellerId = userRef.current.id;
      }
      // Otherwise it should be an agency
      else {
        if (
          !userRef.current.organisation ||
          userRef.current.organisation.length === 0
        )
          throw new EmpException("No agency found");
        sellerId = userRef.current.organisation[0].id;
      }
      if (role === "seller") {
        const resp = await AccessControlApi.verifyAccessForSellers({
          representativeId: sellerId,
          resourceType: resourceType,
          campaignId: requestSpecs?.campaignId ?? undefined,
          taskId: requestSpecs?.taskId ?? undefined,
        });
        if (resp.data.statusMessage === CAMPAIGN_ACCESS_CONTROL.SUCCESS) {
          onGranted("default");
          unmountCurrentView();
        } else if (
          resp.data.statusMessage ===
          CAMPAIGN_ACCESS_CONTROL.CREATOR_HAS_EXCLUSIVE
        ) {
          onGranted("exclusive");
          unmountCurrentView();
        } else if (
          resp.data.statusMessage ===
          CAMPAIGN_ACCESS_CONTROL.CREATOR_HAS_PARTNERSHIP
        ) {
          setCurrentView("CA_003");
        } else {
          setCurrentView(resp.data.statusMessage as ViewType);
        }
      }
    } catch (error) {
      EmpExceptionHandler.handleHttpRequestError(
        error,
        "Unable to verify access control"
      );
    }
  }, [
    onGranted,
    requestSpecs,
    resourceType,
    role,
    userContext,
    unmountCurrentView,
  ]);

  useEffect(() => {
    roleRef.current = location.pathname.split("/")[1];
    verifyAccessControl();
  }, [verifyAccessControl, location]);

  return (
    <motion.div
      animate={wrapperAnimState}
      initial={"visible"}
      variants={fadeInVariants}
      transition={{ duration: 0.3 }}
      className={`emp-campaign-access-control-view ${backgroundColor}`}
    >
      {currentView === "loading" && (
        <div className="loading-wrapper">
          <img
            alt="Creatorfi Logo"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creatorfi-icon.png"
          />
          <div className="emp-progress mt-2">
            <div className="indeterminate"></div>
          </div>
          <span className="loading-lbl mt-2">LOADING</span>
        </div>
      )}
      {currentView === "CA_003" &&
        resourceType === CAMPAIGN_RESOURCE_TYPE.ONGOING_TASK_CHAT && (
          <div className="content-wrapper">
            <motion.img
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 0 * DELAY_MS }}
              alt="Creator Fi Logo"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/partnership.png"
            />
            <motion.span
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 1 * DELAY_MS }}
              className="title-lbl mt-3"
            >
              Your agency will communicate directly with the brand.
            </motion.span>
            <motion.p
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 2 * DELAY_MS }}
              className="mt-3 emp-paragraph mt-2"
            >
              Please consult your agency, as they are representing you in this
              campaign, for any further instructions.
            </motion.p>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 3 * DELAY_MS }}
            ></motion.div>
          </div>
        )}

      {currentView === "CA_003" &&
        resourceType === CAMPAIGN_RESOURCE_TYPE.ONGOING_TASK_PAYMENT && (
          <div className="content-wrapper">
            <motion.img
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 0 * DELAY_MS }}
              alt="Payment Pic"
              src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/money.png"
            />
            <motion.span
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 1 * DELAY_MS }}
              className="title-lbl mt-3"
            >
              Your agency will pay out the completed amount to you.
            </motion.span>
            <motion.p
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 2 * DELAY_MS }}
              className="mt-3 emp-paragraph mt-2"
            >
              To be eligible for the payouts. You're required to complete all
              the tasks.
            </motion.p>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: 3 * DELAY_MS }}
            ></motion.div>
          </div>
        )}
      <div></div>
    </motion.div>
  );
};
