import { request } from "http";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import revealedRateCardImg from "../../assets/images/revealed-rate-card.png";
import EmpException from "../../exception/empException";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpPill from "../shared/EmpPill/EmpPill";
import "./rate-privacy-request-modal.scss";

export interface RateCardRequestModalRef {
  show: (agencyOrgId: string, creatorId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

type ModalParam = {
  agencyOrgId: string;
  creatorId: string;
};

const RateCardRequestModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);

  const modalParamRef = useRef<ModalParam>();
  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const requestRateCardRequest = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!modalParamRef.current) return;

      const resp = await RateCardApi.createRateCardRequest({
        agencyOrgId: modalParamRef.current.agencyOrgId,
        creatorId: modalParamRef.current.creatorId,
      });

      if (resp.data.status === "success") {
        ToastUtils.success("Saved", "Request Sent!");
        props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch rate card privacy settings"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  const show = async (agencyOrgId: string, creatorId: string) => {
    modalParamRef.current = { agencyOrgId, creatorId };
    setVisible(true);
  };

  const dismiss = () => {
    modalParamRef.current = undefined;
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-rate-privacy-request-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">Request for 30-Day Visibility of Rate Card</h2>
          <p className="description">
            You need to request permission from the agency to view their rate
            cards. They will inform you about their decision later. However, you
            can still proceed with the invitation even if you haven't heard back
            from them.
          </p>
        </div>
        <div className="demo-wrapper">
          <div className={`slide`}>
            <div className="absolute-wrapper">
              <EmpPill
                text={"Example"}
                backgroundColor={Color.PRIMARY[100]}
                color={Color.PRIMARY[600]}
              />
            </div>
            <img src={revealedRateCardImg} alt="Revealed Rate Card Mockup" />
          </div>
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"primary"}
            text={"Submit Request"}
            ref={saveBtnRef}
            onSubmit={() => {
              requestRateCardRequest();
            }}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default RateCardRequestModal;
