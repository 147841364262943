import { forwardRef, useState, useRef, useImperativeHandle } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Color } from "../../../../utilities/colors";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../../utilities/formUtils/lengthValidator";
import { PatternValidator } from "../../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../../utilities/formUtils/requiredValidator";
import LinkIcon from "../../../icon/link-icon";
import XCloseIcon from "../../../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../../emp-button/emp-button";
import EmpModal from "../../emp-modal/emp-modal";
import EmpTextInput from "../../emp-text-input/emp-text-input";
import "./insert-link-modal.scss";

interface ModalProps {
  onClose: (url: string) => void;
}
export interface InsertLinkModalRef {
  show: () => void;
  dismiss: () => void;
}
const InsertLinkModal = forwardRef((props: ModalProps, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const actionBtnRef = useRef<EmpButtonRef>(null);
  const submittedRef = useRef(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const [form, setForm] = useState<IFormGroup>({
    link: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_websiteRequired" })
      ),
      new PatternValidator(
        /^(?:https?:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.+~#?&//=]*)$/,
        intl.formatMessage({ id: "validation_websiteInvalid" })
      ),
      new LengthValidator(
        0,
        100,
        undefined,
        intl.formatMessage({ id: "validation_websiteMaxLength" }, { char: 80 })
      ),
    ]),
  });

  const show = async () => {
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    FormGroupUtil.reset(form);
  };

  const insertOnClick = async () => {
    submittedRef.current = true;
    try {
      if (FormGroupUtil.validate(form) === false) {
        setForm({ ...form });
        return;
      }
      props.onClose(form.link.getValue());
      dismiss();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, `Unable to insert link`);
    }
  };

  const formOnChange = (formControl: FormControl) => {
    if (!submittedRef.current) return;
    const hasDiff = FormGroupUtil.validateTrackDiff(form);
    if (hasDiff) {
      setForm({ ...form });
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={`Insert Link`}
      showHeader={false}
      showHeaderBorder={false}
      showFooter={false}
      showFooterBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-insert-link-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <div className="title-wrapper mb-4">
            <h2 className="title">Please enter the link you wish to insert</h2>
          </div>
          <EmpTextInput
            labelText={"Website link"}
            placeholder="e.g https://example.com"
            id={"link"}
            onKeypress={(key: string) => {
              if (key === "Enter") {
                insertOnClick();
              }
            }}
            onChange={formOnChange}
            required
            formControl={form.link}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 16,
          }}
        >
          <div style={{ width: "fit-content" }}>
            <EmpButton
              ref={actionBtnRef}
              onSubmit={() => {
                insertOnClick();
              }}
              text={"Insert Link"}
            />
          </div>
          <div style={{ width: "fit-content", marginRight: 8 }}>
            <EmpButton
              text={<FormattedMessage id="cta_cancel" />}
              buttonStyle="secondary"
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default InsertLinkModal;
