import React, { useEffect, useRef, useState } from "react";
import { FormControl } from "../../../utilities/formUtils/formControl";
import _debounce from "lodash/debounce";
import { v4 } from "uuid";
import "./emp-switch.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onChange?: (formControl: FormControl) => void;
  id: string;
  formControl: FormControl;
  disabled?: boolean;
}
const EmpSwitch = (props: Props) => {
  const { formControl, style, onChange } = props;
  const disabled = props.disabled ?? false;
  const className = props.className ?? "";
  const id = props.id ?? v4();
  const checkboxElemRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    checkboxElemRef.current!.checked = formControl.getValue();
  }, [formControl.resetFlag, formControl.value]);

  const handleOnChange = (value: boolean) => {
    formControl.setValue(value);
    if (onChange) onChange(formControl);
  };

  return (
    <div className={`emp-switch-wrapper ${className}`} style={style}>
      <label className="emp-switch">
        <input
          id={id}
          type="checkbox"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChange(event.target.checked);
          }}
          disabled={disabled}
          ref={checkboxElemRef}
        />
        <span className="emp-slider round"></span>
      </label>
    </div>
  );
};
export default EmpSwitch;
