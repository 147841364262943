import { useCallback } from "react";
import EmpException from "../exception/empException";
import CryptoApi from "../api/campaign-msvc/crypto.api";
import { SelectOption } from "../model/common/selectOption";
import { ApiStatus } from "../model/api/api-status";

const useCrypto = () => {
  const getChainOptions = useCallback(
    async (taskId: string): Promise<SelectOption[]> => {
      try {
        const resp = await CryptoApi.getChainOptions(taskId);
        return resp.data;
      } catch (e) {
        throw new EmpException("Unable to map user onboarding");
      }
    },
    []
  );

  const updateChainOption = useCallback(
    async (taskId: string, chainValue: string): Promise<ApiStatus> => {
      try {
        const resp = await CryptoApi.updateChainOptions(taskId, {
          chain: chainValue,
        });
        return resp.data;
      } catch (e) {
        throw new EmpException("Unable to map user onboarding");
      }
    },
    []
  );

  return { getChainOptions, updateChainOption };
};
export default useCrypto;
