import { useCallback, useEffect, useRef, useState } from "react";
import { UserDto } from "../../../model/user-management/user.dto";
import "./creator-account-view.scss";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EditModal, { EditModalRef } from "../../../components/modals/edit-modal";
import ChangePasswordModal, {
  ChangePasswordModalRef,
} from "../../../components/modals/change-password-modal";
import ChangeEmailModal, {
  ChangeEmailModalRef,
} from "../../../components/modals/change-email-modal";
import EmpException from "../../../exception/empException";
import ToastUtils from "../../../utilities/toast-utils";
import UserUtils from "../../../utilities/user-utils";
import AlertSquareIcon from "../../../components/icon/alert-square";
import { Color } from "../../../utilities/colors";
import FileUtils from "../../../utilities/file-util";
import {
  EmpCropper,
  EmpCropperRef,
} from "../../../components/shared/emp-cropper/emp-cropper";
import { UserImgReqDto } from "../../../model/user-management/user-img-req.dto";
import { USER_ROLES } from "../../../constants/app.constants";
import UserApi from "../../../api/user-msvc/user.api";
import { FormattedMessage, useIntl } from "react-intl";
import useUser from "../../../hooks/useUser";
import { Tooltip, Whisper } from "rsuite";
import GoogleColoredIcon from "../../../components/icon/google-colored-icon";

export const CreatorAccountView = () => {
  const intl = useIntl();
  const editModalRef = useRef<EditModalRef>();
  const changePasswordModal = useRef<ChangePasswordModalRef>();
  const changeEmailModal = useRef<ChangeEmailModalRef>();
  const [imageErrorMessage, setImageErrorMessage] = useState<string>();
  const cropperRef = useRef<EmpCropperRef>();

  const { user, accountOrigin, refreshUser } = useUser();

  const onEdit = async (
    context: "email" | "fullName",
    updatedValue: string
  ) => {
    try {
      if (!user) throw new EmpException("User object not available");
      user[context] = updatedValue;
      await UserApi.updateUserAttribute(user);

      if (context === "email") {
        changeEmailModal.current!.updateComplete();
      } else if (context === "fullName") {
        ToastUtils.success(`Saved`, "Details Updated Successfully");
        editModalRef.current?.dismiss();
      }
      UserUtils.updateUser();
      await refreshUser();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to update user ${context} attribute`
      );
    }
  };

  const updateImage = async (base64: string) => {
    try {
      const request: UserImgReqDto = {
        image: base64,
      };
      await UserApi.updateUserImage(request);
      ToastUtils.success(`Saved`, "Updated Profile Photo");
      UserUtils.updateUser();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to update profile image`
      );
    }
  };

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;
    const isImage = file.type.startsWith("image/");
    const isSmallEnough = FileUtils.isFileSizeSmallerThanMB(file, 3);

    if (!isImage) {
      setImageErrorMessage("Please upload an image file.");
      return;
    }
    if (!isSmallEnough) {
      setImageErrorMessage("File size must be less than or equal to 3MB.");
      return;
    }
    setImageErrorMessage(undefined);
    // Do something with the file, such as upload it
    const base64 = await FileUtils.readFileAsBase64(file);
    // Do something with the base64 string, such as display it as an image
    if (cropperRef.current === undefined) return;
    cropperRef.current.open(base64);
  };

  const roleToNameTranslator = (role: string) => {
    if (role === USER_ROLES.AGENCY)
      return intl.formatMessage({ id: "settingsAccountView_orgNameLabel" });
    else return intl.formatMessage({ id: "settingsAccountView_nameLabel" });
  };

  return (
    <div className="emp-creator-account-view">
      <EmpCropper
        onCrop={(base64: string) => {
          updateImage(base64);
        }}
        ref={cropperRef}
      />
      <EditModal
        ref={editModalRef}
        onSave={(context, updatedValue) =>
          onEdit(context as "fullName", updatedValue)
        }
      />
      <ChangeEmailModal
        ref={changeEmailModal}
        onSave={(updatedEmail: string) => {
          onEdit("email", updatedEmail);
        }}
      />
      <ChangePasswordModal ref={changePasswordModal} onSave={() => {}} />
      <div className="view-header-wrapper">
        <div>
          <h1>
            <FormattedMessage id="settingsAccountView_header" />
          </h1>
          <p className="page-description">
            <FormattedMessage id="settingsAccountView_desc" />
          </p>
        </div>
      </div>
      {user && (
        <div className="card-wrapper">
          <div className="image-card">
            {user.imageType === "url" && (
              <img alt="Profile" src={user.imageResource} />
            )}
            {user.imageType === "avatar" && (
              <div
                style={{ background: user.imageResource }}
                className="avatar"
              >
                <span className="initials">{user.initials}</span>
              </div>
            )}
            <div className="mt-3">
              <label className="logo-upload" htmlFor={"logo-upload"}>
                <FormattedMessage id="settingsAccountView_changeProfileLink" />
              </label>
              {imageErrorMessage && (
                <div className="emp-error-message-wrapper">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{imageErrorMessage}</span>
                </div>
              )}
              <input
                className="upload-hidden"
                accept="image/*"
                type="file"
                id="logo-upload"
                onChange={handleFileUpload}
                name="myfile"
              ></input>
            </div>
          </div>
          <div className="account-card-wrapper">
            <div className="account-card">
              <div className="info-wrapper">
                <EmpContent
                  label={
                    <FormattedMessage id="settingsAccountView_emailAddressLabel" />
                  }
                  value={
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 6 }}
                    >
                      {accountOrigin === "google" && <GoogleColoredIcon size={16}/>}
                      <span>{user.email}</span>
                    </div>
                  }
                />
              </div>
              {/* Enabled Button */}
              {accountOrigin === "emplifive" && (
                <EmpButton
                  onSubmit={() => {
                    changeEmailModal.current?.show(user.email);
                  }}
                  text={<FormattedMessage id="cta_edit" />}
                  buttonStyle="secondary"
                  isFullWidth={false}
                />
              )}
              {/* Disabled button */}
              {accountOrigin !== "emplifive" && (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Since your account was created through Google, you cannot
                      change your email address.
                    </Tooltip>
                  }
                >
                  <div>
                    <EmpButton
                      text={<FormattedMessage id="cta_edit" />}
                      buttonStyle="secondary"
                      isFullWidth={false}
                      disabled
                    />
                  </div>
                </Whisper>
              )}
            </div>

            <div className="account-card">
              <div className="info-wrapper">
                <EmpContent
                  label={
                    <FormattedMessage id="settingsAccountView_passwordLabel" />
                  }
                  value={"************"}
                />
              </div>
              <EmpButton
                onSubmit={() => {
                  changePasswordModal.current?.show();
                }}
                text={<FormattedMessage id="cta_edit" />}
                buttonStyle="secondary"
                isFullWidth={false}
              />
            </div>

            <div className="account-card">
              <div className="info-wrapper">
                <EmpContent
                  label={roleToNameTranslator(user.role).toUpperCase()}
                  value={user.fullName}
                />
              </div>
              <EmpButton
                onSubmit={() => {
                  editModalRef.current?.show(
                    roleToNameTranslator(user.role),
                    "fullName",
                    user.fullName
                  );
                }}
                text={<FormattedMessage id="cta_edit" />}
                buttonStyle="secondary"
                isFullWidth={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
