import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const CommentIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
      }}
    >
      <svg
        width={size}
        height={size - 1}
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7366 10.0726L11.545 10.2509L11.5822 10.5099L11.8672 12.492L9.79625 11.4832L9.60884 11.3919L9.41208 11.4608C8.66794 11.7214 7.85384 11.8659 7 11.8659C3.34047 11.8659 0.5 9.24935 0.5 6.18294C0.5 3.11653 3.34047 0.5 7 0.5C10.6595 0.5 13.5 3.11653 13.5 6.18294C13.5 7.67478 12.8406 9.04552 11.7366 10.0726Z"
          stroke={backgroundColor}
        />
      </svg>
    </div>
  );
};
export default CommentIcon;
