import React, { useRef, useEffect, useState } from "react";
import "./emp-animated-loader.scss";

const FRAME_COUNT = 80;
const FRAME_RATE = 40;
interface Props {
  isVisible: boolean;
}
const EmpAnimatedLoader = (props: Props) => {
  const [isVisible, setVisible] = useState(props.isVisible);

  useEffect(() => {
    setVisible(props.isVisible);
  }, [props.isVisible]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  let currentFrame = 0;
  const frames = useRef<HTMLImageElement[]>([]);

  useEffect(() => {
    // Preload images and draw on canvas
    const context = canvasRef.current!.getContext("2d")!;
    frames.current = Array.from({ length: FRAME_COUNT }, (_, i) => {
      const img = new Image();
      img.src = `https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/sequences/emp-loader_${i.toString().padStart(3, "0")}.png`;
      img.onload = () => {
        // Assuming you want to keep the aspect ratio of the image
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        const height = 100; // Desired height
        const width = height * aspectRatio; // Calculate width based on aspect ratio
        context.drawImage(img, 0, 0, width, height);
      };
      return img;
    });

    const interval = setInterval(() => {
      currentFrame = (currentFrame + 1) % FRAME_COUNT;
      context.clearRect(
        0,
        0,
        canvasRef.current!.width,
        canvasRef.current!.height
      );
      const imageSource = frames.current[currentFrame];
      // Draw with specified height, and calculate width to maintain aspect ratio
      const aspectRatio = imageSource.naturalWidth / imageSource.naturalHeight;
      const height = 100; // Desired height
      const width = height * aspectRatio; // Calculate width based on aspect ratio
      context.drawImage(imageSource, 0, 0, width, height);
    }, 1000 / FRAME_RATE);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`emp-animated-loader ${isVisible ? "visible" : "hidden"}`}>
      <div className="canvas-wrapper">
        <canvas ref={canvasRef} height="100px" width="80px"></canvas>
        <h2>Loading...</h2>
      </div>
    </div>
  );
};

export default EmpAnimatedLoader;
