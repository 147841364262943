import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import StringUtils from "../../../utilities/string.util";
import _debounce from "lodash/debounce";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import ChevronUpIcon from "../../../components/icon/chevron-up";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import CreateTaskModal, {
  CreateTaskModalRef,
} from "../../../components/modals/create-task-modal";
import { LatestNegotiationSingleDto } from "../../../model/campaign/latest-negotiation-single.dto";
import { BrandTaskStatusCardUtils } from "../../../utilities/viewUtils/brand-task-status-card-utils";
import UserUtils from "../../../utilities/user-utils";
import { AppContext } from "../../../context/app.context";
import { UserDto } from "../../../model/user-management/user.dto";
import "../../../utilities/viewUtils/brand-task-status-card.scss";
import "./brand-recruiting-task-summary-view.scss";
import TaskResponseModal, {
  TaskResponseModalRef,
} from "../../../components/modals/task-response-modal";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import { NegotiationLogDto } from "../../../model/campaign/negotiation-log.dto";
import { NegotiationLogTable } from "../../../components/table/negotiation-log-table";
import StartTaskModal, {
  StartTaskModalRef,
} from "../../../components/modals/start-task-modal";
import {
  EMPTY_PROFILE_IMG_URL,
  NEGOTIATION_STATUS,
} from "../../../constants/app.constants";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import ChevronRightIcon from "../../../components/icon/chevron-right";
import { useNavigate } from "react-router-dom";
import DeleteTaskModal, {
  DeleteTaskModalRef,
} from "../../../components/modals/delete-task-modal";
import ReviewDeliverableChangelogModal, {
  ReviewDeliverableChangelogModalRef,
} from "../../../components/modals/review-deliverable-changelog-modal";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task?: TaskDto;
  fetchTask: () => void;
  fetchLatestNegotiation: () => void;
  brandLatestNegotiation: LatestNegotiationSingleDto;
}

interface NegotiationBreakdown {
  acceptedNegotiations: LatestNegotiationExtendedDto[];
  invitedNegotiations: LatestNegotiationExtendedDto[];
  appliedNegotiations: LatestNegotiationExtendedDto[];
  counterofferNegotiations: LatestNegotiationExtendedDto[];
  reviewDeliverableNegotiations: LatestNegotiationExtendedDto[];
}

export const BrandRecruitingTaskSummaryView = (props: Props) => {
  const navigate = useNavigate();
  const { task: taskProp, fetchLatestNegotiation } = props;
  const { user: userContext } = useContext(AppContext);
  const [user, setUser] = useState<UserDto>();
  const [negotationLogs, setNegotationLogs] = useState<NegotiationLogDto[]>([]);
  const [task, setTask] = useState<TaskDto>();
  const [brandLatestNegotiation, setBrandLatestNegotiation] =
    useState<LatestNegotiationSingleDto>(props.brandLatestNegotiation);

  const [deliverableIsExpanded, setDeliverableIsExpanded] = useState(true);
  const resizeDivRef = useRef<HTMLDivElement>(null);

  // Wrapper element to compute expandable size
  const deliverableInfoRef = useRef<HTMLDivElement>(null);

  const deleteTaskModalRef = useRef<DeleteTaskModalRef>();
  const createTaskModalRef = useRef<CreateTaskModalRef>();
  const taskResponseModalRef = useRef<TaskResponseModalRef>();
  const reviewDeliverableChangelogModalRef =
    useRef<ReviewDeliverableChangelogModalRef>();
  const startTaskModalRef = useRef<StartTaskModalRef>();
  const [brandLatestNegotiationBreakdown, setBrandLatestNegotiationBreakdown] =
    useState<NegotiationBreakdown>();
  useEffect(() => {
    // Breakdown the brand's latest negotiation
    if (props.brandLatestNegotiation.hasNegotiation) {
      const acceptedNegotiations = [];
      const invitedNegotiations = [];
      const appliedNegotiations = [];
      const counterofferNegotiations = [];
      const reviewDeliverableNegotiations = [];
      for (const elem of props.brandLatestNegotiation.latestNegotiation) {
        switch (elem.status) {
          case NEGOTIATION_STATUS.BRAND_ACCEPT:
          case NEGOTIATION_STATUS.SELLER_ACCEPT:
            acceptedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.INVITED:
            invitedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.APPLIED:
            appliedNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.SELLER_NEGOTIATE:
            counterofferNegotiations.push(elem);
            break;
          case NEGOTIATION_STATUS.REVIEW_DELIVERABLES:
            reviewDeliverableNegotiations.push(elem);
            break;
          default:
            break;
        }
      }
      setBrandLatestNegotiationBreakdown({
        acceptedNegotiations,
        invitedNegotiations,
        appliedNegotiations,
        counterofferNegotiations,
        reviewDeliverableNegotiations,
      });
    } else {
      setBrandLatestNegotiationBreakdown({
        acceptedNegotiations: [],
        invitedNegotiations: [],
        appliedNegotiations: [],
        counterofferNegotiations: [],
        reviewDeliverableNegotiations: [],
      });
    }

    setBrandLatestNegotiation(props.brandLatestNegotiation);
  }, [props.brandLatestNegotiation]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = await UserUtils.fetchUser(userContext);
      setUser(user);
    };
    fetchUserDetails();
  }, [userContext]);

  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
      getBrandNegotiationLogs(taskProp);
    }
  }, [taskProp]);

  const GUTTER_WIDTH = 20;
  const MIN_CARD_WIDTH = 180;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [deliverableInfoHeight, setDeliverableInfoHeight] = useState<number>(0);

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cards = Math.min(maxCards, 5);
    const cardWidth = Math.floor(
      (rowWidth - (cards - 1) * GUTTER_WIDTH) / cards
    );
    setCardWidth(cardWidth);
  };

  function calculateTotalOuterHeight(divRef: HTMLDivElement) {
    let totalHeight = 0;

    // Helper function to include margin
    function getOuterHeight(el: HTMLElement) {
      let style = window.getComputedStyle(el);
      return (
        el.offsetHeight +
        parseInt(style.marginTop) +
        parseInt(style.marginBottom)
      );
    }

    // Loop through the immediate children of divRef
    Array.from(divRef.children).forEach((child) => {
      const c = child as HTMLElement;
      totalHeight += getOuterHeight(c);
    });

    return totalHeight;
  }

  const updateDeliverableSectionHeight = () => {
    const height = calculateTotalOuterHeight(deliverableInfoRef.current!);
    setDeliverableInfoHeight(height);
  };

  // eslint-disable-next-line
  const debounceFn2: () => void = useCallback(
    _debounce(updateDeliverableSectionHeight, 300, { leading: true }),
    []
  );
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
      debounceFn2();
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn, debounceFn2, task]);

  const renderCountries = (countries: string[]): JSX.Element => {
    const maxDisplayCountries = 3;
    const extra =
      countries.length > maxDisplayCountries
        ? countries.length - maxDisplayCountries
        : undefined;
    const renderableCountries = countries.slice(0, maxDisplayCountries);
    return (
      <div className="country-wrapper">
        {renderableCountries.map((elem) => {
          return (
            <img
              key={COUNTRY_CONSTANTS[elem].name}
              className="country"
              alt={COUNTRY_CONSTANTS[elem].name}
              src={COUNTRY_CONSTANTS[elem].flag_1x1}
            />
          );
        })}
        {extra && <div className="extra">{extra}</div>}
      </div>
    );
  };
  const renderHiredCreators = (
    latestNegotiation: LatestNegotiationSingleDto
  ): JSX.Element => {
    const maxDisplayCreators = 3;
    if (!latestNegotiation.hasNegotiation)
      return <>No confirmed creators yet...</>;
    const hiredCreators = latestNegotiation.latestNegotiation.filter((elem) =>
      [
        NEGOTIATION_STATUS.BRAND_ACCEPT,
        NEGOTIATION_STATUS.SELLER_ACCEPT,
      ].includes(elem.status)
    );
    const extra =
      hiredCreators.length > maxDisplayCreators
        ? hiredCreators.length - maxDisplayCreators
        : undefined;

    if (hiredCreators.length > 0) {
      return (
        <div className="country-wrapper">
          {hiredCreators.map((elem) => {
            return (
              <div>
                {elem.creatorRepresentative.imageType === "url" && (
                  <img
                    alt="Profile"
                    className={`profile-photo`}
                    src={elem.creatorRepresentative.imageResource}
                  />
                )}
                {elem.creatorRepresentative.imageType === "none" && (
                  <img
                    alt="Empty profile"
                    className={`profile-photo`}
                    src={
                      "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                    }
                  />
                )}
                {elem.creatorRepresentative.imageType === "avatar" && (
                  <div
                    style={{
                      background: elem.creatorRepresentative.imageResource,
                    }}
                    className={`avatar`}
                  >
                    <span className="initials">
                      {elem.creatorRepresentative.initials}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
          {extra && <div className="extra">{extra}</div>}
        </div>
      );
    } else {
      return <div>No Creators</div>;
    }
  };

  const renderMetricCreators = (
    acceptedNegotiations: LatestNegotiationExtendedDto[],
    showAmount?: boolean
  ): JSX.Element => {
    const canShowAmount = showAmount ?? false;
    const maxDisplayCreators = 1;
    if (acceptedNegotiations.length === 0)
      return <span className="metric-description">No Creators</span>;
    const creators = acceptedNegotiations.map(
      (elem) => elem.creatorRepresentative
    );
    const extra =
      creators.length > maxDisplayCreators
        ? creators.length - maxDisplayCreators
        : undefined;

    const totalAmount = acceptedNegotiations
      .reduce((prev, currentValue) => prev + Number(currentValue.amount), 0)
      .toFixed(6);

    if (creators.length > 0) {
      return (
        <div className="creator-wrapper mt-2">
          {creators.map((elem, index) => {
            if (index < maxDisplayCreators)
              return (
                <Fragment key={elem.id}>
                  {elem.imageType === "url" && (
                    <img
                      alt="Profile"
                      className={`profile-photo`}
                      src={elem.imageResource}
                    />
                  )}
                  {elem.imageType === "none" && (
                    <img
                      alt="Empty profile"
                      className={`profile-photo`}
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {elem.imageType === "avatar" && (
                    <div
                      style={{ background: elem.imageResource }}
                      className={`avatar`}
                    >
                      <span className="initials">{elem.initials}</span>
                    </div>
                  )}
                </Fragment>
              );
            else return <></>;
          })}
          {extra && <div className="extra">+{extra}</div>}
          {canShowAmount && (
            <>
              <div className="pipe"></div>
              <span className="amount">
                ${FormFieldUtils.toMoney(Number(totalAmount))}
              </span>
            </>
          )}
        </div>
      );
    } else {
      return <span className="metric-description">No Creators</span>;
    }
  };

  const getBrandNegotiationLogs = useCallback(async (task: TaskDto) => {
    try {
      const resp = await NegotiationApi.getBrandNegotiationLogs(
        task.campaign.id,
        task.id
      );
      setNegotationLogs(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error fetching negotiation logs"
      );
    }
  }, []);

  return (
    <div className="emp-brand-recruiting-task-summary-view">
      <DeleteTaskModal ref={deleteTaskModalRef} onSave={() => {}} />
      <TaskResponseModal
        onSave={() => {
          fetchLatestNegotiation();
        }}
        ref={taskResponseModalRef}
      />
      <StartTaskModal
        ref={startTaskModalRef}
        onStart={() => {
          props.fetchTask();
        }}
      />

      {brandLatestNegotiationBreakdown?.reviewDeliverableNegotiations && (
        <ReviewDeliverableChangelogModal
          onSave={fetchLatestNegotiation}
          ref={reviewDeliverableChangelogModalRef}
          latestNegotiations={
            brandLatestNegotiationBreakdown.reviewDeliverableNegotiations
          }
        />
      )}

      {/* Task Summary Metrics Wrapper */}
      {task && user && brandLatestNegotiationBreakdown && (
        <section className="metrics-section mb-6">
          {/* Invite New Creators */}
          <button
            className="emp-button-reset metric-card clickable"
            onClick={() => {
              navigate(
                `/brand/task-recruitment/${task.campaign.id}/task/${task.id}`
              );
            }}
          >
            <div className="info-wrapper">
              <img
                className="metric-img"
                alt="invite-creator"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creator.png"
              />
              <div>
                <span className="metric-title">Invite Creators</span>
                {brandLatestNegotiationBreakdown.invitedNegotiations.length ===
                  0 && (
                  <span className="metric-description">
                    Invite candidates to join your task
                  </span>
                )}
                {brandLatestNegotiationBreakdown.invitedNegotiations.length >
                  0 &&
                  renderMetricCreators(
                    brandLatestNegotiationBreakdown.invitedNegotiations
                  )}
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </button>
          {/* Applications */}
          <button
            className={`emp-button-reset metric-card ${
              brandLatestNegotiationBreakdown.appliedNegotiations.length > 0
                ? "clickable"
                : ""
            }`}
            onClick={() => {
              if (
                brandLatestNegotiationBreakdown.appliedNegotiations.length === 0
              )
                return;
              taskResponseModalRef.current?.show(
                task,
                brandLatestNegotiationBreakdown.appliedNegotiations,
                "application",
                "brand"
              );
            }}
          >
            <div className="info-wrapper">
              <div className="creator-image-wrapper">
                {brandLatestNegotiationBreakdown.appliedNegotiations.length ===
                  0 && (
                  <img
                    className="creator-image"
                    alt="Applied Creator"
                    src={EMPTY_PROFILE_IMG_URL}
                  />
                )}
                {brandLatestNegotiationBreakdown.appliedNegotiations.length >
                  0 && (
                  <>
                    {brandLatestNegotiationBreakdown.appliedNegotiations[0]
                      .creatorRepresentative.imageType === "url" && (
                      <img
                        alt="Profile"
                        className={`creator-image`}
                        src={
                          brandLatestNegotiationBreakdown.appliedNegotiations[0]
                            .creatorRepresentative.imageResource
                        }
                      />
                    )}
                    {brandLatestNegotiationBreakdown.appliedNegotiations[0]
                      .creatorRepresentative.imageType === "none" && (
                      <img
                        alt="Empty profile"
                        className={`creator-image`}
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {brandLatestNegotiationBreakdown.appliedNegotiations[0]
                      .creatorRepresentative.imageType === "avatar" && (
                      <div
                        style={{
                          background:
                            brandLatestNegotiationBreakdown
                              .appliedNegotiations[0].creatorRepresentative
                              .imageResource,
                        }}
                        className={`creator-image`}
                      >
                        <span className="initials">
                          {
                            brandLatestNegotiationBreakdown
                              .appliedNegotiations[0].creatorRepresentative
                              .initials
                          }
                        </span>
                      </div>
                    )}
                  </>
                )}
                {brandLatestNegotiationBreakdown.appliedNegotiations.length >
                  1 && (
                  <div className="badge">
                    <span className="value">
                      +
                      {brandLatestNegotiationBreakdown.appliedNegotiations
                        .length - 1}
                    </span>
                  </div>
                )}
              </div>
              <div>
                {brandLatestNegotiationBreakdown.appliedNegotiations.length ===
                  0 && <span className="metric-title">No Aplications</span>}
                {brandLatestNegotiationBreakdown.appliedNegotiations.length >
                  0 && (
                  <span className="metric-title">
                    {brandLatestNegotiationBreakdown.appliedNegotiations.length}{" "}
                    Application
                    {brandLatestNegotiationBreakdown.appliedNegotiations
                      .length > 1
                      ? "s"
                      : ""}
                  </span>
                )}
                {brandLatestNegotiationBreakdown.appliedNegotiations.length ===
                  0 && (
                  <span className="metric-description">
                    No applications yet
                  </span>
                )}
                {brandLatestNegotiationBreakdown.appliedNegotiations.length >
                  0 && (
                  <span className="metric-description">
                    Respond to Creator or Agency Application
                  </span>
                )}
              </div>
            </div>
            {brandLatestNegotiationBreakdown.appliedNegotiations.length > 0 && (
              <div className="chevron-wrapper">
                <ChevronRightIcon
                  size={18}
                  strokeWidth={2.5}
                  backgroundColor={Color.NEUTRAL[400]}
                />
              </div>
            )}
          </button>
          {/* Counteroffers */}
          <button
            className={`emp-button-reset metric-card ${
              brandLatestNegotiationBreakdown.counterofferNegotiations.length >
              0
                ? "clickable"
                : ""
            }`}
            onClick={() => {
              if (
                brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length === 0
              )
                return;
              taskResponseModalRef.current?.show(
                task,
                brandLatestNegotiationBreakdown.counterofferNegotiations,
                "counteroffer",
                "brand"
              );
            }}
          >
            <div className="info-wrapper">
              <div className="creator-image-wrapper">
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length === 0 && (
                  <img
                    className="creator-image"
                    alt="Applied Creator"
                    src={EMPTY_PROFILE_IMG_URL}
                  />
                )}
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length > 0 && (
                  <>
                    {brandLatestNegotiationBreakdown.counterofferNegotiations[0]
                      .creatorRepresentative.imageType === "url" && (
                      <img
                        alt="Profile"
                        className={`creator-image`}
                        src={
                          brandLatestNegotiationBreakdown
                            .counterofferNegotiations[0].creatorRepresentative
                            .imageResource
                        }
                      />
                    )}
                    {brandLatestNegotiationBreakdown.counterofferNegotiations[0]
                      .creatorRepresentative.imageType === "none" && (
                      <img
                        alt="Empty profile"
                        className={`creator-image`}
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {brandLatestNegotiationBreakdown.counterofferNegotiations[0]
                      .creatorRepresentative.imageType === "avatar" && (
                      <div
                        style={{
                          background:
                            brandLatestNegotiationBreakdown
                              .counterofferNegotiations[0].creatorRepresentative
                              .imageResource,
                        }}
                        className={`creator-image`}
                      >
                        <span className="initials">
                          {
                            brandLatestNegotiationBreakdown
                              .counterofferNegotiations[0].creatorRepresentative
                              .initials
                          }
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length === 0 && (
                  <span className="metric-title">No Counteroffers</span>
                )}
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length > 0 && (
                  <span className="metric-title">
                    {
                      brandLatestNegotiationBreakdown.counterofferNegotiations
                        .length
                    }{" "}
                    Counteroffer
                    {brandLatestNegotiationBreakdown.appliedNegotiations
                      .length > 1
                      ? "s"
                      : ""}
                  </span>
                )}
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length === 0 && (
                  <span className="metric-description">
                    No Counteroffers received
                  </span>
                )}
                {brandLatestNegotiationBreakdown.counterofferNegotiations
                  .length > 0 && (
                  <span className="metric-description">
                    Respond to Agencies' or Creators' Counteroffers
                  </span>
                )}
              </div>
            </div>
            {brandLatestNegotiationBreakdown.counterofferNegotiations.length >
              0 && (
              <div className="chevron-wrapper">
                <ChevronRightIcon
                  size={18}
                  strokeWidth={2.5}
                  backgroundColor={Color.NEUTRAL[400]}
                />
              </div>
            )}
          </button>
          {/* Review Deliverables */}
          {brandLatestNegotiationBreakdown.reviewDeliverableNegotiations
            .length > 0 && (
            <button
              className={`emp-button-reset metric-card clickable`}
              onClick={() => {
                console.log("showing modal");
                reviewDeliverableChangelogModalRef.current?.show(task, "brand");
              }}
            >
              <div className="info-wrapper">
                <div className="creator-image-wrapper">
                  {brandLatestNegotiationBreakdown
                    .reviewDeliverableNegotiations[0].creatorRepresentative
                    .imageType === "url" && (
                    <img
                      alt="Profile"
                      className={`creator-image`}
                      src={
                        brandLatestNegotiationBreakdown
                          .reviewDeliverableNegotiations[0]
                          .creatorRepresentative.imageResource
                      }
                    />
                  )}
                  {brandLatestNegotiationBreakdown
                    .reviewDeliverableNegotiations[0].creatorRepresentative
                    .imageType === "none" && (
                    <img
                      alt="Empty profile"
                      className={`creator-image`}
                      src={
                        "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                      }
                    />
                  )}
                  {brandLatestNegotiationBreakdown
                    .reviewDeliverableNegotiations[0].creatorRepresentative
                    .imageType === "avatar" && (
                    <div
                      style={{
                        background:
                          brandLatestNegotiationBreakdown
                            .reviewDeliverableNegotiations[0]
                            .creatorRepresentative.imageResource,
                      }}
                      className={`creator-image`}
                    >
                      <span className="initials">
                        {
                          brandLatestNegotiationBreakdown
                            .reviewDeliverableNegotiations[0]
                            .creatorRepresentative.initials
                        }
                      </span>
                    </div>
                  )}
                  {brandLatestNegotiationBreakdown.reviewDeliverableNegotiations
                    .length > 1 && (
                    <div className="badge">
                      <span className="value">
                        +
                        {brandLatestNegotiationBreakdown
                          .reviewDeliverableNegotiations.length - 1}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <span className="metric-title">
                    Review Deliverable
                    {brandLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations.length > 1
                      ? "s"
                      : ""}
                  </span>
                  <span className="metric-description">
                    {
                      brandLatestNegotiationBreakdown
                        .reviewDeliverableNegotiations.length
                    }{" "}
                    creator
                    {brandLatestNegotiationBreakdown
                      .reviewDeliverableNegotiations.length > 1
                      ? "s"
                      : ""}{" "}
                    has yet to review the new deliverables
                  </span>
                </div>
              </div>
              <div className="chevron-wrapper">
                <ChevronRightIcon
                  size={18}
                  strokeWidth={2.5}
                  backgroundColor={Color.NEUTRAL[400]}
                />
              </div>
            </button>
          )}
          {/* Start Work */}
          <button
            className={`emp-button-reset metric-card ${
              brandLatestNegotiationBreakdown.acceptedNegotiations.length === 0
                ? "disabled"
                : "clickable"
            }`}
            onClick={() => {
              if (
                brandLatestNegotiationBreakdown.acceptedNegotiations.length ===
                0
              )
                return;
              startTaskModalRef.current?.show(task);
            }}
          >
            <div className="info-wrapper">
              <img
                className="metric-img play-img"
                alt="invite-creator"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/play.png"
              />
              <div>
                <span className="metric-title">Start Task</span>
                {brandLatestNegotiationBreakdown.acceptedNegotiations.length ===
                  0 && (
                  <span className="metric-description">
                    You may start once the creators are confirmed.
                  </span>
                )}
                {brandLatestNegotiationBreakdown.acceptedNegotiations.length >
                  0 &&
                  renderMetricCreators(
                    brandLatestNegotiationBreakdown.acceptedNegotiations,
                    true
                  )}
              </div>
            </div>
            <div className="chevron-wrapper">
              <ChevronRightIcon
                size={18}
                strokeWidth={2.5}
                backgroundColor={Color.NEUTRAL[400]}
              />
            </div>
          </button>
        </section>
      )}

      {task && (
        <section className="task-details-section">
          <CreateTaskModal
            ref={createTaskModalRef}
            onSave={() => {
              props.fetchTask();
              fetchLatestNegotiation();
            }}
          />
          <div className="task-name-wrapper">
            <div className="info-wrapper">
              <h2 className="section-label">{task.name}</h2>
              <EmpPill
                text={StringUtils.capitalizeWords(task.status)}
                {...PILL_COLORS.amber}
              />
            </div>

            <EmpButton
              isFullWidth={false}
              onSubmit={() => {
                deleteTaskModalRef.current?.show(
                  task,
                  props.brandLatestNegotiation
                );
              }}
              buttonStyle="danger"
              text={"Delete Task"}
            />

            <EmpButton
              isFullWidth={false}
              onSubmit={() => {
                createTaskModalRef.current?.show(
                  task.campaign.id,
                  "edit",
                  task
                );
              }}
              buttonStyle="secondary"
              text={"Edit Details"}
            />
          </div>
          <p className="task-description mt-2">{task.description}</p>

          <div ref={resizeDivRef} className="task-details mt-6">
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"BUDGET"}
                value={`${FormFieldUtils.toMoney(
                  0,
                  true
                )}/${FormFieldUtils.toMoney(Number(task.budget), true)}`}
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"EST TASK RUNNING DATE"}
                value={`${DateUtil.toReadableDate(
                  task.startDate
                )} to ${DateUtil.toReadableDate(task.endDate)}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"MODE OF PAYMENT"}
                value={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <EmpCoinIcon
                      mode="icon-only"
                      iconValue={task.paymentMode}
                    />
                    {task.paymentMode}
                  </div>
                }
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"OBJECTIVE"}
                value={`${StringUtils.convertHyphensToTitleCase(
                  task.objective
                )}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"COUNTRIES"}
                value={renderCountries(task.countries)}
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"HIRED CREATORS"}
                value={renderHiredCreators(brandLatestNegotiation)}
              />
            </div>
          </div>
        </section>
      )}

      {task && (
        <section className="deliverable-section">
          <div className="title-wrapper">
            <h2 className="section-label">
              {task.deliverables.length} Deliverable
              {task.deliverables.length > 1 ? "s" : ""}
            </h2>
            <div className="link-wrapper">
              {task.deliverables.length > 0 && (
                <EmpLink
                  onSubmit={() => {
                    setDeliverableIsExpanded(!deliverableIsExpanded);
                  }}
                  text={
                    deliverableIsExpanded ? (
                      <>
                        Hide{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronUpIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        Show Deliverables{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronDownIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    )
                  }
                />
              )}
            </div>
          </div>
          <div
            className="collapsible-section"
            style={{
              height: deliverableIsExpanded ? deliverableInfoHeight : 0,
            }}
          >
            <div style={{ boxSizing: "border-box" }} ref={deliverableInfoRef}>
              {task.deliverables.map((elem, index) => {
                return (
                  <div key={elem.id} className="deliverable-wrapper">
                    <span className="deliverable-indicator">
                      DELIVERABLE {index + 1}
                    </span>
                    <h3 className="deliverable-name-lbl mb-2">{elem.name}</h3>
                    <EmpEditorView value={elem.description} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}

      <section className="events-section">
        <h2 className="section-label">Recent Events</h2>

        <div className="mt-4 table-wrapper">
          {negotationLogs && (
            <NegotiationLogTable
              perspective="brand"
              rawRecords={negotationLogs}
            />
          )}
        </div>
      </section>
    </div>
  );
};
