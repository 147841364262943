import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KycApi from "../../api/payment-msvc/kyc.api";
import SocialMediaApi from "../../api/social-integration-msvc/social-media.api";
import AlertSquareIcon from "../../components/icon/alert-square";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import { TikTokLoader } from "../../components/loaders/tiktok-loader";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpSelect from "../../components/shared/emp-select/emp-select";
import {
  KYC_PROGRESS,
  SM_REDIRECT,
  SOCIAL_MEDIA_TYPE,
} from "../../constants/app.constants";
import { industryOptions } from "../../constants/selectConstants";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import { SaveKycCreatorProfessionalDetailsDto } from "../../model/payment/kyc/save-kyc-creator-professional-info.dto";
import { FacebookPageDto } from "../../model/social-media/facebook-page.dto";
import { InstagramConnectedAccountDto } from "../../model/social-media/instagram-connected-account.dto";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import FacebookPageSelectionModal, {
  FacebookPageSelectionModalRef,
} from "../creator-home-page/dashboard-cards/facebook-page-selection-modal";
import InstagramAccountSelectionModal, {
  InstagramAccountSelectionModalRef,
} from "../creator-home-page/dashboard-cards/instagram-account-selection-modal";
import InstagramConnectInfoModal, {
  InstagramConnectInfoModalRef,
} from "../creator-home-page/dashboard-cards/instagram-connect-info-modal";
import FacebookUtils from "../creator-home-page/facebook";
import { useTikTokLogin } from "../creator-home-page/dashboard-cards/hooks/useTikTokLogin";
import { XLoader } from "../../components/loaders/x-loader";
import XIcon from "../../components/icon/x-icon";
import { useXLogin } from "../creator-home-page/dashboard-cards/hooks/useXLogin";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;

interface Props {
  kycInfo?: KycDto;
  nextStep: () => void;
}

interface FacebookLoginAttributes {
  id: string;
  access_token: string;
}

export const KycCreatorProfessionalDetailsStep = (props: Props) => {
  const { kycInfo, nextStep } = props;
  const intl = useIntl();
  const [form, setForm] = useState<IFormGroup>();
  const { tiktokLogin, bindTikTokAccount } = useTikTokLogin();
  const { xLogin, bindXAccount } = useXLogin();

  const nextBtnRef = useRef<EmpButtonRef>();
  const hasSubmittedRef = useRef(false);

  // Ensures that this component will only be set once
  const facebookPageSelectionModalRef = useRef<FacebookPageSelectionModalRef>();
  const instagramAccountSelectionModalRef =
    useRef<InstagramAccountSelectionModalRef>();
  const instagramConnectInfoModalRef = useRef<InstagramConnectInfoModalRef>();
  const facebookUtil = new FacebookUtils();

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());

  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);
  const [smIsLoading, setSmIsLoading] = useState<"tiktok" | "x" | "none">(
    "none"
  );
  const [socialMediaSpecs, setSocialMediaSpecs] = useState<SmBriefRespDto[]>(
    []
  );
  const [anyPlatformConnected, setAnyPlatformConnected] =
    useState<boolean>(false);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    if (!kycInfo || !form) return;
    if (kycInfo.status === "rejected") fetchKycRejection();
  }, [kycInfo, form]);

  const getUserSocialMediaMetrics = useCallback(async () => {
    try {
      const resp = await SocialMediaApi.getSocialMediaSpecs();
      setSocialMediaSpecs(resp.data);
      setAnyPlatformConnected(checkIfAnyPlatformsConnected(resp.data));
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  }, []);

  const checkIfAnyPlatformsConnected = (
    socialMediaBriefs: SmBriefRespDto[]
  ) => {
    for (let socialMediaBrief of socialMediaBriefs) {
      if (socialMediaBrief.isAvailable) return true;
    }
    return false;
  };

  useEffect(() => {
    getUserSocialMediaMetrics();
  }, [getUserSocialMediaMetrics]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    if (tikTokCode) {
      setSmIsLoading("tiktok");
      bindTikTokAccount(
        tikTokCode,
        setSmIsLoading,
        getUserSocialMediaMetrics,
        "/creator/kyc"
      );
    }

    const xCode = urlParams.get("x_code");
    if (xCode) {
      setSmIsLoading("x");
      bindXAccount(
        xCode,
        setSmIsLoading,
        getUserSocialMediaMetrics,
        "/creator/kyc"
      );
    }
  }, [bindTikTokAccount, bindXAccount, getUserSocialMediaMetrics]);

  useEffect(() => {
    setForm((prevForm: IFormGroup | undefined) => {
      const form = {
        industry: new FormControl(
          "text",
          [new RequiredValidator("Industry is required")],
          prevForm?.industry.getValue() ?? "Content Creator / Influencer"
        ),
        proofOfIdentification: new FormControl(
          "text",
          [],
          prevForm?.proofOfIdentification.getValue() ?? ""
        ),
      };
      if (hasSubmittedRef.current) FormGroupUtil.validate(form);
      return form;
    });
  }, [intl]);

  const formOnChange = (formControl: FormControl): void => {
    if (!hasSubmittedRef.current) return;
    const hasDiff = formControl.validateTrackDiff();
    if (hasDiff) setForm({ ...form });
  };

  const onSubmit = async () => {
    try {
      nextBtnRef.current?.setButtonState("loading");
      if (!form) return;
      hasSubmittedRef.current = false;
      const isValid = FormGroupUtil.validate(form);

      if (!anyPlatformConnected) {
        form.proofOfIdentification.errorMessage = intl.formatMessage({
          id: "validation_socialMediaIntegrationRequired",
        });
        form.proofOfIdentification.hasError = true;
      }

      setForm({ ...form });
      if (!isValid || !anyPlatformConnected) return;
      const request: SaveKycCreatorProfessionalDetailsDto = {
        industry: form.industry.getValue(),
      };
      const resp = await KycApi.saveKycProgress(
        KYC_PROGRESS.CREATOR_PROFESSIONAL_DETAILS.step,
        { details: request }
      );
      if (isApiStatus(resp.data) && resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_professionalInfoStep_successToastHeader",
          }),
          intl.formatMessage({
            id: "kycFormSection_professionalInfoStep_successToastDesc",
          })
        );
        nextStep();
      }
      nextStep();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to save KYC progress"
      );
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  const bindPlatform = (platform: string) => {
    if (platform === SOCIAL_MEDIA_TYPE.FACEBOOK) facebookLogin();
    else if (platform === SOCIAL_MEDIA_TYPE.INSTAGRAM)
      instagramConnectInfoModalRef.current?.show();
    else if (platform === SOCIAL_MEDIA_TYPE.TIKTOK)
      tiktokLogin(SM_REDIRECT.CREATOR_KYC);
    else if (platform === SOCIAL_MEDIA_TYPE.X) {
      xLogin(SM_REDIRECT.CREATOR_KYC);
    }
  };

  const facebookLogin = async () => {
    try {
      const response = await facebookUtil.login();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookPageResp = (await facebookUtil.getFacebookPages(
          userId
        )) as FacebookPageDto;

        if (facebookPageResp.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Your account does not have a Facebook Page"
          );
          return;
        }
        if (facebookPageResp.data.length > 1) {
          facebookPageSelectionModalRef.current?.show(
            facebookPageResp,
            "Facebook"
          );
          return;
        }
        saveFacebookLogin(facebookPageResp.data[0], "Facebook");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const instagramLogin = async () => {
    try {
      const response = await facebookUtil.instagramLogin();
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookInstagramConnectedPage =
          (await facebookUtil.getInstagramConnectedAccount(
            userId
          )) as InstagramConnectedAccountDto;
        if (facebookInstagramConnectedPage.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Instagram needs to bind with a Facebook Page"
          );
          return;
        } //
        if (facebookInstagramConnectedPage.data.length > 0) {
          instagramAccountSelectionModalRef.current?.show(
            facebookInstagramConnectedPage,
            "Instagram"
          );
          return;
        }
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to connect to Facebook"
      );
    }
  };

  const saveFacebookLogin = async (
    fbPageSpec: FacebookLoginAttributes,
    platform: string
  ) => {
    try {
      const resp = await SocialMediaApi.smLogin({
        socialMediaPlatform: platform,
        accessToken: fbPageSpec.access_token,
        socialMediaUserId: fbPageSpec.id,
      });
      if (resp.data.status === "success") {
        ToastUtils.success("Success", "Succesfully binded your FB account");
        if (platform === "Facebook") {
          facebookPageSelectionModalRef.current?.dismiss();
        } else if (platform === "Instagram") {
          instagramAccountSelectionModalRef.current?.dismiss();
        }
        facebookLogout();
        getUserSocialMediaMetrics();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to bind Facebook Page"
      );
    }
  };

  const facebookLogout = async () => {
    await facebookUtil.logout();
  };

  return (
    <div className="kyc-form">
      {smIsLoading === "tiktok" && <TikTokLoader />}
      {smIsLoading === "x" && <XLoader />}
      <FacebookPageSelectionModal
        ref={facebookPageSelectionModalRef}
        onSave={saveFacebookLogin}
        onDismiss={facebookLogout}
      />
      <InstagramAccountSelectionModal
        ref={instagramAccountSelectionModalRef}
        onSave={saveFacebookLogin}
        onDismiss={facebookLogout}
      />
      <InstagramConnectInfoModal
        ref={instagramConnectInfoModalRef}
        onSave={() => {
          instagramLogin();
        }}
      />

      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
      >
        <h2 className="form-header">
          <FormattedMessage id="kycFormSection_professionalInfoStep_header" />
        </h2>
        <p className="emp-paragraph mt-3">
          <FormattedMessage id="kycFormSection_professionalInfoStep_desc" />
        </p>
      </motion.div>

      {form && (
        <div className="form-control-wrapper mt-6">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
          >
            <EmpSelect
              disabled
              id={"industry"}
              labelText={intl.formatMessage({
                id: "kycFormSection_professionalInfoStep_header",
              })}
              required
              placeholder={intl.formatMessage({
                id: "kycFormSection_industryPlaceholder",
              })}
              onChange={formOnChange}
              formControl={form.industry}
              selectOptions={industryOptions}
            />

            {fieldDisputeMap.has("kycCreatorInfo_industry") && (
              <div className="dispute-box">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycCreatorInfo_industry")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
          >
            <div className="body-section">
              <span className="upload-section-label block">
                <FormattedMessage id="kycFormSection_proofOfIdentificationLabel" />
                <span className="required">*</span>
              </span>
              <p className="upload-description mt-1">
                <FormattedMessage id="kycFormSection_smProofOfIdentificationDesc" />
              </p>

              <div>
                {socialMediaSpecs.map((elem, index) => {
                  return (
                    <div key={elem.platform} className="platform-card mt-2">
                      {/* Empty State */}
                      {!elem.isAvailable && (
                        <div className="platform-logo">
                          {elem.platform === "Facebook" && (
                            <FacebookIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "Instagram" && (
                            <InstagramIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "TikTok" && (
                            <TikTokIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "X" && (
                            <XIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                        </div>
                      )}
                      {!elem.isAvailable && (
                        <div className="content">
                          <span className="status-lbl">
                            <FormattedMessage id="bindSocialMediaStep_notConnectedLabel" />
                          </span>
                          <span className="action-lbl">
                            <FormattedMessage
                              id="bindSocialMediaStep_platformCta"
                              values={{
                                cta: (
                                  <EmpLink
                                    onSubmit={() => {
                                      bindPlatform(elem.platform);
                                    }}
                                    text={<FormattedMessage id="cta_here" />}
                                  />
                                ),
                                platform: elem.platform,
                              }}
                            />{" "}
                          </span>
                        </div>
                      )}

                      {/* Filled State */}
                      {elem.isAvailable && (
                        <>
                          <div className="left-elem">
                            <div className="social-media-img-wrapper">
                              <div className="social-media-logo-placeholder">
                                {elem.platform === "Facebook" && (
                                  <FacebookIcon
                                    size={12}
                                    backgroundColor={Color.NEUTRAL[0]}
                                  />
                                )}
                                {elem.platform === "Instagram" && (
                                  <InstagramIcon
                                    size={12}
                                    backgroundColor={Color.NEUTRAL[0]}
                                  />
                                )}
                                {elem.platform === "TikTok" && (
                                  <TikTokIcon
                                    size={12}
                                    backgroundColor={Color.NEUTRAL[0]}
                                  />
                                )}
                                {elem.platform === "X" && (
                                  <XIcon
                                    size={12}
                                    backgroundColor={Color.NEUTRAL[0]}
                                  />
                                )}
                              </div>
                              <img
                                alt={`${elem.name} profile`}
                                className="social-media-image"
                                src={elem.pictureUrl}
                              />
                            </div>
                            <div className="content">
                              <span className="status-lbl">{elem.name}</span>
                              <span className="action-lbl">
                                <FormattedMessage id="bindSocialMediaStep_socialMediaFollowers" />
                                : {elem.followers}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {form.proofOfIdentification.hasError && (
                <div className="emp-error-message-wrapper mt-3">
                  <AlertSquareIcon
                    backgroundColor={Color.RED[600]}
                    size={16}
                    bottom={1}
                  />
                  <span>{form.proofOfIdentification.errorMessage}</span>
                </div>
              )}
            </div>

            {fieldDisputeMap.has("kycCreatorInfo_socialMedia") && (
              <div className="dispute-box mt-3">
                <span className="reviewer-comment">
                  <FormattedMessage id="kycFormSection_reviewerComment" />
                </span>
                <p className="comment">
                  {fieldDisputeMap.get("kycCreatorInfo_socialMedia")!.message}
                </p>
              </div>
            )}
          </motion.div>

          <div className="btn-wrapper">
            <EmpButton
              ref={nextBtnRef}
              isFullWidth={false}
              onSubmit={onSubmit}
              text={<FormattedMessage id="cta_nextStep" />}
            />
          </div>
        </div>
      )}
    </div>
  );
};
