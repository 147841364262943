import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import "./external-role-selection-page.scss";
import { Color } from "../../utilities/colors";
import EmpLink from "../../components/shared/emp-link/emp-link";
import useUser from "../../hooks/useUser";
import ChevronDownIcon from "../../components/icon/chevron-down";
import ChevronUpIcon from "../../components/icon/chevron-up";
import useEmpAuth from "../../hooks/useEmpAuth";
import useEmpGuard from "../../hooks/useEmpGuard";
import { AgencyInterestView } from "../sign-up-page/views/agency-interest-view";
import { PLATFORM_ROLES } from "../../constants/app.constants";
interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const ANIMATION_DELAY = 0.1;

type ViewMode = "role-selection" | "agency-interest";
export const ExternalRoleSelectionPage = (props: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);

  const [viewMode, setViewMode] = useState<ViewMode>("role-selection");
  const agencyGuideLinkRef = useRef<HTMLSpanElement>(null);
  const [isContextMenuVisible, setContextMenuVisible] = useState(false);
  const contextMenuElemRef = useRef<HTMLDivElement>(null);
  const profileDropdownElemRef = useRef<HTMLDivElement>(null);
  const { user } = useUser();
  const { signOut } = useEmpAuth();

  useEffect(() => {
    if (!user) return;
    if (
      user.userOnboardingState === "agency:onboarding" ||
      user.role === PLATFORM_ROLES.AGENCY
    ) {
      navigate("/agency/onboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contextMenuElemRef.current &&
        !contextMenuElemRef.current.contains(event.target as Node) &&
        profileDropdownElemRef.current &&
        !profileDropdownElemRef.current.contains(event.target as Node)
      ) {
        setContextMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenuElemRef]);
  return (
    <>
      {viewMode === "role-selection" && (
        <div className="emp-external-role-selection-page">
          <div className="title-wrapper">
            {user && (
              <section className="user-info-section">
                {isContextMenuVisible && (
                  <nav className="menu-wrapper" ref={contextMenuElemRef}>
                    <ul>
                      <li
                        onClick={async () => {
                          await signOut();
                        }}
                        className="option"
                      >
                        Sign Out
                      </li>
                    </ul>
                  </nav>
                )}
                <div
                  className="user-info-wrapper"
                  ref={profileDropdownElemRef}
                  onClick={() => {
                    setContextMenuVisible(true);
                  }}
                >
                  {user.imageType === "url" && (
                    <img
                      referrerPolicy="no-referrer"
                      className="profile-pic"
                      src={user.imageResource}
                      alt={user.fullName}
                    />
                  )}
                  <span className="profile-name">{user.fullName}</span>
                  {isContextMenuVisible && (
                    <ChevronUpIcon
                      size={18}
                      strokeWidth={2.5}
                      backgroundColor={Color.NEUTRAL[250]}
                    />
                  )}
                  {!isContextMenuVisible && (
                    <ChevronDownIcon
                      size={18}
                      strokeWidth={2.5}
                      backgroundColor={Color.NEUTRAL[250]}
                    />
                  )}
                </div>
              </section>
            )}
            <h1>
              <FormattedMessage id="selectRole_header" />
            </h1>
          </div>

          <div className="platform-role-wrapper mt-3">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: ANIMATION_DELAY * 0 }}
              onClick={() => {
                navigate("/brand/onboard?from=external-role-selection");
              }}
              className="platform-role-card"
            >
              <img
                alt="brand"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/brands-profile.png"
                className="platform-role-img"
              />
              <div>
                <h2 className="platform-role-lbl">
                  <FormattedMessage id="selectRole_brandTitle" />
                </h2>
                <p className="platform-role-description mt-1">
                  <FormattedMessage id="selectRole_brandDesc" />
                </p>
              </div>
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: ANIMATION_DELAY * 1 }}
              onClick={(e: React.MouseEvent) => {
                const clickedElement = e.target as Node;
                const guideElem = agencyGuideLinkRef.current!;
                window.location.hash = "agency-interest";
                setViewMode("agency-interest");
                if (
                  clickedElement === guideElem ||
                  guideElem.contains(clickedElement)
                ) {
                  return;
                }
              }}
              className="platform-role-card"
            >
              <img
                alt="agency"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/agency-profile.png"
                className="platform-role-img"
              />
              <div>
                <h2 className="platform-role-lbl">
                  <FormattedMessage id="selectRole_agencyTitle" />
                </h2>
                <p className="platform-role-description mt-1">
                  <FormattedMessage
                    id="selectRole_agencyDesc"
                  />
                </p>
              </div>
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              onClick={() => {
                navigate("/creator/onboard?from=external-role-selection");
              }}
              transition={{ delay: ANIMATION_DELAY * 2 }}
              className="platform-role-card"
            >
              <img
                alt="creator"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/creators-profile.png"
                className="platform-role-img"
              />
              <div>
                <h2 className="platform-role-lbl">
                  <FormattedMessage id="selectRole_creatorTitle" />
                </h2>
                <p className="platform-role-description mt-1">
                  <FormattedMessage id="selectRole_creatorDesc" />
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {viewMode === "agency-interest" && user && (
        <AgencyInterestView
          mode="sso-lead"
          prefilledDetails={{
            name: user.fullName,
            email: user.email,
            id: user.id,
          }}
          onViewChange={() => {
            setViewMode("role-selection");
          }}
        />
      )}
    </>
  );
};
