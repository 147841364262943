import "./change-email-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import { motion } from "framer-motion";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmailIcon from "../../components/icon/email-icon";
import _debounce from "lodash/debounce";

import LockIcon from "../../components/icon/lock-icon";
import { empDelay } from "../../utilities/delay";
import EmpException from "../../exception/empException";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import AlertSquareIcon from "../../components/icon/alert-square";
import ChevronLeftIcon from "../../components/icon/chevron-left";
import { FormattedMessage, useIntl } from "react-intl";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { confirmUserAttribute, updateUserAttribute } from "aws-amplify/auth";

export interface ChangeEmailModalRef {
  show: (currentEmail: string) => void;
  updateComplete: () => void;
  hide: () => void;
}

interface Props {
  onSave: (updatedEmail: string) => void;
}

interface SlideHeight {
  firstSlide: number;
  secondSlide: number;
  thirdSlide: number;
}

const ChangeEmailModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isPart2Submitted, setPart2Submitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);

  const [bodyVariant, setBodyVariant] = useState<string>("firstSlide");
  const [slideHeights, setSlideHeights] = useState<SlideHeight>({
    firstSlide: 0,
    secondSlide: 0,
    thirdSlide: 0,
  });

  // Slide refs
  const firstSlideRef = useRef<HTMLDivElement>(null);
  const secondSlideRef = useRef<HTMLDivElement>(null);
  const thirdSlideRef = useRef<HTMLDivElement>(null);

  const sendOtpEmailBtnRef = useRef<EmpButtonRef>();
  const completeChallengeBtnRef = useRef<EmpButtonRef>();

  const sliderVariant = {
    firstSlide: { left: "0%", height: slideHeights.firstSlide },
    secondSlide: { left: "-100%", height: slideHeights.secondSlide },
    thirdSlide: { left: "-200%", height: slideHeights.thirdSlide },
  };

  useEffect(() => {
    if (!visible) return;
    const resize = async () => {
      empDelay(100);
      if (
        firstSlideRef?.current &&
        secondSlideRef?.current &&
        thirdSlideRef?.current
      ) {
        computeHiddenFormHeight(
          firstSlideRef.current.clientHeight,
          secondSlideRef.current.clientHeight,
          thirdSlideRef.current.clientHeight
        );
      }
    };

    const observeResize = async (observer: ResizeObserver) => {
      await empDelay(100);
      observer.observe(firstSlideRef.current!);
      observer.observe(secondSlideRef.current!);
      observer.observe(thirdSlideRef.current!);
    };

    const resizeObserver = new ResizeObserver(() => {
      debounceFn(
        firstSlideRef.current!.clientHeight,
        secondSlideRef.current!.clientHeight,
        thirdSlideRef.current!.clientHeight
      );
    });
    resize();
    observeResize(resizeObserver);
    return () => resizeObserver.disconnect();
  }, [visible]);

  const computeHiddenFormHeight = (
    firstSlideHeight: number,
    secondSlideHeight: number,
    thirdSlideHeight: number
  ) => {
    setSlideHeights({
      firstSlide: firstSlideHeight,
      secondSlide: secondSlideHeight,
      thirdSlide: thirdSlideHeight,
    });
  };
  const debounceFn: (
    firstSlideHeight: number,
    secondSlideHeight: number,
    thirdSlideHeight: number
  ) => void = useCallback(
    _debounce(computeHiddenFormHeight, 150, { leading: true }),
    []
  );

  const [form, setForm] = useState<IFormGroup>();

  const [formPart2, setFormPart2] = useState<IFormGroup>({
    otp: new FormControl("text", [
      new RequiredValidator("OTP is required"),
      new LengthValidator(
        0,
        10,
        undefined,
        "OTP must not exceed 10 characters"
      ),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
      updateComplete,
    };
  });

  const updateComplete = () => {
    setBodyVariant("thirdSlide");
  };

  function generateRegexToExcludeEmail(email: string) {
    // Escape special characters in the email address
    const escapedEmail = email.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

    // Create a regex pattern that excludes the exact email address
    const regexPattern = `^(?!${escapedEmail}$).*$`;

    // Return the regex pattern
    return new RegExp(regexPattern);
  }

  const show = async (currentEmail: string) => {
    try {
      const newForm = {
        email: new FormControl("text", [
          new RequiredValidator("Email is required"),
          new LengthValidator(
            0,
            100,
            undefined,
            "Email must not exceed 100 characters"
          ),
          new PatternValidator(
            generateRegexToExcludeEmail(currentEmail),
            "Do not use your current email"
          ),
          new EmailValidator(),
        ]),
      };
      setForm(newForm);
      setVisible(true);
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(500);
    if (form) FormGroupUtil.reset(form);
    FormGroupUtil.reset(formPart2);
    setSubmitted(false);
    setPart2Submitted(false);
    setFormPart2({ ...formPart2 });
    setForm({ ...form });
    setBodyVariant("firstSlide");
  };

  /*--------------- Functions for Slide [1] --------------------- */
  const [errorMessage, setErrorMessage] = useState<string>();
  const submittedEmailRef = useRef<string>();

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validateForm = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  const sendOtpEmail = async () => {
    try {
      if (!form) return;
      sendOtpEmailBtnRef.current?.setButtonState("loading");
      setSubmitted(true);
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey: "email",
          value: form.email.getValue(),
        },
      });
      console.log("Change email output", output);
      // Submission successful
      submittedEmailRef.current = form.email.getValue();
      setBodyVariant("secondSlide");
      setCounter(30);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send verification email"
      );
      if (e instanceof Error) {
        if (e.name === "LimitExceededException")
          ToastUtils.error(
            "Maximum Retries",
            "Please wait before sending again."
          );
        if (e.name === "AliasExistsException")
          setErrorMessage("Email address already exists");
      }
    } finally {
      sendOtpEmailBtnRef.current?.setButtonState("default");
    }
  };

  /*--------------- Functions for Slide [2] --------------------- */
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>();
  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validatePart2Form = (formControl: FormControl): void => {
    if (!isPart2Submitted) return;
    if (formControl.validateTrackDiff()) {
      setFormPart2({ ...formPart2 });
    }
  };

  const backToSlide1 = () => {
    FormGroupUtil.reset(formPart2);
    setFormPart2({ ...formPart2 });
    setBodyVariant("firstSlide");
  };

  const completeChallenge = async () => {
    try {
      if (!form) throw new EmpException("Form not accessible");
      completeChallengeBtnRef.current?.setButtonState("loading");
      if (!submittedEmailRef.current)
        throw new EmpException("Unable to get submitted email");
      setPart2Submitted(true);
      const isValid = FormGroupUtil.validate(formPart2);
      setFormPart2({ ...formPart2 });
      if (!isValid) return;

      await confirmUserAttribute({
        userAttributeKey: "email",
        confirmationCode: formPart2.otp.getValue(),
      });

      props.onSave(form.email.getValue());
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send reset password"
      );
      if (e instanceof Error) {
        if (e.name === "LimitExceededException")
          ToastUtils.error(
            "Maximum Retries",
            "Please wait before sending again."
          );
        else if (e.name === "CodeMismatchException") {
          setOtpErrorMessage("Incorrect OTP Code. Please try again");
        } else if (e.name === "ExpiredCodeException") {
          setOtpErrorMessage(
            "Invalid code provided, please request a code again."
          );
        }
      }
    } finally {
      completeChallengeBtnRef.current?.setButtonState("default");
    }
  };

  const [counter, setCounter] = useState(0);
  const [isOtpWaiting, setOtpWaiting] = useState(true);
  useEffect(() => {
    /**
     * Manages the number of seconds that the user is required to wait before initiating another send OTP request
     */
    const startOtpCounter = async (): Promise<void> => {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(intervalId);
            setOtpWaiting(false);
          }
          return prevCounter - 1;
        });
      }, 1000);
    };
    if (counter === 30) {
      startOtpCounter();
      setOtpWaiting(true);
    }
  }, [counter]);

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showFooter={false}
      showFooterBorder={false}
      showHeader={false}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-change-email-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>

        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          autoComplete="new-password"
        >
          <motion.div
            variants={sliderVariant}
            initial={"firstSlide"}
            animate={bodyVariant}
            className="content-body"
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
            style={{ width: "300%" }}
          >
            <div className="swipe-slide first">
              <div style={{ boxSizing: "border-box" }} ref={firstSlideRef}>
                <div className="title-wrapper">
                  <h3>
                    <FormattedMessage id="updateEmailAddressModal_header" />
                  </h3>
                </div>

                <span className="description">
                  <FormattedMessage id="updateEmailAddressModal_desc" />
                </span>
                {form && (
                  <div className="mt-4">
                    <EmpTextInput
                      id={"email"}
                      onChange={(fc) => validateForm(fc)}
                      formControl={form.email}
                      placeholder={intl.formatMessage({
                        id: "updateEmailAddressModal_emailInputPlaceholder",
                      })}
                      labelText={intl.formatMessage({
                        id: "updateEmailAddressModal_emailInputHeader",
                      })}
                      leftIconComponent={EmailIcon}
                      autocomplete={false}
                    />
                    {errorMessage && (
                      <div className="emp-error-message-wrapper mt-3">
                        <AlertSquareIcon
                          backgroundColor={Color.RED[600]}
                          size={16}
                          bottom={1}
                        />
                        <span>{errorMessage}</span>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="mt-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <EmpButton
                    ref={sendOtpEmailBtnRef}
                    isFullWidth={false}
                    text={
                      <FormattedMessage id="updateEmailAddressModal_submitVerificationCodeBtn" />
                    }
                    onSubmit={() => {
                      sendOtpEmail();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="swipe-slide second">
              <div ref={secondSlideRef}>
                <div className="h-stack" style={{ alignItems: "center" }}>
                  <div className="title-wrapper">
                    <div
                      onClick={() => {
                        backToSlide1();
                      }}
                      className="back-btn-wrapper mr-1"
                    >
                      <ChevronLeftIcon
                        backgroundColor={Color.NEUTRAL[500]}
                        size={22}
                      />
                    </div>
                    <h3>
                      <FormattedMessage id="updateEmailAddressModal_otpHeader" />
                    </h3>
                  </div>
                </div>
                {submittedEmailRef.current && (
                  <span className="description">
                    <FormattedMessage
                      id="updateEmailAddressModal_otpDesc"
                      values={{
                        email: (
                          <span className="highlighted">
                            {submittedEmailRef.current}
                          </span>
                        ),
                      }}
                    />{" "}
                  </span>
                )}
                <div className="mt-4">
                  <EmpTextInput
                    id={""}
                    formControl={formPart2.otp}
                    onChange={(fc) => validatePart2Form(fc)}
                    placeholder={intl.formatMessage({
                      id: "updateEmailAddressModal_otpInputDesc",
                    })}
                    labelText={intl.formatMessage({
                      id: "updateEmailAddressModal_otpInputHeader",
                    })}
                    leftIconComponent={LockIcon}
                  />
                  {otpErrorMessage && (
                    <div className="emp-error-message-wrapper mt-3">
                      <AlertSquareIcon
                        backgroundColor={Color.RED[600]}
                        size={16}
                        bottom={1}
                      />
                      <span>{otpErrorMessage}</span>
                    </div>
                  )}
                </div>
                {isOtpWaiting && (
                  <div className="loader-wrapper mt-2">
                    <div className="emp-spinner small"></div>
                    <span className="block ml-1 color-gray-500">
                      <FormattedMessage
                        id="updateEmailAddressModal_otpRefreshLabel"
                        values={{ second: counter }}
                      />
                    </span>
                  </div>
                )}
                {!isOtpWaiting && (
                  <div className="loader-wrapper mt-2">
                    <EmpLink
                      onSubmit={() => {
                        sendOtpEmail();
                      }}
                      text={"Resend email OTP"}
                    />
                  </div>
                )}
                <div
                  className="mt-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <EmpButton
                    ref={completeChallengeBtnRef}
                    isFullWidth={false}
                    text={<FormattedMessage id="cta_proceed" />}
                    onSubmit={() => {
                      completeChallenge();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="swipe-slide third">
              <div className="third-slide-wrapper" ref={thirdSlideRef}>
                <img
                  className="success-img"
                  alt="Checkmark"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/checkmark-img.png"
                />
                <h3 className="mt-4">
                  <FormattedMessage id="updateEmailAddressModal_successHeader" />
                </h3>
                <span className="description">
                  <FormattedMessage id="updateEmailAddressModal_successDesc" />
                </span>
                <div className="mt-4">
                  <EmpButton
                    isFullWidth={false}
                    text={<FormattedMessage id="cta_ack" />}
                    onSubmit={() => {
                      dismiss();
                    }}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ChangeEmailModal;
