import "./task-response-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import { empDelay } from "../../utilities/delay";
import { TaskDto } from "../../model/campaign/task.dto";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { PatternValidator } from "../../utilities/formUtils/patternValidator";
import { NumericRangeValidator } from "../../utilities/formUtils/numericRangeValidator";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import TaskInvitationResponseView from "./task-invitation-or-application-response-modal/task-invitation-response-view";
import MultiTaskApplicationModalView from "./task-invitation-or-application-response-modal/multi-task-application-modal-view";
import TaskApplicationResponseView from "./task-invitation-or-application-response-modal/task-application-response-view";
import MultiTaskInvitationModalView from "./task-invitation-or-application-response-modal/multi-task-invitation-modal-view";
import TaskCounterofferResponseView from "./task-invitation-or-application-response-modal/task-counteroffer-response-view";
import MultiTaskCounterofferModalView from "./task-invitation-or-application-response-modal/multi-task-counteroffer-modal-view";
import TaskCounterofferOnlyResponseView from "./task-invitation-or-application-response-modal/task-counteroffer-only-response-view";

export interface TaskResponseModalRef {
  dismiss: () => void;
  show: (
    task: TaskDto,
    latestNegotiations: LatestNegotiationExtendedDto[],
    mode: "application" | "invitation" | "counteroffer" | "counteroffer-only",
    context: "brand" | "seller"
  ) => void;
}

type ModalType =
  | "multi-invitation-response"
  | "multi-application-response"
  | "multi-counteroffer-response"
  | "invitation-response"
  | "application-response"
  | "counteroffer-response"
  | "counteroffer-only-response";
interface Props {
  onSave?: () => void;
}

const TaskResponseModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalType>();
  const [task, setTask] = useState<TaskDto>();
  const [latestNegotiations, setLatestNegotiations] =
    useState<LatestNegotiationExtendedDto[]>();
  const userContext = useRef<string>();
  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const [form, setForm] = useState<IFormGroup>({
    rate: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
       -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    task: TaskDto,
    latestNegotiations: LatestNegotiationExtendedDto[],
    mode: "application" | "invitation" | "counteroffer" | "counteroffer-only",
    context: string
  ) => {
    setTask(task);
    setLatestNegotiations(latestNegotiations);
    userContext.current = context;

    if (latestNegotiations.length > 1 && mode === "application")
      setModalMode("multi-application-response");
    else if (latestNegotiations.length > 1 && mode === "invitation")
      setModalMode("multi-invitation-response");
    else if (latestNegotiations.length > 1 && mode === "counteroffer")
      setModalMode("multi-counteroffer-response");
    else if (mode === "application") {
      setModalMode("application-response");
    } else if (mode === "invitation") {
      setModalMode("invitation-response");
    } else if (mode === "counteroffer") {
      setModalMode("counteroffer-response");
    } else if (mode === "counteroffer-only") {
      setModalMode("counteroffer-only-response");
    }
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
    FormGroupUtil.reset(form);
    setForm({ ...form });

    setLatestNegotiations(undefined);
    setTask(undefined);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div>
        <div className="emp-task-invitation-application-response-modal">
          {/* Task Invitation Offer View */}
          <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
            <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
          </div>
          {modalMode === "invitation-response" &&
            latestNegotiations &&
            task && (
              <TaskInvitationResponseView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                  dismiss();
                }}
                onBackClick={() => {}}
                task={task}
                latestNegotiation={latestNegotiations[0]}
              />
            )}
          {modalMode === "application-response" &&
            latestNegotiations &&
            task && (
              <TaskApplicationResponseView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                  dismiss();
                }}
                onBackClick={() => {}}
                task={task}
                latestNegotiation={latestNegotiations[0]}
              />
            )}
          {modalMode === "counteroffer-only-response" &&
            latestNegotiations &&
            task && (
              <TaskCounterofferOnlyResponseView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                  dismiss();
                }}
                context={userContext.current!}
                task={task}
                latestNegotiation={latestNegotiations[0]}
                onCancel={dismiss}
              />
            )}
          {modalMode === "counteroffer-response" &&
            latestNegotiations &&
            task && (
              <TaskCounterofferResponseView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                  dismiss();
                }}
                onBackClick={() => {}}
                task={task}
                latestNegotiation={latestNegotiations[0]}
              />
            )}
          {modalMode === "multi-application-response" &&
            latestNegotiations &&
            task &&
            userContext.current && (
              <MultiTaskApplicationModalView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                }}
                context={userContext.current}
                task={task}
                latestNegotiations={latestNegotiations}
              />
            )}
          {modalMode === "multi-invitation-response" &&
            latestNegotiations &&
            task &&
            userContext.current && (
              <MultiTaskInvitationModalView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                }}
                context={userContext.current}
                task={task}
                latestNegotiations={latestNegotiations}
              />
            )}
          {modalMode === "multi-counteroffer-response" &&
            latestNegotiations &&
            task &&
            userContext.current && (
              <MultiTaskCounterofferModalView
                onComplete={() => {
                  if (props.onSave) props.onSave();
                }}
                task={task}
                context={userContext.current}
                latestNegotiations={latestNegotiations}
              />
            )}
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default TaskResponseModal;
