import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import UserApi from "../../../api/user-msvc/user.api";
import { PermsUtils } from "../../../constants/permissions.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import { AppContext } from "../../../context/app.context";
import EmpException from "../../../exception/empException";
import useEmpGuard from "../../../hooks/useEmpGuard";
import { UserDto } from "../../../model/user-management/user.dto";
import { EmpNotification } from "../../shared/emp-notification/emp-notification";
import EmpProfileDropdownBtn, {
  MenuButtonSpec,
} from "../../shared/emp-profile-dropdown-btn/emp-profile-dropdown-btn";
import dashboardActiveImg from "../../../assets/icons/navbar-header-icons/dashboard-active.png";
import dashboardImg from "../../../assets/icons/navbar-header-icons/dashboard-inactive.png";
import campaignActiveImg from "../../../assets/icons/navbar-header-icons/campaign-active.png";
import campaignImg from "../../../assets/icons/navbar-header-icons/campaign-inactive.png";
import meActiveImg from "../../../assets/icons/navbar-header-icons/me-active.png";
import meImg from "../../../assets/icons/navbar-header-icons/me.png";
import settingsActiveImg from "../../../assets/icons/navbar-header-icons/settings-active.png";
import settingsImg from "../../../assets/icons/navbar-header-icons/settings.png";
import "./emp-creator-template.scss";
import EmpCreatorSideNav from "../../shared/emp-creator-side-nav/emp-creator-side-nav";
import * as Ably from "ably";
import { AblyProvider } from "ably/react";
import { getEnvVar } from "../../../constants/envConstants";
import { EmpGlobalNotification } from "../../../model/common/global-notification";
import { signOut as awsSignOut } from "aws-amplify/auth";

interface Props {
  children: JSX.Element;
}

const menuToPathBinding: { [key: string]: string } = {
  dashboard: "/creator/home",
  profile: "/creator/profile",
  campaigns: "/creator/campaigns-new",
  settings: "/creator/settings",
};

type MenuTabType = "dashboard" | "profile" | "campaigns" | "settings" | "none";
const EmpCreatorTemplate = (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  useEmpGuard("PROTECTED", navigate);

  const ablyClient = useMemo(() => {
    return new Ably.Realtime.Promise({
      authUrl: `${getEnvVar().userMsvc}/realtime`,
    });
  }, []);

  const [user, setUser] = useState<UserDto>();

  useEffect(() => {
    if (ablyClient && user) {
      const channel = ablyClient.channels.get("is_online");
      channel.presence.enter(user.id);
      const globalNotificationChannel = ablyClient.channels.get(
        `global_${user.id}`
      );

      globalNotificationChannel.subscribe((message) => {
        const notification = message.data as EmpGlobalNotification;
        PubSub.publish(PUB_SUB_TOPICS.GLOBAL_NOTIFICATION, notification);
      });
      return () => {
        channel.presence.leave();
        globalNotificationChannel.unsubscribe();
      };
    }
  }, [user, ablyClient]);

  const [selectedMenuTab, setSelectedMenuTab] = useState<MenuTabType>();

  const menuItemRef = useRef<{ [key: string]: HTMLDivElement }>({});
  const underlineElemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const homeKeywords = "/home";
    const campaignKeywords = "/campaigns";
    const profileKeywords = "/profile";
    const settingKeywords = "/settings";

    const path = location.pathname;
    if (path.includes(homeKeywords)) {
      setSelectedMenuTab("dashboard");
    } else if (path.includes(campaignKeywords)) {
      setSelectedMenuTab("campaigns");
    } else if (path.includes(profileKeywords)) {
      setSelectedMenuTab("profile");
    } else if (path.includes(settingKeywords)) {
      setSelectedMenuTab("settings");
    }
  }, [location]);

  const menuButtonSpecs: MenuButtonSpec<any>[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: "kebabMenu_settings" }),
        context: undefined,
        action: () => {
          settingsOnClick();
        },
      },
      {
        label: intl.formatMessage({ id: "kebabMenu_referral" }),
        context: undefined,
        action: () => {
          referralsOnClick();
        },
      },
      {
        label: intl.formatMessage({ id: "kebebMenu_logout" }),
        context: undefined,
        action: (record: any) => {
          signOut();
        },
      },
    ];
  }, [intl]);

  // User context from the App Provider
  const { user: userContext, setUser: setUserContext } = useContext(AppContext);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const response = await UserApi.fetchUser();
      const roleClassification = PermsUtils.getUserRole(response.data);
      // Navigate to creator dashboard here
      if (roleClassification === "agency") {
        navigate("/agency/home");
      }
      setUser(response.data);
      setUserContext!(response.data);
      if (response.data.userOnboardingState !== "COMPLETED")
        navigate("/creator/onboard");
    };
    fetchUserDetails();
  }, []);

  const referralsOnClick = () => {
    navigate("/creator/referrals");
  };

  // Open an alert event listener when this component is loaded for the first time.
  // When component is destroyed, it will unsubscribe.
  useEffect(() => {
    const subscriptionCallback = () => {
      fetchUserDetails();
    };
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.USER,
      subscriptionCallback
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, []);

  /**
   * An asynchronous function that signs out the current authenticated user and redirects them to the home page.
   */
  async function signOut() {
    try {
      await awsSignOut();
      setUserContext!(null);
      navigate("/sign-in");
    } catch (error) {
      if (error instanceof Error) {
        awsSignOut();
        navigate("/sign-in");
      }
      console.log("error signing out: ", error);
    }
  }

  const settingsOnClick = () => {
    navigate("/creator/settings");
  };

  const fetchUserDetails = async () => {
    const response = await UserApi.fetchUser();
    setUser(response.data);
    setUserContext!(response.data);
  };

  useEffect(() => {
    const homeKeywords = "/home";
    const campaignKeywords = ["campaigns", "campaign"];
    const profileKeywords = "/profile";
    const settingsKeyword = "/settings";

    const path = location.pathname;
    if (path.includes(homeKeywords)) {
      setSelectedMenuTab("dashboard");
    } else if (campaignKeywords.some((word) => path.includes(word))) {
      setSelectedMenuTab("campaigns");
    } else if (path.includes(profileKeywords)) {
      setSelectedMenuTab("profile");
    } else if (path.includes(settingsKeyword)) {
      setSelectedMenuTab("settings");
    } else {
      setSelectedMenuTab(undefined);
    }
  }, [location]);

  const menuItemOnClick = (menuItem: MenuTabType) => {
    try {
      setSelectedMenuTab(menuItem);
      navigate(menuToPathBinding[menuItem]);
    } catch (e) {
      console.error(e);
    }
  };

  // This moves the underline
  useEffect(() => {
    if (!underlineElemRef.current)
      throw new EmpException("Unable to get underline element");
    if (!selectedMenuTab) {
      underlineElemRef.current.style.opacity = "0";
      return;
    }
    const menuElem = menuItemRef.current[selectedMenuTab];
    if (!menuElem)
      throw new EmpException("Unable to retreieve target menu element");

    const width = `${menuElem.clientWidth}px`;
    const left = `${menuElem.offsetLeft}px`;
    underlineElemRef.current.style.opacity = "1";
    underlineElemRef.current.style.width = width;
    underlineElemRef.current.style.left = left;
  }, [selectedMenuTab, intl]);

  return (
    <AblyProvider client={ablyClient}>
      <div className="emp-creator-template-wrapper">
        <div className="emp-horizontal-navbar">
          <div className="top-bar-wrapper">
            <div className="top-bar-content">
              <div className="left-elems">
                <img
                  className="logo"
                  alt="CreatorFi logo"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/logo/creatorfi-logo.png"
                />
              </div>
              <div className="right-elems">
                {user && <EmpNotification userType="user" user={user} />}
                <div className="hide-on-mobile">
                  {user && (
                    <EmpProfileDropdownBtn
                      user={user}
                      menuButtonSpecs={menuButtonSpecs}
                    />
                  )}
                </div>
                <div className="show-on-mobile">
                  {user && (
                    <EmpCreatorSideNav
                      user={user}
                      onSignOut={() => {
                        signOut();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="menu-items-wrapper">
            <div className="menu-items-content">
              <div ref={underlineElemRef} className="underline"></div>
              <div
                onClick={() => {
                  menuItemOnClick("dashboard");
                }}
                ref={(el) =>
                  (menuItemRef.current["dashboard"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "dashboard" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "dashboard" && (
                    <img
                      className="icon"
                      src={dashboardActiveImg}
                      alt="Dashboard"
                    />
                  )}
                  {selectedMenuTab !== "dashboard" && (
                    <img className="icon" src={dashboardImg} alt="Dashboard" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_dashboard" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("profile");
                }}
                ref={(el) =>
                  (menuItemRef.current["profile"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "profile" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "profile" && (
                    <img className="icon" src={meActiveImg} alt="Profile" />
                  )}
                  {selectedMenuTab !== "profile" && (
                    <img className="icon" src={meImg} alt="Profile" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_myProfile" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("campaigns");
                }}
                ref={(el) =>
                  (menuItemRef.current["campaigns"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "campaigns" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "campaigns" && (
                    <img
                      className="icon"
                      src={campaignActiveImg}
                      alt="Campaign"
                    />
                  )}
                  {selectedMenuTab !== "campaigns" && (
                    <img className="icon" src={campaignImg} alt="Campaign" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_campaigns" />
                </span>
              </div>
              <div
                onClick={() => {
                  menuItemOnClick("settings");
                }}
                ref={(el) =>
                  (menuItemRef.current["settings"] = el as HTMLDivElement)
                }
                className={`menu-item ${
                  selectedMenuTab === "settings" ? "active" : ""
                }`}
              >
                <div className="icon-wrapper">
                  {selectedMenuTab === "settings" && (
                    <img
                      className="icon"
                      src={settingsActiveImg}
                      alt="Profile"
                    />
                  )}
                  {selectedMenuTab !== "settings" && (
                    <img className="icon" src={settingsImg} alt="Profile" />
                  )}
                </div>
                <span>
                  <FormattedMessage id="menu_settings" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {props.children}
        <div className="emp-mobile-bar">
          <button
            onClick={() => {
              menuItemOnClick("dashboard");
            }}
            className={`emp-button-reset icon-btn ${
              selectedMenuTab === "dashboard" ? "active" : ""
            } `}
          >
            {selectedMenuTab === "dashboard" && (
              <img
                className="icon active"
                src={dashboardActiveImg}
                alt="Profile"
              />
            )}
            {selectedMenuTab !== "dashboard" && (
              <img className="icon" src={dashboardImg} alt="Dashboard" />
            )}
            <span className="icon-text">Dashboard</span>
          </button>
          <button
            onClick={() => {
              menuItemOnClick("profile");
            }}
            className={`emp-button-reset icon-btn ${
              selectedMenuTab === "profile" ? "active" : ""
            } `}
          >
            {selectedMenuTab === "profile" && (
              <img className="icon active" src={meActiveImg} alt="Profile" />
            )}
            {selectedMenuTab !== "profile" && (
              <img className="icon" src={meImg} alt="Profile" />
            )}
            <span className="icon-text">Profile</span>
          </button>
          <button
            onClick={() => {
              menuItemOnClick("campaigns");
            }}
            className={`emp-button-reset icon-btn ${
              selectedMenuTab === "campaigns" ? "active" : ""
            } `}
          >
            {selectedMenuTab === "campaigns" && (
              <img
                className="icon active"
                src={campaignActiveImg}
                alt="Campaigns"
              />
            )}
            {selectedMenuTab !== "campaigns" && (
              <img className="icon" src={campaignImg} alt="Campaigns" />
            )}
            <span className="icon-text">Campaigns</span>
          </button>
          <button
            onClick={() => {
              menuItemOnClick("settings");
            }}
            className={`emp-button-reset icon-btn ${
              selectedMenuTab === "settings" ? "active" : ""
            } `}
          >
            {selectedMenuTab === "settings" && (
              <img
                className="icon active"
                src={settingsActiveImg}
                alt="Settings"
              />
            )}
            {selectedMenuTab !== "settings" && (
              <img className="icon" src={settingsImg} alt="Settings" />
            )}
            <span className="icon-text">Settings</span>
          </button>
        </div>
      </div>
    </AblyProvider>
  );
};
export default EmpCreatorTemplate;
