import _debounce from "lodash/debounce";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import NegotiationApi from "../../api/campaign-msvc/negotiation.api";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import { NEGOTIATION_STATUS, TASK_TYPE } from "../../constants/app.constants";
import EmpException from "../../exception/empException";
import useSelfAdjustingCards from "../../hooks/useSelfAdjustingCards";
import { LatestNegotiationExtendedDto } from "../../model/campaign/latest-negotiation-extended.dto";
import { TaskDto } from "../../model/campaign/task.dto";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import StringUtils from "../../utilities/string.util";
import FacebookIcon from "../icon/facebook-icon";
import InstagramIcon from "../icon/instagram-icon";
import TikTokIcon from "../icon/tiktok-icon";
import XCloseIcon from "../icon/x-close-icon";
import EmpContent from "../shared/emp-content/emp-content";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpPill from "../shared/EmpPill/EmpPill";
import "./agency-invite-creator-to-task-modal.scss";
import ApplyTaskModal, { ApplyTaskModalRef } from "./apply-task-modal";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";

export interface AgencyInviteCreatorToTaskModalRef {
  show: (task: TaskDto, user: UserDto) => void;
  dismiss: () => void;
}

interface Props {
  onSave?: () => void;
}

const AgencyInviteCreatorToTaskModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [creators, setCreators] = useState<SmCreatorRespDto[]>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [task, setTask] = useState<TaskDto>();
  const [user, setUser] = useState<UserDto>();
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const applyTaskModalRef = useRef<ApplyTaskModalRef>();

  const [latestNegotiationMap, setLatestNegotiationMap] =
    useState<Map<string, LatestNegotiationExtendedDto>>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchCreators = async (platform?: string) => {
    try {
      const response = await OrganisationApi.fetchAgencyCreators(platform);
      setCreators(response.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    } finally {
      setLoading(false);
    }
  };
  const fetchTaskNegotiationStatus = async (task: TaskDto, user: UserDto) => {
    try {
      if (!user.organisation || user.organisation.length === 0) {
        throw new EmpException("Not an organisation");
      }
      const representativeId = user.organisation[0].id;
      const latestNegoMap = new Map<string, LatestNegotiationExtendedDto>();
      const resp = await NegotiationApi.getLatestNegotiationByRepresentative(
        representativeId,
        task.campaign.id,
        task.id
      );
      if (!resp.data.hasNegotiation) {
        setLatestNegotiationMap(latestNegoMap);
        return;
      }
      const latestNegotiationData = resp.data.latestNegotiation;
      latestNegotiationData.forEach((elem) => {
        latestNegoMap.set(elem.creatorUserId, elem);
      });
      setLatestNegotiationMap(latestNegoMap);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch seller latest negotiation"
      );
    }
  };

  // If agency ID is included, this is a managed talent
  const show = async (task: TaskDto, user: UserDto) => {
    const platform = [
      TASK_TYPE.FACEBOOK,
      TASK_TYPE.INSTAGRAM,
      TASK_TYPE.TIKTOK,
    ].includes(task.platform)
      ? task.platform
      : undefined;
    fetchCreators(platform);
    fetchTaskNegotiationStatus(task, user);
    setTask(task);
    setUser(user);
    setVisible(true);
  };

  const dismiss = async () => {
    setVisible(false);
    setCreators(undefined);
    await empDelay(300);
    setLoading(true);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"lg"}
    >
      {/* This is body */}
      <div>
        {task && user && (
          <div className="emp-agency-invite-creator-to-task-modal">
            <ApplyTaskModal
              ref={applyTaskModalRef}
              onSave={() => {
                fetchTaskNegotiationStatus(task, user);
                if (props.onSave) props.onSave();
              }}
            />
            <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
              <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
            </div>
            <span className="header">
              Apply on behalf of your Managed Creator
            </span>

            {isLoading && (
              <div className="loading-view">
                <div className="emp-loader">
                  <span className="label">Fetching your creators</span>
                  <div className="emp-progress mt-2">
                    <div className="indeterminate"></div>
                  </div>
                </div>
              </div>
            )}
            <div className="content-section">
              {[
                TASK_TYPE.FACEBOOK,
                TASK_TYPE.INSTAGRAM,
                TASK_TYPE.TIKTOK,
              ].includes(task.platform) && (
                <span className="emp-paragraph">
                  Displaying only creators who have linked their {task.platform}{" "}
                  accounts to the platform. You are only allowed to choose
                  creators who are currently in the{" "}
                  <span className="emp-highlighted-neutral">available</span>{" "}
                  status.
                </span>
              )}
              <div ref={resizeDivRef} className="talent-card-wrapper pt-3 pb-6">
                {latestNegotiationMap &&
                  creators?.map((elem) => {
                    return (
                      <button
                        key={elem.id}
                        onClick={() => {
                          if (latestNegotiationMap.has(elem.id)) {
                            const obj = latestNegotiationMap.get(elem.id);
                            if (
                              ![
                                NEGOTIATION_STATUS.BRAND_REJECT,
                                NEGOTIATION_STATUS.SELLER_NEGOTIATE,
                              ].includes(obj!.status)
                            )
                              return;
                          }
                          applyTaskModalRef.current?.show(
                            task,
                            "agency",
                            user,
                            elem.id
                          );
                        }}
                        className="emp-button-reset talent-card"
                      >
                        {elem.imageType === "url" && (
                          <img
                            alt={elem.name}
                            className="profile-img"
                            src={elem.imageResource}
                          />
                        )}
                        {elem.imageType === "avatar" && (
                          <div
                            className="profile-avatar"
                            style={{ background: elem.imageResource }}
                          >
                            <span>{elem.initials}</span>
                          </div>
                        )}
                        <div className="bio-wrapper">
                          <div className="name-wrapper">
                            <span className="name">{elem.name}</span>
                            {latestNegotiationMap.has(elem.id) && (
                              <EmpPill
                                text={StringUtils.convertHyphensToTitleCase(
                                  latestNegotiationMap.get(elem.id)!.status
                                )}
                                {...PILL_COLORS.gray}
                              />
                            )}
                            {!latestNegotiationMap.has(elem.id) && (
                              <EmpPill text="Available" {...PILL_COLORS.green} />
                            )}
                            <div className="icon-wrapper">
                              {task.platform === TASK_TYPE.FACEBOOK && (
                                <FacebookIcon
                                  size={16}
                                  backgroundColor={Color.NEUTRAL[400]}
                                />
                              )}
                              {task.platform === TASK_TYPE.INSTAGRAM && (
                                <InstagramIcon
                                  size={16}
                                  backgroundColor={Color.NEUTRAL[400]}
                                />
                              )}
                              {task.platform === TASK_TYPE.TIKTOK && (
                                <TikTokIcon
                                  size={16}
                                  backgroundColor={Color.NEUTRAL[400]}
                                />
                              )}
                            </div>
                          </div>
                          <span className="email">{elem.email}</span>
                          <hr className="mv-2" />
                          <div className="metrics-wrapper">
                            <EmpContent
                              label={"AVG FOLLOWERS"}
                              value={elem.followers.toString()}
                            />
                            <EmpContent
                              label={"AVG IMPRESSIONS"}
                              value={elem.impressions.toString()}
                            />
                          </div>
                        </div>
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default AgencyInviteCreatorToTaskModal;
