import "./payment-fail-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import "./payment-fail-modal.scss";
import EmpModal from "../shared/emp-modal/emp-modal";
import { empDelay } from "../../utilities/delay";
import EmpButton from "../shared/emp-button/emp-button";
import { motion } from "framer-motion";

export interface PaymentFailModalRef {
  show: (responseCode: string) => void;
  dismiss: () => void;
}

interface Props {}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const fadeInUpVariant = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
};
const animationDelay = 0.2;
const animationDuration = 0.4;

const RESPONSE_CODE_MSG: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  ERR_59: {
    title: "Suspected Fraud",
    description:
      "Your transaction cannot be completed. Our system has detected unusual activity indicating potential fraud. Please contact your bank for further assistance.",
  },
  ERR_07: {
    title: "Pick Up Card",
    description:
      "Please retrieve your card. For security reasons, this transaction has been stopped, and your card needs to be collected. Contact your bank immediately for assistance.",
  },
  ERR_05: {
    title: "Do not Honor",
    description:
      "Transaction declined. Your bank has instructed us not to honor this transaction. Please contact your bank for more information.",
  },
  ERR_14: {
    title: "Invalid Card Number",
    description:
      "Invalid entry. The card number you entered is incorrect. Please check and enter a valid card number to proceed.",
  },
  ERR_51: {
    title: "Insufficient Funds",
    description:
      "Transaction failed due to insufficient funds. Please ensure adequate funds are available in your account or use an alternative payment method.",
  },
  ERR_ISSUER_DECLINED: {
    title: "Issuer Has Declined This Transaction",
    description:
      "The issuer has declined this transaction. Please contact your bank for assistance",
  },
  ERR_RISK_DECLINED: {
    title: "Risk Decline",
    description:
      "The risk engine has declined this transaction. Please contact your assigned CS personnel",
  },
  ERR_GENERIC: {
    title: "Payment Failed",
    description:
      "Your attempt to make subscription payment has been declined. Please use another card, or try again later.",
  },
};

const PaymentFailModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [modalContent, setModalContent] = useState<{
    title: string;
    description: string;
  }>({ title: "", description: "" });
  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (responseCode: string) => {
    setVisible(true);
    if (!(responseCode in RESPONSE_CODE_MSG)) {
      setModalContent({
        title: "Payment Error Occurred",
        description:
          "Please try another credit card to start subscription, or contact your bank about htis issue.",
      });
      return;
    }
    const { title, description } = RESPONSE_CODE_MSG[responseCode];
    setModalContent({ title, description });
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(300);
  };

  return (
    <EmpModal
      zIndex={300}
      visible={visible}
      setVisible={setVisible}
      bodyPadding={false}
      verticalPadding={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeader={false}
      showHeaderBorder={false}
      size={"xs"}
    >
      {/* This is body */}
      <div className="emp-payment-fail-modal">
        <section className="payment-modal-graphic-section">
          <motion.img
            variants={fadeInUpVariant}
            initial={"hidden"}
            animate="visible"
            className="credit-card-img"
            alt="Payment Failed"
            src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/credit-card.png"
          />
        </section>
        <section className="payment-modal-content-section">
          <motion.span
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            transition={{
              duration: animationDuration,
              delay: 0 * animationDelay,
            }}
            className="payment-title"
          >
            {modalContent.title}
          </motion.span>
          <motion.p
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            transition={{
              duration: animationDuration,
              delay: 1 * animationDelay,
            }}
            className="emp-paragraph mt-3"
          >
            {modalContent.description}
          </motion.p>

          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
            transition={{
              duration: animationDuration,
              delay: 2 * animationDelay,
            }}
            className="mt-6 payment-fail-button-wrapper"
          >
            <EmpButton
              isFullWidth={false}
              onSubmit={() => {
                dismiss();
              }}
              text={"Got it!"}
            />
          </motion.div>
        </section>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default PaymentFailModal;
