import { motion } from "framer-motion";
import { useEffect, useState } from "react";

interface Props {
  background?: "default" | "transparent" | "light" | "dark";
  isLoading: boolean;
  loadingText?: string;
}

const loaderVariant = {
  hidden: { opacity: 0, y: 0 },
  visible: { opacity: 1, y: 0 },
};

const overlayVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const EmpLoaderV2 = (props: Props) => {
  const loadingText = props.loadingText ?? "Loading...";
  const background = props.background ?? "transparent";
  const { isLoading } = props;
  const [variant, setVariant] = useState<"hidden" | "visible">("hidden");

  useEffect(() => {
    if (isLoading) setVariant("visible");
    else setVariant("hidden");
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <motion.div
          initial={variant}
          variants={overlayVariant}
          animate={variant}
          transition={{ duration: 0.5 }}
          className={`emp-loader-overlay ${background}`}
        >
          <motion.div
            initial={variant}
            variants={loaderVariant}
            animate={variant}
            transition={{ duration: 0.5 }}
            className="emp-loader"
          >
            <span className="label">{loadingText}</span>
            <div className="emp-progress mt-2">
              <div className="indeterminate"></div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};
export default EmpLoaderV2;
