import { forwardRef, useMemo, useRef, useState } from "react";
import { CardDto } from "../../model/payment/payment/card.dto";
import { ProductDto } from "../../model/payment/product/product.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import "./plan-selection-view.scss";
import { useNavigate } from "react-router-dom";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import ToastUtils from "../../utilities/toast-utils";
import useUser from "../../hooks/useUser";

interface Props {
  toPaymentMethod?: (cards: CardDto[], selectedPlan: SelectedPlan) => void;
  callbackUrl: string;
  hasFreeOption?: boolean;
}

interface SelectedPlan {
  id: string;
  type: "trial" | "price" | "proceed";
}

const PlanSelectionView = forwardRef((props: Props, ref) => {
  const [planList, setPlanList] = useState<ProductDto>();
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>();
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const nextBtnRef = useRef<EmpButtonRef>();
  const hasFreeOption = props.hasFreeOption ?? false;
  const navigate = useNavigate();
  const { refreshUser } = useUser();

  /**
   * Calculate the content of the button based on the selected plan ID and plan list.
   * @returns {string} The button content.
   */
  const buttonContent = useMemo(() => {
    if (selectedPlan && planList) {
      if (selectedPlan.type === "price") {
        const price = planList.prices.find(
          (elem) => elem.id === selectedPlan.id
        );
        if (price) {
          setBtnDisabled(false);
          return `Pay USD ${Number(price.unitAmount).toFixed(2)}`;
        }
      } else if (selectedPlan.type === "trial") {
        const trial = planList.trials.find(
          (elem) => elem.id === selectedPlan.id
        );
        if (trial) {
          setBtnDisabled(false);
          return `Start Free Trial`;
        }
      } else if (selectedPlan.type === "proceed") {
        setBtnDisabled(false);
        return "Proceed for Free";
      }
    }
    setBtnDisabled(false);
    return "Proceed to Payment";
  }, [selectedPlan, planList]);

  const onNextStep = async () => {
    try {
      if (!selectedPlan) return;
      nextBtnRef.current?.setButtonState("loading");

      // Try to convert agency to brand at this step.
      if (selectedPlan.type === "proceed") {
        const resp = await OrganisationApi.grantBrandRoleToAgency();
        if (resp.data.status === "success") {
          ToastUtils.success(
            "Brand Profile Activated",
            "Your brand profile is now active and ready to use."
          );
          await refreshUser();
          navigate("/brand/home");
        }
        return;
      }
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="emp-plan-selection-view">
      <h2 className="section-title ph-5">Emplifive Subscription</h2>
      <div className="plan-selection-section mt-4">
        <>
          {hasFreeOption && (
            <div
              onClick={() => {
                setSelectedPlan({ id: "proceed", type: "proceed" });
              }}
              className={`plan-selection-card ${
                selectedPlan?.type === "proceed" ? "selected" : ""
              }`}
            >
              <div className="plan-details-wrapper">
                <div
                  className={`knob ${
                    selectedPlan?.type === "proceed" ? "selected" : ""
                  }`}
                >
                  <div
                    className={`knob-ellipsis ${
                      selectedPlan?.type === "proceed" ? "selected" : ""
                    }`}
                  ></div>
                </div>
                <div className="plan-name-wrapper">
                  <span className="plan-name-lbl">Continue for Free</span>
                </div>
                <span className="plan-price-lbl"></span>
              </div>
            </div>
          )}
        </>
      </div>
      <div className="product-capability-section">
        <div>
          <span className="capability-header">Social Media Management</span>
          <p className="capability-description">
            Manage all your social media channels, posts & performance in one
            powerful platform
          </p>
        </div>
        <div>
          <span className="capability-header">Campaign Management</span>
          <p className="capability-description">
            Create, manage and measure the success of your marketing campaign at
            ease.
          </p>
        </div>
        <div>
          <span className="capability-header">Analytics</span>
          <p className="capability-description">
            Track important metrics like engagement & conversions of your social
            media campaign efforts
          </p>
        </div>
      </div>
      <div className="mt-6 btn-wrapper ph-5 pb-4">
        <EmpButton
          isFullWidth={false}
          disabled={isBtnDisabled}
          text={buttonContent}
          ref={nextBtnRef}
          onSubmit={() => {
            onNextStep();
          }}
        />
      </div>
    </div>
  );
});

export default PlanSelectionView;
