import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CreatorInfoApi from "../../../api/user-msvc/creator-info.api";
import ChevronLeftIcon from "../../../components/icon/chevron-left";
import EmpButton from "../../../components/shared/emp-button/emp-button";
import EmpIconButton from "../../../components/shared/emp-icon-button/emp-icon-button";
import EmpRoundedPill, {
  EmpRoundedPillRef,
} from "../../../components/shared/emp-rounded-pill/emp-rounded-pill";
import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import { CreatorInfoOptionsRespDto } from "../../../model/user-management/creator-info-options-resp.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import TranslationUtil from "../../../utilities/translation.util";
import "./select-creator-info-step.scss";
import { Color } from "../../../utilities/colors";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;

interface PillOption {
  value: number;
  isSelected: boolean;
}

interface StepProps {
  nextStep: (intent: "done" | "skip") => void;
  prevStep: () => void;
}

export const SelectCreatorInfoStep = (props: StepProps) => {
  const intl = useIntl();
  const [isLoaded, setIsLoaded] = useState(false);

  const isMobile = useEmpBreakpoint(500);
  const [creatorInfoOptions, setCreatorInfoOptions] =
    useState<CreatorInfoOptionsRespDto>();

  // Service Pill tracker
  const [servicePills, setServicePills] = useState<PillOption[]>([]);
  const servicePillsRef = useRef<EmpRoundedPillRef[]>([]);

  // Interest Pill tracker
  const [interestPills, setInterestPills] = useState<PillOption[]>([]);
  const interestPillsRef = useRef<EmpRoundedPillRef[]>([]);

  // Interest Count Tracker
  const [selectedInterestCount, setSelectedInterestCount] = useState<number>(0);
  const [selectedServiceCount, setSelectedServicesCount] = useState<number>(0);

  const fetchCreatorInfoOptions = useCallback(async () => {
    try {
      const resp = await CreatorInfoApi.fetchCreatorInfoOptions();
      setCreatorInfoOptions(resp.data);

      const servicePillOptions: PillOption[] = [];
      resp.data.services.forEach((elem) => {
        servicePillOptions.push({ value: elem.id, isSelected: false });
      });
      setServicePills(servicePillOptions);

      const interestPillOptions: PillOption[] = [];
      resp.data.interests.forEach((elem) => {
        interestPillOptions.push({ value: elem.id, isSelected: false });
      });
      setInterestPills(interestPillOptions);

      setIsLoaded(true);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info option"
      );
    }
  }, []);

  useEffect(() => {
    fetchCreatorInfoOptions();
  }, [fetchCreatorInfoOptions]);

  const nextButtonDisabled: boolean = useMemo(() => {
    const selectedServices = servicePills.filter((elem) => elem.isSelected);
    const selectedInterests = interestPills.filter((elem) => elem.isSelected);
    return Boolean(selectedServices.length > 4 || selectedInterests.length > 4);
  }, [interestPills, servicePills]);

  const servicePillOnSelect = (index: number, pillRef: EmpRoundedPillRef) => {
    pillRef.click();
    const boolValue = servicePills[index].isSelected;
    servicePills[index].isSelected = !boolValue;
    setServicePills([...servicePills]);
    aggregateSelectedOptions(servicePills, setSelectedServicesCount);
  };

  const interestPillOnSelect = (index: number, pillRef: EmpRoundedPillRef) => {
    pillRef.click();
    const boolValue = interestPills[index].isSelected;
    interestPills[index].isSelected = !boolValue;
    setInterestPills([...interestPills]);
    aggregateSelectedOptions(interestPills, setSelectedInterestCount);
  };

  const aggregateSelectedOptions = (
    options: PillOption[],
    dispatchAction: React.Dispatch<React.SetStateAction<number>>
  ): void => {
    const count = options.reduce((val, elem) => {
      return val + (elem.isSelected ? 1 : 0);
    }, 0);
    dispatchAction(count);
  };

  const onSave = async () => {
    try {
      const serviceValues = servicePills
        .filter((elem) => elem.isSelected)
        .map((elem) => elem.value);
      const interestValues = interestPills
        .filter((elem) => elem.isSelected)
        .map((elem) => elem.value);

      const request = {
        services: serviceValues,
        interests: interestValues,
      };
      const resp = await CreatorInfoApi.saveCreatorInfo(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Creator Information Saved", "Saved");
      }
      props.nextStep("done");
    } catch (e) {
      if (e instanceof Error)
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
    }
  };

  return (
    <div className="emp-creator-info-step">
      <div className="form-wrapper">
        <div className="header-section">
          <EmpIconButton
            buttonStyle="secondary"
            icon={<ChevronLeftIcon backgroundColor={Color.NEUTRAL[100]} />}
            onSubmit={() => {
              props.prevStep();
            }}
          />
          <span className="step-lbl">
            <FormattedMessage id="selectServicesAndInterestStep_header" />
          </span>
        </div>
        <div className="body-section">
          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 2 * animationDelay }}
          >
            <p className="intro-para">
              <FormattedMessage id="selectServicesAndInterestStep_desc" />
            </p>
          </motion.div>

          <motion.div
            className="mt-6"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 2 * animationDelay }}
          >
            {selectedServiceCount <= 4 && (
              <p className="description">
                <FormattedMessage
                  id="selectServicesAndInterestStep_servicesSelection"
                  values={{
                    selection: (
                      <span className="highlighted">
                        {selectedServiceCount}/4
                      </span>
                    ),
                  }}
                />
              </p>
            )}
            {selectedServiceCount > 4 && (
              <p className="error-message">
                <FormattedMessage id="selectServicesAndInterestStep_servicesErrorMsg" />
              </p>
            )}

            {creatorInfoOptions && servicePills.length > 0 && (
              <div className="pill-wrapper mt-4">
                {creatorInfoOptions.services.map((elem, index) => {
                  return (
                    <div
                      onClick={() => {
                        servicePillOnSelect(
                          index,
                          servicePillsRef.current[index]
                        );
                      }}
                      className="pill-btn"
                      key={elem.id}
                    >
                      <EmpRoundedPill
                        isSelected={servicePills[index].isSelected}
                        ref={(el: EmpRoundedPillRef) => {
                          servicePillsRef.current[index] = el;
                        }}
                        text={TranslationUtil.translateCreatorService(
                          intl,
                          elem.id,
                          elem.label
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </motion.div>
        </div>
        <hr className="divider" />
        <div className="body-section no-top-pad">
          <motion.div
            className="mt-4"
            variants={fadeInVariants}
            initial="hidden"
            animate={isLoaded ? "visible" : "hidden"}
            transition={{ duration: 0.2, delay: 4 * animationDelay }}
          >
            {selectedInterestCount <= 4 && (
              <p className="description">
                <FormattedMessage
                  id="selectServicesAndInterestStep_interestsSelection"
                  values={{
                    selection: (
                      <span className="highlighted">
                        {selectedInterestCount}/4
                      </span>
                    ),
                  }}
                />
              </p>
            )}
            {selectedInterestCount > 4 && (
              <p className="error-message">
                <FormattedMessage id="selectServicesAndInterestStep_interestsErrorMsg" />
              </p>
            )}
            {creatorInfoOptions && interestPills.length > 0 && (
              <div className="pill-wrapper mt-4">
                {creatorInfoOptions.interests.map((elem, index) => {
                  return (
                    <div
                      onClick={() => {
                        interestPillOnSelect(
                          index,
                          interestPillsRef.current[index]
                        );
                      }}
                      className="pill-btn"
                      key={elem.id}
                    >
                      <EmpRoundedPill
                        isSelected={interestPills[index].isSelected}
                        ref={(el: EmpRoundedPillRef) => {
                          interestPillsRef.current[index] = el;
                        }}
                        text={TranslationUtil.translateCreatorInterest(
                          intl,
                          elem.id,
                          elem.label
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </motion.div>

          <div className="btn-section">
            <motion.div
              className="mt-10 btn-wrapper"
              variants={fadeInVariants}
              initial="hidden"
              animate={isLoaded ? "visible" : "hidden"}
              transition={{ duration: 0.2, delay: 5 * animationDelay }}
            >
              <EmpButton
                disabled={nextButtonDisabled}
                buttonHeight={isMobile ? "lg" : "sm"}
                text={<FormattedMessage id="cta_nextStep" />}
                onSubmit={() => {
                  onSave();
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
