import { IFormControl } from "./formControl";

export interface IValidator {
  validate(formControl: IFormControl): boolean;
}

export class BaseValidator {
  noError = (formControl: IFormControl): IFormControl => {
    formControl.errorMessage = "";
    formControl.hasError = false;
    return formControl;
  };

  hasError = (
    formControl: IFormControl,
    errorMessage: string
  ): IFormControl => {
    formControl.errorMessage = errorMessage;
    formControl.hasError = true;
    return formControl;
  };
}
