import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import _debounce from "lodash/debounce";
import { TaskDto } from "../../../model/campaign/task.dto";
import {
  ATTACHMENT_TYPE,
  EVIDENCE_TYPE,
} from "../../../constants/app.constants";
import { Color } from "../../../utilities/colors";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import UploadIcon from "../../icon/upload-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import AlertSquareIcon from "../../icon/alert-square";
import FileUtils from "../../../utilities/file-util";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { CreateEvidenceDto } from "../../../model/campaign/create-evidence.dto";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import ToastUtils from "../../../utilities/toast-utils";
import FileIcon from "../../icon/file-icon";
import { motion } from "framer-motion";
import EmpLoaderV2 from "../../shared/emp-loader-v2/emp-loader-v2";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import { uploadCampaignFileToS3 } from "../../../utilities/campaign-file-uploader.util";

interface Props {
  task: TaskDto;
  ongoingDeliverable: OngoingDeliverableExtendedDto;
  onEvidenceCreated: () => void;
  back: () => void;
}

const fadeInVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const SubmitEvidenceAttachmentView = (prop: Props) => {
  const { task, ongoingDeliverable, onEvidenceCreated, back } = prop;
  const intl = useIntl();
  

  const [file, setFile] = useState<File>();
  const [video, setVideo] = useState<File>();
  const [imageFiles, setImageFiles] = useState<File[]>();
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const submitEvidenceBtnRef = useRef<EmpButtonRef>();
  const [isConverting, setConverting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [form, setForm] = useState<IFormGroup>({
    remarks: new FormControl("text", [
      new RequiredValidator("Remarks is required"),
    ]),
  });

  const isSubmittingEvidenceRef = useRef<boolean>(false);
  /**
   * Handles the submission of proof of work.
   * @returns {Promise<void>} A Promise that resolves when the proof of work submission is completed.
   * @throws {Error} If an error occurs during the submission process.
   */
  const onSubmitEvidence = async () => {
    try {
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      if (isSubmittingEvidenceRef.current) return;
      isSubmittingEvidenceRef.current = true;
      setIsUploading(true);
      submitEvidenceBtnRef.current?.setButtonState("loading");
      const files: {
        s3Url: string;
        name: string;
        size: number;
        fileType: string;
      }[] = [];

      if (video) {
        const s3Url = await uploadCampaignFileToS3(
          video,
          "evidence-attachments"
        );
        files.push({
          s3Url,
          name: video.name,
          size: video.size,
          fileType: ATTACHMENT_TYPE.VIDEO,
        });
      } else if (imageFiles) {
        for (let image of imageFiles) {
          const s3Url = await uploadCampaignFileToS3(
            image,
            "evidence-attachments"
          );
          files.push({
            s3Url,
            size: image.size,
            name: image.name,
            fileType: ATTACHMENT_TYPE.IMAGE,
          });
        }
      } else if (file) {
        const s3Url = await uploadCampaignFileToS3(
          file,
          "evidence-attachments"
        );
        files.push({
          s3Url,
          name: file.name,
          size: file.size,
          fileType: ATTACHMENT_TYPE.FILE,
        });
      }
      const request: CreateEvidenceDto = {
        representativeId: ongoingDeliverable.representativeId,
        representativeRole: ongoingDeliverable.representativeRole,
        taskId: ongoingDeliverable.taskId,
        creatorId: ongoingDeliverable.creatorUserId,
        agencyOrgId: ongoingDeliverable.agencyOrgId,
        deliverableId: ongoingDeliverable.deliverableId,
        evidenceRecord: {
          platform: task.platform,
          remarks: form.remarks.getValue(),
          evidenceType: EVIDENCE_TYPE.ATTACHMENT,
          fileAttachmentEvidence: {
            files,
          },
        },
      };
      const resp = await EvidenceApi.createEvidence(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Proof of work Submitted",
          "Thank you for submitting the proof of work"
        );
        onEvidenceCreated();
      }
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error submitting proof of work"
      );
    } finally {
      isSubmittingEvidenceRef.current = false;
      submitEvidenceBtnRef.current?.setButtonState("default");
      setIsUploading(false);
    }
  };

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFile(undefined);
    setVideo(undefined);
    setImageFiles(undefined);
    setErrorMessage(undefined);
    const files: FileList | null = event.target.files;
    if (!files || files.length === 0) return;

    // Check if it's a video
    const vidIndex = FileUtils.findVideo(files);

    // Check if its a file
    const fileIndex = FileUtils.findNonVideoOrImage(files);
    if (vidIndex > -1) {
      setVideo(files[vidIndex]);
      setHasFiles(true);
    } else if (fileIndex > -1) {
      if (!FileUtils.isFileSizeSmallerThanMB(files[fileIndex], 20)) {
        setErrorMessage("File size must be less than or equal to 20MB.");
        return;
      }
      setFile(files[fileIndex]);
      setHasFiles(true);
    } else {
      const processedImageFiles: File[] = [];
      const imageFiles = FileUtils.findImage(files);
      for (let image of imageFiles) {
        if (!FileUtils.isFileSizeSmallerThanMB(image, 3)!) {
          setErrorMessage("Imags size must not exceed 3Mb");
        }
        if (image.type === "image/heic") {
          setConverting(true);
          const heicFile = await FileUtils.convertHeicToJpeg(image);
          processedImageFiles.push(heicFile);
          setConverting(false);
        } else {
          processedImageFiles.push(image);
        }
      }
      setImageFiles(processedImageFiles);
      setHasFiles(true);
    }
  };

  return (
    <div className="sm-image-view view-form-section">
      {isConverting && (
        <motion.div
          variants={fadeInVariant}
          initial="hidden"
          animate="visible"
          className="heic-conversion-overlay"
        >
          <EmpLoaderV2 isLoading={true} loadingText="Converting HEIC..." />
        </motion.div>
      )}
      {isUploading && (
        <motion.div
          variants={fadeInVariant}
          initial="hidden"
          animate="visible"
          className="heic-conversion-overlay"
        >
          <EmpLoaderV2
            isLoading={true}
            loadingText="Uploading Attachments..."
          />
        </motion.div>
      )}

      <h2 className="section-title">File Attachment Upload</h2>
      <section>
        <div className="uploader-section mt-2">
          <label className="upload-label">Upload File</label>
          <p className="emp-paragraph">
            You may upload images, videos and files as part of your proof of
            work.{" "}
          </p>
          {!hasFiles && (
            <label className="file-upload-zone mt-2" htmlFor={"logo-upload"}>
              <UploadIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
              <span className="title">Click here to upload an attachment</span>
              <p className="specs">Only able to upload max 20Mb File</p>
            </label>
          )}
          {hasFiles && (
            <div className="uploaded-attachment-wrapper mt-2">
              {imageFiles && (
                <div className="images-wrapper">
                  {imageFiles.map((file, index) => {
                    return (
                      <img
                        key={file.name}
                        alt={`attachment ${index}`}
                        src={URL.createObjectURL(file)}
                      />
                    );
                  })}
                </div>
              )}
              {video && (
                <div className="video-wrapper">
                  <div className="overlay">
                    <span className="video-name">{video.name}</span>
                  </div>
                  <video
                    loop
                    muted
                    controls
                    className="uploaded-video"
                    src={URL.createObjectURL(video)}
                  ></video>
                </div>
              )}
              {file && (
                <div className="file-upload-zone mt-2">
                  <FileIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
                  <span className="title">{file.name}</span>
                  <p className="specs">
                    {FileUtils.convertBytesToReadableSize(file.size)}
                  </p>
                </div>
              )}
            </div>
          )}
          {hasFiles && (
            <label className="mt-1" htmlFor={"logo-upload"}>
              <EmpLink text={"Replace this upload"} />
            </label>
          )}
          <input
            className="upload-hidden"
            type="file"
            id="logo-upload"
            onChange={handleFileUpload}
            name="files[]"
            multiple
          ></input>
          {errorMessage && (
            <div className="emp-error-message-wrapper">
              <AlertSquareIcon
                backgroundColor={Color.RED[600]}
                size={16}
                bottom={1}
              />
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
        <EmpTextInput
          id={"remarks"}
          multiline
          required
          rows={3}
          textAreaAdaptiveHeight
          labelText="Remarks"
          formControl={form.remarks}
          placeholder={`Enter your remarks here`}
        />
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle="secondary"
            text={<FormattedMessage id="cta_back" />}
            onSubmit={() => {
              back();
            }}
          />

          <EmpButton
            ref={submitEvidenceBtnRef}
            isFullWidth={false}
            text={intl.formatMessage({ id: "button_submitEvidence" })}
            onSubmit={onSubmitEvidence}
          />
        </div>
      </section>
    </div>
  );
};

export default SubmitEvidenceAttachmentView;
