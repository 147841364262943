import "./emp-language-picker.scss";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  EmpLanguage,
  LANGUAGE_CONSTANTS,
} from "../../../constants/language.constants";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import { LocalStorageUtil } from "../../../utilities/cache/local-storage-util";

const MENU_ITEM_HEIGHT = 30;

interface Props {
  bordered?: boolean;
  responsive?: boolean;
  menuPlacement?: "right" | "left";
}
const menuButtonStyle: React.CSSProperties = {
  height: MENU_ITEM_HEIGHT,
};
const EmpLanguagePicker = (props: Props) => {
  const menuPlacement = props.menuPlacement ?? "left";
  const bordered = props.bordered ?? false;
  const responsive = props.responsive ?? true;

  const languageOptions = useRef<EmpLanguage[]>(LANGUAGE_CONSTANTS);
  const [selectedLanguage, setSelectedLanguage] = useState<EmpLanguage>(() => {
    const locale = LocalStorageUtil.getItem("locale", "en");
    return (
      LANGUAGE_CONSTANTS.find((elem) => elem.locale === locale) ??
      LANGUAGE_CONSTANTS[0]
    );
  });
  const menuRef = useRef<HTMLDivElement>(null);
  const isSelectedRef = useRef(false);
  const [isSelected, setSelected] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    // This function is called when the user clicks outside of the menu,
    // and is used to close the menu.
    if (!isSelectedRef.current) return;
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setSelected(false);
    }
  };

  // This function is called when the component is mounted and whenever the
  // isSelected prop changes.
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const iconOnClick = () => {
    if (!isSelected) {
      setSelected(true);
    }
  };

  const buttonOnClick = (data: EmpLanguage) => {
    PubSub.publish(PUB_SUB_TOPICS.LANGUAGE, data.locale);
    setSelectedLanguage(data);
    LocalStorageUtil.setItem("locale", data.locale)!;
    setSelected(false);
  };

  return (
    <>
      <div
        ref={menuRef}
        onClick={() => iconOnClick()}
        className="emp-language-picker-menu"
      >
        <button className={`emp-language-button ${bordered ? "bordered" : ""}`}>
          <img alt={selectedLanguage.label} src={selectedLanguage.flag} />
          <span className={`language-lbl ${responsive ? "responsive" : ""}`}>
            {selectedLanguage.label}
          </span>
        </button>

        {isSelected && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            style={menuPlacement === "left" ? { left: 0 } : { right: 0 }}
            className="emp-language-picker-wrapper"
          >
            {languageOptions.current.map((elem) => {
              return (
                <div
                  key={elem.locale}
                  className="emp-language-btn"
                  onClick={() => {
                    buttonOnClick(elem);
                  }}
                >
                  <img alt={elem.locale} src={elem.flag} />
                  <span className={`language-lbl `}>{elem.label}</span>
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </>
  );
};

export default EmpLanguagePicker;
