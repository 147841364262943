import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import FileIcon from "../../components/icon/file-icon";
import EmpBreadcrumb, {
  EmpBreadcrumbSpecs,
} from "../../components/shared/emp-breadcrumb/emp-breadcrumb";
import EmpContent from "../../components/shared/emp-content/emp-content";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpException from "../../exception/empException";
import { Color } from "../../utilities/colors";
import { empDelay } from "../../utilities/delay";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import FileUtils from "../../utilities/file-util";
import "./seller-campaign-details-page.scss";
import _debounce from "lodash/debounce";
import { TaskCardDto } from "../../model/campaign/task-card.dto";
import { EmpTaskCard } from "../../components/shared/emp-task-card/emp-task-card";
import { DateUtil } from "../../utilities/date";
import { CampaignWOrgDto } from "../../model/campaign/campaign-w-org.dto";
import { EmpImage } from "../../components/shared/emp-image/emp-image";
import { EMPTY_PROFILE_IMG_URL } from "../../constants/app.constants";
import UserUtils from "../../utilities/user-utils";
import { useContext } from "react";
import { AppContext } from "../../context/app.context";
import { UserDto } from "../../model/user-management/user.dto";

const breadcrumbsData: EmpBreadcrumbSpecs[] = [];

export const SellerCampaignDetailsPage = () => {
  const location = useLocation();
  const { id: campaignId } = useParams();
  const [campaign, setCampaign] = useState<CampaignWOrgDto>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [breadcrumbs, setBreadcrumbs] = useState<EmpBreadcrumbSpecs[]>();

  const userRoleRef = useRef<"agency" | "creator">();
  const [taskCards, setTaskCards] = useState<TaskCardDto[]>([]);
  const resizeDivRef = useRef<HTMLDivElement>(null);

  const fetchTasksInCampaign = useCallback(async () => {
    try {
      setLoading(true);
      if (!campaignId) throw new EmpException("No campaign id");
      const resp = await CampaignApi.fetchTasksInCampaign(campaignId, "seller");
      setTaskCards(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch campaign");
    } finally {
      setLoading(false);
    }
  }, [campaignId]);

  useEffect(() => {
    if (taskCards.length > 0) console.log("okay le!");
  }, [taskCards]);

  const fetchCampaignById = useCallback(async () => {
    try {
      if (!campaignId) throw new EmpException("No campaign id");
      const resp = await CampaignApi.fetchCampaignById(campaignId);
      const campaign = resp.data;
      const newBreadcrumbs = [...breadcrumbsData];

      // Setting up breadcrumbs
      newBreadcrumbs.push({
        link: `/${userRoleRef.current}/campaigns-new#Browse%20Campaigns`,
        text: "Browse Campaign",
      });
      newBreadcrumbs.push({
        link: `/${userRoleRef.current}/campaign-details/${campaignId}`,
        text: campaign.name,
      });
      setBreadcrumbs(newBreadcrumbs);
      setCampaign(campaign);
      await empDelay(200);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch campaign");
    }
  }, [campaignId]);

  const { user: userContext } = useContext(AppContext);
  const userRef = useRef<UserDto>();

  const fetchUser = useCallback(async () => {
    const userRole = location.pathname.split("/")[1];
    userRoleRef.current = userRole as "creator" | "agency";

    if (!userRef.current) {
      const user = await UserUtils.fetchUser(userContext);
      userRef.current = user;
    }
  }, [userContext, location]);

  useEffect(() => {
    fetchUser().then((res) => {
      fetchTasksInCampaign();
      fetchCampaignById();
    });
  }, [fetchCampaignById, fetchTasksInCampaign, location, fetchUser]);

  // Campaign Card
  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="bg-tint"></div>
      <div className="emp-page-content-no-padding emp-seller-campaign-details-page">
        {breadcrumbs && (
          <div className="mt-3">
            <EmpBreadcrumb
              items={breadcrumbs}
              loading={isLoading}
              numLoadingItem={2}
            />
          </div>
        )}

        {campaign && (
          <section className="campaign-details-section mt-6">
            <img
              className="campaign-image hide-on-mobile"
              alt={campaign.name}
              src={campaign.pictureUrl}
            />
            <div className="show-on-mobile campaign-image-mobile-wrapper">
              <div className="full-banner-anchor">
                <div className="full-banner-wrapper">
                  <div className="banner-shading">
                    <h2 className="campaign-name-lbl">{campaign.name}</h2>
                  </div>
                  <img
                    className="campaign-image-mobile"
                    alt={campaign.name}
                    src={campaign.pictureUrl}
                  />
                </div>
              </div>
            </div>
            <div className="details-wrapper">
              <h2 className="campaign-name-lbl hide-on-mobile">
                {campaign.name}
              </h2>
              <p className="campaign-description">{campaign.description}</p>

              <div className="campaign-details-wrapper mt-3">
                <EmpContent
                  label={"CAMPAIGN CREATED BY"}
                  value={
                    <div className="brand-wrapper">
                      <EmpImage
                        className="logo"
                        fallback={EMPTY_PROFILE_IMG_URL}
                        src={campaign.organisation.logo}
                        alt={campaign.organisation.companyName}
                      />
                      <span className="company-name">
                        {campaign.organisation.companyName}
                      </span>
                    </div>
                  }
                />
                <EmpContent
                  label={"CREATED ON"}
                  value={
                    <div className="mt-2">
                      {DateUtil.toReadableDateWithTime(campaign.createdDate)}{" "}
                    </div>
                  }
                />
                <EmpContent
                  label={"LAST UPDATED"}
                  value={
                    <div className="mt-2">
                      {DateUtil.toReadableDateWithTime(campaign.updatedDate)}{" "}
                    </div>
                  }
                />

                {campaign.supportingDocumentUrl && (
                  <div className="uploaded-file-card">
                    <div className="image-section">
                      <FileIcon backgroundColor={Color.NEUTRAL[0]} />
                    </div>
                    <div className="text-section">
                      <span className="file-title">Reference Document</span>
                      <div style={{ marginTop: 4 }}>
                        <EmpLink
                          onSubmit={() => {
                            FileUtils.handleFileDownload(
                              "Reference Document.pdf",
                              campaign.supportingDocumentUrl
                            );
                          }}
                          text={"Download"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        <div className="header-wrapper mt-8">
          <h1 className="page-header">Tasks</h1>
        </div>

        <section ref={resizeDivRef} className="tasks-section">
          {taskCards.length > 0 &&
            !isLoading &&
            userRoleRef.current &&
            taskCards.map((elem) => {
              return (
                <EmpTaskCard
                  role={userRoleRef.current}
                  taskCardInfo={elem}
                  key={elem.id}
                  showCampaignLink={false}
                  redirectUrl={`/${userRoleRef.current}/campaign-details/${campaignId}/task/${elem.id}`}
                />
              );
            })}
          {taskCards.length === 0 && !isLoading && (
            <div className="blank-overlay">
              <div className="info-wrapper">
                <img
                  className="empty-img"
                  alt="No tasks found"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                />
                <h2 className="header">No Tasks</h2>
                <p className="description">
                  There are no tasks associated with this campaign.
                </p>
              </div>
            </div>
          )}
          {(isLoading || taskCards.length === 0) &&
            [1, 2, 3, 4].map((elem) => {
              return <EmpTaskCard key={elem} mode={"skeleton"} />;
            })}
        </section>
      </div>
    </div>
  );
};
