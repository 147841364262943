import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import { KycDto } from "../../model/payment/kyc/kyc.dto";
import EmpContent from "../../components/shared/emp-content/emp-content";
import StringUtils from "../../utilities/string.util";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import KycApi from "../../api/payment-msvc/kyc.api";
import ToastUtils from "../../utilities/toast-utils";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { isApiStatus } from "../../model/api/api-status";
import {
  KycFieldDispute,
  KycRejectionRespDto,
} from "../../model/payment/kyc/kyc-rejection-resp.dto";
import InfoCircleIcon from "../../components/icon/info-circle-icon";
import { Color } from "../../utilities/colors";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../../components/shared/emp-checkbox/emp-checkbox";

const ANIM_DELAY = 0.15;
const DURATION = 0.25;

interface Props {
  kycInfo?: KycDto;
  onSave: () => void;
  editBusinessInfo: () => void;
  editShareholderInfo: () => void;
  editPayment: () => void;
}

export const KycAgencySummary = (props: Props) => {
  const {
    kycInfo,
    onSave,
    editBusinessInfo,
    editShareholderInfo,
    editPayment,
  } = props;
  const intl = useIntl();
  const submitApplicationBtnRef = useRef<EmpButtonRef>();
  const retractApplicationBtnRef = useRef<EmpButtonRef>();
  const [isKycCompleted, setIsKycCompleted] = useState<boolean>(true);

  const [kycRejection, setKycRejection] = useState<KycRejectionRespDto>();
  const [fieldDisputeMap, setFieldDisputeMap] = useState<
    Map<string, KycFieldDispute>
  >(new Map());
  const [fieldDisputeSectionList, setFieldDisputeSectionList] = useState<
    string[]
  >([]);

  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const fetchKycRejection = useCallback(async () => {
    try {
      const resp = await KycApi.getRejection();
      const data = resp.data;
      if (isApiStatus(data)) {
        setKycRejection(undefined);
        return;
      }
      setKycRejection(data);
      const map: Map<string, KycFieldDispute> = new Map();
      data.fieldDisputes.forEach((elem) => {
        map.set(`${elem.section}_${elem.field}`, elem);
      });
      setFieldDisputeMap(map);
      const uniqueSectionSet = new Set(
        data.fieldDisputes.map((elem) => elem.section)
      );
      setFieldDisputeSectionList(Array.from(uniqueSectionSet));
    } catch (err) {
      console.error(err);
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Error occurred when fetching KYC listing"
      );
    }
  }, []);

  useEffect(() => {
    if (!kycInfo) return;
    if (kycInfo.status === "rejected") {
      fetchKycRejection();
    }
    setIsKycCompleted(kycInfo.hasCompleted);
  }, [kycInfo, fetchKycRejection]);

  const submitKycApplication = async () => {
    try {
      submitApplicationBtnRef.current?.setButtonState("loading");
      const resp = await KycApi.submitKyc();
      if (resp.data.status === "success") {
        ToastUtils.success(
          intl.formatMessage({
            id: "kycFormSection_summary_successToastHeader",
          }),
          intl.formatMessage({ id: "kycFormSection_summary_successToastDesc" })
        );
        onSave();
      }
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(err, "Please try again later");
    } finally {
      submitApplicationBtnRef.current?.setButtonState("default");
    }
  };

  const retractKycApplication = async () => {
    try {
      retractApplicationBtnRef.current?.setButtonState("loading");
      const resp = await KycApi.retractKyc();
      if (resp.data.status === "success") {
        ToastUtils.success(
          "KYC Application retracted",
          "You may submit the application again."
        );
        onSave();
      }
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(err, "Please try again later");
    } finally {
      retractApplicationBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="kyc-form">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariants}
        className="section-wrapper "
        transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
      >
        {kycInfo && kycInfo.hasCompleted && (
          <>
            <h2 className="form-header">
              <FormattedMessage id="kycInfoSection_summary_submittedHeader" />
            </h2>
            <p className="emp-paragraph mt-3">
              <FormattedMessage id="kycInfoSection_summary_submittedDesc" />
            </p>

            {kycInfo.status === "in-review" && (
              <div className="form-header-card mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_reviewCardHeader" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_reviewCardDesc" />
                </p>
                <EmpButton
                  className="mt-2"
                  buttonHeight="sm"
                  text={
                    <FormattedMessage id="kycFormSection_summary_amendFormBtn" />
                  }
                  ref={retractApplicationBtnRef}
                  onSubmit={() => {
                    retractKycApplication();
                  }}
                  isFullWidth={false}
                />
              </div>
            )}
            {kycInfo.status === "approved" && (
              <div className="form-header-card approved mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_approvedCardTitle" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_approvedCardDesc" />
                </p>
              </div>
            )}
          </>
        )}
        {kycInfo && !kycInfo.hasCompleted && (
          <>
            <h2 className="form-header">
              <FormattedMessage id="kycFormSection_summary_reviewHeader" />
            </h2>
            <p className="emp-paragraph mt-3">
              <FormattedMessage id="kycFormSection_summary_reviewDesc" />
            </p>
            {kycInfo.status === "rejected" && (
              <div className="form-header-card rejected mt-3">
                <span className="form-header-card-title">
                  <FormattedMessage id="kycFormSection_summary_rejectedCardTitle" />
                </span>
                <p className="emp-paragraph mt-1">
                  <FormattedMessage id="kycFormSection_summary_rejectedCardDesc" />
                </p>
                {kycRejection && (
                  <EmpContent
                    className="mt-3"
                    label={"REVIEWER COMMENTS"}
                    value={
                      <p className="emp-paragraph">{kycRejection.remarks}</p>
                    }
                  />
                )}
              </div>
            )}
          </>
        )}
      </motion.div>
      {kycInfo && (
        <div className="mt-6 form-control-wrapper">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 0 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_businessInformationHeader" />
                  </span>
                  {fieldDisputeSectionList.includes("kycBusinessInfo") && (
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.RED[400]}
                    />
                  )}
                </div>
                <EmpButton
                  buttonHeight="sm"
                  buttonStyle={
                    fieldDisputeSectionList.includes("kycBusinessInfo")
                      ? "primary"
                      : "secondary"
                  }
                  isFullWidth={false}
                  disabled={isKycCompleted}
                  onSubmit={() => {
                    editBusinessInfo();
                  }}
                  text={<FormattedMessage id="cta_edit" />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(
                    `kycBusinessInfo_legalBusinessName`
                  )}
                  label={intl.formatMessage({
                    id: "kycFormSection_legalBusinessNameContentLabel",
                  })}
                  value={kycInfo.kycBusinessInfo?.legalBusinessName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(`kycBusinessInfo_country`)}
                  label={intl.formatMessage({
                    id: "kycFormSection_countryContentLabel",
                  })}
                  value={
                    kycInfo.kycBusinessInfo?.country ? (
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[kycInfo.kycBusinessInfo.country][
                              "flag_1x1"
                            ]
                          }
                        />
                        <span>
                          {
                            COUNTRY_CONSTANTS[kycInfo.kycBusinessInfo.country]
                              .name
                          }
                        </span>
                      </div>
                    ) : (
                      "-"
                    )
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(
                    `kycBusinessInfo_companyRegistrationNumber`
                  )}
                  label={intl.formatMessage({
                    id: "kycFormSection_companyRegistrationNoContentLabel",
                  })}
                  labelDisableOverflow
                  value={
                    kycInfo.kycBusinessInfo?.companyRegistrationNumber ?? "-"
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(`kycBusinessInfo_businessType`)}
                  label={intl.formatMessage({
                    id: "kycFormSection_businessTypeContentLabel",
                  })}
                  value={StringUtils.capitalizeWords(
                    kycInfo.kycBusinessInfo?.businessType ?? "-"
                  )}
                />
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(`kycBusinessInfo_industry`)}
                  label={intl.formatMessage({
                    id: "kycFormSection_industryContentLabel",
                  })}
                  value={kycInfo.kycBusinessInfo?.industry ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  hasError={fieldDisputeMap.has(
                    `kycBusinessInfo_businessWebsite`
                  )}
                  label={intl.formatMessage({
                    id: "kycFormSection_businessWebsiteContentLabel",
                  })}
                  value={kycInfo.kycBusinessInfo?.businessWebsite ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_taxInfoSubheader" />
                </span>
              </div>
              <div className="review-info-section">
                <EmpContent
                  className="content-wrapper"
                  label={intl.formatMessage({
                    id: "kycFormSection_taxResidenceContentLabel",
                  })}
                  hasError={fieldDisputeMap.has(`kycBusinessInfo_taxResidence`)}
                  value={
                    kycInfo.kycBusinessInfo?.taxResidence ? (
                      <div className="country-wrapper">
                        <img
                          className="img-flag"
                          alt="country"
                          src={
                            COUNTRY_CONSTANTS[
                              kycInfo.kycBusinessInfo.taxResidence
                            ]["flag_1x1"]
                          }
                        />
                        <span>
                          {
                            COUNTRY_CONSTANTS[
                              kycInfo.kycBusinessInfo.taxResidence
                            ].name
                          }
                        </span>
                      </div>
                    ) : (
                      "-"
                    )
                  }
                />

                <EmpContent
                  className="content-wrapper"
                  label={intl.formatMessage({
                    id: "kycFormSection_taxIdContentLabel",
                  })}
                  hasError={fieldDisputeMap.has(`kycBusinessInfo_taxId`)}
                  value={kycInfo.kycBusinessInfo?.taxId ?? "-"}
                />
              </div>
              {kycInfo.kycBusinessInfo?.country === "sg" && (
                <div className="review-subsection">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_sgObligationsSubheader" />
                  </span>
                </div>
              )}
              {kycInfo.kycBusinessInfo?.country === "sg" && (
                <div className="review-info-section">
                  <EmpContent
                    className="content-wrapper"
                    label={intl.formatMessage({
                      id: "kycFormSection_hasDonationsLabel",
                    })}
                    hasError={fieldDisputeMap.has(
                      `kycBusinessInfo_hasDonations`
                    )}
                    value={
                      kycInfo.kycBusinessInfo?.singaporeObligations
                        ?.hasDonations
                        ? "Y"
                        : "N" ?? "-"
                    }
                  />
                  <EmpContent
                    className="content-wrapper"
                    label={intl.formatMessage({
                      id: "kycFormSection_hasRewardsCrowdfundingContentLabel",
                    })}
                    hasError={fieldDisputeMap.has(
                      `kycBusinessInfo_hasRewardsCrowdfunding`
                    )}
                    value={
                      kycInfo.kycBusinessInfo?.singaporeObligations
                        ?.hasRewardsCrowdfunding
                        ? "Y"
                        : "N" ?? "-"
                    }
                  />
                  <EmpContent
                    className="content-wrapper"
                    label={intl.formatMessage({
                      id: "kycFormSection_hasFinancialServicesContentLabel",
                    })}
                    labelDisableOverflow
                    hasError={fieldDisputeMap.has(
                      `kycBusinessInfo_hasFinancialServices`
                    )}
                    value={
                      kycInfo.kycBusinessInfo?.singaporeObligations
                        ?.hasFinancialServices
                        ? "Y"
                        : "N" ?? "-"
                    }
                  />
                </div>
              )}
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 1 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_stakeholderInformationHeader" />
                  </span>
                  {fieldDisputeSectionList.includes("kycStakeholderInfo") && (
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.RED[400]}
                    />
                  )}
                </div>

                <EmpButton
                  buttonHeight="sm"
                  buttonStyle={
                    fieldDisputeSectionList.includes("kycStakeholderInfo")
                      ? "primary"
                      : "secondary"
                  }
                  disabled={isKycCompleted}
                  onSubmit={() => {
                    editShareholderInfo();
                  }}
                  isFullWidth={false}
                  text={<FormattedMessage id={"cta_edit"} />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_legalNameContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycStakeholderInfo_legalName`)}
                  value={kycInfo.kycStakeholderInfo?.legalName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_emailContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycStakeholderInfo_email`)}
                  value={kycInfo.kycStakeholderInfo?.email ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_mobileNumberContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_mobileNumber`
                  )}
                  value={
                    kycInfo.kycStakeholderInfo?.mobileNumber
                      ? `${kycInfo.kycStakeholderInfo?.dialCode} ${kycInfo.kycStakeholderInfo?.mobileNumber}`
                      : "-"
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_identificationNoContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_identificationNumber`
                  )}
                  value={
                    kycInfo.kycStakeholderInfo?.identificationNumber ?? "-"
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_nationalityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_nationality`
                  )}
                  value={kycInfo.kycStakeholderInfo?.nationality ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_jobTitleContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycStakeholderInfo_jobTitle`)}
                  value={kycInfo.kycStakeholderInfo?.jobTitle ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_streetAddressContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_streetAddress`
                  )}
                  value={kycInfo.kycStakeholderInfo?.streetAddress ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_cityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycStakeholderInfo_city`)}
                  value={kycInfo.kycStakeholderInfo?.city ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_postalCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_postalCode`
                  )}
                  value={kycInfo.kycStakeholderInfo?.postalCode ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_identificationDocSubheader" />
                </span>
              </div>
              <div className="review-info-section">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_proofOfIdentificationContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_identificationDocumentName`
                  )}
                  value={
                    kycInfo.kycStakeholderInfo?.identificationDocumentName ??
                    "-"
                  }
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_proofOfAddressContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(
                    `kycStakeholderInfo_proofOfAddressDocumentName`
                  )}
                  value={
                    kycInfo.kycStakeholderInfo?.proofOfAddressDocumentName ??
                    "-"
                  }
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
          >
            <div className="review-box">
              <div className="title-section">
                <div className="title-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_paymentDetailsHeader" />
                  </span>
                  {fieldDisputeSectionList.includes("kycPayment") && (
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.RED[400]}
                    />
                  )}
                </div>
                <EmpButton
                  disabled={isKycCompleted}
                  buttonHeight="sm"
                  buttonStyle={
                    fieldDisputeSectionList.includes("kycPayment")
                      ? "primary"
                      : "secondary"
                  }
                  isFullWidth={false}
                  onSubmit={() => {
                    editPayment();
                  }}
                  text={<FormattedMessage id="cta_edit" />}
                />
              </div>
              <div className="review-info-section mt-3">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountHolderContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountHolderName`)}
                  value={kycInfo.kycPayment?.accountHolderName ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_countryCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_countryCode`)}
                  value={kycInfo.kycPayment?.countryCode ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_streetAddressContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_streetAddress`)}
                  value={kycInfo.kycPayment?.streetAddress ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_cityContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_city`)}
                  value={kycInfo.kycPayment?.city ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_stateContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_state`)}
                  value={kycInfo.kycPayment?.state ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_postalCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_postalCode`)}
                  value={kycInfo.kycPayment?.postalCode ?? "-"}
                />
              </div>
              <div className="review-subsection">
                <span>
                  <FormattedMessage id="kycFormSection_summary_bankInformationSubheader" />
                </span>
              </div>
              <div className="review-info-section">
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_entityTypeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_entityType`)}
                  value={kycInfo.kycPayment?.entityType ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountTypeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountType`)}
                  value={kycInfo.kycPayment?.accountType ?? "-"}
                />

                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_accountNumberContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_accountNumber`)}
                  value={kycInfo.kycPayment?.accountNumber ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_bankCountryCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_bankCountryCode`)}
                  value={kycInfo.kycPayment?.bankCountryCode ?? "-"}
                />
                <EmpContent
                  className="content-wrapper"
                  label={
                    <FormattedMessage id="kycFormSection_swiftCodeContentLabel" />
                  }
                  hasError={fieldDisputeMap.has(`kycPayment_swiftCode`)}
                  value={kycInfo.kycPayment?.swiftCode ?? "-"}
                />
              </div>
            </div>
          </motion.div>

          {kycInfo && !kycInfo.hasCompleted && (
            <section className="acknowledgement-wrapper mt-4">
              <div className="card">
                <div className="intro-wrapper">
                  <span>
                    <FormattedMessage id="kycFormSection_summary_declarationHeader" />
                  </span>
                </div>
                <div className="control-wrapper mt-2">
                  <div className="checkbox-wrapper">
                    <EmpCheckbox
                      onChange={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                      ref={ackCheckboxRef}
                      id={"acknowledgement"}
                    />
                  </div>
                  <div className="checkbox-text-wrapper">
                    <div
                      onClick={() => {
                        ackCheckboxRef.current?.setChecked(!ackIsChecked);
                        setAckIsChecked(!ackIsChecked);
                      }}
                    >
                      <span className={ackIsChecked ? "checked" : ""}>
                        <FormattedMessage id="kycFormSection_summary_declarationDesc" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {kycInfo && !kycInfo.hasCompleted && (
            <motion.div
              className="review-btn-wrapper"
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: DURATION, delay: ANIM_DELAY * 2 }}
            >
              <EmpButton
                ref={submitApplicationBtnRef}
                isFullWidth={false}
                disabled={!ackIsChecked}
                text={
                  <FormattedMessage id="kycFormSection_summary_submitApplication" />
                }
                onSubmit={submitKycApplication}
              />
            </motion.div>
          )}
        </div>
      )}
    </div>
  );
};
