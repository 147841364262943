import { EmpQueryable } from "../model/common/tableFilters";

export const PAGINATION = {
  PAGE_SIZE: 10,
};

export const DEFAULT_QUERYABLE: EmpQueryable = {
  pageNo: undefined,
  pageSize: undefined,
  sort: undefined,
};
