import { motion, useAnimation } from "framer-motion";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Cropper from "react-easy-crop";
import { FormattedMessage } from "react-intl";
import EmpException from "../../../exception/empException";
import { empDelay } from "../../../utilities/delay";
import EmpButton from "../emp-button/emp-button";
import { EmpCropperUtils } from "./emp-cropper-utils";
import "./emp-cropper.scss";

interface Props {
  image?: string;
  onCrop: (base64: string) => void;
  aspect?: number;
  cropShape?: "rect" | "round";
}

const modalVariant = {
  hidden: { y: 60, opacity: 0, transition: { duration: 0.2 } },
  visible: { y: 0, opacity: 1, transition: { duration: 0.2 } },
};

const overlayVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export interface EmpCropperRef {
  open: (base64: string) => void;
}

export const EmpCropper = forwardRef((props: Props, ref) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [isVisible, setVisible] = useState(false);
  const [image, setImage] = useState<string>();
  const control = useAnimation();

  const aspect = props.aspect ?? 1;
  const cropShape = props.cropShape ?? "round";
  useImperativeHandle(ref, () => {
    return {
      open,
    };
  });

  const open = (base64: string) => {
    setImage(base64);
    setVisible(true);
  };

  const cropperWrapperHeightRef = useRef<number>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  }>();

  const onCropComplete = useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  // Animate in Effect
  useEffect(() => {
    if (isVisible) control.start("visible");
  }, [isVisible]);

  const dismissModal = async () => {
    control.start("hidden");
    await empDelay(300);
    setVisible(false);
  };

  // useEffect(() => {
  //   // const getWrapperWidth = async (imgElem: HTMLImageElement) => {
  //   //   for (let i = 0; i < 5; i++) {
  //   //     await empDelay(100);
  //   //     if (imgElem.height === 0) continue;
  //   //     cropperWrapperHeightRef.current = imgElem.clientHeight + 40;
  //   //     setCropperReady(true);
  //   //     break;
  //   //   }
  //   // };
  //   // if (!imgRef.current || !image) return;
  //   // getWrapperWidth(imgRef.current);
  // }, [image]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedBase64 = await EmpCropperUtils.getCroppedImg(
        image!,
        croppedAreaPixels!
      );
      if (!croppedBase64) throw new EmpException("Temp Image Not found");
      props.onCrop(croppedBase64);
      dismissModal();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <>
      {image && <img ref={imgRef} className="img-ref" src={image} />}
      {isVisible && (
        <motion.div
          className="emp-cropper"
          variants={overlayVariant}
          initial={"hidden"}
          animate={control}
          transition={{ default: { ease: "easeOut" } }}
        >
          <motion.div
            className="cropper-card"
            variants={modalVariant}
            initial={"hidden"}
            animate={control}
            transition={{ default: { ease: "easeOut" } }}
          >
            <div className="header-section">
              <h1 className="header">
                <FormattedMessage id="cta_cropImage" />
              </h1>
            </div>
            <div className="cropper-section">
              (
              <div className="cropper-wrapper">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  cropShape={cropShape}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              )
            </div>
            <div className="footer-section">
              <div className="btn-wrapper">
                <EmpButton
                  buttonStyle="secondary"
                  onSubmit={() => {
                    dismissModal();
                  }}
                  text={<FormattedMessage id="cta_cancel" />}
                />
              </div>
              <div className="btn-wrapper">
                <EmpButton
                  text={<FormattedMessage id="cta_cropImage" />}
                  onSubmit={() => {
                    showCroppedImage();
                  }}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
});
