import { Fragment, useCallback, useEffect, useState } from "react";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpContent from "../../components/shared/emp-content/emp-content";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { SmBriefRespDto } from "../../model/social-media/sm-brief-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import { Color } from "../../utilities/colors";
import "./creator-profile-page.scss";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpRoundedPill from "../../components/shared/emp-rounded-pill/emp-rounded-pill";
import InstagramView from "../../components/views/instagram-view";
import FacebookView from "../../components/views/facebook-view";
import CreatorInfoApi from "../../api/user-msvc/creator-info.api";
import UserApi from "../../api/user-msvc/user.api";
import SocialMediaApi from "../../api/social-integration-msvc/social-media.api";
import TiktokView from "../../components/views/tiktok-view";
import { CreatorAgencyInvitationDto } from "../../model/onboarding/creator-agency-invitation-resp.dto";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import {
  EMPTY_PROFILE_IMG_URL,
  INVITATION_TYPE,
  SOCIAL_MEDIA_TYPE,
} from "../../constants/app.constants";
import EmpException from "../../exception/empException";
import EmpLink from "../../components/shared/emp-link/emp-link";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import { EmpImage } from "../../components/shared/emp-image/emp-image";
import FormFieldUtils from "../../utilities/form-field.util";
import XView from "../../components/views/x-view";
import StringUtils from "../../utilities/string.util";
import XIcon from "../../components/icon/x-icon";
import { getCurrentUser } from "aws-amplify/auth";

interface Props {}

export const CreatorProfilePage = (prop: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [creator, setCreator] = useState<UserDto>();
  const [smCreatorBrief, setSmCreatorBrief] = useState<SmBriefRespDto[]>([]);
  const [creatorAgencyInvitations, setCreatorAgencyInvitations] = useState<
    CreatorAgencyInvitationDto[]
  >([]);
  const [serviceOptions, setServiceOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [interestOptions, setInterestOptions] = useState<
    { label: string; id: number }[]
  >([]);
  const [creatorInfoCompleted, setCreatorInfoCompleted] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>();

  const [creatorId, setCreatorId] = useState<string>();

  const getCreatorId = useCallback(async () => {
    if (creatorId) return creatorId;

    const cognitoUser = await getCurrentUser();
    setCreatorId(cognitoUser.userId);
    return cognitoUser.userId;
  }, []);

  const getCreatorAgencies = useCallback(async (creatorId: string) => {
    try {
      const resp = await UserApi.fetchCreatorAgenciesById(creatorId);
      const creatorAgencies = resp.data;
      setCreatorAgencyInvitations(creatorAgencies);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator agencies"
      );
    }
  }, []);

  useEffect(() => {
    const fetchCreatorById = async () => {
      const id = await getCreatorId();
      await getCreatorAgencies(id);
      const resp = await UserApi.fetchCreatorById(id);
      setCreator(resp.data);
    };
    const fetchCreatorSocialMedia = async () => {
      const id = await getCreatorId();
      const resp = await SocialMediaApi.getSocialMediaSpecByCreatorId(id);
      for (let smBrief of resp.data) {
        if (smBrief.isAvailable) {
          setSelectedPlatform(smBrief.platform);
          break;
        }
      }
      setSmCreatorBrief(resp.data);
    };

    const checkCreatorInfoStatus = async () => {
      try {
        const id = await getCreatorId();
        const resp = await CreatorInfoApi.checkCreatorInfoCompletion(id);
        if (resp.data.statusMessage === "incomplete") {
          setCreatorInfoCompleted(false);
        } else if (resp.data.statusMessage === "complete") {
          setCreatorInfoCompleted(true);
          const creatorInfoResp = await CreatorInfoApi.fetchCreatorInfo(id);

          // Map to options
          setServiceOptions(creatorInfoResp.data.services);
          setInterestOptions(creatorInfoResp.data.interests);
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator info status"
        );
      }
    };

    fetchCreatorById();
    fetchCreatorSocialMedia();
    checkCreatorInfoStatus();
  }, []);

  const renderPlatformIcons = (smBriefs: SmBriefRespDto[]): JSX.Element => {
    return (
      <div className="platform-wrapper">
        {smBriefs.map((elem, index) => {
          return (
            <Fragment key={elem.platform}>
              {elem.platform === "Facebook" && elem.isAvailable && (
                <FacebookIcon size={16} backgroundColor={Color.NEUTRAL[300]} />
              )}
              {elem.platform === "Instagram" && elem.isAvailable && (
                <InstagramIcon size={16} backgroundColor={Color.NEUTRAL[300]} />
              )}
              {elem.platform === "TikTok" && elem.isAvailable && (
                <TikTokIcon size={16} backgroundColor={Color.NEUTRAL[300]} />
              )}
              {elem.platform === "X" && elem.isAvailable && (
                <XIcon size={16} backgroundColor={Color.NEUTRAL[300]} />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const socialMediaOnClick = (platform: string) => {
    setSelectedPlatform(platform);
  };

  const recommendPartnershipStatus = (context: string) => {
    if (context === INVITATION_TYPE.AFFILIATE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeAffiliateOption",
      });
    else if (context === INVITATION_TYPE.EXCLUSIVE)
      return intl.formatMessage({
        id: "inviteCreatorModal_partnershipTypeExclusiveOption",
      });
    throw new EmpException("Invalid invitation type");
  };

  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="emp-page-content no-padding emp-creator-profile-page">
        <div className="content">
          <div className="profile-info-wrapper">
            {creator && (
              <div className="profile-section">
                <div className="emp-card no-padding profile-card-border">
                  <div className="banner"></div>
                  <div className="pic-section">
                    {creator.imageType === "url" && (
                      <img
                        className="profile-pic"
                        srcSet={creator.imageResource}
                        alt="Creator"
                      />
                    )}
                    {creator.imageType === "avatar" && (
                      <div
                        className="avatar"
                        style={{ background: creator.imageResource }}
                      >
                        <span>{creator.initials}</span>
                      </div>
                    )}
                    <span className="creator-name-lbl mt-2">
                      {creator.fullName}
                    </span>
                  </div>
                  <div className="info-section mb-8">
                    <EmpContent
                      label={"COUNTRY"}
                      value={
                        <div className="country-wrapper">
                          <img
                            className="img-flag"
                            alt="country"
                            src={
                              COUNTRY_CONSTANTS[creator.creator.country][
                                "flag_1x1"
                              ]
                            }
                          />
                          <span>
                            {COUNTRY_CONSTANTS[creator.creator.country].name}
                          </span>
                        </div>
                      }
                    />

                    <div className="creator-info-wrapper">
                      <div className="creator-gender">
                        <EmpContent
                          label={"GENDER"}
                          value={StringUtils.capitalizeWords(
                            creator.creator.gender
                          )}
                        />
                      </div>
                      <div className="creator-age">
                        <EmpContent
                          label={"AGE"}
                          value={creator.creator.age.toString()}
                        />
                      </div>
                    </div>

                    {smCreatorBrief && (
                      <EmpContent
                        label={"PLATFORM"}
                        value={renderPlatformIcons(smCreatorBrief)}
                      />
                    )}
                    {!creatorInfoCompleted && (
                      <>
                        <EmpContent
                          label={"INTERESTS"}
                          value={"No Categories"}
                        />
                        <EmpContent
                          label={"SERVICES AVAILABLE"}
                          value={"Not specified"}
                        />
                      </>
                    )}

                    {creatorInfoCompleted && (
                      <>
                        <div className="interest-wrapper">
                          <EmpContent
                            label={"INTERESTS"}
                            value={
                              <div className="pills-wrapper">
                                {interestOptions.map((elem) => {
                                  return (
                                    <EmpRoundedPill
                                      key={elem.id}
                                      text={elem.label}
                                      clickable
                                    />
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                        <div className="service-wrapper">
                          <EmpContent
                            label={"SERVICES AVAILABLE"}
                            value={
                              <div className="pills-wrapper">
                                {serviceOptions.map((elem) => {
                                  return (
                                    <EmpRoundedPill
                                      key={elem.id}
                                      text={elem.label}
                                      clickable
                                    />
                                  );
                                })}
                              </div>
                            }
                          />
                        </div>
                      </>
                    )}

                    {creatorAgencyInvitations && (
                      <>
                        {creatorAgencyInvitations.length === 0 && (
                          <EmpContent
                            label={"REPRESENTATIVE"}
                            value="Freelance Creator"
                          />
                        )}
                        {creatorAgencyInvitations.length > 0 && (
                          <EmpContent
                            label={"REPRESENTATIVE"}
                            value={
                              <div className="agency-wrapper">
                                {creatorAgencyInvitations.map((elem) => {
                                  return (
                                    <Fragment key={elem.id}>
                                      <div className="agency-card">
                                        <img
                                          className="agency-logo"
                                          srcSet={elem.agency.logo}
                                          alt={`${elem.agency.companyName} logo`}
                                        />
                                        <div className="agency-content">
                                          <div className="name-wrapper">
                                            <EmpLink
                                              onSubmit={async () => {
                                                navigate(
                                                  `/creator/agency/${elem.agency.id}`
                                                );
                                              }}
                                              text={elem.agency.companyName}
                                            />
                                          </div>
                                          <div className="mt-1">
                                            <EmpPill
                                              backgroundColor={
                                                Color.NEUTRAL[150]
                                              }
                                              color={Color.NEUTRAL[700]}
                                              text={recommendPartnershipStatus(
                                                elem.invitationType
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="social-media-section">
              {/* Social Media Selector */}

              <div className="emp-card no-padding social-media-selector-card social-card-border">
                {smCreatorBrief.map((elem, index) => {
                  return (
                    <Fragment key={elem.platform}>
                      {elem.isAvailable && (
                        <button
                          onClick={() => {
                            socialMediaOnClick(elem.platform);
                          }}
                          className={`emp-button-reset social-media-option ${
                            elem.platform === selectedPlatform ? "active" : ""
                          }`}
                        >
                          <div className={`picture-section`}>
                            <EmpImage
                              src={elem.pictureUrl}
                              alt={`${elem.name} ${elem.platform} dp`}
                              fallback={EMPTY_PROFILE_IMG_URL}
                            />
                            <div className="social-media-bubble">
                              {elem.platform === SOCIAL_MEDIA_TYPE.FACEBOOK && (
                                <FacebookIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform ===
                                SOCIAL_MEDIA_TYPE.INSTAGRAM && (
                                <InstagramIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.TIKTOK && (
                                <TikTokIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.X && (
                                <XIcon
                                  backgroundColor={Color.NEUTRAL[0]}
                                  size={12}
                                />
                              )}
                            </div>
                          </div>
                          <div className="info-section">
                            <span className="handle-name-lbl">{elem.name}</span>
                            <span className="metric-lbl">
                              <FormattedMessage id="creatorDetailPage_socialMediaFollowers" />
                              : {FormFieldUtils.toCompact(elem.followers)}
                            </span>
                          </div>
                        </button>
                      )}
                      {!elem.isAvailable && (
                        <div key={elem.platform} className={`empty-option`}>
                          <div className={`picture-section`}>
                            <div className="placeholder">
                              {elem.platform === "Facebook" && (
                                <FacebookIcon
                                  backgroundColor={Color.NEUTRAL[500]}
                                  size={20}
                                />
                              )}
                              {elem.platform === "Instagram" && (
                                <InstagramIcon
                                  backgroundColor={Color.NEUTRAL[500]}
                                  size={20}
                                />
                              )}
                              {elem.platform === "TikTok" && (
                                <TikTokIcon
                                  backgroundColor={Color.NEUTRAL[500]}
                                  size={20}
                                />
                              )}
                              {elem.platform === SOCIAL_MEDIA_TYPE.X && (
                                <XIcon
                                  backgroundColor={Color.NEUTRAL[500]}
                                  size={20}
                                />
                              )}
                            </div>
                          </div>
                          <div className="info-section">
                            <span className="handle-name-lbl">
                              {elem.platform}
                            </span>
                            <span className="metric-lbl">
                              <FormattedMessage id="creatorDetailPage_socialMediaNotConnected" />
                            </span>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              {selectedPlatform === SOCIAL_MEDIA_TYPE.FACEBOOK && creatorId && (
                <FacebookView id={creatorId} />
              )}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.INSTAGRAM &&
                creatorId && <InstagramView id={creatorId} />}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.TIKTOK && creatorId && (
                <TiktokView id={creatorId} />
              )}
              {selectedPlatform === SOCIAL_MEDIA_TYPE.X && creatorId && (
                <XView id={creatorId} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
