import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RateCardRequestDto } from "../../../model/social-media/rate-card-request.dto";
import EmpButton from "../emp-button/emp-button";
import EmpContent from "../emp-content/emp-content";
import EmpLink from "../emp-link/emp-link";
import "./emp-rate-cards-request-card.scss";

interface Props {
  cardWidth?: number;
  mode?: "default" | "skeleton" | "loading";
  data?: RateCardRequestDto;
  onClick?: () => void;

  onAccept?: () => void;
  onReject?: () => void;
}

export const RateCardRequestCard = (props: Props) => {
  const navigate = useNavigate();

  const mode = props.mode ?? "default";
  const data = useRef<RateCardRequestDto | undefined>(props.data ?? undefined);

  // const [isReady, setReady] = useState(false);

  // useEffect(() => {
  //     setReady(true)
  // }, [])

  return (
    <div className="emp-rate-card-request-card">
      {mode === "default" && data.current && (
        <div className="card-base rate-card-request-card emp-card no-padding">
          <img
            className="brand-img"
            alt="Requestor"
            src={data.current.brand.logo}
          />
          <div className="info-wrapper">
            {/* <div className="header-wrapper"> */}
            <span className="header">
              {data.current.brand.companyName} requested to view your rate cards
            </span>
            {/* </div> */}
            <div className="metrics-wrapper mt-2">
              <EmpContent
                orientation="horizontal"
                label={"CREATOR"}
                value={
                  <div className="creator-content">
                    {data.current.creator.imageType === "url" && (
                      <img
                        alt={data.current.creator.fullName}
                        className="user-profile"
                        src={data.current.creator.imageResource}
                      />
                    )}
                    {data.current.creator.imageType === "avatar" && (
                      <div
                        className="user-profile"
                        style={{
                          background: data.current.creator.imageResource,
                        }}
                      >
                        <span className="initials">
                          {data.current.creator.initials}
                        </span>
                      </div>
                    )}
                    <EmpLink
                      text={data.current.creator.fullName}
                      onSubmit={() =>
                        navigate(
                          `/agency/creator-details/${data.current?.creatorId}`
                        )
                      }
                    />
                  </div>
                }
              />

              <EmpContent
                orientation="horizontal"
                label={"RECRUITING TASKS"}
                value={
                  <>
                    {data.current.recruitingTasks.map((elem, index) => {
                      if (index < 3)
                        return (
                          <Fragment key={elem.taskId}>
                            <EmpLink
                              text={elem.taskName}
                              onSubmit={() =>
                                navigate(
                                  `/agency/campaign-details/${elem.campaignId}/task/${elem.taskId}`
                                )
                              }
                            />
                            {index < 2 && <span>, </span>}
                          </Fragment>
                        );
                      else return <></>;
                    })}
                    {data.current.recruitingTasks.length > 3 && (
                      <span>
                        {" "}
                        and {data.current.recruitingTasks.length - 3} more.
                      </span>
                    )}
                  </>
                }
              />
            </div>
          </div>
          <div className="btn-wrapper">
            <EmpButton
              buttonHeight="sm"
              text={"Approve"}
              onSubmit={() => {
                if (props.onAccept) props.onAccept();
              }}
            />
            <EmpButton
              buttonHeight="sm"
              text={"Reject"}
              onSubmit={() => {
                if (props.onReject) props.onReject();
              }}
              buttonStyle="danger"
            />
          </div>
        </div>
      )}
      {mode === "skeleton" && (
        <div className="card-base rate-card-skeleton"></div>
      )}
    </div>
  );
};
