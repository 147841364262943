import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NOTIFICATION_RECORD } from "../../../constants/app.constants";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onView: () => void;
  onRefresh: () => void;
}

type TaskInvitationMetadata = {
  campaignId: string;
  taskId: string;
  negotiationId: string;
  representativeRole: string;
  creatorUserId: string;
};
export const RecruitingTaskEventNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = props;

  const userRoleRef = useRef<string>("");
  // const [negotiationObj, setNegotiationObj] = useState<NegotiationExtendedDto>();

  // const [isLoading, setLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  const viewOnClick = async () => {
    if (notification.event === NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE) {
      navigate(
        `/brand/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}`
      );
    } else {
      navigate(
        `/${userRoleRef.current}/campaign-details/${notification.metadata.campaignId}/task/${notification.metadata.taskId}`
      );
    }
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  const creatorOnClick = () => {
    navigate(
      `/${userRoleRef.current}/creator-details/${notification.metadata.creatorUserId}`
    );
  };

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* {isLoading && <div className="loader">
            <div className="emp-spinner"></div>
        </div>} */}

      {/* ============================ NOTIFICATION DISPLAY IMG ================================== */}
      {/* ----------------------- Notification Type : Task Application --------------------------- */}
      {notification.event ===
        NOTIFICATION_RECORD.RECRUITING_TASK_APPLICATION && (
        <div className="organisation-img-wrapper">
          {/* Mini-Badge Image for creator if representative is Agency */}
          {notification.metadata.representativeRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType === "url" && (
              <img
                alt={notification.sender.senderName}
                className="badge-img small-profile-badge"
                src={notification.metadata.creatorRepresentative.imageResource}
              />
            )}
          {notification.metadata.representativeRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType ===
              "avatar" && (
              <div
                style={{
                  background:
                    notification.metadata.creatorRepresentative.imageResource,
                }}
                className="badge-avatar small-profile-badge"
              >
                <span className="initials">
                  {notification.metadata.creatorRepresentative.initials}
                </span>
              </div>
            )}

          {/* The organisation or user image himself */}
          {notification.metadata.representativeRole === "agency" && (
            <img
              alt={notification.metadata.agencyRepresentative.companyName}
              className="organisation-img"
              src={notification.metadata.agencyRepresentative.logo}
            />
          )}
          {notification.metadata.representativeRole === "creator" && (
            <>
              {notification.metadata.creatorRepresentative.imageType ===
                "url" && (
                <img
                  alt={notification.metadata.creatorRepresentative.fullName}
                  className="user-img"
                  src={
                    notification.metadata.creatorRepresentative.imageResource
                  }
                />
              )}
              {notification.metadata.creatorRepresentative.imageType ===
                "avatar" && (
                <div
                  style={{
                    background:
                      notification.metadata.creatorRepresentative.imageResource,
                  }}
                  className="user-avatar"
                >
                  <span className="initials">
                    {notification.metadata.creatorRepresentative.initials}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* ----------------------- Notification Type : Task Invitation --------------------------- */}
      {notification.event ===
        NOTIFICATION_RECORD.RECRUITING_TASK_INVITATION && (
        <div className="organisation-img-wrapper">
          {/* Mini-Badge Image for creator if representative is Agency */}
          {notification.metadata.representativeRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType === "url" && (
              <img
                alt={notification.sender.senderName}
                className="badge-img small-profile-badge"
                src={notification.metadata.creatorRepresentative.imageResource}
              />
            )}
          {notification.metadata.representativeRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType ===
              "avatar" && (
              <div
                style={{
                  background:
                    notification.metadata.creatorRepresentative.imageResource,
                }}
                className="badge-avatar small-profile-badge"
              >
                <span className="initials">
                  {notification.metadata.creatorRepresentative.initials}
                </span>
              </div>
            )}

          {/* The Brand Logo Image */}
          <img
            alt={notification.metadata.brand.companyName}
            className="organisation-img"
            src={notification.metadata.brand.logo}
          />
        </div>
      )}

      {/* ----------------------- Notification Type : Task Counteroffer, Task Acceptance and Task Rejection, Review Deliverables --------------------------- */}
      {[
        NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER,
        NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE,
        NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION,
        NOTIFICATION_RECORD.RECRUITING_REVIEW_DELIVERABLES,
      ].includes(notification.event) && (
        <div className="organisation-img-wrapper">
          {/* Mini-Badge Image for creator if representative is Agency */}
          {notification.sender.senderRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType === "url" && (
              <img
                alt={notification.sender.senderName}
                className="badge-img small-profile-badge"
                src={notification.metadata.creatorRepresentative.imageResource}
              />
            )}
          {notification.sender.senderRole === "agency" &&
            notification.metadata.creatorRepresentative.imageType ===
              "avatar" && (
              <div
                style={{
                  background:
                    notification.metadata.creatorRepresentative.imageResource,
                }}
                className="badge-avatar small-profile-badge"
              >
                <span className="initials">
                  {notification.metadata.creatorRepresentative.initials}
                </span>
              </div>
            )}
          {/* The Agency Logo Image */}
          {notification.sender.senderRole === "agency" && (
            <img
              alt={notification.metadata.agencyRepresentative.companyName}
              className="organisation-img"
              src={notification.metadata.agencyRepresentative.logo}
            />
          )}

          {/* The Brand Logo Image */}
          {notification.sender.senderRole === "brand" && (
            <img
              alt={notification.metadata.brand.companyName}
              className="organisation-img"
              src={notification.metadata.brand.logo}
            />
          )}
          {notification.sender.senderRole === "brand" &&
            notification.metadata.creatorRepresentative.imageType === "url" && (
              <img
                alt={notification.sender.senderName}
                className="badge-img small-profile-badge"
                src={notification.metadata.creatorRepresentative.imageResource}
              />
            )}
          {notification.sender.senderRole === "brand" &&
            notification.metadata.creatorRepresentative.imageType ===
              "avatar" && (
              <div
                style={{
                  background:
                    notification.metadata.creatorRepresentative.imageResource,
                }}
                className="badge-avatar small-profile-badge"
              >
                <span className="initials">
                  {notification.metadata.creatorRepresentative.initials}
                </span>
              </div>
            )}
          {/* The Creator Profile Image  */}
          {notification.sender.senderRole === "creator" && (
            <>
              {notification.metadata.creatorRepresentative.imageType ===
                "url" && (
                <img
                  alt={notification.metadata.creatorRepresentative.fullName}
                  className="user-img"
                  src={
                    notification.metadata.creatorRepresentative.imageResource
                  }
                />
              )}
              {notification.metadata.creatorRepresentative.imageType ===
                "avatar" && (
                <div
                  style={{
                    background:
                      notification.metadata.creatorRepresentative.imageResource,
                  }}
                  className="user-avatar"
                >
                  <span className="initials">
                    {notification.metadata.creatorRepresentative.initials}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* ----------------------- Notification Type : Task Started --------------------------- */}
      {notification.event === NOTIFICATION_RECORD.RECRUITING_TASK_START && (
        <div className="organisation-img-wrapper">
          {/* The Brand Logo Image */}
          <img
            alt={notification.metadata.brand.companyName}
            className="organisation-img"
            src={notification.metadata.brand.logo}
          />
        </div>
      )}

      <div className="notification-content">
        <div className="content-wrapper">
          {/* Notification Record Type = task-invitation */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_INVITATION &&
            notification.metadata.representativeRole === "creator" && (
              <span className="info-text">
                You have been invited to join task:{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_INVITATION &&
            notification.metadata.representativeRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                has been invited to join task:{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}

          {/* Notification Record Type = task-application */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_APPLICATION &&
            notification.metadata.representativeRole === "creator" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                has applied to join your task:{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_APPLICATION &&
            notification.metadata.representativeRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.agencyRepresentative.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has applied on behalf of{" "}
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                to join your task:{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}

          {/* Notification Record Type = task-application */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER &&
            notification.sender.senderRole === "creator" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                has raised a counteroffer of {notification.metadata.amount}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />
                for{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER &&
            notification.sender.senderRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.agencyRepresentative.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has raised a counteroffer on behalf of{" "}
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                of {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />{" "}
                to join your task:{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "creator" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.brand.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has raised a counteroffer of {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />{" "}
                for{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_COUNTEROFFER &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.brand.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has raised a counteroffer of {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />{" "}
                for{" "}
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                to join{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />
              </span>
            )}

          {/* Notification Record Type = task-acceptance */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE &&
            notification.sender.senderRole === "creator" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                has accepted the offer to join task{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />{" "}
                for {notification.metadata.amount}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />
                .
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE &&
            notification.sender.senderRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.agencyRepresentative.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has accepted to join task{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />{" "}
                on behalf of{" "}
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                for {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />
                .
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "creator" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.brand.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has accepted you to join{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />{" "}
                for {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />
              </span>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_ACCEPTANCE &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "agency" && (
              <span className="info-text">
                <EmpLink
                  text={notification.metadata.brand.companyName}
                  onSubmit={creatorOnClick}
                />{" "}
                has accepted{" "}
                <EmpLink
                  text={notification.metadata.creatorRepresentative.fullName}
                  onSubmit={creatorOnClick}
                />{" "}
                to join{" "}
                <EmpLink
                  onSubmit={viewOnClick}
                  text={notification.metadata.taskName}
                />{" "}
                for {notification.metadata.amount}{" "}
                <EmpCoinIcon
                  top={2}
                  className="ml-1"
                  iconValue={notification.metadata.paymentMode}
                  mode="icon-only"
                />
              </span>
            )}

          {/* Notification Record Type = task-rejection */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION &&
            notification.sender.senderRole === "creator" && (
              <div className="notification-text-wrapper">
                <span className="info-text">
                  <EmpLink
                    text={notification.metadata.creatorRepresentative.fullName}
                    onSubmit={creatorOnClick}
                  />{" "}
                  has rejected the offer to join task{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />{" "}
                  for {notification.metadata.amount}{" "}
                  <EmpCoinIcon
                    top={2}
                    className="ml-1"
                    iconValue={notification.metadata.paymentMode}
                    mode="icon-only"
                  />
                  .
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION &&
            notification.sender.senderRole === "agency" && (
              <div className="notification-text-wrapper">
                <span className="info-text">
                  <EmpLink
                    text={
                      notification.metadata.agencyRepresentative.companyName
                    }
                    onSubmit={creatorOnClick}
                  />{" "}
                  has rejected to join task{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />{" "}
                  on behalf of{" "}
                  <EmpLink
                    text={notification.metadata.creatorRepresentative.fullName}
                    onSubmit={creatorOnClick}
                  />{" "}
                  for {notification.metadata.amount}{" "}
                  <EmpCoinIcon
                    top={2}
                    className="ml-1"
                    iconValue={notification.metadata.paymentMode}
                    mode="icon-only"
                  />
                  .
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "creator" && (
              <div className="notification-text-wrapper">
                <span className="info-text">
                  <EmpLink
                    text={notification.metadata.brand.companyName}
                    onSubmit={creatorOnClick}
                  />{" "}
                  has rejected you to join{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />{" "}
                  for {notification.metadata.amount}{" "}
                  <EmpCoinIcon
                    top={2}
                    className="ml-1"
                    iconValue={notification.metadata.paymentMode}
                    mode="icon-only"
                  />
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_TASK_REJECTION &&
            notification.sender.senderRole === "brand" &&
            notification.metadata.representativeRole === "agency" && (
              <div className="notification-text-wrapper">
                <span className="info-text">
                  <EmpLink
                    text={notification.metadata.brand.companyName}
                    onSubmit={creatorOnClick}
                  />{" "}
                  has rejected{" "}
                  <EmpLink
                    text={notification.metadata.creatorRepresentative.fullName}
                    onSubmit={creatorOnClick}
                  />{" "}
                  to join{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />{" "}
                  for {notification.metadata.amount}{" "}
                  <EmpCoinIcon
                    top={2}
                    className="ml-1"
                    iconValue={notification.metadata.paymentMode}
                    mode="icon-only"
                  />
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}

          {/* Notification Record Type = task-start */}
          {notification.event === NOTIFICATION_RECORD.RECRUITING_TASK_START && (
            <>
              {userRoleRef.current === "agency" && (
                <span className="info-text">
                  <span className="emp-highlighted">
                    {notification.sender.senderName}
                  </span>{" "}
                  has initiated task:{" "}
                  <span className="emp-highlighted">
                    {notification.metadata.taskName}
                  </span>
                  . You can now begin coordinating with your creators to fulfill
                  the deliverables.
                </span>
              )}
              {userRoleRef.current === "creator" &&
                notification.metadata.representativeRole === "agency" && (
                  <span className="info-text">
                    <span className="emp-highlighted">
                      {notification.sender.senderName}
                    </span>{" "}
                    has initiated task:{" "}
                    <span className="emp-highlighted">
                      {notification.metadata.taskName}
                    </span>
                    . Collaborate with{" "}
                    <span className="emp-highlighted">
                      {notification.metadata.agencyRepresentative.companyName}
                    </span>{" "}
                    to complete the deliverables.
                  </span>
                )}
              {userRoleRef.current === "creator" &&
                notification.metadata.representativeRole === "creator" && (
                  <span className="info-text">
                    <span className="emp-highlighted">
                      {notification.sender.senderName}
                    </span>{" "}
                    has initiated task:{" "}
                    <span className="emp-highlighted">
                      {notification.metadata.taskName}
                    </span>
                    . To qualify for the payouts, please ensure all deliverables
                    are completed.
                  </span>
                )}
              {userRoleRef.current === "brand" && (
                <span className="info-text">
                  You have initiated task:{" "}
                  <span className="emp-highlighted">
                    {notification.metadata.taskName}
                  </span>
                  . You may start collaborating with the participants to
                  complete all deliverables
                </span>
              )}
            </>
          )}

          {/* Notification Record Type = review deliverables */}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_REVIEW_DELIVERABLES &&
            notification.metadata.representativeRole === "agency" && (
              <div className="notification-text-wrapper">
                <span className="info-text block">
                  <span className="emp-highlighted">
                    {notification.metadata.brand.companyName}
                  </span>{" "}
                  has made updates to the deliverables for{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />
                  . Please review the changes for{" "}
                  <span className="emp-highlighted">
                    {notification.metadata.creatorRepresentative.fullName}
                  </span>{" "}
                  before continuing with the task.
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}
          {notification.event ===
            NOTIFICATION_RECORD.RECRUITING_REVIEW_DELIVERABLES &&
            notification.metadata.representativeRole === "creator" && (
              <div className="notification-text-wrapper">
                <span className="info-text">
                  <span className="emp-highlighted">
                    {notification.metadata.brand.companyName}
                  </span>{" "}
                  has made updates to the deliverables for{" "}
                  <EmpLink
                    onSubmit={viewOnClick}
                    text={notification.metadata.taskName}
                  />
                  . Please review the changes before continuing with the task.
                </span>
                {notification.metadata.remarks &&
                  notification.metadata.remarks.length > 0 && (
                    <>
                      <span className="content-label mt-2">REMARKS</span>
                      <span className="info-text block">
                        {notification.metadata.remarks}
                      </span>
                    </>
                  )}
              </div>
            )}

          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>

        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(
            notification.createdAt.toString()
          )}`}</span>

          <div className="button-wrapper">
            {notification.metadata.state === "valid" && (
              <>
                <EmpLink text={"View"} onSubmit={viewOnClick} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
