import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import { NEGOTIATION_STATUS } from "../../../constants/app.constants";
import EmpException from "../../../exception/empException";
import { AcceptTaskDto } from "../../../model/campaign/accept-task.dto";
import { CounterofferDto } from "../../../model/campaign/counteroffer.dto";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import EmpButton from "../../shared/emp-button/emp-button";
import EmpContent from "../../shared/emp-content/emp-content";
import EmpDropdownBtn, {
  MenuButtonSpec,
} from "../../shared/emp-dropdown-btn/emp-dropdown-btn";
import EmpLink from "../../shared/emp-link/emp-link";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import EmpPill from "../../shared/EmpPill/EmpPill";
import "../invite-task-modal.scss";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "../task-deliverable-modal";
import TaskResponseRemarksModal, {
  TaskResponseRemarksModalRef,
} from "../task-response-remarks-modal";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";
import FormFieldUtils from "../../../utilities/form-field.util";

interface Props {
  task: TaskDto;
  latestNegotiations: LatestNegotiationExtendedDto[];
  onComplete: () => void;
  context: string;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

type ModalViewMode = "selection" | "negotiation";
const MultiTaskCounterofferModalView = (props: Props) => {
  const getDropdownMenuButton = useCallback(
    (
      elem: LatestNegotiationExtendedDto,
      type: "accept" | "negotiate" | "reject",
      state: "disabled" | "primary" | "danger" = "primary"
    ): MenuButtonSpec<LatestNegotiationExtendedDto> => {
      if (type === "accept") {
        return {
          action: (record: LatestNegotiationExtendedDto) => acceptTask(record),
          context: elem,
          label: "Accept",
          tooltip:
            state === "disabled"
              ? "Cannot accept due to changes in deliverables after the creator has applied"
              : undefined,
          buttonStyle: state,
        };
      } else if (type === "negotiate") {
        return {
          action: (record: LatestNegotiationExtendedDto) =>
            toNegotiationMode(record),
          context: elem,
          label: "Negotiate",
          buttonStyle: state,
        };
      } else
        return {
          action: (record: LatestNegotiationExtendedDto) => {
            taskResponseRemarksModalRef.current?.show(
              contextRef.current!,
              "reject",
              record
            );
          },
          context: elem,
          label: "Reject",
          buttonStyle: state,
        };
    },
    []
  );

  const { task, onComplete } = props;

  const [latestNegotiations, setLatestNegotiations] = useState<
    LatestNegotiationExtendedDto[]
  >(props.latestNegotiations);
  const representativeIdsRef = useRef<string[]>(
    props.latestNegotiations.map(
      (elem) => `${elem.representativeId}_${elem.creatorUserId}`
    )
  );

  // ONLY ONE TYPE NOTIFICATION WILL BE STREAMED IN HERE.
  const counterofferPartyRef = useRef<"brand" | "seller">(
    props.latestNegotiations[0].status === NEGOTIATION_STATUS.BRAND_NEGOTIATE
      ? "brand"
      : "seller"
  );

  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();
  const taskResponseRemarksModalRef = useRef<TaskResponseRemarksModalRef>();
  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;
  const [btnLoading, setBtnLoading] = useState(false);

  const [modalViewMode, setModalViewMode] =
    useState<ModalViewMode>("selection");
  const [renegotiationObject, setRenegotiationObject] =
    useState<LatestNegotiationExtendedDto>();

  const contextRef = useRef<"brand" | "seller">();
  const location = useLocation();
  useEffect(() => {
    const role = location.pathname.split("/")[1];
    if (role === "brand") contextRef.current = "brand";
    else if (role === "creator" || "agency") contextRef.current = "seller";
  }, [location]);

  const refetchLatestNegotiation = async () => {
    try {
      setBtnLoading(true);
      let resp = undefined;
      if (counterofferPartyRef.current === "brand") {
        const agencyId = latestNegotiations[0].agencyOrgId;
        resp = await NegotiationApi.getLatestNegotiationByRepresentative(
          agencyId,
          task.campaign.id,
          task.id
        );
      } else if (counterofferPartyRef.current === "seller") {
        const brandOrgId = latestNegotiations[0].brandOrgId;
        resp = await NegotiationApi.getLatestNegotiationByBrandOrgId(
          brandOrgId,
          task.campaign.id,
          task.id
        );
      } else throw new EmpException("Invalid counteroffer party");
      // Only pick out negotiation objects that made it into this view in the first place.
      const newNegotiations = resp.data.latestNegotiation.filter((elem) =>
        representativeIdsRef.current.includes(
          `${elem.representativeId}_${elem.creatorUserId}`
        )
      );
      setLatestNegotiations(newNegotiations);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch seller latest negotiation"
      );
    } finally {
      setBtnLoading(false);
    }
  };

  /**
   * Accepts the task based on the latest negotiation details.
   *
   * @returns {Promise<void>} - A promise that resolves when the task is accepted.
   * @throws {Error} - If an error occurs while accepting the task.
   */
  const acceptTask = async (
    latestNegotiation: LatestNegotiationExtendedDto
  ) => {
    try {
      setBtnLoading(true);
      if (!contextRef.current)
        throw new EmpException("context is required", "Invalid State");
      const request: AcceptTaskDto = {
        role: contextRef.current,
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: Number(latestNegotiation.amount),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: "",
      };
      const resp = await NegotiationApi.acceptTask(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Accepted!", "You have accepted this application.");
        refetchLatestNegotiation();
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error accepting task application"
      );
      setBtnLoading(false);
    }
  };

  const toNegotiationMode = (
    latestNegotiation: LatestNegotiationExtendedDto
  ) => {
    setRenegotiationObject(latestNegotiation);
    setModalViewMode("negotiation");
  };

  const [form, setForm] = useState<IFormGroup>({
    amount: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const submitCounteroffer = async () => {
    try {
      if (!renegotiationObject)
        throw new EmpException("No negotiation object found");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid || !contextRef.current) return;
      setBtnLoading(true);
      const request: CounterofferDto = {
        brandOrgId: renegotiationObject.brandOrgId,
        creatorUserId: renegotiationObject.creatorUserId,
        agencyOrgId: renegotiationObject.agencyOrgId,
        representativeId: renegotiationObject.representativeId,
        representativeRole: renegotiationObject.representativeRole,
        amount: form.amount.getValue(),
        campaignId: renegotiationObject.campaignId,
        taskId: renegotiationObject.taskId,
        remarks: form.remarks.getValue(),
      };

      let resp;
      if (contextRef.current === "brand") {
        resp = await NegotiationApi.sendBrandCounteroffer(request);
      } else {
        resp = await NegotiationApi.sendSellerCounteroffer(request);
      }
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Counteroffer Submitted",
          "The brand has received your counter offer."
        );
        setModalViewMode("selection");
        setRenegotiationObject(undefined);
        refetchLatestNegotiation();
        onComplete();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error rejecting task application"
      );
      setBtnLoading(false);
    }
  };
  return (
    <div>
      <TaskResponseRemarksModal
        ref={taskResponseRemarksModalRef}
        onSave={() => {
          refetchLatestNegotiation();
          onComplete();
        }}
      />
      <TaskDeliverableModal ref={taskDeliverableModalRef} />
      <div className="view-header-wrapper">
        <span className="view-header shift-left">Your Counteroffers</span>
      </div>

      {/* Selection Mode */}
      {modalViewMode === "selection" && (
        <>
          {latestNegotiations[0].status ===
            NEGOTIATION_STATUS.BRAND_NEGOTIATE && (
            <p className="emp-paragraph mt-3">
              The brand has responded with multiple counteroffers. You now have
              the option to accept, reject, or engage in further counteroffers.
              For further details about the task deliverables, please click{" "}
              <EmpLink
                text={"here"}
                onSubmit={() => {
                  taskDeliverableModalRef.current?.show(task);
                }}
              />
              .
            </p>
          )}
          {latestNegotiations[0].status ===
            NEGOTIATION_STATUS.SELLER_NEGOTIATE &&
            latestNegotiations[0].representativeRole === "agency" && (
              <p className="emp-paragraph mt-3">
                The Agency has responded with multiple counteroffers. You now
                have the option to accept, reject, or engage in further
                counteroffers. For further details about the task deliverables,
                please click{" "}
                <EmpLink
                  text={"here"}
                  onSubmit={() => {
                    taskDeliverableModalRef.current?.show(task);
                  }}
                />
                .
              </p>
            )}
          <div className="multi-response-view">
            <div className="response-section mt-4">
              {latestNegotiations.map((elem) => {
                return (
                  <div key={elem.id} className={`response-form-row`}>
                    <div className="relative-wrapper">
                      {elem.creatorRepresentative.imageType === "url" && (
                        <img
                          className={`profile-photo`}
                          alt={elem.creatorRepresentative.fullName}
                          src={elem.creatorRepresentative.imageResource}
                        />
                      )}
                      {elem.creatorRepresentative.imageType === "avatar" && (
                        <div
                          className={`avatar`}
                          style={{
                            background:
                              elem.creatorRepresentative.imageResource,
                          }}
                        >
                          <span className="initials">
                            {elem.creatorRepresentative.initials}
                          </span>
                        </div>
                      )}

                      {elem.representativeRole === "agency" && (
                        <div className="absolute-wrapper">
                          <img
                            className={`profile-photo org-photo`}
                            alt={elem.agencyRepresentative.companyName}
                            src={elem.agencyRepresentative.logo}
                          />
                        </div>
                      )}
                    </div>

                    <div className="details-section">
                      <div className="rep-name-section">
                        <span className="rep-name-lbl">
                          {elem.creatorRepresentative.fullName}
                        </span>
                        <EmpPill
                          backgroundColor={Color.PRIMARY[100]}
                          color={Color.PRIMARY[600]}
                          text={
                            elem.representativeRole === "agency"
                              ? "Represented by Agency"
                              : "Creator"
                          }
                        />
                      </div>
                      <div className="rate-section mt-1">
                        <span className="rate-lbl">
                          Offer: {elem.amount}
                          <EmpCoinIcon iconValue={task.paymentMode} />
                        </span>
                      </div>
                      {elem.status === NEGOTIATION_STATUS.APPLIED &&
                        elem.remarks &&
                        elem.remarks.length > 0 && (
                          <p className="emp-paragraph rep-remarks mt-1">
                            "{elem.remarks}"
                          </p>
                        )}
                    </div>
                    <div className="form-section">
                      {[
                        NEGOTIATION_STATUS.DUP_REJECT,
                        NEGOTIATION_STATUS.BRAND_REJECT,
                        NEGOTIATION_STATUS.SELLER_REJECT,
                      ].includes(elem.status) && (
                        <EmpPill
                          text={"Rejected"}
                          backgroundColor={Color.RED[100]}
                          color={Color.RED[600]}
                        />
                      )}
                      {[
                        NEGOTIATION_STATUS.BRAND_ACCEPT,
                        NEGOTIATION_STATUS.SELLER_ACCEPT,
                      ].includes(elem.status) && (
                        <EmpPill
                          text={"Accepted"}
                          backgroundColor={Color.PRIMARY[100]}
                          color={Color.PRIMARY[600]}
                        />
                      )}
                      {counterofferPartyRef.current === "seller" &&
                        elem.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE && (
                          <EmpPill
                            text={"Counteroffer Sent"}
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                          />
                        )}
                      {counterofferPartyRef.current === "brand" &&
                        elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE && (
                          <EmpPill
                            text={"Counteroffer Sent"}
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                          />
                        )}
                      {counterofferPartyRef.current === "brand" &&
                        elem.status === NEGOTIATION_STATUS.BRAND_NEGOTIATE && (
                          <EmpDropdownBtn
                            isLoading={btnLoading}
                            text={"Respond"}
                            menuButtonSpecs={[
                              {
                                action: (record) => acceptTask(record),
                                context: elem,
                                label: "Accept",
                              },
                              {
                                action: (record) => toNegotiationMode(record),
                                context: elem,
                                label: "Negotiate",
                              },
                              {
                                action: (
                                  record: LatestNegotiationExtendedDto
                                ) => {
                                  taskResponseRemarksModalRef.current?.show(
                                    contextRef.current!,
                                    "reject",
                                    record
                                  );
                                },
                                context: elem,
                                label: "Reject",
                                buttonStyle: "danger",
                              },
                            ]}
                          />
                        )}
                      {counterofferPartyRef.current === "seller" &&
                        elem.status === NEGOTIATION_STATUS.SELLER_NEGOTIATE && (
                          <EmpDropdownBtn
                            text={"Respond"}
                            menuButtonSpecs={
                              task.version === elem.negoTaskVersion
                                ? [
                                    getDropdownMenuButton(
                                      elem,
                                      "accept",
                                      "primary"
                                    ),
                                    getDropdownMenuButton(
                                      elem,
                                      "negotiate",
                                      "primary"
                                    ),
                                    getDropdownMenuButton(
                                      elem,
                                      "reject",
                                      "danger"
                                    ),
                                  ]
                                : [
                                    getDropdownMenuButton(
                                      elem,
                                      "accept",
                                      "disabled"
                                    ),
                                    getDropdownMenuButton(
                                      elem,
                                      "negotiate",
                                      "primary"
                                    ),
                                    getDropdownMenuButton(
                                      elem,
                                      "reject",
                                      "danger"
                                    ),
                                  ]
                            }
                          />
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* Negotiation Mode */}
      {modalViewMode === "negotiation" && renegotiationObject && (
        <div className="single-response-view">
          <TaskDeliverableModal ref={taskDeliverableModalRef} />
          <section className="profile-photo-section">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 1 * animationDelay,
              }}
              className="profile-photo-wrapper"
            >
              <div className="creator-rep-wrapper">
                <div className="relative-wrapper">
                  <div className="creator-profile">
                    {renegotiationObject.creatorRepresentative.imageType ===
                      "url" && (
                      <img
                        alt="Profile"
                        className="profile-photo"
                        src={
                          renegotiationObject.creatorRepresentative
                            .imageResource
                        }
                      />
                    )}
                    {renegotiationObject.creatorRepresentative.imageType ===
                      "none" && (
                      <img
                        alt="Empty profile"
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {renegotiationObject.creatorRepresentative.imageType ===
                      "avatar" && (
                      <div
                        style={{
                          background:
                            renegotiationObject.creatorRepresentative
                              .imageResource,
                        }}
                        className="avatar"
                      >
                        <span className="initials">
                          {renegotiationObject.creatorRepresentative.initials}
                        </span>
                      </div>
                    )}
                  </div>
                  {renegotiationObject.representativeRole === "agency" && (
                    <div className="absolute-wrapper">
                      <img
                        alt="Agency brand"
                        className="profile-photo org-photo"
                        src={renegotiationObject.agencyRepresentative.logo}
                      />
                    </div>
                  )}
                </div>
              </div>
              <span className="plus-label">+</span>
              <img
                alt="Profile"
                className="profile-photo org-photo"
                src={renegotiationObject.brand.logo}
              />
            </motion.div>
          </section>

          <div className="content-section">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 2 * animationDelay,
              }}
              className="task-name-lbl"
            >
              <span>
                Negotiate a new rate for{" "}
                {renegotiationObject.creatorRepresentative.fullName} to join the
                task.
              </span>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 3 * animationDelay,
              }}
              className="mt-4"
            >
              <EmpContent
                label={"BRAND'S OFFER"}
                value={
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 3 }}
                  >
                    {FormFieldUtils.formatNumber(
                      Number(renegotiationObject.amount)
                    )}
                    <EmpCoinIcon iconValue={task.paymentMode} />
                  </div>
                }
              />
            </motion.div>

            <motion.p
              variants={fadeInVariants}
              initial="hidden"
              animate={"visible"}
              transition={{
                duration: animationDuration,
                delay: 4 * animationDelay,
              }}
              className="mt-4"
            >
              <EmpContent
                label={"BRAND'S REMARKS"}
                value={renegotiationObject.remarks}
              />
            </motion.p>

            {modalViewMode === "negotiation" && (
              <div>
                <hr className="emp-hr" />
                <motion.div
                  variants={fadeInVariants}
                  className="mt-4"
                  initial="hidden"
                  animate={"visible"}
                  transition={{
                    duration: animationDuration,
                    delay: 5 * animationDelay,
                  }}
                >
                  <EmpTextInput
                    id={"rate"}
                    labelText="Your Counteroffer"
                    leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
                    leftIconSize={12}
                    description={`If the ${
                      contextRef.current === "brand"
                        ? "creator / agency"
                        : "brand"
                    } finds your offer agreeable, you'll be all set to join this campaign task.`}
                    required
                    formControl={form.amount}
                    placeholder={"00.00"}
                  />
                </motion.div>

                <motion.div
                  variants={fadeInVariants}
                  initial="hidden"
                  animate={"visible"}
                  className="mt-3"
                  transition={{
                    duration: animationDuration,
                    delay: 6 * animationDelay,
                  }}
                >
                  <EmpTextInput
                    id={"remarks"}
                    labelText="Remarks (Optional)"
                    multiline
                    textAreaAdaptiveHeight
                    tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
                    formControl={form.remarks}
                    rows={2}
                    characterCount={300}
                    placeholder={
                      "You may elaborate more about the scope of work, or any price justifications"
                    }
                  />
                </motion.div>
                <motion.div
                  variants={fadeInVariants}
                  initial="hidden"
                  animate={"visible"}
                  style={{
                    display: "flex",
                    gap: 8,
                    justifyContent: "flex-end",
                  }}
                  transition={{
                    duration: animationDuration,
                    delay: 7 * animationDelay,
                  }}
                >
                  <EmpButton
                    buttonStyle="secondary"
                    onSubmit={() => {
                      setRenegotiationObject(undefined);
                      setModalViewMode("selection");
                    }}
                    isFullWidth={false}
                    className="mt-4"
                    text={"Back"}
                  />

                  <EmpButton
                    className="mt-4"
                    onSubmit={submitCounteroffer}
                    isFullWidth={false}
                    buttonStyle="primary"
                    text={"Submit Counteroffer"}
                  />
                </motion.div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default MultiTaskCounterofferModalView;
