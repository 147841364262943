import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableProps,
  EmpTableRef,
  EmpTableEmptyRow,
} from "../shared/EmpTable/EmpTable";
import "./creator-listing-table.scss";
import EmpRoundedPill from "../shared/emp-rounded-pill/emp-rounded-pill";
import { Tooltip, Whisper } from "rsuite";
import EmpLink from "../shared/emp-link/emp-link";
import StringUtils from "../../utilities/string.util";
import EmpButton from "../shared/emp-button/emp-button";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { CreatorCardDto } from "../../model/user/creator-card.dto";
import FacebookIcon from "../icon/facebook-icon";
import { Color } from "../../utilities/colors";
import TikTokIcon from "../icon/tiktok-icon";
import InstagramIcon from "../icon/instagram-icon";
import { EmpImage } from "../shared/emp-image/emp-image";
import FormFieldUtils from "../../utilities/form-field.util";
import { useNavigate } from "react-router-dom";
import XIcon from "../icon/x-icon";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";
import EmptyRowUtils from "../../utilities/empty-row.util";

interface Props {
  rawRecords: CreatorCardDto[];
  hasRestriction: boolean;
  onClick?: (context: "profile" | "invite-btn", record: CreatorCardDto) => void;
  isLoading: boolean;
}

export const CreatorListingTable = (props: Props) => {
  const { rawRecords, isLoading, hasRestriction } = props;
  const [placeholderRecords, setPlaceholderRecords] = useState<
    EmpTableEmptyRow[]
  >([]);
  const empTableRef = useRef<EmpTableRef>();
  const [tableProps, setTableProps] = useState<EmpTableProps<CreatorCardDto>>();
  const [overlayHeight, setOverlayHeight] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasRestriction === true) {
      setPlaceholderRecords(EmptyRowUtils.getEmptyRow(3));
    } else {
      setPlaceholderRecords([]);
    }
  }, [hasRestriction]);

  useEffect(() => {
    const recordsHeight = empTableRef.current?.getRecordHeight(3, "bottom");
    console.log("Record Height", recordsHeight);
    setOverlayHeight(recordsHeight ?? 0);
  }, [tableProps]);

  useEffect(() => {
    console.log(placeholderRecords);
  });

  useEffect(() => {
    setTableProps({
      rowKey: "id",
      data: rawRecords,
      contentColumns: contentSpec,
    });
  }, [rawRecords]);

  const calculateTotalFollowers = useCallback((record: CreatorCardDto) => {
    const facebookFollowers = record.facebookMetrics?.followers ?? 0;
    const instagramFollowers = record.instagramMetrics?.followers ?? 0;
    const tiktokFollowers = record.tiktokMetrics?.followers ?? 0;
    const xFollowers = record.xMetrics?.followers ?? 0;
    return (
      facebookFollowers + instagramFollowers + tiktokFollowers + xFollowers
    );
  }, []);

  const calculateTotalImpressions = useCallback((record: CreatorCardDto) => {
    const facebookImpressions = record.facebookMetrics?.avgImpressions ?? 0;
    const instagramImpressions = record.instagramMetrics?.avgImpressions ?? 0;
    const tiktokImpressions = record.tiktokMetrics?.avgImpressions ?? 0;
    const xImpressions = record.xMetrics?.avgImpressions ?? 0;
    return (
      facebookImpressions +
      instagramImpressions +
      tiktokImpressions +
      xImpressions
    );
  }, []);

  const calculateAverageEngagementAcrossMultiplePlatform = useCallback(
    (record: CreatorCardDto) => {
      const metricKeys = ["facebookMetrics", "tiktokMetrics", "xMetrics"];
      const { total, count } = metricKeys.reduce(
        (acc, key) => {
          const metricKey = key as
            | "facebookMetrics"
            | "tiktokMetrics"
            | "xMetrics";
          const engagement = record[metricKey]?.avgEngagements;
          if (engagement !== undefined) {
            acc.total += engagement;
            acc.count += 1;
          }
          return acc;
        },
        { total: 0, count: 0 }
      );
      // Return the average or 0 if no valid metrics were found
      return count === 0 ? 0 : total / count;
    },
    []
  );

  // Table Column Content Specs
  const contentSpec: EmpTableContentSpec<CreatorCardDto>[] = useMemo(() => {
    const basicStructure = [
      {
        title: "Creator",
        dataIndex: "creator",
        render: (record: CreatorCardDto) => {
          let representative = <></>;
          if (record.representatives.length === 0)
            representative = <>Freelancer</>;
          else if (record.representatives.length > 0) {
            representative = (
              <div>
                {record.representatives.length === 1 && (
                  <>
                    Managed by{" "}
                    <EmpLink
                      text={record.representatives[0].companyName}
                      onSubmit={() => {
                        navigate(
                          `/brand/agency/${record.representatives[0].id}`
                        );
                      }}
                    />
                  </>
                )}
                {record.representatives.length > 1 && (
                  <>Managed by {`${record.representatives.length} Agencies`}</>
                )}
              </div>
            );
          }
          return (
            <div className="initiator-col">
              <div className="profile-wrapper">
                {record.imageType === "url" && (
                  <img
                    referrerPolicy="no-referrer"
                    className="profile"
                    alt={record.displayedName}
                    src={record.imageResource}
                  />
                )}
                {record.imageType === "avatar" && (
                  <div
                    className="profile"
                    style={{ background: record.imageResource }}
                  >
                    <span className="initials">{record.initials}</span>
                  </div>
                )}
              </div>
              <div>
                <div className="name-wrapper">
                  <EmpLink
                    text={record.displayedName}
                    onSubmit={() => {
                      props.onClick && props.onClick("profile", record);
                    }}
                  />
                  {record.country && (
                    <img
                      referrerPolicy="no-referrer"
                      className="img-flag"
                      alt="country"
                      src={COUNTRY_CONSTANTS[record.country]["flag_1x1"]}
                    />
                  )}
                </div>
                {representative}
              </div>
            </div>
          );
        },
      },
      {
        title: "Connected Accounts",
        dataIndex: "connectedAccounts",
        render: (record: CreatorCardDto) => {
          return (
            <div className="connected-accounts-wrapper">
              {record.facebookAccount && (
                <div className="account-wrapper">
                  <div className="platform-icon-wrapper">
                    <FacebookIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  </div>
                  <EmpImage
                    className="picture"
                    alt={record.facebookAccount.name}
                    src={record.facebookAccount.picture}
                    fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  />
                  <span className="account-name">
                    {record.facebookAccount.name}
                  </span>
                </div>
              )}
              {record.instagramAccount && (
                <div className="account-wrapper">
                  <div className="platform-icon-wrapper">
                    <InstagramIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  </div>
                  <EmpImage
                    className="picture"
                    alt={record.instagramAccount.name}
                    src={record.instagramAccount.picture}
                    fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  />
                  <span className="account-name">
                    {record.instagramAccount.name}
                  </span>
                </div>
              )}
              {record.tiktokAccount && (
                <div className="account-wrapper">
                  <div className="platform-icon-wrapper">
                    <TikTokIcon
                      size={14}
                      backgroundColor={Color.NEUTRAL[300]}
                    />
                  </div>
                  <EmpImage
                    className="picture"
                    alt={record.tiktokAccount.name}
                    src={record.tiktokAccount.picture}
                    fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  />
                  <span className="account-name">
                    {record.tiktokAccount.name}
                  </span>
                </div>
              )}
              {record.xAccount && (
                <div className="account-wrapper">
                  <div className="platform-icon-wrapper">
                    <XIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  </div>
                  <EmpImage
                    className="picture"
                    alt={record.xAccount.name}
                    src={record.xAccount.picture}
                    fallback="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                  />
                  <span className="account-name">{record.xAccount.name}</span>
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: "Gender",
        dataIndex: "gender",
        render: (record: CreatorCardDto) => {
          return <>{StringUtils.capitalizeWords(record.gender ?? "Not Set")}</>;
        },
      },
      {
        title: "Total Followers",
        dataIndex: "follower",
        render: (record: CreatorCardDto) => {
          return (
            <>
              {FormFieldUtils.toCompact(calculateTotalFollowers(record))}
              &nbsp;Followers
            </>
          );
        },
      },
      {
        title: "Total Impressions",
        dataIndex: "totalImpressions",
        render: (record: CreatorCardDto) => {
          return (
            <>{FormFieldUtils.toCompact(calculateTotalImpressions(record))}</>
          );
        },
      },
      {
        title: "Avg Engagement",
        dataIndex: "avgEngagement",
        render: (record: CreatorCardDto) => {
          return (
            <>
              {calculateAverageEngagementAcrossMultiplePlatform(record).toFixed(
                2
              )}
              %
            </>
          );
        },
      },
      {
        title: "Avg Reach",
        dataIndex: "avgReach",
        render: (record: CreatorCardDto) => {
          return (
            <>
              {FormFieldUtils.toCompact(record.instagramMetrics?.avgReach ?? 0)}
            </>
          );
        },
      },
      {
        title: "Services",
        dataIndex: "services",
        render: (record: CreatorCardDto) => {
          const numRenderedPills = 2;
          const pillsRemaining = record.services.length - numRenderedPills;
          const renderedInterest = record.services.slice(0, numRenderedPills);
          const tooltipContent = record.services
            .slice(numRenderedPills, record.services.length)
            .map((elem) => elem.label)
            .join(", ");

          return (
            <div className="pill-wrapper">
              {renderedInterest.map((tag, index) => {
                return (
                  <Fragment key={tag.id}>
                    {index < renderedInterest.length - 1 && (
                      <EmpRoundedPill
                        clickable={false}
                        key={tag.id}
                        text={tag.label}
                      />
                    )}
                    {index === renderedInterest.length - 1 && (
                      <div className="pill-h-wrapper">
                        <EmpRoundedPill
                          clickable={false}
                          key={tag.id}
                          text={tag.label}
                        />
                        {pillsRemaining > 0 && (
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>{tooltipContent}</Tooltip>}
                          >
                            <div className="no-wrap">
                              <EmpRoundedPill
                                clickable={false}
                                text={`+${pillsRemaining}`}
                              />
                            </div>
                          </Whisper>
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          );
        },
      },
      {
        title: "Interests",
        dataIndex: "interest",
        render: (record: CreatorCardDto) => {
          const numRenderedPills = 2;
          const pillsRemaining = record.interests.length - numRenderedPills;
          const renderedInterest = record.interests.slice(0, numRenderedPills);
          const tooltipContent = record.interests
            .slice(numRenderedPills, record.interests.length)
            .map((elem) => elem.label)
            .join(", ");

          return (
            <div className="pill-wrapper">
              {renderedInterest.map((tag, index) => {
                return (
                  <Fragment key={tag.id}>
                    {index < renderedInterest.length - 1 && (
                      <EmpRoundedPill
                        clickable={false}
                        key={tag.id}
                        text={tag.label}
                      />
                    )}
                    {index === renderedInterest.length - 1 && (
                      <div className="pill-h-wrapper">
                        <EmpRoundedPill
                          clickable={false}
                          key={tag.id}
                          text={tag.label}
                        />
                        {pillsRemaining > 0 && (
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={<Tooltip>{tooltipContent}</Tooltip>}
                          >
                            <div className="no-wrap">
                              <EmpRoundedPill
                                clickable={false}
                                text={`+${pillsRemaining}`}
                              />
                            </div>
                          </Whisper>
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (record: CreatorCardDto) => {
          return (
            <EmpButton
              isFullWidth={false}
              onSubmit={() => {
                props.onClick && props.onClick("profile", record);
              }}
              buttonHeight="sm"
              text={"View"}
            />
          );
        },
      },
    ];
    return basicStructure;
  }, []);

  return (
    <div className="emp-creator-listing-table">
      {tableProps && (
        <>
          <EmpTable
            ref={empTableRef}
            style={{ width: "100%" }}
            contentColumns={tableProps.contentColumns}
            loading={isLoading}
            showEmptyState={false}
            shimmerLoading={{
              width: [120, 100, 60, 80, 50, 40, 50, 80, 120, 80],
              overlayDesign: <></>,
            }}
            data={
              [...tableProps.data, ...placeholderRecords] as (
                | CreatorCardDto
                | EmpTableEmptyRow
              )[]
            }
            rowKey={tableProps.rowKey}
          />
          {hasRestriction && (
            <div
              className="restriction-overlay"
              style={{ height: overlayHeight }}
            >
              <h3 className="title">Feature Locked!</h3>
              <p className="emp-paragraph mt-3">
                To view all creator profiles on Emplifive, please upgrade your
                free plan.
              </p>
              <EmpButton
                isFullWidth={false}
                className="mt-5"
                text={"View Plans"}
                onSubmit={() => {
                  PubSub.publish(PUB_SUB_TOPICS.VIEW_PLANS);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
