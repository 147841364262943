import { IntlShape } from "react-intl";

export class PasswordUtil {
  static validatePasswordInput(
    intl: IntlShape,
    password: string,
    confirmPassword: string
  ) {
    const containsADigitRegex = /\d/;
    const containsAUppercaseCharRegex = /[A-Z]/;
    const containsALowercaseCharRegex = /[a-z]/;
    const containsASpecialCharRegex = /[^A-Za-z0-9]/; // Regex for special character

    console.log("special case");
    if (password.length < 8) {
      return {
        errorMsg: intl.formatMessage({ id: "validation_passwordMinLength" }),
        hasError: true,
      };
    } else if (password.length > 256) {
      return {
        errorMsg: intl.formatMessage({ id: "validation_passwordMaxLength" }),
        hasError: true,
      };
    } else if (!containsADigitRegex.exec(password)) {
      return {
        errorMsg: intl.formatMessage({
          id: "validation_passwordContainsADigitRegex",
        }),
        hasError: true,
      };
    } else if (!containsAUppercaseCharRegex.exec(password)) {
      return {
        errorMsg: intl.formatMessage({
          id: "validation_passwordAUppercaseCharRegex",
        }),
        hasError: true,
      };
    } else if (!containsALowercaseCharRegex.exec(password)) {
      return {
        errorMsg: intl.formatMessage({
          id: "validation_passwordALowercaseCharRegex",
        }),
        hasError: true,
      };
    } else if (!containsASpecialCharRegex.exec(password)) {
      return {
        errorMsg: intl.formatMessage({ id: "validation_passwordSpecialChar" }),
        hasError: true,
      }; // Check for special character
    } else if (password !== confirmPassword) {
      return {
        errorMsg: intl.formatMessage({ id: "validation_passwordMismatch" }),
        hasError: true,
      };
    }
    return { errorMsg: "", hasError: false };
  }
}
