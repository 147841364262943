import { forwardRef, useImperativeHandle, useState } from "react";
import "./emp-rounded-pill.scss";

interface EmpPillProps {
  backgroundColor?: string;
  color?: string;
  text: string;
  style?: React.CSSProperties;
  isSelected?: boolean;
  clickable?: boolean;
}

export interface EmpRoundedPillRef {
  click: () => void;
}

const EmpRoundedPill = forwardRef((props: EmpPillProps, ref) => {
  const { text, style } = props;

  const [selected, setSelected] = useState<boolean>(props.isSelected ?? false);
  const clickable = props.clickable ?? false;

  useImperativeHandle(ref, () => {
    return {
      click,
    };
  });

  const click = () => {
    setSelected(!selected);
  };

  return (
    <div
      style={style}
      className={`emp-rounded-pill-wrapper ${clickable ? "clickable" : ""}`}
    >
      <div
        className={`emp-rounded-pill ${selected ? "selected" : ""}`}
      >
        <span>{text}</span>
      </div>
    </div>
  );
});
export default EmpRoundedPill;
