import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import DraftApi from "../../../api/campaign-msvc/draft.api";
import OngoingTaskApi from "../../../api/campaign-msvc/ongoing-task.api";
import {
  DELIVERABLE_STATUS,
  FILE_TYPE,
} from "../../../constants/app.constants";
import { ExtendedDraftDto } from "../../../model/campaign/extended-draft.dto";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FileUtils from "../../../utilities/file-util";
import FileIcon from "../../icon/file-icon";
import InfoCircleIcon from "../../icon/info-circle-icon";
import RefreshIcon from "../../icon/refresh-icon";
import ReviewDraftModal, {
  ReviewDraftModalRef,
} from "../../modals/review-draft-modal";
import EmpButton from "../../shared/emp-button/emp-button";
import EmpLoader, { EmpLoaderRef } from "../../shared/emp-loader/emp-loader";
import EmpPill from "../../shared/EmpPill/EmpPill";
import "../emp-kanban.scss";
import "./ongoing-kanban.scss";
import { DraftDto } from "../../../model/campaign/draft.dto";

interface Props {
  task: TaskDto;
  deliverableId: string;
}

type ClassifiedDeliverables = {
  pendingDraft: OngoingDeliverableExtendedDto[];
  inReview: OngoingDeliverableExtendedDto[];
  approved: OngoingDeliverableExtendedDto[];
  dispute: OngoingDeliverableExtendedDto[];
  completed: OngoingDeliverableExtendedDto[];
};

export interface BrandOngoingKanbanRef {
  fetchTaskNegotiations: () => void;
}

export const BrandOngoingKanban = forwardRef((props: Props, ref) => {
  const { task, deliverableId } = props;
  const navigate = useNavigate();

  const [ongoingDeliverables, setOngoingDeliverables] = useState<
    OngoingDeliverableExtendedDto[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [ongoingDeliverableDraftMap, setOngoingDeliverableDraftMap] =
    useState<Map<string, DraftDto>>();
  const reviewDraftModalRef = useRef<ReviewDraftModalRef>();

  useEffect(() => {
    if (loading === true) empLoaderRef.current?.show();
    if (loading === false) empLoaderRef.current?.hide();
  }, [loading]);
  const empLoaderRef = useRef<EmpLoaderRef>();

  const [classifiedNegotiations, setClassifiedNegotiations] =
    useState<ClassifiedDeliverables>({
      pendingDraft: [],
      inReview: [],
      approved: [],
      dispute: [],
      completed: [],
    });

  useImperativeHandle(ref, () => {
    return {
      fetchOngoingDeliverables,
    };
  });

  const fetchOngoingDeliverables = useCallback(
    async (deliverableId: string) => {
      try {
        setLoading(true);
        const resp = await OngoingTaskApi.fetchBrandDeliverables(
          task.id,
          deliverableId
        );

        const evidenceResps = await DraftApi.fetchDraftAsBrand(task.id);

        const draftResps = await DraftApi.fetchDraftAsBrand(task.id);
        const draftMap = new Map<string, DraftDto>();

        draftResps.data.forEach((draft) => {
          // Can just use creator user id as key since this is for one deliverable
          draftMap.set(draft.creatorUserId, draft);
        });
        setOngoingDeliverableDraftMap(draftMap);
        setOngoingDeliverables(resp.data);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch seller latest negotiation"
        );
      } finally {
        setLoading(false);
      }
    },
    [task]
  );

  const viewDraftOnClick = (
    ongoingDeliverable: OngoingDeliverableExtendedDto
  ) => {
    if (!ongoingDeliverableDraftMap?.has(ongoingDeliverable.creatorUserId))
      return;

    // Construct the Draft Extended Dto
    const draftExtended: ExtendedDraftDto = {
      ...ongoingDeliverable,
      draft: ongoingDeliverableDraftMap.get(ongoingDeliverable.creatorUserId)!,
    };
    reviewDraftModalRef.current?.show(draftExtended, task);
  };

  useEffect(() => {
    // Splitting the cards.
    const myClassifiedNegotiations: ClassifiedDeliverables = {
      pendingDraft: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.PENDING_DRAFT
      ),
      inReview: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.IN_REVIEW
      ),
      approved: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.APPROVED
      ),
      dispute: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.DISPUTE
      ),
      completed: ongoingDeliverables.filter(
        (elem) => elem.status === DELIVERABLE_STATUS.COMPLETED
      ),
    };
    setClassifiedNegotiations(myClassifiedNegotiations);
  }, [ongoingDeliverables]);

  useEffect(() => {
    fetchOngoingDeliverables(deliverableId);
  }, [fetchOngoingDeliverables, deliverableId]);

  const renderDraftFile = (creatorUserId: string) => {
    if (!ongoingDeliverableDraftMap?.has(creatorUserId)) return;
    const draft = ongoingDeliverableDraftMap.get(creatorUserId)!;

    const viewableDraft = draft.draftAttachments[0];
    if (!viewableDraft) return <></>;
    if (viewableDraft.fileType === FILE_TYPE.IMAGE) {
      return (
        <div className="draft-content-wrapper">
          <img
            referrerPolicy="no-referrer"
            className="draft-img"
            alt={draft.remarks}
            src={viewableDraft.attachmentUrl}
          />
        </div>
      );
    } else if (viewableDraft.attachmentUrl === FILE_TYPE.FILE) {
      return (
        <div className="draft-content-wrapper">
          <div className="draft-file-wrapper">
            <FileIcon size={24} backgroundColor={Color.NEUTRAL[300]} />
            <span className="title">{viewableDraft.attachmentName}</span>
            <span className="specs">
              {FileUtils.convertBytesToReadableSize(
                viewableDraft.attachmentSize
              )}
            </span>
          </div>
        </div>
      );
    } else if (viewableDraft.fileType === FILE_TYPE.VIDEO) {
      return (
        <div className="draft-content-wrapper">
          <video
            className="draft-video"
            muted
            loop
            controls={false}
            src={viewableDraft.attachmentUrl}
          />
        </div>
      );
    }
  };

  return (
    <div className="emp-kanban emp-ongoing-kanban">
      <ReviewDraftModal
        ref={reviewDraftModalRef}
        onSave={() => {
          fetchOngoingDeliverables(deliverableId);
        }}
      />
      <EmpLoader ref={empLoaderRef} background="transparent" />
      {!loading && (
        <div className="kanban-body-container">
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Pending Draft
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This column lists creators who are yet to submit their
                      drafts for the Brand's approval.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
              <div
                className="plus-icon-hidden-placeholder"
                onClick={() => {
                  navigate(
                    `/brand/task-recruitment/${task.campaign.id}/task/${task.id}`
                  );
                }}
              ></div>
            </div>
            {classifiedNegotiations.pendingDraft.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  referrerPolicy="no-referrer"
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">
                  No participant pending drafts
                </span>
                <p className="empty-state-description">
                  Looks like all drafts has been submitted and approved.
                </p>
                <EmpButton
                  buttonHeight="sm"
                  isFullWidth={false}
                  text={"Invite Creators"}
                  onSubmit={() => {
                    navigate(
                      `/brand/task-recruitment/${task.campaign.id}/task/${task.id}`
                    );
                  }}
                />
              </div>
            )}
            {classifiedNegotiations.pendingDraft.length > 0 &&
              classifiedNegotiations.pendingDraft.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            referrerPolicy="no-referrer"
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}
                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              referrerPolicy="no-referrer"
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      This creator is pending to submit a draft
                    </p>
                    <div
                      className="mt-1"
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                      <div className="revision-pill">
                        <RefreshIcon
                          backgroundColor={Color.NEUTRAL[500]}
                          strokeWidth={3}
                          size={10}
                        />
                        <span>
                          {elem.revisionsLeft} Revision
                          {elem.revisionsLeft > 1 ? "s" : ""} Left
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                In Review
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This column includes all creator drafts awaiting your
                      review. If a draft is rejected, it will be moved back to
                      the Pending Draft column. Conversely, if approved, it will
                      be advanced to the Approved column.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            {classifiedNegotiations.inReview.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Drafts for Review</span>
                <p className="empty-state-description">
                  There are no drafts that require your approval.
                </p>
              </div>
            )}
            {classifiedNegotiations.inReview.length > 0 &&
              classifiedNegotiations.inReview.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {ongoingDeliverableDraftMap &&
                      ongoingDeliverableDraftMap.has(elem.creatorUserId) &&
                      renderDraftFile(elem.creatorUserId)}

                    <p className="kanban-description mt-2">
                      A draft has been submitted for you to review.
                    </p>
                    <div className="mt-2 actions-wrapper">
                      <div>
                        <EmpPill
                          backgroundColor={Color.NEUTRAL[150]}
                          color={Color.NEUTRAL[600]}
                          size={"sm"}
                          text={DateUtil.toReadableDate(elem.createdDate)}
                        />
                        <div className="revision-pill mt-1">
                          <RefreshIcon
                            backgroundColor={Color.NEUTRAL[500]}
                            strokeWidth={3}
                            size={10}
                          />
                          <span>
                            {elem.revisionsLeft} Revision
                            {elem.revisionsLeft > 1 ? "s" : ""} Left
                          </span>
                        </div>
                      </div>
                      <EmpButton
                        onSubmit={() => {
                          viewDraftOnClick(elem);
                        }}
                        isFullWidth={false}
                        text={"View"}
                        buttonHeight="sm"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                In Dispute
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      A dispute has been raised! A Customer Success
                      representative from Emplifive will be reaching out to the
                      relevant parties to address and resolve this issue.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.dispute.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Disputes</span>
                <p className="empty-state-description">
                  Neither party has submitted a dispute{" "}
                </p>
              </div>
            )}
            {classifiedNegotiations.dispute.length > 0 &&
              classifiedNegotiations.dispute.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}
                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      There is a dispute regarding this creator
                    </p>

                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Approved
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      This column lists creators whose deliverables you have
                      approved. In this phase, the creator is required to
                      proceed with completing the task and submit their proof of
                      work before it can move to the Completed column.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.approved.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">No Approved Drafts</span>
                <p className="empty-state-description">
                  You have either yet to approve drafts, or that all
                  deliverables has been completed.
                </p>
              </div>
            )}
            {classifiedNegotiations.approved.length > 0 &&
              classifiedNegotiations.approved.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      This creator has been granted approval to complete the
                      deliverable.
                    </p>

                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="kanban-column">
            <div className="kanban-header">
              <span className="title-lbl">
                Completed
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      The card will only progress to this column once proof of
                      work has been submitted following the brand's approval. A
                      creator becomes eligible for payouts when all their
                      deliverables have reached this state.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      top={2}
                      size={14}
                      backgroundColor={Color.NEUTRAL[500]}
                    />
                  </div>
                </Whisper>
              </span>
            </div>
            <div className="vertical-spacer"></div>
            {classifiedNegotiations.completed.length === 0 && (
              <div className="empty-state-wrapper">
                <img
                  alt="Empty column"
                  src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/arrow.png"
                />
                <span className="empty-state-title">
                  No Creators ready to start
                </span>
                <p className="empty-state-description">
                  You, creators, or agencies have accepted an offer to take part
                  in this task
                </p>
              </div>
            )}
            {classifiedNegotiations.completed.length > 0 &&
              classifiedNegotiations.completed.map((elem, index) => {
                return (
                  <div
                    key={elem.id}
                    className={`kanban-card ${index > 0 ? "mt-2" : ""}`}
                  >
                    <div className="profile-section">
                      <div className="image-wrapper">
                        {elem.creator.imageType === "url" && (
                          <img
                            className={`profile-photo`}
                            alt={elem.creator.fullName}
                            src={elem.creator.imageResource}
                          />
                        )}
                        {elem.creator.imageType === "avatar" && (
                          <div
                            className={`avatar`}
                            style={{ background: elem.creator.imageResource }}
                          >
                            <span className="initials">
                              {elem.creator.initials}
                            </span>
                          </div>
                        )}

                        {elem.representativeRole === "agency" && (
                          <div className="absolute-wrapper">
                            <img
                              className={`profile-photo org-photo`}
                              alt={elem.agencyRepresentative.companyName}
                              src={elem.agencyRepresentative.logo}
                            />
                          </div>
                        )}
                      </div>
                      <div className="details-wrapper">
                        <div className="name-lbl">{elem.creator.fullName}</div>
                        <div className="representative-wrapper">
                          {elem.representativeRole === "creator" && "Creator"}
                          {elem.representativeRole === "agency" &&
                            elem.agencyRepresentative.companyName}
                          <EmpPill
                            backgroundColor={Color.PRIMARY[100]}
                            color={Color.PRIMARY[600]}
                            text={
                              elem.representativeRole === "agency"
                                ? "Agency"
                                : "Creator"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <p className="kanban-description mt-2">
                      The creator has completed the task.
                    </p>
                    <div className="mt-1">
                      <EmpPill
                        backgroundColor={Color.NEUTRAL[150]}
                        color={Color.NEUTRAL[600]}
                        size={"sm"}
                        text={DateUtil.toReadableDate(elem.createdDate)}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
});
