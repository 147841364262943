import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationApi from "../../../api/notification-msvc/notification.api";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../utilities/toast-utils";
import CheckIcon from "../../icon/check-icon";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onView: () => void;
  onRefresh: () => void;
}

export const KycMessageNotification = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [notification, setNotification] = useState<NotificationDto>(
    props.notification
  );
  const userRoleRef = useRef<string>("");

  useEffect(() => {
    userRoleRef.current = location.pathname.split("/")[1];
  }, [location]);

  useEffect(() => {
    setNotification({ ...props.notification });
  }, [props.notification]);

  const notifOnClick = async () => {
    try {
      if (notification.hasViewed) return;
      const resp = await NotificationApi.markNotificationAsRead(
        notification.id
      );
      if (resp.data.status === "success") {
        notification.hasViewed = true;
        setNotification({ ...notification });
        props.onRefresh();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to mark notification as read"
      );
    }
  };

  const viewOnClick = async () => {
    navigate(`/${userRoleRef.current}/settings/#payment`);
    props.onView();
    await NotificationApi.markNotificationAsRead(notification.id);
    props.onRefresh();
  };

  return (
    <div
      className="emp-notification-record"
      onClick={() => {
        notifOnClick();
      }}
    >
      {/* System Img */}
      {notification.sender.senderType === "system" && (
        <img
          alt={"system"}
          className="organisation-img"
          src={notification.sender.imageResource}
        />
      )}

      <div className="notification-content">
        <div className="content-wrapper">
          {notification.metadata.state === "in-review" && (
            <span className="info-text">
              <FormattedMessage id="kycNotification_approved" />
            </span>
          )}
          {notification.metadata.state === "approved" && (
            <span className="info-text">
              <FormattedMessage id="kycNotification_inReview" />
            </span>
          )}
          {notification.metadata.state === "rejected" && (
            <span className="info-text">
              <FormattedMessage id="kycNotification_rejected" />
            </span>
          )}
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
          <div className="button-wrapper">
            <EmpLink text={"View"} onSubmit={viewOnClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
