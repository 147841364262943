import "../../styles/shared/evidence-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import { TaskDto } from "../../model/campaign/task.dto";
import { FormattedMessage, useIntl } from "react-intl";
import { getEvidenceTypeOptions } from "../../constants/selectConstants";
import { SelectOption } from "../../model/common/selectOption";
import SubmitEvidenceSmLinkView from "./submit-evidence-modal-view/submit-evidence-sm-link-view";
import SubmitEvidenceListingView from "./submit-evidence-modal-view/submit-evidence-listing-view";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EvidenceApi from "../../api/campaign-msvc/evidence.api";
import { EvidenceDto } from "../../model/campaign/evidence.dto";
import { OngoingDeliverableExtendedDto } from "../../model/campaign/ongoing-deliverable-extended.dto";
import SubmitEvidenceSmImageView from "./submit-evidence-modal-view/submit-evidence-sm-image-view";
import SubmitEvidenceDetailsView from "./submit-evidence-modal-view/submit-evidence-details-view";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import SubmitEvidenceAttachmentView from "./submit-evidence-modal-view/submit-evidence-attachment-view";
import { XLoader } from "../loaders/x-loader";
import { SOCIAL_MEDIA_TYPE } from "../../constants/app.constants";
import SubmitEvidenceMethodsView from "./submit-evidence-modal-view/submit-evidence-methods-view";

export interface SubmitEvidenceModalRef {
  show: (
    representativeId: string,
    evidenceId: string,
    ongoingDeliverable: OngoingDeliverableExtendedDto,
    action?: "sm-binding"
  ) => void;
  hide: () => void;
}

interface Props {
  task: TaskDto;
  onSave: () => void;
}

type ViewModeType = "none" | "listing" | "form" | "evidence-detail";
type SubmitEvidenceView =
  | "select-method"
  | "sm-link"
  | "sm-image"
  | "file-attachment";
const SubmitEvidenceModal = forwardRef((props: Props, ref) => {
  const { onSave, task } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const intl = useIntl();
  const [evidenceTypeOptions, setEvidenceTypeOptions] = useState<
    SelectOption[]
  >([]);

  const [viewMode, setViewMode] = useState<ViewModeType>("none");

  const [currentEvidenceType, setCurrentEvidenceType] =
    useState<SubmitEvidenceView>();

  const [loading, setLoading] = useState(true);
  const evidenceIdRef = useRef<string>();
  const representativeIdRef = useRef<string>();
  const selectedEvidenceRecord = useRef<EvidenceRecordDto>();
  const [form, setForm] = useState<IFormGroup>({
    evidenceType: new FormControl("text", [new RequiredValidator()]),
  });

  const currentPlatformRef = useRef<string | null>(null);

  const ongoingDeliverableRef = useRef<OngoingDeliverableExtendedDto>();
  const [evidence, setEvidence] = useState<EvidenceDto>();

  useEffect(() => {
    console.log({ viewMode, currentEvidenceType, evidence });
  }, [viewMode, currentEvidenceType, evidence]);

  // Translation use effect.
  useEffect(() => {
    setEvidenceTypeOptions(getEvidenceTypeOptions(intl));
  }, [intl]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchEvidenceById = async (
    evidenceId: string,
    representativeId: string
  ): Promise<EvidenceDto> => {
    try {
      setLoading(true);
      evidenceIdRef.current = evidenceId;
      representativeIdRef.current = representativeId;
      const resp = await EvidenceApi.fetchEvidenceByIdAsSeller(evidenceId);
      setEvidence(resp.data);
      return resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to fetch evidence");
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  const hasEvidenceRecords = (evidence?: EvidenceDto): boolean => {
    return Boolean(
      evidence?.evidenceRecords?.length && evidence.evidenceRecords.length > 0
    );
  };

  const show = async (
    representativeId: string,
    evidenceId: string,
    ongoingDeliverable: OngoingDeliverableExtendedDto,
    action?: "sm-binding"
  ) => {
    console.log({ representativeId, evidenceId, ongoingDeliverable });

    setVisible(true);
    ongoingDeliverableRef.current = ongoingDeliverable;
    const evidence = await fetchEvidenceById(evidenceId, representativeId);
    setLoading(false);

    if (action && action === "sm-binding") {
      setViewMode("form");
      setCurrentEvidenceType("select-method");
      return;
    }
    if (hasEvidenceRecords(evidence)) {
      setViewMode("listing");
    } else {
      setViewMode("form");
      setCurrentEvidenceType("select-method");
    }
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
    setLoading(true);
    setViewMode("none");
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"md"}
    >
      {/* This is body */}
      <div className="emp-evidence-modal">
        <button
          onClick={() => dismiss()}
          className="emp-button-reset dismiss-icon-wrapper"
        >
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </button>

        {loading && task.platform !== SOCIAL_MEDIA_TYPE.X && (
          <div className="loader-wrapper">
            <div className="emp-spinner large"></div>
          </div>
        )}

        {loading && task.platform === SOCIAL_MEDIA_TYPE.X && (
          <XLoader
            title={"Tweet Storm Brewing"}
            description="We will not take more than 2 mins"
            positioning="absolute"
          />
        )}

        {!loading && evidenceIdRef.current && representativeIdRef.current && (
          <>
            <div className="text-content-section mb-2">
              <h2 className="title">
                <FormattedMessage id="submitEvidenceModal_title" />
              </h2>
              <p className="emp-paragraph mt-2">
                <FormattedMessage id="submitEvidenceModal_desc" />
              </p>
            </div>
            {viewMode === "form" &&
              currentEvidenceType &&
              task &&
              ongoingDeliverableRef.current && (
                <>
                  {currentEvidenceType === "select-method" && (
                    <SubmitEvidenceMethodsView
                      ongoingDeliverable={ongoingDeliverableRef.current}
                      onSetPlatform={(platform) => {
                        currentPlatformRef.current = platform;
                      }}
                      task={task}
                      redirectTo={(destination) => {
                        setCurrentEvidenceType(destination);
                      }}
                      back={() => {
                        setViewMode("listing");
                      }}
                      hasBack={hasEvidenceRecords(evidence)}
                    />
                  )}

                  {currentEvidenceType === "sm-link" &&
                    currentPlatformRef.current && (
                      <SubmitEvidenceSmLinkView
                        task={task}
                        back={() => {
                          setCurrentEvidenceType("select-method");
                        }}
                        onEvidenceCreated={async () => {
                          await fetchEvidenceById(
                            evidenceIdRef.current!,
                            representativeIdRef.current!
                          );
                          setViewMode("listing");
                          onSave();
                        }}
                        ongoingDeliverable={ongoingDeliverableRef.current}
                        platform={currentPlatformRef.current}
                      />
                    )}

                  {currentEvidenceType === "sm-image" && (
                    <SubmitEvidenceSmImageView
                      task={task}
                      back={() => {
                        setCurrentEvidenceType("select-method");
                      }}
                      onEvidenceCreated={async () => {
                        fetchEvidenceById(
                          evidenceIdRef.current!,
                          representativeIdRef.current!
                        );
                        setViewMode("listing");
                        onSave();
                      }}
                      ongoingDeliverable={ongoingDeliverableRef.current}
                    />
                  )}

                  {currentEvidenceType === "file-attachment" && (
                    <SubmitEvidenceAttachmentView
                      task={task}
                      back={() => {
                        setCurrentEvidenceType("select-method");
                      }}
                      onEvidenceCreated={async () => {
                        fetchEvidenceById(
                          evidenceIdRef.current!,
                          representativeIdRef.current!
                        );
                        setViewMode("listing");
                        onSave();
                      }}
                      ongoingDeliverable={ongoingDeliverableRef.current}
                    />
                  )}
                </>
              )}
            {viewMode === "listing" && evidence && (
              <SubmitEvidenceListingView
                task={task}
                evidence={evidence}
                role="seller"
                onEvidenceSelected={(evidenceRecord) => {
                  setViewMode("evidence-detail");
                  selectedEvidenceRecord.current = evidenceRecord;
                }}
                onEvidenceUpdate={async () => {
                  const evidenceDto = await fetchEvidenceById(
                    evidenceIdRef.current!,
                    representativeIdRef.current!
                  );
                  if (
                    !evidenceDto ||
                    evidenceDto.evidenceRecords.length === 0
                  ) {
                    setViewMode("form");
                    setCurrentEvidenceType("select-method");
                  } else {
                    setViewMode("listing");
                  }
                  onSave();
                }}
                onCreateEvidence={() => {
                  setCurrentEvidenceType("select-method");
                  setViewMode("form");
                }}
              />
            )}
            {viewMode === "evidence-detail" &&
              selectedEvidenceRecord.current && (
                <SubmitEvidenceDetailsView
                  role="seller"
                  back={() => {
                    setViewMode("listing");
                  }}
                  task={task}
                  evidenceRecordDto={selectedEvidenceRecord.current}
                  onEvidenceUpdate={async () => {
                    const evidenceDto = await fetchEvidenceById(
                      evidenceIdRef.current!,
                      representativeIdRef.current!
                    );
                    if (
                      !evidenceDto ||
                      evidenceDto.evidenceRecords.length === 0
                    ) {
                      setViewMode("form");
                      setCurrentEvidenceType("select-method");
                    } else {
                      setViewMode("listing");
                    }
                    onSave();
                  }}
                />
              )}
          </>
        )}
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default SubmitEvidenceModal;
