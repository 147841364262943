import { Element as SlateElement, Transforms, Editor } from "slate";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const EditorUtilities = {
  isMarkActive(editor: Editor, format: string) {
    const marks = Editor.marks(editor) as any;
    return marks ? marks[format] === true : false;
  },
  isBlockActive(editor: Editor, format: string) {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
      })
    );
    return !!match;
  },

  toggleMark(editor: Editor, format: string) {
    const isActive = EditorUtilities.isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },
  toggleBlock(editor: Editor, format: string) {
    const isActive = EditorUtilities.isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type),
      split: true,
    });
    let newProperties: Partial<SlateElement>;

    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);
    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  },
  detectList(editor: Editor) {
    const [match] = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "paragraph",
      })
    );
    if (!match) return;
    const text = match[0].children[0].text;
    if (text === "- ") {
      const [, path] = match;
      const block = {
        type: "bulleted-list",
        children: [
          {
            type: "list-item",
            children: [
              {
                text: "",
              },
            ],
          },
        ],
      };
      Transforms.removeNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "paragraph",
      });
      Transforms.insertNodes(editor, block, {
        at: [path[0]],
      });
      // Select the first bullet
      Transforms.select(editor, [path[0], 0]);
    }
  },
};
