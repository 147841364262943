import React, { useCallback, useEffect, useState } from "react";
import Highcharts, { chart } from "highcharts";
import markerClusters from "highcharts/modules/marker-clusters";
import highchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import OrganisationApi from "../../api/user-msvc/organisation.api";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { COUNTRY_CONSTANTS } from "../../constants/countries.contants";
import { Color } from "../../utilities/colors";
import { SmCreatorRespDto } from "../../model/user-management/sm-creator-resp.dto";
import { FormattedMessage, useIntl } from "react-intl";

// Import the map data module
highchartsMap(Highcharts);
markerClusters(Highcharts);

interface ChartData {
  name: string;
  lat: number;
  lon: number;
  country: string;
  marker: {
    symbol: string;
    height: number;
    width: number;
  };
  dataLabels: {
    format: string;
  };
}
interface SmAccumulator extends SmCreatorRespDto {
  qty: number;
}

interface Props {
  creators: SmCreatorRespDto[];
  onLoaded: () => void;
}
const AgencyMapChart = (props: Props) => {
  const { onLoaded } = props;
  const [chartOptions, setChartOptions] = useState<any>();
  const intl = useIntl();

  const fetchMap = async () => {
    const response = await axios.get(
      "https://code.highcharts.com/mapdata/custom/world.topo.json"
    );
    return response.data;
  };

  const processAgencyCreators = useCallback(
    async (creators: SmCreatorRespDto[]): Promise<ChartData[]> => {
      try {
        // reduce to only have accumulated data.
        const accumulatedData = creators.reduce(
          (accumulator: SmAccumulator[], currentItem: SmCreatorRespDto) => {
            const existingItem = accumulator.find(
              (item) => item.country === currentItem.country
            );
            if (existingItem) {
              existingItem.qty++;
            } else {
              accumulator.push({
                ...currentItem,
                qty: 1,
              });
            }
            return accumulator;
          },
          []
        );

        const chartData: ChartData[] = accumulatedData.map((elem) => {
          return {
            name: COUNTRY_CONSTANTS[elem.country].name,
            lat: COUNTRY_CONSTANTS[elem.country].lat,
            lon: COUNTRY_CONSTANTS[elem.country].lng,
            country: elem.country.toUpperCase(),
            marker: {
              symbol: `url(${COUNTRY_CONSTANTS[elem.country].flag_1x1})`,
              width: 15,
              height: 15,
            },
            dataLabels: {
              format: `${
                elem.qty > 1
                  ? intl.formatMessage(
                      { id: "agencyHomePage_prescenceMapDataLabel_plural" },
                      { value: elem.qty }
                    )
                  : intl.formatMessage({
                      id: "agencyHomePage_prescenceMapDataLabel_single",
                    })
              }`,
            },
          };
        });
        return chartData;
      } catch (error) {
        EmpExceptionHandler.handleHttpRequestError(
          error,
          "Unable to fetch existing creators"
        );
        throw error;
      }
    },
    [intl]
  );

  const prepareChartOptions = (
    topology: string,
    data: any,
    seriesData: any
  ) => {
    const options = {
      chart: {
        map: topology,
        backgroundColor: Color.GRADIENT_CARD_MD,
        reflow: true,
      },
      title: {
        text: "",
        align: "left",
      },
      mapNavigation: {
        enabled: true,
      },
      mapView: {
        padding: "5%",
        projection: {
          name: "Miller",
        },
      },
      xAxis: {
        gridLineWidth: 1, // Set the width of the gridlines on the x-axis.
      },
      yAxis: {
        gridLineWidth: 1, // Set the width of the gridlines on the y-axis.
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "<b>{point.name}</b>",
      },
      plotOptions: {
        map: {
          allAreas: true,
          joinBy: ["iso-a2", "code"],
          nullColor: "#000000",
          dataLabels: {
            enabled: false,
            color: "#FFC107",
            style: {
              fontWeight: "bold",
            },
            // Only show dataLabels for areas with high label rank
            format:
              "{#if (lt point.properties.labelrank 5)}" +
              "{point.properties.iso-a2}" +
              "{/if}",
          },
          tooltip: {
            headerFormat: "",
            pointFormat: "{point.name}: <b>{series.name}</b>",
          },
        },
      },
      series: [
        {
          type: "map",
          affectsMapView: false,
          allAreas: true,
          nullColor: Color.GRADIENT_CARD_MD,
          showInLegend: false,
          name: "Hide countries without creators",
        },
        {
          showInLegend: false,
          type: "map",
          affectsMapView: true,
          allAreas: true,
          color: Color.PRIMARY[300],
          borderColor: Color.NEUTRAL[800],
          name: "Countries with Creators",
        },
        {
          showInLegend: false,
          type: "mappoint",
          enableMouseTracking: true,
          accessibility: {
            point: {
              descriptionFormat:
                "{#if isCluster}" +
                "Grouping of {clusterPointsAmount} points." +
                "{else}" +
                "{name}, country code: {country}." +
                "{/if}",
            },
          },
          colorKey: "clusterPointsAmount",
          name: "Country",
          data: data,
          color: "rgb(196, 207, 231)",

          marker: {
            lineWidth: 1,
            lineColor: "#FFC107",
            height: 10,
            width: 10,
            symbol: "circle",
            radius: 8,
          },
          dataLabels: {
            format: intl.formatMessage({
              id: "agencyHomePage_prescenceMapDataLabel_single",
            }),
            verticalAlign: "top",
          },
        },
      ],
    };
    setChartOptions(options);
  };

  useEffect(() => {
    const prepareMap = async () => {
      const topology = await fetchMap();
      const data = await processAgencyCreators(props.creators);
      const seriesData = data.map((elem) => elem.country);
      prepareChartOptions(topology, data, seriesData);
    };
    prepareMap();
  }, [props.creators, processAgencyCreators, intl]);

  return (
    <div className="emp-card agency-map-chart mt-3">
      <h2 className="card-header-lbl">
        <FormattedMessage id="agencyHomePage_prescenceMapCardHeader" />
      </h2>
      <p className="emp-paragraph mt-2">
        <FormattedMessage id="agencyHomePage_prescenceMapDesc" />
      </p>
      {chartOptions && (
        <div style={{ aspectRatio: 2, background: "black", width: "100%" }}>
          <HighchartsReact
            containerProps={{ style: { width: "100%", height: "100%" } }}
            constructorType={"mapChart"}
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
      )}
    </div>
  );
};

export default AgencyMapChart;
