import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { CreateRateCardRequestDto } from "../../model/social-media/create-rate-card-request.dto";
import { RateCardDetailsDto } from "../../model/social-media/rate-card-details-resp.dto";
import { RateCardRatesDto } from "../../model/social-media/rate-card-rates.dto";
import { RateCardRequestDto } from "../../model/social-media/rate-card-request.dto";
import { RateCardRespDto } from "../../model/social-media/rate-card-resp.dto";
import { RateCardSaveReqDto } from "../../model/social-media/rate-card-save-req.dto";
import { UpdateRateCardRequestDto } from "../../model/social-media/update-rate-card-request.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const RateCardApi = {
  getRateCard: async (
    userId: string,
    platform: string
  ): Promise<ApiResponse<RateCardDetailsDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<RateCardDetailsDto[]>>(
      ENDPOINTS.userMicroservice.rateCard.fetchRateCard(userId, platform)
    );
    return response.data;
  },

  getRateCardWRole: async (
    userId: string,
    platform: string,
    role: string
  ): Promise<ApiResponse<RateCardDetailsDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<RateCardDetailsDto[]>>(
      ENDPOINTS.userMicroservice.rateCard.fetchRateCardWRole(
        userId,
        platform,
        role
      )
    );
    return response.data;
  },
  getOneRateCard: async (
    userId: string,
    platform: string
  ): Promise<ApiResponse<RateCardRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<RateCardRespDto[]>>(
      ENDPOINTS.userMicroservice.rateCard.fetchOneRateCard(userId, platform)
    );
    return response.data;
  },

  readRateCard: async (
    userId: string,
    platform: string,
    organisationId?: string
  ): Promise<ApiResponse<RateCardRatesDto>> => {
    const orgId = organisationId ?? "no-org";
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<RateCardRatesDto>>(
      ENDPOINTS.userMicroservice.rateCard.fetchReadRateCard(
        userId,
        orgId,
        platform
      )
    );
    return response.data;
  },
  saveRateCard: async (
    request: RateCardSaveReqDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.rateCard.root,
      request
    );
    return response.data;
  },

  createRateCardRequest: async (
    request: CreateRateCardRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.rateCardRequest.root,
      request
    );
    return response.data;
  },

  updateRateCardRequest: async (
    request: UpdateRateCardRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.rateCardRequest.root,
      request
    );
    return response.data;
  },
  getPendingBrandRateCardRequests: async (): Promise<
    ApiResponse<RateCardRequestDto[]>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<RateCardRequestDto[]>>(
      ENDPOINTS.userMicroservice.rateCardRequest.getPendingBrandRequests
    );
    return response.data;
  },
  getPendingBrandRateCardRequestCount: async (): Promise<
    ApiResponse<number>
  > => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<number>>(
      ENDPOINTS.userMicroservice.rateCardRequest.getPendingBrandRequestsCount
    );
    return response.data;
  },
};
export default RateCardApi;
