import { useCallback, useState } from "react";

import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../../utilities/toast-utils";
import TikTokApi from "../../../../api/social-integration-msvc/tiktok.api";
import { SmRedirectDefinitionLocalStorage } from "../../../sm-redirect-page/sm-redirect";
import { LocalStorageUtil } from "../../../../utilities/cache/local-storage-util";

export type BindTikTokAccountFn = (
  twitterCode: string,
  setLoader?: React.Dispatch<React.SetStateAction<"x" | "none" | "tiktok">>,
  refreshFn?: () => void,
  replacedUrl?: string
) => Promise<void>;

export function useTikTokLogin() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const tiktokLogin = async (
    redirectUrl: string,
    metadata?: { [key: string]: string }
  ) => {
    try {
      setLoading(true);
      const redirectDefinition: SmRedirectDefinitionLocalStorage = {
        value: redirectUrl,
        metadata: metadata,
        platform: "TikTok",
      };
      LocalStorageUtil.setItem("sm-redirect-definition", redirectDefinition);
      const resp = await TikTokApi.auth();
      window.location.href = resp.data;
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to Twitter"
      );
    } finally {
      setLoading(false);
    }
  };

  // Twitter/X Detection
  const bindTikTokAccount: BindTikTokAccountFn = useCallback(
    async (
      twitterCode: string,
      setLoader?: React.Dispatch<React.SetStateAction<"x" | "none" | "tiktok">>,
      refreshFn?: () => void,
      replacedUrl?: string
    ) => {
      try {
        setLoading(true);
        const response = await TikTokApi.getToken(twitterCode);
        if (response.data.status === "success") {
          ToastUtils.success(
            "Account Binded",
            "You have binded your TikTok account successfully."
          );
          if (refreshFn) refreshFn();
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to Twitter account"
        );
      } finally {
        if (setLoader) setLoader("none");
        if (replacedUrl) window.history.replaceState("", "", replacedUrl);
        setLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    tiktokLogin,
    bindTikTokAccount,
  };
}
