import { Amplify, ResourcesConfig } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import EmpToastManager from "./components/shared/emp-toast-manager/emp-toast-manager";
import EmpCreatorTemplate from "./components/template/emp-creator-template/emp-creator-template";
import EmpAgencyTemplate from "./components/template/emp-agency-template/emp-agency-template";
import { ENV_CONSTANTS, getEnv } from "./constants/envConstants";
import { FourOFourPage } from "./pages/404-page/404-page";
import { AgencyHomePage } from "./pages/agency-home-page/agency-home-page";
import { AgencyProfilePage } from "./pages/agency-profile-page/agency-profile-page";
import { ComingSoonPage } from "./pages/coming-soon-page/coming-soon-page";
import { CreatorAccessPage } from "./pages/creator-access-page/creator-access-page";
import { CreatorHomePage } from "./pages/creator-home-page/creator-home-page";
import { CreatorOnboardingPage } from "./pages/creator-onboarding-page/creator-onboarding-page";
import { CreatorProfilePage } from "./pages/creator-profile-page/creator-profile-page";
import { CreatorSettingsPage } from "./pages/creator-settings-page/creator-settings-page";
import { InvitationPage } from "./pages/invitation-page/invitation-page";
import { AgencySignUpPage } from "./pages/agency-sign-up-page/agency-sign-up-page";
import { ReferralPage } from "./pages/referral-page/referral-page";
import { AgencySettingsPage } from "./pages/agency-settings-page/agency-settings-page";
import { SignInPage } from "./pages/sign-in-page/sign-in-page";
import { SignUpPage } from "./pages/sign-up-page/sign-up-page";
import { CreatorDetailsPage } from "./pages/creator-details-page/creator-details-page";
import { TalentEntryPage } from "./pages/talent-entry-page/talent-entry-page";
import { CreatorPage } from "./pages/talent-page/creator-page";
import { ViewAgencyProfilePage } from "./pages/view-agency-profile-page/view-agency-profile-page";
import EmpBrandTemplate from "./components/template/emp-brand-template/emp-brand-template";
import { BrandHomePage } from "./pages/brand-home-page/brand-home-page";
import { BrandOnboardingPage } from "./pages/brand-onboarding-page/brand-onboarding-page";
import { BrandCampaignPage } from "./pages/brand-campaign-page/brand-campaign-page";
import { BrandCampaignDetailsPage } from "./pages/brand-campaign-details-page/brand-campaign-details-page";
import { SellerCampaignListingPage } from "./pages/seller-campaign-listing-page/seller-campaign-listing-page";
import { BrandTaskDetailsPage } from "./pages/brand-task-details-page/brand-task-details-page";
import { BrandTaskRecruitmentPage } from "./pages/brand-task-recruitment-page/brand-task-recruitment-page";
import { SellerCampaignDetailsPage } from "./pages/seller-campaign-details-page/seller-campaign-details-page";
import { SellerTaskDetailsPage } from "./pages/seller-task-details-page/seller-task-details-page";
import { AgencyCreatorDetailsPage } from "./pages/agency-creator-details-page/agency-creator-details-page";
import { BrandCreatorInvitationPage } from "./pages/brand-creator-invitation-page/brand-creator-invitation-page";
import { BrandCreatorDetailsPage } from "./pages/brand-creator-details-page/brand-creator-details-page";
import { IntlProvider } from "react-intl";

import en from "./translations/en.json";
import zh from "./translations/zh.json";
import th from "./translations/th.json";
import id from "./translations/id.json";

import { useEffect, useState } from "react";
import { PUB_SUB_TOPICS } from "./constants/pubSubTopics";
import { LocalStorageUtil } from "./utilities/cache/local-storage-util";
import { KycPage } from "./pages/kyc-page/kyc-page";
import { BrandCreatorsListingPage } from "./pages/brand-sellers-listing-page/brand-creators-listing-page";
import { BrandSettingsPage } from "./pages/brand-settings-page copy/brand-settings-page";
import { TestPage } from "./pages/test-page/test-page";
import { GlobalNotificationManager } from "./pages/seller-task-details-page/views/mobile/global-notifications-manager";
import { BrandSocialMediaManagerPage } from "./pages/brand-social-media-manager/brand-social-media-manager";
import SmRedirectPage from "./pages/sm-redirect-page/sm-redirect";
import { ExternalRoleSelectionPage } from "./pages/external-role-selection-page/external-role-selection-page";
import { AgencyOnboardingPage } from "./pages/agency-onboarding-page/agency-onboarding-page";
import { EmpAuthListener } from "./components/shared/emp-auth-listener";
import { OauthRedirectPage } from "./pages/oauth-redirect-page";

const awsConstants = ENV_CONSTANTS[getEnv()].aws as ResourcesConfig;

Amplify.configure(awsConstants);

// Load translations for the supported languages

const messages: Record<string, any> = {
  en,
  zh,
  th,
  id,
};

function App() {
  const [locale, setLocale] = useState<string>("en");

  // Use effect to initialize subscription for language.
  useEffect(() => {
    setLocale(LocalStorageUtil.getItem("locale", "en")!);
    const subscriptionCallback = (_: string, data: string) => {
      setLocale(data);
    };
    const subscription = PubSub.subscribe(
      PUB_SUB_TOPICS.LANGUAGE,
      subscriptionCallback
    );
    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="app-page-wrapper">
        <EmpToastManager />
        <GlobalNotificationManager />
        <BrowserRouter>
          <EmpAuthListener />
          <Routes>
            <Route
              path="oauth-redirect"
              element={<OauthRedirectPage />}
            ></Route>
            <Route path="/" element={<SignInPage />}></Route>
            <Route path="sign-in" element={<SignInPage />}></Route>
            <Route path="sign-up" element={<SignUpPage />}></Route>

            {/* 404 Route */}
            <Route path="*" element={<FourOFourPage />} />
            <Route path="onboard/:id" element={<AgencySignUpPage />}></Route>
            <Route
              path="external/role-selection"
              element={<ExternalRoleSelectionPage />}
            ></Route>
            <Route
              path="talent-entry/:id"
              element={<TalentEntryPage />}
            ></Route>

            <Route path="invite/:id" element={<InvitationPage />}></Route>
            <Route
              path="agency/onboard"
              element={<AgencyOnboardingPage />}
            ></Route>
            <Route
              path="agency/settings"
              element={<EmpAgencyTemplate children={<AgencySettingsPage />} />}
            ></Route>
            <Route
              path="agency/campaigns"
              element={<EmpAgencyTemplate children={<ComingSoonPage />} />}
            ></Route>
            <Route
              path="agency/campaigns-new"
              element={
                <EmpAgencyTemplate children={<SellerCampaignListingPage />} />
              }
            ></Route>
            <Route
              path="agency/profile"
              element={<EmpAgencyTemplate children={<AgencyProfilePage />} />}
            ></Route>
            <Route
              path="agency/profile/:id"
              element={
                <EmpAgencyTemplate children={<ViewAgencyProfilePage />} />
              }
            ></Route>
            <Route
              path="agency/creators"
              element={<EmpAgencyTemplate children={<CreatorPage />} />}
            ></Route>
            <Route
              path="agency/home"
              element={<EmpAgencyTemplate children={<AgencyHomePage />} />}
            ></Route>
            <Route
              path="agency/referrals"
              element={<EmpAgencyTemplate children={<ReferralPage />} />}
            ></Route>
            <Route
              path="agency/creator-details/:creatorId"
              element={
                <EmpAgencyTemplate children={<AgencyCreatorDetailsPage />} />
              }
            ></Route>
            <Route
              path="agency/campaign-details/:id"
              element={
                <EmpAgencyTemplate children={<SellerCampaignDetailsPage />} />
              }
            ></Route>
            <Route
              path="agency/campaign-details/:campaignId/task/:taskId"
              element={
                <EmpAgencyTemplate children={<SellerTaskDetailsPage />} />
              }
            ></Route>
            <Route
              path="agency/kyc"
              element={<KycPage role="agency" />}
            ></Route>

            <Route
              path="creator-access/:id"
              element={<CreatorAccessPage />}
            ></Route>
            <Route
              path="creator/home"
              element={<EmpCreatorTemplate children={<CreatorHomePage />} />}
            ></Route>
            <Route
              path="creator/settings"
              element={
                <EmpCreatorTemplate children={<CreatorSettingsPage />} />
              }
            ></Route>
            <Route
              path="creator/profile"
              element={<EmpCreatorTemplate children={<CreatorProfilePage />} />}
            ></Route>
            <Route
              path="creator/referrals"
              element={<EmpCreatorTemplate children={<ReferralPage />} />}
            ></Route>
            <Route
              path="creator/onboard"
              element={<CreatorOnboardingPage />}
            ></Route>
            <Route
              path="creator/agency/:id"
              element={
                <EmpCreatorTemplate children={<ViewAgencyProfilePage />} />
              }
            ></Route>
            <Route
              path="creator/creator-details/:id"
              element={<EmpCreatorTemplate children={<CreatorDetailsPage />} />}
            ></Route>
            <Route
              path="creator/campaigns-new"
              element={
                <EmpCreatorTemplate children={<SellerCampaignListingPage />} />
              }
            ></Route>
            <Route
              path="creator/campaigns"
              element={<EmpCreatorTemplate children={<ComingSoonPage />} />}
            ></Route>
            <Route
              path="creator/campaign-details/:id"
              element={
                <EmpCreatorTemplate children={<SellerCampaignDetailsPage />} />
              }
            ></Route>
            <Route
              path="creator/campaign-details/:campaignId/task/:taskId"
              element={
                <EmpCreatorTemplate children={<SellerTaskDetailsPage />} />
              }
            ></Route>
            <Route
              path="creator/kyc"
              element={<KycPage role="creator" />}
            ></Route>
            {/* Delete this after */}
            <Route path="test" element={<TestPage />}></Route>

            <Route
              path="brand/home"
              element={<EmpBrandTemplate children={<BrandHomePage />} />}
            ></Route>
            <Route
              path="brand/onboard"
              element={<BrandOnboardingPage />}
            ></Route>
            <Route
              path="brand/creators"
              element={
                <EmpBrandTemplate children={<BrandCreatorsListingPage />} />
              }
            ></Route>
            <Route
              path="brand/settings"
              element={<EmpBrandTemplate children={<BrandSettingsPage />} />}
            ></Route>
            <Route
              path="brand/agency/:id"
              element={
                <EmpBrandTemplate children={<ViewAgencyProfilePage />} />
              }
            ></Route>
            <Route
              path="brand/referrals"
              element={<EmpBrandTemplate children={<ReferralPage />} />}
            ></Route>
            <Route
              path="brand/campaigns"
              element={<EmpBrandTemplate children={<BrandCampaignPage />} />}
            ></Route>
            <Route
              path="brand/campaign-details/:campaignId/task/:taskId"
              element={<EmpBrandTemplate children={<BrandTaskDetailsPage />} />}
            ></Route>
            <Route
              path="brand/campaign-details/:id"
              element={
                <EmpBrandTemplate children={<BrandCampaignDetailsPage />} />
              }
            ></Route>
            <Route
              path="brand/task-recruitment/:campaignId/task/:taskId"
              element={
                <EmpBrandTemplate children={<BrandTaskRecruitmentPage />} />
              }
            ></Route>
            <Route
              path="brand/creator-invitation/:creatorId/campaign/:campaignId/task/:taskId"
              element={
                <EmpBrandTemplate children={<BrandCreatorInvitationPage />} />
              }
            ></Route>
            <Route
              path="brand/creator-details/:creatorId"
              element={
                <EmpBrandTemplate children={<BrandCreatorDetailsPage />} />
              }
            ></Route>
            <Route
              path="brand/social-media-manager"
              element={
                <EmpBrandTemplate children={<BrandSocialMediaManagerPage />} />
              }
            ></Route>
            <Route path="redirect" element={<SmRedirectPage />}></Route>
          </Routes>
        </BrowserRouter>
        {/* <EmpFooter /> */}
      </div>
    </IntlProvider>
  );
}

export default App;
