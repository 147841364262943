import { forwardRef, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentFailModal, {
  PaymentFailModalRef,
} from "../../../components/modals/payment-fail-modal";
import EmpButton, {
  EmpButtonRef,
} from "../../../components/shared/emp-button/emp-button";
import { PlanDto } from "../../../model/subscription/plan.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import "./brand-plan-selection-step.scss";
import useUser from "../../../hooks/useUser";

interface Props {
  nextStep: (intent: "done" | "skip", plan: PlanDto) => void;
  prevStep: () => void;
}

interface SelectedPlan {
  id: string;
  type: "trial" | "price" | "proceed";
}

const BrandPlanSelectionStep = forwardRef((props: Props, ref) => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>();
  const [isBtnDisabled, setBtnDisabled] = useState(true);
  const nextBtnRef = useRef<EmpButtonRef>();
  const paymentFailModalRef = useRef<PaymentFailModalRef>();

  const { refreshUser } = useUser();

  /**
   * Calculate the content of the button based on the selected plan ID and plan list.
   * @returns {string} The button content.
   */
  const buttonContent = useMemo(() => {
    if (selectedPlan) {
      if (selectedPlan.type === "proceed") {
        setBtnDisabled(false);
        return "Proceed for Free";
      }
    }
    setBtnDisabled(false);
    return "Proceed to Payment";
  }, [selectedPlan]);

  const onNextStep = async () => {
    try {
      if (!selectedPlan) return;
      nextBtnRef.current?.setButtonState("loading");
      if (selectedPlan.type === "proceed") {
        await refreshUser();
        navigate("/brand/home");
      }
    } catch (e) {
      if (e instanceof Error) {
        EmpExceptionHandler.builder().handleGenericError().build().process(e);
      }
    } finally {
      nextBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="emp-brand-plan-selection-step">
      <PaymentFailModal ref={paymentFailModalRef} />
      <h2 className="section-title ph-5">Brand Subscription</h2>
      <div className="plan-selection-section mt-4">
        {/* Continue for free button */}
        <div
          onClick={() => {
            setSelectedPlan({ id: "proceed", type: "proceed" });
          }}
          className={`plan-selection-card ${
            selectedPlan?.type === "proceed" ? "selected" : ""
          }`}
        >
          <div className="plan-details-wrapper">
            <div
              className={`knob ${
                selectedPlan?.type === "proceed" ? "selected" : ""
              }`}
            >
              <div
                className={`knob-ellipsis ${
                  selectedPlan?.type === "proceed" ? "selected" : ""
                }`}
              ></div>
            </div>
            <div className="plan-name-wrapper">
              <span className="plan-name-lbl">Continue for Free</span>
            </div>
            <span className="plan-price-lbl"></span>
          </div>
        </div>
      </div>
      <div className="product-capability-section">
        <div>
          <span className="capability-header">Social Media Management</span>
          <p className="capability-description">
            Manage all your social media channels, posts & performance in one
            powerful platform
          </p>
        </div>
        <div>
          <span className="capability-header">Campaign Management</span>
          <p className="capability-description">
            Create, manage and measure the success of your marketing campaign at
            ease.
          </p>
        </div>
        <div>
          <span className="capability-header">Analytics</span>
          <p className="capability-description">
            Track important metrics like engagement & conversions of your social
            media campaign efforts
          </p>
        </div>
      </div>
      <div className="mt-6 btn-wrapper ph-5 pb-4">
        <EmpButton
          isFullWidth={false}
          disabled={isBtnDisabled}
          text={buttonContent}
          ref={nextBtnRef}
          onSubmit={() => {
            onNextStep();
          }}
        />
      </div>
    </div>
  );
});

export default BrandPlanSelectionStep;
