import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormControl } from "../../../utilities/formUtils/formControl";
import { IFormGroup } from "../../../utilities/formUtils/formGroup";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import _debounce from "lodash/debounce";
import { TaskDto } from "../../../model/campaign/task.dto";
import {
  PLATFORM_ROLES,
  PROOF_OF_WORK_MEDIA_TYPE,
  SOCIAL_MEDIA_TYPE,
  TASK_TYPE,
  X_MEDIA_TYPE,
} from "../../../constants/app.constants";
import EmpException from "../../../exception/empException";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import TikTokApi from "../../../api/social-integration-msvc/tiktok.api";
import HeartIcon from "../../icon/heart-icon";
import MessageIcon from "../../icon/message-icon";
import ShareIcon from "../../icon/share-icon";
import { Color } from "../../../utilities/colors";
import { TtMediaWithProfileDto } from "../../../model/tiktok/tt-media-w-profile.dto";
import TikTokIcon from "../../icon/tiktok-icon";
import EvidenceApi from "../../../api/campaign-msvc/evidence.api";
import { CreateEvidenceDto } from "../../../model/campaign/create-evidence.dto";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import FacebookApi from "../../../api/social-integration-msvc/facebook.api";
import { FbMediaWithProfileDto } from "../../../model/facebook/fb-media-w-profile.dto";
import ToastUtils from "../../../utilities/toast-utils";
import EmpSelect from "../../shared/emp-select/emp-select";
import { instagramMediaTypeOptions } from "../../../constants/selectConstants";
import SelectIgPost, { SelectIgPostModalRef } from "../select-ig-post-modal";
import InstagramApi from "../../../api/social-integration-msvc/instagram.api";
import { IgMediaWithProfileDto } from "../../../model/instagram/ig-media-w-profile.dto";
import EyeIcon from "../../icon/eye-icon";
import SelectIgStoryModal, {
  SelectIgStoryModalRef,
} from "../select-ig-story-modal";
import PlayIcon from "../../icon/play-icon";
import CursorClickIcon from "../../icon/cursor-click-icon";
import SelectTiktokPostModal, {
  SelectTiktokPostModalRef,
} from "../select-tiktok-post-modal";
import SelectFacebookPostModal, {
  SelectFacebookPostModalRef,
} from "../select-facebook-post-modal";
import XApi from "../../../api/social-integration-msvc/x.api";
import XIcon from "../../icon/x-icon";
import FacebookIcon from "../../icon/facebook-icon";
import InstagramIcon from "../../icon/instagram-icon";
import SelectXTweetModal, {
  SelectXTweetModalRef,
} from "../select-x-tweet-modal";
import RepeatIcon from "../../icon/repeat-icon";
import Barchart1Icon from "../../icon/barchart-1-icon";
import XTweetPollWidget from "../../views/x-tweet-poll-widget";
import { XTweetDto } from "../../../model/x/x-tweet.dto";
import FormFieldUtils from "../../../utilities/form-field.util";
import SubmitEvidenceSelectSmModal, {
  SubmitEvidenceSelectSmModalRef,
} from "./submit-evidence-select-sm-modal";
import useUser from "../../../hooks/useUser";

interface Props {
  task: TaskDto;
  ongoingDeliverable: OngoingDeliverableExtendedDto;
  onEvidenceCreated: () => void;
  back: () => void;
  platform: string;
}

interface SocialMediaProfile {
  platform: string;
  picture: string;
  name: string;
  followers: number;
}

type IgMediaType = "post" | "story";

const SubmitEvidenceSmLinkView = (prop: Props) => {
  const intl = useIntl();
  const { task, ongoingDeliverable, onEvidenceCreated, back } = prop;
  const { sellerType } = useUser();

  const [isSmAvailable, setSmAvailable] = useState(false);
  const [platform, setPlatform] = useState<string>(prop.platform);
  const initialPlatformRef = useRef(prop.platform);

  const processBtnRef = useRef<EmpButtonRef>();
  const submitEvidenceBtnRef = useRef<EmpButtonRef>();
  const igMediaIdRef = useRef<string>();
  const ttMediaIdRef = useRef<string>();
  const fbMediaIdRef = useRef<string>();
  const xTweetUrlRef = useRef<string>();

  const selectTiktokPostModalRef = useRef<SelectTiktokPostModalRef>();
  const selectFacebookPostModalRef = useRef<SelectFacebookPostModalRef>();
  const selectXTweetModalRef = useRef<SelectXTweetModalRef>();

  const selectIgPostModalRef = useRef<SelectIgPostModalRef>();
  const selectIgStoryModalRef = useRef<SelectIgStoryModalRef>();

  // Determines if user has already selected either a Facebook, Instagram or TikTok media
  const [hasSelectedMedia, setHasSelectedMedia] = useState(false);
  // Is Loading = True when system is fetching a media for preview;
  const [isLoading, setLoading] = useState(true);
  const [submittable, setSubmittable] = useState(false);
  const [tiktokMedia, setTikTokMedia] = useState<TtMediaWithProfileDto>();
  const [instagramMedia, setInstagramMedia] = useState<IgMediaWithProfileDto>();
  const [facebookMedia, setFacebookMedia] = useState<FbMediaWithProfileDto>();
  const [xTweet, setXTweet] = useState<XTweetDto>();
  const [smDetail, setSmDetail] = useState<SocialMediaProfile>();
  const [isSmDetailLoading, setSmDetailLoading] = useState(true);
  const [igMediaType, setIgMediaType] = useState<IgMediaType>("post");

  const submitEvidenceSelectSmModalRef =
    useRef<SubmitEvidenceSelectSmModalRef>();

  const [form, setForm] = useState<IFormGroup>({
    remarks: new FormControl("text", []),
    igMediaType: new FormControl("text", [], "post"),
    ttMediaType: new FormControl("text", [], "tiktok-video"),
    fbMediaType: new FormControl("text", [], "facebook-post"),
    xMediaType: new FormControl("text", [], "tweet"),
  });

  const fetchSmProfile = useCallback(
    async (selectedPlatform: string) => {
      try {
        setSmDetailLoading(true);
        setSmDetail(undefined);
        if (selectedPlatform === TASK_TYPE.TIKTOK) {
          const tiktokResp = await TikTokApi.basicProfile(
            ongoingDeliverable.creatorUserId
          );
          setSmDetail({
            platform: SOCIAL_MEDIA_TYPE.TIKTOK,
            picture: tiktokResp.data.picture,
            name: tiktokResp.data.name,
            followers: tiktokResp.data.followers,
          });
        } else if (selectedPlatform === TASK_TYPE.X) {
          const xResp = await XApi.getBasicProfile(
            ongoingDeliverable.creatorUserId
          );
          setSmDetail({
            platform: SOCIAL_MEDIA_TYPE.X,
            picture: xResp.data.picture,
            name: xResp.data.name,
            followers: xResp.data.followers,
          });
        } else if (selectedPlatform === TASK_TYPE.FACEBOOK) {
          const xResp = await FacebookApi.basicProfile(
            ongoingDeliverable.creatorUserId
          );
          setSmDetail({
            platform: SOCIAL_MEDIA_TYPE.FACEBOOK,
            picture: xResp.data.picture,
            name: xResp.data.name,
            followers: xResp.data.followers,
          });
        } else if (selectedPlatform === TASK_TYPE.INSTAGRAM) {
          const xResp = await InstagramApi.basicProfile(
            ongoingDeliverable.creatorUserId
          );
          setSmDetail({
            platform: SOCIAL_MEDIA_TYPE.INSTAGRAM,
            picture: xResp.data.picture,
            name: xResp.data.name,
            followers: xResp.data.followers,
          });
        }
        setSmAvailable(true);
      } catch (error) {
        console.error(error);
        setSmAvailable(false);
      } finally {
        setSmDetailLoading(false);
      }
    },
    [ongoingDeliverable]
  );

  useEffect(() => {
    fetchSmProfile(initialPlatformRef.current);
  }, [fetchSmProfile]);

  // Special function to process instagram media
  const processInstagramMedia = async (
    igMediaId: string,
    type: "post" | "story"
  ) => {
    try {
      setInstagramMedia(undefined);
      setLoading(true);
      setHasSelectedMedia(true);
      if (type === "post") {
        const resp = await InstagramApi.getMediaPostById(
          ongoingDeliverable.creatorUserId,
          igMediaId
        );
        if (resp.data.status === "error") {
          ToastUtils.error(
            "Unable to retrieve Ig Post",
            "Media posted before business account conversion"
          );
          return;
        }
        igMediaIdRef.current = igMediaId;
        setInstagramMedia(resp.data.payload);
        setSubmittable(true);
      } else if (type === "story") {
        const resp = await InstagramApi.getMediaStoryById(
          ongoingDeliverable.creatorUserId,
          igMediaId
        );
        igMediaIdRef.current = igMediaId;
        setInstagramMedia(resp.data.payload);
        setSubmittable(true);
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to process media");
    } finally {
      setLoading(false);
    }
  };

  const processTiktokMedia = async (ttMediaId: string) => {
    try {
      setTikTokMedia(undefined);
      setLoading(true);
      setHasSelectedMedia(true);
      const resp = await TikTokApi.getMediaById(
        ongoingDeliverable.creatorUserId,
        ttMediaId
      );
      ttMediaIdRef.current = ttMediaId;
      setTikTokMedia(resp.data);
      setSubmittable(true);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to process media");
    } finally {
      setLoading(false);
    }
  };

  const processFacebookMedia = async (fbMediaId: string) => {
    try {
      setFacebookMedia(undefined);
      setLoading(true);
      setHasSelectedMedia(true);
      const resp = await FacebookApi.getMediaById(
        ongoingDeliverable.creatorUserId,
        fbMediaId
      );
      fbMediaIdRef.current = fbMediaId;
      setFacebookMedia(resp.data);
      setSubmittable(true);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to process media");
    } finally {
      setLoading(false);
    }
  };

  const processXMedia = async (xTweetUrl: string) => {
    try {
      setXTweet(undefined);
      setLoading(true);
      setHasSelectedMedia(true);
      const resp = await XApi.getTweetByUrls(ongoingDeliverable.creatorUserId, [
        xTweetUrl,
      ]);
      if (resp.data.length === 0) {
        ToastUtils.error("Unable to fetch Tweet", "No Tweet Fetched");
      }
      setXTweet(resp.data[0]);
      xTweetUrlRef.current = xTweetUrl;
      setSubmittable(true);
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to process media");
    } finally {
      setLoading(false);
    }
  };

  const getMediaType = () => {
    if (platform === TASK_TYPE.FACEBOOK) {
      if (
        facebookMedia!.status_type === "added_photos" ||
        facebookMedia!.status_type === "shared_story"
      )
        return PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE;
      else if (facebookMedia!.status_type === "added_video")
        return PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO;
      else if (facebookMedia!.status_type === "mobile_status_update")
        return PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT;
    } else if (platform === TASK_TYPE.TIKTOK) {
      return PROOF_OF_WORK_MEDIA_TYPE.TIKTOK_VIDEO;
    } else if (platform === TASK_TYPE.INSTAGRAM) {
      if (
        instagramMedia!.media_product_type === "STORY" &&
        instagramMedia!.media_type === "VIDEO"
      )
        return PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_VIDEO;
      else if (instagramMedia!.media_product_type === "STORY")
        return PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_STORY_PICTURE;
      else if (
        instagramMedia!.media_product_type === "FEED" &&
        instagramMedia!.media_type === "VIDEO"
      )
        return PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO;
      else if (instagramMedia!.media_product_type === "FEED")
        return PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_PICTURE;
      return PROOF_OF_WORK_MEDIA_TYPE.INSTAGRAM_FEED_VIDEO;
    } else if (platform === TASK_TYPE.X) {
      return PROOF_OF_WORK_MEDIA_TYPE.X_TWEET;
    }
    return "none";
  };

  const getMediaIdBasedOnPlatform = (platform: string) => {
    if (platform === TASK_TYPE.INSTAGRAM) return igMediaIdRef.current;
    else if (platform === TASK_TYPE.FACEBOOK) return fbMediaIdRef.current;
    else if (platform === TASK_TYPE.TIKTOK) return ttMediaIdRef.current;
    else if (platform === TASK_TYPE.X) return xTweetUrlRef.current;
  };

  const isSubmittingEvidenceRef = useRef<boolean>(false);
  const onSubmitEvidence = async () => {
    try {
      if (isSubmittingEvidenceRef.current) return;
      isSubmittingEvidenceRef.current = true;
      submitEvidenceBtnRef.current?.setButtonState("loading");

      if (platform === TASK_TYPE.INSTAGRAM && !igMediaIdRef.current)
        throw new EmpException("No Instagram media");
      else if (platform === TASK_TYPE.FACEBOOK && !fbMediaIdRef.current)
        throw new EmpException("No Facebook media");
      else if (platform === TASK_TYPE.TIKTOK && !ttMediaIdRef.current)
        throw new EmpException("No Tiktok media");
      else if (platform === TASK_TYPE.X && !xTweetUrlRef.current)
        throw new EmpException("No X media");

      const request: CreateEvidenceDto = {
        representativeId: ongoingDeliverable.representativeId,
        representativeRole: ongoingDeliverable.representativeRole,
        taskId: ongoingDeliverable.taskId,
        creatorId: ongoingDeliverable.creatorUserId,
        agencyOrgId: ongoingDeliverable.agencyOrgId,
        deliverableId: ongoingDeliverable.deliverableId,
        evidenceRecord: {
          platform: platform,
          mediaType: getMediaType(),
          mediaId: getMediaIdBasedOnPlatform(platform),
          remarks: form.remarks.getValue(),
          evidenceType: "sm-link",
        },
      };
      const resp = await EvidenceApi.createEvidence(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Proof of work Submitted",
          "Thank you for updating the proof of work"
        );
        onEvidenceCreated();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error submitting proof of work"
      );
    } finally {
      isSubmittingEvidenceRef.current = false;
      submitEvidenceBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <section className="view-form-section sm-link-view">
      <SubmitEvidenceSelectSmModal
        task={task}
        onSelect={(changedPlatform: string) => {
          fetchSmProfile(changedPlatform);
          setPlatform(changedPlatform);
        }}
        onDismiss={() => {
          fetchSmProfile(platform);
        }}
        ref={submitEvidenceSelectSmModalRef}
        ongoingDeliverable={ongoingDeliverable}
      />
      <SelectIgPost
        onSave={(igMediaId: string) => {
          processInstagramMedia(igMediaId, "post");
        }}
        ref={selectIgPostModalRef}
      />
      <SelectIgStoryModal
        onSave={(igMediaId: string) => {
          processInstagramMedia(igMediaId, "story");
        }}
        ref={selectIgStoryModalRef}
      />
      <SelectTiktokPostModal
        onSave={(ttMediaId) => {
          processTiktokMedia(ttMediaId);
        }}
        ref={selectTiktokPostModalRef}
      />
      <SelectFacebookPostModal
        onSave={(fbMediaId) => {
          processFacebookMedia(fbMediaId);
        }}
        ref={selectFacebookPostModalRef}
      />
      <SelectXTweetModal
        onSave={(xTweetId) => {
          processXMedia(xTweetId);
        }}
        ref={selectXTweetModalRef}
      />
      {!isSmDetailLoading && smDetail && (
        <section className="form-card-wrapper ">
          <div className="sm-picture-wrapper">
            <div className="social-badge">
              {platform === TASK_TYPE.TIKTOK && (
                <TikTokIcon backgroundColor={Color.NEUTRAL[100]} size={12} />
              )}
              {platform === TASK_TYPE.X && (
                <XIcon backgroundColor={Color.NEUTRAL[100]} size={12} />
              )}
              {platform === TASK_TYPE.FACEBOOK && (
                <FacebookIcon backgroundColor={Color.NEUTRAL[100]} size={12} />
              )}
              {platform === TASK_TYPE.INSTAGRAM && (
                <InstagramIcon backgroundColor={Color.NEUTRAL[100]} size={12} />
              )}
            </div>
            <img
              referrerPolicy="no-referrer"
              className="profile-pic"
              alt="socials"
              src={smDetail.picture}
            />
          </div>
          <div className="text-wrapper ml-2">
            <span className="title-lbl">{smDetail.name}</span>
            <span className="follower-lbl">
              {FormFieldUtils.toCompact(smDetail.followers)} Followers
            </span>
          </div>
          {sellerType === PLATFORM_ROLES.CREATOR && (
            <EmpButton
              text={"Change"}
              buttonStyle="secondary"
              onSubmit={() => {
                submitEvidenceSelectSmModalRef.current?.show(
                  ongoingDeliverable.creatorUserId
                );
              }}
              buttonHeight="sm"
              isFullWidth={false}
            />
          )}
        </section>
      )}
      {!isSmDetailLoading && !smDetail && (
        <section className="form-card-wrapper warn">
          <div className="text-wrapper ml-2">
            <span className="title-lbl">No {platform} Account Connected</span>
            {sellerType === PLATFORM_ROLES.CREATOR && (
              <span className="follower-lbl">
                To use this feature, Please select or connect to your {platform}{" "}
                account.
              </span>
            )}
            {sellerType === PLATFORM_ROLES.AGENCY && (
              <span className="follower-lbl">
                To use this feature, please request your creator to connect to a{" "}
                {platform} account.
              </span>
            )}
          </div>
          {sellerType === PLATFORM_ROLES.CREATOR && (
            <EmpButton
              text={"Connect"}
              buttonStyle="primary"
              onSubmit={() => {
                submitEvidenceSelectSmModalRef.current?.show(
                  ongoingDeliverable.creatorUserId
                );
              }}
              buttonHeight="sm"
              isFullWidth={false}
            />
          )}
        </section>
      )}
      {isSmDetailLoading && (
        <section className="empty-form-card-wrapper ">
          <div className="empty-profile-pic-wrapper">
            <div className="empty-sm-pic emp-shimmer"></div>
          </div>
          <div className="empty-description-wrapper">
            <div className="empty-description emp-shimmer"></div>
            <div className="empty-description-2 emp-shimmer mt-1"></div>
          </div>
        </section>
      )}
      <section>
        {platform === TASK_TYPE.INSTAGRAM && (
          <div className="split-form mt-3">
            <EmpSelect
              labelText="Instagram Media Type"
              placeholder="Select Media Type"
              required
              id={"igMediaType"}
              onChange={(fc) => {
                setIgMediaType(fc.getValue());
              }}
              selectOptions={instagramMediaTypeOptions}
              disabled={!isSmAvailable}
              formControl={form.igMediaType}
            />

            <div className="btn-wrapper">
              <EmpButton
                ref={processBtnRef}
                onSubmit={() => {
                  if (igMediaType === "post") {
                    selectIgPostModalRef.current?.show(
                      ongoingDeliverable.creatorUserId
                    );
                  } else if (igMediaType === "story") {
                    selectIgStoryModalRef.current?.show(
                      ongoingDeliverable.creatorUserId
                    );
                  }
                }}
                disabled={!isSmAvailable}
                buttonHeight="lg"
                isFullWidth={false}
                text={igMediaType === "post" ? "Select Post" : "Select Story"}
              />
            </div>
          </div>
        )}
        {/* Form Control if Task Platform is TikTok */}
        {platform === TASK_TYPE.TIKTOK && (
          <div className="split-form mt-3">
            <EmpSelect
              labelText="Media Type"
              placeholder="Select Media Type"
              required
              disabled
              id={"ttMediaType"}
              selectOptions={[{ label: "TikTok Video", value: "tiktok-video" }]}
              formControl={form.ttMediaType}
            />

            <div className="btn-wrapper">
              <EmpButton
                ref={processBtnRef}
                onSubmit={() => {
                  selectTiktokPostModalRef.current?.show(
                    ongoingDeliverable.creatorUserId
                  );
                }}
                disabled={!isSmAvailable}
                buttonHeight="lg"
                isFullWidth={false}
                text={igMediaType === "post" ? "Select Post" : "Select Story"}
              />
            </div>
          </div>
        )}

        {platform === TASK_TYPE.FACEBOOK && (
          <div className="split-form mt-3">
            <EmpSelect
              labelText="Media Type"
              placeholder="Select Media Type"
              required
              disabled
              id={"fbMediaType"}
              selectOptions={[
                { label: "Facebook Feed Post", value: "facebook-post" },
              ]}
              formControl={form.fbMediaType}
            />

            <div className="btn-wrapper">
              <EmpButton
                ref={processBtnRef}
                onSubmit={() => {
                  selectFacebookPostModalRef.current?.show(
                    ongoingDeliverable.creatorUserId
                  );
                }}
                disabled={!isSmAvailable}
                buttonHeight="lg"
                isFullWidth={false}
                text={igMediaType === "post" ? "Select Post" : "Select Story"}
              />
            </div>
          </div>
        )}

        {platform === TASK_TYPE.X && (
          <div className="split-form mt-3">
            <EmpSelect
              labelText="Media Type"
              placeholder="Select Media Type"
              required
              disabled
              id={"xMediaType"}
              selectOptions={[{ label: "Tweet", value: "tweet" }]}
              formControl={form.xMediaType}
            />

            <div className="btn-wrapper">
              <EmpButton
                ref={processBtnRef}
                disabled={!isSmAvailable}
                onSubmit={() => {
                  selectXTweetModalRef.current?.show(
                    ongoingDeliverable.creatorUserId
                  );
                }}
                buttonHeight="lg"
                isFullWidth={false}
                text={"Select Tweet"}
              />
            </div>
          </div>
        )}
        <div>
          <EmpTextInput
            labelText={`${intl.formatMessage({ id: "input_remarks_label" })}`}
            placeholder={intl.formatMessage({
              id: "input_remarks_placeholder",
            })}
            id={"remarks"}
            multiline
            rows={3}
            disabled={!isSmAvailable}
            textAreaAdaptiveHeight
            formControl={form.remarks}
          />
        </div>

        {hasSelectedMedia && (
          <div className="fetched-media-section mt-4">
            {isLoading && (
              <span className="media-header">Loading Media...</span>
            )}
            {!isLoading && (
              <span className="media-header">
                <FormattedMessage
                  id={"submitEvidenceModal_fetchedMediaHeader"}
                  values={{ platform: platform }}
                />
              </span>
            )}
            <div className="social-media-card">
              <div className="top-section">
                {isLoading && (
                  <>
                    <div className="empty-avatar emp-shimmer"></div>
                    <div className="info-section">
                      <div className="empty-name emp-shimmer"></div>
                    </div>
                  </>
                )}
                {!isLoading && tiktokMedia && (
                  <>
                    <img
                      className="social-media-avatar"
                      alt="tiktok avatar"
                      src={tiktokMedia.avatar_url}
                    />
                    <div className="info-section">
                      <span className="name-lbl">
                        {tiktokMedia.display_name}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && facebookMedia && (
                  <>
                    <img
                      className="social-media-avatar"
                      alt="Facebook avatar"
                      src={facebookMedia.avatar_url}
                    />
                    <div className="info-section">
                      <span className="name-lbl">
                        {facebookMedia.display_name}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && instagramMedia && (
                  <>
                    <img
                      className="social-media-avatar"
                      alt="Facebook avatar"
                      src={instagramMedia.avatar_url}
                    />
                    <div className="info-section">
                      <span className="name-lbl">
                        {instagramMedia.display_name}
                      </span>
                    </div>
                  </>
                )}
                {!isLoading && xTweet && (
                  <>
                    <img
                      className="social-media-avatar"
                      alt={xTweet.handle}
                      src={xTweet.picture}
                    />
                    <div className="info-section">
                      <span className="name-lbl">{xTweet.handle}</span>
                    </div>
                  </>
                )}
              </div>

              <div className="media-section">
                {isLoading && (
                  <div className="media-wrapper">
                    <div className="empty-media emp-shimmer"></div>
                  </div>
                )}
                {!isLoading && tiktokMedia && (
                  <div className="media-wrapper">
                    <img
                      referrerPolicy="no-referrer"
                      alt="tiktok cover"
                      src={tiktokMedia.cover_image_url}
                    />
                  </div>
                )}
                {!isLoading && facebookMedia && (
                  <>
                    <div className="media-wrapper">
                      {facebookMedia.status_type !== "mobile_status_update" && (
                        <img
                          alt="Facebook cover"
                          src={facebookMedia.full_picture}
                        />
                      )}
                    </div>
                    {facebookMedia.status_type === "mobile_status_update" && (
                      <div className="text-wrapper">
                        <p className="emp-paragraph">{facebookMedia.message}</p>
                      </div>
                    )}
                  </>
                )}
                {!isLoading && instagramMedia && (
                  <div className="media-wrapper">
                    {instagramMedia.media_product_type === "FEED" && (
                      <img
                        referrerPolicy="no-referrer"
                        alt="Media cover"
                        src={instagramMedia.media_url}
                      />
                    )}
                    {instagramMedia.media_product_type === "REELS" && (
                      <video muted loop src={instagramMedia.media_url} />
                    )}
                    {instagramMedia.media_product_type === "STORY" &&
                      instagramMedia.media_type === "IMAGE" && (
                        <img
                          referrerPolicy="no-referrer"
                          alt="Media cover"
                          src={instagramMedia.media_url}
                        />
                      )}
                    {instagramMedia.media_product_type === "STORY" &&
                      instagramMedia.media_type === "VIDEO" && (
                        <video muted loop src={instagramMedia.media_url} />
                      )}
                  </div>
                )}
                {!isLoading && xTweet && (
                  <>
                    <div className="text-wrapper x">
                      <p className="emp-paragraph">{xTweet.text}</p>
                    </div>
                    {xTweet.media && xTweet.media.length > 0 && (
                      <div className="x-media-grid-wrapper">
                        <div className="x-media-grid">
                          {xTweet.media.map((media, mediaIndex) => {
                            return (
                              <div
                                key={media.url}
                                className={`x-media-wrapper items-${
                                  xTweet.media!.length
                                }`}
                              >
                                {media.type === X_MEDIA_TYPE.VIDEO && (
                                  <div className="play-video-overlay">
                                    <button
                                      onClick={() => {
                                        window.open(xTweet.permalink, "_blank");
                                      }}
                                      className="emp-button-reset play-circle"
                                    >
                                      <PlayIcon
                                        size={24}
                                        backgroundColor={Color.NEUTRAL[0]}
                                      />
                                    </button>
                                  </div>
                                )}
                                <img
                                  alt={`post ${mediaIndex + 1}`}
                                  src={media.url}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {xTweet.poll && (
                      <div className="x-poll-wrapper">
                        <XTweetPollWidget poll={xTweet.poll} />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="bottom-section">
                {isLoading && (
                  <>
                    <div className="empty-metrics emp-shimmer"></div>
                    <div className="empty-description emp-shimmer"></div>
                  </>
                )}
                {!isLoading && tiktokMedia && (
                  <>
                    <div className="metrics-bar">
                      <div className="metric-wrapper">
                        <HeartIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{tiktokMedia.like_count}</span>
                      </div>
                      <div className="metric-wrapper">
                        <MessageIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{tiktokMedia.comment_count}</span>
                      </div>
                      <div className="metric-wrapper">
                        <ShareIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{tiktokMedia.share_count}</span>
                      </div>
                    </div>
                    <p className="description emp-paragraph">
                      {tiktokMedia.video_description}
                    </p>
                  </>
                )}
                {!isLoading && facebookMedia && (
                  <>
                    <div className="metrics-bar">
                      <div className="metric-wrapper">
                        <EyeIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{facebookMedia.impression_count}</span>
                      </div>
                      <div className="metric-wrapper">
                        <HeartIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{facebookMedia.like_count}</span>
                      </div>
                      <div className="metric-wrapper">
                        <MessageIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{facebookMedia.comment_count}</span>
                      </div>
                      <div className="metric-wrapper">
                        <ShareIcon
                          size={14}
                          backgroundColor={Color.NEUTRAL[600]}
                        />
                        <span>{facebookMedia.share_count}</span>
                      </div>
                    </div>
                    {facebookMedia.status_type !== "mobile_status_update" && (
                      <p className="description emp-paragraph">
                        {facebookMedia.message}
                      </p>
                    )}
                  </>
                )}
                {!isLoading && instagramMedia && (
                  <>
                    {instagramMedia.media_type === "FEED" && (
                      <div className="metrics-bar">
                        <div className="metric-wrapper">
                          <EyeIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.impression_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.like_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <MessageIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.comment_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <ShareIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.share_count}</span>
                        </div>
                      </div>
                    )}
                    {instagramMedia.media_product_type === "REELS" && (
                      <div className="metrics-bar">
                        <div className="metric-wrapper">
                          <PlayIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.play_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <HeartIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.like_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <MessageIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.comment_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <ShareIcon
                            size={14}
                            backgroundColor={Color.NEUTRAL[600]}
                          />
                          <span>{instagramMedia.share_count}</span>
                        </div>
                      </div>
                    )}
                    {instagramMedia.media_product_type === "STORY" && (
                      <div className="metrics-bar">
                        <div className="metric-wrapper">
                          <PlayIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={14}
                          />
                          <span>{instagramMedia.impression_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <HeartIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={14}
                          />
                          <span>{instagramMedia.like_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <MessageIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={14}
                          />
                          <span>{instagramMedia.comment_count}</span>
                        </div>
                        <div className="metric-wrapper">
                          <ShareIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={14}
                          />
                          <span>{instagramMedia.share_count}</span>
                        </div>
                      </div>
                    )}
                    <p className="description emp-paragraph">
                      {instagramMedia.caption}
                    </p>
                  </>
                )}

                {!isLoading && xTweet && (
                  <div className="metrics-bar">
                    <div className="metric-wrapper">
                      <MessageIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{xTweet.replyCount}</span>
                    </div>
                    <div className="metric-wrapper">
                      <RepeatIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{xTweet.retweetCount}</span>
                    </div>
                    <div className="metric-wrapper">
                      <HeartIcon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{xTweet.likeCount}</span>
                    </div>
                    <div className="metric-wrapper">
                      <Barchart1Icon
                        size={14}
                        backgroundColor={Color.NEUTRAL[600]}
                      />
                      <span>{xTweet.viewCount}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className="mt-6"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle="secondary"
            text={<FormattedMessage id="cta_back" />}
            onSubmit={() => {
              back();
            }}
          />
          <EmpButton
            ref={submitEvidenceBtnRef}
            isFullWidth={false}
            disabled={!submittable}
            text={intl.formatMessage({ id: "button_submitEvidence" })}
            onSubmit={onSubmitEvidence}
          />
        </div>
      </section>
    </section>
  );
};

export default SubmitEvidenceSmLinkView;
