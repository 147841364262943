import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useSearchParams } from "react-router-dom";

import EmpTable, {
  EmpTableContentSpec,
  EmpTableProps,
} from "../../../../components/shared/EmpTable/EmpTable";
import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import SocialMediaManagementApi from "../../../../api/smm-msvc/social-media-management.api";
import { UserDto } from "../../../../model/user-management/user.dto";
import {
  SmGetPostsQueryDto,
  SmPostDto,
} from "../../../../model/smm/smm-post.dto";
import FacebookIcon from "../../../../components/icon/facebook-icon";
import InstagramIcon from "../../../../components/icon/instagram-icon";
import TikTokIcon from "../../../../components/icon/tiktok-icon";
import { DateUtil } from "../../../../utilities/date";
import { Color } from "../../../../utilities/colors";
import ChevronDownIcon from "../../../../components/icon/chevron-down";
import EmpDropdownBtn from "../../../../components/shared/emp-dropdown-btn/emp-dropdown-btn";
import { IconProps } from "../../../../model/common/icon";
import XIcon from "../../../../components/icon/x-icon";
import { EMPTY_PROFILE_IMG_URL } from "../../../../constants/app.constants";
import PlayIcon from "../../../../components/icon/play-icon";
import EmpLoaderV2 from "../../../../components/shared/emp-loader-v2/emp-loader-v2";
import RefreshIcon from "../../../../components/icon/refresh-icon";
import EmpPagination, {
  EmpPaginationProps,
} from "../../../../components/shared/EmpPagination/EmpPagination";
import { EmpQueryable } from "../../../../model/common/tableFilters";
import EmpButton from "../../../../components/shared/emp-button/emp-button";
import { useSmPostList } from "../../hooks/useSmPostList";
import { AppContext } from "../../../../context/app.context";
import UserUtils from "../../../../utilities/user-utils";
import BrandEditPostModal, {
  BrandEditPostModalRef,
} from "../../modal/brand-edit-post-modal";
import DeleteModal, {
  DeleteModalRef,
} from "../../../../components/modals/delete-modal";
import { deletePostUtils } from "../../utils/smm-posts-utils";
import EmpException from "../../../../exception/empException";
import EmpLink from "../../../../components/shared/emp-link/emp-link";

type PostFilterSocialMedia = "Facebook" | "Instagram" | "X" | "Tiktok";

const DEFAULT_PAGE = "1";
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_STATUS_FILTER = ["draft"];

type DraftsTabProps = {
  postIds?: string[];
  setPostIds?: (postIds: string[]) => void;
};

export const DraftsTab: React.FC<DraftsTabProps> = ({
  postIds,
  setPostIds,
}) => {
  const brandEditPostModalRef = useRef<BrandEditPostModalRef>();
  const deleteModalRef = useRef<DeleteModalRef>();
  const userRef = useRef<UserDto>();
  const { user: userContext } = useContext(AppContext);

  const paginationQuery = useRef<EmpQueryable>({});

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const [pagination, setPagination] = useState<EmpPaginationProps>();
  const [postHistoryTableProps, setPostHistoryTableProps] =
    useState<EmpTableProps<SmPostDto>>();
  const [socialView, setSocialView] = useState<PostFilterSocialMedia | "All">(
    "All"
  );

  const draftPostContentSpec: EmpTableContentSpec<SmPostDto>[] = useMemo(() => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        width: 120,
        render: (record) => {
          return <p>{record.id ? record.id : "-"}</p>;
        },
      },
      {
        title: "Post",
        dataIndex: "creatorName",
        width: 300,
        render: (record) => {
          const formattedRecord = JSON.parse(record.metadata);
          const description = getPostDescription(formattedRecord);

          const extractFirstUrl = (): string => {
            const {
              image_urls,
              video_urls,
              video_url,
              photo_urls,
              media,
              media_url,
              media_items,
              link,
              file_url,
              thread,
            } = formattedRecord;

            if (link) return link;
            if (file_url) return file_url;
            if (image_urls?.length) return image_urls[0];
            if (video_urls?.length) return video_urls[0];
            if (photo_urls?.length) return photo_urls[0];
            if (video_url) return video_url;
            if (media_url) return media_url;
            if (media?.length) return media[0].url;
            if (media_items?.length) return media_items[0].url;
            if (thread?.length) {
              const firstMedia = thread[0].media;
              if (firstMedia?.length) return firstMedia[0].url;
            }
            return "";
          };

          const mediaUrl: string = extractFirstUrl();

          const renderImage = (url: string): JSX.Element => (
            <div className="media-wrapper">
              <img src={url} alt="post" />
            </div>
          );

          const renderVideo = (url: string): JSX.Element => (
            <div className="media-wrapper">
              <video src={url} />
              <PlayIcon backgroundColor={Color.NEUTRAL[100]} size={16} />
            </div>
          );

          const renderMedia = (): JSX.Element | null => {
            if (
              record.postType === "reel" ||
              record.postType === "video-post"
            ) {
              return renderVideo(mediaUrl);
            }

            if (record.postType === "tweet") {
              if (formattedRecord.media?.length) {
                const isImage = formattedRecord.media[0].type === "image";
                if (isImage) {
                  return renderImage(mediaUrl);
                }
                return renderVideo(mediaUrl);
              }
            }

            if (record.postType === "thread-tweet") {
              if (
                formattedRecord.thread?.length &&
                formattedRecord.thread[0].media?.length
              ) {
                const isImage =
                  formattedRecord.thread[0].media[0].type === "image";
                if (isImage) {
                  return renderImage(formattedRecord.thread[0].media[0].url);
                }
                return renderVideo(formattedRecord.thread[0].media[0].url);
              }
            }

            if (formattedRecord.photo_urls?.length) {
              return renderImage(formattedRecord.photo_urls[0]);
            }

            if (formattedRecord.image_urls?.length) {
              return renderImage(formattedRecord.image_urls[0]);
            }

            if (formattedRecord.video_urls?.length) {
              return renderVideo(formattedRecord.video_urls[0]);
            }

            if (!mediaUrl) return null;

            const mediaType: "image" | "video" | undefined =
              formattedRecord.media_type ||
              formattedRecord.media_items?.[0]?.media_type;

            switch (mediaType) {
              case "image":
                return renderImage(mediaUrl);
              case "video":
                return renderVideo(mediaUrl);
              default:
                return null;
            }
          };

          return (
            <div className="creator-record-row">
              {renderMedia()}
              <div className="post-wrapper">
                <p>{description}</p>
              </div>
            </div>
          );
        },
      },
      {
        title: "Published Account",
        dataIndex: "connectedAccount",
        render: (record) => {
          const { socialSpec } = record;
          return (
            <Link to={socialSpec.profileUrl} target="_blank">
              <div className="profile-wrapper">
                <div className={`picture-section`}>
                  <img
                    src={socialSpec?.pictureUrl || EMPTY_PROFILE_IMG_URL}
                    alt={`${socialSpec?.name} ${socialSpec?.platform} `}
                  />
                  {socialSpec?.platform && (
                    <div
                      className={`social-media-bubble ${socialSpec?.platform.toLowerCase()}`}
                    >
                      {socialSpec?.platform.toLowerCase() === "facebook" && (
                        <FacebookIcon
                          backgroundColor={Color.NEUTRAL[0]}
                          size={12}
                        />
                      )}
                      {socialSpec?.platform.toLowerCase() === "instagram" && (
                        <InstagramIcon
                          backgroundColor={Color.NEUTRAL[0]}
                          size={12}
                        />
                      )}
                      {socialSpec?.platform.toLowerCase() === "tiktok" && (
                        <TikTokIcon
                          backgroundColor={Color.NEUTRAL[0]}
                          size={12}
                        />
                      )}
                      {socialSpec?.platform.toLowerCase() === "x" && (
                        <XIcon backgroundColor={Color.NEUTRAL[0]} size={12} />
                      )}
                    </div>
                  )}
                </div>
                <div className="info-section">
                  {socialSpec ? (
                    <>
                      <span className="handle-name-lbl">
                        {socialSpec?.name}
                      </span>
                      <span className="metric-lbl">
                        {socialSpec?.followers} Followers
                      </span>
                    </>
                  ) : (
                    <span className="handle-name-lbl">Unlinked Account</span>
                  )}
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        title: "Create Date",
        dataIndex: "createdDate",
        render: (record) => {
          return (
            <span>
              {record.createdDate
                ? DateUtil.toReadableDateWithTime(record.createdDate as string)
                : "-"}
            </span>
          );
        },
      },
      {
        title: "Update At",
        dataIndex: "updatedAt",
        render: (record) => {
          return (
            <div className="scheduled-time">
              <p>
                {record.updatedAt
                  ? DateUtil.toReadableDateWithTime(record.updatedAt as string)
                  : "-"}
              </p>
            </div>
          );
        },
      },
      {
        title: "Post Type",
        dataIndex: "postType",
        render: (record) => {
          formattedPostType(record.postType);
          return (
            <div className="post-type">
              <p>{formattedPostType(record.postType)}</p>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        render: (record) => {
          return (
            <>
              <EmpButton
                isFullWidth={false}
                onSubmit={() =>
                  deleteModalRef.current?.show(
                    "Delete Draft",
                    "draft",
                    handleDeleteDraft,
                    record.id.toString()
                  )
                }
                buttonHeight="sm"
                buttonStyle="danger"
                text={"Delete"}
                className="ml-2 mr-1"
              />
              <EmpButton
                isFullWidth={false}
                onSubmit={() => brandEditPostModalRef.current?.show(record)}
                buttonHeight="sm"
                text={"Edit"}
              />
            </>
          );
        },
      },
    ];
  }, []);

  const validateOrganisationMembership = (user: UserDto) => {
    // Check if 'organisation' is defined and has at least one item
    if (!user.organisation || user.organisation.length === 0) {
      throw new EmpException("Not an organisation");
    }
  };

  const getPost = useCallback(async () => {
    const platformName: string = searchParams.get("platform") || "";
    const page: number = parseInt(
      searchParams.get("page") || DEFAULT_PAGE,
      DEFAULT_PAGE_SIZE
    );

    if (!userRef.current) {
      const user = await UserUtils.fetchUser(userContext);
      userRef.current = user;
    }

    const user = userRef.current!;
    validateOrganisationMembership(user);

    const brandId = user.organisation![0].id;
    const platformNames = !!platformName ? [platformName] : undefined;

    const query: SmGetPostsQueryDto = {
      brandId,
      postIds,
      platformNames,
      statuses: DEFAULT_STATUS_FILTER,
      page,
    };

    if (postIds && postIds.length > 0) {
      setSocialView("All");
    }

    setIsLoading(true);

    try {
      const { data } = await SocialMediaManagementApi.post.getAllPost(query);
      const { currentPage, total } = data;

      setPagination({
        currentPage,
        totalRecord: total,
        pageSize: DEFAULT_PAGE_SIZE,
      });

      setPostHistoryTableProps({
        data: data.data,
        contentColumns: draftPostContentSpec,
        rowKey: "id",
      });
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred when fetching post history"
      );
    } finally {
      setIsLoading(false);
    }
  }, [draftPostContentSpec, postIds, searchParams, userContext]);

  const { handleDeleteDraft } = deletePostUtils({ getPost });

  const {
    handlePaginationChange,
    handleRefreshClick,
    handleSocialClick,
    formattedPostType,
    getPostDescription,
  } = useSmPostList({
    isLoading,
    setSearchParams,
    getPost,
    setSocialView,
    paginationQuery,
    setPostIds,
  });

  useEffect(() => {
    getPost();
  }, [getPost, searchParams]);

  return (
    <div className="main-content scheduled-post-tab">
      <DeleteModal ref={deleteModalRef} onSave={() => {}} suppressToast />
      <BrandEditPostModal
        isEditDraft={true}
        ref={brandEditPostModalRef}
        getPost={getPost}
      />
      <div className="header-content-wrapper">
        <div className="left-side">
          <h3>Drafts Tab</h3>
          <span
            className="refresh-icon-wrapper"
            onClick={() => handleRefreshClick()}
          >
            <RefreshIcon backgroundColor={Color.NEUTRAL[500]} />
          </span>
        </div>
        <div className="right-side">
          <EmpDropdownBtn
            text={socialView}
            buttonStyle="outline-secondary"
            isLoading={isLoading}
            menuButtonSpecs={[
              {
                action: () => handleSocialClick(),
                context: "All",
                label: "All",
              },
              {
                action: () => handleSocialClick("Facebook"),
                context: "Facebook",
                label: "Facebook",
              },
              {
                action: () => handleSocialClick("Instagram"),
                context: "Instagram",
                label: "Instagram",
              },
              {
                action: () => handleSocialClick("TikTok"),
                context: "TikTok",
                label: "TikTok",
              },
              {
                action: () => handleSocialClick("X"),
                context: "X",
                label: "X",
              },
            ]}
            rightIcon={ChevronDownIcon}
          />
        </div>
      </div>
      <EmpTable
        shimmerLoading={{
          width: [120, 300, 100, 60, 80, 50, 50],
          overlayDesign: (
            <>
              <EmpLoaderV2 isLoading={true} />
            </>
          ),
        }}
        contentColumns={draftPostContentSpec}
        data={postHistoryTableProps?.data || []}
        rowKey={postHistoryTableProps?.rowKey || "id"}
        loading={isLoading}
        showEmptyState={true}
        emptyStateHeader={"No draft post found."}
        isLastColumnFixed={true}
      />
      <div className="pagination-wrapper mv-2">
        <EmpPagination
          onChange={(queryable) => handlePaginationChange(queryable)}
          pagination={pagination}
          queryable={paginationQuery.current}
        />
      </div>
    </div>
  );
};
