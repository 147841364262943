import { ENDPOINTS } from "../../constants/endpoints";
import { EmpInterceptor } from "../emp-interceptor";

const InvoiceApi = {
  downloadInvoice: async (
    generatedInvoiceId: string,
    documentName: string = "emplifive-invoice.pdf"
  ) => {
    const downloadUrl =
      ENDPOINTS.paymentMicroservice.airwallexInvoice.downloadInvoice(
        generatedInvoiceId
      );
    const axios = await EmpInterceptor.axiosClient();
    try {
      const response = await axios.get(downloadUrl, {
        responseType: "blob", // Set responseType to 'blob' to handle binary data
      });

      if (response.status === 200) {
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName);
        document.body.appendChild(link);

        link.click();

        // Clean up the object URL to free up resources
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the file:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while downloading the file:", error);
    }
  },
};
export default InvoiceApi;
