import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _debounce from "lodash/debounce";
import "./x-view.scss";
import { motion } from "framer-motion";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EyeIcon from "../icon/eye-icon";
import HeartsIcon from "../icon/hearts-icon";
import ImageIcon from "../icon/image-icon";
import UsersIcon from "../icon/users-icon";
import RateCardModal, { RateCardModalRef } from "../modals/rate-card-modal";
import EmpButton from "../shared/emp-button/emp-button";
import EmpContent from "../shared/emp-content/emp-content";
import { Color } from "../../utilities/colors";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import { RateCardDetailsDto } from "../../model/social-media/rate-card-details-resp.dto";
import EmpPill from "../shared/EmpPill/EmpPill";
import RateCardReadonlyModal, {
  RateCardReadonlyModalRef,
} from "../modals/rate-card-readonly-modal";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { AppContext } from "../../context/app.context";
import { UserDto } from "../../model/user-management/user.dto";
import UserUtils from "../../utilities/user-utils";
import { PermsUtils } from "../../constants/permissions.constants";
import EmpLink from "../shared/emp-link/emp-link";
import { useLocation } from "react-router-dom";
import StringUtils from "../../utilities/string.util";
import EmpIconButton from "../shared/emp-icon-button/emp-icon-button";
import SettingsIcon from "../icon/settings-icon";
import RateCardRequestModal, {
  RateCardRequestModalRef,
} from "../modals/rate-privacy-request-modal";
import RatePrivacySettingModal, {
  RateCardPrivacyModalRef,
} from "../modals/rate-privacy-setting-modal";
import { ReactKeyUtil } from "../../utilities/react-key.util";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import { DateUtil } from "../../utilities/date";
import { Tooltip, Whisper } from "rsuite";
import InfoCircleIcon from "../icon/info-circle-icon";
import FormFieldUtils from "../../utilities/form-field.util";
import XApi from "../../api/social-integration-msvc/x.api";
import { XProfileWMediaDto } from "../../model/x/x-profile-w-media.dto";
import XIcon from "../icon/x-icon";
import MarkerIcon from "../icon/marker-icon";
import LinkIcon from "../icon/link-icon";
import Calendar2Icon from "../icon/calendar-2-icon";
import { SOCIAL_MEDIA_TYPE } from "../../constants/app.constants";
import { useCountGridColumns } from "../../hooks/useCountGridColumns";
import XTweet from "./x-tweet";

interface Props {
  id: string;
}

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const DELAY = 0.1;

const XView = (props: Props) => {
  const { id } = props;
  const intl = useIntl();
  const location = useLocation();

  const [user, setUser] = useState<UserDto>();
  const { user: userContext } = useContext(AppContext);
  const roleRef = useRef<string>("");

  const isMobile = useEmpBreakpoint(500);
  const [xBasicProfile, setXBasicProfile] = useState<XProfileWMediaDto>();

  // Multiple rate cards that can be viewed by brands
  const [myRateCard, setMyRateCard] = useState<RateCardDetailsDto>();

  // Hidden rate card notice for agencies
  const [externalRatecards, setExternalRatecards] = useState<
    RateCardDetailsDto[]
  >([]);

  const rateCardReadonlyModalRef = useRef<RateCardReadonlyModalRef>();
  const rateCardModalRef = useRef<RateCardModalRef>();
  const rateCardRequestModalRef = useRef<RateCardRequestModalRef>();
  const rateCardPrivacySettingRef = useRef<RateCardPrivacyModalRef>();

  const setMyRateCards = (rateCardDetails: RateCardDetailsDto[]) => {
    const rateCardBelongsToMe = rateCardDetails.find((elem) => elem.isMe);
    setMyRateCard(rateCardBelongsToMe);
  };

  const determineExternalRateCards = (
    rateCardDetails: RateCardDetailsDto[]
  ) => {
    const externalOrgRateCards = [];
    for (let rateCardDetail of rateCardDetails) {
      if (!rateCardDetail.isMe && rateCardDetail.isViewable) {
        externalOrgRateCards.push(rateCardDetail);
      }
    }
    setExternalRatecards(externalOrgRateCards);
  };

  const fetchRateCard = useCallback(async () => {
    try {
      const resp = await RateCardApi.getRateCardWRole(
        id,
        SOCIAL_MEDIA_TYPE.X,
        roleRef.current
      );
      const rateCardDetails = resp.data;
      setMyRateCards(rateCardDetails);
      determineExternalRateCards(rateCardDetails);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch creator info status"
      );
    }
  }, [id]);

  useEffect(() => {
    roleRef.current = location.pathname.split("/")[1];

    const fetchUserDetails = async () => {
      setUser(await UserUtils.fetchUser(userContext));
    };
    const fetchXProfile = async () => {
      const resp = await XApi.getProfileWithTweets(id);
      setXBasicProfile(resp.data);
    };
    fetchRateCard();
    fetchXProfile();
    fetchUserDetails();
  }, [id, fetchRateCard, location.pathname, userContext]);

  /**
   * Get the rate card description based on the user's role.
   * @returns {string} The rate card description.
   */
  const getRateCardDescription = useCallback(
    (rateCardPrivacy: boolean) => {
      if (!user) return <span></span>;
      const role = PermsUtils.getUserRole(user);
      if (role === "agency" && rateCardPrivacy)
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_agency_rateCardCardPrivacyOnDesc"
            />
          </>
        );
      if (role === "agency" && !rateCardPrivacy)
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_agency_rateCardCardPrivacyOffDesc"
            />
          </>
        );
      else if (role === "creator")
        return (
          <>
            {" "}
            <FormattedMessage
              id="sm_creator_rateCardCardDesc"
            />
          </>
        );
      return <span></span>;
    },
    [user]
  );

  const renderRateCardOwner = (rateCardOwner: string): JSX.Element => {
    if (rateCardOwner === "agency")
      return <FormattedMessage id="sm_rateCardOwnerAgency" />;
    else if (rateCardOwner === "creator")
      return <FormattedMessage id="sm_rateCardOwnerCreator" />;
    return <></>;
  };

  const externalRateCardGridRef = useRef<HTMLDivElement>(null);
  const gridColumns = useCountGridColumns(externalRateCardGridRef);

  const remainingColumns = useMemo(() => {
    return gridColumns - (externalRatecards.length % gridColumns);
  }, [externalRatecards, gridColumns]);

  return (
    <div className="emp-x-view ">
      <RateCardReadonlyModal ref={rateCardReadonlyModalRef} />
      <RateCardModal
        ref={rateCardModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RateCardRequestModal
        ref={rateCardRequestModalRef}
        onSave={() => {
          fetchRateCard();
        }}
      />
      <RatePrivacySettingModal
        onSave={() => fetchRateCard()}
        ref={rateCardPrivacySettingRef}
      />
      <div style={{ flex: 1 }}></div>
      <div className="social-media-label mt-3">
        <XIcon size={18} backgroundColor={Color.NEUTRAL[200]} />
        <h1>Twitter / X</h1>
      </div>
      {xBasicProfile && !xBasicProfile.isUpdated && (
        <div className="emp-info-alert">
          <InfoCircleIcon
            backgroundColor={Color.PRIMARY[500]}
            size={14}
            strokeWidth={3}
            top={2.5}
          />
          <span>
            We're currently gathering your X metrics. This process should take
            approximately 2-5 minutes. Please check back shortly.
          </span>
        </div>
      )}
      {xBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 0, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smTiktok_followersLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCompact(xBasicProfile.followers)}
              </span>
              <div className="icon-wrapper">
                {" "}
                <UsersIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 1, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <span className="context-lbl">
                <FormattedMessage id="smTiktok_postsLabel" />
              </span>
              <span className="value-lbl">
                {FormFieldUtils.toCommify(xBasicProfile.mediaCount)}
              </span>
              <div className="icon-wrapper">
                <ImageIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
            </motion.div>

            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 2, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <EyeIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">IMPRESSIONS</span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Total number of views over the last 28 days.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>
              <span
                className={`value-lbl ${
                  !xBasicProfile.isUpdated ? "small" : ""
                }`}
              >
                {xBasicProfile.isUpdated
                  ? FormFieldUtils.toCompact(xBasicProfile.views)
                  : "Not Ready"}
              </span>
            </motion.div>
            <motion.div
              variants={fadeInVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: DELAY * 3, duration: 0.4 }}
              className="metric-card emp-card no-padding"
            >
              <div className="icon-wrapper">
                <HeartsIcon size={36} backgroundColor={Color.NEUTRAL[600]} />
              </div>
              <div className="h-stack">
                <span className="context-lbl">ENGAGEMENT</span>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      Total number of Engagement over the last 28 days.
                    </Tooltip>
                  }
                >
                  <div className="emp-tooltip-wrapper">
                    <InfoCircleIcon
                      size={16}
                      backgroundColor={Color.NEUTRAL[300]}
                      strokeWidth={2.5}
                    />
                  </div>
                </Whisper>
              </div>
              <span
                className={`value-lbl ${
                  !xBasicProfile.isUpdated ? "small" : ""
                }`}
              >
                {xBasicProfile.isUpdated
                  ? `${xBasicProfile.engagement.toFixed(2)}%`
                  : "Not Ready"}
              </span>
            </motion.div>
          </div>
        </div>
      )}
      {!xBasicProfile && (
        <div className="social-media-info-section ">
          <div className="metrics-section">
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
            <div className="metric-card-empty emp-shimmer"></div>
          </div>
        </div>
      )}
      {myRateCard && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_rateCardHeader" />
          </h1>
        </div>
      )}
      {myRateCard && (
        <div className={`emp-card rates-section`}>
          <div className="header-wrapper">
            <div>
              <div className="title-wrapper">
                <h2 className="emp-card-header">
                  <FormattedMessage id="sm_rateCardCardHeader" />
                </h2>
                {myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardHiddenPill" />
                      }
                    />
                  )}
                {!myRateCard.isRateCardPrivacy &&
                  roleRef.current === "agency" && (
                    <EmpPill
                      backgroundColor={Color.PRIMARY[100]}
                      color={Color.PRIMARY[600]}
                      text={
                        <FormattedMessage id="sm_agency_rateCardCardVisiblePill" />
                      }
                    />
                  )}
              </div>

              {myRateCard.isEditable && (
                <p className="emp-card-description">
                  {getRateCardDescription(myRateCard.isRateCardPrivacy)}
                </p>
              )}
            </div>
            {!isMobile && myRateCard.isEditable && (
              <div className="rates-btn-wrapper">
                {roleRef.current === "agency" && (
                  <EmpIconButton
                    onSubmit={() => {
                      rateCardPrivacySettingRef.current?.show();
                    }}
                    buttonStyle="secondary"
                    icon={<SettingsIcon backgroundColor={Color.NEUTRAL[500]} />}
                  />
                )}
                <EmpButton
                  text={<FormattedMessage id="sm_rateCardEditRatesBtn" />}
                  onSubmit={() => {
                    rateCardModalRef.current?.show(id, "X");
                  }}
                  isFullWidth={false}
                  buttonHeight="sm"
                />
              </div>
            )}
            {!myRateCard.isEditable && (
              <EmpButton
                text={"View Detailed Rates"}
                isFullWidth={false}
                buttonHeight="sm"
              />
            )}
          </div>
          <div className="rates-wrapper mt-2">
            {myRateCard.rateCards.map((rc, index) => {
              return (
                <div className="rate" key={rc.rateId}>
                  {rc.origin === "Template" && (
                    <EmpContent
                      label={TranslationUtil.translateRateCardName(
                        intl,
                        "X",
                        rc.rateName
                      )}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {rc.origin === "Custom" && (
                    <EmpContent
                      label={rc.rateName}
                      value={
                        rc.price > 0 ? (
                          `${rc.price.toFixed(2)} USD`
                        ) : (
                          <span
                            style={{
                              fontWeight: 600,
                              color: Color.NEUTRAL[400],
                            }}
                          >
                            <FormattedMessage id="sm_notSetLabel" />
                          </span>
                        )
                      }
                    />
                  )}
                  {!isMobile && index < myRateCard.rateCards.length - 1 && (
                    <div className="divider"></div>
                  )}
                </div>
              );
            })}
          </div>
          {isMobile && myRateCard.isEditable && (
            <div className="mt-4">
              <EmpButton
                text={"Edit Rates"}
                onSubmit={() => {
                  rateCardModalRef.current?.show(id, "X");
                }}
                isFullWidth={true}
                buttonHeight="sm"
              />
            </div>
          )}
        </div>
      )}

      {externalRatecards.length > 0 && (
        <div className="social-media-label  mt-3">
          <h1>
            <FormattedMessage id="sm_externalRateCardHeader" />
          </h1>
        </div>
      )}

      <div className="external-rates-section" ref={externalRateCardGridRef}>
        {externalRatecards.length > 0 &&
          ReactKeyUtil.addReactKey(externalRatecards).map((elem) => {
            if (
              elem.isRateCardPrivacy === false ||
              (elem.isRateCardPrivacy === true &&
                elem.rateCardRequestStatus === "approved")
            ) {
              return (
                <div
                  key={elem.reactKey}
                  className="emp-card no-padding external-rate-card"
                >
                  <div className="header-section">
                    {elem.rateCardOwner === "agency" && (
                      <img
                        className="pic"
                        alt="external subject logo"
                        src={elem.organisation.logo}
                      />
                    )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "url" && (
                        <img
                          className="pic"
                          alt="external subject logo"
                          src={elem.creator.imageResource}
                        />
                      )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "avatar" && (
                        <div
                          className="avatar"
                          style={{ background: elem.creator.imageResource }}
                        >
                          <span>{elem.creator.initials}</span>
                        </div>
                      )}
                    <div className="details-section">
                      {elem.rateCardOwner === "creator" && (
                        <span className="name-lbl">
                          {elem.creator.fullName}
                        </span>
                      )}
                      {elem.rateCardOwner === "agency" && (
                        <span className="name-lbl">
                          {elem.organisation.companyName}
                        </span>
                      )}

                      <EmpPill
                        text={renderRateCardOwner(elem.rateCardOwner)}
                        backgroundColor={Color.PRIMARY[100]}
                        color={Color.PRIMARY[600]}
                      />
                    </div>
                  </div>
                  {elem.rateCardExpiryDate && (
                    <div
                      className="rates-section"
                      style={{
                        borderBottom: `1px solid ${Color.NEUTRAL[100]}`,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }}
                    >
                      <p className="emp-paragraph">
                        Expires {DateUtil.toPeriod(elem.rateCardExpiryDate)}
                      </p>
                    </div>
                  )}
                  <div className="rates-breakdown-section mt-4">
                    {elem.rateCards
                      .filter((rc) => rc.origin === "Template")
                      .map((rc) => {
                        return (
                          <Fragment key={rc.rateId}>
                            <EmpContent
                              label={TranslationUtil.translateRateCardName(
                                intl,
                                "X",
                                rc.rateName
                              )}
                              value={
                                rc.price > 0 ? (
                                  `${rc.price.toFixed(2)} USD`
                                ) : (
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: Color.NEUTRAL[400],
                                    }}
                                  >
                                    <FormattedMessage id="sm_notSetLabel" />
                                  </span>
                                )
                              }
                            />
                          </Fragment>
                        );
                      })
                      .slice(0, 2)}
                  </div>
                  <div className="btn-section">
                    <EmpButton
                      buttonHeight="sm"
                      buttonStyle="secondary"
                      className="mt-4"
                      onSubmit={() => {
                        rateCardReadonlyModalRef.current?.show(
                          id,
                          "X",
                          elem.organisation?.id
                        );
                      }}
                      text={<FormattedMessage id="sm_rateCardMoreRatesBtn" />}
                    />
                  </div>
                </div>
              );
            } else if (elem.isRateCardPrivacy === true) {
              return (
                <div
                  key={elem.reactKey}
                  className="emp-card no-padding external-rate-card"
                >
                  <div className="header-section">
                    {elem.rateCardOwner === "agency" && (
                      <img
                        className="pic"
                        alt="external subject logo"
                        src={elem.organisation.logo}
                      />
                    )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "url" && (
                        <img
                          className="pic"
                          alt="external subject logo"
                          src={elem.creator.imageResource}
                        />
                      )}
                    {elem.rateCardOwner === "creator" &&
                      elem.creator.imageType === "avatar" && (
                        <div
                          className="avatar"
                          style={{ background: elem.creator.imageResource }}
                        >
                          <span>{elem.creator.initials}</span>
                        </div>
                      )}
                    <div className="details-section">
                      {elem.rateCardOwner === "creator" && (
                        <span className="name-lbl">
                          {elem.creator.fullName}
                        </span>
                      )}
                      {elem.rateCardOwner === "agency" && (
                        <span className="name-lbl">
                          {elem.organisation.companyName}
                        </span>
                      )}

                      <EmpPill
                        text={StringUtils.convertHyphensToTitleCase(
                          elem.rateCardOwner
                        )}
                        backgroundColor={Color.PRIMARY[100]}
                        color={Color.PRIMARY[600]}
                      />
                    </div>
                  </div>
                  <div className="rates-breakdown-section mt-4 hidden">
                    {elem.rateCardRequestStatus === "hidden" && (
                      <span>Agency has chosen to hide its rates</span>
                    )}
                    {elem.rateCardRequestStatus === "pending" && (
                      <span>
                        You have requested to view the rates from the agency.
                        Please wait for their approval
                      </span>
                    )}
                    {elem.rateCardRequestStatus === "rejected" && (
                      <span>
                        Your request to view this rate card has been rejected by
                        the Agency
                      </span>
                    )}
                    {elem.rateCardRequestStatus === "expired" && (
                      <span>
                        Your request to view rate card has already expired
                      </span>
                    )}
                  </div>
                  <div className="btn-section">
                    <EmpButton
                      buttonHeight="sm"
                      className="mt-4"
                      onSubmit={() => {
                        rateCardRequestModalRef.current?.show(
                          elem.organisation.id,
                          elem.creator.id
                        );
                      }}
                      text={"Request for rates"}
                    />
                  </div>
                </div>
              );
            }
          })}

        {!isMobile &&
          externalRatecards.length > 0 &&
          Array.from(Array(remainingColumns).keys()).map((elem) => {
            return (
              <div key={elem} className="external-empty-rate-card">
                <span className="label">
                  <FormattedMessage id="sm_externalRateCardEmptyCard" />
                </span>
              </div>
            );
          })}
      </div>

      <div className="social-media-label mt-3">
        <h1>
          <FormattedMessage id="sm_recentPostHeader" />
        </h1>
      </div>
      {xBasicProfile && (
        <div className="social-media-post-section mt-2">
          {xBasicProfile.tweets.length > 0 && (
            <div className="tweets-column">
              {xBasicProfile.tweets.map((elem, index) => {
                return (
                  <XTweet
                    xProfile={xBasicProfile}
                    xTweet={elem}
                    index={index}
                  />
                );
              })}
            </div>
          )}
          {xBasicProfile.tweets.length === 0 && (
            <div className={`empty-tweet-column`}>
              <div className="not-updated-placeholder">
                <div className="placeholder-content">
                  <img
                    alt="X"
                    src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/x-image.webp"
                  />
                  {!xBasicProfile.isUpdated && (
                    <>
                      <h2 className="overlay-header">
                        Tweets are not ready yet
                      </h2>
                      <p className="emp-paragraph">
                        We're gathering your Tweets. This process should take
                        approximately 3-4 minutes. Please check back shortly.
                      </p>
                    </>
                  )}
                  {xBasicProfile.isUpdated && (
                    <>
                      <h2>No Tweets here!</h2>
                      <p className="emp-paragraph">
                        Search results only include tweets from the past 30 days
                        that are available in X’s search feature.
                      </p>
                    </>
                  )}
                </div>
              </div>
              {[0, 1, 2].map((elem) => {
                return (
                  <div key={elem} className="empty-tweet emp-shimmer"></div>
                );
              })}
            </div>
          )}
          <div className="profile-column">
            <div
              className="banner"
              style={{
                backgroundImage: xBasicProfile.coverPicture
                  ? `url(${xBasicProfile.coverPicture})`
                  : "none",
              }}
            >
              <div className="profile-details">
                <img
                  className="profile-picture"
                  src={xBasicProfile.picture}
                  alt={xBasicProfile.name}
                />
                <div className="profile-info">
                  <div className="name-wrapper">
                    <span className="name-lbl">{xBasicProfile.name}</span>
                    {xBasicProfile.verifiedType !== "none" && (
                      <img
                        className="verified-mark"
                        alt="x verified mark"
                        src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/x-verified.webp"
                      />
                    )}
                  </div>
                  <span className="handle-lbl">@{xBasicProfile.handle}</span>
                </div>
              </div>
            </div>
            <p className="profile-description">{xBasicProfile.description}</p>

            <div className="profile-metadata">
              <div className="metadata">
                <Calendar2Icon
                  strokeWidth={2.5}
                  backgroundColor={Color.NEUTRAL[600]}
                  size={16}
                />
                <span className="metadata-text">
                  Joined{" "}
                  {DateUtil.toReadableDateShortYear(xBasicProfile.joinedDate)}
                </span>
              </div>
              {xBasicProfile.location && (
                <div className="metadata">
                  <MarkerIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={16}
                  />
                  <span className="metadata-text">
                    {xBasicProfile.location}
                  </span>
                </div>
              )}
              {xBasicProfile.url && (
                <div className="metadata">
                  <LinkIcon
                    strokeWidth={2.5}
                    backgroundColor={Color.NEUTRAL[600]}
                    size={16}
                  />
                  <a
                    rel="noreferrer"
                    className="metadata-link"
                    href={xBasicProfile.url}
                    target="_blank"
                  >
                    {xBasicProfile.url}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Empty State */}
      {!xBasicProfile && (
        <div className="social-media-post-section mt-2">
          <div className={`empty-tweet-column`}>
            {[0, 1, 2, 3].map((elem) => {
              return <div key={elem} className="empty-tweet emp-shimmer"></div>;
            })}
          </div>
          <div className={`empty-profile-column emp-shimmer`}></div>
        </div>
      )}
    </div>
  );
};
export default XView;
