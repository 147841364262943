import { ENDPOINTS } from "../../constants/endpoints";
import { AgencyTaskStatusRespDto } from "../../model/agency-task/agency-task-status-resp.dto";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { EmpInterceptor } from "../emp-interceptor";

const AgencyTaskApi = {
  fetchAgencyTask: async (
    organisationId: string
  ): Promise<ApiResponse<AgencyTaskStatusRespDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<AgencyTaskStatusRespDto[]>>(
      ENDPOINTS.userMicroservice.agencyTask.fetchAgencyTask(organisationId)
    );
    return response.data;
  },
  markAgencyTaskAsComplete: async (
    organisationId: string,
    taskId: number
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.userMicroservice.agencyTask.markAgencyTaskAsComplete(
        organisationId,
        taskId
      )
    );
    return response.data;
  },
};
export default AgencyTaskApi;
