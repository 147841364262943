import { MutableRefObject, useCallback, useMemo, useState } from "react";

import EmpExceptionHandler from "../../../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../../../utilities/toast-utils";
import FacebookUtils from "../../facebook";
import { FacebookPageDto } from "../../../../model/social-media/facebook-page.dto";
import { FacebookPageSelectionModalRef } from "../facebook-page-selection-modal";
import SocialMediaApi from "../../../../api/social-integration-msvc/social-media.api";
import { SOCIAL_MEDIA_TYPE } from "../../../../constants/app.constants";

export type FacebookLoginAttributes = {
  id: string;
  access_token: string;
};

export const useFacebookLogin = (
  facebookLoginModal: MutableRefObject<
    FacebookPageSelectionModalRef | undefined
  >
) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const facebookUtil = useMemo(() => new FacebookUtils(), []);

  /**
   * Logs the user out of their Facebook account.
   *
   * @function
   * @returns {Promise<void>} A promise that resolves when the logout process is complete.
   */
  const facebookLogout = useCallback(async () => {
    await facebookUtil.logout();
  }, [facebookUtil]);

  /**
   * Binds the user's Facebook account to the application using the provided Facebook login attributes.
   *
   * @function
   * @param {FacebookLoginAttributes} fbPageSpec - The attributes required for Facebook login.
   * @param {string} [platform] - Optional platform information.
   * @returns {Promise<boolean | void>} A promise that resolves to true if the account is successfully bound, or void if an error occurs.
   */
  const bindFacebookAccount = useCallback(
    async (fbPageSpec: FacebookLoginAttributes, platform?: string) => {
      try {
        setLoading(true);
        const resp = await SocialMediaApi.smLogin({
          socialMediaPlatform: SOCIAL_MEDIA_TYPE.FACEBOOK,
          accessToken: fbPageSpec.access_token,
          socialMediaUserId: fbPageSpec.id,
        });
        if (resp.data.status === "success") {
          ToastUtils.success("Success", "Succesfully binded your FB account");
          facebookLoginModal.current?.dismiss();
          facebookLogout();
          return true;
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to bind Facebook Page"
        );
      } finally {
        setLoading(false);
      }
    },
    [facebookLogout, facebookLoginModal]
  );

  /**
   * Logs the user into their Facebook account and handles the binding process of their Facebook Page.
   *
   * @function
   * @returns {Promise<void>} A promise that resolves when the login and binding process is complete.
   */
  const facebookLogin = useCallback(async () => {
    try {
      setLoading(true);
      const response = await facebookUtil.login("creator");
      if (response.status === "connected") {
        const userId = response.authResponse.userID;
        const facebookPageResp = (await facebookUtil.getFacebookPages(
          userId
        )) as FacebookPageDto;

        if (facebookPageResp.data.length === 0) {
          ToastUtils.error(
            "No Facebook Pages Found",
            "Your account does not have a Facebook Page"
          );
          return;
        }
        if (facebookPageResp.data.length > 0) {
          facebookLoginModal.current?.show(facebookPageResp, "Facebook");
          return;
        }
        // If there is exactly one Facebook Page
        bindFacebookAccount(facebookPageResp.data[0]);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to login to Facebook"
      );
    } finally {
      setLoading(false);
    }
  }, [facebookUtil, bindFacebookAccount, facebookLoginModal]);

  return {
    isLoading,
    facebookLogin,
    bindFacebookAccount,
    facebookLogout,
  };
};
