import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { TtMediaInfoDto } from "../../model/tiktok/tt-media-info-resp.dto";
import { XBasicProfileDto } from "../../model/x/x-basic-profile.dto";
import { XProfileWMediaDto } from "../../model/x/x-profile-w-media.dto";
import { XTweetDto } from "../../model/x/x-tweet.dto";
import { EmpInterceptor } from "../emp-interceptor";

// Configure Error Interceptor
const XApi = {
  auth: async (): Promise<ApiResponse<string>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<string>>(
      `${ENDPOINTS.userMicroservice.x.auth}`
    );
    return response.data;
  },

  getToken: async (code: string): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      `${ENDPOINTS.userMicroservice.x.getToken}`,
      { code }
    );
    return response.data;
  },

  getProfileWithTweets: async (
    creatorId: string,
    origin?: string
  ): Promise<ApiResponse<XProfileWMediaDto>> => {
    let originQuery = "";
    if (origin) {
      originQuery = `?origin=${origin}`;
    }
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<XProfileWMediaDto>>(
      `${ENDPOINTS.userMicroservice.x.getProfileWithTweets(
        creatorId
      )}${originQuery}`
    );
    return response.data;
  },

  getBasicProfile: async (
    creatorId: string
  ): Promise<ApiResponse<XBasicProfileDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<XBasicProfileDto>>(
      ENDPOINTS.userMicroservice.x.getBasicProfile(creatorId)
    );
    return response.data;
  },

  getTweetByUrls: async (
    creatorId: string,
    tweetUrls: string[]
  ): Promise<ApiResponse<XTweetDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<XTweetDto[]>>(
      ENDPOINTS.userMicroservice.x.getMultipleTweets(creatorId, tweetUrls)
    );
    return response.data;
  },
};
export default XApi;
