import "./upload-chat-attachment-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import UploadIcon from "../icon/upload-icon";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import FileUtils from "../../utilities/file-util";
import FileIcon from "../icon/file-icon";
import AlertSquareIcon from "../icon/alert-square";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpLink from "../shared/emp-link/emp-link";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { DeliverableMessageDto } from "../../model/campaign/deliverable-message.dto";
import { DeliverableConversationDto } from "../../model/campaign/deliverable-conversation.dto";
import { ATTACHMENT_TYPE } from "../../constants/app.constants";
import OngoingTaskApi from "../../api/campaign-msvc/ongoing-task.api";
import { TaskDto } from "../../model/campaign/task.dto";

export interface UploadChatAttachmentModalRef {
  show: (
    sender: "brand" | "seller",
    selectedConversation: DeliverableConversationDto
  ) => void;
  hide: () => void;
}

interface Props {
  task: TaskDto;
  onSave: () => void;
}

const UploadChatAttachmentModal = forwardRef((props: Props, ref) => {
  const { onSave, task } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [video, setVideo] = useState<File>();
  const [file, setFile] = useState<File>();
  const [imageFiles, setImageFiles] = useState<File[]>();

  const [hasFiles, setHasFiles] = useState<boolean>(false);

  const senderRef = useRef<string>();
  const selectedConversationRef = useRef<DeliverableConversationDto>();

  const [form, setForm] = useState<IFormGroup>({
    message: new FormControl("text", [new RequiredValidator()]),
  });

  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (
    sender: "brand" | "seller",
    selectedConversation: DeliverableConversationDto
  ) => {
    senderRef.current = sender;
    selectedConversationRef.current = selectedConversation;

    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setFile(undefined);
    setVideo(undefined);
    setImageFiles(undefined);
    setHasFiles(false);
    setVisible(false);
  };

  const resetForm = () => {
    FormGroupUtil.reset(form);
    setFile(undefined);
    setErrorMessage(undefined);
    setForm({ ...form });
  };

  /**
   * This function handles file uploads and validates the uploaded file.
   * @param event - The input event that triggers the file upload.
   * @returns A promise that resolves with void.
   */
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFile(undefined);
    setVideo(undefined);
    setImageFiles(undefined);
    setErrorMessage(undefined);

    const files: FileList | null = event.target.files;
    if (!files || files.length === 0) return;

    // Check if it's a video
    const vidIndex = FileUtils.findVideo(files);

    // Check if its a file
    const fileIndex = FileUtils.findNonVideoOrImage(files);
    if (vidIndex > -1) {
      setVideo(files[vidIndex]);
      setHasFiles(true);
    } else if (fileIndex > -1) {
      if (!FileUtils.isFileSizeSmallerThanMB(files[fileIndex], 20)) {
        setErrorMessage("File size must be less than or equal to 20MB.");
        return;
      }
      setFile(files[fileIndex]);
      setHasFiles(true);
    } else {
      const imageFiles = FileUtils.findImage(files);
      for (let image of imageFiles) {
        if (!FileUtils.isFileSizeSmallerThanMB(image, 2)!) {
          setErrorMessage("Imags size must not exceed 2Mb");
        }
      }
      setImageFiles(imageFiles);
      setHasFiles(true);
    }
    // Do something with the file, such as upload it
    // const base64 = await readFileAsBase64(file);
  };

  const sendAttachment = async (): Promise<void> => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!hasFiles || !selectedConversationRef.current || !senderRef.current)
        return;

      const selectedConversation = selectedConversationRef.current;
      const sender = senderRef.current;
      const isBrandSender = sender === "brand";

      const recipientId = isBrandSender
        ? selectedConversation.representativeId
        : selectedConversation.brand.id;
      const senderId = isBrandSender
        ? selectedConversation.brand.id
        : selectedConversation.representativeId;
      const senderRole = isBrandSender
        ? "brand"
        : selectedConversation.representativeRole;
      const recipientRole = isBrandSender
        ? selectedConversation.representativeRole
        : "brand";

      let attachmentType = ATTACHMENT_TYPE.NONE;
      let imageFileBase64 = undefined;
      let videoBase64 = undefined;
      let fileBase64 = undefined;

      if (video) {
        attachmentType = ATTACHMENT_TYPE.VIDEO;
        videoBase64 = {
          base64: await FileUtils.readFileAsBase64(video),
          name: video.name,
          size: video.size,
        };
      } else if (imageFiles) {
        attachmentType = ATTACHMENT_TYPE.IMAGES;
        const fileList: { base64: string; name: string; size: number }[] = [];
        for (let image of imageFiles) {
          fileList.push({
            size: image.size,
            base64: await FileUtils.readFileAsBase64(image),
            name: image.name,
          });
        }
        imageFileBase64 = fileList;
      } else if (file) {
        attachmentType = ATTACHMENT_TYPE.FILE;
        fileBase64 = {
          base64: await FileUtils.readFileAsBase64(file),
          name: file.name,
          size: file.size,
        };
      }

      const request: DeliverableMessageDto = {
        representativeId: selectedConversation.representativeId,
        representativeRole: selectedConversation.representativeRole,
        taskId: task.id,
        agencyOrgId: selectedConversation.agencyRepresentative?.id,
        brandOrgId: selectedConversation.brand.id,
        creatorUserId: selectedConversation.creator.id!,

        conversationId: selectedConversation.id,
        senderId,
        senderRole,
        recipientId,
        recipientRole,
        text: form.message.getValue(),
        attachmentType,
        imageFiles: imageFileBase64,
        video: videoBase64,
        file: fileBase64,
      };
      await OngoingTaskApi.sendMessage(request);
      props.onSave();
      dismiss();
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send attachment"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-upload-chat-attachment-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section mb-2">
          <h2 className="title">Send Attachment</h2>
          <p className="description"></p>
        </div>

        <div className="content-section">
          <div className="uploader-section">
            {!hasFiles && (
              <>
                <label
                  className="file-upload-zone mt-2"
                  htmlFor={"logo-upload"}
                >
                  <UploadIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
                  <span className="title">
                    Click here to upload an attachment
                  </span>
                  <p className="specs">Only able to upload max 20Mb File</p>
                </label>
              </>
            )}
            {hasFiles && (
              <div className="uploaded-attachment-wrapper">
                {video && (
                  <div className="video-wrapper">
                    <div className="overlay">
                      <span className="video-name">{video.name}</span>
                    </div>
                    <video
                      loop
                      muted
                      controls
                      className="uploaded-video"
                      src={URL.createObjectURL(video)}
                    ></video>
                  </div>
                )}
                {file && (
                  <div className="file-upload-zone mt-2">
                    <FileIcon size={35} backgroundColor={Color.NEUTRAL[300]} />
                    <span className="title">{file.name}</span>
                    <p className="specs">
                      {FileUtils.convertBytesToReadableSize(file.size)}
                    </p>
                  </div>
                )}
                {imageFiles && (
                  <div className="images-wrapper">
                    {imageFiles.map((file, index) => {
                      return (
                        <img
                          key={file.name}
                          alt={`attachment ${index}`}
                          src={URL.createObjectURL(file)}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {hasFiles && (
              <label className="mt-1" htmlFor={"logo-upload"}>
                <EmpLink text={"Replace this upload"} />
              </label>
            )}
            <input
              className="upload-hidden"
              type="file"
              id="logo-upload"
              onChange={handleFileUpload}
              name="files[]"
              multiple
            ></input>
            {errorMessage && (
              <div className="emp-error-message-wrapper">
                <AlertSquareIcon
                  backgroundColor={Color.RED[600]}
                  size={16}
                  bottom={1}
                />
                <span>{errorMessage}</span>
              </div>
            )}
          </div>
          {hasFiles && (
            <div className="mt-2">
              <EmpTextInput
                id="message"
                formControl={form.message}
                multiline
                textAreaAdaptiveHeight
                rows={1}
                placeholder="Enter caption..."
              />
            </div>
          )}
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            isFullWidth={false}
            text={"Upload Attachment"}
            onSubmit={() => {
              sendAttachment();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default UploadChatAttachmentModal;
