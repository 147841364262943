import "./select-ig-story-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import InstagramApi from "../../api/social-integration-msvc/instagram.api";
import EmpLoaderV2 from "../shared/emp-loader-v2/emp-loader-v2";
import { empDelay } from "../../utilities/delay";
import { IgMediaDto } from "../../model/instagram/ig-media.dto";
import PlayIcon from "../icon/play-icon";
import EmpButton from "../shared/emp-button/emp-button";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import ShareIcon from "../icon/share-icon";

export interface SelectIgStoryModalRef {
  show: (creatorId: string) => void;
  hide: () => void;
}

interface Props {
  onSave: (igMediaId: string) => void;
}

const SelectIgStoryModal = forwardRef((props: Props, ref) => {
  const { onSave } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const creatorIdRef = useRef<string>();

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const [eventFlag, setEventFlag] = useState(false);

  const [igStoryMedias, setIgStoryMedias] = useState<IgMediaDto[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const delaySet = async () => {
      await empDelay(100);
      setEventFlag((prevFlag: boolean) => {
        return !prevFlag;
      });
    };
    delaySet();
  }, [visible]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchInstagramStories = async (creatorId: string) => {
    try {
      setLoading(true);
      const resp = await InstagramApi.getStory(creatorId);
      setIgStoryMedias(resp.data);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch Instagram Info"
      );
    } finally {
      setLoading(false);
    }
  };

  const show = async (creatorId: string) => {
    creatorIdRef.current = creatorId;
    fetchInstagramStories(creatorId);
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
    setIgStoryMedias(undefined);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"lg"}
    >
      {/* This is body */}
      <div className="emp-select-ig-story-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">Select Instagram Story</h2>
          <p className="description">
            Please choose an Instagram story that you wish to submit as
            evidence. Kindly note that Instagram stories older than 24 hours may
            not be visible in this selection. If you've missed the timeframe,
            you will need to submit your proof of work using the 'Social Media
            Image Upload' option instead.
          </p>
        </div>
        <EmpLoaderV2 isLoading={loading} />
        <div className="post-section">
          <div className="post-wrapper" ref={resizeDivRef}>
            {igStoryMedias && igStoryMedias.length === 0 && (
              <div className="empty-overlay">
                <div className="info-wrapper mt-3">
                  <img
                    className="empty-img"
                    alt="Empty Campaign Icon"
                    srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
                  />
                  <h2 className="header">No Instagram Stories</h2>
                  <p className="description">
                    We were unable to fetch any active instagram stories from
                    your account. If your story has expired, you can submit your
                    proof of work using the "Social Media Upload" option.
                  </p>
                  <EmpButton
                    isFullWidth={false}
                    text={"Back"}
                    onSubmit={() => {
                      dismiss();
                    }}
                  />
                </div>
              </div>
            )}
            {(loading || igStoryMedias?.length === 0) &&
              [1, 2, 3].map((elem) => {
                return (
                  <div key={elem} className={`empty-card emp-shimmer`}></div>
                );
              })}
            {!loading &&
              igStoryMedias?.map((elem) => {
                return (
                  <div
                    onClick={() => {
                      onSave(elem.id);
                      dismiss();
                    }}
                    key={elem.id}
                    className={`ig-card`}
                  >
                    <div className="img-wrapper">
                      {(elem.media_type === "IMAGE" ||
                        elem.media_type === "CAROUSEL_ALBUM") && (
                        <img
                          referrerPolicy="no-referrer"
                          src={elem.media_url}
                          alt={`instagram post`}
                        />
                      )}
                      {elem.media_type === "VIDEO" && (
                        <video muted loop src={elem.media_url}></video>
                      )}
                    </div>
                    <div className="info-section">
                      <div className="metrics-wrapper">
                        <div className="metric">
                          <PlayIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.impression_count}
                          </span>
                        </div>
                        <div className="metric">
                          <HeartIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.like_count}
                          </span>
                        </div>
                        <div className="metric">
                          <MessageIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.comment_count}
                          </span>
                        </div>
                        <div className="metric">
                          <ShareIcon
                            backgroundColor={Color.NEUTRAL[600]}
                            size={16}
                          />
                          <span className="metric-value">
                            {elem.share_count}
                          </span>
                        </div>
                      </div>
                      <p className="caption">{elem.caption}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default SelectIgStoryModal;
