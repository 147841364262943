import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationDto } from "../../../model/notification/notification.dto";
import { CreatorAgencyInvitationDto } from "../../../model/onboarding/creator-agency-invitation-resp.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { OrganisationRespDto } from "../../../model/user/organisation-resp.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import CheckIcon from "../../icon/check-icon";
import RateCardAcceptConfirmationModal, {
  RateCardAcceptConfirmationModalRef,
} from "../../modals/rate-card-accept-confirmation-modal";
import RateCardRejectConfirmationModal, {
  RateCardRejectConfirmationModalRef,
} from "../../modals/rate-card-reject-confirmation-modal";
import EmpLink from "../../shared/emp-link/emp-link";
import "../notification-record.scss";

interface Props {
  notification: NotificationDto;
  user: UserDto;
  onRefresh: () => void;
  onView: () => void;
}

export const RateCardRequestNotification = (props: Props) => {
  const { notification, user } = props;
  const navigate = useNavigate();

  const rateCardAcceptConfirmationModalRef =
    useRef<RateCardAcceptConfirmationModalRef>();
  const rateCardRejectConfirmationModalRef =
    useRef<RateCardRejectConfirmationModalRef>();
  const [isLoading] = useState(false);

  return (
    <div className="emp-notification-record">
      <RateCardAcceptConfirmationModal
        ref={rateCardAcceptConfirmationModalRef}
        onSave={() => {
          props.onRefresh();
        }}
      />
      <RateCardRejectConfirmationModal
        ref={rateCardRejectConfirmationModalRef}
        onSave={() => {
          props.onRefresh();
        }}
      />
      {isLoading && (
        <div className="loader">
          <div className="emp-spinner"></div>
        </div>
      )}

      <div className="organisation-img-wrapper">
        {notification.sender.senderType === "organisation" && (
          <img
            alt={notification.sender.senderName}
            className="organisation-img"
            src={notification.sender.imageResource}
          />
        )}
        {notification.metadata.creator.imageType === "url" && (
          <img
            alt={notification.metadata.creator.fulName}
            className="badge-img small-profile-badge"
            src={notification.metadata.creator.imageResource}
          />
        )}
        {notification.metadata.creator.imageType === "avatar" && (
          <div
            style={{ background: notification.metadata.creator.imageResource }}
            className="badge-avatar small-profile-badge"
          >
            <span className="initials">
              {notification.metadata.creator.initials}
            </span>
          </div>
        )}
      </div>

      <div className="notification-content">
        <div className="content-wrapper">
          <span className="info-text">
            {notification.sender.senderName} has requested to view your rate
            cards for <EmpLink text={notification.metadata.creator.fullName} />{" "}
            here
          </span>
          {!notification.hasViewed && <div className="badge"></div>}
          {notification.hasViewed && (
            <CheckIcon size={18} backgroundColor={Color.GREEN[500]} />
          )}
        </div>
        <div className="footer mt-1">
          <span className="small-text">{`${DateUtil.toPeriod(notification.createdAt.toString())}`}</span>
          <div className="button-wrapper">
            {notification.metadata.state === "valid" && (
              <>
                <EmpLink
                  text={"View"}
                  onSubmit={() => {
                    navigate("/agency/creators#Rate%20Card%20Request");
                    props.onView();
                  }}
                />
                <EmpLink
                  text={"Accept"}
                  onSubmit={() => {
                    rateCardAcceptConfirmationModalRef.current?.show(
                      notification.sender.senderId,
                      notification.sender.senderName,
                      notification.metadata.creator.id,
                      notification.metadata.creator.fullName,
                      notification.metadata.rateCardRequestId
                    );
                  }}
                />
                <EmpLink
                  linkStyle="danger"
                  text={"Reject"}
                  onSubmit={() => {
                    rateCardRejectConfirmationModalRef.current?.show(
                      notification.sender.senderId,
                      notification.sender.senderName,
                      notification.metadata.creator.id,
                      notification.metadata.creator.fullName,
                      notification.metadata.rateCardRequestId
                    );
                  }}
                />
              </>
            )}
            {notification.metadata.state === "approved" && (
              <>
                <span className="disabled-text">Approved</span>
              </>
            )}
            {notification.metadata.state === "rejected" && (
              <>
                <span className="disabled-text">Rejected</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
