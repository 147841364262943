import moment from "moment-timezone";

export class DateUtil {
  private static READABLE_DATE_FRMT = "DD MMM YYYY";
  private static READABLE_DATE_SHORT_YEAR_FRMT = "DD MMM YY";
  private static READABLE_DATE_W_TIME_FRMT = "DD MMM YYYY HH:mm";
  private static READABLE_12HR_TIME_FRMT = "hh:mm A";

  static toReadableDate = (date: string | number): string => {
    const mDate = moment(date);
    return mDate.format(this.READABLE_DATE_FRMT);
  };
  static toReadable12HrTime = (date: string | number): string => {
    const mDate = moment(date);
    return mDate.format(this.READABLE_12HR_TIME_FRMT);
  };

  static toReadableDateShortYear = (date: string | number): string => {
    const mDate = moment(date);
    return mDate.format(this.READABLE_DATE_SHORT_YEAR_FRMT);
  };

  static toReadableDateWithTime = (date: string | number): string => {
    const mDate = moment(date);
    return mDate.format(this.READABLE_DATE_W_TIME_FRMT);
  };

  static toPeriod(dateTimeStr: string): string {
    const dateTime = new Date(dateTimeStr);

    if (isNaN(dateTime.getTime())) {
      throw new Error(
        "Invalid date string. Please provide a valid date and time string."
      );
    }

    const now = new Date();
    const mode = now.getTime() > dateTime.getTime() ? "past" : "future";
    const timeDiffInSeconds = Math.floor(
      Math.abs(now.getTime() - dateTime.getTime()) / 1000
    );
    if (timeDiffInSeconds < 60) {
      return mode === "past" ? "Just now" : "later";
    } else if (timeDiffInSeconds < 3600) {
      const minutesAgo = Math.floor(timeDiffInSeconds / 60);

      return `${minutesAgo} mins ${mode === "past" ? "ago" : "later"}`;
    } else if (timeDiffInSeconds < 86400) {
      const hoursAgo = Math.floor(timeDiffInSeconds / 3600);

      return `${hoursAgo}h ${mode === "past" ? "ago" : "later"}`;
    } else if (timeDiffInSeconds < 604800) {
      const daysAgo = Math.floor(timeDiffInSeconds / 86400);

      return `${daysAgo} days ${mode === "past" ? "ago" : "later"}`;
    } else if (timeDiffInSeconds < 2592000) {
      const weeksAgo = Math.floor(timeDiffInSeconds / 604800);

      return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ${
        mode === "past" ? "ago" : "later"
      }`;
    } else if (timeDiffInSeconds < 31536000) {
      const monthsAgo = Math.floor(timeDiffInSeconds / 2592000);

      return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ${
        mode === "past" ? "ago" : "later"
      }`;
    } else {
      const yearsAgo = Math.floor(timeDiffInSeconds / 31536000);

      return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ${
        mode === "past" ? "ago" : "later"
      }`;
    }
  }
}
