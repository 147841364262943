export class Color {
  static GRADIENT_CARD_SM =
    "linear-gradient(360deg, #18182a -40%, #060610 10%)";
  static GRADIENT_CARD_MD =
    "linear-gradient(360deg, #18182a -30%, #060610 20%)";
  static GRADIENT_CARD_LG =
    "linear-gradient(360deg, #18182a -20%, #060610 30%)";

  static NEUTRAL = {
    0: "#ffffff",
    50: "#F8F8F9",
    100: "#EFF0F1",
    150: "#DFE0E3",
    200: "#CFD1D6",
    250: "#BFC2C8",
    300: "#AEB3BA",
    350: "#9EA3AC",
    400: "#8E949F",
    450: "#7E8591",
    500: "#6F7682",
    550: "#595E68",
    600: "#4E535B",
    650: "#43474E",
    700: "#383B41",
    750: "#2C2F34",
    800: "#212327",
    850: "#16181A",
    900: "#0B0C0D",
    950: "#000000",
    1000: "#000000",
  };

  static gray2 = "c3cad7";
  static PRIMARY = {
    50: "#f3e7fd",
    100: "#e7cffb",
    150: "#dbb7fa",
    200: "#cf9ff8",
    250: "#c387f6",
    300: "#b66ff4",
    350: "#aa57f2",
    400: "#9e3ff1",
    450: "#9227ef",
    500: "#860fed",
    550: "#790ed5",
    600: "#6b0cbe",
    650: "#5e0ba6",
    700: "#50098e",
    750: "#430877",
    800: "#36065f",
    850: "#280447",
    900: "#1b032f",
    950: "#0d0118",
  };

  static RED = {
    50: "#FFEEEE",
    100: "#FFD2D3",
    200: "#FFA5A7",
    300: "#FF777B",
    400: "#FF4A4F",
    500: "#FF1D23",
    600: "#D50006",
    700: "#8E0004",
    800: "#470002",
  };

  static GREEN = {
    100: "#C2F4D2",
    200: "#84EAA6",
    300: "#47DF79",
    400: "#21BD54",
    500: "#168039",
    600: "#11602B",
    700: "#0B401D",
    800: "#06200E",
  };

  static YELLOW = {
    100: "#FFF9C4",
    200: "#FFF59D",
    300: "#FFF176",
    400: "#FFEE58",
    500: "#FFEB3B",
    600: "#FDD835",
    700: "#FBC02D",
    800: "#F9A825",
    900: "#F57F17",
  };

  /**
   * Converts a hex color code to an RGBA color string with the specified opacity.
   *
   * @param hex - The hex color code (e.g., "#RRGGBB" or "#RGB").
   * @param opacity - The opacity level (from 0 to 1).
   * @returns The RGBA color string (e.g., "rgba(255, 255, 255, 0.5)").
   */
  static hexToRgba(hex: string, opacity: number): string {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse the hex string to get the RGB values
    let r: number, g: number, b: number;

    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    } else {
      throw new Error(
        'Invalid hex color format. Expected "#RGB" or "#RRGGBB".'
      );
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
