import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { EmpAppProvider } from "./context/app.context";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThirdwebProvider } from "thirdweb/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={"6LciZM0kAAAAAKBrF_W3_JM2896W8FRJq8gOyryS"}
  >
    <ThirdwebProvider>
      <EmpAppProvider>
        <App />
      </EmpAppProvider>
    </ThirdwebProvider>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
