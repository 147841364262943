import { useCallback, useEffect, useRef, useState } from "react";
import CampaignApi from "../../api/campaign-msvc/campaign.api";
import SearchIcon from "../../components/icon/search-icon";
import CreateCampaignModal, {
  CreateCampaignModalRef,
} from "../../components/modals/create-campaign-modal";
import EmpButton from "../../components/shared/emp-button/emp-button";
import { EmpCampaignCard } from "../../components/shared/emp-campaign-card/emp-campaign-card";
import EmpMultiFilter, {
  MultiFilterOptionRef,
} from "../../components/shared/emp-multi-filter/emp-multi-filter";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import { campaignObjectiveOptions } from "../../constants/selectConstants";
import { CampaignDto } from "../../model/campaign/campaign.dto";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { IFormGroup } from "../../utilities/formUtils/formGroup";
import _debounce from "lodash/debounce";
import "./brand-campaign-page.scss";
import AnnouncementIcon from "../../components/icon/announcement-icon";
import { useCountGridColumns } from "../../hooks/useCountGridColumns";

interface Props {}

const BrandBrowseCampaignView = (props: Props) => {
  const campaignsRef = useRef<CampaignDto[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const currentFilteredObjectives = useRef<string[]>([]);
  const objectiveMultiFilterRef = useRef<MultiFilterOptionRef>();

  const [form] = useState<IFormGroup>({
    search: new FormControl("text", []),
  });

  const createCampaignModalRef = useRef<CreateCampaignModalRef>();
  const resizeDivRef = useRef<HTMLDivElement>(null);
  // Determining Responsive card width

  const gridColumns = useCountGridColumns(resizeDivRef);
  const [shimmerCard, setShimmerCards] = useState<number[]>([]);

  useEffect(() => {
    setShimmerCards(Array.from(Array(gridColumns * 2).keys()));
  }, [gridColumns]);
  /**
   * Fetches brand campaigns from the API and updates the state with the fetched data.
   * @returns {Promise<void>} A Promise that resolves when the campaigns are fetched and the state is updated.
   */
  const fetchBrandCampaigns = useCallback(async () => {
    try {
      const resp = await CampaignApi.fetchCampaigns();
      campaignsRef.current = resp.data;
      setCampaigns(resp.data);
      controlsOnChange();
    } catch (e) {
      setCampaigns([]);
      setFilteredCampaigns([]);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch campaigns"
      );
    } finally {
      setLoading(false);
    }
  }, [form.search]);

  /**
   * Handles the change event for the controls (search keyword and campaign objectives).
   * Filters the campaigns based on the search keyword and selected campaign objectives,
   * and updates the state with the filtered campaigns.
   */
  const controlsOnChange = (): void => {
    // Filter by searchkeyword first
    let filteredCamapigns: CampaignDto[] = [];
    const searchKeyword = form.search.getValue().toLowerCase();
    filteredCamapigns = campaignsRef.current.filter((elem) =>
      elem.name.toLowerCase().trim().includes(searchKeyword)
    );

    // Filter by campaign objectives next
    if (currentFilteredObjectives.current.length > 0) {
      filteredCamapigns = filteredCamapigns.filter((elem) =>
        currentFilteredObjectives.current.includes(elem.objective)
      );
    }
    setFilteredCampaigns([...filteredCamapigns]);
  };

  const updateFormSearch = useCallback(
    (value: string) => {
      form.search.forceUpdateValue(value);
    },
    [form]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get("search");
    if (search) {
      updateFormSearch(search);
    }
    fetchBrandCampaigns();

    return () => {
      const url = window.location.pathname + window.location.hash;
      window.history.replaceState(null, "", url);
    };
  }, [fetchBrandCampaigns, updateFormSearch]);

  return (
    <div className="mt-4">
      <CreateCampaignModal
        ref={createCampaignModalRef}
        onSave={() => {
          fetchBrandCampaigns();
        }}
      />
      <div className="controls-section">
        <div className="form-control-section">
          <div className="search-textbox">
            <EmpTextInput
              labelText="Search Campaign"
              id={"search"}
              onKeypress={(e) => {
                if (e === "Enter") {
                  fetchBrandCampaigns();
                }
              }}
              onChange={() => {
                controlsOnChange();
              }}
              formControl={form.search}
              placeholder={"Search Campaign Keywords"}
              leftIconComponent={SearchIcon}
            />
          </div>
          <EmpMultiFilter
            ref={objectiveMultiFilterRef}
            menuItems={campaignObjectiveOptions}
            controlLabel={"Filter Objectives"}
            onChange={(values: string[]) => {
              currentFilteredObjectives.current = values;
              controlsOnChange();
            }}
          />
        </div>
        <EmpButton
          className="action-button"
          onSubmit={() => {
            createCampaignModalRef.current?.show();
          }}
          isFullWidth={false}
          rightIcon={AnnouncementIcon}
          text={"Create Campaign"}
        />
      </div>
      <div className="cards-section-wrapper">
        {filteredCampaigns !== undefined && (
          <span className="records-title">
            {filteredCampaigns.length} Campaign
            {filteredCampaigns.length > 1 ? "s" : ""} found
          </span>
        )}
      </div>

      <div className="campaign-card-section" ref={resizeDivRef}>
        {!isLoading &&
          filteredCampaigns.length > 0 &&
          filteredCampaigns.map((elem) => {
            return (
              <EmpCampaignCard
                redirectUrl={`/brand/campaign-details/${elem.id}`}
                key={elem.id}
                campaign={elem}
              />
            );
          })}
        {!isLoading && filteredCampaigns.length === 0 && (
          <div className="blank-overlay">
            <div className="info-wrapper">
              <img
                className="empty-img"
                alt="No talents found"
                srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-state.png"
              />
              <h2 className="header">No Campaigns found</h2>
              <p className="description">
                Please try another search query. Looking to create a new
                campaign? Click on the button below
              </p>
              <EmpButton
                onSubmit={() => {
                  createCampaignModalRef.current?.show();
                }}
                isFullWidth={false}
                text={"Create Campaign"}
              />
            </div>
          </div>
        )}
        {!isLoading &&
          filteredCampaigns.length === 0 &&
          shimmerCard.map((elem) => {
            return (
              <div className="blank-card">
                <div className="blank-card-wrapper">
                  <div className="top-section">
                    <div className="shimmer-block shimmer-color"></div>
                  </div>
                  <div className="bottom-section">
                    <div className="name-block shimmer-color"></div>
                    <div className="metrics">
                      <div className="metric-block-wrapper">
                        <div className="metric-title-block-1 shimmer-color"></div>
                        <div className="metric-value-block-1 shimmer-color"></div>
                      </div>
                      <div className="metric-block-wrapper">
                        <div className="metric-title-block-2 shimmer-color"></div>
                        <div className="metric-value-block-2 shimmer-color"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {isLoading &&
          shimmerCard.map((elem) => {
            return <div key={elem} className="loading-card emp-shimmer"></div>;
          })}
      </div>
    </div>
  );
};

export default BrandBrowseCampaignView;
