import { useEffect, useRef, useState } from "react";
import "./brand-campaign-page.scss";

import EmpSecondaryTabBar from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import BrandBrowseCampaignView from "./brand-browse-campaign-view";
import BrandBrowseTaskView from "./brand-browse-task-view";
import BrandCompletedTasksView from "./brand-completed-tasks-view";
import CampaignGuideModal, {
  CampaignGuideModalRef,
} from "../../components/modals/campaign-guide-modal";
import { Color } from "../../utilities/colors";
import HelpCircleIcon from "../../components/icon/help-circle-icon";
import EmpIconButton from "../../components/shared/emp-icon-button/emp-icon-button";

export const BrandCampaignPage = () => {
  const campaignGuideModalRef = useRef<CampaignGuideModalRef>();
  const [tabs, setTabs] = useState([
    {
      display: "My Campaigns",
      text: "My Campaigns",
      isSelected: false,
    },
    {
      display: "My Tasks",
      text: "My Tasks",
      isSelected: false,
    },
    {
      display: "Completed Tasks",
      text: "Completed Tasks",
      isSelected: false,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  useEffect(() => {
    let fragment = window.location.hash.slice(1);
    setTabs((prevTabs) => {
      let modifiedTab = prevTabs;
      if (fragment) {
        fragment = fragment.replaceAll("%20", " ");
        const fragmentHash = prevTabs.find((elem) => elem.text === fragment);
        if (!fragmentHash)
          modifiedTab = prevTabs.map((tab, index) =>
            index === 0 ? { ...tab, isSelected: true } : tab
          );
        else fragmentHash.isSelected = true;
      } else {
        modifiedTab = prevTabs.map((tab, index) =>
          index === 0 ? { ...tab, isSelected: true } : tab
        );
      }
      return [...modifiedTab];
    });
  }, []);

  // Campaign Card
  return (
    <div className="emp-page-wrapper emp-responsive">
      <CampaignGuideModal ref={campaignGuideModalRef} />
      <div className="bg-tint"></div>

      <div className="emp-page-content-no-padding emp-brand-campaign-page">
        <div className="header-wrapper">
          <h1 className="page-header">My Campaigns</h1>
          <EmpIconButton
            onSubmit={() => {
              campaignGuideModalRef.current?.show();
            }}
            buttonStyle="secondary"
            suppressMobileView
            icon={
              <HelpCircleIcon backgroundColor={Color.NEUTRAL[400]} size={18} />
            }
          />
        </div>
        <EmpSecondaryTabBar
          tabs={tabs}
          onChange={(tabLabel: string) => {
            setSelectedTab(tabLabel);
            window.location.hash = tabLabel;
          }}
        />
        <div>
          {selectedTab === "My Campaigns" && <BrandBrowseCampaignView />}
          {selectedTab === "My Tasks" && <BrandBrowseTaskView />}
          {selectedTab === "Completed Tasks" && <BrandCompletedTasksView />}
        </div>
      </div>
    </div>
  );
};
