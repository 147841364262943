import EmpException from "../../exception/empException";
import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";

export class LengthValidator extends BaseValidator implements IValidator {
  minLength;
  maxLength;
  customMinErrorMsg?: string = undefined;
  customMaxErrorMsg?: string = undefined;

  constructor(
    minLength: number,
    maxLength: number,
    customMinErrorMsg?: string,
    customMaxErrorMsg?: string
  ) {
    super();
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.customMinErrorMsg = customMinErrorMsg;
    this.customMaxErrorMsg = customMaxErrorMsg;
  }

  /**
   * @purpose Perform valdiation based on supplied min length and max length
   * @param formControl: IFormControl
   * @returns True if valid | False if invalid
   */
  validate(formControl: FormControl): boolean {
    const DEFAULT_MIN_ERR = `Minumum of ${this.minLength} characters allowed`;
    const DEFAULT_MAX_ERR = `You can't exceed ${this.maxLength} characters`;

    const minErrMsg = this.customMinErrorMsg ?? DEFAULT_MIN_ERR;
    const maxErrMsg = this.customMaxErrorMsg ?? DEFAULT_MAX_ERR;

    switch (formControl.formType) {
      case "text":
        return this.validateTextType(formControl, minErrMsg, maxErrMsg);
      case "rich-text":
        return this.validateRichTextType(formControl, minErrMsg, maxErrMsg);
      default:
        throw new EmpException("Incorrect form type");
    }
  }

  countRichTextCharacters = (value: any) => {
    let totalCharacters = 0;
    function traverse(currentNode: any) {
      if (Array.isArray(currentNode)) {
        currentNode.forEach((childNode) => traverse(childNode));
      } else if (currentNode && typeof currentNode === "object") {
        if (
          currentNode.hasOwnProperty("text") &&
          typeof currentNode.text === "string"
        ) {
          totalCharacters += currentNode.text.length;
        }
        if (currentNode.hasOwnProperty("children")) {
          traverse(currentNode.children);
        }
      }
    }
    traverse(value);
    return totalCharacters;
  };

  validateTextType = (
    formControl: FormControl,
    minErrMsg: string,
    maxErrMsg: string
  ) => {
    const value = formControl.getValue();
    const evaluable = value ? value.trim() : "";

    if (evaluable.length < this.minLength && evaluable.length > 0) {
      this.hasError(formControl, minErrMsg);
      return false;
    } else if (evaluable.length > this.maxLength) {
      this.hasError(formControl, maxErrMsg);
      return false;
    }
    this.noError(formControl);
    return true;
  };

  validateRichTextType = (
    formControl: FormControl,
    minErrMsg: string,
    maxErrMsg: string
  ) => {
    const length = this.countRichTextCharacters(formControl.getValue());
    if (length < this.minLength && length > 0) {
      this.hasError(formControl, minErrMsg);
      return false;
    } else if (length > this.maxLength) {
      this.hasError(formControl, maxErrMsg);
      return false;
    }
    this.noError(formControl);
    return true;
  };
}
