import { Tooltip, Whisper } from "rsuite";
import { IconProps } from "../../../model/common/icon";
import { Color } from "../../../utilities/colors";
import "./EmpPill.scss";
import InfoCircleIcon from "../../icon/info-circle-icon";

interface EmpPillProps {
  Icon?: React.ComponentType<IconProps>;
  backgroundColor?: string;
  color?: string;
  text: string | JSX.Element;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  size?: "sm" | "md";
  fullWidth?: boolean;
  tooltip?: string;
}

const EmpPill = ({
  Icon,
  text,
  style,
  icon,
  size = "md",
  backgroundColor = Color.PRIMARY[500],
  color = Color.NEUTRAL[100],
  fullWidth,
  tooltip,
}: EmpPillProps) => {
  const pillStyle: React.CSSProperties = {
    backgroundColor,
    color,
  };

  return (
    <div
      style={style}
      className={`emp-pill-wrapper ${fullWidth ? "w-full" : ""}`}
    >
      <div style={pillStyle} className={`emp-pill ${size}`}>
        {icon && <span className="emp-pill-icon">{icon}</span>}
        {Icon && (
          <span className="emp-pill-icon-left">
            <Icon size={12} backgroundColor={color} />
          </span>
        )}

        <span>{text}</span>
        {tooltip && (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>{tooltip}</Tooltip>}
          >
            <div className="emp-pill-icon-right">
              <InfoCircleIcon size={13} backgroundColor={color} />
            </div>
          </Whisper>
        )}
      </div>
    </div>
  );
};
export default EmpPill;
