import html2canvas from "html2canvas";

export function analyse(img: HTMLImageElement, border: number) {
  var canvas = document.createElement("canvas"), // create a canvas element
    ctx = canvas.getContext("2d")!, // get context
    w = img.naturalWidth, // get actual width..
    h = img.naturalHeight;

  canvas.width = w; // set canvas size
  canvas.height = h;

  ctx.drawImage(img, 0, 0); // draw in image

  // do checks:, for example:
  //if (border*2 > canvas.width || border*2 > canvas.height) throw "Image too small!";

  // get borders, avoid overlaps (though it does not really matter in this case):
  var top = ctx.getImageData(0, 0, w, border).data;
  var left = ctx.getImageData(0, border, border, h - border * 2).data;
  var right = ctx.getImageData(w - border, border, border, h - border * 2).data;
  var bottom = ctx.getImageData(0, h - border, w, border).data;

  var r = 0,
    g = 0,
    b = 0,
    cnt = 0;

  // count pixels and add up color components: (see function below)
  countBuffer(top);
  countBuffer(left);
  countBuffer(right);
  countBuffer(bottom);

  // calc average
  r = (r / cnt + 0.5) | 0;
  g = (g / cnt + 0.5) | 0;
  b = (b / cnt + 0.5) | 0;

  return "rgb(" + r + "," + g + "," + b + ")";

  function countBuffer(data: Uint8ClampedArray) {
    var i = 0,
      len = data.length;
    while (i < len) {
      r += data[i++]; // add red component etc.
      g += data[i++];
      b += data[i++];
      i++;
      cnt++; // count one pixel
    }
  }
}

export function getColorBrightness(color: string): string {
  // Extract the RGB values from the input color string
  const match = color.match(/\d+/g);
  if (!match) {
    throw new Error("Invalid color format");
  }
  const [r, g, b] = match.map(Number);

  // Calculate the brightness using a standard formula (perceived brightness)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return "black" if the color is light, "white" if the color is dark
  return brightness >= 128 ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)";
}

export function getShade(color: string): "dark" | "light" {
  // Extract the RGB values from the input color string
  const match = color.match(/\d+/g);
  if (!match) {
    throw new Error("Invalid color format");
  }
  const [r, g, b] = match.map(Number);

  // Calculate the brightness using a standard formula (perceived brightness)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return "black" if the color is light, "white" if the color is dark
  return brightness >= 128 ? "dark" : "light";
}

export function calculateLighterShade(
  color: string,
  lightnessFactor: number
): string {
  const [r, g, b] = color.match(/\d+/g)!.map(Number);

  const lighterR = Math.min(r + lightnessFactor, 255);
  const lighterG = Math.min(g + lightnessFactor, 255);
  const lighterB = Math.min(b + lightnessFactor, 255);

  return `rgb(${lighterR}, ${lighterG}, ${lighterB})`;
}

export function calculateDarkerShade(
  color: string,
  lightnessFactor: number
): string {
  const [r, g, b] = color.match(/\d+/g)!.map(Number);

  const lighterR = Math.min(r - lightnessFactor, 255);
  const lighterG = Math.min(g - lightnessFactor, 255);
  const lighterB = Math.min(b - lightnessFactor, 255);

  return `rgb(${lighterR}, ${lighterG}, ${lighterB})`;
}

export function convertHtmlToJpgBase64(
  element: HTMLDivElement
): Promise<string> {
  return new Promise((resolve, reject) => {
    html2canvas(element, { useCORS: true })
      .then((canvas) => {
        // Convert the canvas to base64 JPEG
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
