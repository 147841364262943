import axios from "axios";
import CampaignFileUploaderApi, {
  CompleteMultipartUploadRequestDto,
} from "../api/campaign-msvc/file-uploader.api";
import ToastUtils from "./toast-utils";
import EmpException from "../exception/empException";

type CompleteMultipartUploadDto = {
  key: string;
  url: string;
};

function prepareFile(file: File) {
  const maxNameLength = 20;
  const fileExt = file.name.substring(file.name.lastIndexOf("."));
  const namePartMaxLength = maxNameLength - fileExt.length;
  const namePart = file.name
    .substring(0, file.name.lastIndexOf("."))
    .substring(0, namePartMaxLength);
  const truncatedFileName = `${namePart}${fileExt}`;
  const fileWithTruncatedName = new File([file], truncatedFileName, {
    type: file.type,
  });
  return fileWithTruncatedName;
}

async function createMultipartUpload(file: File, keyPrefix: string) {
  const key = `${keyPrefix}/${Date.now()}-${file.name}`; // The key for the file in the bucket
  const response = await CampaignFileUploaderApi.createMultipartUpload(key);
  if (response.status === "success") {
    return response.data;
  } else {
    ToastUtils.error(
      "Error uploading video",
      response.error?.message as string
    );
    return null;
  }
}

export type ProgressTrackerType = { num: number; max: number };
async function uploadPart(
  file: File,
  uploadId: string,
  key: string,
  parts: any[],
  progressTrackerOptions?: ProgressTrackerOptions
) {
  const CHUNK_SIZE = 50 * 1024 * 1024;
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  const partNumberMap = new Map<number, ProgressTrackerType>();

  for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
    const start = (partNumber - 1) * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const blob = file.slice(start, end);

    // Generate presigned URL for the part
    const presignedUrlResponse =
      await CampaignFileUploaderApi.generatePresignedUrl(
        partNumber,
        uploadId,
        key
      );
    const presignedUrl = presignedUrlResponse.data;

    try {
      // Upload the part to the presigned URL using Axios
      const response = await axios.put(presignedUrl, blob, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          partNumberMap.set(partNumber, {
            num: progressEvent.loaded ?? 0,
            max: progressEvent.total ?? 0,
          });

          const totalBytes = file.size;

          const downloadedBytes = calculateSum(partNumberMap, totalChunks);
          console.log(downloadedBytes, totalBytes);

          if (progressTrackerOptions) {
            progressTrackerOptions.setProgress({
              downloadedBytes,
              totalBytes,
              status: "Uploading",
              id: progressTrackerOptions.id,
            });
          }

          // const uploadedBytes = Array.from(partNumberMap.values()).reduce(
          //   (acc, bytes) => acc + bytes,
          //   0
          // );
          // const percentCompleted = Math.round(
          //   (uploadedBytes / totalBytes) * 100
          // );
          // console.log(totalBytes, percentCompleted);

          // Call the progress callback function with the progress value
        },
      });
      const etag = response.headers["etag"];

      // Add the ETag and part number to the parts array
      parts.push({ ETag: etag, PartNumber: partNumber });
    } catch (error) {
      ToastUtils.error("Error uploading media", error as string);
      throw error; // Rethrow or handle as needed
    }
  }
}

function calculateSum(
  map: Map<number, ProgressTrackerType>,
  expectedLength: number
) {
  let sum = 0;
  const mapEntries = Array.from(map.entries()); // Convert map to an array of [key, value] pairs
  const actualLength = mapEntries.length; // Get the actual length of the map
  const lengthToUse = Math.min(expectedLength, actualLength); // Use the smaller of the two lengths

  for (let i = 0; i < lengthToUse; i++) {
    const element = mapEntries[i][1]; // Access the element (the value of the map entry)
    if (i === lengthToUse - 1) {
      // For the last element, take the "num"
      sum += element.num;
    } else {
      // For all other elements, take the "max"
      sum += element.max;
    }
  }

  return sum;
}

const completeMultipartUpload = async (
  completeMultipartData: CompleteMultipartUploadRequestDto
): Promise<CompleteMultipartUploadDto | null> => {
  try {
    const response = await CampaignFileUploaderApi.completeMultipart(
      completeMultipartData
    );

    if (response.status === "success") {
      return response.data as unknown as CompleteMultipartUploadDto;
    } else {
      ToastUtils.error(
        "Error completing multipart upload",
        response.error?.message as string
      );
      return null;
    }
  } catch (error) {
    ToastUtils.error("Error completing multipart upload", error as string);
    return null;
  }
};

type ProgressTrackerOptions = {
  setProgress: React.Dispatch<React.SetStateAction<ProgressType | undefined>>;
  id: string;
};
export type ProgressType = {
  id: string;
  downloadedBytes: number;
  totalBytes: number;
  status: string;
};

export async function uploadCampaignFileToS3(
  file: File,
  keyPrefix: string,
  progressTrackerOptions?: ProgressTrackerOptions
) {
  try {
    // Step 1: Create Multipart Upload
    const processedFile = prepareFile(file);

    const multipartResponse = await createMultipartUpload(
      processedFile,
      keyPrefix
    );
    if (!multipartResponse) {
      console.error(`Failed to create multipart upload for ${file.name}`);
      throw new EmpException(
        `Failed to create multipart upload for ${file.name}`
      );
    }
    const { Key: key, UploadId: uploadId } = multipartResponse;

    // Step 2: Upload File Parts
    let parts: any[] = [];
    await uploadPart(file, uploadId, key, parts, progressTrackerOptions);

    // Step 3: Complete multipart data.
    const result: CompleteMultipartUploadDto | null =
      await completeMultipartUpload({ key, uploadId, parts });

    if (!result) {
      throw new EmpException(`Unable to complete multipart ${file.name}`);
    }
    return result.url;
  } catch (error) {
    console.error(error);
    return "no url";
  }
}
