import { IntlShape } from "react-intl";

export const getAirwallexErrorConstants = (
  intl: IntlShape
): { [key: string]: string } => {
  return {
    "001": intl.formatMessage({ id: "validation_airwallex_001" }),
    "008": intl.formatMessage({ id: "validation_airwallex_008" }),
    "032": intl.formatMessage({ id: "validation_airwallex_032" }),
  };
};
