import "./brand-social-media-manager.scss";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import EmpSecondaryTabBar, {
  EmpTab,
} from "../../components/shared/emp-secondary-tabbar/emp-secondary-tabbar";
import BrandSocialMediaManagerOverviewTab from "./tab/overview";
import BrandSocialMediaManagerCalendarTab from "./tab/brand-social-media-manager-calendar-tab";
import SocialMediaManagementApi from "../../api/smm-msvc/social-media-management.api";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import UserUtils from "../../utilities/user-utils";
import { AppContext } from "../../context/app.context";
import { SmConnectionBriefRespDto } from "../../model/smm/smm-connection-brief-resp.dto";
import { UserDto } from "../../model/user-management/user.dto";
import EmpException from "../../exception/empException";
import EmpInternalServerError from "../../exception/empInternalServerError";
import ToastUtils from "../../utilities/toast-utils";
import { SM_REDIRECT } from "../../constants/app.constants";
import useSubscriptionGuard from "../../hooks/useSubscriptionGuard";

export const BrandSocialMediaManagerPage = () => {
  const intl = useIntl();
  const { hasSubscription } = useSubscriptionGuard();

  useEffect(() => {
    console.log(hasSubscription);
  }, [hasSubscription]);

  const [connects, setConnects] = useState<SmConnectionBriefRespDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user: userContext } = useContext(AppContext);

  const tabsConstant: EmpTab[] = useMemo(() => {
    return [
      {
        display: intl.formatMessage({ id: "brandSocialMediaPage_overviewTab" }),
        text: "Overview",
        isSelected: false,
      },
      {
        display: intl.formatMessage({ id: "brandSocialMediaPage_calendarTab" }),
        text: "Calendar",
        isSelected: false,
      },
    ];
  }, [intl]);

  const [tabs, setTabs] = useState<EmpTab[]>([]);

  const [selectedTab, setSelectedTab] = useState<string>("Overview");

  const handleTabChange = (tabLabel: string) => {
    setSelectedTab(tabLabel);
    window.location.hash = tabLabel;
  };

  const fetchCurrentUser = useCallback(async () => {
    return UserUtils.fetchUser(userContext);
  }, [userContext]);

  const validateOrganisationMembership = (user: UserDto) => {
    // Check if 'organisation' is defined and has at least one item
    if (!user.organisation || user.organisation.length === 0) {
      throw new EmpException("Not an organisation");
    }
  };

  const fetchSocialMediaConnections = useCallback(async () => {
    try {
      const user = await fetchCurrentUser();
      validateOrganisationMembership(user);
      if (user.organisation) {
        const brandId = user.organisation[0].id;
        const resp = await SocialMediaManagementApi.getSocialMediaConnection(
          brandId
        );
        setConnects(resp.data);
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch seller latest negotiation"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSocialMediaConnections();
  }, [fetchSocialMediaConnections]);

  useEffect(() => {
    const selectedTab = tabs.find((elem) => elem.isSelected);
    setSelectedTab(selectedTab ? selectedTab.text : "none");
  }, [tabs]);

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    const updateTabs = () => {
      let fragment = decodeURIComponent(window.location.hash.slice(1));
      let found = false;

      const modifiedTab = tabsConstant.map((tab, index) => {
        if (tab.text === fragment) {
          found = true;
          return { ...tab, isSelected: true };
        } else if (index === 0 && !fragment) {
          return { ...tab, isSelected: true };
        }
        return { ...tab, isSelected: false };
      });
      if (!found && fragment) {
        modifiedTab[0] = { ...modifiedTab[0], isSelected: true };
      }

      return modifiedTab;
    };
    setTabs(updateTabs());
  }, [tabsConstant, setTabs, fetchSocialMediaConnections]);

  useEffect(() => {
    setTabs((prev) => {
      return [...prev];
    });
  }, [intl]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const storedValue = localStorage.getItem("branch-blind-social");

    const bindTikTokAccount = async (TikTokCode: string) => {
      try {
        const user = await fetchCurrentUser();
        validateOrganisationMembership(user);

        if (user.organisation) {
          const brandId = user.organisation[0].id;
          const response = await SocialMediaManagementApi.smLogin(brandId, {
            socialMediaPlatform: "Tiktok",
            code: TikTokCode,
            socialMediaUserId: "",
          });

          if (response.data.status === "success") {
            fetchSocialMediaConnections();
            ToastUtils.success(
              "Account Binded",
              "You have binded your TikTok account"
            );
          }
        }
      } catch (e) {
        if (e instanceof EmpInternalServerError) {
          console.log(e.message);
          console.log(e.name);
        }
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to TikTok account"
        );
      } finally {
        localStorage.removeItem("branch-blind-social");
      }
    };

    const bindXAccount = async (XCode: string) => {
      try {
        const user = await fetchCurrentUser();
        validateOrganisationMembership(user);

        if (user.organisation) {
          const brandId = user.organisation[0].id;

          const response = await SocialMediaManagementApi.smLogin(brandId, {
            socialMediaPlatform: "x",
            code: XCode,
            socialMediaUserId: "",
          });
          if (response.data.status === "success") {
            fetchSocialMediaConnections();
            ToastUtils.success(
              "Account Binded",
              "You have binded your X account successfully."
            );
          }
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to X account"
        );
      }
    };

    if (
      code &&
      storedValue &&
      JSON.parse(storedValue) === SM_REDIRECT.BRAND_SMM
    ) {
      bindTikTokAccount(code);
    } else if (
      code &&
      storedValue &&
      JSON.parse(storedValue) === SM_REDIRECT.BRAND_SMM
    ) {
      bindXAccount(code);
    } else {
      localStorage.removeItem("branch-blind-social");
    }
  }, []);

  return (
    <div className="emp-page-wrapper emp-responsive">
      <div className="emp-page-content-no-padding emp-brand-social-media-manager-page ">
        <div className="header-wrapper header-space-between ">
          <h1 className="page-header">
            <FormattedMessage id="menu_social" />
          </h1>
        </div>
        {/* <EmpSecondaryTabBar tabs={tabs} onChange={handleTabChange} /> */}
        {selectedTab === "Overview" && (
          <BrandSocialMediaManagerOverviewTab
            connects={connects}
            loading={loading}
            fetchSocialMediaConnections={fetchSocialMediaConnections}
          />
        )}
        {selectedTab === "Calendar" && (
          <BrandSocialMediaManagerCalendarTab
            connects={connects}
            loading={loading}
            fetchSocialMediaConnections={fetchSocialMediaConnections}
          />
        )}
      </div>
    </div>
  );
};
