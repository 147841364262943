import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { TaskDto } from "../../../model/campaign/task.dto";
import EmpModal from "../../shared/emp-modal/emp-modal";
import { Color } from "../../../utilities/colors";
import XCloseIcon from "../../icon/x-close-icon";
import "./submit-evidence-select-sm-modal.scss";
import { FormattedMessage } from "react-intl";
import { UnlinkBtn } from "../../../pages/creator-home-page/dashboard-cards/unlink-btn";
import FacebookIcon from "../../icon/facebook-icon";
import InstagramIcon from "../../icon/instagram-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import XIcon from "../../icon/x-icon";
import { SmBriefRespDto } from "../../../model/social-media/sm-brief-resp.dto";
import SocialMediaApi from "../../../api/social-integration-msvc/social-media.api";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import EmpButton from "../../shared/emp-button/emp-button";
import ToastUtils from "../../../utilities/toast-utils";
import {
  FacebookLoginAttributes,
  useFacebookLogin,
} from "../../../pages/creator-home-page/dashboard-cards/hooks/useFacebookLogin";
import FacebookPageSelectionModal, {
  FacebookPageSelectionModalRef,
} from "../../../pages/creator-home-page/dashboard-cards/facebook-page-selection-modal";
import InstagramConnectInfoModal, {
  InstagramConnectInfoModalRef,
} from "../../../pages/creator-home-page/dashboard-cards/instagram-connect-info-modal";
import InstagramAccountSelectionModal, {
  InstagramAccountSelectionModalRef,
} from "../../../pages/creator-home-page/dashboard-cards/instagram-account-selection-modal";
import { useInstagramLogin } from "../../../pages/creator-home-page/dashboard-cards/hooks/useInstagramLogin";
import {
  SM_REDIRECT,
  SOCIAL_MEDIA_TYPE,
} from "../../../constants/app.constants";
import {
  BindTikTokAccountFn,
  useTikTokLogin,
} from "../../../pages/creator-home-page/dashboard-cards/hooks/useTikTokLogin";
import {
  BindXAccountFn,
  useXLogin,
} from "../../../pages/creator-home-page/dashboard-cards/hooks/useXLogin";
import { OngoingDeliverableExtendedDto } from "../../../model/campaign/ongoing-deliverable-extended.dto";
import { TikTokLoader } from "../../loaders/tiktok-loader";
import { XLoader } from "../../loaders/x-loader";
import useUser from "../../../hooks/useUser";

interface Props {
  task: TaskDto;
  ongoingDeliverable: OngoingDeliverableExtendedDto;
  onSelect: (platform: string) => void;
  onDismiss?: () => void;
}

export interface SubmitEvidenceSelectSmModalRef {
  show: (creatorUserId: string) => void;
  dismiss: () => void;
}
const SubmitEvidenceSelectSmModal = forwardRef((prop: Props, ref) => {
  const { task, ongoingDeliverable, onSelect, onDismiss } = prop;
  const { sellerType } = useUser();
  useEffect(() => {
    console.log({ sellerType });
  }, [sellerType]);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const [visible, setVisible] = useState<boolean>(false);
  const [socialMediaSpecs, setSocialMediaSpecs] = useState<SmBriefRespDto[]>(
    []
  );
  // Load Social Media Hooks
  const facebookPageSelectionModalRef = useRef<FacebookPageSelectionModalRef>();
  const instagramAccountSelectionModalRef =
    useRef<InstagramAccountSelectionModalRef>();
  const instagramConnectInfoModalRef = useRef<InstagramConnectInfoModalRef>();

  // Social Media Accounts Hooks
  const {
    isLoading: isFacebookLoading,
    facebookLogin,
    bindFacebookAccount,
    facebookLogout,
  } = useFacebookLogin(facebookPageSelectionModalRef);

  const {
    isLoading: isInstagramLoading,
    instagramLogin,
    bindInstagramAccount,
  } = useInstagramLogin(instagramAccountSelectionModalRef);

  const {
    isLoading: isTikTokLoading,
    tiktokLogin,
    bindTikTokAccount,
  } = useTikTokLogin();
  const { xLogin, bindXAccount } = useXLogin();

  const [smIsLoading, setSmIsLoading] = useState<"tiktok" | "x" | "none">(
    "none"
  );

  const platformToLoaderBinding: { [key: string]: boolean } = useMemo(() => {
    return {
      Facebook: isFacebookLoading,
      Instagram: isInstagramLoading,
      TikTok: isTikTokLoading,
    };
  }, [isFacebookLoading, isInstagramLoading, isTikTokLoading]);
  const creatorUserIdRef = useRef<string>();

  /**
   * Fetches and sets social media metrics for a given creator user ID.
   *
   * @function
   * @param {string} creatorUserId - The ID of the creator whose social media metrics are to be fetched.
   * @returns {Promise<void>} A promise that resolves when the metrics are successfully fetched and set, or rejects with an error.
   */
  const getUserSocialMediaMetrics = useCallback(
    async (creatorUserId: string) => {
      try {
        const resp = await SocialMediaApi.getSocialMediaSpecByCreatorId(
          creatorUserId
        );
        setSocialMediaSpecs(resp.data);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to connect to Facebook"
        );
      }
    },
    []
  );

  const show = useCallback(
    async (creatorUserId: string) => {
      creatorUserIdRef.current = creatorUserId;
      setVisible(true);
      getUserSocialMediaMetrics(creatorUserId);
    },
    [getUserSocialMediaMetrics]
  );

  const dismiss = useCallback(async () => {
    setVisible(false);
    if (onDismiss) onDismiss();
  }, [onDismiss]);

  /**
   * Unlinks a social media account from the user's profile based on the specified platform.
   *
   * @function
   * @param {string} platform - The social media platform to be unlinked (e.g., "Facebook").
   * @returns {Promise<void>} A promise that resolves when the unlinking process is complete and updates are fetched, or rejects with an error.
   */
  const onUnlink = useCallback(
    async (platform: string) => {
      try {
        const resp = await SocialMediaApi.unlinkSocialMedia(platform);
        if (resp.data.status === "success") {
          ToastUtils.success(
            "Successfully Unlinked",
            `Your ${platform} account has been unlinked`
          );
          if (creatorUserIdRef.current)
            getUserSocialMediaMetrics(creatorUserIdRef.current);
        }
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          `Unable to unlink ${platform}`
        );
      }
    },
    [getUserSocialMediaMetrics]
  );

  /**
   * Completes the connection process for the specified social media platform by binding the account and updating user metrics.
   *
   * @function
   * @param {"tiktok" | "x"} platform - The social media platform to bind (either "tiktok" or "x").
   * @param {string} code - The code used to bind the social media account.
   * @param {BindXAccountFn | BindTikTokAccountFn} bindAccount - The function to bind the social media account.
   * @returns {Promise<void>} A promise that resolves when the connection process is complete.
   */
  const completeConnection = useCallback(
    async (
      platform: "tiktok" | "x",
      code: string,
      bindAccount: BindXAccountFn | BindTikTokAccountFn
    ) => {
      setSmIsLoading(platform);
      await bindAccount(code, setSmIsLoading);
      getUserSocialMediaMetrics(creatorUserIdRef.current!);
      const url = new URL(window.location.href);
      url.searchParams.delete(`${platform}_code`);
      url.searchParams.delete("evidenceId");
      url.searchParams.delete("representativeId");
      window.history.replaceState("", "", url.toString());
    },
    [getUserSocialMediaMetrics, setSmIsLoading]
  );

  /**
   * Effect hook to handle social media connection based on URL parameters.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tikTokCode = urlParams.get("tiktok_code");
    const xCode = urlParams.get("x_code");
    if (tikTokCode) {
      completeConnection("tiktok", tikTokCode, bindTikTokAccount);
    } else if (xCode) {
      completeConnection("x", xCode, bindXAccount);
    }
  }, [bindTikTokAccount, bindXAccount, completeConnection]);

  /**
   * Binds the specified social media platform by initiating the appropriate login or connection process.
   *
   * @function
   * @param {string} platform - The social media platform to bind. Must be one of the SOCIAL_MEDIA_TYPE values.
   */
  const bindPlatform = useCallback(
    (platform: string) => {
      const redirectConfig = {
        ongoingDeliverableId: ongoingDeliverable.id,
        representativeId: ongoingDeliverable.representativeId,
        base: `/${task.campaign.id}/task/${task.id}`,
      };

      switch (platform) {
        case SOCIAL_MEDIA_TYPE.FACEBOOK:
          facebookLogin();
          break;
        case SOCIAL_MEDIA_TYPE.INSTAGRAM:
          instagramConnectInfoModalRef.current?.show();
          break;
        case SOCIAL_MEDIA_TYPE.TIKTOK:
          tiktokLogin(SM_REDIRECT.SELLER_PROOF_OF_WORK, redirectConfig);
          break;
        case SOCIAL_MEDIA_TYPE.X:
          xLogin(SM_REDIRECT.SELLER_PROOF_OF_WORK, redirectConfig);
          break;
        default:
          console.warn(`Unsupported platform: ${platform}`);
      }
    },
    [
      facebookLogin,
      tiktokLogin,
      xLogin,
      ongoingDeliverable.id,
      ongoingDeliverable.representativeId,
      task.campaign.id,
      task.id,
    ]
  );

  /**
   * Processes the account selection based on the specified platform and attributes.
   *
   * @function
   * @param {string} platform - The social media platform being selected (e.g., "Facebook").
   * @param {FacebookLoginAttributes} specs - The attributes required for Facebook login.
   * @returns {Promise<void>} A promise that resolves when the account binding and metric fetching are complete.
   */
  const processAccountSelection = useCallback(
    async (platform: string, specs: FacebookLoginAttributes) => {
      if (platform === SOCIAL_MEDIA_TYPE.FACEBOOK) {
        await bindFacebookAccount(specs);
      } else if (platform === SOCIAL_MEDIA_TYPE.INSTAGRAM) {
        await bindInstagramAccount(specs);
      }
      getUserSocialMediaMetrics(creatorUserIdRef.current!);
    },
    [bindFacebookAccount, getUserSocialMediaMetrics, bindInstagramAccount]
  );

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      verticalPadding={false}
      showHeaderBorder={false}
      modalGlobalElement={
        <button onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </button>
      }
      size={"sm"}
    >
      <div className="emp-submit-evidence-select-sm-modal">
        {smIsLoading === "tiktok" && <TikTokLoader />}
        {smIsLoading === "x" && <XLoader />}
        <InstagramConnectInfoModal
          ref={instagramConnectInfoModalRef}
          onSave={() => {
            instagramLogin();
          }}
        />
        <FacebookPageSelectionModal
          ref={facebookPageSelectionModalRef}
          onSave={(fbPageSpec, platform) => {
            processAccountSelection(platform, fbPageSpec);
          }}
          onDismiss={facebookLogout}
        />
        <InstagramAccountSelectionModal
          ref={instagramAccountSelectionModalRef}
          onSave={(fbPageSpec, platform) => {
            processAccountSelection(platform, fbPageSpec);
          }}
          onDismiss={facebookLogout}
        />
        <div className="modal-shine-border">
          <section className="header-section">
            <h2>Select a Social Media Account</h2>
            <p className="emp-paragraph mt-3">
              To proceed with link submission for your proof of work, please
              select a social media platform.
            </p>
          </section>
          <section className="social-media-section">
            {socialMediaSpecs.map((elem) => {
              return (
                <div key={elem.platform} className="platform-card mt-2">
                  {platformToLoaderBinding[elem.platform] && (
                    <div className="emp-progress loader">
                      <div className="indeterminate"></div>
                    </div>
                  )}
                  {/* Empty State */}
                  {!elem.isAvailable && (
                    <>
                      <div className="left-elem">
                        <div className="platform-logo">
                          {elem.platform === "Facebook" && (
                            <FacebookIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "Instagram" && (
                            <InstagramIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "TikTok" && (
                            <TikTokIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                          {elem.platform === "X" && (
                            <XIcon
                              size={28}
                              backgroundColor={Color.NEUTRAL[300]}
                            />
                          )}
                        </div>
                        <div className="content">
                          <span className="status-lbl">
                            {elem.platform} Account
                          </span>
                          {sellerType === "creator" && (
                            <span className="action-lbl">Not Connected</span>
                          )}
                          {sellerType === "agency" && (
                            <span className="action-lbl">
                              Request creator to connect this platform
                            </span>
                          )}
                        </div>
                      </div>
                      {sellerType === "creator" && (
                        <div className="right-elem">
                          <EmpButton
                            text={"Bind"}
                            onSubmit={() => {
                              bindPlatform(elem.platform);
                            }}
                            buttonStyle="secondary"
                            isFullWidth={false}
                            buttonHeight="sm"
                          />
                        </div>
                      )}
                    </>
                  )}

                  {/* Filled State */}
                  {elem.isAvailable && (
                    <>
                      <div className="left-elem">
                        <div className="social-media-img-wrapper">
                          <div className="social-media-logo-placeholder">
                            {elem.platform === "Facebook" && (
                              <FacebookIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "Instagram" && (
                              <InstagramIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "TikTok" && (
                              <TikTokIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                            {elem.platform === "X" && (
                              <XIcon
                                size={12}
                                backgroundColor={Color.NEUTRAL[0]}
                              />
                            )}
                          </div>
                          <img
                            alt={`${elem.name} profile`}
                            className="social-media-image"
                            src={elem.pictureUrl}
                          />
                        </div>
                        <div className="content">
                          <div className="top-section">
                            <span className="status-lbl">{elem.name}</span>
                            {sellerType === "creator" && (
                              <div className="unlink-btn-wrapper">
                                <UnlinkBtn
                                  onSubmit={() => {
                                    onUnlink(elem.platform);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <span className="action-lbl">
                            <FormattedMessage id="bindSocialMediaStep_socialMediaFollowers" />
                            : {elem.followers}
                          </span>
                        </div>
                      </div>
                      <div className="right-elem">
                        <EmpButton
                          text={"Select"}
                          isFullWidth={false}
                          buttonHeight="sm"
                          onSubmit={() => {
                            onSelect(elem.platform);
                            dismiss();
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </section>
          <section className="footer-section">
            <EmpButton
              text={"Close"}
              buttonStyle="secondary"
              isFullWidth={false}
              onSubmit={dismiss}
            />
          </section>
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default SubmitEvidenceSelectSmModal;
