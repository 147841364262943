import "./rate-card-readonly-modal.scss";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton from "../../components/shared/emp-button/emp-button";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { RateCardRespDto } from "../../model/social-media/rate-card-resp.dto";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import RateCardApi from "../../api/social-integration-msvc/rate-card.api";
import { FormattedMessage, useIntl } from "react-intl";
import TranslationUtil from "../../utilities/translation.util";
import EmpLink from "../shared/emp-link/emp-link";
import { PUB_SUB_TOPICS } from "../../constants/pubSubTopics";
import FormFieldUtils from "../../utilities/form-field.util";

type RateCardModalMode = "restricted" | "available" | "unavailable";
type EmpSocialMediaPlatform = "Facebook" | "Instagram" | "TikTok" | "X";
export interface RateCardReadonlyModalRef {
  show: (
    creatorId: string,
    platform: EmpSocialMediaPlatform,
    organisationId?: string
  ) => void;
  hide: () => void;
}

const RateCardReadonlyModal = forwardRef(
  (props: { mode?: RateCardModalMode }, ref) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [rateCardDto, setRateCardDto] = useState<RateCardRespDto[]>([]);
    const [isMyCreator, setIsMyCreator] = useState(true);
    const creatorIdRef = useRef<string>();
    const platformRef = useRef<string>();
    const [mode, setMode] = useState(props.mode ?? "unavailable");
    // Form is set to empty at first

    const intl = useIntl();
    useImperativeHandle(ref, () => {
      return {
        show,
        dismiss,
      };
    });

    useEffect(() => {
      setMode(props.mode ?? "unavailable");
    }, [props.mode]);

    const show = async (
      creatorId: string,
      platform: EmpSocialMediaPlatform,
      organisationId?: string
    ) => {
      creatorIdRef.current = creatorId;
      platformRef.current = platform;
      fetchRateCard(creatorId, platform, organisationId);
      setVisible(true);
    };

    const fetchRateCard = async (
      creatorId: string,
      platform: EmpSocialMediaPlatform,
      organisationId?: string
    ) => {
      try {
        const resp = await RateCardApi.readRateCard(
          creatorId,
          platform,
          organisationId
        );
        const rates = resp.data.rates;
        setIsMyCreator(resp.data.isMyCreator);
        setRateCardDto(rates);
      } catch (e) {
        EmpExceptionHandler.handleHttpRequestError(
          e,
          "Unable to fetch creator info status"
        );
      }
    };

    const dismiss = () => {
      setVisible(false);
    };

    return (
      <EmpModal
        visible={visible}
        setVisible={setVisible}
        showHeader={false}
        showFooter={false}
        showFooterBorder={false}
        showHeaderBorder={false}
        bodyPadding={false}
        onClose={dismiss}
        size={"sm"}
      >
        {/* This is body */}
        <div className="emp-readonly-rate-card-modal">
          <div
            onClick={() => dismiss()}
            className="rate-card-dismiss-icon-wrapper"
          >
            <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
          </div>
          <div className="text-content-section">
            <h2 className="title">
              <FormattedMessage
                id="rateCardReadonlyModal_header"
                values={{ platform: platformRef.current }}
              />
            </h2>
            <p className="description">
              <FormattedMessage id="rateCardReadonlyModal_desc" />
            </p>
          </div>
          {mode === "restricted" && !isMyCreator && (
            <div className="fenced-card">
              <img
                alt="paid feature"
                src="https://cdn3d.iconscout.com/3d/premium/thumb/spark-11405296-9169400.png?f=webp"
              />
              <p className="emp-paragraph">
                Showing Example Rates.{" "}
                <EmpLink
                  text={"Subscribe"}
                  onSubmit={() => {
                    PubSub.publish(PUB_SUB_TOPICS.VIEW_PLANS);
                  }}
                />{" "}
                to see actual rates!
              </p>
            </div>
          )}
          <div className="rate-section">
            <div className="rate-header-section mt-4">
              <div className="rate-name-cell">
                <span className="header-text">
                  <FormattedMessage id="rateCardReadonlyModal_rateItemColumn" />
                </span>
              </div>
              <div className="rate-value-cell">
                <span className="header-text">
                  <FormattedMessage id="rateCardReadonlyModal_estimatedPriceColumn" />
                </span>
              </div>
            </div>
            <div className="rate-content-section mv-4">
              {rateCardDto.map((elem, index) => {
                return (
                  <div key={elem.rateId}>
                    <div key={elem.rateId} className="rate-row">
                      <div className="rate-name-cell">
                        {elem.origin === "Template" && (
                          <div>
                            <span className="rate-header">
                              {TranslationUtil.translateRateCardName(
                                intl,
                                platformRef.current!,
                                elem.rateName
                              )}
                            </span>
                            <span className="rate-description mt-1">
                              {TranslationUtil.translateRateCardDescription(
                                intl,
                                platformRef.current!,
                                elem.rateName
                              )}
                            </span>
                          </div>
                        )}
                        {elem.origin === "Custom" && (
                          <div>
                            <span className="rate-header">{elem.rateName}</span>
                            <span className="rate-description mt-1">
                              {elem.description}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="rate-value-cell">
                        <span className="rate-header">
                          {elem.price > 0 && (
                            <>{FormFieldUtils.toMoney(elem.price, true)}</>
                          )}

                          {elem.price === 0 && (
                            <span
                              style={{
                                fontWeight: 600,
                                color: Color.NEUTRAL[400],
                              }}
                            >
                              <FormattedMessage id="sm_notSetLabel" />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    {/* Spacer */}
                    {elem.addOnRates.length > 0 && <div className="mt-2"></div>}
                    {elem.addOnRates.map((aor) => {
                      return (
                        <div key={aor.rateId} className="rate-row">
                          <div className="rate-name-cell">
                            <div className="nested-row-line"></div>
                            {aor.origin === "Template" && (
                              <div className="ml-2 pv-1">
                                <span className="rate-header">
                                  {TranslationUtil.translateRateCardName(
                                    intl,
                                    platformRef.current!,
                                    aor.rateName
                                  )}
                                </span>
                                <span className="rate-description mt-1">
                                  {TranslationUtil.translateRateCardDescription(
                                    intl,
                                    platformRef.current!,
                                    aor.rateName
                                  )}
                                </span>
                              </div>
                            )}
                            {aor.origin === "Custom" && (
                              <div className="ml-2 pv-1">
                                <span className="rate-header">
                                  {aor.rateName}
                                </span>
                                <span className="rate-description mt-1">
                                  {aor.description}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="rate-value-cell">
                            <span className="rate-header">
                              {aor.price > 0 && (
                                <>{FormFieldUtils.toMoney(aor.price, true)}</>
                              )}
                              {aor.price === 0 && (
                                <span
                                  style={{
                                    fontWeight: 600,
                                    color: Color.NEUTRAL[400],
                                  }}
                                >
                                  <FormattedMessage id="sm_notSetLabel" />
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className="mt-6 ph-5"
            style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
          >
            <EmpButton
              isFullWidth={false}
              buttonStyle={"secondary"}
              text={<FormattedMessage id="cta_cancel" />}
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
        {/* This is footer */}
        <div></div>
      </EmpModal>
    );
  }
);

export default RateCardReadonlyModal;
