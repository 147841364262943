import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import EmpTextInput from "../shared/emp-text-input/emp-text-input";
import { Color } from "../../utilities/colors";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import "./manage-deliverable-modal.scss";
import { ExtendedDraftDto } from "../../model/campaign/extended-draft.dto";
import { AppContext } from "../../context/app.context";
import UserUtils from "../../utilities/user-utils";
import { OrganisationRespDto } from "../../model/user/organisation-resp.dto";
import { CreateCampaignDisputeDto } from "../../model/campaign/create-campaign-dispute.dto";
import EmpException from "../../exception/empException";
import CampaignDisputeApi from "../../api/campaign-msvc/campaign-dispute.api";
import ToastUtils from "../../utilities/toast-utils";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";

export interface CreateDisputeModalRef {
  show: (draft: ExtendedDraftDto) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const CreateDisputeModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const extendedDraftRef = useRef<ExtendedDraftDto>();

  const { user: userContext } = useContext(AppContext);
  const [organisation, setOrganisation] = useState<OrganisationRespDto>();

  const saveBtnRef = useRef<EmpButtonRef>();

  // Form is set to empty at first
  const [form, setForm] = useState<IFormGroup>({
    title: new FormControl("text", [
      new RequiredValidator("Dispute Title is required"),
      new LengthValidator(
        10,
        100,
        "Deliverable name must be at least 10 characters",
        "Deliverable name must not exceed 100 characters"
      ),
    ]),
    description: new FormControl("text", [
      new RequiredValidator("Description is required"),
      new LengthValidator(
        100,
        1000,
        "Description must be at least 100 characters",
        "Description must not exceed 1000 characters"
      ),
    ]),
  });

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validate = (formControl: FormControl): void => {
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchUser = async () => {
    const fetchedOrganisation = await UserUtils.fetchOrganisation(userContext);
    setOrganisation(fetchedOrganisation);
  };

  const show = async (extendedDraft: ExtendedDraftDto) => {
    extendedDraftRef.current = extendedDraft;
    setVisible(true);
    fetchUser();
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const createDispute = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!extendedDraftRef.current)
        throw new EmpException("Extended draft information not found");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      if (!isValid) return;
      const request: CreateCampaignDisputeDto = {
        disputeName: form.title.getValue(),
        disputeDescription: form.description.getValue(),
        deliverableId: extendedDraftRef.current.deliverableId,
        creatorUserId: extendedDraftRef.current.creator.id,
      };
      const resp =
        await CampaignDisputeApi.createCampaignDisputeAsBrand(request);
      if (resp.data.status === "success") {
        ToastUtils.success(
          "Dispute Submitted",
          "Dispute received. Your Customer Success Officer will respond promptly."
        );
        dismiss();
        return;
      }
      ToastUtils.error("Something went wrong", "Please try again");
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error occurred while sending dispute"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.reset(form);
    setForm({ ...form });
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-create-deliverable-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">Raise a Dispute</h2>
          {organisation && (
            <p className="description">
              If the draft from the creator or agency does not meet your
              expectations and you've exhausted your revisions, you can initiate
              a dispute. Our customer success team will respond to your concerns
              at <span className="emp-highlighted">{organisation?.email}</span>
            </p>
          )}
        </div>
        <div className="mt-4 ph-5">
          <EmpTextInput
            id={"title"}
            labelText="Dispute Title"
            required
            formControl={form.title}
            onChange={validate}
            placeholder={"Dispute Title"}
          />
        </div>
        <div className="ph-5 mt-4">
          <EmpTextInput
            id={"description"}
            labelText="Description"
            description="Please be descriptive with the issue, so that our customer success team can administer the right support!"
            required
            multiline
            rows={2}
            textAreaAdaptiveHeight
            characterCount={1000}
            formControl={form.description}
            onChange={validate}
            placeholder={
              "Please provide a detailed description of this dispute..."
            }
          />
        </div>
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={"Cancel"}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            isFullWidth={false}
            text={"Submit Dispute"}
            onSubmit={() => {
              createDispute();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default CreateDisputeModal;
