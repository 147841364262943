import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { NotificationDto } from "../../model/notification/notification.dto";
import { EmpInterceptor } from "../emp-interceptor";

const NotificationApi = {
  fetchNotifications: async (
    recipientUserId: string
  ): Promise<ApiResponse<NotificationDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<NotificationDto[]>>(
      ENDPOINTS.notificationMsvc.notification.getNotifications(recipientUserId)
    );
    return response.data;
  },
  markNotificationAsRead: async (
    notificationId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.notificationMsvc.notification.markNotificationAsRead(
        notificationId
      )
    );
    return response.data;
  },
  markAllNotificationAsRead: async (
    recipientUserId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.notificationMsvc.notification.markAllNotificationAsRead(
        recipientUserId
      )
    );
    return response.data;
  },
};
export default NotificationApi;
