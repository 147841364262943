import "./emp-profile-dropdown-btn.scss";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { EmpButtonRef } from "../emp-button/emp-button";
import EmpAvatar from "../emp-avatar/emp-avatar";
import { UserDto } from "../../../model/user-management/user.dto";
import { ReactKeyUtil } from "../../../utilities/react-key.util";
import EmpPill from "../EmpPill/EmpPill";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

export interface MenuButtonSpec<T> {
  action: (record: T) => void;
  context: T;
  label: string;
}
interface Props<T> {
  user: Partial<UserDto>;
  menuButtonSpecs: MenuButtonSpec<T>[];
  isLoading?: boolean;
  role?: string;
}
const MENU_ITEM_HEIGHT = 30;
const GUTTER_WIDTH = 10;
const MAX_MENU_ITEMS = 8;

const menuButtonStyle: React.CSSProperties = {
  height: MENU_ITEM_HEIGHT,
};

const EmpProfileDropdownBtn = <T extends {}>(props: Props<T>) => {
  const { menuButtonSpecs, role } = props;
  const buttonRef = useRef<EmpButtonRef>();

  const menuRef = useRef<HTMLDivElement>(null);

  const isSelectedRef = useRef(false);
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (props === undefined) return;
    const state = props.isLoading ? "loading" : "default";
    buttonRef.current?.setButtonState(state);
  }, [props.isLoading]);

  const handleClickOutside = (event: MouseEvent) => {
    // This function is called when the user clicks outside of the menu,
    // and is used to close the menu.
    if (!isSelectedRef.current) return;
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setSelected(false);
    }
  };

  /**
   * This function is called when the user clicks on a menu item.
   * It should navigate to the link specified in the spec.
   * @param spec
   */
  const menuItemOnClick = (spec: MenuButtonSpec<T>) => {
    spec.action(spec.context);
    setSelected(false);
  };

  // This function is called when the component is mounted and whenever the
  // isSelected prop changes.
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const iconOnClick = () => {
    if (!isSelected) {
      setSelected(true);
    }
  };

  return (
    <>
      <div
        ref={menuRef}
        onClick={() => iconOnClick()}
        className="emp-profile-dropdown-menu"
      >
        <div className="dropdown-profile-wrapper">
          {props.user.imageType === "avatar" && (
            <div
              style={{ backgroundImage: props.user.imageResource }}
              className="avatar-profile-img"
            >
              <span className="initials">{props.user.initials}</span>
            </div>
          )}
          {props.user.imageType === "url" && (
            <img
              className="avatar-profile-img"
              referrerPolicy="no-referrer"
              src={props.user.imageResource}
              alt={`${props.user.fullName}'s Display`}
            />
          )}
          <div className="dropdown-profile-name-wrapper">
            <span className="name-lbl">
              {props.user.fullName === ""
                ? "Unnamed User"
                : props.user.fullName}
            </span>
            {role && <EmpPill text={role} size="sm" {...PILL_COLORS["gray"]} />}
          </div>
        </div>
        {isSelected && (
          <motion.div
            initial={{ opacity: 0, y: "95%" }}
            animate={{ opacity: 1, y: "100%" }}
            transition={{ duration: 0.2 }}
            className="emp-dropdown-menu-wrapper"
          >
            {ReactKeyUtil.addReactKey(menuButtonSpecs).map((elem) => {
              return (
                <div
                  onClick={() => menuItemOnClick(elem)}
                  key={elem.reactKey}
                  className="emp-dropdown-menu-btn"
                  style={menuButtonStyle}
                >
                  {elem.label}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </>
  );
};

export default EmpProfileDropdownBtn;
