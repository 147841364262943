import "./emp-toast-manager.scss";

import React, { ReactElement, useEffect, useRef, useState } from "react";
import PubSub from "pubsub-js";
import EmpToast from "../emp-toast/emp-toast";
import { IToastSpecs } from "../../../model/common/toast";
import { PUB_SUB_TOPICS } from "../../../constants/pubSubTopics";
import RandomUtils from "../../../utilities/random.util";

interface AlertElement {
  element: ReactElement;
  id: string;
}
const EmpToastManager = () => {
  const [alertComponents, setAlertComponents] = useState<AlertElement[]>([]);
  const alertComponentRef = useRef<AlertElement[]>([]);
  const closedAlertRef = useRef<string[]>([]);
  alertComponentRef.current = alertComponents;

  /**
   * Callback initiated from the toast component
   * @param id - Id assigned to the toast.
   */
  const onModalDismiss = (id: string): void => {
    closedAlertRef.current.push(id);
    if (closedAlertRef.current.length === alertComponentRef.current?.length) {
      setAlertComponents([]);
      closedAlertRef.current = [];
    }
  };

  /**
   * This event executes when a new alert event is received.
   * @param _ : Topic Name -> Not used in this function.
   * @param data : Specifications from the publisher
   */
  const receiveAlertEvent = (_: string, data: IToastSpecs) => {
    const id = Math.floor(RandomUtils.secureRandom() * 1000000).toString();
    alertComponents.push({
      element: (
        <EmpToast
          onComplete={(id) => {
            onModalDismiss(id);
          }}
          id={id}
          key={id}
          title={data.title}
          type={data.type}
          description={data.description}
        />
      ),
      id,
    });

    setAlertComponents([...alertComponents]);
  };

  // Open an alert event listener when this component is loaded for the first time.
  // When component is destroyed, it will unsubscribe.
  useEffect(() => {
    PubSub.subscribe(PUB_SUB_TOPICS.ALERT, receiveAlertEvent);
    return () => {
      PubSub.unsubscribe(PUB_SUB_TOPICS.ALERT);
    };
  }, [alertComponents]);

  return (
    <div className="emp-toast-manager">
      <div className="toast-wrapper">
        {alertComponents.map((elem) => {
          return elem.element;
        })}
      </div>
    </div>
  );
};
export default EmpToastManager;
