import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { DraftDto } from "../../model/campaign/draft.dto";
import { ReviewDraftDto } from "../../model/campaign/review-draft.dto";
import { SaveDraftDto } from "../../model/campaign/save-draft.dto";
import FileUtils from "../../utilities/file-util";
import { EmpInterceptor } from "../emp-interceptor";

const DraftApi = {
  createDraft: async (
    request: SaveDraftDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.draft.base,
      request
    );
    return response.data;
  },

  updateDraft: async (
    request: SaveDraftDto,
    draftId: string
  ): Promise<ApiResponse<ApiStatus> | null> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.draft.baseWithDraftId(draftId),
      request
    );
    return response.data;
  },

  
  fetchDraftAsSeller: async (
    taskId: string
  ): Promise<ApiResponse<DraftDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<DraftDto[]>>(
      ENDPOINTS.campaignMsvc.draft.fetchDraftAsSeller(taskId)
    );
    return response.data;
  },
  fetchDraftAsBrand: async (
    taskId: string
  ): Promise<ApiResponse<DraftDto[]>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<DraftDto[]>>(
      ENDPOINTS.campaignMsvc.draft.fetchDraftAsBrand(taskId)
    );
    return response.data;
  },
  reviewDraft: async (
    draftId: string,
    brandOrgId: string,
    request: ReviewDraftDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(
      ENDPOINTS.campaignMsvc.draft.draftReview(draftId, brandOrgId),
      request
    );
    return response.data;
  },
};
export default DraftApi;
