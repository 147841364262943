import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import {
  SocialTiktokGetDailyPostLimitationResponseDto,
  SocialTiktokImagePostRequestDto,
  SocialTiktokVideoPostRequestDto,
} from "../../model/smm/smm-tiktok.dto";
import { EmpInterceptor } from "../emp-interceptor";

const SocialMediaManagementTikTokApi = {
  getUserPrivacy: async (
    id: string,
    socialMediaUserId: string
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.tiktok.getUserPrivacy(id, socialMediaUserId)
    );
    return response.data;
  },

  createVideoPost: async (
    id: string,
    socialMediaUserId: string,
    req: SocialTiktokVideoPostRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.tiktok.createVideoPost(id, socialMediaUserId),
      req
    );
    return response.data;
  },

  createImagePost: async (
    id: string,
    socialMediaUserId: string,
    req: SocialTiktokImagePostRequestDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<ApiStatus>>(
      ENDPOINTS.smmManagement.tiktok.createImagePost(id, socialMediaUserId),
      req
    );
    return response.data;
  },

  getDailyPostLimitation: async (
    date: number,
    id: string,
    socialMediaUserId: string
  ): Promise<ApiResponse<SocialTiktokGetDailyPostLimitationResponseDto>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<
      ApiResponse<SocialTiktokGetDailyPostLimitationResponseDto>
    >(
      ENDPOINTS.smmManagement.tiktok.getDailyPostLimitation(
        id,
        socialMediaUserId
      ),
      {
        params: { date },
      }
    );
    return response.data;
  },
};

export default SocialMediaManagementTikTokApi;
