import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import CampaignSummaryApi from "../../../api/campaign-msvc/campaign-summary.api";
import FacebookIcon from "../../../components/icon/facebook-icon";
import InstagramIcon from "../../../components/icon/instagram-icon";
import TargetIcon from "../../../components/icon/target-icon";
import TikTokIcon from "../../../components/icon/tiktok-icon";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableSeperator,
} from "../../../components/shared/EmpTable/EmpTable";
import { TASK_STATUS, TASK_TYPE } from "../../../constants/app.constants";
import { CreatorActiveTaskDto } from "../../../model/campaign/creator-active-task.dto";
import { UserDto } from "../../../model/user-management/user.dto";
import { Color } from "../../../utilities/colors";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import FormFieldUtils from "../../../utilities/form-field.util";
import "./creator-home-active-task-table.scss";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  user: UserDto;
}
export const CreatorHomeActiveTaskTable = (props: Props) => {
  const { user } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const [activeTaskRecords, setActiveTaskRecords] = useState<
    (CreatorActiveTaskDto | EmpTableSeperator)[]
  >([]);
  const [isLoading, setLoading] = useState(true);

  const tableContentSpec: EmpTableContentSpec<CreatorActiveTaskDto>[] =
    useMemo(() => {
      return [
        {
          title: intl.formatMessage({
            id: "agencyHomePage_campaignTaskTableTaskHeader",
          }),
          dataIndex: "taskAndCampaign",
          render: (record) => {
            return (
              <div className="task-name-cell">
                <img
                  className="campaign-img"
                  alt={record.taskName}
                  src={record.campaignImage}
                />
                <div>
                  <EmpLink
                    text={record.taskName}
                    onSubmit={() => {
                      navigate(
                        `/creator/campaign-details/${record.campaignId}/task/${record.taskId}`
                      );
                    }}
                  />
                  <div className="task-platform-wrapper">
                    {record.taskPlatform === TASK_TYPE.FACEBOOK && (
                      <>
                        <FacebookIcon
                          size={10}
                          backgroundColor={Color.NEUTRAL[500]}
                        />
                        <span className="task-type-small">Facebook</span>
                      </>
                    )}
                    {record.taskPlatform === TASK_TYPE.INSTAGRAM && (
                      <>
                        <InstagramIcon
                          size={10}
                          top={0.5}
                          backgroundColor={Color.NEUTRAL[500]}
                        />
                        <span className="task-type-small">Instagram</span>
                      </>
                    )}
                    {record.taskPlatform === TASK_TYPE.TIKTOK && (
                      <>
                        <TikTokIcon
                          size={10}
                          backgroundColor={Color.NEUTRAL[500]}
                        />
                        <span className="task-type-small">TikTok</span>
                      </>
                    )}
                    {record.taskPlatform === TASK_TYPE.GENERAL && (
                      <>
                        <TargetIcon
                          size={10}
                          backgroundColor={Color.NEUTRAL[500]}
                        />
                        <span className="task-type-small">General Task </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (record) => {
            return (
              <>
                {record.taskStatus === TASK_STATUS.ONGOING && (
                  <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
                )}
                {record.taskStatus === TASK_STATUS.RECRUITING && (
                  <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
                )}
              </>
            );
          },
        },
        {
          title: "Amount Committed",
          dataIndex: "amountCommitted",
          render: (record) => {
            if (record.amountCommitted === -1 && record.agencies.length > 0) {
              return (
                <span className="raw-text">Please consult your agency</span>
              );
            } else if (
              record.amountCommitted === -1 &&
              record.taskStatus === TASK_STATUS.RECRUITING
            ) {
              return <span className="raw-text">No confirmed amount</span>;
            }
            return (
              <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
                <span className="raw-text">
                  {record.amountCommitted.toFixed(2)}
                </span>
                <EmpCoinIcon
                  iconValue={record.taskPaymentMode}
                  mode="icon-only"
                />
              </div>
            );
          },
        },
        {
          title: "Representative",
          dataIndex: "allRepresentatives",
          render: (record) => {
            return (
              <div className="creators-cell">
                {record.agencies.length > 0 &&
                  record.agencies.map((elem) => {
                    return (
                      <div key={elem.id}>
                        <img
                          className="profile-img org"
                          src={elem.logo}
                          alt={elem.companyName}
                        />
                      </div>
                    );
                  })}
                {record.isSelfRepresenting && (
                  <>
                    {user.imageType === "url" && (
                      <img
                        className="profile-img"
                        src={user.imageResource}
                        alt={user.fullName}
                      />
                    )}
                    {user.imageType === "avatar" && (
                      <div
                        className="profile-img"
                        style={{ background: user.imageResource }}
                      >
                        <span className="initials">{user.initials}</span>
                      </div>
                    )}
                  </>
                )}
                {record.agencies.length === 0 && !record.isSelfRepresenting && (
                  <span className="raw-text">No Representatives</span>
                )}
              </div>
            );
          },
        },
      ];
    }, [intl, navigate]);

  const fetchCreatorActiveTasks = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await CampaignSummaryApi.fetchCreatorActiveTask();
      const records = resp.data;
      const recruitingTasks = records.filter(
        (elem) => elem.taskStatus === TASK_STATUS.RECRUITING
      );
      const ongoingTasks = records.filter(
        (elem) => elem.taskStatus === TASK_STATUS.ONGOING
      );

      const tableRecords: (CreatorActiveTaskDto | EmpTableSeperator)[] = [];

      if (recruitingTasks.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: "Showing recruiting tasks",
        });
        tableRecords.push(...recruitingTasks);
      }
      if (ongoingTasks.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: "Showing ongoing tasks",
        });
        tableRecords.push(...ongoingTasks);
      }
      setActiveTaskRecords(tableRecords);
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Unable to fetch active tasks"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCreatorActiveTasks();
  }, []);
  return (
    <div className="creator-active-task-card  dashboard-card no-padding">
      <div className="header-section">
        <h3 className="card-header">
          <FormattedMessage id="participatingCampaignCard_header" />
        </h3>
        <p className="emp-paragraph mt-2">
          <FormattedMessage id="participatingCampaignCard_desc" />
        </p>
      </div>

      <EmpTable
        paddingSize="sm"
        loading={isLoading || activeTaskRecords.length === 0}
        shimmerLoading={{
          width: [120, 60, 200, 100],
          overlayDesign: (
            <div className="table-overlay-wrapper">
              <img
                alt="No tasks"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/gray-loudspeaker.png"
              />
              <span className="header">No Participating Campaigns</span>
              <span className="description">
                You're not involved in any campaigns
              </span>
            </div>
          ),
        }}
        contentColumns={tableContentSpec}
        data={activeTaskRecords}
        rowKey={"id"}
      />
    </div>
  );
};
