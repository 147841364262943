import { IconProps } from "../model/common/icon";

// Define the Hook with the IconProps type as an argument
const useEmpIcon = (props: IconProps) => {
  // Return an object with the extracted properties
  return {
    size: props.size ?? 20, // fallback to 20 if size is not defined
    top: props.top ? `${props.top}px` : "0", // fallback to 0 if top is not defined
    right: props.right ? `${props.right}px` : "0", // fallback to 0 if right is not defined
    bottom: props.bottom ? `${props.bottom}px` : "0", // fallback to 0 if bottom is not defined
    left: props.left ? `${props.left}px` : "0", // fallback to 0 if left is not defined
    backgroundColor: props.backgroundColor ?? "#000000", // fallback to #000000 if backgroundColor is not defined
    stroke: props.strokeWidth ?? 2,
    hoverEnabled: props.hoverEnabled ?? false,
    hoverColor: props.hoverColor ?? props.backgroundColor ?? "#000000",
  };
};
export default useEmpIcon;
