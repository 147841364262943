import "./auth-error-modal.scss";
import { forwardRef, useImperativeHandle, useState } from "react";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import useEmpAuth from "../../hooks/useEmpAuth";
import { AUTH_ERR_CODES } from "../../constants/app.constants";

export interface AuthErrorModalRef {
  show: (errorCode: string) => void;
  dismiss: () => void;
}

interface Props {
  onSave?: () => void;
}

const AuthErrorModal = forwardRef((props: Props, ref) => {
  const { getErrorCodeToMessageMapper } = useEmpAuth();
  const [visible, setVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    description: string;
  }>();

  const show = (
    errorCode: (typeof AUTH_ERR_CODES)[keyof typeof AUTH_ERR_CODES]
  ) => {
    setVisible(true);
    const { title, description } = getErrorCodeToMessageMapper(errorCode);
    setErrorMessage({ title, description });
  };

  const dismiss = async () => {
    setVisible(false);
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      onClose={dismiss}
      showHeaderBorder={false}
      size={"sm"}
      modalGlobalElement={
        <div onClick={dismiss} className="emp-modal-x-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
      }
    >
      {/* This is body */}
      <div className="emp-auth-error-modal">
        {errorMessage && (
          <div className="error-msg-wrapper">
            <h2>{errorMessage.title}</h2>
            <p className="emp-paragraph mt-3">{errorMessage.description}</p>
          </div>
        )}
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default AuthErrorModal;
