import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ProfileApi from "../../api/user-msvc/profile.api";
import FileIcon from "../../components/icon/file-icon";
import TrashIcon from "../../components/icon/trash-icon";
import XCloseIcon from "../../components/icon/x-close-icon";
import DeleteModal, {
  DeleteModalRef,
} from "../../components/modals/delete-modal";
import UploadFileModal, {
  UploadFileModalRef,
} from "../../components/modals/upload-file-modal";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import EmpException from "../../exception/empException";
import { AgencyProfileDto } from "../../model/profile/agency-profile.dto";
import { ProfileAttachmentDto } from "../../model/profile/profile-attachment.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import ToastUtils from "../../utilities/toast-utils";
import "./edit-about-us-modal.scss";

export interface EditAboutUsModalRef {
  show: (profile: AgencyProfileDto) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

const EditAboutUsModal = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [visible, setVisible] = useState<boolean>(false);
  const uploadFileModalRef = useRef<UploadFileModalRef>();
  const deleteFileModalRef = useRef<DeleteModalRef>();
  const [profile, setProfile] = useState<AgencyProfileDto>();

  const [form, setForm] = useState<IFormGroup>({
    aboutUs: new FormControl("text", [
      new LengthValidator(
        0,
        400,
        undefined,
        intl.formatMessage(
          { id: "validation_maxLengthDynamic" },
          { char: "400" }
        )
      ),
    ]),
  });

  const saveBtnRef = useRef<EmpButtonRef>();

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async (profile: AgencyProfileDto) => {
    form.aboutUs.forceUpdateValue(profile.aboutUs);
    setProfile(profile);
    setVisible(true);
  };

  const dismiss = () => {
    resetForm();
    setVisible(false);
  };

  const fetchProfile = async (organisationId: string) => {
    try {
      const response = await ProfileApi.fetchProfile(organisationId);
      const profile = response.data;
      setProfile(profile);
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "An error occurred while fetching user details."
      );
    }
  };

  /**
   * Saves the "about us" section of the profile.
   *
   * @throws {EmpException} If no profile object is found.
   * @throws {EmpExceptionHandler} If there is an error during the saving process.
   */
  const saveAboutUs = async () => {
    try {
      if (!profile) throw new EmpException("No Profile Object found");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const resp = await ProfileApi.updateAboutUs(
        profile.organisationId,
        form.aboutUs.getValue()
      );
      if (resp.data.status === "success") {
        props.onSave();
        dismiss();
        ToastUtils.success("Saved", "About Us Section Updated");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to save about us");
    }
  };

  const resetForm = () => {
    // Resetting the form
    FormGroupUtil.reset(form);
    setProfile(undefined);
    setForm({ ...form });
  };

  const deleteFn = async (id: string) => {
    try {
      const resp = await ProfileApi.deleteProfileAttachment(id);
      if (resp.data.status === "success") {
        dismiss();
        ToastUtils.success("Deleted", "Profile Attachment has been removed");
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(e, "Unable to save about us");
    }
  };

  const deleteFile = (profileAttachment: ProfileAttachmentDto) => {
    if (!deleteFileModalRef.current)
      throw new EmpException("Unable to trigger deletion view");
    const callbackFn = (id: string) => {
      deleteFn(id);
    };
    deleteFileModalRef.current.show(
      "attachment",
      profileAttachment.label,
      callbackFn,
      profileAttachment.id.toString()
    );
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-about-us-modal">
        {profile && (
          <>
            <DeleteModal
              ref={deleteFileModalRef}
              onSave={() => {
                fetchProfile(profile?.organisationId);
                props.onSave();
              }}
            />
            <UploadFileModal
              ref={uploadFileModalRef}
              onSave={() => {
                fetchProfile(profile?.organisationId);
                props.onSave();
              }}
            />
          </>
        )}

        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="editAboutUsModal_header" />
          </h2>
          <p className="description">
            <FormattedMessage id="editAboutUsModal_desc" />
          </p>
        </div>

        <div className="about-us-form-section mt-4">
          <EmpTextInput
            labelText={intl.formatMessage({
              id: "editAboutUsModal_aboutUsInputLabel",
            })}
            id={"aboutUs"}
            placeholder={intl.formatMessage({
              id: "editAboutUsModal_aboutUsInputPlaceholder",
            })}
            multiline
            characterCount={400}
            formControl={form.aboutUs}
          />
        </div>
        <hr className="mv-5" />

        {profile && (
          <div className="uploaded-content-section mt-3">
            <div className="header-wrapper">
              <span className="header">
                <FormattedMessage id="editAboutUsModal_uploadedContentSubHeader" />
              </span>
              <EmpLink
                onSubmit={() =>
                  uploadFileModalRef.current?.show(profile.organisationId)
                }
                text={
                  <FormattedMessage id="editAboutUsModal_uploadedNewFileLink" />
                }
              />
            </div>

            <div className="uploaded-files-section mt-3">
              {profile.profileAttachments.length === 0 && (
                <div className="empty-state">
                  <span className="empty-state-lbl">
                    <FormattedMessage id="editAboutUsModal_uploadedContentEmptyHeader" />
                  </span>
                </div>
              )}
              {profile.profileAttachments.length === 0 &&
                [1, 2].map((elem, index) => {
                  return (
                    <div key={elem} className="file-card-skeleton">
                      <div className="left-wrapper">
                        <div className="image-section">
                          <FileIcon backgroundColor={Color.NEUTRAL[200]} />
                        </div>
                        <div className="text-section">
                          <div className="file-title-skeleton"></div>
                          <div className="file-name-skeleton"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {profile.profileAttachments.length > 0 &&
                profile.profileAttachments.map((elem, index) => {
                  return (
                    <div key={elem.id} className="file-card">
                      <div className="left-wrapper">
                        <div className="image-section">
                          <FileIcon backgroundColor={Color.NEUTRAL[0]} />
                        </div>
                        <div className="text-section">
                          <span className="file-title">{elem.label}</span>
                          <span className="file-name">{elem.fileName}</span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          deleteFile(elem);
                        }}
                      >
                        <TrashIcon
                          hoverEnabled
                          hoverColor={Color.RED[500]}
                          backgroundColor={Color.NEUTRAL[400]}
                          size={18}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"secondary"}
            text={<FormattedMessage id="cta_cancel" />}
            onSubmit={() => {
              dismiss();
            }}
          />
          <EmpButton
            ref={saveBtnRef}
            isFullWidth={false}
            text={<FormattedMessage id="cta_save" />}
            onSubmit={() => {
              saveAboutUs();
            }}
          />
        </div>
      </div>

      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default EditAboutUsModal;
