import { IconProps } from "../../model/common/icon";
import useEmpIcon from "../../hooks/useEmpIcon";

const VideoRecordOffIcon = (props: IconProps) => {
  const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props);
  return (
    <div
      style={{
        position: "relative",
        top,
        left,
        right,
        bottom,
        display: "flex",
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.167 29.166C19.502 29.166 11.667 37.001 11.667 46.666V93.3327C11.667 102.998 19.502 110.833 29.167 110.833H81.667C89.5575 110.833 96.2283 105.611 98.4118 98.4339M99.167 69.9993L120.367 48.7992C122.866 46.3003 124.115 45.0509 125.188 44.9665C126.119 44.8932 127.028 45.27 127.635 45.9799C128.334 46.7981 128.334 48.5651 128.334 52.099V87.8997C128.334 91.4336 128.334 93.2006 127.635 94.0188C127.028 94.7287 126.119 95.1055 125.188 95.0322C124.115 94.9478 122.866 93.6984 120.367 91.1995L99.167 69.9993ZM99.167 69.9993V57.166C99.167 47.3651 99.167 42.4646 97.2596 38.7212C95.5818 35.4283 92.9047 32.7512 89.6118 31.0734C85.8684 29.166 80.9679 29.166 71.167 29.166H55.417M11.667 11.666L128.334 128.333"
          stroke={backgroundColor}
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default VideoRecordOffIcon;
