import _debounce from "lodash/debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import ChevronDownIcon from "../../../components/icon/chevron-down";
import ChevronUpIcon from "../../../components/icon/chevron-up";
import EmpContent from "../../../components/shared/emp-content/emp-content";
import EmpLink from "../../../components/shared/emp-link/emp-link";
import EmpPill from "../../../components/shared/EmpPill/EmpPill";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import { TaskDto } from "../../../model/campaign/task.dto";
import { Color } from "../../../utilities/colors";
import { DateUtil } from "../../../utilities/date";
import FormFieldUtils from "../../../utilities/form-field.util";
import StringUtils from "../../../utilities/string.util";
import "./seller-exclusive-task-summary-view.scss";
import "../../../utilities/viewUtils/seller-task-status-card.scss";
import EmpEditorView from "../../../components/shared/emp-editor/emp-editor-viewer";
import { EmpCoinIcon } from "../../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  task: TaskDto;
}

export const SellerExclusiveTaskSummaryView = (props: Props) => {
  const { task: taskProp } = props;
  const [task, setTask] = useState<TaskDto>();

  const [deliverableIsExpanded, setDeliverableIsExpanded] = useState(true);
  const resizeDivRef = useRef<HTMLDivElement>(null);

  // Wrapper element to compute expandable size
  const deliverableInfoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (taskProp) {
      setTask(taskProp);
    }
  }, [taskProp]);

  const GUTTER_WIDTH = 20;
  const MIN_CARD_WIDTH = 180;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [deliverableInfoHeight, setDeliverableInfoHeight] = useState<number>(0);

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cards = Math.min(maxCards, 5);
    const cardWidth = Math.floor(
      (rowWidth - (cards - 1) * GUTTER_WIDTH) / cards
    );
    setCardWidth(cardWidth);
  };

  function calculateTotalOuterHeight(divRef: HTMLDivElement) {
    let totalHeight = 0;

    // Helper function to include margin
    function getOuterHeight(el: HTMLElement) {
      let style = window.getComputedStyle(el);
      return (
        el.offsetHeight +
        parseInt(style.marginTop) +
        parseInt(style.marginBottom)
      );
    }

    // Loop through the immediate children of divRef
    Array.from(divRef.children).forEach((child) => {
      const c = child as HTMLElement;
      totalHeight += getOuterHeight(c);
    });

    return totalHeight;
  }

  const updateDeliverableSectionHeight = () => {
    const height = calculateTotalOuterHeight(deliverableInfoRef.current!);
    setDeliverableInfoHeight(height);
  };

  // eslint-disable-next-line
  const debounceFn2: () => void = useCallback(
    _debounce(updateDeliverableSectionHeight, 300, { leading: true }),
    []
  );
  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );

  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
      debounceFn2();
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn, task]);

  const renderCountries = (countries: string[]): JSX.Element => {
    const maxDisplayCountries = 3;
    const extra =
      countries.length > maxDisplayCountries
        ? countries.length - maxDisplayCountries
        : undefined;
    const renderableCountries = countries.slice(0, maxDisplayCountries);
    return (
      <div className="country-wrapper">
        {renderableCountries.map((elem) => {
          return (
            <img
              key={COUNTRY_CONSTANTS[elem].name}
              className="country"
              alt={COUNTRY_CONSTANTS[elem].name}
              src={COUNTRY_CONSTANTS[elem].flag_1x1}
            />
          );
        })}
        {extra && <div className="extra">{extra}</div>}
      </div>
    );
  };

  return (
    <div className="emp-seller-exclusive-task-summary-view">
      {task && (
        <section className="task-details-section">
          <p className="task-description">{task.description}</p>

          <div ref={resizeDivRef} className="task-details mt-6">
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"BUDGET"}
                value={`${FormFieldUtils.toMoney(
                  0,
                  true
                )}/${FormFieldUtils.toMoney(Number(task.budget), true)}`}
              />
            </div>

            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"EST TASK RUNNING DATE"}
                value={`${DateUtil.toReadableDate(
                  task.startDate
                )} to ${DateUtil.toReadableDate(task.endDate)}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"MODE OF PAYMENT"}
                value={
                  <div style={{display: "flex", alignItems: "center", gap : 4}}>
                    <EmpCoinIcon
                      mode="icon-only"
                      iconValue={task.paymentMode}
                    />
                    {task.paymentMode}
                  </div>
                }
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"OBJECTIVE"}
                value={`${StringUtils.convertHyphensToTitleCase(
                  task.objective
                )}`}
              />
            </div>
            <div style={{ width: cardWidth }}>
              <EmpContent
                label={"COUNTRIES"}
                value={renderCountries(task.countries)}
              />
            </div>
          </div>
        </section>
      )}
      {task && (
        <section className="deliverable-section">
          <div className="title-wrapper">
            <h2 className="section-label">
              {task.deliverables.length} Deliverable
              {task.deliverables.length > 1 ? "s" : ""}
            </h2>
            <div className="link-wrapper">
              {task.deliverables.length > 0 && (
                <EmpLink
                  onSubmit={() => {
                    setDeliverableIsExpanded(!deliverableIsExpanded);
                  }}
                  text={
                    deliverableIsExpanded ? (
                      <>
                        Hide{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronUpIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        Show Deliverables{" "}
                        <div style={{ display: "inline-block" }}>
                          <ChevronDownIcon
                            size={20}
                            top={5}
                            left={-4}
                            backgroundColor={Color.PRIMARY[600]}
                          />
                        </div>
                      </>
                    )
                  }
                />
              )}
            </div>
          </div>
          <div
            className="collapsible-section"
            style={{
              height: deliverableIsExpanded ? deliverableInfoHeight : 0,
            }}
          >
            <div style={{ boxSizing: "border-box" }} ref={deliverableInfoRef}>
              {task.deliverables.map((elem, index) => {
                return (
                  <div key={elem.id} className="deliverable-wrapper">
                    <span className="deliverable-indicator">
                      DELIVERABLE {index + 1}
                    </span>
                    <h3 className="deliverable-name-lbl mb-2">{elem.name}</h3>
                    <EmpEditorView value={elem.description} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
