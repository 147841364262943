import { useCallback } from "react";
import "./pow-modal-listing.scss";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import EmpContent from "../shared/emp-content/emp-content";
import { DateUtil } from "../../utilities/date";
import EmpLink from "../shared/emp-link/emp-link";
import MessageIcon from "../icon/message-icon";
import { FacebookPost } from "../../model/campaign/social-media.dto";
import {
  EVIDENCE_TYPE_DISPLAY,
  PROOF_OF_WORK_MEDIA_TYPE,
} from "../../constants/app.constants";
import HeartIcon from "../icon/heart-icon";
import { Color } from "../../utilities/colors";
import FacebookIcon from "../icon/facebook-icon";
import EyeIcon from "../icon/eye-icon";
import FileCheckIcon from "../icon/file-check-icon";
import { EmpImage } from "../shared/emp-image/emp-image";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import CheckIcon from "../icon/check-icon";
import EmpPill from "../shared/EmpPill/EmpPill";
import ShareIcon from "../icon/share-icon";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  socialMediaObject: FacebookPost;
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
}

const picOrVideoType = [
  PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE,
  PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO,
];
export const FacebookPow = (props: Props) => {
  const {
    socialMediaObject: facebookMedia,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
  } = props;

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        {picOrVideoType.includes(evidenceRecord.mediaType) && (
          <img
            className="preview-image"
            alt={facebookMedia.name}
            src={facebookMedia.picture}
          />
        )}
        {facebookMedia.mediaType === PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
          <div className="preview-text">
            <p className="text">{facebookMedia.text}</p>
          </div>
        )}
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE && (
                <span className="card-title">Facebook Image Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO && (
                <span className="card-title">Facebook Video Post</span>
              )}
              {evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
                <span className="card-title">Facebook Post </span>
              )}
              <div className="metrics-bar mt-2">
                <div className="metric-wrapper">
                  <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{facebookMedia.impressionCount}</span>
                </div>
                <div className="metric-wrapper">
                  <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{facebookMedia.likeCount}</span>
                </div>
                <div className="metric-wrapper">
                  <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{facebookMedia.commentCount}</span>
                </div>
                <div className="metric-wrapper">
                  <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
                  <span>{facebookMedia.shareCount}</span>
                </div>
                <div className="metric-wrapper">
                  <div className="download-btn-wrapper">
                    <EmpLink
                      href={evidenceRecord.mediaUrl}
                      newTab
                      text={"Link to Post"}
                      showNewTabIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={CheckIcon}
              {...PILL_COLORS.green}
              text={"Live Data"}
              tooltip={`This data is pulled directly from the ${evidenceRecord.platform} API. Refresh the page to access the most up-to-date information.`}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, facebookMedia]);

  const renderPowCard = useCallback(() => {
    return (
      <div className="image-wrapper">
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <img
              className="social-media-avatar"
              alt={facebookMedia.name}
              src={facebookMedia.avatarPicture}
            />
            <div className="social-media-platform-badge">
              <FacebookIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">{facebookMedia.name}</span>
          </div>
        </div>
        <div className="post-wrapper">
          {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
            <div
              className={`additional-deliverable-wrapper-badge ${
                evidenceRecord.mediaType ===
                PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT
                  ? "anchor-bottom"
                  : ""
              }`}
            >
              <span className="badge-text">
                +{noOfOtherEvidenceRecords - 1}
              </span>
              <FileCheckIcon
                strokeWidth={2.5}
                size={12}
                backgroundColor={Color.NEUTRAL[300]}
              />
            </div>
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE && (
            <img
              className="attachment-img"
              alt="Uploaded evidence"
              src={facebookMedia.picture}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO && (
            <img
              className="attachment-img"
              alt="Uploaded evidence"
              src={facebookMedia.picture}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
            <div className=" attachment-text ">
              <p className="emp-paragraph">{facebookMedia.text}</p>
            </div>
          )}
        </div>

        <div className="sm-metrics-section">
          <div className="metrics-bar">
            <div className="metric-wrapper">
              <EyeIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{facebookMedia.impressionCount}</span>
            </div>
            <div className="metric-wrapper">
              <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{facebookMedia.likeCount}</span>
            </div>
            <div className="metric-wrapper">
              <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
              <span>{facebookMedia.commentCount}</span>
            </div>
            <div className="metric-wrapper">
              <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
              <span>{facebookMedia.shareCount}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [evidenceRecord, facebookMedia, noOfOtherEvidenceRecords]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <img
              className="social-media-avatar"
              alt={facebookMedia?.name}
              src={facebookMedia?.avatarPicture}
            />
            <div className="social-media-platform-badge">
              <FacebookIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">{facebookMedia?.name}</span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_PICTURE && (
            <img
              className="sm-image"
              alt="Uploaded evidence"
              src={facebookMedia.picture}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_VIDEO && (
            <img
              className="sm-image"
              alt="Uploaded evidence"
              src={facebookMedia.picture}
            />
          )}
          {evidenceRecord.mediaType ===
            PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
            <p className="sm-paragraph ">{facebookMedia.text}</p>
          )}
        </div>
        <div className="sm-metrics">
          <div className="metric-wrapper">
            <EyeIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{facebookMedia.impressionCount}</span>
          </div>
          <div className="metric-wrapper">
            <HeartIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{facebookMedia.likeCount}</span>
          </div>
          <div className="metric-wrapper">
            <MessageIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{facebookMedia.commentCount}</span>
          </div>
          <div className="metric-wrapper">
            <ShareIcon size={16} backgroundColor={Color.NEUTRAL[600]} />
            <span>{facebookMedia.shareCount}</span>
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, facebookMedia]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        {picOrVideoType.includes(evidenceRecord.mediaType) && (
          <EmpImage alt="Proof of work preview" src={facebookMedia.picture} />
        )}
        {evidenceRecord.mediaType ===
          PROOF_OF_WORK_MEDIA_TYPE.FACEBOOK_TEXT && (
          <div className="text-container">Text</div>
        )}
      </>
    );
  }, [facebookMedia, evidenceRecord]);

  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
