import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CreatorTaskRepDto,
  TaskRepresentativeDto,
} from "../../../model/user/creator-task-rep.dto";
import "../invite-task-modal.scss";
import _debounce from "lodash/debounce";
import EmpButton from "../../shared/emp-button/emp-button";
import EmpPill from "../../shared/EmpPill/EmpPill";
import StringUtils from "../../../utilities/string.util";
import { Color } from "../../../utilities/colors";
import { LatestNegotiationDto } from "../../../model/campaign/latest-negotiation.dto";
import FormFieldUtils from "../../../utilities/form-field.util";
import { NEGOTIATION_STATUS } from "../../../constants/app.constants";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";

interface Props {
  creatorTaskRep: CreatorTaskRepDto;
  onNext: (selectedCreatorReps: TaskRepresentativeDto[]) => void;
  latestNegotiations: LatestNegotiationDto[];
}

interface TaskRepresentativeExtended extends TaskRepresentativeDto {
  isSelected: boolean;
}

const TaskRepSelectionView = (props: Props) => {
  const { creatorTaskRep, onNext, latestNegotiations } = props;

  const latestNegotiationMap: Map<string, LatestNegotiationDto> =
    useMemo(() => {
      const resultMap = new Map<string, LatestNegotiationDto>();
      latestNegotiations
        .filter((elem) => elem.creatorUserId === creatorTaskRep.id)
        .forEach((elem) => resultMap.set(elem.representativeId, elem));
      return resultMap;
    }, [latestNegotiations, creatorTaskRep]);

  const resizeDivRef = useRef<HTMLDivElement>(null);
  const MIN_CARD_WIDTH = 150;

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [cardsPerRow, setCardsPerRow] = useState<number>(0);
  const [taskRepresentatives, setTaskRepresentative] = useState<
    TaskRepresentativeExtended[]
  >(() => {
    return creatorTaskRep.taskRepresentative.map((elem) => {
      return { ...elem, isSelected: false };
    });
  });

  const computeDetailsCardWidth = () => {
    const rowWidth = resizeDivRef.current!.offsetWidth;
    const maxCards = Math.max(Math.floor(rowWidth / MIN_CARD_WIDTH), 1);
    const cardWidth = Math.floor(rowWidth / maxCards);
    setCardWidth(cardWidth);
    setCardsPerRow(maxCards);
  };

  const proceedBtnDisabled: boolean = useMemo(() => {
    const taskRep = taskRepresentatives.find((elem) => elem.isSelected);
    if (!taskRep) return true;
    return false;
  }, [taskRepresentatives]);

  const proceedOnClick = () => {
    const selectedTaskRepresentatives = taskRepresentatives.filter(
      (elem) => elem.isSelected
    );
    onNext(selectedTaskRepresentatives);
  };

  // eslint-disable-next-line
  const debounceFn: (width: number) => void = useCallback(
    _debounce(computeDetailsCardWidth, 300, { leading: true }),
    []
  );
  /**
   * Detect the width of the details row when the 'lead' object has been set
   */
  useEffect(() => {
    if (!resizeDivRef.current) return;
    // window resize listener
    const resizeObserver = new ResizeObserver(() => {
      debounceFn(resizeDivRef.current!.offsetWidth);
    });
    resizeObserver.observe(resizeDivRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [debounceFn]);

  const renderNegotiationStatus = (
    elem: TaskRepresentativeExtended,
    index: number
  ): JSX.Element => {
    let isDisabled = false;
    let negotiation = undefined;

    if (latestNegotiationMap.has(elem.id)) {
      negotiation = latestNegotiationMap.get(elem.id);
      if (![NEGOTIATION_STATUS.INVITED].includes(negotiation!.status))
        isDisabled = true;
    }

    return (
      <div
        onClick={() => {
          if (isDisabled) return;
          taskRepresentatives[index].isSelected =
            !taskRepresentatives[index].isSelected;
          setTaskRepresentative([...taskRepresentatives]);
        }}
        key={elem.id}
        style={{
          width: cardWidth,
          background: isDisabled ? Color.NEUTRAL[800] : Color.NEUTRAL[900],
        }}
        className={`rep-card 
            ${
              (index + 1) % cardsPerRow === 0 ||
              index === taskRepresentatives.length - 1
                ? "rep-card-border-right"
                : ""
            }
            ${
              taskRepresentatives.length < index + 1 + cardsPerRow
                ? "rep-card-border-bottom"
                : ""
            }
            ${elem.isSelected ? "selected" : ""}
        `}
      >
        <div className={`knob ${elem.isSelected ? "selected" : ""}`}>
          <div
            className={`knob-ellipsis ${elem.isSelected ? "selected" : ""}`}
          ></div>
        </div>

        {elem.representativeType === "user" && (
          <>
            {elem.creator.imageType === "url" && (
              <img
                className={`profile-photo ${elem.isSelected ? "selected" : ""}`}
                alt={elem.creator.fullName}
                src={elem.creator.imageResource}
              />
            )}
            {elem.creator.imageType === "avatar" && (
              <div className={`avatar ${elem.isSelected ? "selected" : ""}`}>
                <span className="initials">{elem.creator.initials}</span>
              </div>
            )}
            <span className="rep-name-lbl mt-2">{elem.creator.fullName}</span>
          </>
        )}
        {elem.representativeType === "organisation" && (
          <>
            <img
              className={`profile-photo ${
                elem.isSelected ? "selected" : ""
              } org-photo`}
              alt={elem.agency.companyName}
              src={elem.agency.logo}
            />
            <span className="rep-name-lbl mt-2">{elem.agency.companyName}</span>
          </>
        )}
        <div className="mt-1">
          <EmpPill
            {...PILL_COLORS.primary}
            text={StringUtils.capitalizeWords(elem.representativeRole)}
          />
        </div>
        {negotiation && (
          <div className="negotiation-wrapper mt-2">
            <span className="block">
              {StringUtils.convertHyphensToTitleCase(negotiation.status)} |{" "}
              {negotiation.amount}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="task-rep-selection-view">
      <span className="view-header">Task Representative</span>
      <p className="view-description mt-2">
        This creator is affiliated with multiple agencies. You may select one or
        more people to work with. Please note that when you choose to go ahead
        with one of them, the other guys will be rejected.
      </p>
      <div className="task-rep-section mt-4" ref={resizeDivRef}>
        {taskRepresentatives.map((elem, index) => {
          return renderNegotiationStatus(elem, index);
        })}
      </div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <EmpButton
          isFullWidth={false}
          disabled={proceedBtnDisabled}
          onSubmit={proceedOnClick}
          className="mt-4"
          text={"Proceed"}
        />
      </div>
    </div>
  );
};
export default TaskRepSelectionView;
