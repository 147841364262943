import EmpException from "../../exception/empException";
import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";

export class PatternValidator extends BaseValidator implements IValidator {
  customErrorMessage: string = VALIDATION_MESSAGE.PATTERN_INVALID;
  pattern: RegExp;
  /**
   * @purpose Override error message if necessary
   * @param errorMessage Custom error message that
   * overrides default Required Validator error message
   */
  constructor(pattern: RegExp, errorMessage?: string) {
    super();
    this.pattern = pattern;
    if (errorMessage) this.customErrorMessage = errorMessage;
  }

  /**
   * @purpose Perform pattern validation
   * @param value Evaluated String
   * @returns True if valid | False if invalid
   */
  validate(formControl: FormControl): boolean {
    if (formControl.formType === "text") {
      return this.validateText(formControl);
    }
    throw new EmpException(
      "The Pattern Validator is unable to validate this form type"
    );
  }

  validateText(formControl: FormControl): boolean {
    const regex = this.pattern;
    const value = formControl.getValue();
    const evaluable = value ? value.trim() : "";

    if (evaluable.length === 0) {
      this.noError(formControl);
      return true;
    } else if (!regex.test(evaluable)) {
      this.hasError(formControl, this.customErrorMessage);
      return false;
    }
    this.noError(formControl);
    return true;
  }
}
