import { useCallback } from "react";
import "./pow-modal-listing.scss";
import { EvidenceRecordDto } from "../../model/campaign/evidence-record.dto";
import { TiktokVideo } from "../../model/campaign/social-media.dto";
import { DateUtil } from "../../utilities/date";
import HeartIcon from "../icon/heart-icon";
import MessageIcon from "../icon/message-icon";
import PlayIcon from "../icon/play-icon";
import ShareIcon from "../icon/share-icon";
import EmpContent from "../shared/emp-content/emp-content";
import EmpLink from "../shared/emp-link/emp-link";
import { Color } from "../../utilities/colors";
import TikTokIcon from "../icon/tiktok-icon";
import FileCheckIcon from "../icon/file-check-icon";
import { EmpImage } from "../shared/emp-image/emp-image";
import { EVIDENCE_TYPE_DISPLAY } from "../../constants/app.constants";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import CheckIcon from "../icon/check-icon";
import EmpPill from "../shared/EmpPill/EmpPill";

interface Props {
  type: "pow-modal-listing" | "pow-card" | "ca-table" | "pow-modal-details";
  evidenceRecord: EvidenceRecordDto;
  noOfOtherEvidenceRecords?: number;
  socialMediaObject: TiktokVideo;
}

export const TikTokPow = (props: Props) => {
  const {
    socialMediaObject: tiktokVideo,
    type,
    evidenceRecord,
    noOfOtherEvidenceRecords,
  } = props;

  const renderPowModalListing = useCallback(() => {
    return (
      <>
        <img
          className="preview-image"
          alt={tiktokVideo.name}
          src={tiktokVideo.picture}
        />
        <div className="info-section">
          <div className="top-section-wrapper">
            <div className="top-section-info-wrapper">
              <span className="card-title">TikTok Video</span>
              <div className="metrics-bar mt-2">
                <div className="metric-wrapper">
                  <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{tiktokVideo.viewCount}</span>
                </div>
                <div className="metric-wrapper">
                  <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{tiktokVideo.likeCount}</span>
                </div>
                <div className="metric-wrapper">
                  <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{tiktokVideo.commentCount}</span>
                </div>
                <div className="metric-wrapper">
                  <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                  <span>{tiktokVideo.shareCount}</span>
                </div>
                <div className="metric-wrapper">
                  <div className="download-btn-wrapper">
                    <EmpLink
                      href={evidenceRecord.mediaUrl}
                      newTab
                      text={"Link to Post"}
                      showNewTabIcon
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listing-pills-wrapper">
            <EmpPill
              {...PILL_COLORS.primary}
              text={EVIDENCE_TYPE_DISPLAY.SOCIAL_MEDIA_LINK}
            />
            <EmpPill
              Icon={CheckIcon}
              {...PILL_COLORS.green}
              text={"Live Data"}
              tooltip={`This data is pulled directly from the ${evidenceRecord.platform} API. Refresh the page to access the most up-to-date information.`}
            />
          </div>
          <div className="content-wrapper mt-2">
            <EmpContent
              label={"SUBMITTED DATE"}
              value={DateUtil.toReadableDateWithTime(
                evidenceRecord.createdDate
              )}
            />
            <EmpContent
              label={"REMARKS"}
              value={
                <span className="remarks-span">
                  {evidenceRecord.remarks?.length > 0
                    ? evidenceRecord.remarks
                    : "No Remarks"}
                </span>
              }
            />
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, tiktokVideo]);

  const renderPowCard = useCallback(() => {
    return (
      <>
        <div className="image-wrapper">
          <div className="sm-top-section">
            <div className="avatar-wrapper">
              <img
                className="social-media-avatar"
                alt={tiktokVideo.name}
                src={tiktokVideo.avatarPicture}
              />
              <div className="social-media-platform-badge">
                <TikTokIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
              </div>
            </div>
            <div className="info-section">
              <span className="name-lbl">{tiktokVideo.name}</span>
            </div>
          </div>
          <div className="post-wrapper">
            {noOfOtherEvidenceRecords && noOfOtherEvidenceRecords > 1 && (
              <div className={`additional-deliverable-wrapper-badge`}>
                <span className="badge-text">
                  +{noOfOtherEvidenceRecords - 1}
                </span>
                <FileCheckIcon
                  strokeWidth={2.5}
                  size={12}
                  backgroundColor={Color.NEUTRAL[300]}
                />
              </div>
            )}
            <img
              className="attachment-img"
              alt="Uploaded evidence"
              src={tiktokVideo.picture}
            />
          </div>
          <div className="sm-metrics-section">
            <div className="metrics-bar">
              <div className="metric-wrapper">
                <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{tiktokVideo.viewCount}</span>
              </div>
              <div className="metric-wrapper">
                <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{tiktokVideo.likeCount}</span>
              </div>
              <div className="metric-wrapper">
                <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{tiktokVideo.commentCount}</span>
              </div>
              <div className="metric-wrapper">
                <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
                <span>{tiktokVideo.shareCount}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [tiktokVideo, noOfOtherEvidenceRecords]);

  const renderPowModalDetails = useCallback(() => {
    return (
      <>
        <div className="sm-top-section">
          <div className="avatar-wrapper">
            <img
              className="social-media-avatar"
              alt={tiktokVideo?.name}
              src={tiktokVideo?.avatarPicture}
            />
            <div className="social-media-platform-badge">
              <TikTokIcon size={10} backgroundColor={Color.NEUTRAL[0]} />
            </div>
          </div>
          <div className="info-section">
            <span className="name-lbl">{tiktokVideo?.name}</span>
          </div>
          <EmpLink
            text={"View Post"}
            newTab
            href={evidenceRecord.mediaUrl}
            showNewTabIcon
          />
        </div>
        <div className="sm-content">
          <img
            className="sm-image"
            alt="Uploaded evidence"
            src={tiktokVideo.picture}
          />
        </div>

        <div className="sm-metrics">
          <div className="metric-wrapper">
            <PlayIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{tiktokVideo.viewCount}</span>
          </div>
          <div className="metric-wrapper">
            <HeartIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{tiktokVideo.likeCount}</span>
          </div>
          <div className="metric-wrapper">
            <MessageIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{tiktokVideo.commentCount}</span>
          </div>
          <div className="metric-wrapper">
            <ShareIcon size={14} backgroundColor={Color.NEUTRAL[300]} />
            <span>{tiktokVideo.shareCount}</span>
          </div>
        </div>
      </>
    );
  }, [evidenceRecord, tiktokVideo]);

  const renderCaTable = useCallback(() => {
    return (
      <>
        <EmpImage alt="Proof of work preview" src={tiktokVideo.picture} />
      </>
    );
  }, [tiktokVideo]);
  return (
    <>
      {type === "pow-card" && renderPowCard()}
      {type === "pow-modal-details" && renderPowModalDetails()}
      {type === "ca-table" && renderCaTable()}

      <div className="pow-modal-listing">
        {type === "pow-modal-listing" && renderPowModalListing()}
      </div>
    </>
  );
};
