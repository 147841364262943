import { PUB_SUB_TOPICS } from "../constants/pubSubTopics";
import PubSub from "pubsub-js";
import { ToastEnum } from "../constants/toast";

/**
 * Custom implemented service to display alert toast when an event is triggered.
 */
const ToastUtils = {
  success: (title: string, description: string): void => {
    PubSub.publish(PUB_SUB_TOPICS.ALERT, {
      title,
      description,
      type: ToastEnum.SUCCESS,
    });
  },
  error: (title: string, description: string): void => {
    PubSub.publish(PUB_SUB_TOPICS.ALERT, {
      title,
      description,
      type: ToastEnum.ERROR,
    });
  },
};
export default ToastUtils;
