import "./rate-privacy-setting-modal.scss";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import XCloseIcon from "../icon/x-close-icon";
import { Color } from "../../utilities/colors";
import EmpSwitch from "../shared/emp-switch/emp-switch";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { FormControl } from "../../utilities/formUtils/formControl";
import { AGENCY_PRIVACY_SETTING_NAME } from "../../constants/app.constants";
import hiddenRateCardImg from "../../assets/images/hidden-rate-card.png";
import revealedRateCardImg from "../../assets/images/revealed-rate-card.png";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import SettingsApi from "../../api/user-msvc/settings.api";
import EmpException from "../../exception/empException";
import ToastUtils from "../../utilities/toast-utils";
import { FormattedMessage } from "react-intl";
import InfoCircleIcon from "../icon/info-circle-icon";

export interface RateCardPrivacyModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

type RateCardType = "hidden" | "revealed" | "none";

const RatePrivacySettingModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [formIsPristine, setFormIsPristine] = useState(true);

  const saveBtnRef = useRef<EmpButtonRef>();
  const agencyIdRef = useRef<string>();
  const [rateCardType, setRateCardType] = useState<RateCardType>("hidden");
  const [form, setForm] = useState<IFormGroup>({});

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const fetchRateCardPrivacySetting = async () => {
    try {
      const resp = await SettingsApi.fetchRatePrivacy();
      const ratePrivacy = resp.data;
      const value = Boolean(ratePrivacy.value === "Y");
      agencyIdRef.current = ratePrivacy.agencyId;
      form.ratePrivacy = new FormControl("boolean", [], value);
      setRateCardType(value ? "hidden" : "revealed");
      setForm({ ...form });
      setFormIsPristine(FormGroupUtil.isPristine(form));
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch rate card privacy settings"
      );
    }
  };

  const savePrivacySetting = async () => {
    try {
      saveBtnRef.current?.setButtonState("loading");
      if (!agencyIdRef.current) throw new EmpException("No agency id found");
      const request = {
        settings: [
          {
            agencyId: agencyIdRef.current,
            settingName: AGENCY_PRIVACY_SETTING_NAME.RATE_PRIVACY,
            value: form.ratePrivacy.getValue() ? "Y" : "N",
          },
        ],
      };
      const resp = await SettingsApi.updateAgencyPrivacySettings(request);
      if (resp.data.status === "success") {
        ToastUtils.success("Saved", "Rate Privacy Setting Updated");
        fetchRateCardPrivacySetting();
        props.onSave();
        dismiss();
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to fetch rate card privacy settings"
      );
    } finally {
      saveBtnRef.current?.setButtonState("default");
    }
  };

  const show = async () => {
    fetchRateCardPrivacySetting();
    setVisible(true);
  };

  const formControlOnChange = (formControl: FormControl) => {
    const value = formControl.getValue();
    setRateCardType(value ? "hidden" : "revealed");
    setFormIsPristine(FormGroupUtil.isPristine(form));
  };

  const dismiss = () => {
    setVisible(false);
    setForm({});
    setRateCardType("none");
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showHeader={false}
      showFooter={false}
      showFooterBorder={false}
      showHeaderBorder={false}
      bodyPadding={false}
      onClose={dismiss}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-rate-privacy-setting-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>
        <div className="text-content-section">
          <h2 className="title">
            <FormattedMessage id="settingsPrivacyView_ratePrivacyLabel" />
          </h2>
          <p className="description">
            <FormattedMessage id="settingsPrivacyView_ratePrivacyDesc" />
          </p>
        </div>
        <section className="acknowledgement-wrapper">
          <div className="card">
            <div className="intro-wrapper">
              <InfoCircleIcon
                size={16}
                strokeWidth={2.5}
                backgroundColor={Color.PRIMARY[600]}
              />
              <span>This setting is enforced across all your creators.</span>
            </div>
          </div>
        </section>
        <div className="demo-wrapper">
          <div
            className={`slide ${rateCardType === "revealed" ? "selected" : ""}`}
          >
            <img src={revealedRateCardImg} alt="Revealed Rate Card Mockup" />
          </div>
          <div
            className={`slide ${rateCardType === "hidden" ? "selected" : ""}`}
          >
            <img src={hiddenRateCardImg} alt="Hidden Rate Card Mockup" />
          </div>
        </div>
        {form.ratePrivacy && (
          <div className="info-wrapper">
            <div className="modal-setting-item">
              <span className="info-header">Rate Card Privacy</span>
              <div className="switch-wrapper">
                <EmpSwitch
                  onChange={(fc) => {
                    formControlOnChange(fc);
                  }}
                  id={"rateCardPrivacy"}
                  formControl={form.ratePrivacy}
                />
              </div>
            </div>
          </div>
        )}

        <div
          className="mt-6 ph-5"
          style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
        >
          <EmpButton
            isFullWidth={false}
            buttonStyle={"primary"}
            text={"Save Changes"}
            ref={saveBtnRef}
            disabled={formIsPristine}
            onSubmit={() => {
              savePrivacySetting();
            }}
          />
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default RatePrivacySettingModal;
