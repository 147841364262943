import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/app.context";
import "./brand-settings-page.scss";
import { BrandAccountView } from "./views/brand-account-view";
import { BrandManageTeamView } from "./views/brand-manage-team-view";
import { BrandPaymentView } from "./views/brand-payment-view";
import { BrandSubscriptionView } from "./views/brand-subscription-view";
import { BrandSupportView } from "./views/brand-support-view";
import { signOut as awsSignOut } from "aws-amplify/auth";

export const BrandSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentView, setView] = useState<
    | "account"
    | "manage-team"
    | "payment"
    | "support"
    | "subscription"
    | "privacy"
  >("manage-team");

  // M2 ACTIVATION

  /** Redirecting to the correct view based on the window fragment */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    const callback = queryParams.get("emp-callback");
    if (type === "SUCCESS_URL" && callback === "update") {
      window.location.hash = "#subscription";
    }

    const fragment = window.location.hash;
    if (fragment !== "") {
      if (fragment === "#account") {
        setView("account");
      } else if (fragment === "#manage-team") {
        setView("manage-team");
      }
      // M2 ACTIVATION
      else if (fragment === "#subscription") {
        setView("subscription");
      } else if (fragment === "#payment") {
        setView("payment");
      } else if (fragment === "#suport") {
        setView("support");
      } else if (fragment === "#privacy") {
        setView("privacy");
      }
    } else {
      setView("account");
    }
  }, [location]);

  const { setUser: setUserContext } = useContext(AppContext);
  const onSignOut = async () => {
    try {
      await awsSignOut();
      setUserContext!(null);
      navigate("/sign-in");
    } catch (error) {
      if (error instanceof Error) {
        awsSignOut();
        navigate("/sign-in");
      }
    }
  };
  return (
    <div className="emp-page-wrapper">
      <div className="emp-page-content no-padding brand-settings-page mt-5">
        <div className="header-wrapper">
          <h1>
            <FormattedMessage id="settingsPage_header" /> ⚙️
          </h1>
        </div>

        <div className="content">
          <div className="menu-wrapper">
            <div
              onClick={() => {
                setView("account");
                window.location.hash = "account";
              }}
              className={`settings-menu-item ${
                currentView === "account" ? "active" : ""
              }`}
            >
              <FormattedMessage id="settingsPage_accountMenuItem" />
            </div>
            {/* <div
              onClick={() => {
                setView("subscription");
                window.location.hash = "subscription";
              }}
              className={`settings-menu-item ${
                currentView === "subscription" ? "active" : ""
              }`}
            >
              <FormattedMessage id="settingsPage_subscriptionMenuItem" />
            </div> */}
            <div
              onClick={() => {
                setView("manage-team");
                window.location.hash = "manage-team";
              }}
              className={`settings-menu-item ${
                currentView === "manage-team" ? "active" : ""
              }`}
            >
              <FormattedMessage id="settingsPage_manageTeamMenuItem" />
            </div>
            <div
              onClick={() => {
                setView("payment");
                window.location.hash = "payment";
              }}
              className={`settings-menu-item ${
                currentView === "payment" ? "active" : ""
              }`}
            >
              <FormattedMessage id="settingsPage_paymentMenuItem" />
            </div>
            <div
              onClick={() => {
                setView("support");
                window.location.hash = "support";
              }}
              className={`settings-menu-item ${
                currentView === "support" ? "active" : ""
              }`}
            >
              <FormattedMessage id="settingsPage_supportMenuItem" />
            </div>
            <div
              onClick={() => {
                onSignOut();
              }}
              className="settings-menu-item logout"
            >
              <FormattedMessage id="settingsPage_logoutMenuItem" />
            </div>
          </div>
          <div className="setting-view-wrapper">
            {currentView === "account" && <BrandAccountView />}
            {currentView === "manage-team" && <BrandManageTeamView />}
            {currentView === "subscription" && <BrandSubscriptionView />}
            {currentView === "support" && <BrandSupportView />}
            {currentView === "payment" && <BrandPaymentView />}
          </div>
        </div>
      </div>
    </div>
  );
};
