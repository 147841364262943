import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import "./emp-expand-toggle-btn.scss";

interface Props {
  onClick?: () => void;
  isExpanded: boolean;
}

const rotatableLineVariant = {
  expanded: { rotate: 90 },
  collapsed: { rotate: 0 },
};
const fadeableLineVariant = {
  expanded: { opacity: 0 },
  collapsed: { opacity: 1 },
};

export const EmpExpandToggleBtn = (props: Props) => {
  const { isExpanded, onClick } = props;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <>
      <button className=" emp-toggle-btn">
        <div
          className="bg-hover"
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          <div className="anim-circle"></div>
        </div>
        <div className="btn-wrapper">
          <motion.div
            className="horizontal-line"
            transition={{ duration: 0.1, ease: "linear" }}
            initial={expanded ? "expanded" : "collapsed"}
            variants={fadeableLineVariant}
            animate={expanded ? "expanded" : "collapsed"}
          ></motion.div>
          <motion.div
            transition={{ duration: 0.2, ease: "linear" }}
            initial={expanded ? "expanded" : "collapsed"}
            variants={rotatableLineVariant}
            animate={expanded ? "expanded" : "collapsed"}
            className="rotatable-line"
          >
            <div className="line"></div>
          </motion.div>
        </div>
      </button>
    </>
  );
};
