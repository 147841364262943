import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { UrlPreview } from "../../model/campaign/url-preview.dto";
import { EmpInterceptor } from "../emp-interceptor";

const UnfurlApi = {
  unfurlUrl: async (unfurlUrl: string): Promise<ApiResponse<UrlPreview>> => {
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.post<ApiResponse<UrlPreview>>(
      ENDPOINTS.campaignMsvc.urlUnfurl.base,
      { url: unfurlUrl }
    );
    return response.data;
  },
};
export default UnfurlApi;
