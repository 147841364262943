import { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TASK_STATUS, TASK_TYPE } from "../../../constants/app.constants";
import { COUNTRY_CONSTANTS } from "../../../constants/countries.contants";
import { getEnv, getEnvVar } from "../../../constants/envConstants";
import { PILL_COLORS } from "../../../constants/pill-mappers.constants";
import { TaskCardDto } from "../../../model/campaign/task-card.dto";
import { Color } from "../../../utilities/colors";
import FormFieldUtils from "../../../utilities/form-field.util";
import FacebookIcon from "../../icon/facebook-icon";
import InstagramIcon from "../../icon/instagram-icon";
import TargetIcon from "../../icon/target-icon";
import TikTokIcon from "../../icon/tiktok-icon";
import EmpContent from "../emp-content/emp-content";
import EmpLink from "../emp-link/emp-link";
import EmpPill from "../EmpPill/EmpPill";
import "./emp-task-card.scss";
import XIcon from "../../icon/x-icon";

interface Props {
  cardWidth?: number;
  mode?: "default" | "skeleton";
  role?: "brand" | "creator" | "agency";
  taskCardInfo?: TaskCardDto;
  showEngaged?: boolean;
  redirectUrl?: string;
  showCampaignLink?: boolean;
  campaignRedirectUrl?: string;
}

export const EmpTaskCard = (props: Props) => {
  const { role } = props;
  const navigate = useNavigate();
  const showCampaignLink = props.showCampaignLink ?? true;
  const showEngaged = props.showEngaged ?? true;
  const mode = props.mode ?? "default";
  const taskCard = useRef<TaskCardDto | undefined>(
    props.taskCardInfo ?? undefined
  );
  const campaignLinkRef = useRef<HTMLDivElement>(null);
  const agencyLinkRefs = useRef<HTMLDivElement[]>([]);

  const width = props.cardWidth ?? 500;

  const renderCountries = (countries: string[]): JSX.Element => {
    const maxDisplayCountries = 2;
    const extra =
      countries.length > maxDisplayCountries
        ? countries.length - maxDisplayCountries
        : undefined;
    const renderableCountries = countries.slice(0, maxDisplayCountries);
    return (
      <div className="country-wrapper">
        {renderableCountries.map((elem, index) => {
          if (maxDisplayCountries - 1 < index)
            return <Fragment key={"none"}></Fragment>;
          return (
            <img
              key={COUNTRY_CONSTANTS[elem].name}
              className="country"
              alt={COUNTRY_CONSTANTS[elem].name}
              src={COUNTRY_CONSTANTS[elem].flag_1x1}
            />
          );
        })}
        {extra && <div className="extra">+{extra}</div>}
      </div>
    );
  };

  const renderCreators = (): JSX.Element => {
    const maxDisplay = 2;
    if (!taskCard.current?.participantsOverview) return <>Dont Render</>;
    const { acceptedCreators } = taskCard.current.participantsOverview;

    const acceptedExtra =
      acceptedCreators.length > maxDisplay
        ? acceptedCreators.length - maxDisplay
        : undefined;

    if (acceptedCreators.length > 0) {
      return (
        <div className="country-wrapper">
          {acceptedCreators.map((elem, index) => {
            if (maxDisplay - 1 < index)
              return <Fragment key={"none"}></Fragment>;
            if (elem.imageType === "url")
              return (
                <img
                  key={elem.id}
                  className="profile"
                  alt={elem.id}
                  src={elem.imageResource}
                />
              );
            else if (elem.imageType === "avatar")
              return (
                <div
                  key={elem.id}
                  className="profile"
                  style={{ background: elem.imageResource }}
                >
                  <span className="initials">{elem.initials}</span>
                </div>
              );
            else return <Fragment key={"none"}></Fragment>;
          })}
          {acceptedExtra && <div className="extra">+{acceptedExtra}</div>}
        </div>
      );
    } else {
      return <>No Creators</>;
    }
  };

  const renderAgencies = (): JSX.Element => {
    const maxDisplay = 1;
    if (!taskCard.current?.agencyRepresentativesOverview)
      return <>Dont Render</>;
    const agyReprsentative = taskCard.current?.agencyRepresentativesOverview;
    const acceptedExtra =
      agyReprsentative.length > maxDisplay
        ? agyReprsentative.length - maxDisplay
        : undefined;

    if (agyReprsentative.length > 0) {
      return (
        <div className="agency-wrapper">
          {agyReprsentative.map((elem, index) => {
            if (maxDisplay - 1 < index)
              return <Fragment key={"none"}></Fragment>;
            return (
              <Fragment key={elem.id}>
                <img
                  className="profile"
                  referrerPolicy="no-referrer"
                  alt={elem.id}
                  src={elem.logo}
                />
                <div
                  ref={(el: HTMLDivElement) =>
                    (agencyLinkRefs.current[index] = el)
                  }
                >
                  <EmpLink
                    href={`${getEnvVar().appUrl}/${role}/agency/${elem.id}`}
                    newTab
                    text={elem.companyName}
                  />
                </div>
              </Fragment>
            );
          })}
          {acceptedExtra && <div className="extra">+{acceptedExtra}</div>}
        </div>
      );
    } else {
      return <>No Representatives</>;
    }
  };
  // style={{ width: `${width}px` }}
  return (
    <div className="emp-task-card">
      {mode === "default" && role && taskCard.current && (
        <div
          className="task-card"
          onClick={(e: React.MouseEvent) => {
            if (!props.redirectUrl) return;
            const clickedElement = e.target as Node;
            const linkElem = campaignLinkRef.current!;
            if (linkElem) {
              if (
                clickedElement === linkElem ||
                linkElem.contains(clickedElement)
              ) {
                return;
              }
            }
            for (const agencyLinkElem of agencyLinkRefs.current) {
              if (
                clickedElement === agencyLinkElem ||
                agencyLinkElem.contains(clickedElement)
              ) {
                return;
              }
            }
            navigate(props.redirectUrl);
          }}
        >
          <div className="task-wrapper">
            {["agency", "creator"].includes(role) &&
              showEngaged &&
              taskCard.current.isEngaged && (
                <div className="participation-badge">
                  <span>Engaged</span>
                </div>
              )}
            <div className="campaign-img-wrapper">
              {taskCard.current.numActions > 0 && (
                <div className="actions-badge absolute-positioning show-on-mobile-flex">
                  <span className="value">{taskCard.current.numActions}</span>
                </div>
              )}
              <div className={`task-img ${taskCard.current.platform}`}>
                {taskCard.current.platform === TASK_TYPE.FACEBOOK && (
                  <FacebookIcon backgroundColor={Color.NEUTRAL[0]} size={24} />
                )}
                {taskCard.current.platform === TASK_TYPE.TIKTOK && (
                  <TikTokIcon backgroundColor={Color.NEUTRAL[0]} size={24} />
                )}
                {taskCard.current.platform === TASK_TYPE.INSTAGRAM && (
                  <InstagramIcon backgroundColor={Color.NEUTRAL[0]} size={24} />
                )}
                {taskCard.current.platform === TASK_TYPE.X && (
                  <XIcon backgroundColor={Color.NEUTRAL[0]} size={24} />
                )}
                {taskCard.current.platform === TASK_TYPE.GENERAL && (
                  <TargetIcon backgroundColor={Color.NEUTRAL[0]} size={24} />
                )}
              </div>
              <div className="campaign-img-div">
                <img
                  className="campaign-img"
                  alt="task"
                  src={taskCard.current.campaign.picture}
                />
              </div>
            </div>

            <div className="task-details-wrapper">
              <div className="task-header-wrapper">
                <div className="task-name-wrapper">
                  <div className="details-wrapper">
                    <span className="task-name-lbl">
                      {taskCard.current.name}
                    </span>
                    <div className="task-status-wrapper">
                      {taskCard.current.status === TASK_STATUS.RECRUITING && (
                        <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
                      )}
                      {taskCard.current.status === TASK_STATUS.ONGOING && (
                        <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
                      )}
                      {taskCard.current.status === TASK_STATUS.COMPLETED && (
                        <EmpPill text={"Completed"} {...PILL_COLORS.green} />
                      )}
                      <span className="pipe show-on-mobile">|</span>
                      <p className="task-description show-on-mobile">
                        {taskCard.current.numDeliverables} Deliverable
                        {taskCard.current.numDeliverables > 1 ? "s" : ""}
                      </p>
                    </div>
                  </div>
                  {taskCard.current.numActions > 0 && (
                    <div className="actions-badge hide-on-mobile">
                      <span className="value">
                        {taskCard.current.numActions}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-1 task-mid-section-wrapper">
                  {showCampaignLink && (
                    <>
                      <div ref={campaignLinkRef}>
                        <EmpLink
                          onSubmit={() => {
                            if (!props.campaignRedirectUrl) return;
                            navigate(props.campaignRedirectUrl);
                          }}
                          text={taskCard.current.campaign.name}
                        />
                      </div>
                      <span className="pipe hide-on-mobile">|</span>
                    </>
                  )}
                  <p className="task-description hide-on-mobile">
                    {taskCard.current.numDeliverables} Deliverable
                    {taskCard.current.numDeliverables > 1 ? "s" : ""}
                  </p>
                </div>
              </div>
              <div className="task-info-wrapper">
                {role === "agency" && (
                  <EmpContent
                    label={"CREATED BY"}
                    value={
                      <div className="organisation-wrapper">
                        <img
                          className="profile"
                          alt={taskCard.current.organisation.companyName}
                          src={taskCard.current.organisation.logo}
                        />
                        <span>{taskCard.current.organisation.companyName}</span>
                      </div>
                    }
                  />
                )}
                {role === "creator" && (
                  <EmpContent
                    label={"REPRESENTING AGENCIES"}
                    value={renderAgencies()}
                  />
                )}
                <EmpContent
                  label={"COUNTRIES"}
                  className="hide-on-mobile"
                  value={renderCountries(taskCard.current.countries)}
                />
                {taskCard.current.participantsOverview && (
                  <EmpContent
                    label={"CONFIRMED CREATORS"}
                    value={renderCreators()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {mode === "skeleton" && (
        <div className="skeletal-card">
          <div className="task-img-skeleton shimmer-color hide-on-mobile"></div>
          <div className="task-details-wrapper-skeleton hide-on-mobile">
            <div className="task-top-section">
              <div className="task-name-skeleton shimmer-color"></div>
              <div className="task-status-skeleton shimmer-color mt-2"></div>
            </div>
            <div className="task-bottom-section">
              <div>
                <div className="date-title-skeleton shimmer-color"></div>
                <div className="date-value-skeleton shimmer-color mt-2"></div>
              </div>
              <div>
                <div className="country-title-skeleton shimmer-color"></div>
                <div className="rounded-img-wrapper mt-2">
                  <div className="rounded-img shimmer-color"></div>
                  <div className="rounded-img shimmer-color"></div>
                  <div className="rounded-img shimmer-color"></div>
                </div>
              </div>
              <div>
                <div className="hired-creators-title-skeleton shimmer-color"></div>
                <div className="rounded-img-wrapper mt-2">
                  <div className="rounded-img shimmer-color"></div>
                  <div className="rounded-img shimmer-color"></div>
                  <div className="rounded-img shimmer-color"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
