import "./change-password-modal.scss";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import EmpButton, {
  EmpButtonRef,
} from "../../components/shared/emp-button/emp-button";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import EmpModal from "../../components/shared/emp-modal/emp-modal";
import EmpExceptionHandlerBuilder from "../../utilities/errorUtils/empExceptionHandlerBuilder";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../utilities/toast-utils";
import { motion } from "framer-motion";
import EmpTextInput from "../../components/shared/emp-text-input/emp-text-input";
import _debounce from "lodash/debounce";
import LockIcon from "../../components/icon/lock-icon";
import { empDelay } from "../../utilities/delay";
import XCloseIcon from "../../components/icon/x-close-icon";
import { Color } from "../../utilities/colors";
import AlertSquareIcon from "../../components/icon/alert-square";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import { updatePassword } from "aws-amplify/auth";
import { PasswordUtil } from "../../utilities/password.util";
import { FormattedMessage, useIntl } from "react-intl";

export interface ChangePasswordModalRef {
  show: () => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
}

interface SlideHeight {
  firstSlide: number;
  secondSlide: number;
}
const ChangePasswordModal = forwardRef((props: Props, ref) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const intl = useIntl();

  const [bodyVariant, setBodyVariant] = useState<string>("firstSlide");
  const [slideHeights, setSlideHeights] = useState<SlideHeight>({
    firstSlide: 0,
    secondSlide: 0,
  });

  // Slide refs
  const firstSlideRef = useRef<HTMLDivElement>(null);
  const secondSlideRef = useRef<HTMLDivElement>(null);

  const changePasswordBtnRef = useRef<EmpButtonRef>();

  const sliderVariant = {
    firstSlide: { left: "0%", height: slideHeights.firstSlide },
    secondSlide: { left: "-100%", height: slideHeights.secondSlide },
  };

  useEffect(() => {
    if (!visible) return;
    const resize = async () => {
      empDelay(100);
      if (firstSlideRef?.current && secondSlideRef?.current) {
        computeHiddenFormHeight(
          firstSlideRef.current.clientHeight,
          secondSlideRef.current.clientHeight
        );
      }
    };

    const observeResize = async (observer: ResizeObserver) => {
      await empDelay(100);
      observer.observe(firstSlideRef.current!);
      observer.observe(secondSlideRef.current!);
    };

    const resizeObserver = new ResizeObserver(() => {
      debounceFn(
        firstSlideRef.current!.clientHeight,
        secondSlideRef.current!.clientHeight
      );
    });
    resize();
    observeResize(resizeObserver);
    return () => resizeObserver.disconnect();
  }, [visible]);

  const computeHiddenFormHeight = (
    firstSlideHeight: number,
    secondSlideHeight: number
  ) => {
    setSlideHeights({
      firstSlide: firstSlideHeight,
      secondSlide: secondSlideHeight,
    });
  };
  const debounceFn: (
    firstSlideHeight: number,
    secondSlideHeight: number
  ) => void = useCallback(
    _debounce(computeHiddenFormHeight, 150, { leading: true }),
    []
  );

  const [form, setForm] = useState<IFormGroup>({
    password: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_passwordRequired" })
      ),
    ]),
    newPassword: new FormControl("text", [
      new RequiredValidator(
        intl.formatMessage({ id: "validation_passwordRequired" })
      ),
    ]),
    confirmPassword: new FormControl("text", [
      new RequiredValidator("Confirm password is required"),
    ]),
  });

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = async () => {
    try {
      setVisible(true);
    } catch (e) {
      if (e instanceof Error) {
        new EmpExceptionHandlerBuilder()
          .handleCommonlHttpErrors()
          .handleGenericError()
          .build()
          .process(e);
        return;
      }
      ToastUtils.error("An Error Occurred", "Please try again");
    }
  };

  const dismiss = async () => {
    setVisible(false);
    await empDelay(500);
    FormGroupUtil.reset(form);
    setSubmitted(false);
    setErrorMessage(undefined);
    setForm({ ...form });
    setBodyVariant("firstSlide");
  };

  /*--------------- Functions for Slide [1] --------------------- */
  const [errorMessage, setErrorMessage] = useState<string>();

  /**
   * This function validates a form control and updates the form state if necessary.
   * @param formControl - The form control to be validated.
   * @returns void
   */
  const validateForm = (formControl: FormControl): void => {
    if (!isSubmitted) return;
    if (formControl.validateTrackDiff()) {
      setForm({ ...form });
    }
  };

  const verifyPassword = async () => {
    try {
      changePasswordBtnRef.current?.setButtonState("loading");

      setSubmitted(true);
      const formIsValid = FormGroupUtil.validate(form);
      setForm({ ...form });

      const result = PasswordUtil.validatePasswordInput(
        intl,
        form.newPassword.getValue(),
        form.confirmPassword.getValue()
      );
      if (result.hasError) {
        form.confirmPassword.errorMessage = result.errorMsg;
        form.confirmPassword.hasError = true;
      }
      if (!formIsValid || result.hasError) {
        setForm({ ...form });
        return;
      }
      // const user = await ();

      await updatePassword({
        oldPassword: form.password.getValue(),
        newPassword: form.newPassword.getValue(),
      });

      // Submission successful
      setBodyVariant("secondSlide");
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Unable to send verification email"
      );
      if (e instanceof Error) {
        if (e.name === "LimitExceededException")
          ToastUtils.error(
            "Maximum Retries",
            "Please wait before sending again."
          );
        if (e.name === "NotAuthorizedException")
          setErrorMessage("Current password is incorrect.");
      }
    } finally {
      changePasswordBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      showFooter={false}
      showFooterBorder={false}
      showHeader={false}
      showHeaderBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-change-password-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon backgroundColor={Color.NEUTRAL[500]} />
        </div>

        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          autoComplete="new-password"
        >
          <motion.div
            variants={sliderVariant}
            initial={"firstSlide"}
            animate={bodyVariant}
            className="content-body"
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
            style={{ width: "200%" }}
          >
            <div className="swipe-slide first">
              <div style={{ boxSizing: "border-box" }} ref={firstSlideRef}>
                <div className="title-wrapper">
                  <h3>
                    <FormattedMessage id="changePasswordModal_header" />
                  </h3>
                </div>

                <span className="description">
                  <FormattedMessage id="changePasswordModal_desc" />
                </span>
                <div className="mt-4">
                  <EmpTextInput
                    id={""}
                    formControl={form.password}
                    onChange={(fc) => validateForm(fc)}
                    type="password"
                    showPassword
                    placeholder={intl.formatMessage({
                      id: "changePasswordModal_currentPasswordInputLabel",
                    })}
                    labelText={intl.formatMessage({
                      id: "changePasswordModal_currentPasswordInputPlaceholder",
                    })}
                    leftIconComponent={LockIcon}
                  />
                  <EmpTextInput
                    id={"newPassword"}
                    onChange={(fc) => validateForm(fc)}
                    formControl={form.newPassword}
                    placeholder={intl.formatMessage({
                      id: "changePasswordModal_newPasswordInputLabel",
                    })}
                    labelText={intl.formatMessage({
                      id: "changePasswordModal_newPasswordInputPlaceholder",
                    })}
                    type="password"
                    showPassword
                    required
                    autocomplete={false}
                    leftIconComponent={LockIcon}
                  />
                  <EmpTextInput
                    id={"confirmPassword"}
                    onChange={(fc) => validateForm(fc)}
                    formControl={form.confirmPassword}
                    placeholder={intl.formatMessage({
                      id: "changePasswordModal_confirmPasswordInputLabel",
                    })}
                    labelText={intl.formatMessage({
                      id: "changePasswordModal_confirmPasswordInputPlaceholder",
                    })}
                    type="password"
                    showPassword
                    required
                    autocomplete={false}
                    leftIconComponent={LockIcon}
                  />
                  {errorMessage && (
                    <div className="emp-error-message-wrapper mt-3">
                      <AlertSquareIcon
                        backgroundColor={Color.RED[600]}
                        size={16}
                        bottom={1}
                      />
                      <span>{errorMessage}</span>
                    </div>
                  )}
                </div>
                <div
                  className="mt-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <EmpButton
                    ref={changePasswordBtnRef}
                    isFullWidth={false}
                    text={
                      <FormattedMessage id="changePasswordModal_changePasswordBtn" />
                    }
                    onSubmit={() => {
                      verifyPassword();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="swipe-slide second">
              <div className="second-slide-wrapper" ref={secondSlideRef}>
                <img
                  className="success-img"
                  alt="Checkmark"
                  srcSet="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/checkmark-img.png"
                />
                <h3 className="mt-4">
                  <FormattedMessage id="changePasswordModal_successHeader" />
                </h3>
                <span className="description">
                  <FormattedMessage id="changePasswordModal_successDesc" />
                </span>
                <div className="mt-4">
                  <EmpButton
                    isFullWidth={false}
                    text={<FormattedMessage id="cta_ack" />}
                    onSubmit={() => {
                      dismiss();
                    }}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});
export default ChangePasswordModal;
