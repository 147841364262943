import { ENDPOINTS } from "../../constants/endpoints";
import { ApiResponse } from "../../model/api/api-response";
import { ApiStatus } from "../../model/api/api-status";
import { SelectOption } from "../../model/common/selectOption";
import { EmpInterceptor } from "../emp-interceptor";

const CryptoApi = {
  getChainOptions: async (
    taskId: string
  ): Promise<ApiResponse<SelectOption[]>> => {
    const url = ENDPOINTS.campaignMsvc.crypto.getChainOptions(taskId);
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.get<ApiResponse<SelectOption[]>>(url);
    return response.data;
  },

  updateChainOptions: async (
    taskId: string,
    dto: { chain: string }
  ): Promise<ApiResponse<ApiStatus>> => {
    const url = ENDPOINTS.campaignMsvc.crypto.getChainOptions(taskId);
    const axios = await EmpInterceptor.axiosClient();
    const response = await axios.put<ApiResponse<ApiStatus>>(url, dto);
    return response.data;
  },
};
export default CryptoApi;
