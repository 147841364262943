import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import EmpException from "../../exception/empException";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import ToastUtils from "../../utilities/toast-utils";
import XCloseIcon from "../icon/x-close-icon";
import EmpButton, { EmpButtonRef } from "../shared/emp-button/emp-button";
import EmpModal from "../shared/emp-modal/emp-modal";
import "./confirm-modal.scss";

export interface ConfirmModalRef {
  show: (context: string, item: string, fn: () => void) => void;
  hide: () => void;
}

interface Props {
  onSave: () => void;
  suppressToast?: boolean;
  title?: string | JSX.Element;
  confirmBtnText?: string;
  description?: string | JSX.Element;
}

const ConfirmModal = forwardRef((props: Props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const actionBtnRef = useRef<EmpButtonRef>(null);

  const infoRef = useRef<{ context: string; item: string }>({
    context: "",
    item: "",
  });
  const confirmFnRef = useRef<() => void>();
  const suppressToast = props.suppressToast ?? false;
  const title = props.title ?? <FormattedMessage id="confirmModal_header" />;
  const description = props.description ?? (
    <FormattedMessage id="confirmModal_desc" />
  );
  const confirmBtnText = props.confirmBtnText ?? (
    <FormattedMessage id="cta_confirm" />
  );

  useImperativeHandle(ref, () => {
    return {
      show,
      dismiss,
    };
  });

  const show = (context: string, item: string, fn: () => void) => {
    infoRef.current = { context, item };
    confirmFnRef.current = fn;
    setVisible(true);
  };

  const dismiss = () => {
    setVisible(false);
  };

  const confirmOnClick = async () => {
    try {
      if (!confirmFnRef.current)
        throw new EmpException("Unable to get confirm function");
      confirmFnRef.current();
      props.onSave();
      dismiss();
      if (!suppressToast) {
        ToastUtils.success(
          `Successfully confirmed ${infoRef.current.item}`,
          `${infoRef.current.context} confirmed`
        );
      }
    } catch (e) {
      EmpExceptionHandler.handleHttpRequestError(
        e,
        `Unable to confirm ${infoRef.current.context}`
      );
    }
  };

  return (
    <EmpModal
      visible={visible}
      setVisible={setVisible}
      header={`Confirm ${infoRef.current.context}`}
      showHeader={false}
      showHeaderBorder={false}
      showFooter={false}
      showFooterBorder={false}
      size={"sm"}
    >
      {/* This is body */}
      <div className="emp-confirm-modal">
        <div onClick={() => dismiss()} className="dismiss-icon-wrapper">
          <XCloseIcon
            hoverColor={Color.NEUTRAL[800]}
            hoverEnabled
            backgroundColor={Color.NEUTRAL[500]}
          />
        </div>
        <div className="confirm-modal-text-content-section">
          <div className="title-wrapper">
            <h2 className="title">{title}</h2>
          </div>
          <p className="description">{description}</p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 16,
          }}
        >
          <div style={{ width: "fit-content" }}>
            <EmpButton
              ref={actionBtnRef}
              onSubmit={() => {
                confirmOnClick();
              }}
              text={confirmBtnText}
              buttonStyle="primary"
            />
          </div>
          <div style={{ width: "fit-content", marginRight: 8 }}>
            <EmpButton
              text={<FormattedMessage id="cta_cancel" />}
              buttonStyle="secondary"
              onSubmit={() => {
                dismiss();
              }}
            />
          </div>
        </div>
      </div>
      {/* This is footer */}
      <div></div>
    </EmpModal>
  );
});

export default ConfirmModal;
