import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import NegotiationApi from "../../../api/campaign-msvc/negotiation.api";
import { NEGOTIATION_STATUS } from "../../../constants/app.constants";
import { CounterofferDto } from "../../../model/campaign/counteroffer.dto";
import { LatestNegotiationExtendedDto } from "../../../model/campaign/latest-negotiation-extended.dto";
import { TaskDto } from "../../../model/campaign/task.dto";
import EmpExceptionHandler from "../../../utilities/errorUtils/empExceptionHandler";
import { FormControl } from "../../../utilities/formUtils/formControl";
import {
  FormGroupUtil,
  IFormGroup,
} from "../../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../../utilities/formUtils/lengthValidator";
import { NumericRangeValidator } from "../../../utilities/formUtils/numericRangeValidator";
import { PatternValidator } from "../../../utilities/formUtils/patternValidator";
import { RequiredValidator } from "../../../utilities/formUtils/requiredValidator";
import ToastUtils from "../../../utilities/toast-utils";
import EmpButton, { EmpButtonRef } from "../../shared/emp-button/emp-button";
import EmpLink from "../../shared/emp-link/emp-link";
import EmpTextInput from "../../shared/emp-text-input/emp-text-input";
import "../invite-task-modal.scss";
import TaskDeliverableModal, {
  TaskDeliverableModalRef,
} from "../task-deliverable-modal";
import EmpCheckbox, {
  EmpCheckboxRef,
} from "../../shared/emp-checkbox/emp-checkbox";
import { EmpCoinIcon } from "../../shared/emp-coin-icon/emp-coin-icon";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const animationDelay = 0.1;
const animationDuration = 0.3;

interface Props {
  task: TaskDto;
  latestNegotiation: LatestNegotiationExtendedDto;
  context: string;
  onCancel: () => void;
  onComplete: () => void;
}

const TaskCounterofferOnlyResponseView = (props: Props) => {
  const { task, latestNegotiation, onComplete, context, onCancel } = props;
  const counterofferBtnRef = useRef<EmpButtonRef>();
  const taskDeliverableModalRef = useRef<TaskDeliverableModalRef>();
  const numberRegex = /^(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,6})?$/;

  const ackCheckboxRef = useRef<EmpCheckboxRef>();
  const [ackIsChecked, setAckIsChecked] = useState(false);

  const location = useLocation();

  const [form, setForm] = useState<IFormGroup>({
    amount: new FormControl("text", [
      new RequiredValidator("Please specify a rate"),
      new PatternValidator(numberRegex, "Please provide a numeric value"),
      new NumericRangeValidator(
        0,
        40000,
        "Rate must be more than zero",
        "Rate must not exceed 40,000"
      ),
    ]),
    remarks: new FormControl("text", [
      new LengthValidator(
        -1,
        400,
        "",
        "Application remarks must not exceed 400 characters"
      ),
    ]),
  });

  const sendCounteroffer = async () => {
    try {
      counterofferBtnRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const request: CounterofferDto = {
        brandOrgId: latestNegotiation.brandOrgId,
        creatorUserId: latestNegotiation.creatorUserId,
        agencyOrgId: latestNegotiation.agencyOrgId,
        representativeId: latestNegotiation.representativeId,
        representativeRole: latestNegotiation.representativeRole,
        amount: form.amount.getValue(),
        campaignId: latestNegotiation.campaignId,
        taskId: latestNegotiation.taskId,
        remarks: form.remarks.getValue(),
      };
      let resp;
      if (context === "brand") {
        resp = await NegotiationApi.sendBrandCounteroffer(request);
      } else {
        resp = await NegotiationApi.sendSellerCounteroffer(request);
      }

      if (resp.data.status === "success") {
        ToastUtils.success(
          "Counteroffer Submitted",
          "The brand has received your counter offer."
        );
        onComplete();
      }
    } catch (e) {
      console.error(e);
      EmpExceptionHandler.handleHttpRequestError(
        e,
        "Error rejecting task application"
      );
    } finally {
      counterofferBtnRef.current?.setButtonState("default");
    }
  };

  return (
    <div className="single-response-view">
      <TaskDeliverableModal ref={taskDeliverableModalRef} />
      <section className="profile-photo-section">
        {[
          NEGOTIATION_STATUS.SELLER_ACCEPT,
          NEGOTIATION_STATUS.BRAND_ACCEPT,
        ].includes(latestNegotiation.status) &&
          context === "brand" && (
            <div className="profile-photo-wrapper">
              <img
                alt="Profile"
                className="profile-photo org-photo"
                src={latestNegotiation.brand.logo}
              />
              <span className="plus-label">+</span>
              <div className="creator-rep-wrapper">
                <div className="relative-wrapper">
                  <div className="creator-profile">
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "url" && (
                      <img
                        alt="Profile"
                        className="profile-photo"
                        src={
                          latestNegotiation.creatorRepresentative.imageResource
                        }
                      />
                    )}
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "none" && (
                      <img
                        alt="Empty profile"
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "avatar" && (
                      <div
                        style={{
                          background:
                            latestNegotiation.creatorRepresentative
                              .imageResource,
                        }}
                        className="avatar"
                      >
                        <span className="initials">
                          {latestNegotiation.creatorRepresentative.initials}
                        </span>
                      </div>
                    )}
                  </div>
                  {latestNegotiation.representativeRole === "agency" && (
                    <div className="absolute-wrapper">
                      <img
                        alt="Agency brand"
                        className="profile-photo org-photo"
                        src={latestNegotiation.agencyRepresentative.logo}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        {[
          NEGOTIATION_STATUS.SELLER_ACCEPT,
          NEGOTIATION_STATUS.BRAND_ACCEPT,
        ].includes(latestNegotiation.status) &&
          context === "seller" && (
            <div className="profile-photo-wrapper">
              <div className="creator-rep-wrapper">
                <div className="relative-wrapper">
                  <div className="creator-profile">
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "url" && (
                      <img
                        alt="Profile"
                        className="profile-photo"
                        src={
                          latestNegotiation.creatorRepresentative.imageResource
                        }
                      />
                    )}
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "none" && (
                      <img
                        alt="Empty profile"
                        src={
                          "https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/empty-profile.png"
                        }
                      />
                    )}
                    {latestNegotiation.creatorRepresentative.imageType ===
                      "avatar" && (
                      <div
                        style={{
                          background:
                            latestNegotiation.creatorRepresentative
                              .imageResource,
                        }}
                        className="avatar"
                      >
                        <span className="initials">
                          {latestNegotiation.creatorRepresentative.initials}
                        </span>
                      </div>
                    )}
                  </div>
                  {latestNegotiation.representativeRole === "agency" && (
                    <div className="absolute-wrapper">
                      <img
                        alt="Agency brand"
                        className="profile-photo org-photo"
                        src={latestNegotiation.agencyRepresentative.logo}
                      />
                    </div>
                  )}
                </div>
              </div>
              <span className="plus-label">+</span>
              <img
                alt="Profile"
                className="profile-photo org-photo"
                src={latestNegotiation.brand.logo}
              />
            </div>
          )}
      </section>

      <div className="content-section">
        <motion.span
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 1 * animationDelay,
          }}
          className="task-name-lbl"
        >
          {[
            NEGOTIATION_STATUS.SELLER_ACCEPT,
            NEGOTIATION_STATUS.BRAND_ACCEPT,
          ].includes(latestNegotiation.status) &&
            context === "seller" &&
            latestNegotiation.representativeRole === "creator" && (
              <>Submit a counteroffer to join this task.</>
            )}
          {[
            NEGOTIATION_STATUS.SELLER_ACCEPT,
            NEGOTIATION_STATUS.BRAND_ACCEPT,
          ].includes(latestNegotiation.status) &&
            context === "seller" &&
            latestNegotiation.representativeRole === "agency" && (
              <>
                Submit a counteroffer for{" "}
                {latestNegotiation.creatorRepresentative.fullName} to join this
                task.
              </>
            )}
          {[
            NEGOTIATION_STATUS.SELLER_ACCEPT,
            NEGOTIATION_STATUS.BRAND_ACCEPT,
          ].includes(latestNegotiation.status) &&
            context === "brand" && (
              <>
                Submit a counteroffer for{" "}
                {latestNegotiation.creatorRepresentative.fullName} to take part
                in this task.
              </>
            )}
        </motion.span>

        <motion.p
          variants={fadeInVariants}
          initial="hidden"
          animate={"visible"}
          transition={{
            duration: animationDuration,
            delay: 2 * animationDelay,
          }}
          className="description mt-4"
        >
          You may refer to the{" "}
          <EmpLink
            text={"deliverables"}
            onSubmit={() => {
              taskDeliverableModalRef.current?.show(task);
            }}
          />{" "}
          to find out the scope of this task.
        </motion.p>

        <div>
          <hr className="emp-hr" />
          <motion.div
            variants={fadeInVariants}
            className="mt-4"
            initial="hidden"
            animate={"visible"}
            transition={{
              duration: animationDuration,
              delay: 3 * animationDelay,
            }}
          >
            <EmpTextInput
              id={"rate"}
              labelText="Your Counteroffer"
              leftComponent={<EmpCoinIcon iconValue={task.paymentMode} />}
              leftIconSize={12}
              description={`If the ${
                context === "brand" ? "creator / agency" : "brand"
              } finds your offer agreeable, you'll be all set to join this campaign task.`}
              required
              formControl={form.amount}
              placeholder={"00.00"}
            />
          </motion.div>

          <motion.div
            variants={fadeInVariants}
            initial="hidden"
            animate={"visible"}
            className="mt-3"
            transition={{
              duration: animationDuration,
              delay: 3 * animationDelay,
            }}
          >
            <EmpTextInput
              id={"remarks"}
              labelText="Remarks (Optional)"
              multiline
              textAreaAdaptiveHeight
              tooltip="Craft a concise and captivating campaign description. Highlight campaign goals, target audience, and unique features to engage stakeholders. Make it persuasive, providing a clear understanding of the campaign's purpose and benefits."
              formControl={form.remarks}
              rows={2}
              characterCount={300}
              placeholder={
                "You may elaborate more about the scope of work, or any price justifications"
              }
            />
          </motion.div>
        </div>
      </div>

      <section className="acknowledgement-wrapper mt-4">
        <div className="card">
          <div className="intro-wrapper">
            <span>CONFIRMATION</span>
          </div>
          <div className="control-wrapper mt-2">
            <div className="checkbox-wrapper">
              <EmpCheckbox
                onChange={() => {
                  ackCheckboxRef.current?.setChecked(!ackIsChecked);
                  setAckIsChecked(!ackIsChecked);
                }}
                ref={ackCheckboxRef}
                id={"acknowledgement"}
              />
            </div>
            <div className="checkbox-text-wrapper">
              <div
                onClick={() => {
                  ackCheckboxRef.current?.setChecked(!ackIsChecked);
                  setAckIsChecked(!ackIsChecked);
                }}
              >
                <span className={ackIsChecked ? "checked" : ""}>
                  {context === "brand" && (
                    <>
                      I understand that upon submitting a counteroffer, the
                      creator will no longer be in the accepted state. I will
                      not be able to start the task with this creator until
                      there is a response to this counteroffer.
                    </>
                  )}
                  {context === "seller" && (
                    <>
                      I understand that upon submitting a counteroffer, the
                      creator will no longer be in the accepted state and I have
                      to wait for the brand to react to my counteroffer.
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <motion.div
        variants={fadeInVariants}
        initial="hidden"
        animate={"visible"}
        style={{ display: "flex", gap: 8, justifyContent: "flex-end" }}
        transition={{ duration: animationDuration, delay: 4 * animationDelay }}
      >
        <EmpButton
          buttonStyle="secondary"
          onSubmit={onCancel}
          isFullWidth={false}
          className="mt-4"
          text={"Cancel"}
        />

        <EmpButton
          ref={counterofferBtnRef}
          onSubmit={() => {
            sendCounteroffer();
          }}
          className="mt-4"
          disabled={!ackIsChecked}
          isFullWidth={false}
          buttonStyle="primary"
          text={"Submit Counteroffer"}
        />
      </motion.div>
    </div>
  );
};
export default TaskCounterofferOnlyResponseView;
